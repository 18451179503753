import * as Yup from 'yup'
import {
  noWhitespaceEmail,
  noWhitespacePhone,
  noWhitespaceString,
} from '../common/YupExtensions'
import { DefaultContact, IContact } from '../Definitions'
import { createITagFormValues, ITagFormValues, toTagDto } from '../Tags/TagFormValues'

export const ContactSchema = Yup.object().shape({
  email: noWhitespaceEmail(),
  firstName: noWhitespaceString('First Name is required', true),
  lastName: noWhitespaceString('Last Name is required', true),
})

export const AlternateContactSchema = Yup.object().shape(
  {
    email: noWhitespaceEmail(),
    firstName: Yup.string().when(
      ['lastName', 'officeNumber'],
      (lastName: any, officeNumber: any) => {
        return !lastName && !officeNumber
          ? noWhitespaceString('First or Last Name or Office # is required', true)
          : noWhitespaceString('First or Last Name or Office # is required')
      }
    ),
    lastName: Yup.string().when(
      ['firstName', 'officeNumber'],
      (firstName: any, officeNumber: any) => {
        return !firstName && !officeNumber
          ? noWhitespaceString('First or Last Name or Office # is required', true)
          : noWhitespaceString('First or Last Name or Office # is required')
      }
    ),
    officeNumber: Yup.string().when(
      ['firstName', 'lastName'],
      (firstName: any, lastName: any) => {
        return !firstName && !lastName
          ? noWhitespacePhone('First or Last Name or Office # is required', true)
          : noWhitespacePhone('First or Last Name or Office # is required')
      }
    ),
  },
  [
    ['firstName', 'lastName'],
    ['firstName', 'officeNumber'],
    ['lastName', 'officeNumber'],
  ]
)

export interface IContactFormValues {
  readonly id: string
  firstName: string
  lastName: string
  suffix: string
  title: string
  officeNumber: string
  cellNumber: string
  faxNumber: string
  isCostSavingsReport: boolean
  isSpecialPricing: boolean
  email: string
  contactType: string
  comments: string
  isActive: boolean
  dateIncentiveGiven?: Date | undefined
  contactTags: ITagFormValues[]
}

function setDefaultValues(): IContactFormValues {
  return {
    cellNumber: '',
    comments: '',
    contactType: '',
    dateIncentiveGiven: undefined,
    email: '',
    faxNumber: '',
    firstName: '',
    id: '',
    isActive: true,
    isCostSavingsReport: false,
    isSpecialPricing: false,
    lastName: '',
    officeNumber: '',
    suffix: '',
    contactTags: [] as ITagFormValues[],
    title: '',
  }
}

function setValuesFromContact(contact: IContact): IContactFormValues {
  return {
    cellNumber: contact.cellNumber || '',
    comments: contact.comments || '',
    contactType: contact.contactType || '',
    dateIncentiveGiven: contact.dateIncentiveGiven || undefined,
    email: contact.email || '',
    faxNumber: contact.faxNumber || '',
    firstName: contact.firstName || '',
    id: contact.id || '',
    isActive: contact.isActive,
    isCostSavingsReport: contact.isCostSavingsReport || false,
    isSpecialPricing: contact.isSpecialPricing || false,
    lastName: contact.lastName || '',
    officeNumber: contact.officeNumber || '',
    suffix: contact.suffix || '',
    contactTags: contact.contactTags.map(createITagFormValues) || [],
    title: contact.title || '',
  }
}

export function createIContactFormValues(contact?: IContact) {
  if (contact) {
    return setValuesFromContact(contact)
  } else {
    return setDefaultValues()
  }
}

export function toIContact(
  formValues: IContactFormValues,
  original?: IContact
): IContact {
  const contact = original === undefined ? DefaultContact() : original
  return {
    ...contact,
    cellNumber: formValues.cellNumber,
    comments: formValues.comments,
    contactType: formValues.contactType,
    dateIncentiveGiven: formValues.dateIncentiveGiven || undefined,
    email: formValues.email,
    faxNumber: formValues.faxNumber,
    firstName: formValues.firstName,
    id: formValues.id,
    isActive: formValues.isActive,
    isCostSavingsReport: formValues.isCostSavingsReport,
    isSpecialPricing: formValues.isSpecialPricing,
    lastName: formValues.lastName,
    officeNumber: formValues.officeNumber,
    suffix: formValues.suffix,
    contactTags: formValues.contactTags ? formValues.contactTags.map(toTagDto) : [],
    title: formValues.title,
  }
}
