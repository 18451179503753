import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import HelpIcon from '@material-ui/icons/Help'
import { inject, observer } from 'mobx-react'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import * as React from 'react'
import { IStores } from '../../Stores'
import { INotification } from '../../stores/NotificationStore/NotificationStore'

interface INotifierProps extends WithSnackbarProps {
  markNotificationShown?: (key: string) => void
  notifications?: INotification[]
  removeNotification?: (key: string) => void
  notificationConfig?: any
  shown?: boolean
  openDialog?: (notification: INotification) => void
}
@observer
class Notifier extends React.Component<INotifierProps> {
  public render() {
    const {
      enqueueSnackbar,
      markNotificationShown,
      notifications,
      removeNotification,
      notificationConfig,
    } = this.props
    notifications!
      .filter((n: INotification) => !n.isShown)
      .forEach((notification: INotification) => {
        const actions = []
        if (notification.information) {
          actions.push(
            <IconButton
              key="info"
              color="primary"
              onClick={this.openDialog(notification)}
            >
              <HelpIcon color="action" />
            </IconButton>
          )
        }
        if (this.shouldPersist(notification)) {
          notification.options.autoHideDuration = undefined
          actions.push(
            <IconButton
              key="close"
              onClick={removeNotification!(notification.key) as any}
            >
              <CloseIcon />
            </IconButton>
          )
        } else {
          setTimeout(() => {
            removeNotification!(notification.key)
          }, notificationConfig.DefaultAutoHide)
        }
        notification.options.action = actions
        enqueueSnackbar(notification.message, notification.options)
        markNotificationShown!(notification.key)
      })
    return null
  }

  private shouldPersist(notification: INotification) {
    return (
      this.props.notificationConfig.PersistAllNotifications ||
      (this.props.notificationConfig.PersistErrorNotifications &&
        notification.options.variant === 'error')
    )
  }

  public openDialog = (notification: INotification) => () => {
    this.props.openDialog!(notification)
  }
}

const InjectedNotifier = inject<IStores, INotifierProps, Partial<INotifierProps>, any>(
  (stores: IStores) => ({
    markNotificationShown: stores.notifications.markNotificationShown,
    notifications: stores.notifications.notifications,
    openDialog: stores.notifications.openDialog,
    removeNotification: stores.notifications.removeNotification,
  })
)(withSnackbar(Notifier))

export default InjectedNotifier
export { Notifier }
