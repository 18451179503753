import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog'
import * as React from 'react'
import Button from '../common/Button'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { inject } from 'mobx-react'
import { IStores } from 'src/Stores'
import { ClaimHeaderDetailsSummaryResult } from 'src/viewModels/ClaimHeaderDetailsSummaryResult'
import moment from 'moment'
import { markClaimDeleted } from 'src/services/ClaimService'
import { Delete } from '@material-ui/icons'

export interface IMarClaimDeletedModal extends DialogProps {
  cancel: () => void
  CMS1500Id: string
  groupInfo?: string
  patientHeader?: ClaimHeaderDetailsSummaryResult
}

class MarClaimDeletedModal extends React.Component<IMarClaimDeletedModal> {
  public componentDidMount() {}

  private markClaimDeleted = async () => {
    markClaimDeleted(this.props.CMS1500Id).then(() => {
      this.props.cancel()
    })
  }

  public render() {
    const { cancel, ...rest } = this.props
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={cancel}
        {...rest}
        disableBackdropClick={true}
      >
        <ResponsiveAppBar
          title={
            'Mark Claim Deleted - ' +
            this.props.patientHeader?.patientLast +
            ', ' +
            this.props.patientHeader?.patientFirst +
            ' (' +
            moment(this.props.patientHeader?.dateOfBirth).format('MM/DD/YYYY') +
            ') ' +
            this.props.groupInfo
          }
          pageIcon={<Delete />}
          isModal={true}
        />
        <DialogContent style={{ marginTop: 20 }}>
          <div>Are you sure you want to mark this claim deleted?</div>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="center"
            style={{ margin: '0px 10px 4px' }}
          >
            <Grid item>
              <Button onClick={cancel} style={{ marginRight: '8px' }}>
                Cancel
              </Button>

              <Button
                onClick={() => {
                  this.markClaimDeleted()
                }}
                color="primary"
                variant="contained"
              >
                Mark Deleted
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}
const InjectedMarClaimDeletedModal = inject<
  IStores,
  IMarClaimDeletedModal,
  Partial<IMarClaimDeletedModal>,
  any
>((stores: IStores) => ({
  patientHeader: stores.cms1500.headerDetails,
  groupInfo: stores.cms1500.selectedEOCLegacy,
}))(MarClaimDeletedModal)

export default InjectedMarClaimDeletedModal
