import { inject } from 'mobx-react'
import * as React from 'react'
import { Route, RouteComponentProps, withRouter } from 'react-router-dom'
import { IOrganization, IUser } from '../../Definitions'
import { IStores } from '../../Stores'
import IRoute from '../IRoute'
import RedirectWithErrorMessage from '../RedirectWithErrorMessage'

interface IRoleRequiredRouteProps extends RouteComponentProps<{}> {
  currentAppOrganization?: IOrganization
  route: IRoute
  user?: IUser
}

const RoleRequiredRoute: React.SFC<IRoleRequiredRouteProps> = (props) => {
  const { currentAppOrganization, route, user } = props
  const allowedRoles = route.allowedRoles ? route.allowedRoles : []
  const allowedOrganizations = route.allowedOrganization ? route.allowedOrganization : []
  const userRoles = user ? user.roles : []
  const isInAllowedRole =
    allowedRoles.length === 0 ||
    allowedRoles.some((x: string) => userRoles.indexOf(x) > -1)
  const isInAllowedOrganization =
    allowedOrganizations.length === 0 ||
    allowedOrganizations.some(
      (o) => o === (currentAppOrganization ? currentAppOrganization.id : undefined)
    )
  let routeRender: React.ComponentClass | React.StatelessComponent
  if (isInAllowedRole && isInAllowedOrganization) {
    routeRender = route.component
  } else {
    routeRender = () => (
      <RedirectWithErrorMessage
        messageId="routeRequiresRole"
        message="Your account or organization is not permitted on that page."
        redirectTo="/patients"
      />
    )
  }
  return <Route path={route.path} component={routeRender} exact={route.exact} />
}

const InjectedRoleRequiredRoute = inject<
  IStores,
  IRoleRequiredRouteProps,
  Partial<IRoleRequiredRouteProps>,
  any
>((stores: IStores) => ({
  currentAppOrganization: stores.global.currentAppOrganization,
  user: stores.global.user,
}))(RoleRequiredRoute)

export default withRouter(InjectedRoleRequiredRoute)
