import { Edit, Visibility } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultCommunication, ICommunication } from '../../Definitions'
import {
  Client,
  CommunicationDto,
  ICommunicationDto,
  SwaggerResponse,
  TagDto,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'
import PatientsStore from '../PatientsStore/PatientsStore'
import ProvidersStore from '../ProvidersStore'

const ColumnSettingsDto = 'CommunicationDto'

export default class CommunicationsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public isEditModalOpen: boolean
  @observable
  public communications: ICommunication[] = []
  @observable
  public fullCommunications: ICommunication[] = []
  @observable
  public selectedCommunication?: ICommunication
  @observable
  public canLoadMore: boolean = false
  @observable
  public loadedPages: number = 0
  @observable
  public pageSize: number = 25
  public dataTableStore: DataTableStore<ICommunicationDto, ICommunication>
  public fullDataTableStore: DataTableStore<ICommunicationDto, ICommunication>
  @observable
  public selectedDateRange: string | undefined
  @observable
  public selectedStartDate: Date | null = null
  @observable
  public selectedEndDate: Date | null = null
  @observable
  public selectedDOSRange: string | undefined
  @observable
  public selectedStartDOS: Date | null = null
  @observable
  public selectedEndDOS: Date | null = null
  @observable
  public selectedType: string
  @observable
  public selectedTags: TagDto[] = []
  @observable
  public contentFilter: string

  constructor(
    private patientsStore: PatientsStore,
    private providerStore: ProvidersStore,
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<ICommunicationDto, ICommunication>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllCommunications(
          this.patientsStore.selectedPatient
            ? this.patientsStore.selectedPatient.id
            : undefined,
          this.providerStore.selectedProvider
            ? this.providerStore.selectedProvider.id
            : undefined,
          this.selectedStartDate ? this.selectedStartDate : undefined,
          this.selectedEndDate ? this.selectedEndDate : undefined,
          this.selectedStartDOS ? this.selectedStartDOS : undefined,
          this.selectedEndDOS ? this.selectedEndDOS : undefined,
          this.selectedType,
          this.selectedTags.map((x) => (x ? x.name : '')).toString(),
          filter,
          page,
          this.pageSize,
          orderBy,
          includeInactives
        ),
      (communication) => this.setupCommunications(communication)
    )

    this.fullDataTableStore = new DataTableStore<ICommunicationDto, ICommunication>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllCommunications(
          this.patientsStore.selectedPatient
            ? this.patientsStore.selectedPatient.id
            : undefined,
          this.providerStore.selectedProvider
            ? this.providerStore.selectedProvider.id
            : undefined,
          this.selectedStartDate ? this.selectedStartDate : undefined,
          this.selectedEndDate ? this.selectedEndDate : undefined,
          this.selectedStartDOS ? this.selectedStartDOS : undefined,
          this.selectedEndDOS ? this.selectedEndDOS : undefined,
          this.selectedType,
          this.selectedTags.map((x) => (x ? x.name : '')).toString(),
          filter,
          page,
          15,
          orderBy,
          includeInactives
        ),
      (communication) => this.setupCommunications(communication)
    )
  }

  @action.bound
  public setSelectedDateRange(dateRange: string | undefined) {
    this.selectedDateRange = dateRange
    const startDate = new Date(new Date().toDateString())
    const endDate = new Date(new Date().toDateString())
    switch (this.selectedDateRange) {
      case 'Yesterday':
        this.selectedStartDate = startDate
        this.selectedEndDate = endDate
        this.selectedStartDate.setDate(startDate.getDate() - 1)
        this.selectedEndDate.setDate(endDate.getDate() - 1)
        break
      case 'Last 7 Days':
        this.selectedStartDate = startDate
        this.selectedStartDate.setDate(startDate.getDate() - 7)
        this.selectedEndDate = endDate
        break
      case 'Last 30 Days':
        this.selectedStartDate = startDate
        this.selectedStartDate.setDate(startDate.getDate() - 30)
        this.selectedEndDate = endDate
        break
      case undefined:
        this.selectedStartDate = null
        this.selectedEndDate = null
        break
      case 'Today':
      case 'Custom':
      default:
        this.selectedStartDate = startDate
        this.selectedEndDate = endDate
        break
    }

    this.dataTableStore.loadData()
    this.fullDataTableStore.loadData()
  }

  @action.bound
  public setSelectedDOSRange(dosRange: string | undefined) {
    this.selectedDOSRange = dosRange
    const startDOS = new Date(new Date().toDateString())
    const endDOS = new Date(new Date().toDateString())
    switch (this.selectedDOSRange) {
      case 'Yesterday':
        this.selectedStartDOS = startDOS
        this.selectedEndDOS = endDOS
        this.selectedStartDOS.setDate(startDOS.getDate() - 1)
        this.selectedEndDOS.setDate(endDOS.getDate() - 1)
        break
      case 'Last 7 Days':
        this.selectedStartDOS = startDOS
        this.selectedStartDOS.setDate(startDOS.getDate() - 7)
        this.selectedEndDOS = endDOS
        break
      case 'Last 30 Days':
        this.selectedStartDOS = startDOS
        this.selectedStartDOS.setDate(startDOS.getDate() - 30)
        this.selectedEndDOS = endDOS
        break
      case undefined:
        this.selectedStartDOS = null
        this.selectedEndDOS = null
        break
      case 'Today':
      case 'Custom':
      default:
        this.selectedStartDOS = startDOS
        this.selectedEndDOS = endDOS
        break
    }

    this.dataTableStore.loadData()
    this.fullDataTableStore.loadData()
  }

  @action.bound
  public clearFilters() {
    this.selectedDateRange = undefined
    this.selectedEndDate = null
    this.selectedStartDate = null
    this.selectedDOSRange = undefined
    this.selectedEndDOS = null
    this.selectedStartDOS = null
    this.selectedTags = []
    this.selectedType = ''
    this.contentFilter = ''
  }

  @action.bound
  public setSelectedDate(date: Date | null, isStartDate: boolean) {
    const dateString = date ? new Date(date.toDateString()) : null
    if (isStartDate) {
      this.selectedStartDate = dateString
    } else {
      this.selectedEndDate = dateString
    }

    this.dataTableStore.loadData()
    this.fullDataTableStore.loadData()
  }

  @action.bound
  public setSelectedDOS(dateOfService: Date | null, isStartDOS: boolean) {
    const dosString = dateOfService ? new Date(dateOfService.toDateString()) : null
    if (isStartDOS) {
      this.selectedStartDOS = dosString
    } else {
      this.selectedEndDOS = dosString
    }

    this.dataTableStore.loadData()
    this.fullDataTableStore.loadData()
  }

  @action.bound
  public updateSearchFilter(value: string) {
    this.contentFilter = value
    this.dataTableStore!.updateTableOptions({
      filter: this.contentFilter,
      page: 1,
    })
    this.fullDataTableStore!.updateTableOptions({
      filter: this.contentFilter,
      page: 1,
    })
  }

  @action.bound
  public setSelectedType(type: string) {
    this.selectedType = type

    this.dataTableStore.loadData()
    this.fullDataTableStore.loadData()
  }

  @action.bound
  public setSelectedTags(tags: TagDto[]) {
    this.selectedTags = tags

    this.dataTableStore.loadData()
    this.fullDataTableStore.loadData()
  }

  @action.bound
  public setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
  }

  @action.bound
  public closeEditDialog() {
    this.isEditModalOpen = false
    this.selectedCommunication = undefined
  }

  @action.bound
  public disableCommunication(communication: ICommunication) {
    this.isLoading = true
    return this.client
      .archiveCommunication(communication.id)
      .then(() => {
        this.dataTableStore.loadData()
        this.fullDataTableStore.loadData()
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndCommunications(): Promise<void> {
    if (!this.globalStore.user) {
      return Promise.resolve()
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          const filteredData = data.result.filter((x) => x.fieldName !== 'displayTags') // Filter out the tags from the minimal table

          this.dataTableStore.setColumns(filteredData)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getColumnSettingsAndFullCommunications(): Promise<void> {
    if (!this.globalStore.user) {
      return Promise.resolve()
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.fullDataTableStore.setColumns(data.result)
          const sortedColumn = _.find(
            this.fullDataTableStore.columns,
            (col) => !!col.sort
          )
          if (sortedColumn) {
            this.fullDataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.fullDataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public loadAdditionalPage() {
    this.isLoading = true
    this.loadedPages += 1
    return this.client
      .getAllCommunications(
        this.patientsStore.selectedPatient
          ? this.patientsStore.selectedPatient.id
          : undefined,
        this.providerStore.selectedProvider
          ? this.providerStore.selectedProvider.id
          : undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        this.loadedPages,
        this.pageSize
      )
      .then((resp: SwaggerResponse<ICommunicationDto[]>) => {
        runInAction(() => {
          const newCommunications = resp.result.map((c) => this.addDefaultFields(c))
          this.communications = this.communications.concat(newCommunications)
          const header = JSON.parse(resp.headers['x-pagination'] || '{"TotalPages": 0}')
          this.determineLoadable(header.TotalPages)
          this.isLoading = false
        })
      })
  }

  @action.bound
  public determineLoadable(totalPages: number) {
    if (totalPages > this.loadedPages) {
      this.canLoadMore = true
    } else {
      this.canLoadMore = false
    }
  }

  @action.bound
  public emptyLoadedCommunications() {
    this.communications = []
    this.loadedPages = 0
  }

  @action.bound
  public emptyCommunicationsTable() {
    this.dataTableStore.clearData()
  }

  @action.bound
  public getAllCommunications() {
    this.isLoading = true
    return this.client
      .getAllCommunications(
        this.patientsStore.selectedPatient
          ? this.patientsStore.selectedPatient.id
          : undefined,
        this.providerStore.selectedProvider
          ? this.providerStore.selectedProvider.id
          : undefined
      )
      .then((resp: SwaggerResponse<ICommunicationDto[]>) =>
        runInAction(() => {
          this.communications = this.setupCommunications(resp.result)
        })
      )
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (this.isEditModalOpen) {
      this.isEditModalOpen = false
    }
    if (shouldClearFilter) {
      this.fullDataTableStore.clearFilter()
    }
    this.fullDataTableStore.loadData()
    this.dataTableStore.loadData()
  }

  @action.bound
  public saveCommunication(communication: ICommunication) {
    this.isLoading = true
    let promise
    const communicationDto = new CommunicationDto(communication)
    if (communication.isNew) {
      communicationDto.patientId = this.patientsStore.selectedPatient
        ? this.patientsStore.selectedPatient.id
        : undefined
      communicationDto.providerId = this.providerStore.selectedProvider
        ? this.providerStore.selectedProvider.id
        : undefined
      promise = this.client.createCommunication(communicationDto)
    } else {
      promise = this.client.updateCommunication(communication.id, communicationDto)
    }
    return promise
      .then(() => {
        this.resetUIState(communication.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public openDialog() {
    this.isModalOpen = true
  }

  @action.bound
  public openDialogWithCommunication(communication: ICommunication) {
    this.isEditModalOpen = true
    this.selectedCommunication = communication
  }

  @action.bound
  public recoverCommunication(communication: ICommunication) {
    this.isLoading = true
    return this.client
      .reactivateCommunication(communication.id)
      .then(() => {
        this.dataTableStore.loadData()
        this.fullDataTableStore.loadData()
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private setupCommunications = (communications: ICommunicationDto[]) => {
    return communications.map((x) =>
      this.setupCommunicationMenuItems(this.addDefaultFields(x))
    )
  }

  private addDefaultFields = (communication: ICommunicationDto): ICommunication => {
    return { ...DefaultCommunication(), ...communication }
  }

  private setupCommunicationMenuItems = (communication: ICommunication) => {
    communication.menuItems = []
    if (communication.communicationTags.find((x) => x.isLocked === true) === undefined) {
      communication.menuItems.push({
        icon: Edit,
        name: 'Edit',
        onClick: () => this.openDialogWithCommunication(communication),
      })
    } else {
      communication.menuItems.push({
        icon: Visibility,
        name: 'View',
        onClick: () => this.openDialogWithCommunication(communication),
      })
    }
    return communication
  }
}
