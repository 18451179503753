import {
  Button,
  createStyles,
  Grid,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { Info } from '@material-ui/icons'
import { Form, Formik, FormikActions, getIn } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Prompt } from 'react-router-dom'
import AddressField from '../common/AddressField/AddressField'
import CardWithTitle from '../common/CardWithTitle/CardWithTitle'
import FullWidthField from '../common/FullWidthField'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import SelectField from '../common/SelectField/SelectField'
import {
  noWhitespaceEmail,
  noWhitespacePhone,
  noWhitespaceString,
} from '../common/YupExtensions'
import { IOrganization, IUser } from '../Definitions'
import { IStores } from '../Stores'
import { OrganizationId } from '../utils/OrganizationIds'
import * as Yup from 'yup'
import {
  createICompanyFormValues,
  ICompanyFormValues,
  toICompany,
} from './CompanyFormValues'
import { Helmet } from 'react-helmet'

const styles = ({ spacing }: Theme) =>
  createStyles({
    cardContent: {
      paddingTop: '20px !important',
    },
    contact: {
      paddingBottom: '20px !important',
      paddingTop: '7px !important',
    },
    topOfPage: {
      marginTop: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  })

const zipRegex: RegExp = new RegExp('(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)')

const ComponentSchema = Yup.object().shape({
  billingInquiries: Yup.object({
    city: noWhitespaceString().required().label('City'),
    email: noWhitespaceEmail('Email is required', true),
    faxNumber: noWhitespacePhone().required().label('Fax Number'),
    line1: noWhitespaceString().required().label('Street Address'),
    line2: Yup.string(),
    phoneNumber: noWhitespacePhone().required().label('Phone Number'),
    state: noWhitespaceString().required().label('State'),
    website: Yup.string(),
    zip: noWhitespaceString()
      .required()
      .label('Zip')
      .matches(zipRegex, 'Invalid Zip Code'),
  }),
  generalInquiries: Yup.object({
    city: noWhitespaceString().required().label('City'),
    email: noWhitespaceEmail().email('Email is invalid').required('Email is required'),
    faxNumber: noWhitespacePhone().required().label('Fax Number'),
    line1: noWhitespaceString().required().label('Street Address'),
    line2: Yup.string(),
    phoneNumber: noWhitespacePhone().required().label('Phone Number'),
    state: noWhitespaceString().required().label('State'),
    website: noWhitespaceString().required().label('Website'),
    zip: noWhitespaceString()
      .required()
      .label('Zip')
      .matches(zipRegex, 'Invalid Zip Code'),
  }),
  initials: noWhitespaceString('Initials are required', true).max(
    3,
    'Must be fewer or equal to 3 characters'
  ),
  name: noWhitespaceString('Name is required', true),
  navigationLabel: noWhitespaceString('Navigation Label is required', true).max(
    15,
    'Must be fewer or equal to 15 characters'
  ),
})

interface IEditCompanyProps extends WithStyles<typeof styles> {
  currentAppOrganization?: IOrganization
  getCurrentUserOrganization?: () => Promise<void>
  getAllUsers?: (pageSize?: number) => Promise<void>
  getOrganizationsMenu?: () => Promise<void>
  isLoading?: boolean
  organization?: IOrganization
  rerouteToPath?: (path: string) => void
  saveOrganization?: (organization: IOrganization) => Promise<void>
  users?: IUser[]
}

interface IEditCompanyState {
  reload: boolean
}
class EditCompany extends React.Component<IEditCompanyProps, IEditCompanyState> {
  constructor(props: IEditCompanyProps) {
    super(props)
    this.state = {
      reload: false,
    }
  }
  public async componentDidMount() {
    this.props.getAllUsers!(5000)
    await this.props.getCurrentUserOrganization!()
  }

  public async componentDidUpdate(prevProps: IEditCompanyProps) {
    if (
      this.props.organization &&
      prevProps.organization &&
      this.props.organization.id !== prevProps.organization.id
    ) {
      this.props.getAllUsers!(5000)
    }
    await this.props.getCurrentUserOrganization!()
  }

  private cancel = () => {
    this.props.rerouteToPath!('/admin')
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload }, () => {
      submitForm()
    })
  }

  public save = async (
    values: ICompanyFormValues,
    formikBag: FormikActions<ICompanyFormValues>
  ) => {
    const organization = await toICompany(values, this.props.organization)

    this.props.saveOrganization!(organization).then(() => {
      this.props.getOrganizationsMenu!().then(() => {
        formikBag.setSubmitting(false)
        if (this.state.reload) {
          this.props.rerouteToPath!(`/admin/companyInfo`)
        } else {
          this.props.rerouteToPath!(`/admin`)
        }
      })
    })
  }

  public hasValue = (field: string, values: ICompanyFormValues) => {
    const value = getIn(values, field)
    return !!value
  }

  public getUserName(item: IUser) {
    return item ? item.name : ''
  }

  public getUserValue(item: IUser) {
    return item ? item.id : ''
  }

  public renderContacts = (values: ICompanyFormValues) => {
    const { users = [] } = this.props
    return (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        spacing={2}
        justifyContent="flex-end"
      >
        <Grid item container direction="row" spacing={2} alignItems="center">
          <Grid item xs={6}>
            <SelectField
              inputId="patientDemographicsUserId"
              getName={this.getUserName}
              getValue={this.getUserValue}
              items={users}
              label="Patient Demographics"
              name="patientDemographicsUserId"
              fullWidth
              shrinkLabel={this.hasValue('patientDemographicsUserId', values)}
              outlined={true}
            />
          </Grid>
          <Grid item xs={6}>
            <i>
              {'Example: All inquiries should be directed to: {Name} {Email Address}'}
            </i>
          </Grid>
        </Grid>
        {this.props.currentAppOrganization &&
        this.props.currentAppOrganization.id === OrganizationId.Surgical ? (
          <Grid item container direction="row" spacing={2} alignItems="center">
            <Grid item xs={6}>
              <SelectField
                inputId="globalInvoiceUserId"
                getName={this.getUserName}
                getValue={this.getUserValue}
                items={users}
                label="Global Invoice"
                name="globalInvoiceUserId"
                fullWidth
                shrinkLabel={this.hasValue('globalInvoiceUserId', values)}
                outlined={true}
              />
            </Grid>
            <Grid item xs={6}>
              <i>{'Example: Direct all inquiries to: {Name} {Toll-Free Phone Number}'}</i>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {this.props.currentAppOrganization &&
        this.props.currentAppOrganization.id === OrganizationId.Diagnostic ? (
          <>
            <Grid item container direction="row" spacing={2} alignItems="center">
              <Grid item xs={6}>
                <SelectField
                  inputId="distributeTestResultsCCId"
                  getName={this.getUserName}
                  getValue={this.getUserValue}
                  items={users}
                  label="Distribute Test Results Email (CC)"
                  name="distributeTestResultsCCId"
                  fullWidth
                  shrinkLabel={this.hasValue('distributeTestResultsCCId', values)}
                  outlined={true}
                />
              </Grid>
              <Grid item xs={6}>
                <i>{'Example: CC {Name} <{Email Address}>'}</i>
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2} alignItems="center">
              <Grid item xs={6}>
                <SelectField
                  inputId="distributeTestResultsAdditionalInfoId"
                  getName={this.getUserName}
                  getValue={this.getUserValue}
                  items={users}
                  label="Distribute Test Results Email (For Additional Information)"
                  name="distributeTestResultsAdditionalInfoId"
                  fullWidth
                  shrinkLabel={this.hasValue(
                    'distributeTestResultsAdditionalInfoId',
                    values
                  )}
                  outlined={true}
                />
              </Grid>
              <Grid item xs={6}>
                <i>
                  {
                    'Example: For additional information, contact {Name} at {Email Address} or {Direct Phone Number}'
                  }
                </i>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
    )
  }

  public render() {
    const { organization } = this.props
    const initialValues = createICompanyFormValues(organization)

    return (
      <>
        <Helmet>
          <title>Company Information</title>
        </Helmet>
        {organization && (
          <Formik
            initialValues={initialValues}
            onSubmit={this.save}
            validationSchema={ComponentSchema}
            enableReinitialize={true}
          >
            {({ isValid, values, submitForm, dirty, submitCount }) => (
              <Form>
                <Prompt
                  when={dirty && submitCount === 0}
                  message="Are you sure you want to leave the page? There are unsaved changes."
                />
                <ResponsiveAppBar title={'Company Information'} pageIcon={<Info />}>
                  <Grid container direction="row" spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button size="small" variant="contained" onClick={this.cancel}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        disabled={!isValid}
                        onClick={this.handleSave(submitForm, true)}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        disabled={!isValid}
                        onClick={this.handleSave(submitForm, false)}
                      >
                        Save and Close
                      </Button>
                    </Grid>
                  </Grid>
                </ResponsiveAppBar>

                <Grid
                  container
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  className={this.props.classes.topOfPage}
                >
                  <Grid item>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <CardWithTitle title="Company" icon={Info}>
                          <Grid item xs={6} className={this.props.classes.cardContent}>
                            <FullWidthField
                              InputLabelProps={{
                                shrink: this.hasValue('name', values),
                              }}
                              name="name"
                              label="Company Name"
                              variant="outlined"
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3} className={this.props.classes.cardContent}>
                            <FullWidthField
                              InputLabelProps={{
                                shrink: this.hasValue('navigationLabel', values),
                              }}
                              name="navigationLabel"
                              label="Navigation Label"
                              variant="outlined"
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3} className={this.props.classes.cardContent}>
                            <FullWidthField
                              InputLabelProps={{
                                shrink: this.hasValue('initials', values),
                              }}
                              name="initials"
                              label="Initials"
                              variant="outlined"
                              required={true}
                            />
                          </Grid>
                        </CardWithTitle>
                      </Grid>
                      <Grid item xs={12}>
                        <CardWithTitle title="General Inquiries" icon={Info}>
                          <Grid item xs={12}>
                            <AddressField
                              name={'generalInquiries'}
                              label=""
                              showPhone={true}
                              showFax={true}
                              showWebsite={true}
                              showEmail={true}
                              outlined={true}
                              required={true}
                              requireFax={true}
                              requirePhone={true}
                              setStyle={{
                                paddingBottom: '20px',
                                paddingTop: '7px',
                              }}
                            />
                          </Grid>
                        </CardWithTitle>
                      </Grid>
                      <Grid item xs={12}>
                        <CardWithTitle title="Billing Inquiries" icon={Info}>
                          <Grid item xs={12}>
                            <AddressField
                              name={'billingInquiries'}
                              label=""
                              showPhone={true}
                              showFax={true}
                              showWebsite={true}
                              showEmail={true}
                              outlined={true}
                              required={true}
                              requireFax={true}
                              requirePhone={true}
                              requireWebsite={false}
                              setStyle={{
                                paddingBottom: '20px',
                                paddingTop: '7px',
                              }}
                            />
                          </Grid>
                        </CardWithTitle>
                      </Grid>
                      <Grid item xs={12}>
                        <CardWithTitle title="Document Contacts" icon={Info}>
                          <Grid item xs={12}>
                            {this.renderContacts(values)}
                          </Grid>
                        </CardWithTitle>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </>
    )
  }
}

const InjectedEditCompany = inject<
  IStores,
  IEditCompanyProps,
  Partial<IEditCompanyProps>,
  any
>((stores: IStores) => ({
  organization: stores.organizations.selectedOrganization,
  getCurrentUserOrganization: stores.organizations.getCurrentUserOrganization,
  getAllUsers: stores.users.getAllUsers,
  getOrganizationsMenu: stores.organizations.getOrganizationsMenu,
  isLoading: stores.organizations.isLoading,
  rerouteToPath: stores.global.rerouteToPath,
  saveOrganization: stores.organizations.saveOrganization,
  users: stores.users.users,
}))(EditCompany)

export default withStyles(styles)(InjectedEditCompany)
