import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IStores } from '../Stores'
import { Check, CreditCard, Delete, Drafts, UndoRounded } from '@material-ui/icons'
import {
  Typography,
  TableCell,
  Table,
  TableRow,
  Button,
  CircularProgress,
  FormControl,
  TableBody,
  TableHead,
  TextField,
  Grid,
  Tooltip,
  createStyles,
  WithStyles,
  withStyles,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { formatCurrency, formatDate } from 'src/utils/Formatter'
import { pagedList } from 'src/viewModels/pagedList'
import ReactPaginate from 'react-paginate'
import { ReceivedCheckSearchRequest } from 'src/viewModels/ReceivedCheckSearchRequest'
import { ReceivedCheckSummaryResult } from 'src/viewModels/ReceivedCheckSummaryResult'
import {
  GetReceivedChecksTotalsSummary,
  searchReceivedChecks,
} from 'src/services/SearchService'
import { ReceivedCheckTotalSummary } from 'src/viewModels/ReceivedCheckTotalSummary'
import UnApplyCheckModal from './UnApplyCheckModal'
import { ArchiveOutline } from 'mdi-material-ui'
import {
  GetUnappliedReceivedCheckHistory,
  getReceivedCheckAppliedFundsDetail,
} from 'src/services/CheckService'
import { ReceivedCheckDetailSummary } from 'src/viewModels/ReceivedCheckDetailSummary'
import CheckDetailsModal from './CheckDetailsModal'
import MarkAsDeletedModal from './MarkAsDeletedModal'

const CurrencyFormat: any = require('react-currency-format')
const styles = () =>
  createStyles({
    strikeOut: {
      textDecoration: 'line-through red',
    },
  })

interface IReceivedCheckProps extends WithStyles<typeof styles> {
  setCheckRequest?: (request: ReceivedCheckSearchRequest) => void
  storedRequest?: ReceivedCheckSearchRequest
}

class ReceivedChecks extends React.Component<IReceivedCheckProps> {
  public state = {
    searchPayer: this.props.storedRequest?.payer ?? '',
    searchCheckNumber: this.props.storedRequest?.checkNumber ?? '',
    searchCheckDateStart: this.props.storedRequest?.checkDateStart ?? undefined,
    searchCheckDateEnd: this.props.storedRequest?.checkDateEnd ?? undefined,
    searchShowRefundOnly: this.props.storedRequest?.onlyShowRefunds ?? false,
    gridData: new pagedList().items as ReceivedCheckSummaryResult[],
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 20,
    gridLoading: false,
    receivedChecksTotals: new ReceivedCheckTotalSummary(),
    selectedCheck: undefined as unknown as ReceivedCheckSummaryResult,
    unapplyCheckModalOpen: false,
    checkDetailsModalOpen: false,
    selectedCheckDetails: undefined as unknown as ReceivedCheckDetailSummary,
    checkDetailModalTitle: '',
    checkDetailIcon: <CreditCard />,
    isCheckDetailModalLoading: false,
    isUnappliedHistory: false,
    markAsDeletedModalOpen: false,
  }

  public componentDidMount() {}

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getReceivedChecksData(undefined, true))
  }

  private isSearchGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isSearchGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private getAppliedCheckDetail = (checkId: string) => {
    this.setState({ isCheckDetailModalLoading: true })

    getReceivedCheckAppliedFundsDetail(checkId)
      .then((response) => {
        this.setState({ selectedCheckDetails: response })
      })
      .finally(() => {
        this.setState({
          checkDetailsModalOpen: true,
          checkDetailIcon: <CreditCard />,
          isCheckDetailModalLoading: false,
          checkDetailModalTitle: 'APPLIED DETAILS',
          isUnappliedHistory: false,
        })
      })
  }

  private getUnappliedCheckDetail = (checkId: string) => {
    this.setState({ isCheckDetailModalLoading: true })

    GetUnappliedReceivedCheckHistory(checkId)
      .then((response) => {
        this.setState({ selectedCheckDetails: response })
      })
      .finally(() => {
        this.setState({
          checkDetailsModalOpen: true,
          checkDetailIcon: <ArchiveOutline />,
          isCheckDetailModalLoading: false,
          checkDetailModalTitle: 'UNAPPLIED DETAILS',
          isUnappliedHistory: true,
        })
      })
  }

  private getReceivedChecksData = async (
    setSavedSearch: boolean = false,
    forPageChange: boolean = false
  ) => {
    this.setState({ gridLoading: true })
    const { page, pageSize } = this.state

    const data: ReceivedCheckSearchRequest = {
      checkDateStart: this.state.searchCheckDateStart,
      checkDateEnd: this.state.searchCheckDateEnd,
      checkNumber: this.state.searchCheckNumber,
      payer: this.state.searchPayer,
      onlyShowRefunds: this.state.searchShowRefundOnly,
      page: page,
      pageSize: pageSize,
    }
    await searchReceivedChecks(data).then(async (response: any) => {
      if (forPageChange) {
        this.setState({
          gridData: response.items,
          pageCount: response.totalPages,
          totalItems: response.totalItems,
          gridLoading: false,
        })
      } else {
        await GetReceivedChecksTotalsSummary(data).then((totals: any) => {
          this.setState({
            gridData: response.items,
            pageCount: response.totalPages,
            totalItems: response.totalItems,
            gridLoading: false,
            receivedChecksTotals: totals,
          })

          if (response.totalItems > 0) {
            this.setPaginationOffsetData()
          }

          if (setSavedSearch) {
            this.props.setCheckRequest!(data)
          }
        })
      }
    })
  }

  private search = (event: any) => {
    this.setState({ page: 1 }, () => {
      this.getReceivedChecksData(true)
    })
    event.preventDefault()
  }

  private clearSearch = () => {
    var select_box = document.getElementById('claimStatus') as HTMLSelectElement
    if (select_box != undefined) {
      select_box.selectedIndex = 0
    }

    var select_box2 = document.getElementById('billoutStatus') as HTMLSelectElement
    if (select_box2 != undefined) {
      select_box2.selectedIndex = 0
    }
    var start = document.getElementById('startDate') as HTMLInputElement
    if (start != undefined) {
      start.value = ''
    }
    var end = document.getElementById('endDate') as HTMLInputElement
    if (end != undefined) {
      end.value = ''
    }
    this.setState({
      searchCheckDateStart: undefined,
      searchCheckDateEnd: undefined,
      searchPayer: '',
      searchCheckNumber: '',
      gridData: undefined,
      pageCount: 0,
      totalItems: 0,
      gridLoading: false,
      searchShowRefundOnly: false,
    })
  }

  private handleStartDateChange = (event: any) => {
    this.setState({ searchCheckDateStart: event.target.value })
  }

  private handleEndDateChange = (event: any) => {
    this.setState({ searchCheckDateEnd: event.target.value })
  }

  private handlePayerChange = (event: any) => {
    this.setState({ searchPayer: event.target.value })
  }

  private handleCheckNumberChange = (event: any) => {
    this.setState({ searchCheckNumber: event.target.value })
  }

  private getLineItemStyle = (markedDeleted: boolean) => {
    if (markedDeleted) {
      return this.props.classes.strikeOut
    } else {
      return undefined
    }
  }

  private handleShowRefundOnly = (_event: React.ChangeEvent, value: boolean) => {
    this.setState({ searchShowRefundOnly: value })
  }

  private mapSearchGridData = () => {
    if (this.isSearchGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={8} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          <TableCell
            style={{
              whiteSpace: 'nowrap',
              paddingRight: 0,
              paddingLeft: '20px',
            }}
          >
            {record.hasHistoricalUnappliedFunds ? (
              <>
                <Tooltip title="Click to view unapplied funds history">
                  <span>
                    <ArchiveOutline
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        this.getUnappliedCheckDetail(record.checkId)
                      }}
                    />
                  </span>
                </Tooltip>
                &nbsp;&nbsp;
              </>
            ) : (
              <>
                <ArchiveOutline style={{ visibility: 'hidden' }} />
                &nbsp;&nbsp;
              </>
            )}
            {record.hasAppliedFunds ? (
              <>
                <Tooltip title="Click to view applied funds detail">
                  <CreditCard
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      this.getAppliedCheckDetail(record.checkId)
                    }}
                  />
                </Tooltip>
                &nbsp;&nbsp;
              </>
            ) : (
              <>
                <CreditCard style={{ visibility: 'hidden' }} />
                &nbsp;&nbsp;
              </>
            )}
          </TableCell>
          <TableCell className={this.getLineItemStyle(record.markedDeleted!)}>
            {record.payer}
          </TableCell>
          <TableCell
            align="right"
            className={this.getLineItemStyle(record.markedDeleted!)}
          >
            {record.checkDate ? formatDate(record.checkDate.toString()) : ''}
          </TableCell>
          <TableCell className={this.getLineItemStyle(record.markedDeleted!)}>
            {record.checkNumber}
          </TableCell>
          <TableCell
            align="right"
            className={this.getLineItemStyle(record.markedDeleted!)}
          >
            {formatCurrency(record.checkAmount.toString())}
          </TableCell>
          <TableCell
            align="right"
            className={this.getLineItemStyle(record.markedDeleted!)}
          >
            {formatCurrency(record.amountApplied.toString())}
          </TableCell>
          <TableCell
            align="right"
            className={this.getLineItemStyle(record.markedDeleted!)}
          >
            {formatCurrency(record.remainingFunds.toString())}
          </TableCell>
          <TableCell className={this.getLineItemStyle(record.markedDeleted!)}>
            {record.refund ? <Check color="error" /> : <></>}
          </TableCell>
          <TableCell
            align="right"
            className={this.getLineItemStyle(record.markedDeleted!)}
          >
            {record.paidClaims}
          </TableCell>
          <TableCell
            align="right"
            className={this.getLineItemStyle(record.markedDeleted!)}
          >
            {record.paidInvoices}
          </TableCell>
          <TableCell>
            {record.hasAppliedFunds && (
              <Tooltip title="Unapply Check">
                <UndoRounded
                  color={'error'}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.setState({
                      selectedCheck: record,
                      unapplyCheckModalOpen: true,
                    })
                  }}
                />
              </Tooltip>
            )}
            &nbsp;&nbsp;
            {!record.hasAppliedFunds && !record.markedDeleted && (
              <Tooltip title="Mark check as deleted">
                <Delete
                  style={{ cursor: 'pointer', color: 'red' }}
                  onClick={() => {
                    this.setState({
                      selectedCheck: record,
                      markAsDeletedModalOpen: true,
                    })
                  }}
                />
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
      ))
    }
  }

  public render() {
    const { unapplyCheckModalOpen, checkDetailsModalOpen, markAsDeletedModalOpen } =
      this.state
    return (
      <>
        <Helmet>
          <title>Received Checks</title>
        </Helmet>
        <ResponsiveAppBar title="Received Checks" pageIcon={<Drafts />} />
        {this.state.selectedCheck && this.state.unapplyCheckModalOpen && (
          <UnApplyCheckModal
            close={(reload: boolean) => {
              if (reload) {
                this.getReceivedChecksData(true)
              }
              this.setState({ unapplyCheckModalOpen: false })
            }}
            open={unapplyCheckModalOpen}
            check={this.state.selectedCheck}
          />
        )}
        {this.state.checkDetailsModalOpen && this.state.selectedCheckDetails && (
          <CheckDetailsModal
            close={(reload: boolean) => {
              if (reload) {
                this.getReceivedChecksData(true)
              }
              this.setState({ checkDetailsModalOpen: false })
            }}
            open={checkDetailsModalOpen}
            checkDetail={this.state.selectedCheckDetails}
            title={this.state.checkDetailModalTitle}
            dialogIcon={this.state.checkDetailIcon}
            isUnappliedHistory={this.state.isUnappliedHistory}
          />
        )}
        {this.state.selectedCheck && this.state.markAsDeletedModalOpen && (
          <MarkAsDeletedModal
            close={(reload: boolean) => {
              if (reload) {
                this.getReceivedChecksData(true)
              }
              this.setState({ markAsDeletedModalOpen: false })
            }}
            open={markAsDeletedModalOpen}
            check={this.state.selectedCheck}
          />
        )}
        {this.state.gridLoading ||
          (this.state.isCheckDetailModalLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '2',
              }}
            >
              <CircularProgress size={100} />
            </div>
          ))}
        <form onSubmit={() => {}}>
          <Grid container style={{ padding: '10px' }} xs={12}>
            <Typography
              color="primary"
              component="h6"
              variant="h6"
              gutterBottom
              style={{ paddingBottom: '10px' }}
            >
              SEARCH CRITERIA
            </Typography>
            <Grid container direction="row" spacing={2} style={{ width: '100%' }}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    value={this.state.searchPayer}
                    onChange={this.handlePayerChange}
                    variant="outlined"
                    label="Payer"
                    name="searchPayer"
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <TextField
                    value={this.state.searchCheckNumber}
                    onChange={this.handleCheckNumberChange}
                    label="Check Number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ fullWidth: true }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item container style={{ width: '356px' }} direction="row">
                <Grid item>
                  <TextField
                    inputProps={{ id: 'startDate', style: { padding: '8px' } }}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.handleStartDateChange}
                    type="date"
                    name="startDate"
                    variant="outlined"
                    defaultValue={this.state.searchCheckDateStart}
                    label="Check Start Date"
                    fullWidth
                  />
                </Grid>
                <Grid item style={{ margin: '0px 10px' }}>
                  <strong> - </strong>
                </Grid>
                <Grid item>
                  <TextField
                    inputProps={{ id: 'endDate', style: { padding: '8px' } }}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.handleEndDateChange}
                    type="date"
                    name="endDate"
                    variant="outlined"
                    defaultValue={this.state.searchCheckDateEnd}
                    label="Check End Date"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={1} style={{ paddingRight: '170px' }}>
                <FormControl>
                  <FormControlLabel
                    label="Only Show Refunds"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={this.state.searchShowRefundOnly}
                        onChange={this.handleShowRefundOnly}
                        inputProps={{
                          id: 'show-refund-filter',
                        }}
                        value="searchShowRefundOnly"
                        name="searchShowRefundOnly"
                      />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={2}
                style={{
                  marginLeft: '10px',
                  marginTop: '10px',
                  paddingTop: '10px',
                  width: '100%',
                }}
              >
                <Button
                  type="submit"
                  onClick={this.search}
                  color="primary"
                  size="medium"
                  variant="contained"
                  style={{ marginRight: '10px' }}
                >
                  Search
                </Button>
                <Button
                  color="secondary"
                  onClick={this.clearSearch}
                  size="medium"
                  variant="contained"
                >
                  Clear Search
                </Button>
              </Grid>
            </Grid>
            <Grid item container direction="row" style={{ paddingTop: '20px' }}>
              <Grid item xs={12}>
                <Typography noWrap variant="h5">
                  Check Count:&nbsp;
                  <strong>
                    {this.isSearchGridDataEmpty()
                      ? 'N/A'
                      : this.state.receivedChecksTotals.checkCount}
                  </strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography noWrap variant="h5">
                  Total Funds:&nbsp;
                  <strong>
                    {this.isSearchGridDataEmpty() ? (
                      'N/A'
                    ) : (
                      <CurrencyFormat
                        value={this.state.receivedChecksTotals.totalCheckAmount}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    )}
                  </strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography noWrap variant="h5">
                  Total Applied Funds:&nbsp;
                  <strong>
                    {this.isSearchGridDataEmpty() ? (
                      'N/A'
                    ) : (
                      <CurrencyFormat
                        value={this.state.receivedChecksTotals.totalCheckAppliedFunds}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    )}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="flex-start"
          wrap="wrap"
        >
          <Grid item xs={12}>
            <Table aria-label="simple table" style={{ padding: '20px' }}>
              <TableHead>
                <TableRow className="gridPadding">
                  <TableCell style={{ paddingLeft: '20px' }}></TableCell>
                  <TableCell>Payer</TableCell>
                  <TableCell align="right">Check Date</TableCell>
                  <TableCell>Check Number</TableCell>
                  <TableCell align="right">Check Amount</TableCell>
                  <TableCell align="right">Amount Applied</TableCell>
                  <TableCell align="right">Remaining Funds</TableCell>
                  <TableCell>Refund</TableCell>
                  <TableCell align="right"># of Claims</TableCell>
                  <TableCell align="right"># of Invoices</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '10%' }}>{this.mapSearchGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                forcePage={this.state.page - 1}
                pageCount={this.state.pageCount}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}

const InjectedReceivedChecks = inject<
  IStores,
  IReceivedCheckProps,
  Partial<IReceivedCheckProps>,
  any
>((stores: IStores) => ({
  setCheckRequest: stores.global.setCheckRequest,
  storedRequest: stores.global.checkRequest,
}))(ReceivedChecks)

export default withStyles(styles)(InjectedReceivedChecks)
