import {
  AppBar,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  Select,
  OutlinedInput,
  Theme,
  withStyles,
  WithStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank, RecordVoiceOver } from '@material-ui/icons'
import { Field, Form, Formik, FormikActions, getIn } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import _ from 'lodash'
import { inject } from 'mobx-react'
import moment from 'moment'
import * as React from 'react'
import { ChangeEvent } from 'react'
import { Helmet } from 'react-helmet'
import { Prompt, RouteComponentProps } from 'react-router-dom'
import { ReferralContactType } from 'src/models/enums/ReferralContactType.enum'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import { ReferralContactDTO } from 'src/viewModels/ReferralContactDTO'
import * as Yup from 'yup'
import PatientActionBar from '../../common/ActionBar/PatientActionBar'
import CardWithTitle from '../../common/CardWithTitle/CardWithTitle'
import FullWidthField from '../../common/FullWidthField'
import ResponsiveAppBar from '../../common/ResponsiveAppBar'
import TypeAheadField from '../../common/TypeAheadField/TypeAheadField'
import {
  DefaultReferral,
  IContact,
  IEmployer,
  IPatient,
  IReferral,
} from '../../Definitions'
import { IStores } from '../../Stores'
import {
  createIReferralFormValues,
  IReferralFormValues,
  toIReferral,
} from './ReferralFormValues'
import States, { IState } from 'src/utils/States'

const styles = ({ spacing }: Theme) =>
  createStyles({
    pageHeader: {
      fontSize: '40px',
      fontWeight: 300,
      lineHeight: '40px',
      paddingLeft: '20px',
      paddingTop: '20px',
    },
    pageHeaderIcon: {
      paddingRight: '10px',
    },
    paper: {
      marginTop: 4,
      paddingTop: spacing(2),
    },
    topOfPage: {
      marginTop: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  })

function parseDateString(_value: Date, originalValue: string) {
  return new Date(moment(originalValue, 'YYYY-MM-DD').toISOString())
}

const ReferralSchema = Yup.object().shape({
  claimNumber: Yup.string(),
  doi: Yup.date()
    .transform(parseDateString)
    .min(new Date('1-1-1900'), 'Please enter a complete date'),
  health: Yup.boolean(),
  initials: Yup.string(),
  occupation: Yup.string(),
  referralCaller: Yup.string(),
  referralDate: Yup.date()
    .transform(parseDateString)
    .min(new Date('1-1-1900'), 'Please enter a complete date')
    .required('Referral date is required'),
  referralIssue: Yup.boolean(),
  referralTime: Yup.string(),
})

interface IEditReferralParams {
  patientId: string
  referralId: string
}

interface IEditReferralProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IEditReferralParams> {
  employerContacts?: IContact[]
  getEmployerContacts?: (id: string, filter: string) => Promise<void>
  getPatientById?: (id: string) => Promise<void>
  getReferralById?: (id: string) => Promise<void>
  getEmployers?: (filter: string) => Promise<void>
  employers?: IEmployer[]
  isLoading?: boolean
  isModalOpen?: boolean
  openDialogWithContact?: (contact: IContact) => void
  referral?: IReferral
  referralEditFinished?: () => void
  rerouteToPath?: (path: string) => void
  saveReferral?: (referral: IReferral) => Promise<void>
  setSelectedReferral?: (referral: IReferral | undefined) => void
  insuranceCompanyOptions: DropdownOption[]
  getInsuranceCompanyOptions: () => Promise<void>
  filterInsuranceCompanyOptions: (filter: string) => void
  getNCMCompanyOptions: () => Promise<void>
  filterNCMCompanyOptions: (filter: string) => void
  ncmCompanyOptions: DropdownOption[]
  getInsuranceCompanyContactOptions: (companyId: string) => Promise<void>
  getNCMContactOptions: (companyId: string) => Promise<void>
  ncmContactOptions: DropdownOption[]
  insuranceCompanyContactOptions: DropdownOption[]
  patient: IPatient
}

class EditReferral extends React.Component<IEditReferralProps> {
  public state = {
    isLoading: false,
    reload: false,
    disableNCM: true,
    disableInsurance: true,
    initialInsurance: undefined,
    initialNCM: undefined,
    selectedInsuranceContactId:
      this.props.referral?.insuranceCompanyContactId ?? undefined,
    selectedNCMContactId: this.props.referral?.nurseCaseManagerContactId ?? undefined,
    selectedInsuranceContacts: new Map<string, string>(),
    selectedNCMContacts: new Map<string, string>(),
    dirtyInsurance: false,
    oldInsuranceContacts: '',
    initialInsuranceContacts: '',
    dirtyNCM: false,
    oldNCMContacts: '',
    initialNCMContacts: '',
    initialJurisdiction: '',
    selectedJurisdiction: '',
  }

  private async setup() {
    const { match } = this.props
    this.setState({ isLoading: true, reload: false })
    let promises = [] as Array<Promise<void>>
    if (match && match.params.referralId) {
      promises.push(this.props.getReferralById!(match.params.referralId))
    } else {
      this.props.setSelectedReferral!(DefaultReferral(true))
    }
    if (match && match.params.patientId) {
      promises.push(this.props.getPatientById!(match.params.patientId))
    }

    await Promise.all(promises).then(async () => {
      promises = [] as Array<Promise<void>>

      if (!this.props.referral!.isNew) {
        const formikValues = this.referralRef.current?.state?.values

        formikValues.jurisdiction = this.props.referral!.jurisdiction
        this.setState({
          selectedJurisdiction: this.props.referral!.jurisdiction,
          initialJurisdiction: this.props.referral!.jurisdiction,
        })

        if (this.props.referral!.nurseCaseManagerId) {
          formikValues.nurseCaseManagerCompanyId = this.props.referral?.nurseCaseManagerId
          this.props.getNCMContactOptions(this.props.referral!.nurseCaseManagerId)
          this.setState({ disableNCM: false })
        }

        if (this.props.referral!.employerId) {
          promises.push(this.props.getEmployers!(this.props.referral!.employer!.name!))
        }

        if (this.props.referral!.insuranceCompanyId) {
          formikValues.insuranceCompanyId = this.props.referral!.insuranceCompanyId
          this.props.getInsuranceCompanyContactOptions(
            this.props.referral!.insuranceCompanyId
          )
          this.setState({ disableInsurance: false })
        }
      }

      promises.push(this.props.getInsuranceCompanyOptions!())
      promises.push(this.props.getNCMCompanyOptions!())

      await Promise.all(promises).then(() => {
        this.setState({ isLoading: false }, () => {
          if (
            this.props.referral!.insuranceCompanyId &&
            this.props.referral?.insuranceCompany?.name != undefined
          ) {
            var initialSelection = new DropdownOption()
            initialSelection.description = this.props.referral?.insuranceCompany?.name
            initialSelection.id = this.props.referral!.insuranceCompanyId
            initialSelection.isEnabled = true
            this.setState({ initialInsurance: initialSelection })
            if (
              this.props.referral.insuranceCompanyContactId &&
              this.props.insuranceCompanyContactOptions
            ) {
              var index = this.props.insuranceCompanyContactOptions.findIndex(
                (x) => x.id == this.props.referral?.insuranceCompanyContactId
              )
              var d = document.getElementById(
                'insuranceCompanyContact'
              ) as HTMLSelectElement
              d.options[0].disabled = false
              d.selectedIndex = index + 1
              var contacts = new Map<string, string>()
              if (this.props.referral.additionalInsuranceContacts) {
                this.props.referral.additionalInsuranceContacts.forEach((contact) => {
                  contacts.set(contact.contactId, contact.contactName)
                })
              }
              this.setState({
                selectedInsuranceContactId:
                  this.props.referral?.insuranceCompanyContactId,
                selectedInsuranceContacts: contacts,
                oldInsuranceContacts: JSON.stringify(
                  Array.from(new Map([...contacts].sort()))
                ),
                initialInsuranceContacts: JSON.stringify(
                  Array.from(new Map([...contacts].sort()))
                ),
              })
            }
          }
          if (
            this.props.referral?.nurseCaseManagerId &&
            this.props.referral.nurseCaseManager?.company != undefined
          ) {
            var initialSelection = new DropdownOption()
            initialSelection.description = this.props.referral?.nurseCaseManager.company
            initialSelection.id = this.props.referral!.nurseCaseManagerId
            initialSelection.isEnabled = true
            this.setState({ initialNCM: initialSelection })
            if (
              this.props.referral.nurseCaseManagerContactId &&
              this.props.ncmContactOptions
            ) {
              var index = this.props.ncmContactOptions.findIndex(
                (x) => x.id == this.props.referral?.nurseCaseManagerContactId
              )
              var d = document.getElementById(
                'nurseCaseManagerContact'
              ) as HTMLSelectElement
              d.options[0].disabled = false
              d.selectedIndex = index + 1
              var contacts = new Map<string, string>()
              if (this.props.referral.additionalNCMContacts) {
                this.props.referral.additionalNCMContacts.forEach((contact) => {
                  contacts.set(contact.contactId, contact.contactName)
                })
              }
              this.setState({
                selectedNCMContactId: this.props.referral?.nurseCaseManagerContactId,
                selectedNCMContacts: contacts,
                oldNCMContacts: JSON.stringify(Array.from(new Map([...contacts].sort()))),
                initialNCMContacts: JSON.stringify(
                  Array.from(new Map([...contacts].sort()))
                ),
              })
            }
          }
        })
      })
    })
  }

  public async componentDidMount() {
    await this.setup()
  }

  public async componentDidUpdate(prevProps: IEditReferralProps) {
    if (this.props.match.params.referralId !== prevProps.match.params.referralId) {
      await this.setup()
    }
  }

  public componentWillUnmount() {
    this.props.setSelectedReferral!(undefined)
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload }, () => {
      submitForm()
    })
  }

  private setAdditionalContacts = (type: ReferralContactType, referral: IReferral) => {
    var additionalList = [] as ReferralContactDTO[]
    if (type == ReferralContactType.Insurance) {
      var keys = this.state.selectedInsuranceContacts.keys()
      var vals = this.state.selectedInsuranceContacts.values()
      this.state.selectedInsuranceContacts.forEach(() => {
        var contact = new ReferralContactDTO(
          referral.id,
          keys.next().value,
          type,
          vals.next().value
        )
        additionalList.push(contact)
      })
    } else {
      var keys = this.state.selectedNCMContacts.keys()
      var vals = this.state.selectedNCMContacts.values()
      this.state.selectedNCMContacts.forEach(() => {
        var contact = new ReferralContactDTO(
          referral.id,
          keys.next().value,
          type,
          vals.next().value
        )
        additionalList.push(contact)
      })
    }

    return additionalList
  }

  public save = async (
    values: IReferralFormValues,
    formikBag: FormikActions<IReferralFormValues>
  ) => {
    const referral = toIReferral(values, this.props.referral)
    if (referral.insuranceCompanyId) {
      referral.insuranceCompanyContactId = this.state.selectedInsuranceContactId
    } else {
      referral.insuranceCompanyContactId = undefined
    }

    if (referral.nurseCaseManagerId) {
      referral.nurseCaseManagerContactId = this.state.selectedNCMContactId
    } else {
      referral.nurseCaseManagerContactId = undefined
    }

    referral.additionalInsuranceContacts = this.setAdditionalContacts(
      ReferralContactType.Insurance,
      referral
    )
    referral.additionalNCMContacts = this.setAdditionalContacts(
      ReferralContactType.NCM,
      referral
    )
    referral.patientId = this.props.patient.id
    referral.jurisdiction = this.state.selectedJurisdiction

    await this.props.saveReferral!(referral)
      .then(() => {
        formikBag.setSubmitting(false)
        if (this.state.reload) {
          this.props.rerouteToPath!(
            `/patients/patient/${this.props.match.params.patientId}/referral/${referral.id}`
          )
          this.setState({
            dirtyInsurance: false,
            dirtyNCM: false,
          })
          this.setup()
        } else {
          this.cancel()
        }
      })
      .catch(() => formikBag.setSubmitting(false))
  }

  private cancel = () => {
    this.props.rerouteToPath!(`/patients/patient/${this.props.match.params.patientId}`)
  }

  private getInitialEmployer(id: string) {
    return _.find(this.props.employers, (x: IEmployer) => x.id === id)
  }

  private getSelectedEmployer(values: IReferralFormValues) {
    return _.find(this.props.employers, (x: IEmployer) => x.id === values.employerId)
  }

  private getSelectedInsurance(values: IReferralFormValues) {
    return _.find(
      this.props.insuranceCompanyOptions,
      (x: DropdownOption) => x.id === values.insuranceCompanyId
    )
  }

  private getSelectedNCM(values: IReferralFormValues) {
    return _.find(
      this.props.ncmCompanyOptions,
      (x: DropdownOption) => x.id === values.nurseCaseManagerId
    )
  }

  private getEmployerName(item: IEmployer) {
    if (item) {
      return item.name
    } else {
      return ''
    }
  }

  private getValue(item: any) {
    return item ? item.id : ''
  }

  private setValue =
    (setFieldValue: (field: string, value: any) => void) =>
    (fieldName: string, value: IReferralFormValues) => {
      const newValue = value !== undefined ? value.id : undefined
      setFieldValue(`${fieldName}Id`, newValue)
      setFieldValue(fieldName, value)
      const parentFieldName = fieldName.replace('Contact', '')
      if (newValue && newValue !== '') {
      } else {
        setFieldValue(parentFieldName, undefined)
        setFieldValue(`${parentFieldName}Id`, undefined)
      }
    }

  private setSelectedInsuranceContact = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    this.setState({
      selectedInsuranceContactId: event.target.selectedOptions[0].accessKey,
      selectedInsuranceContacts: new Map<string, string>(),
    })
  }

  private setSelectedNCMContact = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    this.setState({
      selectedNCMContactId: event.target.selectedOptions[0].accessKey,
      selectedNCMContacts: new Map<string, string>(),
    })
  }

  private getAllInsuranceCompanies = async (filter: string) => {
    await this.props.filterInsuranceCompanyOptions(filter)
  }

  private getAllNurseCaseManagerCompanies = async (filter: string) => {
    await this.props.filterNCMCompanyOptions(filter)
  }

  public updateNCMContacts = (value: any) => {
    this.props.getNCMContactOptions(value.id)
    var d = document.getElementById('nurseCaseManagerContact') as HTMLSelectElement
    d.options[0].disabled = false
    d.selectedIndex = 0
    this.setState({
      disableNCM: false,
      selectedNCMContactId: undefined,
      selectedNCMContacts: new Map<string, string>(),
    })
  }

  public updateInsuranceContacts = (value: any) => {
    this.props.getInsuranceCompanyContactOptions(value.id)
    var d = document.getElementById('insuranceCompanyContact') as HTMLSelectElement
    d.options[0].disabled = false
    d.selectedIndex = 0
    this.setState({
      disableInsurance: false,
      selectedInsuranceContactId: undefined,
      selectedInsuranceContacts: new Map<string, string>(),
    })
  }

  public referralRef = React.createRef<any>()

  public forceUpdateHandler() {
    var newTags = JSON.stringify(
      Array.from(new Map([...this.state.selectedInsuranceContacts].sort()))
    )
    var newNCM = JSON.stringify(
      Array.from(new Map([...this.state.selectedNCMContacts].sort()))
    )

    this.setState({
      dirtyInsurance:
        newTags != this.state.oldInsuranceContacts &&
        newTags != this.state.initialInsuranceContacts,
      oldInsuranceContacts: newTags,
      dirtyNCM:
        newNCM != this.state.oldNCMContacts && newNCM != this.state.initialNCMContacts,
      oldNCMContacts: newNCM,
    })
  }

  private setSelectedInsuranceContacts = (event: ChangeEvent<HTMLSelectElement>) => {
    if (this.state.selectedInsuranceContacts.has(event.currentTarget.dataset.value!)) {
      this.state.selectedInsuranceContacts.delete(event.currentTarget.dataset.value!)
      this.setState({
        selectedInsuranceContacts: this.state.selectedInsuranceContacts,
      })
      this.forceUpdateHandler()
    } else {
      this.state.selectedInsuranceContacts.set(
        event.currentTarget.dataset.value!,
        event.currentTarget.dataset.name!
      )
      this.setState({
        selectedInsuranceContacts: this.state.selectedInsuranceContacts,
      })
      this.forceUpdateHandler()
    }
  }

  private setSelectedNCMContacts = (event: ChangeEvent<HTMLSelectElement>) => {
    if (this.state.selectedNCMContacts.has(event.currentTarget.dataset.value!)) {
      this.state.selectedNCMContacts.delete(event.currentTarget.dataset.value!)
      this.setState({ selectedNCMContacts: this.state.selectedNCMContacts })
      this.forceUpdateHandler()
    } else {
      this.state.selectedNCMContacts.set(
        event.currentTarget.dataset.value!,
        event.currentTarget.dataset.name!
      )
      this.setState({ selectedNCMContacts: this.state.selectedNCMContacts })
      this.forceUpdateHandler()
    }
  }

  private setSelectedJurisdiction = (event: any) => {
    this.setState({ selectedJurisdiction: event.target.value })
    this.forceUpdateHandler()
  }

  public render() {
    const { classes, referral, employers, insuranceCompanyOptions, ncmCompanyOptions } =
      this.props
    const hasValue = (field: string, values: IReferralFormValues) => {
      const value = getIn(values, field)

      return !!value
    }
    const MenuProps = {
      getContentAnchorEl: null,
      PaperProps: {
        style: {
          maxHeight: 400,
        },
      },
    }
    const title = referral && referral.isNew ? 'Add New Referral' : 'Edit Referral'
    return (
      <>
        <Helmet>
          <title>
            {title +
              ': ' +
              this.props.patient?.lastName +
              ', ' +
              this.props.patient?.firstName +
              ' (' +
              moment(this.props.patient?.dateOfBirth).format('MM/DD/YYYY') +
              ')'}
          </title>
        </Helmet>
        {referral ? (
          <Formik
            initialValues={createIReferralFormValues(referral)}
            onSubmit={this.save}
            validationSchema={ReferralSchema}
            enableReinitialize={true}
            ref={this.referralRef}
          >
            {({
              errors,
              isValid,
              values,
              initialValues,
              setFieldValue,
              submitForm,
              dirty,
              submitCount,
            }) => (
              <Form>
                <Prompt
                  when={dirty && submitCount === 0}
                  message="Are you sure you want to leave the page? There are unsaved changes."
                />
                <AppBar style={{ boxShadow: 'none' }} position="sticky">
                  <ResponsiveAppBar title={title} pageIcon={<RecordVoiceOver />}>
                    <Grid container direction="row" spacing={2} justifyContent="flex-end">
                      {this.state.isLoading ? (
                        <Grid item>
                          <CircularProgress size={24} />
                        </Grid>
                      ) : (
                        <></>
                      )}
                      <Grid item>
                        <Button size="small" variant="contained" onClick={this.cancel}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          size="small"
                          color="secondary"
                          variant="contained"
                          disabled={
                            !isValid &&
                            !this.state.dirtyInsurance &&
                            !this.state.dirtyNCM &&
                            (this.state.selectedNCMContactId ==
                              this.props.referral?.nurseCaseManagerContactId ||
                              this.state.selectedNCMContactId == undefined) &&
                            (this.state.selectedInsuranceContactId ==
                              this.props.referral?.insuranceCompanyContactId ||
                              this.state.selectedInsuranceContactId == undefined) &&
                            this.state.selectedJurisdiction ==
                              this.state.initialJurisdiction
                          }
                          onClick={this.handleSave(submitForm, true)}
                        >
                          Save
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          size="small"
                          color="secondary"
                          variant="contained"
                          disabled={
                            !isValid &&
                            !this.state.dirtyInsurance &&
                            !this.state.dirtyNCM &&
                            (this.state.selectedNCMContactId ==
                              this.props.referral?.nurseCaseManagerContactId ||
                              this.state.selectedNCMContactId == undefined) &&
                            (this.state.selectedInsuranceContactId ==
                              this.props.referral?.insuranceCompanyContactId ||
                              this.state.selectedInsuranceContactId == undefined) &&
                            this.state.selectedJurisdiction ==
                              this.state.initialJurisdiction
                          }
                          onClick={this.handleSave(submitForm, false)}
                        >
                          Save and Close
                        </Button>
                      </Grid>
                    </Grid>
                  </ResponsiveAppBar>
                  <PatientActionBar />
                </AppBar>

                {!this.state.isLoading ? (
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justifyContent="space-between"
                    className={classes.topOfPage}
                  >
                    <Grid item lg={8} md={12}>
                      <CardWithTitle title="Referral" icon={RecordVoiceOver}>
                        <Grid item md={4} xs={12}>
                          <FullWidthField
                            format="YYYY-MM-DD"
                            type="date"
                            defaultValue={values.doi}
                            name="doi"
                            label="DOI"
                            inputId="doi"
                            fullWidth={true}
                            clearable
                            shrink={hasValue('doi', values)}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FullWidthField
                            format="YYYY-MM-DD"
                            type="date"
                            defaultValue={values.referralDate}
                            name="referralDate"
                            label="Referral Date"
                            inputId="referralDate"
                            fullWidth={true}
                            clearable
                            shrink={hasValue('referralDate', values)}
                            variant="outlined"
                            required
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FullWidthField
                            variant="outlined"
                            type="outlined"
                            name="referralTime"
                            label="Referral Time"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TypeAheadField
                            outlined
                            label="Employer"
                            labelShrink={hasValue('employerId', values)}
                            inputId="employerId"
                            name="employer"
                            cypressLabel="employer"
                            fullWidth
                            setFieldValue={this.setValue(setFieldValue)}
                            initialValue={this.getInitialEmployer(
                              initialValues.employerId
                            )}
                            selectedValue={this.getSelectedEmployer(values)}
                            items={employers}
                            getName={this.getEmployerName}
                            getValue={this.getValue}
                            errorMessage={errors.employerId}
                            getItems={this.props.getEmployers}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FullWidthField
                            variant="outlined"
                            type="outlined"
                            name="occupation"
                            label="Occupation"
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          direction="row"
                          lg={12}
                          spacing={2}
                          justifyContent="space-between"
                          style={{ marginBottom: '20px' }}
                        >
                          <Grid
                            container
                            item
                            lg={6}
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Grid item style={{ marginTop: '16px' }}>
                              <Typography
                                variant={'body1'}
                                color="textSecondary"
                                style={{
                                  height: '18px',
                                  paddingRight: '8px',
                                  marginBottom: '4px',
                                }}
                              >
                                INSURANCE
                              </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TypeAheadField
                                outlined
                                label="Insurance Company"
                                labelShrink={hasValue('insuranceCompanyId', values)}
                                inputId="insuranceCompanyId"
                                name="insuranceCompany"
                                fullWidth
                                setFieldValue={this.setValue(setFieldValue)}
                                initialValue={this.state.initialInsurance}
                                selectedValue={this.getSelectedInsurance(values)}
                                items={insuranceCompanyOptions}
                                getValue={this.getValue}
                                getName={(item: DropdownOption) => {
                                  return item.description
                                }}
                                errorMessage={errors.insuranceCompanyContactId}
                                getItems={this.getAllInsuranceCompanies}
                                updateParentComponent={this.updateInsuranceContacts}
                              />
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Primary Insurance Company Contact
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={260} />}
                                  inputProps={{ label: true, notched: true }}
                                  fullWidth
                                  name="insuranceCompanyContact"
                                  id="insuranceCompanyContact"
                                  onChange={this.setSelectedInsuranceContact}
                                  native={true}
                                  disabled={this.state.disableInsurance}
                                >
                                  <option value="">Select a contact</option>
                                  {this.props.insuranceCompanyContactOptions ? (
                                    this.props.insuranceCompanyContactOptions.map(
                                      (item: DropdownOption) => (
                                        <option
                                          accessKey={item.id}
                                          value={item.description}
                                          disabled={!item.isEnabled}
                                        >
                                          {item.description}{' '}
                                          {!item.isEnabled ? '(Inactive)' : ''}
                                        </option>
                                      )
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Additional Insurance Company Contacts
                                </InputLabel>
                                <Select
                                  disabled={
                                    this.state.selectedInsuranceContactId == undefined
                                  }
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={290} />}
                                  inputProps={{ label: true, notched: true }}
                                  fullWidth
                                  id="insuranceSelect"
                                  multiple
                                  value={Array.from(
                                    this.state.selectedInsuranceContacts.keys()
                                  )}
                                  renderValue={() =>
                                    Array.from(
                                      this.state.selectedInsuranceContacts.values()
                                    ).join(', ')
                                  }
                                  onChange={this.setSelectedInsuranceContacts}
                                  MenuProps={MenuProps}
                                >
                                  {this.props.insuranceCompanyContactOptions ? (
                                    this.props.insuranceCompanyContactOptions
                                      ?.filter(
                                        (x) =>
                                          x.id != this.state.selectedInsuranceContactId
                                      )
                                      .map((item: DropdownOption) => (
                                        <MenuItem
                                          value={item.id}
                                          data-name={item.description}
                                        >
                                          {item.id &&
                                          this.state.selectedInsuranceContacts.has(
                                            item.id
                                          ) ? (
                                            <CheckBox
                                              color="secondary"
                                              style={{ paddingRight: '10px' }}
                                            />
                                          ) : (
                                            <CheckBoxOutlineBlank
                                              color="action"
                                              style={{ paddingRight: '10px' }}
                                            />
                                          )}{' '}
                                          {item.description}{' '}
                                          {!item.isEnabled ? '(Inactive)' : ''}
                                        </MenuItem>
                                      ))
                                  ) : (
                                    <></>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            lg={6}
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Grid item style={{ marginTop: '16px' }}>
                              <Typography
                                variant={'body1'}
                                color="textSecondary"
                                style={{
                                  height: '18px',
                                  paddingRight: '8px',
                                  marginBottom: '4px',
                                }}
                              >
                                NURSE CASE MANAGER
                              </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TypeAheadField
                                outlined
                                label="Nurse Case Manager Company"
                                labelShrink={hasValue('nurseCaseManagerId', values)}
                                inputId="nurseCaseManagerId"
                                name="nurseCaseManager"
                                fullWidth
                                initialValue={this.state.initialNCM}
                                selectedValue={this.getSelectedNCM(values)}
                                setFieldValue={this.setValue(setFieldValue)}
                                items={ncmCompanyOptions}
                                getName={(item: DropdownOption) => {
                                  return item.description
                                }}
                                getValue={this.getValue}
                                getItems={this.getAllNurseCaseManagerCompanies}
                                updateParentComponent={this.updateNCMContacts}
                              />
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Primary Nurse Case Manager Contact
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={270} />}
                                  inputProps={{ label: true, notched: true }}
                                  fullWidth
                                  name="nurseCaseManagerContact"
                                  id="nurseCaseManagerContact"
                                  onChange={this.setSelectedNCMContact}
                                  native={true}
                                  disabled={this.state.disableNCM}
                                >
                                  <option value="">Select a contact</option>
                                  {this.props.ncmContactOptions ? (
                                    this.props.ncmContactOptions.map(
                                      (item: DropdownOption) => (
                                        <option
                                          accessKey={item.id}
                                          value={item.description}
                                          disabled={!item.isEnabled}
                                        >
                                          {item.description}{' '}
                                          {!item.isEnabled ? '(Inactive)' : ''}
                                        </option>
                                      )
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Additional Nurse Case Manager Contacts
                                </InputLabel>
                                <Select
                                  disabled={this.state.selectedNCMContactId == undefined}
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={295} />}
                                  inputProps={{ label: true, notched: true }}
                                  fullWidth
                                  id="ncmSelect"
                                  multiple
                                  value={Array.from(
                                    this.state.selectedNCMContacts.keys()
                                  )}
                                  renderValue={() =>
                                    Array.from(
                                      this.state.selectedNCMContacts.values()
                                    ).join(', ')
                                  }
                                  onChange={this.setSelectedNCMContacts}
                                  MenuProps={MenuProps}
                                >
                                  {this.props.ncmContactOptions ? (
                                    this.props.ncmContactOptions
                                      ?.filter(
                                        (x) => x.id != this.state.selectedNCMContactId
                                      )
                                      .map((item: DropdownOption) => (
                                        <MenuItem
                                          value={item.id}
                                          data-name={item.description}
                                        >
                                          {item.id &&
                                          this.state.selectedNCMContacts.has(item.id) ? (
                                            <CheckBox
                                              color="secondary"
                                              style={{ paddingRight: '10px' }}
                                            />
                                          ) : (
                                            <CheckBoxOutlineBlank
                                              color="action"
                                              style={{ paddingRight: '10px' }}
                                            />
                                          )}{' '}
                                          {item.description}{' '}
                                          {!item.isEnabled ? '(Inactive)' : ''}
                                        </MenuItem>
                                      ))
                                  ) : (
                                    <></>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <FullWidthField
                            variant="outlined"
                            type="outlined"
                            name="referralCaller"
                            label="Referral Caller"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FullWidthField
                            variant="outlined"
                            type="outlined"
                            name="claimNumber"
                            label="Claim Number"
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel shrink={true} variant="outlined">
                              Jurisdiction
                            </InputLabel>
                            <Select
                              variant="outlined"
                              input={<OutlinedInput notched labelWidth={85} />}
                              inputProps={{ label: true, notched: true }}
                              native={true}
                              fullWidth
                              onChange={this.setSelectedJurisdiction}
                              id="jurisdictionId"
                              name="jurisdiction"
                              value={this.state.selectedJurisdiction}
                            >
                              <option value="">Select a jurisdiction</option>
                              {States.map((item: IState) => (
                                <option
                                  accessKey={item.name}
                                  value={item.name}
                                  disabled={false}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </Select>{' '}
                          </FormControl>
                        </Grid>
                        <Grid item md={1} xs={12}>
                          <FullWidthField
                            variant="outlined"
                            type="outlined"
                            name="initials"
                            label="initials"
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <Field
                            name="health"
                            Label={{
                              label: 'Self Pay',
                            }}
                            component={CheckboxWithLabel}
                          />
                        </Grid>
                      </CardWithTitle>
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
              </Form>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </>
    )
  }
}

const InjectedEditReferral = inject<
  IStores,
  IEditReferralProps,
  Partial<IEditReferralProps>,
  any
>((stores: IStores) => ({
  employerContacts: stores.contacts.employerContacts,
  employers: stores.employers.employers,
  getEmployers: stores.employers.getAllEmployers,
  getPatientById: stores.patients.getPatientById,
  getReferralById: stores.referrals.getReferralWithContactsById,
  insuranceCompanies: stores.insuranceCompanies.insuranceCompanies,
  insuranceCompanyContacts: stores.contacts.insuranceCompanyContacts,
  isLoading: stores.referrals.isLoading,
  isModalOpen: stores.contacts.isModalOpen,
  nurseCaseManagerContacts: stores.contacts.nurseCaseManagerContacts,
  nurseCaseManagers: stores.nurseCaseManagers.nurseCaseManagers,
  referral: stores.referrals.selectedReferral,
  rerouteToPath: stores.global.rerouteToPath,
  saveReferral: stores.referrals.saveReferral,
  setSelectedReferral: stores.referrals.setSelectedReferral,
  insuranceCompanyOptions: stores.referrals.filteredInsuranceCompanyOptions,
  getInsuranceCompanyOptions: stores.referrals.getInsuranceCompanyOptions,
  filterInsuranceCompanyOptions: stores.referrals.filterInsuranceCompanyOptions,
  ncmCompanyOptions: stores.referrals.filteredNCMCompanyOptions,
  getNCMCompanyOptions: stores.referrals.getNCMCompanyOptions,
  filterNCMCompanyOptions: stores.referrals.filterNCMCompanyOptions,
  ncmContactOptions: stores.referrals.ncmContactOptions,
  insuranceCompanyContactOptions: stores.referrals.insuranceCompanyContactOptions,
  getInsuranceCompanyContactOptions: stores.referrals.getInsuranceCompanyContactOptions,
  getNCMContactOptions: stores.referrals.getNCMContactOptions,
  patient: stores.patients.selectedPatient,
}))(EditReferral)

export default withStyles(styles)(InjectedEditReferral)
