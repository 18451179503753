import {
  createStyles,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Check, CreditCard } from '@material-ui/icons'
import { FormikActions, getIn } from 'formik'
import { inject } from 'mobx-react'
// tslint:disable-next-line: no-var-requires
const CurrencyFormat: any = require('react-currency-format')
import Moment from 'moment'
import React from 'react'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import NumberMaskedInput from '../common/NumberMaskedInput'
import { IStores } from '../Stores'
import * as Yup from 'yup'
import {
  createICheckFormValues,
  ICheckFormValues,
  toIApplyPayment,
} from './CheckFormValues'
import { IPaymentLineItem } from 'src/viewModels/PaymentLIneItem'
import moment from 'moment'
import { formatDate } from 'src/utils/Formatter'
import { IPaymentLineItemDetailSummary } from 'src/viewModels/PaymentLineItemDetailSummary'
import { ProcessReceivedCheckResults } from 'src/viewModels/ProcessReceivedCheckResult'
import { applyRefundPayment } from 'src/services/ClaimService'
import { IPaymentDetailSummaryResult } from 'src/viewModels/PaymentDetailSummaryResult'

const styles = () =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        '&:hover': {
          backgroundColor: '#E1E1E1',
        },
        backgroundColor: '#EEEEEE',
      },
    },
  })

const maximumDate = moment().subtract(0, 'days')
const ReceiveRefundPaymentDialogSchema = Yup.object().shape({
  amount: Yup.number().required('Required').min(0, 'Please enter a positive number.'),
  checkNumber: Yup.string().required('Required'),
  datePaid: Yup.date()
    .max(maximumDate.toLocaleString(), 'Date Cannot be in the future!')
    .required('Required'),
})

interface IReceiveRefundPaymentDialogProps extends WithStyles<typeof styles> {
  close?: () => void
  selectedCMS1500Id?: string
  setSelectedCMS1500Id?: (cms1500Id?: string) => void
  lineItemDetailsWithBalance?: IPaymentLineItem[]
  appliedPayments?: IPaymentLineItemDetailSummary[]
  isLoading?: boolean
  isOpen?: boolean
  openProcessReceivedCheckDialog?: (results: ProcessReceivedCheckResults) => void
  paymentSummary?: IPaymentDetailSummaryResult
}

class ReceiveRefundPaymentDialog extends React.Component<IReceiveRefundPaymentDialogProps> {
  totalMismatch = false
  negativeArray = new Array<boolean>()
  applyTotalMismatch = false

  public componentDidMount() {
    this.props.lineItemDetailsWithBalance?.forEach(() => {
      this.negativeArray.push(false)
    })
  }

  private saveCheck = async (
    formValues: ICheckFormValues,
    formikBag: FormikActions<ICheckFormValues>
  ) => {
    let totalCharge = 0
    var i = 0

    for (const formValue of formValues.itemList) {
      const num = +(formValue.paymentAmount ?? 0)
      if (num < 0) {
        this.negativeArray[i] = true
        formikBag.setSubmitting(false)
        return
      } else {
        this.negativeArray[i] = false
      }
      totalCharge += num * 100
      i++
    }
    totalCharge = totalCharge / 100

    this.totalMismatch = totalCharge != formValues.applyAmount
    this.applyTotalMismatch =
      Number(formValues.amount ?? 0) < Number(formValues.applyAmount ?? 0)

    if (this.totalMismatch || this.applyTotalMismatch) {
      formikBag.setSubmitting(false)
    } else {
      const check = toIApplyPayment(formValues, this.props.selectedCMS1500Id!)
      await applyRefundPayment(check)
        .then((response: ProcessReceivedCheckResults) => {
          this.props.openProcessReceivedCheckDialog!(response)
          this.props.close!()
        })
        .finally(() => {
          formikBag.setSubmitting(false)
        })
    }
  }

  private close = () => {
    this.totalMismatch = false
    this.negativeArray = new Array<boolean>()
    this.applyTotalMismatch = false
    this.props.close!()
  }

  private renderCheckList() {
    return (
      <Table size="small">
        <TableHead>
          <TableRow className="gridPadding">
            <TableCell align="right">Check Date</TableCell>
            <TableCell>Check Number</TableCell>
            <TableCell>Refund</TableCell>
            <TableCell align="right">Applied Date</TableCell>
            <TableCell>Applied By</TableCell>
            <TableCell align="right">DOS</TableCell>
            <TableCell align="right">Line#</TableCell>
            <TableCell>CPT Code</TableCell>
            <TableCell align="right">Amount Paid</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.appliedPayments ? (
            this.props.appliedPayments.map((record, index) => {
              return (
                <TableRow className="gridPadding" key={index}>
                  <TableCell align="right">{formatDate(record.checkDate!)}</TableCell>
                  <TableCell>{record.checkNumber}</TableCell>
                  <TableCell>{record.refund ? <Check color="error" /> : <></>}</TableCell>
                  <TableCell align="right">
                    {formatDate(record.appliedDatePaid!)}
                  </TableCell>
                  <TableCell>{record.appliedBy!}</TableCell>
                  <TableCell align="right">{formatDate(record.dateOfService!)}</TableCell>
                  <TableCell align="right">{record.lineNumber}</TableCell>
                  <TableCell>{record.cptCode}</TableCell>
                  <TableCell
                    align="right"
                    style={{
                      color:
                        record.amountPaid != undefined && Number(record.amountPaid) < 0
                          ? 'red'
                          : 'black',
                    }}
                  >
                    {record.amountPaid ? record.amountPaidDisplay : ''}
                  </TableCell>
                </TableRow>
              )
            })
          ) : (
            <TableRow key="blank">
              <TableCell colSpan={7} align="center">
                <i>None</i>
              </TableCell>
            </TableRow>
          )}
          <TableRow key="footer">
            <TableCell colSpan={5}>
              <div />
            </TableCell>
            <TableCell>
              <strong>Total:</strong>
            </TableCell>
            <TableCell
              colSpan={2}
              align="right"
              style={{
                color:
                  this.props
                    .appliedPayments!.map((x) => Number(x.amountPaid) || 0)
                    .reduce((a, b) => a! + b!, 0) < 0
                    ? 'red'
                    : 'black',
              }}
            >
              {this.props.appliedPayments &&
              this.props
                .appliedPayments!.map((x) => Number(x.amountPaid) || 0)
                .reduce((a, b) => a! + b!, 0) != 0 ? (
                <CurrencyFormat
                  value={this.props
                    .appliedPayments!.map((x) => Number(x.amountPaid) || 0)
                    .reduce((a, b) => a! + b!, 0)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'($'}
                  suffix={')'}
                  allowNegative={false}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              ) : (
                <span>$0.00</span>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  private renderLineItemList(itemList: IPaymentLineItem[]) {
    return (
      <Table style={{ paddingTop: '10px' }} size="small">
        <TableHead>
          <TableRow key={'cpt-header'}>
            <TableCell align="right">Date of Service</TableCell>
            <TableCell align="right">Line</TableCell>
            <TableCell>CPT</TableCell>
            <TableCell colSpan={4}>Modifier</TableCell>
            <TableCell align="right">Contract Amount</TableCell>
            <TableCell align="right">Remaining Refund Balance</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemList ? (
            itemList!.map((y, lineIdx) => {
              return (
                <TableRow key={`q24-${lineIdx}`}>
                  <TableCell align="right">
                    {Moment(y.dateOfService).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell align="right">{y.lineNumber}</TableCell>
                  <TableCell>{y.dProcedureCptHcpcs}</TableCell>
                  <TableCell>{y.dModifier1}</TableCell>
                  <TableCell>{y.dModifier2}</TableCell>
                  <TableCell>{y.dModifier3}</TableCell>
                  <TableCell>{y.dModifier4}</TableCell>
                  <TableCell align="right">
                    <CurrencyFormat
                      value={y.contractAmount}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <CurrencyFormat
                      value={y.remainingBalance}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <FullWidthField
                      inputProps={{
                        max: '1000000',
                        style: {
                          maxWidth: '90px',
                          padding: '4px',
                        },
                      }}
                      variant="outlined"
                      name={`[itemList[${lineIdx}].paymentAmount`}
                    />
                    {this.negativeArray[lineIdx] && (
                      <div className="error" style={{ paddingTop: '10px' }}>
                        Please enter a positive number.
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              )
            })
          ) : (
            <TableRow key="blank">
              <TableCell colSpan={10} align="center">
                <i>None</i>
              </TableCell>
            </TableRow>
          )}
          <TableRow key="footer">
            <TableCell colSpan={7} align="right">
              <strong>Total:</strong>
            </TableCell>
            <TableCell align="right">
              {itemList ? (
                <CurrencyFormat
                  value={itemList!
                    .map((x) => x.billOutAmount || 0)
                    .reduce((a, b) => a! + b!, 0)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              ) : (
                <span>$0.00</span>
              )}
            </TableCell>
            <TableCell align="right">
              {itemList ? (
                <CurrencyFormat
                  value={itemList!
                    .map((x) => x.remainingBalance || 0)
                    .reduce((a, b) => a! + b!, 0)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              ) : (
                <span>$0.00</span>
              )}
            </TableCell>
            <TableCell align="right">
              {itemList ? (
                <CurrencyFormat
                  value={itemList!
                    .map((x) => +(x.paymentAmount || 0))
                    .reduce((a, b) => a! + b!, 0)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              ) : (
                <span>$0.00</span>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  public render() {
    const { isLoading, isOpen, selectedCMS1500Id, lineItemDetailsWithBalance } =
      this.props
    const hasValue = (field: string, values: any) => {
      const value = getIn(values, field)

      return !!value
    }
    return (
      <>
        {selectedCMS1500Id && (
          <ESDialog
            close={this.close}
            closeButtonText="Cancel"
            initialValues={createICheckFormValues(
              selectedCMS1500Id!,
              undefined,
              lineItemDetailsWithBalance!
            )}
            isLoading={isLoading}
            open={isOpen!}
            save={this.saveCheck}
            showSaveButton={true}
            maxWidth="md"
            title={
              `Receive Refund Payment -
                    ${this.props.paymentSummary?.patientName} (${this.props.paymentSummary?.patientDob})` +
              (this.props.paymentSummary?.groupNumber
                ? ` ${this.props.paymentSummary?.groupNumber}`
                : '')
            }
            saveButtonText="Save"
            titleIcon={<CreditCard />}
            validationSchema={ReceiveRefundPaymentDialogSchema}
          >
            {({ values, errors }) => (
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                spacing={5}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    spacing={5}
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Grid item>
                      <FullWidthField
                        format="YYYY-MM-DD"
                        defaultValue={values.datePaid}
                        type="date"
                        name="datePaid"
                        label="Check Date"
                        inputId="datePaid"
                        fullWidth={true}
                        clearable={true}
                        shrink={true}
                        required
                        errorMessage={errors.datePaid}
                      />
                    </Grid>
                    <Grid item>
                      <FullWidthField
                        required
                        name="checkNumber"
                        label="Check Number"
                        InputLabelProps={{
                          shrink: hasValue('checkNumber', values),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FullWidthField
                        required
                        name="amount"
                        label="Check Amount"
                        InputProps={{
                          inputComponent: NumberMaskedInput,
                          inputProps: {
                            currency: true,
                            guide: false,
                            numberOfDigits: 12,
                          },
                        }}
                        InputLabelProps={{ shrink: hasValue('amount', values) }}
                      />
                    </Grid>
                    <Grid item>
                      <FullWidthField
                        required
                        name="applyAmount"
                        label="Amount to Apply"
                        InputProps={{
                          inputComponent: NumberMaskedInput,
                          inputProps: {
                            currency: true,
                            guide: false,
                            numberOfDigits: 12,
                          },
                        }}
                        InputLabelProps={{
                          shrink: hasValue('applyAmount', values),
                        }}
                      />
                      <Grid item style={{ paddingTop: '0px' }}>
                        {this.totalMismatch && (
                          <div className="error">
                            Sum of claim line amounts must match check amount entered.
                          </div>
                        )}
                      </Grid>
                      <Grid item style={{ paddingTop: '0px' }}>
                        {this.applyTotalMismatch && (
                          <div className="error">
                            Amount to Apply must be less than or equal to Check Amount.
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="center"
                  alignItems="center"
                >
                  {this.renderLineItemList(values.itemList)}
                </Grid>
                <ExpansionPanel style={{ marginTop: '10px' }}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography color="textSecondary" variant="body2">
                      PAST RECEIVED REFUND PAYMENTS
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {this.renderCheckList()}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            )}
          </ESDialog>
        )}
      </>
    )
  }
}

const InjectedReceivePaymentDialog = inject<
  IStores,
  IReceiveRefundPaymentDialogProps,
  Partial<IReceiveRefundPaymentDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.providerRefundStore.closeDialog,
  selectedCMS1500Id: stores.providerRefundStore.selectedCMS1500Id,
  setSelectedCMS1500Id: stores.providerRefundStore.setSelectedCMS1500Id,
  lineItemDetailsWithBalance:
    stores.providerRefundStore.selectedRefundListItemsWithBalance,
  appliedPayments: stores.providerRefundStore.selectedAppliedPayments,
  isLoading: stores.providerRefundStore.isLoading,
  isOpen: stores.providerRefundStore.isOpen,
  openProcessReceivedCheckDialog: stores.receivePayments.openProcessReceivedCheckDialog,
  paymentSummary: stores.providerRefundStore.selectedPaymentSummary,
}))(withStyles(styles)(ReceiveRefundPaymentDialog))

export default InjectedReceivePaymentDialog
