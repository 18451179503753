import { Grid } from '@material-ui/core'
import { AttachMoney } from '@material-ui/icons'
import { connect, FormikActions, FormikContext } from 'formik'
import React from 'react'
import DatePickerField from '../common/DatePickerField/DatePickerField'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import { CMS1500LineItemDto } from '../viewModels/claim/CMS1500LineItemDto'
import { IClaimFormValues } from './ClaimFormValues'
import { createLineItemFormValues, ILineItemFormValues } from './LineItemFormValues'

export interface IHaveLineItemFormValues {
  lineItems: ILineItemFormValues
}

interface IBox24LineItemProps {
  index: number
  disabled?: boolean
  lineItem: CMS1500LineItemDto
}

interface IFormikProps {
  formik: FormikContext<IClaimFormValues>
}

const Box24Popover: React.SFC<IBox24LineItemProps & IFormikProps> = (props) => {
  const { index, formik, disabled = false, lineItem } = props

  function save(
    values: ILineItemFormValues,
    formikBag: FormikActions<ILineItemFormValues>
  ) {
    formik.setFieldValue(`q24.${index}`, values)
    formik.setFieldValue(`q24.${index}.isModalOpen`, false)
    formikBag.setSubmitting(false)
  }

  const closeModal = () => () => {
    formik.setFieldValue(`q24.${index}.isModalOpen`, false)
  }

  function renderField() {
    return (
      <Grid container direction="row" xs={12} spacing={1} wrap="nowrap">
        <Grid item xs={2}>
          <DatePickerField
            inputId="aDateOfServiceFrom"
            name={'aDateOfServiceFrom'}
            label="FROM:"
            fullWidth
            shrink={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePickerField
            inputId="aDateOfServiceTo"
            name={'aDateOfServiceTo'}
            label="TO:"
            fullWidth
            shrink={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'bPlaceOfServiceTo'}
            label="Place of Service"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'cEmg'}
            label="EMG"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'dProcedureCptHcpcs'}
            label="CPT/HCPCS"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'dModifier1'}
            label="MODIFIER"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'dModifier2'}
            label=" "
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'dModifier3'}
            label=" "
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'dModifier4'}
            label=" "
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={1}>
          <FullWidthField
            name={'eDiagnosisPointer'}
            label="Diagnosis Pointer"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'fCharges'}
            label="Charges"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'contractAmount'}
            label="Contract Amount"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'gDaysOrUnits'}
            label="Quantity"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'hFamilyPlan'}
            label="EPSDT Family Plan"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'iIdQualifier'}
            label="ID. Qual"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <FullWidthField
            name={'iNpi'}
            label="NPI"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <ESDialog
      close={closeModal()}
      initialValues={createLineItemFormValues(lineItem)}
      save={save}
      open={formik.values.q24[index].isModalOpen!}
      title={'Edit Box 24 Line Item'}
      maxWidth="xl"
      titleIcon={<AttachMoney />}
    >
      {() => <>{renderField()}</>}
    </ESDialog>
  )
}

export default connect<IBox24LineItemProps, IClaimFormValues>(Box24Popover)
