import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { Info } from '@material-ui/icons'
import { Form, Formik, FormikActions, FormikProps } from 'formik'
import * as React from 'react'
import Button from '../common/Button'
import ResponsiveAppBar from '../common/ResponsiveAppBar'

export interface IESDialog<T> extends DialogProps {
  bottomActions?: (props: FormikProps<T>, boundFunction?: any) => React.ReactNode
  cancel: () => void
  children: (props: FormikProps<T>) => React.ReactNode
  initialValues: T
  isLoading?: boolean
  title: string
  titleIcon?: React.ReactElement<SvgIconProps>
  create1500: (values: T, formikActions: FormikActions<T>) => void
  complete1500: (values: T, formikActions: FormikActions<T>) => void
  validationSchema?: any
  isInitialValid?: boolean
  incomingCMS1500Id: string
  is1500Created?: boolean
  billoutCompleted: boolean
  setBilloutCompleted: (complete: boolean) => void
}

interface IBillOutESDialogState<T> {
  currentValues?: T
  billOutMismatch: boolean
}

export default class ESDialog<T> extends React.Component<
  IESDialog<T>,
  IBillOutESDialogState<T>
> {
  public state = {
    billOutMismatch: false,
    currentValues: this.props.initialValues,
  }

  public componentDidMount() {
    ;(window as any).dialogFormikRef = React.createRef()
  }

  private completeBillOut = async (values: T, formikBag: FormikActions<T>) => {
    this.props.setBilloutCompleted(true)
    await this.setState((state) => {
      return {
        ...state,
        billOutMismatch: values !== this.state.currentValues,
      }
    })

    if (!this.state.billOutMismatch) {
      this.props.complete1500(values, formikBag)
    }
  }

  private submitCreate1500 = async (values: T, formikBag: FormikActions<T>) => {
    this.props.create1500(values, formikBag)
    await this.setState((state) => {
      return {
        ...state,
        currentValues: values,
        billOutMismatch: false,
      }
    })
  }

  public render() {
    const {
      bottomActions = () => null,
      cancel,
      initialValues,
      isLoading,
      create1500,
      title,
      titleIcon = <Info />,
      validationSchema,
      isInitialValid,
      incomingCMS1500Id,
      ...rest
    } = this.props
    return (
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        onClose={cancel}
        {...rest}
        disableBackdropClick={true}
      >
        {this.props.billoutCompleted && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1200',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={this.submitCreate1500}
          validationSchema={validationSchema}
          isInitialValid={isInitialValid}
          ref={(window as any).dialogFormikRef}
        >
          {(formikProps: FormikProps<T>) => (
            <Form>
              <ResponsiveAppBar title={title} pageIcon={titleIcon} isModal={true} />
              <DialogContent style={{ marginTop: 8 }}>
                {this.props.children(formikProps)}
              </DialogContent>
              {this.state.billOutMismatch && (
                <div
                  style={{ textAlign: 'right', margin: '0px 20px 12px' }}
                  className="error"
                >
                  Bill out values do not match created 1500, please recreate 1500.
                </div>
              )}
              <DialogActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ margin: '0px 20px 4px' }}
                >
                  <Grid item>{bottomActions(formikProps)}</Grid>
                  <Grid item>
                    <Button onClick={cancel} style={{ marginRight: '8px' }}>
                      Cancel
                    </Button>

                    <Button
                      disabled={
                        !formikProps.isValid ||
                        !this.props.is1500Created ||
                        this.props.billoutCompleted
                      }
                      onClick={() => {
                        this.completeBillOut(formikProps.values, formikProps)
                      }}
                      color="primary"
                      variant="contained"
                      style={{ marginRight: '8px' }}
                    >
                      Complete Bill Out
                    </Button>
                    <Button
                      disabled={!formikProps.isValid}
                      isLoading={
                        isLoading || formikProps.isValidating || formikProps.isSubmitting
                      }
                      color="primary"
                      onClick={() => {
                        formikProps.submitForm()
                      }}
                      variant="contained"
                    >
                      Create 1500
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  }
}
