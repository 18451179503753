import { IBodyPartDto, IProcedureDto, TestDto } from '../../generated_client'
import {
  createISurgeryCodeLineItemFormValues,
  fillBlankSurgeryCodes,
  filterSurgeryCodes,
  ISurgeryCodeLineItemFormValues,
  ISurgeryCodeLineItemFormValuesToDto,
} from '../EpisodesOfCare/EpisodeOfCareFormValues'

export interface ITestFormValues {
  bodyPartId: string
  bodyPart?: IBodyPartDto
  cptCodes: ISurgeryCodeLineItemFormValues[]
  createdOn: Date
  diagnosis: string
  dxCodes: ISurgeryCodeLineItemFormValues[]
  episodeOfCareId: string
  followUpDateTime?: Date
  readonly id: string
  isActive: boolean
  modifiedOn: Date
  name: string
  procedureId: string
  procedure?: IProcedureDto
  referralIssue?: boolean
  rxReceived: boolean
  status: string
  testIssue: boolean
  extended?: boolean
}

function setDefaultValues(): ITestFormValues {
  return {
    bodyPartId: '',
    cptCodes: [],
    createdOn: new Date(),
    diagnosis: '',
    dxCodes: [],
    episodeOfCareId: '',
    extended: true,
    followUpDateTime: undefined,
    id: '',
    isActive: true,
    modifiedOn: new Date(),
    name: '',
    procedureId: '',
    referralIssue: false,
    rxReceived: false,
    status: '',
    testIssue: false,
  }
}

function setValuesFromTest(test: TestDto): ITestFormValues {
  return {
    bodyPart: test.bodyPart,
    bodyPartId: test.bodyPartId || '',
    cptCodes: fillBlankSurgeryCodes(
      test.cptCodes!.map(createISurgeryCodeLineItemFormValues)
    ),
    createdOn: test.createdOn || new Date(),
    diagnosis: test.diagnosis || '',
    dxCodes: fillBlankSurgeryCodes(
      test.dxCodes!.map(createISurgeryCodeLineItemFormValues)
    ),
    episodeOfCareId: test.episodeOfCareId || '',
    extended: !test.id,
    followUpDateTime: test.followUpDateTime,
    id: test.id || '',
    isActive: test.isActive || true,
    modifiedOn: test.modifiedOn || new Date(),
    name: test.name || '',
    procedure: test.procedure,
    procedureId: test.procedureId || '',
    referralIssue: test.referralIssue || false,
    rxReceived: test.rxReceived || false,
    status: test.status || '',
    testIssue: test.testIssue || false,
  }
}

export function createITestFormValues(test?: TestDto): ITestFormValues {
  if (test === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromTest(test)
  }
}

export function toITest(formValues: ITestFormValues, original?: TestDto): TestDto {
  const test = original === undefined ? new TestDto() : original
  return new TestDto({
    ...test,
    bodyPartId: formValues.bodyPartId,
    cptCodes: filterSurgeryCodes(formValues.cptCodes).map(
      ISurgeryCodeLineItemFormValuesToDto
    ),
    createdOn: formValues.createdOn,
    diagnosis: formValues.diagnosis,
    dxCodes: filterSurgeryCodes(formValues.dxCodes).map(
      ISurgeryCodeLineItemFormValuesToDto
    ),
    episodeOfCareId: formValues.episodeOfCareId,
    followUpDateTime: formValues.followUpDateTime
      ? new Date(formValues.followUpDateTime)
      : undefined,
    id: formValues.id,
    isActive: formValues.isActive,
    modifiedOn: formValues.modifiedOn,
    name: formValues.name,
    procedureId: formValues.procedureId,
    referralIssue: formValues.referralIssue,
    rxReceived: formValues.rxReceived,
    status: formValues.status,
    testIssue: formValues.testIssue,
  })
}
