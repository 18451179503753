import {
  Chip,
  createStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { InputProps } from '@material-ui/core/Input'
import Downshift, {
  ControllerStateAndHelpers,
  GetItemPropsOptions,
  GetMenuPropsOptions,
  GetPropsCommonOptions,
} from 'downshift'
import { connect, FormikContext, getIn } from 'formik'
import * as _ from 'lodash'
import ChipInput, { ChipRenderer, ChipRendererArgs } from 'material-ui-chip-input'
import * as React from 'react'

type GetMenuPropsFn = (
  options?: GetMenuPropsOptions,
  otherOptions?: GetPropsCommonOptions
) => any

type GetItemPropsFn = (options: GetItemPropsOptions<any>) => any

const getItemsDebounceTimeout = 500

const styles = createStyles({
  chip: {
    marginRight: 4,
  },
  noSuggestions: {
    fontStyle: 'italic',
    padding: `11px 16px`,
  },
  popper: {
    zIndex: 9999,
  },
})

const downshiftResetState = {
  isOpen: false,
}

interface IFormikProps {
  formik: FormikContext<any>
}

interface IFreeTypeAheadFieldProps<T> extends WithStyles<typeof styles> {
  createNew: (newItem: string) => T
  disabled?: boolean
  fullWidth?: boolean
  getName: (item: T) => string
  getValue: (item: T) => string
  getItems: (filter: string) => void
  getChipColor?: (item: T) => string
  items: T[]
  name: string
  label: string
  required?: boolean
  outlined?: boolean
  readonly?: boolean
}

interface IFreeTypeAheadFieldState {
  downshiftInputValue: string
}

class FreeTypeAheadField<T> extends React.Component<
  IFreeTypeAheadFieldProps<T> & IFormikProps,
  IFreeTypeAheadFieldState
> {
  private inputRef: any

  constructor(props: IFreeTypeAheadFieldProps<T> & IFormikProps) {
    super(props)
    this.state = {
      downshiftInputValue: '',
    }
  }

  private get currentFormValues() {
    const { formik, name } = this.props
    return getIn(formik.values, name, [])
  }

  private setInputRef = (element: any) => {
    this.inputRef = element
  }

  public render() {
    const {
      fullWidth,
      label,
      required,
      formik,
      name,
      outlined,
      disabled = false,
    } = this.props

    const errorMessage = getIn(formik.errors, name)
    const hasError = errorMessage !== undefined
    const variant = outlined ? 'outlined' : 'standard'
    return (
      <Downshift
        inputValue={this.state.downshiftInputValue}
        onChange={this.onExistingSelected}
        itemToString={this.itemToString}
        onInputValueChange={this.onInputChanged}
        onOuterClick={this.onOuterClick}
        selectedItem={this.currentFormValues}
      >
        {({
          getInputProps,
          getMenuProps,
          getItemProps,
          highlightedIndex,
          inputValue,
          isOpen,
          setState,
        }) => {
          const inputProps = getInputProps<InputProps>()
          const onKeyDown = inputProps.onKeyDown
          delete inputProps.onKeyDown
          return (
            <div>
              <ChipInput
                InputProps={inputProps}
                InputLabelProps={{
                  required,
                  shrink: !!inputValue || this.currentFormValues.length > 0,
                }}
                label={label}
                fullWidth={fullWidth}
                disabled={disabled}
                inputValue={inputValue || ''}
                value={this.currentFormValues}
                chipRenderer={this.renderChip}
                onAdd={this.onNewAdded(setState)}
                onDelete={this.onDelete(setState)}
                onKeyDown={onKeyDown}
                inputRef={this.setInputRef}
                helperText={errorMessage}
                error={hasError}
                variant={variant}
              />
              {this.renderSuggestions(
                getMenuProps,
                getItemProps,
                isOpen,
                highlightedIndex
              )}
            </div>
          )
        }}
      </Downshift>
    )
  }

  public itemToString = (item: T) => {
    return this.props.getName(item)
  }

  private onExistingSelected = (
    selectedItem: T,
    helpers: ControllerStateAndHelpers<T>
  ) => {
    if (selectedItem) {
      this.addChip(selectedItem)
    }
    helpers.setState(downshiftResetState)
    this.setState({ downshiftInputValue: '' })
  }

  private onNewAdded = (setState: any) => (inputValue: string) => {
    const { createNew } = this.props
    const newItem = createNew(inputValue)
    if (newItem) {
      this.addChip(newItem)
    }
    setState(downshiftResetState)
    this.setState({ downshiftInputValue: '' })
  }

  private onOuterClick = (helpers: ControllerStateAndHelpers<T>) => {
    helpers.setState(downshiftResetState)
    this.setState({ downshiftInputValue: '' })
  }

  private addChip = (itemToAdd: T) => {
    const { formik, name } = this.props
    const newValues = this.currentFormValues.concat([itemToAdd])
    formik.setFieldValue(name, newValues)
  }

  private onDelete = (setState: any) => (chip: T) => {
    const { formik, getValue, name } = this.props
    const value = getValue(chip)
    const values = this.currentFormValues
    const newValues = values.filter((x: T) => getValue(x) !== value)
    formik.setFieldValue(name, newValues)
    setState(downshiftResetState)
  }

  private onInputChanged = (inputValue: string) => {
    if (typeof inputValue === 'string') {
      this.setState({ downshiftInputValue: inputValue })
    }
    _.debounce(() => {
      if (this.props.getItems) {
        this.props.getItems(inputValue)
      }
    }, getItemsDebounceTimeout)()
  }

  private renderChip: ChipRenderer = ({
    chip,
    handleClick,
    handleDelete,
  }: ChipRendererArgs) => {
    const { classes, getName, getValue, getChipColor } = this.props
    let style = { backgroundColor: '#FFFFFF', color: '#000000' }
    if (getChipColor) {
      const tagColor = getChipColor(chip)
      style = { backgroundColor: tagColor, color: '#FFFFFF' }
    }
    return (
      <Chip
        className={classes.chip}
        key={getValue(chip)}
        label={getName(chip)}
        onClick={handleClick}
        style={style}
        onDelete={getName(chip) !== 'System' ? handleDelete : undefined}
        variant="outlined"
      />
    )
  }

  private filterSelectedFromOptions = () => {
    const { getValue } = this.props
    const chipValues: string[] = this.currentFormValues.map(getValue)
    return (item: T) => {
      return !chipValues.some((cv) => getValue(item) === cv)
    }
  }

  private renderSuggestions(
    getMenuProps: GetMenuPropsFn,
    getItemProps: GetItemPropsFn,
    isOpen: boolean,
    highlightedIndex: number | null
  ) {
    const { classes, items = [] } = this.props
    const menuProps = getMenuProps({}, { suppressRefError: true })
    const menuItems = items
      .filter(this.filterSelectedFromOptions())
      .map(this.renderMenuItem(getItemProps, highlightedIndex))

    const noSuggestionsMessage = (
      <Typography className={classes.noSuggestions} variant="body2">
        No suggestions found. Press Enter to create a new entry
      </Typography>
    )

    const suggestions = <MenuList {...menuProps}>{menuItems}</MenuList>
    return (
      <div>
        <Popper
          className={classes.popper}
          open={isOpen}
          keepMounted={false}
          anchorEl={this.inputRef}
          placement="bottom"
          modifiers={{
            preventOverflow: {
              boundariesElement: 'viewport',
              enabled: true,
            },
          }}
          style={{
            width: this.inputRef ? this.inputRef.clientWidth : undefined,
          }}
        >
          <Paper>{menuItems.length > 0 ? suggestions : noSuggestionsMessage}</Paper>
        </Popper>
      </div>
    )
  }

  private renderMenuItem =
    (getItemProps: GetItemPropsFn, highlightedIndex: number | null) =>
    (item: T, index: number) => {
      const { getName, getValue, getChipColor } = this.props
      const itemProps = getItemProps({ key: getValue(item), item })
      const isSelected = highlightedIndex === index
      let style = { backgroundColor: '#FFFFFF', color: '#000000' }
      if (getChipColor) {
        const itemColor = getChipColor(item)
        style = { backgroundColor: itemColor, color: '#FFFFFF' }
      }
      return (
        <MenuItem {...itemProps} selected={isSelected} style={style}>
          {getName(item)}
        </MenuItem>
      )
    }
}

export default withStyles(styles)(
  connect<IFreeTypeAheadFieldProps<any>, any>(FreeTypeAheadField)
)
