import { createStyles, Paper, withStyles, WithStyles } from '@material-ui/core'
import React from 'react'

const styles = createStyles({
  tabPanel: {
    width: '100%',
  },
})

interface ITabPanelProps extends WithStyles<typeof styles> {
  children?: React.ReactNode
  index: number
  value: number
}

class TabPanel extends React.Component<ITabPanelProps> {
  public render() {
    const { children, value, index } = this.props

    return (
      <Paper
        role="tabpanel"
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        hidden={value !== index}
        className={this.props.classes.tabPanel}
      >
        {children}
      </Paper>
    )
  }
}

export default withStyles(styles)(TabPanel)
