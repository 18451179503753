export interface IClaimLineItemLookupRequest {
  providerTypeId: string
  providerGroupId: string
  cptCode: string
  page: number
  pageSize: number
}

export class ClaimLineItemLookupRequest implements IClaimLineItemLookupRequest {
  public providerTypeId: string
  public providerGroupId: string
  public cptCode: string
  public page: number
  public pageSize: number
}
