import { AddCircle, Edit, LocalHospital, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultLocation, ILocation } from '../../Definitions'
import {
  AddressDto,
  Client,
  ILocationDto,
  LocationDto,
  ProviderTypeDto,
  SwaggerResponse,
  TagDto,
} from '../../generated_client'
import { IProcedureFilter } from '../../schedules/ProviderLookupDialog'
import GlobalStore from '../../stores/GlobalStore'
import { OrganizationId } from '../../utils/OrganizationIds'
import DataTableStore from '../DataTableStore'
import ProvidersStore from '../ProvidersStore'
import {
  GetLocationById,
  GetPhysicianOptionsAsync,
  GetProviderGroupOptions,
} from 'src/services/LookupService'
import { DropdownOption } from 'src/viewModels/DropdownOption'
const ColumnSettingsDto = 'LocationDto'
const DistanceColumnSettingsDto = 'DistanceLocationDto'
export enum Distance {
  twentyFiveMiles = '25 miles',
  fiftyMiles = '50 miles',
  oneHundredMiles = '100 miles',
  twoFiftyMiles = '250 miles',
  fiveHundredMiles = '500 miles',
}
import Constants from 'src/config'

export default class LocationsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public locations: ILocation[]
  @observable
  public providerTypes: ProviderTypeDto[]
  @observable
  public selectedLocation?: ILocation
  @observable
  public selectedEditClaimLocation?: ILocation
  @observable
  public selectedEditScheduleLocation?: ILocation
  @observable
  public selectedModalEditScheduleLocation?: ILocation
  @observable
  public selectedDistance: string = Distance.twentyFiveMiles
  @observable
  public selectedAddress?: AddressDto
  @observable
  public selectedProviderTypeId?: string
  @observable
  public selectedProcedureFilters?: IProcedureFilter[]
  @observable
  public selectedTags: TagDto[] = []
  @observable
  public selectedProviderLocation?: string
  @observable
  public selectedProviderGroup?: string
  public dataTableStore: DataTableStore<ILocationDto, ILocation>
  @observable
  public canFindLatLong: boolean
  @observable
  public physicianItems: DropdownOption[]
  @observable
  public isGroupModalOpen: boolean = false
  @observable
  groupOptions: DropdownOption[]
  @observable
  filteredGroupOptions: DropdownOption[]

  constructor(
    private providerStore: ProvidersStore,
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<ILocationDto, ILocation>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllLocations(
          this.providerStore.selectedEditScheduleProvider
            ? this.providerStore.selectedEditScheduleProvider.id
            : this.providerStore.selectedProvider
              ? this.providerStore.selectedProvider.id
              : undefined,
          this.selectedProviderTypeId,
          filter
            ? filter
            : this.selectedProviderLocation
              ? this.selectedProviderLocation
              : undefined,
          this.selectedTags.map((x) => (x ? x.name : '')).toString(),
          this.selectedProcedureFilters
            ? this.selectedProcedureFilters
                .filter((x) => x.selected)
                .map((x) => (x ? x.procedure!.diagnostic : ''))
                .toString()
            : undefined,
          this.selectedAddress ? this.selectedAddress.latitude : undefined,
          this.selectedAddress ? this.selectedAddress.longitude : undefined,
          this.getRange(),
          page,
          5,
          orderBy,
          includeInactives,
          this.selectedProviderGroup ? this.selectedProviderGroup : ''
        ),
      (location) => this.setupLocations(location)
    )
  }

  @action.bound
  public closeDialog() {
    this.dataTableStore.clearFilter()
    this.dataTableStore.loadData()
    this.isModalOpen = false
    if (this.selectedLocation) {
      this.selectedLocation = undefined
    }
    if (this.selectedAddress) {
      this.selectedAddress = undefined
    }

    if (this.selectedEditClaimLocation) {
      this.selectedEditClaimLocation = undefined
    }

    if (this.selectedEditScheduleLocation) {
      this.selectedEditScheduleLocation = undefined
    }
  }

  @action.bound
  public disableLocation(location: ILocation) {
    this.isLoading = true
    return this.client
      .archiveLocation(location.id)
      .then(() => {
        this.dataTableStore.loadData()
        this.getAllLocationsForProvider()
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndLocations() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(
        this.globalStore.user!.id,
        this.selectedAddress ? DistanceColumnSettingsDto : ColumnSettingsDto
      )
      .then((data) => {
        runInAction(() => {
          if (
            this.globalStore.currentAppOrganization &&
            this.globalStore.currentAppOrganization.id === OrganizationId.Surgical
          ) {
            data.result = data.result.filter((x) => x.fieldName !== 'procedure')
          }
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public async getLocationById(locationId: string) {
    var selectedLocation = await GetLocationById(locationId)
    this.selectedEditClaimLocation = this.addDefaultFields(selectedLocation)
  }

  @action.bound
  public getAllLocations(
    filter?: string | undefined,
    page?: number,
    orderBy?: string | undefined,
    includeInactives?: boolean | undefined
  ) {
    this.isLoading = true
    return this.client
      .getAllLocations(
        this.providerStore.selectedProvider
          ? this.providerStore.selectedProvider.id
          : undefined,
        undefined,
        filter,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        page,
        undefined, //Pagesize
        orderBy,
        includeInactives
      )
      .then((resp: SwaggerResponse<ILocationDto[]>) =>
        runInAction(() => {
          this.locations = this.setupLocations(resp.result)
          this.isLoading = false
          return resp
        })
      )
  }

  @action.bound
  public getAllLocationsForProvider() {
    this.isLoading = true
    const providerId = this.providerStore.selectedEditScheduleProvider
      ? this.providerStore.selectedEditScheduleProvider.id
      : this.providerStore.selectedProvider
        ? this.providerStore.selectedProvider.id
        : undefined
    if (providerId) {
      return this.client
        .getAllLocations(
          providerId,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          1, //Page
          5, //Pagesize
          undefined,
          false
        )
        .then((resp: SwaggerResponse<ILocationDto[]>) =>
          runInAction(() => {
            this.locations = this.setupLocations(resp.result)
          })
        )
        .finally(() => {
          this.isLoading = false
        })
    } else {
      return Promise.resolve()
    }
  }

  @action.bound
  public getAllLocationsByProviderId(providerId: string) {
    this.isLoading = true
    //This is only used by the Simple Event Dialog's dropdown
    if (providerId) {
      return this.client
        .getAllLocations(
          providerId,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          1, //Page
          5000, //Pagesize
          undefined,
          false
        )
        .then((resp: SwaggerResponse<ILocationDto[]>) =>
          runInAction(() => {
            this.locations = this.setupLocations(resp.result)
          })
        )
        .finally(() => {
          this.isLoading = false
        })
    } else {
      return Promise.resolve()
    }
  }

  @action.bound
  public openDialogWithLocation(location: ILocation) {
    this.isModalOpen = true
    this.selectedLocation = location
  }

  @action.bound
  public async getProviderGroupOptions() {
    this.isLoading = true
    this.groupOptions = await GetProviderGroupOptions()
    this.filteredGroupOptions = this.groupOptions
    this.isLoading = false
  }

  @action.bound
  public async filterProviderGroupOptions(filter: string) {
    this.filteredGroupOptions = this.groupOptions.filter((x) =>
      x.description.toLowerCase().includes(filter.toLowerCase())
    )
  }

  @action.bound
  public openGroupDialogWithLocation(location: ILocation) {
    this.isGroupModalOpen = true
    this.selectedLocation = location
    this.getProviderGroupOptions()
  }

  @action.bound
  public closeGroupModal() {
    this.isGroupModalOpen = false
    this.selectedLocation = undefined
    this.dataTableStore.loadData()
  }

  @action.bound
  public recoverLocation(location: ILocation) {
    this.isLoading = true
    return this.client
      .reactivateLocation(location.id)
      .then(() => {
        this.dataTableStore.loadData()
        this.getAllLocationsForProvider()
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(isNew: boolean) {
    if (!isNew) {
      this.closeDialog()
    } else {
      this.client
        .getLocationById(this.selectedLocation!.id)
        .then((resp: SwaggerResponse<ILocationDto>) =>
          runInAction(() => {
            let location = this.addDefaultFields(resp.result)
            this.openDialogWithLocation(location)
          })
        )
    }
  }

  @action.bound
  public saveLocation(location: ILocation) {
    this.isLoading = true
    let promise
    const locationDto = new LocationDto(location)
    locationDto.verified = true

    if (this.providerStore.selectedProvider) {
      locationDto.providerId = this.providerStore.selectedProvider.id
    }
    if (location.isNew) {
      promise = this.client.createLocation(
        this.providerStore.selectedProvider!.id,
        locationDto
      )
    } else {
      if (this.selectedAddress) {
        this.selectedAddress.id = locationDto.physicalAddress?.id
        locationDto.physicalAddress = this.selectedAddress
      }

      promise = this.client.updateLocation(location.id, locationDto)
    }
    return promise
      .then(() => {
        this.resetUIState(location.isNew)
        this.getAllLocationsForProvider()
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupLocations = (locations: ILocationDto[]) => {
    return locations.map((x) => this.setupLocationMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (location: ILocationDto): ILocation => {
    if (this.selectedProcedureFilters && location.procedure) {
      location.procedure = location
        .procedure!.split(' | ')
        .filter(
          (x) =>
            this.selectedProcedureFilters!.find((y) =>
              x.includes(y.procedure!.diagnostic!)
            ) !== undefined
        )
        .join(' | ')
    }
    return { ...DefaultLocation(), ...location }
  }

  private setupLocationMenuItems = (location: ILocation) => {
    location.displayAddress = this.buildDisplayAddress(location.physicalAddress)
    location.menuItems = [
      {
        icon: Edit,
        color: '#29348F',
        name: 'Edit',
        onClick: () => this.openDialogWithLocation(location),
      },
    ]

    if (this.globalStore.user?.roles?.includes(Constants.Roles.Administrator)) {
      location.menuItems.push({
        icon: LocalHospital,
        color: '#29348F',
        name: 'Change Group',
        onClick: () => this.openGroupDialogWithLocation(location),
      })
    }
    if (location.isActive) {
      location.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableLocation(location),
      })
    } else {
      location.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverLocation(location),
      })
    }
    return location
  }

  @action.bound
  public resetCoordinates() {
    if (this.selectedAddress) {
      this.selectedAddress.longitude = undefined
      this.selectedAddress.latitude = undefined
    }
    if (this.selectedLocation && this.selectedLocation.physicalAddress) {
      this.selectedLocation.physicalAddress.latitude = undefined
      this.selectedLocation.physicalAddress.longitude = undefined
    }
  }

  private buildDisplayAddress(address: AddressDto) {
    if (!address) {
      return ''
    }
    const { line1, line2, city, state, zip } = address

    if (line1 === '' && line2 === '' && city === '' && state === '' && zip === '') {
      return ''
    }

    let addressString = line1 || ''
    if (line2) {
      addressString += ` ${line2}`
    }
    addressString += `, ${city || ''}, ${state || ''} ${zip || ''}`
    return addressString
  }

  public getRange() {
    switch (this.selectedDistance) {
      case Distance.twentyFiveMiles:
        return 25
      case Distance.fiftyMiles:
        return 50
      case Distance.oneHundredMiles:
        return 100
      case Distance.twoFiftyMiles:
        return 250
      case Distance.fiveHundredMiles:
        return 500
      default:
        return 25
    }
  }

  @action.bound
  public clearData() {
    this.selectedAddress = undefined
    this.selectedLocation = undefined
    this.selectedProviderTypeId = undefined
    this.selectedTags = []
    this.selectedDistance = Distance.twentyFiveMiles
    this.selectedProcedureFilters = undefined
    return Promise.resolve()
  }

  @action.bound
  public setSelectedTags(tags: TagDto[]) {
    this.selectedTags = tags
  }

  @action.bound
  public addSelectedTag(tag: TagDto) {
    this.selectedTags.push(tag)
  }

  @action.bound
  public removeSelectedTag(tagId: string) {
    this.selectedTags = this.selectedTags.filter((x) => x.id != tagId)
    return Promise.resolve()
  }

  @action.bound
  public setDistance(distance: string) {
    this.selectedDistance = distance
  }

  @action.bound
  public setSelectedAddress(address?: AddressDto, load: boolean = true) {
    this.selectedAddress = address
    if (load) {
      this.dataTableStore.loadData()
    }
    return Promise.resolve()
  }

  @action.bound
  public setCanFindLat(dist: boolean) {
    this.canFindLatLong = dist
  }

  @action.bound
  public setSelectedProviderTypeId(providerTypeId: string) {
    this.selectedProviderTypeId = providerTypeId
  }

  @action.bound
  public setSelectedEditScheduleLocation(location?: ILocation, reload?: () => void) {
    this.selectedEditScheduleLocation = location
    this.getPhysicianOptions().then(() => {
      if (reload != undefined) {
        reload()
      }
    })
  }

  @action.bound
  public setSelectedEditModalScheduleLocation(location?: ILocation) {
    this.selectedModalEditScheduleLocation = location
  }

  @action.bound
  public setSelectedEditClaimLocation(location?: ILocation) {
    this.selectedEditClaimLocation = location
  }

  @action.bound
  public setSelectedLocation(location?: ILocation) {
    this.selectedLocation = location
  }

  @action.bound
  public clearLocations() {
    this.locations = []
  }

  @action.bound
  public setSelectedProcedureFilters(procedureFilters: IProcedureFilter[]) {
    this.selectedProcedureFilters = procedureFilters
    this.dataTableStore.loadData()
  }

  @action.bound
  public setSelectedProviderLocation(locationName?: string) {
    this.selectedProviderLocation = locationName
  }

  @action.bound
  public setSelectedProviderGroup(providerGroup?: string) {
    this.selectedProviderGroup = providerGroup
  }

  @action.bound
  public async getPhysicianOptions() {
    if (this.selectedEditScheduleLocation !== undefined) {
      this.physicianItems = await GetPhysicianOptionsAsync(
        this.selectedEditScheduleLocation?.id ?? '0'
      )
    }
  }
}
