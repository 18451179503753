import { TableCell, TableSortLabel } from '@material-ui/core'
import React from 'react'

type AlignOptions = 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined

interface ISortedTableCellProps {
  orderBy: string
  order: string
  columnName: string
  propertyName: string
  onClick: (propertyName: string) => void
  align?: AlignOptions
  style?: React.CSSProperties | undefined
}
function SortedTableCell(props: ISortedTableCellProps) {
  type OrderDirection = 'asc' | 'desc' | undefined

  return (
    <TableCell align={props.align} style={props.style}>
      <TableSortLabel
        active={props.orderBy === props.propertyName}
        direction={
          props.orderBy === props.propertyName ? (props.order as OrderDirection) : 'asc'
        }
        aria-label={props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        onClick={() => props.onClick(props.propertyName)}
      >
        {props.columnName}
      </TableSortLabel>
    </TableCell>
  )
}
export default SortedTableCell
