import {
  createStyles,
  FormControlLabel,
  Grid,
  Switch,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { Person } from '@material-ui/icons'
import { Field, FormikActions, getIn } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import { inject } from 'mobx-react'
import * as React from 'react'
import DatePickerField from '../common/DatePickerField/DatePickerField'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import PhoneNumberMask from '../common/PhoneNumberMask'
import SelectField from '../common/SelectField/SelectField'
import { IContact, IContactType } from '../Definitions'
import { IStores } from '../Stores'
import {
  AlternateContactSchema,
  ContactSchema,
  createIContactFormValues,
  IContactFormValues,
  toIContact,
} from './ContactFormValues'

const styles = ({ spacing }: Theme) =>
  createStyles({
    topOfPage: {
      marginLeft: spacing(3),
      marginRight: spacing(3),
      marginTop: spacing(11),
      padding: spacing(2),
    },
  })

interface IContactEditDialogProps extends WithStyles<typeof styles> {
  close?: () => void
  contact?: IContact
  contactTypes?: IContactType[]
  getAllContactTypes?: (includeInactives: boolean) => Promise<void>
  isLoading?: boolean
  isOpen?: boolean
  saveContact?: (contact: IContact) => void
  title?: string
  contactVariant?: string
}

class ContactEditDialog extends React.Component<IContactEditDialogProps> {
  public async componentDidMount() {
    await this.props.getAllContactTypes!(true)
  }

  private editContactTypes(contactTypes: IContactType[], savedContactTypeId: string) {
    contactTypes.forEach((contactType) => {
      if (!contactType.isActive) {
        if (contactType.id === savedContactTypeId) {
          if (!contactType.name.includes('(Inactive)')) {
            contactType.name += ' (Inactive)'
          }
        }
      }
    })
    return contactTypes
  }

  private getName(item: any) {
    return item ? item.name : ''
  }

  private getValue(item: any) {
    return item ? item.id : ''
  }

  public state = {
    contactTypeError: false,
  }

  public render() {
    const {
      close,
      contact,
      isLoading = false,
      isOpen = false,
      title,
      contactVariant = '',
      contactTypes,
    } = this.props
    const initialValues = createIContactFormValues(contact)

    const hasValue = (field: string, values: IContactFormValues) => {
      const value = getIn(values, field)

      return !!value
    }

    const isAlternate =
      contactVariant === 'ReferringDoctor' || contactVariant === 'InsuranceCompany'

    return (
      <ESDialog
        close={close!}
        initialValues={initialValues}
        isLoading={isLoading}
        open={isOpen}
        save={this.save}
        title={title || ''}
        titleIcon={<Person />}
        validationSchema={!isAlternate ? ContactSchema : AlternateContactSchema}
      >
        {({ values, setFieldValue }) => {
          return (
            <Grid container direction="column" spacing={3} style={{ marginTop: 8 }}>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={2}>
                  <FullWidthField
                    shrink={hasValue('firstName', values)}
                    variant="outlined"
                    autoFocus
                    name="firstName"
                    label="First Name"
                    required={!isAlternate}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FullWidthField
                    shrink={hasValue('lastName', values)}
                    variant="outlined"
                    name="lastName"
                    label="Last Name"
                    required={!isAlternate}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FullWidthField
                    shrink={hasValue('suffix', values)}
                    variant="outlined"
                    name="suffix"
                    label="Suffix"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FullWidthField
                    shrink={hasValue('title', values)}
                    variant="outlined"
                    name="title"
                    label="Title"
                  />
                </Grid>
              </Grid>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={2}>
                  <FullWidthField
                    shrink={hasValue('officeNumber', values)}
                    variant="outlined"
                    name="officeNumber"
                    label="Office #"
                    InputProps={{ inputComponent: PhoneNumberMask }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FullWidthField
                    shrink={hasValue('cellNumber', values)}
                    variant="outlined"
                    name="cellNumber"
                    label="Cell #"
                    InputProps={{ inputComponent: PhoneNumberMask }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FullWidthField
                    shrink={hasValue('faxNumber', values)}
                    variant="outlined"
                    name="faxNumber"
                    label="Fax #"
                    InputProps={{ inputComponent: PhoneNumberMask }}
                  />
                </Grid>
                {contactVariant === 'NurseCaseManager' ||
                contactVariant === 'InsuranceCompany' ? (
                  <Grid item xs={3}>
                    <DatePickerField
                      name="dateIncentiveGiven"
                      label="Date Incentive Given"
                      inputId="dateIncentiveGiven"
                      fullWidth={true}
                      clearable
                      shrink={hasValue('dateIncentiveGiven', values)}
                      variant="outlined"
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={4}>
                  <FullWidthField
                    shrink={hasValue('email', values)}
                    variant="outlined"
                    name="email"
                    label="Email"
                  />
                </Grid>
                <Grid item xs={2}>
                  <SelectField
                    inputId="contactType"
                    getName={this.getName}
                    getValue={this.getValue}
                    items={this.editContactTypes(contactTypes!, values.contactType)}
                    label="Type"
                    name="contactType"
                    fullWidth
                    shrinkLabel={true}
                    outlined
                    disableInactive={true}
                  />
                </Grid>
                <Grid item justifyContent="flex-end" className="error">
                  {this.state.contactTypeError
                    ? 'Please select an active contact type'
                    : ''}
                </Grid>
                {!isAlternate ? (
                  <Grid item xs={2}>
                    <Field
                      name="isSpecialPricing"
                      Label={{
                        label: 'Special Pricing?',
                      }}
                      component={CheckboxWithLabel}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                {contactVariant === 'NurseCaseManager' ||
                contactVariant === 'InsuranceCompany' ? (
                  <Grid item xs={2}>
                    <Field
                      name="isCostSavingsReport"
                      Label={{
                        label: 'Cost Savings Report',
                      }}
                      component={CheckboxWithLabel}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={6}>
                  <FullWidthField
                    shrink={hasValue('comments', values)}
                    name="comments"
                    label="Comments"
                    rows="6"
                    multiline={true}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={2} style={{ position: 'absolute', bottom: -5 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isActive}
                        onChange={this.handleIsActiveChange(values, setFieldValue)}
                        value="isActive"
                        color="secondary"
                        name="isActive"
                      />
                    }
                    label={values.isActive ? 'Active' : 'Inactive'}
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        }}
      </ESDialog>
    )
  }

  public save = (
    values: IContactFormValues,
    formikBag: FormikActions<IContactFormValues>
  ) => {
    if (
      this.props.contactTypes
        ?.filter((x) => !x.isActive)
        .some((x) => x.id == values.contactType)
    ) {
      this.setState({ contactTypeError: true })
      formikBag.setSubmitting(false)
    } else {
      this.setState({ contactTypeError: false })
      const contact = toIContact(values, this.props.contact)
      this.props.saveContact!(contact)
    }
  }

  private handleIsActiveChange =
    (values: IContactFormValues, setFieldValue: (field: string, value: any) => void) =>
    () => {
      setFieldValue('isActive', !values.isActive)
    }
}

const InjectedContactEditDialog = inject<
  IStores,
  IContactEditDialogProps,
  Partial<IContactEditDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.contacts.closeDialog,
  contact: stores.contacts.selectedContact,
  contactTypes: stores.contactTypes.contactTypes,
  getAllContactTypes: stores.contactTypes.getAllContactTypes,
  isLoading: stores.contacts.isLoading,
  isOpen: stores.contacts.isModalOpen,
  saveContact: stores.contacts.saveContact,
  title: stores.contacts.modalTitle,
}))(ContactEditDialog)

export default withStyles(styles)(InjectedContactEditDialog)
