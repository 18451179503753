import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React from 'react'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { formatCurrency, formatDate } from 'src/utils/Formatter'
import { ReceivedCheckDetailSummary } from 'src/viewModels/ReceivedCheckDetailSummary'
const CurrencyFormat: any = require('react-currency-format')

interface ICheckDetailsModal extends DialogProps {
  close: (reload: boolean) => void
  checkDetail: ReceivedCheckDetailSummary
  title: string
  dialogIcon: React.JSX.Element
  isUnappliedHistory: boolean
}

class CheckDetailsModal extends React.Component<ICheckDetailsModal> {
  private mapGridData = () => {
    if (this.props.checkDetail.checkAppliedFundsDetail?.length == 0) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={8} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.props.checkDetail!.checkAppliedFundsDetail.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          {this.props.isUnappliedHistory ? (
            <TableCell align="right">{record.unapplyRun}</TableCell>
          ) : (
            <></>
          )}
          <TableCell align="right">
            {record.appliedOn ? formatDate(record.appliedOn.toString()) : ''}
          </TableCell>
          <TableCell>{record.appliedBy}</TableCell>
          <TableCell>{record.legacyEOCDisplay}</TableCell>
          <TableCell>{record.patientName}</TableCell>
          <TableCell>{record.providerGroup}</TableCell>
          <TableCell
            align="right"
            style={{
              color: record.appliedAmount < 0 ? 'red' : 'black',
            }}
          >
            {record.appliedAmount < 0 ? (
              <CurrencyFormat
                value={record.appliedAmount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'($'}
                suffix={')'}
                allowNegative={false}
                fixedDecimalScale={true}
                decimalScale={2}
              />
            ) : (
              formatCurrency(record.appliedAmount.toString())
            )}
          </TableCell>
        </TableRow>
      ))
    }
  }

  public render() {
    const { close, checkDetail, title, isUnappliedHistory, ...modalProps } = this.props
    const gridTotal = this.props
      .checkDetail!.checkAppliedFundsDetail!.map((x) => Number(x.appliedAmount) || 0)
      .reduce((a, b) => a! + b!, 0)
    const remainingFunds = checkDetail.checkTotal - Math.abs(gridTotal)
    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={() => close(false)}
        {...modalProps}
        disableBackdropClick
      >
        <ResponsiveAppBar
          title={`${title} - Check Number: ${checkDetail.checkNumber}
                        , Check Date: ${
                          checkDetail.checkDate
                            ? formatDate(checkDetail.checkDate.toString())
                            : ''
                        }
                        , Check Amount: ${formatCurrency(
                          checkDetail.checkTotal.toString()
                        )}`}
          pageIcon={this.props.dialogIcon}
          isModal
        />
        <DialogContent style={{ marginTop: 10 }}>
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={2} wrap="nowrap" alignItems="baseline">
              <strong>Payer: </strong>&nbsp;&nbsp;
              {this.props.checkDetail.payer ? this.props.checkDetail.payer : 'N/A'}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Refund Check?: </strong>&nbsp;&nbsp;
              {this.props.checkDetail.refund ? 'YES' : 'NO'}
              &nbsp;&nbsp;
            </Grid>
            <Grid item>
              <Table aria-label="simple table" style={{ padding: '20px' }}>
                <TableHead>
                  <TableRow className="gridPadding">
                    {this.props.isUnappliedHistory ? (
                      <TableCell align="right">Unapply Run</TableCell>
                    ) : (
                      <></>
                    )}
                    <TableCell align="right">
                      {isUnappliedHistory ? 'Unapplied On' : 'Applied On'}
                    </TableCell>
                    <TableCell>
                      {isUnappliedHistory ? 'Unapplied By' : 'Applied By'}
                    </TableCell>
                    <TableCell>Legacy/EOC</TableCell>
                    <TableCell>Patient</TableCell>
                    <TableCell>Provider Group</TableCell>
                    <TableCell align="right">
                      {isUnappliedHistory ? 'Unapplied Amount' : 'Applied Amount'}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '10%' }}>
                  {this.mapGridData()}
                  {!isUnappliedHistory && (
                    <>
                      <TableRow>
                        <TableCell colSpan={5} align="right">
                          <strong>TOTAL APPLIED:&nbsp;&nbsp;</strong>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color: checkDetail.refund ? 'red' : 'black',
                          }}
                        >
                          {checkDetail.refund ? (
                            <CurrencyFormat
                              value={gridTotal}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'($'}
                              suffix={')'}
                              allowNegative={false}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          ) : (
                            formatCurrency(gridTotal!.toString())
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={5} align="right">
                          <strong>REMAINING FUNDS:&nbsp;&nbsp;</strong>
                        </TableCell>
                        <TableCell align="right">
                          <strong>
                            <CurrencyFormat
                              value={remainingFunds}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                              allowNegative={false}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          </strong>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button onClick={() => close(false)}>Close</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default CheckDetailsModal
