import { Button, Grid, Typography } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { Field, FormikActions, getIn } from 'formik'
import { inject } from 'mobx-react'
import moment from 'moment'
import * as React from 'react'
import DateTimePickerField from '../common/DateTimePickerField/DateTimePickerField'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import InterceptCheckboxWithLabel from '../common/InterceptCheckboxWithLabel'
import { IScheduleAppointmentTask, IUser } from '../Definitions'
import { IStores } from '../Stores'
import {
  createIScheduleAppointmentTaskFormValues,
  IScheduleAppointmentTaskFormValues,
  ScheduleAppointmentTaskSchema,
  toScheduleAppointmentTask,
} from './ScheduleAppointmentTaskFormValues'

interface IScheduleAppointmentTaskDialogProps {
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean
  saveScheduleAppointmentTask?: (
    scheduleAppointmentTask: IScheduleAppointmentTask
  ) => Promise<void>
  scheduleAppointmentTask?: IScheduleAppointmentTask
  user?: IUser
}

class ScheduleAppointmentTaskDialog extends React.Component<IScheduleAppointmentTaskDialogProps> {
  public save = async (
    values: IScheduleAppointmentTaskFormValues,
    formikBag: FormikActions<IScheduleAppointmentTaskFormValues>
  ) => {
    if (values.dateCompleted) {
      values.dateCompleted = new Date(values.dateCompleted)
    }
    const scheduleAppointmentTask = toScheduleAppointmentTask(
      values,
      this.props.scheduleAppointmentTask
    )
    scheduleAppointmentTask.updatedByUserId = this.props.user!.id
    this.props.saveScheduleAppointmentTask!(scheduleAppointmentTask).finally(() =>
      formikBag.setSubmitting(false)
    )
  }

  public render() {
    const { close, isLoading, isOpen, scheduleAppointmentTask } = this.props
    const hasValue = (field: string, values: IScheduleAppointmentTaskFormValues) => {
      const value = getIn(values, field)

      return !!value
    }

    const lastUpdated = scheduleAppointmentTask!.updatedByUser
      ? `Last updated by ${scheduleAppointmentTask!.updatedByUser!.name} on ${moment(
          scheduleAppointmentTask!.modifiedOn
        ).format('MM/DD/YYYY')} at ${moment(scheduleAppointmentTask!.modifiedOn).format(
          'hh:mm A'
        )}`
      : ''

    const timingNotice = scheduleAppointmentTask
      ? scheduleAppointmentTask.days !== 0
        ? `This task should be completed by ${
            scheduleAppointmentTask.schedule!.appointmentDate
              ? moment(scheduleAppointmentTask.dueDate).format('MM/DD/YYYY')
              : 'TBD'
          }.`
        : 'This task does not have a due date.'
      : ''
    return (
      <>
        {scheduleAppointmentTask ? (
          <ESDialog
            close={close!}
            initialValues={createIScheduleAppointmentTaskFormValues(
              scheduleAppointmentTask
            )}
            isLoading={isLoading}
            open={isOpen!}
            save={this.save}
            title={'Appointment Task'}
            titleIcon={<CheckCircle />}
            validationSchema={ScheduleAppointmentTaskSchema}
          >
            {({ values, errors, setFieldValue }) => (
              <Grid container direction="column" spacing={3}>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    style={{
                      paddingTop: '12px',
                      paddingBottom: '12px',
                    }}
                  >
                    {scheduleAppointmentTask.name}
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    marginLeft: '12px',
                    marginBottom: '10px',
                    paddingBottom: '12px',
                  }}
                >
                  {scheduleAppointmentTask.timing !== '' ? (
                    <i style={{ padding: '0px 0px' }}>{timingNotice}</i>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={3}>
                      <DateTimePickerField
                        name="dateCompleted"
                        label="Completed"
                        inputId="dateCompleted"
                        fullWidth={true}
                        clearable={true}
                        shrink={hasValue('dateCompleted', values)}
                        variant="outlined"
                        errorMessage={errors.dateCompleted}
                        disabled={values.taskNotApplicable}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ marginTop: '8px' }}
                        onClick={this.handleMarkAsCompleteClicked(setFieldValue)}
                      >
                        Mark as Complete
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="taskNotApplicable"
                        Label={{
                          label: 'This task is not applicable for this appointment',
                        }}
                        component={InterceptCheckboxWithLabel}
                        onChange={this.onTaskNotApplicableChange(setFieldValue)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FullWidthField
                    shrink={hasValue('comments', values)}
                    name="comments"
                    label="Comments"
                    rows="6"
                    multiline={true}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  style={{
                    marginLeft: '16px',
                    marginTop: '10px',
                    fontStyle: 'italics',
                  }}
                >
                  <span>{lastUpdated}</span>
                </Grid>
              </Grid>
            )}
          </ESDialog>
        ) : (
          <></>
        )}
      </>
    )
  }

  public onTaskNotApplicableChange =
    (setFieldValue: (name: string, value: any) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setFieldValue('dateCompleted', undefined)
      }
    }
  public handleMarkAsCompleteClicked =
    (setFieldValue: (name: string, value: any) => void) => () => {
      setFieldValue('dateCompleted', new Date())
    }
}

const InjectedScheduleAppointmentTaskDialog = inject<
  IStores,
  IScheduleAppointmentTaskDialogProps,
  Partial<IScheduleAppointmentTaskDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.scheduleAppointmentTasks.closeDialog,
  isLoading: stores.scheduleAppointmentTasks.isLoading,
  isOpen: stores.scheduleAppointmentTasks.isModalOpen,
  saveScheduleAppointmentTask:
    stores.scheduleAppointmentTasks.saveScheduleAppointmentTask,
  scheduleAppointmentTask:
    stores.scheduleAppointmentTasks.selectedScheduleAppointmentTask,
  user: stores.global.user,
}))(ScheduleAppointmentTaskDialog)

export default InjectedScheduleAppointmentTaskDialog
