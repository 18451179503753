import {
  Button,
  CircularProgress,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  Grid,
  TextField,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import {
  ArrowDropUp,
  AttachMoney,
  InsertDriveFileOutlined,
  LocalAtmRounded,
  ReceiptOutlined,
  RecordVoiceOver,
} from '@material-ui/icons'
import { Form } from 'formik'
import { inject } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import TitleBar from '../common/TitleBar/TitleBar'
import {
  DefaultCMS1500,
  ICMS1500,
  IEpisodeOfCare,
  ILocation,
  IPatient,
  IProvider,
  ISchedule,
  IUser,
} from '../Definitions'
import { IProviderDto } from '../generated_client'
import { CMS1500DiagnosisDto } from 'src/viewModels/claim/CMS1500DiagnosisDto'
import { CMS1500LineItemDto } from 'src/viewModels/claim/CMS1500LineItemDto'
import { IStores } from '../Stores'
import 'src/ReceiveClaim/ClaimForm.css'
import AppealNameConstants from 'src/constants/AppealNameConstants'
import { RouteComponentProps } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ViewProviderGroupContractModal from 'src/ReceiveClaim/ViewProviderGroupContractModal'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import ClaimDocumentButton from 'src/ReceiveClaim/ClaimDocumentButton'
import LineItemContractModal from 'src/ReceiveClaim/LineItemContractModal'
import GeneratePDFDocumentButton from 'src/ReceiveClaim/GeneratePDFDocumentButton'
import ActionLogModal from 'src/workflow/ActionLogModal'
import { InboxType } from 'src/utils/InboxType'
import QuoteTypeNameConstants from 'src/constants/QuoteTypeNameConstants'
import ReferralContactModal from 'src/patients/EpisodesOfCare/ReferralContactModal'

const styles = ({ spacing }: Theme) =>
  createStyles({
    bottomItem: {
      bottom: 0,
      position: 'absolute',
    },
    bottomItemParent: {
      position: 'relative',
    },
    dateTo: {
      marginTop: 'auto',
    },
    form: {},
    radioExtraText: {
      fontSize: '10pt',
    },
    radioExtraTextBold: {
      fontStyle: 'italic',
      fontWeight: 'bold',
    },
    radioLabel: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    radioOption: {
      marginRight: '14px',
    },
    smallDateField: {
      marginTop: 'auto',
    },
    topOfPage: {
      marginTop: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
    topSpacing: {
      marginTop: '10px',
    },
  })

interface IReceiveClaimParams {
  episodeOfCareId: string
  quoteId: string
  claimId: string
}

interface IViewClaimProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IReceiveClaimParams> {
  loadClaim?: (claimId: string) => Promise<void>
  selectedCMS1500?: ICMS1500
  selectedLocation?: ILocation
  selectedPatient?: IPatient
  selectedProvider?: IProvider
  selectedSchedule?: ISchedule
  setSelectedCMS1500?: (cms1500: ICMS1500 | undefined) => void
  setSelectedLocation?: (location?: ILocation) => void
  setSelectedProvider?: (provider?: IProvider) => void
  showContractModal?: (provider: IProviderDto | undefined) => void
  loadEpisodeOfCare?: (episodeOfCareId: string) => Promise<void>
  selectedEpisodeOfCare?: IEpisodeOfCare
  setSelectedEpisodeOfCare?: (episodeOfCare: IEpisodeOfCare | undefined) => void
  setSelectedSchedule?: (schedule?: ISchedule) => void
  setReturn?: () => void
  closeTab?: () => void
  workflowStyling?: boolean
  openContractModal?: (index: number) => void
  contractModalIsOpen?: boolean
  user?: IUser
  billoutButton?: React.JSX.Element
  isReferralContactModalOpen?: boolean
  openReferralContactModal?: () => void
}

class ViewClaim extends React.Component<IViewClaimProps> {
  constructor(props: IViewClaimProps) {
    super(props)
  }

  public state = {
    surgeryDate: undefined,
    type: undefined,
    showContractModal: false,
    showPdfButton: false,
    actionLogIsOpen: false,
    isLoading: false,
  }

  public componentDidMount() {
    this.setup()
  }

  private async setup() {
    const { match, loadEpisodeOfCare, loadClaim } = this.props
    const promises = [] as Array<Promise<void>>

    if (match && match.params.episodeOfCareId) {
      promises.push(loadEpisodeOfCare!(match.params.episodeOfCareId))
    }

    if (match && match.params.claimId) {
      promises.push(loadClaim!(match.params.claimId))
    } else {
      this.props.setSelectedCMS1500!(DefaultCMS1500(true))
    }

    await Promise.all(promises).then(() => {
      if (
        this.props.selectedCMS1500 &&
        !this.props.selectedCMS1500!.isNew &&
        this.props.selectedCMS1500!.claimEvents!.length > 0
      ) {
        const surgeryComponentId = '9c2b71e7-0898-4d8f-b40c-c197d325866a'
        const surgeryComponent = this.props.selectedEpisodeOfCare?.schedules?.find(
          (x) => x.componentId == surgeryComponentId && x.isActive && !x.rescheduled
        )

        this.props.setSelectedSchedule!(
          this.props.selectedCMS1500!.claimEvents![0]!.schedule as unknown as ISchedule
        )
        this.props.setSelectedLocation!(
          this.props.selectedCMS1500!.claimEvents![0]!.location as unknown as ILocation
        )

        var type = this.props.selectedLocation?.providerTypes?.find(
          (x) => x.id == this.props.selectedCMS1500?.providerTypeId
        )?.name

        var allowedUserRoles =
          process.env.REACT_APP_PAID_CONTRACT_AMOUNT_GROUPS?.split(',')
        var userAllowed = this.props.user?.roles.some(
          (x) => allowedUserRoles?.some((role) => role == x)
        )
        var isWorkflowAdmin =
          this.props.user?.roles.some((x) => x == 'Workflow Admin') || false

        this.setState({
          hasSelectedSchedule: true,
          hasSelectedLocation: true,
          surgeryDate: surgeryComponent?.appointmentDate,
          type: type,
          showContractModal: userAllowed,
          showPdfButton: isWorkflowAdmin,
        })
      }
    })
  }

  componentWillUnmount(): void {
    this.props.setSelectedCMS1500!(undefined)
    this.props.setSelectedEpisodeOfCare!(undefined)
    this.props.setSelectedLocation!(undefined)
  }

  private cancel = () => {
    this.props.setReturn!()
    this.props.closeTab!()
  }

  public getLinkPath() {
    return (
      '/patients/patient/' +
      this.props.selectedEpisodeOfCare?.referral?.patientId +
      '/referral/' +
      this.props.selectedEpisodeOfCare?.referralId +
      '/episodeOfCare/' +
      this.props.selectedEpisodeOfCare?.id
    )
  }

  private openContractModal(index: number) {
    this.props.openContractModal!(index)
  }

  public render() {
    const { showContractModal } = this.props

    const { selectedEpisodeOfCare, selectedCMS1500, workflowStyling } = this.props

    const surgeryComponentId = '9c2b71e7-0898-4d8f-b40c-c197d325866a'

    const surgeryComponent = selectedEpisodeOfCare?.schedules?.find(
      (x) => x.componentId == surgeryComponentId && x.isActive && !x.rescheduled
    )

    return selectedEpisodeOfCare == undefined || selectedCMS1500 == undefined ? (
      <Grid container direction="column" justifyContent="center">
        <Grid item container direction="row" justifyContent="center">
          <h1>Loading...</h1>
        </Grid>
        <Grid item container direction="row" justifyContent="center">
          <CircularProgress size={64} />
        </Grid>
      </Grid>
    ) : (
      <>
        {workflowStyling != true && (
          <Helmet>
            <title>{`View Claim: ${selectedEpisodeOfCare?.referral!.patient!
              .lastName}, ${selectedEpisodeOfCare?.referral!.patient!
              .firstName} (${moment(
              selectedEpisodeOfCare?.referral!.patient!.dateOfBirth
            ).format(
              'MM/DD/YYYY'
            )}) ${selectedEpisodeOfCare?.episodeOfCareNumberOrLegacyId}`}</title>
          </Helmet>
        )}
        <ViewProviderGroupContractModal />
        {this.state.actionLogIsOpen && (
          <ActionLogModal
            closeModal={() => this.setState({ actionLogIsOpen: false })}
            isOpen={this.state.actionLogIsOpen}
            associatedItemId={selectedCMS1500.id}
            inboxType={InboxType.Claim}
          />
        )}
        {selectedEpisodeOfCare && selectedCMS1500 && (
          <>
            {this.props.contractModalIsOpen && (
              <LineItemContractModal
                hideButton={true}
                selectedCMS1500={selectedCMS1500}
              />
            )}
            <Form>
              {workflowStyling != true && (
                <ResponsiveAppBar title="View Claim" pageIcon={<AttachMoney />}>
                  <Grid container direction="row" spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button size="small" variant="contained" onClick={this.cancel}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </ResponsiveAppBar>
              )}
            </Form>
          </>
        )}
        {this.props.selectedCMS1500?.appealType !== undefined ? (
          <Grid item style={{ paddingLeft: 8, paddingRight: 8 }}>
            <Toolbar
              style={{
                backgroundColor: '#d32f2f',
                color: 'white',
                minHeight: '30px',
                textAlign: 'center',
              }}
            >
              <Typography variant="h4" color="inherit" style={{ width: '100%' }}>
                APPEAL
              </Typography>
            </Toolbar>
          </Grid>
        ) : (
          <></>
        )}
        <Grid style={{ backgroundColor: '#FAFAFA' }}>
          {this.state.isLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '2',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          {this.props.isReferralContactModalOpen && (
            <ReferralContactModal
              selectedReferralId={this.props.selectedEpisodeOfCare?.referralId!}
            />
          )}
          <Grid item>
            <Grid container item style={{ padding: 8 }}>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ArrowDropUp />}>
                  <TitleBar
                    title={
                      'General - ' +
                      (this.props.selectedCMS1500?.billStatus == 'New'
                        ? 'RECEIVED'
                        : this.props.selectedCMS1500?.billStatus
                          ? this.props.selectedCMS1500?.billStatus.toUpperCase()
                          : '[UNKNOWN]')
                    }
                    icon={AttachMoney}
                    titleButton={
                      workflowStyling != true ? (
                        <Tooltip title="View Action Log">
                          <ReceiptOutlined
                            fontSize="small"
                            style={{ cursor: 'pointer', marginRight: 'auto' }}
                            onClick={(event) => {
                              event.stopPropagation()
                              this.setState({ actionLogIsOpen: true })
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <></>
                      )
                    }
                    titleComponent={
                      <div style={{ paddingTop: '8px', paddingLeft: '8px' }}>
                        {this.props.billoutButton}
                        {this.state.showPdfButton && (
                          <GeneratePDFDocumentButton
                            claimId={selectedCMS1500.id}
                            disabled={false}
                            setIsLoading={(loading: boolean) =>
                              this.setState({ isLoading: loading })
                            }
                          />
                        )}
                        <ClaimDocumentButton
                          docId={this.props.selectedCMS1500?.stagedDocumentId ?? ''}
                          setIsLoading={(loading: boolean) =>
                            this.setState({ isLoading: loading })
                          }
                        />
                      </div>
                    }
                    isTitleComponentPushedRight={true}
                  />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container item spacing={1}>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item>
                        <strong>Patient Name:</strong>&nbsp;
                        {this.props.selectedEpisodeOfCare
                          ? this.props.selectedEpisodeOfCare.referral!.patient!
                              .firstName +
                            ' ' +
                            this.props.selectedEpisodeOfCare.referral!.patient!.lastName
                          : 'undefined'}
                      </Grid>
                      <Grid
                        item
                        container
                        spacing={1}
                        xs={1}
                        style={{ minWidth: 'fit-content' }}
                      >
                        <Grid item>
                          <strong>EOC/Legacy:</strong>
                        </Grid>
                        <Grid item>
                          <div
                            style={{
                              color: 'darkblue',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              sessionStorage.setItem('patientTab', '0')
                              sessionStorage.setItem('eocTab', '1')
                              window.open(this.getLinkPath())
                            }}
                          >
                            {this.props.selectedEpisodeOfCare
                              ? this.props.selectedEpisodeOfCare
                                  .episodeOfCareNumberOrLegacyId
                              : 'undefined'}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Show Referral Contact">
                          <RecordVoiceOver
                            fontSize="small"
                            color={'primary'}
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={this.props.openReferralContactModal}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <strong> Group #:</strong>&nbsp;
                        {this.props.selectedCMS1500
                          ? this.props.selectedCMS1500.groupNumber
                            ? this.props.selectedCMS1500.groupNumber
                            : 'TBD'
                          : 'TBD'}
                      </Grid>
                      <Grid item>
                        <strong>Body Part:</strong>&nbsp;
                        {this.props.selectedSchedule?.bodyParts != undefined
                          ? this.props.selectedSchedule!.bodyParts
                          : this.props.selectedEpisodeOfCare
                            ? this.props.selectedEpisodeOfCare!.bodyPartText
                            : ''}
                      </Grid>
                      <Grid item>
                        {this.props.selectedCMS1500?.appealType != undefined ? (
                          <Typography noWrap variant="body2" color="error">
                            <strong>Appeal Type:</strong>&nbsp;
                            {AppealNameConstants[this.props.selectedCMS1500?.appealType]}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item>
                        <strong>ToC Date:</strong>&nbsp;
                        {this.props.selectedEpisodeOfCare != undefined &&
                        this.props.selectedEpisodeOfCare.effectiveDate != undefined
                          ? moment(this.props.selectedEpisodeOfCare.effectiveDate).format(
                              'L'
                            )
                          : 'N/A'}
                      </Grid>
                      <Grid item>
                        <strong>Bundle Type:</strong>&nbsp;
                        {this.props.selectedEpisodeOfCare != undefined &&
                        this.props.selectedEpisodeOfCare.quote != undefined &&
                        this.props.selectedEpisodeOfCare.quote.type != undefined
                          ? QuoteTypeNameConstants[
                              this.props.selectedEpisodeOfCare.quote.type
                            ]
                          : 'N/A'}
                      </Grid>
                      <Grid item>
                        <strong>Surgery Date:</strong>&nbsp;
                        {surgeryComponent?.appointmentDate
                          ? moment(surgeryComponent.appointmentDate).format('L')
                          : 'N/A'}
                      </Grid>
                      <Grid item>
                        <strong># of Days:</strong>&nbsp;
                        {this.props.selectedEpisodeOfCare != undefined &&
                        this.props.selectedEpisodeOfCare.quote != undefined &&
                        this.props.selectedEpisodeOfCare.quote.bundleDurationDays !=
                          undefined
                          ? this.props.selectedEpisodeOfCare.quote.bundleDurationDays
                          : 'N/A'}
                      </Grid>
                      <Grid item>
                        <strong>End Date:</strong>&nbsp;
                        {this.props.selectedEpisodeOfCare != undefined &&
                        this.props.selectedEpisodeOfCare.quote != undefined &&
                        this.props.selectedEpisodeOfCare.quote.endDate != undefined
                          ? moment(this.props.selectedEpisodeOfCare.quote.endDate).format(
                              'L'
                            )
                          : 'N/A'}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      spacing={2}
                      xs={12}
                      style={{ paddingBottom: '34px' }}
                    >
                      <Grid item>
                        <strong>Date of Service:</strong>&nbsp;
                        {moment(this.props.selectedCMS1500?.dateOfService).format('L')}
                      </Grid>
                      <Grid item>
                        <strong>Provider Group:</strong>&nbsp;
                        <span style={{ paddingRight: '4px' }}>
                          {this.props.selectedCMS1500?.providerName}
                        </span>
                        <Tooltip title="View Provider Contract Summary">
                          <InsertDriveFileOutlined
                            style={{
                              cursor: 'pointer',
                              fontSize: '16px',
                            }}
                            onClick={() => {
                              showContractModal!(this.props.selectedCMS1500?.provider)
                            }}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <strong>Provider Location:</strong>&nbsp;
                        {this.props.selectedCMS1500?.q32LocationInfo ??
                          '{UNKNOWN PROVIDER LOCATION}'}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ paddingBottom: 12 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="Provider Type"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ style: { padding: '8px' } }}
                          name="providerTypeId"
                          value={this.state.type}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: '3px' }}>
                        <FormControl fullWidth>
                          <TextField
                            inputProps={{
                              id: 'incomingOrOutgoingDate',
                              style: { padding: '8px' },
                            }}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            name="incomingOrOutgoingDate"
                            variant="standard"
                            value={moment(
                              this.props.selectedCMS1500?.incomingOrOutgoingDate
                            ).format('yyyy-MM-DD')}
                            label="Date Received"
                            fullWidth
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="column"
                        xs={2}
                        style={{ marginTop: '3px' }}
                      >
                        <Grid item>
                          <TextField
                            inputProps={{
                              id: 'dueDate',
                              style: { padding: '8px' },
                            }}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            name="dueDate"
                            variant="standard"
                            value={moment(this.props.selectedCMS1500?.dueDate).format(
                              'yyyy-MM-DD'
                            )}
                            label="Date Due"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        xs={2}
                        style={{
                          marginRight: '10px',
                          marginTop: '11px',
                          paddingLeft: '8px',
                        }}
                      >
                        <Grid item>
                          <TextField
                            name="q28TotalCharge"
                            label="Total Charge"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            value={this.props.selectedCMS1500?.q28TotalCharge?.toFixed(2)}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container style={{ padding: 8 }}>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ArrowDropUp />}>
                  <TitleBar title={'Box 21'} icon={AttachMoney} />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid item style={{ position: 'relative' }}>
                    <p style={{ bottom: 0, margin: 0, position: 'static' }}>
                      Diagnosis or Nature of Illness or Injury (Relate A-L to service line
                      below) *
                    </p>
                    <Grid item container direction="row" xs={12}>
                      <>
                        {this.props.selectedCMS1500?.q21Diagnosis?.map(
                          (diagnosis: CMS1500DiagnosisDto, index: number) => (
                            <Grid item xs={3} key={index}>
                              <TextField
                                name={`q21Diagnosis.${index}.code`}
                                label={diagnosis.pointer}
                                value={diagnosis.code}
                                disabled
                                fullWidth
                              />
                            </Grid>
                          )
                        )}
                      </>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container style={{ padding: 8 }}>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ArrowDropUp />}>
                  <TitleBar title={'Box 24'} icon={AttachMoney} />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>{this.renderBox24()}</ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  private renderBox24 = () => {
    return (
      <>
        <Grid container direction="column" spacing={1}>
          {this.props.selectedCMS1500?.q24?.length == 0 ? (
            <Grid container direction="row" justifyContent="space-evenly">
              <Grid item container spacing={1} xs={3}>
                <Grid item xs={7}>
                  <TextField
                    name={'aDateOfServiceFrom'}
                    label="Date of Service"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Place of Service"
                    name={'bPlaceOfServiceTo'}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} xs={3}>
                <Grid item container spacing={1} xs={9}>
                  <Grid item xs={4}>
                    <TextField
                      name={'dProcedureCptHcpcs'}
                      label="CPT/HCPCS"
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name={'dModifier1'}
                      label={'MODIFIER'}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name={'dModifier2'}
                      label=" "
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name={'dModifier3'}
                      label=" "
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name={'dModifier4'}
                      label=" "
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name={'eDiagnosisPointer'}
                    label={'Diagnosis Pointer'}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} xs={5}>
                <Grid item xs={2}>
                  <TextField
                    name={'fCharges'}
                    label="Charges"
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name={'contractAmount'}
                    label={'Contract Amount'}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name={'gDaysOrUnits'}
                    label="Quantity"
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name={'iNpi'}
                    label={'NPI'}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {this.props.selectedCMS1500?.q24?.map((_lineItem: CMS1500LineItemDto, idx) => (
            <Grid container direction="row" justifyContent="space-evenly">
              <Grid item container spacing={1} xs={3}>
                <Grid item xs={7}>
                  <TextField
                    name={'aDateOfServiceFrom'}
                    label="Date of Service"
                    value={
                      _lineItem.aDateOfServiceFrom
                        ? moment(_lineItem.aDateOfServiceFrom).format('L')
                        : ''
                    }
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Place of Service"
                    name={'bPlaceOfServiceTo'}
                    InputLabelProps={{ shrink: true }}
                    value={_lineItem.bPlaceOfServiceTo}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} xs={3}>
                <Grid item container spacing={1} xs={9}>
                  <Grid item xs={4}>
                    <TextField
                      name={'dProcedureCptHcpcs'}
                      label="CPT/HCPCS"
                      InputLabelProps={{ shrink: true }}
                      value={_lineItem.dProcedureCptHcpcs}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name={'dModifier1'}
                      label={'MODIFIER'}
                      InputLabelProps={{ shrink: true }}
                      value={_lineItem.dModifier1}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name={'dModifier2'}
                      label=" "
                      InputLabelProps={{ shrink: true }}
                      value={_lineItem.dModifier2}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name={'dModifier3'}
                      label=" "
                      InputLabelProps={{ shrink: true }}
                      value={_lineItem.dModifier3}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name={'dModifier4'}
                      label=" "
                      InputLabelProps={{ shrink: true }}
                      value={_lineItem.dModifier4}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name={'eDiagnosisPointer'}
                    label={'Diagnosis Pointer'}
                    InputLabelProps={{ shrink: true }}
                    value={_lineItem.eDiagnosisPointer}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} xs={5}>
                <Grid item xs={2}>
                  <TextField
                    name={'fCharges'}
                    label="Charges"
                    InputLabelProps={{ shrink: true }}
                    value={_lineItem.fCharges?.toFixed(2)}
                    disabled
                  />
                </Grid>
                <Grid item xs={2} style={{ paddingRight: '0px' }}>
                  <TextField
                    name={'contractAmount'}
                    label={'Contract Amount'}
                    InputLabelProps={{ shrink: true }}
                    value={_lineItem.contractAmount?.toFixed(2)}
                    disabled
                  />
                </Grid>
                {this.state.showContractModal && (
                  <Grid
                    item
                    style={{
                      paddingRight: '10px',
                      paddingTop: '20px',
                      alignItems: 'flex-start',
                      display: 'flex',
                    }}
                  >
                    <Tooltip title="Choose Historical Contract Amount">
                      <LocalAtmRounded
                        fontSize="large"
                        color={
                          !(
                            this.props.selectedCMS1500?.providerId &&
                            this.props.selectedCMS1500?.providerTypeId &&
                            _lineItem.dProcedureCptHcpcs
                          )
                            ? 'disabled'
                            : 'primary'
                        }
                        style={{
                          cursor: !(
                            this.props.selectedCMS1500?.providerId &&
                            this.props.selectedCMS1500?.providerTypeId &&
                            _lineItem.dProcedureCptHcpcs
                          )
                            ? 'auto'
                            : 'pointer',
                        }}
                        onClick={
                          !(
                            this.props.selectedCMS1500?.providerId &&
                            this.props.selectedCMS1500?.providerTypeId &&
                            _lineItem.dProcedureCptHcpcs
                          )
                            ? () => {}
                            : () => {
                                this.openContractModal(idx)
                              }
                        }
                      />
                    </Tooltip>
                  </Grid>
                )}
                <Grid item xs={2}>
                  <TextField
                    name={'gDaysOrUnits'}
                    label="Quantity"
                    InputLabelProps={{ shrink: true }}
                    value={_lineItem.gDaysOrUnits}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name={'iNpi'}
                    label={'NPI'}
                    InputLabelProps={{ shrink: true }}
                    value={_lineItem.iNpi}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </>
    )
  }
}

const InjectedViewClaim = inject<IStores, IViewClaimProps, Partial<IViewClaimProps>, any>(
  (stores: IStores) => ({
    loadClaim: stores.cms1500.loadCMS1500,
    selectedCMS1500: stores.cms1500.selectedCMS1500,
    selectedLocation: stores.locations.selectedEditClaimLocation,
    selectedPatient: stores.patients.selectedPatient,
    selectedProvider: stores.providers.selectedProvider,
    selectedSchedule: stores.schedules.selectedSchedule,
    selectedEpisodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
    setSelectedCMS1500: stores.cms1500.setSelectedCMS1500,
    setSelectedLocation: stores.locations.setSelectedEditClaimLocation,
    setSelectedProvider: stores.providers.setSelectedEditClaimProvider,
    setSelectedSchedule: stores.schedules.setSelectedSchedule,
    loadEpisodeOfCare: stores.patientEdit.getEpisodeOfCareById,
    setSelectedEpisodeOfCare: stores.patientEdit.setSelectedEpisodeOfCare,
    showContractModal: stores.cms1500.openProviderGroupContractDialog,
    setReturn: stores.global.setReturn,
    closeTab: stores.global.closeTab,
    openContractModal: stores.cms1500.openContractModal,
    contractModalIsOpen: stores.cms1500.contractModalIsOpen,
    user: stores.global.user,
    openReferralContactModal: stores.patientEdit.openReferralContactModal,
    isReferralContactModalOpen: stores.patientEdit.isReferralContactModalOpen,
  })
)(ViewClaim)

export default withStyles(styles)(InjectedViewClaim)
