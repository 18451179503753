import { Grid } from '@material-ui/core'
import { Smartphone } from '@material-ui/icons'
import { FormikActions } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import { IPatient } from '../Definitions'
import { IStores } from '../Stores'
import * as Yup from 'yup'

const PatientSMSSchema = Yup.object().shape({
  contents: Yup.string().required('Message is Required'),
})

interface IPatientSMSFormValues {
  contents: string
}

interface IPatientSMSDialogProps {
  patient?: IPatient
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean
  loadCommunications?: () => void
  sendPatientSMS?: (patient: IPatient, contents: string) => Promise<void>
}

class PatientSMSDialog extends React.Component<IPatientSMSDialogProps> {
  public save = (
    values: IPatientSMSFormValues,
    formikBag: FormikActions<IPatientSMSFormValues>
  ) => {
    this.props.sendPatientSMS!(this.props.patient!, values.contents).finally(() => {
      formikBag.setSubmitting(false)
      this.props.loadCommunications!()
    })
  }

  public render() {
    const { close, isLoading, isOpen, patient } = this.props

    return (
      <ESDialog
        close={close!}
        initialValues={{ contents: '' } as IPatientSMSFormValues}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={'Text Patient'}
        validationSchema={PatientSMSSchema}
        maxWidth="sm"
        saveButtonText="Send Text"
        titleIcon={<Smartphone />}
      >
        {() => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <span>
                Text will be sent to {patient ? patient.name : ''} at{' '}
                {patient ? patient.cellPhone : ''}
              </span>
            </Grid>
            <Grid item xs={12}>
              <FullWidthField
                autoFocus
                name="contents"
                label="Patient Text Message"
                required
                variant="outlined"
                fullWidth
                rows={4}
                multiline
                disabled={patient ? !patient.allowTexting : false}
              />
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }
}

const InjectedPatientSMSDialog = inject<
  IStores,
  IPatientSMSDialogProps,
  Partial<IPatientSMSDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.patients.closePatientSMSDialog,
  isLoading: stores.patients.isSMSLoading,
  isOpen: stores.patients.isPatientSMSDialogOpen,
  loadCommunications: stores.communications.getColumnSettingsAndCommunications,
  patient: stores.patients.selectedPatient,
  sendPatientSMS: stores.patients.sendPatientSMS,
}))(PatientSMSDialog)

export default InjectedPatientSMSDialog
