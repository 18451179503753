import { FolderOpen, InsertDriveFile, PictureAsPdf } from '@material-ui/icons'
import ChatIcon from '@material-ui/icons/Chat'
import { inject } from 'mobx-react'
import React from 'react'
import { GetLegacySharedDrivePath, GetSharedDrivePath } from 'src/services/PatientService'
import { SharedDrivePathRequest } from 'src/viewModels/SharedDrivePathRequest'
import CommunicationsDialog from '../../Communications/CommunicationsDialog'
import EditCommunicationDialog from '../../Communications/EditCommunicationDialog'
import { ICommunication, IEpisodeOfCare, IPatient } from '../../Definitions'
import PatientSMSDialog from '../../patients/PatientSMSDialog'
import { IStores } from '../../Stores'
import ActionBar, { IActionBarItem } from './ActionBar'

interface IPatientActionBarProps {
  episodeOfCare?: IEpisodeOfCare
  closePatientSMSDialog?: () => void
  generatePatientDemographicsReport?: () => void
  isDemographicsVisible?: boolean
  isCommuicationEditModalOpen?: boolean
  isCommunicationOpen?: boolean
  isPatientSMSDialogOpen?: boolean
  loadFullCommunications?: () => Promise<void>
  openCommunicationDialog?: () => void
  openDialogWithCommunication?: (communication: ICommunication) => void
  openPatientSMSDialog?: () => void
  patient?: IPatient
}

class PatientActionBar extends React.Component<IPatientActionBarProps> {
  public openCommunicationsDialog = async () => {
    await this.props.loadFullCommunications!().then(() => {
      this.props.openCommunicationDialog!()
    })
  }

  public openDialog = (communication: ICommunication) => () => {
    if (this.props.patient && !this.props.patient.isNew) {
      this.props.openDialogWithCommunication!(communication)
    }
  }

  public render() {
    const { isDemographicsVisible = false, isCommunicationOpen } = this.props
    const patientSMSDialog = this.props.isPatientSMSDialogOpen && <PatientSMSDialog />
    const fullCommunicationDialog = (
      <CommunicationsDialog open={isCommunicationOpen!} close={close!} />
    )
    const communicationDialog = this.props.isCommuicationEditModalOpen && (
      <EditCommunicationDialog />
    )
    let actionBarItems: IActionBarItem[] = [
      {
        icon: <ChatIcon />,
        label: 'Comm Log',
        onClick: async () => {
          await this.props.loadFullCommunications!().then(() => {
            this.props.openCommunicationDialog!()
          })
        },
      },
      {
        icon: <InsertDriveFile />,
        label: 'Patient Docs',
        onClick: () => {
          window.open(`/patients/patient/${this.props.patient?.id}/documents`)
        },
      },
      {
        disabled: this.props.patient?.preCertNumber == undefined,
        icon: <FolderOpen />,
        label: 'Legacy Shared Drive',
        onClick: async () => {
          var request = new SharedDrivePathRequest()
          request.preCertNumber = this.props.patient!.preCertNumber
          request.patientFirst = this.props.patient!.firstName
          request.patientLast = this.props.patient!.lastName
          window.open(await GetLegacySharedDrivePath(request), '_blank')
        },
      },
      {
        icon: <FolderOpen />,
        label: 'Shared Drive',
        onClick: async () => {
          var request = new SharedDrivePathRequest()
          request.patientFirst = this.props.patient!.firstName
          request.patientLast = this.props.patient!.lastName
          window.open(
            await GetSharedDrivePath(this.props.patient!.id!, request),
            '_blank'
          )
        },
      },
    ]

    if (isDemographicsVisible) {
      actionBarItems = actionBarItems.concat([
        {
          icon: <PictureAsPdf />,
          label: 'Demographics',
          onClick: () => {
            this.props.generatePatientDemographicsReport!()
          },
        },
      ])
    }

    return (
      <>
        <ActionBar items={actionBarItems} patient={this.props.patient} />
        {patientSMSDialog}
        {fullCommunicationDialog}
        {communicationDialog}
      </>
    )
  }
}

const InjectedPatientActionBar = inject<
  IStores,
  IPatientActionBarProps,
  Partial<IPatientActionBarProps>,
  any
>((stores: IStores) => ({
  episodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  generatePatientDemographicsReport: stores.patientEdit.generatePatientDemographicsReport,
  isCommuicationEditModalOpen: stores.communications.isEditModalOpen,
  isCommunicationOpen: stores.communications.isModalOpen,
  isPatientSMSDialogOpen: stores.patients.isPatientSMSDialogOpen,
  loadFullCommunications: stores.communications.getColumnSettingsAndFullCommunications,
  openCommunicationDialog: stores.communications.openDialog,
  openDialogWithCommunication: stores.communications.openDialogWithCommunication,
  openPatientSMSDialog: stores.patients.openPatientSMSDialog,
  patient: stores.patients.selectedPatient,
}))(PatientActionBar)

export default InjectedPatientActionBar
