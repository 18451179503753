export interface IClaimHeaderDetailsSummaryResult {
  patientFirst: string
  patientLast: string
  dateOfBirth: Date
  providerTypeId: string
  providerType: string
  providerId: string
  providerName: string
  locationName: string
  episodeOfCareNotes: string
  billingNotes: string
  claimReceiveDate: string
  claimStatus: string
  eocLegacy: string
  insuranceCompany: string
}

export class ClaimHeaderDetailsSummaryResult implements IClaimHeaderDetailsSummaryResult {
  public patientFirst: string
  public patientLast: string
  public dateOfBirth: Date
  public providerTypeId: string
  public providerType: string
  public providerId: string
  public providerName: string
  public locationName: string
  public episodeOfCareNotes: string
  public billingNotes: string
  public claimReceiveDate: string
  public claimStatus: string
  public eocLegacy: string
  public insuranceCompany: string
}
