import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core/Checkbox'
import FormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@material-ui/core/FormControlLabel'
import { FieldProps } from 'formik'
import * as React from 'react'

interface ICheckboxProps
  extends FieldProps,
    Omit<MuiCheckboxProps, 'form' | 'defaultChecked' | 'name' | 'value'> {}

const fieldToCheckbox = ({
  field,
  form: { isSubmitting },
  disabled,
  ...props
}: ICheckboxProps): MuiCheckboxProps => {
  const onChange = (e: React.ChangeEvent<any>) => {
    if (props.onChange) {
      props.onChange(e, !props.checked)
    }
    field.onChange(e)
  }

  return {
    disabled: disabled !== undefined ? disabled : isSubmitting,
    ...props,
    ...field,
    checked: props.checked !== undefined ? props.checked : field.value,
    onChange,
    value: field.value ? 'checked' : '',
  }
}

interface ICheckboxWithLabelProps extends ICheckboxProps {
  Label: Omit<MuiFormControlLabelProps, 'name' | 'field' | 'value' | 'inputRef'>
}

const InterceptCheckboxWithLabel: React.ComponentType<ICheckboxWithLabelProps> = ({
  Label,
  ...props
}) => (
  <FormControlLabel {...Label} control={<MuiCheckbox {...fieldToCheckbox(props)} />} />
)

export default InterceptCheckboxWithLabel
