import { Button, Grid } from '@material-ui/core'
import { AddCircle, RemoveCircle } from '@material-ui/icons'
import { Field, FieldArray, FormikActions, FormikErrors } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import AddressField from '../common/AddressField/AddressField'
import { toAddressDto } from '../common/AddressField/AddressFormValues'
import CheckboxField from '../common/CheckboxField'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import InterceptCheckboxWithLabel from '../common/InterceptCheckboxWithLabel'
import PhoneNumberMask from '../common/PhoneNumberMask'
import SelectField from '../common/SelectField/SelectField'
import { ILocation, IProcedure } from '../Definitions'
import { AddressDto, ProviderTypeDto } from '../generated_client'
import LocationBillings from '../locationBillings/LocationBillings'
import { IStores } from '../Stores'
import * as Yup from 'yup'
import {
  createILocationFormValues,
  ILocationFormValues,
  toILocation,
  setDefaultValuesLocationProcedureFormValues,
} from './LocationFormValues'
import LocationLookupMapContainer from './LocationLookupMapContainer'
import { noWhitespaceString } from 'src/common/YupExtensions'
import { GetCoordinates } from 'src/services/LookupService'

interface ILocationDialogProps {
  close?: () => void
  getAllProcedures?: () => Promise<void>
  isLoading?: boolean
  isOpen?: boolean
  location?: ILocation
  procedures?: IProcedure[]
  providerTypes?: ProviderTypeDto[]
  saveLocation?: (location: ILocation) => Promise<void>
  setSelectedAddress?: (address?: AddressDto, load?: boolean) => Promise<void>
  activeBillingAddressCount?: number
  selectedAddress?: AddressDto | undefined
  resetCoordinates?: () => void
}

const LocationSchema = Yup.object().shape({
  email: Yup.string().email('Not a valid email'),
  faxNumber: Yup.string(),
  locationProcedures: Yup.array().of(
    Yup.object().shape({
      contractPrice: Yup.number()
        .transform(function (value, _originalValue) {
          return isNaN(value) ? null : this.isType(value) ? value : -1
        })
        .nullable(true)
        .min(0, 'Price must be 0 or greater')
        .max(1000000000000, 'Price must be 1000000000000 or less'),
      otherBilling: Yup.string(),
      procedureId: Yup.string().when('contractPrice', {
        is: (contractPrice) =>
          contractPrice !== null && contractPrice !== undefined && contractPrice !== '',
        otherwise: Yup.string(),
        then: Yup.string().required('Provider is required'),
      }),
      usesSecondProvider: Yup.boolean(),
    })
  ),
  name: Yup.string().required('Name is required'),
  phoneNumber: Yup.string(),
  physicalAddress: Yup.object({
    city: noWhitespaceString('City is required', true),
    line1: noWhitespaceString('Street address is required', true),
    line2: Yup.string(),
    state: noWhitespaceString('State is required', true),
    zip: noWhitespaceString('Zip code is required', true),
  }),
})
@observer
class LocationDialog extends React.Component<ILocationDialogProps> {
  public componentDidMount() {
    this.props.getAllProcedures!()
  }

  public state = {
    verified: undefined,
    previousValues: '',
    procedureError: false,
    saveAttempted: false,
  }

  public sameAddressName = (isSame: boolean) => {
    return isSame ? 'physicalAddress' : 'billingAddress'
  }

  public saveAsync = async (
    values: ILocationFormValues,
    locationToSave: ILocation,
    saveLocation: (location: ILocation) => Promise<void>
  ) => {
    locationToSave = await toILocation(values, locationToSave)
    return saveLocation!(locationToSave)
  }

  public save = (
    values: ILocationFormValues,
    formikBag: FormikActions<ILocationFormValues>
  ) => {
    this.setState({ saveAttempted: true })
    if (this.props.activeBillingAddressCount == 0 && !this.props.location!.isNew) {
      formikBag.setSubmitting(false)
    } else if (values.providerTypeIds.every((x) => x.checked != true)) {
      formikBag.setSubmitting(false)
    } else if (values.locationProcedures.some((x) => x.isActive == false)) {
      formikBag.setSubmitting(false)
      this.setState({ procedureError: true })
    } else {
      this.setState({ procedureError: false })
      this.saveAsync(values, this.props.location!, this.props.saveLocation!).finally(
        () => {
          formikBag.setSubmitting(false)
        }
      )
    }
  }

  private select = (values: ILocationFormValues) => async () => {
    if (values.physicalAddress) {
      var addressDto = await toAddressDto(values.physicalAddress)

      return GetCoordinates(addressDto).then((response: any) => {
        var val = response.result
        addressDto.latitude = val.latitude
        addressDto.longitude = val.longitude
        if (addressDto.longitude == undefined) {
          this.setState({ verified: false })
        } else {
          this.setState({ verified: true })
        }
        this.props.setSelectedAddress!(addressDto, false)
      })
    }
  }

  private toggleLocationRequiresSingleCaseAgreement =
    (setFieldValue: (field: string, values: any) => void, values: ILocationFormValues) =>
    (_event: React.MouseEvent<HTMLElement>) => {
      setFieldValue('requiresSingleCaseAgreement', !values.requiresSingleCaseAgreement)
    }

  public render() {
    const { close, isLoading, isOpen, location } = this.props

    return (
      <ESDialog
        close={close!}
        initialValues={createILocationFormValues(location, this.props.providerTypes)}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={location!.isNew ? 'Add New Location' : 'Edit Location'}
        validationSchema={LocationSchema}
        isInitialValid={location && !location.isNew}
        maxWidth="xl"
        disableSaveButton={
          this.props.selectedAddress?.longitude == undefined &&
          !(location?.verified && this.state.verified) &&
          !(location?.verified && location?.physicalAddress.longitude != undefined)
        }
      >
        {({ values, setFieldValue, errors }) => (
          <Grid container spacing={2} xs={12} style={{ marginTop: '12px' }}>
            <Grid item container spacing={2} direction="column" xs={6} wrap="nowrap">
              <Grid item xs={12}>
                <FullWidthField
                  autoFocus
                  name="name"
                  label="Location Name"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item container spacing={2} direction="row" xs={12}>
                <Grid item xs={6}>
                  <FullWidthField name="npi" label="NPI Number" variant="outlined" />
                </Grid>
                <Grid item xs={2}>
                  <a
                    href="https://npiregistry.cms.hhs.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lookup
                  </a>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <AddressField
                  name="physicalAddress"
                  label="Physical Address"
                  required={true}
                  showFax
                  verified={location?.verified || this.state.verified}
                  location={location}
                  resetCoordinates={this.props.resetCoordinates}
                  previousValues={this.state.previousValues}
                  setPrevious={(vals: string) => this.setState({ previousValues: vals })}
                  outlined
                />
              </Grid>
              <Grid item container direction="row" xs={12}>
                <Grid item container direction="row" justifyContent="flex-start" xs={12}>
                  <Grid item>
                    <Button
                      onClick={this.select(values)}
                      data-cy="close"
                      color="primary"
                      variant="contained"
                      disabled={
                        this.props.selectedAddress?.longitude != undefined ||
                        (location?.verified &&
                          location?.physicalAddress.longitude != undefined) ||
                        !(
                          values.physicalAddress?.line1 &&
                          values.physicalAddress.city &&
                          values.physicalAddress.state &&
                          values.physicalAddress.zip
                        )
                      }
                    >
                      Verify
                    </Button>
                  </Grid>
                  {this.state.verified == false ? (
                    <Grid style={{ paddingLeft: '15px', color: 'red' }}>
                      {' '}
                      Please enter a valid address.{' '}
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item container direction="column" justifyContent="flex-end" xs={12}>
                  <Grid item>
                    <LocationLookupMapContainer location={location} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} wrap="nowrap">
                <h4 style={{ marginBottom: '10px' }}>Request for Results</h4>
                <Grid item container direction="row" spacing={1} xs={12}>
                  <Grid item xs={4}>
                    <FullWidthField
                      name="phoneNumber"
                      label="Phone #"
                      InputProps={{ inputComponent: PhoneNumberMask }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FullWidthField
                      name="faxNumber"
                      label="Fax #"
                      InputProps={{ inputComponent: PhoneNumberMask }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FullWidthField
                      name="email"
                      label="Email Address"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container spacing={2} direction="column" xs={6} wrap="nowrap">
              <Grid item container spacing={2} direction="column">
                <Grid item container spacing={2} direction="row" xs={12}>
                  <Grid item spacing={1} />
                  <Grid item xs={12} spacing={2}>
                    <FullWidthField
                      name={'requiresSingleCaseAgreement'}
                      checked={values.requiresSingleCaseAgreement}
                      Label={{ label: 'Requires Single Case Agreement' }}
                      component={InterceptCheckboxWithLabel}
                      onClick={this.toggleLocationRequiresSingleCaseAgreement(
                        setFieldValue,
                        values
                      )}
                      variant="outlined"
                    />
                  </Grid>
                  <FieldArray name="providerTypeIds">
                    {() => (
                      <Grid item xs={12} direction="row">
                        {values.providerTypeIds.length > 0 &&
                          values.providerTypeIds.map((providerTypeId, index) => (
                            <FullWidthField
                              name={`providerTypeIds.${index}.checked`}
                              Label={{
                                label: providerTypeId.abbreviation,
                              }}
                              component={CheckboxWithLabel}
                              onClick={this.toggleProviderTypeCheckBox(
                                `providerTypeIds.${index}.checked`,
                                setFieldValue,
                                values.providerTypeIds[index].checked
                              )}
                            />
                          ))}
                      </Grid>
                    )}
                  </FieldArray>
                  <Grid item xs={12} spacing={2}>
                    <FullWidthField
                      variant="outlined"
                      multiline
                      rows={8}
                      name="providerLocationComments"
                      label="Provider Location Comments"
                    />
                  </Grid>
                  <Grid item xs={12} spacing={2}>
                    <FullWidthField
                      variant="outlined"
                      multiline
                      rows={8}
                      name="providerContractComments"
                      label="Provider Location Contract Comments"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <h3>Procedures Available at This Location</h3>
              </Grid>
              <Grid item>
                <Grid item container direction="column" xs={12}>
                  <Grid item container direction="row" xs={12} spacing={3}>
                    <Grid item xs={3}>
                      <span>Diagnostic Procedure</span>
                    </Grid>
                    <Grid item xs={1}>
                      <span>Tesla</span>
                    </Grid>
                    <Grid item xs={1}>
                      <span>Weight Limit (Lbs)</span>
                    </Grid>
                    <Grid item container xs={2}>
                      <Grid item xs={3} />
                      <Grid item xs={6}>
                        <span>Contract Price</span>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <span>Other Billing</span>
                    </Grid>
                    <Grid item xs={2}>
                      <span>Uses 2nd Provider</span>
                    </Grid>
                  </Grid>
                  <Grid>
                    {this.renderLocationProcedures(values, setFieldValue, errors)}
                  </Grid>
                  <Grid item justifyContent="flex-end" className="error">
                    {this.state.procedureError ? 'Please select an active procedure' : ''}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <LocationBillings />
            </Grid>
            <Grid container justifyContent="flex-end">
              {this.props.activeBillingAddressCount == 0 &&
                !this.props.location!.isNew && (
                  <span className="error">An active billing address is required.</span>
                )}
            </Grid>
            <Grid container justifyContent="flex-end">
              {this.props.selectedAddress?.longitude == undefined &&
                !(location?.verified && this.state.verified) &&
                !(
                  location?.verified && location?.physicalAddress.longitude != undefined
                ) && (
                  <span className="error">Physical address needs to be verified.</span>
                )}
            </Grid>
            <Grid container justifyContent="flex-end" className="error">
              {values.providerTypeIds.every((x) => x.checked != true) &&
              this.state.saveAttempted
                ? 'Provider Type is required.'
                : ''}
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }

  private renderLocationProcedures(
    values: ILocationFormValues,
    setFieldValue: (field: string, value: any) => void,
    errors: FormikErrors<ILocationFormValues>
  ) {
    const locationProcedures: any[] = []
    const totalLines = values.locationProcedures.length

    const { procedures } = this.props

    values.locationProcedures.forEach((__, lineNumber) => {
      const removeCircleStyle = {
        color: '#930A0A',
        cursor: 'pointer',
        fontSize: '30px',
      } as React.CSSProperties

      const addCircleStyle = {
        color: '#29348F',
        cursor: 'pointer',
        fontSize: '30px',
      } as React.CSSProperties

      locationProcedures.push(
        <Grid item container direction="row" xs={12} spacing={1}>
          <Grid item xs={3}>
            <SelectField
              inputId={`locationProcedures[${lineNumber}].procedureId`}
              getName={this.getName}
              getValue={this.getId}
              items={procedures}
              name={`locationProcedures[${lineNumber}].procedureId`}
              fullWidth
              required={
                !!values.locationProcedures[lineNumber].contractPrice !== undefined &&
                values.locationProcedures[lineNumber].contractPrice !== 0
              }
              errorMessage={
                errors.locationProcedures && errors.locationProcedures[lineNumber]
                  ? errors.locationProcedures[lineNumber]!.procedureId
                  : undefined
              }
              disableDuplicates={{
                comparator: (x) => x.procedureId,
                list: values.locationProcedures,
              }}
              disableInactive={true}
            />
          </Grid>
          <Grid item xs={1}>
            <FullWidthField
              name={`locationProcedures[${lineNumber}].tesla`}
              type="number"
            />
          </Grid>
          <Grid item xs={1}>
            <FullWidthField
              name={`locationProcedures[${lineNumber}].weightLimit`}
              type="number"
            />
          </Grid>
          <Grid item xs={2}>
            <FullWidthField
              name={`locationProcedures[${lineNumber}].contractPrice`}
              type="number"
            />
          </Grid>
          <Grid item xs={2}>
            <FullWidthField
              name={`locationProcedures[${lineNumber}].otherBilling`}
              type="string"
            />
          </Grid>
          <Grid item container xs={1}>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <CheckboxField
                name={`locationProcedures[${lineNumber}].usesSecondProvider`}
                label=""
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <RemoveCircle
              style={removeCircleStyle}
              onClick={this.removeLineItem(values, lineNumber, setFieldValue)}
            />
            {totalLines === 1 || (totalLines > 1 && lineNumber === totalLines - 1) ? (
              <AddCircle
                style={addCircleStyle}
                onClick={
                  lineNumber === totalLines - 1
                    ? this.addLine(values, setFieldValue)
                    : undefined
                }
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      )
    })
    return locationProcedures
  }

  private getName(procedure: IProcedure) {
    return procedure ? procedure.diagnostic! : ''
  }

  private getId(procedure: IProcedure) {
    return procedure ? procedure.id! : ''
  }

  private removeLineItem =
    (
      values: ILocationFormValues,
      lineIndex: number,
      setFieldValue: (field: string, value: any) => void
    ) =>
    () => {
      let locationProcedures = values.locationProcedures
      if (values.locationProcedures.length === 1 && lineIndex === 0) {
        locationProcedures = [setDefaultValuesLocationProcedureFormValues()]
      } else {
        locationProcedures.splice(lineIndex, 1)
      }
      setFieldValue('locationProcedures', locationProcedures)
    }

  private addLine =
    (values: ILocationFormValues, setFieldValue: (field: string, value: any) => void) =>
    () => {
      const newLineItem = setDefaultValuesLocationProcedureFormValues()
      const locationProcedures = values.locationProcedures
      setFieldValue('locationProcedures', locationProcedures.concat([newLineItem]))
    }

  public sameAddressCheckbox() {
    return (
      <Field
        name="isBillingAddressSameAsPhysicalAddress"
        Label={{
          label: 'Same As Physical Address',
        }}
        style={{
          height: '20px',
        }}
        component={CheckboxWithLabel}
      />
    )
  }

  private toggleProviderTypeCheckBox =
    (
      fieldId: string,
      setFieldValue: (field: string, value: any) => void,
      value: boolean
    ) =>
    (_event: React.MouseEvent<HTMLElement>) => {
      setFieldValue(fieldId, !value)
    }
}

const InjectedLocationDialog = inject<
  IStores,
  ILocationDialogProps,
  Partial<ILocationDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.locations.closeDialog,
  getAllProcedures: stores.procedures.getAllProcedures,
  isLoading: stores.locations.isLoading,
  isOpen: stores.locations.isModalOpen,
  location: stores.locations.selectedLocation,
  procedures: stores.procedures.procedures,
  providerTypes: stores.providers.providerTypes,
  saveLocation: stores.locations.saveLocation,
  setSelectedAddress: stores.locations.setSelectedAddress,
  selectedAddress: stores.locations.selectedAddress,
  activeBillingAddressCount: stores.locationBillings.activeBillingAddressCount,
  resetCoordinates: stores.locations.resetCoordinates,
}))(LocationDialog)

export default InjectedLocationDialog
