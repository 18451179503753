import {
  Button,
  createStyles,
  Divider,
  Grid,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import _ from 'lodash'
import { inject } from 'mobx-react'
import Moment from 'moment'
import React from 'react'
// tslint:disable-next-line: no-var-requires
const CurrencyFormat: any = require('react-currency-format')
import { IClaimFormValues } from '../ReceiveClaim/ClaimFormValues'
import { IStores } from '../Stores'
import { IBillTableFormValues } from './billsTable/BillTableFormValues'

const styles = () =>
  createStyles({
    infoData: {
      paddingBottom: '4px',
      paddingTop: '4px',
      textAlign: 'right',
    },
    infoDataTitle: {
      textAlign: 'left',
    },
  })

interface IBillTotalsProps extends WithStyles<typeof styles> {
  submitForm: () => void
  billTableValues: IBillTableFormValues
}

class BillTotals extends React.Component<IBillTotalsProps> {
  private getSelectedBills(values: IBillTableFormValues) {
    const cms1500s = _.flatMap(values.providers, (x) => x.cmS1500s!)
    return {
      selectedBills: cms1500s.filter((x) => x !== undefined && x.isChecked),
      unselectedBills: cms1500s.filter((x) => x !== undefined && !x.isChecked),
    }
  }

  private calculateSelectedTotal(bills: IClaimFormValues[]) {
    let total = 0

    bills.forEach((x) => {
      x.q24.forEach((y) => {
        if (
          typeof y.overrideAmount !== 'undefined' &&
          typeof y.overrideAmount !== 'string'
        ) {
          total += +y.overrideAmount
        } else if (typeof y.contractAmount !== 'undefined') {
          total += +y.contractAmount!
        }
      })
    })
    return total
  }

  public render() {
    const { classes } = this.props
    const { billTableValues } = this.props

    const bills = this.getSelectedBills(billTableValues)
    const selectedBillsTotal = this.calculateSelectedTotal(bills.selectedBills)

    const overdueBills = {
      overdueSelected: bills.selectedBills.filter((x) =>
        Moment(new Date()).isAfter(x.dueDate)
      ),
      overdueUnselected: bills.unselectedBills.filter((x) =>
        Moment(new Date()).isAfter(x.dueDate)
      ),
    }

    const otherBills = {
      otherSelected: bills.selectedBills.filter(
        (x) => !Moment(new Date()).isAfter(x.dueDate)
      ),
      otherUnselected: bills.unselectedBills.filter(
        (x) => !Moment(new Date()).isAfter(x.dueDate)
      ),
    }
    return (
      <Grid container direction="column">
        <Grid item style={{ padding: '15px' }}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h5">Claims</Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.infoData}
            >
              <Grid item xs={3} className={classes.infoDataTitle}>
                <Typography variant="body2">Overdue:</Typography>
              </Grid>
              <Grid item xs={4} className={classes.infoData}>
                <Typography variant="body2">
                  {overdueBills.overdueSelected.length} of{' '}
                  {overdueBills.overdueSelected.length +
                    overdueBills.overdueUnselected.length}
                </Typography>
              </Grid>
              <Grid item xs={5} className={classes.infoData}>
                <Typography variant="body2">
                  <CurrencyFormat
                    value={this.calculateSelectedTotal(overdueBills.overdueSelected)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.infoData}
            >
              <Grid item className={classes.infoDataTitle} xs={3}>
                <Typography variant="body2">Other:</Typography>
              </Grid>
              <Grid item className={classes.infoData} xs={4}>
                <Typography variant="body2">
                  {otherBills.otherSelected.length} of{' '}
                  {otherBills.otherSelected.length + otherBills.otherUnselected.length}
                </Typography>
              </Grid>
              <Grid item className={classes.infoData} xs={5}>
                <Typography variant="body2">
                  <CurrencyFormat
                    value={this.calculateSelectedTotal(otherBills.otherSelected)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Divider />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.infoData}
            >
              <Grid item className={classes.infoDataTitle} xs={4}>
                <Typography variant="body2">
                  <strong>CHECK TOTAL:</strong>
                </Typography>
              </Grid>
              <Grid item className={classes.infoData} xs={3}>
                <Typography variant="body2">
                  <strong>{bills.selectedBills ? bills.selectedBills.length : 0}</strong>
                </Typography>
              </Grid>
              <Grid item className={classes.infoData} xs={5}>
                <Typography variant="body2">
                  <strong>
                    <CurrencyFormat
                      value={selectedBillsTotal}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                    />
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              className={classes.infoData}
              style={{ paddingTop: '50px' }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.props.submitForm}
                  disabled={
                    bills.selectedBills.length === 0 ||
                    bills.selectedBills.length === undefined
                  }
                >
                  Assign Checks
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const InjectedBillTotals = inject<
  IStores,
  IBillTotalsProps,
  Partial<IBillTotalsProps>,
  any
>(() => ({}))(BillTotals)

export default withStyles(styles)(InjectedBillTotals)
