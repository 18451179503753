import { DefaultTag, ITag } from '../Definitions'
import { TagDto } from '../generated_client'

export interface ITagFormValues {
  readonly id: string
  isActive: boolean
  isLocked?: boolean
  name: string
  tagColor: string
}

function setDefaultValues(): ITagFormValues {
  return {
    id: '',
    isActive: true,
    isLocked: false,
    name: '',
    tagColor: '#A5A5A5',
  }
}

function setValuesFromTag(tag: TagDto): ITagFormValues {
  return {
    id: tag.id || '',
    isActive: tag.isActive || true,
    isLocked: tag.isLocked || false,
    name: tag.name || '',
    tagColor: tag.tagColor || '',
  }
}

export function createITagFormValues(tag?: TagDto): ITagFormValues {
  if (tag === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromTag(tag)
  }
}

export function toTagDto(formValues: ITagFormValues) {
  return new TagDto({
    id: formValues.id,
    isActive: formValues.isActive,
    name: formValues.name,
    tagColor: formValues.tagColor,
  })
}

export function toITag(formValues: ITagFormValues, original?: ITag): ITag {
  const tag = original === undefined ? DefaultTag() : original

  return {
    ...tag,
    id: formValues.id,
    isActive: formValues.isActive,
    name: formValues.name,
    tagColor: formValues.tagColor,
  }
}

export function getTagName(t: TagDto) {
  return t ? t.name! : ''
}

export function getTagValue(t: TagDto) {
  return t ? (t.id ? t.id! : t.name!) : ''
}

export function createNewTag(t: string, tags: ITagFormValues[]) {
  if (t && !tags.find((x) => x.name === t)) {
    if (t.toLowerCase() !== 'system') {
      return new TagDto({
        name: t,
      })
    }
  }
  return undefined
}

export function getTagColor(t: TagDto) {
  if (t && t.id) {
    return t.tagColor!
  }
  return '#FFFFFF'
}
