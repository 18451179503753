import { LineItemDistributionDetail } from '../LineItemDistributionDetail'
import { CMS1500DiagnosisDto } from '../claim/CMS1500DiagnosisDto'

export interface IBilloutRequest {
  lineItemDistributionDetails: LineItemDistributionDetail[]
  diagnosisCodes: CMS1500DiagnosisDto[]
}

export class BilloutRequest implements IBilloutRequest {
  public lineItemDistributionDetails: LineItemDistributionDetail[]
  public diagnosisCodes: CMS1500DiagnosisDto[]
}
