import { Button, Grid, Typography } from '@material-ui/core'
import { Label } from '@material-ui/icons'
import { FormikActions } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import { noWhitespaceString } from '../common/YupExtensions'
import { DefaultTag, ITag } from '../Definitions'
import { IStores } from '../Stores'
import * as Yup from 'yup'
import { ColorSelectDialog } from './ColorSelectDialog'

interface ITagFormValues {
  name: string
  tagColor: string
}

const TagSchema = Yup.object().shape({
  name: noWhitespaceString('Name is required', true),
})

interface ITagDialogProps {
  close?: () => void
  tag?: ITag
  isLoading?: boolean
  isOpen?: boolean
  saveTag?: (tag: ITag) => Promise<void>
}

class TagDialog extends React.Component<ITagDialogProps> {
  public state = {
    isColorSelectOpen: false,
  }

  public save = (values: ITagFormValues, formikBag: FormikActions<ITagFormValues>) => {
    const tag = {
      ...DefaultTag(this.props.tag!.isNew),
      ...values,
      id: this.props.tag!.id,
      isActive: true,
    } as ITag
    this.props.saveTag!(tag).finally(() => formikBag.setSubmitting(false))
  }

  public toggleColorSelect = () => {
    this.setState({ isColorSelectOpen: !this.state.isColorSelectOpen })
  }

  public render() {
    const { close, isLoading, isOpen, tag } = this.props
    const initialValues = {
      name: tag!.name ? tag!.name : '',
      tagColor: tag!.tagColor ? tag!.tagColor : '#A5A5A5',
    } as ITagFormValues
    return (
      <ESDialog
        close={close!}
        initialValues={initialValues}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={tag!.isNew ? 'Add New Tag' : 'Edit Tag'}
        titleIcon={<Label />}
        validationSchema={TagSchema}
        maxWidth="sm"
      >
        {({ values, setFieldValue }) => (
          <>
            <Grid justifyContent="center" alignItems="center" container spacing={2}>
              <Grid item xs={6}>
                <FullWidthField label="Tag Name" name="name" required />
              </Grid>
              <Grid item xs={4}>
                <Typography color="textSecondary">Color (Click to Select)</Typography>
                <Button
                  fullWidth
                  onClick={this.toggleColorSelect}
                  style={{
                    backgroundColor: values.tagColor,
                    height: '30px',
                  }}
                />
              </Grid>
            </Grid>
            <ColorSelectDialog
              close={this.toggleColorSelect}
              open={this.state.isColorSelectOpen}
              setFieldValue={setFieldValue}
            />
          </>
        )}
      </ESDialog>
    )
  }
}

const InjectedTagDialog = inject<IStores, ITagDialogProps, Partial<ITagDialogProps>, any>(
  (stores: IStores) => ({
    close: stores.tags.closeDialog,
    isLoading: stores.tags.isLoading,
    isOpen: stores.tags.isModalOpen,
    saveTag: stores.tags.saveTag,
    tag: stores.tags.selectedTag,
  })
)(TagDialog)

export default InjectedTagDialog
