import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultDxCode, IDxCode } from '../../Definitions'
import {
  Client,
  DxCodeDto,
  IDxCodeDto,
  SurgeryCodeDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'DxCodeDto'

export default class DxCodesStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public dxCodes: IDxCode[]
  @observable
  public selectedDxCode?: IDxCode
  @observable
  public newDxCodesToSave: SurgeryCodeDto[]
  public dataTableStore: DataTableStore<IDxCodeDto, IDxCode>

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IDxCodeDto, IDxCode>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllDxCodes('', filter, page, undefined, orderBy, includeInactives),
      (dxCode) => this.setupDxCodes(dxCode)
    )
  }

  @action.bound
  public setNewDxCodesToSave(dxCodes: SurgeryCodeDto[]) {
    this.newDxCodesToSave = dxCodes
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedDxCode = undefined
  }

  @action.bound
  public disableDxCode(dxCode: IDxCode) {
    this.isLoading = true
    return this.client
      .archiveDxCode(dxCode.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndDxCodes() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllDxCodes(dxCodeList?: string[]) {
    this.isLoading = true
    return this.client
      .getAllDxCodes(JSON.stringify(dxCodeList), undefined, 1, 5000)
      .then((resp: SwaggerResponse<IDxCodeDto[]>) =>
        runInAction(() => {
          this.dxCodes = this.setupDxCodes(resp.result)
        })
      )
  }

  @action.bound
  public openDialogWithDxCode(dxCode: IDxCode) {
    this.isModalOpen = true
    this.selectedDxCode = dxCode
  }

  @action.bound
  public recoverDxCode(dxCode: IDxCode) {
    this.isLoading = true
    return this.client
      .reactivateDxCode(dxCode.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveDxCode(dxCode: IDxCode) {
    this.isLoading = true
    let promise
    const dxCodeDto = new DxCodeDto(dxCode)
    if (dxCode.isNew) {
      promise = this.client.createDxCode(dxCodeDto)
    } else {
      promise = this.client.updateDxCode(dxCode.id, dxCodeDto)
    }
    return promise
      .then(() => {
        this.resetUIState(dxCode.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupDxCodes = (dxCodes: IDxCodeDto[]) => {
    return dxCodes.map((x) => this.setupDxCodeMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (dxCode: IDxCodeDto): IDxCode => {
    return { ...DefaultDxCode(), ...dxCode }
  }

  private setupDxCodeMenuItems = (dxCode: IDxCode) => {
    dxCode.menuItems = [
      {
        icon: Edit,
        name: 'Edit',
        onClick: () => this.openDialogWithDxCode(dxCode),
      },
    ]
    if (dxCode.isActive) {
      dxCode.menuItems.push({
        color: '#930A0A',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Delete',
        onClick: () => this.disableDxCode(dxCode),
      })
    } else {
      dxCode.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverDxCode(dxCode),
      })
    }
    return dxCode
  }
}
