import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultOrganization, IOrganization } from '../../Definitions'
import {
  Client,
  IOrganizationDto,
  OrganizationDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import { OrganizationId } from '../../utils/OrganizationIds'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'OrganizationDto'

export default class OrganizationsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public organizations: IOrganization[]
  @observable
  public selectedOrganization?: IOrganization | undefined
  public dataTableStore: DataTableStore<IOrganizationDto, IOrganization>
  @observable
  public sessionUserOrganizations: IOrganization[]

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IOrganizationDto, IOrganization>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllOrganizations(filter, page, undefined, orderBy, includeInactives),
      (orgs) => this.setupOrganizations(orgs)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedOrganization = undefined
  }

  @action.bound
  public disableOrganization(organization: IOrganization) {
    this.isLoading = true
    return this.client
      .archiveOrganization(organization.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndOrganizations() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllOrganizations() {
    if (!this.globalStore.isAdmin) {
      this.organizations = [
        {
          ...DefaultOrganization(),
          ...this.globalStore.currentUserOrganization,
        },
      ]
      return Promise.resolve()
    } else {
      return this.client
        .getAllOrganizations()
        .then((resp: SwaggerResponse<IOrganizationDto[]>) =>
          runInAction(() => {
            this.organizations = resp.result.map(this.addDefaultFields)
          })
        )
    }
  }

  @action.bound
  public getOrganizationsMenu() {
    if (!this.globalStore.isAdmin && this.globalStore.currentUserOrganization) {
      return this.client
        .getOrganizationById(this.globalStore.currentUserOrganization.id!)
        .then((resp: SwaggerResponse<IOrganizationDto>) =>
          runInAction(() => {
            const userOrganization = this.addDefaultFields(resp.result)
            this.sessionUserOrganizations = []
            this.sessionUserOrganizations.push(userOrganization)
          })
        )
    } else {
      return this.client
        .getAllOrganizations()
        .then((resp: SwaggerResponse<IOrganizationDto[]>) =>
          runInAction(() => {
            this.sessionUserOrganizations = resp.result.map(this.addDefaultFields)
            if (this.globalStore.currentAppOrganization) {
              this.globalStore.setCurrentAppOrganization(
                this.sessionUserOrganizations.find(
                  (x) => x.id === this.globalStore.currentAppOrganization!.id
                )!
              )
            }
          })
        )
    }
  }

  @action.bound
  public getCurrentUserOrganization() {
    if (
      this.globalStore.currentAppOrganization == undefined ||
      this.selectedOrganization == undefined
    ) {
      this.isLoading = true
      return this.client
        .getOrganizationById(this.globalStore.currentUserOrganization.id!)
        .then((resp: SwaggerResponse<IOrganizationDto>) =>
          runInAction(() => {
            this.selectedOrganization = this.addDefaultFields(resp.result)
            this.globalStore.currentAppOrganization = this.selectedOrganization
          })
        )
    }
    return Promise.resolve()
  }

  @action.bound
  public switchOrganization() {
    if (
      this.globalStore.currentAppOrganization &&
      this.globalStore.currentAppOrganization.id! === OrganizationId.Surgical
    ) {
      this.globalStore.changeTheme(OrganizationId.Diagnostic)

      this.client
        .getOrganizationById(OrganizationId.Diagnostic)
        .then((resp: SwaggerResponse<IOrganizationDto>) =>
          runInAction(() => {
            const userOrganization = this.addDefaultFields(resp.result)
            this.globalStore.currentAppOrganization = userOrganization
          })
        )
    } else {
      this.globalStore.changeTheme(OrganizationId.Surgical)
      this.client
        .getOrganizationById(OrganizationId.Surgical)
        .then((resp: SwaggerResponse<IOrganizationDto>) =>
          runInAction(() => {
            const userOrganization = this.addDefaultFields(resp.result)
            this.globalStore.currentAppOrganization = userOrganization
          })
        )
    }
  }

  @action.bound
  public openDialogWithOrganization(organization: IOrganization) {
    this.isModalOpen = true
    this.selectedOrganization = organization
  }

  @action.bound
  public recoverOrganization(organization: IOrganization) {
    this.isLoading = true
    return this.client
      .reactivateOrganization(organization.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public saveOrganization(organization: IOrganization) {
    this.isLoading = true
    let promise
    const organizationDto = new OrganizationDto(organization)
    organizationDto.createdOn = organization.createdOn
      ? new Date(organization.createdOn)
      : new Date()
    organizationDto.modifiedOn = organization.modifiedOn
      ? new Date(organization.modifiedOn)
      : undefined
    organizationDto.deletedOn = organization.deletedOn
      ? new Date(organization.deletedOn)
      : undefined

    if (organization.isNew) {
      promise = this.client.createOrganization(organizationDto)
    } else {
      promise = this.client.updateOrganization(organization.id, organizationDto)
    }
    return promise
      .then(() => {
        this.dataTableStore.loadData()
        this.closeDialog()
      })
      .catch(() => {})
      .finally(() => {
        this.isLoading = false
      })
  }

  private setupOrganizations = (organizations: IOrganizationDto[]) => {
    return organizations.map((x) =>
      this.setupOrganizationMenuItems(this.addDefaultFields(x))
    )
  }

  private addDefaultFields = (organization: IOrganizationDto): IOrganization => {
    return { ...DefaultOrganization(), ...organization }
  }

  private setupOrganizationMenuItems = (organization: IOrganization) => {
    organization.menuItems = [
      {
        icon: Edit,
        isConfirm: false,
        name: 'Edit',
        onClick: () => this.openDialogWithOrganization(organization),
      },
    ]
    if (organization.isActive) {
      organization.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableOrganization(organization),
      })
    } else {
      organization.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverOrganization(organization),
      })
    }
    return organization
  }
}
