export interface IAdvancedSearchConfigurationDTO {
  id: string
  name: string
  advanceSearchQueryJson: string
  userId: string
  isGlobal: boolean
}

export class AdvancedSearchConfigurationDTO implements IAdvancedSearchConfigurationDTO {
  public id: string
  public name: string
  public advanceSearchQueryJson: string
  public userId: string
  public isGlobal: boolean
}
