import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core'
import React, { ChangeEvent } from 'react'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { inject } from 'mobx-react'
import { IStores } from 'src/Stores'
import moment from 'moment'
import _ from 'lodash'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import { StaticAction } from 'src/utils/StaticAction'
import { ProcessAction } from 'src/viewModels/workflow/ProcessAction'
import { processAction } from 'src/services/WorkflowService'
import { UserDropdownOption } from 'src/viewModels/UserDropdownOption'

interface IAssignPendModalProps {
  isOpen: boolean
  closeModal: () => void
  action: InboxActionSummary
  getUserOptions?: (inboxId: string, actionId: string) => void
  userOptions?: UserDropdownOption[]
  rerouteToPath?: (path: string) => void
  inboxItemId: number
  inboxId: string
  handleProcessActionFinish?: (selectedAction?: InboxActionSummary) => void
}

class AssignPendModal extends React.Component<IAssignPendModalProps> {
  public state = {
    pendDate: undefined,
    assignDueDate: undefined,
    userOption: undefined,
    requiredMissing: false,
    pendDateError: false,
    dueDateError: false,
    comments: '',
    pendNote: '',
    executed: false,
    isLoading: true,
  }

  public componentDidMount(): void {
    if (this.props.action.staticActionId == StaticAction.ASSIGN) {
      this.props.getUserOptions!(this.props.inboxId, this.props.action.id.toString())
    }
    this.setState({ isLoading: false })
  }

  private async save() {
    this.setState({
      requiredMissing: false,
      pendDateError: false,
      dueDate: false,
      isLoading: true,
    })
    var action = this.props.action
    var data = new ProcessAction()

    if (action.staticActionId == StaticAction.PEND) {
      if (
        this.state.pendDate == undefined ||
        this.state.pendNote == '' ||
        (this.state.comments == '' && this.props.action.requireComment)
      ) {
        this.setState({
          requiredMissing: true,
          executed: false,
          isLoading: false,
        })
        return
      }

      if (this.state.pendDate != undefined) {
        if (this.isPastDate(new Date(this.state.pendDate))) {
          this.setState({
            pendDateError: true,
            executed: false,
            isLoading: false,
          })
          return
        }

        data.pendDate = new Date(moment(this.state.pendDate, 'YYYY-MM-DD').toISOString())
      }

      data.pendNote = this.state.pendNote
    } else {
      if (
        this.state.userOption == undefined ||
        (this.state.comments == '' && this.props.action.requireComment)
      ) {
        this.setState({
          requiredMissing: true,
          executed: false,
          isLoading: false,
        })
        return
      }

      if (this.state.assignDueDate != undefined) {
        if (this.isPastDate(new Date(this.state.assignDueDate))) {
          this.setState({
            dueDateError: true,
            executed: false,
            isLoading: false,
          })
          return
        }

        data.assignDueDate = new Date(
          moment(this.state.assignDueDate, 'YYYY-MM-DD').toISOString()
        )
      }

      data.assignUser = this.state.userOption
    }

    data.comment = this.state.comments

    processAction(data, action!.id.toString(), this.props.inboxItemId.toString())
      .then(() => {
        this.props.handleProcessActionFinish!(action)
        this.setState({ isLoading: false })
        this.props.closeModal()
      })
      .catch(() => {
        this.setState({ executed: false, isLoading: false })
        this.close()
      })
  }

  private close = () => {
    this.setState({
      requiredMissing: false,
      pendDateError: false,
      executed: false,
    })
    this.props.closeModal()
  }

  private handlePendDateChange = (event: any) => {
    this.setState({ pendDate: event.target.value, noFilters: false })
  }

  private handleAssignDueDateChange = (event: any) => {
    this.setState({ assignDueDate: event.target.value, noFilters: false })
  }

  private setSelectedUser = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    this.setState({ userOption: event.target.selectedOptions[0].value })
  }

  private isPastDate(dateToVerify: Date): boolean {
    var now = new Date()
    var today = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    )

    // Typescript trick to find if it is in the past
    if (dateToVerify.getTime() < today.getTime()) {
      return true
    }

    return false
  }

  public render() {
    var modalTitle = this.props.action.actionName.toUpperCase()

    const MenuProps = {
      getContentAnchorEl: null,
      PaperProps: {
        style: {
          maxHeight: 400,
        },
      },
    }

    return (
      <>
        <Dialog
          key={this.props.action.id}
          fullWidth={true}
          maxWidth="sm"
          open={this.props.isOpen!}
          disableBackdropClick
        >
          <DialogTitle style={{ padding: 0 }}>
            <ResponsiveAppBar title={modalTitle} isModal={true} />
          </DialogTitle>
          <DialogContent style={{ padding: '12px 12px 8px' }}>
            {this.state.isLoading && (
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1200',
                }}
              >
                <CircularProgress size={100} />
              </div>
            )}
            <Grid container direction="column" spacing={2}>
              {this.props.action.staticActionId == StaticAction.ASSIGN && (
                <>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel shrink={true} variant="outlined">
                        User
                      </InputLabel>
                      <Select
                        variant="outlined"
                        input={<OutlinedInput notched labelWidth={35} />}
                        inputProps={{ label: true, notched: true }}
                        fullWidth
                        id="userOptions"
                        native={true}
                        onChange={this.setSelectedUser}
                        MenuProps={MenuProps}
                      >
                        <option value="">Select a user</option>
                        {this.props.userOptions?.map((user) => (
                          <option value={user.id}>{user.description}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      inputProps={{ id: 'assignDueDate' }}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleAssignDueDateChange}
                      type="date"
                      name="assignDueDate"
                      variant="outlined"
                      defaultValue={this.state.assignDueDate}
                      label="Due Date"
                      fullWidth
                    />
                  </Grid>
                </>
              )}
              {this.props.action.staticActionId == StaticAction.PEND && (
                <Grid item xs={12}>
                  <TextField
                    inputProps={{ id: 'pendDate' }}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.handlePendDateChange}
                    type="date"
                    name="pendDate"
                    variant="outlined"
                    required
                    defaultValue={this.state.pendDate}
                    label="Pend Date"
                    fullWidth
                  />
                </Grid>
              )}
              {this.props.action.staticActionId == StaticAction.PEND && (
                <Grid item xs={12}>
                  <TextField
                    label="Pend Note"
                    name="notes"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required={true}
                    multiline
                    minRows="1"
                    onChange={(event) => this.setState({ pendNote: event.target.value })}
                  />
                </Grid>
              )}
              {(this.props.action.staticActionId == StaticAction.ASSIGN ||
                this.props.action.displayComment) && (
                <Grid item xs={12}>
                  <TextField
                    label="Comments"
                    name="notes"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required={this.props.action.requireComment}
                    multiline
                    minRows="4"
                    onChange={(event) => this.setState({ comments: event.target.value })}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          {this.state.requiredMissing && (
            <div
              style={{ textAlign: 'right', margin: '0px 20px 12px' }}
              className="error"
            >
              Please fill all required fields.
            </div>
          )}
          {this.state.pendDateError && (
            <div
              style={{ textAlign: 'right', margin: '0px 20px 12px' }}
              className="error"
            >
              Pend Date cannot be in the past.
            </div>
          )}
          {this.state.dueDateError && (
            <div
              style={{ textAlign: 'right', margin: '0px 20px 12px' }}
              className="error"
            >
              Due Date cannot be in the past.
            </div>
          )}
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              disabled={
                this.state.executed ||
                (this.props.action.requireComment && this.state.comments === '') ||
                (this.props.action.staticActionId == StaticAction.PEND &&
                  (this.state.pendDate == undefined ||
                    this.state.pendDate == '' ||
                    this.state.pendNote === '')) ||
                (this.props.action.staticActionId == StaticAction.ASSIGN &&
                  this.state.userOption == undefined)
              }
              onClick={() => {
                this.setState({ executed: true })
                this.save()
              }}
            >
              Execute
            </Button>
            <Button
              onClick={() => {
                this.close()
              }}
              data-cy="close"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const InjectedAssignPendModal = inject<
  IStores,
  IAssignPendModalProps,
  Partial<IAssignPendModalProps>,
  any
>((stores: IStores) => ({
  getUserOptions: stores.users.getUserOptions,
  userOptions: stores.users.userOptions,
  rerouteToPath: stores.global.rerouteToPath,
}))(AssignPendModal)

export default InjectedAssignPendModal
