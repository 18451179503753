import { Button } from '@material-ui/core'
import React from 'react'
import { reviewClaim } from 'src/services/ClaimService'

interface IClaimDocumentButtonProps {
  disabled: boolean
  claimId: string
  setIsLoading: (loading: boolean) => void
}

function GeneratePDFDocumentButton(props: IClaimDocumentButtonProps) {
  async function openDoc(event: any) {
    event.stopPropagation()
    props.setIsLoading(true)
    await reviewClaim(props.claimId).then(() => {
      props.setIsLoading(false)
    })
  }

  return (
    <Button
      variant="contained"
      disabled={props.disabled}
      color="primary"
      onClick={(e) => openDoc(e)}
      style={{ marginRight: '8px' }}
    >
      Generate PDF
    </Button>
  )
}

export default GeneratePDFDocumentButton
