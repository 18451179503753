import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  Typography,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import moment from 'moment'
import React from 'react'
import { IPatient } from 'src/Definitions'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { deletePatientDocument } from 'src/services/PatientService'
import { formatDate } from 'src/utils/Formatter'
import { DocumentSummaryResult } from 'src/viewModels/DocumentSummaryResult'

interface IDeletePatientDocumentModal extends DialogProps {
  close: (reload: boolean) => void
  patient: IPatient
  document: DocumentSummaryResult
}

class DeletePatientDocumentModal extends React.Component<IDeletePatientDocumentModal> {
  public state = {
    deletePatientDocumentProcessing: false,
  }

  private submit = (patientId: string, documentId: string) => {
    deletePatientDocument(patientId, documentId)
      .then(() => {
        this.props.close(true)
      })
      .finally(() => {
        this.setState({ deletePatientDocumentProcessing: false })
      })
  }

  public render() {
    const { close, patient, document, ...modalProps } = this.props

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => close(false)}
        {...modalProps}
        disableBackdropClick
      >
        {this.state.deletePatientDocumentProcessing && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1200',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <ResponsiveAppBar
          title={`Delete Document - [${this.props.patient?.lastName}, ${this.props.patient
            ?.firstName} (${moment(this.props.patient?.dateOfBirth).format(
            'MM/DD/YYYY'
          )})]`}
          pageIcon={<Delete />}
          isModal
        />
        <DialogContent style={{ marginTop: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} wrap="nowrap">
              <Typography variant="body1">
                <strong>Are you sure you want to delete this document:</strong>
              </Typography>
            </Grid>
            <Grid container item xs={12} wrap="nowrap" direction="column">
              <Grid item>
                <strong>File Name:</strong> {document.fileName}
              </Grid>
              <Grid item>
                <strong>Date:</strong>&nbsp;
                {document.fileDate ? formatDate(document.fileDate.toString()) : ''}
              </Grid>
              <Grid item>
                <strong>Description:</strong> {document.description}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button onClick={() => close(false)}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  this.setState({ deletePatientDocumentProcessing: true })
                  this.submit(patient.id, document.documentId)
                }}
                color="primary"
                variant="contained"
                disabled={this.state.deletePatientDocumentProcessing}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default DeletePatientDocumentModal
