import { ILineItemDistributionDetail } from './LineItemDistributionDetail'

export interface IApplyPaymentRequest {
  checkNumber: string | undefined
  datePaid: Date | undefined
  checkAmount?: number | undefined // CMS1500Type
  cms1500Id: string | undefined
  lineDistributionDetail: ILineItemDistributionDetail[]
  payer?: string
  receiverName?: string
  receiverAddress?: string
}

export class ApplyPaymentRequest implements IApplyPaymentRequest {
  public checkNumber: string | undefined
  public datePaid: Date | undefined
  public checkAmount?: number | undefined // CMS1500Type
  public cms1500Id: string | undefined
  public lineDistributionDetail: ILineItemDistributionDetail[]
  public payer?: string
  public receiverName?: string
  public receiverAddress?: string
  constructor(
    cmsId: string,
    checkNumber: string,
    datePaid: Date,
    checkAmount: number,
    lineItems: ILineItemDistributionDetail[],
    payer?: string,
    receiverName?: string,
    receiverAddress?: string
  ) {
    this.checkNumber = checkNumber
    this.datePaid = datePaid
    this.checkAmount = checkAmount
    this.cms1500Id = cmsId
    this.lineDistributionDetail = lineItems
    this.payer = payer
    this.receiverName = receiverName
    this.receiverAddress = receiverAddress
  }
}
