import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Email } from '@material-ui/icons'
import { ContentCopy } from 'mdi-material-ui'
import React from 'react'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { CreateAdjusterEmail } from 'src/services/QuoteService'
import { processAction, processDynamicAction } from 'src/services/WorkflowService'
import { EmailLogRequest } from 'src/viewModels/quotes/EmailLogRequest'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import { ObjectiveProcessAction } from 'src/viewModels/workflow/ObjectiveProcessAction'

interface IAdjusterEmailModalProps {
  closeModal: () => void
  isOpen: boolean
  reloadParent?: () => void
  action: InboxActionSummary
  isDynamicItem: boolean
  inboxItemId: number
  rerouteToPath?: (path: string) => void
  associatedItemId: string
  inboxId: string
  handleProcessActionFinish?: (selectedAction?: InboxActionSummary) => void
}

class SendToAdjusterModal extends React.Component<IAdjusterEmailModalProps> {
  public state = {
    recipients: '',
    cc: '',
    body: '',
    mailTo: '',
    loading: true,
    executed: false,
    comments: '',
  }

  public componentDidMount() {
    CreateAdjusterEmail(this.props.associatedItemId).then((value) => {
      this.setState({
        recipients: value.recipientAddresses,
        cc: value.ccAddresses,
        body: value.body,
        mailTo: value.mailTo,
        loading: false,
      })
    })
  }

  private handleBodyChange = (event: any) => {
    this.setState({ body: event.target.value })
  }

  public render() {
    const { isOpen, associatedItemId } = this.props

    return (
      <Dialog key={associatedItemId} fullWidth={true} maxWidth="md" open={isOpen!}>
        {this.state.loading && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title={'Send To Adjuster Email'}
            pageIcon={<Email />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" item xs={12} spacing={2}>
            <Grid item xs={12} style={{ paddingTop: '22px' }}>
              <Grid container direction="row">
                <Grid item>
                  <Typography
                    variant={'h6'}
                    component={'h6'}
                    color="primary"
                    style={{ paddingRight: '16px', marginBottom: '8px' }}
                  >
                    CONTACTS
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip title={'Copy'}>
                    <ContentCopy
                      onClick={() => this.copyTo()}
                      style={{ cursor: 'pointer' }}
                      fontSize="small"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Typography>
                <React.Fragment>
                  <span style={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                    To: {this.state.recipients + '\n'}
                    Cc: {this.state.cc}
                  </span>
                </React.Fragment>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item>
                  <Typography
                    variant={'h6'}
                    component={'h6'}
                    color="primary"
                    style={{ paddingRight: '16px', marginBottom: '8px' }}
                  >
                    BODY
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip title={'Copy'}>
                    <ContentCopy
                      onClick={() => this.copyBody()}
                      style={{ cursor: 'pointer' }}
                      fontSize="small"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Typography>
                <FormControl fullWidth>
                  <TextField
                    value={this.state.body}
                    onChange={this.handleBodyChange}
                    name="body"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    minRows={15}
                    multiline
                  />
                </FormControl>
              </Typography>
            </Grid>
            {this.props.action.displayComment && (
              <Grid item xs={12}>
                <TextField
                  label="Comments"
                  name="notes"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required={this.props.action.requireComment}
                  multiline
                  minRows="4"
                  onChange={(event) => this.setState({ comments: event.target.value })}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ display: 'flex' }}>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: 'auto' }}
            href={this.state.mailTo}
          >
            Open Outlook
          </Button>
          <Button
            onClick={this.addLog}
            color="primary"
            variant="contained"
            disabled={
              this.state.executed ||
              (this.props.action.requireComment && this.state.comments === '')
            }
          >
            Execute
          </Button>
          <Button onClick={this.close} data-cy="close" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  public secondFormRef = React.createRef<any>()

  private copyBody = () => {
    navigator.clipboard.writeText(this.state.body)
    alert('Copied body')
  }

  private copyTo = () => {
    navigator.clipboard.writeText(this.state.recipients)
    alert('Copied recipient addresses')
  }

  private close = () => {
    this.setState({
      recipients: '',
      cc: '',
      body: '',
      mailTo: '',
      loading: true,
      executed: false,
      comments: '',
    })
    this.props.closeModal!()
  }

  private addLog = async () => {
    this.setState({ loading: true, executed: true })

    var action = this.props.action
    let data = new ObjectiveProcessAction()

    data.comment = this.state.comments
    data.sendToAdjusterData = new EmailLogRequest(
      this.state.body,
      this.state.cc,
      this.state.recipients
    )

    if (action.requireComment && data.comment == '') {
      this.setState({ requiredMissing: true })
      return
    }

    if (this.props.isDynamicItem) {
      processDynamicAction(
        data,
        this.props.inboxId.toString(),
        action!.id.toString(),
        this.props.associatedItemId.toString()
      )
        .then(() => {
          this.props.handleProcessActionFinish!(action)
          this.props.closeModal()
        })
        .catch(() => {
          this.setState({ executed: false, loading: false })
        })
    } else {
      processAction(data, action!.id.toString(), this.props.inboxItemId.toString())
        .then(() => {
          this.props.handleProcessActionFinish!(action)
          this.props.closeModal()
        })
        .catch(() => {
          this.setState({ executed: false, loading: false })
        })
    }
  }
}

export default SendToAdjusterModal
