export class CMS1500DiagnosisDto implements ICMS1500DiagnosisDto {
  id?: string | undefined
  pointer?: string | undefined
  code?: string | undefined

  constructor(data?: ICMS1500DiagnosisDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.pointer = data['pointer']
      this.code = data['code']
    }
  }

  static fromJS(data: any): CMS1500DiagnosisDto {
    data = typeof data === 'object' ? data : {}
    let result = new CMS1500DiagnosisDto()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['pointer'] = this.pointer
    data['code'] = this.code
    return data
  }
}

export interface ICMS1500DiagnosisDto {
  id?: string | undefined
  pointer?: string | undefined
  code?: string | undefined
}
