import ESDialog from '../common/ESDialog'

import { Grid, IconButton, InputAdornment } from '@material-ui/core'

import { Visibility, VisibilityOff } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import FullWidthField from '../common/FullWidthField'
import { IUser } from '../Definitions'
import { IStores } from '../Stores'
import { IPasswordPattern } from '../stores/LoginStore'
import * as Yup from 'yup'

let ChangePasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords do not match')
    .required('Confirm Password is Required'),
  currentPassword: Yup.string().required('Current password is Required'),
})

interface IChangePasswordUserDto extends IUser {
  oldPassword: string
  password: string
}

interface IChangePasswordFormValues {
  currentPassword: string
  password: string
  confirmPassword: string
}

export interface IChangePasswordDialog {
  close?: () => void
  currentUser?: IUser
  getPasswordPattern?: () => void
  isLoading?: boolean
  isOpen?: boolean
  logout?: () => void
  passwordPattern?: IPasswordPattern
  saveUser?: (user: IUser, imageHasChanged?: boolean) => void
}

@inject((stores: IStores) => ({
  close: stores.users.closeChangePasswordDialog,
  currentUser: stores.global.user,
  getPasswordPattern: stores.login.getPasswordPattern,
  isLoading: stores.users.isLoading,
  isOpen: stores.users.isChangePasswordModalOpen,
  logout: () => stores.login.logout(),
  passwordPattern: stores.login.passwordPattern,
  saveUser: stores.users.saveUser,
}))
class ChangePasswordDialog extends React.Component<IChangePasswordDialog> {
  public state = {
    showPassword: false,
  }

  public componentDidMount() {
    this.props.getPasswordPattern!()
  }

  public onShowPasswordChange = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  public save = (values: IChangePasswordFormValues) => {
    const user = {
      ...this.props.currentUser,
      oldPassword: values.currentPassword,
      password: values.password,
    } as IChangePasswordUserDto
    delete user.organization
    this.props.saveUser!(user as IUser)
  }
  public saveAndLogout = (values: IChangePasswordFormValues) => {
    this.save(values)
    this.props.close!()
    this.props.logout!()
  }

  public render() {
    const { close, isLoading, isOpen, passwordPattern } = this.props
    let password = Yup.string().required('Password is Required')
    if (passwordPattern && passwordPattern.regex) {
      password = password.matches(passwordPattern.regex, passwordPattern.failMessage)
    }
    ChangePasswordSchema = ChangePasswordSchema.shape({
      password,
    })
    return (
      <ESDialog
        close={close!}
        initialValues={{
          currentPassword: '',
          password: '',
          confirmPassword: '',
        }}
        isLoading={isLoading}
        open={isOpen!}
        save={this.saveAndLogout}
        title="Change Password"
        validationSchema={ChangePasswordSchema}
      >
        {() => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FullWidthField
                autoFocus
                label="Current Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.onShowPasswordChange}
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name="currentPassword"
                required
                type={this.state.showPassword ? 'text' : 'password'}
              />
            </Grid>
            <Grid item xs={12}>
              <FullWidthField
                FormHelperTextProps={{
                  component: 'pre',
                }}
                label="Password"
                name="password"
                required
                type={this.state.showPassword ? 'text' : 'password'}
              />
            </Grid>
            <Grid item xs={12}>
              <FullWidthField
                label="Confirm Password"
                name="confirmPassword"
                required
                type={this.state.showPassword ? 'text' : 'password'}
              />
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }
}
export default ChangePasswordDialog
