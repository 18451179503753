import {
  AppBar,
  Button,
  CircularProgress,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { Assignment, Code, EmailOutlined, List } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Field, Form, Formik, FormikActions, getIn } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Prompt, RouteComponentProps } from 'react-router-dom'
import AuthorizationLogGrid from 'src/authorizationLog/AuthorizationLogGrid'
import { EOCEmailType } from 'src/models/enums/EOCEmailType.enum'
import {
  generateEOCEmail,
  getQuoteSummaryMessage,
  logEOCEmailGeneration,
} from 'src/services/EpisodeOfCareService'
import * as Yup from 'yup'
import PatientActionBar from '../../common/ActionBar/PatientActionBar'
import CardWithNoTitle from '../../common/CardWithNoTitle/CardWithNoTitle'
import DatePickerField from '../../common/DatePickerField/DatePickerField'
import ESDialog from '../../common/ESDialog'
import FullWidthField from '../../common/FullWidthField'
import ResponsiveAppBar from '../../common/ResponsiveAppBar'
import SelectField from '../../common/SelectField/SelectField'
import TypeAheadField from '../../common/TypeAheadField/TypeAheadField'
import { noWhitespaceString } from '../../common/YupExtensions'
import Constants from '../../config'
import {
  DefaultCptCode,
  DefaultDxCode,
  DefaultEpisodeOfCare,
  ICptCode,
  IDxCode,
  IEpisodeOfCare,
  IOrganization,
  IPatient,
  IReferral,
  IReferringDoctor,
  IUser,
} from '../../Definitions'
import {
  CptCodeDto,
  DxCodeDto,
  IBodyPartDto,
  IProcedureDto,
  SurgeryCodeDto,
} from '../../generated_client'
import { IStores } from '../../Stores'
import DiagnosticEOCStatus from '../../utils/DiagnosticEOCStatus'
import EOCStatus from '../../utils/EOCStatus'
import { OrganizationId } from '../../utils/OrganizationIds'
import SchedulingTable from '../Scheduling/SchedulingTable'
import TestsContainer from '../Test/TestsContainer'
import CodeExpandingList from './CodeExpandingList'
import {
  createIEpisodeOfCareFormValues,
  IEpisodeOfCareFormValues,
  toIEpisodeOfCare,
} from './EpisodeOfCareFormValues'
import moment from 'moment'
import ReferralContactModal from './ReferralContactModal'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    checklistPanel: {
      borderBottom: '2px',
      borderBottomColor: palette.secondary.main,
      borderBottomStyle: 'solid',
      marginTop: '10px',
      minHeight: '32px',
      paddingLeft: '10px',
    },
    pageHeader: {
      fontSize: '40px',
      fontWeight: 300,
      lineHeight: '40px',
      paddingLeft: '20px',
      paddingTop: '20px',
    },
    pageHeaderIcon: {
      paddingRight: '10px',
    },
    paper: {
      marginTop: 4,
      paddingTop: spacing(2),
    },
    topOfPage: {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: spacing(2),
      paddingLeft: spacing(2),
      width: '100%',
    },
  })

interface INewSurgeryCodeFormValues {
  cptCodes: ICptCode[]
  dxCodes: IDxCode[]
}

const EpisodeOfCareDiagnosticSchema = Yup.object().shape({
  bodyPartText: Yup.string(),
  careCoordinatorId: noWhitespaceString().required().label('Care Coordinator'),
  cptCodes: Yup.array().of(
    Yup.object().shape({
      code: Yup.string(),
      priority: Yup.number(),
    })
  ),
  ctScan: Yup.string(),
  dxCodes: Yup.array().of(
    Yup.object().shape({
      code: Yup.string(),
      priority: Yup.number(),
    })
  ),
  effectiveDate: Yup.date().when('invoiceSurgeryStatus', {
    is: (invoiceSurgeryStatus) => invoiceSurgeryStatus !== undefined,
    otherwise: Yup.date(),
    then: Yup.date().required().label('Takeover of Care Date'),
  }),
  emg: Yup.string(),
  episodeOfCareNumber: Yup.string(),
  invoiceStatus: Yup.string(),
  invoiceSurgeryStatus: Yup.string(),
  isClinicalNotesReceived: Yup.bool(),
  isNoPtToBeScheduled: Yup.bool(),
  isPatientOnBoarding: Yup.bool(),
  mri: Yup.string(),
  notes: Yup.string(),
  tests: Yup.array().of(
    Yup.object().shape({
      bodyPartId: noWhitespaceString('Body Part is required', true),
      cptCodes: Yup.array().of(
        Yup.object().shape({
          code: Yup.string(),
          priority: Yup.number(),
        })
      ),
      diagnosis: Yup.string(),
      dxCodes: Yup.array().of(
        Yup.object().shape({
          code: Yup.string(),
          priority: Yup.number(),
        })
      ),
      followUpDateTime: Yup.date(),
      notes: Yup.string(),
      procedureId: noWhitespaceString('Procedure is required', true),
      rxReceived: Yup.bool(),
      status: Yup.string(),
      testIssue: Yup.bool(),
    })
  ),
  xRay: Yup.string(),
})

const EpisodeOfCareSurgicalSchema = Yup.object().shape({
  bodyPartText: noWhitespaceString('Body Part is Required', true),
  careCoordinatorId: noWhitespaceString().required().label('Care Coordinator'),
  cptCodes: Yup.array().of(
    Yup.object().shape({
      code: Yup.string(),
      priority: Yup.number(),
    })
  ),
  ctScan: Yup.string(),
  dxCodes: Yup.array().of(
    Yup.object().shape({
      code: Yup.string(),
      priority: Yup.number(),
    })
  ),
  effectiveDate: Yup.date().when('invoiceSurgeryStatus', {
    is: (invoiceSurgeryStatus) => invoiceSurgeryStatus !== undefined,
    otherwise: Yup.date(),
    then: Yup.date().required().label('Takeover of Care Date'),
  }),
  emg: Yup.string(),
  episodeOfCareNumber: Yup.string(),
  invoiceStatus: Yup.string(),
  invoiceSurgeryStatus: Yup.string(),
  isClinicalNotesReceived: Yup.bool(),
  isNoPtToBeScheduled: Yup.bool(),
  isPatientOnBoarding: Yup.bool(),
  mri: Yup.string(),
  notes: Yup.string(),
  tests: Yup.array().of(
    Yup.object().shape({
      bodyPartId: noWhitespaceString('Body Part is required', true),
      cptCodes: Yup.array().of(
        Yup.object().shape({
          code: Yup.string(),
          priority: Yup.number(),
        })
      ),
      diagnosis: Yup.string(),
      dxCodes: Yup.array().of(
        Yup.object().shape({
          code: Yup.string(),
          priority: Yup.number(),
        })
      ),
      followUpDateTime: Yup.date(),
      notes: Yup.string(),
      procedureId: noWhitespaceString('Procedure is required', true),
      rxReceived: Yup.bool(),
      status: Yup.string(),
      testIssue: Yup.bool(),
    })
  ),
  xRay: Yup.string(),
})

interface IEditEpisodeOfCareParams {
  patientId: string
  referralId: string
  episodeOfCareId: string
}

interface IEditEpisodeOfCareProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IEditEpisodeOfCareParams> {
  bodyParts?: IBodyPartDto[]
  closeCodeCheckModal?: () => void
  closeTab?: (fallBackPath?: string) => void
  cptCodes?: ICptCode[]
  currentAppOrganization?: IOrganization
  dxCodes?: IDxCode[]
  referringDoctors?: IReferringDoctor[]
  getAllUsers?: (pageSize?: number, roleFilter?: string) => Promise<void>
  getAllCptCodes?: (cptCodesList?: string[]) => Promise<void>
  getAllDxCodes?: (dxCodesList?: string[]) => Promise<void>
  getAllProcedures?: () => Promise<void>
  getPatientById?: (id: string) => Promise<void>
  getReferralById?: (id: string) => Promise<void>
  getEpisodeOfCareById?: (id: string) => Promise<void>
  getReferringDoctors?: (filter: string) => Promise<void>
  isCodeCheckModalOpen?: boolean
  newCptCodesToSave?: SurgeryCodeDto[]
  newDxCodesToSave?: SurgeryCodeDto[]
  openCodeCheckModal?: () => void
  patient: IPatient | undefined
  procedures?: IProcedureDto[]
  episodeOfCare?: IEpisodeOfCare
  rerouteToPath?: (path: string) => void
  returnRoutePath?: string
  saveCptCode?: (cptCode: ICptCode) => Promise<void>
  saveDxCode?: (dxCode: IDxCode) => Promise<void>
  saveEpisodeOfCare?: (episodeOfCare: IEpisodeOfCare) => Promise<void>
  setNewCptCodesToSave?: (cptCodes: SurgeryCodeDto[]) => void
  setNewDxCodesToSave?: (dxCodes: SurgeryCodeDto[]) => void
  setPageSize?: (pageSize: number) => void
  setSelectedEpisodeOfCare?: (episodeOfCare: IEpisodeOfCare | undefined) => void
  setSelectedPatient?: (patient: IPatient | undefined) => void
  setSelectedReferral?: (referral: IReferral | undefined) => void
  users?: IUser[]
  loadSchedules?: () => Promise<void>
  loadBodyParts?: () => Promise<void>
  setReturn?: (path?: string) => Promise<void>
  eocToDashboard?: boolean
  eocToQuotes?: boolean
  resetEOC?: () => void
  openReferralContactModal?: () => void
  isReferralContactModalOpen?: boolean
}

interface IEditEpisodeOfCareState {
  expandedDetailsPanel: string
  expandedSchedulePanel: string
  expandedLogPanel: string
  isDelete: boolean
  reload: boolean
  emailContent: string
  generateEmail: EOCEmailType
  isEOCSaved: boolean
  quoteSummary: string
  isLoading: boolean
}
@observer
class EditEpisodeOfCare extends React.Component<
  IEditEpisodeOfCareProps,
  IEditEpisodeOfCareState
> {
  constructor(props: IEditEpisodeOfCareProps) {
    super(props)
    this.state = {
      expandedDetailsPanel:
        props.episodeOfCare && props.episodeOfCare.isNew ? 'detailsPanel' : '',
      expandedSchedulePanel:
        props.episodeOfCare && props.episodeOfCare.isNew ? '' : 'schedulePanel',
      expandedLogPanel:
        props.episodeOfCare && props.episodeOfCare.isNew ? '' : 'logPanel',
      isDelete: false,
      reload: false,
      emailContent: '',
      generateEmail: EOCEmailType.NewSurgeryEmail,
      isEOCSaved: false,
      quoteSummary: '',
      isLoading: true,
    }
  }

  private async setup() {
    const { match } = this.props
    this.setState({
      isDelete: false,
      isEOCSaved: false,
      isLoading: true,
    })
    this.setState({
      expandedDetailsPanel: match && match.params.episodeOfCareId ? '' : 'detailsPanel',
      expandedSchedulePanel: match && match.params.episodeOfCareId ? 'schedulePanel' : '',
    })
    const promises = [this.props.loadBodyParts!()] as Array<Promise<void>>

    if (match && match.params.episodeOfCareId) {
      promises.push(this.props.getEpisodeOfCareById!(match.params.episodeOfCareId))
      generateEOCEmail(match.params.episodeOfCareId, this.state.generateEmail).then(
        (result) => {
          this.setState({ emailContent: result })
        }
      )
      getQuoteSummaryMessage(match.params.episodeOfCareId).then((result) => {
        this.setState({ quoteSummary: result })
      })
    } else {
      this.props.setSelectedEpisodeOfCare!(DefaultEpisodeOfCare(true))
    }

    if (match && match.params.referralId) {
      promises.push(this.props.getReferralById!(match.params.referralId))
    }

    if (match && match.params.patientId) {
      promises.push(this.props.getPatientById!(match.params.patientId))
    }

    promises.push(this.props.getAllUsers!(5000, Constants.Roles.CareCoordinator))

    await Promise.all(promises)
      .then(() => {
        this.props.setPageSize!(8)
        this.props.loadSchedules!()
        this.props.getAllProcedures!()
        if (this.props.episodeOfCare!.referringDoctor) {
          this.props.getReferringDoctors!(
            this.props.episodeOfCare!.referringDoctor!.lastName!
          )
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  public async componentDidMount() {
    await this.setup()
  }

  public async componentDidUpdate(prevProps: IEditEpisodeOfCareProps) {
    if (
      this.props.match.params.episodeOfCareId !== prevProps.match.params.episodeOfCareId
    ) {
      await this.setup()
    }
  }

  public componentWillUnmount() {
    this.props.setSelectedEpisodeOfCare!(undefined)
    this.props.setSelectedReferral!(undefined)
    this.props.setSelectedPatient!(undefined)
  }

  public save = (
    values: IEpisodeOfCareFormValues,
    formikBag: FormikActions<IEpisodeOfCareFormValues>
  ) => {
    this.setState({ isEOCSaved: true, isLoading: true })
    const { currentAppOrganization } = this.props
    const episodeOfCare = toIEpisodeOfCare(values, this.props.episodeOfCare)
    episodeOfCare.cptCodes.forEach((x: CptCodeDto) => (x.code = x.code!.toUpperCase()))
    episodeOfCare.dxCodes.forEach((x: CptCodeDto) => (x.code = x.code!.toUpperCase()))
    if (this.props.episodeOfCare!.isNew) {
      episodeOfCare.organizationId = currentAppOrganization!.id
    }
    let cptCodesList: SurgeryCodeDto[]
    let dxCodesList: SurgeryCodeDto[]

    if (currentAppOrganization!.id === OrganizationId.Surgical) {
      cptCodesList = episodeOfCare.cptCodes
      dxCodesList = episodeOfCare.dxCodes
    } else {
      cptCodesList = _.flatMap(episodeOfCare.tests, (x) => x.cptCodes!)
      dxCodesList = _.flatMap(episodeOfCare.tests, (x) => x.dxCodes!)
    }

    this.props.saveEpisodeOfCare!(episodeOfCare)
      .then(() => {
        formikBag.setSubmitting(false)
        const getAllCptCodes = this.props.getAllCptCodes!(
          cptCodesList.map((y) => y.code!.trim())
        )
        const getAllDxCodes = this.props.getAllDxCodes!(
          dxCodesList.map((y) => y.code!.trim())
        )

        Promise.all([getAllCptCodes, getAllDxCodes]).then(() => {
          const newCptCodes = cptCodesList.filter(
            (x) =>
              this.props.cptCodes!.find(
                (y) => y.code.trim().toLowerCase() === x.code!.trim().toLowerCase()
              ) === undefined
          )
          const newDxCodes = dxCodesList.filter(
            (x) =>
              this.props.dxCodes!.find(
                (y) => y.code.trim().toLowerCase() === x.code!.trim().toLowerCase()
              ) === undefined
          )
          this.props.setNewCptCodesToSave!(newCptCodes)
          this.props.setNewDxCodesToSave!(newDxCodes)
          if (newCptCodes.length > 0 || newDxCodes.length > 0) {
            //refresh on save and then show modal to facilitate not now on save
            this.props.rerouteToPath!(
              `/patients/patient/${this.props.match.params.patientId}/referral/${this.props.match.params.referralId}/episodeOfCare/${episodeOfCare.id}`
            )
            this.setup()
            this.props.openCodeCheckModal!()
          } else {
            if (this.state.reload) {
              this.props.rerouteToPath!(
                `/patients/patient/${this.props.match.params.patientId}/referral/${this.props.match.params.referralId}/episodeOfCare/${episodeOfCare.id}`
              )
              this.setup()
            } else {
              this.cancel()
            }
          }
        })
      })
      .catch(() => {
        formikBag.setSubmitting(false)
        this.setState({ isLoading: false })
      })
  }

  private getInitialReferringDoctor(id: string) {
    return _.find(this.props.referringDoctors, (x: IReferringDoctor) => x.id === id)
  }

  private getSelectedReferringDoctor(values: IEpisodeOfCareFormValues) {
    return _.find(
      this.props.referringDoctors,
      (x: IReferringDoctor) => x.id === values.referringDoctorId
    )
  }

  private getReferringDoctorName(item: IReferringDoctor) {
    if (item) {
      return `${item.name} ${item.address.phoneNumber}`
    } else {
      return ''
    }
  }

  private getReferringDoctorValue(item: any) {
    return item ? item.id : ''
  }

  private setValue =
    (setFieldValue: (field: string, value: any) => void) =>
    (fieldName: string, value: IEpisodeOfCareFormValues) => {
      const newValue = value !== undefined ? value.id : undefined
      setFieldValue(`${fieldName}Id`, newValue)
      setFieldValue(fieldName, value)
      const parentFieldName = fieldName.replace('Contact', '')
      if (newValue && newValue !== '') {
        switch (fieldName) {
          default:
            break
        }
      } else {
        setFieldValue(parentFieldName, undefined)
        setFieldValue(`${parentFieldName}Id`, undefined)
      }
    }

  private addDxCodeDescriptions = (
    values: IEpisodeOfCareFormValues,
    testIndex: number,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const { currentAppOrganization } = this.props
    if (currentAppOrganization!.id === OrganizationId.Diagnostic) {
      const episodeOfCare = toIEpisodeOfCare(values, this.props.episodeOfCare)

      const surgeryCodeDtos = episodeOfCare.tests[testIndex].dxCodes
        ? episodeOfCare.tests[testIndex].dxCodes!
        : []

      const dxCodesListCodes = surgeryCodeDtos.map(
        (surgeryCodeDto: DxCodeDto) => surgeryCodeDto.code!
      )

      this.props.getAllDxCodes!(dxCodesListCodes).then(() => {
        const dxCodes = this.props.dxCodes!.filter(
          (idxCode) =>
            surgeryCodeDtos.find(
              (surgeryCodeDto: DxCodeDto) =>
                surgeryCodeDto.code!.trim() === idxCode.code.trim() &&
                idxCode.description !== ''
            ) !== undefined
        )

        const orderedDescriptions = dxCodes
          .sort((idxCode1, idxCode2) =>
            dxCodesListCodes.indexOf(idxCode1.code) >
            dxCodesListCodes.indexOf(idxCode2.code)
              ? 1
              : -1
          )
          .map((idxCode) => idxCode.description)
          .join('; ')

        setFieldValue(`tests[${testIndex}].diagnosis`, orderedDescriptions)
      })
    }
  }

  private cancel = () => {
    this.props.setSelectedEpisodeOfCare!(undefined)
    this.props.setSelectedReferral!(undefined)

    if (this.props.eocToDashboard) {
      this.props.setReturn!('/')
      this.props.resetEOC!()
    } else if (this.props.eocToQuotes) {
      this.props.setReturn!('/quotes')
      this.props.resetEOC!()
    } else if (sessionStorage.getItem('eocTab') === '1') {
      this.props.setReturn!()
    } else if (this.props.returnRoutePath === undefined) {
      this.props.setReturn!(`/patients/patient/${this.props.match.params.patientId}`)
    }
    this.props.closeTab!()
  }

  public getValue(item: string) {
    return item
  }

  public getUserName(item: IUser) {
    return item ? item.name : ''
  }

  public getUserValue(item: IUser) {
    return item ? item.id : ''
  }

  public getBodyPartName(bodyPart: IBodyPartDto) {
    return bodyPart ? bodyPart.inactiveName! : ''
  }

  public getBodyPartId(bodyPart: IBodyPartDto) {
    return bodyPart ? bodyPart.id! : ''
  }

  private handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload }, () => {
      submitForm()
    })
  }

  private handleDetailsChange = (panel: string) => (__: React.MouseEvent) => {
    this.setState({
      expandedDetailsPanel: panel === this.state.expandedDetailsPanel ? '' : panel,
    })
  }

  private handleScheduleChange = (panel: string) => (__: React.MouseEvent) => {
    this.setState({
      expandedSchedulePanel: panel === this.state.expandedSchedulePanel ? '' : panel,
    })
  }

  private handleLogChange = (panel: string) => (__: React.MouseEvent) => {
    this.setState({
      expandedLogPanel: panel === this.state.expandedLogPanel ? '' : panel,
    })
  }

  private generateEmailClick = (values: IEpisodeOfCareFormValues) => () => {
    logEOCEmailGeneration(values.id, this.state.generateEmail)
  }

  private handleGenerateEmailChange = (event: any) => {
    this.setState({ generateEmail: event.target.value })
    generateEOCEmail(this.props.episodeOfCare!.id, event.target.value).then((result) => {
      this.setState({ emailContent: result })
    })
  }

  public render() {
    const {
      bodyParts = [],
      currentAppOrganization,
      episodeOfCare,
      users,
      isCodeCheckModalOpen,
      newCptCodesToSave,
      newDxCodesToSave,
      procedures,
      referringDoctors,
      isReferralContactModalOpen,
    } = this.props
    const hasValue = (field: string, values: IEpisodeOfCareFormValues) => {
      const value = getIn(values, field)

      return !!value
    }
    const title =
      episodeOfCare && episodeOfCare.isNew
        ? 'Add New Episode of Care'
        : 'Edit Episode of Care'
    const shortenedTitle =
      episodeOfCare && episodeOfCare.isNew ? 'Add New EOC' : 'Edit EOC'

    const newCodeFormValues = {
      cptCodes: newCptCodesToSave
        ? newCptCodesToSave.map((x) => ({ code: x.code }) as ICptCode)
        : [],
      dxCodes: newDxCodesToSave
        ? newDxCodesToSave.map((x) => ({ code: x.code }) as ICptCode)
        : [],
    } as INewSurgeryCodeFormValues

    const readonly =
      episodeOfCare && currentAppOrganization
        ? episodeOfCare.isNew
          ? false
          : episodeOfCare.organizationId !== currentAppOrganization!.id
        : true

    return (
      <>
        <Helmet>
          <title>
            {shortenedTitle +
              ': ' +
              this.props.patient?.lastName +
              ', ' +
              this.props.patient?.firstName +
              ' (' +
              moment(this.props.patient?.dateOfBirth).format('MM/DD/YYYY') +
              ')' +
              ' ' +
              this.props.episodeOfCare?.episodeOfCareNumberOrLegacyId}
          </title>
        </Helmet>
        {episodeOfCare && (
          <>
            {isReferralContactModalOpen && (
              <ReferralContactModal
                selectedReferralId={this.props.match.params.referralId}
              />
            )}
            <Formik
              initialValues={createIEpisodeOfCareFormValues(episodeOfCare)}
              onSubmit={this.save}
              validationSchema={
                currentAppOrganization!.id === OrganizationId.Surgical
                  ? EpisodeOfCareSurgicalSchema
                  : EpisodeOfCareDiagnosticSchema
              }
              enableReinitialize={true}
            >
              {({
                isValid,
                values,
                setFieldValue,
                errors,
                submitForm,
                dirty,
                submitCount,
              }) => (
                <Form>
                  <Prompt
                    when={dirty && submitCount === 0}
                    message="Are you sure you want to leave the page? There are unsaved changes."
                  />
                  {this.state.isLoading && (
                    <div
                      style={{
                        position: 'fixed',
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '1200',
                      }}
                    >
                      <CircularProgress size={100} />
                    </div>
                  )}
                  <AppBar style={{ boxShadow: 'none' }} position="sticky">
                    <ResponsiveAppBar title={title} pageIcon={<Assignment />}>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                      >
                        <Grid item>
                          <Button size="small" variant="contained" onClick={this.cancel}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            color="secondary"
                            size="small"
                            onClick={this.handleSave(submitForm, true)}
                            variant="contained"
                            disabled={readonly || !isValid || this.state.isEOCSaved}
                          >
                            Save
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            disabled={readonly || !isValid || this.state.isEOCSaved}
                            onClick={this.handleSave(submitForm, false)}
                          >
                            Save and Close
                          </Button>
                        </Grid>
                      </Grid>
                    </ResponsiveAppBar>
                    <PatientActionBar isDemographicsVisible={true} />
                  </AppBar>
                  <Grid>
                    <Grid item xs={12}>
                      <CardWithNoTitle>
                        <Grid container direction="row" spacing={2}>
                          <Grid item container xs={12}>
                            <Grid item container direction="row" spacing={2}>
                              <Grid item>
                                <Typography
                                  color="primary"
                                  component="h6"
                                  variant="h6"
                                  style={{ paddingBottom: '20px' }}
                                >
                                  EOC/Legacy:&nbsp;
                                  {episodeOfCare.episodeOfCareNumberOrLegacyId}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  style={{ whiteSpace: 'nowrap' }}
                                  onClick={this.props.openReferralContactModal}
                                >
                                  Referral Contact
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid item container direction="row" spacing={2}>
                              {(episodeOfCare.isNew &&
                                currentAppOrganization!.id === OrganizationId.Surgical) ||
                              (!episodeOfCare.isNew &&
                                episodeOfCare.organizationId ===
                                  OrganizationId.Surgical) ? (
                                <Grid item lg={2} md={6} xs={12}>
                                  <FullWidthField
                                    label="Body Part"
                                    name="bodyPartText"
                                    errorMessage={errors.bodyPartText}
                                    shrinkLabel={true}
                                    variant={'outlined'}
                                    disabled={readonly}
                                    required={
                                      currentAppOrganization!.id ===
                                      OrganizationId.Surgical
                                    }
                                  />
                                </Grid>
                              ) : (
                                <></>
                              )}
                              <Grid item lg={3} md={6} xs={12}>
                                <TypeAheadField
                                  outlined
                                  label="Referring Doctor"
                                  labelShrink={hasValue('referringDoctorId', values)}
                                  inputId="referringDoctorId"
                                  name="referringDoctor"
                                  cypressLabel="referringDoctor"
                                  fullWidth
                                  setFieldValue={this.setValue(setFieldValue)}
                                  initialValue={this.getInitialReferringDoctor(
                                    values.referringDoctorId
                                  )}
                                  selectedValue={this.getSelectedReferringDoctor(values)}
                                  items={referringDoctors}
                                  getName={this.getReferringDoctorName}
                                  getValue={this.getReferringDoctorValue}
                                  errorMessage={errors.referringDoctorId}
                                  getItems={this.props.getReferringDoctors}
                                  disabled={readonly}
                                />
                              </Grid>
                              <Grid item lg={2} md={6} xs={12}>
                                {readonly ? (
                                  <FullWidthField
                                    variant="outlined"
                                    name="careCoordinatorName"
                                    label="Care Coordinator"
                                    disabled
                                  />
                                ) : (
                                  <SelectField
                                    inputId="careCoordinatorId"
                                    getName={this.getUserName}
                                    getValue={this.getUserValue}
                                    items={users}
                                    label="Care Coordinator"
                                    name="careCoordinatorId"
                                    fullWidth
                                    errorMessage={errors.careCoordinatorId}
                                    required={true}
                                    shrinkLabel={true}
                                    outlined
                                    disabled={readonly}
                                    disableInactive={true}
                                  />
                                )}
                              </Grid>
                              <Grid item lg={2} md={4} xs={6}>
                                <DatePickerField
                                  name="effectiveDate"
                                  label="Takeover of Care Date"
                                  inputId="effectiveDate"
                                  required={values.invoiceSurgeryStatus !== ''}
                                  clearable
                                  shrink={hasValue('effectiveDate', values)}
                                  fullWidth
                                  variant="outlined"
                                  disabled={readonly}
                                />
                              </Grid>
                              {currentAppOrganization!.id === OrganizationId.Surgical ? (
                                <Grid
                                  container
                                  direction="column"
                                  item
                                  lg={3}
                                  md={6}
                                  xs={12}
                                  spacing={1}
                                  style={{ paddingRight: '20px' }}
                                >
                                  <Grid item container direction="row" spacing={1}>
                                    <Grid item xs={8}>
                                      <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                          shrink={true}
                                          htmlFor="generate-email-select"
                                          variant="outlined"
                                        >
                                          Email Type
                                        </InputLabel>
                                        <Select
                                          fullWidth
                                          value={this.state.generateEmail}
                                          id="generateEmail"
                                          variant="outlined"
                                          native={true}
                                          onChange={this.handleGenerateEmailChange}
                                          input={
                                            <OutlinedInput
                                              id="generate-email-select"
                                              name="generateEmail"
                                              notched
                                              labelWidth={80}
                                            />
                                          }
                                          inputProps={{
                                            label: true,
                                            notched: true,
                                          }}
                                        >
                                          <option value={EOCEmailType.NewSurgeryEmail}>
                                            New Surgery Email
                                          </option>
                                          <option value={EOCEmailType.NewEOCEmail}>
                                            New EOC Email
                                          </option>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Tooltip title="Generate Email">
                                        <Button
                                          fullWidth
                                          size="small"
                                          color="secondary"
                                          disabled={
                                            (+this.state.generateEmail ===
                                              EOCEmailType.NewSurgeryEmail &&
                                              values.invoiceSurgeryStatus !==
                                                EOCStatus.SurgicalBundle &&
                                              values.invoiceSurgeryStatus !==
                                                EOCStatus.InjectionBundle) ||
                                            dirty
                                          }
                                          variant="contained"
                                          href={this.state.emailContent}
                                          onClick={this.generateEmailClick(values)}
                                        >
                                          <EmailOutlined />
                                        </Button>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : (
                                <></>
                              )}
                              <Grid
                                item
                                container
                                direction="row"
                                xs={5}
                                style={{ paddingLeft: '24px' }}
                              >
                                {this.props.episodeOfCare?.quote && (
                                  <Grid item xs={12} style={{ paddingBottom: '10px' }}>
                                    <strong>
                                      <pre>{this.state.quoteSummary}</pre>
                                    </strong>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    padding: '0px',
                                  }}
                                >
                                  <SelectField
                                    inputId="invoiceSurgeryStatus"
                                    getName={this.getValue}
                                    getValue={this.getValue}
                                    items={
                                      (episodeOfCare.isNew &&
                                        currentAppOrganization!.id ===
                                          OrganizationId.Surgical) ||
                                      (!episodeOfCare.isNew &&
                                        episodeOfCare.organizationId ===
                                          OrganizationId.Surgical)
                                        ? [
                                            EOCStatus.Cancelled,
                                            EOCStatus.Evaluation,
                                            EOCStatus.CaseClosed,
                                            EOCStatus.InjectionBundle,
                                            EOCStatus.NonSurgical,
                                            EOCStatus.NonSurgicalBundle,
                                            EOCStatus.SurgicalBundle,
                                            EOCStatus.SxPostponed,
                                          ]
                                        : [
                                            DiagnosticEOCStatus.BadDebt,
                                            DiagnosticEOCStatus.Cancelled,
                                            DiagnosticEOCStatus.CombinedOther,
                                            DiagnosticEOCStatus.CourtesySchedule,
                                            DiagnosticEOCStatus.DirectPay,
                                            DiagnosticEOCStatus.Misc,
                                            DiagnosticEOCStatus.PaidByCompany,
                                          ]
                                    }
                                    label="Episode of Care Status"
                                    name="invoiceSurgeryStatus"
                                    shrinkLabel={true}
                                    outlined
                                    disabled={readonly}
                                    fullWidth
                                  />
                                  <Tooltip title="View Quote" placement="right-start">
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      size="small"
                                      disabled={
                                        readonly ||
                                        !episodeOfCare.quoteId ||
                                        !episodeOfCare.quote!.isActive
                                      }
                                      style={{
                                        whiteSpace: 'nowrap',
                                        marginLeft: '8px',
                                        width: '150px',
                                      }}
                                      onClick={this.navigateToQuote(
                                        episodeOfCare.quoteId!
                                      )}
                                    >
                                      <List /> View Quote
                                    </Button>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                            {!episodeOfCare.isNew &&
                            episodeOfCare.organizationId === OrganizationId.Diagnostic ? (
                              <Grid
                                item
                                container
                                direction="row"
                                xs={12}
                                className={this.props.classes.checklistPanel}
                              >
                                <Field
                                  name="calledPatientFlag"
                                  Label={{
                                    label: 'Called Patient',
                                  }}
                                  component={CheckboxWithLabel}
                                  disabled={readonly}
                                />
                                <Field
                                  name="noAnswerFlag"
                                  Label={{
                                    label: 'No Answer',
                                  }}
                                  component={CheckboxWithLabel}
                                  disabled={readonly}
                                />
                                <Field
                                  name="leftVoicemailFlag"
                                  Label={{
                                    label: 'Left Voicemail',
                                  }}
                                  component={CheckboxWithLabel}
                                  disabled={readonly}
                                />
                                <Field
                                  name="sentTextFlag"
                                  Label={{
                                    label: 'Sent Text',
                                  }}
                                  component={CheckboxWithLabel}
                                  disabled={readonly}
                                />
                                <Field
                                  name="readyToScheduleFlag"
                                  Label={{
                                    label: 'Ready to Schedule',
                                  }}
                                  component={CheckboxWithLabel}
                                  disabled={readonly}
                                />
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          {(episodeOfCare.organizationId == ''
                            ? currentAppOrganization!.id
                            : episodeOfCare.organizationId) ===
                          OrganizationId.Surgical ? (
                            <>
                              <div style={{ width: '100vw' }}>
                                <ExpansionPanel
                                  style={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                  }}
                                  expanded={
                                    this.state.expandedDetailsPanel === 'detailsPanel'
                                  }
                                  onChange={this.handleDetailsChange('detailsPanel')}
                                >
                                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography color="textSecondary" variant="body1">
                                      EPISODE OF CARE DETAILS
                                    </Typography>
                                  </ExpansionPanelSummary>
                                  <ExpansionPanelDetails>
                                    <Grid container xs={12} spacing={2}>
                                      <Grid item md={1} xs={3}>
                                        <SelectField
                                          inputId="mri"
                                          getName={this.getValue}
                                          getValue={this.getValue}
                                          items={['Yes', 'No']}
                                          label="MRI"
                                          name="mri"
                                          fullWidth
                                          shrinkLabel={true}
                                          outlined
                                          disabled={readonly}
                                        />
                                      </Grid>
                                      <Grid item md={1} xs={3}>
                                        <SelectField
                                          inputId="emg"
                                          getName={this.getValue}
                                          getValue={this.getValue}
                                          items={['Yes', 'No']}
                                          label="EMG"
                                          name="emg"
                                          fullWidth
                                          shrinkLabel={true}
                                          outlined
                                          disabled={readonly}
                                        />
                                      </Grid>
                                      <Grid item md={1} xs={3}>
                                        <SelectField
                                          inputId="xRay"
                                          getName={this.getValue}
                                          getValue={this.getValue}
                                          items={['Yes', 'No']}
                                          label="X-Ray"
                                          name="xRay"
                                          fullWidth
                                          shrinkLabel={true}
                                          outlined
                                          disabled={readonly}
                                        />
                                      </Grid>
                                      <Grid item md={1} xs={3}>
                                        <SelectField
                                          inputId="ctScan"
                                          getName={this.getValue}
                                          getValue={this.getValue}
                                          items={['Yes', 'No']}
                                          label="CT Scan"
                                          name="ctScan"
                                          fullWidth
                                          shrinkLabel={true}
                                          outlined
                                          disabled={readonly}
                                        />
                                      </Grid>
                                      <Grid item lg={2} md={3} xs={12}>
                                        <Field
                                          name="isClinicalNotesReceived"
                                          Label={{
                                            label: 'Clinical Notes Received',
                                          }}
                                          component={CheckboxWithLabel}
                                          disabled={readonly}
                                        />
                                      </Grid>
                                      <Grid item lg={2} md={3} xs={12}>
                                        <Field
                                          name="isPatientOnBoarding"
                                          Label={{
                                            label: 'Patient Onboarding',
                                          }}
                                          component={CheckboxWithLabel}
                                          disabled={readonly}
                                        />
                                      </Grid>
                                      <Grid item lg={3} md={3} xs={12}>
                                        <Field
                                          name="isNoPtToBeScheduled"
                                          Label={{
                                            label:
                                              'No PT to be Scheduled for this Patient',
                                          }}
                                          component={CheckboxWithLabel}
                                          disabled={readonly}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <CodeExpandingList
                                          type="cptCodes"
                                          disabled={readonly}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <CodeExpandingList
                                          type="dxCodes"
                                          disabled={readonly}
                                        />
                                      </Grid>
                                    </Grid>
                                  </ExpansionPanelDetails>
                                </ExpansionPanel>
                              </div>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12}>
                                <TestsContainer
                                  addDxCodeDescriptions={this.addDxCodeDescriptions}
                                  bodyParts={bodyParts}
                                  procedures={procedures}
                                  readonly={readonly}
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item container direction="row" spacing={2}>
                            <Grid item md={6} xs={12}>
                              <FullWidthField
                                variant="outlined"
                                name="notes"
                                label="EOC Notes"
                                fullWidth
                                rows={8}
                                multiline
                                disabled={readonly}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <FullWidthField
                                variant="outlined"
                                name="claimNotes"
                                label="Billing Notes"
                                fullWidth
                                rows={8}
                                multiline
                                disabled={readonly}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {episodeOfCare && !episodeOfCare.isNew ? (
                          <div style={{ width: '100vw' }}>
                            <ExpansionPanel
                              style={{ marginTop: '10px' }}
                              expanded={this.state.expandedLogPanel === 'logPanel'}
                              onChange={this.handleLogChange('logPanel')}
                            >
                              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography color="textSecondary" variant="body1">
                                  AUTHORIZATION AND CONTRACT LOG
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Grid
                                  container
                                  xs={12}
                                  spacing={1}
                                  style={{ paddingBottom: '10px' }}
                                >
                                  <AuthorizationLogGrid
                                    episodeOfCareId={episodeOfCare.id}
                                  />
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                              style={{ marginTop: '10px' }}
                              expanded={
                                this.state.expandedSchedulePanel === 'schedulePanel'
                              }
                              onChange={this.handleScheduleChange('schedulePanel')}
                            >
                              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography color="textSecondary" variant="body1">
                                  SCHEDULE INFORMATION
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Grid
                                  container
                                  xs={12}
                                  spacing={1}
                                  style={{ paddingBottom: '10px' }}
                                >
                                  <SchedulingTable
                                    episodeOfCareId={episodeOfCare.id}
                                    bodyPart={episodeOfCare.bodyPartText}
                                  />
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </div>
                        ) : (
                          <></>
                        )}
                      </CardWithNoTitle>
                    </Grid>
                  </Grid>

                  {isCodeCheckModalOpen ? (
                    this.codeDialog(
                      newCodeFormValues,
                      isCodeCheckModalOpen,
                      episodeOfCare
                    )
                  ) : (
                    <></>
                  )}
                </Form>
              )}
            </Formik>
          </>
        )}
      </>
    )
  }

  private codeDialog = (
    initialValues: INewSurgeryCodeFormValues,
    isCodeCheckModalOpen: boolean,
    episodeOfCare: IEpisodeOfCare
  ) => {
    return (
      <ESDialog
        close={this.props.closeCodeCheckModal!}
        initialValues={initialValues}
        open={isCodeCheckModalOpen!}
        save={this.saveNewCodes(episodeOfCare)}
        title={'Code(s) Not Found'}
        maxWidth="sm"
        titleIcon={<Code />}
        closeButtonText="Not Now"
        disableBackdropClick={true}
        isInitialValid={true}
      >
        {({ values }) => (
          <Grid container direction="column" spacing={3} style={{ paddingTop: '20px' }}>
            <Grid item xs={12}>
              <span>
                One or more of the CPT and DX Codes used was not found in the database.
                Please enter a description for each missing code below to add the code and
                description to the database.
              </span>
            </Grid>
            <Grid item xs={12}>
              <h2>CPT Codes</h2>
            </Grid>
            {values.cptCodes.map((x, idx) => {
              return (
                <Grid key={x.code} item xs={12}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={4}>
                      <FullWidthField
                        autoFocus
                        InputLabelProps={{ shrink: true }}
                        name={`cptCodes[${idx}].code`}
                        label={idx === 0 ? 'CPT Code' : undefined}
                        disabled
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <FullWidthField
                        InputLabelProps={{ shrink: true }}
                        name={`cptCodes[${idx}].description`}
                        label={idx === 0 ? 'Description' : undefined}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
            <Grid item xs={12}>
              <h2>DX Codes</h2>
            </Grid>
            {values.dxCodes.map((x, idx) => {
              return (
                <Grid key={x.code} item xs={12}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={4}>
                      <FullWidthField
                        InputLabelProps={{ shrink: true }}
                        name={`dxCodes[${idx}].code`}
                        label={idx === 0 ? 'DX Code' : undefined}
                        disabled
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <FullWidthField
                        InputLabelProps={{ shrink: true }}
                        name={`dxCodes[${idx}].description`}
                        label={idx === 0 ? 'Description' : undefined}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        )}
      </ESDialog>
    )
  }

  private saveNewCodes =
    (episodeOfCare: IEpisodeOfCare) =>
    async (
      values: INewSurgeryCodeFormValues,
      formikBag: FormikActions<INewSurgeryCodeFormValues>
    ) => {
      const cptCodes = values.cptCodes
        .filter((x) => x.description !== undefined && x.description !== '')
        .map(
          (x) =>
            new CptCodeDto({
              ...DefaultCptCode(true),
              code: x.code,
              description: x.description,
            })
        )
      const dxCodes = values.dxCodes
        .filter((x) => x.description !== undefined && x.description !== '')
        .map(
          (x) =>
            new DxCodeDto({
              ...DefaultDxCode(true),
              code: x.code,
              description: x.description,
            })
        )

      const promises = [] as Array<Promise<void>>

      cptCodes.forEach((x) => {
        promises.push(this.props.saveCptCode!({ ...x } as ICptCode))
      })

      dxCodes.forEach((x) => {
        promises.push(this.props.saveDxCode!({ ...x } as IDxCode))
      })

      await Promise.all(promises).then(() => {
        formikBag.setSubmitting(false)
        this.props.closeCodeCheckModal!()
        if (this.state.reload) {
          this.props.rerouteToPath!(
            `/patients/patient/${this.props.match.params.patientId}/referral/${this.props.match.params.referralId}/episodeOfCare/${episodeOfCare.id}`
          )
          this.setup()
        } else {
          this.cancel()
        }
      })
    }

  private navigateToQuote = (quoteId: string) => () => {
    window.open(`/workflow/quote/${quoteId}/default-inbox`, '_blank')
  }
}

const InjectedEditEpisodeOfCare = inject<
  IStores,
  IEditEpisodeOfCareProps,
  Partial<IEditEpisodeOfCareProps>,
  any
>((stores: IStores) => ({
  bodyParts: stores.patientEdit.bodyParts,
  closeCodeCheckModal: stores.patientEdit.closeCodeCheckModal,
  closeTab: stores.global.closeTab,
  cptCodes: stores.cptCodes.cptCodes,
  currentAppOrganization: stores.global.currentAppOrganization,
  dxCodes: stores.dxCodes.dxCodes,
  episodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  getAllCptCodes: stores.cptCodes.getAllCptCodes,
  getAllDxCodes: stores.dxCodes.getAllDxCodes,
  getAllProcedures: stores.procedures.getAllProcedures,
  getAllUsers: stores.users.getAllUsers,
  getEpisodeOfCareById: stores.patientEdit.getEpisodeOfCareById,
  getPatientById: stores.patients.getPatientById,
  getReferralById: stores.referrals.getReferralWithContactsById,
  getReferringDoctorById: stores.referringDoctors.getReferringDoctorById,
  getReferringDoctors: stores.referringDoctors.getAllReferringDoctors,
  isCodeCheckModalOpen: stores.patientEdit.isCodeCheckModalOpen,
  loadBodyParts: stores.patientEdit.getBodyParts,
  loadSchedules: stores.schedules.getColumnSettingsAndSchedules,
  newCptCodesToSave: stores.cptCodes.newCptCodesToSave,
  newDxCodesToSave: stores.dxCodes.newDxCodesToSave,
  openCodeCheckModal: stores.patientEdit.openCodeCheckModal,
  procedures: stores.procedures.procedures,
  referral: stores.referrals.selectedReferral,
  referringDoctors: stores.referringDoctors.referringDoctors,
  returnRoutePath: stores.global.returnRoutePath,
  rerouteToPath: stores.global.rerouteToPath,
  saveCptCode: stores.cptCodes.saveCptCode,
  saveDxCode: stores.dxCodes.saveDxCode,
  saveEpisodeOfCare: stores.patientEdit.saveEpisodeOfCare,
  setNewCptCodesToSave: stores.cptCodes.setNewCptCodesToSave,
  setNewDxCodesToSave: stores.dxCodes.setNewDxCodesToSave,
  setPageSize: stores.communications.setPageSize,
  setReturn: stores.global.setReturn,
  setSelectedEpisodeOfCare: stores.patientEdit.setSelectedEpisodeOfCare,
  setSelectedPatient: stores.patients.setSelectedPatient,
  patient: stores.patients.selectedPatient,
  setSelectedReferral: stores.referrals.setSelectedReferral,
  users: stores.users.users,
  eocToDashboard: stores.schedules.eocToDashboard,
  eocToQuotes: stores.schedules.eocToQuotes,
  resetEOC: stores.schedules.resetEOC,
  openReferralContactModal: stores.patientEdit.openReferralContactModal,
  isReferralContactModalOpen: stores.patientEdit.isReferralContactModalOpen,
}))(EditEpisodeOfCare)

export default withStyles(styles)(InjectedEditEpisodeOfCare)
