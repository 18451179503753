import Axios from 'axios'
import { CMS1500Dto } from '../viewModels/claim/Cms1500Dto'
import { AppealType } from 'src/utils/AppealType'
import createPdfObjUrl from 'src/utils/CreatePdfObjUrl'
import { AppealLogDTO } from 'src/viewModels/AppealLogDTO'
import { AppealLogSearchRequest } from 'src/viewModels/AppealLogSearchRequest'
import { IApplyPaymentRequest } from 'src/viewModels/ApplyPaymentRequest'
import { BundleQuoteSummary } from 'src/viewModels/BundleQuoteSummary'
import { pagedList } from 'src/viewModels/pagedList'
import { IPaymentDetailSummaryResult } from 'src/viewModels/PaymentDetailSummaryResult'
import { IClaimSearchRequest } from '../viewModels/ClaimSearchRequest'
import { ClaimHeaderDetailsSummaryResult } from 'src/viewModels/ClaimHeaderDetailsSummaryResult'
import { LineItemSummaryResult } from 'src/viewModels/LineItemSummaryResult'
import { ProcessReceivedCheckResults } from 'src/viewModels/ProcessReceivedCheckResult'
import { IClaimLastHistorySummaryResult } from 'src/viewModels/quotes/ClaimLastHistorySummaryResult'
import { ISentCheckSummaryResult } from 'src/viewModels/SentCheckSummaryResult'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function searchClaims(req: IClaimSearchRequest): Promise<pagedList> {
  const response = await Axios.post<pagedList>(`${base_url}/api/claim/search`, req)

  return response.data
}

export async function applyPayments(
  req: IApplyPaymentRequest[]
): Promise<ISentCheckSummaryResult[]> {
  const response = await Axios.post<ISentCheckSummaryResult[]>(
    `${base_url}/api/claim/apply-payments`,
    req
  )
  return response.data
}

export async function getClaimPaymentDetails(
  req: string
): Promise<IPaymentDetailSummaryResult> {
  const response = await Axios.get<IPaymentDetailSummaryResult>(
    `${base_url}/api/claim/${req}/payment-details`
  )

  return response.data
}

export async function reviewClaim(id: string): Promise<string> {
  const response = await Axios.post<string>(`${base_url}/api/claim/${id}/review`)
  window.open(createPdfObjUrl(response.data), '_blank')
  return response.data
}

export async function RemoveClaimFromGlobal(id: string): Promise<string> {
  const response = await Axios.post<string>(`${base_url}/api/claim/${id}/quote-remove`)
  return response.data
}

export async function AddClaimToGlobal(id: string): Promise<string> {
  const response = await Axios.post<string>(`${base_url}/api/claim/${id}/quote-add`)
  return response.data
}

export async function getQuoteTotals(id: string): Promise<BundleQuoteSummary> {
  const response = await Axios.get<BundleQuoteSummary>(
    `${base_url}/api/eoc/${id}/quote-totals`
  )
  return response.data
}

export async function CopyClaim(id: string, type: AppealType): Promise<CMS1500Dto> {
  const response = await Axios.get<CMS1500Dto>(`${base_url}/api/claim/${id}/appeal`, {
    params: {
      type: type,
    },
  })
  return response.data
}

export async function GetAppealLogByClaimId(
  claimId: string,
  req?: AppealLogSearchRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/claim/${claimId}/appeal-log`,
    req
  )
  return response.data
}
export async function AddAppealNote(req: AppealLogDTO): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/claim/${req.originalCMS1500Id}/appeal-note`,
    req
  )
  return response.data
}

export async function getHeaderInfo(
  id: string
): Promise<ClaimHeaderDetailsSummaryResult> {
  const response = await Axios.get<ClaimHeaderDetailsSummaryResult>(
    `${base_url}/api/claim/${id}/eoc-patient-detail`
  )

  return response.data
}

export async function retrieveRefundLineItemsWithBalance(
  req: string
): Promise<LineItemSummaryResult[]> {
  const response = await Axios.get<LineItemSummaryResult[]>(
    `${base_url}/api/claim/${req}/refund-line-item-with-balance`
  )

  return response.data
}

export async function applyRefundPayment(
  req: IApplyPaymentRequest
): Promise<ProcessReceivedCheckResults> {
  const response = await Axios.post<ProcessReceivedCheckResults>(
    `${base_url}/api/claim/${req.cms1500Id}/apply-refund`,
    req
  )
  return response.data
}

export async function getClaimLastUpdateHistory(
  req: string
): Promise<IClaimLastHistorySummaryResult> {
  const response = await Axios.get<IClaimLastHistorySummaryResult>(
    `${base_url}/api/claim/${req}/last-update-history`
  )

  return response.data
}

export async function markClaimDeleted(id: string): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/claim/${id}/mark-claim-deleted`
  )
  return response.data
}
