import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { Formik, FormikProps } from 'formik'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { ChangeEvent } from 'react'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { IStores } from 'src/Stores'
import FullWidthField from '../common/FullWidthField'
import SelectField from '../common/SelectField/SelectField'
import { IAppointmentTask } from '../Definitions'
import {
  ActiveAppointmentTaskSchema,
  createIAppointmentTaskFormValues,
  IAppointmentTaskFormValues,
  InactiveAppointmentTaskSchema,
  toAppointmentTask,
} from './AppointmentTaskFormValues'

const styles = ({ spacing }: Theme) =>
  createStyles({
    topOfPage: {
      marginLeft: spacing(3),
      marginRight: spacing(3),
      marginTop: spacing(11),
      padding: spacing(2),
    },
  })

interface IAppointmentTaskDialogProps extends WithStyles<typeof styles> {
  close?: () => void
  appointmentTask?: IAppointmentTask
  appointmentTasks?: IAppointmentTask[]
  isLoading?: boolean
  isOpen?: boolean
  saveAppointmentTask?: (appointmentTask: IAppointmentTask) => void
}
@observer
class AppointmentTaskDialog extends React.Component<IAppointmentTaskDialogProps> {
  public getValue(item: string) {
    return item
  }

  public state = {
    isActive: true,
  }
  public displayOrder = 1
  public componentDidMount(): void {
    if (this.props.appointmentTask && this.props.appointmentTask.isNew) {
      this.displayOrder =
        this.props.appointmentTasks && this.props.appointmentTasks.length > 0
          ? this.props.appointmentTasks.sort((x) => x.displayOrder!).reverse()[0]
              .displayOrder! + 1
          : 1
    }
  }

  public secondFormRef = React.createRef<any>()

  public render() {
    const initialValues = createIAppointmentTaskFormValues(
      this.props.appointmentTask,
      this.displayOrder
    )
    const { appointmentTask, isOpen = false } = this.props

    const validationSchema = this.state.isActive
      ? ActiveAppointmentTaskSchema
      : InactiveAppointmentTaskSchema

    const modalTitle =
      appointmentTask && appointmentTask.isNew
        ? 'Add New Appointment Task'
        : 'Edit Appointment Task'
    return (
      <Dialog fullWidth={true} maxWidth="sm" open={isOpen!}>
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title={modalTitle}
            pageIcon={<CheckCircle />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={3} style={{ marginTop: 8 }}>
            <Formik
              initialValues={initialValues}
              onSubmit={() => {}}
              validationSchema={validationSchema}
              ref={this.secondFormRef}
            >
              {(formikProps: FormikProps<IAppointmentTaskFormValues>) => (
                <>
                  <Grid container item direction="row" spacing={2}>
                    <Grid item xs={12}>
                      <FullWidthField
                        variant="outlined"
                        autoFocus
                        name="name"
                        label="Task Name"
                        required
                        disabled={appointmentTask && appointmentTask.isLocked}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" spacing={2}>
                    <Grid item xs={4}>
                      <FormControl style={{ width: '100%' }} variant="outlined">
                        <InputLabel shrink={true} variant="outlined">
                          Priority
                        </InputLabel>
                        <Select
                          variant="outlined"
                          input={<OutlinedInput notched labelWidth={55} />}
                          inputProps={{ label: true, notched: true }}
                          name="priority"
                          native={true}
                          fullWidth
                          required
                          onChange={(event: any) =>
                            this.setPrioritySelect(event, formikProps)
                          }
                          id="priority"
                          value={formikProps.values.priority}
                        >
                          <option value={0}>Low</option>
                          <option value={1}>Medium</option>
                          <option value={2}>High</option>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" spacing={2}>
                    <Grid item xs={4} style={{ textAlign: 'center', paddingTop: '26px' }}>
                      Should be completed
                    </Grid>
                    <Grid item xs={2}>
                      <FullWidthField variant="outlined" name="days" label="Days" />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectField
                        inputId="timing"
                        getName={this.getValue}
                        getValue={this.getValue}
                        items={['Before Appointment Date', 'After Appointment Date']}
                        label="Timing"
                        name="timing"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" spacing={2}>
                    <Grid item xs={3}>
                      <FullWidthField
                        variant="outlined"
                        name="displayOrder"
                        label="Display Order"
                        disabled={appointmentTask && !appointmentTask.isActive}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            form="secondForm"
            color="primary"
            type="submit"
            onClick={() => this.save()}
          >
            Save
          </Button>
          <Button onClick={this.props.close} data-cy="close">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private getFormikValues = () => {
    const formikValues = this.secondFormRef.current?.state?.values
    this.secondFormRef.current.submitForm()
    return formikValues
  }

  public save = () => {
    const formikValues = this.getFormikValues()
    const appointmentTask = toAppointmentTask(formikValues, this.props.appointmentTask)

    this.props.saveAppointmentTask!(appointmentTask)
  }

  private setPrioritySelect = (
    event: ChangeEvent<HTMLSelectElement>,
    formikProps: FormikProps<IAppointmentTaskFormValues>
  ) => {
    this.setState({ selectedPriority: event.target.selectedOptions[0].value })
    formikProps.setFieldValue('priority', event.target.selectedOptions[0].value)
  }
}

const InjectedAppointmentTaskDialog = inject<
  IStores,
  IAppointmentTaskDialogProps,
  Partial<IAppointmentTaskDialogProps>,
  any
>((stores: IStores) => ({
  appointmentTask: stores.appointmentTasks.selectedAppointmentTask,
  appointmentTasks: stores.appointmentTasks.dataTableStore.data,
  close: stores.appointmentTasks.closeDialog,
  isLoading: stores.appointmentTasks.isLoading,
  isOpen: stores.appointmentTasks.isModalOpen,
  saveAppointmentTask: stores.appointmentTasks.saveAppointmentTask,
  title: stores.appointmentTasks.modalTitle,
}))(AppointmentTaskDialog)

export default withStyles(styles)(InjectedAppointmentTaskDialog)
