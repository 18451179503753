import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from 'src/Stores'
import CreateQuote from 'src/quotes/CreateQuote'
import { IProviderTypeDto } from 'src/generated_client'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { RouteComponentProps } from 'react-router-dom'
import { QuoteStatus } from 'src/utils/QuoteStatus'
import ReconcileQuote from 'src/quotes/ReconcileQuote'
import { CircularProgress } from '@material-ui/core'
import { QuoteInboxItem } from 'src/viewModels/workflow/QuoteInboxItem'

interface IRenderQuoteRouteParams {
  quoteId: string
  inboxId: string
}

interface IRenderQuoteProps extends RouteComponentProps<IRenderQuoteRouteParams> {
  getAllUsers?: (pageSize?: number, roleFilter?: string) => Promise<void>
  getLineItemsTotals?: (lineItems: any) => number
  getProviderTypes?: () => Promise<void>
  getTypesByCategory?: (type: string) => Promise<void>
  loadQuote?: (quoteId: string, reload?: boolean) => Promise<void>
  providerTypes?: IProviderTypeDto[]
  rerouteToPath?: (path: string) => void
  selectedQuote?: IQuoteDTO
  openModal?: (quote: IQuoteDTO, modalSwitch: string) => void
  openStatusModal?: (quote: IQuoteDTO) => void
  openReconcileModal?: (quote: IQuoteDTO, modalSwitch: string) => void
  assistantWarning?: boolean
  openReconModal?: (
    quote: IQuoteDTO,
    modalSwitch: string,
    existingQuoteLineId?: string
  ) => void
  isAdjusterEmailModalOpen?: boolean
  setAsViewOnly?: boolean
  createInvoice?: (quoteInboxItem: QuoteInboxItem, actionId: number) => void
  quoteInboxItem: QuoteInboxItem
}

class RenderQuote extends React.Component<IRenderQuoteProps> {
  public async componentDidMount(): Promise<void> {
    if (this.props.match.params.quoteId) {
      await this.props.loadQuote!(this.props.match.params.quoteId)
    }
  }

  public render() {
    if (this.props.selectedQuote && this.props) {
      if (
        this.props.selectedQuote?.status == QuoteStatus.NeedsRecon ||
        this.props.selectedQuote?.status == QuoteStatus.Reconciled
      ) {
        return (
          <ReconcileQuote
            getAllUsers={this.props.getAllUsers}
            getLineItemsTotals={this.props.getLineItemsTotals}
            getProviderTypes={this.props.getProviderTypes}
            getTypesByCategory={this.props.getTypesByCategory}
            loadQuote={this.props.loadQuote}
            providerTypes={this.props.providerTypes}
            rerouteToPath={this.props.rerouteToPath}
            selectedQuote={this.props.selectedQuote}
            openReconcileModal={this.props.openReconcileModal}
            openReconModal={this.props.openReconModal}
            assistantWarning={this.props.assistantWarning}
            isAdjusterEmailModalOpen={this.props.isAdjusterEmailModalOpen}
            history={this.props.history}
            location={this.props.location}
            match={this.props.match}
            setAsViewOnly={this.props.setAsViewOnly}
            createInvoice={this.props.createInvoice}
            quoteInboxItem={this.props.quoteInboxItem}
          />
        )
      }
      return (
        <CreateQuote
          getAllUsers={this.props.getAllUsers}
          getLineItemsTotals={this.props.getLineItemsTotals}
          getProviderTypes={this.props.getProviderTypes}
          getTypesByCategory={this.props.getTypesByCategory}
          loadQuote={this.props.loadQuote}
          providerTypes={this.props.providerTypes}
          rerouteToPath={this.props.rerouteToPath}
          selectedQuote={this.props.selectedQuote}
          openModal={this.props.openModal}
          assistantWarning={this.props.assistantWarning}
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
          setAsViewOnly={this.props.setAsViewOnly}
          quoteInboxItem={this.props.quoteInboxItem}
        />
      )
    }
    return (
      <>
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '2',
          }}
        >
          <CircularProgress size={100} />
        </div>
      </>
    )
  }
}

const InjectedRenderQuote = inject<
  IStores,
  IRenderQuoteProps,
  Partial<IRenderQuoteProps>,
  any
>((stores: IStores) => ({
  getAllUsers: stores.users.getAllUsers,
  getLineItemsTotals: stores.quotes.getLineItemsTotal,
  getProviderTypes: stores.quotes.getProviderTypes,
  getTypesByCategory: stores.quotes.getTypesByCategory,
  loadQuote: stores.quotes.loadQuote,
  providerTypes: stores.quotes.providerTypes,
  rerouteToPath: stores.global.rerouteToPath,
  selectedQuote: stores.quotes.selectedQuote,
  openModal: stores.quotes.openQuoteLineModal,
  openReconModal: stores.quotes.openReconcileQuoteLineModal,
  assistantWarning: stores.quotes.assistantWarning,
  createInvoice: stores.quotes.createInvoice,
}))(RenderQuote)

export default InjectedRenderQuote
