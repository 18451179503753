import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from '@material-ui/core'
import { inject } from 'mobx-react'
import React from 'react'
import { IEpisodeOfCare } from 'src/Definitions'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { processAction, processDynamicAction } from 'src/services/WorkflowService'
import { QuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import { ObjectiveProcessAction } from 'src/viewModels/workflow/ObjectiveProcessAction'

interface ISetBundleDurationDaysModalProps {
  closeModal: () => void
  isOpen: boolean
  reloadParent?: () => void
  action: InboxActionSummary
  isDynamicItem: boolean
  inboxItemId: number
  rerouteToPath?: (path: string) => void
  associatedItemId: string
  inboxId: string
  handleProcessActionFinish?: (selectedAction?: InboxActionSummary) => void
  data: ObjectiveProcessAction
  selectedQuote?: QuoteDTO
  selectedEpisodeOfCare?: IEpisodeOfCare
}

class SetBundleDurationDaysModal extends React.Component<ISetBundleDurationDaysModalProps> {
  public state = {
    loading: true,
    executed: false,
    comments: '',
    bundleDurationDays: undefined,
  }

  componentDidMount(): void {
    this.setBundleDurationDays = this.setBundleDurationDays.bind(this)
    this.setState({
      bundleDurationDays: this.props.selectedQuote?.bundleDurationDays,
      loading: false,
    })
  }

  private setBundleDurationDays(event: any) {
    this.setState({ bundleDurationDays: event.target.value })
  }

  public render() {
    const { isOpen, associatedItemId } = this.props

    return (
      <Dialog key={associatedItemId} fullWidth={true} maxWidth="sm" open={isOpen!}>
        {this.state.loading && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar title={this.props.action.actionName} isModal={true} />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" xs={12} style={{ paddingTop: '10px' }}>
            <form>
              <Grid item sm={2} style={{ whiteSpace: 'nowrap', paddingBottom: '10px' }}>
                <FormControl fullWidth>
                  <TextField
                    inputProps={{ id: 'bundleDurationDays' }}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.setBundleDurationDays}
                    name="bundleDurationDays"
                    type="number"
                    size="small"
                    variant="outlined"
                    value={this.state.bundleDurationDays}
                    label="# of Days"
                    required
                  />
                </FormControl>
              </Grid>
              {this.props.action.displayComment && (
                <Grid item xs={12}>
                  <TextField
                    label="Comments"
                    name="notes"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required={this.props.action.requireComment}
                    multiline
                    minRows="4"
                    onChange={(event) => this.setState({ comments: event.target.value })}
                  />
                </Grid>
              )}
            </form>
          </Grid>
        </DialogContent>
        <DialogActions style={{ display: 'flex' }}>
          <Button
            onClick={this.save}
            color="primary"
            variant="contained"
            type="submit"
            disabled={
              this.state.executed ||
              (this.props.action.requireComment && this.state.comments === '') ||
              this.state.bundleDurationDays == '' ||
              this.state.bundleDurationDays == undefined
            }
          >
            Execute
          </Button>
          <Button onClick={this.close} data-cy="close" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  public secondFormRef = React.createRef<any>()

  private close = () => {
    this.setState({
      loading: true,
      executed: false,
      comments: '',
      bundleDurationDays: undefined,
    })
    this.props.closeModal!()
  }

  private save = async () => {
    this.setState({ loading: true, executed: true })

    var action = this.props.action
    let data = new ObjectiveProcessAction()

    data.comment = this.state.comments
    data.bundleDurationDays = this.state.bundleDurationDays

    if (
      this.state.bundleDurationDays == '' ||
      this.state.bundleDurationDays == undefined ||
      (action.requireComment && data.comment == '')
    ) {
      this.setState({ requiredMissing: true })
      return
    }

    if (this.props.isDynamicItem) {
      processDynamicAction(
        data,
        this.props.inboxId.toString(),
        action!.id.toString(),
        this.props.associatedItemId.toString()
      )
        .then(() => {
          this.props.handleProcessActionFinish!(action)
          this.props.closeModal()
        })
        .catch(() => {
          this.setState({ executed: false, loading: false })
        })
    } else {
      processAction(data, action!.id.toString(), this.props.inboxItemId.toString())
        .then(() => {
          this.props.handleProcessActionFinish!(action)
          this.props.closeModal()
        })
        .catch(() => {
          this.setState({ executed: false, loading: false })
        })
    }
  }
}

const InjectedSetBundleDurationDaysModal = inject<
  IStores,
  ISetBundleDurationDaysModalProps,
  Partial<ISetBundleDurationDaysModalProps>,
  any
>((stores: IStores) => ({
  selectedQuote: stores.quotes.selectedQuote,
  selectedEpisodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
}))(SetBundleDurationDaysModal)

export default InjectedSetBundleDurationDaysModal
