import {
  CircularProgress,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import {
  Add,
  Assignment,
  CallMerge,
  CallSplit,
  InfoOutlined,
  MoneyOffOutlined,
  PlaylistAdd,
  RemoveCircle,
  UndoRounded,
} from '@material-ui/icons'
import { Form, Formik } from 'formik'
import * as React from 'react'
import { inject } from 'mobx-react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { RouteComponentProps } from 'react-router'
import { Prompt } from 'react-router-dom'
import CardWithTitle from '../common/CardWithTitle/CardWithTitle'
import FullWidthField from '../common/FullWidthField'
import { IProviderTypeDto } from '../generated_client'
import * as Yup from 'yup'
import Constants from '../config'
import QuoteDetails from './QuoteDetails'
import { createIQuoteFormValues, submitQuoteFormValues } from './QuoteFormValues'
import QuoteTotals from './QuoteTotals'
import { IStores } from 'src/Stores'
import { formatCurrency } from 'src/utils/Formatter'
import AddQuoteLineModal from './AddQuoteLineModal'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import ReconcileLineItemModal from './ReconcileLineItemModal'
import { PlaylistRemove } from 'mdi-material-ui'
import ReconcileLineItemWizardModal from './ReconcileLineItemWizardModal'
import { QuoteStatus } from 'src/utils/QuoteStatus'
import { CostSourceText } from './CostSourceText'
import { QuoteLineItemType } from 'src/utils/QuoteLineItemType'
import { QuoteInboxItem } from 'src/viewModels/workflow/QuoteInboxItem'
const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(5),
    },
    notes: {
      padding: spacing(2),
    },
    totals: {
      padding: spacing(2),
    },
    strikeOut: {
      textDecoration: 'line-through red',
    },
  })

const QuoteSchema = Yup.object().shape({
  adjustedReconciledCost: Yup.number()
    .transform(function (value, _originalValue) {
      return this.isType(value) ? value : -1
    })
    .min(0, 'Adjusted Cost must be 0 or greater')
    .max(1000000000000, 'Adjusted Cost must be 1000000000000 or less')
    .required('Reconciled Adjusted Cost is required'),
  targetReconciledMargin: Yup.number()
    .transform(function (value, _originalValue) {
      return this.isType(value) ? value : -1
    })
    .min(0, 'Target Margin must be 0% or greater')
    .max(100, 'Target Margin must be 100% or less')
    .required('Reconciled Target Margin is required'),
})

interface IReconcileQuoteRouteParams {
  quoteId: string
}

interface IReconcileQuoteProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IReconcileQuoteRouteParams> {
  getAllUsers?: (pageSize?: number, roleFilter?: string) => Promise<void>
  getLineItemsTotals?: (lineItems: any) => number
  getLineItemsReconciledTotal?: (lineItems: any) => number
  getProviderTypes?: () => Promise<void>
  getTypesByCategory?: (type: string) => Promise<void>
  isContractModalOpen?: boolean
  isMultpleProcedureDiscountDialogOpen?: boolean
  loadQuote?: (quoteId: string, reload?: boolean) => Promise<void>
  providerTypes?: IProviderTypeDto[]
  rerouteToPath?: (path: string) => void
  selectedQuote?: IQuoteDTO
  openReconcileModal?: (quote: IQuoteDTO, modalSwitch: string) => void
  assistantWarning?: boolean
  openReconModal?: (
    quote: IQuoteDTO,
    modalSwitch: string,
    existingQuoteLineId?: string
  ) => void
  isAdjusterEmailModalOpen?: boolean
  setAsViewOnly?: boolean
  isNum?: (str: any) => boolean
  deleteLineItem?: (
    property: QuoteLineItemType,
    index: number,
    reconcile: boolean,
    forceUpdateHandler: () => void
  ) => void
  excludeLineItem?: (
    property: QuoteLineItemType,
    index: number,
    forceUpdateHandler: () => void
  ) => void
  clearReconcileLineItem?: (
    property: QuoteLineItemType,
    index: number,
    forceUpdateHandler: () => void
  ) => void
  getSubtotal?: (property: QuoteLineItemType) => string
  renderLegend?: () => JSX.Element
  setGetQuoteValuesForStaticAction?: (parentfunction?: () => Promise<IQuoteDTO>) => void
  renderClaimButtons?: (section: string) => JSX.Element | JSX.Element[]
  createInvoice?: (quoteInboxItem: QuoteInboxItem, actionId: number) => void
  quoteInboxItem: QuoteInboxItem
  isReferralContactModalOpen?: boolean
  openReferralContactModal?: () => void
}

interface IReconcileQuoteState {
  reload: boolean
  generatedLink: string
  expandProfessional: boolean
  expandTechnical: boolean
  expandAnesth: boolean
  expandPhysical: boolean
  expandMisc: boolean
  lineItemDirty: boolean
  pcListStringify: string
  tcListStringify: string
  anListStringify: string
  ptListStringify: string
  miListStringify: string
  isLoading: boolean
  viewOnly: boolean
  hasNotSubmitted: boolean
}

class ReconcileQuote extends React.Component<IReconcileQuoteProps, IReconcileQuoteState> {
  constructor(props: IReconcileQuoteProps) {
    super(props)
    this.state = {
      reload: false,
      generatedLink: '',
      expandProfessional: true,
      expandTechnical: true,
      expandAnesth: true,
      expandPhysical: true,
      expandMisc: true,
      lineItemDirty: false,
      pcListStringify: '',
      tcListStringify: '',
      anListStringify: '',
      ptListStringify: '',
      miListStringify: '',
      isLoading: true,
      viewOnly: false,
      hasNotSubmitted: true,
    }
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this)
  }

  public setMode() {
    var status = this.props.selectedQuote?.status

    if (this.props.setAsViewOnly) {
      this.setState({ viewOnly: true })
    } else {
      if (status == QuoteStatus.Reconciled) {
        this.setState({ viewOnly: true })
      }
    }
  }

  public forceUpdateHandler() {
    var newPC = JSON.stringify(
      this.props.selectedQuote?.professionalComponentLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )
    var newTC = JSON.stringify(
      this.props.selectedQuote?.technicalComponentLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )
    var newAN = JSON.stringify(
      this.props.selectedQuote?.anesthesiaLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )
    var newPT = JSON.stringify(
      this.props.selectedQuote?.physicalTherapyLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )
    var newMI = JSON.stringify(
      this.props.selectedQuote?.miscellaneousLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )

    this.setState({
      lineItemDirty:
        this.state.pcListStringify != newPC ||
        this.state.tcListStringify != newTC ||
        this.state.anListStringify != newAN ||
        this.state.ptListStringify != newPT ||
        this.state.miListStringify != newMI,
    })

    this.setMode()
    this.forceUpdate()
  }

  public async componentDidMount() {
    this.props.setGetQuoteValuesForStaticAction!(this.staticActionSave)
    await this.setup()
  }

  public getLineItemStyle = (deleted: boolean) => {
    if (deleted) {
      return this.props.classes.strikeOut
    } else {
      return undefined
    }
  }

  public async setup() {
    const { getProviderTypes, getTypesByCategory, getAllUsers } = this.props

    await Promise.all([
      getProviderTypes!(),
      getTypesByCategory!('PC'),
      getTypesByCategory!('TC'),
      getTypesByCategory!('MI'),
      getAllUsers!(5000, Constants.Roles.CareCoordinator),
    ]).then(() => {
      this.setState(
        {
          lineItemDirty: false,
          pcListStringify: JSON.stringify(
            this.props.selectedQuote!.professionalComponentLineItems
          ),
          tcListStringify: JSON.stringify(
            this.props.selectedQuote!.technicalComponentLineItems
          ),
          anListStringify: JSON.stringify(this.props.selectedQuote!.anesthesiaLineItems),
          ptListStringify: JSON.stringify(
            this.props.selectedQuote!.physicalTherapyLineItems
          ),
          miListStringify: JSON.stringify(
            this.props.selectedQuote!.miscellaneousLineItems
          ),
          isLoading: false,
        },
        () => {
          this.forceUpdateHandler()
        }
      )
    })
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload, isLoading: true }, () => {
      submitForm()
    })
  }

  public staticActionSave = async () => {
    let values = this.quoteRef.current?.state?.values
    this.quoteRef.current.submitForm()

    const quote = await submitQuoteFormValues(values, true).finally(() => {
      this.setState({ hasNotSubmitted: false }, () => {
        this.quoteRef.current.setSubmitting(false)
      })
    })

    return quote
  }

  public reconcilePTLineItem(index: number) {
    this.props.selectedQuote!.physicalTherapyLineItems![index].reconciledCost =
      this.props.selectedQuote!.physicalTherapyLineItems![index].cost
    this.forceUpdateHandler()
  }

  private mapTechnicalGridDataWithReconciliation = () => {
    if (this.props.selectedQuote?.technicalComponentLineItems!.length == 0) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            Click + to Add a Line Item
          </TableCell>
        </TableRow>
      )
    } else {
      return this.props.selectedQuote?.technicalComponentLineItems!.map(
        (billOut, index) => (
          <TableRow key={index} className="gridPaddingDense">
            <TableCell
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
            >
              {billOut.reconciledCMS1500LineItemId != undefined
                ? billOut.reconciledActualLocationGroupName
                : billOut.providerName}
              {billOut.reconciledActualLocationGroupName != undefined &&
              billOut.providerName != billOut.reconciledActualLocationGroupName ? (
                <Typography variant="caption" className={this.props.classes.strikeOut}>
                  {billOut.providerName}
                </Typography>
              ) : (
                <></>
              )}
            </TableCell>

            <TableCell
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
            >
              {billOut.typeText ?? ''}
            </TableCell>

            <TableCell
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
            >
              {billOut.reconciledCMS1500LineItemId != undefined
                ? billOut.reconciledActualCPT
                : billOut.cptCode}
              {billOut.reconciledActualCPT != undefined &&
              billOut.cptCode != billOut.reconciledActualCPT ? (
                <Typography variant="caption" className={this.props.classes.strikeOut}>
                  {billOut.cptCode}
                </Typography>
              ) : (
                <></>
              )}
            </TableCell>

            <TableCell
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
            >
              {billOut.costSource != undefined ? CostSourceText[billOut.costSource!] : ''}
              {billOut.otherDescription ? (
                <Tooltip title={billOut.otherDescription}>
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>

            <TableCell
              style={{ paddingRight: 0 }}
              align="right"
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
            >
              {formatCurrency(
                (
                  (billOut.reconciledActualCost
                    ? billOut.reconciledActualCost
                    : billOut.actualCost) ?? 0
                )?.toString()
              )}

              {billOut.reconciledActualCost != billOut.actualCost &&
              billOut.reconciledCMS1500LineItemId != undefined ? (
                <Typography variant="caption" className={this.props.classes.strikeOut}>
                  {billOut.actualCost != undefined
                    ? formatCurrency(billOut.actualCost.toString())
                    : ''}
                </Typography>
              ) : (
                <></>
              )}
            </TableCell>

            {billOut.reconciledActualCost && billOut.cost == undefined ? (
              <TableCell colSpan={2}></TableCell>
            ) : (
              <>
                <TableCell
                  style={{ paddingRight: 0 }}
                  className={this.getLineItemStyle(
                    billOut.markedDeleted || billOut.markExcluded || false
                  )}
                  align="right"
                >
                  {billOut.cost != undefined
                    ? formatCurrency(billOut.cost.toString())
                    : ''}
                </TableCell>
                <TableCell align="left" style={{ paddingLeft: 5, paddingRight: 0 }}>
                  {billOut.calculationExplanation && billOut.cost != undefined ? (
                    <Tooltip title={billOut.calculationExplanation}>
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </TableCell>
              </>
            )}
            <TableCell
              style={{ paddingRight: 0 }}
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
              align="right"
            >
              {billOut.reconciledCost != undefined
                ? formatCurrency(billOut.reconciledCost.toString())
                : ''}
            </TableCell>
            <TableCell align="right">{billOut.reasonCode}</TableCell>
            <TableCell align="left" style={{ paddingLeft: 5, paddingRight: 0 }}>
              {billOut.reconciledCalculationExplanation &&
              billOut.reconciledCost != undefined ? (
                <Tooltip title={billOut.reconciledCalculationExplanation}>
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>

            {this.state.viewOnly ? (
              <TableCell></TableCell>
            ) : (
              <>
                <TableCell style={{ width: '46px' }}>
                  {billOut.cost != undefined ? (
                    billOut.markExcluded ? (
                      <PlaylistAdd
                        onClick={() =>
                          this.props.excludeLineItem!(
                            QuoteLineItemType.TC,
                            index,
                            this.forceUpdateHandler
                          )
                        }
                        cursor="pointer"
                      ></PlaylistAdd>
                    ) : (
                      <PlaylistRemove
                        onClick={
                          billOut.reconciledCost != undefined
                            ? () => {}
                            : () =>
                                this.props.excludeLineItem!(
                                  QuoteLineItemType.TC,
                                  index,
                                  this.forceUpdateHandler
                                )
                        }
                        cursor={billOut.reconciledCost != undefined ? '' : 'pointer'}
                        color={billOut.reconciledCost != undefined ? 'disabled' : 'error'}
                      />
                    )
                  ) : billOut.markedDeleted ? (
                    <UndoRounded
                      onClick={() =>
                        this.props.deleteLineItem!(
                          QuoteLineItemType.TC,
                          index,
                          true,
                          this.forceUpdateHandler
                        )
                      }
                      cursor="pointer"
                    />
                  ) : (
                    <RemoveCircle
                      onClick={() =>
                        this.props.deleteLineItem!(
                          QuoteLineItemType.TC,
                          index,
                          true,
                          this.forceUpdateHandler
                        )
                      }
                      cursor="pointer"
                      color="error"
                    />
                  )}
                </TableCell>
                <TableCell style={{ width: '58px' }}>
                  {billOut.markedDeleted == false &&
                  billOut.markExcluded != true &&
                  billOut.cost != undefined ? (
                    billOut.reconciledCost != undefined ? (
                      <CallSplit
                        color="primary"
                        cursor="pointer"
                        onClick={() =>
                          this.props.clearReconcileLineItem!(
                            QuoteLineItemType.TC,
                            index,
                            this.forceUpdateHandler
                          )
                        }
                      />
                    ) : (
                      <CallMerge
                        color="primary"
                        cursor="pointer"
                        onClick={() =>
                          this.props.openReconModal!(
                            this.props.selectedQuote!,
                            'TC',
                            billOut.id
                          )
                        }
                      />
                    )
                  ) : (
                    <></>
                  )}
                </TableCell>
              </>
            )}
          </TableRow>
        )
      )
    }
  }

  private mapProfessionalGridDataWithReconciliation = () => {
    if (this.props.selectedQuote?.professionalComponentLineItems!.length == 0) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            Click + to Add a Line Item
          </TableCell>
        </TableRow>
      )
    } else {
      return this.props.selectedQuote?.professionalComponentLineItems!.map(
        (billOut, index) => (
          <TableRow key={index} className="gridPaddingDense">
            <TableCell
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
            >
              {billOut.reconciledCMS1500LineItemId != undefined
                ? billOut.reconciledActualLocationGroupName
                : billOut.providerName}
              {billOut.reconciledActualLocationGroupName != undefined &&
              billOut.providerName != billOut.reconciledActualLocationGroupName ? (
                <Typography variant="caption" className={this.props.classes.strikeOut}>
                  {billOut.providerName}
                </Typography>
              ) : (
                <></>
              )}
            </TableCell>

            <TableCell
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
            >
              {billOut.physicianName}
            </TableCell>
            <TableCell
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
            >
              {billOut.typeText ?? ''}
            </TableCell>

            <TableCell
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
            >
              {billOut.reconciledCMS1500LineItemId != undefined
                ? billOut.reconciledActualCPT
                : billOut.cptCode}
              {billOut.reconciledActualCPT != undefined &&
              billOut.cptCode != billOut.reconciledActualCPT ? (
                <Typography variant="caption" className={this.props.classes.strikeOut}>
                  {billOut.cptCode}
                </Typography>
              ) : (
                <></>
              )}
            </TableCell>

            <TableCell
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
            >
              {billOut.costSource != undefined ? CostSourceText[billOut.costSource!] : ''}
              {billOut.otherDescription ? (
                <Tooltip title={billOut.otherDescription}>
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>

            <TableCell
              align="right"
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
            >
              {(billOut.cost == 0 || billOut.cost == undefined) &&
              billOut.assistantMultiplier != undefined &&
              billOut.reconciledCost == undefined
                ? 'N/A'
                : formatCurrency(
                    (
                      (billOut.reconciledActualCost
                        ? billOut.reconciledActualCost
                        : billOut.actualCost) ?? 0
                    )?.toString()
                  )}

              {billOut.reconciledActualCost != billOut.actualCost &&
              billOut.reconciledCMS1500LineItemId != undefined ? (
                <Typography variant="caption" className={this.props.classes.strikeOut}>
                  {billOut.actualCost != undefined
                    ? formatCurrency(billOut.actualCost.toString())
                    : ''}
                </Typography>
              ) : (
                <></>
              )}
            </TableCell>
            <TableCell align="left" style={{ paddingLeft: 5 }}>
              {billOut.assistantSurgeonInfo ? (
                <Tooltip title={billOut.assistantSurgeonInfo}>
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>

            {billOut.reconciledActualCost && billOut.cost == undefined ? (
              <TableCell colSpan={2}></TableCell>
            ) : (
              <>
                <TableCell
                  style={{ paddingRight: 0 }}
                  className={this.getLineItemStyle(
                    billOut.markedDeleted || billOut.markExcluded || false
                  )}
                  align="right"
                >
                  {billOut.cost == 0 &&
                  billOut.assistantMultiplier != undefined &&
                  billOut.calculationExplanation == undefined &&
                  !billOut.markedDeleted
                    ? 'Will Calculate on Save'
                    : billOut.cost != undefined
                      ? formatCurrency(billOut.cost.toString())
                      : ''}
                </TableCell>
                <TableCell align="left" style={{ paddingLeft: 5, paddingRight: 0 }}>
                  {billOut.calculationExplanation && billOut.cost != undefined ? (
                    <Tooltip title={billOut.calculationExplanation}>
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </TableCell>
              </>
            )}
            <TableCell
              style={{ paddingRight: 0 }}
              className={this.getLineItemStyle(
                billOut.markedDeleted || billOut.markExcluded || false
              )}
              align="right"
            >
              {(billOut.cost == 0 || billOut.cost == undefined) &&
              billOut.assistantMultiplier != undefined &&
              billOut.reconciledCost == undefined &&
              !billOut.markedDeleted
                ? 'Will Calculate on Save'
                : billOut.reconciledCost != undefined
                  ? formatCurrency(billOut.reconciledCost.toString())
                  : ''}
            </TableCell>
            <TableCell align="right">{billOut.reasonCode}</TableCell>
            <TableCell align="left" style={{ paddingLeft: 5, paddingRight: 0 }}>
              {billOut.reconciledCalculationExplanation &&
              billOut.reconciledCost != undefined ? (
                <Tooltip title={billOut.reconciledCalculationExplanation}>
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>
            {this.state.viewOnly ? (
              <TableCell></TableCell>
            ) : (
              <>
                <TableCell style={{ width: '46px' }}>
                  {billOut.cost != undefined ? (
                    billOut.markExcluded ? (
                      <PlaylistAdd
                        onClick={() =>
                          this.props.excludeLineItem!(
                            QuoteLineItemType.PC,
                            index,
                            this.forceUpdateHandler
                          )
                        }
                        cursor="pointer"
                      ></PlaylistAdd>
                    ) : (
                      <PlaylistRemove
                        onClick={
                          billOut.reconciledCost != undefined
                            ? () => {}
                            : () =>
                                this.props.excludeLineItem!(
                                  QuoteLineItemType.PC,
                                  index,
                                  this.forceUpdateHandler
                                )
                        }
                        cursor={billOut.reconciledCost != undefined ? '' : 'pointer'}
                        color={billOut.reconciledCost != undefined ? 'disabled' : 'error'}
                      />
                    )
                  ) : billOut.markedDeleted ? (
                    <UndoRounded
                      onClick={() =>
                        this.props.deleteLineItem!(
                          QuoteLineItemType.PC,
                          index,
                          true,
                          this.forceUpdateHandler
                        )
                      }
                      cursor="pointer"
                    />
                  ) : (
                    <RemoveCircle
                      onClick={() =>
                        this.props.deleteLineItem!(
                          QuoteLineItemType.PC,
                          index,
                          true,
                          this.forceUpdateHandler
                        )
                      }
                      cursor="pointer"
                      color="error"
                    />
                  )}
                </TableCell>
                <TableCell style={{ width: '58px' }}>
                  {billOut.markExcluded != true && billOut.cost != undefined ? (
                    billOut.reconciledCost != undefined ? (
                      <CallSplit
                        color="primary"
                        cursor="pointer"
                        onClick={() =>
                          this.props.clearReconcileLineItem!(
                            QuoteLineItemType.PC,
                            index,
                            this.forceUpdateHandler
                          )
                        }
                      />
                    ) : (
                      <CallMerge
                        color="primary"
                        cursor="pointer"
                        onClick={() =>
                          this.props.openReconModal!(
                            this.props.selectedQuote!,
                            'PC',
                            billOut.id
                          )
                        }
                      />
                    )
                  ) : (
                    <></>
                  )}
                </TableCell>
              </>
            )}
          </TableRow>
        )
      )
    }
  }

  private mapAnesthesiaGridDataReconciliation = () => {
    if (this.props.selectedQuote?.anesthesiaLineItems!.length == 0) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            Click + to Add a Line Item
          </TableCell>
        </TableRow>
      )
    } else {
      return this.props.selectedQuote?.anesthesiaLineItems!.map((billOut, index) => (
        <TableRow key={index} className="gridPaddingDense">
          <TableCell style={{ padding: 0 }}>
            {billOut.reconciledCMS1500LineItemId == undefined &&
            billOut.reconciledCost != undefined ? (
              <Tooltip title="Reconciled with No Claim">
                <MoneyOffOutlined />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
          >
            {billOut.reconciledCMS1500LineItemId != undefined
              ? billOut.reconciledActualLocationGroupName
              : billOut.providerName}
            {billOut.reconciledActualLocationGroupName != undefined &&
            billOut.providerName != billOut.reconciledActualLocationGroupName ? (
              <Typography variant="caption" className={this.props.classes.strikeOut}>
                {billOut.providerName}
              </Typography>
            ) : (
              <></>
            )}
          </TableCell>

          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
          >
            {billOut.physicianName ?? ''}
          </TableCell>

          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
          >
            {billOut.reconciledCMS1500LineItemId != undefined
              ? billOut.reconciledActualCPT
              : billOut.cptCode}
            {billOut.reconciledCMS1500LineItemId != undefined &&
            billOut.cptCode != billOut.reconciledActualCPT ? (
              <Typography variant="caption" className={this.props.classes.strikeOut}>
                {billOut.cptCode}
              </Typography>
            ) : (
              <></>
            )}
          </TableCell>

          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
          >
            {billOut.costSource != undefined ? CostSourceText[billOut.costSource!] : ''}
            {billOut.otherDescription ? (
              <Tooltip title={billOut.otherDescription}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>

          <TableCell
            style={{ paddingRight: 0 }}
            align="right"
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
          >
            {formatCurrency(
              (
                (billOut.reconciledActualCost
                  ? billOut.reconciledActualCost
                  : billOut.actualCost) ?? 0
              )?.toString()
            )}

            {billOut.reconciledActualCost != billOut.actualCost &&
            billOut.reconciledCMS1500LineItemId != undefined ? (
              <Typography variant="caption" className={this.props.classes.strikeOut}>
                {billOut.actualCost != undefined
                  ? formatCurrency(billOut.actualCost.toString())
                  : ''}
              </Typography>
            ) : (
              <></>
            )}
          </TableCell>

          {billOut.reconciledActualCost && billOut.cost == undefined ? (
            <TableCell colSpan={2}></TableCell>
          ) : (
            <>
              <TableCell
                style={{ paddingRight: 0 }}
                className={this.getLineItemStyle(
                  billOut.markedDeleted || billOut.markExcluded || false
                )}
                align="right"
              >
                {billOut.cost != undefined ? formatCurrency(billOut.cost.toString()) : ''}
              </TableCell>
              <TableCell align="left" style={{ paddingLeft: 5, paddingRight: 0 }}>
                {billOut.calculationExplanation && billOut.cost != undefined ? (
                  <Tooltip title={billOut.calculationExplanation}>
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </TableCell>
            </>
          )}
          <TableCell
            style={{ paddingRight: 0 }}
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
            align="right"
          >
            {billOut.reconciledCost != undefined
              ? formatCurrency(billOut.reconciledCost.toString())
              : ''}
          </TableCell>
          <TableCell align="right">{billOut.reasonCode}</TableCell>
          <TableCell align="left" style={{ paddingLeft: 5, paddingRight: 0 }}>
            {billOut.reconciledCalculationExplanation &&
            billOut.reconciledCost != undefined ? (
              <Tooltip title={billOut.reconciledCalculationExplanation}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>

          {this.state.viewOnly ? (
            <TableCell></TableCell>
          ) : (
            <>
              <TableCell style={{ width: '46px' }}>
                {billOut.cost != undefined ? (
                  billOut.markExcluded ? (
                    <PlaylistAdd
                      onClick={() =>
                        this.props.excludeLineItem!(
                          QuoteLineItemType.AN,
                          index,
                          this.forceUpdateHandler
                        )
                      }
                      cursor="pointer"
                    ></PlaylistAdd>
                  ) : (
                    <PlaylistRemove
                      onClick={
                        billOut.reconciledCost != undefined
                          ? () => {}
                          : () =>
                              this.props.excludeLineItem!(
                                QuoteLineItemType.AN,
                                index,
                                this.forceUpdateHandler
                              )
                      }
                      cursor={billOut.reconciledCost != undefined ? '' : 'pointer'}
                      color={billOut.reconciledCost != undefined ? 'disabled' : 'error'}
                    />
                  )
                ) : billOut.markedDeleted ? (
                  <UndoRounded
                    onClick={() =>
                      this.props.deleteLineItem!(
                        QuoteLineItemType.AN,
                        index,
                        true,
                        this.forceUpdateHandler
                      )
                    }
                    cursor="pointer"
                  />
                ) : (
                  <RemoveCircle
                    onClick={() =>
                      this.props.deleteLineItem!(
                        QuoteLineItemType.AN,
                        index,
                        true,
                        this.forceUpdateHandler
                      )
                    }
                    cursor="pointer"
                    color="error"
                  />
                )}
              </TableCell>
              <TableCell style={{ width: '58px' }}>
                {billOut.markExcluded != true && billOut.cost != undefined ? (
                  billOut.reconciledCost != undefined ? (
                    <CallSplit
                      color="primary"
                      cursor="pointer"
                      onClick={() =>
                        this.props.clearReconcileLineItem!(
                          QuoteLineItemType.AN,
                          index,
                          this.forceUpdateHandler
                        )
                      }
                    />
                  ) : (
                    <CallMerge
                      color="primary"
                      cursor="pointer"
                      onClick={() =>
                        this.props.openReconModal!(
                          this.props.selectedQuote!,
                          'AN',
                          billOut.id
                        )
                      }
                    />
                  )
                ) : (
                  <></>
                )}
              </TableCell>
            </>
          )}
        </TableRow>
      ))
    }
  }

  private mapPhysicalGridDataReconciliation = () => {
    if (this.props.selectedQuote?.physicalTherapyLineItems!.length == 0) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            Click + to Add a Line Item
          </TableCell>
        </TableRow>
      )
    } else {
      return this.props.selectedQuote?.physicalTherapyLineItems!.map((billOut, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
            style={{ whiteSpace: 'nowrap' }}
          >
            {billOut.providerName}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
          >
            {billOut.numberOfVisits?.toString()}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
            align="right"
          >
            {billOut.actualCostPerVisit
              ? formatCurrency(billOut.actualCostPerVisit?.toString())
              : ''}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
            align="right"
          >
            {billOut.actualCostPerVisit && billOut.numberOfVisits
              ? formatCurrency(
                  (billOut.actualCostPerVisit * billOut.numberOfVisits)?.toString()
                )
              : ''}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
            align="right"
          >
            {billOut.costPerVisit ? formatCurrency(billOut.costPerVisit?.toString()) : ''}
          </TableCell>
          <TableCell
            style={{ paddingRight: 0 }}
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
            align="right"
          >
            {billOut.cost != undefined ? formatCurrency(billOut.cost.toString()) : ''}
          </TableCell>
          <TableCell
            style={{ paddingRight: 0 }}
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
            align="right"
          >
            {billOut.reconciledCost != undefined
              ? formatCurrency(billOut.reconciledCost.toString())
              : ''}
          </TableCell>
          {this.state.viewOnly ? (
            <TableCell></TableCell>
          ) : (
            <>
              <TableCell>
                {billOut.cost != undefined ? (
                  billOut.markExcluded ? (
                    <PlaylistAdd
                      onClick={() =>
                        this.props.excludeLineItem!(
                          QuoteLineItemType.PT,
                          index,
                          this.forceUpdateHandler
                        )
                      }
                      cursor="pointer"
                    ></PlaylistAdd>
                  ) : (
                    <PlaylistRemove
                      onClick={
                        billOut.reconciledCost != undefined
                          ? () => {}
                          : () =>
                              this.props.excludeLineItem!(
                                QuoteLineItemType.PT,
                                index,
                                this.forceUpdateHandler
                              )
                      }
                      cursor={billOut.reconciledCost != undefined ? '' : 'pointer'}
                      color={billOut.reconciledCost != undefined ? 'disabled' : 'error'}
                    />
                  )
                ) : billOut.markedDeleted ? (
                  <UndoRounded
                    onClick={() =>
                      this.props.deleteLineItem!(
                        QuoteLineItemType.PT,
                        index,
                        true,
                        this.forceUpdateHandler
                      )
                    }
                    cursor="pointer"
                  />
                ) : (
                  <RemoveCircle
                    onClick={() =>
                      this.props.deleteLineItem!(
                        QuoteLineItemType.PT,
                        index,
                        true,
                        this.forceUpdateHandler
                      )
                    }
                    cursor="pointer"
                    color="error"
                  />
                )}
              </TableCell>
              <TableCell>
                {billOut.markedDeleted == false &&
                billOut.markExcluded != true &&
                billOut.cost != undefined ? (
                  billOut.reconciledCost != undefined ? (
                    <CallSplit
                      color="primary"
                      cursor="pointer"
                      onClick={() =>
                        this.props.clearReconcileLineItem!(
                          QuoteLineItemType.PT,
                          index,
                          this.forceUpdateHandler
                        )
                      }
                    />
                  ) : (
                    <CallMerge
                      color="primary"
                      cursor="pointer"
                      onClick={() => this.reconcilePTLineItem(index)}
                    />
                  )
                ) : (
                  <></>
                )}
              </TableCell>
            </>
          )}
        </TableRow>
      ))
    }
  }

  private mapMiscGridDataWithReconciliation = () => {
    if (this.props.selectedQuote?.miscellaneousLineItems!.length == 0) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            Click + to Add a Line Item
          </TableCell>
        </TableRow>
      )
    } else {
      return this.props.selectedQuote?.miscellaneousLineItems!.map((billOut, index) => (
        <TableRow key={index} className="gridPaddingDense">
          <TableCell style={{ padding: 0 }}>
            {billOut.reconciledCMS1500LineItemId == undefined &&
            billOut.reconciledCost != undefined ? (
              <Tooltip title="Reconciled with No Claim">
                <MoneyOffOutlined />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
          >
            {billOut.typeText ?? ''}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
          >
            {billOut.reconciledCMS1500LineItemId != undefined
              ? billOut.reconciledActualLocationGroupName
              : billOut.providerName}
            {billOut.reconciledActualLocationGroupName != undefined &&
            billOut.providerName != billOut.reconciledActualLocationGroupName ? (
              <Typography variant="caption" className={this.props.classes.strikeOut}>
                {billOut.providerName}
              </Typography>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
          >
            {billOut.lineDescription ?? ''}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
          >
            {billOut.reconciledCMS1500LineItemId != undefined
              ? billOut.reconciledActualCPT
              : billOut.cptCode}
            {billOut.reconciledActualCPT != undefined &&
            billOut.cptCode != billOut.reconciledActualCPT ? (
              <Typography variant="caption" className={this.props.classes.strikeOut}>
                {billOut.cptCode}
              </Typography>
            ) : (
              <></>
            )}
          </TableCell>

          <TableCell
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
          >
            {billOut.costSource != undefined ? CostSourceText[billOut.costSource!] : ''}
            {billOut.otherDescription ? (
              <Tooltip title={billOut.otherDescription}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>

          <TableCell
            style={{ paddingRight: 0 }}
            align="right"
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
          >
            {formatCurrency(
              (
                (billOut.reconciledActualCost
                  ? billOut.reconciledActualCost
                  : billOut.actualCost) ?? 0
              )?.toString()
            )}

            {billOut.reconciledActualCost != billOut.actualCost &&
            billOut.reconciledCMS1500LineItemId != undefined ? (
              <Typography variant="caption" className={this.props.classes.strikeOut}>
                {billOut.actualCost != undefined
                  ? formatCurrency(billOut.actualCost.toString())
                  : ''}
              </Typography>
            ) : (
              <></>
            )}
          </TableCell>

          {billOut.reconciledActualCost && billOut.cost == undefined ? (
            <TableCell colSpan={2}></TableCell>
          ) : (
            <>
              <TableCell
                style={{ paddingRight: 0 }}
                className={this.getLineItemStyle(
                  billOut.markedDeleted || billOut.markExcluded || false
                )}
                align="right"
              >
                {billOut.cost != undefined ? formatCurrency(billOut.cost.toString()) : ''}
              </TableCell>
              <TableCell align="left" style={{ paddingLeft: 5, paddingRight: 0 }}>
                {billOut.calculationExplanation && billOut.cost != undefined ? (
                  <Tooltip title={billOut.calculationExplanation}>
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </TableCell>
            </>
          )}
          <TableCell
            style={{ paddingRight: 0 }}
            className={this.getLineItemStyle(
              billOut.markedDeleted || billOut.markExcluded || false
            )}
            align="right"
          >
            {billOut.reconciledCost != undefined
              ? formatCurrency(billOut.reconciledCost.toString())
              : ''}
          </TableCell>
          <TableCell align="right">{billOut.reasonCode}</TableCell>
          <TableCell align="left" style={{ paddingLeft: 5, paddingRight: 0 }}>
            {billOut.reconciledCalculationExplanation &&
            billOut.reconciledCost != undefined ? (
              <Tooltip title={billOut.reconciledCalculationExplanation}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          {this.state.viewOnly ? (
            <TableCell></TableCell>
          ) : (
            <>
              <TableCell style={{ width: '46px' }}>
                {billOut.cost != undefined ? (
                  billOut.markExcluded ? (
                    <PlaylistAdd
                      onClick={() =>
                        this.props.excludeLineItem!(
                          QuoteLineItemType.MI,
                          index,
                          this.forceUpdateHandler
                        )
                      }
                      cursor="pointer"
                    ></PlaylistAdd>
                  ) : (
                    <PlaylistRemove
                      onClick={
                        billOut.reconciledCost != undefined
                          ? () => {}
                          : () =>
                              this.props.excludeLineItem!(
                                QuoteLineItemType.MI,
                                index,
                                this.forceUpdateHandler
                              )
                      }
                      cursor={billOut.reconciledCost != undefined ? '' : 'pointer'}
                      color={billOut.reconciledCost != undefined ? 'disabled' : 'error'}
                    />
                  )
                ) : billOut.markedDeleted ? (
                  <UndoRounded
                    onClick={() =>
                      this.props.deleteLineItem!(
                        QuoteLineItemType.MI,
                        index,
                        true,
                        this.forceUpdateHandler
                      )
                    }
                    cursor="pointer"
                  />
                ) : (
                  <RemoveCircle
                    onClick={() =>
                      this.props.deleteLineItem!(
                        QuoteLineItemType.MI,
                        index,
                        true,
                        this.forceUpdateHandler
                      )
                    }
                    cursor="pointer"
                    color="error"
                  />
                )}
              </TableCell>
              <TableCell style={{ width: '58px' }}>
                {billOut.markExcluded != true && billOut.cost != undefined ? (
                  billOut.reconciledCost != undefined ? (
                    <CallSplit
                      color="primary"
                      cursor="pointer"
                      onClick={() =>
                        this.props.clearReconcileLineItem!(
                          QuoteLineItemType.MI,
                          index,
                          this.forceUpdateHandler
                        )
                      }
                    />
                  ) : (
                    <CallMerge
                      color="primary"
                      cursor="pointer"
                      onClick={() =>
                        this.props.openReconModal!(
                          this.props.selectedQuote!,
                          'MI',
                          billOut.id
                        )
                      }
                    />
                  )
                ) : (
                  <></>
                )}
              </TableCell>
            </>
          )}
        </TableRow>
      ))
    }
  }

  private renderPC() {
    return (
      <ExpansionPanel
        expanded={this.state.expandProfessional}
        onChange={() =>
          this.setState({ expandProfessional: !this.state.expandProfessional })
        }
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container justifyContent="space-between" style={{ paddingTop: '5px' }}>
            <Typography display="inline" color="textSecondary" variant="body2">
              PROFESSIONAL COMPONENTS
            </Typography>
            <Typography
              display="inline"
              color="textPrimary"
              variant="subtitle1"
              align="right"
            >
              <span>
                {' Quoted: ' +
                  this.props.getSubtotal!(QuoteLineItemType.PC) +
                  ' (' +
                  this.props.selectedQuote?.professionalComponentLineItems!.length +
                  (this.props.selectedQuote?.professionalComponentLineItems!.length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
              <span>
                {' Reconciled: ' +
                  this.getReconSubtotal(QuoteLineItemType.PC) +
                  ' (' +
                  this.props.selectedQuote?.professionalComponentLineItems!.filter(
                    (x) => x.reconciledCost
                  ).length +
                  (this.props.selectedQuote?.professionalComponentLineItems!.filter(
                    (x) => x.reconciledCost
                  ).length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
            </Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row">
            <Grid item xs={12}>
              <>{this.props.renderClaimButtons!('PC')}</>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="gridPaddingDense">
                    <TableCell>Provider</TableCell>
                    <TableCell>Physician</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>CPT</TableCell>
                    <TableCell>Cost Source</TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                        paddingLeft: 0,
                      }}
                    >
                      Billed Charges
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Quoted $
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={2}
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Reconciled $
                    </TableCell>
                    <TableCell align="right">Reason Code</TableCell>
                    <TableCell align="right" style={{ paddingRight: '14px' }} colSpan={3}>
                      {this.state.viewOnly ? (
                        <></>
                      ) : (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.props.selectedQuote == undefined}
                          aria-label="Add"
                          onClick={() =>
                            this.props.openReconModal!(
                              this.props.selectedQuote!,
                              'PC',
                              undefined
                            )
                          }
                        >
                          <Add />
                        </Fab>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '100%' }}>
                  {this.mapProfessionalGridDataWithReconciliation()}
                </TableBody>
              </Table>
            </Grid>
            {!this.state.viewOnly && this.props.renderLegend!()}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  private renderTC() {
    return (
      <ExpansionPanel
        expanded={this.state.expandTechnical}
        onChange={() => this.setState({ expandTechnical: !this.state.expandTechnical })}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container justifyContent="space-between" style={{ paddingTop: '5px' }}>
            <Typography display="inline" color="textSecondary" variant="body2">
              TECHNICAL COMPONENTS
            </Typography>
            <Typography
              display="inline"
              color="textPrimary"
              variant="subtitle1"
              align="right"
            >
              <span>
                {' Quoted: ' +
                  this.props.getSubtotal!(QuoteLineItemType.TC) +
                  ' (' +
                  this.props.selectedQuote?.technicalComponentLineItems!.length +
                  (this.props.selectedQuote?.technicalComponentLineItems!.length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
              <span>
                {' Reconciled: ' +
                  this.getReconSubtotal(QuoteLineItemType.TC) +
                  ' (' +
                  this.props.selectedQuote?.technicalComponentLineItems!.filter(
                    (x) => x.reconciledCost
                  ).length +
                  (this.props.selectedQuote?.technicalComponentLineItems!.filter(
                    (x) => x.reconciledCost
                  ).length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
            </Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row">
            <Grid item xs={12}>
              <>{this.props.renderClaimButtons!('TC')}</>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="gridPaddingDense">
                    <TableCell>Provider</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>CPT</TableCell>
                    <TableCell>Cost Source</TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                        paddingLeft: 0,
                      }}
                    >
                      Billed Charges
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Quoted $
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={2}
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Reconciled $
                    </TableCell>
                    <TableCell align="right">Reason Code</TableCell>
                    <TableCell align="right" style={{ paddingRight: '14px' }} colSpan={3}>
                      {this.state.viewOnly ? (
                        <></>
                      ) : (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.props.selectedQuote == undefined}
                          aria-label="Add"
                          onClick={() =>
                            this.props.openReconModal!(
                              this.props.selectedQuote!,
                              'TC',
                              undefined
                            )
                          }
                        >
                          <Add />
                        </Fab>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '100%' }}>
                  {this.mapTechnicalGridDataWithReconciliation()}
                </TableBody>
              </Table>
            </Grid>
            {!this.state.viewOnly && this.props.renderLegend!()}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  private renderAN() {
    return (
      <ExpansionPanel
        expanded={this.state.expandAnesth}
        onChange={() => this.setState({ expandAnesth: !this.state.expandAnesth })}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container justifyContent="space-between" style={{ paddingTop: '5px' }}>
            <Typography display="inline" color="textSecondary" variant="body2">
              ANESTHESIOLOGIST
            </Typography>
            <Typography
              display="inline"
              color="textPrimary"
              variant="subtitle1"
              align="right"
            >
              <span>
                {' Quoted: ' +
                  this.props.getSubtotal!(QuoteLineItemType.AN) +
                  ' (' +
                  this.props.selectedQuote?.anesthesiaLineItems!.length +
                  (this.props.selectedQuote?.anesthesiaLineItems!.length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
              <span>
                {' Reconciled: ' +
                  this.getReconSubtotal(QuoteLineItemType.AN) +
                  ' (' +
                  this.props.selectedQuote?.anesthesiaLineItems!.filter(
                    (x) => x.reconciledCost
                  ).length +
                  (this.props.selectedQuote?.anesthesiaLineItems!.filter(
                    (x) => x.reconciledCost
                  ).length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
            </Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row">
            <Grid item xs={12}>
              <>{this.props.renderClaimButtons!('AN')}</>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="gridPaddingDense">
                    <TableCell style={{ padding: 0 }} />
                    <TableCell>Provider</TableCell>
                    <TableCell>Physician</TableCell>
                    <TableCell>CPT</TableCell>
                    <TableCell>Cost Source</TableCell>
                    <TableCell align="right" style={{ paddingRight: 0 }}>
                      Billed Charges
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Quoted $
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={2}
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Reconciled $
                    </TableCell>
                    <TableCell align="right">Reason Code</TableCell>
                    <TableCell align="right" style={{ paddingRight: '14px' }} colSpan={3}>
                      {this.state.viewOnly ? (
                        <></>
                      ) : (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.props.selectedQuote == undefined}
                          aria-label="Add"
                          onClick={() =>
                            this.props.openReconModal!(
                              this.props.selectedQuote!,
                              'AN',
                              undefined
                            )
                          }
                        >
                          <Add />
                        </Fab>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '100%' }}>
                  {this.mapAnesthesiaGridDataReconciliation()}
                </TableBody>
              </Table>
            </Grid>
            {!this.state.viewOnly && this.props.renderLegend!()}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  private renderPT() {
    return (
      <ExpansionPanel
        expanded={this.state.expandPhysical}
        onChange={() => this.setState({ expandPhysical: !this.state.expandPhysical })}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container justifyContent="space-between" style={{ paddingTop: '5px' }}>
            <Typography display="inline" color="textSecondary" variant="body2">
              PHYSICAL THERAPY
            </Typography>
            <Typography
              display="inline"
              color="textPrimary"
              variant="subtitle1"
              align="right"
            >
              <span>
                {' Quoted: ' +
                  this.props.getSubtotal!(QuoteLineItemType.PT) +
                  ' (' +
                  this.props.selectedQuote?.physicalTherapyLineItems!.length +
                  (this.props.selectedQuote?.physicalTherapyLineItems!.length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
              <span>
                {' Reconciled: ' +
                  this.getReconSubtotal(QuoteLineItemType.PT) +
                  ' (' +
                  this.props.selectedQuote?.physicalTherapyLineItems!.filter(
                    (x) => x.reconciledCost
                  ).length +
                  (this.props.selectedQuote?.physicalTherapyLineItems!.filter(
                    (x) => x.reconciledCost
                  ).length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
            </Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row">
            <Grid item xs={12}>
              <>{this.props.renderClaimButtons!('PT')}</>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="gridPadding">
                    <TableCell>Provider</TableCell>
                    <TableCell>Number of Visits</TableCell>
                    <TableCell align="right">Est. U&C Per Visit</TableCell>
                    <TableCell align="right">Est. U&C $</TableCell>
                    <TableCell align="right">Quoted Per Visit</TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Quoted $
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Reconciled $
                    </TableCell>
                    <TableCell align="right" style={{ paddingRight: '40px' }} colSpan={3}>
                      {this.state.viewOnly ? (
                        <></>
                      ) : (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.props.selectedQuote == undefined}
                          aria-label="Add"
                          onClick={() =>
                            this.props.openReconModal!(
                              this.props.selectedQuote!,
                              'PT',
                              undefined
                            )
                          }
                        >
                          <Add />
                        </Fab>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '100%' }}>
                  {this.mapPhysicalGridDataReconciliation()}
                </TableBody>
              </Table>
            </Grid>
            {!this.state.viewOnly && this.props.renderLegend!()}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  private renderMI() {
    return (
      <ExpansionPanel
        expanded={this.state.expandMisc}
        onChange={() => this.setState({ expandMisc: !this.state.expandMisc })}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container justifyContent="space-between" style={{ paddingTop: '5px' }}>
            <Typography display="inline" color="textSecondary" variant="body2">
              MISCELLANEOUS
            </Typography>
            <Typography
              display="inline"
              color="textPrimary"
              variant="subtitle1"
              align="right"
            >
              <span>
                {' Quoted: ' +
                  this.props.getSubtotal!(QuoteLineItemType.MI) +
                  ' (' +
                  this.props.selectedQuote?.miscellaneousLineItems!.length +
                  (this.props.selectedQuote?.miscellaneousLineItems!.length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
              <span>
                {' Reconciled: ' +
                  this.getReconSubtotal(QuoteLineItemType.MI) +
                  ' (' +
                  this.props.selectedQuote?.miscellaneousLineItems!.filter(
                    (x) => x.reconciledCost
                  ).length +
                  (this.props.selectedQuote?.miscellaneousLineItems!.filter(
                    (x) => x.reconciledCost
                  ).length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
            </Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row">
            <Grid item xs={12}>
              <>{this.props.renderClaimButtons!('MI')}</>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="gridPaddingDense">
                    <TableCell style={{ padding: 0 }} />
                    <TableCell>Type</TableCell>
                    <TableCell>Provider</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>CPT</TableCell>
                    <TableCell>Cost Source</TableCell>
                    <TableCell align="right" style={{ paddingRight: 0 }}>
                      Billed Charges
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Quoted $
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={2}
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Reconciled $
                    </TableCell>
                    <TableCell align="right">Reason Code</TableCell>
                    <TableCell align="right" style={{ paddingRight: '14px' }} colSpan={3}>
                      {this.state.viewOnly ? (
                        <></>
                      ) : (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.props.selectedQuote == undefined}
                          aria-label="Add"
                          onClick={() =>
                            this.props.openReconModal!(
                              this.props.selectedQuote!,
                              'MI',
                              undefined
                            )
                          }
                        >
                          <Add />
                        </Fab>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '100%' }}>
                  {this.mapMiscGridDataWithReconciliation()}
                </TableBody>
              </Table>
            </Grid>
            {!this.state.viewOnly && this.props.renderLegend!()}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  private getReconSubtotal = (property: QuoteLineItemType) => {
    var subtotal = 0

    if (this.props.selectedQuote) {
      switch (property) {
        case QuoteLineItemType.PC:
          this.props.selectedQuote
            .professionalComponentLineItems!.filter((x) => !x.markExcluded)
            .forEach((x) => {
              if (x.reconciledCost) {
                subtotal += +x.reconciledCost
              }
            })
          break
        case QuoteLineItemType.TC:
          this.props.selectedQuote
            .technicalComponentLineItems!.filter((x) => !x.markExcluded)
            .forEach((x) => {
              if (x.reconciledCost) {
                subtotal += +x.reconciledCost
              }
            })
          break
        case QuoteLineItemType.AN:
          this.props.selectedQuote
            .anesthesiaLineItems!.filter((x) => !x.markExcluded)
            .forEach((x) => {
              if (x.reconciledCost) {
                subtotal += +x.reconciledCost
              }
            })
          break
        case QuoteLineItemType.PT:
          this.props.selectedQuote
            .physicalTherapyLineItems!.filter((x) => !x.markExcluded)
            .forEach((x) => {
              if (x.reconciledCost) {
                subtotal += +x.reconciledCost
              }
            })
          break
        case QuoteLineItemType.MI:
          this.props.selectedQuote
            .miscellaneousLineItems!.filter((x) => !x.markExcluded)
            .forEach((x) => {
              if (x.reconciledCost) {
                subtotal += +x.reconciledCost
              }
            })
          break
        default:
          break
      }
    }
    return formatCurrency(subtotal.toString())
  }

  public quoteRef = React.createRef<any>()

  public render() {
    const { selectedQuote, getLineItemsTotals, getLineItemsReconciledTotal } = this.props

    return (
      <>
        {selectedQuote ? (
          <Formik
            ref={this.quoteRef}
            initialValues={createIQuoteFormValues(selectedQuote)}
            onSubmit={() => {}}
            validationSchema={QuoteSchema}
            enableReinitialize={true}
          >
            {({ values, dirty, submitCount }) => (
              <>
                <Prompt
                  when={(dirty || this.state.lineItemDirty) && submitCount === 0}
                  message="Are you sure you want to leave the page? There are unsaved changes."
                />
                <AddQuoteLineModal reloadParent={this.forceUpdateHandler} />
                <ReconcileLineItemModal reloadParent={this.forceUpdateHandler} />
                <ReconcileLineItemWizardModal reloadParent={this.forceUpdateHandler} />
                <Form>
                  {
                    //@ts-ignore
                    (values.adjustedReconciledCost === '' ||
                      //@ts-ignore
                      values.targetReconciledMargin === '') && (
                      <Grid item container justifyContent="flex-end">
                        <Typography
                          style={{
                            paddingRight: '10px',
                            paddingBottom: '10px',
                          }}
                          color="error"
                        >
                          Missing Reconciliation Total Data
                        </Typography>
                      </Grid>
                    )
                  }
                  <Grid container direction="column" spacing={3} wrap="nowrap">
                    {this.state.isLoading && (
                      <div
                        style={{
                          position: 'fixed',
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          cursor: 'pointer',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 800,
                        }}
                      >
                        <CircularProgress size={100} />
                      </div>
                    )}
                    <Grid item>
                      {this.props.selectedQuote && (
                        <QuoteDetails
                          disableInvoiceButtons={
                            (dirty || this.state.lineItemDirty) && submitCount === 0
                          }
                          values={values}
                          selectedQuote={this.props.selectedQuote}
                          assistantWarning={this.props.assistantWarning}
                          viewMode={this.state.viewOnly}
                          updateMain={this.forceUpdateHandler}
                          isAdjusterEmailModalOpen={this.props.isAdjusterEmailModalOpen}
                          rerouteToPath={this.props.rerouteToPath}
                          takeoverOfCareDate={this.props.quoteInboxItem?.takeoverOfCare}
                          createInvoice={this.props.createInvoice}
                          quoteInboxItem={this.props.quoteInboxItem}
                          isReferralContactModalOpen={
                            this.props.isReferralContactModalOpen
                          }
                          openReferralContactModal={this.props.openReferralContactModal}
                        />
                      )}
                    </Grid>
                    <Grid item>{this.renderPC()}</Grid>
                    <Grid item>{this.renderTC()}</Grid>
                    <Grid item>{this.renderAN()}</Grid>
                    <Grid item>{this.renderPT()}</Grid>
                    <Grid item>{this.renderMI()}</Grid>
                    <Grid container item direction="row" spacing={3}>
                      <Grid item xs={4}>
                        <CardWithTitle title="Notes" icon={Assignment}>
                          <Grid item container>
                            <FullWidthField
                              name="note"
                              fullWidth
                              multiline
                              rows="9"
                              style={{ marginBottom: 4 }}
                            />
                          </Grid>
                        </CardWithTitle>
                      </Grid>
                      <QuoteTotals
                        viewMode={this.state.viewOnly}
                        reconMode={true}
                        getLineItemsTotal={getLineItemsTotals!}
                        getLineItemsReconciledTotal={getLineItemsReconciledTotal!}
                      />
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <>
            {this.state.isLoading && (
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 800,
                }}
              >
                <CircularProgress size={100} />
              </div>
            )}
          </>
        )}
      </>
    )
  }
}

const InjectedquotesList = inject<
  IStores,
  IReconcileQuoteProps,
  Partial<IReconcileQuoteProps>,
  any
>((stores: IStores) => ({
  getAllUsers: stores.users.getAllUsers,
  getLineItemsTotals: stores.quotes.getLineItemsTotal,
  getLineItemsReconciledTotal: stores.quotes.getLineItemsReconciledTotal,
  getProviderTypes: stores.quotes.getProviderTypes,
  getTypesByCategory: stores.quotes.getTypesByCategory,
  loadQuote: stores.quotes.loadQuote,
  providerTypes: stores.quotes.providerTypes,
  rerouteToPath: stores.global.rerouteToPath,
  selectedQuote: stores.quotes.selectedQuote,
  openReconModal: stores.quotes.openReconcileQuoteLineModal,
  assistantWarning: stores.quotes.assistantWarning,
  isAdjusterEmailModalOpen: stores.quotes.isAdjusterEmailModalOpen,
  isNum: stores.quotes.isNum,
  deleteLineItem: stores.quotes.deleteLineItem,
  excludeLineItem: stores.quotes.excludeLineItem,
  clearReconcileLineItem: stores.quotes.clearReconcileLineItem,
  getSubtotal: stores.quotes.getSubtotal,
  renderLegend: stores.quotes.renderLegend,
  setGetQuoteValuesForStaticAction: stores.quotes.setGetQuoteValuesForStaticAction,
  renderClaimButtons: stores.quotes.renderClaimButtons,
  createInvoice: stores.quotes.createInvoice,
  openReferralContactModal: stores.patientEdit.openReferralContactModal,
  isReferralContactModalOpen: stores.patientEdit.isReferralContactModalOpen,
}))(ReconcileQuote)

export default withStyles(styles)(InjectedquotesList)
