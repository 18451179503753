import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { inject } from 'mobx-react'
import React, { ChangeEvent } from 'react'
import ReactPaginate from 'react-paginate'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { searchClaims } from 'src/services/ClaimService'
import { processAction } from 'src/services/WorkflowService'
import { AppealType } from 'src/utils/AppealType'
import { formatCurrency, formatDate } from 'src/utils/Formatter'
import { ClaimSearchRequest } from 'src/viewModels/ClaimSearchRequest'
import {
  ClaimSummaryResult,
  IClaimSummaryResult,
} from 'src/viewModels/ClaimSummaryResult'
import { pagedList } from 'src/viewModels/pagedList'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import { MarkClaimAsAppealSummary } from 'src/viewModels/workflow/MarkClaimAsAppealSummary'
import { ObjectiveProcessAction } from 'src/viewModels/workflow/ObjectiveProcessAction'

interface IMarkClaimAsAppealModalProps {
  isOpen: boolean
  closeModal: () => void
  action: InboxActionSummary
  rerouteToPath?: (path: string) => void
  inboxItemId: number
  inboxId: string
  associatedItemId: string
  data: ObjectiveProcessAction
  handleProcessActionFinish?: (selectedAction?: InboxActionSummary) => void
}

class MarkClaimAsAppealModal extends React.Component<IMarkClaimAsAppealModalProps> {
  public state = {
    comment: '',
    selectedClaimId: '',
    selectedAppealType: '',
    isLoading: true,
    modalPage: 1,
    hasClaim: false,
    hasType: false,
    isExecuting: false,
    gridData: new pagedList().items as ClaimSummaryResult[],
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 5,
  }

  public componentDidMount(): void {
    this.getClaimData(this.props.data.claimData?.episodeOfCareId!)
    this.props.data.markAppealData = new MarkClaimAsAppealSummary()
  }

  private getClaimData = async (episodeOfCareId: string) => {
    this.setState({ isLoading: true })
    const { page, pageSize } = this.state
    const requestData = new ClaimSearchRequest()
    requestData.page = page
    requestData.pageSize = pageSize
    requestData.episodeOfCareId = episodeOfCareId
    requestData.excludeAppeals = true
    requestData.excludeClaimId = this.props.associatedItemId
    await searchClaims(requestData)
      .then((response: any) => {
        this.setState({
          gridData: response.items,
          pageCount: response.totalPages,
          totalItems: response.totalItems,
        })

        if (response.totalItems > 0) {
          this.setPaginationOffsetData()
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  private async save() {
    var action = this.props.action
    this.props.data.comment = this.state.comment
    this.props.data.markAppealData!.originalClaimId = this.state.selectedClaimId
    this.props.data.markAppealData!.appealType = this.state
      .selectedAppealType as unknown as AppealType

    processAction(
      this.props.data,
      action.id.toString(),
      this.props.inboxItemId.toString()
    )
      .then(() => {
        this.props.handleProcessActionFinish!(action)
        this.close()
      })
      .catch(() => {
        this.close()
      })
  }

  private close = () => {
    this.setState({
      isExecuting: false,
      isLoading: false,
      comment: '',
    })

    this.props.closeModal()
  }

  private setSelectedClaim = async (claimId: string) => {
    this.setState({
      selectedClaimId: claimId,
      modalPage: 2,
      hasClaim: true,
    })
  }

  private changeTypeHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    let selectedAppealType = event.target.selectedOptions[0].accessKey

    this.setState({
      selectedAppealType: selectedAppealType,
      hasType: selectedAppealType !== '',
    })
  }

  private getClaimReceivedDate = (claim: IClaimSummaryResult) => {
    if (!claim.isPastDue) {
      return <TableCell align="right">{formatDate(claim.claimReceivedDate)}</TableCell>
    } else {
      const toolTipTitle = 'Due date: ' + formatDate(claim.dueDate)
      return (
        <Tooltip title={toolTipTitle}>
          <TableCell style={{ color: 'red' }} align="right">
            {formatDate(claim.claimReceivedDate)}
          </TableCell>
        </Tooltip>
      )
    }
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={11} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((claim, index) => (
        <TableRow key={index}>
          <TableCell>
            <Button
              onClick={() => this.setSelectedClaim(claim.cms1500Id!)}
              color="primary"
              variant="contained"
            >
              Select
            </Button>
          </TableCell>
          <TableCell style={{ whiteSpace: 'nowrap', width: '1px' }}>
            {claim.groupNumber}&nbsp;&nbsp;
          </TableCell>
          <TableCell>{claim.status}</TableCell>
          {this.getClaimReceivedDate(claim)}
          <TableCell>{claim.providerLocation}</TableCell>
          <TableCell>{claim.locationType}</TableCell>
          <TableCell align="right">{formatDate(claim.dateOfService)}</TableCell>
          <TableCell align="right">{formatCurrency(claim.totalClaimCharges)}</TableCell>
          <TableCell align="right" style={{ color: 'black' }}>
            {claim.totalContractAmountDisplay != undefined
              ? claim.totalContractAmountDisplay
              : ''}
          </TableCell>
          <TableCell align="right">{claim.checkNumber}</TableCell>
          <TableCell align="right">{formatDate(claim.processedDate)}</TableCell>
        </TableRow>
      ))
    }
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () =>
      this.getClaimData(this.props.data!.claimData!.episodeOfCareId!)
    )
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  public render() {
    var modalTitle = this.props.action.actionName.toUpperCase()

    return (
      <>
        <Dialog
          key={this.props.action.id}
          fullWidth={true}
          maxWidth="lg"
          open={this.props.isOpen!}
        >
          <DialogTitle style={{ padding: 0 }}>
            <ResponsiveAppBar title={modalTitle} isModal={true} />
          </DialogTitle>
          <DialogContent style={{ padding: '12px 12px 8px' }}>
            {this.state.isLoading && (
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1200',
                }}
              >
                <CircularProgress size={100} />
              </div>
            )}
            {this.state.modalPage === 1 && (
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>EOC/Legacy</TableCell>
                        <TableCell>Claim Status</TableCell>
                        <TableCell align="right">Claim Rcv</TableCell>
                        <TableCell style={{ minWidth: '250px' }}>
                          Provider Location
                        </TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell align="right">DOS</TableCell>
                        <TableCell align="right">Claim Amt</TableCell>
                        <TableCell align="right">Contract Amt</TableCell>
                        <TableCell>Check#</TableCell>
                        <TableCell align="right">Processed</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{this.mapGridData()}</TableBody>
                  </Table>
                  <div className="pagination-row">
                    {this.paginationInfo()}
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      onPageChange={this.handlePageChange}
                      pageCount={this.state.pageCount}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                      //@ts-ignore
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            {this.state.modalPage === 2 && (
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12} style={{ paddingRight: '20px' }}>
                  <InputLabel shrink={true} variant={'outlined'}>
                    Appeal Type
                  </InputLabel>
                  <Select
                    native={true}
                    fullWidth
                    onChange={this.changeTypeHandler}
                    id="eventSelect"
                  >
                    <option value="">Please select an appeal type</option>
                    <option
                      accessKey={AppealType.OverpaidProvider.toString()}
                      value={AppealType.OverpaidProvider.toString()}
                    >
                      Overpaid Provder
                    </option>
                    <option
                      accessKey={AppealType.PayAfterDenial.toString()}
                      value={AppealType.PayAfterDenial.toString()}
                    >
                      Pay After Denial
                    </option>
                    <option
                      accessKey={AppealType.UnderpaidProvider.toString()}
                      value={AppealType.UnderpaidProvider.toString()}
                    >
                      Underpaid Provider
                    </option>
                  </Select>
                </Grid>
                {this.props.action.displayComment && (
                  <Grid item xs={12}>
                    <TextField
                      label="Comments"
                      name="notes"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required={this.props.action.requireComment}
                      multiline
                      minRows="4"
                      onChange={(event) => this.setState({ comment: event.target.value })}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            {this.state.modalPage == 2 && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.setState(
                      {
                        totalItems: 0,
                        page: 1,
                        modalPage: 1,
                        hasClaim: false,
                        gridData: new pagedList().items as ClaimSummaryResult[],
                      },
                      () => {
                        this.getClaimData(this.props.data.claimData?.episodeOfCareId!)
                      }
                    )
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.setState({
                      isExecuting: true,
                      isLoading: true,
                    })
                    this.save()
                  }}
                  disabled={
                    this.state.isExecuting ||
                    !this.state.hasClaim ||
                    !this.state.hasType ||
                    (this.props.action.requireComment && this.state.comment === '')
                  }
                >
                  Execute
                </Button>
              </>
            )}
            <Button
              onClick={() => {
                this.close()
              }}
              data-cy="close"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const InjectedMarkClaimAsAppealModal = inject<
  IStores,
  IMarkClaimAsAppealModalProps,
  Partial<IMarkClaimAsAppealModalProps>,
  any
>((stores: IStores) => ({
  rerouteToPath: stores.global.rerouteToPath,
}))(MarkClaimAsAppealModal)

export default InjectedMarkClaimAsAppealModal
