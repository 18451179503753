import { Label } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultTag, ITag } from '../Definitions'
import { IStores } from '../Stores'
import TagDeleteDialog from './TagDeleteDialog'
import TagDialog from './TagDialog'

export interface ITagsProps {
  dataTableManager?: IDataTableManager<ITag>
  disableTag?: (tag: ITag) => void
  getColumnSettingsAndTags?: () => void
  isDeleteDialogOpen?: boolean
  isModalOpen?: boolean
  openDialogWithTag?: (tag: ITag) => void
  recoverTag?: (tag: ITag) => void
}

class Tags extends React.Component<ITagsProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndTags!()
  }

  public render() {
    const tagDialog = this.props.isModalOpen && <TagDialog />
    const tagDeleteDialog = this.props.isDeleteDialogOpen && <TagDeleteDialog />
    return (
      <>
        <Helmet>
          <title>Tags</title>
        </Helmet>
        <ResponsiveAppBar title="Tags" pageIcon={<Label />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={false}
          enableSearch={true}
          enableShowInactives={false}
          enableToggleColumns={true}
          addButtonOnClick={this.openDialog(DefaultTag(true))}
        />
        {tagDialog}
        {tagDeleteDialog}
      </>
    )
  }

  public openDialog = (tag: ITag) => () => {
    this.props.openDialogWithTag!(tag)
  }
}

const InjectedTags = inject<IStores, ITagsProps, Partial<ITagsProps>, any>(
  (stores: IStores) => ({
    dataTableManager: stores.tags.dataTableStore,
    disableTag: stores.tags.disableTag,
    getColumnSettingsAndTags: stores.tags.getColumnSettingsAndTags,
    isDeleteDialogOpen: stores.tags.isDeleteDialogOpen,
    isModalOpen: stores.tags.isModalOpen,
    openDialogWithTag: stores.tags.openDialogWithTag,
    recoverTag: stores.tags.recoverTag,
  })
)(Tags)

export default InjectedTags
