import { AddCircle, Edit, Assignment } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { IStores } from '../../Stores'
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ReactPaginate from 'react-paginate'
import { EpisodeOfCareSummaryResult } from 'src/viewModels/EpisodeOfCareSummaryResult'
import { IReferral } from 'src/Definitions'

interface IEpisodesOfCareProps {
  editEpisodeOfCare?: (episodeOfCareId: string | undefined) => void
  addEpisodeOfCare?: (eoc: undefined, openInNewTab: boolean) => void
  loadEpisodesOfCare?: () => Promise<void>
  selectedReferral?: IReferral
  items?: EpisodeOfCareSummaryResult[]
  totalItems?: number
  pageCount?: number
  firstRecordIndex?: number
  lastRecordIndex?: number
  page?: number
  setPage?: (page: number) => void
}

class EpisodesOfCare extends React.Component<IEpisodesOfCareProps> {
  public state = {
    gridLoading: false,
  }

  private getEOCData = async () => {
    if (this.props.selectedReferral) {
      this.setState({ gridLoading: true })
      this.props.loadEpisodesOfCare!().finally(() =>
        this.setState({ gridLoading: false })
      )
    }
  }

  private addEOC = () => {
    if (this.props.selectedReferral) {
      this.props.addEpisodeOfCare!(undefined, false)
    }
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.props.setPage!(page + 1)
    this.getEOCData()
  }

  private isGridDataEmpty = () => {
    return this.props.totalItems == undefined || this.props.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div style={{ paddingBottom: '20px' }} className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.props.firstRecordIndex} to {this.props.lastRecordIndex} of{' '}
            {this.props.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty() || this.props.selectedReferral == undefined) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.props.items?.map((eoc, index) => (
        <TableRow key={index}>
          <TableCell>{eoc.referralDateDisplay}</TableCell>
          <TableCell>{eoc.takeoverOfCare}</TableCell>
          <TableCell>{eoc.generatedNumberOrLegacy}</TableCell>
          <TableCell>{eoc.status}</TableCell>
          <TableCell>{eoc.surgeryDateDisplay}</TableCell>
          <TableCell>{eoc.lastMMIDateDisplay}</TableCell>
          <TableCell>{eoc.careCoordinatorName}</TableCell>
          <TableCell>{eoc.bodyPart}</TableCell>
          <TableCell>{eoc.organizationName}</TableCell>
          <TableCell style={{ padding: '0px', color: 'inherit' }}>
            <Edit
              data-testid={`episodeOfCareGridEdit.${index}`}
              style={{ cursor: 'pointer' }}
              fontSize="small"
              onClick={() => this.props.editEpisodeOfCare!(eoc.episodeOfCareId)}
            />
          </TableCell>
        </TableRow>
      ))
    }
  }

  public render() {
    var isAddButtonDisabled = this.props.selectedReferral == undefined

    return (
      <>
        <Grid container direction="row-reverse">
          <Grid item style={{ color: '#AAAAAA' }}>
            <AddCircle
              color={isAddButtonDisabled ? 'inherit' : 'primary'}
              onClick={isAddButtonDisabled ? undefined : this.addEOC}
              style={{
                fontSize: '30px',
                marginRight: '8px',
                cursor: isAddButtonDisabled ? 'auto' : 'pointer',
              }}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="center" spacing={2}>
          <Grid item>
            <Assignment style={{ fontSize: '32px' }} color="secondary" />
          </Grid>
          <Grid item style={{ fontSize: '20px', paddingTop: '4px' }}>
            Episodes of Care
          </Grid>
        </Grid>
        {this.state.gridLoading && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1200',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <Grid container item md={12} style={{ minWidth: '750px', width: '100%' }}>
          <Table aria-label="simple table" style={{ padding: '20' }}>
            <TableHead>
              <TableRow className="gridPadding">
                <TableCell>Referral Date</TableCell>
                <TableCell>Takeover of Care</TableCell>
                <TableCell>EOC/Legacy</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Surgery Date</TableCell>
                <TableCell>MMI</TableCell>
                <TableCell>Care Coordinator</TableCell>
                <TableCell>Body Part</TableCell>
                <TableCell>Org</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ width: '100%' }}>{this.mapGridData()}</TableBody>
          </Table>
        </Grid>
        <div className="pagination-row">
          {this.paginationInfo()}
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            onPageChange={this.handlePageChange}
            pageCount={this.props.pageCount ?? 0}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={(this.props.page ?? 1) - 1}
            //@ts-ignore
            renderOnZeroPageCount={null}
          />
        </div>
      </>
    )
  }
}

const InjectedEpisodesOfCare = inject<
  IStores,
  IEpisodesOfCareProps,
  Partial<IEpisodesOfCareProps>,
  any
>((stores: IStores) => ({
  editEpisodeOfCare: stores.patientEdit.openEOCFromGrid,
  addEpisodeOfCare: stores.patientEdit.addOrEditEpisodeOfCare,
  loadEpisodesOfCare: stores.patientEdit.getEOCs,
  selectedReferral: stores.referrals.selectedReferral,
  items: stores.patientEdit.eocs,
  totalItems: stores.patientEdit.eocTotalItems,
  pageCount: stores.patientEdit.eocPageCount,
  firstRecordIndex: stores.patientEdit.eocFirstRecordIndex,
  lastRecordIndex: stores.patientEdit.eocLastRecordIndex,
  page: stores.patientEdit.eocPage,
  setPage: stores.patientEdit.setEOCPage,
}))(EpisodesOfCare)

export default InjectedEpisodesOfCare
