import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { ReceiptOutlined } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'

interface IQuoteLogLongCommentsModalProps {
  close?: () => void
  isOpen?: boolean
  selectedQuote?: IQuoteDTO
  selectedLongComments?: string
}

class QuoteLogLongCommentsModal extends React.Component<IQuoteLogLongCommentsModalProps> {
  public render() {
    const { isOpen, selectedQuote } = this.props

    return (
      <Dialog key={selectedQuote?.id} fullWidth={true} maxWidth="lg" open={isOpen!}>
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title={'Comments'}
            pageIcon={<ReceiptOutlined />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent style={{ paddingTop: '8px' }}>
          <div style={{ whiteSpace: 'pre-wrap' }}>{this.props.selectedLongComments}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private close = () => {
    this.props.close!()
  }
}

const InjectedQuoteLogLongCommentsModal = inject<
  IStores,
  IQuoteLogLongCommentsModalProps,
  Partial<IQuoteLogLongCommentsModalProps>,
  any
>((stores: IStores) => ({
  close: stores.quotes.closeLongCommentsModal,
  isOpen: stores.quotes.isLongCommentsModalOpen,
  selectedQuote: stores.quotes.selectedQuote,
  selectedLongComments: stores.quotes.selectedLongComments,
}))(QuoteLogLongCommentsModal)

export default InjectedQuoteLogLongCommentsModal
