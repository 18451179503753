import {
  AppBar,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ReceiptOutlined, Warning } from '@material-ui/icons'
import { inject } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import { Helmet } from 'react-helmet'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import AssignPendModal from './AssignPendModal'
import ActionLogModal from './ActionLogModal'
import StandardCommentModal from './StandardCommentModal'
import AssociatePatientEOCModal from './customForms/AssociatePatientEOCModal'
import { ICMS1500, IUser } from 'src/Definitions'
import { ProcessAction } from 'src/viewModels/workflow/ProcessAction'
import {
  GetClaimReadyForBilloutInbox,
  processAction,
  processDynamicAction,
} from 'src/services/WorkflowService'
import { StaticAction } from 'src/utils/StaticAction'
import { CommentOutline } from 'mdi-material-ui'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import { WorkflowInboxItemHeader } from 'src/viewModels/workflow/InboxItemHeader'
import { DocumentCustomForm } from 'src/utils/DocumentCustomForm'
import { ClaimCustomForm } from 'src/utils/ClaimCustomForm'
import InboxTypePathConstants, { InboxType } from 'src/utils/InboxType'
import { ClaimStaticAction } from 'src/utils/ClaimStaticAction.enum'
import { BilloutStaticAction } from 'src/utils/BilloutStaticAction.enum'
import { ObjectiveProcessAction } from 'src/viewModels/workflow/ObjectiveProcessAction'
import CompleteWithContractTBDModal from './customForms/CompleteWithContractTBDModal'
import CompleteClaimModal from './customForms/CompleteClaimModal'
import MarkClaimAsAppealModal from './customForms/MarkClaimAsAppealModal'
import SendToAdjusterModal from 'src/workflow/customForms/SendToAdjusterModal'
import { QuoteCustomForm } from 'src/utils/QuoteCustomForm'
import AssignToEntryUserAndSendModal from './customForms/AssignToEntryUserAndSendModal'
import { QuoteStaticAction } from 'src/utils/QuoteStaticAction.enum'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import SetBundleDurationDaysModal from './customForms/SetBundleDurationDaysModal'
import ReceiveInvoicePaymentModal from './customForms/ReceiveInvoicePaymentModal'
import CreateBilloutModal from './customForms/CreateBilloutModal'
import { BillOutCustomForm } from 'src/utils/BillOutCustomForm'
import { BillOutInboxItem } from 'src/viewModels/workflow/BillOutInboxItem'
import ReceiveBilloutPaymentModal from './customForms/ReceiveBilloutPaymentModal'

interface IWorkflowHeaderProps {
  headerInfo: WorkflowInboxItemHeader
  inboxType: InboxType
  icon: JSX.Element
  openActionLog?: (itemId: string) => void
  user?: IUser
  rerouteToPath?: (path: string) => void
  getClaimValuesForStaticAction?: () => Promise<ICMS1500>
  getQuoteValuesForStaticAction?: () => Promise<IQuoteDTO>
}

type ModalRenderProps = {
  closeModal: () => void
  isOpen: boolean
  inboxItemId: number
  inboxId: string
  associatedItemId: string
  updateClaim: boolean
  action: InboxActionSummary
  isDynamicItem: boolean
  inboxType: InboxType
  data: ObjectiveProcessAction
  actionList: InboxActionSummary[]
  handleProcessActionFinish?: (selectedAction?: InboxActionSummary) => void
  closeTab?: () => void
  entryUser: string
  nextInboxId?: number
  nextInboxText?: string
  takeoverOfCareDate?: Date
}
type ModalName =
  | 'assignPendModal'
  | 'commentModal'
  | 'associatePatientModal'
  | 'actionLogModal'
  | 'completeTBDModal'
  | 'completeClaimModal'
  | 'markClaimAsAppealModal'
  | 'sendToAdjusterModal'
  | 'assignToEntryUserAndSendModal'
  | 'setBundleDurationDaysModal'
  | 'receiveInvoicePaymentModal'
  | 'createBilloutModal'
  | 'receiveBilloutPaymentModal'

const modalRenderers: Record<
  ModalName,
  (props: ModalRenderProps) => React.ReactElement | null
> = {
  assignPendModal: (props) => <AssignPendModal {...props} />,
  commentModal: (props) => <StandardCommentModal {...props} />,
  associatePatientModal: (props) => <AssociatePatientEOCModal {...props} />,
  actionLogModal: (props) => <ActionLogModal {...props} />,
  completeTBDModal: (props) => <CompleteWithContractTBDModal {...props} />,
  completeClaimModal: (props) => <CompleteClaimModal {...props} />,
  markClaimAsAppealModal: (props) => <MarkClaimAsAppealModal {...props} />,
  sendToAdjusterModal: (props) => <SendToAdjusterModal {...props} />,
  assignToEntryUserAndSendModal: (props) => <AssignToEntryUserAndSendModal {...props} />,
  setBundleDurationDaysModal: (props) => <SetBundleDurationDaysModal {...props} />,
  receiveInvoicePaymentModal: (props) => <ReceiveInvoicePaymentModal {...props} />,
  createBilloutModal: (props) => <CreateBilloutModal {...props} />,
  receiveBilloutPaymentModal: (props) => <ReceiveBilloutPaymentModal {...props} />,
}

interface IWorkflowHeaderState {
  actionToExecute?: InboxActionSummary
  selectedAction?: InboxActionSummary
  activeModal: ModalName | null
  executed: boolean
  data: ObjectiveProcessAction
  billoutSearchDefault?: number | undefined
}

class WorkflowHeader extends React.Component<IWorkflowHeaderProps> {
  public state: IWorkflowHeaderState = {
    activeModal: null,
    actionToExecute: undefined,
    selectedAction: undefined,
    executed: false,
    data: undefined as unknown as ObjectiveProcessAction,
    billoutSearchDefault: undefined,
  }

  private priorityNames = {
    1: 'Normal',
    2: 'Medium',
    3: 'High',
  }

  componentDidMount(): void {
    this.closeModal = this.closeModal.bind(this)
    this.closeTab = this.closeTab.bind(this)
    this.handleProcessActionFinish = this.handleProcessActionFinish.bind(this)
  }

  private setSelectedAction = async (event: any) => {
    var selectedAction = this.props.headerInfo.actions.find(
      (x) => x.id == event.target.value
    )
    let data = new ObjectiveProcessAction()
    this.setState({
      selectedAction: selectedAction,
      actionToExecute: undefined,
      data: data,
    })
  }

  public closeModal() {
    this.setState({ activeModal: null, executed: false })
  }

  public closeTab() {
    if (
      window.location.toString().indexOf('default-inbox') > -1 ||
      window.location.toString().indexOf('default-billout-search') > -1
    ) {
      window.close()
    } else if (window.location.toString().indexOf('billout-search') > -1) {
      this.props.rerouteToPath!(`/billOut`)
    } else if (window.location.toString().indexOf('received-search-default') > -1) {
      this.props.rerouteToPath!(`/receivePayment`)
    } else {
      this.props.rerouteToPath!(`/workflow-inboxes`)
    }
  }

  private handleProcessActionFinish(selectedAction?: InboxActionSummary) {
    if (selectedAction?.closeAfterProcessing) {
      this.closeTab()
    } else if (
      !selectedAction?.closeAfterProcessing &&
      selectedAction?.nextInboxId == undefined &&
      this.state.billoutSearchDefault == undefined
    ) {
      this.props.rerouteToPath!(
        `/workflow/${InboxTypePathConstants[this.props.inboxType]}/${
          this.props.headerInfo.associatedItemId
        }/default-inbox`
      )
      window.location.reload()
    } else if (this.state.billoutSearchDefault != undefined) {
      var claimId = (this.props.headerInfo as unknown as BillOutInboxItem).claimId
      this.props.rerouteToPath!(
        `/workflow/billout/${claimId}/default-billout-search/${this.state.billoutSearchDefault}`
      )
    } else {
      window.location.reload()
    }
  }

  private async executeAction() {
    var selectedAction = this.state.selectedAction as unknown as InboxActionSummary

    if (
      (selectedAction.staticActionId == BilloutStaticAction.REVERSE_DISMISSAL ||
        selectedAction.staticActionId == BilloutStaticAction.REVERSE_BILLOUT) &&
      !selectedAction.closeAfterProcessing &&
      window.location.toString().indexOf('default-inbox') > -1
    ) {
      this.setState({
        billoutSearchDefault: await GetClaimReadyForBilloutInbox(),
      })
    }
    if (selectedAction.hasCustomForm) {
      switch (selectedAction.customFormName) {
        case DocumentCustomForm.CreateClaim:
        case ClaimCustomForm.UpdatePatient:
          this.setState({
            actionToExecute: selectedAction,
            activeModal: 'associatePatientModal',
          })
          break
        case ClaimCustomForm.InitialComplete:
          this.state.data.claimData = await this.props.getClaimValuesForStaticAction!()
          this.setState({
            data: this.state.data,
            actionToExecute: selectedAction,
            activeModal: 'completeTBDModal',
          })
          break
        case ClaimCustomForm.FinalComplete:
          this.state.data.claimData = await this.props.getClaimValuesForStaticAction!()
          this.setState({
            data: this.state.data,
            actionToExecute: selectedAction,
            activeModal: 'completeClaimModal',
          })
          break
        case ClaimCustomForm.MarkAsAppeal:
          this.state.data.claimData = await this.props.getClaimValuesForStaticAction!()
          this.setState({
            data: this.state.data,
            actionToExecute: selectedAction,
            activeModal: 'markClaimAsAppealModal',
          })
          break
        case QuoteCustomForm.SendToAdjuster:
          this.setState({
            actionToExecute: selectedAction,
            activeModal: 'sendToAdjusterModal',
          })
          break
        case QuoteCustomForm.SetBundleDurationDays:
          this.setState({
            data: this.state.data,
            actionToExecute: selectedAction,
            activeModal: 'setBundleDurationDaysModal',
          })
          break
        case ClaimCustomForm.AssignToEntryUserAndSend:
        case DocumentCustomForm.AssignToEntryUserAndSend:
        case QuoteCustomForm.AssignToEntryUserAndSend:
          this.state.data.assignUser = this.props.headerInfo.entryUser
          this.setState({
            data: this.state.data,
            actionToExecute: selectedAction,
            activeModal: 'assignToEntryUserAndSendModal',
          })
          break
        case QuoteCustomForm.ReceiveInvoicePayment:
          this.setState({
            data: this.state.data,
            actionToExecute: selectedAction,
            activeModal: 'receiveInvoicePaymentModal',
          })
          break
        case BillOutCustomForm.CreateBillout:
          this.setState({
            data: this.state.data,
            actionToExecute: selectedAction,
            activeModal: 'createBilloutModal',
          })
          break
        case BillOutCustomForm.ReceiveBilloutPayment:
          this.setState({
            data: this.state.data,
            actionToExecute: selectedAction,
            activeModal: 'receiveBilloutPaymentModal',
          })
          break
        default:
          break
      }
    } else if (
      selectedAction?.staticActionId != undefined &&
      selectedAction?.staticActionId != StaticAction.AUTO_ASSIGN &&
      selectedAction?.staticActionId != BilloutStaticAction.PRINT &&
      selectedAction?.staticActionId != BilloutStaticAction.PRINT_VERIFIED &&
      selectedAction?.staticActionId != BilloutStaticAction.REVERSE_BILLOUT &&
      selectedAction?.staticActionId != BilloutStaticAction.REVERSE_DISMISSAL
    ) {
      switch (selectedAction.staticActionId) {
        case StaticAction.RELEASE_LOCK:
          var data = new ProcessAction()
          processAction(
            data,
            selectedAction!.id.toString(),
            this.props.headerInfo.id.toString()
          )
            .then(() => {
              this.handleProcessActionFinish(selectedAction)
            })
            .catch(() => {
              this.setState({ executed: false })
            })
          break
        case StaticAction.ASSIGN:
        case StaticAction.PEND:
          this.setState({
            actionToExecute: selectedAction,
            activeModal: 'assignPendModal',
          })
          break
        case ClaimStaticAction.SAVE_CLAIM:
          {
            let data = new ObjectiveProcessAction()
            data.claimData = await this.props.getClaimValuesForStaticAction!()

            if (selectedAction.displayComment) {
              this.setState({
                data: data,
                actionToExecute: selectedAction,
                activeModal: selectedAction.displayComment ? 'commentModal' : null,
              })
            } else {
              processAction(
                data,
                selectedAction!.id.toString(),
                this.props.headerInfo.id.toString()
              )
                .then(() => {
                  this.handleProcessActionFinish(selectedAction)
                })
                .catch(() => {
                  this.setState({ executed: false })
                })
            }
          }
          break
        case QuoteStaticAction.SAVE_QUOTE:
          {
            let data = new ObjectiveProcessAction()
            data.quoteData = await this.props.getQuoteValuesForStaticAction!()

            if (selectedAction.displayComment) {
              this.setState({
                data: data,
                actionToExecute: selectedAction,
                activeModal: selectedAction.displayComment ? 'commentModal' : null,
              })
            } else {
              processAction(
                data,
                selectedAction!.id.toString(),
                this.props.headerInfo.id.toString()
              )
                .then(() => {
                  this.handleProcessActionFinish(selectedAction)
                })
                .catch(() => {
                  this.setState({ executed: false })
                })
            }
          }
          break
        default:
          break
      }
    } else if (selectedAction.displayComment) {
      //Comment modal
      this.setState({
        actionToExecute: selectedAction,
        activeModal: 'commentModal',
      })
    } else {
      var data = new ProcessAction()

      if (this.props.headerInfo.isDynamicItem) {
        processDynamicAction(
          data,
          this.props.headerInfo.inboxId.toString(),
          selectedAction!.id.toString(),
          this.props.headerInfo.associatedItemId.toString()
        )
          .then(() => {
            this.handleProcessActionFinish(selectedAction)
          })
          .catch(() => {
            this.setState({ executed: false })
          })
      } else {
        processAction(
          data,
          selectedAction!.id.toString(),
          this.props.headerInfo.id.toString()
        )
          .then(() => {
            this.handleProcessActionFinish(selectedAction)
          })
          .catch(() => {
            this.setState({ executed: false })
          })
      }
    }
  }

  private close() {
    if (
      this.props.headerInfo.restrictedToOutOfWorkflowActions ||
      this.props.headerInfo.isDynamicItem
    ) {
      this.closeTab()
    } else {
      var actions = this.props.headerInfo.actions
      var unlock = actions.find((x) => x.staticActionId == StaticAction.RELEASE_LOCK)

      var data = new ProcessAction()
      data.assignUser = ''

      processAction(data, unlock!.id.toString(), this.props.headerInfo.id.toString())
        .then(() => {
          this.closeTab()
        })
        .catch(() => {
          this.setState({ executed: false })
        })
    }
  }

  public render() {
    const { headerInfo } = this.props

    return (
      <>
        {this.props.headerInfo && (
          <>
            <Helmet>
              <title>{'Process ' + this.props.headerInfo.inboxName}</title>
            </Helmet>
            {(this.state.activeModal == 'actionLogModal' ||
              (this.state.activeModal &&
                this.state.actionToExecute &&
                this.state.executed)) &&
              modalRenderers[this.state.activeModal]({
                closeModal: this.closeModal,
                isOpen: this.state.activeModal != undefined,
                inboxItemId: headerInfo.id,
                action:
                  this.state.activeModal == 'actionLogModal' ||
                  this.state.actionToExecute == undefined
                    ? new InboxActionSummary()
                    : this.state.actionToExecute,
                inboxId: headerInfo.inboxId.toString(),
                associatedItemId: headerInfo.associatedItemId,
                updateClaim: this.props.inboxType == InboxType.Claim,
                isDynamicItem: headerInfo.isDynamicItem,
                inboxType: this.props.inboxType,
                data: this.state.data,
                actionList: headerInfo.actions,
                handleProcessActionFinish: this.handleProcessActionFinish,
                closeTab: this.closeTab,
                entryUser: headerInfo.entryUser,
                nextInboxId: this.state.selectedAction?.nextInboxId,
                nextInboxText: this.state.selectedAction?.nextInboxText,
              })}
            <AppBar style={{ boxShadow: 'none' }} position="sticky">
              <ResponsiveAppBar
                title={'Process ' + this.props.headerInfo.inboxName}
                pageIcon={this.props.icon}
              ></ResponsiveAppBar>
            </AppBar>
            {this.state.executed && (
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '2',
                }}
              >
                <CircularProgress size={100} />
              </div>
            )}

            <Grid
              container
              className="actionBar"
              style={{
                width: '100%',
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '20px',
              }}
            >
              <Grid item container direction="column" xs={9}>
                <Grid item container direction="row" justifyContent="flex-start">
                  <Grid item xs={5}>
                    <FormControl style={{ width: '100%' }} variant="outlined">
                      <InputLabel shrink={true} variant="outlined">
                        Actions
                      </InputLabel>
                      <Select
                        variant="outlined"
                        input={<OutlinedInput notched labelWidth={55} />}
                        inputProps={{ label: true, notched: true }}
                        native={true}
                        fullWidth
                        onChange={this.setSelectedAction}
                        id="actionSelect"
                        value={this.state.selectedAction?.id}
                      >
                        <option value="">Select an action</option>
                        {// TODO:  Need to discuss order by and static action placement
                        headerInfo.actions?.map((action) =>
                          !action.excludeFromMainActionList ? (
                            <option value={action.id}>{action.actionName}</option>
                          ) : (
                            <></>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={4}
                    spacing={1}
                    style={{
                      paddingLeft: '8px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.setState({ executed: true })
                          this.executeAction()
                        }}
                        disabled={
                          this.state.executed || this.state.selectedAction == undefined
                        }
                      >
                        Execute
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          this.setState({ executed: true })
                          this.close()
                        }}
                        disabled={this.state.executed}
                      >
                        {headerInfo.restrictedToOutOfWorkflowActions ||
                        headerInfo.isDynamicItem
                          ? 'Close'
                          : 'Unlock'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    INBOX: <strong>{headerInfo.inboxName}</strong>
                  </Typography>
                </Grid>
                {headerInfo.lockedBy && (
                  <Grid item>
                    <Typography variant="caption" style={{ display: 'contents' }}>
                      LOCKED BY: <strong>{headerInfo.lockedBy}</strong>
                    </Typography>
                    &nbsp;&nbsp;
                    {this.props.headerInfo.lockedBy != this.props.user?.userName && (
                      <Tooltip title="This item is locked">
                        <Warning style={{ fontSize: '16px', color: '#ffc107' }} />
                      </Tooltip>
                    )}
                  </Grid>
                )}
                {headerInfo.lastAction && (
                  <Grid item>
                    <Typography variant="caption" style={{ display: 'contents' }}>
                      LAST ACTION:&nbsp;
                      <strong>{headerInfo.lastAction.name}</strong> on{' '}
                      {moment(headerInfo.lastAction.date).format('L')}
                      &nbsp;by&nbsp;{headerInfo.lastAction.user}
                    </Typography>
                    &nbsp;&nbsp;
                    {headerInfo.lastAction.comment && (
                      <Tooltip title={headerInfo.lastAction.comment}>
                        <span>
                          <CommentOutline />
                        </span>
                      </Tooltip>
                    )}
                  </Grid>
                )}
                {this.props.inboxType == InboxType.Billout &&
                  (this.props.headerInfo as unknown as BillOutInboxItem)
                    .currentStatus && (
                    <Grid item>
                      <Typography variant="caption" style={{ display: 'contents' }}>
                        BILL OUT STATUS:&nbsp;
                        <strong>
                          {
                            (this.props.headerInfo as unknown as BillOutInboxItem)
                              .currentStatus
                          }
                        </strong>
                      </Typography>
                    </Grid>
                  )}
              </Grid>
              <Grid
                container
                item
                direction="column"
                xs={3}
                alignContent="flex-end"
                style={{ paddingRight: '8px' }}
              >
                {headerInfo.entryDate && (
                  <Grid item>
                    <Typography variant="caption">
                      ENTRY DATE&nbsp;
                      <strong>
                        {moment(
                          headerInfo.isDynamicItem ? new Date() : headerInfo.entryDate
                        ).format('L')}
                      </strong>
                      &nbsp;
                      <Tooltip title="View Action Log">
                        <ReceiptOutlined
                          fontSize="small"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.setState({ activeModal: 'actionLogModal' })
                          }}
                        />
                      </Tooltip>
                    </Typography>
                  </Grid>
                )}
                {!headerInfo.isDynamicItem && headerInfo.priority && (
                  <Grid item>
                    <Typography variant="caption">
                      PRIORITY&nbsp;
                      <strong>{this.priorityNames[headerInfo.priority]}</strong>
                    </Typography>
                  </Grid>
                )}
                {headerInfo.pendDate && (
                  <Grid item>
                    <Typography variant="caption">
                      PEND&nbsp;
                      <strong>{moment(headerInfo.pendDate).format('L')}</strong>{' '}
                      <i>{headerInfo.pendNote}</i>
                    </Typography>
                  </Grid>
                )}
                {headerInfo.assignedUser && (
                  <Grid item>
                    <Typography variant="caption">
                      ASSIGNMENT: <strong>{headerInfo.assignedUser}</strong>
                      {headerInfo.assignmentDueDate && (
                        <i>
                          &nbsp; with due date&nbsp;
                          {moment(headerInfo.assignmentDueDate).format('MM/DD/YYYY')}
                        </i>
                      )}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </>
    )
  }
}

const InjectedWorkflowHeader = inject<
  IStores,
  IWorkflowHeaderProps,
  Partial<IWorkflowHeaderProps>,
  any
>((stores: IStores) => ({
  user: stores.global.user,
  rerouteToPath: stores.global.rerouteToPath,
  getClaimValuesForStaticAction: stores.cms1500.getClaimValuesForStaticAction,
  getQuoteValuesForStaticAction: stores.quotes.getQuoteValuesForStaticAction,
}))(WorkflowHeader)

export default InjectedWorkflowHeader
