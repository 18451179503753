import { action, observable, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import { parse } from 'query-string'
import { IOrganization } from '../../Definitions'
import { Client, LoginDto, PasswordRuleSettings } from '../../generated_client'
import addDefaultUserFields from '../../utils/addDefaultUserFields'
import GlobalStore from '../GlobalStore'
import NotificationStore from '../NotificationStore'

export interface IPasswordPattern {
  regex?: RegExp
  failMessage?: string
}

class LoginStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isLoadingForgotPassword: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public passwordPattern: IPasswordPattern
  @observable
  public isResetTokenValid: boolean

  constructor(
    private globalStore: GlobalStore,
    private notificationStore: NotificationStore,
    private routerStore: RouterStore,
    private client: Client
  ) {}

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
  }

  @action.bound
  public forgotPassword(email: string) {
    this.isLoadingForgotPassword = true
    this.notificationStore.sendNotification(
      '200',
      'Password reset email being sent to ' +
        email.replace(/(?<=.{3}).*(?=\@.*?)/, '***') +
        '. It may take a few minutes.',
      { variant: 'info' }
    )
    return this.client
      .apiLoginForgotPasswordPost(new LoginDto({ email }))
      .then(() => {
        this.notificationStore.sendNotification(
          '200',
          'Password reset email has been sent.',
          {
            variant: 'success',
          }
        )
        runInAction(() => {
          this.closeDialog()
        })
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoadingForgotPassword = false
        })
      })
  }

  @action.bound
  public login(userName: string, password: string) {
    this.isLoading = true
    return this.client
      .apiLoginPost(new LoginDto({ userName, password }))
      .then((resp) => {
        const data = resp.result
        const user = addDefaultUserFields(data)
        this.globalStore.setUser(user)
        this.globalStore.changeTheme(user.organization!.id)
        this.globalStore.setCurrentAppOrganization(
          user.organization as unknown as IOrganization
        )
        this.navigateAfterLogin()
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public getTokenValidity(userName: string, resetToken: string) {
    this.isLoading = true
    this.client
      .apiLoginTokenValidityPost(userName, resetToken)
      .then((resp) => {
        runInAction(() => {
          this.isResetTokenValid = resp.result
        })
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  public logout() {
    this.client
      .apiLoginDelete()
      .then(() => {
        this.globalStore.clearUserAndRouteToLogin()
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public openDialog() {
    this.isModalOpen = true
  }

  @action.bound
  public resetPassword(userName: string, password: string, resetPasswordToken: string) {
    this.isLoading = true
    return this.client
      .apiLoginPut(new LoginDto({ userName, password, resetPasswordToken }))
      .then(() => {
        this.notificationStore.sendNotification(
          '200',
          'Your password has been reset. Redirecting you to the home page.',
          { variant: 'success' }
        )
        this.isResetTokenValid = false
        this.login(userName, password)
      })
      .catch(() => {
        throw new Error()
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public getPasswordPattern() {
    if (!this.passwordPattern) {
      return this.client.apiPasswordRulesGet().then((response) => {
        runInAction(() => {
          this.passwordPattern = this.getPasswordPatternForRules(response.result)
        })
      })
    }
    return Promise.resolve()
  }

  private getPasswordPatternForRules(passwordRuleSettings: PasswordRuleSettings) {
    const regexObject: IPasswordPattern = {
      failMessage: '',
      regex: new RegExp(''),
    }
    let passwordRegex = ''

    const regexCap = `(?=.*[A-Z])`
    const regexLow = `(?=.*[a-z])`
    const regexDig = `(?=.*\\d)`
    const regexSpc = `(?=.*[#@$%*&?!^~;:_,.\\(\\)\\+\\-\\=\\[\\]\\{\\}\\<\\>])`
    let failMessage = 'Password should follow the following rules:\n'

    const regexLength = `[A-Za-z\\d#@$%*&?!^~;:_,.\\(\\)\\+\\-\\=\\[\\]\\{\\}\\<\\>]{${passwordRuleSettings.requiredLength},}`
    failMessage += `\t- Contain at least ${passwordRuleSettings.requiredLength} characters\n`

    if (passwordRuleSettings.requireUppercase) {
      passwordRegex += regexCap
      failMessage += '\t- Contain at least 1 uppercase letter\n'
    }
    if (passwordRuleSettings.requireLowercase) {
      passwordRegex += regexLow
      failMessage += '\t- Contain at least 1 lowercase letter\n'
    }
    if (passwordRuleSettings.requireDigit) {
      passwordRegex += regexDig
      failMessage += '\t- Contain at least 1 digit\n'
    }
    if (passwordRuleSettings.requireNonAlphanumeric) {
      passwordRegex += regexSpc
      failMessage += '\t- Contain at least 1 special character ($!@ etc.)'
    }

    regexObject.regex = new RegExp((passwordRegex += regexLength))
    regexObject.failMessage = failMessage

    return regexObject
  }

  private navigateAfterLogin() {
    let route = '/patients'
    const queryParams = parse(this.routerStore.location.search)
    if (queryParams && queryParams.previousRoute) {
      route = atob('' + queryParams.previousRoute)
    }
    this.routerStore.push(route)
  }
}

export default LoginStore
