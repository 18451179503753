import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from 'src/Stores'
import WorkflowInbox from './WorkflowInbox'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import { RouteComponentProps } from 'react-router-dom'
import { InsertDriveFile } from '@material-ui/icons'
import { getDocumentInboxItem, processToWorkflow } from 'src/services/WorkflowService'
import { IncomingDocumentSummary } from 'src/viewModels/IncomingDocumentSummary'
import { DocumentInboxItem } from 'src/viewModels/workflow/DocumentInboxItem'
import { InboxType } from 'src/utils/InboxType'
import createDocumentUrl from 'src/utils/createDocumentUrl'

interface IDocumentInboxRouteParams {
  documentName: string
  documentId: string
  inboxId: string
}

interface IDocumentInboxProps extends RouteComponentProps<IDocumentInboxRouteParams> {
  rerouteToPath?: (path: string) => void
}

class DocumentInbox extends React.Component<IDocumentInboxProps> {
  public state = {
    docData: '',
    headerInfo: undefined as unknown as DocumentInboxItem,
    isLoading: true,
  }

  public async componentDidMount(): Promise<void> {
    if (this.props.match.params.documentName) {
      var summary = new IncomingDocumentSummary()
      summary.name = this.props.match.params.documentName

      await processToWorkflow(summary)
        .then((response: any) => {
          this.props.rerouteToPath!(
            `/workflow/document/${response.associatedItemId}/inbox/${response.inboxId}`
          )
        })
        .catch(() => {
          this.props.rerouteToPath!(`/workflow-inboxes`)
        })
    } else {
      await getDocumentInboxItem(
        this.props.match.params.documentId,
        this.props.match.params.inboxId
      )
        .then((response: any) => {
          var doc = createDocumentUrl(
            response.associatedStagedDocument.content,
            response.associatedStagedDocument.mime
          )
          this.setState({
            docData: doc,
            headerInfo: response,
            isLoading: false,
          })
        })
        .catch(() => {
          this.props.rerouteToPath!(`/workflow-inboxes`)
        })
    }
  }

  public renderDoc() {
    return (
      <Grid
        container
        spacing={2}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
          paddingTop: '8px',
        }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              window.open(this.state.docData, 'new', 'width=1200,height=800')
            }
          >
            View in New Window
          </Button>
        </Grid>
        <iframe
          src={this.state.docData}
          height="600px"
          width="100%"
          style={{ border: 'none' }}
        />
      </Grid>
    )
  }

  public render() {
    return (
      //Spinner
      <>
        {this.state.isLoading && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '2',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <WorkflowInbox
          inboxType={InboxType.Document}
          item={this.renderDoc()}
          headerInfo={this.state.headerInfo}
          icon={<InsertDriveFile />}
        />
      </>
    )
  }
}

const InjectedDocumentInbox = inject<
  IStores,
  IDocumentInboxProps,
  Partial<IDocumentInboxProps>,
  any
>((stores: IStores) => ({
  rerouteToPath: stores.global.rerouteToPath,
}))(DocumentInbox)

export default InjectedDocumentInbox
