import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import { DefaultReferringDoctor, IReferringDoctor } from '../../Definitions'
import {
  Client,
  IReferringDoctorDto,
  ReferringDoctorDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'ReferringDoctorDto'

export default class ReferringDoctorsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public referringDoctors: IReferringDoctor[]
  @observable
  public selectedReferringDoctor?: IReferringDoctor | undefined
  public dataTableStore: DataTableStore<IReferringDoctorDto, IReferringDoctor>

  constructor(
    private globalStore: GlobalStore,
    private routerStore: RouterStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IReferringDoctorDto, IReferringDoctor>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllReferringDoctors(filter, page, undefined, orderBy, includeInactives),
      (referringDoctor) => this.setupReferringDoctors(referringDoctor)
    )
  }

  @action.bound
  public disableReferringDoctor(referringDoctor: IReferringDoctor) {
    this.isLoading = true
    return this.client
      .archiveReferringDoctor(referringDoctor.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndReferringDoctors() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllReferringDoctors(filter: string) {
    this.isLoading = true
    return this.client
      .getAllReferringDoctors(filter, undefined, undefined, undefined, true)
      .then((resp: SwaggerResponse<IReferringDoctorDto[]>) =>
        runInAction(() => {
          this.referringDoctors = this.setupReferringDoctors(resp.result)
        })
      )
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public getReferringDoctorById(id: string) {
    this.isLoading = true
    return this.client
      .getReferringDoctorById(id)
      .then((resp: SwaggerResponse<IReferringDoctorDto>) =>
        runInAction(() => {
          this.selectedReferringDoctor = this.addDefaultFields(resp.result)
        })
      )
  }

  @action.bound
  public editReferringDoctor(referringDoctor: IReferringDoctor) {
    this.selectedReferringDoctor = referringDoctor
    this.routerStore.push(`/admin/referringDoctors/referringDoctor/${referringDoctor.id}`)
  }

  @action.bound
  public createReferringDoctor() {
    this.selectedReferringDoctor = DefaultReferringDoctor(true)
    this.routerStore.push(`/admin/referringDoctors/referringDoctor/`)
  }

  @action.bound
  public referringDoctorEditFinished() {
    this.selectedReferringDoctor = undefined
    this.routerStore.push(`/admin/referringDoctors`)
  }

  @action.bound
  public recoverReferringDoctor(referringDoctor: IReferringDoctor) {
    this.isLoading = true
    return this.client
      .reactivateReferringDoctor(referringDoctor.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
  }

  @action.bound
  public setSelectedReferringDoctor(referringDoctor: IReferringDoctor | undefined) {
    this.selectedReferringDoctor = referringDoctor
  }

  @action.bound
  public saveReferringDoctor(referringDoctor: IReferringDoctor) {
    this.isLoading = true
    let promise
    const referringDoctorDto = new ReferringDoctorDto(referringDoctor)
    if (referringDoctor.isNew) {
      promise = this.client.createReferringDoctor(referringDoctorDto)
    } else {
      promise = this.client.updateReferringDoctor(referringDoctor.id, referringDoctorDto)
    }
    return promise
      .then(() => {
        this.resetUIState(referringDoctor.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupReferringDoctors = (referringDoctors: IReferringDoctorDto[]) => {
    return referringDoctors.map((x) =>
      this.setupReferringDoctorMenuItems(this.addDefaultFields(x))
    )
  }

  private addDefaultFields = (referringDoctor: IReferringDoctorDto): IReferringDoctor => {
    return { ...DefaultReferringDoctor(), ...referringDoctor }
  }

  private setupReferringDoctorMenuItems = (referringDoctor: IReferringDoctor) => {
    referringDoctor.menuItems = [
      {
        icon: Edit,
        name: 'Edit',
        onClick: () => this.editReferringDoctor(referringDoctor),
      },
    ]
    if (referringDoctor.isActive) {
      referringDoctor.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableReferringDoctor(referringDoctor),
      })
    } else {
      referringDoctor.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverReferringDoctor(referringDoctor),
      })
    }
    return referringDoctor
  }
}
