import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import React from 'react'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { inject } from 'mobx-react'
import { IStores } from 'src/Stores'
import _ from 'lodash'
import { IProcessAction, ProcessAction } from 'src/viewModels/workflow/ProcessAction'
import { processAction, processDynamicAction } from 'src/services/WorkflowService'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import { UserDropdownOption } from 'src/viewModels/UserDropdownOption'

interface IStandardCommentModalProps {
  isOpen: boolean
  closeModal: () => void
  action: InboxActionSummary
  isDynamicItem: boolean
  inboxItemId: number
  userOptions?: UserDropdownOption[]
  rerouteToPath?: (path: string) => void
  associatedItemId: string
  inboxId: string
  data: IProcessAction
  handleProcessActionFinish?: (selectedAction?: InboxActionSummary) => void
}

class StandardCommentModal extends React.Component<IStandardCommentModalProps> {
  public state = {
    requiredMissing: false,
    comments: '',
    isLoading: false,
    executed: false,
  }

  public componentDidMount(): void {}

  private async save() {
    var action = this.props.action
    let data = this.props.data ?? new ProcessAction()

    data.comment = this.state.comments

    if (action.requireComment && data.comment == '') {
      this.setState({ requiredMissing: true })
      return
    }
    if (this.props.isDynamicItem) {
      processDynamicAction(
        data,
        this.props.inboxId.toString(),
        action!.id.toString(),
        this.props.associatedItemId.toString()
      )
        .then(() => {
          this.props.handleProcessActionFinish!(action)
          this.setState({ isLoading: false })
          this.props.closeModal()
        })
        .catch(() => {
          this.setState({ executed: false, isLoading: false })
          this.props.closeModal()
        })
    } else {
      processAction(data, action!.id.toString(), this.props.inboxItemId.toString())
        .then(() => {
          this.props.handleProcessActionFinish!(action)
          this.setState({ isLoading: false })
          this.props.closeModal()
        })
        .catch(() => {
          this.setState({ executed: false, isLoading: false })
          this.props.closeModal()
        })
    }
  }

  public render() {
    var modalTitle = this.props.action.actionName.toUpperCase()
    return (
      <Dialog
        key={this.props.action.actionName}
        fullWidth={true}
        maxWidth="sm"
        open={this.props.isOpen!}
        disableBackdropClick
      >
        {this.state.isLoading && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1200',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar title={modalTitle} isModal={true} />
        </DialogTitle>
        <DialogContent style={{ padding: '12px 12px 8px' }}>
          <Grid container direction="column" spacing={1}>
            {this.props.action.displayComment && (
              <Grid item xs={12}>
                <TextField
                  label="Comments"
                  name="notes"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required={this.props.action.requireComment}
                  multiline
                  minRows="4"
                  onChange={(event) => this.setState({ comments: event.target.value })}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {this.state.requiredMissing && (
          <div style={{ textAlign: 'right', margin: '0px 20px 12px' }} className="error">
            Please fill all required fields.
          </div>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={
              this.state.executed ||
              (this.props.action.requireComment && this.state.comments === '')
            }
            onClick={() => {
              this.setState({ isLoading: true, executed: true })
              this.save()
            }}
          >
            Execute
          </Button>
          <Button onClick={this.props.closeModal} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const InjectedStandardCommentModal = inject<
  IStores,
  IStandardCommentModalProps,
  Partial<IStandardCommentModalProps>,
  any
>((stores: IStores) => ({
  rerouteToPath: stores.global.rerouteToPath,
}))(StandardCommentModal)

export default InjectedStandardCommentModal
