import { QuoteOption } from 'src/utils/QuoteOption'

export interface IBillOutSearchRequest {
  filter: string | undefined
  organizationId: string | undefined
  type: string | undefined // CMS1500Type
  showBilledOut: boolean
  showPaid: boolean
  includeInactives: boolean
  startDate?: string
  endDate?: string
  patientFirstName?: string | undefined
  patientLastName?: string | undefined
  insurance?: string | undefined
  page: number
  pageSize: number
  eocGeneratedOrLegacyId: string | undefined
  componentId: string | undefined
  quoteOption: QuoteOption
  insuranceId: string | undefined
  providerGroupName: string | undefined
}

export class BillOutSearchRequest implements IBillOutSearchRequest {
  public filter: string | undefined
  public organizationId: string | undefined
  public type: string | undefined // CMS1500Type
  public showBilledOut: boolean
  public showPaid: boolean
  public includeInactives: boolean
  public startDate?: string
  public endDate?: string
  public patientFirstName?: string | undefined
  public patientLastName?: string | undefined
  public insurance?: string | undefined
  public page: number
  public pageSize: number
  public eocGeneratedOrLegacyId: string | undefined
  public componentId: string | undefined
  public quoteOption: QuoteOption
  public insuranceId: string | undefined
  public providerGroupName: string | undefined
}
