import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultMedicareCostLookup, IMedicareCostLookup } from '../../Definitions'
import {
  Client,
  DiscountsDto,
  ICptCostLookupDto,
  IMedicareCostLookupDto,
  LineItemDto,
  MedicareCostLookupDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'MedicareCostLookupDto'

export default class MedicareCostLookupsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public medicareCostLookups: IMedicareCostLookup[]
  @observable
  public selectedMedicareCostLookup?: IMedicareCostLookupDto
  @observable
  public selectedDate?: Date | null = null
  @observable
  public discounts?: DiscountsDto[]
  public dataTableStore: DataTableStore<IMedicareCostLookupDto, IMedicareCostLookup>

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IMedicareCostLookupDto, IMedicareCostLookup>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllMedicareCostLookups(
          filter,
          this.selectedDate ? this.selectedDate : undefined,
          page,
          undefined,
          orderBy,
          includeInactives
        ),
      (medicareCostLookup) => this.setupMedicareCostLookups(medicareCostLookup)
    )
  }

  @action.bound
  public clearDiscounts() {
    this.discounts = undefined
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedMedicareCostLookup = undefined
  }

  @action.bound
  public setSelectedDate(date: Date | null) {
    this.selectedDate = date

    this.dataTableStore.loadData()
  }

  @action.bound
  public clearFilters() {
    this.selectedDate = null
  }

  @action.bound
  public disableMedicareCostLookup(medicareCostLookup: IMedicareCostLookup) {
    this.isLoading = true
    return this.client
      .archiveMedicareCostLookup(medicareCostLookup.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndMedicareCostLookups() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllMedicareCostLookups() {
    this.isLoading = true
    return this.client
      .getAllMedicareCostLookups()
      .then((resp: SwaggerResponse<IMedicareCostLookupDto[]>) =>
        runInAction(() => {
          this.medicareCostLookups = this.setupMedicareCostLookups(resp.result)
        })
      )
  }

  @action.bound
  public lookupMedicareCosts(
    lineItemId?: string,
    providerId?: string,
    contractId?: string,
    cptCode?: string,
    mods?: string
  ) {
    return this.client
      .lookupMedicareCosts(lineItemId, providerId, contractId, cptCode, mods)
      .then((resp: SwaggerResponse<ICptCostLookupDto[]>) => {
        return resp.result
      })
      .catch(() => {
        return []
      })
  }

  @action.bound
  public applyDiscounts(lineItems?: LineItemDto[]) {
    this.isLoading = true
    return this.client
      .applyDiscounts(lineItems)
      .then((resp: SwaggerResponse<DiscountsDto[]>) => {
        this.discounts = resp.result
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public openDialogWithMedicareCostLookup(medicareCostLookup: IMedicareCostLookup) {
    this.isModalOpen = true
    this.selectedMedicareCostLookup = medicareCostLookup
  }

  @action.bound
  public recoverMedicareCostLookup(medicareCostLookup: IMedicareCostLookup) {
    this.isLoading = true
    return this.client
      .reactivateMedicareCostLookup(medicareCostLookup.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveMedicareCostLookup(medicareCostLookup: IMedicareCostLookup) {
    this.isLoading = true
    let promise
    const medicareCostLookupDto = new MedicareCostLookupDto(medicareCostLookup)
    if (medicareCostLookup.isNew) {
      promise = this.client.createMedicareCostLookup(medicareCostLookupDto)
    } else {
      promise = this.client.updateMedicareCostLookup(
        medicareCostLookup.id,
        medicareCostLookupDto
      )
    }
    return promise
      .then(() => {
        this.resetUIState(medicareCostLookup.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupMedicareCostLookups = (medicareCostLookups: IMedicareCostLookupDto[]) => {
    return medicareCostLookups.map((x) =>
      this.setupMedicareCostLookupMenuItems(this.addDefaultFields(x))
    )
  }

  private addDefaultFields = (
    medicareCostLookup: IMedicareCostLookupDto
  ): IMedicareCostLookup => {
    return { ...DefaultMedicareCostLookup(), ...medicareCostLookup }
  }

  public editMedicareCostLookup(medicareCostLookup: IMedicareCostLookup) {
    this.openDialogWithMedicareCostLookup(medicareCostLookup)
  }

  private setupMedicareCostLookupMenuItems = (
    medicareCostLookup: IMedicareCostLookup
  ) => {
    medicareCostLookup.menuItems = []
    if (medicareCostLookup.isActive) {
      medicareCostLookup.menuItems.push({
        icon: Edit,
        name: 'Edit',
        onClick: () => this.editMedicareCostLookup(medicareCostLookup),
      })
      medicareCostLookup.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableMedicareCostLookup(medicareCostLookup),
      })
    } else {
      medicareCostLookup.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverMedicareCostLookup(medicareCostLookup),
      })
    }
    return medicareCostLookup
  }
}
