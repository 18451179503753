import {
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import moment from 'moment'
import * as React from 'react'
import CardWithTitle from '../../common/CardWithTitle/CardWithTitle'
import { IQuoteFormValues } from '../QuoteFormValues'
import { GetEOCPatientReferralInfo } from 'src/services/LookupService'
import { EOCPatientReferralSummaryResult } from 'src/viewModels/EOCPatientReferralSummaryResult'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { ChangeEvent } from 'react'
import './inputStyling.css'
import { IGlobalInvoiceCPTCodeDTO } from 'src/viewModels/quotes/GlobalInvoiceCPTCodeDTO'
import { ListAlt } from '@material-ui/icons'
import { QuoteType } from 'src/utils/QuoteType'
import QuoteTypeNameConstants from 'src/constants/QuoteTypeNameConstants'

const styles = ({ spacing }: Theme) =>
  createStyles({
    bold: {
      fontWeight: 'bold',
    },
    paper: {
      padding: spacing(2),
    },
  })

const StyledHeaderRow = withStyles(() =>
  createStyles({
    root: {
      height: '12px',
      fontSize: '10px',
      padding: 0,
    },
  })
)(TableRow)

interface IQuoteDetailsProps extends WithStyles<typeof styles> {
  disabled: boolean
  values: IQuoteFormValues
  selectedQuote?: IQuoteDTO
  cptDescriptionList?: IGlobalInvoiceCPTCodeDTO[]
  setInvoiceSuffix?: (suffix: string) => void
  suffix?: string
  takeoverOfCareDate?: Date
}

class InvoiceQuoteHeader extends React.Component<IQuoteDetailsProps> {
  public componentDidMount() {
    this.getEOCPatientReferralInfoByEOCNumber()
  }

  public getEOCPatientReferralInfoByEOCNumber() {
    const { episodeOfCareNumber } = this.props.values
    GetEOCPatientReferralInfo(episodeOfCareNumber).then(
      (response: EOCPatientReferralSummaryResult) => {
        this.setState({
          eocId: response.eocId,
          patientId: response.patientId,
          referralId: response.referralId,
        })
      }
    )
  }

  private changeDescription = (y: number) => (event: ChangeEvent<HTMLInputElement>) => {
    if (this.props.cptDescriptionList && this.props.cptDescriptionList.at(y)) {
      this.props.cptDescriptionList.at(y)!.cptDescription = event.target.value
      this.setState({})
    }
  }

  private changeCpt = (y: number) => (event: ChangeEvent<HTMLInputElement>) => {
    if (this.props.cptDescriptionList && this.props.cptDescriptionList.at(y)) {
      this.props.cptDescriptionList.at(y)!.cptCode = event.target.value
      this.setState({})
    }
  }

  private changeDx = () => (event: ChangeEvent<HTMLInputElement>) => {
    if (this.props.selectedQuote) {
      this.props.selectedQuote.dxCodes = event.target.value
      this.setState({})
    }
  }

  private changeSuffix = () => (event: ChangeEvent<HTMLInputElement>) => {
    this.props.setInvoiceSuffix!(event.target.value)
    this.setState({})
  }

  public getLinkPath() {
    return (
      '/patients/patient/' +
      this.state.patientId +
      '/referral/' +
      this.state.referralId +
      '/episodeOfCare/' +
      this.state.eocId
    )
  }
  public state = {
    eocId: '',
    patientId: '',
    referralId: '',
  }

  private mapCPT() {
    return (
      <Table size="small">
        <TableHead>
          <StyledHeaderRow key={'cpt-header'}>
            <TableCell>CPT Code</TableCell>
            <TableCell>CPT Description</TableCell>
          </StyledHeaderRow>
        </TableHead>
        <TableBody>
          {this.props.cptDescriptionList ? (
            this.props.cptDescriptionList.map((y, lineIdx) => {
              return (
                <TableRow key={`pcCPT-${lineIdx}`}>
                  {(this.props.cptDescriptionList &&
                    lineIdx < this.props.cptDescriptionList?.length - 2) ||
                  this.props.selectedQuote?.type !== QuoteType.NonSurgical ? (
                    <TableCell>{y.cptCode}</TableCell>
                  ) : (
                    <TableCell>
                      <input
                        name={`description-${lineIdx}`}
                        className="inputBox cptCodes"
                        height="8px"
                        value={y.cptCode}
                        onChange={this.changeCpt(lineIdx)}
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <input
                      name={`description-${lineIdx}`}
                      className="inputBox cptCodes"
                      height="8px"
                      value={y.cptDescription}
                      onChange={this.changeDescription(lineIdx)}
                    />
                  </TableCell>
                </TableRow>
              )
            })
          ) : (
            <TableRow key="blank">
              <TableCell colSpan={10} align="center">
                <i>None</i>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    )
  }

  public render() {
    const { values, classes, selectedQuote } = this.props

    return (
      <CardWithTitle
        title="Global Invoice"
        icon={ListAlt}
        headerStyle={{ marginBottom: 0 }}
        isTitleComponentPushedRight={true}
      >
        <Grid container item direction="row" spacing={2}>
          <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
            <span className={classes.bold}>Patient Name: </span>
            <span>{values.patientName}</span>
          </Grid>
          <Grid item sm={1}>
            <span className={classes.bold}>Type: </span>
            <span>
              {' '}
              {this.props.selectedQuote?.type != undefined
                ? QuoteTypeNameConstants[this.props.selectedQuote?.type]
                : ''}
            </span>
          </Grid>
          <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
            <span className={classes.bold}>Start Date: </span>
            {this.props.selectedQuote?.type == QuoteType.Surgical ||
            this.props.selectedQuote?.type == QuoteType.Injection ? (
              <>
                <span>
                  {values.surgeryDate ? moment(values.surgeryDate).format('L') : 'N/A'}
                </span>
              </>
            ) : (
              <>
                <span>
                  {this.props.takeoverOfCareDate
                    ? moment(this.props.takeoverOfCareDate).format('L')
                    : 'N/A'}
                </span>
              </>
            )}
          </Grid>
          <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
            <span className={classes.bold}># of Days: </span>
            <span>{this.props.selectedQuote?.bundleDurationDays}</span>
          </Grid>
          <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
            <span className={classes.bold}>End Date: </span>
            <span>{moment(this.props.selectedQuote?.endDate).format('L')}</span>
          </Grid>
          <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
            <span className={classes.bold}>Body Part: </span>
            <span>{values.bodyPartName}</span>
          </Grid>
          <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
            <span className={classes.bold}>Date of Birth: </span>
            <span>
              {values.dateOfBirth ? moment(values.dateOfBirth).format('L') : ''}
            </span>
          </Grid>
          <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
            <span className={classes.bold}>EOC/Legacy: </span>
            <span
              style={{
                color: 'darkblue',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                sessionStorage.setItem('patientTab', '0')
                sessionStorage.setItem('eocTab', '1')
                window.open(this.getLinkPath())
              }}
            >
              {values.episodeOfCareOrLeagacyId}
            </span>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={3} style={{ paddingTop: '20px' }}>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <InputLabel
                onChange={() => {
                  this.changeDx()
                }}
                shrink={true}
                style={{ left: '20px' }}
              >
                DX Codes
              </InputLabel>
              <OutlinedInput
                className="inputBox"
                labelWidth={67}
                notched={true}
                name="dxCodes"
                value={selectedQuote?.dxCodes}
                onChange={this.changeDx()}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel
                onChange={() => {
                  this.changeSuffix()
                }}
                shrink={true}
                style={{ left: '20px' }}
              >
                Suffix
              </InputLabel>
              <OutlinedInput
                className="inputBox"
                labelWidth={67}
                notched={true}
                name="suffix"
                onChange={this.changeSuffix()}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            {this.mapCPT()}
          </Grid>
        </Grid>
      </CardWithTitle>
    )
  }
}

export default withStyles(styles)(InvoiceQuoteHeader)
