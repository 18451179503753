import * as Yup from 'yup'

const stringRegex = /^(.*)?\S+(.*)?$/
const phoneRegex =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/
const defaultStringMessage = 'Invalid Whitespace'
const defaultPhoneMessage = 'Invalid Phone Number'
const defaultEmailMessage = 'Invalid Email'

const noWhitespaceString = (
  message: string = defaultStringMessage,
  required: boolean = false
) => {
  return required
    ? Yup.string().matches(stringRegex, defaultStringMessage).required(message)
    : Yup.string().matches(stringRegex, defaultStringMessage)
}
const noWhitespacePhone = (
  message: string = defaultPhoneMessage,
  required: boolean = false
) => {
  return required
    ? Yup.string().matches(phoneRegex, defaultPhoneMessage).required(message)
    : Yup.string().matches(phoneRegex, defaultPhoneMessage)
}
const noWhitespaceEmail = (
  message: string = defaultEmailMessage,
  required: boolean = false
) => {
  return required
    ? Yup.string()
        .matches(stringRegex, defaultEmailMessage)
        .required(message)
        .email(message)
    : Yup.string().matches(stringRegex, defaultEmailMessage).email(message)
}
const noWhitespaceArray = (message: string) => {
  return Yup.array().of(noWhitespaceString(defaultStringMessage, true)).required(message)
}

export { noWhitespaceString, noWhitespacePhone, noWhitespaceEmail, noWhitespaceArray }
