import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  Select,
} from '@material-ui/core'
import { ClipboardOutline, Clock } from 'mdi-material-ui'
import { inject } from 'mobx-react'
import { RouterStore } from 'mobx-react-router'
import React, { ChangeEvent } from 'react'
import { Helmet } from 'react-helmet'
import ReactPaginate from 'react-paginate'
import config from 'src/config'
import { IComponent, IUser } from 'src/Definitions'
import { searchDashboard } from 'src/services/SearchService'
import { formatDate } from 'src/utils/Formatter'
import { DashboardSearchRequest } from 'src/viewModels/DashboardSearchRequest'
import { DashboardSummaryResult } from 'src/viewModels/DashboardSummaryResult'
import { pagedList } from 'src/viewModels/pagedList'
import { IStores } from '../../Stores'
import { Warning } from '@material-ui/icons'

const styles = ({}: Theme) => createStyles({})

interface ISurgicalPatientFlowModuleProps {
  rerouteToPath?: (path: string) => void
  routerStore?: RouterStore
  getAllComponents?: () => Promise<void>
  components?: IComponent[]
  getAllUsers?: (size: number, role: string) => void
  cares?: IUser[]
  setReturn?: (path?: string) => Promise<void>
  setDashboardRequest?: (request: DashboardSearchRequest) => void
  storedRequest?: DashboardSearchRequest
}

class SurgicalPatientFlowModule extends React.Component<ISurgicalPatientFlowModuleProps> {
  public componentDidMount() {
    this.props.getAllComponents!()
    this.props.getAllUsers!(5000, config.Roles.CareCoordinator)
    this.getDashboardData()
  }

  private DisplayPriority = {
    0: 'Low',
    1: 'Medium',
    2: 'High',
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private getDashboardData = async (setSavedSearch: boolean = false) => {
    const { page, pageSize, searchPatientFirstName, searchPatientLastName } = this.state
    const data: DashboardSearchRequest = {
      patientFirstName: searchPatientFirstName,
      patientLastName: searchPatientLastName,
      apptDateStart: this.state.searchApptDateStart,
      apptDateEnd: this.state.searchApptDateEnd,
      providerGroupName: this.state.searchProviderGroupName,
      coordinatorId: this.state.searchCoordinatorId,
      componentId: this.state.searchComponent,
      page: page,
      pageSize: pageSize,
    }
    await searchDashboard(data).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        loadingGrid: false,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
      if (setSavedSearch) {
        this.props.setDashboardRequest!(data)
      }
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1, loadingGrid: true }, () => this.getDashboardData())
  }

  private isSearchGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isSearchGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private search = (event: any) => {
    this.setState({ page: 1, loadingGrid: true }, () => this.getDashboardData(true))
    event.preventDefault()
  }

  private clearSearch = () => {
    var select_box = document.getElementById('componentSelect') as HTMLSelectElement
    if (select_box != undefined) {
      select_box.selectedIndex = 0
    }

    var care_select_box = document.getElementById('careSelect') as HTMLSelectElement
    if (care_select_box != undefined) {
      care_select_box.selectedIndex = 0
    }
    var start = document.getElementById('startDate') as HTMLInputElement
    if (start != undefined) {
      start.value = ''
    }
    var end = document.getElementById('endDate') as HTMLInputElement
    if (end != undefined) {
      end.value = ''
    }
    this.props.setDashboardRequest!(new DashboardSearchRequest())
    this.setState({
      searchApptDateStart: undefined,
      searchApptDateEnd: undefined,
      searchProviderGroupName: '',
      searchCoordinatorId: '',
      searchComponent: '',
      searchPatientFirstName: '',
      searchPatientLastName: '',
      gridData: undefined,
      pageCount: 0,
      totalItems: 0,
    })
  }

  private handlePatientFirstNameChange = (event: any) => {
    this.setState({ searchPatientFirstName: event.target.value })
  }

  private handlePatientLastNameChange = (event: any) => {
    this.setState({ searchPatientLastName: event.target.value })
  }

  private handleProviderGroupNameChange = (event: any) => {
    this.setState({ searchProviderGroupName: event.target.value })
  }

  private setSelectedCoordinator = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ searchCoordinatorId: event.target.value })
  }

  private setSelectedComponent = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ searchComponent: event.target.value })
  }

  private handleStartDateChange = (event: any) => {
    this.setState({ searchApptDateStart: event.target.value })
  }

  private handleEndDateChange = (event: any) => {
    this.setState({ searchApptDateEnd: event.target.value })
  }

  private redirectToComponents = (
    patientId?: string,
    referralId?: string,
    episodeOfCareId?: string,
    scheduleId?: string
  ) => {
    if (patientId && referralId && episodeOfCareId && scheduleId) {
      this.props.setReturn!('/').then(() => {
        sessionStorage.setItem('patientTab', '0')
        sessionStorage.setItem('eocTab', '0')
        this.props.routerStore?.push(
          `/patients/patient/${patientId}/referral/${referralId}/episodeOfCare/${episodeOfCareId}/schedule/${scheduleId}`
        )
      })
    }
  }

  private redirectToScheduling(
    patientId?: string,
    referralId?: string,
    episodeOfCareId?: string
  ) {
    if (patientId && referralId && episodeOfCareId) {
      this.props.setReturn!('/').then(() => {
        sessionStorage.setItem('patientTab', '0')
        sessionStorage.setItem('eocTab', '0')
        this.props.routerStore?.push(
          `/patients/patient/${patientId}/referral/${referralId}/episodeOfCare/${episodeOfCareId}`
        )
      })
    }
  }

  private mapSearchGridData = () => {
    if (this.isSearchGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={12} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          <TableCell style={{ paddingLeft: '30px' }}>
            {record.overdueTasks ? (
              <Tooltip title={record.overdueTasks}>
                <div
                  style={{
                    color: 'red',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Warning style={{ width: '100%' }} />
                  {this.DisplayPriority[record.priority]}
                </div>
              </Tooltip>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {this.DisplayPriority[record.priority]}
              </div>
            )}
          </TableCell>
          <TableCell>{record.patientName}</TableCell>
          <TableCell align="right">
            {record.patientDob ? formatDate(record.patientDob.toString()) : ''}
          </TableCell>
          <TableCell align="right">
            {record.apptDate ? formatDate(record.apptDate.toString()) : ''}
          </TableCell>
          <TableCell>{record.component}</TableCell>
          <TableCell>{record.highTasks}</TableCell>
          <TableCell>{record.mediumTasks}</TableCell>
          <TableCell>{record.lowTasks}</TableCell>
          <TableCell>{record.providerGroupName}</TableCell>
          <TableCell>{record.careCoordinator}</TableCell>
          <TableCell>
            <Tooltip title="Tasks">
              <span>
                <ClipboardOutline
                  style={{ cursor: 'pointer' }}
                  color="primary"
                  onClick={() => {
                    this.redirectToComponents!(
                      record.patientId,
                      record.referralId,
                      record.eocId,
                      record.scheduleId
                    )
                  }}
                />
              </span>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Schedule">
              <span>
                <Clock
                  style={{ cursor: 'pointer' }}
                  color="primary"
                  onClick={() => {
                    this.redirectToScheduling!(
                      record.patientId,
                      record.referralId,
                      record.eocId
                    )
                  }}
                />
              </span>
            </Tooltip>
          </TableCell>
        </TableRow>
      ))
    }
  }

  public state = {
    searchApptDateStart: this.props.storedRequest?.apptDateStart ?? undefined,
    searchApptDateEnd: this.props.storedRequest?.apptDateEnd ?? undefined,
    searchProviderGroupName: this.props.storedRequest?.providerGroupName ?? '',
    searchCoordinatorId: this.props.storedRequest?.coordinatorId ?? '',
    searchComponent: this.props.storedRequest?.componentId ?? '',
    searchPatientFirstName: this.props.storedRequest?.patientFirstName ?? '',
    searchPatientLastName: this.props.storedRequest?.patientLastName ?? '',
    gridData: new pagedList().items as DashboardSummaryResult[],
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 20,
    loadingGrid: true,
  }

  public render() {
    return (
      <>
        <Helmet>
          <title>Coordinator Dashboard</title>
        </Helmet>
        <Grid container style={{ width: '100%', paddingBottom: '20px' }}>
          <div>
            {this.state.loadingGrid && (
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 800,
                }}
              >
                <CircularProgress size={100} />
              </div>
            )}
            <form onSubmit={this.search}>
              <Grid container style={{ margin: '10px', marginLeft: '30px' }} xs={11}>
                <Typography
                  color="primary"
                  component="h6"
                  variant="h6"
                  gutterBottom
                  style={{ paddingBottom: '10px' }}
                >
                  SEARCH CRITERIA
                </Typography>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        value={this.state.searchPatientFirstName}
                        onChange={this.handlePatientFirstNameChange}
                        label="Patient First Name"
                        name="patientFirst"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        label="Patient Last Name"
                        name="patientLast"
                        variant="outlined"
                        value={this.state.searchPatientLastName}
                        onChange={this.handlePatientLastNameChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item container xs={6} direction="row">
                    <Grid item>
                      <TextField
                        inputProps={{
                          id: 'startDate',
                          style: { padding: '8px' },
                        }}
                        InputLabelProps={{ shrink: true }}
                        onChange={this.handleStartDateChange}
                        type="date"
                        name="startDate"
                        variant="outlined"
                        defaultValue={this.state.searchApptDateStart}
                        label="Start Date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item style={{ margin: '0px 10px' }}>
                      <strong> - </strong>
                    </Grid>
                    <Grid item>
                      <TextField
                        inputProps={{
                          id: 'endDate',
                          style: { padding: '8px' },
                        }}
                        InputLabelProps={{ shrink: true }}
                        onChange={this.handleEndDateChange}
                        type="date"
                        name="endDate"
                        variant="outlined"
                        defaultValue={this.state.searchApptDateEnd}
                        label="End Date"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        value={this.state.searchProviderGroupName}
                        onChange={this.handleProviderGroupNameChange}
                        label="Provider Group Name"
                        name="providerName"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl style={{ width: '100%' }} variant="outlined">
                      <InputLabel
                        shrink={true}
                        variant="outlined"
                        htmlFor="componentSelect"
                      >
                        Component
                      </InputLabel>
                      <Select
                        variant="outlined"
                        input={
                          <OutlinedInput
                            notched
                            labelWidth={85}
                            id="componentSelect-select"
                            name="componentSelect"
                          />
                        }
                        inputProps={{ label: true, notched: true }}
                        native={true}
                        fullWidth
                        onChange={this.setSelectedComponent}
                        value={this.state.searchComponent}
                        id="componentSelect"
                      >
                        <option value="">Select a component</option>
                        {this.props.components?.map((component) => (
                          <option value={component.id}>{component.name}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl style={{ width: '100%' }} variant="outlined">
                      <InputLabel shrink={true} variant="outlined">
                        Care Coordinator
                      </InputLabel>
                      <Select
                        variant="outlined"
                        input={<OutlinedInput notched labelWidth={120} />}
                        inputProps={{ label: true, notched: true }}
                        native={true}
                        fullWidth
                        onChange={this.setSelectedCoordinator}
                        value={this.state.searchCoordinatorId}
                        id="careSelect"
                      >
                        <option value="">Select a care coordinator</option>
                        {this.props.cares?.map((option) => (
                          <option value={option.id}>{option.name}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    style={{
                      marginLeft: '8px',
                      paddingBottom: '10px',
                      paddingTop: '10px',
                    }}
                  >
                    <Button
                      type="submit"
                      color="primary"
                      size="medium"
                      variant="contained"
                      style={{ marginRight: '10px' }}
                    >
                      Search
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.clearSearch}
                      size="medium"
                      variant="contained"
                    >
                      Clear Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
            <Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignContent="center"
                alignItems="flex-start"
                wrap="wrap"
              >
                <Grid item xs={12} style={{ minWidth: '750px' }}>
                  <Table aria-label="simple table" style={{ padding: '20' }}>
                    <TableHead>
                      <TableRow className="gridPadding">
                        <TableCell style={{ paddingLeft: '30px' }}>Priority</TableCell>
                        <TableCell>Patient</TableCell>
                        <TableCell align="right">DOB</TableCell>
                        <TableCell align="right">Appt Date</TableCell>
                        <TableCell>Component</TableCell>
                        <TableCell>High Priority</TableCell>
                        <TableCell>Med Priority</TableCell>
                        <TableCell>Low Priority</TableCell>
                        <TableCell>Provider Group</TableCell>
                        <TableCell>Care Coordinator</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ width: '10%' }}>
                      {this.mapSearchGridData()}
                    </TableBody>
                  </Table>
                  <div className="pagination-row">
                    {this.paginationInfo()}
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      onPageChange={this.handlePageChange}
                      forcePage={this.state.page - 1}
                      pageCount={this.state.pageCount}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                      //@ts-ignore
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </>
    )
  }
}

const InjectedSurgicalPatientFlowModule = inject<
  IStores,
  ISurgicalPatientFlowModuleProps,
  Partial<ISurgicalPatientFlowModuleProps>,
  any
>((stores: IStores) => ({
  rerouteToPath: stores.global.rerouteToPath,
  routerStore: stores.scheduleAppointmentTasks.routerStore,
  getAllComponents: stores.components.getAllComponents,
  components: stores.components.components,
  getAllUsers: stores.users.getAllUsers,
  cares: stores.users.users,
  setReturn: stores.global.setReturn,
  setDashboardRequest: stores.global.setDashboardRequest,
  storedRequest: stores.global.dashboardRequest,
}))(SurgicalPatientFlowModule)

export default withStyles(styles)(InjectedSurgicalPatientFlowModule)
