import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Receipt } from '@material-ui/icons'
import React from 'react'
import ReactPaginate from 'react-paginate'
import { pagedList } from 'src/viewModels/pagedList'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { GetInvoiceLogByInvoiceId } from 'src/services/QuoteService'
import { InvoiceLogSearchRequest } from 'src/viewModels/quotes/InvoiceLogSearchRequest'
import { InvoiceLogDTO } from 'src/viewModels/quotes/InvoiceLogDTO'
import moment from 'moment'
import { formatCurrency } from 'src/utils/Formatter'

interface IGlolbalInvoiceLogModalProps {
  invoiceId: string
  headerText: string
  isOpen?: boolean
  quoteId: string
  closeModal: () => void
}

class InvoiceLogModal extends React.Component<IGlolbalInvoiceLogModalProps> {
  public async componentDidMount(): Promise<void> {
    await this.getInvoiceLogData()
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private getInvoiceLogData = async () => {
    const { page, pageSize } = this.state
    const invoiceId = this.props.invoiceId
    const data: InvoiceLogSearchRequest = {
      invoiceId,
      page,
      pageSize,
    }

    await GetInvoiceLogByInvoiceId(this.props.quoteId, data).then(
      async (response: any) => {
        this.setState({
          gridData: response.items,
          pageCount: response.totalPages,
          totalItems: response.totalItems,
        })
        if (response.totalItems > 0) {
          this.setPaginationOffsetData()
        }
      }
    )
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getInvoiceLogData())
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems === 0
  }

  private mapLogGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          <TableCell>{moment(record.sentDate).format('MM/DD/YYYY')}</TableCell>
          <TableCell>{record.createdByName}</TableCell>
          <TableCell align="right">
            {record.invoiceTotal != undefined
              ? formatCurrency(record.invoiceTotal.toString())
              : ''}
          </TableCell>
          <TableCell align="right">
            {record.ucTotal != undefined ? formatCurrency(record.ucTotal.toString()) : ''}
          </TableCell>
          <TableCell align="right">
            {record.totalAmountPaid != undefined
              ? formatCurrency(record.totalAmountPaid.toString())
              : ''}
          </TableCell>
          <TableCell>
            <pre>{record.notes ?? ''}</pre>
          </TableCell>
        </TableRow>
      ))
    }
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  public state = {
    gridData: new pagedList().items as InvoiceLogDTO[],
    page: 1,
    pageCount: 1,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 5,
    invoiceId: this.props.invoiceId,
  }

  public render() {
    return (
      <>
        <Dialog
          key={this.props.quoteId}
          fullWidth={true}
          maxWidth="lg"
          open={this.props.isOpen!}
        >
          <DialogTitle style={{ padding: 0 }}>
            <ResponsiveAppBar
              title={'Invoice Log - ' + this.props.headerText}
              pageIcon={<Receipt />}
              isModal={true}
            />
          </DialogTitle>
          <DialogContent>
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              alignContent="center"
              alignItems="flex-start"
              wrap="wrap"
            >
              <Grid item xs={12} style={{ minWidth: '750px' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className="gridPadding">
                      <TableCell>Sent Date</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell align="right">Invoice Total</TableCell>
                      <TableCell align="right">U&C Total</TableCell>
                      <TableCell align="right">Amount Paid</TableCell>
                      <TableCell>Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ width: '100%' }}>{this.mapLogGridData()}</TableBody>
                </Table>
                <div className="pagination-row">
                  {this.paginationInfo()}
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    onPageChange={this.handlePageChange}
                    forcePage={this.state.page - 1}
                    pageCount={this.state.pageCount}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    //@ts-ignore
                    renderOnZeroPageCount={null}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeModal} data-cy="close">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default InvoiceLogModal
