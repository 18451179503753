import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultNurseCaseManager, INurseCaseManager } from '../../Definitions'
import {
  Client,
  INurseCaseManagerDto,
  NurseCaseManagerDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'NurseCaseManagerDto'

export default class NurseCaseManagersStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public nurseCaseManagers: INurseCaseManager[]
  @observable
  public selectedNurseCaseManager?: INurseCaseManager | undefined
  public dataTableStore: DataTableStore<INurseCaseManagerDto, INurseCaseManager>
  public isConfirmDialogOpen: boolean

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<INurseCaseManagerDto, INurseCaseManager>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllNurseCaseManagers(
          filter,
          page,
          undefined,
          orderBy,
          includeInactives
        ),
      (nurseCaseManager) => this.setupNurseCaseManagers(nurseCaseManager)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedNurseCaseManager = undefined
  }

  @action.bound
  public disableNurseCaseManager(nurseCaseManager: INurseCaseManager) {
    this.isLoading = true
    return this.client
      .archiveNurseCaseManager(nurseCaseManager.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getNurseCaseManagerById(id: string) {
    this.isLoading = true
    return this.client
      .getNurseCaseManagerById(id)
      .then((resp: SwaggerResponse<INurseCaseManagerDto>) =>
        runInAction(() => {
          this.selectedNurseCaseManager = this.addDefaultFields(resp.result)
        })
      )
  }

  @action.bound
  public getColumnSettingsAndNurseCaseManagers() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllNurseCaseManagers(filter: string) {
    this.isLoading = true
    return this.client
      .getAllNurseCaseManagers(filter)
      .then((resp: SwaggerResponse<INurseCaseManagerDto[]>) =>
        runInAction(() => {
          this.nurseCaseManagers = this.setupNurseCaseManagers(resp.result)
        })
      )
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }
  @action.bound
  public openConfirmDialog() {
    this.isConfirmDialogOpen = true
  }

  @action.bound
  public openDialogWithNurseCaseManager(nurseCaseManager: INurseCaseManager) {
    this.isModalOpen = true
    this.selectedNurseCaseManager = nurseCaseManager
  }
  private editNurseCaseManager(nurseCaseManager: INurseCaseManager) {
    this.setSelectedNurseCaseManager!(nurseCaseManager)
    this.globalStore.rerouteToPath(
      '/admin/nurseCaseManagers/nurseCaseManager/' + nurseCaseManager.id
    )
  }

  @action.bound
  public setSelectedNurseCaseManager(nurseCaseManager: INurseCaseManager | undefined) {
    this.selectedNurseCaseManager = nurseCaseManager
  }

  @action.bound
  public recoverNurseCaseManager(nurseCaseManager: INurseCaseManager) {
    this.isLoading = true
    return this.client
      .reactivateNurseCaseManager(nurseCaseManager.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
  }

  @action.bound
  public saveNurseCaseManager(nurseCaseManager: INurseCaseManager) {
    this.isLoading = true
    let promise
    const nurseCaseManagerDto = new NurseCaseManagerDto(nurseCaseManager)
    if (nurseCaseManager.isNew) {
      promise = this.client.createNurseCaseManager(nurseCaseManagerDto)
    } else {
      promise = this.client.updateNurseCaseManager(
        nurseCaseManager.id,
        nurseCaseManagerDto
      )
    }
    return promise
      .then(() => {
        this.resetUIState(nurseCaseManager.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupNurseCaseManagers = (nurseCaseManagers: INurseCaseManagerDto[]) => {
    return nurseCaseManagers.map((x) =>
      this.setupNurseCaseManagerMenuItems(this.addDefaultFields(x))
    )
  }

  private addDefaultFields = (
    nurseCaseManager: INurseCaseManagerDto
  ): INurseCaseManager => {
    return { ...DefaultNurseCaseManager(), ...nurseCaseManager }
  }

  private setupNurseCaseManagerMenuItems = (nurseCaseManager: INurseCaseManager) => {
    nurseCaseManager.menuItems = [
      {
        icon: Edit,
        name: 'Edit',
        onClick: () => this.editNurseCaseManager(nurseCaseManager),
      },
    ]
    if (nurseCaseManager.isActive) {
      nurseCaseManager.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableNurseCaseManager(nurseCaseManager),
      })
    } else {
      nurseCaseManager.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverNurseCaseManager(nurseCaseManager),
      })
    }
    return nurseCaseManager
  }
}
