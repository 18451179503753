export interface IMiscellaneousLineItemDTO {
  providerId: string
  providerName: string
  typeId?: string
  cptCode?: string
  cost?: number
  lineDescription: string
  id?: string
  markedDeleted?: boolean
  markedDeletedCost?: number
  typeText?: string
  costSource?: number
  otherDescription?: string
  actualCost?: number
  bilateral: boolean
  percentageOfContract?: number
  multipleProcedurePercentage?: number
  calculationExplanation?: string
  markExcluded?: boolean
  markExcludedCost?: number
  reconciledActualCPT?: string
  reconciledActualLocationGroupName?: string
  reconciledActualCost?: number
  reconciledCost?: number
  reconciledOverrideCost?: number
  reconciledCMS1500LineItemId?: string
  reconciledCalculationExplanation?: string
  reasonCode?: string
  markedDeletedReasonCode?: string
}

export class MiscellaneousLineItemDTO implements IMiscellaneousLineItemDTO {
  public providerId: string
  public providerName: string
  public typeId?: string
  public cptCode?: string
  public cost?: number
  public lineDescription: string
  public id?: string
  public markedDeleted?: boolean
  public markedDeletedCost?: number
  public typeText?: string
  public costSource?: number
  public otherDescription?: string
  public actualCost?: number
  public bilateral: boolean
  public percentageOfContract?: number
  public multipleProcedurePercentage?: number
  public calculationExplanation?: string
  public markExcluded?: boolean
  public markExcludedCost?: number
  public reconciledActualCPT?: string
  public reconciledActualLocationGroupName?: string
  public reconciledActualCost?: number
  public reconciledCost?: number
  public reconciledOverrideCost?: number
  public reconciledCMS1500LineItemId?: string
  public reconciledCalculationExplanation?: string
  public reasonCode?: string
  public markedDeletedReasonCode?: string
  constructor(
    providerId: string,
    providerName: string,
    bilateral: boolean,
    lineDescription: string,
    cost?: number,
    typeId?: string,
    cptCode?: string,
    id?: string,
    markedDeleted?: boolean,
    typeText?: string,
    costSource?: number,
    otherDescription?: string,
    actualCost?: number,
    percentageOfContract?: number,
    multipleProcedurePercentage?: number,
    calculationExplanation?: string,
    markExcluded?: boolean,
    markExcludedCost?: number,
    reconciledActualCPT?: string,
    reconciledActualLocationGroupName?: string,
    reconciledActualCost?: number,
    reconciledCost?: number,
    reconciledOverrideCost?: number,
    reconciledCMS1500LineItemId?: string,
    reconciledCalculationExplanation?: string,
    reasonCode?: string,
    markedDeletedReasonCode?: string
  ) {
    ;(this.providerId = providerId),
      (this.providerName = providerName),
      (this.typeId = typeId),
      (this.cptCode = cptCode),
      (this.cost = cost),
      (this.lineDescription = lineDescription),
      (this.id = id),
      (this.markedDeleted = markedDeleted),
      (this.typeText = typeText),
      (this.bilateral = bilateral),
      (this.costSource = costSource),
      (this.otherDescription = otherDescription),
      (this.actualCost = actualCost),
      (this.percentageOfContract = percentageOfContract),
      (this.multipleProcedurePercentage = multipleProcedurePercentage),
      (this.calculationExplanation = calculationExplanation),
      (this.markExcluded = markExcluded),
      (this.markExcludedCost = markExcludedCost),
      (this.reconciledActualCPT = reconciledActualCPT),
      (this.reconciledActualLocationGroupName = reconciledActualLocationGroupName),
      (this.reconciledActualCost = reconciledActualCost),
      (this.reconciledCost = reconciledCost),
      (this.reconciledOverrideCost = reconciledOverrideCost),
      (this.reconciledCMS1500LineItemId = reconciledCMS1500LineItemId),
      (this.reconciledCalculationExplanation = reconciledCalculationExplanation),
      (this.reasonCode = reasonCode),
      (this.markedDeletedReasonCode = markedDeletedReasonCode)
  }
}
