export interface IProcessAction {
  comment: string | undefined
  assignUser: string | undefined
  assignDueDate: Date | undefined
  pendDate: Date | undefined
  pendNote: string | undefined
}

export class ProcessAction implements IProcessAction {
  public comment: string | undefined
  public assignUser: string | undefined
  public assignDueDate: Date | undefined
  public pendDate: Date | undefined
  public pendNote: string | undefined
}
