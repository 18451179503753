import {
  createStyles,
  FormControlLabel,
  Switch,
  withStyles,
  WithStyles,
} from '@material-ui/core'

import * as React from 'react'

const styles = () => createStyles({})

interface IIsActiveToggleProps extends WithStyles {
  disabled?: boolean
  isActive: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const IsActiveToggle: React.SFC<IIsActiveToggleProps> = ({
  disabled,
  isActive,
  onChange,
}) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={isActive}
          disabled={disabled}
          onChange={onChange}
          value="isActive"
          color="secondary"
          name="isActive"
        />
      }
      label={isActive ? 'Active' : 'Inactive'}
    />
  )
}

export default withStyles(styles)(IsActiveToggle)
