export interface IPhysicalTherapyLineItemDTO {
  providerId: string
  providerName: string
  numberOfVisits?: number
  costPerVisit?: number
  actualCostPerVisit?: number
  cost?: number
  id?: string
  markedDeleted?: boolean
  markedDeletedCost?: number
  markExcluded?: boolean
  markExcludedCost?: number
  reconciledCost?: number
  reconciledOverrideCost?: number
  displayNumberOfVisits?: string
}

export class PhysicalTherapyLineItemDTO implements IPhysicalTherapyLineItemDTO {
  providerId: string
  providerName: string
  numberOfVisits?: number
  costPerVisit?: number
  actualCostPerVisit?: number
  cost?: number
  id?: string
  markedDeleted?: boolean
  markedDeletedCost?: number
  markExcluded?: boolean
  markExcludedCost?: number
  reconciledCost?: number
  reconciledOverrideCost?: number
  displayNumberOfVisits?: string
  constructor(
    providerId: string,
    providerName: string,
    cost?: number,
    numberOfVisits?: number,
    costPerVisit?: number,
    actualCostPerVisit?: number,
    id?: string,
    markedDeleted?: boolean,
    markExcluded?: boolean,
    markExcludedCost?: number,
    reconciledCost?: number,
    reconciledOverrideCost?: number
  ) {
    ;(this.providerId = providerId),
      (this.providerName = providerName),
      (this.cost = cost),
      (this.numberOfVisits = numberOfVisits),
      (this.costPerVisit = costPerVisit),
      (this.actualCostPerVisit = actualCostPerVisit),
      (this.id = id),
      (this.markedDeleted = markedDeleted),
      (this.markExcluded = markExcluded),
      (this.markExcludedCost = markExcludedCost),
      (this.reconciledCost = reconciledCost),
      (this.reconciledOverrideCost = reconciledOverrideCost)
  }
}
