import {
  createStyles,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  WithStyles,
  withStyles,
  Button,
  Typography,
  TextField,
  Tooltip,
  InputLabel,
  OutlinedInput,
  Select,
  CircularProgress,
} from '@material-ui/core'
import ReactPaginate from 'react-paginate'
import { CreditCard, InfoOutlined, ReceiptOutlined, Warning } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { ICMS1500 } from '../Definitions'
import { IStores } from '../Stores'
import { BillOutSearchRequest } from '../viewModels/BillOutSearchRequest'
import { searchPayments } from 'src/services/BillOutService'
import { IOrganization } from '../Definitions'
import { formatCurrency, formatDate } from '../utils/Formatter'
import '../Grid.css'
import { pagedList } from 'src/viewModels/pagedList'
import { ReceivePaymentSummaryResult } from 'src/viewModels/ReceivePaymentSummaryResult'
import { Helmet } from 'react-helmet'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import BilloutLogModal from './BilloutLogModal'

const styles = () => createStyles({})

interface IReceivePaymentProps extends WithStyles<typeof styles> {
  setCanSwitchOrganizations?: (canSwitch: boolean) => void
  dataTableManager?: IDataTableManager<ICMS1500>
  pageSize: number
  currentAppOrganization: IOrganization
  setSelectedCMS1500Id: (cms1500Id?: string) => void
  setUpdateParentStateAfterModal: (parentfunction?: () => void) => void
  insuranceList?: DropdownOption[]
  getInsuranceDropdown?: () => void
  openLogModal?: () => void
  closeLogModal?: () => void
  isLogModalOpen?: boolean
  selectedCMS1500Id?: string
  isLoading?: boolean
  getHeaderInfo?: (billoutId: string) => void
  rerouteToPath?: (path: string) => void
  setReceivePaymentRequest?: (billOutSearchRequest: BillOutSearchRequest) => void
  receivePaymentRequest?: BillOutSearchRequest
}

class ReceivePayment extends React.Component<IReceivePaymentProps> {
  public componentDidMount() {
    this.props.setUpdateParentStateAfterModal(this.getPaymentData)
    this.props.setCanSwitchOrganizations!(true)
  }

  public componentWillUnmount() {
    this.props.setCanSwitchOrganizations!(false)
  }

  public state = {
    gridData: new pagedList().items as ReceivePaymentSummaryResult[],
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 25,
    searchPatientLastName: this.props.receivePaymentRequest?.patientLastName ?? '',
    searchPatientFirstName: this.props.receivePaymentRequest?.patientFirstName ?? '',
    searchEocGeneratedOrLegacyId:
      this.props.receivePaymentRequest?.eocGeneratedOrLegacyId ?? '',
    searchApptDateStart: this.props.receivePaymentRequest?.startDate ?? '',
    searchApptDateEnd: this.props.receivePaymentRequest?.endDate ?? '',
    searchInsurance: this.props.receivePaymentRequest?.insurance ?? '',
    logModalHeader: '',
    selectedGroupInfo: '',
    isLoading: false,
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to&nbsp;
            {this.state.lastRecordIndex} of {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((billOut, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: '30px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.openBilloutItem(billOut.cms1500Id!)}
            >
              Open
            </Button>
          </TableCell>
          <TableCell style={{ color: billOut.urgent ? 'red' : 'default' }}>
            {billOut.urgent && (
              <Tooltip title="Urgent">
                <Warning color="error" fontSize="small" />
              </Tooltip>
            )}
          </TableCell>
          <TableCell style={{ whiteSpace: 'nowrap' }}>{billOut.groupNumber}</TableCell>
          <TableCell>{billOut.patientName}</TableCell>
          <TableCell align="right">{formatDate(billOut.dateOfService)}</TableCell>
          <TableCell>{billOut.type}</TableCell>
          <TableCell>
            {billOut.insuranceCompanyName}&nbsp;
            {billOut.insuranceCompanyName ? (
              <Tooltip title={billOut.insuranceCompanyContact || ''}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell>{billOut.currentInbox}&nbsp;</TableCell>
          <TableCell align="right">{formatCurrency(billOut.billedAmount)}</TableCell>
          <TableCell align="right">
            {formatCurrency(billOut.balanceDue)}&nbsp;
            <Tooltip title="Show Bill Out Log">
              <ReceiptOutlined
                style={{ cursor: 'pointer' }}
                fontSize="small"
                onClick={() => {
                  this.props.setSelectedCMS1500Id(billOut.cms1500Id)
                  this.setState({ selectedGroupInfo: billOut.groupNumber! }, () => {
                    this.props.getHeaderInfo!(billOut.incomingCMS1500Id!)
                    this.props.openLogModal!()
                  })
                }}
              />
            </Tooltip>
          </TableCell>
        </TableRow>
      ))
    }
  }

  private search = (event: any) => {
    this.setState({ page: 1 }, () => this.getPaymentData(true))
    event.preventDefault()
  }

  private clearSearch = () => {
    var select_box = document.getElementById('companySelect') as HTMLSelectElement
    if (select_box != undefined) {
      select_box.selectedIndex = 0
    }
    var start = document.getElementById('startDate') as HTMLInputElement
    if (start != undefined) {
      start.value = ''
    }
    var end = document.getElementById('endDate') as HTMLInputElement
    if (end != undefined) {
      end.value = ''
    }
    this.setState(
      {
        searchPatientLastName: '',
        searchPatientFirstName: '',
        searchEocGeneratedOrLegacyId: '',
        searchApptDateStart: '',
        searchApptDateEnd: '',
        searchInsurance: undefined,
      },
      () => {
        this.setState({ gridData: undefined, pageCount: 0, totalItems: 0 })
        this.props.setReceivePaymentRequest!(new BillOutSearchRequest())
      }
    )
  }

  private openBilloutItem = (associatedItemId: string) => {
    this.props.rerouteToPath!(
      `/workflow/billout/${associatedItemId}/received-search-default`
    )
  }

  private handlePatientFirstNameChange = (event: any) => {
    this.setState({ searchPatientFirstName: event.target.value })
  }

  private handlePatientLastNameChange = (event: any) => {
    this.setState({ searchPatientLastName: event.target.value })
  }

  private handleEocLegacyIdSearchRequestChange = (event: any) => {
    this.setState({ searchEocGeneratedOrLegacyId: event.target.value })
  }

  private handleStartDateChange = (event: any) => {
    this.setState({ searchApptDateStart: event.target.value })
  }
  private handleEndDateChange = (event: any) => {
    this.setState({ searchApptDateEnd: event.target.value })
  }

  private setSelectedCompany = (event: any) => {
    this.setState({ searchInsurance: event.target.value })
  }

  private getPaymentData = async (savedSearch: boolean = false) => {
    await this.setState({ isLoading: true })
    const { page, pageSize } = this.state
    const requestData = new BillOutSearchRequest()
    requestData.page = page
    requestData.pageSize = pageSize
    requestData.patientFirstName = this.state.searchPatientFirstName
    requestData.patientLastName = this.state.searchPatientLastName
    requestData.eocGeneratedOrLegacyId = this.state.searchEocGeneratedOrLegacyId
    requestData.insurance = this.state.searchInsurance
    requestData.startDate = this.state.searchApptDateStart
    requestData.endDate = this.state.searchApptDateEnd
    requestData.organizationId = this.props.currentAppOrganization.id
    await searchPayments(requestData).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        isLoading: false,
      })

      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
        this.props.getInsuranceDropdown!()
      }

      if (savedSearch) {
        this.props.setReceivePaymentRequest!(requestData)
      }
    })
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getPaymentData())
  }

  public render() {
    return (
      <>
        <Helmet>
          <title>Receive Payments</title>
        </Helmet>
        <Grid container style={{ width: '100%', paddingBottom: '20px' }}>
          {this.props.selectedCMS1500Id ? (
            <BilloutLogModal
              closeModal={() => {
                this.props.closeLogModal!()
                this.setState({ logModalHeader: '' })
              }}
              isOpen={this.props.isLogModalOpen!}
              cms1500Id={this.props.selectedCMS1500Id!}
              groupInfo={this.state.selectedGroupInfo}
            />
          ) : (
            <></>
          )}
          <ResponsiveAppBar title={'Receive Payments'} pageIcon={<CreditCard />} />
          {(this.state.isLoading || this.props.isLoading) && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 800,
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <form onSubmit={this.search}>
            <Grid
              container
              style={{ padding: '10px', paddingLeft: '30px', width: '100%' }}
              xs={12}
            >
              <Typography
                color="primary"
                component="h6"
                variant="h6"
                gutterBottom
                style={{ paddingBottom: '10px' }}
              >
                SEARCH CRITERIA
              </Typography>
              <Grid container direction="row" spacing={2} style={{ width: '100%' }}>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchPatientFirstName}
                      onChange={this.handlePatientFirstNameChange}
                      label="Patient First Name"
                      name="patientFirst"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchPatientLastName}
                      onChange={this.handlePatientLastNameChange}
                      label="Patient Last Name"
                      name="patientLast"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchEocGeneratedOrLegacyId}
                      onChange={this.handleEocLegacyIdSearchRequestChange}
                      label="EOC/Legacy"
                      name="eocLegacyId"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl style={{ width: '100%' }} variant="outlined">
                    <InputLabel shrink={true} variant="outlined">
                      Insurance Company
                    </InputLabel>
                    <Select
                      variant="outlined"
                      input={<OutlinedInput notched labelWidth={145} />}
                      inputProps={{ label: true, notched: true }}
                      native={true}
                      fullWidth
                      onChange={this.setSelectedCompany}
                      id="companySelect"
                      value={this.state.searchInsurance}
                      disabled={
                        this.props.insuranceList == undefined ||
                        this.props.insuranceList?.length == 0
                      }
                    >
                      <option value="">Select a company</option>
                      {this.props.insuranceList?.map((company) => (
                        <option accessKey={company.id} value={company.description}>
                          {company.description}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container xs={4} direction="row">
                  <Grid item>
                    <TextField
                      inputProps={{
                        id: 'startDate',
                        style: { padding: '8px' },
                      }}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleStartDateChange}
                      type="date"
                      name="startDate"
                      variant="outlined"
                      defaultValue={this.state.searchApptDateStart}
                      label="Appt. Start Date"
                      fullWidth
                    />
                  </Grid>
                  <Grid item style={{ margin: '0px 10px' }}>
                    <strong> - </strong>
                  </Grid>
                  <Grid item>
                    <TextField
                      inputProps={{ id: 'endDate', style: { padding: '8px' } }}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleEndDateChange}
                      type="date"
                      name="endDate"
                      variant="outlined"
                      defaultValue={this.state.searchApptDateEnd}
                      label="Appt. End Date"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{
                    marginLeft: '8px',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                  }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="contained"
                    style={{ marginRight: '10px' }}
                  >
                    Search
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.clearSearch}
                    size="medium"
                    variant="contained"
                  >
                    Clear Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="gridPadding" style={{ width: '100%' }}>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>EOC/Legacy</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell align="right">DOS</TableCell>
                  <TableCell>EOC Status</TableCell>
                  <TableCell>Insurance Company</TableCell>
                  <TableCell>Current Inbox</TableCell>
                  <TableCell align="right">Amt Billed</TableCell>
                  <TableCell align="right">Balance Due</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '100%' }}>{this.mapGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                pageCount={this.state.pageCount}
                forcePage={this.state.page - 1}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}

const InjectedReceivePayment = inject<
  IStores,
  IReceivePaymentProps,
  Partial<IReceivePaymentProps>,
  any
>((stores: IStores) => ({
  currentAppOrganization: stores.global.currentAppOrganization,
  pageSize: stores.receivePayments.pageSize,
  setSelectedCMS1500Id: stores.receivePayments.setSelectedCMS1500Id,
  setCanSwitchOrganizations: stores.global.setCanSwitchOrganizations,
  setUpdateParentStateAfterModal: stores.receivePayments.setParentUpdateFunction,
  getInsuranceDropdown: stores.receivePayments.getInsuranceDropdown,
  insuranceList: stores.receivePayments.insuranceList,
  openLogModal: stores.receivePayments.openLogModal,
  closeLogModal: stores.receivePayments.closeLogModal,
  isLogModalOpen: stores.receivePayments.isLogModalOpen,
  selectedCMS1500Id: stores.receivePayments.selectedCMS1500Id,
  getHeaderInfo: stores.patients.getHeaderInfo,
  isLoading: stores.receivePayments.isLoading,
  rerouteToPath: stores.global.rerouteToPath,
  setReceivePaymentRequest: stores.global.setReceivePaymentRequest,
  receivePaymentRequest: stores.global.receivePaymentRequest,
}))(ReceivePayment)

export default withStyles(styles)(InjectedReceivePayment)
