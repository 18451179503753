import { inject } from 'mobx-react'
import * as React from 'react'

import { History, Location, LocationState } from 'history'
import routes, { navigationSections } from '../../Routes'
import { Router } from '../../routing'
import { IStores } from '../../Stores'
import NavigationDrawer from '../NavigationDrawer'

interface IContainerProps {
  className: string
  isLoggedIn: boolean
  drawerWidth: number
}

const container: React.SFC<IContainerProps> = (props) => (
  <div
    className={props.className}
    style={{
      height: '100%',
      marginLeft: `${props.isLoggedIn ? props.drawerWidth : 0}px`,
    }}
  >
    {props.children}
  </div>
)

interface IShellProps {
  drawerWidth?: number
  history?: History
  isDrawerOpen?: boolean
  isTooltipOpen?: boolean
  isLoggedIn?: boolean
  location?: Location<LocationState>
  rerouteIfNotLoggedIn?: (location?: Location<LocationState>) => void
  toggleDrawer?: () => void
}

class Shell extends React.Component<IShellProps> {
  public componentWillMount() {
    this.props.rerouteIfNotLoggedIn!()
    this.props.history!.listen((location) => {
      this.props.rerouteIfNotLoggedIn!(location)
    })
  }

  public render() {
    const {
      drawerWidth,
      history,
      isDrawerOpen,
      isTooltipOpen,
      isLoggedIn,
      location,
      toggleDrawer,
    } = this.props
    const isOnLogin = !location || location.pathname === '/login'

    return (
      <Router
        container={container}
        containerProps={{ drawerWidth, isLoggedIn }}
        routes={routes}
        history={history!}
      >
        {isLoggedIn && !isOnLogin && (
          <NavigationDrawer
            isOpen={isDrawerOpen!}
            isTooltipOpen={isTooltipOpen!}
            toggleDrawer={toggleDrawer!}
            navigationSections={navigationSections}
          />
        )}
      </Router>
    )
  }
}

const InjectedShell = inject<IStores, IShellProps, Partial<IShellProps>, any>(
  (stores: IStores) => ({
    drawerWidth: stores.global.drawerWidth,
    history: stores.routing.history,
    isDrawerOpen: stores.global.isDrawerOpen,
    isLoggedIn: stores.global.isLoggedIn,
    location: stores.routing.location,
    rerouteIfNotLoggedIn: stores.global.rerouteIfNotLoggedIn,
    toggleDrawer: stores.global.toggleDrawer,
  })
)(Shell)

export default InjectedShell
