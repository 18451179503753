import { Grid } from '@material-ui/core'
import { AttachMoney } from '@material-ui/icons'
import { FormikActions } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import AddressField from '../common/AddressField/AddressField'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import { ILocation, ILocationBilling } from '../Definitions'
import { IStores } from '../Stores'
import * as Yup from 'yup'
import {
  createILocationBillingFormValues,
  ILocationBillingFormValues,
  toILocationBilling,
} from './LocationBillingFormValues'
import { AddressDTO } from 'src/viewModels/AddressDTO'
import { createProviderLocationBillingAddress } from 'src/services/LocationService'

const LocationBillingSchema = Yup.object().shape({
  address: Yup.object().shape({
    city: Yup.string().required('City is required'),
    line1: Yup.string().required('Address is required'),
    line2: Yup.string(),
    name: Yup.string().required('Billing Name is required'),
    state: Yup.string().required('State is required'),
    zip: Yup.string().required('ZIP code is required'),
  }),
})

interface ILocationBillingDialogProps {
  close?: () => void
  getColumnSettingsAndLocationBillings?: () => void
  isLoading?: boolean
  isOpen?: boolean
  locationBilling?: ILocationBilling
  saveLocationBilling?: (locationBilling: ILocationBilling) => Promise<void>
  selectedLocation?: ILocation
}

@inject((stores: IStores) => ({
  close: stores.locationBillings.closeDialog,
  getColumnSettingsAndLocationBillings:
    stores.locationBillings.getColumnSettingsAndLocationBillings,
  isLoading: stores.locationBillings.isLoading,
  isOpen: stores.locationBillings.isModalOpen,
  locationBilling: stores.locationBillings.selectedLocationBilling,
  saveLocationBilling: stores.locationBillings.saveLocationBilling,
  selectedLocation: stores.locations.selectedLocation,
}))
export default class LocationBillingDialog extends React.Component<ILocationBillingDialogProps> {
  public save = async (
    values: ILocationBillingFormValues,
    formikBag: FormikActions<ILocationBillingFormValues>
  ) => {
    if (this.props.locationBilling!.isNew) {
      let address = new AddressDTO()
      address.line1 = values.address?.line1
      address.line2 = values.address?.line2
      address.city = values.address?.city
      //@ts-ignore
      address.state = values.address?.state?.name
      address.zip = values.address?.zip
      address.phoneNumber = values.address?.phoneNumber
      address.name = values.address?.name

      createProviderLocationBillingAddress(
        this.props.selectedLocation?.id,
        address
      ).finally(() => {
        formikBag.setSubmitting(false)
        this.props.close!()
      })
    } else {
      const locationBilling = await toILocationBilling(values, this.props.locationBilling)

      this.props.saveLocationBilling!(locationBilling).finally(() => {
        formikBag.setSubmitting(false)
        this.props.close!()
      })
    }
  }

  public render() {
    const { close, isLoading, isOpen, locationBilling, selectedLocation } = this.props
    return (
      <>
        {selectedLocation && (
          <ESDialog
            close={close!}
            initialValues={createILocationBillingFormValues(
              selectedLocation.id,
              locationBilling
            )}
            isLoading={isLoading}
            open={isOpen!}
            save={this.save}
            title={
              locationBilling!.isNew ? 'Add New Billing Address' : 'Edit Billing Address'
            }
            validationSchema={LocationBillingSchema}
            titleIcon={<AttachMoney />}
          >
            {({}) => (
              <Grid container spacing={3} style={{ marginTop: 8 }}>
                <Grid item xs={12}>
                  <FullWidthField
                    autoFocus
                    name="address.name"
                    label="Billing Name"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddressField
                    outlined
                    name="address"
                    label="Address"
                    showPhone={true}
                    required={true}
                  />
                </Grid>
              </Grid>
            )}
          </ESDialog>
        )}
      </>
    )
  }
}
