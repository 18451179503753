import { ILineItemSummaryResult } from './LineItemSummaryResult'

export interface IPaymentLineItem extends ILineItemSummaryResult {
  paymentAmount: number | undefined
}

export class PaymentLineItem implements IPaymentLineItem {
  constructor(summaryObject: ILineItemSummaryResult) {
    this.cmS1500LineItemId = summaryObject.cmS1500LineItemId
    this.lineNumber = summaryObject.lineNumber
    this.dateOfService = summaryObject.dateOfService
    this.dProcedureCptHcpcs = summaryObject.dProcedureCptHcpcs
    this.dModifier1 = summaryObject.dModifier1
    this.dModifier2 = summaryObject.dModifier2
    this.dModifier3 = summaryObject.dModifier3
    this.dModifier4 = summaryObject.dModifier4
    this.gDaysOrUnits = summaryObject.gDaysOrUnits
    this.contractAmount = summaryObject.contractAmount
    this.billOutAmount = summaryObject.billOutAmount
    this.remainingBalance = summaryObject.remainingBalance
    this.eDiagnosisPointer = summaryObject.eDiagnosisPointer
  }

  public cmS1500LineItemId: string | undefined
  public lineNumber: number | undefined
  public dateOfService: Date | undefined
  public dProcedureCptHcpcs: string | undefined
  public dModifier1: string | undefined
  public dModifier2: string | undefined
  public dModifier3: string | undefined
  public dModifier4: string | undefined
  public gDaysOrUnits: string | undefined
  public contractAmount: number | undefined
  public billOutAmount: number | undefined
  public remainingBalance: number | undefined
  public paymentAmount: number | undefined
  public eDiagnosisPointer: string | undefined
}
