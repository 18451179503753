export interface IDocumentSearchRequest {
  fileName: string | undefined
  description: string | undefined
  fileDateStart: Date | undefined
  fileDateEnd: Date | undefined
  category: string | undefined
  type: string | undefined
  episodeOfCareNumberOrLegacyId: string | undefined
  page?: number
  pageSize?: number
}

export class DocumentSearchRequest implements IDocumentSearchRequest {
  public fileName: string | undefined
  public description: string | undefined
  public fileDateStart: Date | undefined
  public fileDateEnd: Date | undefined
  public category: string | undefined
  public type: string | undefined
  public episodeOfCareNumberOrLegacyId: string | undefined
  public page?: number | undefined
  public pageSize?: number | undefined
}
