import Axios from 'axios'
import { ReferralDTO } from 'src/viewModels/ReferralDTO'
import { ISearchRequestBase } from 'src/viewModels/SearchRequestBase'
import { pagedList } from 'src/viewModels/pagedList'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function insertReferral(data: ReferralDTO): Promise<string> {
  const response = await Axios.post<string>(`${base_url}/api/referral`, data)

  return response.data
}

export async function updateReferral(id: string, data: ReferralDTO): Promise<string> {
  const response = await Axios.put<string>(`${base_url}/api/referral/${id}`, data)

  return response.data
}

export async function getReferralById(id: string): Promise<ReferralDTO> {
  const response = await Axios.get<ReferralDTO>(`${base_url}/api/referral/${id}`)

  return response.data
}

export async function searchEOCs(
  referralId: string,
  data: ISearchRequestBase
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/referral/${referralId}/eocs`,
    data
  )

  return response.data
}
