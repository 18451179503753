export enum BillStatusNumber {
  New,
  ContractTBD,
  Imported,
  Dismissed,
  NeedsDenied,
  Denied,
  Paid,
  BilledOut,
  Refunded,
  ImportedReceived,
  ImportedPaid,
  ImportedBilledOut,
  ImportedDenied,
  Deleted,
  PaidHolding,
  DeniedHolding,
}
