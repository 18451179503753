import { AccountBox } from '@material-ui/icons'
import * as _ from 'lodash'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultUser, IUser } from '../Definitions'
import { SimpleEntityDto } from '../generated_client'
import { IStores } from '../Stores'

interface IUsersProps {
  currentOrganization?: SimpleEntityDto
  currentUser?: IUser
  dataTableManager?: IDataTableManager<IUser>
  getColumnSettingsAndUsers?: () => void
  getOrganizations?: () => void
  getRoles?: () => void
  isAdmin?: boolean
  isLoading?: boolean
  isModalOpen?: boolean
  openDialogWithUser?: (user: IUser) => void
  setCanSwitchOrganizations?: (canSwitch: boolean) => void
}

@inject((stores: IStores) => ({
  currentOrganization: stores.global.currentUserOrganization,
  currentUser: stores.global.user,
  dataTableManager: stores.users.dataTableStore,
  getColumnSettingsAndUsers: stores.users.getColumnSettingsAndUsers,
  getOrganizations: stores.organizations.getAllOrganizations,
  getRoles: stores.roles.getAllRoles,
  isAdmin: stores.global.isAdmin,
  isLoading: stores.users.isLoading,
  isModalOpen: stores.users.isModalOpen,
  openDialogWithUser: stores.users.openDialogWithUser,
  setCanSwitchOrganizations: stores.global.setCanSwitchOrganizations,
}))
@observer
class Users extends React.Component<IUsersProps> {
  public componentDidMount() {
    this.props.getOrganizations!()

    this.props.getRoles!()
    this.props.getColumnSettingsAndUsers!()
    this.props.setCanSwitchOrganizations!(true)
  }

  public componentWillUnmount() {
    this.props.setCanSwitchOrganizations!(false)
  }

  public openDialog = (user: IUser) => () => {
    const { isAdmin, currentOrganization } = this.props
    if (!isAdmin && currentOrganization) {
      user.organization = currentOrganization
      user.organizationId = currentOrganization.id!
    }
    this.props.openDialogWithUser!(user)
  }

  public render() {
    return (
      <>
        <Helmet>
          <title>Users</title>
        </Helmet>
        <ResponsiveAppBar title="Users" pageIcon={<AccountBox />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={true}
          enableToggleColumns={true}
          addButtonOnClick={this.openDialog(DefaultUser(true))}
        />
      </>
    )
  }
}

export default Users
