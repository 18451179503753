import { createStyles, WithStyles, withStyles } from '@material-ui/core'
import { RecordVoiceOver } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IReferringDoctor } from '../Definitions'
import { IStores } from '../Stores'

const styles = () => createStyles({})

interface IReferringDoctorsProps extends WithStyles<typeof styles> {
  createReferringDoctor?: () => void
  dataTableManager?: IDataTableManager<IReferringDoctor>
  disableReferringDoctor?: (referringDoctor: IReferringDoctor) => void
  getColumnSettingsAndReferringDoctors?: () => void
  openDialogWithReferringDoctor?: (referringDoctor: IReferringDoctor) => void
  recoverReferringDoctor?: (referringDoctor: IReferringDoctor) => void
}

class ReferringDoctors extends React.Component<IReferringDoctorsProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndReferringDoctors!()
  }

  public render() {
    return (
      <>
        <Helmet>
          <title>Referring Doctors</title>
        </Helmet>
        <ResponsiveAppBar title="Referring Doctors" pageIcon={<RecordVoiceOver />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableToggleColumns={true}
          enableShowInactives={true}
          onRowClick={undefined}
          addButtonOnClick={this.onCreateReferringDoctor}
        />
      </>
    )
  }

  public onCreateReferringDoctor = () => {
    this.props.createReferringDoctor!()
  }
}

const InjectedReferringDoctors = inject<
  IStores,
  IReferringDoctorsProps,
  Partial<IReferringDoctorsProps>,
  any
>((stores: IStores) => ({
  createReferringDoctor: stores.referringDoctors.createReferringDoctor,
  dataTableManager: stores.referringDoctors.dataTableStore,
  disableReferringDoctor: stores.referringDoctors.disableReferringDoctor,
  getColumnSettingsAndReferringDoctors:
    stores.referringDoctors.getColumnSettingsAndReferringDoctors,
  recoverReferringDoctor: stores.referringDoctors.recoverReferringDoctor,
}))(ReferringDoctors)

export default withStyles(styles)(InjectedReferringDoctors)
