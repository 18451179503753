import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import { Receipt, ReceiptOutlined, Warning } from '@material-ui/icons'
import { FormikActions } from 'formik'
import { inject } from 'mobx-react'
import React from 'react'
import * as Yup from 'yup'
import BillOutESDialog from './BillOutESDialog'
import Moment from 'moment'
import { ICMS1500 } from '../Definitions'
import {
  createIClaimFormValues,
  IClaimFormValues,
  submitBillOutFormValues,
  submitClaimFormValues,
} from '../ReceiveClaim/ClaimFormValues'
import { IStores } from '../Stores'
import ChangeBillStatusDialog from './ChangeBillStatusDialog/ChangeBillStatusDialog'
import { complete1500BillOut, createOutgoingCMS1500 } from 'src/services/BillOutService'
import { IPaymentLineItem } from 'src/viewModels/PaymentLIneItem'
import FullWidthField from 'src/common/FullWidthField'
import NumberMaskedInput from 'src/common/NumberMaskedInput'
import { ClaimHeaderDetailsSummaryResult } from 'src/viewModels/ClaimHeaderDetailsSummaryResult'
import BillOutNotesModal from './BillOutNotesModal'
import { formatDate } from 'src/utils/Formatter'
// tslint:disable-next-line: no-var-requires
const CurrencyFormat: any = require('react-currency-format')

const BillOutDialogSchema = Yup.object().shape({
  q24: Yup.array().of(
    Yup.object({
      billOutAmount: Yup.number()
        .required('Required')
        .typeError('Bill Out Amount must be a number'),
    })
  ),
})

interface IBillOutDialogProps {
  close?: () => void
  closeBillStatusDialog?: () => void
  cms1500?: ICMS1500
  patientHeader?: ClaimHeaderDetailsSummaryResult
  lineItemDetails?: IPaymentLineItem[]
  isBillStatusDialogOpen?: boolean
  isOpen?: boolean
  openBillStatusDialog?: () => void
  createOutgoingCMS1500?: (cms1500: ICMS1500) => Promise<void>
  setIsCMS1500Saved?: (isSaved: boolean) => void
  isCMS1500Saved?: boolean
  groupInfo?: string
  openBillOutNotesModal?: () => void
}

class BillOutDialog extends React.Component<IBillOutDialogProps> {
  public state = {
    billoutMissing: false,
    billoutComplete: false,
  }

  public componentWillUnmount() {
    this.props.setIsCMS1500Saved!(false)
  }

  //@ts-ignore
  private save = async (
    values: IClaimFormValues,
    formikBag: FormikActions<IClaimFormValues>
  ) => {
    const cms1500 = await submitClaimFormValues(values, this.props.cms1500)

    this.props.createOutgoingCMS1500!(cms1500).then(() => {
      this.props.setIsCMS1500Saved!(true)
      formikBag.setSubmitting(false)
    })
  }

  private cancel = () => {
    this.props.closeBillStatusDialog!()
  }

  private completeOutgoingCMS1500 = async (
    values: IClaimFormValues,
    formikBag: FormikActions<IClaimFormValues>
  ) => {
    if (this.props.lineItemDetails != null) {
      this.props.lineItemDetails!.map((y, lineIdx) => {
        values.q24[lineIdx].aDateOfServiceFrom = y.dateOfService
        values.q24[lineIdx].aDateOfServiceTo = y.dateOfService
        values.q24[lineIdx].contractAmount = y.contractAmount
          ? y.contractAmount?.toString()
          : ''
        values.q24[lineIdx].dProcedureCptHcpcs = y.dProcedureCptHcpcs ?? ''
        values.q24[lineIdx].eDiagnosisPointer = y.eDiagnosisPointer ?? ''
        values.q24[lineIdx].fCharges = y.remainingBalance
          ? y.remainingBalance.toString()
          : ''
        values.q24[lineIdx].gDaysOrUnits = y.gDaysOrUnits ?? ''
        values.q24[lineIdx].dModifier1 = y.dModifier1 ?? ''
        values.q24[lineIdx].dModifier2 = y.dModifier2 ?? ''
        values.q24[lineIdx].dModifier3 = y.dModifier3 ?? ''
        values.q24[lineIdx].dModifier4 = y.dModifier4 ?? ''
        values.q24[lineIdx].id = y.cmS1500LineItemId ?? ''
      })
      if (this.props.cms1500 != null && values.billedOutAmountTotal != null) {
        this.props.cms1500!.billedOutAmountTotal = values.billedOutAmountTotal
      }
    }
    const billOutData = await submitBillOutFormValues(values)
    complete1500BillOut(this.props.cms1500!.id, billOutData)
      .then(() => {
        this.props.setIsCMS1500Saved!(true)
        formikBag.setSubmitting(false)
        this.cancel()
      })
      .catch(() => {
        formikBag.setSubmitting(false)
        this.setState({ billoutComplete: false })
      })
  }

  private createOutgoingCMS1500 = async (
    values: IClaimFormValues,
    formikBag: FormikActions<IClaimFormValues>
  ) => {
    if (this.props.lineItemDetails != null) {
      if (
        values.q24.length != this.props.lineItemDetails.length ||
        values.q24.some((x) => x.billOutAmount == undefined || isNaN(x.billOutAmount))
      ) {
        this.setState({ billoutMissing: true })
        formikBag.setSubmitting(false)
        return
      }

      this.props.lineItemDetails!.map((y, lineIdx) => {
        values.q24[lineIdx].aDateOfServiceFrom = y.dateOfService
        values.q24[lineIdx].aDateOfServiceTo = y.dateOfService
        values.q24[lineIdx].contractAmount = y.contractAmount
          ? y.contractAmount?.toString()
          : ''
        values.q24[lineIdx].eDiagnosisPointer = y.eDiagnosisPointer ?? ''
        values.q24[lineIdx].dProcedureCptHcpcs = y.dProcedureCptHcpcs ?? ''
        values.q24[lineIdx].fCharges = y.remainingBalance
          ? y.remainingBalance.toString()
          : ''
        values.q24[lineIdx].gDaysOrUnits = y.gDaysOrUnits ?? ''
        values.q24[lineIdx].dModifier1 = y.dModifier1 ?? ''
        values.q24[lineIdx].dModifier2 = y.dModifier2 ?? ''
        values.q24[lineIdx].dModifier3 = y.dModifier3 ?? ''
        values.q24[lineIdx].dModifier4 = y.dModifier4 ?? ''
        values.q24[lineIdx].id = y.cmS1500LineItemId ?? ''
      })
      if (this.props.cms1500 != null && values.billedOutAmountTotal != null) {
        this.props.cms1500!.billedOutAmountTotal = values.billedOutAmountTotal
      }
      const billOutData = await submitBillOutFormValues(values)
      await createOutgoingCMS1500(this.props.cms1500!.id, billOutData).then(
        (_response: any) => {
          this.props.setIsCMS1500Saved!(true)
          formikBag.setSubmitting(false)
        }
      )
    }
  }

  private onBlur(values: IClaimFormValues) {
    values.billedOutAmountTotal = values.q24
      .filter(
        (x) =>
          x != undefined &&
          x.billOutAmount != undefined &&
          !isNaN(x.billOutAmount) &&
          x.billOutAmount.toString() != ''
      )
      .map((x) => parseFloat((x.billOutAmount ?? 0) as unknown as string))
      .reduce((a, b) => a! + b!, 0)
  }

  private onKeyUp() {
    if (this.state.billoutMissing != false) {
      this.setState({ billoutMissing: false })
    }
  }

  private renderLineItemList(values: IClaimFormValues) {
    return (
      <>
        <Table style={{ paddingTop: '10px' }} size="small">
          <TableHead>
            <TableRow key={'cpt-header'}>
              <TableCell align="right">Date of Service</TableCell>
              <TableCell align="right">Line</TableCell>
              <TableCell>CPT</TableCell>
              <TableCell colSpan={4}>Modifier</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Charge Amount</TableCell>
              <TableCell align="right">Contract Amount</TableCell>
              <TableCell align="right">Bill Out</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.lineItemDetails ? (
              this.props.lineItemDetails!.map((y, lineIdx) => {
                return (
                  <TableRow key={`q24-${lineIdx}`}>
                    <TableCell align="right">
                      {Moment(y.dateOfService).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell align="right">{y.lineNumber}</TableCell>
                    <TableCell>{y.dProcedureCptHcpcs}</TableCell>
                    <TableCell>{y.dModifier1}</TableCell>
                    <TableCell>{y.dModifier2}</TableCell>
                    <TableCell>{y.dModifier3}</TableCell>
                    <TableCell>{y.dModifier4}</TableCell>
                    <TableCell align="right">{y.gDaysOrUnits}</TableCell>
                    <TableCell align="right">
                      <CurrencyFormat
                        value={
                          //Charge amount
                          y.remainingBalance
                        }
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <CurrencyFormat
                        value={y.contractAmount}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <FullWidthField
                        name={`q24.${lineIdx}.${'billOutAmount'}`}
                        required={true}
                        InputProps={{
                          inputComponent: NumberMaskedInput,
                          inputProps: {
                            currency: true,
                            guide: false,
                            numberOfDigits: 12,
                          },
                        }}
                        onBlur={this.onBlur(values)}
                        onKeyUp={() => this.onKeyUp()}
                        autoComplete="off"
                        disabled={!!values.createZeroDollarBill}
                      />
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow key="blank">
                <TableCell colSpan={10} align="center">
                  <i>None</i>
                </TableCell>
              </TableRow>
            )}
            <TableRow key="footer">
              <TableCell colSpan={8} align="right">
                <strong>Total:</strong>
              </TableCell>
              <TableCell align="right">
                {this.props.lineItemDetails ? (
                  <CurrencyFormat
                    value={this.props
                      .lineItemDetails!.map((x) => x.remainingBalance || 0)
                      .reduce((a, b) => a! + b!, 0)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                ) : (
                  <span>$0.00</span>
                )}
              </TableCell>
              <TableCell align="right">
                {this.props.lineItemDetails ? (
                  <CurrencyFormat
                    value={this.props
                      .lineItemDetails!.map((x) => x.contractAmount || 0)
                      .reduce((a, b) => a! + b!, 0)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                ) : (
                  <span>$0.00</span>
                )}
              </TableCell>
              <TableCell align="right">
                {values ? (
                  <CurrencyFormat
                    value={values.billedOutAmountTotal}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                ) : (
                  <span>$0.00</span>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    )
  }

  public render() {
    const {
      cms1500,
      isOpen,
      isBillStatusDialogOpen,
      isCMS1500Saved,
      patientHeader,
      groupInfo,
    } = this.props
    return (
      <>
        {cms1500 && (
          <BillOutESDialog
            initialValues={createIClaimFormValues(cms1500)}
            open={isOpen!}
            onClose={this.cancel}
            title={
              'Create 1500 ' +
              (patientHeader?.patientLast != undefined
                ? '- ' +
                  patientHeader?.patientLast +
                  ', ' +
                  patientHeader?.patientFirst +
                  (patientHeader?.dateOfBirth
                    ? ' (' + Moment(patientHeader?.dateOfBirth).format('MM/DD/YYYY') + ')'
                    : '') +
                  ' ' +
                  groupInfo
                : '')
            }
            cancel={this.cancel}
            create1500={this.createOutgoingCMS1500}
            complete1500={this.completeOutgoingCMS1500}
            maxWidth="lg"
            titleIcon={<Receipt />}
            validationSchema={BillOutDialogSchema}
            incomingCMS1500Id={cms1500.id}
            is1500Created={isCMS1500Saved}
            billoutCompleted={this.state.billoutComplete}
            setBilloutCompleted={(value: boolean) =>
              this.setState({ billoutComplete: value })
            }
          >
            {({ values }) => (
              <Grid container>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  wrap="nowrap"
                  alignItems="baseline"
                >
                  {patientHeader ? (
                    <Grid item>
                      <strong>Claim Rcv: </strong>{' '}
                      {patientHeader!.claimReceiveDate
                        ? formatDate(patientHeader!.claimReceiveDate)
                        : 'N/A'}
                      &nbsp;&nbsp;
                      {patientHeader!.claimStatus!.includes('Paid') ? (
                        <></>
                      ) : (
                        <Tooltip title="Claim has not been paid">
                          <Warning style={{ color: '#ffc107', cursor: 'pointer' }} />
                        </Tooltip>
                      )}
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item>
                    <strong>Provider Group: </strong>
                    {patientHeader?.providerName ?? 'N/A'}
                  </Grid>
                  <Grid item>
                    <strong>Provider Location: </strong>
                    {patientHeader?.locationName ?? 'N/A'}{' '}
                    <Tooltip title={'Show Notes'}>
                      <ReceiptOutlined
                        style={{ cursor: 'pointer' }}
                        fontSize="small"
                        onClick={() => {
                          this.props.openBillOutNotesModal!()
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  spacing={5}
                  wrap="nowrap"
                  style={{ margin: 0, width: '100%', padding: 16 }}
                >
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {this.props.lineItemDetails ? (
                      this.renderLineItemList(values)
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                  {isBillStatusDialogOpen && (
                    <ChangeBillStatusDialog
                      cms1500={values}
                      open={isBillStatusDialogOpen}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  justifyContent="flex-end"
                  xs={12}
                  style={{ color: 'red', display: 'flex' }}
                >
                  {this.state.billoutMissing ? 'Please fill out all fields' : ''}
                </Grid>
              </Grid>
            )}
          </BillOutESDialog>
        )}
        <BillOutNotesModal patientHeader={patientHeader} />
      </>
    )
  }
}

const InjectedBillOutDialog = inject<
  IStores,
  IBillOutDialogProps,
  Partial<IBillOutDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.billOutStore.closeDialog,
  closeBillStatusDialog: stores.billOutStore.closeBillStatusDialog,
  cms1500: stores.billOutStore.selectedCMS1500,
  lineItemDetails: stores.billOutStore.selectedListItems,
  createOutgoingCMS1500: stores.billOutStore.createOutgoingCMS1500,
  isBillStatusDialogOpen: stores.billOutStore.isBillStatusDialogOpen,
  isCMS1500Saved: stores.billOutStore.isCMS1500Saved,
  isOpen: stores.billOutStore.isOpen,
  openBillOutNotesModal: stores.billOutStore.openBillOutNotesModal,
  openBillStatusDialog: stores.billOutStore.openBillStatusDialog,
  setIsCMS1500Saved: stores.billOutStore.setIsCMS1500Saved,
  patientHeader: stores.patients.headerDetails,
}))(BillOutDialog)

export default InjectedBillOutDialog
