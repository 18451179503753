import { ILineItemDistributionDetail } from 'src/viewModels/LineItemDistributionDetail'
import { CMS1500LineItemDto } from 'src/viewModels/claim/CMS1500LineItemDto'

export interface ILineItemFormValues {
  id: string
  aDateOfServiceFrom?: Date
  aDateOfServiceTo?: Date
  bPlaceOfServiceTo: string
  billOutAmount?: number
  cEmg: string
  cms1500Id: string
  contractAmount: string
  dProcedureCptHcpcs: string
  dModifier1: string
  dModifier2: string
  dModifier3: string
  dModifier4: string
  eDiagnosisPointer: string
  fCharges: string | undefined
  gDaysOrUnits: string
  hFamilyPlan: string
  iIdQualifier: string
  iId: string
  iNpi: string
  overrideAmount?: number
  reasonCode: string
  isModalOpen?: boolean
}

function setDefaultValues() {
  return {
    bPlaceOfServiceTo: '',
    billOutAmount: undefined,
    cEmg: '',
    cms1500Id: '',
    contractAmount: '',
    dModifier1: '',
    dModifier2: '',
    dModifier3: '',
    dModifier4: '',
    dProcedureCptHcpcs: '',
    eDiagnosisPointer: '',
    fCharges: undefined,
    gDaysOrUnits: '',
    hFamilyPlan: '',
    iId: '',
    iIdQualifier: '',
    iNpi: '',
    id: '',
    isModalOpen: false,
    reasonCode: '',
  }
}

function setValuesFromDto(lineItemDto: CMS1500LineItemDto) {
  return {
    aDateOfServiceFrom: lineItemDto.aDateOfServiceFrom || undefined,
    aDateOfServiceTo: lineItemDto.aDateOfServiceTo || undefined,
    bPlaceOfServiceTo: lineItemDto.bPlaceOfServiceTo || '',
    billOutAmount: lineItemDto.billOutAmount || undefined,
    cEmg: lineItemDto.cEmg || '',
    cms1500Id: lineItemDto.cmS1500Id || '',
    contractAmount:
      lineItemDto.contractAmount != undefined
        ? lineItemDto.contractAmount!.toFixed(2)
        : '',
    dModifier1: lineItemDto.dModifier1 || '',
    dModifier2: lineItemDto.dModifier2 || '',
    dModifier3: lineItemDto.dModifier3 || '',
    dModifier4: lineItemDto.dModifier4 || '',
    dProcedureCptHcpcs: lineItemDto.dProcedureCptHcpcs || '',
    eDiagnosisPointer: lineItemDto.eDiagnosisPointer || '',
    fCharges:
      lineItemDto.fCharges != undefined ? lineItemDto.fCharges!.toFixed(2) : undefined,
    gDaysOrUnits: lineItemDto.gDaysOrUnits || '',
    hFamilyPlan: lineItemDto.hFamilyPlan || '',
    iId: lineItemDto.iId || '',
    iIdQualifier: lineItemDto.iIdQualifier || '',
    iNpi: lineItemDto.iNpi || '',
    id: lineItemDto.id || '',
    isModalOpen: false,
    overrideAmount: lineItemDto.overrideAmount || undefined,
    reasonCode: lineItemDto.reasonCode || '',
  }
}

export function createLineItemFormValues(
  lineItemDto?: CMS1500LineItemDto
): ILineItemFormValues {
  if (lineItemDto === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromDto(lineItemDto)
  }
}

export function toCMS1500LineItemDto(formValues: ILineItemFormValues, index: number) {
  return new CMS1500LineItemDto({
    aDateOfServiceFrom: formValues.aDateOfServiceFrom,
    aDateOfServiceTo: formValues.aDateOfServiceTo,
    bPlaceOfServiceTo: formValues.bPlaceOfServiceTo,
    billOutAmount: formValues.billOutAmount,
    cEmg: formValues.cEmg,
    cmS1500Id: formValues.cms1500Id,
    contractAmount: formValues.contractAmount ? +formValues.contractAmount! : undefined,
    dModifier1: formValues.dModifier1,
    dModifier2: formValues.dModifier2,
    dModifier3: formValues.dModifier3,
    dModifier4: formValues.dModifier4,
    dProcedureCptHcpcs: formValues.dProcedureCptHcpcs,
    eDiagnosisPointer: formValues.eDiagnosisPointer,
    fCharges: formValues.fCharges ? +formValues.fCharges : undefined,
    gDaysOrUnits: formValues.gDaysOrUnits,
    hFamilyPlan: formValues.hFamilyPlan,
    iId: formValues.iId,
    iIdQualifier: formValues.iIdQualifier,
    iNpi: formValues.iNpi,
    id: formValues.id,
    lineNumber: ++index,
    overrideAmount: formValues.overrideAmount,
    reasonCode: formValues.reasonCode,
  })
}

export function toBillOutLineItemDistribution(
  formValues: ILineItemFormValues
): ILineItemDistributionDetail {
  return {
    cms1500LineDetailId: formValues.id,
    amount: formValues.billOutAmount,
    cptCode: formValues.dProcedureCptHcpcs,
    diagnosisPointer: formValues.eDiagnosisPointer,
  }
}
