export interface IClaimSearchRequest {
  filter: string | undefined
  episodeOfCareId: string | undefined
  scheduleId: string | undefined
  status: string | undefined
  includeInactives: boolean
  excludeAppeals: boolean
  excludeClaimId: string | undefined
  page: number
  pageSize: number
}

export class ClaimSearchRequest implements IClaimSearchRequest {
  public filter: string | undefined
  public episodeOfCareId: string | undefined
  public scheduleId: string | undefined
  public status: string | undefined
  public includeInactives: boolean
  public excludeAppeals: boolean
  public excludeClaimId: string | undefined
  public page: number
  public pageSize: number
}
