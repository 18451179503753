import { AddressDto } from '../../generated_client'

export interface IAddressFormValues {
  readonly id: string
  line1: string
  line2: string
  city: string
  state: string | { name: string } | undefined
  zip: string
  name?: string
  phoneNumber: string
  faxNumber?: string
  website?: string
  email: string
  latitude?: number
  longitude?: number
  addressDisplay: string
}

function setDefaultValues() {
  return {
    city: '',
    email: '',
    faxNumber: '',
    id: '',
    line1: '',
    line2: '',
    name: undefined,
    phoneNumber: '',
    state: undefined,
    website: '',
    zip: '',
    addressDisplay: '',
  }
}

function setValuesFromDto(addressDto: AddressDto) {
  return {
    city: addressDto.city || '',
    email: addressDto.email || '',
    faxNumber: addressDto.faxNumber || '',
    id: addressDto.id || '',
    latitude: addressDto.latitude || undefined,
    line1: addressDto.line1 || '',
    line2: addressDto.line2 || '',
    longitude: addressDto.longitude || undefined,
    name: addressDto.name || undefined,
    phoneNumber: addressDto.phoneNumber || '',
    state: addressDto.state ? { name: addressDto.state } : undefined,
    website: addressDto.website || '',
    zip: addressDto.zip || '',
    addressDisplay: addressDto.addressDisplay || '',
  }
}

export function createAddressFormValues(addressDto?: AddressDto): IAddressFormValues {
  if (addressDto === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromDto(addressDto)
  }
}

export async function toAddressDto(address: IAddressFormValues): Promise<AddressDto> {
  const addressDto = new AddressDto({
    city: address.city,
    email: address.email,
    faxNumber: address.faxNumber,
    id: address.id,
    latitude: address.latitude,
    line1: address.line1,
    line2: address.line2,
    longitude: address.longitude,
    name: address.name,
    phoneNumber: address.phoneNumber,
    state: getState(address.state),
    website: address.website,
    zip: address.zip,
  })

  return addressDto
}

export function getState(state: string | { name: string } | undefined) {
  if (state === undefined) {
    return ''
  }
  return typeof state === 'string' ? state : state.name
}
