import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem'
import * as React from 'react'

export default class ConfirmMenuItem extends React.Component<MenuItemProps> {
  public state = {
    isConfirm: false,
  }

  public itemClicked = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation()
    if (this.state.isConfirm && this.props.onClick) {
      this.props.onClick(event)
    }
    this.setState({ isConfirm: !this.state.isConfirm })
  }

  public mouseLeft = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    this.setState({ isConfirm: false })
  }

  public render() {
    return (
      <MenuItem
        {...this.props}
        button={this.props.button || undefined}
        onClick={this.itemClicked}
        onMouseLeave={this.mouseLeft}
      >
        {this.state.isConfirm ? 'Are you sure?' : this.props.children}
      </MenuItem>
    )
  }
}
