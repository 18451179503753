import { withStyles } from '@material-ui/core'
import { Face } from '@material-ui/icons'
import { styles } from 'material-ui-pickers/wrappers/InlineWrapper'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultNurseCaseManager, INurseCaseManager } from '../Definitions'
import { IStores } from '../Stores'

interface INurseCaseManagersProps {
  dataTableManager?: IDataTableManager<INurseCaseManager>
  disableNurseCaseManager?: (nurseCaseManager: INurseCaseManager) => void
  getColumnSettingsAndNurseCaseManagers?: () => void
  isModalOpen?: boolean
  openDialogWithNurseCaseManager?: (nurseCaseManager: INurseCaseManager) => void
  recoverNurseCaseManager?: (nurseCaseManager: INurseCaseManager) => void
  setSelectedNurseCaseManager?: (nurseCaseManager: INurseCaseManager) => void
  rerouteToPath?: (path: string) => void
}

class NurseCaseManagers extends React.Component<INurseCaseManagersProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndNurseCaseManagers!()
  }

  public render() {
    return (
      <>
        <Helmet>
          <title>Nurse Case Managers</title>
        </Helmet>
        <ResponsiveAppBar title="Nurse Case Managers" pageIcon={<Face />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={true}
          enableToggleColumns={true}
          addButtonOnClick={this.openNurseCaseManager(DefaultNurseCaseManager(true))}
        />
      </>
    )
  }

  public openNurseCaseManager = (nurseCaseManager: INurseCaseManager) => () => {
    this.props.setSelectedNurseCaseManager!(nurseCaseManager)
    this.props.rerouteToPath!('/admin/nurseCaseManagers/nurseCaseManager/')
  }
}
const InjectedNurseCaseManagers = inject<
  IStores,
  INurseCaseManager,
  Partial<INurseCaseManagersProps>,
  any
>((stores: IStores) => ({
  dataTableManager: stores.nurseCaseManagers.dataTableStore,
  disableNurseCaseManager: stores.nurseCaseManagers.disableNurseCaseManager,
  getColumnSettingsAndNurseCaseManagers:
    stores.nurseCaseManagers.getColumnSettingsAndNurseCaseManagers,
  isModalOpen: stores.nurseCaseManagers.isModalOpen,
  recoverNurseCaseManager: stores.nurseCaseManagers.recoverNurseCaseManager,
  rerouteToPath: stores.global.rerouteToPath,
  setSelectedNurseCaseManager: stores.nurseCaseManagers.setSelectedNurseCaseManager,
}))(NurseCaseManagers)

export default withStyles(styles)(InjectedNurseCaseManagers)
