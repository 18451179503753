import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import { DefaultInsuranceCompany, IInsuranceCompany } from '../../Definitions'
import {
  Client,
  IInsuranceCompanyDto,
  InsuranceCompanyDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'InsuranceCompanyDto'

export default class InsuranceCompaniesStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public insuranceCompanies: IInsuranceCompany[]
  @observable
  public selectedInsuranceCompany?: IInsuranceCompany | undefined
  public dataTableStore: DataTableStore<IInsuranceCompanyDto, IInsuranceCompany>

  constructor(
    private globalStore: GlobalStore,
    private routerStore: RouterStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IInsuranceCompanyDto, IInsuranceCompany>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllInsuranceCompanies(
          filter,
          page,
          undefined,
          orderBy,
          includeInactives
        ),
      (insuranceCompany) => this.setupInsuranceCompanies(insuranceCompany)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedInsuranceCompany = undefined
  }

  @action.bound
  public disableInsuranceCompany(insuranceCompany: IInsuranceCompany) {
    this.isLoading = true
    return this.client
      .archiveInsuranceCompany(insuranceCompany.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndInsuranceCompanies() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllInsuranceCompanies(filter: string) {
    this.isLoading = true
    return this.client
      .getAllInsuranceCompanies(filter)
      .then((resp: SwaggerResponse<IInsuranceCompanyDto[]>) =>
        runInAction(() => {
          this.insuranceCompanies = this.setupInsuranceCompanies(resp.result)
        })
      )
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public setSelectedInsuranceCompany(insuranceCompany: IInsuranceCompany | undefined) {
    this.selectedInsuranceCompany = insuranceCompany
  }

  @action.bound
  public recoverInsuranceCompany(insuranceCompany: IInsuranceCompany) {
    this.isLoading = true
    return this.client
      .reactivateInsuranceCompany(insuranceCompany.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
  }

  @action.bound
  public saveInsuranceCompany(insuranceCompany: IInsuranceCompany) {
    this.isLoading = true
    let promise
    const insuranceCompanyDto = new InsuranceCompanyDto(insuranceCompany)
    if (insuranceCompany.isNew) {
      promise = this.client.createInsuranceCompany(insuranceCompanyDto)
    } else {
      promise = this.client.updateInsuranceCompany(
        insuranceCompany.id,
        insuranceCompanyDto
      )
    }
    return promise
      .then(() => {
        this.resetUIState(insuranceCompany.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupInsuranceCompanies = (insuranceCompanies: IInsuranceCompanyDto[]) => {
    return insuranceCompanies.map((x) =>
      this.setupInsuranceCompanyMenuItems(this.addDefaultFields(x))
    )
  }

  private addDefaultFields = (
    insuranceCompany: IInsuranceCompanyDto
  ): IInsuranceCompany => {
    return { ...DefaultInsuranceCompany(), ...insuranceCompany }
  }

  private setupInsuranceCompanyMenuItems = (insuranceCompany: IInsuranceCompany) => {
    insuranceCompany.menuItems = []
    if (insuranceCompany.isActive) {
      insuranceCompany.menuItems.push({
        icon: Edit,
        name: 'Edit',
        onClick: () => this.openInsuranceCompanyPage(insuranceCompany),
      })
      insuranceCompany.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableInsuranceCompany(insuranceCompany),
      })
    } else {
      insuranceCompany.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverInsuranceCompany(insuranceCompany),
      })
    }
    return insuranceCompany
  }

  @action.bound
  public getInsuranceCompanyById(id: string) {
    this.isLoading = true
    return this.client
      .getInsuranceCompanyById(id)
      .then((resp: SwaggerResponse<IInsuranceCompanyDto>) =>
        runInAction(() => {
          this.selectedInsuranceCompany = this.addDefaultFields(resp.result)
        })
      )
  }

  @action.bound
  public openInsuranceCompanyPage(insuranceCompany: IInsuranceCompany | undefined) {
    this.selectedInsuranceCompany = insuranceCompany
    const id = insuranceCompany ? insuranceCompany.id : ''
    this.routerStore.push('/admin/insuranceCompanies/insuranceCompany/' + id)
  }
}
