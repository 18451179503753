import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import { inject } from 'mobx-react'
import * as React from 'react'
import { pagedList } from 'src/viewModels/pagedList'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { Delete, InsertDriveFile, OpenInNew } from '@material-ui/icons'
import { IStores } from '../Stores'
import ReactPaginate from 'react-paginate'
import { Helmet } from 'react-helmet'
import { formatDate } from 'src/utils/Formatter'
import { DocumentSearchRequest } from 'src/viewModels/DocumentSearchRequest'
import { searchDocuments } from 'src/services/PatientService'
import UploadModal from './UploadModal'
import { DocumentSummaryResult } from 'src/viewModels/DocumentSummaryResult'
import { RouteComponentProps } from 'react-router-dom'
import { ChangeEvent } from 'react'
import { DocumentCategory } from 'src/utils/DocumentCategory'
import { DocumentType } from 'src/utils/DocumentType'
import { IPatient } from 'src/Definitions'
import Moment from 'moment'
import { Upload } from 'mdi-material-ui'
import DeletePatientDocumentModal from './DeletePatientDocumentModal/DeletePatientDocumentModal'

const styles = ({}: Theme) => createStyles({})

interface IPatientDocumentRouteParams {
  patientId: string
}

interface IClaimsAcquisitionProps
  extends RouteComponentProps<IPatientDocumentRouteParams> {
  isModalOpen?: boolean
  getPatientById?: (id: string) => Promise<void>
  patient?: IPatient
  openUploadModal?: (patientId: string) => void
  setUpdateParentStateAfterModal: (parentfunction?: () => void) => void
}

class PatientDocuments extends React.Component<IClaimsAcquisitionProps> {
  public state = {
    componentNames: new Array<{ id: string; description: string }>(),
    gridData: new pagedList().items as DocumentSummaryResult[],
    searchFileName: '',
    searchDescription: '',
    searchDateStart: '',
    searchDateEnd: '',
    searchCategory: '',
    searchType: '',
    searchEOC: '',
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 50,
    gridLoading: false,
    isDeletePatientDocumentModalOpen: false,
    selectedDocument: undefined as unknown as DocumentSummaryResult,
  }

  public async componentDidMount() {
    this.props.setUpdateParentStateAfterModal(this.getDocumentData)
    this.props.getPatientById!(this.props.match.params.patientId).then(() =>
      this.getDocumentData()
    )
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems === 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={13} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((doc, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell>{doc.fileName}</TableCell>
          <TableCell>{doc.description}</TableCell>
          <TableCell>{doc.categoryDescription}</TableCell>
          <TableCell>{doc.typeDescription}</TableCell>
          <TableCell>{doc.episodeOfCareNumberOrLegacyId}</TableCell>
          <TableCell style={{ whiteSpace: 'nowrap' }}>{doc.createdBy}</TableCell>
          <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
            {doc.fileDate ? formatDate(doc.fileDate.toString()) : ''}
          </TableCell>
          <TableCell>
            <Tooltip title="Open Document in a New Window">
              <OpenInNew
                onClick={() => {
                  window.open(doc.fileUrl)
                }}
                style={{
                  cursor: 'pointer',
                  color: '#29348F',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              />
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Delete Document">
              <Delete
                onClick={() => {
                  this.setState({
                    selectedDocument: doc,
                    isDeletePatientDocumentModalOpen: true,
                  })
                }}
                style={{
                  cursor: 'pointer',
                  color: 'red',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              />
            </Tooltip>
          </TableCell>
        </TableRow>
      ))
    }
  }

  private getDocumentData = async () => {
    this.setState({ gridLoading: true })
    const { page, pageSize } = this.state
    const requestData = new DocumentSearchRequest()
    requestData.page = page
    requestData.pageSize = pageSize
    requestData.fileName = this.state.searchFileName
    requestData.description = this.state.searchDescription
    requestData.category = this.state.searchCategory
    requestData.type = this.state.searchType
    requestData.episodeOfCareNumberOrLegacyId = this.state.searchEOC
    requestData.fileDateStart = new Date(this.state.searchDateStart)
    requestData.fileDateEnd = new Date(this.state.searchDateEnd)

    await searchDocuments(this.props.match.params.patientId, requestData).then(
      (response: any) => {
        this.setState({
          gridData: response.items,
          pageCount: response.totalPages,
          totalItems: response.totalItems,
          gridLoading: false,
        })

        if (response.totalItems > 0) {
          this.setPaginationOffsetData()
        }
      }
    )
    this.setState({ gridLoading: false })
  }

  private search = (event: any) => {
    this.setState({ page: 1 }, () => this.getDocumentData())
    event.preventDefault()
  }

  private clearSearch = () => {
    var cat_select_box = document.getElementById('categorySelect') as HTMLSelectElement
    if (cat_select_box != undefined) {
      cat_select_box.selectedIndex = 0
    }
    var type_select_box = document.getElementById('typeSelect') as HTMLSelectElement
    if (type_select_box != undefined) {
      type_select_box.selectedIndex = 0
    }
    var start = document.getElementById('fileStart') as HTMLInputElement
    if (start != undefined) {
      start.value = ''
    }
    var end = document.getElementById('fileEnd') as HTMLInputElement
    if (end != undefined) {
      end.value = ''
    }
    this.setState(
      {
        page: 1,
        searchFileName: '',
        searchDescription: '',
        searchDateStart: '',
        searchDateEnd: '',
        searchCategory: '',
        searchType: '',
        searchEOC: '',
        pageCount: 0,
        firstRecordIndex: 0,
        lastRecordIndex: 0,
        totalItems: 0,
      },
      () => {
        this.setState({ gridData: undefined, pageCount: 0, totalItems: 0 })
      }
    )
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getDocumentData())
  }

  private handleFileNameChange = (event: any) => {
    this.setState({ searchFileName: event.target.value })
  }

  private handleEOCChange = (event: any) => {
    this.setState({ searchEOC: event.target.value })
  }

  private handleDescriptionChange = (event: any) => {
    this.setState({ searchDescription: event.target.value })
  }

  private handleFileDateStartChange = (event: any) => {
    this.setState({ searchDateStart: event.target.value })
  }

  private handleFileDateEndChange = (event: any) => {
    this.setState({ searchDateEnd: event.target.value })
  }

  private setSelectedCategory = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ searchCategory: event.target.selectedOptions[0].value })
  }

  private setSelectedType = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ searchType: event.target.selectedOptions[0].value })
  }

  public render() {
    const { isDeletePatientDocumentModalOpen, gridLoading, selectedDocument } = this.state
    return (
      <>
        <Helmet>
          <title>
            {'Patient Docs: ' +
              (this.props.patient
                ? this.props.patient.lastName +
                  ', ' +
                  this.props.patient.firstName +
                  ' (' +
                  Moment(this.props.patient?.dateOfBirth).format('MM/DD/YYYY') +
                  ')'
                : '')}
          </title>
        </Helmet>
        <UploadModal />
        <ResponsiveAppBar title={'Patient Documents'} pageIcon={<InsertDriveFile />} />
        {isDeletePatientDocumentModalOpen && (
          <DeletePatientDocumentModal
            open={isDeletePatientDocumentModalOpen}
            close={(reload: boolean) => {
              if (reload) {
                this.getDocumentData()
              }
              this.setState({ isDeletePatientDocumentModalOpen: false })
            }}
            patient={this.props.patient!}
            document={selectedDocument}
          />
        )}

        {gridLoading && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '2',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <form onSubmit={this.search}>
          <Grid container style={{ margin: '10px' }} xs={12}>
            <Typography
              color="primary"
              component="h6"
              variant="h6"
              gutterBottom
              style={{ paddingBottom: '10px' }}
            >
              SEARCH CRITERIA [{this.props.patient?.lastName},{' '}
              {this.props.patient?.firstName} (
              {Moment(this.props.patient?.dateOfBirth).format('MM/DD/YYYY')})]
            </Typography>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6} lg={2}>
                <FormControl fullWidth>
                  <TextField
                    value={this.state.searchFileName}
                    onChange={this.handleFileNameChange}
                    label="File Name"
                    name="fileName"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <FormControl fullWidth>
                  <TextField
                    value={this.state.searchEOC}
                    onChange={this.handleEOCChange}
                    label="EOC/Legacy"
                    name="eoc"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item container xs={12} md={6} lg={4} direction="row">
                <FormControl fullWidth style={{ width: '48%' }}>
                  <TextField
                    value={this.state.searchDateStart}
                    onChange={this.handleFileDateStartChange}
                    label="Date Start"
                    name="fileStart"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    type="date"
                    defaultValue={this.state.searchDateStart}
                  />
                </FormControl>
                <strong style={{ margin: '0px 10px' }}> - </strong>
                <FormControl fullWidth style={{ width: '45%' }}>
                  <TextField
                    value={this.state.searchDateEnd}
                    onChange={this.handleFileDateEndChange}
                    label="Date End"
                    name="fileEnd"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    type="date"
                    defaultValue={this.state.searchDateEnd}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <FormControl fullWidth>
                  <TextField
                    value={this.state.searchDescription}
                    onChange={this.handleDescriptionChange}
                    label="Description"
                    name="description"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl style={{ width: '100%' }} variant="outlined">
                  <InputLabel shrink={true} variant="outlined">
                    Category
                  </InputLabel>
                  <Select
                    variant="outlined"
                    input={<OutlinedInput notched labelWidth={65} />}
                    inputProps={{ label: true, notched: true }}
                    native={true}
                    fullWidth
                    onChange={this.setSelectedCategory}
                    id="categorySelect"
                  >
                    <option value={''}>Select a category</option>
                    <option value={DocumentCategory.Authorization}>Authorization</option>
                    <option value={DocumentCategory.Billing}>Billing</option>
                    <option value={DocumentCategory.Contracts}>Contracts</option>
                    <option value={DocumentCategory.Medical}>Medical</option>
                    <option value={DocumentCategory.Misc}>Miscellaneous</option>
                    <option value={DocumentCategory.PT}>PT</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl style={{ width: '100%' }} variant="outlined">
                  <InputLabel shrink={true} variant="outlined">
                    Type
                  </InputLabel>
                  <Select
                    variant="outlined"
                    input={<OutlinedInput notched labelWidth={40} />}
                    inputProps={{ label: true, notched: true }}
                    native={true}
                    fullWidth
                    onChange={this.setSelectedType}
                    id="typeSelect"
                  >
                    <option value={''}>Select a type</option>
                    <option value={DocumentType.PatientSurgicalContract}>
                      Agreement for Surgical Services
                    </option>
                    <option value={DocumentType.PatientInjectionContract}>
                      Agreement for Surgical Injection Services
                    </option>
                    <option value={DocumentType.BillOut1500}>Bill-Out 1500</option>
                    <option value={DocumentType.DME}>DME</option>
                    <option value={DocumentType.FCE}>FCE</option>
                    <option value={DocumentType.InHome}>In-Home</option>
                    <option value={DocumentType.InitialEval}>Initial Eval</option>
                    <option value={DocumentType.InpatientRehab}>Inpatient Rehab</option>
                    <option value={DocumentType.InvoiceInitial}>Initial Invoice</option>
                    <option value={DocumentType.PatientDemographics}>
                      Patient Demographics
                    </option>
                    <option value={DocumentType.InvoiceResend}>Resend Invoice</option>
                    <option value={DocumentType.OccupationalTherapy}>
                      Occupational Therapy
                    </option>
                    <option value={DocumentType.PhysicalTherapy}>Physical Therapy</option>
                    <option value={DocumentType.SurgeryFacility}>
                      Surgery / Facility
                    </option>
                    <option value={DocumentType.TakeoverOfCare}>Takeover of Care</option>
                    <option value={DocumentType.Upload}>User Uploaded</option>
                    <option value={DocumentType.WorkConditioning}>
                      Work Conditioning
                    </option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            style={{
              marginLeft: '8px',
              paddingBottom: '10px',
              paddingTop: '10px',
            }}
          >
            <Grid item xs={11} style={{ display: 'inherit' }}>
              <Button
                type="submit"
                color="primary"
                size="medium"
                variant="contained"
                style={{ marginRight: '10px' }}
              >
                Search
              </Button>
              <Button
                color="secondary"
                onClick={this.clearSearch}
                size="medium"
                variant="contained"
                style={{ marginRight: '30px' }}
              >
                Clear Search
              </Button>
              <Typography
                variant="body2"
                color="primary"
                style={{ marginTop: 'auto', marginBottom: 'auto' }}
              >
                <strong>
                  This page displays documents uploaded or generated in the application.
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Upload Document">
                <Upload
                  style={{
                    cursor: 'pointer',
                    color: '#29348F',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                  onClick={() =>
                    this.props.openUploadModal!(this.props.match.params.patientId)
                  }
                />
              </Tooltip>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} style={{ paddingTop: '16px' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="gridPadding">
                <TableCell>File Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>EOC/Legacy</TableCell>
                <TableCell>User</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell></TableCell> {/* Open Document */}
                <TableCell></TableCell> {/* Delete Document */}
              </TableRow>
            </TableHead>
            <TableBody style={{ width: '100%' }}>{this.mapGridData()}</TableBody>
          </Table>
          <div className="pagination-row">
            {this.paginationInfo()}
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              onPageChange={this.handlePageChange}
              pageCount={this.state.pageCount}
              containerClassName={'pagination'}
              activeClassName={'active'}
              //@ts-ignore
              renderOnZeroPageCount={null}
            />
          </div>
        </Grid>
      </>
    )
  }
}

const InjectedClaimsAcquisition = inject<
  IStores,
  IClaimsAcquisitionProps,
  Partial<IClaimsAcquisitionProps>,
  any
>((stores: IStores) => ({
  isModalOpen: stores.patients.isUploadModalOpen,
  getPatientById: stores.patients.getPatientById,
  patient: stores.patients.selectedPatient,
  openUploadModal: stores.patients.openUploadModal,
  setUpdateParentStateAfterModal: stores.patients.setParentUpdateFunction,
}))(PatientDocuments)

export default withStyles(styles)(InjectedClaimsAcquisition)
