export interface IAddressDTO {
  name: string | undefined
  line1: string | undefined
  line2: string | undefined
  city: string | undefined
  state: string | undefined
  zip: string | undefined
  phoneNumber: string | undefined
}

export class AddressDTO implements IAddressDTO {
  public name: string | undefined
  public line1: string | undefined
  public line2: string | undefined
  public city: string | undefined
  public state: string | undefined
  public zip: string | undefined
  public phoneNumber: string | undefined
}
