import moment from 'moment'
import { DefaultReferral, IReferral } from '../../Definitions'

export interface IReferralFormValues {
  readonly id: string
  doi?: string | undefined
  referralDate: string
  referralTime: string
  employerId: string
  employerContactId: string
  occupation: string
  insuranceCompanyId: string
  insuranceCompanyContactId: string
  nurseCaseManagerId: string
  nurseCaseManagerContactId: string
  referralCaller: string
  claimNumber: string
  initials: string
  isActive: boolean
  health?: boolean
  jurisdiction: string
}

function setDefaultValues(): IReferralFormValues {
  return {
    claimNumber: '',
    doi: undefined,
    employerContactId: '',
    employerId: '',
    health: false,
    id: '',
    initials: '',
    insuranceCompanyContactId: '',
    insuranceCompanyId: '',
    isActive: true,
    nurseCaseManagerContactId: '',
    nurseCaseManagerId: '',
    occupation: '',
    referralCaller: '',
    referralDate: '',
    referralTime: '',
    jurisdiction: '',
  }
}

function setValuesFromReferral(referral: IReferral): IReferralFormValues {
  return {
    claimNumber: referral.claimNumber || '',
    doi: referral.doi ? moment(referral.doi).format('YYYY-MM-DD') : undefined,
    employerContactId: referral.employerContactId || '',
    employerId: referral.employerId || '',
    health: referral.health,
    id: referral.id || '',
    initials: referral.initials || '',
    insuranceCompanyContactId: referral.insuranceCompanyContactId || '',
    insuranceCompanyId: referral.insuranceCompanyId || '',
    isActive: referral.isActive || false,
    nurseCaseManagerContactId: referral.nurseCaseManagerContactId || '',
    nurseCaseManagerId: referral.nurseCaseManagerId || '',
    occupation: referral.occupation || '',
    referralCaller: referral.referralCaller || '',
    referralDate: referral.referralDate
      ? moment(referral.referralDate).format('YYYY-MM-DD')
      : '',
    referralTime: referral.referralTime || '',
    jurisdiction: referral.jurisdiction || '',
  }
}

export function createIReferralFormValues(referral?: IReferral): IReferralFormValues {
  if (referral === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromReferral(referral)
  }
}

export function toIReferral(
  formValues: IReferralFormValues,
  original?: IReferral
): IReferral {
  const referral = original === undefined ? DefaultReferral() : original
  return {
    ...referral,
    claimNumber: formValues.claimNumber,
    doi: formValues.doi
      ? new Date(moment(formValues.doi, 'YYYY-MM-DD').toISOString())
      : undefined,
    employerContactId: formValues.employerContactId,
    employerId: formValues.employerId,
    health: formValues.health,
    id: formValues.id,
    initials: formValues.initials,
    insuranceCompanyContactId: formValues.insuranceCompanyContactId,
    insuranceCompanyId: formValues.insuranceCompanyId,
    isActive: formValues.isActive,
    nurseCaseManagerContactId: formValues.nurseCaseManagerContactId,
    nurseCaseManagerId: formValues.nurseCaseManagerId || '',
    occupation: formValues.occupation || '',
    referralCaller: formValues.referralCaller || '',
    referralDate: new Date(moment(formValues.referralDate, 'YYYY-MM-DD').toISOString()),
    referralTime: formValues.referralTime || '',
    jurisdiction: formValues.jurisdiction || '',
  }
}
