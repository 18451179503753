import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Info } from '@material-ui/icons'
import React from 'react'
import ReactPaginate from 'react-paginate'
import { getAuthorizationLogsByEpisodeOfCareId } from 'src/services/AuthorizationLogService'
import { AuthorizationLogSearchRequest } from 'src/viewModels/AuthorizationLogSearchRequest'
import { AuthorizationLogDTO } from 'src/viewModels/AuthorizationLogDTO'
import { pagedList } from 'src/viewModels/pagedList'
import moment from 'moment'

interface IAuthorizationLogGridProps {
  episodeOfCareId: string
}

class AuthorizationLogGrid extends React.Component<IAuthorizationLogGridProps> {
  public componentDidMount(): void {
    this.getAuthorizationLogData()
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private getAuthorizationLogData = async () => {
    const { page, pageSize, episodeOfCareId } = this.state
    const data: AuthorizationLogSearchRequest = {
      episodeOfCareId,
      page,
      pageSize,
    }

    await getAuthorizationLogsByEpisodeOfCareId(data).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getAuthorizationLogData())
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems === 0
  }

  private mapLogGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          <TableCell>{moment(record.createdDate).format('MM/DD/YYYY')}</TableCell>
          <TableCell>{record.authorizationTypeString}</TableCell>
          <TableCell>
            {record.data?.trim().length > 0 ? (
              <Tooltip
                title={
                  <React.Fragment>
                    <span
                      style={{
                        fontSize: '18px',
                        lineHeight: '1.5em',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {record.data}
                    </span>
                  </React.Fragment>
                }
              >
                <Info />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell>{record.createdByName}</TableCell>
        </TableRow>
      ))
    }
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  public state = {
    gridData: new pagedList().items as AuthorizationLogDTO[],
    page: 1,
    pageCount: 1,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 5,
    episodeOfCareId: this.props.episodeOfCareId,
  }

  public render() {
    return (
      <>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="flex-start"
          wrap="wrap"
        >
          <Grid item xs={12} style={{ minWidth: '750px' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="gridPadding">
                  <TableCell>Created On</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Created By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '100%' }}>{this.mapLogGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                forcePage={this.state.page - 1}
                pageCount={this.state.pageCount}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default AuthorizationLogGrid
