import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Receipt } from '@material-ui/icons'
import React from 'react'
import ReactPaginate from 'react-paginate'
import { pagedList } from 'src/viewModels/pagedList'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import moment from 'moment'
import { AppealLogSearchRequest } from 'src/viewModels/AppealLogSearchRequest'
import { GetAppealLogByClaimId } from 'src/services/ClaimService'
import { AppealLogDTO } from 'src/viewModels/AppealLogDTO'
import AppealNameConstants from 'src/constants/AppealNameConstants'
import { getHeaderInfo } from 'src/services/ClaimService'
import { ClaimHeaderDetailsSummaryResult } from 'src/viewModels/ClaimHeaderDetailsSummaryResult'

interface IAppealLogModalProps {
  isOpen?: boolean
  claimId: string
  closeModal: () => void
  groupInfo: string
}

class AppealLogModal extends React.Component<IAppealLogModalProps> {
  public async componentDidMount(): Promise<void> {
    await this.getAppealLogData()
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private getAppealLogData = async () => {
    const { page, pageSize } = this.state
    const claimId = this.props.claimId
    const data: AppealLogSearchRequest = {
      claimId,
      page,
      pageSize,
    }

    await GetAppealLogByClaimId(this.props.claimId, data).then(async (response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })

    await getHeaderInfo(this.props.claimId).then(
      (resp: ClaimHeaderDetailsSummaryResult) => {
        this.setState({ headerData: resp })
      }
    )
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getAppealLogData())
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems === 0
  }

  private mapLogGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          <TableCell>{moment(record.createdDate).format('MM/DD/YYYY')}</TableCell>
          <TableCell>{record.createdByName}</TableCell>
          <TableCell>{AppealNameConstants[record.appealType]}</TableCell>
          <TableCell>{record.notes}</TableCell>
        </TableRow>
      ))
    }
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  public state = {
    gridData: new pagedList().items as AppealLogDTO[],
    page: 1,
    pageCount: 1,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 5,
    claimId: this.props.claimId,
    headerData: new ClaimHeaderDetailsSummaryResult(),
  }

  public render() {
    const { headerData } = this.state

    return (
      <>
        <Dialog
          key={this.props.claimId}
          fullWidth={true}
          maxWidth="lg"
          open={this.props.isOpen!}
        >
          <DialogTitle style={{ padding: 0 }}>
            <ResponsiveAppBar
              title={
                'Appeal Log' +
                (headerData?.patientLast != undefined
                  ? ' - ' +
                    headerData?.patientLast +
                    ', ' +
                    headerData?.patientFirst +
                    (headerData?.dateOfBirth
                      ? ' (' + moment(headerData?.dateOfBirth).format('MM/DD/YYYY') + ')'
                      : '') +
                    ' ' +
                    this.props.groupInfo
                  : '')
              }
              pageIcon={<Receipt />}
              isModal={true}
            />
          </DialogTitle>
          <DialogContent>
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              alignContent="center"
              alignItems="flex-start"
              wrap="wrap"
            >
              <Grid item xs={12} style={{ minWidth: '750px' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className="gridPadding">
                      <TableCell>Created Date</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Appeal Type</TableCell>
                      <TableCell>Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ width: '100%' }}>{this.mapLogGridData()}</TableBody>
                </Table>
                <div className="pagination-row">
                  {this.paginationInfo()}
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    onPageChange={this.handlePageChange}
                    forcePage={this.state.page - 1}
                    pageCount={this.state.pageCount}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    //@ts-ignore
                    renderOnZeroPageCount={null}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeModal} data-cy="close">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default AppealLogModal
