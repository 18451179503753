import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core'
import React from 'react'

interface IColorSelectDialogProps {
  close: () => void
  open: boolean
  setFieldValue: (name: string, value: any) => void
}

export class ColorSelectDialog extends React.Component<IColorSelectDialogProps> {
  public setValue = (color: string) => (_event: React.MouseEvent<HTMLElement>) => {
    this.props.setFieldValue('tagColor', color)
    this.props.close()
  }

  public render() {
    const { close, open } = this.props
    return (
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Choose Color</DialogTitle>
        <DialogContent>
          <Grid
            alignItems="center"
            justifyContent="center"
            direction="row"
            container
            spacing={2}
          >
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#2062af')}
                style={{
                  backgroundColor: '#2062af',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#58aeb7')}
                style={{
                  backgroundColor: '#58aeb7',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#f4b528')}
                style={{
                  backgroundColor: '#f4b528',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#dd3e48')}
                style={{
                  backgroundColor: '#dd3e48',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#bf89ae')}
                style={{
                  backgroundColor: '#bf89ae',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            alignItems="center"
            justifyContent="center"
            direction="row"
            container
            spacing={2}
          >
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#5c88be')}
                style={{
                  backgroundColor: '#5c88be',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#59bc10')}
                style={{
                  backgroundColor: '#59bc10',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#e87034')}
                style={{
                  backgroundColor: '#e87034',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#f84c44')}
                style={{
                  backgroundColor: '#f84c44',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#8c47fb')}
                style={{
                  backgroundColor: '#8c47fb',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            alignItems="center"
            justifyContent="center"
            direction="row"
            container
            spacing={2}
          >
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#51c1ee')}
                style={{
                  backgroundColor: '#51c1ee',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#8cc453')}
                style={{
                  backgroundColor: '#8cc453',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#c2987d')}
                style={{
                  backgroundColor: '#c2987d',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#ce7777')}
                style={{
                  backgroundColor: '#ce7777',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={this.setValue('#9086ba')}
                style={{
                  backgroundColor: '#9086ba',
                  height: '50px',
                  width: '50px',
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
