import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Info } from '@material-ui/icons'
import { inject } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { formatCurrency } from 'src/utils/Formatter'
import { SentCheckSummaryResult } from 'src/viewModels/SentCheckSummaryResult'

interface IAssignedCheckModalProps {
  closeModal?: () => void
  isOpen?: boolean
  isVerifyOpen?: boolean
  results?: SentCheckSummaryResult[]
}

class AssignedCheckModel extends React.Component<IAssignedCheckModalProps> {
  public render() {
    return (
      <Dialog
        key={this.props.results?.length}
        fullWidth={true}
        maxWidth="md"
        open={this.props.isOpen! || this.props.isVerifyOpen!}
      >
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar title="Check Summary" pageIcon={<Info />} isModal={true} />
        </DialogTitle>
        <DialogContent style={{ paddingBottom: '10px' }}>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="flex-start"
            wrap="wrap"
          >
            <Grid
              container
              item
              xs={12}
              spacing={3}
              style={{ paddingTop: 12, paddingBottom: 12 }}
              wrap="nowrap"
            >
              <Grid item>
                <strong>Number of Checks:</strong> {this.props.results?.length ?? 0}
              </Grid>
              <Grid item>
                <strong>Total:</strong>{' '}
                {formatCurrency(
                  (
                    this.props.results?.reduce(
                      (sum, current) => sum + current.checkAmount,
                      0
                    ) ?? 0
                  ).toString()
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="simple table" style={{ padding: '20px' }}>
                <TableHead>
                  <TableRow className="gridPadding">
                    <TableCell>Billing Information</TableCell>
                    <TableCell align="right">Check Date</TableCell>
                    <TableCell>Check Number</TableCell>
                    <TableCell align="right">Check Amount</TableCell>
                    <TableCell align="right"># of Claims</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '10%' }}>
                  {this.mapCheckSummaryData()}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private close = () => {
    this.props.closeModal!()
  }

  private mapCheckSummaryData = () => {
    return this.props.results?.map((record, index) => (
      <TableRow className="gridPadding" key={index}>
        <TableCell>
          <strong>{record.billingName}</strong>
          <br></br>
          {record.billingAddress}
        </TableCell>
        <TableCell align="right">
          {record.checkDate ? moment(record.checkDate).format('MM/DD/YYYY') : ''}
        </TableCell>
        <TableCell>{record.checkNumber}</TableCell>
        <TableCell align="right">
          {formatCurrency(record.checkAmount.toString())}
        </TableCell>
        <TableCell align="right">{record.claimCount}</TableCell>
      </TableRow>
    ))
  }
}

const InjectedAssignedCheckModel = inject<
  IStores,
  IAssignedCheckModalProps,
  Partial<IAssignedCheckModalProps>,
  any
>((stores: IStores) => ({
  closeModal: stores.billsStore.closeAssignedCheck,
  isOpen: stores.billsStore.isAssignedCheckOpen,
  results: stores.billsStore.assignedCheckResults,
}))(AssignedCheckModel)

export default InjectedAssignedCheckModel
