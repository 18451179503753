import { IHaveAddressFormValues } from '../common/AddressField/AddressField'
import {
  createAddressFormValues,
  toAddressDto,
} from '../common/AddressField/AddressFormValues'
import { DefaultOrganization, IOrganization } from '../Definitions'
import { UserDto } from '../generated_client'

export interface ICompanyFormValues extends IHaveAddressFormValues {
  readonly id: string
  createdOn?: Date
  modifiedOn?: Date
  deletedOn?: Date
  isActive: boolean
  initials: string
  name: string
  navigationLabel: string
  globalInvoiceUserId?: string
  globalInvoiceUser?: UserDto
  patientDemographicsUserId?: string
  patientDemographicsUser?: UserDto
  distributeTestResultsCCId?: string
  distributeTestResultsCC?: UserDto
  distributeTestResultsAdditionalInfoId?: string
  distributeTestResultsAdditionalInfo?: UserDto
}

function setDefaultValues(): ICompanyFormValues {
  return {
    billingInquiries: createAddressFormValues(),
    createdOn: new Date(),
    deletedOn: undefined,
    generalInquiries: createAddressFormValues(),
    id: '',
    initials: '',
    isActive: true,
    modifiedOn: new Date(),
    name: '',
    navigationLabel: '',
  }
}

function setValuesFromOrganization(organization: IOrganization): ICompanyFormValues {
  return {
    billingInquiries: createAddressFormValues(organization.billingInquiries),
    createdOn: organization.createdOn || new Date(),
    deletedOn: organization.deletedOn || undefined,
    distributeTestResultsAdditionalInfoId:
      organization.distributeTestResultsAdditionalInfoId,
    distributeTestResultsCCId: organization.distributeTestResultsCCId,
    generalInquiries: createAddressFormValues(organization.generalInquiries),
    globalInvoiceUserId: organization.globalInvoiceUserId,
    id: organization.id || '',
    initials: organization.initials || '',
    isActive: organization.isActive,
    modifiedOn: organization.modifiedOn,
    name: organization.name || '',
    navigationLabel: organization.navigationLabel || '',
    patientDemographicsUserId: organization.patientDemographicsUserId,
  }
}

export function createICompanyFormValues(
  organization?: IOrganization
): ICompanyFormValues {
  if (organization === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromOrganization(organization)
  }
}

export async function toICompany(
  formValues: ICompanyFormValues,
  original?: IOrganization
): Promise<IOrganization> {
  const organization = original === undefined ? DefaultOrganization() : original
  const generalInquiries = await toAddressDto(formValues.generalInquiries!)
  const billingInquiries = await toAddressDto(formValues.billingInquiries!)
  return {
    ...organization,
    billingInquiries,
    distributeTestResultsAdditionalInfoId:
      formValues.distributeTestResultsAdditionalInfoId,
    distributeTestResultsCCId: formValues.distributeTestResultsCCId,
    generalInquiries,
    globalInvoiceUser: undefined,
    globalInvoiceUserId: formValues.globalInvoiceUserId,
    id: formValues.id,
    initials: formValues.initials,
    isActive: formValues.isActive,
    name: formValues.name,
    navigationLabel: formValues.navigationLabel,
    patientDemographicsUser: undefined,
    patientDemographicsUserId: formValues.patientDemographicsUserId,
  }
}
