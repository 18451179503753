import { inject, observer } from 'mobx-react'
import ReactPaginate from 'react-paginate'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { EditRounded, AddCircle, RestorePageRounded, Check } from '@material-ui/icons'
import * as React from 'react'
import IDataTableManager from '../common/IDataTableManager'
import { DefaultLocationBilling, ILocation, ILocationBilling } from '../Definitions'
import { IStores } from '../Stores'
import LocationBillingDialog from './LocationBillingDialog'
import {
  getProviderLocationBillingAddresees,
  recoverProviderLocationBillingAddress,
} from 'src/services/LocationService'
import '../Grid.css'
import { pagedList } from 'src/viewModels/pagedList'
import { ProviderLocationBillingAddressSummaryResult } from 'src/viewModels/ProviderLocationBillingAddressSummaryResult'

interface ILocationBillingsProps {
  dataTableManager?: IDataTableManager<ILocationBilling>
  disableLocationBilling?: (locationBilling: ILocationBilling) => void
  isModalOpen?: boolean
  openDialogWithLocationBilling?: (locationBilling: ILocationBilling) => void
  recoverLocationBilling?: (locationBilling: ILocationBilling) => void
  selectedLocation?: ILocation
  loadLocationBilling?: (locationBillingId: string) => void
  setUpdateParentStateAfterModal?: (parentfunction?: () => void) => void
  setActiveBillingAddressCount?: (count: number) => void
}

@inject((stores: IStores) => ({
  dataTableManager: stores.locationBillings.dataTableStore,
  disableLocationBilling: stores.locationBillings.disableLocationBilling,
  isModalOpen: stores.locationBillings.isModalOpen,
  openDialogWithLocationBilling: stores.locationBillings.openDialogWithLocationBilling,
  selectedLocation: stores.locations.selectedLocation,
  loadLocationBilling: stores.locationBillings.loadLocationBilling,
  setUpdateParentStateAfterModal: stores.locationBillings.setParentUpdateFunction,
  setActiveBillingAddressCount: stores.locationBillings.setActiveBillingAddressCount,
}))
@observer
class LocationBillings extends React.Component<ILocationBillingsProps> {
  public componentDidMount() {
    this.getBillingAddressData()
    this.props.setUpdateParentStateAfterModal!(this.getBillingAddressData)
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private getBillingAddressData = async () => {
    const { page, pageSize } = this.state
    await getProviderLocationBillingAddresees(
      this.props.selectedLocation?.id,
      page,
      pageSize
    ).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
      })
      this.props.setActiveBillingAddressCount!(
        response.items.filter((x: { isActive: any }) => x.isActive)!.length!
      )
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private recover = async (addressId: string) => {
    await recoverProviderLocationBillingAddress(
      this.props.selectedLocation?.id,
      addressId
    ).then((_resp: any) => {
      this.getBillingAddressData()
    })
  }

  private edit = async (locationBillingId: string) => {
    this.props.loadLocationBilling!(locationBillingId)
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getBillingAddressData())
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className="gridNoPadding" key={index}>
          <TableCell>{record.name}</TableCell>
          <TableCell>{record.fullAddress}</TableCell>
          <TableCell>{record.city}</TableCell>
          <TableCell>{record.state}</TableCell>
          {this.isActive(record)}
          {record.isActive ? (
            <TableCell>
              <Tooltip title="Edit Address">
                <EditRounded
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.edit(record.locationBillingId!)
                  }}
                ></EditRounded>
              </Tooltip>
            </TableCell>
          ) : (
            <></>
          )}
          {this.isRecoverable(record)}
        </TableRow>
      ))
    }
  }

  private isActive = (record: any) => {
    if (!record.isActive) {
      return <TableCell></TableCell>
    } else {
      return (
        <TableCell>
          <Check style={{ color: '#5CB85C' }} />
        </TableCell>
      )
    }
  }
  private isRecoverable = (record: any) => {
    if (!record.isActive) {
      return (
        <TableCell>
          <Tooltip title="Recover Address">
            <RestorePageRounded
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.recover(record.addressId)
              }}
            ></RestorePageRounded>
          </Tooltip>
        </TableCell>
      )
    } else {
      return <TableCell></TableCell>
    }
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  public state = {
    gridData: new pagedList().items as ProviderLocationBillingAddressSummaryResult[],
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 5,
  }
  public render() {
    const locationBillingDialog = this.props.isModalOpen && <LocationBillingDialog />
    return (
      <>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="flex-start"
          wrap="wrap"
        >
          <Grid item xs={12} style={{ minWidth: '750px' }}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Typography
                  variant={'h6'}
                  color="textSecondary"
                  style={{ height: '18px' }}
                >
                  BILLING ADDRESSES
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title="Add New Billing Address">
                  <AddCircle
                    color={this.props.selectedLocation!.isNew ? 'inherit' : 'primary'}
                    onClick={
                      this.props.selectedLocation!.isNew
                        ? undefined
                        : this.openDialog(DefaultLocationBilling(true))
                    }
                    style={{
                      fontSize: '30px',
                      cursor: this.props.selectedLocation!.isNew ? 'auto' : 'pointer',
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="gridNoPadding">
                  <TableCell>Billing Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '10%' }}>{this.mapGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                pageCount={this.state.pageCount}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
        </Grid>
        {locationBillingDialog}
      </>
    )
  }

  public openDialog = (locationBilling: ILocationBilling) => () => {
    this.props.openDialogWithLocationBilling!(locationBilling)
  }
}

export default LocationBillings
