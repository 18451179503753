export interface IPendingCheckRequest {
  checkDate: Date | undefined
  checkNumber: string | undefined
  checkId: string
}

export class PendingCheckRequest implements IPendingCheckRequest {
  public checkDate: Date | undefined
  public checkNumber: string | undefined
  public checkId: string
}
