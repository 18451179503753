import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { inject } from 'mobx-react'
import React from 'react'
// tslint:disable-next-line: no-var-requires
const CurrencyFormat: any = require('react-currency-format')
import { AttachMoney, PictureAsPdfRounded } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import { Prompt } from 'react-router-dom'
import * as Yup from 'yup'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultCheck, ICheck } from '../Definitions'
import { IStores } from '../Stores'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { IBillTableFormValues } from './billsTable/BillTableFormValues'
import { SentCheckSummaryResult } from 'src/viewModels/SentCheckSummaryResult'
import { getPendingSentChecks } from 'src/services/SearchService'
import { PendingCheckRequest } from 'src/viewModels/PendingCheckRequest'
import { processPendingChecks } from 'src/services/CheckService'
import CardWithTitle from 'src/common/CardWithTitle/CardWithTitle'

const styles = ({ spacing }: Theme) =>
  createStyles({
    buttonGrid: {
      marginTop: '20px',
    },
    paperTable: {
      overflow: 'auto',
    },
    sidebar: {
      height: '100%',
    },
    table: {
      paddingLeft: '20px',
      paddingRight: '20px',
      marginTop: '20px',
    },
    form: {
      padding: spacing(2),
    },
  })

const CheckSchema = Yup.object().shape({
  providers: Yup.array().of(
    Yup.object({
      checkNumber: Yup.string().required('Check Number is required'),
    })
  ),
})

export interface IPendingChecksProps extends WithStyles<typeof styles> {
  pendingChecks?: IBillTableFormValues
  previewEOB?: (check: ICheck) => Promise<void>
  rerouteToPath?: (path: string) => void
  saveCheck?: (check: ICheck) => Promise<void>
  closeTab?: () => void
  setReturn?: () => void
}

class PendingChecks extends React.Component<IPendingChecksProps> {
  public state = {
    isSaved: false,
    reload: false,
    pendingChecks: new Array<SentCheckSummaryResult>(),
    checkTotalsProcessedToday: 0,
    checkCountProcessedToday: 0,
    loading: true,
    proccessCheck: new Array<boolean>(),
    selectedTotal: 0,
    validationError: false,
  }

  private previewEOB = (idx: number) => {
    this.setState({ loading: true })
    const newCheck = DefaultCheck(true)
    var tempCheck = {
      ...newCheck,
      id: this.state.pendingChecks[idx].checkId,
      checkNumber: this.state.pendingChecks[idx].checkNumber ?? '',
      datePaid: new Date(this.state.pendingChecks[idx].checkDate ?? ''),
    }
    this.props.previewEOB!(tempCheck).then(() => {
      this.setState({ loading: false })
    })
  }

  private save = async (close: boolean) => {
    var listOfchecks = new Array<PendingCheckRequest>()

    this.state.pendingChecks.forEach((val, idx) => {
      if (this.state.proccessCheck[idx]) {
        if (
          val.checkDate == undefined ||
          val.checkNumber == undefined ||
          val.checkNumber == ''
        ) {
          this.setState({ validationError: true, isSaved: false })
          return
        }

        var check = new PendingCheckRequest()
        check.checkDate = new Date(moment(val.checkDate, 'YYYY-MM-DD').toISOString())
        check.checkNumber = val.checkNumber
        check.checkId = val.checkId

        listOfchecks.push(check)
      }
    })

    if (listOfchecks.length > 0) {
      processPendingChecks(listOfchecks)
        .then(() => {
          this.setState(
            {
              isSaved: true,
              loading: true,
              proccessCheck: new Array<boolean>(),
              selectedTotal: 0,
            },
            () => {
              this.setup()
              if (close) {
                this.cancel()
              }
            }
          )
        })
        .catch(() => this.setState({ loading: false }))
    }
  }

  public componentDidMount() {
    this.props.setReturn!()
    this.setup()
  }

  private setup() {
    getPendingSentChecks().then((response) => {
      this.setState({
        pendingChecks: response.checks,
        checkTotalsProcessedToday: response.checkTotalsProcessedToday,
        checkCountProcessedToday: response.checkCountProcessedToday,
        processCheck: new Array<boolean>(response.checks.length).fill(false),
        loading: false,
        isSaved: false,
      })
    })
  }

  private cancel = () => {
    this.props.closeTab!()
  }

  private handleCheckDateChange = (event: any, idx: number) => {
    this.state.pendingChecks[idx].checkDate = event.target.value
    this.setState({
      pendingChecks: this.state.pendingChecks,
      validationError: false,
    })
  }

  private handleCheckNumberChange = (event: any, idx: number) => {
    this.state.pendingChecks[idx].checkNumber = event.target.value
    this.setState({
      pendingChecks: this.state.pendingChecks,
      validationError: false,
    })
  }

  private onConfirmPaymentChanged = (idx: number) => {
    this.state.proccessCheck[idx] = !this.state.proccessCheck[idx]
    var selectedTotal = 0
    this.state.pendingChecks.forEach((val, idx) => {
      if (this.state.proccessCheck[idx]) {
        selectedTotal += val.checkAmount
      }
    })
    this.setState({
      proccessCheck: this.state.proccessCheck,
      selectedTotal: selectedTotal,
      validationError: false,
    })
  }

  public renderGrid() {
    if (this.state.pendingChecks.length == 0) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={8} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.pendingChecks.map((provider, idx) => (
        <TableRow key={provider.checkId}>
          <TableCell component="th" scope="row">
            <strong>{provider.billingName}</strong>
            <br></br>
            {provider.billingAddress}
          </TableCell>
          <TableCell component="th" scope="row">
            {moment(provider.createdOn).format('MM/DD/YYYY')}
          </TableCell>
          <TableCell component="th" scope="row">
            {provider.createdBy}
          </TableCell>
          <TableCell>
            <CurrencyFormat
              value={provider.checkAmount}
              thousandSeparator={true}
              displayType={'text'}
              prefix={'$'}
              fixedDecimalScale={true}
              decimalScale={2}
            />
          </TableCell>
          <TableCell component="th" scope="row">
            {provider.claimCount}
          </TableCell>
          <TableCell>
            <FormControl fullWidth>
              <TextField
                inputProps={{ id: 'checkNumber' }}
                InputLabelProps={{ shrink: true }}
                name={`checkNumber`}
                variant="outlined"
                value={provider.checkNumber}
                required
                onChange={(e) => this.handleCheckNumberChange(e, idx)}
              />
            </FormControl>
          </TableCell>
          <TableCell>
            <FormControl fullWidth>
              <TextField
                inputProps={{ id: 'datePaid' }}
                InputLabelProps={{ shrink: true }}
                type="date"
                name={`datePaid`}
                variant="outlined"
                required
                value={moment(provider.checkDate ?? new Date()).format('yyyy-MM-DD')}
                onChange={(e) => this.handleCheckDateChange(e, idx)}
              />
            </FormControl>
          </TableCell>
          <TableCell style={{ paddingRight: '20px' }}>
            <Tooltip title="Preview EOB">
              <div onClick={() => this.previewEOB(idx)} style={{ cursor: 'pointer' }}>
                <PictureAsPdfRounded color="primary" />
              </div>
            </Tooltip>
          </TableCell>
          <TableCell>
            <FormControl fullWidth>
              <FormControlLabel
                label="Confirm Payment"
                labelPlacement="end"
                control={
                  <Checkbox
                    checked={this.state.proccessCheck[idx]}
                    onChange={() => this.onConfirmPaymentChanged(idx)}
                    inputProps={{
                      id: 'confirm-payment',
                    }}
                    value={this.state.proccessCheck[idx]}
                    name="confirmPayment"
                  />
                }
              />
            </FormControl>
          </TableCell>
        </TableRow>
      ))
    }
  }

  public render() {
    const { classes } = this.props
    return (
      <>
        <Helmet>
          <title>{'Checks Holding'}</title>
        </Helmet>
        {this.state.pendingChecks ? (
          <Formik
            initialValues={this.state.pendingChecks!}
            onSubmit={() => {}}
            enableReinitialize={true}
            validationSchema={CheckSchema}
          >
            {({ isSubmitting, dirty, submitCount }) => (
              <>
                {(isSubmitting || this.state.loading) && (
                  <div
                    style={{
                      position: 'fixed',
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: 800,
                    }}
                  >
                    <CircularProgress size={100} />
                  </div>
                )}

                <Prompt
                  when={dirty && submitCount === 0}
                  message="Are you sure you want to leave the page? There are unsaved changes."
                />
                <ResponsiveAppBar title={'Checks Holding'} pageIcon={<AttachMoney />}>
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="flex-end"
                    spacing={3}
                  >
                    <Grid item>
                      <Button size="small" variant="contained" onClick={this.cancel}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => this.save(false)}
                        disabled={
                          isSubmitting ||
                          this.state.isSaved ||
                          !this.state.proccessCheck.some((x) => x == true)
                        }
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        color="secondary"
                        variant="contained"
                        disabled={
                          isSubmitting ||
                          this.state.isSaved ||
                          !this.state.proccessCheck.some((x) => x == true)
                        }
                        onClick={() => this.save(true)}
                      >
                        Save and Close
                      </Button>
                    </Grid>
                  </Grid>
                </ResponsiveAppBar>
                <Form className={classes.form}>
                  <Grid container direction="column" spacing={1} wrap="nowrap">
                    <Grid item>
                      <CardWithTitle
                        title="Summary"
                        headerStyle={{ marginBottom: 0 }}
                        icon={AttachMoney}
                      >
                        <Grid
                          container
                          direction="row"
                          spacing={2}
                          style={{ paddingLeft: '8px' }}
                        >
                          <Grid item xs={12}>
                            {this.state.validationError && (
                              <Typography
                                noWrap
                                style={{ textAlign: 'right' }}
                                color="error"
                              >
                                <strong>
                                  All selected checks must have a check date and check
                                  number
                                </strong>
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={4}>
                            <strong>Checks Holding: </strong>
                            {this.state.pendingChecks.length}
                          </Grid>
                          <Grid item xs={4}>
                            <strong>Selected Checks: </strong>
                            {this.state.proccessCheck.filter((x) => x == true).length}
                          </Grid>
                          <Grid item xs={4}>
                            <strong>Checks Confirmed Today: </strong>
                            {this.state.checkCountProcessedToday}
                          </Grid>
                          <Grid item xs={4} style={{ alignItems: 'flex-start' }}>
                            <strong>Total: </strong>
                            <CurrencyFormat
                              value={this.state.pendingChecks.reduce(
                                (accumulator, x) => accumulator + x.checkAmount,
                                0
                              )}
                              thousandSeparator={true}
                              displayType={'text'}
                              prefix={'$'}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          </Grid>
                          <Grid item xs={4} style={{ alignItems: 'flex-start' }}>
                            <strong>Total: </strong>
                            <CurrencyFormat
                              value={this.state.selectedTotal}
                              thousandSeparator={true}
                              displayType={'text'}
                              prefix={'$'}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          </Grid>
                          <Grid item xs={4} style={{ alignItems: 'flex-start' }}>
                            <strong>Total: </strong>
                            <CurrencyFormat
                              value={this.state.checkTotalsProcessedToday}
                              thousandSeparator={true}
                              displayType={'text'}
                              prefix={'$'}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          </Grid>
                        </Grid>
                      </CardWithTitle>
                    </Grid>
                    <Grid item xs={12} spacing={2} className={classes.table}>
                      <Paper className={classes.paperTable}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Billing Information</TableCell>
                              <TableCell>Created</TableCell>
                              <TableCell>Created By</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>Claims Paid</TableCell>
                              <TableCell>Check Number*</TableCell>
                              <TableCell>Check Date*</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>{this.renderGrid()}</TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </>
    )
  }
}

const InjectedPendingChecks = inject<
  IStores,
  IPendingChecksProps,
  Partial<IPendingChecksProps>,
  any
>((stores: IStores) => ({
  previewEOB: stores.checks.previewEOB,
  rerouteToPath: stores.global.rerouteToPath,
  saveCheck: stores.checks.saveCheck,
  closeTab: stores.global.closeTab,
  setReturn: stores.global.setReturn,
}))(withStyles(styles)(PendingChecks))

export default InjectedPendingChecks
