import { DocumentCategory } from 'src/utils/DocumentCategory'

export interface IPatientDocumentDTO {
  patientId: string
  fileName: string
  mime: string
  content: string
  category: DocumentCategory
  date: Date
  description?: string
}

export class PatientDocumentDTO implements IPatientDocumentDTO {
  public patientId: string
  public fileName: string
  public mime: string
  public content: string
  public category: DocumentCategory
  public date: Date
  public description?: string

  constructor(
    patientId: string,
    fileName: string,
    mime: string,
    content: string,
    category: DocumentCategory,
    date: Date,
    description?: string
  ) {
    this.patientId = patientId
    this.fileName = fileName
    this.mime = mime
    this.content = content
    this.category = category
    this.date = date
    this.description = description
  }
}
