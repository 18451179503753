import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { CallMerge, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React, { ChangeEvent } from 'react'
import {
  DefaultProvider,
  IEpisodeOfCare,
  ILocation,
  IOrganization,
  IProvider,
  IUser,
} from 'src/Definitions'
import { IStores } from 'src/Stores'
import Moment from 'moment'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { Form, Formik, getIn } from 'formik'
import { ProviderTypeDto, IPhysicianDto, ITypeDto, TypeDto } from 'src/generated_client'
import { IProcedureFilter } from 'src/schedules/ProviderLookupDialog'
import { pagedList } from 'src/viewModels/pagedList'
import { ProviderBillingLocationSummaryResult } from 'src/viewModels/ProviderBillingLocationSummaryResult'
import FullWidthField from 'src/common/FullWidthField'
import TypeAheadField from 'src/common/TypeAheadField/TypeAheadField'
import * as Yup from 'yup'
import { ProfessionalComponentLineItemDTO } from 'src/viewModels/quotes/ProfessionalComponentLineItemDTO'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { TechnicalComponentLineItemDTO } from 'src/viewModels/quotes/TechnicalComponentLineItemDTO'
import { AnesthesiaLineItemDTO } from 'src/viewModels/quotes/AnesthesiaLineItemDTO'
import { PhysicalTherapyLineItemDTO } from 'src/viewModels/quotes/PhysicalTherapyLineItemDTO'
import { MiscellaneousLineItemDTO } from 'src/viewModels/quotes/MiscellaneousLineItemDTO'
import { QuoteCalculationRequest } from 'src/viewModels/QuoteCalculationRequest'
import { calculateLineItem } from 'src/services/QuoteService'
import { formatCurrency } from 'src/utils/Formatter'
import { ReconcileQuoteClaimDropdownOption } from 'src/viewModels/quotes/ReconcileQuoteClaimDropdownOption'
import {
  GetLocationById,
  GetReconcileQuoteClaimLineOptions,
} from 'src/services/LookupService'
import { CostSourceText } from './CostSourceText'
import QuoteTypeNameConstants from 'src/constants/QuoteTypeNameConstants'

const styles = ({ palette }: Theme) =>
  createStyles({
    quoteInfo: {
      paddingTop: '2px!important',
      paddingBottom: '2px!important',
    },
    quoteDetailContainer: {
      marginTop: '5px',
      marginBottom: '10px',
    },
    quoteDetailRow: {
      borderTop: '2px',
      borderTopColor: palette.secondary.main,
      borderTopStyle: 'solid',
      borderBottom: '1px',
      borderBottomColor: palette.grey[300],
      borderBottomStyle: 'ridge',
      borderLeft: '1px',
      borderLeftColor: palette.grey[300],
      borderLeftStyle: 'ridge',
      borderRight: '1px',
      borderRightColor: palette.grey[300],
      borderRightStyle: 'ridge',
      marginBottom: -1,
      minHeight: '32px',
      paddingLeft: '10px',
    },
  })
interface IAddQuoteLineModalProps extends WithStyles<typeof styles> {
  close?: ((reload: () => void) => void) | (() => void)
  isLoading?: boolean
  isOpen?: boolean
  quote?: IQuoteDTO
  isNum?: (str: any) => boolean

  addProviderLocation?: (scheduleId: string, locationId: string) => Promise<void>
  clearData?: () => Promise<void>
  clearLocations?: () => void
  currentAppOrganization?: IOrganization
  getAllProviderTypes?: () => Promise<void>
  getScheduleById?: (id: string) => Promise<void>
  lookupTitle?: string
  providerTypes?: ProviderTypeDto[]
  selectedEpisodeOfCare?: IEpisodeOfCare
  selectedProvider?: IProvider
  selectedLocation?: ILocation
  selectedProviderTypeId?: string
  setSelectedLocation?: (location?: ILocation) => void
  setSelectedProcedureFilters?: (procedureFilters?: IProcedureFilter[]) => void
  setSelectedProvider?: (provider?: IProvider) => void
  setSelectedProviderGroup?: (providerGroup?: string) => void
  setSelectedProviderLocation?: (locationName?: string) => void
  setSelectedProviderTypeId?: (selectedProviderTypeId: string) => void
  updateParentStateAfterModalEvent?: () => void
  updateParentStateAfterModal?: () => void
  addReconciledClaimLine?: (id: string) => void
  usedClaimLines?: Array<string>

  getPhysicians?: (filter: string, providerId: string) => Promise<void>
  physicians?: IPhysicianDto[]
  getTypes?: (filter: string) => Promise<void>
  types?: ITypeDto[]
  selectedType?: ITypeDto
  setSelectedType?: (selectedType?: ITypeDto) => void

  reloadParent?: () => void
  modalSwitch?: string
  user?: IUser
  // true for reconcile, false for add
  existingQuoteLineId?: string
  showAssistantWarning?: () => void
  claimDropdown?: Array<ReconcileQuoteClaimDropdownOption>
}

class ReconcileLineItemModal extends React.Component<IAddQuoteLineModalProps> {
  public setPhysicianValue = (fieldName: string, value: any) => {
    const newValue = value !== undefined ? value.id : ''
    this.secondFormRef.current.setFieldValue('physicianId', newValue)
    this.secondFormRef.current.setFieldValue(fieldName, value)
  }

  private getPhysicianName = (physician: IPhysicianDto) =>
    (physician ? physician.name : '')!
  private getPhysicianValue = (physician: IPhysicianDto) =>
    (physician ? physician.id : '')!

  private setSelectedType = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true

    var type = new TypeDto()
    type.id = event.target.selectedOptions[0].accessKey
    type.name = event.target.selectedOptions[0].value

    if (this.props.modalSwitch == 'AN') {
      this.secondFormRef.current!.state!.values!.monitored = undefined
      this.calculateLineCost()
    }

    this.props.setSelectedType!(type)
    this.assistantSetFields(type.name)
  }

  private setSelectedCostSource = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    this.setState({
      selectedCostSource: event.target.selectedOptions[0].value,
      warningCostSource: false,
    })
  }

  private setSelectedMultiDiscount = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    this.setState({ selectedMultiDiscount: event.target.selectedOptions[0].value }, () =>
      this.calculateLineCost()
    )
  }

  private setBilateral = async () => {
    this.setState({ bilateral: !this.state.bilateral }, () => this.calculateLineCost())
  }

  private calculateLineCost = async () => {
    this.setState({ cost: 'Calculating...' })
    const formikValues = this.secondFormRef.current?.state?.values
    const requestData = new QuoteCalculationRequest()

    requestData.multipleProcedureMultiplier = this.state.selectedMultiDiscount
    requestData.contractMultiplier =
      !this.props.isNum!(formikValues.contractMultiplier) &&
      formikValues.contractMultiplier != undefined
        ? undefined
        : formikValues.contractMultiplier
    requestData.actualCost =
      !this.props.isNum!(formikValues.actualCost) && formikValues.actualCost != undefined
        ? undefined
        : formikValues.actualCost
    requestData.bilateral = this.state.bilateral || false
    requestData.monitoredMultiplier =
      !this.props.isNum!(formikValues.monitored) && formikValues.monitored != undefined
        ? undefined
        : formikValues.monitored
    //Still need calc explanation, so just pass along override
    requestData.isRecon = true
    requestData.overrideCost =
      !this.props.isNum!(formikValues.overrideCost) &&
      formikValues.overrideCost != undefined
        ? undefined
        : formikValues.overrideCost

    await calculateLineItem(requestData).then((response: any) => {
      this.setState({
        cost: response.reconciledCost,
        calcText: response.reconciledCalculationExplanation,
        reconciledOverrideCost: response.reconciledOverrideCost,
      })
    })
  }

  private assistantSetFields(type?: string) {
    const formikValues = this.secondFormRef.current?.state?.values

    if (
      (type == undefined || type == 'Assistant') &&
      formikValues.assistant != undefined &&
      this.props.isNum!(formikValues.assistant)
    ) {
      formikValues.actualCost = undefined
      formikValues.contractMultiplier = undefined
      var select_box = document.getElementById('discountSelect') as HTMLSelectElement

      if (select_box != undefined) {
        select_box.selectedIndex = 0
      }

      this.setState({
        assistantPresent: true,
        bilateral: false,
        cost: 'Will Calculate on Save',
        selectedMultiDiscount: undefined,
      })
    } else {
      if (type != undefined && type != 'Assistant') {
        formikValues.assistant = undefined
      }
      this.setState({ assistantPresent: false })
      this.calculateLineCost()
    }
  }

  public changeSelectHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    var claimId = event?.target.selectedOptions[0].accessKey
    var line = this.props.claimDropdown?.find(
      (x) => x.id == event?.target.selectedOptions[0].accessKey
    )
    this.setState(
      {
        claimLineDropdown: await GetReconcileQuoteClaimLineOptions(claimId),
        claimId: claimId,
        groupNumber: line?.groupNumber,
        episodeOfCareNumber: line?.episodeOfCareNumber,
        providerName: line?.providerName,
        providerId: line?.providerId,
        disableLineSelect: false,
        warningClaim: false,
        claimLineId: undefined,
        lineNumber: undefined,
        reconciledCPT: undefined,
        reconciledBillCharges: undefined,
      },
      () => {
        this.selectProvider(
          line?.providerLocationId ?? '',
          line?.providerId ?? '',
          line?.providerName ?? '',
          line?.providerLocationName ?? ''
        )
        this.props.usedClaimLines?.forEach((lineId) => {
          this.state.claimLineDropdown?.forEach((claimLine) => {
            if (lineId == claimLine.id) {
              claimLine.isEnabled = false
            }
          })
        })
        this.setState({ claimLineDropdown: this.state.claimLineDropdown })
      }
    )
    var lineItemSelect = document.getElementById('lineSelect') as HTMLSelectElement
    if (lineItemSelect) {
      lineItemSelect.options[0].disabled = false
      lineItemSelect.selectedIndex = 0
    }
  }

  public changeLineHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    var line = this.state.claimLineDropdown.find(
      (x) => x.lineNumber?.toString() == event?.target.selectedOptions[0].accessKey
    )
    this.setState({
      claimLineId: line?.id,
      lineNumber: line?.lineNumber,
      reconciledCPT: line?.cpt,
      reconciledBillCharges: line?.billedCharges,
      warningClaimLine: false,
    })

    if (!this.props.existingQuoteLineId) {
      const formikValues = await this.getFormikValues()
      formikValues.actualCost = line?.billedCharges
      formikValues.cpt = line?.cpt
      this.calculateLineCost()
    }
  }

  public getDisplayClaim = async () => {
    const formikValues = await this.getFormikValues()
    if (this.state.claimId && this.state.lineNumber) {
      return (
        this.state.episodeOfCareNumber +
        '-' +
        this.state.groupNumber +
        ': ' +
        this.state.providerName +
        ' [' +
        this.state.lineNumber +
        ', CPT: ' +
        formikValues.cpt +
        ', Billed Charges: ' +
        formatCurrency((formikValues.actualCost ?? 0).toString()) +
        ']'
      )
    }
    return ''
  }

  public onReasonCodeChange(event: any) {
    this.setState({
      reasonCode: event.target.value,
      warningReasonCode: false,
    })
  }

  public handleAllowMultiSelect(_event: React.ChangeEvent, value: boolean) {
    this.setState({
      allowMultiSelect: value,
      selectedLines: new Map<string, string>(),
      claimLineId: undefined,
    })
  }

  private handleMultiSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (this.state.selectedLines.has(event.currentTarget.dataset.value!)) {
      this.state.selectedLines.delete(event.currentTarget.dataset.value!)
      this.setState({
        selectedLines: this.state.selectedLines,
        warningClaimLine: false,
      })
    } else {
      this.state.selectedLines.set(
        event.currentTarget.dataset.value!,
        event.currentTarget.dataset.name!
      )
      this.setState({
        selectedLines: this.state.selectedLines,
        warningClaimLine: false,
      })
    }
  }

  public render() {
    const { isOpen, quote } = this.props
    const quoteId = quote ? quote.id : ''
    const patientName = quote?.patientName
    const dob =
      quote?.dateOfBirth != undefined
        ? Moment(quote?.dateOfBirth).format('MM/DD/YYYY')
        : ''
    const surgery =
      quote?.surgeryDate != undefined
        ? Moment(quote?.surgeryDate).format('MM/DD/YYYY')
        : 'TBD'
    const body = quote?.bodyPartName != undefined ? quote?.bodyPartName : 'TBD'
    var QuoteLineSchema = undefined
    var modalTitle = ''
    var claimLabel = ''

    switch (this.props.modalSwitch) {
      case 'PC':
        if (this.state.assistantPresent) {
          QuoteLineSchema = Yup.object().shape({
            physician: Yup.string(),
            cpt: Yup.string(),
            assistant: Yup.number().typeError('Assistant must be a valid number'),
            description: Yup.string().required('Description is required'),
            costSource: Yup.number().required('Cost Source is required'),
          })
        } else {
          QuoteLineSchema = Yup.object().shape({
            physician: Yup.string(),
            cpt: Yup.string(),
            assistant: Yup.number().typeError('Assistant must be a valid number'),
            actualCost: Yup.number()
              .typeError('Billed charges must be a valid dollar amount')
              .required('Billed Charges is Required'),
            contractMultiplier: Yup.number().typeError(
              'Percentage of contract must be a valid dollar amount'
            ),
            description: Yup.string().required('Description is required'),
            costSource: Yup.number().required('Cost Source is required'),
            pcClaimSelect: Yup.string().required('Claim is required'),
            lineSelect: Yup.string().required('Claim Line is required'),
            overrideCost: Yup.number().typeError(
              'Reconciled Override $ must be a number'
            ),
          })
        }
        modalTitle = `${
          this.props.existingQuoteLineId ? '' : 'Add '
        }Reconcile Professional Component Line`
        claimLabel = 'PC Claims'
        break
      case 'TC':
        QuoteLineSchema = Yup.object().shape({
          cpt: Yup.string(),
          actualCost: Yup.number()
            .typeError('Billed Charges must be a valid dollar amount')
            .required('Billed Charges is Required'),
          contractMultiplier: Yup.number().typeError(
            'Percentage of contract must be a valid number'
          ),
          description: Yup.string().required('Description is required'),
          costSource: Yup.number().required('Cost Source is required'),
        })
        modalTitle = `${
          this.props.existingQuoteLineId ? '' : 'Add '
        }Reconcile Technical Component Line`
        claimLabel = 'TC Claims'
        break
      case 'AN':
        QuoteLineSchema = Yup.object().shape({
          cpt: Yup.string(),
          actualCost: Yup.number()
            .typeError('Billed Charges must be a valid dollar amount')
            .required('Billed Charges is Required'),
          contractMultiplier: Yup.number().typeError(
            'Percentage of contract must be a valid number'
          ),
          description: Yup.string().required('Description is required'),
          costSource: Yup.number().required('Cost Source is required'),
          monitored: Yup.number().typeError('Monitored AN must be a number'),
          overrideCost: Yup.number().typeError('Reconciled Override $ must be a number'),
        })
        modalTitle = `${
          this.props.existingQuoteLineId ? '' : 'Add '
        } Reconcile Anesthesiologist Component Line`
        claimLabel = 'AN Claims'
        break
      case 'PT':
        QuoteLineSchema = Yup.object().shape({
          numberOfVisits: Yup.number()
            .typeError('Number of Visits must be a number')
            .required('Number of Visits is required'),
          costPerVisit: Yup.number()
            .typeError("'Reconciled $' Per Visit must be a valid dollar amount")
            .required("'Reconciled $' per Visit is required"),
          cost: Yup.number()
            .typeError("'Reconciled $' must be a valid dollar amount")
            .required("'Reconciled $' is required"),
        })
        modalTitle = 'Add Reconcile Physical Therapy Line'
        break
      case 'MI':
        QuoteLineSchema = Yup.object().shape({
          cpt: Yup.string(),
          actualCost: Yup.number()
            .typeError('Billed Charges must be a valid dollar amount')
            .required("Actual 'Reconciled $' is Required"),
          contractMultiplier: Yup.number().typeError(
            'Percentage of contract must be a valid number'
          ),
          description: Yup.string().required('Description is required'),
          costSource: Yup.number().required('Cost Source is required'),
          type: Yup.string().required('Type is required'),
          lineDescription: Yup.string().required('Line Description is required'),
          overrideCost: Yup.number().typeError('Reconciled Override $ must be a number'),
        })
        modalTitle = `${
          this.props.existingQuoteLineId ? '' : 'Add '
        } Reconcile Miscellaneous Component Line`
        claimLabel = 'MI Claims'
        break
    }

    const MenuProps = {
      getContentAnchorEl: null,
      PaperProps: {
        style: {
          maxHeight: 400,
        },
      },
    }

    return (
      <Dialog key={quoteId} fullWidth={true} maxWidth="xl" open={isOpen!}>
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar title={modalTitle} pageIcon={<CallMerge />} isModal={true} />
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ paddingTop: 12, paddingBottom: 8 }}
          >
            <Grid container item spacing={3} xs={12} wrap="nowrap">
              <Grid item>
                <strong>{patientName}</strong>
              </Grid>
              <Grid item>
                <strong>DOB:</strong> {dob}
              </Grid>
              <Grid item>
                <strong>Quote Type:</strong>{' '}
                {quote?.type != undefined ? QuoteTypeNameConstants[quote?.type] : ''}
              </Grid>
              <Grid item>
                <strong>Surgery:</strong> {surgery}
              </Grid>
              <Grid item>
                <strong>Body Part:</strong> {body}
              </Grid>
            </Grid>
            {this.props.existingQuoteLineId ? (
              <Grid container item xs={12} spacing={2}>
                {this.renderSelectedLineData()}
              </Grid>
            ) : (
              <></>
            )}
            {this.props.modalSwitch == 'TC' &&
              this.props.existingQuoteLineId == undefined && (
                <Grid container xs={12} justifyContent="flex-end">
                  <Grid item>
                    <FormControl>
                      <FormControlLabel
                        label="Allow Multiple Selection"
                        labelPlacement="end"
                        control={
                          <Checkbox
                            checked={this.state.allowMultiSelect}
                            onChange={this.handleAllowMultiSelect}
                            inputProps={{
                              id: 'allow-multi-select',
                            }}
                            value="allowMultiSelect"
                            name="allowMultiSelect"
                          />
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            {this.props.existingQuoteLineId ? (
              <Grid item xs={12}>
                <Formik
                  initialValues={{ overrideCost: '' }}
                  onSubmit={() => {}}
                  validationSchema={QuoteLineSchema}
                  ref={this.simpleReconFormRef}
                >
                  <Form id="simpleRecon">
                    <Grid container item xs={12} spacing={1}>
                      <Grid item lg={5}>
                        <FormControl style={{ width: '100%' }} variant="outlined">
                          <InputLabel shrink={true} variant="outlined">
                            {claimLabel} *
                          </InputLabel>
                          <Select
                            variant="outlined"
                            input={<OutlinedInput notched labelWidth={80} />}
                            inputProps={{ label: true, notched: true }}
                            native={true}
                            fullWidth
                            onChange={this.changeSelectHandler}
                            id="pcClaimSelect"
                          >
                            <option value="">Please select a claim</option>
                            {this.props.claimDropdown?.map(
                              (item: ReconcileQuoteClaimDropdownOption) => (
                                <option
                                  accessKey={item.id}
                                  value={item.description}
                                  disabled={!item.isEnabled}
                                >
                                  {item.description}
                                </option>
                              )
                            )}
                          </Select>
                        </FormControl>
                        {this.state.warningClaim && (
                          <FormHelperText style={{ color: 'red' }}>
                            Claim is Required
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item lg={5}>
                        <FormControl style={{ width: '100%' }} variant="outlined">
                          <InputLabel shrink={true} variant="outlined">
                            Claim Line Item *
                          </InputLabel>
                          <Select
                            variant="outlined"
                            input={<OutlinedInput notched labelWidth={120} />}
                            inputProps={{ label: true, notched: true }}
                            native={true}
                            fullWidth
                            disabled={this.state.disableLineSelect}
                            onChange={this.changeLineHandler}
                            id="lineSelect"
                          >
                            <option value="">Please select claim line</option>
                            {this.state.claimLineDropdown?.map(
                              (item: ReconcileQuoteClaimDropdownOption) => (
                                <option
                                  accessKey={item.lineNumber?.toString()}
                                  value={item.description}
                                  disabled={!item.isEnabled}
                                >
                                  {item.description}
                                </option>
                              )
                            )}
                          </Select>
                        </FormControl>
                        {this.state.warningClaimLine && (
                          <FormHelperText style={{ color: 'red' }}>
                            Claim Line is Required
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item lg={2}>
                        <FullWidthField
                          label="Reconciled $ *"
                          name="overrideCost"
                          onKeyUp={() => this.setState({ warningReconVal: false })}
                          variant="outlined"
                        />
                        {this.state.warningReconVal && (
                          <FormHelperText style={{ color: 'red' }}>
                            Reconciled $ is Required
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Form>
                </Formik>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{ cpt: '', costSourceSelect: '' }}
              onSubmit={() => {}}
              validationSchema={QuoteLineSchema}
              ref={this.secondFormRef}
            >
              {({ values }) => (
                <Form id="secondForm">
                  {!this.props.existingQuoteLineId &&
                  (this.props.modalSwitch == 'PC' || this.props.modalSwitch == 'TC') ? (
                    <Grid item xs={12}>
                      <Grid spacing={2} justifyContent="space-between">
                        <Grid
                          item
                          container
                          style={{ margin: '10px 0px' }}
                          xs={12}
                          spacing={2}
                        >
                          <Grid item xs={8}>
                            <FormControl style={{ width: '100%' }} variant="outlined">
                              <InputLabel shrink={true} variant="outlined">
                                {claimLabel} *
                              </InputLabel>
                              <Select
                                variant="outlined"
                                input={<OutlinedInput notched labelWidth={80} />}
                                inputProps={{ label: true, notched: true }}
                                native={true}
                                fullWidth
                                required
                                onChange={this.changeSelectHandler}
                                id="pcClaimSelect"
                              >
                                <option value="">Please select a claim</option>
                                {this.props.claimDropdown?.map(
                                  (item: ReconcileQuoteClaimDropdownOption) => (
                                    <option
                                      accessKey={item.id}
                                      value={item.description}
                                      disabled={!item.isEnabled}
                                    >
                                      {item.description}
                                    </option>
                                  )
                                )}
                              </Select>
                            </FormControl>
                            {this.state.warningClaim && (
                              <FormHelperText style={{ color: 'red' }}>
                                Claim is Required
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={4}>
                            {this.state.allowMultiSelect ? (
                              <>
                                {this.state.claimLineDropdown && (
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel shrink={true} variant="outlined">
                                      Claim Line Items *
                                    </InputLabel>
                                    <Select
                                      variant="outlined"
                                      input={<OutlinedInput notched labelWidth={132} />}
                                      inputProps={{
                                        label: true,
                                        notched: true,
                                      }}
                                      fullWidth
                                      id="claimLineItems"
                                      multiple
                                      disabled={this.state.disableLineSelect}
                                      value={Array.from(this.state.selectedLines.keys())}
                                      renderValue={() =>
                                        Array.from(
                                          this.state.selectedLines.values()
                                        ).join(', ')
                                      }
                                      onChange={this.handleMultiSelectChange}
                                      MenuProps={MenuProps}
                                    >
                                      {this.state.claimLineDropdown.map((item) => (
                                        <MenuItem
                                          value={item.id}
                                          data-name={item.description}
                                          disabled={!item.isEnabled}
                                        >
                                          {item.id &&
                                          this.state.selectedLines.has(item.id) ? (
                                            <CheckBox
                                              color="secondary"
                                              style={{ paddingRight: '10px' }}
                                            />
                                          ) : (
                                            <CheckBoxOutlineBlank
                                              color="action"
                                              style={{ paddingRight: '10px' }}
                                            />
                                          )}{' '}
                                          {item.description}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                )}
                              </>
                            ) : (
                              <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Claim Line Item *
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={123} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  required
                                  disabled={this.state.disableLineSelect}
                                  onChange={this.changeLineHandler}
                                  id="lineSelect"
                                >
                                  <option value="">Please select claim line</option>
                                  {this.state.claimLineDropdown?.map(
                                    (item: ReconcileQuoteClaimDropdownOption) => (
                                      <option
                                        accessKey={item.lineNumber?.toString()}
                                        value={item.description}
                                        disabled={!item.isEnabled}
                                      >
                                        {item.description}
                                      </option>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            )}
                            {this.state.warningClaimLine && (
                              <FormHelperText style={{ color: 'red' }}>
                                Claim Line is Required
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid spacing={2} justifyContent="space-between">
                    {this.props.modalSwitch == 'PC' && !this.props.existingQuoteLineId ? (
                      <>
                        <Grid
                          item
                          container
                          style={{ margin: '10px 0px' }}
                          xs={12}
                          spacing={2}
                        >
                          <Grid item xs={6} md={3}>
                            <TypeAheadField
                              outlined
                              name="physician"
                              label="Physician"
                              cypressLabel="Physician"
                              fullWidth
                              setFieldValue={this.setPhysicianValue}
                              selectedValue={getIn(values, 'physician')}
                              items={this.props.physicians}
                              disabled={this.state.providerId == ''}
                              getName={this.getPhysicianName}
                              getValue={this.getPhysicianValue}
                              getItems={() =>
                                this.props.getPhysicians!(
                                  '',
                                  this.props.selectedProvider?.id!
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={6} md={3}>
                            {this.props.types ? (
                              <FormControl fullWidth variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Type
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={38} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  onChange={this.setSelectedType}
                                  id="type"
                                >
                                  <option value="">Select a type</option>
                                  {this.props.types.map((item: TypeDto) => (
                                    <option
                                      accessKey={item.id}
                                      value={item.name}
                                      disabled={false}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                                </Select>{' '}
                              </FormControl>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          {this.props.selectedType?.name == 'Assistant' ? (
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="Assistant Surgeon %"
                                name="assistant"
                                variant="outlined"
                                onKeyUp={() => this.assistantSetFields()}
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="CPT"
                              name="cpt"
                              variant="outlined"
                              disabled={this.state.claimLineId != undefined}
                            />
                          </Grid>
                          <Grid item xs={6} md={3} lg={2}>
                            <FormControl fullWidth>
                              <FormControlLabel
                                label="Bilateral"
                                labelPlacement="end"
                                onChange={() => {
                                  this.setBilateral()
                                }}
                                disabled={this.state.assistantPresent}
                                control={
                                  <Checkbox
                                    checked={this.state.bilateral}
                                    inputProps={{
                                      id: 'bilateral',
                                    }}
                                    value={this.state.bilateral}
                                    name="bilateral"
                                  />
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} md={3} lg={4}>
                            <FormControl style={{ width: '100%' }} variant="outlined">
                              <InputLabel shrink={true} variant="outlined">
                                Cost Source *
                              </InputLabel>
                              <Select
                                variant="outlined"
                                input={<OutlinedInput notched labelWidth={96} />}
                                inputProps={{ label: true, notched: true }}
                                native={true}
                                fullWidth
                                required
                                onChange={this.setSelectedCostSource}
                                id="costSourceSelect"
                              >
                                <option value="">Select a cost source</option>
                                {Object.keys(CostSourceText).map((key) => (
                                  <option accessKey={key} value={key}>
                                    {CostSourceText[key]}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                            {this.state.warningCostSource && (
                              <FormHelperText style={{ color: 'red' }}>
                                Cost Source is Required
                              </FormHelperText>
                            )}
                          </Grid>
                          {CostSourceText[this.state.selectedCostSource ?? 0] ==
                          'Other' ? (
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="Description"
                                name="description"
                                variant="outlined"
                                onKeyUp={() =>
                                  this.setState({
                                    warningOtherDescription: false,
                                  })
                                }
                                required
                              />
                              {this.state.warningOtherDescription && (
                                <FormHelperText style={{ color: 'red' }}>
                                  Description is Required
                                </FormHelperText>
                              )}
                            </Grid>
                          ) : (
                            <></>
                          )}
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="Billed Charges"
                              name="actualCost"
                              variant="outlined"
                              onKeyUp={() => {
                                this.calculateLineCost()
                                this.setState({ warningBilledCharges: false })
                              }}
                              disabled={
                                this.state.assistantPresent ||
                                this.state.claimLineId != undefined
                              }
                              required={!this.state.assistantPresent}
                            />
                            {this.state.warningBilledCharges && (
                              <FormHelperText style={{ color: 'red' }}>
                                Billed Charges is Required
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="% of Contract"
                              name="contractMultiplier"
                              variant="outlined"
                              disabled={this.state.assistantPresent}
                              onKeyUp={() => this.calculateLineCost()}
                            />
                          </Grid>
                          <Grid item xs={6} md={2}>
                            {this.props.types ? (
                              <FormControl fullWidth variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  MPPD
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={45} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  onChange={this.setSelectedMultiDiscount}
                                  disabled={this.state.assistantPresent}
                                  id="eventSelect"
                                >
                                  <option value="">Select a MPPD</option>
                                  <option value="100">100%</option>
                                  <option value="50">50%</option>
                                  <option value="25">25%</option>
                                </Select>
                              </FormControl>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <strong>Reconciled $:</strong>{' '}
                            {this.props.isNum!(this.state.cost) ||
                            this.state.cost == undefined
                              ? formatCurrency((this.state.cost ?? 0).toString())
                              : this.state.cost.toString()}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="Reconciled Override $"
                              name="overrideCost"
                              variant="outlined"
                              onKeyUp={() => this.calculateLineCost()}
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    {this.props.modalSwitch == 'TC' && !this.props.existingQuoteLineId ? (
                      <>
                        <Grid
                          item
                          container
                          style={{ margin: '10px 0px' }}
                          xs={12}
                          spacing={2}
                        >
                          <Grid item xs={6} md={3}>
                            {this.props.types ? (
                              <FormControl fullWidth variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Type
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={38} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  onChange={this.setSelectedType}
                                  id="type"
                                >
                                  <option value="">Select a type</option>
                                  {this.props.types.map((item: TypeDto) => (
                                    <option
                                      accessKey={item.id}
                                      value={item.name}
                                      disabled={false}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                                </Select>{' '}
                              </FormControl>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="CPT"
                              name="cpt"
                              variant="outlined"
                              disabled={
                                this.state.claimLineId != undefined ||
                                this.state.allowMultiSelect
                              }
                            />
                          </Grid>
                          <Grid item xs={6} md={5} lg={6}>
                            <FormControl fullWidth>
                              <FormControlLabel
                                label="Bilateral"
                                labelPlacement="end"
                                disabled={this.state.allowMultiSelect}
                                onChange={() => {
                                  this.setBilateral()
                                }}
                                control={
                                  <Checkbox
                                    checked={this.state.bilateral}
                                    inputProps={{
                                      id: 'bilateral',
                                    }}
                                    value={this.state.bilateral}
                                    name="bilateral"
                                  />
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} md={3} lg={3}>
                            <FormControl style={{ width: '100%' }} variant="outlined">
                              <InputLabel shrink={true} variant="outlined">
                                Cost Source*
                              </InputLabel>
                              <Select
                                variant="outlined"
                                input={<OutlinedInput notched labelWidth={96} />}
                                inputProps={{ label: true, notched: true }}
                                native={true}
                                fullWidth
                                required
                                onChange={this.setSelectedCostSource}
                                id="costSourceSelect"
                              >
                                <option value="">Select a cost source</option>
                                {Object.keys(CostSourceText).map((key) => (
                                  <option accessKey={key} value={key}>
                                    {CostSourceText[key]}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                            {this.state.warningCostSource && (
                              <FormHelperText style={{ color: 'red' }}>
                                Cost Source is Required
                              </FormHelperText>
                            )}
                          </Grid>
                          {CostSourceText[this.state.selectedCostSource ?? 0] ==
                          'Other' ? (
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="Description"
                                name="description"
                                variant="outlined"
                                onKeyUp={() =>
                                  this.setState({
                                    warningOtherDescription: false,
                                  })
                                }
                                required
                              />
                              {this.state.warningOtherDescription && (
                                <FormHelperText style={{ color: 'red' }}>
                                  Description is Required
                                </FormHelperText>
                              )}
                            </Grid>
                          ) : (
                            <></>
                          )}
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="Billed Charges"
                              name="actualCost"
                              variant="outlined"
                              onKeyUp={() => {
                                this.calculateLineCost()
                                this.setState({ warningBilledCharges: false })
                              }}
                              disabled={
                                this.state.claimLineId != undefined ||
                                this.state.allowMultiSelect
                              }
                              required={!this.state.allowMultiSelect}
                            />
                            {this.state.warningBilledCharges &&
                              !this.state.allowMultiSelect && (
                                <FormHelperText style={{ color: 'red' }}>
                                  Billed Charges is Required
                                </FormHelperText>
                              )}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="% of Contract"
                              name="contractMultiplier"
                              variant="outlined"
                              onKeyUp={() => this.calculateLineCost()}
                              disabled={this.state.allowMultiSelect}
                            />
                          </Grid>
                          <Grid item xs={6} md={2}>
                            {this.props.types ? (
                              <FormControl fullWidth variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  MPPD
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={45} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  onChange={this.setSelectedMultiDiscount}
                                  id="eventSelect"
                                  disabled={this.state.allowMultiSelect}
                                >
                                  <option value="">Select a MPPD</option>
                                  <option value="100">100%</option>
                                  <option value="50">50%</option>
                                  <option value="25">25%</option>
                                </Select>
                              </FormControl>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <strong>Reconciled $:</strong>{' '}
                            {this.props.isNum!(this.state.cost) ||
                            this.state.cost == undefined
                              ? formatCurrency((this.state.cost ?? 0).toString())
                              : this.state.cost.toString()}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="Reconciled Override $"
                              name="overrideCost"
                              variant="outlined"
                              onKeyUp={() => this.calculateLineCost()}
                              required={this.state.allowMultiSelect}
                            />
                            {this.state.warningReconVal &&
                              this.state.allowMultiSelect && (
                                <FormHelperText style={{ color: 'red' }}>
                                  Reconciled $ is Required
                                </FormHelperText>
                              )}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    {this.props.modalSwitch == 'AN' && !this.props.existingQuoteLineId ? (
                      <>
                        <Grid
                          item
                          container
                          style={{ margin: '10px 0px' }}
                          xs={12}
                          spacing={2}
                        >
                          <Grid item xs={6} md={3}>
                            <TypeAheadField
                              outlined
                              disabled={this.state.providerId == ''}
                              name="physician"
                              label="Physician"
                              cypressLabel="Physician"
                              fullWidth
                              setFieldValue={this.setPhysicianValue}
                              selectedValue={getIn(values, 'physician')}
                              items={this.props.physicians}
                              getName={this.getPhysicianName}
                              getValue={this.getPhysicianValue}
                              getItems={() =>
                                this.props.getPhysicians!(
                                  '',
                                  this.props.selectedProvider?.id!
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={6} md={3}>
                            {this.props.types ? (
                              <FormControl fullWidth variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Type
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={38} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  onChange={this.setSelectedType}
                                  id="type"
                                >
                                  <option value="">Select a type</option>
                                  {this.props.types.map((item: TypeDto) => (
                                    <option
                                      accessKey={item.id}
                                      value={item.name}
                                      disabled={false}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                                </Select>{' '}
                              </FormControl>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          {this.props.selectedType?.name == 'General/MAC' ? (
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="# Units"
                                name="monitored"
                                variant="outlined"
                                onKeyUp={() => this.calculateLineCost()}
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="CPT"
                              name="cpt"
                              variant="outlined"
                              value="12232"
                              disabled
                            />
                          </Grid>
                          <Grid item xs={6} md={3} lg={2}>
                            <FormControl fullWidth>
                              <FormControlLabel
                                label="Bilateral"
                                labelPlacement="end"
                                onChange={() => {
                                  this.setBilateral()
                                }}
                                control={
                                  <Checkbox
                                    checked={this.state.bilateral}
                                    inputProps={{
                                      id: 'bilateral',
                                    }}
                                    value={this.state.bilateral}
                                    name="bilateral"
                                  />
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} md={3} lg={4}>
                            <FormControl style={{ width: '100%' }} variant="outlined">
                              <InputLabel shrink={true} variant="outlined">
                                'Cost Source *
                              </InputLabel>
                              <Select
                                variant="outlined"
                                input={<OutlinedInput notched labelWidth={96} />}
                                inputProps={{ label: true, notched: true }}
                                native={true}
                                fullWidth
                                required
                                onChange={this.setSelectedCostSource}
                                id="costSourceSelect"
                              >
                                <option value="">Select a cost source</option>
                                {Object.keys(CostSourceText).map((key) => (
                                  <option accessKey={key} value={key}>
                                    {CostSourceText[key]}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                            {this.state.warningCostSource && (
                              <FormHelperText style={{ color: 'red' }}>
                                Cost Source is Required
                              </FormHelperText>
                            )}
                          </Grid>
                          {CostSourceText[this.state.selectedCostSource ?? 0] ==
                          'Other' ? (
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="Description"
                                name="description"
                                variant="outlined"
                                onKeyUp={() =>
                                  this.setState({
                                    warningOtherDescription: false,
                                  })
                                }
                                required
                              />
                              {this.state.warningOtherDescription && (
                                <FormHelperText style={{ color: 'red' }}>
                                  Description is Required
                                </FormHelperText>
                              )}
                            </Grid>
                          ) : (
                            <></>
                          )}
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="Billed Charges"
                              name="actualCost"
                              variant="outlined"
                              onKeyUp={() => {
                                this.calculateLineCost()
                                this.setState({ warningBilledCharges: false })
                              }}
                              required
                            />
                            {this.state.warningBilledCharges && (
                              <FormHelperText style={{ color: 'red' }}>
                                Billed Charges is Required
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="% of Contract"
                              name="contractMultiplier"
                              variant="outlined"
                              onKeyUp={() => this.calculateLineCost()}
                            />
                          </Grid>
                          <Grid item xs={6} md={2}>
                            {this.props.types ? (
                              <FormControl fullWidth variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  MPPD
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={45} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  onChange={this.setSelectedMultiDiscount}
                                  id="eventSelect"
                                >
                                  <option value="">Select a MPPD</option>
                                  <option value="100">100%</option>
                                  <option value="50">50%</option>
                                  <option value="25">25%</option>
                                </Select>
                              </FormControl>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <strong>Reconciled $:</strong>{' '}
                            {this.props.isNum!(this.state.cost) ||
                            this.state.cost == undefined
                              ? formatCurrency((this.state.cost ?? 0).toString())
                              : this.state.cost.toString()}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="Reconciled Override $"
                              name="test"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    {this.props.modalSwitch == 'PT' ? (
                      <>
                        <Grid
                          container
                          item
                          xs={12}
                          spacing={2}
                          style={{ margin: '0px' }}
                        >
                          <Grid item xs={6}>
                            <FullWidthField
                              label="Est. U&C Per Visit"
                              name="actualCostPerVisit"
                              variant="outlined"
                              onKeyUp={() => {
                                this.setState({
                                  warningActualCostPerVisit: false,
                                })
                              }}
                              required
                            />
                            {this.state.warningActualCostPerVisit && (
                              <FormHelperText style={{ color: 'red' }}>
                                Est. U&C Per Visit is Required
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <FullWidthField
                              label="Quoted Per Visit"
                              name="costPerVisit"
                              variant="outlined"
                              onKeyUp={() => {
                                this.calculateCost()
                                this.setState({ warningCostPerVisit: false })
                              }}
                              required
                            />
                            {this.state.warningCostPerVisit && (
                              <FormHelperText style={{ color: 'red' }}>
                                Quoted Per Visit is Required
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <FullWidthField
                              label="Number of Visits"
                              name="numberOfVisits"
                              variant="outlined"
                              onKeyUp={() => {
                                this.calculateCost()
                                this.setState({ warningNumberOfVisits: false })
                              }}
                              required
                            />
                            {this.state.warningNumberOfVisits && (
                              <FormHelperText style={{ color: 'red' }}>
                                Number of Visits is Required
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <strong>Cost:</strong>{' '}
                            {this.props.isNum!(this.state.cost) ||
                            this.state.cost == undefined
                              ? formatCurrency((this.state.cost ?? 0).toString())
                              : this.state.cost.toString()}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    {this.props.modalSwitch == 'MI' && !this.props.existingQuoteLineId ? (
                      <>
                        <Grid
                          item
                          container
                          style={{ margin: '10px 0px' }}
                          xs={12}
                          spacing={2}
                        >
                          <Grid item xs={6} md={3}>
                            {this.props.types ? (
                              <>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel shrink={true} variant="outlined">
                                    Type *
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    input={<OutlinedInput notched labelWidth={44} />}
                                    inputProps={{ label: true, notched: true }}
                                    native={true}
                                    fullWidth
                                    onChange={this.setSelectedType}
                                    id="type"
                                    required
                                  >
                                    <option value="">Select a type</option>
                                    {this.props.types.map((item: TypeDto) => (
                                      <option
                                        accessKey={item.id}
                                        value={item.name}
                                        disabled={false}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </Select>{' '}
                                </FormControl>
                                {this.state.typeMissing && (
                                  <Grid
                                    container
                                    justifyContent="flex-start"
                                    className="error modal-form-error"
                                  >
                                    Type is Required
                                  </Grid>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          <Grid item xs={6} md={4} lg={4}>
                            <FullWidthField
                              label="Line Description"
                              name="lineDescription"
                              variant="outlined"
                              onKeyUp={this.setState({
                                warningMiscDescription: false,
                              })}
                              required
                            />
                            {this.state.warningMiscDescription && (
                              <FormHelperText style={{ color: 'red' }}>
                                Line Description is Required
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="CPT"
                              name="cpt"
                              variant="outlined"
                              disabled={false}
                            />
                          </Grid>
                          <Grid item xs={6} md={3} lg={2}>
                            <FormControl fullWidth>
                              <FormControlLabel
                                label="Bilateral"
                                labelPlacement="end"
                                onChange={() => {
                                  this.setBilateral()
                                }}
                                control={
                                  <Checkbox
                                    checked={this.state.bilateral}
                                    inputProps={{
                                      id: 'bilateral',
                                    }}
                                    value={this.state.bilateral}
                                    name="bilateral"
                                  />
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} md={3} lg={4}>
                            <FormControl style={{ width: '100%' }} variant="outlined">
                              <InputLabel shrink={true} variant="outlined">
                                Cost Source *
                              </InputLabel>
                              <Select
                                variant="outlined"
                                input={<OutlinedInput notched labelWidth={96} />}
                                inputProps={{ label: true, notched: true }}
                                native={true}
                                fullWidth
                                required
                                onChange={this.setSelectedCostSource}
                                id="costSourceSelect"
                              >
                                <option value="">Select a cost source</option>
                                {Object.keys(CostSourceText).map((key) => (
                                  <option accessKey={key} value={key}>
                                    {CostSourceText[key]}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                            {this.state.warningCostSource && (
                              <FormHelperText style={{ color: 'red' }}>
                                Cost Source is Required
                              </FormHelperText>
                            )}
                          </Grid>
                          {CostSourceText[this.state.selectedCostSource ?? 0] ==
                          'Other' ? (
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="Description"
                                name="description"
                                variant="outlined"
                                onKeyUp={() =>
                                  this.setState({
                                    warningOtherDescription: false,
                                  })
                                }
                                required
                              />
                              {this.state.warningOtherDescription && (
                                <FormHelperText style={{ color: 'red' }}>
                                  Description is Required
                                </FormHelperText>
                              )}
                            </Grid>
                          ) : (
                            <></>
                          )}
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="Billed Charges"
                              name="actualCost"
                              variant="outlined"
                              onKeyUp={() => {
                                this.calculateLineCost()
                                this.setState({ warningBilledCharges: false })
                              }}
                              required
                            />
                            {this.state.warningBilledCharges && (
                              <FormHelperText style={{ color: 'red' }}>
                                Billed Charges is Required
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="% of Contract"
                              name="contractMultiplier"
                              variant="outlined"
                              onKeyUp={() => this.calculateLineCost()}
                            />
                          </Grid>
                          <Grid item xs={6} md={2}>
                            {this.props.types ? (
                              <FormControl fullWidth variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  MPPD
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={45} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  onChange={this.setSelectedMultiDiscount}
                                  id="eventSelect"
                                >
                                  <option value="">Select a MPPD</option>
                                  <option value="100">100%</option>
                                  <option value="50">50%</option>
                                  <option value="25">25%</option>
                                </Select>
                              </FormControl>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <strong>Reconciled $:</strong>{' '}
                            {this.props.isNum!(this.state.cost) ||
                            this.state.cost == undefined
                              ? formatCurrency((this.state.cost ?? 0).toString())
                              : this.state.cost.toString()}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <FullWidthField
                              label="Reconciled Override $"
                              name="test"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignItems: 'flex-end' }}>
          {this.props.modalSwitch != 'PT' && (
            <Grid xs={2}>
              <TextField
                label="Reason Code"
                name={`reasonCode`}
                id={`reasonCode`}
                variant="outlined"
                style={{
                  display:
                    this.state.claimLineId == undefined &&
                    this.props.modalSwitch != 'PC' &&
                    this.props.modalSwitch != 'TC'
                      ? 'none'
                      : 'inherit',
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                onChange={(e: any) => this.onReasonCodeChange(e)}
              />
              {this.state.warningReasonCode && (
                <FormHelperText
                  style={{
                    color: 'red',
                    paddingRight: '4px',
                    textAlign: 'right',
                  }}
                >
                  Reason Code is Required
                </FormHelperText>
              )}
            </Grid>
          )}
          {this.props.existingQuoteLineId &&
          (this.props.modalSwitch == 'AN' || this.props.modalSwitch == 'MI') ? (
            <>
              <Button
                variant="contained"
                form={this.props.existingQuoteLineId ? 'simpleRecon' : 'secondForm'}
                color="primary"
                type="submit"
                disabled={this.state.claimLineId != undefined}
                onClick={() => this.saveReconNoClaim()}
              >
                Reconcile w/No Claim
              </Button>
              &nbsp;&nbsp;
            </>
          ) : (
            <></>
          )}
          {this.props.existingQuoteLineId ||
          this.props.modalSwitch == 'PC' ||
          this.props.modalSwitch == 'TC' ? (
            <>
              <Button
                variant="contained"
                form={this.props.existingQuoteLineId ? 'simpleRecon' : 'secondForm'}
                color="primary"
                type="submit"
                onClick={
                  this.props.existingQuoteLineId
                    ? () => this.saveRecon()
                    : () => this.save()
                }
              >
                {this.props.existingQuoteLineId ? 'Reconcile' : 'Add'}
              </Button>
            </>
          ) : this.props.modalSwitch != 'PC' &&
            this.props.modalSwitch != 'TC' &&
            this.state.totalItems == 0 ? (
            <Button
              variant="contained"
              style={{ margin: '0px 4px' }}
              onClick={() => {
                this.setState({
                  modalPage: this.state.modalPage + 1,
                  selectedCostSource: undefined,
                  selectedMultiDiscount: undefined,
                  bilateral: false,
                  cost: '',
                })
              }}
            >
              Skip
            </Button>
          ) : (
            <></>
          )}
          <Button onClick={this.close} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  public state = {
    modalPage: 1,
    providerName: '',
    providerId: '',
    gridData: new pagedList().items as ProviderBillingLocationSummaryResult[],
    providerGroup: '',
    providerLocation: '',
    providerTypeId: '',
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 25,
    selectedMultiDiscount: undefined,
    selectedCostSource: undefined,
    bilateral: false,
    cost: '',
    calcText: undefined,
    claimId: undefined,
    assistantPresent: false,
    claimLineDropdown: Array<ReconcileQuoteClaimDropdownOption>(),
    selectedLines: new Map<string, string>(),
    groupNumber: '',
    lineNumber: undefined,
    claimLineId: undefined,
    reconciledOverrideCost: undefined,
    disableLineSelect: true,
    episodeOfCareNumber: undefined,
    reconciledCPT: undefined,
    reconciledBillCharges: undefined,
    reasonCode: '',
    warningBilledCharges: false,
    warningClaim: false,
    warningClaimLine: false,
    warningCostSource: false,
    warningOtherDescription: false,
    warningCostPerVisit: false,
    warningActualCostPerVisit: false,
    warningNumberOfVisits: false,
    warningMiscDescription: false,
    warningReconVal: false,
    warningReasonCode: false,
    typeMissing: false,
    allowMultiSelect: false,
  }

  public secondFormRef = React.createRef<any>()
  public simpleReconFormRef = React.createRef<any>()

  private validateReconRequiredFields = (formikValues2: any) => {
    if (this.state.claimId == undefined) {
      this.setState({ warningClaim: true })
    }

    if (this.state.claimLineId == undefined) {
      this.setState({ warningClaimLine: true })
    }

    if (!this.props.isNum!(formikValues2.overrideCost)) {
      this.setState({ warningReconVal: true })
    }

    if (this.state.reasonCode == '') {
      this.setState({ warningReasonCode: true })
    }
  }

  private validateReconNoClaimFields = (formikValues2: any) => {
    if (!this.props.isNum!(formikValues2.overrideCost)) {
      this.setState({ warningReconVal: true })
    }
  }

  private saveRecon = async () => {
    const formikValues2 = await this.getFormikValues2()

    await this.calculateLineCost()

    this.validateReconRequiredFields(formikValues2)

    var selectedLine
    switch (this.props.modalSwitch) {
      case 'PC':
        if (
          this.state.claimId == undefined ||
          this.state.claimLineId == undefined ||
          formikValues2.overrideCost == undefined ||
          !this.props.isNum!(formikValues2.overrideCost) ||
          this.state.reasonCode == ''
        ) {
          return
        }

        if (this.state.claimLineId != undefined) {
          this.props.addReconciledClaimLine!(this.state.claimLineId)
        }

        selectedLine = this.props.quote!.professionalComponentLineItems?.filter(
          (x) => x.id == this.props.existingQuoteLineId
        )
        if (selectedLine && selectedLine?.length == 1) {
          selectedLine[0]!.reconciledCost = formikValues2.overrideCost
          selectedLine[0]!.reconciledActualCost = this.state.reconciledBillCharges
          selectedLine[0]!.reconciledActualLocationGroupName = this.state.providerName
          selectedLine[0]!.reconciledActualCPT = this.state.reconciledCPT
          selectedLine[0]!.reconciledCMS1500LineItemId = this.state.claimLineId
          selectedLine[0]!.reasonCode = this.state.reasonCode
        }
        break
      case 'TC':
        if (
          this.state.claimId == undefined ||
          this.state.claimLineId == undefined ||
          formikValues2.overrideCost == undefined ||
          !this.props.isNum!(formikValues2.overrideCost) ||
          this.state.reasonCode == ''
        ) {
          return
        }

        if (this.state.claimLineId != undefined) {
          this.props.addReconciledClaimLine!(this.state.claimLineId)
        }

        selectedLine = this.props.quote!.technicalComponentLineItems?.filter(
          (x) => x.id == this.props.existingQuoteLineId
        )
        if (selectedLine && selectedLine?.length == 1) {
          selectedLine[0]!.reconciledCost = formikValues2.overrideCost
          selectedLine[0]!.reconciledActualCost = this.state.reconciledBillCharges
          selectedLine[0]!.reconciledActualLocationGroupName = this.state.providerName
          selectedLine[0]!.reconciledActualCPT = this.state.reconciledCPT
          selectedLine[0]!.reconciledCMS1500LineItemId = this.state.claimLineId
          selectedLine[0]!.reasonCode = this.state.reasonCode
        }
        break
      case 'AN':
        if (
          this.state.claimId == undefined ||
          this.state.claimLineId == undefined ||
          formikValues2.overrideCost == undefined ||
          !this.props.isNum!(formikValues2.overrideCost) ||
          this.state.reasonCode == ''
        ) {
          return
        }

        if (this.state.claimLineId != undefined) {
          this.props.addReconciledClaimLine!(this.state.claimLineId)
        }

        selectedLine = this.props.quote!.anesthesiaLineItems?.filter(
          (x) => x.id == this.props.existingQuoteLineId
        )
        if (selectedLine && selectedLine?.length == 1) {
          selectedLine[0]!.reconciledCost = formikValues2.overrideCost
          selectedLine[0]!.reconciledActualCost = this.state.reconciledBillCharges
          selectedLine[0]!.reconciledActualLocationGroupName = this.state.providerName
          selectedLine[0]!.reconciledActualCPT = this.state.reconciledCPT
          selectedLine[0]!.reconciledCMS1500LineItemId = this.state.claimLineId
          selectedLine[0]!.reasonCode = this.state.reasonCode
        }
        break
      case 'MI':
        if (
          this.state.claimId == undefined ||
          this.state.claimLineId == undefined ||
          formikValues2.overrideCost == undefined ||
          !this.props.isNum!(formikValues2.overrideCost) ||
          this.state.reasonCode == ''
        ) {
          return
        }

        if (this.state.claimLineId != undefined) {
          this.props.addReconciledClaimLine!(this.state.claimLineId)
        }

        selectedLine = this.props.quote!.miscellaneousLineItems?.filter(
          (x) => x.id == this.props.existingQuoteLineId
        )
        if (selectedLine && selectedLine?.length == 1) {
          selectedLine[0]!.reconciledCost = formikValues2.overrideCost
          selectedLine[0]!.reconciledActualCost = this.state.reconciledBillCharges
          selectedLine[0]!.reconciledActualLocationGroupName = this.state.providerName
          selectedLine[0]!.reconciledActualCPT = this.state.reconciledCPT
          selectedLine[0]!.reconciledCMS1500LineItemId = this.state.claimLineId
          selectedLine[0]!.reasonCode = this.state.reasonCode
        }
        break
    }

    this.simpleReconFormRef.current.setSubmitting(false)

    this.close()
  }

  private saveReconNoClaim = async () => {
    const formikValues2 = await this.getFormikValues2()

    this.validateReconNoClaimFields(formikValues2)

    var selectedLine
    switch (this.props.modalSwitch) {
      case 'AN':
        if (
          formikValues2.overrideCost == undefined ||
          !this.props.isNum!(formikValues2.overrideCost)
        ) {
          return
        }
        selectedLine = this.props.quote!.anesthesiaLineItems?.filter(
          (x) => x.id == this.props.existingQuoteLineId
        )
        if (selectedLine && selectedLine?.length == 1) {
          selectedLine[0]!.reconciledCost = formikValues2.overrideCost
        }
        break
      case 'MI':
        if (
          formikValues2.overrideCost == undefined ||
          !this.props.isNum!(formikValues2.overrideCost)
        ) {
          return
        }
        selectedLine = this.props.quote!.miscellaneousLineItems?.filter(
          (x) => x.id == this.props.existingQuoteLineId
        )
        if (selectedLine && selectedLine?.length == 1) {
          selectedLine[0]!.reconciledCost = formikValues2.overrideCost
        }
        break
    }

    this.simpleReconFormRef.current.setSubmitting(false)

    this.close()
  }

  private validateRequiredFields = (formikValues: any) => {
    if (!this.props.isNum!(this.state.cost)) {
      this.setState({ warningBilledCharges: true })
    }
    if (this.state.claimId == undefined) {
      this.setState({ warningClaim: true })
    }
    if (
      (this.state.claimLineId == undefined && !this.state.allowMultiSelect) ||
      (this.state.selectedLines.size < 1 && this.state.allowMultiSelect)
    ) {
      this.setState({ warningClaimLine: true })
    }
    if (this.state.selectedCostSource == undefined) {
      this.setState({ warningCostSource: true })
    }
    if (formikValues.description == undefined) {
      this.setState({ warningOtherDescription: true })
    }
    if (formikValues.costPerVisit == undefined) {
      this.setState({ warningCostPerVisit: true })
    }
    if (formikValues.actualCostPerVisit == undefined) {
      this.setState({ warningActualCostPerVisit: true })
    }
    if (formikValues.numberOfVisits == undefined) {
      this.setState({ warningNumberOfVisits: true })
    }
    if (formikValues.lineDescription == undefined) {
      this.setState({ warningMiscDescription: true })
    }
    if (!this.props.isNum!(formikValues.overrideCost)) {
      this.setState({ warningReconVal: true })
    }
    if (this.state.reasonCode == '') {
      this.setState({ warningReasonCode: true })
    }
  }

  private save = async () => {
    const formikValues = await this.getFormikValues()

    var returned = undefined

    this.validateRequiredFields(formikValues)

    switch (this.props.modalSwitch) {
      case 'PC':
        if (
          (formikValues.assistant == undefined &&
            (!this.props.isNum!(this.state.cost) ||
              (!this.props.isNum!(formikValues.assistant) &&
                formikValues.assistant != undefined) ||
              (!this.props.isNum!(formikValues.actualCost) &&
                formikValues.actualCost != undefined) ||
              (!this.props.isNum!(formikValues.contractMultiplier) &&
                formikValues.contractMultiplier != undefined) ||
              this.state.claimId == undefined ||
              this.state.claimLineId == undefined)) ||
          (this.state.reasonCode == '' && this.state.claimLineId != undefined)
        ) {
          return
        }

        //Required even if assistant is used
        if (
          this.state.selectedCostSource == undefined ||
          (CostSourceText[this.state.selectedCostSource] == 'Other' &&
            formikValues.description == undefined) ||
          this.state.claimId == undefined ||
          this.state.claimLineId == undefined
        ) {
          return
        }
        returned = new ProfessionalComponentLineItemDTO(
          this.state.providerId,
          this.state.providerName,
          this.props.selectedType?.id!,
          formikValues.physicianId,
          formikValues.physician ? formikValues.physician.name : '',
          formikValues.cpt,
          this.state.bilateral || false,
          undefined,
          undefined,
          false,
          this.state.selectedCostSource,
          formikValues.description,
          formikValues.assistant,
          formikValues.actualCost,
          formikValues.contractMultiplier,
          this.state.selectedMultiDiscount,
          this.props.selectedType?.name,
          undefined,
          undefined,
          false,
          undefined,
          formikValues.cpt,
          this.state.providerName,
          formikValues.actualCost,
          this.props.isNum!(this.state.cost) ? +this.state.cost : undefined,
          this.state.reconciledOverrideCost,
          this.state.claimLineId,
          this.state.calcText,
          this.state.reasonCode
        )

        if (this.state.claimLineId != undefined) {
          this.props.addReconciledClaimLine!(this.state.claimLineId)
        }

        if (returned.assistantMultiplier != undefined) {
          this.props.showAssistantWarning!()
        }

        if (returned.typeText == 'Surgeon') {
          this.props.quote!.professionalComponentLineItems!.forEach((element) => {
            if (element.assistantMultiplier != undefined) {
              this.props.showAssistantWarning!()
              return
            }
          })
        }

        this.props.quote?.professionalComponentLineItems?.push(returned)
        break

      case 'TC':
        if (
          !this.props.isNum!(this.state.cost) ||
          (!this.props.isNum!(formikValues.actualCost) &&
            formikValues.actualCost != undefined) ||
          (!this.props.isNum!(formikValues.contractMultiplier) &&
            formikValues.contractMultiplier != undefined) ||
          (this.state.claimLineId == undefined && !this.state.allowMultiSelect) ||
          (this.state.selectedLines.size < 1 && this.state.allowMultiSelect) ||
          (!this.props.isNum!(formikValues.overrideCost) &&
            this.state.allowMultiSelect) ||
          this.state.selectedCostSource == undefined ||
          (CostSourceText[this.state.selectedCostSource] == 'Other' &&
            formikValues.description == undefined) ||
          (this.state.reasonCode == '' && this.state.claimLineId != undefined)
        ) {
          return
        }

        if (this.state.claimLineId != undefined) {
          this.props.addReconciledClaimLine!(this.state.claimLineId)
          returned = new TechnicalComponentLineItemDTO(
            this.state.providerId,
            this.state.providerName,
            this.state.bilateral || false,
            undefined,
            this.props.selectedType?.id,
            formikValues.cpt,
            undefined,
            false,
            this.state.selectedCostSource,
            formikValues.description,
            formikValues.actualCost,
            formikValues.contractMultiplier,
            this.state.selectedMultiDiscount,
            this.props.selectedType?.name,
            this.state.calcText,
            false,
            undefined,
            formikValues.cpt,
            this.state.providerName,
            formikValues.actualCost,
            this.props.isNum!(this.state.cost) ? +this.state.cost : undefined,
            this.state.reconciledOverrideCost,
            this.state.claimLineId,
            this.state.calcText,
            this.state.reasonCode
          )
          this.props.quote?.technicalComponentLineItems!.push(returned)
        } else {
          var ids = Array.from(this.state.selectedLines.keys())
          ids.forEach((id) => {
            this.props.addReconciledClaimLine!(id)
            var line = this.state.claimLineDropdown.find((x) => x.id == id)

            returned = new TechnicalComponentLineItemDTO(
              this.state.providerId,
              this.state.providerName,
              false,
              undefined,
              this.props.selectedType?.id,
              line?.cpt,
              undefined,
              false,
              this.state.selectedCostSource,
              formikValues.description,
              formikValues.actualCost,
              undefined,
              undefined,
              this.props.selectedType?.name,
              this.state.calcText,
              false,
              undefined,
              line?.cpt,
              this.state.providerName,
              line?.billedCharges,
              this.props.isNum!(this.state.cost) ? +this.state.cost : undefined,
              this.state.reconciledOverrideCost,
              id,
              this.state.calcText,
              this.state.reasonCode
            )
            this.props.quote?.technicalComponentLineItems!.push(returned)
          })
        }
        break

      case 'AN':
        if (
          !this.props.isNum!(this.state.cost) ||
          (!this.props.isNum!(formikValues.actualCost) &&
            formikValues.actualCost != undefined) ||
          (!this.props.isNum!(formikValues.contractMultiplier) &&
            formikValues.contractMultiplier != undefined) ||
          this.state.selectedCostSource == undefined ||
          (CostSourceText[this.state.selectedCostSource] == 'Other' &&
            formikValues.description == undefined) ||
          (this.state.reasonCode == '' && this.state.claimLineId != undefined)
        ) {
          return
        }
        var actual = formikValues.actualCost

        if (
          this.props.isNum!(formikValues.monitored) &&
          formikValues.monitored != undefined
        ) {
          actual = formikValues.actualCost * formikValues.monitored
        }
        returned = new AnesthesiaLineItemDTO(
          this.state.providerId,
          this.state.providerName,
          formikValues.physicianId,
          formikValues.physician ? formikValues.physician.name : '',
          formikValues.cpt,
          this.state.bilateral || false,
          actual,
          +this.state.cost,
          undefined,
          false,
          this.state.selectedCostSource,
          formikValues.description,
          formikValues.contractMultiplier,
          this.state.selectedMultiDiscount,
          this.props.selectedType?.id,
          this.props.selectedType?.name,
          formikValues.monitored,
          this.state.calcText,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          this.state.reasonCode
        )

        this.props.quote?.anesthesiaLineItems!.push(returned)
        break

      case 'PT':
        if (
          !this.props.isNum!(formikValues.cost) ||
          !this.props.isNum!(formikValues.costPerVisit) ||
          !this.props.isNum!(formikValues.numberOfVisits)
        ) {
          return
        }
        returned = new PhysicalTherapyLineItemDTO(
          this.state.providerId,
          this.state.providerName,
          +this.state.cost,
          +formikValues.numberOfVisits,
          +formikValues.costPerVisit,
          +formikValues.actualCostPerVisit,
          undefined,
          false
        )

        this.props.quote?.physicalTherapyLineItems!.push(returned)
        break

      case 'MI':
        var typeMissing = this.props.selectedType == undefined
        if (
          !this.props.isNum!(this.state.cost) ||
          (!this.props.isNum!(formikValues.actualCost) &&
            formikValues.actualCost != undefined) ||
          (!this.props.isNum!(formikValues.contractMultiplier) &&
            formikValues.contractMultiplier != undefined) ||
          this.state.selectedCostSource == undefined ||
          typeMissing ||
          (CostSourceText[this.state.selectedCostSource] == 'Other' &&
            formikValues.description == undefined) ||
          typeof formikValues.lineDescription != 'string' ||
          (this.state.reasonCode == '' && this.state.claimLineId != undefined)
        ) {
          this.setState({ typeMissing: typeMissing })
          return
        }
        returned = new MiscellaneousLineItemDTO(
          this.state.providerId,
          this.state.providerName,
          this.state.bilateral || false,
          formikValues.lineDescription,
          +this.state.cost,
          this.props.selectedType?.id,
          formikValues.cpt,
          '',
          false,
          this.props.selectedType?.name,
          this.state.selectedCostSource,
          formikValues.description,
          formikValues.actualCost,
          formikValues.contractMultiplier,
          this.state.selectedMultiDiscount,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          this.state.reasonCode
        )

        this.props.quote?.miscellaneousLineItems!.push(returned)
        break
    }

    this.secondFormRef.current.setSubmitting(false)

    this.close()
  }

  private getFormikValues = async () => {
    const formikValues = this.secondFormRef.current?.state?.values
    await this.secondFormRef.current.submitForm()
    return formikValues
  }

  private getFormikValues2 = async () => {
    const formikValues = this.simpleReconFormRef.current?.state?.values
    await this.simpleReconFormRef.current.submitForm()
    return formikValues
  }

  private calculateCost = () => {
    const formikValues = this.secondFormRef.current?.state?.values
    var costPer = formikValues.costPerVisit ?? 0
    var numVisits = formikValues.numberOfVisits ?? 0
    this.secondFormRef.current.setFieldValue('cost', numVisits * costPer)
    this.secondFormRef.current.setFieldValue('isCostOverridden', false)
  }

  private renderSelectedLineData = () => {
    var selectedLine
    switch (this.props.modalSwitch) {
      case 'PC':
        selectedLine = selectedLine =
          this.props.quote!.professionalComponentLineItems?.filter(
            (x) => x.id == this.props.existingQuoteLineId
          )
        break
      case 'TC':
        selectedLine = this.props.quote!.technicalComponentLineItems?.filter(
          (x) => x.id == this.props.existingQuoteLineId
        )
        break
      case 'AN':
        selectedLine = this.props.quote!.anesthesiaLineItems?.filter(
          (x) => x.id == this.props.existingQuoteLineId
        )
        break
      case 'MI':
        selectedLine = this.props.quote!.miscellaneousLineItems?.filter(
          (x) => x.id == this.props.existingQuoteLineId
        )
        break
    }
    if (selectedLine && selectedLine?.length == 1) {
      return (
        <Grid
          container
          item
          spacing={3}
          xs={12}
          className={this.props.classes.quoteDetailContainer}
        >
          <Grid item xs={12} className={this.props.classes.quoteInfo}>
            <Typography variant={'body1'} color="textSecondary">
              QUOTE INFORMATION
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="row"
            xs={12}
            className={this.props.classes.quoteDetailRow}
          >
            <Grid container item spacing={3}>
              <Grid item wrap="nowrap">
                <strong>Provider:</strong> {selectedLine[0].providerName}
              </Grid>
              <Grid item wrap="nowrap">
                <strong>CPT:</strong> {selectedLine[0].cptCode}
              </Grid>
              <Grid item wrap="nowrap">
                <strong>Source:</strong>{' '}
                {selectedLine[0].costSource != undefined
                  ? this.props.isNum!(selectedLine[0].costSource)
                    ? CostSourceText[selectedLine[0].costSource!]
                    : ''
                  : ''}
              </Grid>
              <Grid item wrap="nowrap">
                <strong>Billed Charges:</strong>{' '}
                {formatCurrency((selectedLine[0].actualCost ?? 0).toString())}
              </Grid>
              <Grid item wrap="nowrap">
                <strong>Quoted $:</strong>{' '}
                {formatCurrency((selectedLine[0].cost ?? 0).toString())}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }
    return <></>
  }

  private close = () => {
    this.setState({
      modalPage: 1,
      pageCount: 0,
      totalItems: 0,
      providerName: '',
      providerId: '',
      selectedCostSource: undefined,
      selectedMultiDiscount: undefined,
      bilateral: false,
      cost: '',
      assistantPresent: false,
      calcText: undefined,
      claimId: undefined,
      claimLineDropdown: new Array<ReconcileQuoteClaimDropdownOption>(),
      groupNumber: '',
      lineNumber: undefined,
      disableLineSelect: true,
      claimLineId: undefined,
      reconciledOverrideCost: undefined,
      episodeOfCareNumber: undefined,
      reconciledCPT: undefined,
      reconciledBillCharges: undefined,
      reasonCode: '',
      warningBilledCharges: false,
      warningClaim: false,
      warningClaimLine: false,
      warningCostSource: false,
      warningOtherDescription: false,
      warningCostPerVisit: false,
      warningActualCostPerVisit: false,
      warningNumberOfVisits: false,
      warningMiscDescription: false,
      warningReconVal: false,
      warningReasonCode: false,
      typeMissing: false,
      allowMultiSelect: false,
      selectedLines: new Map<string, string>(),
    })
    this.props.setSelectedProviderTypeId!('')
    this.props.setSelectedProvider!(undefined)
    this.props.setSelectedLocation!(undefined)
    this.props.setSelectedType!(undefined)
    this.props.clearLocations!()
    this.props.close!(this.props.reloadParent!)
  }

  public componentDidMount() {
    this.handleAllowMultiSelect = this.handleAllowMultiSelect.bind(this)
    this.props.clearData!().then(() => {
      this.props.setSelectedProvider!(undefined)
      this.props.getAllProviderTypes!()
    })
  }

  private async selectProvider(
    locationId: string,
    providerId: string,
    providerName: string,
    locationName: string
  ) {
    let provider = DefaultProvider(false)
    provider.id = providerId
    provider.name = providerName
    let location = await GetLocationById(locationId)
    location.name = locationName
    this.props.setSelectedProvider!({ ...DefaultProvider(), ...provider })
    this.props.setSelectedLocation!(location as unknown as ILocation)

    this.setState({ providerName: providerName, providerId: providerId })

    if (this.props.updateParentStateAfterModalEvent) {
      this.props.updateParentStateAfterModalEvent()
    }
    if (this.props.updateParentStateAfterModal) {
      this.props.updateParentStateAfterModal()
    }
  }
}

const InjectedReconcileQuoteLineModal = inject<
  IStores,
  IAddQuoteLineModalProps,
  Partial<IAddQuoteLineModalProps>,
  any
>((stores: IStores) => ({
  close: stores.quotes.closeReconcileQuoteLineModal,
  dataTableManager: stores.quotes.billsDataTableStore,
  isLoading: stores.quotes.isLoading,
  isOpen: stores.quotes.isQuoteReconcileLineModalOpen,
  quote: stores.quotes.selectedQuote,
  patientHeader: stores.patients.headerDetails,

  addProviderLocation: stores.schedules.addProviderLocation,
  clearData: stores.locations.clearData,
  clearLocations: stores.locations.clearLocations,
  currentAppOrganization: stores.global.currentAppOrganization,
  getAllProviderTypes: stores.providers.getAllProviderTypes,
  getScheduleById: stores.schedules.getScheduleById,
  providerTypes: stores.providers.providerTypes,
  selectedEpisodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  selectedProviderTypeId: stores.locations.selectedProviderTypeId,
  selectedLocation: stores.locations.selectedEditClaimLocation,
  selectedProvider: stores.providers.selectedModalEditScheduleProvider,
  setSelectedAddress: stores.locations.setSelectedAddress,
  setSelectedProcedureFilters: stores.locations.setSelectedProcedureFilters,
  setSelectedProviderGroup: stores.locations.setSelectedProviderGroup,
  setSelectedProviderLocation: stores.locations.setSelectedProviderLocation,
  setSelectedProviderTypeId: stores.locations.setSelectedProviderTypeId,
  setSelectedLocation: stores.locations.setSelectedEditClaimLocation,
  setSelectedProvider: stores.providers.setSelectedModalEditScheduleProvider,
  setSelectedSchedule: stores.schedules.setSelectedSchedule,

  getPhysicians: stores.quotes.getPhysicianSuggestions,
  physicians: stores.quotes.physicians,
  getTypes: stores.quotes.getTypesForModal,
  types: stores.quotes.genericTypes,
  setSelectedType: stores.quotes.setSelectedType,
  selectedType: stores.quotes.selectedType,
  modalSwitch: stores.quotes.modalSwitch,
  user: stores.global.user,
  existingQuoteLineId: stores.quotes.existingQuoteLineId,
  existingQuoteLine: stores.quotes.existingQuoteLine,
  showAssistantWarning: stores.quotes.showAssistantWarning,
  claimDropdown: stores.quotes.claimDropdown,
  usedClaimLines: stores.quotes.usedClaimLines,
  addReconciledClaimLine: stores.quotes.addReconciledClaimLine,
  isNum: stores.quotes.isNum,
}))(ReconcileLineItemModal)

export default withStyles(styles)(InjectedReconcileQuoteLineModal)
