import {
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { Close, FilterList } from '@material-ui/icons'
import { observer } from 'mobx-react'
import * as React from 'react'
import IDataTableManager from '../IDataTableManager'
import ITableOptions from '../ITableOptions'

const styles = createStyles({
  showInactivesLabel: {
    paddingLeft: '20px',
  },
})

export interface IESDataTableFilterDialogProps {
  dataTableManager: IDataTableManager<any>
  enableShowInactives?: boolean
  filterComponents?: JSX.Element
  updateTableOptions: (tableOptions: ITableOptions) => void
  handleFilterClickAway: () => void
}

@observer
class IESDataTableFilterDialog extends React.Component<
  IESDataTableFilterDialogProps & WithStyles<typeof styles>
> {
  public render() {
    const { handleFilterClickAway } = this.props

    return (
      <Grid container direction="column" style={{ padding: '20px', minWidth: '300px' }}>
        <Grid item container alignItems="center" spacing={1}>
          <Grid item>
            <FilterList style={{ fontSize: '40px' }} color="secondary" />
          </Grid>
          <Grid item>
            <Typography variant="h5">Filters</Typography>
          </Grid>
          <Grid
            item
            style={{
              marginLeft: 'auto',
              marginRight: '10px',
            }}
          >
            <Button onClick={handleFilterClickAway}>
              <Close />
            </Button>
          </Grid>
        </Grid>
        {this.renderFilters()}
      </Grid>
    )
  }

  private onShowInactivesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked
    this.props.updateTableOptions({ includeInactives: value, page: 1 })
  }

  private renderFilters = () => {
    if (
      this.props.filterComponents === undefined &&
      (this.props.enableShowInactives === undefined ||
        this.props.enableShowInactives === false)
    ) {
      return (
        <Grid item style={{ padding: '10px' }} id="noFilterErrorMessage">
          No Filters Available
        </Grid>
      )
    } else {
      return (
        <Grid container direction="column">
          {this.props.enableShowInactives === undefined ||
          this.props.enableShowInactives ? (
            <Grid item>
              <Grid container direction="column">
                <FormControlLabel
                  style={{ padding: '0px' }}
                  className={this.props.classes.showInactivesLabel}
                  control={
                    <Checkbox
                      checked={this.props.dataTableManager.tableOptions.includeInactives}
                      color="primary"
                      name="includeInactives"
                      onChange={this.onShowInactivesChanged}
                      value="shouldShowInactives"
                      id="showInactivesCheckbox"
                    />
                  }
                  label="Show Inactives"
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item>{this.props.filterComponents}</Grid>
        </Grid>
      )
    }
  }
}

export default withStyles(styles)(IESDataTableFilterDialog)
