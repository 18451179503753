import { action, observable } from 'mobx'
import { ICMS1500 } from 'src/Definitions'
import {
  getClaimPaymentDetails,
  retrieveRefundLineItemsWithBalance,
} from 'src/services/ClaimService'
import { ILineItemSummaryResult } from 'src/viewModels/LineItemSummaryResult'
import { IPaymentDetailSummaryResult } from 'src/viewModels/PaymentDetailSummaryResult'
import { IPaymentLineItem, PaymentLineItem } from 'src/viewModels/PaymentLIneItem'
import {
  IPaymentLineItemDetailSummary,
  PaymentLineItemDetailSummary,
} from 'src/viewModels/PaymentLineItemDetailSummary'
import { ProcessReceivedCheckResults } from 'src/viewModels/ProcessReceivedCheckResult'

export default class ReceivePaymentStore {
  @observable
  public isOpen: boolean = false
  @observable
  public isLoading: boolean = false
  @observable
  public isProcessRefundCheckOpen?: boolean = false
  @observable
  public checkResults?: ProcessReceivedCheckResults
  @observable
  public selectedAppliedPayments?: IPaymentLineItemDetailSummary[]
  @observable
  public selectedRefundListItemsWithBalance?: IPaymentLineItem[]
  @observable
  public selectedPaymentSummary?: IPaymentDetailSummaryResult
  @observable
  public selectedCMS1500?: ICMS1500
  @observable
  public selectedCMS1500Id?: string
  @observable
  public updateParentStateAfterModal?: () => void

  @action.bound
  public setSelectedCMS1500Id(cms1500Id: string) {
    this.selectedCMS1500Id = cms1500Id
  }

  @action.bound
  public openProcessReceivedCheckDialog(results: ProcessReceivedCheckResults) {
    this.checkResults = results
    this.isProcessRefundCheckOpen = true
  }

  @action.bound
  closeProcessReceivedCheck() {
    this.isProcessRefundCheckOpen = false
    this.checkResults = undefined
  }

  @action.bound
  public closeDialog = () => {
    this.selectedCMS1500 = undefined
    this.selectedRefundListItemsWithBalance = undefined
    this.isOpen = false
    if (this.updateParentStateAfterModal) {
      this.updateParentStateAfterModal()
    }
  }

  private loadRefundLineItemDetailsWithBalance = async (cms1500Id: string) => {
    this.isLoading = true

    await retrieveRefundLineItemsWithBalance(cms1500Id).then(
      (response: ILineItemSummaryResult[]) => {
        this.selectedRefundListItemsWithBalance = response.map(
          (item) => new PaymentLineItem(item)
        )
      }
    )
  }

  private loadAppliedPayments = async (cms1500Id: string) => {
    this.isLoading = true

    await getClaimPaymentDetails(cms1500Id).then(
      (response: IPaymentDetailSummaryResult) => {
        this.selectedPaymentSummary = response
        this.selectedAppliedPayments = response.lineItems.map(
          (item) => new PaymentLineItemDetailSummary(item)
        )
      }
    )
  }

  @action.bound
  public loadReceivePaymentData = async (cms1500Id: string) => {
    this.isLoading = true
    const promises = [] as Array<Promise<void>>
    promises.push(this.loadRefundLineItemDetailsWithBalance(cms1500Id))
    promises.push(this.loadAppliedPayments(cms1500Id))

    await Promise.all(promises).finally(() => {
      this.isLoading = false
      this.isOpen = true
    })
  }

  @action.bound
  public setParentUpdateFunction(parentFunction: () => void) {
    this.updateParentStateAfterModal = parentFunction
  }
}
