import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { Receipt } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { ClaimHeaderDetailsSummaryResult } from 'src/viewModels/ClaimHeaderDetailsSummaryResult'

interface IBillOutNotesModalProps {
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean
  patientHeader?: ClaimHeaderDetailsSummaryResult
}

class BillOutNotesModal extends React.Component<IBillOutNotesModalProps> {
  public render() {
    const { isOpen, patientHeader } = this.props

    return (
      <Dialog
        key={patientHeader?.patientLast}
        fullWidth={true}
        maxWidth="md"
        open={isOpen!}
      >
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar title="Notes" pageIcon={<Receipt />} isModal={true} />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" item xs={12} spacing={2}>
            <Grid item xs={12} style={{ paddingTop: '16px' }}>
              <Typography
                variant={'body1'}
                color="textSecondary"
                style={{
                  height: '18px',
                  paddingRight: '8px',
                  marginBottom: '8px',
                }}
              >
                EPISODE OF CARE NOTES
              </Typography>
              <span style={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                {patientHeader?.episodeOfCareNotes != ''
                  ? patientHeader?.episodeOfCareNotes
                  : 'N/A'}
              </span>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={'body1'}
                color="textSecondary"
                style={{
                  height: '18px',
                  paddingRight: '8px',
                  marginBottom: '8px',
                }}
              >
                BILLING NOTES
              </Typography>
              <Typography>
                <React.Fragment>
                  <span style={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                    {patientHeader?.billingNotes != ''
                      ? patientHeader?.billingNotes
                      : 'N/A'}
                  </span>
                </React.Fragment>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private close = () => {
    this.props.close!()
  }
}

const InjectedBillOutNotesModal = inject<
  IStores,
  IBillOutNotesModalProps,
  Partial<IBillOutNotesModalProps>,
  any
>((stores: IStores) => ({
  close: stores.billOutStore.closeBillOutNotesModal,
  isLoading: stores.quotes.isLoading,
  isOpen: stores.billOutStore.isBillOutNotesModalOpen,
}))(BillOutNotesModal)

export default InjectedBillOutNotesModal
