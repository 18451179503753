export interface IQuoteSearchRequest {
  page: number
  pageSize: number
  patientFirstName?: string | undefined
  patientLastName?: string | undefined
  cptCode?: string | undefined
  providerGroup?: string | undefined
  insuranceCompany?: string | undefined
  primaryAdjuster?: string | undefined
  surgeryDateStart?: string | undefined
  surgeryDateEnd?: string | undefined
  quoteStatuses?: number[] | undefined
  quoteTypes?: number[] | undefined
  eocStatus?: string | undefined
  employer?: string | undefined
  urgent: boolean
  eocNum?: string | undefined
}

export class QuoteSearchRequest implements IQuoteSearchRequest {
  public page: number
  public pageSize: number
  public patientFirstName?: string | undefined
  public patientLastName?: string | undefined
  public cptCode?: string | undefined
  public providerGroup?: string | undefined
  public insuranceCompany?: string | undefined
  public primaryAdjuster?: string | undefined
  public surgeryDateStart?: string | undefined
  public surgeryDateEnd?: string | undefined
  public quoteStatuses?: number[] | undefined
  public quoteTypes?: number[] | undefined
  public eocStatus?: string | undefined
  public employer?: string | undefined
  public urgent: boolean
  public eocNumber?: string | undefined
}
