import {
  Button,
  CircularProgress,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import { CheckBox, CheckBoxOutlineBlank, LocalHospital } from '@material-ui/icons'
import { Form, Formik, FormikActions } from 'formik'
import { inject } from 'mobx-react'
import React, { ChangeEvent } from 'react'
import ReactPaginate from 'react-paginate'
import { GetCoordinates, searchProviderLocations } from 'src/services/LookupService'
import { LocationSearchRequest } from 'src/viewModels/LocationSearchRequest'
import { pagedList } from 'src/viewModels/pagedList'
import { ProviderLocationSummaryResult } from 'src/viewModels/ProviderLocationSummaryResult'
import AddressField from '../common/AddressField/AddressField'
import {
  createAddressFormValues,
  IAddressFormValues,
} from '../common/AddressField/AddressFormValues'
import FullWidthField from '../common/FullWidthField'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import SelectInput from '../common/SelectInput/SelectInput'
import {
  DefaultLocation,
  DefaultProvider,
  IEpisodeOfCare,
  ILocation,
  IOrganization,
  IPatient,
  IProvider,
  ISchedule,
} from '../Definitions'
import {
  AddressDto,
  IProviderDto,
  IProviderTypeDto,
  ProviderTypeDto,
  TagDto,
  TestDto,
} from '../generated_client'
import { IStores } from '../Stores'
import { Distance } from '../stores/LocationsStore/LocationsStore'
import { ITagFormValues } from '../Tags/TagFormValues'
import { OrganizationId } from '../utils/OrganizationIds'
import ProviderLookupMapContainer from './ProviderLookupMapContainer'

const styles = ({ palette }: Theme) =>
  createStyles({
    procedureFilter: {
      backgroundColor: palette.primary.main,
    },
    title: {
      backgroundColor: palette.primary.main,
      color: '#ffffff',
    },
  })

interface IProviderLookupDialogProps extends DialogProps {
  addProviderLocation?: (scheduleId: string, locationId: string) => Promise<void>
  clearData?: () => Promise<void>
  clearLocations?: () => void
  close?: () => void
  currentAppOrganization?: IOrganization
  getAllProviderTypes?: () => Promise<void>
  getAllTags?: (filter: string) => void
  getScheduleById?: (id: string) => Promise<void>
  isOpen?: boolean
  lookupTitle?: string
  providerTypes?: ProviderTypeDto[]
  scheduleId?: string
  selectedAddress?: AddressDto
  selectedDistance?: string
  selectedEpisodeOfCare?: IEpisodeOfCare
  selectedPatient?: IPatient
  selectedProvider?: IProvider
  selectedLocation?: ILocation
  selectedProviderTypeId?: string
  selectedSchedule?: ISchedule
  selectedTags?: TagDto[]
  setDistance?: (distance: string) => void
  setSelectedAddress?: (address?: AddressDto, load?: boolean) => Promise<void>
  setSelectedLocation?: (
    location?: ILocation,
    reload?: () => void
  ) => void | ((location?: ILocation) => void)
  setSelectedProcedureFilters?: (procedureFilters?: IProcedureFilter[]) => void
  setSelectedProvider?: (provider?: IProvider) => void
  setSelectedProviderGroup?: (providerGroup?: string) => void
  setSelectedProviderLocation?: (locationName?: string) => void
  setSelectedProviderTypeId?: (selectedProviderTypeId: string) => void
  setSelectedTags?: (tags: TagDto[]) => void
  canFindLatLong?: boolean
  setCanFindLat?: (dist: boolean) => void
  tags?: TagDto[]
  reloadParent?: () => void
}

export interface IProcedureFilter extends TestDto {
  selected: boolean
}

interface IProviderLookupFormValues {
  address: IAddressFormValues
  filter?: string
  providerTags: ITagFormValues[]
  providerTypeId?: string
  provider?: IProviderDto
  procedureFilters: IProcedureFilter[]
  providerGroup?: string
}

class ProviderLookupDialog extends React.Component<
  IProviderLookupDialogProps & WithStyles<typeof styles>
> {
  public state = {
    gridData: new pagedList().items as ProviderLocationSummaryResult[],
    providerGroup: '',
    providerLocation: '',
    providerTypeId: '',
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 25,
    selectedTags: new Map<string, string>(),
    mapKey: 0,
    previousValues: '',
    invalidAddress: false,
    hasCoordinates: false,
    gridLoading: true,
  }

  public componentDidMount() {
    this.props.clearData!().then(() => {
      this.props.setSelectedProvider!(undefined)
      // const selectedProcedures = this.props.selectedEpisodeOfCare ? this.props.selectedEpisodeOfCare.tests.map((x) => ({ ...x, selected: true } as IProcedureFilter)) : [];
      // this.props.setSelectedProcedureFilters!(selectedProcedures);
      this.setAddress(this.props.selectedPatient!.address, false).then(() => {
        this.props.getAllProviderTypes!()
        this.setCoordinates()
      })
      this.props.getAllTags!('')
      this.setState({ gridLoading: false })
    })
  }

  private save = async (
    values: IProviderLookupFormValues,
    formikActions: FormikActions<IProviderLookupFormValues>
  ) => {
    this.props.setSelectedLocation!(undefined)
    this.props.setSelectedProvider!(undefined)
    // this.props.setSelectedProcedureFilters!(values.procedureFilters); // TODO: This needs readded when we update the diagnostic portion.
    this.props.setSelectedProviderGroup!(values.providerGroup)
    this.props.setSelectedProviderLocation!(values.filter)
    this.setState({
      providerGroup: values.providerGroup,
      providerLocation: values.filter,
      providerTypeId: this.props.selectedProviderTypeId,
      page: 1,
    })
    this.getProviderData()
    formikActions.setSubmitting(false)
  }

  private setCoordinates(formValues?: IProviderLookupFormValues) {
    this.setState({ invalidAddress: false })
    var address = new AddressDto()

    if (formValues?.address) {
      address.line1 = formValues?.address.line1
      address.city = formValues?.address.city
      var actualState = ''
      if (formValues?.address.state != '' && formValues?.address.state != undefined) {
        var state = formValues?.address.state as { name: string }
        actualState = state.name
      }
      address.state = actualState
      address.zip = formValues?.address.zip
    } else {
      address.line1 = this.props.selectedPatient?.address.line1
      address.city = this.props.selectedPatient?.address.city
      address.state = this.props.selectedPatient?.address.state
      address.zip = this.props.selectedPatient?.address.zip
    }

    if (
      (address.zip != undefined && address.zip != '') ||
      (address.state != undefined &&
        address.state != '' &&
        address.city != undefined &&
        address.city != '')
    ) {
      GetCoordinates(address).then((response: any) => {
        var coordinates = response.result
        this.setState({
          invalidAddress:
            coordinates.latitude == undefined || coordinates.longitude == undefined,
          hasCoordinates:
            coordinates.latitude != undefined && coordinates.longitude != undefined,
        })
        address.latitude = coordinates.latitude
        address.longitude = coordinates.longitude
        //Update map
        this.props.setSelectedAddress!(address, false)
      })
    } else {
      this.setState({ invalidAddress: true, hasCoordinates: false })
      address.latitude = undefined
      address.longitude = undefined
      //Update map
      this.props.setSelectedAddress!(address, false)
    }
  }

  private setAddress(address: AddressDto, load: boolean) {
    if (address) {
      return GetCoordinates(address).then((response: any) => {
        var coordinates = response.result
        address.latitude = coordinates.latitude
        address.longitude = coordinates.longitude
        this.props.setSelectedAddress!(address, load)
      })
    } else {
      const defaultAddress = {
        city: '',
        state: '',
        line1: '',
        line2: '',
        zip: '',
      } as AddressDto
      return GetCoordinates!(defaultAddress).then(() => {
        defaultAddress.latitude = undefined
        defaultAddress.longitude = undefined
        this.props.setSelectedAddress!(defaultAddress, load)
      })
    }
  }

  private getProviderTypes = (providerTypes?: ProviderTypeDto[]) => {
    return providerTypes ? ([undefined] as any[]).concat(providerTypes) || [] : []
  }

  private getProviderTypeName = (type: IProviderTypeDto) => {
    return type ? type.name || '' : ''
  }

  private getProviderTypeValue = (type: IProviderTypeDto) => {
    return type ? type.id || '' : ''
  }

  private getValue(item: Distance) {
    return item
  }

  private onDistanceChange = () => (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setDistance!(event.target.value)
  }

  private setSelectedProviderTypeId = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setSelectedProviderTypeId!(event.target.value)
  }

  private close = () => {
    this.props.setSelectedProvider!(undefined)
    this.props.setSelectedLocation!(undefined)
    this.props.setSelectedAddress!(undefined, false)
    this.props.clearLocations!()
    this.props.close!()
  }

  private toggleProcedureFilter =
    (
      values: IProcedureFilter[],
      idx: number,
      setFieldValue: (field: string, value: any) => void
    ) =>
    (_: React.MouseEvent) => {
      const updatedFilters = values
      updatedFilters[idx].selected = !values[idx].selected
      setFieldValue('procedureFilters', updatedFilters)
    }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private getProviderData = async () => {
    this.setState({ gridLoading: true })
    const { page, pageSize, providerGroup, providerLocation, providerTypeId } = this.state
    const requestData = new LocationSearchRequest()
    requestData.providerGroup = providerGroup
    requestData.tags = Array.from(this.state.selectedTags.keys())
    requestData.filter = providerLocation
    requestData.latitude = this.props.selectedAddress?.latitude
    requestData.longitude = this.props.selectedAddress?.longitude
    switch (this.props.selectedDistance) {
      case Distance.fiftyMiles:
        requestData.range = 50
        break
      case Distance.oneHundredMiles:
        requestData.range = 100
        break
      case Distance.twoFiftyMiles:
        requestData.range = 250
        break
      case Distance.fiveHundredMiles:
        requestData.range = 500
        break
      default:
        requestData.range = 25
        break
    }
    if (providerTypeId !== '') {
      requestData.providerTypeId = providerTypeId
    }
    requestData.page = page
    requestData.pageSize = pageSize
    await searchProviderLocations(requestData).then((response: any) => {
      this.setState({
        mapKey: ++this.state.mapKey,
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        gridLoading: false,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getProviderData())
  }

  private selectProvider(
    locationId: string,
    providerId: string,
    providerName: string,
    locationName: string
  ) {
    let location = DefaultLocation(false)
    location.id = locationId
    location.name = locationName
    let provider = DefaultProvider(false)
    provider.id = providerId
    provider.name = providerName
    location.providerId = providerId
    this.props.setSelectedProvider!({ ...DefaultProvider(), ...provider })
    this.props.setSelectedLocation!(location, this.props.reloadParent)

    if (
      this.props.scheduleId &&
      this.props.selectedSchedule!.id === this.props.scheduleId
    ) {
      if (this.props.selectedSchedule) {
        this.props.addProviderLocation!(
          this.props.selectedSchedule!.id,
          this.props.selectedLocation!.id
        ).finally(() => {
          this.props.getScheduleById!(this.props.selectedSchedule!.id).finally(() => {
            const newItem =
              this.props.selectedSchedule!.scheduleProviderLocations!.filter(
                (x) => x.locationId === this.props.selectedLocation!.id
              )
            if (newItem.length > 0 && newItem[0]) {
              this.props.setSelectedLocation!(
                newItem[0]!.location as unknown as ILocation,
                this.props.reloadParent
              )
            }
          })
        })
      }
    }
    this.props.close!()
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((provider, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              className="gridButton"
              onClick={() =>
                this.selectProvider(
                  provider.locationId!,
                  provider.providerId!,
                  provider.providerName!,
                  provider.locationName!
                )
              }
            >
              Select
            </Button>
          </TableCell>
          <TableCell>{provider.providerLocationDescription}</TableCell>
          <TableCell style={{ minWidth: '120px' }}>
            <Grid container>
              {(provider.tags !== undefined
                ? (provider.tags as TagDto[])
                : ([] as TagDto[])
              ).map((x) => (
                <Grid item style={{ marginBottom: '8px', marginTop: '8px' }}>
                  <span
                    style={{
                      backgroundColor: x.tagColor,
                      borderRadius: '4px',
                      color: '#FFFFFF',
                      marginRight: '5px',
                      padding: '4px',
                    }}
                    key={x.id}
                  >
                    {x.name}
                  </span>
                </Grid>
              ))}
            </Grid>
          </TableCell>
          <TableCell>{provider.types}</TableCell>
          <TableCell>
            {provider.line1} {provider.city}, {provider.state} {provider.zip}
          </TableCell>
          <TableCell>{provider.distanceInMiles}</TableCell>
        </TableRow>
      ))
    }
  }

  private setSelectedTags = (event: ChangeEvent<HTMLSelectElement>) => {
    if (this.state.selectedTags.has(event.currentTarget.dataset.value!)) {
      this.state.selectedTags.delete(event.currentTarget.dataset.value!)
      this.setState({ selectedTags: this.state.selectedTags })
    } else {
      this.state.selectedTags.set(
        event.currentTarget.dataset.value!,
        event.currentTarget.dataset.name!
      )
      this.setState({ selectedTags: this.state.selectedTags })
    }
  }

  public render() {
    const {
      classes,
      selectedEpisodeOfCare,
      providerTypes,
      selectedDistance,
      selectedPatient,
      selectedAddress,
    } = this.props

    const address = selectedPatient
      ? selectedPatient.address
        ? selectedPatient.address
        : selectedAddress
      : selectedAddress

    const selectedProcedures = selectedEpisodeOfCare
      ? selectedEpisodeOfCare.tests.map(
          (x) => ({ ...x, selected: true }) as IProcedureFilter
        )
      : []

    const resetValues = {
      page: 1,
      address: createAddressFormValues(undefined),
      procedureFilters: [],
      providerTags: [],
      providerTypeId: undefined,
      provider: undefined,
      filter: '',
      providerGroup: '',
    }

    const MenuProps = {
      getContentAnchorEl: null,
      PaperProps: {
        style: {
          maxHeight: 400,
        },
      },
    }

    return (
      <Dialog open={this.props.isOpen!} maxWidth="lg" fullWidth={true}>
        <DialogTitle style={{ padding: 0, marginBottom: '15px' }}>
          <ResponsiveAppBar
            title={this.props.lookupTitle ? this.props.lookupTitle : 'Provider Lookup'}
            pageIcon={<LocalHospital />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent>
          {this.state.gridLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1200',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          {selectedPatient ? (
            <Formik
              initialValues={
                {
                  procedureFilters: selectedProcedures,
                  providerTags: [],
                  providerTypeId: undefined,
                  provider: undefined,
                  address: createAddressFormValues(address),
                } as IProviderLookupFormValues
              }
              onSubmit={this.save}
              enableReinitialize={true}
            >
              {({ submitForm, values, setFieldValue, resetForm }) => (
                <Form>
                  <Grid
                    container
                    direction="column"
                    spacing={1}
                    style={{ paddingTop: '10px' }}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item lg={6} xs={12}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <AddressField
                              name="address"
                              showEmail={false}
                              showPhone={false}
                              showFax={false}
                              outlined={true}
                              handleParentChange={this.props.setCanFindLat}
                              addressLabel="Patient Address"
                              resetCoordinates={() => {
                                this.setState({ hasCoordinates: false })
                              }}
                              patient={this.props.selectedPatient}
                              setPrevious={(vals: string) =>
                                this.setState({ previousValues: vals })
                              }
                              previousValues={this.state.previousValues}
                            />
                          </Grid>
                          <Grid item container direction="row">
                            <Grid item xs={8}>
                              <SelectInput
                                inputId="distance"
                                getName={this.getValue}
                                getValue={this.getValue}
                                items={[
                                  Distance.twentyFiveMiles,
                                  Distance.fiftyMiles,
                                  Distance.oneHundredMiles,
                                  Distance.twoFiftyMiles,
                                  Distance.fiveHundredMiles,
                                ]}
                                label="Distance"
                                name="distance"
                                fullWidth
                                onChange={this.onDistanceChange()}
                                value={selectedDistance}
                                shrinkLabel={true}
                                outlined={true}
                                disabled={!this.state.hasCoordinates}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              justifyContent="center"
                              style={{ display: 'flex' }}
                            >
                              <Button
                                color="primary"
                                variant="contained"
                                disabled={this.state.hasCoordinates}
                                onClick={() => this.setCoordinates(values)}
                              >
                                Verify Address
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container justifyContent="flex-end" className="error">
                              {this.state.invalidAddress
                                ? 'This address cannot be found. Please review.'
                                : ''}
                            </Grid>
                            <hr style={{ marginBottom: '20px' }} color="Primary" />
                            {this.props.currentAppOrganization &&
                            this.props.currentAppOrganization.id ===
                              OrganizationId.Surgical ? (
                              <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Provider Type
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={100} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  onChange={this.setSelectedProviderTypeId}
                                  id="providerTypeId"
                                  value={this.props.selectedProviderTypeId}
                                >
                                  {this.getProviderTypes(providerTypes)?.map(
                                    (provider) => (
                                      <option value={this.getProviderTypeValue(provider)}>
                                        {this.getProviderTypeName(provider)}
                                      </option>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            ) : (
                              <Grid
                                item
                                container
                                direction="column"
                                style={{ paddingTop: '15px' }}
                                spacing={1}
                              >
                                <Grid item>
                                  <label>Procedures</label>
                                </Grid>
                                <Grid item>
                                  <Grid direction="row">
                                    {values.procedureFilters.map((x, idx) => (
                                      <span
                                        className={classes.procedureFilter}
                                        style={{
                                          backgroundColor: !x.selected
                                            ? 'gray'
                                            : undefined,
                                          borderRadius: '2px',
                                          color: '#FFFFFF',
                                          cursor: 'pointer',
                                          marginLeft: '1px',
                                          marginRight: '8px',
                                          padding: '5px',
                                          width: 'fit-content',
                                        }}
                                        key={x.id}
                                        onClick={this.toggleProcedureFilter(
                                          values.procedureFilters,
                                          idx,
                                          setFieldValue
                                        )}
                                      >
                                        {x.procedure!.diagnostic}
                                      </span>
                                    ))}
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item>
                            <FullWidthField
                              label="Provider Group"
                              name="providerGroup"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item>
                            <FullWidthField
                              label="Provider Location"
                              name="filter"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              style={{ maxWidth: '600px' }}
                            >
                              <InputLabel shrink={true} variant="outlined">
                                Tag
                              </InputLabel>
                              <Select
                                variant="outlined"
                                input={<OutlinedInput notched labelWidth={28} />}
                                inputProps={{ label: true, notched: true }}
                                fullWidth
                                id="eventSelect"
                                multiple
                                value={Array.from(this.state.selectedTags.keys())}
                                renderValue={() =>
                                  Array.from(this.state.selectedTags.values()).join(', ')
                                }
                                onChange={this.setSelectedTags}
                                MenuProps={MenuProps}
                              >
                                {this.props.tags?.map((item: TagDto) => (
                                  <MenuItem value={item.id} data-name={item.name}>
                                    {item.id && this.state.selectedTags.has(item.id) ? (
                                      <CheckBox
                                        color="secondary"
                                        style={{ paddingRight: '10px' }}
                                      />
                                    ) : (
                                      <CheckBoxOutlineBlank
                                        color="action"
                                        style={{ paddingRight: '10px' }}
                                      />
                                    )}{' '}
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item container direction="row">
                            <div style={{ marginTop: '16px' }}>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={submitForm}
                                type="submit"
                              >
                                Search
                              </Button>
                            </div>
                            <div style={{ marginTop: '16px', marginLeft: '10px' }}>
                              <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  resetForm(resetValues)
                                  this.props.setSelectedProviderTypeId!('')
                                  this.props.setDistance!(Distance.twentyFiveMiles)
                                  this.props.setSelectedAddress!(undefined, false)
                                  this.setState({
                                    page: 1,
                                    selectedTags: new Map<string, string>(),
                                    hasCoordinates: false,
                                  })
                                }}
                              >
                                Clear Search
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <ProviderLookupMapContainer
                          locations={this.state.gridData}
                          key={this.state.mapKey}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignContent="center"
                      alignItems="flex-start"
                      wrap="wrap"
                    >
                      <Grid item xs={12} style={{ minWidth: '750px' }}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow className="gridPadding">
                              <TableCell></TableCell>
                              <TableCell>Provider Group (Location)</TableCell>
                              <TableCell style={{ minWidth: '120px' }}>
                                Group Tags
                              </TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Address</TableCell>
                              <TableCell>Distance (mi)</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody style={{ width: '100%' }}>
                            {this.mapGridData()}
                          </TableBody>
                        </Table>
                        <div className="pagination-row">
                          {this.paginationInfo()}
                          <ReactPaginate
                            forcePage={this.state.page - 1}
                            previousLabel={'<'}
                            nextLabel={'>'}
                            onPageChange={this.handlePageChange}
                            pageCount={this.state.pageCount}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            //@ts-ignore
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const InjectedProviderLookupDialog = inject<
  IStores,
  IProviderLookupDialogProps,
  Partial<IProviderLookupDialogProps>,
  any
>((stores: IStores) => ({
  addProviderLocation: stores.schedules.addProviderLocation,
  clearData: stores.locations.clearData,
  clearLocations: stores.locations.clearLocations,
  close: stores.schedules.closeProviderLookupDialog,
  currentAppOrganization: stores.global.currentAppOrganization,
  getAllProviderTypes: stores.providers.getAllProviderTypes,
  getAllTags: stores.tags.getAllTags,
  getScheduleById: stores.schedules.getScheduleById,
  isOpen: stores.schedules.isProviderLookupDialogOpen,
  providerTypes: stores.providers.providerTypes,
  selectedAddress: stores.locations.selectedAddress,
  selectedDistance: stores.locations.selectedDistance,
  selectedEpisodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  selectedPatient: stores.patients.selectedPatient,
  selectedProviderTypeId: stores.locations.selectedProviderTypeId,
  selectedSchedule: stores.schedules.selectedSchedule,
  selectedTags: stores.locations.selectedTags,
  setDistance: stores.locations.setDistance,
  setSelectedAddress: stores.locations.setSelectedAddress,
  setSelectedProcedureFilters: stores.locations.setSelectedProcedureFilters,
  setSelectedProviderGroup: stores.locations.setSelectedProviderGroup,
  setSelectedProviderLocation: stores.locations.setSelectedProviderLocation,
  setSelectedProviderTypeId: stores.locations.setSelectedProviderTypeId,
  setSelectedTags: stores.locations.setSelectedTags,
  canFindLatLong: stores.locations.canFindLatLong,
  setCanFindLat: stores.locations.setCanFindLat,
  tags: stores.tags.tags,
}))(ProviderLookupDialog)

export default withStyles(styles)(InjectedProviderLookupDialog)
