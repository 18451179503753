import { createStyles, Grid, TextField, WithStyles, withStyles } from '@material-ui/core'
import { Chat } from '@material-ui/icons'
import { DatePicker } from 'material-ui-pickers'
import { inject } from 'mobx-react'
import { Moment } from 'moment'
import React from 'react'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import SelectInput from '../common/SelectInput/SelectInput'
import TypeAheadFieldFilter from '../common/TypeAheadFieldFilter/TypeAheadFieldFilter'
import { DefaultCommunication, ICommunication, IProvider } from '../Definitions'
import { TagDto } from '../generated_client'
import { IStores } from '../Stores'
import EditCommunicationDialog from '../Communications/EditCommunicationDialog'

interface ICommunicationFormValues {
  contact: string
  locationId: string
  message: string
  occurredAtLocation: boolean
}

const styles = () => createStyles({})

interface IEditCommunicationsLogProps extends WithStyles<typeof styles> {
  clearFilters?: () => void
  dataTableManager?: IDataTableManager<ICommunication>
  isEditModalOpen?: boolean
  saveCommunication?: (communication: ICommunication) => Promise<void>
  openDialogWithCommunication?: (communication: ICommunication) => void
  selectedProvider?: IProvider
  selectedDateRange?: string
  selectedStartDate?: Date | null
  selectedEndDate?: Date | null
  selectedType?: string
  tags?: TagDto[]
  selectedTags?: TagDto[]
  setSelectedDateRange?: (dateRange: string) => void
  setSelectedDate?: (date: Date | null, isStartDate: boolean) => void
  setSelectedType?: (type: string) => void
  setSelectedTags?: (tags: TagDto[]) => void
  updateSearchFilter?: (value: string) => void
  getAllTags?: (filter: string, includeSystem: boolean) => void
  contentFilter?: string
}

class EditCommunicationsLog extends React.Component<IEditCommunicationsLogProps> {
  public componentWillUnmount() {
    this.props.clearFilters!()
  }
  public render() {
    const communicationDialog = this.props.isEditModalOpen && <EditCommunicationDialog />
    const filters = this.renderDateFilters()

    return (
      <>
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={false}
          enableShowInactives={true}
          enableToggleColumns={true}
          filterComponents={filters}
          title="Communication Log"
          icon={Chat}
          addButtonOnClick={this.openDialog(DefaultCommunication(true))}
          rowsPerPage={15}
          elevation={0}
          padding={0}
        />
        {communicationDialog}
      </>
    )
  }

  public openDialog = (communication: ICommunication) => () => {
    this.props.openDialogWithCommunication!(communication)
  }

  private getValue(item: string) {
    return item
  }

  private renderDateFilters() {
    const {
      selectedDateRange,
      selectedStartDate,
      selectedEndDate,
      selectedType,
      contentFilter,
      tags,
      selectedTags,
      setSelectedTags,
    } = this.props

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={4}>
              <SelectInput
                inputId="dateRange"
                getName={this.getValue}
                getValue={this.getValue}
                items={[
                  undefined,
                  'Today',
                  'Yesterday',
                  'Last 7 Days',
                  'Last 30 Days',
                  'Custom',
                ]}
                label="Date Range"
                name="dateRange"
                fullWidth
                onChange={this.onSelectedDateRangeChange()}
                value={selectedDateRange}
                shrinkLabel={!!selectedDateRange}
                outlined={true}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                value={selectedStartDate!}
                format="MM/DD/YYYY"
                label="Start Date"
                clearable
                variant="outlined"
                onChange={this.onDateChange(true)}
                InputLabelProps={{ shrink: !!selectedStartDate }}
                disabled={selectedDateRange !== 'Custom'}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                value={selectedEndDate!}
                format="MM/DD/YYYY"
                label="End Date"
                clearable
                variant="outlined"
                onChange={this.onDateChange(false)}
                InputLabelProps={{ shrink: !!selectedEndDate }}
                disabled={selectedDateRange !== 'Custom'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={4}>
              <SelectInput
                inputId="type"
                getName={this.getValue}
                getValue={this.getValue}
                items={[undefined, 'Email', 'Phone', 'Text', 'Fax', 'Other']}
                label="Type"
                name="type"
                fullWidth
                onChange={this.onselectedTypeChange()}
                value={selectedType}
                shrinkLabel={!!selectedType}
                outlined={true}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Content"
                margin="none"
                name="content"
                onChange={this.onSearchChanged}
                value={contentFilter}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <TypeAheadFieldFilter
            data-cy="tagsFilter"
            label="Tags"
            getName={this.getTagName}
            getValue={this.getTagValue}
            fullWidth
            getItems={this.getTags}
            items={tags || []}
            onChipChange={setSelectedTags!}
            selectedItems={selectedTags || []}
          />
        </Grid>
      </Grid>
    )
  }

  private getTags = (filter: string) => {
    this.props.getAllTags!(filter, true)
  }

  public getTagName(tag: TagDto) {
    return tag ? tag.name! : ''
  }

  public getTagValue(tag: TagDto) {
    return tag ? tag.id! : ''
  }

  private onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    this.props.updateSearchFilter!(value)
  }

  private onSelectedDateRangeChange =
    () => (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.props.setSelectedDateRange!(event.target.value)
    }

  private onselectedTypeChange = () => (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setSelectedType!(event.target.value)
  }

  private onDateChange = (isStartDate: boolean) => (date: Moment) => {
    this.props.setSelectedDate!(date ? date.toDate() : null, isStartDate)
  }
}

const InjectedEditCommunicationsLog = inject<
  IStores,
  IEditCommunicationsLogProps,
  Partial<IEditCommunicationsLogProps>,
  any
>((stores: IStores) => ({
  clearFilters: stores.communications.clearFilters,
  dataTableManager: stores.communications.fullDataTableStore,
  getAllTags: stores.tags.getAllTags,
  isEditModalOpen: stores.communications.isEditModalOpen,
  loadCommunications: stores.communications.getColumnSettingsAndCommunications,
  openDialogWithCommunication: stores.communications.openDialogWithCommunication,
  saveCommunication: stores.communications.saveCommunication,
  selectedDateRange: stores.communications.selectedDateRange,
  selectedEndDate: stores.communications.selectedEndDate,
  selectedProvider: stores.providers.selectedProvider,
  selectedStartDate: stores.communications.selectedStartDate,
  selectedTags: stores.communications.selectedTags,
  selectedType: stores.communications.selectedType,
  setSelectedDate: stores.communications.setSelectedDate,
  setSelectedDateRange: stores.communications.setSelectedDateRange,
  setSelectedTags: stores.communications.setSelectedTags,
  setSelectedType: stores.communications.setSelectedType,
  tags: stores.tags.tags,
  updateSearchFilter: stores.communications.updateSearchFilter,
}))(EditCommunicationsLog)

export default withStyles(styles)(InjectedEditCommunicationsLog)
export { ICommunicationFormValues }
