export enum QuoteStatus {
  NeedsQuote,
  Pending /* No longer in use at the moment */,
  NeedsRecon,
  Reconciled,
  Complete /* No longer in use at the moment */,
  Imported,
  Draft,
  Quoted,
  PendingContract,
  PendingCareCoordinator,
  PendingFinance,
  Invoiced /* No longer in use at the moment */,
  Dismissed,
}
