import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { Info } from '@material-ui/icons'
import { Form, Formik, FormikActions, FormikProps } from 'formik'
import * as React from 'react'
import Button from '../Button'
import ResponsiveAppBar from '../ResponsiveAppBar'

export interface IESDialog<T> extends DialogProps {
  bottomActions?: (props: FormikProps<T>, boundFunction?: any) => React.ReactNode
  close: () => void
  closeButtonText?: string
  children: (props: FormikProps<T>) => React.ReactNode
  initialValues?: T
  isLoading?: boolean
  title: string
  titleIcon?: React.ReactElement<SvgIconProps>
  save?: (values: T, formikActions: FormikActions<T>) => void
  saveButtonText?: string
  disableSaveButton?: boolean
  showSaveButton?: boolean
  validationSchema?: any
  isInitialValid?: boolean
}

export default class ESDialog<T> extends React.Component<IESDialog<T>> {
  public componentDidMount() {
    ;(window as any).dialogFormikRef = React.createRef()
  }

  public render() {
    const {
      bottomActions = () => null,
      close,
      closeButtonText,
      initialValues,
      isLoading,
      save,
      saveButtonText,
      disableSaveButton,
      showSaveButton = true,
      title,
      titleIcon = <Info />,
      validationSchema,
      isInitialValid,
      ...rest
    } = this.props
    return (
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        onClose={close}
        {...rest}
        disableBackdropClick={true}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={save!}
          validationSchema={validationSchema}
          isInitialValid={isInitialValid}
          ref={(window as any).dialogFormikRef}
        >
          {(formikProps: FormikProps<T>) => (
            <Form>
              <ResponsiveAppBar title={title} pageIcon={titleIcon} isModal={true} />
              <DialogContent style={{ marginTop: 8 }}>
                {this.props.children(formikProps)}
              </DialogContent>
              <DialogActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ margin: '0px 20px 4px' }}
                >
                  <Grid item>{bottomActions(formikProps)}</Grid>
                  <Grid item>
                    <Button
                      onClick={close}
                      data-cy="close"
                      style={{ marginRight: '8px' }}
                    >
                      {closeButtonText ? closeButtonText : 'Close'}
                    </Button>
                    {showSaveButton && (
                      <Button
                        disabled={
                          disableSaveButton
                            ? disableSaveButton || !formikProps.isValid
                            : !formikProps.isValid
                        }
                        isLoading={
                          isLoading ||
                          formikProps.isValidating ||
                          formikProps.isSubmitting
                        }
                        color="primary"
                        onClick={formikProps.submitForm}
                        variant="contained"
                      >
                        {saveButtonText ? saveButtonText : 'Save'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  }
}
