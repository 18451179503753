import { Tooltip, Typography } from '@material-ui/core'
import { AssignmentTurnedIn, Block, Check, Edit, Info, Warning } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import Moment from 'moment'
import React from 'react'
import { IColumnSetting } from '../../common/ESDataTable'
import {
  DefaultScheduleAppointmentTask,
  IScheduleAppointmentTask,
} from '../../Definitions'
import {
  Client,
  IScheduleAppointmentTaskDto,
  ScheduleAppointmentTaskDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'
import SchedulesStore from '../SchedulesStore/SchedulesStore'

const ColumnSettingsDto = 'ScheduleAppointmentTaskDto'
const PatientFlowColumnSettings = 'PatientFlowDto'

export default class ScheduleAppointmentTasksStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public scheduleAppointmentTasks: IScheduleAppointmentTask[]
  @observable
  public selectedScheduleAppointmentTask?: IScheduleAppointmentTask

  @observable
  public selectedCareCoordinatorId: string
  @observable
  public selectedStartDate: Date | null = null
  @observable
  public selectedEndDate: Date | null = null
  @observable
  public filterByOverdue: boolean = false
  @observable
  public countAllOpenTasks: number = 0
  public dataTableStore: DataTableStore<
    IScheduleAppointmentTaskDto,
    IScheduleAppointmentTask
  >
  public patientFlowDataTableStore: DataTableStore<
    IScheduleAppointmentTaskDto,
    IScheduleAppointmentTask
  >

  constructor(
    private globalStore: GlobalStore,
    public routerStore: RouterStore,
    private client: Client,
    private scheduleStore: SchedulesStore
  ) {
    this.dataTableStore = new DataTableStore<
      IScheduleAppointmentTaskDto,
      IScheduleAppointmentTask
    >(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllScheduleAppointmentTasks(
          undefined,
          this.scheduleStore.selectedSchedule
            ? this.scheduleStore.selectedSchedule.id
            : '',
          undefined,
          undefined,
          undefined,
          undefined,
          filter,
          page,
          undefined,
          orderBy,
          includeInactives
        ),
      (scheduleAppointmentTask) =>
        this.setupScheduleAppointmentTasks(scheduleAppointmentTask, false)
    )
    this.patientFlowDataTableStore = new DataTableStore<
      IScheduleAppointmentTaskDto,
      IScheduleAppointmentTask
    >(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllScheduleAppointmentTasks(
          undefined,
          this.scheduleStore.selectedSchedule
            ? this.scheduleStore.selectedSchedule.id
            : '',
          this.selectedCareCoordinatorId,
          this.filterByOverdue,
          this.selectedStartDate,
          this.selectedEndDate,
          filter,
          page,
          undefined,
          orderBy,
          includeInactives
        ),
      (scheduleAppointmentTask) =>
        this.setupScheduleAppointmentTasks(
          _.orderBy(scheduleAppointmentTask, (x) => Moment(x.dueDate!), ['asc']),
          true
        )
    )
  }
  @action.bound
  public clearFilters() {
    this.selectedCareCoordinatorId = ''
    this.selectedEndDate = null
    this.selectedStartDate = null
    this.filterByOverdue = false
  }
  @action.bound
  public setSelectedCareCoordinatorId(id: string) {
    this.selectedCareCoordinatorId = id
    this.patientFlowDataTableStore.loadData()
  }
  @action.bound
  public setFilterByOverdue(filterByOverdue: boolean) {
    this.filterByOverdue = filterByOverdue
    this.patientFlowDataTableStore.loadData()
  }
  @action.bound
  public setSelectedStartDate(date: Date | null) {
    this.selectedStartDate = date
    this.patientFlowDataTableStore.loadData()
  }
  @action.bound
  public setSelectedEndDate(date: Date | null) {
    this.selectedEndDate = date
    this.patientFlowDataTableStore.loadData()
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedScheduleAppointmentTask = undefined
  }

  @action.bound
  public disableScheduleAppointmentTask(
    scheduleAppointmentTask: IScheduleAppointmentTask
  ) {
    this.isLoading = true
    return this.client
      .archiveScheduleAppointmentTask(scheduleAppointmentTask.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndScheduleAppointmentTasks(isPatientFlow: boolean = false) {
    if (!this.globalStore.user) {
      return Promise.resolve()
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(
        this.globalStore.user!.id,
        isPatientFlow ? PatientFlowColumnSettings : ColumnSettingsDto
      )
      .then((data) => {
        runInAction(() => {
          if (isPatientFlow) {
            this.patientFlowDataTableStore.setColumns(data.result)
            const sortedColumn = _.find(
              this.patientFlowDataTableStore.columns,
              (col) => !!col.sort
            )
            if (sortedColumn) {
              this.patientFlowDataTableStore.setTableOrderBy(sortedColumn.sort)
            }
            this.patientFlowDataTableStore.loadData()
          } else {
            this.dataTableStore.setColumns(data.result)
            const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
            if (sortedColumn) {
              this.dataTableStore.setTableOrderBy(sortedColumn.sort)
            }
            this.dataTableStore.loadData()
          }
          this.addCustomColumn(isPatientFlow)
          this.addCustomComment()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  private addCustomColumn(isPatientFlow: boolean) {
    const columnSetting = {
      columnName: '',
      fieldName: 'customColumnCell',
      isVisible: true,
      type: 'React.Component',
    } as IColumnSetting

    if (!isPatientFlow) {
      this.dataTableStore.columns.splice(0, 0, columnSetting)
    } else {
      this.patientFlowDataTableStore.columns.splice(0, 0, columnSetting)
    }
  }

  private addCustomComment() {
    const columnSetting = {
      columnName: 'Comments',
      fieldName: 'customCommentCell',
      isVisible: true,
      type: 'React.Component',
    } as IColumnSetting

    this.dataTableStore.columns.splice(5, 1, columnSetting)
  }

  public isTaskRed(data: IScheduleAppointmentTask) {
    if (
      data &&
      !data.dateCompleted &&
      !data.taskNotApplicable &&
      data.schedule &&
      data.schedule.appointmentDate
    ) {
      const currentDate = new Date()
      const dueDate = new Date(data.schedule.appointmentDate)
      if (data.timing === 'Before Appointment Date') {
        dueDate.setDate(dueDate.getDate() - data.days)
      } else if (data.timing === 'After Appointment Date') {
        dueDate.setDate(dueDate.getDate() + data.days)
      } else if (data.timing === '' || data.timing === undefined) {
        return false
      }

      if (currentDate > dueDate) {
        return true
      }
    }
    return false
  }

  @action.bound
  public getAllScheduleAppointmentTasks() {
    this.isLoading = true
    return this.client
      .getAllScheduleAppointmentTasks()
      .then((resp: SwaggerResponse<IScheduleAppointmentTaskDto[]>) =>
        runInAction(() => {
          this.scheduleAppointmentTasks = this.setupScheduleAppointmentTasks(
            resp.result,
            false
          )
        })
      )
  }

  @action.bound
  public openDialogWithScheduleAppointmentTask(
    scheduleAppointmentTask: IScheduleAppointmentTask
  ) {
    this.isModalOpen = true
    this.selectedScheduleAppointmentTask = scheduleAppointmentTask
  }

  @action.bound
  public recoverScheduleAppointmentTask(
    scheduleAppointmentTask: IScheduleAppointmentTask
  ) {
    this.isLoading = true
    return this.client
      .reactivateScheduleAppointmentTask(scheduleAppointmentTask.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveScheduleAppointmentTask(scheduleAppointmentTask: IScheduleAppointmentTask) {
    this.isLoading = true
    let promise
    const scheduleAppointmentTaskDto = new ScheduleAppointmentTaskDto(
      scheduleAppointmentTask
    )
    if (scheduleAppointmentTask.isNew) {
      promise = this.client.createScheduleAppointmentTask(scheduleAppointmentTaskDto)
    } else {
      promise = this.client.updateScheduleAppointmentTask(
        scheduleAppointmentTask.id,
        scheduleAppointmentTaskDto
      )
    }
    return promise
      .then(() => {
        this.resetUIState(scheduleAppointmentTask.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
          this.isModalOpen = false
          window.location.reload()
        })
      )
  }

  public updateTaskApplicable(
    scheduleAppointmentTask: IScheduleAppointmentTask,
    isTaskNotApplicable: boolean
  ) {
    scheduleAppointmentTask.taskNotApplicable = isTaskNotApplicable
    this.saveScheduleAppointmentTask(scheduleAppointmentTask)
  }
  public markComplete(scheduleAppointmentTask: IScheduleAppointmentTask) {
    scheduleAppointmentTask.dateCompleted = new Date()
    this.saveScheduleAppointmentTask(scheduleAppointmentTask)
  }

  private customizeScheduleAppointmentTaskRecord = (
    scheduleAppointmentTask: IScheduleAppointmentTask,
    isPatientFlow: boolean
  ) => {
    if (this.isTaskRed(scheduleAppointmentTask)) {
      scheduleAppointmentTask.customColumnCell = <Warning htmlColor="#CC3F3F" />
    }

    if (
      scheduleAppointmentTask.comments !== undefined &&
      scheduleAppointmentTask.comments !== ''
    ) {
      const tooltipContent = (
        <Typography color="inherit">
          {scheduleAppointmentTask.abbreviatedComments}
        </Typography>
      )
      const commentToolTip = (
        <Tooltip title={tooltipContent} placement="top" interactive>
          <Info color="action" />
        </Tooltip>
      )
      scheduleAppointmentTask.customCommentCell = commentToolTip
    }

    return this.setupScheduleAppointmentTaskMenuItems(
      scheduleAppointmentTask,
      isPatientFlow
    )
  }

  private setupScheduleAppointmentTasks = (
    scheduleAppointmentTasks: IScheduleAppointmentTaskDto[],
    isPatientFlow: boolean
  ) => {
    return scheduleAppointmentTasks.map((x) =>
      this.customizeScheduleAppointmentTaskRecord(this.addDefaultFields(x), isPatientFlow)
    )
  }

  private addDefaultFields = (
    scheduleAppointmentTask: IScheduleAppointmentTaskDto
  ): IScheduleAppointmentTask => {
    return { ...DefaultScheduleAppointmentTask(), ...scheduleAppointmentTask }
  }

  private setupScheduleAppointmentTaskMenuItems = (
    scheduleAppointmentTask: IScheduleAppointmentTask,
    isPatientFlow: boolean
  ) => {
    scheduleAppointmentTask.menuItems = []

    if (!isPatientFlow) {
      scheduleAppointmentTask.menuItems.push({
        disabled:
          this.globalStore.currentAppOrganization!.id !==
          scheduleAppointmentTask.organizationId,
        icon: Edit,
        name: 'Edit',
        onClick: () =>
          this.openDialogWithScheduleAppointmentTask(scheduleAppointmentTask),
      })
      if (scheduleAppointmentTask.dateCompleted == undefined) {
        scheduleAppointmentTask.menuItems.push({
          color: '#32612D',
          disabled:
            this.globalStore.currentAppOrganization!.id !==
            scheduleAppointmentTask.organizationId,
          icon: AssignmentTurnedIn,
          isConfirm: false,
          name: 'Mark as Complete',
          onClick: () => {
            this.markComplete(scheduleAppointmentTask)
          },
        })
      }
      if (scheduleAppointmentTask.taskNotApplicable) {
        scheduleAppointmentTask.menuItems.push({
          color: '#29348F',
          disabled:
            this.globalStore.currentAppOrganization!.id !==
            scheduleAppointmentTask.organizationId,
          icon: Check,
          isConfirm: false,
          name: 'Mark as Applicable',
          onClick: () => {
            this.updateTaskApplicable(scheduleAppointmentTask, false)
          },
        })
      } else {
        scheduleAppointmentTask.menuItems.push({
          color: 'red',
          disabled:
            this.globalStore.currentAppOrganization!.id !==
            scheduleAppointmentTask.organizationId,
          icon: Block,
          isConfirm: false,
          name: 'Mark as Not Applicable',
          onClick: () => {
            this.updateTaskApplicable(scheduleAppointmentTask, true)
          },
        })
      }
    }
    return scheduleAppointmentTask
  }

  @action.bound
  public getCountAllOpenTasks() {
    return this.client.getCountAllOpenTasks().then((resp: SwaggerResponse<number>) =>
      runInAction(() => {
        this.countAllOpenTasks = resp.result
      })
    )
  }
}
