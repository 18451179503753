import { IHaveAddressFormValues } from '../common/AddressField/AddressField'
import {
  createAddressFormValues,
  toAddressDto,
} from '../common/AddressField/AddressFormValues'
import { DefaultInsuranceCompany, IInsuranceCompany } from '../Definitions'

export interface IInsuranceCompanyFormValues extends IHaveAddressFormValues {
  readonly id: string
  billingFaxNumber: string
  claimsEmail: string
  isActive: boolean
  name: string
  legacyId: string
  isSpecialPricing: boolean
  includeNurseCaseManager: boolean
}

function setDefaultValues(): IInsuranceCompanyFormValues {
  return {
    billingAddress: createAddressFormValues(),
    billingFaxNumber: '',
    claimsEmail: '',
    id: '',
    isActive: true,
    isSpecialPricing: false,
    includeNurseCaseManager: false,
    legacyId: '',
    name: '',
    physicalAddress: createAddressFormValues(),
  }
}

function setValuesFromInsuranceCompany(
  insuranceCompany: IInsuranceCompany
): IInsuranceCompanyFormValues {
  return {
    billingAddress: createAddressFormValues(insuranceCompany.billingAddress),
    billingFaxNumber: insuranceCompany.billingFaxNumber || '',
    claimsEmail: insuranceCompany.claimsEmail || '',
    id: insuranceCompany.id || '',
    isActive: insuranceCompany.isActive,
    isSpecialPricing: insuranceCompany.isSpecialPricing,
    includeNurseCaseManager: insuranceCompany.includeNurseCaseManager,
    legacyId: insuranceCompany.legacyId || '',
    name: insuranceCompany.name || '',
    physicalAddress: createAddressFormValues(insuranceCompany.physicalAddress),
  }
}

export function createIInsuranceCompanyFormValues(
  insuranceCompany?: IInsuranceCompany
): IInsuranceCompanyFormValues {
  if (insuranceCompany === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromInsuranceCompany(insuranceCompany)
  }
}

export async function toIInsuranceCompany(
  formValues: IInsuranceCompanyFormValues,
  original?: IInsuranceCompany
): Promise<IInsuranceCompany> {
  const insuranceCompany = original === undefined ? DefaultInsuranceCompany() : original
  const billingAddress = await toAddressDto(formValues.billingAddress!)
  const physicalAddress = await toAddressDto(formValues.physicalAddress!)

  return {
    ...insuranceCompany,
    billingAddress,
    billingFaxNumber: formValues.billingFaxNumber,
    claimsEmail: formValues.claimsEmail,
    id: formValues.id,
    isActive: formValues.isActive,
    isSpecialPricing: formValues.isSpecialPricing,
    includeNurseCaseManager: formValues.includeNurseCaseManager,
    legacyId: formValues.legacyId,
    name: formValues.name,
    physicalAddress,
  }
}
