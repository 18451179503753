import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { Form, Formik, FormikActions } from 'formik'
import * as React from 'react'
import { inject } from 'mobx-react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { RouteComponentProps } from 'react-router'
import { Prompt } from 'react-router-dom'
import ResponsiveAppBar from '../../common/ResponsiveAppBar'
import { IProviderTypeDto } from '../../generated_client'
import * as Yup from 'yup'
import Constants from '../../config'
import { createIQuoteFormValues, IQuoteFormValues } from '../QuoteFormValues'
import { PreviewInvoicePDF } from 'src/services/QuoteService'
import { IStores } from 'src/Stores'
import { formatCurrency } from 'src/utils/Formatter'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import InvoiceQuoteHeader from './InvoiceQuoteHeader'
import { GlobalInvoiceDTO } from 'src/viewModels/quotes/GlobalInvoiceDTO'
import { GlobalInvoiceLineItemDTO } from 'src/viewModels/quotes/GlobalInvoiceLineItemDTO'
import { Helmet } from 'react-helmet'
import { ChangeEvent } from 'react'
import { IGlobalInvoiceCPTCodeDTO } from 'src/viewModels/quotes/GlobalInvoiceCPTCodeDTO'
import { ListAlt } from '@material-ui/icons'
import { QuoteInboxItem } from 'src/viewModels/workflow/QuoteInboxItem'
import { ObjectiveProcessAction } from 'src/viewModels/workflow/ObjectiveProcessAction'
import {
  getDefaultQuoteInboxItem,
  processAction,
  processDynamicAction,
} from 'src/services/WorkflowService'
import { IGlobalInvoicePaymentSummary } from 'src/viewModels/InvoicePaymentSummaryResult'

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(5),
    },
    notes: {
      padding: spacing(2),
    },
    totals: {
      padding: spacing(2),
    },
    strikeOut: {
      textDecoration: 'line-through red',
    },
  })

const QuoteSchema = Yup.object().shape({
  adjustedCost: Yup.number()
    .transform(function (value, _originalValue) {
      return this.isType(value) ? value : -1
    })
    .min(0, 'Adjusted Cost must be 0 or greater')
    .max(1000000000000, 'Adjusted Cost must be 1000000000000 or less')
    .required('Adjusted Cost is required'),
  targetMargin: Yup.number()
    .transform(function (value, _originalValue) {
      return this.isType(value) ? value : -1
    })
    .min(0, 'Target Margin must be 0% or greater')
    .max(100, 'Target Margin must be 100% or less')
    .required('Target Margin is required'),
})

interface ICreateQuoteRouteParams {
  quoteId: string
  actionId: string
}

interface ICreateQuoteProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<ICreateQuoteRouteParams> {
  getAllUsers?: (pageSize?: number, roleFilter?: string) => Promise<void>
  getLineItemsTotals?: (lineItems: any) => number
  getProviderTypes?: () => Promise<void>
  getTypesByCategory?: (type: string) => Promise<void>
  isContractModalOpen?: boolean
  isMultpleProcedureDiscountDialogOpen?: boolean
  loadQuote?: (quoteId: string, reload?: boolean) => Promise<void>
  fetchCptDescriptions?: () => Promise<void>
  cptDescriptionList?: IGlobalInvoiceCPTCodeDTO[]
  suffix?: string
  setInvoiceSuffix?: (suffix: string) => void
  providerTypes?: IProviderTypeDto[]
  rerouteToPath?: (path: string) => void
  selectedQuote?: IQuoteDTO
  openModal?: (quote: IQuoteDTO, modalSwitch: string) => void
  openStatusModal?: (quote: IQuoteDTO) => void
  isNum?: (str: any) => boolean
  selectedQuoteInboxItem?: QuoteInboxItem
  setSelectedQuoteInboxItem?: (selectedQuoteInboxItem: QuoteInboxItem) => void
  getInvoicePaymentSummary: () => Promise<void>
  selectedInvoicePaymentSummary?: IGlobalInvoicePaymentSummary
}

interface ICreateQuoteState {
  reload: boolean
  generatedLink: string
  expandSidebar: boolean
  expandMain: boolean
  lineItemDirty: boolean
  pcListStringify: string
  tcListStringify: string
  anListStringify: string
  ptListStringify: string
  miListStringify: string
  checkboxes: Map<string, boolean>
  osxTotal: number
  ucTotal: number
  pdfLoading: boolean
  actionId: string
  quoteId: string
  invoiceLoading: boolean
}

class CreateGlobalInvoice extends React.Component<ICreateQuoteProps, ICreateQuoteState> {
  constructor(props: ICreateQuoteProps) {
    super(props)
    this.state = {
      reload: true,
      generatedLink: '',
      expandMain: true,
      expandSidebar: true,
      lineItemDirty: false,
      pcListStringify: '',
      tcListStringify: '',
      anListStringify: '',
      ptListStringify: '',
      miListStringify: '',
      checkboxes: new Map<string, boolean>(),
      osxTotal: 0,
      ucTotal: 0,
      pdfLoading: false,
      actionId: '',
      quoteId: '',
      invoiceLoading: true,
    }
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this)

    for (
      let i = 0;
      i < (this.props.selectedQuote?.summarizedPCInvoiceLineItems?.length ?? 0);
      i++
    ) {
      this.state.checkboxes[`InitialEvalpc${i}`] = false
      this.state.checkboxes[`SurgeonFeespc${i}`] = false
      this.state.checkboxes[`AssistantSurgeonFeespc${i}`] = false
      this.state.checkboxes[`PostOp90pc${i}`] = false
      this.state.checkboxes[`PostOp10pc${i}`] = false
      this.state.checkboxes[`PermanentImpairmentpc${i}`] = false
      this.state.checkboxes[`FaciltyFeepc${i}`] = false
      this.state.checkboxes[`Hardwarepc${i}`] = false
      this.state.checkboxes[`Inpatientpc${i}`] = false
      this.state.checkboxes[`AnesthesiaServicepc${i}`] = false
      this.state.checkboxes[`pcNewLineItem${i}`] = false
    }
    for (
      let i = 0;
      i < (this.props.selectedQuote?.summarizedTCInvoiceLineItems?.length ?? 0);
      i++
    ) {
      this.state.checkboxes[`InitialEvaltc${i}`] = false
      this.state.checkboxes[`SurgeonFeestc${i}`] = false
      this.state.checkboxes[`AssistantSurgeonFeestc${i}`] = false
      this.state.checkboxes[`PostOp90tc${i}`] = false
      this.state.checkboxes[`PostOp10tc${i}`] = false
      this.state.checkboxes[`PermanentImpairmenttc${i}`] = false
      this.state.checkboxes[`FaciltyFeetc${i}`] = false
      this.state.checkboxes[`Hardwaretc${i}`] = false
      this.state.checkboxes[`Inpatienttc${i}`] = false
      this.state.checkboxes[`AnesthesiaServicetc${i}`] = false
      this.state.checkboxes[`tcNewLineItem${i}`] = false
    }

    for (
      let i = 0;
      i < (this.props.selectedQuote?.summarizedANInvoiceLineItems?.length ?? 0);
      i++
    ) {
      this.state.checkboxes[`AnesthesiaServicean${i}`] = false
      this.state.checkboxes[`anNewLineItem${i}`] = false
    }

    for (
      let i = 0;
      i < (this.props.selectedQuote?.summarizedPTInvoiceLineItems?.length ?? 0);
      i++
    ) {
      this.state.checkboxes[`PhysicalTherapy90pt${i}`] = false
      this.state.checkboxes[`PhysicalTherapy10pt${i}`] = false
      this.state.checkboxes[`PhysicalTherapySinglept${i}`] = false
      this.state.checkboxes[`PhysicalTherapyWorkConditioning${i}`] = false
      this.state.checkboxes[`ptNewLineItem${i}`] = false
    }

    for (
      let i = 0;
      i < (this.props.selectedQuote?.miscellaneousLineItems?.length ?? 0);
      i++
    ) {
      this.state.checkboxes[`miNewLineItem${i}`] = false
    }
  }

  public forceUpdateHandler() {
    var newPC = JSON.stringify(
      this.props.selectedQuote?.professionalComponentLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )
    var newTC = JSON.stringify(
      this.props.selectedQuote?.technicalComponentLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )
    var newAN = JSON.stringify(
      this.props.selectedQuote?.anesthesiaLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )
    var newPT = JSON.stringify(
      this.props.selectedQuote?.physicalTherapyLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )
    var newMI = JSON.stringify(
      this.props.selectedQuote?.miscellaneousLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )

    this.setState({
      lineItemDirty:
        this.state.pcListStringify != newPC ||
        this.state.tcListStringify != newTC ||
        this.state.anListStringify != newAN ||
        this.state.ptListStringify != newPT ||
        this.state.miListStringify != newMI,
    })

    this.forceUpdate()
  }
  public async componentDidMount() {
    await this.setup()
  }

  public getLineItemStyle = (deleted: boolean) => {
    if (deleted) {
      return this.props.classes.strikeOut
    } else {
      return undefined
    }
  }

  getTotalCharge = () => {
    var total = 0
    var ucTotal = 0
    this.props.selectedQuote?.summarizedPCInvoiceLineItems?.forEach((line) => {
      total += line.reconciledCost ?? 0
      ucTotal += +(line.reconciledActualCost ?? 0)
    })
    this.props.selectedQuote?.summarizedTCInvoiceLineItems?.forEach((line) => {
      total += line.reconciledCost ?? 0
      ucTotal += +(line.reconciledActualCost ?? 0)
    })
    this.props.selectedQuote?.summarizedANInvoiceLineItems?.forEach((line) => {
      total += line.reconciledCost ?? 0
      ucTotal += +(line.reconciledActualCost ?? 0)
    })
    this.props.selectedQuote?.summarizedPTInvoiceLineItems?.forEach((line) => {
      total += line.reconciledCost ?? 0
      ucTotal += +(line.cost ?? 0)
    })
    this.props.selectedQuote?.miscellaneousLineItems?.forEach((line) => {
      total += line.reconciledCost ?? 0
      ucTotal += +(line.reconciledActualCost ?? 0)
    })

    this.setState({ osxTotal: total, ucTotal: ucTotal })
  }

  private async getQuoteInboxItem(quoteId: string) {
    if (quoteId && this.props.selectedQuoteInboxItem == undefined) {
      await getDefaultQuoteInboxItem(quoteId).then(async (response: any) => {
        this.props.setSelectedQuoteInboxItem!(response)
      })
    }
  }

  private routeBackToQuote(
    formikBag: FormikActions<IQuoteFormValues>,
    nextInboxId?: number
  ) {
    formikBag.setSubmitting(false)
    this.props.setSelectedQuoteInboxItem!(undefined as unknown as QuoteInboxItem)

    let routePath = nextInboxId
      ? `/workflow/quote/${this.props.selectedQuote!.id}/inbox/${nextInboxId}`
      : `/workflow/quote/${this.props.selectedQuote!.id}/default-inbox`

    this.props.rerouteToPath!(routePath)
    window.location.reload()
  }

  public async setup() {
    const {
      match,
      getProviderTypes,
      getTypesByCategory,
      loadQuote,
      fetchCptDescriptions,
      getAllUsers,
      getInvoicePaymentSummary,
    } = this.props

    this.props.setInvoiceSuffix!('')

    await Promise.all([
      getProviderTypes!(),
      getTypesByCategory!('PC'),
      getTypesByCategory!('TC'),
      getTypesByCategory!('MI'),
      getAllUsers!(5000, Constants.Roles.CareCoordinator),
      this.getQuoteInboxItem(match.params.quoteId),
    ]).then(() => {
      loadQuote!(match.params.quoteId, false).then(async () => {
        await Promise.all([fetchCptDescriptions!(), getInvoicePaymentSummary()]).then(
          () => {
            this.getTotalCharge()
            this.setState({
              lineItemDirty: false,
              pcListStringify: JSON.stringify(
                this.props.selectedQuote!.professionalComponentLineItems
              ),
              tcListStringify: JSON.stringify(
                this.props.selectedQuote!.technicalComponentLineItems
              ),
              anListStringify: JSON.stringify(
                this.props.selectedQuote!.anesthesiaLineItems
              ),
              ptListStringify: JSON.stringify(
                this.props.selectedQuote!.physicalTherapyLineItems
              ),
              miListStringify: JSON.stringify(
                this.props.selectedQuote!.miscellaneousLineItems
              ),
              actionId: match.params.actionId,
              invoiceLoading: false,
            })
          }
        )
      })
    })
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload, invoiceLoading: true }, () => {
      submitForm()
    })
  }

  public getGlobalLines = () => {
    var finalInvoiceList = new Array<GlobalInvoiceLineItemDTO>()
    var lineNum = 0
    var i = 0
    this.props.selectedQuote?.summarizedPCInvoiceLineItems?.forEach((line) => {
      var returnline = new GlobalInvoiceLineItemDTO(
        undefined,
        undefined,
        new Date(),
        undefined,
        line.reconciledCost,
        line.reconciledActualCost,
        line.providerId,
        line.reconciledActualLocationGroupName,
        line.physicianName,
        undefined,
        undefined,
        undefined,
        lineNum,
        'PC',
        undefined,
        this.state.checkboxes[`InitialEvalpc${i}`],
        this.state.checkboxes[`SurgeonFeespc${i}`],
        this.state.checkboxes[`AssistantSurgeonFeespc${i}`],
        this.state.checkboxes[`PostOp90pc${i}`],
        this.state.checkboxes[`PostOp10pc${i}`],
        this.state.checkboxes[`PermanentImpairmentpc${i}`],
        this.state.checkboxes[`FaciltyFeepc${i}`],
        this.state.checkboxes[`Hardwarepc${i}`],
        this.state.checkboxes[`Inpatientpc${i}`],
        this.state.checkboxes[`AnesthesiaServicepc${i}`],
        false,
        false,
        false,
        false,
        this.state.checkboxes[`pcNewLineItem${i}`]
      )
      finalInvoiceList.push(returnline)
      lineNum++
      i++
    })
    i = 0
    this.props.selectedQuote?.summarizedTCInvoiceLineItems?.forEach((line) => {
      var returnline = new GlobalInvoiceLineItemDTO(
        undefined,
        undefined,
        new Date(),
        undefined,
        line.reconciledCost,
        line.reconciledActualCost,
        line.providerId,
        line.reconciledActualLocationGroupName,
        undefined,
        undefined,
        undefined,
        undefined,
        lineNum,
        'TC',
        undefined,
        this.state.checkboxes[`InitialEvaltc${i}`],
        this.state.checkboxes[`SurgeonFeestc${i}`],
        this.state.checkboxes[`AssistantSurgeonFeestc${i}`],
        this.state.checkboxes[`PostOp90tc${i}`],
        this.state.checkboxes[`PostOp10tc${i}`],
        this.state.checkboxes[`PermanentImpairmenttc${i}`],
        this.state.checkboxes[`FaciltyFeetc${i}`],
        this.state.checkboxes[`Hardwaretc${i}`],
        this.state.checkboxes[`Inpatienttc${i}`],
        this.state.checkboxes[`AnesthesiaServicetc${i}`],
        false,
        false,
        false,
        false,
        this.state.checkboxes[`tcNewLineItem${i}`]
      )
      finalInvoiceList.push(returnline)
      lineNum++
      i++
    })
    i = 0
    this.props.selectedQuote?.summarizedANInvoiceLineItems?.forEach((line) => {
      var returnline = new GlobalInvoiceLineItemDTO(
        undefined,
        undefined,
        new Date(),
        undefined,
        line.reconciledCost,
        line.reconciledActualCost,
        line.providerId,
        line.reconciledActualLocationGroupName,
        line.physicianName,
        undefined,
        undefined,
        undefined,
        lineNum,
        'AN',
        undefined,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        this.state.checkboxes[`AnesthesiaServicean${i}`],
        false,
        false,
        false,
        false,
        this.state.checkboxes[`anNewLineItem${i}`]
      )
      finalInvoiceList.push(returnline)
      lineNum++
      i++
    })
    i = 0
    this.props.selectedQuote?.summarizedPTInvoiceLineItems?.forEach((line) => {
      var returnline = new GlobalInvoiceLineItemDTO(
        undefined,
        undefined,
        new Date(),
        undefined,
        line.reconciledCost,
        line.cost,
        line.providerId,
        line.providerName,
        undefined,
        undefined,
        undefined,
        line.displayNumberOfVisits,
        lineNum,
        'PT',
        undefined,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        this.state.checkboxes[`PhysicalTherapy90pt${i}`],
        this.state.checkboxes[`PhysicalTherapy10pt${i}`],
        this.state.checkboxes[`PhysicalTherapySinglept${i}`],
        this.state.checkboxes[`PhysicalTherapyWorkConditioning${i}`],
        this.state.checkboxes[`ptNewLineItem${i}`]
      )
      finalInvoiceList.push(returnline)
      lineNum++
      i++
    })
    i = 0
    this.props.selectedQuote?.miscellaneousLineItems
      ?.filter((x) => x.markExcluded != true)
      .forEach((line) => {
        var returnline = new GlobalInvoiceLineItemDTO(
          undefined,
          undefined,
          new Date(),
          undefined,
          line.reconciledCost,
          line.reconciledActualCost,
          line.providerId,
          line.reconciledActualLocationGroupName,
          undefined,
          undefined,
          undefined,
          line.lineDescription,
          lineNum,
          'MI',
          line.typeText,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          this.state.checkboxes[`miNewLineItem${i}`]
        )
        finalInvoiceList.push(returnline)
        lineNum++
        i++
      })
    return finalInvoiceList
  }

  public save = (
    values: IQuoteFormValues,
    formikBag: FormikActions<IQuoteFormValues>
  ) => {
    console.log(values)
    let globalLines = this.getGlobalLines()
    var globalCpts = this.props.cptDescriptionList
    var action = this.props.selectedQuoteInboxItem!.actions.find(
      (x) => x.id === +this.state.actionId
    )

    let globalDTO = new GlobalInvoiceDTO(
      undefined,
      undefined,
      new Date(),
      this.props.selectedQuote?.id,
      +this.state.osxTotal.toFixed(2),
      +this.state.ucTotal.toFixed(2),
      this.props.selectedQuote?.adjustedReconciledCost,
      this.props.selectedQuote?.dxCodes,
      this.props.suffix,
      globalLines,
      globalCpts
    )

    let data = new ObjectiveProcessAction()

    data.globalInvoiceData = globalDTO

    if (this.props.selectedQuoteInboxItem!.isDynamicItem) {
      processDynamicAction(
        data,
        this.props.selectedQuoteInboxItem!.inboxId.toString(),
        action!.id.toString(),
        this.props.selectedQuoteInboxItem!.associatedItemId.toString()
      )
        .then(() => {
          this.routeBackToQuote(formikBag, action?.nextInboxId)
        })
        .catch(() => {
          formikBag.setSubmitting(false)
          this.props.setSelectedQuoteInboxItem!(undefined as unknown as QuoteInboxItem)
        })
    } else {
      processAction(
        data,
        action!.id.toString(),
        this.props.selectedQuoteInboxItem!.id.toString()
      )
        .then(() => {
          this.routeBackToQuote(formikBag, action?.nextInboxId)
        })
        .catch(() => {
          formikBag.setSubmitting(false)
          this.props.setSelectedQuoteInboxItem!(undefined as unknown as QuoteInboxItem)
        })
    }
  }

  public previewInvoice() {
    let globalLines = this.getGlobalLines()
    var globalCpts = this.props.cptDescriptionList
    let globalDTO = new GlobalInvoiceDTO(
      undefined,
      undefined,
      new Date(),
      this.props.selectedQuote?.id,
      +this.state.osxTotal.toFixed(2),
      +this.state.ucTotal.toFixed(2),
      this.props.selectedQuote?.adjustedReconciledCost,
      this.props.selectedQuote?.dxCodes,
      this.props.suffix,
      globalLines,
      globalCpts
    )

    PreviewInvoicePDF(this.props.selectedQuote?.id!, globalDTO).finally(() => {
      this.setState({ pdfLoading: false })
    })
  }

  public get title() {
    return 'Create Global Invoice'
  }

  private cancel = () => {
    this.props.rerouteToPath!(
      `/workflow/quote/${this.props.selectedQuote!.id}/default-inbox`
    )
  }

  public handleExpandMain = () => {
    this.setState({ expandMain: !this.state.expandMain })
  }

  public handleExpandSidebar = () => {
    this.setState({ expandSidebar: !this.state.expandSidebar })
  }

  private mapPCQuoteInfo = () => {
    if (this.props.selectedQuote?.professionalComponentLineItems!.length == 0) {
      return <li>NONE</li>
    } else {
      return this.props.selectedQuote
        ?.professionalComponentLineItems!.filter((x) => x.markExcluded != true)
        .map((y, lineIdx) => (
          <React.Fragment>
            <li id={`pcQ-${lineIdx}`} style={{ fontSize: 'small' }}>
              {y.reconciledActualLocationGroupName ?? y.providerName}
              <ul>
                <li>{`CPT: ${y.reconciledActualCPT ?? y.cptCode}`}</li>
                <li>{`Type: ${y.typeText ? y.typeText : ''}`}</li>
                <li>{`Quoted: ${y.cost ? formatCurrency(y.cost.toString()) : ''}`}</li>
                <li>{`Reconciled: ${
                  y.reconciledCost ? formatCurrency(y.reconciledCost.toString()) : ''
                }`}</li>
              </ul>
            </li>
          </React.Fragment>
        ))
    }
  }

  private mapTCQuoteInfo = () => {
    if (this.props.selectedQuote?.technicalComponentLineItems!.length == 0) {
      return <></>
    } else {
      return this.props.selectedQuote
        ?.technicalComponentLineItems!.filter((x) => x.markExcluded != true)
        .map((y, lineIdx) => (
          <React.Fragment>
            <li id={`pcQ-${lineIdx}`} style={{ fontSize: 'small' }}>
              {y.reconciledActualLocationGroupName ?? y.providerName}
              <ul>
                <li>{`CPT: ${y.reconciledActualCPT ?? y.cptCode}`}</li>
                <li>{`Type: ${y.typeText ? y.typeText : ''}`}</li>
                <li>{`Quoted: ${y.cost ? formatCurrency(y.cost.toString()) : ''}`}</li>
                <li>{`Reconciled: ${
                  y.reconciledCost ? formatCurrency(y.reconciledCost.toString()) : ''
                }`}</li>
              </ul>
            </li>
          </React.Fragment>
        ))
    }
  }

  private mapANQuoteInfo = () => {
    if (this.props.selectedQuote?.anesthesiaLineItems!.length == 0) {
      return <li>NONE</li>
    } else {
      return this.props.selectedQuote
        ?.anesthesiaLineItems!.filter((x) => x.markExcluded != true)
        .map((y, lineIdx) => (
          <React.Fragment>
            <li id={`pcQ-${lineIdx}`} style={{ fontSize: 'small' }}>
              {y.reconciledActualLocationGroupName ?? y.providerName}
              <ul>
                <li>{`CPT: ${y.reconciledActualCPT ?? y.cptCode}`}</li>
                <li>{`Type: ${y.typeText ? y.typeText : ''}`}</li>
                <li>{`Quoted: ${y.cost ? formatCurrency(y.cost.toString()) : ''}`}</li>
                <li>{`Reconciled: ${
                  y.reconciledCost ? formatCurrency(y.reconciledCost.toString()) : ''
                }`}</li>
              </ul>
            </li>
          </React.Fragment>
        ))
    }
  }

  private mapPTQuoteInfo = () => {
    if (this.props.selectedQuote?.physicalTherapyLineItems!.length == 0) {
      return <li>NONE</li>
    } else {
      return this.props.selectedQuote
        ?.physicalTherapyLineItems!.filter((x) => x.markExcluded != true)
        .map((y, lineIdx) => (
          <React.Fragment>
            <li id={`pcQ-${lineIdx}`} style={{ fontSize: 'small' }}>
              {y.providerName}
              <ul>
                <li>{`# of Visits: ${y.numberOfVisits}`}</li>
                <li>{`Quoted per Visit: ${
                  y.costPerVisit ? formatCurrency(y.costPerVisit.toString()) : ''
                }`}</li>
                <li>{`Quoted: ${y.cost ? formatCurrency(y.cost.toString()) : ''}`}</li>
                <li>{`Reconciled: ${
                  y.reconciledCost ? formatCurrency(y.reconciledCost.toString()) : ''
                }`}</li>
              </ul>
            </li>
          </React.Fragment>
        ))
    }
  }

  private mapMIQuoteInfo = () => {
    if (this.props.selectedQuote?.miscellaneousLineItems!.length == 0) {
      return <li>NONE</li>
    } else {
      return this.props.selectedQuote
        ?.miscellaneousLineItems!.filter((x) => x.markExcluded != true)
        .map((y, lineIdx) => (
          <React.Fragment>
            <li id={`miQ-${lineIdx}`} style={{ fontSize: 'small' }}>
              {y.typeText}
              <ul>
                <li>{`Type: ${y.typeText ? y.typeText : ''}`}</li>
                <li>{`Description: ${y.lineDescription ?? ''}`}</li>
                <li>{`Provider: ${
                  y.reconciledActualLocationGroupName ?? y.providerName ?? ''
                }`}</li>
                <li>{`Quoted: ${y.cost ? formatCurrency(y.cost.toString()) : ''}`}</li>
                <li>{`Reconciled: ${
                  y.reconciledCost ? formatCurrency(y.reconciledCost.toString()) : ''
                }`}</li>
              </ul>
            </li>
          </React.Fragment>
        ))
    }
  }

  private changePCCost = (y: number) => (event: ChangeEvent<HTMLInputElement>) => {
    if (
      this.props.selectedQuote &&
      this.props.selectedQuote.summarizedPCInvoiceLineItems &&
      this.props.selectedQuote.summarizedPCInvoiceLineItems!.at(y) &&
      (this.props.isNum!(event.target.value) || event.target.value == '')
    ) {
      this.props.selectedQuote.summarizedPCInvoiceLineItems!.at(y)!.reconciledActualCost =
        event.target.value as unknown as number
      this.getTotalCharge()
    }
  }

  private changeCheckbox = (y: string) => {
    var before = this.state.checkboxes[y]
    this.state.checkboxes[y] = !before
    this.setState({})
  }

  private mapProfessionalGridData = () => {
    if (this.props.selectedQuote?.summarizedPCInvoiceLineItems?.length == 0) {
      return <></>
    } else {
      return this.props.selectedQuote?.summarizedPCInvoiceLineItems?.map((y, lineIdx) => (
        <React.Fragment>
          <TableRow
            key={`pcSummary-${lineIdx}`}
            style={{ height: '10px', backgroundColor: 'lightgrey' }}
          >
            <TableCell>{y.reconciledActualLocationGroupName}</TableCell>
            <TableCell>{y.physicianName}</TableCell>
            <TableCell></TableCell>
            <TableCell align="right">
              {formatCurrency((y.reconciledCost ?? 0).toString())}
            </TableCell>
            <TableCell align="right">
              <input
                name="pcUC"
                value={y.reconciledActualCost ?? 0}
                onChange={this.changePCCost(lineIdx)}
              />
            </TableCell>
            {this.props.selectedQuote?.globalInvoiceId != undefined && (
              <TableCell>
                <FormControl fullWidth>
                  <Checkbox
                    onChange={() => {
                      this.changeCheckbox(`pcNewLineItem${lineIdx}`)
                    }}
                    name={`pc${lineIdx}newLineItem`}
                    checked={this.state.checkboxes[`pcNewLineItem${lineIdx}`]}
                    value={this.state.checkboxes[`pcNewLineItem${lineIdx}`]}
                  />
                </FormControl>
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell colSpan={5} align="center">
              <Grid container item direction="row" xs={12}>
                <Grid container item direction="column" xs={12} lg={6}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Initial evaluation"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`InitialEvalpc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`InitialEvalpc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`InitialEvalpc${lineIdx}`]}
                          name={`pc${lineIdx}isInitialEvaluation`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Surgeon fees"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`SurgeonFeespc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`SurgeonFeespc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`SurgeonFeespc${lineIdx}`]}
                          name={`pc${lineIdx}isSurgeonFees`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Assistant surgeon fees"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`AssistantSurgeonFeespc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={
                            this.state.checkboxes[`AssistantSurgeonFeespc${lineIdx}`]
                          }
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={
                            this.state.checkboxes[`AssistantSurgeonFeespc${lineIdx}`]
                          }
                          name={`pcisAssistantSurgeonFees`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Facility Fees"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`FaciltyFeepc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`FaciltyFeepc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`FaciltyFeepc${lineIdx}`]}
                          name={`pc${lineIdx}isFaciltyFee`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Hardware/Implants"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`Hardwarepc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`Hardwarepc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`Hardwarepc${lineIdx}`]}
                          name={`pc${lineIdx}isHardwareImplants`}
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid container item direction="column" xs={12} lg={6}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Inpatient stay"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`Inpatientpc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`Inpatientpc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`Inpatientpc${lineIdx}`]}
                          name={`pc${lineIdx}isInpatientStay`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Anesthesia services"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`AnesthesiaServicepc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`AnesthesiaServicepc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`AnesthesiaServicepc${lineIdx}`]}
                          name={`pc${lineIdx}isAnesthesiaService`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Post-operative care with surgeon through 90 days"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`PostOp90pc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`PostOp90pc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`PostOp90pc${lineIdx}`]}
                          name={`pc${lineIdx}isPostOpWithSurgeon90Days`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Post-operative care with surgeon through 10 days"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`PostOp10pc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`PostOp10pc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`PostOp10pc${lineIdx}`]}
                          name={`pc${lineIdx}isPostOpWithSurgeon10Days`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Permanent impairment rating (PPI / PPD)"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`PermanentImpairmentpc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={
                            this.state.checkboxes[`PermanentImpairmentpc${lineIdx}`]
                          }
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`PermanentImpairmentpc${lineIdx}`]}
                          name={`pc${lineIdx}isPermanentImpairment`}
                        />
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))
    }
  }

  private changeTCCost = (y: number) => (event: ChangeEvent<HTMLInputElement>) => {
    if (
      this.props.selectedQuote &&
      this.props.selectedQuote.summarizedTCInvoiceLineItems &&
      this.props.selectedQuote.summarizedTCInvoiceLineItems!.at(y) &&
      (this.props.isNum!(event.target.value) || event.target.value == '')
    ) {
      this.props.selectedQuote.summarizedTCInvoiceLineItems!.at(y)!.reconciledActualCost =
        event.target.value as unknown as number
      this.getTotalCharge()
    }
  }

  private mapTechnicalGridData = () => {
    if (this.props.selectedQuote?.summarizedTCInvoiceLineItems!.length == 0) {
      return <></>
    } else {
      return this.props.selectedQuote?.summarizedTCInvoiceLineItems!.map((y, lineIdx) => (
        <React.Fragment>
          <TableRow
            key={`tcSummary-${lineIdx}`}
            style={{ height: '10px', backgroundColor: 'lightgrey' }}
          >
            <TableCell>{y.reconciledActualLocationGroupName}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="right">
              {formatCurrency((y.reconciledCost ?? 0).toString())}
            </TableCell>
            <TableCell align="right">
              <input
                name="tcUC"
                value={y.reconciledActualCost}
                onChange={this.changeTCCost(lineIdx)}
              />
            </TableCell>
            {this.props.selectedQuote?.globalInvoiceId != undefined && (
              <TableCell>
                <FormControl fullWidth>
                  <Checkbox
                    onChange={() => {
                      this.changeCheckbox(`tcNewLineItem${lineIdx}`)
                    }}
                    name={`tc${lineIdx}newLineItem`}
                    checked={this.state.checkboxes[`tcNewLineItem${lineIdx}`]}
                    value={this.state.checkboxes[`tcNewLineItem${lineIdx}`]}
                  />
                </FormControl>
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell colSpan={5} align="center">
              <Grid container item direction="row" xs={12}>
                <Grid container item direction="column" xs={12} lg={6}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Initial evaluation"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`InitialEvaltc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`InitialEvaltc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`InitialEvaltc${lineIdx}`]}
                          name={`tc${lineIdx}isInitialEvaluation`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Surgeon fees"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`SurgeonFeestc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`SurgeonFeestc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`SurgeonFeestc${lineIdx}`]}
                          name={`tc${lineIdx}isSurgeonFees`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Assistant surgeon fees"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`AssistantSurgeonFeestc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={
                            this.state.checkboxes[`AssistantSurgeonFeestc${lineIdx}`]
                          }
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={
                            this.state.checkboxes[`AssistantSurgeonFeestc${lineIdx}`]
                          }
                          name={`tc${lineIdx}isAssistantSurgeonFees`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Facility Fees"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`FaciltyFeetc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`FaciltyFeetc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`FaciltyFeetc${lineIdx}`]}
                          name={`tc${lineIdx}isFaciltyFee`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Hardware/Implants"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`Hardwaretc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`Hardwaretc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`Hardwaretc${lineIdx}`]}
                          name={`tc${lineIdx}isHardwareImplants`}
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid container item direction="column" xs={12} lg={6}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Inpatient stay"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`Inpatienttc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`Inpatienttc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`Inpatienttc${lineIdx}`]}
                          name={`tc${lineIdx}isInpatientStay`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Anesthesia services"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`AnesthesiaServicetc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`AnesthesiaServicetc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`AnesthesiaServicetc${lineIdx}`]}
                          name={`tc${lineIdx}isAnesthesiaService`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Post-operative care with surgeon through 90 days"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`PostOp90tc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`PostOp90tc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`PostOp90tc${lineIdx}`]}
                          name={`tc${lineIdx}isPostOpWithSurgeon90Days`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Post-operative care with surgeon through 10 days"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`PostOp10tc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={this.state.checkboxes[`PostOp10tc${lineIdx}`]}
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`PostOp10tc${lineIdx}`]}
                          name={`tc${lineIdx}isPostOpWithSurgeon10Days`}
                        />
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Permanent impairment rating (PPI / PPD)"
                      labelPlacement="end"
                      onChange={() => {
                        this.changeCheckbox(`PermanentImpairmenttc${lineIdx}`)
                      }}
                      control={
                        <Checkbox
                          checked={
                            this.state.checkboxes[`PermanentImpairmenttc${lineIdx}`]
                          }
                          inputProps={{
                            id: 'bilateral',
                          }}
                          value={this.state.checkboxes[`PermanentImpairmenttc${lineIdx}`]}
                          name={`tc${lineIdx}isPermanentImpairment`}
                        />
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))
    }
  }

  private changeANCost = (y: number) => (event: ChangeEvent<HTMLInputElement>) => {
    if (
      this.props.selectedQuote &&
      this.props.selectedQuote.summarizedANInvoiceLineItems &&
      this.props.selectedQuote.summarizedANInvoiceLineItems!.at(y) &&
      (this.props.isNum!(event.target.value) || event.target.value == '')
    ) {
      this.props.selectedQuote.summarizedANInvoiceLineItems!.at(y)!.reconciledActualCost =
        event.target.value as unknown as number
      this.getTotalCharge()
    }
  }

  private mapAnesthesiaGridData = () => {
    if (this.props.selectedQuote?.summarizedANInvoiceLineItems!.length == 0) {
      return <></>
    } else {
      return this.props.selectedQuote?.summarizedANInvoiceLineItems!.map((y, lineIdx) => (
        <React.Fragment>
          <TableRow
            key={`anSummary-${lineIdx}`}
            style={{ height: '10px', backgroundColor: 'lightgrey' }}
          >
            <TableCell>{y.reconciledActualLocationGroupName}</TableCell>
            <TableCell>{y.physicianName}</TableCell>
            <TableCell></TableCell>
            <TableCell align="right">
              {formatCurrency((y.reconciledCost ?? 0).toString())}
            </TableCell>
            <TableCell align="right">
              <input
                name="anUC"
                value={y.reconciledActualCost}
                onChange={this.changeANCost(lineIdx)}
              />
            </TableCell>
            {this.props.selectedQuote?.globalInvoiceId != undefined && (
              <TableCell>
                <FormControl fullWidth>
                  <Checkbox
                    name={`an${lineIdx}newLineItem`}
                    onChange={() => {
                      this.changeCheckbox(`anNewLineItem${lineIdx}`)
                    }}
                    checked={this.state.checkboxes[`anNewLineItem${lineIdx}`]}
                    value={this.state.checkboxes[`anNewLineItem${lineIdx}`]}
                  />
                </FormControl>
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell colSpan={5} align="center">
              <Grid container item direction="row" xs={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    label="Anesthesia services"
                    labelPlacement="end"
                    onChange={() => {
                      this.changeCheckbox(`AnesthesiaServicean${lineIdx}`)
                    }}
                    control={
                      <Checkbox
                        checked={this.state.checkboxes[`AnesthesiaServicean${lineIdx}`]}
                        inputProps={{
                          id: 'bilateral',
                        }}
                        value={this.state.checkboxes[`AnesthesiaServicean${lineIdx}`]}
                        name={`an${lineIdx}isAnesthesiaService`}
                      />
                    }
                  />
                </FormControl>
              </Grid>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))
    }
  }

  private changePTCost = (y: number) => (event: ChangeEvent<HTMLInputElement>) => {
    if (
      this.props.selectedQuote &&
      this.props.selectedQuote.summarizedPTInvoiceLineItems &&
      this.props.selectedQuote.summarizedPTInvoiceLineItems!.at(y) &&
      (this.props.isNum!(event.target.value) || event.target.value == '')
    ) {
      this.props.selectedQuote.summarizedPTInvoiceLineItems!.at(y)!.cost = event.target
        .value as unknown as number
      this.getTotalCharge()
    }
  }

  private changePTVisits = (y: number) => (event: ChangeEvent<HTMLInputElement>) => {
    if (
      this.props.selectedQuote &&
      this.props.selectedQuote.summarizedPTInvoiceLineItems &&
      this.props.selectedQuote.summarizedPTInvoiceLineItems!.at(y)
    ) {
      this.props.selectedQuote.summarizedPTInvoiceLineItems!.at(
        y
      )!.displayNumberOfVisits = event.target.value
      this.setState({})
    }
  }

  private mapPhysicalGridData = () => {
    if (this.props.selectedQuote?.summarizedPTInvoiceLineItems!.length == 0) {
      return <></>
    } else {
      return this.props.selectedQuote?.summarizedPTInvoiceLineItems!.map((y, lineIdx) => (
        <React.Fragment>
          <TableRow
            key={`ptSummary-${lineIdx}`}
            style={{ height: '10px', backgroundColor: 'lightgrey' }}
          >
            <TableCell>{y.providerName}</TableCell>
            <TableCell></TableCell>
            <TableCell>
              <input
                name="pt-Additional"
                value={y.displayNumberOfVisits}
                onChange={this.changePTVisits(lineIdx)}
              />
            </TableCell>
            <TableCell align="right">
              {formatCurrency((y.reconciledCost ?? 0).toString())}
            </TableCell>
            <TableCell align="right">
              <input
                name="ptUC"
                value={y.cost ?? 0}
                onChange={this.changePTCost(lineIdx)}
              />
            </TableCell>
            {this.props.selectedQuote?.globalInvoiceId != undefined && (
              <TableCell>
                <FormControl fullWidth>
                  <Checkbox
                    onChange={() => {
                      this.changeCheckbox(`ptNewLineItem${lineIdx}`)
                    }}
                    name={`pt${lineIdx}newLineItem`}
                    checked={this.state.checkboxes[`ptNewLineItem${lineIdx}`]}
                    value={this.state.checkboxes[`ptNewLineItem${lineIdx}`]}
                  />
                </FormControl>
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell colSpan={5} align="center">
              <Grid container item direction="row" xs={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    label="Physical therapy through 90 days post-op"
                    labelPlacement="end"
                    onChange={() => {
                      this.changeCheckbox(`PhysicalTherapy90pt${lineIdx}`)
                    }}
                    control={
                      <Checkbox
                        checked={this.state.checkboxes[`PhysicalTherapy90pt${lineIdx}`]}
                        inputProps={{
                          id: 'bilateral',
                        }}
                        value={this.state.checkboxes[`PhysicalTherapy90pt${lineIdx}`]}
                        name={`pt${lineIdx}isPhysicalTherapy90DaysPost`}
                      />
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    label="Physical therapy through 10 days post-op"
                    labelPlacement="end"
                    onChange={() => {
                      this.changeCheckbox(`PhysicalTherapy10pt${lineIdx}`)
                    }}
                    control={
                      <Checkbox
                        checked={this.state.checkboxes[`PhysicalTherapy10pt${lineIdx}`]}
                        inputProps={{
                          id: 'bilateral',
                        }}
                        value={this.state.checkboxes[`PhysicalTherapy10pt${lineIdx}`]}
                        name={`pt${lineIdx}isPhysicalTherapy10DaysPost`}
                      />
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    label="Physical therapy – single appt. home education plan"
                    labelPlacement="end"
                    onChange={() => {
                      this.changeCheckbox(`PhysicalTherapySinglept${lineIdx}`)
                    }}
                    control={
                      <Checkbox
                        checked={
                          this.state.checkboxes[`PhysicalTherapySinglept${lineIdx}`]
                        }
                        inputProps={{
                          id: 'bilateral',
                        }}
                        value={this.state.checkboxes[`PhysicalTherapySinglept${lineIdx}`]}
                        name={`pt${lineIdx}isPhysicalTherapySingleAppt`}
                      />
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    label="Physical therapy – Work conditioning"
                    labelPlacement="end"
                    onChange={() => {
                      this.changeCheckbox(`PhysicalTherapyWorkConditioning${lineIdx}`)
                    }}
                    control={
                      <Checkbox
                        checked={
                          this.state.checkboxes[
                            `PhysicalTherapyWorkConditioning${lineIdx}`
                          ]
                        }
                        inputProps={{
                          id: 'bilateral',
                        }}
                        value={
                          this.state.checkboxes[
                            `PhysicalTherapyWorkConditioning${lineIdx}`
                          ]
                        }
                        name={`pt${lineIdx}isPhysicalTherapyWorkConditioning`}
                      />
                    }
                  />
                </FormControl>
              </Grid>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))
    }
  }

  private changeMICost = (y: number) => (event: ChangeEvent<HTMLInputElement>) => {
    if (
      this.props.selectedQuote &&
      this.props.selectedQuote.miscellaneousLineItems &&
      this.props.selectedQuote.miscellaneousLineItems!.filter(
        (x) => x.markExcluded != true
      ) &&
      this.props.selectedQuote
        .miscellaneousLineItems!.filter((x) => x.markExcluded != true)
        .at(y) &&
      (this.props.isNum!(event.target.value) || event.target.value == '')
    ) {
      this.props.selectedQuote
        .miscellaneousLineItems!.filter((x) => x.markExcluded != true)
        .at(y)!.reconciledActualCost = event.target.value as unknown as number
      this.getTotalCharge()
    }
  }

  private changeMIText = (y: number) => (event: ChangeEvent<HTMLInputElement>) => {
    if (
      this.props.selectedQuote &&
      this.props.selectedQuote.miscellaneousLineItems &&
      this.props.selectedQuote.miscellaneousLineItems!.filter(
        (x) => x.markExcluded != true
      ) &&
      this.props.selectedQuote
        .miscellaneousLineItems!.filter((x) => x.markExcluded != true)
        .at(y)
    ) {
      this.props.selectedQuote
        .miscellaneousLineItems!.filter((x) => x.markExcluded != true)
        .at(y)!.lineDescription = event.target.value
      this.setState({})
    }
  }

  private mapMiscGridData = () => {
    if (this.props.selectedQuote?.miscellaneousLineItems!.length == 0) {
      return <></>
    } else {
      return this.props.selectedQuote
        ?.miscellaneousLineItems!.filter((x) => x.markExcluded != true)
        .map((y, lineIdx) => (
          <React.Fragment>
            <TableRow key={`miSummary-${lineIdx}`}>
              <TableCell>{`${y.typeText} ${
                y.providerName || y.reconciledActualLocationGroupName
                  ? '- ' + y.reconciledActualLocationGroupName ?? y.providerName
                  : ''
              }`}</TableCell>
              <TableCell></TableCell>
              <TableCell>
                <input
                  id="mi-Additional"
                  value={y.lineDescription}
                  onChange={this.changeMIText(lineIdx)}
                />
              </TableCell>
              <TableCell align="right">
                {formatCurrency((y.reconciledCost ?? y.cost ?? 0).toString())}
              </TableCell>
              <TableCell align="right">
                <input
                  name="miUC"
                  value={y.reconciledActualCost ?? y.actualCost ?? 0}
                  onChange={this.changeMICost(lineIdx)}
                />
              </TableCell>
              {this.props.selectedQuote?.globalInvoiceId != undefined && (
                <TableCell>
                  <FormControl fullWidth>
                    <Checkbox
                      name={`mi${lineIdx}newLineItem`}
                      onChange={() => {
                        this.changeCheckbox(`miNewLineItem${lineIdx}`)
                      }}
                      checked={this.state.checkboxes[`miNewLineItem${lineIdx}`]}
                      value={this.state.checkboxes[`miNewLineItem${lineIdx}`]}
                    />
                  </FormControl>
                </TableCell>
              )}
            </TableRow>
          </React.Fragment>
        ))
    }
  }

  public invoiceRef = React.createRef<any>()

  public render() {
    const { classes, selectedQuote } = this.props

    return (
      <>
        {this.state.pdfLoading ||
          (this.state.invoiceLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 400,
              }}
            >
              <CircularProgress size={100} />
            </div>
          ))}
        <Helmet>
          <title>{this.title}</title>
        </Helmet>

        <Formik
          //Initial values is not actually used, just here to appease formik
          initialValues={createIQuoteFormValues(selectedQuote)}
          onSubmit={this.save}
          validationSchema={QuoteSchema}
          enableReinitialize={true}
          ref={this.invoiceRef}
        >
          {({ values, submitForm, dirty, submitCount }) => (
            <>
              <Prompt
                when={(dirty || this.state.lineItemDirty) && submitCount === 0}
                message="Are you sure you want to leave the page? There are unsaved changes."
              />
              <ResponsiveAppBar title={this.title} pageIcon={<ListAlt />}>
                <Grid container direction="row" spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button size="small" variant="contained" onClick={this.cancel}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      onClick={() => {
                        this.setState({ pdfLoading: true })
                        this.previewInvoice()
                      }}
                      disabled={this.state.pdfLoading || this.state.invoiceLoading}
                    >
                      Preview Invoice
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      onClick={this.handleSave(submitForm, true)}
                      disabled={this.state.pdfLoading || this.state.invoiceLoading}
                    >
                      Create Invoice
                    </Button>
                  </Grid>
                </Grid>
              </ResponsiveAppBar>
              {selectedQuote &&
                this.props.selectedQuoteInboxItem &&
                !this.state.invoiceLoading && (
                  <Form className={classes.form} style={{ padding: '10px' }}>
                    <Grid container direction="column" spacing={1} wrap="nowrap">
                      <Grid item>
                        {
                          <InvoiceQuoteHeader
                            disabled={
                              (dirty || this.state.lineItemDirty) && submitCount === 0
                            }
                            values={values}
                            selectedQuote={this.props.selectedQuote}
                            cptDescriptionList={this.props.cptDescriptionList}
                            suffix={this.props.suffix}
                            setInvoiceSuffix={this.props.setInvoiceSuffix}
                            takeoverOfCareDate={
                              this.props.selectedQuoteInboxItem!.takeoverOfCare
                            }
                          />
                        }
                      </Grid>
                      <Grid container item direction="row" spacing={1}>
                        <Grid item md={12} lg={9}>
                          <ExpansionPanel
                            expanded={this.state.expandMain}
                            onChange={this.handleExpandMain}
                          >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                              <Grid
                                container
                                justifyContent="space-between"
                                style={{ paddingTop: '5px' }}
                              >
                                <Typography
                                  display="inline"
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  INVOICE DETAILS
                                </Typography>
                              </Grid>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Table size="small">
                                <TableHead>
                                  <TableRow key={'invoice-header'} style={{ padding: 0 }}>
                                    <TableCell>Provider & Service</TableCell>
                                    <TableCell>Physician</TableCell>
                                    <TableCell>Additional Details</TableCell>
                                    <TableCell align="right">OSx Charge</TableCell>
                                    <TableCell align="right">Usual & Customary</TableCell>
                                    {this.props.selectedQuote?.globalInvoiceId !=
                                      undefined && <TableCell>New</TableCell>}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.mapProfessionalGridData()}
                                  {this.mapTechnicalGridData()}
                                  {this.mapAnesthesiaGridData()}
                                  {this.mapPhysicalGridData()}
                                  <TableRow
                                    style={{
                                      height: '10px',
                                      backgroundColor: 'lightgrey',
                                    }}
                                  >
                                    <TableCell
                                      colSpan={
                                        this.props.selectedQuote?.globalInvoiceId !=
                                        undefined
                                          ? 6
                                          : 5
                                      }
                                    >
                                      Miscellaneous
                                    </TableCell>
                                  </TableRow>
                                  {this.mapMiscGridData()}
                                  <TableRow>
                                    <TableCell colSpan={3} align="right">
                                      <strong>Total OSx Charges:</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                      {formatCurrency(this.state.osxTotal.toString())}
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan={3} align="right">
                                      <strong>Reconciled Adj. Global Price:</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                      {`${formatCurrency(
                                        (
                                          this.props.selectedQuote
                                            ?.adjustedReconciledCost ?? 0
                                        ).toString()
                                      )}`}
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan={3} align="right">
                                      <strong>U&C Total:</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                      {`${formatCurrency(this.state.ucTotal.toString())}`}
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan={3} align="right">
                                      <strong>Invoice Total:</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                      {`${formatCurrency(
                                        (
                                          this.props.selectedQuote
                                            ?.adjustedReconciledCost ?? 0
                                        ).toString()
                                      )}`}
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                  {this.props.selectedInvoicePaymentSummary && (
                                    <TableRow>
                                      <TableCell colSpan={3} align="right">
                                        <strong>Payments Received:</strong>
                                      </TableCell>
                                      <TableCell align="right">
                                        {`${formatCurrency(
                                          (
                                            this.props.selectedInvoicePaymentSummary
                                              ?.totalPaid ?? 0
                                          ).toString()
                                        )}`}
                                      </TableCell>
                                      <TableCell align="right"></TableCell>
                                    </TableRow>
                                  )}
                                  {this.props.selectedInvoicePaymentSummary && (
                                    <TableRow>
                                      <TableCell colSpan={3} align="right">
                                        <strong>Remaining Balance:</strong>
                                      </TableCell>
                                      <TableCell align="right">
                                        {`${formatCurrency(
                                          (
                                            (this.props.selectedQuote!
                                              .adjustedReconciledCost ?? 0) -
                                            (this.props.selectedInvoicePaymentSummary
                                              ?.totalPaid ?? 0)
                                          ).toString()
                                        )}`}
                                      </TableCell>
                                      <TableCell align="right"></TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </Grid>
                        <Grid item md={12} lg={3}>
                          <ExpansionPanel
                            expanded={this.state.expandSidebar}
                            onChange={this.handleExpandSidebar}
                          >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                              <Grid
                                container
                                justifyContent="space-between"
                                style={{ paddingTop: '5px' }}
                              >
                                <Typography
                                  display="inline"
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  RECONCILED&nbsp;
                                  {`${formatCurrency(
                                    (
                                      this.props.selectedQuote?.adjustedReconciledCost ??
                                      0
                                    ).toString()
                                  )}`}
                                </Typography>
                              </Grid>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <ul
                                style={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  paddingRight: 0,
                                  paddingLeft: '5px',
                                  fontSize: 'small',
                                  listStyle: 'none',
                                }}
                              >
                                <li>PROFESSIONAL COMPONENTS</li>
                                <ul>{this.mapPCQuoteInfo()}</ul>
                                <li>TECHNICAL COMPONENTS</li>
                                <ul>{this.mapTCQuoteInfo()}</ul>
                                <li>ANESTHESIOLOGIST</li>
                                <ul>{this.mapANQuoteInfo()}</ul>
                                <li>PHYSICAL THERAPY</li>
                                <ul>{this.mapPTQuoteInfo()}</ul>
                                <li>MISCELLANEOUS</li>
                                <ul>{this.mapMIQuoteInfo()}</ul>
                              </ul>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )}
            </>
          )}
        </Formik>
      </>
    )
  }
}

const InjectedquotesList = inject<
  IStores,
  ICreateQuoteProps,
  Partial<ICreateQuoteProps>,
  any
>((stores: IStores) => ({
  getAllUsers: stores.users.getAllUsers,
  getLineItemsTotals: stores.quotes.getLineItemsTotal,
  getProviderTypes: stores.quotes.getProviderTypes,
  getTypesByCategory: stores.quotes.getTypesByCategory,
  loadQuote: stores.quotes.loadQuote,
  fetchCptDescriptions: stores.quotes.fetchCptDescriptions,
  cptDescriptionList: stores.quotes.cptDescriptionList,
  suffix: stores.quotes.invoiceSuffix,
  setInvoiceSuffix: stores.quotes.setInvoiceSuffix,
  providerTypes: stores.quotes.providerTypes,
  rerouteToPath: stores.global.rerouteToPath,
  selectedQuote: stores.quotes.selectedQuote,
  isNum: stores.quotes.isNum,
  selectedQuoteInboxItem: stores.quotes.selectedQuoteInboxItem,
  setSelectedQuoteInboxItem: stores.quotes.setSelectedQuoteInboxItem,
  getInvoicePaymentSummary: stores.quotes.getInvoicePaymentSummary,
  selectedInvoicePaymentSummary: stores.quotes.selectedInvoicePaymentSummary,
}))(CreateGlobalInvoice)

export default withStyles(styles)(InjectedquotesList)
