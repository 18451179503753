import moment from 'moment'
import { IProvider } from '../Definitions'
import { ICptCostLookupDto, LineItemDto } from '../generated_client'
import { IQuoteDTO, QuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import {
  IPhysicalTherapyLineItemDTO,
  PhysicalTherapyLineItemDTO,
} from 'src/viewModels/quotes/PhysicalTherapyLineItemDTO'
import {
  IProfessionalComponentLineItemDTO,
  ProfessionalComponentLineItemDTO,
} from 'src/viewModels/quotes/ProfessionalComponentLineItemDTO'
import {
  AnesthesiaLineItemDTO,
  IAnesthesiaLineItemDTO,
} from 'src/viewModels/quotes/AnesthesiaLineItemDTO'
import { TechnicalComponentLineItemDTO } from 'src/viewModels/quotes/TechnicalComponentLineItemDTO'
import {
  IMiscellaneousLineItemDTO,
  MiscellaneousLineItemDTO,
} from 'src/viewModels/quotes/MiscellaneousLineItemDTO'
import { DocumentButtonSummaryResult } from 'src/viewModels/quotes/DocumentButtonSummaryResult'

function getProvider(lineItem?: any) {
  if (lineItem && lineItem.provider) {
    return {
      id: lineItem.provider.id || '',
      name: lineItem.provider.name || '',
    } as IProvider
  }
  return { id: '', name: '' } as IProvider
}

function getPhysician(lineItem?: { physician?: { id?: string; name?: string } }) {
  if (lineItem && lineItem.physician) {
    return {
      id: lineItem.physician.id || '',
      name: lineItem.physician.name || '',
    }
  }
  return { id: '', name: '' }
}

export function createILineItemDto(
  lineItem:
    | IProfessionalComponentLineItemFormValues
    | ITechnicalComponentLineItemFormValues
) {
  return new LineItemDto({
    cost: lineItem.cost,
    cptCode: lineItem.cptCode,
    id: lineItem.id,
    providerId: lineItem.providerId,
  })
}

export interface ITechnicalComponentLineItemFormValues {
  providerId: string
  provider: IProvider
  typeId: string
  typeText: string
  cptCode: string
  cost?: number
  bilateral: boolean
  readonly id: string
  cptCostLookups?: ICptCostLookupDto[]
  isLoaded?: boolean
  discountApplied?: boolean
  isSet?: boolean
  markedDeleted?: boolean
  costSource?: number
  otherDescription?: string
  actualCost?: number
  percentageOfContract?: number
  multipleProcedurePercentage?: number
  calculationExplanation?: string
  markExcluded?: boolean
  markExcludedCost?: number
  reconciledActualCPT?: string
  reconciledActualLocationGroupName?: string
  reconciledActualCost?: number
  reconciledCost?: number
  reconciledOverrideCost?: number
  reconciledCMS1500LineItemId?: string
  reconciledCalculationExplanation?: string
  reasonCode?: string
}

export function createITechnicalComponentLineItemFormValues(
  lineItem?: TechnicalComponentLineItemDTO
): ITechnicalComponentLineItemFormValues {
  if (lineItem) {
    return {
      cost: lineItem.cost,
      bilateral: lineItem.bilateral || false,
      cptCode: lineItem.cptCode || '',
      id: lineItem.id || '',
      isLoaded: false,
      isSet: true,
      provider: getProvider(lineItem),
      providerId: lineItem.providerId || '',
      typeId: lineItem.typeId || '',
      typeText: lineItem.typeText || '',
      markedDeleted: lineItem.markedDeleted || false,
      costSource: lineItem.costSource,
      otherDescription: lineItem.otherDescription,
      actualCost: lineItem.actualCost,
      percentageOfContract: lineItem.percentageOfContract,
      multipleProcedurePercentage: lineItem.multipleProcedurePercentage,
      calculationExplanation: lineItem.calculationExplanation,
      markExcluded: lineItem.markExcluded || false,
      markExcludedCost: lineItem.markExcludedCost,
      reconciledActualCPT: lineItem.reconciledActualCPT,
      reconciledActualLocationGroupName: lineItem.reconciledActualLocationGroupName,
      reconciledActualCost: lineItem.reconciledActualCost,
      reconciledCost: lineItem.reconciledCost,
      reconciledOverrideCost: lineItem.reconciledOverrideCost,
      reconciledCMS1500LineItemId: lineItem.reconciledCMS1500LineItemId,
      reconciledCalculationExplanation: lineItem.reconciledCalculationExplanation,
      reasonCode: lineItem.reasonCode,
    }
  }
  return {
    cost: undefined,
    bilateral: false,
    cptCode: '',
    id: '',
    isLoaded: true,
    isSet: false,
    provider: getProvider(),
    providerId: '',
    typeId: '',
    typeText: '',
    markedDeleted: false,
    costSource: undefined,
    otherDescription: undefined,
    actualCost: undefined,
    percentageOfContract: undefined,
    multipleProcedurePercentage: undefined,
    calculationExplanation: undefined,
  }
}

export function ITechnicalComponentLineItemFormValuesToDto(
  formValues: ITechnicalComponentLineItemFormValues
): TechnicalComponentLineItemDTO {
  return new TechnicalComponentLineItemDTO(
    formValues.providerId,
    '',
    formValues.bilateral,
    formValues.cost,
    formValues.typeId,
    formValues.cptCode,
    formValues.id,
    formValues.markedDeleted,
    formValues.costSource,
    formValues.otherDescription,
    formValues.actualCost,
    formValues.percentageOfContract,
    formValues.multipleProcedurePercentage,
    formValues.typeText,
    formValues.calculationExplanation,
    formValues.markExcluded,
    formValues.markExcludedCost,
    formValues.reconciledActualCPT,
    formValues.reconciledActualLocationGroupName,
    formValues.reconciledActualCost,
    formValues.reconciledCost,
    formValues.reconciledOverrideCost,
    formValues.reconciledCMS1500LineItemId,
    formValues.reconciledCalculationExplanation,
    formValues.reasonCode
  )
}

export interface IAnesthesiaLineItemFormValues {
  providerId: string
  provider: {
    id: string
    name: string
  }
  physician?: {
    id: string
    name: string
  }
  physicianId: string
  cptCode: string
  cost?: number
  bilateral: boolean
  typeId: string
  typeText: string
  readonly id: string
  inclusions?: string
  markedDeleted?: boolean
  costSource?: number
  otherDescription?: string
  actualCost: number
  percentageOfContract?: number
  multipleProcedurePercentage?: number
  monitoredMultiplier?: number
  calculationExplanation?: string
  markExcluded?: boolean
  markExcludedCost?: number
  reconciledActualCPT?: string
  reconciledActualLocationGroupName?: string
  reconciledActualCost?: number
  reconciledCost?: number
  reconciledOverrideCost?: number
  reconciledCMS1500LineItemId?: string
  reconciledCalculationExplanation?: string
  reasonCode?: string
}

export function createIAnesthesiaLineItemFormValues(
  lineItem?: IAnesthesiaLineItemDTO
): IAnesthesiaLineItemFormValues {
  if (lineItem) {
    return {
      cost: lineItem.cost,
      bilateral: lineItem.bilateral || false,
      cptCode: lineItem.cptCode || '',
      id: lineItem.id || '',
      physician: undefined,
      physicianId: lineItem.physicianId || '',
      provider: getProvider(lineItem),
      providerId: lineItem.providerId || '',
      typeId: lineItem.typeId || '',
      typeText: lineItem.typeText || '',
      markedDeleted: lineItem.markedDeleted || false,
      costSource: lineItem.costSource,
      otherDescription: lineItem.otherDescription,
      actualCost: lineItem.actualCost,
      percentageOfContract: lineItem.percentageOfContract,
      multipleProcedurePercentage: lineItem.multipleProcedurePercentage,
      monitoredMultiplier: lineItem.monitoredMultiplier,
      calculationExplanation: lineItem.calculationExplanation,
      markExcluded: lineItem.markExcluded || false,
      markExcludedCost: lineItem.markExcludedCost,
      reconciledActualCPT: lineItem.reconciledActualCPT,
      reconciledActualLocationGroupName: lineItem.reconciledActualLocationGroupName,
      reconciledActualCost: lineItem.reconciledActualCost,
      reconciledCost: lineItem.reconciledCost,
      reconciledOverrideCost: lineItem.reconciledOverrideCost,
      reconciledCMS1500LineItemId: lineItem.reconciledCMS1500LineItemId,
      reconciledCalculationExplanation: lineItem.reconciledCalculationExplanation,
      reasonCode: lineItem.reasonCode,
    }
  }
  return {
    cost: 0,
    bilateral: false,
    cptCode: '',
    id: '',
    physician: getPhysician(),
    physicianId: '',
    provider: getProvider(),
    providerId: '',
    typeId: '',
    typeText: '',
    markedDeleted: false,
    costSource: undefined,
    otherDescription: undefined,
    actualCost: 0,
    percentageOfContract: undefined,
    multipleProcedurePercentage: undefined,
    monitoredMultiplier: undefined,
    calculationExplanation: undefined,
  }
}

export function IAnesthesiaLineItemFormValuesToDto(
  formValues: IAnesthesiaLineItemFormValues
): AnesthesiaLineItemDTO {
  return new AnesthesiaLineItemDTO(
    formValues.providerId,
    '',
    formValues.physicianId,
    '',
    formValues.cptCode,
    formValues.bilateral,
    formValues.actualCost,
    formValues.cost,
    formValues.id,
    formValues.markedDeleted,
    formValues.costSource,
    formValues.otherDescription,
    formValues.percentageOfContract,
    formValues.multipleProcedurePercentage,
    formValues.typeId,
    formValues.typeText,
    formValues.monitoredMultiplier,
    formValues.calculationExplanation,
    formValues.markExcluded,
    formValues.markExcludedCost,
    formValues.reconciledActualCPT,
    formValues.reconciledActualLocationGroupName,
    formValues.reconciledActualCost,
    formValues.reconciledCost,
    formValues.reconciledOverrideCost,
    formValues.reconciledCMS1500LineItemId,
    formValues.reconciledCalculationExplanation,
    formValues.reasonCode
  )
}

export interface IMiscellaneousLineItemFormValues {
  providerId: string
  provider: {
    id: string
    name: string
  }
  typeId: string
  typeText: string
  cptCode: string
  cost?: number
  bilateral: boolean
  lineDescription: string
  readonly id: string
  inclusions?: string
  markedDeleted?: boolean
  costSource?: number
  otherDescription?: string
  actualCost?: number
  percentageOfContract?: number
  multipleProcedurePercentage?: number
  calculationExplanation?: string
  markExcluded?: boolean
  markExcludedCost?: number
  reconciledActualCPT?: string
  reconciledActualLocationGroupName?: string
  reconciledActualCost?: number
  reconciledCost?: number
  reconciledOverrideCost?: number
  reconciledCMS1500LineItemId?: string
  reconciledCalculationExplanation?: string
  reasonCode?: string
}

export function createIMiscellaneousLineItemFormValues(
  lineItem?: IMiscellaneousLineItemDTO
): IMiscellaneousLineItemFormValues {
  if (lineItem) {
    return {
      cost: lineItem.cost,
      bilateral: lineItem.bilateral || false,
      cptCode: lineItem.cptCode || '',
      lineDescription: lineItem.lineDescription || '',
      id: lineItem.id || '',
      provider: getProvider(lineItem),
      providerId: lineItem.providerId || '',
      typeId: lineItem.typeId || '',
      typeText: lineItem.typeText || '',
      markedDeleted: lineItem.markedDeleted || false,
      costSource: lineItem.costSource,
      otherDescription: lineItem.otherDescription,
      actualCost: lineItem.actualCost,
      percentageOfContract: lineItem.percentageOfContract,
      multipleProcedurePercentage: lineItem.multipleProcedurePercentage,
      calculationExplanation: lineItem.calculationExplanation,
      markExcluded: lineItem.markExcluded || false,
      markExcludedCost: lineItem.markExcludedCost,
      reconciledActualCPT: lineItem.reconciledActualCPT,
      reconciledActualLocationGroupName: lineItem.reconciledActualLocationGroupName,
      reconciledActualCost: lineItem.reconciledActualCost,
      reconciledCost: lineItem.reconciledCost,
      reconciledOverrideCost: lineItem.reconciledOverrideCost,
      reconciledCMS1500LineItemId: lineItem.reconciledCMS1500LineItemId,
      reconciledCalculationExplanation: lineItem.reconciledCalculationExplanation,
      reasonCode: lineItem.reasonCode,
    }
  }
  return {
    cost: 0,
    bilateral: false,
    cptCode: '',
    lineDescription: '',
    id: '',
    provider: getProvider(),
    providerId: '',
    typeId: '',
    typeText: '',
    markedDeleted: false,
    costSource: undefined,
    otherDescription: undefined,
    actualCost: undefined,
    percentageOfContract: undefined,
    multipleProcedurePercentage: undefined,
    calculationExplanation: undefined,
  }
}

export function IMiscellaneousLineItemFormValuesToDto(
  formValues: IMiscellaneousLineItemFormValues
): MiscellaneousLineItemDTO {
  return new MiscellaneousLineItemDTO(
    formValues.providerId,
    '',
    formValues.bilateral,
    formValues.lineDescription,
    formValues.cost,
    formValues.typeId,
    formValues.cptCode,
    formValues.id,
    formValues.markedDeleted,
    '',
    formValues.costSource,
    formValues.otherDescription,
    formValues.actualCost,
    formValues.percentageOfContract,
    formValues.multipleProcedurePercentage,
    formValues.calculationExplanation,
    formValues.markExcluded,
    formValues.markExcludedCost,
    formValues.reconciledActualCPT,
    formValues.reconciledActualLocationGroupName,
    formValues.reconciledActualCost,
    formValues.reconciledCost,
    formValues.reconciledOverrideCost,
    formValues.reconciledCMS1500LineItemId,
    formValues.reconciledCalculationExplanation,
    formValues.reasonCode
  )
}

export interface IProfessionalComponentLineItemFormValues {
  providerId: string
  provider: IProvider
  typeId: string
  typeText: string
  physicianId: string
  physician?: {
    id: string
    name: string
  }
  cptCode: string
  cost?: number
  bilateral: boolean
  readonly id: string
  contractId?: string
  cptCostLookups?: ICptCostLookupDto[]
  isLoaded?: boolean
  discountApplied?: boolean
  isSet?: boolean
  markedDeleted?: boolean
  costSource?: number
  otherDescription?: string
  assistantMultiplier?: number
  actualCost?: number
  percentageOfContract?: number
  multipleProcedurePercentage?: number
  calculationExplanation?: string
  assistantSurgeonInfo?: string
  markExcluded?: boolean
  markExcludedCost?: number
  reconciledActualCPT?: string
  reconciledActualLocationGroupName?: string
  reconciledActualCost?: number
  reconciledCost?: number
  reconciledOverrideCost?: number
  reconciledCMS1500LineItemId?: string
  reconciledCalculationExplanation?: string
  reasonCode?: string
}

export function createIProfessionalComponentLineItemFormValues(
  lineItem?: IProfessionalComponentLineItemDTO
): IProfessionalComponentLineItemFormValues {
  if (lineItem) {
    return {
      cost: lineItem.cost,
      bilateral: lineItem.bilateral || false,
      cptCode: lineItem.cptCode || '',
      id: lineItem.id || '',
      isLoaded: false,
      isSet: true,
      physician: undefined,
      physicianId: lineItem.physicianId || '',
      provider: getProvider(lineItem),
      providerId: lineItem.providerId || '',
      typeId: lineItem.typeId || '',
      typeText: lineItem.typeText || '',
      markedDeleted: lineItem.markedDeleted || false,
      costSource: lineItem.costSource,
      otherDescription: lineItem.otherDescription,
      assistantMultiplier: lineItem.assistantMultiplier,
      actualCost: lineItem.actualCost,
      percentageOfContract: lineItem.percentageOfContract,
      multipleProcedurePercentage: lineItem.multipleProcedurePercentage,
      calculationExplanation: lineItem.calculationExplanation,
      assistantSurgeonInfo: lineItem.assistantSurgeonInfo,
      markExcluded: lineItem.markExcluded || false,
      markExcludedCost: lineItem.markExcludedCost,
      reconciledActualCPT: lineItem.reconciledActualCPT,
      reconciledActualLocationGroupName: lineItem.reconciledActualLocationGroupName,
      reconciledActualCost: lineItem.reconciledActualCost,
      reconciledCost: lineItem.reconciledCost,
      reconciledOverrideCost: lineItem.reconciledOverrideCost,
      reconciledCMS1500LineItemId: lineItem.reconciledCMS1500LineItemId,
      reconciledCalculationExplanation: lineItem.reconciledCalculationExplanation,
      reasonCode: lineItem.reasonCode,
    }
  }
  return {
    cost: undefined,
    bilateral: false,
    cptCode: '',
    discountApplied: false,
    id: '',
    isLoaded: true,
    isSet: false,
    physician: getPhysician(),
    physicianId: '',
    provider: getProvider(),
    providerId: '',
    typeId: '',
    typeText: '',
    markedDeleted: false,
    costSource: undefined,
    otherDescription: undefined,
    assistantMultiplier: undefined,
    actualCost: undefined,
    percentageOfContract: undefined,
    multipleProcedurePercentage: undefined,
    calculationExplanation: undefined,
    assistantSurgeonInfo: undefined,
    markExcluded: false,
    markExcludedCost: undefined,
    reconciledActualCPT: undefined,
    reconciledActualLocationGroupName: undefined,
    reconciledActualCost: undefined,
    reconciledCost: undefined,
    reconciledOverrideCost: undefined,
    reconciledCMS1500LineItemId: undefined,
    reconciledCalculationExplanation: undefined,
  }
}

export function IProfessionalComponentLineItemFormValuesToDto(
  formValues: IProfessionalComponentLineItemFormValues
): ProfessionalComponentLineItemDTO {
  return new ProfessionalComponentLineItemDTO(
    formValues.providerId,
    '',
    formValues.typeId,
    formValues.physicianId,
    '',
    formValues.cptCode,
    formValues.bilateral,
    formValues.cost,
    formValues.id,
    formValues.markedDeleted,
    formValues.costSource,
    formValues.otherDescription,
    formValues.assistantMultiplier,
    formValues.actualCost,
    formValues.percentageOfContract,
    formValues.multipleProcedurePercentage,
    formValues.typeText,
    formValues.calculationExplanation,
    formValues.assistantSurgeonInfo,
    formValues.markExcluded,
    formValues.markExcludedCost,
    formValues.reconciledActualCPT,
    formValues.reconciledActualLocationGroupName,
    formValues.reconciledActualCost,
    formValues.reconciledCost,
    formValues.reconciledOverrideCost,
    formValues.reconciledCMS1500LineItemId,
    formValues.reconciledCalculationExplanation,
    formValues.reasonCode
  )
}

export interface IPhysicalTherapyLineItemFormValues {
  providerId: string
  provider: {
    id: string
    name: string
  }
  numberOfVisits?: number
  costPerVisit?: number
  actualCostPerVisit?: number
  cost?: number
  readonly id: string
  inclusions?: string
  markedDeleted?: boolean
  markExcluded?: boolean
  markExcludedCost?: number
  reconciledCost?: number
  reconciledOverrideCost?: number
}

export function createIPhysicalTherapyLineItemFormValues(
  lineItem?: IPhysicalTherapyLineItemDTO
): IPhysicalTherapyLineItemFormValues {
  if (lineItem) {
    return {
      cost: lineItem.cost,
      costPerVisit: lineItem.costPerVisit,
      actualCostPerVisit: lineItem.actualCostPerVisit,
      id: lineItem.id || '',
      numberOfVisits: lineItem.numberOfVisits,
      provider: getProvider(lineItem),
      providerId: lineItem.providerId || '',
      markedDeleted: lineItem.markedDeleted || false,
      markExcluded: lineItem.markExcluded || false,
      markExcludedCost: lineItem.markExcludedCost,
      reconciledCost: lineItem.reconciledCost,
      reconciledOverrideCost: lineItem.reconciledOverrideCost,
    }
  }
  return {
    cost: undefined,
    costPerVisit: undefined,
    actualCostPerVisit: undefined,
    id: '',
    numberOfVisits: undefined,
    provider: getProvider(),
    providerId: '',
    markedDeleted: false,
    markExcluded: false,
    markExcludedCost: undefined,
    reconciledCost: undefined,
    reconciledOverrideCost: undefined,
  }
}

export function IPhysicalTherapyLineItemFormValuesToDto(
  formValues: IPhysicalTherapyLineItemFormValues
): PhysicalTherapyLineItemDTO {
  return new PhysicalTherapyLineItemDTO(
    formValues.providerId,
    '',
    formValues.cost,
    formValues.numberOfVisits,
    formValues.costPerVisit,
    formValues.actualCostPerVisit,
    formValues.id,
    formValues.markedDeleted,
    formValues.markExcluded,
    formValues.markExcludedCost,
    formValues.reconciledCost,
    formValues.reconciledOverrideCost
  )
}

export interface IQuoteFormValues {
  adjustedCost?: number
  adjustedReconciledCost?: number
  anesthesiaLineItems: IAnesthesiaLineItemFormValues[]
  bodyPartName: string
  dateOfBirth?: Date
  readonly id: string
  episodeOfCareNumber?: number
  isAdjustedCostOverridden: boolean
  isAdjustedReconciledCostOverridden: boolean
  note: string
  patientIdNumber?: string
  patientName: string
  physicalTherapyLineItems: IPhysicalTherapyLineItemFormValues[]
  professionalComponentLineItems: IProfessionalComponentLineItemFormValues[]
  status: number
  surgeryDate: Date
  endDate?: Date
  bundleDurationDays?: number
  targetMargin?: number
  targetReconciledMargin?: number
  technicalComponentLineItems: ITechnicalComponentLineItemFormValues[]
  miscellaneousLineItems: IMiscellaneousLineItemFormValues[]
  claimButtons?: DocumentButtonSummaryResult[]
  dxCodes?: string
  episodeOfCareOrLeagacyId?: string
}

export function createIQuoteFormValues(quote?: IQuoteDTO): IQuoteFormValues {
  if (quote) {
    return {
      adjustedCost: quote.adjustedCost,
      adjustedReconciledCost: quote.adjustedReconciledCost,
      anesthesiaLineItems: quote.anesthesiaLineItems!.map(
        createIAnesthesiaLineItemFormValues
      ),
      bodyPartName: quote.bodyPartName ? quote.bodyPartName : '',
      dateOfBirth: quote.dateOfBirth,
      episodeOfCareNumber: quote.episodeOfCareNumber,
      id: quote.id || '',
      isAdjustedCostOverridden: quote.isAdjustedCostOverridden,
      isAdjustedReconciledCostOverridden: quote.isAdjustedReconciledCostOverridden,
      miscellaneousLineItems: quote.miscellaneousLineItems!.map(
        createIMiscellaneousLineItemFormValues
      ),
      note: quote.note,
      patientIdNumber: quote.patientIdNumber,
      patientName: quote.patientName,
      physicalTherapyLineItems: quote.physicalTherapyLineItems!.map(
        createIPhysicalTherapyLineItemFormValues
      ),
      professionalComponentLineItems: quote.professionalComponentLineItems!.map(
        createIProfessionalComponentLineItemFormValues
      ),
      status: quote.status,
      surgeryDate: quote.surgeryDate,
      endDate: quote.endDate,
      bundleDurationDays: quote.bundleDurationDays,
      targetMargin: quote.targetMargin,
      targetReconciledMargin: quote.targetReconciledMargin,
      technicalComponentLineItems: quote.technicalComponentLineItems!.map(
        createITechnicalComponentLineItemFormValues
      ),
      claimButtons: quote.claimButtons,
      dxCodes: quote.dxCodes,
      episodeOfCareOrLeagacyId: quote.episodeOfCareOrLegacyId,
    }
  } else {
    return {
      anesthesiaLineItems: [],
      bodyPartName: '',
      dateOfBirth: undefined,
      id: '',
      isAdjustedCostOverridden: false,
      isAdjustedReconciledCostOverridden: false,
      miscellaneousLineItems: [],
      note: '',
      patientIdNumber: '',
      patientName: '',
      physicalTherapyLineItems: [],
      professionalComponentLineItems: [],
      status: 0,
      surgeryDate: moment().startOf('day').toDate(),
      endDate: undefined,
      bundleDurationDays: undefined,
      targetMargin: 30,
      targetReconciledMargin: 30,
      technicalComponentLineItems: [],
      dxCodes: undefined,
    }
  }
}

export function applyChangesToQuote(
  formValues: IQuoteFormValues,
  original: IQuoteDTO
): IQuoteDTO {
  return {
    ...original,
    adjustedCost: formValues.adjustedCost,
    anesthesiaLineItems: formValues.anesthesiaLineItems.map(
      IAnesthesiaLineItemFormValuesToDto
    ),
    isAdjustedCostOverridden: formValues.isAdjustedCostOverridden,
    miscellaneousLineItems: formValues.miscellaneousLineItems.map(
      IMiscellaneousLineItemFormValuesToDto
    ),
    note: formValues.note,
    physicalTherapyLineItems: formValues.physicalTherapyLineItems.map(
      IPhysicalTherapyLineItemFormValuesToDto
    ),
    professionalComponentLineItems: formValues.professionalComponentLineItems.map(
      IProfessionalComponentLineItemFormValuesToDto
    ),
    status: formValues.status,
    // surgeryDate: newformValues.surgeryDate,
    targetMargin: formValues.targetMargin,
    targetReconciledMargin: formValues.targetReconciledMargin,
    technicalComponentLineItems: formValues.technicalComponentLineItems.map(
      ITechnicalComponentLineItemFormValuesToDto
    ),
    claimButtons: formValues.claimButtons,
    dxCodes: formValues.dxCodes,
  }
}

export function toIQuoteDTO(formValues: IQuoteFormValues, reconMode: boolean): IQuoteDTO {
  let physList: PhysicalTherapyLineItemDTO[] = []
  formValues.physicalTherapyLineItems.map((item) => {
    physList.push(
      new PhysicalTherapyLineItemDTO(
        item.providerId,
        '',
        item.cost,
        item.numberOfVisits,
        item.costPerVisit,
        item.actualCostPerVisit,
        item.id,
        item.markedDeleted,
        item.markExcluded,
        item.markExcludedCost,
        item.reconciledCost,
        item.reconciledOverrideCost
      )
    )
  })

  let profCompList: ProfessionalComponentLineItemDTO[] = []
  formValues.professionalComponentLineItems.map((item) => {
    profCompList.push(
      new ProfessionalComponentLineItemDTO(
        item.providerId,
        '',
        item.typeId,
        item.physicianId,
        '',
        item.cptCode,
        item.bilateral,
        item.cost,
        item.id,
        item.markedDeleted,
        item.costSource,
        item.otherDescription,
        item.assistantMultiplier,
        item.actualCost,
        item.percentageOfContract,
        item.multipleProcedurePercentage,
        item.typeText,
        item.calculationExplanation,
        item.assistantSurgeonInfo,
        item.markExcluded,
        item.markExcludedCost,
        item.reconciledActualCPT,
        item.reconciledActualLocationGroupName,
        item.reconciledActualCost,
        item.reconciledCost,
        item.reconciledOverrideCost,
        item.reconciledCMS1500LineItemId,
        item.reconciledCalculationExplanation,
        item.reasonCode
      )
    )
  })

  let anesList: AnesthesiaLineItemDTO[] = []
  formValues.anesthesiaLineItems.map((item) => {
    anesList.push(
      new AnesthesiaLineItemDTO(
        item.providerId,
        '',
        item.physicianId,
        '',
        item.cptCode,
        item.bilateral,
        item.actualCost,
        item.cost,
        item.id,
        item.markedDeleted,
        item.costSource,
        item.otherDescription,
        item.percentageOfContract,
        item.multipleProcedurePercentage,
        item.typeId,
        item.typeText,
        item.monitoredMultiplier,
        item.calculationExplanation,
        item.markExcluded,
        item.markExcludedCost,
        item.reconciledActualCPT,
        item.reconciledActualLocationGroupName,
        item.reconciledActualCost,
        item.reconciledCost,
        item.reconciledOverrideCost,
        item.reconciledCMS1500LineItemId,
        item.reconciledCalculationExplanation,
        item.reasonCode
      )
    )
  })

  let techCompList: TechnicalComponentLineItemDTO[] = []
  formValues.technicalComponentLineItems.map((item) => {
    techCompList.push(
      new TechnicalComponentLineItemDTO(
        item.providerId,
        '',
        item.bilateral,
        item.cost,
        item.typeId,
        item.cptCode,
        item.id,
        item.markedDeleted,
        item.costSource,
        item.otherDescription,
        item.actualCost,
        item.percentageOfContract,
        item.multipleProcedurePercentage,
        item.typeText,
        item.calculationExplanation,
        item.markExcluded,
        item.markExcludedCost,
        item.reconciledActualCPT,
        item.reconciledActualLocationGroupName,
        item.reconciledActualCost,
        item.reconciledCost,
        item.reconciledOverrideCost,
        item.reconciledCMS1500LineItemId,
        item.reconciledCalculationExplanation,
        item.reasonCode
      )
    )
  })

  let miscList: MiscellaneousLineItemDTO[] = []
  formValues.miscellaneousLineItems.map((item) => {
    miscList.push(
      new MiscellaneousLineItemDTO(
        item.providerId,
        '',
        item.bilateral,
        item.lineDescription,
        item.cost,
        item.typeId,
        item.cptCode,
        item.id,
        item.markedDeleted,
        item.typeText,
        item.costSource,
        item.otherDescription,
        item.actualCost,
        item.percentageOfContract,
        item.multipleProcedurePercentage,
        item.calculationExplanation,
        item.markExcluded,
        item.markExcludedCost,
        item.reconciledActualCPT,
        item.reconciledActualLocationGroupName,
        item.reconciledActualCost,
        item.reconciledCost,
        item.reconciledOverrideCost,
        item.reconciledCMS1500LineItemId,
        item.reconciledCalculationExplanation,
        item.reasonCode
      )
    )
  })

  const quote = new QuoteDTO(
    formValues.note,
    formValues.isAdjustedCostOverridden,
    formValues.targetMargin,
    formValues.adjustedCost,
    reconMode ? formValues.targetReconciledMargin : undefined,
    reconMode ? formValues.adjustedReconciledCost : undefined,
    '', //adjustedCostOverridenBy
    undefined, //adjustCostOverridenOn
    physList,
    profCompList,
    anesList,
    techCompList,
    miscList,
    formValues.claimButtons,
    formValues.dxCodes
  )

  return quote
}

export async function submitQuoteFormValues(
  formValues: IQuoteFormValues,
  reconMode: boolean
): Promise<IQuoteDTO> {
  let physList: PhysicalTherapyLineItemDTO[] = []
  formValues.physicalTherapyLineItems.map((item) => {
    physList.push(
      new PhysicalTherapyLineItemDTO(
        item.providerId,
        '',
        item.cost,
        item.numberOfVisits,
        item.costPerVisit,
        item.actualCostPerVisit,
        item.id,
        item.markedDeleted,
        item.markExcluded,
        item.markExcludedCost,
        item.reconciledCost,
        item.reconciledOverrideCost
      )
    )
  })

  let profCompList: ProfessionalComponentLineItemDTO[] = []
  formValues.professionalComponentLineItems.map((item) => {
    profCompList.push(
      new ProfessionalComponentLineItemDTO(
        item.providerId,
        '',
        item.typeId,
        item.physicianId,
        '',
        item.cptCode,
        item.bilateral,
        item.cost,
        item.id,
        item.markedDeleted,
        item.costSource,
        item.otherDescription,
        item.assistantMultiplier,
        item.actualCost,
        item.percentageOfContract,
        item.multipleProcedurePercentage,
        item.typeText,
        item.calculationExplanation,
        item.assistantSurgeonInfo,
        item.markExcluded,
        item.markExcludedCost,
        item.reconciledActualCPT,
        item.reconciledActualLocationGroupName,
        item.reconciledActualCost,
        item.reconciledCost,
        item.reconciledOverrideCost,
        item.reconciledCMS1500LineItemId,
        item.reconciledCalculationExplanation,
        item.reasonCode
      )
    )
  })

  let anesList: AnesthesiaLineItemDTO[] = []
  formValues.anesthesiaLineItems.map((item) => {
    anesList.push(
      new AnesthesiaLineItemDTO(
        item.providerId,
        '',
        item.physicianId,
        '',
        item.cptCode,
        item.bilateral,
        item.actualCost,
        item.cost,
        item.id,
        item.markedDeleted,
        item.costSource,
        item.otherDescription,
        item.percentageOfContract,
        item.multipleProcedurePercentage,
        item.typeId,
        item.typeText,
        item.monitoredMultiplier,
        item.calculationExplanation,
        item.markExcluded,
        item.markExcludedCost,
        item.reconciledActualCPT,
        item.reconciledActualLocationGroupName,
        item.reconciledActualCost,
        item.reconciledCost,
        item.reconciledOverrideCost,
        item.reconciledCMS1500LineItemId,
        item.reconciledCalculationExplanation,
        item.reasonCode
      )
    )
  })

  let techCompList: TechnicalComponentLineItemDTO[] = []
  formValues.technicalComponentLineItems.map((item) => {
    techCompList.push(
      new TechnicalComponentLineItemDTO(
        item.providerId,
        '',
        item.bilateral,
        item.cost,
        item.typeId,
        item.cptCode,
        item.id,
        item.markedDeleted,
        item.costSource,
        item.otherDescription,
        item.actualCost,
        item.percentageOfContract,
        item.multipleProcedurePercentage,
        item.typeText,
        item.calculationExplanation,
        item.markExcluded,
        item.markExcludedCost,
        item.reconciledActualCPT,
        item.reconciledActualLocationGroupName,
        item.reconciledActualCost,
        item.reconciledCost,
        item.reconciledOverrideCost,
        item.reconciledCMS1500LineItemId,
        item.reconciledCalculationExplanation,
        item.reasonCode
      )
    )
  })

  let miscList: MiscellaneousLineItemDTO[] = []
  formValues.miscellaneousLineItems.map((item) => {
    miscList.push(
      new MiscellaneousLineItemDTO(
        item.providerId,
        '',
        item.bilateral,
        item.lineDescription,
        item.cost,
        item.typeId,
        item.cptCode,
        item.id,
        item.markedDeleted,
        item.typeText,
        item.costSource,
        item.otherDescription,
        item.actualCost,
        item.percentageOfContract,
        item.multipleProcedurePercentage,
        item.calculationExplanation,
        item.markExcluded,
        item.markExcludedCost,
        item.reconciledActualCPT,
        item.reconciledActualLocationGroupName,
        item.reconciledActualCost,
        item.reconciledCost,
        item.reconciledOverrideCost,
        item.reconciledCMS1500LineItemId,
        item.reconciledCalculationExplanation,
        item.reasonCode
      )
    )
  })

  const quote = new QuoteDTO(
    formValues.note,
    formValues.isAdjustedCostOverridden,
    formValues.targetMargin,
    formValues.adjustedCost,
    reconMode ? formValues.targetReconciledMargin : undefined,
    reconMode ? formValues.adjustedReconciledCost : undefined,
    '', //adjustedCostOverridenBy
    undefined, //adjustCostOverridenOn
    physList,
    profCompList,
    anesList,
    techCompList,
    miscList,
    formValues.claimButtons,
    formValues.dxCodes,
    undefined,
    undefined,
    undefined,
    undefined,
    formValues.endDate,
    formValues.bundleDurationDays
  )

  return quote
}
