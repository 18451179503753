import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  Typography,
} from '@material-ui/core'
import { UndoRounded } from '@material-ui/icons'
import React from 'react'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { markAsDeletedReceivedCheck } from 'src/services/CheckService'
import { formatCurrency, formatDate } from 'src/utils/Formatter'
import { ReceivedCheckSummaryResult } from 'src/viewModels/ReceivedCheckSummaryResult'

interface IMarkAsDeletedModal extends DialogProps {
  close: (reload: boolean) => void
  check: ReceivedCheckSummaryResult
}

class MarkAsDeletedModal extends React.Component<IMarkAsDeletedModal> {
  public state = {
    markAsDeletedCheckProcessing: false,
  }

  private markAsDeletedReceivedCheck = (checkId: string) => {
    markAsDeletedReceivedCheck(checkId)
      .then(() => {
        this.props.close(true)
      })
      .finally(() => {
        this.setState({ markAsDeletedCheckProcessing: false })
      })
  }

  public render() {
    const { close, check, ...modalProps } = this.props
    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={() => close(false)}
        {...modalProps}
        disableBackdropClick
      >
        {this.state.markAsDeletedCheckProcessing && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1200',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <ResponsiveAppBar
          title={`Mark as Deleted - Check Number: ${check.checkNumber}
              , Check Date: ${
                check.checkDate ? formatDate(check.checkDate.toString()) : ''
              }
              , Check Amount: ${formatCurrency(check.checkAmount.toString())}`}
          pageIcon={<UndoRounded />}
          isModal
        />
        <DialogContent style={{ marginTop: 10 }}>
          <Typography variant="body1">
            Are you sure you want to mark this check as deleted?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button onClick={() => close(false)}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  this.setState({ markAsDeletedCheckProcessing: true })
                  this.markAsDeletedReceivedCheck(check.checkId)
                }}
                color="primary"
                variant="contained"
                disabled={this.state.markAsDeletedCheckProcessing}
              >
                Mark Deleted
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default MarkAsDeletedModal
