const config = {
  NotificationConfig: {
    DefaultAutoHide: 2000,
    PersistAllNotifications: false,
    PersistErrorNotifications: true,
  },
  Roles: {
    Administrator: 'Administrator',
    CareCoordinator: 'Care Coordinator',
    GeneralFinance: 'General Finance',
    FinanceTier1: 'Finance Tier 1',
    FinanceTier2: 'Finance Tier 2',
    FinanceTier3: 'Finance Tier 3',
    ProviderInfoUpdater: 'Provider Info Updater',
    WorkflowAdmin: 'Workflow Admin',
    FinanceManager: 'Finance Manager',
  },
}

export default config
