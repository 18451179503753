import { Person } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import { DefaultContact, IContact } from '../Definitions'
import { IStores } from '../Stores'
import ContactDialog from './ContactDialog'

interface IContactsProps {
  dataTableManager?: IDataTableManager<IContact>
  disableContact?: (contact: IContact) => void
  getColumnSettingsAndContacts?: () => void
  isModalOpen?: boolean
  openDialogWithContact?: (contact: IContact) => void
  recoverContact?: (contact: IContact) => void
}

class Contacts extends React.Component<IContactsProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndContacts!()
  }

  public render() {
    const contactDialog = this.props.isModalOpen && <ContactDialog />
    return (
      <>
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={true}
          enableToggleColumns={true}
          addButtonOnClick={this.openDialog(DefaultContact(true))}
          title="Contacts"
          icon={Person}
          elevation={0}
          padding={0}
          rowsPerPage={5}
        />
        {contactDialog}
      </>
    )
  }

  public openDialog = (contact: IContact) => () => {
    this.props.openDialogWithContact!(contact)
  }
}

const InjectedConstants = inject<IStores, IContactsProps, Partial<IContactsProps>, any>(
  (stores: IStores) => ({
    dataTableManager: stores.contacts.dataTableStore,
    disableContact: stores.contacts.disableContact,
    getColumnSettingsAndContacts: stores.contacts.getColumnSettingsAndContacts,
    isModalOpen: stores.contacts.isModalOpen,
    openDialogWithContact: stores.contacts.openDialogWithContact,
    recoverContact: stores.contacts.recoverContact,
  })
)(Contacts)

export default InjectedConstants
