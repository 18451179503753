import { Grid } from '@material-ui/core'
import { Chat } from '@material-ui/icons'
import { FormikActions, getIn } from 'formik'
import _ from 'lodash'
import { inject } from 'mobx-react'
import * as React from 'react'
import * as Yup from 'yup'
import DatePickerField from '../common/DatePickerField/DatePickerField'
import ESDialog from '../common/ESDialog'
import FreeTypeAheadField from '../common/FreeTypeAheadField/FreeTypeAheadField'
import FullWidthField from '../common/FullWidthField'
import SelectField from '../common/SelectField/SelectField'
import { ICommunication, IPatient } from '../Definitions'
import { SwaggerResponse, TagDto } from '../generated_client'
import { IStores } from '../Stores'
import {
  createNewTag,
  getTagColor,
  getTagName,
  getTagValue,
  ITagFormValues,
} from '../Tags/TagFormValues'
import {
  createICommunicationFormValues,
  ICommunicationFormValues,
  isEditable,
  toICommunication,
} from './CommunicationFormValues'

const CommunicationSchema = Yup.object().shape({
  communicationDate: Yup.date(),
  isActive: Yup.boolean(),
  message: Yup.string(),
  type: Yup.string(),
})

interface IEditCommunicationDialogProps {
  close?: () => void
  communication?: ICommunication
  getAllTags?: (filter: string, includeSystem: boolean) => void
  isLoading?: boolean
  isOpen?: boolean
  disabled?: boolean
  saveCommunication?: (
    communication: ICommunication
  ) => Promise<void> | Promise<SwaggerResponse<void>>
  selectedPatient?: IPatient
  tags?: TagDto[]
}

class EditCommunicationDialog extends React.Component<IEditCommunicationDialogProps> {
  public componentDidMount() {
    this.props.getAllTags!('', false)
  }

  public save = (
    values: ICommunicationFormValues,
    formikBag: FormikActions<ICommunicationFormValues>
  ) => {
    const communication = toICommunication(values, this.props.communication)
    this.props.saveCommunication!(communication).finally(() =>
      formikBag.setSubmitting(false)
    )
  }

  public render() {
    const { close, isLoading, isOpen, communication, tags, selectedPatient } = this.props
    const hasValue = (field: string, values: ICommunicationFormValues) => {
      const value = getIn(values, field)
      return !!value
    }

    const title =
      (communication!.isNew ? 'Add New Communication' : 'Edit Communication') +
      (selectedPatient ? ` for ${selectedPatient!.name}` : '')

    return (
      <ESDialog
        close={close!}
        initialValues={createICommunicationFormValues(communication)}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={title}
        titleIcon={<Chat />}
        validationSchema={CommunicationSchema}
      >
        {({ values }) => (
          <Grid container spacing={3} style={{ marginBottom: '12px', marginTop: '12px' }}>
            <Grid container item xs={12} spacing={5}>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item xs={3}>
                    <DatePickerField
                      name="communicationDate"
                      label="Date"
                      inputId="communicationDate"
                      fullWidth={true}
                      clearable
                      disabled={isEditable(communication)}
                      shrink={hasValue('communicationDate', values)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {!isEditable(communication) ? (
                      <SelectField
                        inputId="type"
                        getName={this.getValue}
                        getValue={this.getValue}
                        items={['Email', 'Phone', 'Text', 'Fax', 'Other']}
                        label="Type"
                        disabled={isEditable(communication)}
                        name="type"
                        fullWidth
                        shrinkLabel={true}
                        outlined
                      />
                    ) : (
                      <FullWidthField
                        name="type"
                        label="Type"
                        variant="outlined"
                        disabled={isEditable(communication)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <DatePickerField
                      name="dateOfService"
                      label="Date of Service"
                      inputId="dateOfService"
                      fullWidth={true}
                      clearable
                      disabled={isEditable(communication)}
                      shrink={hasValue('dateOfService', values)}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <FreeTypeAheadField
                  name="communicationTags"
                  label="Tags"
                  fullWidth={true}
                  createNew={this.createNewTag(values.communicationTags)}
                  getName={this.getTagName}
                  disabled={isEditable(communication)}
                  getValue={this.getTagValue}
                  getChipColor={this.getTagColor}
                  getItems={this.getTags}
                  items={tags || []}
                  outlined
                />
              </Grid>
              <Grid item xs={12}>
                <FullWidthField
                  autoFocus
                  name="message"
                  label="Content"
                  rows="6"
                  disabled={isEditable(communication)}
                  multiline
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }

  private getTags = (filter: string) => {
    this.props.getAllTags!(filter, false)
  }

  private getTagName = (t: TagDto) => {
    return getTagName(t)
  }

  private getTagValue = (t: TagDto) => {
    return getTagValue(t)
  }

  private createNewTag = (tags: ITagFormValues[]) => (t: string) => {
    return createNewTag(t, tags)
  }

  private getTagColor = (t: TagDto) => {
    return getTagColor(t)
  }

  public getValue(item: string) {
    return item
  }
}

const InjectedEditCommunicationDialog = inject<
  IStores,
  IEditCommunicationDialogProps,
  Partial<IEditCommunicationDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.communications.closeEditDialog,
  communication: stores.communications.selectedCommunication,
  getAllTags: stores.tags.getAllTags,
  isLoading: stores.communications.isLoading,
  isOpen: stores.communications.isEditModalOpen,
  saveCommunication: stores.communications.saveCommunication,
  selectedPatient: stores.patients.selectedPatient,
  tags: stores.tags.tags,
}))(EditCommunicationDialog)

export default InjectedEditCommunicationDialog
