import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultPreExistingCondition, IPreExistingCondition } from '../../Definitions'
import {
  Client,
  IPreExistingConditionDto,
  PreExistingConditionDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'PreExistingConditionDto'

export default class PreExistingConditionsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public preExistingConditions: IPreExistingCondition[]
  @observable
  public selectedPreExistingCondition?: IPreExistingCondition
  public dataTableStore: DataTableStore<IPreExistingConditionDto, IPreExistingCondition>

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<
      IPreExistingConditionDto,
      IPreExistingCondition
    >(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllPreExistingConditions(
          filter,
          page,
          undefined,
          orderBy,
          includeInactives
        ),
      (preExistingCondition) => this.setupPreExistingConditions(preExistingCondition)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedPreExistingCondition = undefined
  }

  @action.bound
  public disablePreExistingCondition(preExistingCondition: IPreExistingCondition) {
    this.isLoading = true
    return this.client
      .archivePreExistingCondition(preExistingCondition.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndPreExistingConditions() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllPreExistingConditions() {
    this.isLoading = true
    return this.client
      .getAllPreExistingConditions(undefined, 1, 5000)
      .then((resp: SwaggerResponse<IPreExistingConditionDto[]>) =>
        runInAction(() => {
          this.preExistingConditions = this.setupPreExistingConditions(resp.result)
        })
      )
  }

  @action.bound
  public openDialogWithPreExistingCondition(preExistingCondition: IPreExistingCondition) {
    this.isModalOpen = true
    this.selectedPreExistingCondition = preExistingCondition
  }

  @action.bound
  public recoverPreExistingCondition(preExistingCondition: IPreExistingCondition) {
    this.isLoading = true
    return this.client
      .reactivatePreExistingCondition(preExistingCondition.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public savePreExistingCondition(preExistingCondition: IPreExistingCondition) {
    this.isLoading = true
    let promise
    const preExistingConditionDto = new PreExistingConditionDto(preExistingCondition)
    if (preExistingCondition.isNew) {
      promise = this.client.createPreExistingCondition(preExistingConditionDto)
    } else {
      promise = this.client.updatePreExistingCondition(
        preExistingCondition.id,
        preExistingConditionDto
      )
    }
    return promise
      .then(() => {
        this.resetUIState(preExistingCondition.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupPreExistingConditions = (
    preExistingConditions: IPreExistingConditionDto[]
  ) => {
    return preExistingConditions.map((x) =>
      this.setupPreExistingConditionMenuItems(this.addDefaultFields(x))
    )
  }

  private addDefaultFields = (
    preExistingCondition: IPreExistingConditionDto
  ): IPreExistingCondition => {
    return { ...DefaultPreExistingCondition(), ...preExistingCondition }
  }

  private setupPreExistingConditionMenuItems = (
    preExistingCondition: IPreExistingCondition
  ) => {
    preExistingCondition.menuItems = [
      {
        icon: Edit,
        name: 'Edit',
        onClick: () => this.openDialogWithPreExistingCondition(preExistingCondition),
      },
    ]
    if (preExistingCondition.isActive) {
      preExistingCondition.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disablePreExistingCondition(preExistingCondition),
      })
    } else {
      preExistingCondition.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverPreExistingCondition(preExistingCondition),
      })
    }
    return preExistingCondition
  }
}
