import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { NoteAdd } from '@material-ui/icons'
import React from 'react'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { Form, Formik } from 'formik'
import FullWidthField from 'src/common/FullWidthField'
import { AppealLogDTO } from 'src/viewModels/AppealLogDTO'
import { AddAppealNote } from 'src/services/ClaimService'
import { AppealType } from 'src/utils/AppealType'

interface IQuoteStatusModalProps {
  isOpen?: boolean
  claimId: string
  closeModal: () => void
}

class AppealNoteModal extends React.Component<IQuoteStatusModalProps> {
  public secondFormRef = React.createRef<any>()

  public render() {
    const { isOpen, claimId } = this.props

    return (
      <Dialog
        key={claimId}
        fullWidth={true}
        maxWidth="md"
        open={isOpen!}
        onClose={this.close}
      >
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title="Add Appeal Note"
            pageIcon={<NoteAdd />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent style={{ paddingBottom: '0px' }}>
          <Grid item xs={12}>
            <Formik
              initialValues={{ comment: '' }}
              onSubmit={() => {}}
              validationSchema={undefined}
              ref={this.secondFormRef}
            >
              {() => (
                <Form id="secondForm">
                  <Grid spacing={2} justifyContent="space-between">
                    <>
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        style={{ margin: '10px 0px 0px 0px' }}
                      >
                        <Grid item xs={12}>
                          <FullWidthField
                            label="Note"
                            name="note"
                            variant="outlined"
                            multiline
                            rows="6"
                          />
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close" variant="contained">
            Close
          </Button>
          <Button onClick={this.save} data-cy="save" variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private close = () => {
    this.props.closeModal!()
  }

  private save = () => {
    const formikValues = this.secondFormRef.current?.state?.values
    var dto = new AppealLogDTO()
    dto.notes = formikValues.note
    dto.appealType = AppealType.NA
    dto.originalCMS1500Id = this.props.claimId

    AddAppealNote(dto).then(() => {
      this.props.closeModal!()
    })
  }
}

export default AppealNoteModal
