import _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import Moment from 'moment'
import { IBillTableFormValues } from '../../bills/billsTable/BillTableFormValues'
import { DefaultProvider, IProvider } from '../../Definitions'
import { Client, IProviderDto, SwaggerResponse } from '../../generated_client'
import GlobalStore from '../GlobalStore'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import {
  GetPayProviderTotals,
  GetSurgeryPayProviderLocationOptions,
} from 'src/services/LookupService'
import { PayProvidersSearchRequest } from 'src/viewModels/PayProvidersSearchRequest'
import { getAllSurgeryProviderBills } from 'src/services/SearchService'
import { SentCheckSummaryResult } from 'src/viewModels/SentCheckSummaryResult'
import { PayProvidersTotalSummary } from 'src/viewModels/PayProvidersTotalSummary'

export default class BillsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public payProviderAlertMessage: string = ''
  @observable
  public isModalOpen: boolean = false
  @observable
  public providers: IProvider[] = []
  @observable
  public filteredProviders: IProvider[] = []
  @observable
  public selectedBills?: IBillTableFormValues
  @observable
  public selectedDateRange: string | undefined
  @observable
  public selectedFromDate: Date | null = null
  @observable
  public selectedToDate: Date | null = null
  @observable
  public surgeryPayProviderOptions?: DropdownOption[]
  @observable
  public payProviderTotals?: PayProvidersTotalSummary
  @observable
  public isAssignedCheckOpen?: boolean
  @observable
  public assignedCheckResults?: SentCheckSummaryResult[]

  constructor(
    private globalStore: GlobalStore,
    private routerStore: RouterStore,
    private client: Client
  ) {}

  @action.bound
  public getAllProviderBills(providerId?: string) {
    this.isLoading = true
    return this.client
      .getAllProviderBills(
        providerId,
        this.globalStore.currentAppOrganization
          ? this.globalStore.currentAppOrganization.id
          : undefined
      )
      .then((resp: SwaggerResponse<IProviderDto[]>) =>
        runInAction(() => {
          this.providers = resp.result.map((x) => this.addDefaultFields(x))
          this.filteredProviders = this.providers
        })
      )
      .catch(() => {})
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public getAllSurgeryProviderBills(request: PayProvidersSearchRequest) {
    this.isLoading = true
    return getAllSurgeryProviderBills(request)
      .then((resp: IProviderDto[]) =>
        runInAction(() => {
          this.providers = resp.map((x) => this.addDefaultFields(x))
          this.filteredProviders = this.providers
        })
      )
      .catch(() => {})
      .finally(() => {
        this.isLoading = false
      })
  }

  private addDefaultFields = (provider: IProviderDto): IProvider => {
    return { ...DefaultProvider(), ...provider }
  }

  @action.bound
  public openWriteChecks(values: IBillTableFormValues) {
    this.selectedBills = values
    this.selectedBills.providers = this.selectedBills.providers.filter((x) =>
      x.cmS1500s!.find((y) => y.isChecked)
    )
    this.routerStore.push('/payproviders/checks')
  }

  @action.bound
  public setSelectedDateRange(dateRange: string | undefined) {
    this.selectedDateRange = dateRange
    const startDate = new Date(new Date().toDateString())
    const endDate = new Date(new Date().toDateString())
    switch (this.selectedDateRange) {
      case 'Yesterday':
        this.selectedFromDate = startDate
        this.selectedToDate = endDate
        this.selectedFromDate.setDate(startDate.getDate() - 1)
        this.selectedToDate.setDate(endDate.getDate() - 1)
        break
      case 'Last 7 Days':
        this.selectedFromDate = startDate
        this.selectedFromDate.setDate(startDate.getDate() - 7)
        this.selectedToDate = endDate
        break
      case 'Last 30 Days':
        this.selectedFromDate = startDate
        this.selectedFromDate.setDate(startDate.getDate() - 30)
        this.selectedToDate = endDate
        break
      case undefined:
        this.selectedFromDate = null
        this.selectedToDate = null
        break
      case 'Today':
      case 'Custom':
      default:
        this.selectedFromDate = startDate
        this.selectedToDate = endDate
        break
    }

    this.filterProviders()
  }

  @action.bound
  public setSelectedDate(date: Date | null, isStartDate: boolean) {
    const dateString = date ? new Date(date.toDateString()) : null
    if (isStartDate) {
      this.selectedFromDate = dateString
    } else {
      this.selectedToDate = dateString
    }

    this.filterProviders()
  }

  @action.bound
  public clearProviders() {
    this.providers = []
    this.payProviderAlertMessage = ''
    this.filterProviders()
  }

  private filterProviders() {
    this.filteredProviders = _.cloneDeep(this.providers).filter(
      (provider) => this.filterCms1500sAndReturnLength(provider) > 0
    )
  }

  private filterCms1500sAndReturnLength(provider: IProvider) {
    provider.cmS1500s = provider.cmS1500s.filter(
      (cms1500) =>
        Moment(cms1500.incomingOrOutgoingDate).isBetween(
          this.selectedFromDate ? this.selectedFromDate : new Date(1900, 1, 1),
          this.selectedToDate ? this.selectedToDate : undefined,
          'day',
          '[]'
        ) ||
        (!this.selectedFromDate && !this.selectedToDate)
    )

    return provider.cmS1500s.length
  }

  @action.bound
  public clearFilters() {
    this.selectedDateRange = undefined
    this.selectedToDate = null
    this.selectedFromDate = null
  }

  @action.bound
  public getSurgeryPayProviderOptions() {
    return GetSurgeryPayProviderLocationOptions().then((result) => {
      this.surgeryPayProviderOptions = result
    })
  }

  @action.bound
  public getPayProviderTotals() {
    return GetPayProviderTotals().then((result) => {
      this.payProviderTotals = result
    })
  }

  @action.bound
  public loadSurgeryPayProviderBills = async (
    request: PayProvidersSearchRequest,
    setSavedSearch: boolean = false
  ) => {
    this.isLoading = true
    const promises = [] as Array<Promise<void>>
    promises.push(this.getAllSurgeryProviderBills(request))
    promises.push(this.getSurgeryPayProviderOptions())
    promises.push(this.getPayProviderTotals())

    await Promise.all(promises).finally(() => {
      this.isLoading = false
      let locationText = this.providers!.length == 1 ? ' location' : ' locations'
      if (
        (request.locationName == undefined || request.locationName == '') &&
        (request.eocGeneratedOrLegacyId == undefined ||
          request.eocGeneratedOrLegacyId == '') &&
        (request.contractAmountMax == undefined || request.contractAmountMax == '') &&
        (request.contractAmountMin == undefined || request.contractAmountMin == '') &&
        (request.dueDateStart == undefined || request.dueDateStart == '') &&
        (request.dueDateEnd == undefined || request.dueDateEnd == '')
      ) {
        this.payProviderAlertMessage =
          'Displaying ' +
          this.providers!.length +
          ' of ' +
          this.surgeryPayProviderOptions!.length +
          locationText +
          ' with outstanding claims'
      } else {
        this.payProviderAlertMessage = ''
      }
      if (setSavedSearch) {
        this.globalStore.setPayProviderRequest!(request)
      }
    })
  }

  @action.bound
  public openAssignedCheckDialog(results: SentCheckSummaryResult[]) {
    this.assignedCheckResults = results
    this.isAssignedCheckOpen = true
  }

  @action.bound
  closeAssignedCheck() {
    this.isAssignedCheckOpen = false
    this.assignedCheckResults = undefined
    this.routerStore.push('/payproviders')
  }
}
