import {
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
} from '@material-ui/core'
import { SelectProps } from '@material-ui/core/Select'
import { Field } from 'formik'
import { Select } from 'formik-material-ui'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { IUser } from 'src/Definitions'
import { DropdownOption } from 'src/viewModels/DropdownOption'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'fit-content',
    },
  },
  anchorOrigin: {
    horizontal: 'left',
    vertical: 'bottom',
  },
  getContentAnchorEl: null,
}

export interface ISelectFieldProps<T> extends SelectProps {
  name: string
  label?: string
  items?: T[]
  inputId: string
  required?: boolean
  fullwidth?: boolean
  disabled?: boolean
  errorMessage?: string
  checkedItems?: string[]
  cypressLabel?: string
  childCypressLabel?: string
  getName: (item: T) => string
  getValue: (item: T) => string
  shrinkLabel?: boolean
  onChange?: (any?: any) => void
  filterItems?: (item: T) => boolean
  outlined?: boolean
  disableDuplicates?: IDuplicateFunction
  disableInactive?: boolean
}

interface ISelectFieldState {
  labelWidth: number
}

interface IDuplicateFunction {
  comparator: (a: any) => any
  list: any[]
}

export default class SelectField<T> extends React.Component<
  ISelectFieldProps<T>,
  ISelectFieldState
> {
  private InputLabelRef: HTMLElement | null

  constructor(props: ISelectFieldProps<T>) {
    super(props)
    this.state = {
      labelWidth: 0,
    }
  }

  public componentDidMount() {
    if (this.props.outlined) {
      this.setState({
        labelWidth: (
          ReactDOM.findDOMNode(this.InputLabelRef as React.ReactInstance) as HTMLElement
        ).offsetWidth,
      })
    }
  }

  public render() {
    const {
      name,
      label,
      items,
      inputId,
      disabled,
      disableDuplicates,
      disableInactive = false,
      fullWidth = false,
      required = false,
      multiple = false,
      checkedItems = Array<string>(),
      getName,
      getValue,
      cypressLabel,
      childCypressLabel,
      onChange,
      filterItems = () => true,
      outlined,
      errorMessage,
    } = this.props
    return (
      <FormControl
        fullWidth={fullWidth}
        required={required}
        disabled={disabled}
        error={errorMessage !== undefined}
      >
        {label && (
          <InputLabel
            htmlFor={inputId}
            shrink={this.shouldShrinkLabel()}
            ref={
              outlined
                ? (ref) => {
                    this.InputLabelRef = ref
                  }
                : undefined
            }
            variant={outlined ? 'outlined' : 'standard'}
          >
            {label}
          </InputLabel>
        )}
        <Field
          data-cy={cypressLabel}
          component={Select}
          name={name}
          inputProps={{ id: inputId }}
          input={this.input()}
          displayEmpty
          MenuProps={MenuProps}
          multiple={multiple}
          disabled={disabled}
          onChange={onChange ? onChange!() : undefined}
          renderValue={multiple ? this.renderText(items || []) : undefined}
        >
          {items &&
            items.filter(filterItems).map((item: T, index: number) => (
              <MenuItem
                key={index}
                value={getValue(item)}
                data-cy={childCypressLabel}
                disabled={
                  (disableDuplicates &&
                    disableDuplicates.list &&
                    disableDuplicates.list.find(
                      (x) => disableDuplicates.comparator(x) === getValue(item)
                    ) !== undefined) ||
                  (disableInactive &&
                    !(item as unknown as IUser).isActive &&
                    !(item as unknown as DropdownOption).isEnabled)
                }
              >
                {multiple && <Checkbox checked={checkedItems.includes(getValue(item))} />}
                {getName(item)}
              </MenuItem>
            ))}
        </Field>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    )
  }
  private renderText = (items: T[]) => (x: string[]) =>
    items
      .filter((i) => x.includes(this.props.getValue(i)))
      .map((i) => this.props.getName(i))
      .join(', ')

  private input() {
    if (this.props.outlined) {
      return (
        <OutlinedInput
          labelWidth={this.state.labelWidth}
          notched={this.shouldShrinkLabel()}
          id={this.props.inputId}
        />
      )
    }
    return <Input id={this.props.inputId} />
  }

  private shouldShrinkLabel() {
    return this.props.shrinkLabel ? true : undefined
  }
}
