import { action, observable } from 'mobx'
import { OptionsObject } from 'notistack'

interface INotification {
  key: string
  message: string
  isShown: boolean
  options: OptionsObject
  information?: string
}

class NotificationStore {
  @observable
  public notifications: INotification[] = []
  @observable
  public isModalOpen: boolean = false
  @observable
  public modalNotification: INotification

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
  }

  @action.bound
  public openDialog(notification: INotification) {
    this.isModalOpen = true
    this.modalNotification = notification
  }

  @action.bound
  public sendNotification(
    key: string,
    message: string,
    options: OptionsObject = {},
    information?: string
  ) {
    if (this.notifications.some((n) => n.key === key)) {
      return
    }
    const notification: INotification = {
      key,
      message,
      isShown: false,
      options,
      information,
    }
    this.notifications.push(notification)
  }

  @action.bound
  public markNotificationShown(key: string) {
    this.notifications.filter((n) => n.key === key).forEach((n) => (n.isShown = true))
  }

  @action.bound
  public removeNotification(key: string) {
    this.notifications = this.notifications.filter((n) => n.key !== key)
    return undefined
  }
}

export default NotificationStore
export { INotification }
