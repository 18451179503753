import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import { inject } from 'mobx-react'
import { OptionsObject } from 'notistack'
import React from 'react'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { processAction } from 'src/services/WorkflowService'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import { ObjectiveProcessAction } from 'src/viewModels/workflow/ObjectiveProcessAction'

interface IAssignToEntryUserAndSendModalProps {
  isOpen: boolean
  closeModal: () => void
  action: InboxActionSummary
  rerouteToPath?: (path: string) => void
  inboxItemId: number
  inboxId: string
  associatedItemId: string
  data: ObjectiveProcessAction
  entryUser: string
  nextInboxId?: number
  nextInboxText?: string
  sendNotification?: (
    key: string,
    message: string,
    options?: OptionsObject,
    information?: string | undefined
  ) => void
  handleProcessActionFinish?: (selectedAction?: InboxActionSummary) => void
}

class AssignToEntryUserAndSendModal extends React.Component<IAssignToEntryUserAndSendModalProps> {
  public state = {
    executed: false,
    isLoading: true,
    comments: '',
    nextInboxId: undefined,
    nextInboxText: '',
  }

  public componentDidMount(): void {
    if (this.props.nextInboxId == undefined || this.props.nextInboxText == '') {
      this.props.sendNotification!(
        '404',
        'This action is incorrectly configured. Please contact your administrator.',
        {
          variant: 'error',
        }
      )
      this.close()
    } else if (this.props.entryUser == '') {
      this.props.sendNotification!(
        '404',
        'Missing entry user. Please contact your administrator.',
        {
          variant: 'error',
        }
      )
      this.close()
    }
    this.setState({
      isLoading: false,
    })
  }

  private close = () => {
    this.setState({
      comments: '',
      executed: false,
      nextInboxId: undefined,
      nextInboxText: '',
      isLoading: false,
    })
    this.props.closeModal()
  }

  private async save() {
    var action = this.props.action
    this.props.data.comment = this.state.comments
    this.props.data.assignUser = this.props.entryUser

    processAction(
      this.props.data,
      action!.id.toString(),
      this.props.inboxItemId.toString()
    )
      .then(() => {
        this.props.handleProcessActionFinish!(action)
        this.close()
      })
      .catch(() => {
        this.setState({ executed: false, isLoading: false })
        this.close()
      })
  }

  public render() {
    var modalTitle = this.props.action.actionName.toUpperCase()

    return (
      <>
        <Dialog
          key={this.props.action.id}
          fullWidth={true}
          maxWidth="md"
          open={this.props.isOpen!}
          disableBackdropClick
        >
          <DialogTitle style={{ padding: 0 }}>
            <ResponsiveAppBar title={modalTitle} isModal={true} />
          </DialogTitle>
          <DialogContent style={{ padding: '12px 12px 8px' }}>
            {this.state.isLoading && (
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1200',
                }}
              >
                <CircularProgress size={100} />
              </div>
            )}
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <h2>Assignment and Routing Details</h2>
                <ul>
                  <li>ASSIGNING TO: {this.props.entryUser}</li>
                  <li>ROUTING TO: {this.props.nextInboxText}</li>
                </ul>
              </Grid>
              {this.props.action.displayComment && (
                <Grid item xs={12}>
                  <TextField
                    label="Comments"
                    name="notes"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required={this.props.action.requireComment}
                    multiline
                    minRows="4"
                    onChange={(event) => this.setState({ comments: event.target.value })}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              disabled={this.props.action.requireComment && this.state.comments === ''}
              onClick={() => {
                this.setState({ executed: true, isLoading: true })
                this.save()
              }}
            >
              Execute
            </Button>
            <Button
              onClick={() => {
                this.close()
              }}
              data-cy="close"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const InjectedAssignToEntryUserAndSendModal = inject<
  IStores,
  IAssignToEntryUserAndSendModalProps,
  Partial<IAssignToEntryUserAndSendModalProps>,
  any
>((stores: IStores) => ({
  rerouteToPath: stores.global.rerouteToPath,
  sendNotification: stores.notifications.sendNotification,
}))(AssignToEntryUserAndSendModal)

export default InjectedAssignToEntryUserAndSendModal
