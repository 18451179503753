import { Grid } from '@material-ui/core'
import { Hotel } from '@material-ui/icons'
import { FormikActions, FormikProps } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import IsActiveToggle from '../common/IsActiveToggle'
import { noWhitespaceString } from '../common/YupExtensions'
import { IProcedure } from '../Definitions'
import { IStores } from '../Stores'
import * as Yup from 'yup'

const ProcedureSchema = Yup.object().shape({
  diagnostic: noWhitespaceString('Diagnostic is required', true),
})

interface IProcedureDialogProps {
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean
  procedure?: IProcedure
  saveProcedure?: (procedure: IProcedure) => Promise<void>
}

@inject((stores: IStores) => ({
  close: stores.procedures.closeDialog,
  isLoading: stores.procedures.isLoading,
  isOpen: stores.procedures.isModalOpen,
  procedure: stores.procedures.selectedProcedure,
  saveProcedure: stores.procedures.saveProcedure,
}))
export default class ProcedureDialog extends React.Component<IProcedureDialogProps> {
  public save = (values: IProcedure, formikBag: FormikActions<IProcedure>) => {
    this.props.saveProcedure!({ ...values }).finally(() => formikBag.setSubmitting(false))
  }

  private bottomActions(formikProps: FormikProps<IProcedure>) {
    const onChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      formikProps.setFieldValue('isActive', checked)
    }
    return <IsActiveToggle isActive={formikProps.values.isActive} onChange={onChange} />
  }

  public render() {
    const { close, isLoading, isOpen, procedure } = this.props
    return (
      <ESDialog
        close={close!}
        initialValues={{ ...procedure } as IProcedure}
        isLoading={isLoading}
        open={isOpen!}
        titleIcon={<Hotel />}
        save={this.save}
        bottomActions={this.bottomActions}
        title={procedure!.isNew ? 'Add New Procedure' : 'Edit Procedure'}
        validationSchema={ProcedureSchema}
      >
        {({}) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FullWidthField name="diagnostic" label="Diagnostic" required />
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }
}
