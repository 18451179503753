import _ from 'lodash'
import { AppealType } from 'src/utils/AppealType'
import { IHaveAddressFormValues } from '../common/AddressField/AddressField'
import {
  createAddressFormValues,
  IAddressFormValues,
  toAddressDto,
} from '../common/AddressField/AddressFormValues'
import { DefaultCMS1500, ICMS1500 } from '../Definitions'
import {
  AddressDto,
  ClaimEventDto,
  EpisodeOfCareDto,
  IEpisodeOfCareDto,
  ILocationDto,
  IProviderDto,
} from '../generated_client'
import { BillStatus } from '../utils/BillStatus'
import { OrganizationId } from '../utils/OrganizationIds'
import { DefaultState, IState } from '../utils/States'
import {
  createLineItemFormValues,
  ILineItemFormValues,
  toCMS1500LineItemDto,
  toBillOutLineItemDistribution,
} from './LineItemFormValues'
import { CMS1500DiagnosisDto } from 'src/viewModels/claim/CMS1500DiagnosisDto'
import { CMS1500Dto } from 'src/viewModels/claim/Cms1500Dto'
import { CMS1500LineItemDto } from 'src/viewModels/claim/CMS1500LineItemDto'
import { BilloutRequest, IBilloutRequest } from 'src/viewModels/workflow/BilloutRequest'
import { ILineItemDistributionDetail } from 'src/viewModels/LineItemDistributionDetail'

export interface IDiagnosisFormValues {
  diagnoses: Diagnosis[]
}

export interface ILineItemsFormValues {
  lineItems: ILineItemFormValues[]
}

export interface IClaimFormValues extends IHaveAddressFormValues {
  q10ARelatedToEmployment: string
  q10BAutoAccidentState?: IState
  q10BRelatedToAutoAccident: string
  q10CRelatedToOtherAccident: string
  q10DClaimCodes: string
  q11ADateOfBirth: Date | undefined
  q11ASex: string
  q11BOtherClaimId: string
  q11BQualifier: string
  q11CInsuranceProgramName: string
  q11DAnotherBenefitPlan: string
  q11PolicyFecaNumber: string
  q12PatientSignature: string
  q12PatientSignatureDate: Date | undefined
  q13InsuredSignature: string
  q14DateOfIllness: Date
  q14Qualifier: string
  q15OtherDate: Date | undefined
  q15Qualifier: string
  q16UnableToWorkFrom: Date | undefined
  q16UnabletoWorkTo: Date | undefined
  q17AId: string
  q17AQualifier: string
  q17BNpi: string
  q17NameOfReferring: string
  q17Qualifier: string
  q18HospitalizationFrom: Date | undefined
  q18HospitalizationTo: Date | undefined
  q19AdditionalClaims: string
  q1AInsuranceId: string
  q1Insurance: string
  q20Charges: number
  q20OutsideLab: string
  q21Diagnosis: Diagnosis[]
  q21DiagnosisIcdInd: string
  q22OriginalRefNo: string
  q22ResubmissionCode: string
  q23PrioAuthorizationNumber: string
  q24: ILineItemFormValues[]
  q25FederalTaxId: string
  q25FederalTaxIdSource: string
  q26PatientAccountNo: string
  q27AcceptAssignment: string
  q28TotalCharge: string | undefined
  q29AmountPaid: string | undefined
  q2PatientName: string
  q30ReservedNucc: number | undefined
  q31Date: Date | undefined
  q31Signature: string
  q32ALocationNpi: string
  q32B: string
  q32LocationInfo: string
  q33ABillingProviderNpi: string
  q33B: string
  q33BillingProviderInfo: string
  q33BillingProviderPhoneNumber: string
  q3Date: Date | undefined
  q3Sex: string
  q4InsuredName: string
  q5PatientAddress: IAddressFormValues
  q6RelationshipToInsured: string
  q7InsuredAddress: IAddressFormValues
  q8ReservedNucc: string
  q9AOtherInsuredPolicyNumber: string
  q9BReservedNucc: string
  q9CReservedNucc: string
  q9DInsuranceProgramName: string
  q9OtherInsuredName: string
  createdOn: Date
  dueDate?: Date
  readonly id: string
  incomingOrOutgoingDate?: Date
  isActive: boolean
  isIncoming?: boolean
  modifiedOn: Date
  name: string
  organizationId: string
  billStatus: string
  provider: IProviderDto | undefined
  providerId: string
  providerLocation: ILocationDto | undefined
  providerLocationId: string
  episodeOfCare: IEpisodeOfCareDto | undefined
  episodeOfCareId: string
  dateOfService: Date
  isExpanded?: boolean
  isChecked?: boolean
  providerTypeId: string
  createZeroDollarBill?: boolean
  contractAmountTotal?: number
  billedOutAmountTotal?: number
  appealType?: AppealType | undefined
  lastAppealDate?: Date | undefined
  groupNumber?: number | undefined
  lastAppealCMS1500Id?: string
}

export class Diagnosis {
  public code: string
  public id: string
  public pointer: string
}

export function diagnosisToDto(diagnosis: Diagnosis): CMS1500DiagnosisDto {
  return new CMS1500DiagnosisDto({
    code: diagnosis.code,
    id: diagnosis.id,
    pointer: diagnosis.pointer,
  })
}

function setDefaultValues(episodeOfCare?: EpisodeOfCareDto): IClaimFormValues {
  return {
    billStatus: BillStatus.Received,
    billedOutAmountTotal: undefined,
    contractAmountTotal: undefined,
    createdOn: new Date(),
    dateOfService: new Date(),
    dueDate: undefined,
    episodeOfCare: episodeOfCare || undefined,
    episodeOfCareId: episodeOfCare ? episodeOfCare.id! : '',
    id: '',
    incomingOrOutgoingDate: undefined,
    isActive: true,
    isChecked: undefined,
    isExpanded: undefined,
    isIncoming: undefined,
    modifiedOn: new Date(),
    name: '',
    organizationId: '',
    provider: undefined,
    providerId: '',
    providerLocation: undefined,
    providerLocationId: '',
    providerTypeId: '',
    q10ARelatedToEmployment: 'true',
    q10BAutoAccidentState: DefaultState,
    q10BRelatedToAutoAccident: 'false',
    q10CRelatedToOtherAccident: 'false',
    q10DClaimCodes: '',
    q11ADateOfBirth: undefined,
    q11ASex: '',
    q11BOtherClaimId:
      episodeOfCare && episodeOfCare!.organizationId === OrganizationId.Diagnostic
        ? episodeOfCare!.referral!.employer!.name!
        : '',
    q11BQualifier: '',
    q11CInsuranceProgramName: 'WORKERS COMPENSATION',
    q11DAnotherBenefitPlan: 'false',
    q11PolicyFecaNumber: '',
    q12PatientSignature: '',
    q12PatientSignatureDate: undefined,
    q13InsuredSignature: '',
    q14DateOfIllness:
      episodeOfCare && episodeOfCare!.referral ? episodeOfCare!.referralDOI! : new Date(),
    q14Qualifier: '',
    q15OtherDate: undefined,
    q15Qualifier: '',
    q16UnableToWorkFrom: undefined,
    q16UnabletoWorkTo: undefined,
    q17AId: '',
    q17AQualifier: '',
    q17BNpi:
      episodeOfCare &&
      episodeOfCare!.organizationId === OrganizationId.Diagnostic &&
      episodeOfCare!.referringDoctor
        ? episodeOfCare!.referringDoctor!.npi!
        : '',
    q17NameOfReferring:
      episodeOfCare && episodeOfCare!.organizationId === OrganizationId.Diagnostic
        ? episodeOfCare!.referringDoctorName!
        : '',
    q17Qualifier: '',
    q18HospitalizationFrom: undefined,
    q18HospitalizationTo: undefined,
    q19AdditionalClaims: '',
    q1AInsuranceId:
      episodeOfCare && episodeOfCare!.referral && episodeOfCare!.referral!.claimNumber
        ? episodeOfCare!.referral!.claimNumber
        : '',
    q1Insurance: 'OTHER',
    q20Charges: 0,
    q20OutsideLab: '',
    q21Diagnosis: createDefaultDiagnoses(),
    q21DiagnosisIcdInd: '',
    q22OriginalRefNo: '',
    q22ResubmissionCode: '',
    q23PrioAuthorizationNumber: '',
    q24: createLineItemsFormValues(),
    q25FederalTaxId:
      episodeOfCare && episodeOfCare!.organizationId === OrganizationId.Diagnostic
        ? '27-5221515'
        : '',
    q25FederalTaxIdSource:
      episodeOfCare && episodeOfCare!.organizationId === OrganizationId.Diagnostic
        ? 'EIN'
        : '',
    q26PatientAccountNo: '',
    q27AcceptAssignment: '',
    q28TotalCharge: undefined,
    q29AmountPaid: undefined,
    q2PatientName: episodeOfCare
      ? `${episodeOfCare.referral!.patient!.lastName}, ${
          episodeOfCare.referral!.patient!.firstName
        }`
      : '',
    q30ReservedNucc: undefined,
    q31Date: undefined,
    q31Signature: '',
    q32ALocationNpi: '',
    q32B: '',
    q32LocationInfo: '',
    q33ABillingProviderNpi: '',
    q33B: '',
    q33BillingProviderInfo: '',
    q33BillingProviderPhoneNumber: '',
    q3Date: episodeOfCare ? episodeOfCare.referral!.patient!.dateOfBirth! : undefined,
    q3Sex: episodeOfCare ? episodeOfCare.referral!.patient!.gender! : '',
    q4InsuredName: '',
    q5PatientAddress: createAddressFormValues(
      episodeOfCare
        ? ({
            ...episodeOfCare.referral!.patient!.address,
            id: undefined,
          } as AddressDto)
        : undefined
    ),
    q6RelationshipToInsured: 'SELF',
    q7InsuredAddress: createAddressFormValues(),
    q8ReservedNucc: '',
    q9AOtherInsuredPolicyNumber: '',
    q9BReservedNucc: '',
    q9CReservedNucc: '',
    q9DInsuranceProgramName: '',
    q9OtherInsuredName: '',
  }
}

export function setValuesFromClaim(
  claim: ICMS1500 | CMS1500Dto,
  episodeOfCare?: EpisodeOfCareDto | undefined
): IClaimFormValues {
  return {
    billStatus: claim.billStatus || BillStatus.Received,
    billedOutAmountTotal: claim.billedOutAmountTotal,
    contractAmountTotal: claim.contractAmountTotal,
    createdOn: claim.createdOn || new Date(),
    dateOfService: claim.dateOfService || new Date(),
    dueDate: claim.dueDate || undefined,
    episodeOfCare: episodeOfCare || claim.episodeOfCare || undefined,
    episodeOfCareId: episodeOfCare ? episodeOfCare.id! : claim.episodeOfCareId!,
    id: claim.id || '',
    incomingOrOutgoingDate: claim.incomingOrOutgoingDate,
    isActive: claim.isActive || true,
    isChecked: false,
    isExpanded: false,
    isIncoming: claim.isIncoming || true,
    modifiedOn: claim.modifiedOn || new Date(),
    name: claim.name || '',
    organizationId: claim.organizationId || '',
    provider: claim.provider || undefined,
    providerId: claim.providerId || '',
    providerLocation:
      claim &&
      claim.claimEvents &&
      claim.claimEvents.length > 0 &&
      claim.claimEvents[0]!.location
        ? claim.claimEvents[0]!.location
        : undefined,
    providerLocationId:
      claim &&
      claim.claimEvents &&
      claim.claimEvents.length > 0 &&
      claim.claimEvents[0]!.locationId
        ? claim.claimEvents[0]!.locationId
        : '',
    providerTypeId: claim.providerTypeId || '',
    q10ARelatedToEmployment: claim.q10ARelatedToEmployment ? 'true' : 'false',
    q10BAutoAccidentState: { name: claim.q10BAutoAccidentState || '' },
    q10BRelatedToAutoAccident: claim.q10BRelatedToAutoAccident ? 'true' : 'false',
    q10CRelatedToOtherAccident: claim.q10CRelatedToOtherAccident ? 'true' : 'false',
    q10DClaimCodes: claim.q10DClaimCodes || '',
    q11ADateOfBirth: claim.q11ADateOfBirth || undefined,
    q11ASex: claim.q11ASex || '',
    q11BOtherClaimId:
      claim.q11BOtherClaimId ||
      (episodeOfCare &&
      episodeOfCare!.organizationId === OrganizationId.Diagnostic &&
      episodeOfCare!.referral &&
      episodeOfCare!.referral!.employer
        ? episodeOfCare!.referral!.employer!.name!
        : ''),
    q11BQualifier: claim.q11BQualifier || '',
    q11CInsuranceProgramName: claim.q11CInsuranceProgramName || 'WORKERS COMPENSATION',
    q11DAnotherBenefitPlan: claim.q11DAnotherBenefitPlan ? 'true' : 'false',
    q11PolicyFecaNumber: claim.q11PolicyFecaNumber || '',
    q12PatientSignature: claim.q12PatientSignature || '',
    q12PatientSignatureDate: claim.q12PatientSignatureDate,
    q13InsuredSignature: claim.q13InsuredSignature || '',
    q14DateOfIllness:
      claim.q14DateOfIllness ||
      (episodeOfCare && episodeOfCare!.referral
        ? episodeOfCare!.referralDOI!
        : new Date()),
    q14Qualifier: claim.q14Qualifier || '',
    q15OtherDate: claim.q15OtherDate || undefined,
    q15Qualifier: claim.q15Qualifier || '',
    q16UnableToWorkFrom: claim.q16UnableToWorkFrom || undefined,
    q16UnabletoWorkTo: claim.q16UnabletoWorkTo || undefined,
    q17AId: claim.q17AId || '',
    q17AQualifier: claim.q17AQualifier || '',
    q17BNpi:
      claim.q17BNpi ||
      (episodeOfCare &&
      episodeOfCare!.organizationId === OrganizationId.Diagnostic &&
      episodeOfCare!.referringDoctor
        ? episodeOfCare!.referringDoctor!.npi!
        : ''),
    q17NameOfReferring:
      claim.q17NameOfReferring ||
      (episodeOfCare && episodeOfCare!.organizationId === OrganizationId.Diagnostic
        ? episodeOfCare!.referringDoctorName!
        : ''),
    q17Qualifier: claim.q17Qualifier || '',
    q18HospitalizationFrom: claim.q18HospitalizationFrom || undefined,
    q18HospitalizationTo: claim.q18HospitalizationTo || undefined,
    q19AdditionalClaims: claim.q19AdditionalClaims || '',
    q1AInsuranceId:
      claim.q1AInsuranceId ??
      (episodeOfCare && episodeOfCare!.referral && episodeOfCare!.referral!.claimNumber
        ? episodeOfCare!.referral!.claimNumber
        : ''),
    q1Insurance: claim.q1Insurance || 'OTHER',
    q20Charges: claim.q20Charges || 0,
    q20OutsideLab:
      claim.q20OutsideLab !== undefined ? (claim.q20OutsideLab ? 'true' : 'false') : '',
    q21Diagnosis: createDiagnosisFormValues(claim.q21Diagnosis),
    q21DiagnosisIcdInd: claim.q21DiagnosisIcdInd || '',
    q22OriginalRefNo: claim.q22OriginalRefNo || '',
    q22ResubmissionCode: claim.q22ResubmissionCode || '',
    q23PrioAuthorizationNumber: claim.q23PrioAuthorizationNumber || '',
    q24: createLineItemsFormValues(claim.q24),
    q25FederalTaxId:
      claim.q25FederalTaxId != undefined && claim.q25FederalTaxId != ''
        ? claim.q25FederalTaxId
        : episodeOfCare && episodeOfCare!.organizationId === OrganizationId.Diagnostic
          ? '27-5221515'
          : '',
    q25FederalTaxIdSource:
      claim.q25FederalTaxIdSource != undefined && claim.q25FederalTaxIdSource != ''
        ? claim.q25FederalTaxIdSource
        : episodeOfCare && episodeOfCare!.organizationId === OrganizationId.Diagnostic
          ? 'EIN'
          : '',
    q26PatientAccountNo: claim.q26PatientAccountNo ?? '',
    q27AcceptAssignment:
      claim.q27AcceptAssignment !== undefined
        ? claim.q27AcceptAssignment
          ? 'true'
          : 'false'
        : '',
    q28TotalCharge:
      claim.q28TotalCharge != undefined ? claim.q28TotalCharge!.toFixed(2) : undefined,
    q29AmountPaid:
      claim.q29AmountPaid != undefined ? claim.q29AmountPaid!.toFixed(2) : undefined,
    q2PatientName: getQ2PatientName(claim, episodeOfCare),
    q30ReservedNucc: claim.q30ReservedNucc || undefined,
    q31Date: claim.q31Date,
    q31Signature: claim.q31Signature || '',
    q32ALocationNpi: claim.q32ALocationNpi || '',
    q32B: claim.q32B || '',
    q32LocationInfo: claim.q32LocationInfo || '',
    q33ABillingProviderNpi: claim.q33ABillingProviderNpi || '',
    q33B: claim.q33B || '',
    q33BillingProviderInfo: claim.q33BillingProviderInfo || '',
    q33BillingProviderPhoneNumber: claim.q33BillingProviderPhoneNumber || '',
    q3Date:
      claim.q3Date ??
      (episodeOfCare ? episodeOfCare.referral!.patient!.dateOfBirth! : new Date()),
    q3Sex:
      claim.q3Sex != '' && claim.q3Sex != undefined
        ? claim.q3Sex
        : episodeOfCare
          ? episodeOfCare.referral!.patient!.gender!
          : '',
    q4InsuredName: claim.q4InsuredName || '',
    q5PatientAddress: createAddressFormValues(
      claim.q5PatientAddress
        ? new AddressDto(claim.q5PatientAddress)
        : episodeOfCare
          ? ({
              ...episodeOfCare!.referral!.patient!.address,
              id: undefined,
            } as AddressDto)
          : undefined
    ),
    q6RelationshipToInsured: claim.q6RelationshipToInsured || 'SELF',
    q7InsuredAddress: createAddressFormValues(claim.q7InsuredAddress!),
    q8ReservedNucc: claim.q8ReservedNucc || '',
    q9AOtherInsuredPolicyNumber: claim.q9AOtherInsuredPolicyNumber || '',
    q9BReservedNucc: claim.q9BReservedNucc || '',
    q9CReservedNucc: claim.q9CReservedNucc || '',
    q9DInsuranceProgramName: claim.q9DInsuranceProgramName || '',
    q9OtherInsuredName: claim.q9OtherInsuredName || '',
    appealType: claim.appealType,
    lastAppealDate: claim.lastAppealDate,
    groupNumber: claim.groupNumber,
    lastAppealCMS1500Id: claim.lastAppealCMS1500Id,
  }
}

function createDefaultLineItemFormValues() {
  const lineItems = new Array<ILineItemFormValues>(1)
  return _.map(lineItems, () => createLineItemFormValues())
}

function createLineItemsFormValues(lineItems?: CMS1500LineItemDto[]) {
  if (lineItems === undefined || lineItems.length === 0) {
    return createDefaultLineItemFormValues()
  } else {
    return setValuesFromLineItems(lineItems)
  }
}

function getQ2PatientName(
  claim: ICMS1500 | CMS1500Dto,
  episodeOfCare?: EpisodeOfCareDto | undefined
) {
  if (claim && claim.q2PatientName) {
    return claim.q2PatientName
  } else if (episodeOfCare) {
    return `${episodeOfCare.referral!.patient!.lastName}, ${
      episodeOfCare.referral!.patient!.firstName
    }`
  } else {
    return ''
  }
}

function setValuesFromLineItems(lineItems: CMS1500LineItemDto[]) {
  const sortedLineItems = [...lineItems]
  return sortedLineItems.map((x) => createLineItemFormValues(x))
}

function createDefaultDiagnoses() {
  const diagnoses = new Array<Diagnosis>(12)
  for (let i = 0; i < diagnoses.length; i++) {
    diagnoses[i] = new Diagnosis()
    diagnoses[i].code = ''
    diagnoses[i].id = ''
  }
  diagnoses[0].pointer = 'A'
  diagnoses[1].pointer = 'B'
  diagnoses[2].pointer = 'C'
  diagnoses[3].pointer = 'D'
  diagnoses[4].pointer = 'E'
  diagnoses[5].pointer = 'F'
  diagnoses[6].pointer = 'G'
  diagnoses[7].pointer = 'H'
  diagnoses[8].pointer = 'I'
  diagnoses[9].pointer = 'J'
  diagnoses[10].pointer = 'K'
  diagnoses[11].pointer = 'L'
  return diagnoses
}

export function setValuesFromDiagnosis(
  diagnosis: CMS1500DiagnosisDto[],
  addExtraRows?: boolean
): Diagnosis[] {
  const fullDiagnosisList = diagnosis
  if (addExtraRows) {
    const allLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L']
    const usedLetters = fullDiagnosisList.map((d) => d.pointer)
    const missingLetters = allLetters.filter((letter) => !usedLetters.includes(letter))
    missingLetters.forEach((missingLetter) => {
      const newDiagnosis = new CMS1500DiagnosisDto()
      newDiagnosis.pointer = missingLetter
      fullDiagnosisList.push(newDiagnosis)
    })
  }
  var sortedList = fullDiagnosisList.sort((a, b) => a.pointer!.localeCompare(b.pointer!))
  return sortedList.map((x) => {
    return { code: x.code, pointer: x.pointer, id: x.id } as Diagnosis
  })
}

function createDiagnosisFormValues(diagnosis?: CMS1500DiagnosisDto[]): Diagnosis[] {
  if (diagnosis === undefined) {
    return createDefaultDiagnoses()
  } else {
    return setValuesFromDiagnosis(diagnosis, true)
  }
}

export function createIClaimFormValues(
  claim?: ICMS1500 | CMS1500Dto,
  episodeOfCare?: EpisodeOfCareDto
) {
  if (claim === undefined) {
    return setDefaultValues(episodeOfCare)
  } else {
    return setValuesFromClaim(claim, episodeOfCare)
  }
}

export async function submitClaimFormValues(
  values: IClaimFormValues,
  original?: ICMS1500,
  selectedSchedule?: string
): Promise<ICMS1500> {
  const cms1500 = original === undefined ? DefaultCMS1500() : original
  let claimEvents = undefined as unknown as ClaimEventDto[]

  if (original?.isNew || original?.appealType !== undefined) {
    claimEvents =
      selectedSchedule && values.providerLocationId
        ? [
            new ClaimEventDto({
              locationId: values.providerLocationId,
              isActive: true,
              scheduleId: selectedSchedule,
            }),
          ]
        : !selectedSchedule
          ? [
              new ClaimEventDto({
                locationId: values.providerLocationId,
                isActive: true,
              }),
            ]
          : []
  }

  return {
    ...cms1500,
    billStatus: values.billStatus,
    billedOutAmountTotal: values.billedOutAmountTotal || 0,
    claimEvents: claimEvents,
    contractAmountTotal: values.contractAmountTotal || 0,
    dateOfService: values.dateOfService,
    dueDate: values.dueDate,
    episodeOfCareId: values.episodeOfCareId,
    episodeOfCare: undefined,
    id: values.id,
    incomingOrOutgoingDate: values.incomingOrOutgoingDate,
    isActive: values.isActive,
    providerId: values.providerId,
    provider: undefined,
    providerTypeId: values.providerTypeId,
    q10ARelatedToEmployment: values.q10ARelatedToEmployment === 'true',
    q10BAutoAccidentState: values.q10BAutoAccidentState
      ? values.q10BAutoAccidentState.name
      : '',
    q10BRelatedToAutoAccident: values.q10BRelatedToAutoAccident === 'true',
    q10CRelatedToOtherAccident: values.q10CRelatedToOtherAccident === 'true',
    q10DClaimCodes: values.q10DClaimCodes,
    q11ADateOfBirth: values.q11ADateOfBirth,
    q11ASex: values.q11ASex,
    q11BOtherClaimId: values.q11BOtherClaimId,
    q11BQualifier: values.q11BQualifier,
    q11CInsuranceProgramName: values.q11CInsuranceProgramName,
    q11DAnotherBenefitPlan: values.q11DAnotherBenefitPlan === 'true',
    q11PolicyFecaNumber: values.q11PolicyFecaNumber,
    q12PatientSignature: values.q12PatientSignature,
    q12PatientSignatureDate: values.q12PatientSignatureDate,
    q13InsuredSignature: values.q13InsuredSignature,
    q14DateOfIllness: values.q14DateOfIllness,
    q14Qualifier: values.q14Qualifier,
    q15OtherDate: values.q15OtherDate,
    q15Qualifier: values.q15Qualifier,
    q16UnableToWorkFrom: values.q16UnableToWorkFrom,
    q16UnabletoWorkTo: values.q16UnabletoWorkTo,
    q17AId: values.q17AId,
    q17AQualifier: values.q17AQualifier,
    q17BNpi: values.q17BNpi,
    q17NameOfReferring: values.q17NameOfReferring,
    q17Qualifier: values.q17Qualifier,
    q18HospitalizationFrom: values.q18HospitalizationFrom,
    q18HospitalizationTo: values.q18HospitalizationTo,
    q19AdditionalClaims: values.q19AdditionalClaims,
    q1AInsuranceId: values.q1AInsuranceId,
    q1Insurance: values.q1Insurance,
    q20Charges: values.q20Charges,
    q20OutsideLab:
      values.q20OutsideLab != '' ? values.q20OutsideLab === 'true' : undefined,
    q21Diagnosis: values.q21Diagnosis.map(diagnosisToDto),
    q21DiagnosisIcdInd: values.q21DiagnosisIcdInd,
    q22OriginalRefNo: values.q22OriginalRefNo,
    q22ResubmissionCode: values.q22ResubmissionCode,
    q23PrioAuthorizationNumber: values.q23PrioAuthorizationNumber,
    q24: values.q24.map(toCMS1500LineItemDto),
    q25FederalTaxId: values.q25FederalTaxId,
    q25FederalTaxIdSource: values.q25FederalTaxIdSource,
    q26PatientAccountNo: values.q26PatientAccountNo,
    q27AcceptAssignment:
      values.q27AcceptAssignment != ''
        ? values.q27AcceptAssignment === 'true'
        : undefined,
    q28TotalCharge: +values.q28TotalCharge!,
    q29AmountPaid: +values.q29AmountPaid!,
    q2PatientName: values.q2PatientName,
    q30ReservedNucc: values.q30ReservedNucc,
    q31Date: values.q31Date,
    q31Signature: values.q31Signature,
    q32ALocationNpi: values.q32ALocationNpi,
    q32B: values.q32B,
    q32LocationInfo: values.q32LocationInfo,
    q33ABillingProviderNpi: values.q33ABillingProviderNpi,
    q33B: values.q33B,
    q33BillingProviderInfo: values.q33BillingProviderInfo,
    q33BillingProviderPhoneNumber: values.q33BillingProviderPhoneNumber,
    q3Date: values.q3Date,
    q3Sex: values.q3Sex,
    q4InsuredName: values.q4InsuredName,
    q5PatientAddress: await toAddressDto(values.q5PatientAddress),
    q6RelationshipToInsured: values.q6RelationshipToInsured,
    q7InsuredAddress: await toAddressDto(values.q7InsuredAddress),
    q8ReservedNucc: values.q8ReservedNucc,
    q9AOtherInsuredPolicyNumber: values.q9AOtherInsuredPolicyNumber,
    q9BReservedNucc: values.q9BReservedNucc,
    q9CReservedNucc: values.q9CReservedNucc,
    q9DInsuranceProgramName: values.q9DInsuranceProgramName,
    q9OtherInsuredName: values.q9OtherInsuredName,
    appealType: original?.appealType,
    appealNotes: original?.appealNotes,
    appealDescription: original?.appealDescription,
    lastAppealDate: original?.lastAppealDate,
    originalCMS1500Id: original?.originalCMS1500Id,
    billCheckDtos: undefined,
  }
}

export async function submitBillOutFormValues(
  values: IClaimFormValues
): Promise<ILineItemDistributionDetail[]> {
  return values.q24
    .filter((x) => x.dProcedureCptHcpcs !== '')
    .map(toBillOutLineItemDistribution)
}

export async function submitBillOutWorkflowCustomFormValues(
  values: IClaimFormValues
): Promise<IBilloutRequest> {
  let createBilloutRequest = new BilloutRequest()

  createBilloutRequest.lineItemDistributionDetails = values.q24
    .filter((x) => x.dProcedureCptHcpcs !== '')
    .map(toBillOutLineItemDistribution)

  createBilloutRequest.diagnosisCodes = values.q21Diagnosis.map(diagnosisToDto)

  return createBilloutRequest
}
