export interface IDashboardSearchRequest {
  patientFirstName: string | undefined
  patientLastName: string | undefined
  apptDateStart: string | undefined
  apptDateEnd: string | undefined
  providerGroupName: string | undefined
  componentId: string | undefined
  coordinatorId: string | undefined
  page?: number
  pageSize?: number
}

export class DashboardSearchRequest implements IDashboardSearchRequest {
  public patientFirstName: string | undefined
  public patientLastName: string | undefined
  public apptDateStart: string | undefined
  public apptDateEnd: string | undefined
  public providerGroupName: string | undefined
  public componentId: string | undefined
  public coordinatorId: string | undefined
  public page?: number | undefined
  public pageSize?: number | undefined
}
