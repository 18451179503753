import { createTheme } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

const primaryColor = '#29348F'
const secondaryColor = '#8bb84b'

const surgicalTheme = createTheme({
  overrides: {
    MuiCheckbox: {
      root: {
        height: '25px',
      },
    },
    MuiExpansionPanel: {
      root: {
        '&$expanded': {
          marginTop: '10px',
        },
        marginTop: '10px',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        paddingBottom: '5px',
        paddingLeft: '20px',
        paddingRight: '0px',
        paddingTop: '15px',
      },
    },
    MuiExpansionPanelSummary: {
      content: {
        '&$expanded': {
          margin: '2px 0',
        },
        margin: '2px',
      },
      root: {
        '&$expanded': {
          minHeight: '32px',
        },
        borderBottom: '2px',
        borderBottomColor: secondaryColor,
        borderBottomStyle: 'solid',
        marginBottom: -1,
        minHeight: '32px',
        paddingLeft: '10px',
      },
    },
    MuiFormControlLabel: {
      label: {
        whiteSpace: 'nowrap',
      },
    },
    MuiLink: {
      root: {
        whiteSpace: 'nowrap',
        cursor: 'pointer',
      },
    },
    MuiMenuItem: {
      root: {
        '&:focus': {
          backgroundColor: alpha(primaryColor, 0.5),
        },
        '&:hover': {
          backgroundColor: alpha(primaryColor, 0.5),
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '8px',
      },
    },
    MuiSelect: {
      select: {
        padding: '8px',
      },
    },
    MuiTableCell: {
      root: {
        padding: '4px 14px 4px 12px',
      },
    },
    MuiNativeSelect: {
      select: {
        padding: '8px',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '16px',
        maxWidth: '500px',
        whiteSpace: 'pre-wrap',
      },
    },
  },

  palette: {
    action: {
      disabledBackground: alpha('#aaaaaa', 0.3),
    },
    primary: { main: primaryColor, contrastText: '#ffffff' },
    secondary: { main: secondaryColor },
  },
})

export default surgicalTheme
