import { Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import {
  DefaultMultipleProcedureDiscountExclusionCode,
  IMultipleProcedureDiscountExclusionCode,
} from '../../Definitions'
import {
  Client,
  IMultipleProcedureDiscountExclusionCodeDto,
  MultipleProcedureDiscountExclusionCodeDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'MultipleProcedureDiscountExclusionCodeDto'

export default class MultipleProcedureDiscountExclusionCodesStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public multipleProcedureDiscountExclusionCodes: IMultipleProcedureDiscountExclusionCode[]
  @observable
  public selectedMultipleProcedureDiscountExclusionCode?: IMultipleProcedureDiscountExclusionCode
  public dataTableStore: DataTableStore<
    IMultipleProcedureDiscountExclusionCodeDto,
    IMultipleProcedureDiscountExclusionCode
  >

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<
      IMultipleProcedureDiscountExclusionCodeDto,
      IMultipleProcedureDiscountExclusionCode
    >(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllMultipleProcedureDiscountExclusionCodes(
          filter,
          page,
          undefined,
          orderBy,
          includeInactives
        ),
      (multipleProcedureDiscountExclusionCode) =>
        this.setupMultipleProcedureDiscountExclusionCodes(
          multipleProcedureDiscountExclusionCode
        )
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedMultipleProcedureDiscountExclusionCode = undefined
  }

  @action.bound
  public deleteMultipleProcedureDiscountExclusionCode(
    multipleProcedureDiscountExclusionCode: IMultipleProcedureDiscountExclusionCode
  ) {
    this.isLoading = true
    return this.client
      .deleteMultipleProcedureDiscountExclusionCode(
        multipleProcedureDiscountExclusionCode.id
      )
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndMultipleProcedureDiscountExclusionCodes() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllMultipleProcedureDiscountExclusionCodes() {
    this.isLoading = true
    return this.client
      .getAllMultipleProcedureDiscountExclusionCodes()
      .then((resp: SwaggerResponse<IMultipleProcedureDiscountExclusionCodeDto[]>) =>
        runInAction(() => {
          this.multipleProcedureDiscountExclusionCodes =
            this.setupMultipleProcedureDiscountExclusionCodes(resp.result)
        })
      )
  }

  @action.bound
  public openDialogWithMultipleProcedureDiscountExclusionCode(
    multipleProcedureDiscountExclusionCode: IMultipleProcedureDiscountExclusionCode
  ) {
    this.isModalOpen = true
    this.selectedMultipleProcedureDiscountExclusionCode =
      multipleProcedureDiscountExclusionCode
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveMultipleProcedureDiscountExclusionCode(
    multipleProcedureDiscountExclusionCode: IMultipleProcedureDiscountExclusionCode
  ) {
    this.isLoading = true
    let promise
    const multipleProcedureDiscountExclusionCodeDto =
      new MultipleProcedureDiscountExclusionCodeDto(
        multipleProcedureDiscountExclusionCode
      )
    if (multipleProcedureDiscountExclusionCode.isNew) {
      promise = this.client.createMultipleProcedureDiscountExclusionCode(
        multipleProcedureDiscountExclusionCodeDto
      )
    } else {
      promise = this.client.updateMultipleProcedureDiscountExclusionCode(
        multipleProcedureDiscountExclusionCode.id,
        multipleProcedureDiscountExclusionCodeDto
      )
    }
    return promise
      .then(() => {
        this.resetUIState(multipleProcedureDiscountExclusionCode.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupMultipleProcedureDiscountExclusionCodes = (
    multipleProcedureDiscountExclusionCodes: IMultipleProcedureDiscountExclusionCodeDto[]
  ) => {
    return multipleProcedureDiscountExclusionCodes.map((x) =>
      this.setupMultipleProcedureDiscountExclusionCodeMenuItems(this.addDefaultFields(x))
    )
  }

  private addDefaultFields = (
    multipleProcedureDiscountExclusionCode: IMultipleProcedureDiscountExclusionCodeDto
  ): IMultipleProcedureDiscountExclusionCode => {
    return {
      ...DefaultMultipleProcedureDiscountExclusionCode(),
      ...multipleProcedureDiscountExclusionCode,
    }
  }

  private setupMultipleProcedureDiscountExclusionCodeMenuItems = (
    multipleProcedureDiscountExclusionCode: IMultipleProcedureDiscountExclusionCode
  ) => {
    multipleProcedureDiscountExclusionCode.menuItems = [
      {
        icon: Edit,
        isConfirm: false,
        name: 'Edit',
        onClick: () =>
          this.openDialogWithMultipleProcedureDiscountExclusionCode(
            multipleProcedureDiscountExclusionCode
          ),
      },
      {
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Delete',
        onClick: () =>
          this.deleteMultipleProcedureDiscountExclusionCode(
            multipleProcedureDiscountExclusionCode
          ),
      },
    ]
    return multipleProcedureDiscountExclusionCode
  }
}
