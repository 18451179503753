import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'

export const DraggableTableHeaderCell = (id: string, index: number) => (props: any) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        const style: React.CSSProperties = {
          ...provided.draggableProps.style,
          backgroundColor: 'white',
          borderBottom: 'none',
          fontWeight: 'bold',
        }
        if (snapshot.isDragging) {
          style.border = '1px solid rgba(224, 224, 224, 1)'
        }
        return (
          <th
            {...props}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            style={style}
          >
            {props.children}
          </th>
        )
      }}
    </Draggable>
  )
}
