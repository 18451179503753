export interface IProfessionalComponentLineItemDTO {
  providerId: string
  providerName?: string
  typeId: string
  physicianId: string
  physicianName?: string
  cptCode: string
  cost?: number
  id?: string
  markedDeleted?: boolean
  markedDeletedCost?: number
  costSource?: number
  otherDescription?: string
  assistantMultiplier?: number
  actualCost?: number
  bilateral: boolean
  percentageOfContract?: number
  multipleProcedurePercentage?: number
  typeText?: string
  calculationExplanation?: string
  assistantSurgeonInfo?: string
  markExcluded?: boolean
  markExcludedCost?: number
  reconciledActualCPT?: string
  reconciledActualLocationGroupName?: string
  reconciledActualCost?: number
  reconciledCost?: number
  reconciledOverrideCost?: number
  reconciledCMS1500LineItemId?: string
  reconciledCalculationExplanation?: string
  reasonCode?: string
  markedDeletedReasonCode?: string
}

export class ProfessionalComponentLineItemDTO
  implements IProfessionalComponentLineItemDTO
{
  public providerId: string
  public providerName: string
  public typeId: string
  public physicianId: string
  public physicianName: string
  public cptCode: string
  public cost?: number
  public id?: string
  public markedDeleted?: boolean
  public markedDeletedCost?: number
  public costSource?: number
  public otherDescription?: string
  public assistantMultiplier?: number
  public actualCost?: number
  public bilateral: boolean
  public percentageOfContract?: number
  public multipleProcedurePercentage?: number
  public typeText?: string
  public calculationExplanation?: string
  public assistantSurgeonInfo?: string
  public markExcluded?: boolean
  public markExcludedCost?: number
  public reconciledActualCPT?: string
  public reconciledActualLocationGroupName?: string
  public reconciledActualCost?: number
  public reconciledCost?: number
  public reconciledOverrideCost?: number
  public reconciledCMS1500LineItemId?: string
  public reconciledCalculationExplanation?: string
  public reasonCode?: string
  public markedDeletedReasonCode?: string
  constructor(
    providerId: string,
    providerName: string,
    typeId: string,
    physicianId: string,
    physicianName: string,
    cptCode: string,
    bilateral: boolean,
    cost?: number,
    id?: string,
    markedDeleted?: boolean,
    costSource?: number,
    otherDescription?: string,
    assistantMultiplier?: number,
    actualCost?: number,
    percentageOfContract?: number,
    multipleProcedurePercentage?: number,
    typeText?: string,
    calculationExplanation?: string,
    assistantSurgeonInfo?: string,
    markExcluded?: boolean,
    markExcludedCost?: number,
    reconciledActualCPT?: string,
    reconciledActualLocationGroupName?: string,
    reconciledActualCost?: number,
    reconciledCost?: number,
    reconciledOverrideCost?: number,
    reconciledCMS1500LineItemId?: string,
    reconciledCalculationExplanation?: string,
    reasonCode?: string,
    markedDeletedReasonCode?: string
  ) {
    ;(this.providerId = providerId),
      (this.providerName = providerName),
      (this.typeId = typeId),
      (this.physicianId = physicianId),
      (this.physicianName = physicianName),
      (this.cptCode = cptCode),
      (this.cost = cost),
      (this.id = id),
      (this.markedDeleted = markedDeleted),
      (this.bilateral = bilateral),
      (this.costSource = costSource),
      (this.otherDescription = otherDescription),
      (this.assistantMultiplier = assistantMultiplier),
      (this.actualCost = actualCost),
      (this.percentageOfContract = percentageOfContract),
      (this.multipleProcedurePercentage = multipleProcedurePercentage),
      (this.typeText = typeText),
      (this.calculationExplanation = calculationExplanation),
      (this.assistantSurgeonInfo = assistantSurgeonInfo),
      (this.markExcluded = markExcluded),
      (this.markExcludedCost = markExcludedCost),
      (this.reconciledActualCPT = reconciledActualCPT),
      (this.reconciledActualLocationGroupName = reconciledActualLocationGroupName),
      (this.reconciledActualCost = reconciledActualCost),
      (this.reconciledCost = reconciledCost),
      (this.reconciledOverrideCost = reconciledOverrideCost),
      (this.reconciledCMS1500LineItemId = reconciledCMS1500LineItemId),
      (this.reconciledCalculationExplanation = reconciledCalculationExplanation),
      (this.reasonCode = reasonCode),
      (this.markedDeletedReasonCode = markedDeletedReasonCode)
  }
}
