import { Grid } from '@material-ui/core'
import { FormikActions } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import { noWhitespaceString } from '../common/YupExtensions'
import { IMultipleProcedureDiscountExclusionCode } from '../Definitions'
import { IStores } from '../Stores'
import * as Yup from 'yup'

const MultipleProcedureDiscountExclusionCodeSchema = Yup.object().shape({
  code: noWhitespaceString('CPT Code is required', true),
})

interface IMultipleProcedureDiscountExclusionCodeDialogProps {
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean
  multipleProcedureDiscountExclusionCode?: IMultipleProcedureDiscountExclusionCode
  saveMultipleProcedureDiscountExclusionCode?: (
    multipleProcedureDiscountExclusionCode: IMultipleProcedureDiscountExclusionCode
  ) => Promise<void>
}

class MultipleProcedureDiscountExclusionCodeDialog extends React.Component<IMultipleProcedureDiscountExclusionCodeDialogProps> {
  public save = (
    values: IMultipleProcedureDiscountExclusionCode,
    formikBag: FormikActions<IMultipleProcedureDiscountExclusionCode>
  ) => {
    this.props.saveMultipleProcedureDiscountExclusionCode!({
      ...values,
    }).finally(() => formikBag.setSubmitting(false))
  }

  public render() {
    const { close, isLoading, isOpen, multipleProcedureDiscountExclusionCode } =
      this.props
    return (
      <ESDialog
        close={close!}
        initialValues={
          {
            ...multipleProcedureDiscountExclusionCode,
          } as IMultipleProcedureDiscountExclusionCode
        }
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={
          multipleProcedureDiscountExclusionCode!.isNew
            ? 'Add New Multiple Procedure Discount Exclusion Code'
            : 'Edit Multiple Procedure Discount Exclusion Code'
        }
        validationSchema={MultipleProcedureDiscountExclusionCodeSchema}
      >
        {() => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FullWidthField autoFocus name="code" label="CPT Code" required />
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }
}

const InjectedMultipleProcedureDiscountExclusionCodeDialog = inject<
  IStores,
  IMultipleProcedureDiscountExclusionCodeDialogProps,
  Partial<IMultipleProcedureDiscountExclusionCodeDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.multipleProcedureDiscountExclusionCodes.closeDialog,
  isLoading: stores.multipleProcedureDiscountExclusionCodes.isLoading,
  isOpen: stores.multipleProcedureDiscountExclusionCodes.isModalOpen,
  multipleProcedureDiscountExclusionCode:
    stores.multipleProcedureDiscountExclusionCodes
      .selectedMultipleProcedureDiscountExclusionCode,
  saveMultipleProcedureDiscountExclusionCode:
    stores.multipleProcedureDiscountExclusionCodes
      .saveMultipleProcedureDiscountExclusionCode,
}))(MultipleProcedureDiscountExclusionCodeDialog)

export default InjectedMultipleProcedureDiscountExclusionCodeDialog
