import { ClaimEventSummary } from './ClaimEventSummary'
import { IProcessAction } from './ProcessAction'
import { ICMS1500Dto } from '../claim/Cms1500Dto'
import { MarkClaimAsAppealSummary } from './MarkClaimAsAppealSummary'
import { EmailLogRequest } from '../quotes/EmailLogRequest'
import { QuoteDTO } from '../quotes/QuoteDTO'
import { IApplyInvoicePaymentRequest } from '../ApplyInvoicePaymentRequest'
import { GlobalInvoiceDTO } from '../quotes/GlobalInvoiceDTO'
import { BilloutRequest } from './BilloutRequest'
import { ApplyPaymentRequest } from '../ApplyPaymentRequest'

export interface IObjectiveProcessAction extends IProcessAction {
  comment: string | undefined
  assignUser: string | undefined
  assignDueDate: Date | undefined
  pendDate: Date | undefined
  pendNote: string | undefined
  claimEventData: ClaimEventSummary | undefined
  claimData: ICMS1500Dto | undefined
  markAppealData: MarkClaimAsAppealSummary | undefined
  sendToAdjusterData: EmailLogRequest | undefined
  quoteData: QuoteDTO | undefined
  InvoiceCheckData: IApplyInvoicePaymentRequest | undefined
  bundleDurationDays: number | undefined
  billOutData: BilloutRequest | undefined
  globalInvoiceData: GlobalInvoiceDTO | undefined
  billoutCheckData: ApplyPaymentRequest | undefined
}

export class ObjectiveProcessAction implements IObjectiveProcessAction {
  public comment: string | undefined
  public assignUser: string | undefined
  public assignDueDate: Date | undefined
  public pendDate: Date | undefined
  public pendNote: string | undefined
  public claimEventData: ClaimEventSummary | undefined
  public claimData: ICMS1500Dto | undefined
  public markAppealData: MarkClaimAsAppealSummary | undefined
  public sendToAdjusterData: EmailLogRequest | undefined
  public quoteData: QuoteDTO | undefined
  public InvoiceCheckData: IApplyInvoicePaymentRequest | undefined
  public bundleDurationDays: number | undefined
  public billOutData: BilloutRequest | undefined
  public globalInvoiceData: GlobalInvoiceDTO | undefined
  public billoutCheckData: ApplyPaymentRequest | undefined
}
