import {
  CircularProgress,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
  withTheme,
} from '@material-ui/core'
import {
  ArrowDropUp,
  AttachMoney,
  InsertDriveFileOutlined,
  RecordVoiceOver,
} from '@material-ui/icons'
import { connect, ErrorMessage, FieldArray, FormikContext, getIn } from 'formik'
import { inject } from 'mobx-react'
import * as moment from 'moment'
import React from 'react'
import AddressField from '../common/AddressField/AddressField'
import DatePickerField from '../common/DatePickerField/DatePickerField'
import FullWidthField from '../common/FullWidthField'
import PhoneNumberMask from '../common/PhoneNumberMask'
import SelectField from '../common/SelectField/SelectField'
import TitleBar from '../common/TitleBar/TitleBar'
import TypeAheadField from '../common/TypeAheadField/TypeAheadField'
import { ICMS1500, ILocation, ISchedule } from '../Definitions'
import {
  AddressDto,
  EpisodeOfCareDto,
  IEpisodeOfCareDto,
  IProviderDto,
  IProviderTypeDto,
} from '../generated_client'
import { IStores } from '../Stores'
import States, { IState } from '../utils/States'
import Box24 from './Box24'
import { createIClaimFormValues, Diagnosis, IClaimFormValues } from './ClaimFormValues'
import { ILineItemFormValues } from './LineItemFormValues'
import './ClaimForm.css'
import AppealNameConstants from 'src/constants/AppealNameConstants'
import ClaimDocumentButton from './ClaimDocumentButton'
import GeneratePDFDocumentButton from './GeneratePDFDocumentButton'
import QuoteTypeNameConstants from 'src/constants/QuoteTypeNameConstants'
import ReferralContactModal from 'src/patients/EpisodesOfCare/ReferralContactModal'

const styles = ({ spacing }: Theme) =>
  createStyles({
    bottomItem: {
      bottom: 5,
      position: 'absolute',
    },
    bottomItemParent: {
      position: 'relative',
    },
    dateTo: {
      marginTop: 'auto',
    },
    form: {
      padding: '20px',
    },
    paper: {
      padding: '20px',
    },
    radioExtraText: {
      fontSize: '10pt',
    },
    radioExtraTextBold: {
      fontStyle: 'italic',
      fontWeight: 'bold',
    },
    radioLabel: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    radioOption: {
      marginRight: '14px',
    },
    smallDateField: {
      marginTop: 'auto',
    },
    topOfPage: {
      marginTop: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
    topSpacing: {
      marginTop: '10px',
    },
  })

interface IFullFormProps extends WithStyles<typeof styles> {
  episodeOfCare?: IEpisodeOfCareDto
  loadClaim?: (claimId: string) => Promise<void>
  selectedCMS1500?: ICMS1500
  selectedSchedule?: ISchedule
  selectedLocation?: ILocation
  setSelectedCMS1500?: (cms1500: ICMS1500 | undefined) => void
  errorState?: boolean
  dueDateBefore?: boolean
  showContractModal?: (provider: IProviderDto | undefined) => void
  dueDate?: string | undefined
  handleDueDateChange?: (dueDateVal: string) => void
  receivedDate?: string | undefined
  surgeryDate?: Date | undefined
  handleDateChange?: (event: any) => void
  openContractModal?: (index: number) => void
  showContractModalButton: boolean
  showPDFButton: boolean
  hasNotSubmitted: boolean
  isReferralContactModalOpen?: boolean
  openReferralContactModal?: () => void
}

interface IFormikProps {
  formik: FormikContext<IClaimFormValues>
}

class FullForm extends React.Component<IFullFormProps & IFormikProps> {
  constructor(props: IFullFormProps & IFormikProps) {
    super(props)
  }

  public state = {
    isLoading: false,
  }

  public initialValues = createIClaimFormValues(
    this.props.selectedCMS1500,
    new EpisodeOfCareDto(this.props.episodeOfCare)
  )

  public getLinkPath() {
    return (
      '/patients/patient/' +
      this.props.episodeOfCare?.referral?.patientId +
      '/referral/' +
      this.props.episodeOfCare?.referralId +
      '/episodeOfCare/' +
      this.props.episodeOfCare?.id
    )
  }

  public render() {
    const { formik, classes, showContractModal } = this.props

    if (this.props.selectedSchedule && this.props.selectedSchedule!.appointmentDate) {
      formik.values.dateOfService = this.props.selectedSchedule!.appointmentDate!
      this.initialValues.dateOfService = formik.values.dateOfService
      formik.values.q14DateOfIllness =
        this.props.episodeOfCare?.referralDOI ??
        this.props.selectedSchedule!.appointmentDate!
      this.initialValues.q14DateOfIllness = formik.values.q14DateOfIllness
    } else {
      formik.values.q14DateOfIllness = this.props.episodeOfCare?.referralDOI ?? new Date()
      this.initialValues.q14DateOfIllness = formik.values.q14DateOfIllness
    }

    const hasValue = (field: string, values: IClaimFormValues) => {
      const value = getIn(values, field)
      return !!value
    }

    const getStateName = (state: IState) => {
      return state.name
    }

    return (
      <Grid style={{ backgroundColor: '#FAFAFA' }}>
        {this.state.isLoading && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '2',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        {this.props.isReferralContactModalOpen && (
          <ReferralContactModal
            selectedReferralId={this.props.episodeOfCare?.referralId!}
          />
        )}
        <Grid item>
          <Grid container style={{ padding: 8 }}>
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ArrowDropUp />}>
                <TitleBar
                  title={
                    'General - ' +
                    (this.props.selectedCMS1500?.billStatus == 'New'
                      ? 'RECEIVED'
                      : this.props.selectedCMS1500?.billStatus
                        ? this.props.selectedCMS1500?.billStatus.toUpperCase()
                        : '[UNKNOWN]')
                  }
                  icon={AttachMoney}
                  titleComponent={
                    <div style={{ paddingTop: '8px', paddingLeft: '8px' }}>
                      {this.props.showPDFButton && (
                        <GeneratePDFDocumentButton
                          claimId={formik.values.id}
                          disabled={
                            formik.values.id == '' ||
                            (JSON.stringify(this.initialValues) !=
                              JSON.stringify(formik.values) &&
                              this.props.hasNotSubmitted)
                          }
                          setIsLoading={(loading: boolean) =>
                            this.setState({ isLoading: loading })
                          }
                        />
                      )}
                      <ClaimDocumentButton
                        docId={this.props.selectedCMS1500?.stagedDocumentId ?? ''}
                        setIsLoading={(loading: boolean) =>
                          this.setState({ isLoading: loading })
                        }
                      />
                    </div>
                  }
                  isTitleComponentPushedRight={true}
                />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container item spacing={1}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item>
                      <strong>Patient Name:</strong>&nbsp;
                      {this.props.episodeOfCare
                        ? this.props.episodeOfCare.referral!.patient!.firstName +
                          ' ' +
                          this.props.episodeOfCare.referral!.patient!.lastName
                        : 'undefined'}
                    </Grid>
                    <Grid
                      item
                      container
                      spacing={1}
                      xs={1}
                      style={{ minWidth: 'fit-content' }}
                    >
                      <Grid item>
                        <strong>EOC/Legacy:</strong>
                      </Grid>
                      <Grid item>
                        <div
                          style={{
                            color: 'darkblue',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            sessionStorage.setItem('patientTab', '0')
                            sessionStorage.setItem('eocTab', '1')
                            window.open(this.getLinkPath())
                          }}
                        >
                          {this.props.episodeOfCare
                            ? this.props.episodeOfCare.episodeOfCareNumberOrLegacyId
                            : 'undefined'}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Show Referral Contact">
                        <RecordVoiceOver
                          fontSize="small"
                          color={'primary'}
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={this.props.openReferralContactModal}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <strong> Group #:</strong>&nbsp;
                      {this.props.selectedCMS1500
                        ? this.props.selectedCMS1500.groupNumber
                          ? this.props.selectedCMS1500.groupNumber
                          : 'TBD'
                        : 'TBD'}
                    </Grid>
                    <Grid item>
                      <strong>Body Part:</strong>&nbsp;
                      {this.props.selectedSchedule?.bodyParts != undefined
                        ? this.props.selectedSchedule!.bodyParts
                        : this.props.episodeOfCare
                          ? this.props.episodeOfCare!.bodyPartText
                          : ''}
                    </Grid>
                    <Grid item>
                      {this.props.selectedCMS1500?.appealType != undefined ? (
                        <Typography noWrap variant="body2" color="error">
                          <strong>Appeal Type:</strong>&nbsp;
                          {AppealNameConstants[this.props.selectedCMS1500?.appealType]}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item>
                      <strong>ToC Date:</strong>&nbsp;
                      {this.props.episodeOfCare != undefined &&
                      this.props.episodeOfCare.effectiveDate != undefined
                        ? moment(this.props.episodeOfCare.effectiveDate).format('L')
                        : 'N/A'}
                    </Grid>
                    <Grid item>
                      <strong>Bundle Type:</strong>&nbsp;
                      {this.props.episodeOfCare != undefined &&
                      this.props.episodeOfCare.quote != undefined &&
                      this.props.episodeOfCare.quote.type != undefined
                        ? QuoteTypeNameConstants[this.props.episodeOfCare.quote.type]
                        : 'N/A'}
                    </Grid>
                    <Grid item>
                      <strong>Surgery Date:</strong>&nbsp;
                      {this.props.surgeryDate != undefined
                        ? moment(this.props.surgeryDate).format('L')
                        : 'N/A'}
                    </Grid>
                    <Grid item>
                      <strong># of Days:</strong>&nbsp;
                      {this.props.episodeOfCare != undefined &&
                      this.props.episodeOfCare.quote != undefined &&
                      this.props.episodeOfCare.quote.bundleDurationDays != undefined
                        ? this.props.episodeOfCare.quote.bundleDurationDays
                        : 'N/A'}
                    </Grid>
                    <Grid item>
                      <strong>End Date:</strong>&nbsp;
                      {this.props.episodeOfCare != undefined &&
                      this.props.episodeOfCare.quote != undefined &&
                      this.props.episodeOfCare.quote.endDate != undefined &&
                      this.props.selectedCMS1500?.inGlobal
                        ? moment(this.props.episodeOfCare.quote.endDate).format('L')
                        : 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    spacing={2}
                    xs={12}
                    style={{ paddingBottom: '34px' }}
                  >
                    <Grid item>
                      <strong>Date of Service:</strong>&nbsp;
                      {moment(formik.values.dateOfService).format('L')}
                    </Grid>
                    <Grid item>
                      <strong>Provider Group:</strong>&nbsp;
                      <span style={{ paddingRight: '4px' }}>
                        {this.getLocationProvider(formik.values)}
                      </span>
                      <Tooltip title="View Provider Contract Summary">
                        <InsertDriveFileOutlined
                          style={{
                            cursor: 'pointer',
                            fontSize: '16px',
                          }}
                          onClick={() => {
                            showContractModal!(formik.values.providerLocation?.provider)
                          }}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <strong>Provider Location:</strong>&nbsp;
                      {`${formik.values.providerLocation?.name} ${formik.values.providerLocation?.physicalAddress?.addressDisplay}` ??
                        '{UNKNOWN PROVIDER LOCATION}'}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ paddingBottom: 12 }}>
                    <Grid item xs={3}>
                      <SelectField
                        label="Provider Type"
                        name="providerTypeId"
                        inputId="providerTypeId"
                        getName={this.getProviderTypeName}
                        getValue={this.getProviderTypeValue}
                        items={this.getProviderTypes(formik.values)}
                        fullWidth
                        disabled={this.isProviderTypeDisabled(formik.values)}
                        shrinkLabel={!!formik.values.providerTypeId}
                        required
                      />
                    </Grid>
                    <Grid item xs={2} style={{ marginTop: '3px' }}>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{
                            id: 'incomingOrOutgoingDate',
                            style: { padding: '8px' },
                          }}
                          InputLabelProps={{ shrink: true }}
                          onChange={this.props.handleDateChange}
                          type="date"
                          name="incomingOrOutgoingDate"
                          variant="standard"
                          value={this.props.receivedDate}
                          label="Date Received"
                          fullWidth
                          required
                          error={this.props.receivedDate == ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="column"
                      xs={2}
                      style={{ marginTop: '3px' }}
                    >
                      <Grid item>
                        <TextField
                          inputProps={{
                            id: 'dueDate',
                            style: { padding: '8px' },
                          }}
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          name="dueDate"
                          onChange={this.handleDueDateChange}
                          variant="standard"
                          value={this.props.dueDate}
                          label="Date Due"
                          fullWidth
                          required
                          error={this.props.dueDate == ''}
                        />
                      </Grid>
                      <Grid item style={{ paddingTop: '4px' }}>
                        {this.props.dueDateBefore && (
                          <div className="error">
                            Due date must occur on or after Date Received.
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      xs={2}
                      style={{
                        marginRight: '10px',
                        marginTop: '11px',
                        paddingLeft: '8px',
                      }}
                    >
                      <Grid item>
                        <FullWidthField
                          name="q28TotalCharge"
                          label="Total Charge"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: hasValue('q28TotalCharge', formik.values),
                          }}
                        />
                      </Grid>
                      <Grid item style={{ paddingTop: '0px' }}>
                        {this.props.errorState && (
                          <div className="error">
                            Sum of claim line charges must match Total Charges entered.
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ paddingBottom: 12 }}></Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Grid container item style={{ padding: 8 }}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ArrowDropUp />}>
              <TitleBar title={'Boxes 1 - 13'} icon={AttachMoney} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container item style={{ padding: 8 }}>
                <Grid container direction="row" spacing={2}>
                  <Grid xs={8} item>
                    <FormLabel>Insured I.D. Type</FormLabel>
                    <RadioGroup
                      row={true}
                      value={formik.values.q1Insurance}
                      onChange={this.setFormFieldValue(
                        'q1Insurance',
                        formik.setFieldValue
                      )}
                    >
                      <FormControlLabel
                        className={classes.radioOption}
                        value={'MEDICARE'}
                        control={<Radio />}
                        label={
                          <Grid container direction="column">
                            <Grid item className={classes.radioLabel}>
                              MEDICARE
                            </Grid>
                            <Grid item className={classes.radioLabel}>
                              (MEDICARE#)
                            </Grid>
                          </Grid>
                        }
                      />
                      <FormControlLabel
                        className={classes.radioOption}
                        value={'MEDICAID'}
                        control={<Radio />}
                        label={
                          <Grid container direction="column">
                            <Grid item className={classes.radioLabel}>
                              MEDICAID
                            </Grid>
                            <Grid item className={classes.radioLabel}>
                              (MEDICAID#)
                            </Grid>
                          </Grid>
                        }
                      />
                      <FormControlLabel
                        className={classes.radioOption}
                        value={'TRICARE'}
                        control={<Radio />}
                        label={
                          <Grid container direction="column">
                            <Grid item className={classes.radioLabel}>
                              TRICARE
                            </Grid>
                            <Grid item className={classes.radioLabel}>
                              (ID#/DoD#)
                            </Grid>
                          </Grid>
                        }
                      />
                      <FormControlLabel
                        className={classes.radioOption}
                        value={'CHAMPVA'}
                        control={<Radio />}
                        label={
                          <Grid container direction="column">
                            <Grid item className={classes.radioLabel}>
                              CHAMPVA
                            </Grid>
                            <Grid item className={classes.radioLabel}>
                              (Member ID#)
                            </Grid>
                          </Grid>
                        }
                      />
                      <FormControlLabel
                        className={classes.radioOption}
                        value={'GROUP HEALTH PLAN'}
                        control={<Radio />}
                        label={
                          <Grid container direction="column">
                            <Grid item className={classes.radioLabel}>
                              GROUP HEALTH PLAN
                            </Grid>
                            <Grid item className={classes.radioLabel}>
                              (ID#)
                            </Grid>
                          </Grid>
                        }
                      />
                      <FormControlLabel
                        className={classes.radioOption}
                        value={'FECA BLK LUNG'}
                        control={<Radio />}
                        label={
                          <Grid container direction="column">
                            <Grid item className={classes.radioLabel}>
                              FECA BLK LUNG
                            </Grid>
                            <Grid item className={classes.radioLabel}>
                              (ID#)
                            </Grid>
                          </Grid>
                        }
                      />
                      <FormControlLabel
                        className={classes.radioOption}
                        value={'OTHER'}
                        control={<Radio />}
                        label={
                          <Grid container direction="column">
                            <Grid item className={classes.radioLabel}>
                              OTHER
                            </Grid>
                            <Grid item className={classes.radioLabel}>
                              (ID#)
                            </Grid>
                          </Grid>
                        }
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={4}>
                    <FullWidthField
                      name="q1AInsuranceId"
                      label="Insured's I.D. Number"
                      InputLabelProps={{
                        shrink: hasValue('q1AInsuranceId', formik.values),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={4}>
                    <FullWidthField
                      name="q2PatientName"
                      label="Patient's Name (Last Name, First Name, Middle Initial)"
                      InputLabelProps={{
                        shrink: hasValue('q2PatientName', formik.values),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} container direction="row">
                    <Grid item xs={8}>
                      <DatePickerField
                        inputId="q3Date"
                        name="q3Date"
                        label="Patient's Birth Date"
                        shrink={hasValue('q3Date', formik.values)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel>Sex</FormLabel>
                      <RadioGroup
                        row={true}
                        value={formik.values.q3Sex}
                        onChange={this.setFormFieldValue('q3Sex', formik.setFieldValue)}
                      >
                        <FormControlLabel
                          className={classes.radioOption}
                          value={'Male'}
                          control={<Radio />}
                          label={'M'}
                        />
                        <FormControlLabel
                          className={classes.radioOption}
                          value={'Female'}
                          control={<Radio />}
                          label={'F'}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <FullWidthField
                      name="q4InsuredName"
                      label="Insured's Name (Last Name, First Name, Middle Initial)"
                      InputLabelProps={{
                        shrink: hasValue('q4InsuredName', formik.values),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={4}>
                    <AddressField
                      name="q5PatientAddress"
                      label="Patient's Address (No., Street)"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel>Patient Relationship to Insured</FormLabel>
                    <RadioGroup
                      row={true}
                      value={formik.values.q6RelationshipToInsured}
                      onChange={this.setFormFieldValue(
                        'q6RelationshipToInsured',
                        formik.setFieldValue
                      )}
                    >
                      <FormControlLabel
                        className={classes.radioOption}
                        value={'SELF'}
                        control={<Radio />}
                        label={'Self'}
                      />
                      <FormControlLabel
                        className={classes.radioOption}
                        value={'SPOUSE'}
                        control={<Radio />}
                        label={'Spouse'}
                      />
                      <FormControlLabel
                        className={classes.radioOption}
                        value={'CHILD'}
                        control={<Radio />}
                        label={'Child'}
                      />
                      <FormControlLabel
                        className={classes.radioOption}
                        value={'OTHER'}
                        control={<Radio />}
                        label={'Other'}
                      />
                    </RadioGroup>
                    <FullWidthField
                      name="q8ReservedNucc"
                      label="Reserved For NUCC Use"
                      rows="3"
                      multiline={true}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: hasValue('q8ReservedNucc', formik.values),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AddressField
                      name="q7InsuredAddress"
                      label="Insured's Address (No., Street)"
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={2}>
                  <Grid
                    item
                    xs={4}
                    container
                    direction="column"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <FullWidthField
                        name="q9OtherInsuredName"
                        label="Other Insured's Name (Last Name, First Name, Middle Initial)"
                        InputLabelProps={{
                          shrink: hasValue('q9OtherInsuredName', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FullWidthField
                        name="q9AOtherInsuredPolicyNumber"
                        label="Other Insured's Policy or Group Number"
                        InputLabelProps={{
                          shrink: hasValue('q9AOtherInsuredPolicyNumber', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FullWidthField
                        name="q9BReservedNucc"
                        label="Reserved For NUCC Use"
                        InputLabelProps={{
                          shrink: hasValue('q9BReservedNucc', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FullWidthField
                        name="q9CReservedNucc"
                        label="Reserved For NUCC Use"
                        InputLabelProps={{
                          shrink: hasValue('q9CReservedNucc', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FullWidthField
                        name="q9DInsuranceProgramName"
                        label="Insurance Plan Name or Program Name"
                        InputLabelProps={{
                          shrink: hasValue('q9DInsuranceProgramName', formik.values),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    container
                    direction="column"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <FormLabel>Is Patient's Condition Related To:</FormLabel>
                    </Grid>
                    <Grid item container direction="column">
                      <Grid item>
                        <FormLabel>Employment? (Current or Previous)</FormLabel>
                      </Grid>
                      <Grid item container direction="row" justifyContent="center">
                        <Grid item xs={8}>
                          <RadioGroup
                            row={true}
                            value={formik.values.q10ARelatedToEmployment}
                            onChange={this.setFormFieldValue(
                              'q10ARelatedToEmployment',
                              formik.setFieldValue
                            )}
                          >
                            <FormControlLabel
                              className={classes.radioOption}
                              value={'true'}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              className={classes.radioOption}
                              value={'false'}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <FormLabel>Auto Accident?</FormLabel>
                      </Grid>
                      <Grid item container direction="row" justifyContent="center">
                        <Grid item xs={4}>
                          <RadioGroup
                            row={true}
                            value={formik.values.q10BRelatedToAutoAccident}
                            onChange={this.setFormFieldValue(
                              'q10BRelatedToAutoAccident',
                              formik.setFieldValue
                            )}
                          >
                            <FormControlLabel
                              className={classes.radioOption}
                              value={'true'}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              className={classes.radioOption}
                              value={'false'}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={4}>
                          <TypeAheadField
                            label="Place (State)"
                            name="q10BAutoAccidentState"
                            cypressLabel="states"
                            childCypressLabel="state"
                            disabled={!formik.values.q10BRelatedToAutoAccident}
                            fullWidth
                            setFieldValue={formik.setFieldValue}
                            selectedValue={formik.values.q10BAutoAccidentState}
                            items={States}
                            getName={getStateName}
                            getValue={getStateName}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <FormLabel>Other Accident?</FormLabel>
                      </Grid>
                      <Grid item container direction="row" justifyContent="center">
                        <Grid item xs={8}>
                          <RadioGroup
                            row={true}
                            value={formik.values.q10CRelatedToOtherAccident}
                            onChange={this.setFormFieldValue(
                              'q10CRelatedToOtherAccident',
                              formik.setFieldValue
                            )}
                          >
                            <FormControlLabel
                              className={classes.radioOption}
                              value={'true'}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              className={classes.radioOption}
                              value={'false'}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <FullWidthField
                        name="q10DClaimCodes"
                        label="Claim Codes (Designated by NUCC)"
                        InputLabelProps={{
                          shrink: hasValue('q10DClaimCodes', formik.values),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={4} container direction="column">
                    <Grid item>
                      <FullWidthField
                        name="q11PolicyFecaNumber"
                        label="Insured's Policy Group or FECA Number"
                        InputLabelProps={{
                          shrink: hasValue('q11PolicyFecaNumber', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item container direction="row">
                      <Grid item xs={8}>
                        <DatePickerField
                          inputId="q11ADateOfBirth"
                          name="q11ADateOfBirth"
                          label="Insured's Date of Birth"
                          shrink={hasValue('q11ADateOfBirth', formik.values)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormLabel>Sex</FormLabel>
                        <RadioGroup
                          row={true}
                          value={formik.values.q11ASex}
                          onChange={this.setFormFieldValue(
                            'q11ASex',
                            formik.setFieldValue
                          )}
                        >
                          <FormControlLabel
                            className={classes.radioOption}
                            value={'Male'}
                            control={<Radio />}
                            label={'M'}
                          />
                          <FormControlLabel
                            className={classes.radioOption}
                            value={'Female'}
                            control={<Radio />}
                            label={'F'}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <FullWidthField
                        name="q11BOtherClaimId"
                        label="Other Claim Id (Designated by NUCC)"
                        InputLabelProps={{
                          shrink: hasValue('q11BOtherClaimId', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FullWidthField
                        name="q11CInsuranceProgramName"
                        label="Insurance Plan Name or Program Name"
                        InputLabelProps={{
                          shrink: hasValue('q11CInsuranceProgramName', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FormLabel>Is there another health benefit plan?</FormLabel>
                    </Grid>
                    <Grid item container direction="row">
                      <Grid item xs={4}>
                        <RadioGroup
                          row={true}
                          value={formik.values.q11DAnotherBenefitPlan}
                          onChange={this.setFormFieldValue(
                            'q11DAnotherBenefitPlan',
                            formik.setFieldValue
                          )}
                        >
                          <FormControlLabel
                            className={classes.radioOption}
                            value={'true'}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            className={classes.radioOption}
                            value={'false'}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item className={classes.radioExtraText} xs={8}>
                        <strong className={classes.radioExtraTextBold}>If yes,</strong>
                        &nbsp; complete Other Insured's Name, Policy Number, and Plan
                        Name.
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  className={classes.topSpacing}
                >
                  <Grid item container spacing={1} xs={8}>
                    <Grid item xs={12}>
                      READ BACK OF FORM BEFORE COMPLETING & SIGNING THIS FORM.
                    </Grid>
                    <Grid item xs={12}>
                      PATIENT'S OR AUTHORIZED PERSON'S SIGNATURE. I authorize the release
                      of any medical or other information necessary to process this claim.
                      I also request payment of government benefits either to myself or to
                      the party who accepts assignment below.
                    </Grid>
                    <Grid item xs={8}>
                      <FullWidthField
                        name="q12PatientSignature"
                        label="Signed"
                        InputLabelProps={{
                          shrink: hasValue('q12PatientSignature', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <DatePickerField
                        inputId="q12PatientSignatureDate"
                        name="q12PatientSignatureDate"
                        label="Date"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1} xs={4}>
                    <Grid item xs={12}>
                      INSURED'S OR AUTHORIZED PERSON'S SIGNATURE.
                    </Grid>
                    <Grid item xs={12}>
                      I authorize payment of medical benefits to the undersigned physician
                      or supplier for services described below.
                    </Grid>
                    <Grid item xs={12}>
                      <FullWidthField
                        name="q13InsuredSignature"
                        label="Signed"
                        InputLabelProps={{
                          shrink: hasValue('q13InsuredSignature', formik.values),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>

        <Grid container item style={{ padding: 8 }}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ArrowDropUp />}>
              <TitleBar title={'Boxes 14 - 23'} icon={AttachMoney} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container item style={{ padding: 8 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid
                    item
                    container
                    xs={4}
                    className={classes.smallDateField}
                    spacing={1}
                  >
                    <Grid item xs={9}>
                      <DatePickerField
                        fullWidth
                        inputId="q14DateOfIllness"
                        name="q14DateOfIllness"
                        label="Date of Current Illness, Injury, or Pregnancy (LMP)"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FullWidthField
                        name="q14Qualifier"
                        label="Qualifier"
                        InputLabelProps={{
                          shrink: hasValue('q14Qualifier', formik.values),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={4}
                    className={classes.smallDateField}
                    spacing={1}
                  >
                    <Grid item xs={3}>
                      <FullWidthField
                        name="q15Qualifier"
                        label="Qualifier"
                        InputLabelProps={{
                          shrink: hasValue('q15Qualifier', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <DatePickerField
                        fullWidth
                        inputId="q15OtherDate"
                        name="q15OtherDate"
                        label="Other Date"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={4} justifyContent="flex-start">
                    <Grid item xs={12} className={classes.bottomItemParent}>
                      <FormLabel className={classes.bottomItem}>
                        Dates patient unable to work in current occupation
                      </FormLabel>
                    </Grid>
                    <Grid item container xs={12} className={classes.bottomItemParent}>
                      <Grid
                        item
                        xs={6}
                        className={classes.bottomItem}
                        style={{ left: 0 }}
                      >
                        <DatePickerField
                          inputId="q16UnableToWorkFrom"
                          name="q16UnableToWorkFrom"
                          label="From"
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={classes.bottomItem}
                        style={{ right: 0 }}
                      >
                        <DatePickerField
                          inputId="q16UnabletoWorkTo"
                          name="q16UnabletoWorkTo"
                          label="To"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item container xs={4} justifyContent="flex-start" spacing={1}>
                    <Grid item xs={3}>
                      <FullWidthField
                        name="q17Qualifier"
                        label="Qualifier"
                        InputLabelProps={{
                          shrink: hasValue('q17Qualifier', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <FullWidthField
                        name="q17NameOfReferring"
                        label="Name of Referring Provider or Other Source"
                        InputLabelProps={{
                          shrink: hasValue('q17NameOfReferring', formik.values),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container xs={4} justifyContent="flex-start" spacing={1}>
                    <Grid item xs={3}>
                      <FullWidthField
                        name="q17AQualifier"
                        label="Qualifier"
                        InputLabelProps={{
                          shrink: hasValue('q17AQualifier', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <FullWidthField
                        name="q17AId"
                        label="ID"
                        InputLabelProps={{
                          shrink: hasValue('q17AId', formik.values),
                        }}
                      />
                      <FullWidthField
                        name="q17BNpi"
                        label="NPI"
                        InputLabelProps={{
                          shrink: hasValue('q17BNpi', formik.values),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container xs={4} justifyContent="flex-start">
                    <Grid item xs={12} className={classes.bottomItemParent}>
                      <FormLabel className={classes.bottomItem}>
                        Hospitalization Dates Related to Current Services
                      </FormLabel>
                    </Grid>
                    <Grid item container xs={12} className={classes.bottomItemParent}>
                      <Grid
                        item
                        xs={6}
                        className={classes.bottomItem}
                        style={{ left: 0 }}
                      >
                        <DatePickerField
                          inputId="q18HospitalizationFrom"
                          name="q18HospitalizationFrom"
                          label="From"
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={classes.bottomItem}
                        style={{ right: 0 }}
                      >
                        <DatePickerField
                          inputId="q18HospitalizationTo"
                          name="q18HospitalizationTo"
                          label="To"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item xs={8}>
                    <FullWidthField
                      name="q19AdditionalClaims"
                      label="Additional Claim Information (Designated by NUCC)"
                      InputLabelProps={{
                        shrink: hasValue('q19AdditionalClaims', formik.values),
                      }}
                    />
                  </Grid>
                  <Grid item container xs={4}>
                    <Grid item xs={6}>
                      <FormLabel>Outside Lab?</FormLabel>
                      <RadioGroup
                        row={true}
                        value={formik.values.q20OutsideLab}
                        onChange={this.setFormFieldValue(
                          'q20OutsideLab',
                          formik.setFieldValue
                        )}
                      >
                        <FormControlLabel
                          className={classes.radioOption}
                          value={'true'}
                          control={<Radio />}
                          label={'Yes'}
                        />
                        <FormControlLabel
                          className={classes.radioOption}
                          value={'false'}
                          control={<Radio />}
                          label={'No'}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <FullWidthField
                        name="q20Charges"
                        label="Charges"
                        type="number"
                        InputLabelProps={{
                          shrink: hasValue('q20Charges', formik.values),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item container xs={8} justifyContent="space-between">
                    <Grid item xs={9} style={{ position: 'relative' }}>
                      <p
                        style={{
                          bottom: 0,
                          margin: 0,
                          position: 'absolute',
                        }}
                      >
                        Diagnosis or Nature of Illness or Injury (Relate A-L to service
                        line below) *
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <FullWidthField
                        name="q21DiagnosisIcdInd"
                        label="ICD Ind."
                        InputLabelProps={{
                          shrink: hasValue('q21DiagnosisIcdInd', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item container direction="row" xs={12}>
                      <FieldArray
                        name="q21Diagnosis"
                        render={this.renderDiagnosisFields(formik.values)}
                      />
                      <ErrorMessage name="q21Diagnosis">
                        {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid item container xs={4} justifyContent="space-between">
                    <Grid item xs={6}>
                      <FullWidthField
                        name="q22ResubmissionCode"
                        label="Resubmission Code"
                        InputLabelProps={{
                          shrink: hasValue('q22ResubmissionCode', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FullWidthField
                        name="q22OriginalRefNo"
                        label="Original Ref. No."
                        InputLabelProps={{
                          shrink: hasValue('q22OriginalRefNo', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FullWidthField
                        name="q23PrioAuthorizationNumber"
                        label="Prior Authorization Number"
                        InputLabelProps={{
                          shrink: hasValue('q23PrioAuthorizationNumber', formik.values),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>

        <Grid container item style={{ padding: 8 }}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ArrowDropUp />}>
              <TitleBar title={'Box 24'} icon={AttachMoney} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {this.renderBox24(formik.values)}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        <Grid container item style={{ padding: 8 }}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ArrowDropUp />}>
              <TitleBar title={'Boxes 25 - 33'} icon={AttachMoney} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container item style={{ padding: 8 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item container xs={4} spacing={2}>
                    <Grid item xs={7} className={classes.bottomItemParent}>
                      <FullWidthField
                        name="q25FederalTaxId"
                        label="Federal Tax I.D. Number"
                        InputLabelProps={{
                          shrink: hasValue('q25FederalTaxId', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={5} className={classes.bottomItemParent}>
                      <RadioGroup
                        row={true}
                        value={formik.values.q25FederalTaxIdSource}
                        onChange={this.setFormFieldValue(
                          'q25FederalTaxIdSource',
                          formik.setFieldValue
                        )}
                      >
                        <FormControlLabel
                          className={classes.radioOption}
                          value={'SSN'}
                          control={<Radio />}
                          label={'SSN'}
                        />
                        <FormControlLabel
                          className={classes.radioOption}
                          value={'EIN'}
                          control={<Radio />}
                          label={'EIN'}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid item container xs={4} spacing={2}>
                    <Grid item xs={6} className={classes.bottomItemParent}>
                      <FullWidthField
                        name="q26PatientAccountNo"
                        label="Patient's Account No."
                        InputLabelProps={{
                          shrink: hasValue('q26PatientAccountNo', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormLabel>
                        Accept Assignment? (For govt. claims, see back)
                      </FormLabel>
                      <RadioGroup
                        row={true}
                        value={formik.values.q27AcceptAssignment}
                        onChange={this.setFormFieldValue(
                          'q27AcceptAssignment',
                          formik.setFieldValue
                        )}
                      >
                        <FormControlLabel
                          className={classes.radioOption}
                          value={'true'}
                          control={<Radio />}
                          label={'Yes'}
                        />
                        <FormControlLabel
                          className={classes.radioOption}
                          value={'false'}
                          control={<Radio />}
                          label={'No'}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid item container xs={4} justifyContent="space-between">
                    <Grid item xs={3} className={classes.bottomItemParent}>
                      <FullWidthField
                        name="q28TotalCharge"
                        label="Total Charge"
                        type="number"
                        className={classes.bottomItem}
                        disabled
                        InputLabelProps={{
                          shrink: hasValue('q28TotalCharge', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.bottomItemParent}>
                      <FullWidthField
                        name="q29AmountPaid"
                        label="Amount Paid"
                        type="number"
                        className={classes.bottomItem}
                        InputLabelProps={{
                          shrink: hasValue('q29AmountPaid', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.bottomItemParent}>
                      <FullWidthField
                        name="q30ReservedNucc"
                        label="Reserved for NUCC Use"
                        type="number"
                        className={classes.bottomItem}
                        InputLabelProps={{
                          shrink: hasValue('q30ReservedNucc', formik.values),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item container xs={4} justifyContent="space-between">
                    <FormLabel>
                      Signature of Physician or Supplier Including Degrees or Credentials
                    </FormLabel>
                    <FormLabel>
                      (I certify that the statements on the reverse apply to this bill and
                      are made a part thereof.)
                    </FormLabel>
                    <Grid item xs={7} className={classes.bottomItemParent}>
                      <FullWidthField
                        name="q31Signature"
                        label="Signature"
                        className={classes.bottomItem}
                        InputLabelProps={{
                          shrink: hasValue('q31Signature', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.bottomItemParent}>
                      <DatePickerField
                        inputId="q31Date"
                        name="q31Date"
                        label="Date"
                        fullWidth
                        className={classes.bottomItem}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={4} justifyContent="space-between">
                    <Grid item xs={12}>
                      <FullWidthField
                        name="q32LocationInfo"
                        label="Service Facility Location Information"
                        rows="3"
                        multiline={true}
                        variant="outlined"
                        disabled
                        InputLabelProps={{
                          shrink: hasValue('q32LocationInfo', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.bottomItemParent}>
                      <FullWidthField
                        name="q32ALocationNpi"
                        label="Service Facility NPI"
                        className={classes.bottomItem}
                        disabled
                        InputLabelProps={{
                          shrink: hasValue('q32ALocationNpi', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={5} className={classes.bottomItemParent}>
                      <FullWidthField
                        name="q32B"
                        label=""
                        className={classes.bottomItem}
                        InputLabelProps={{
                          shrink: hasValue('q32B', formik.values),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={4} justifyContent="space-between">
                    <Grid item xs={5} className={classes.bottomItemParent}>
                      <FormLabel className={classes.bottomItem}>
                        Billing Provider
                      </FormLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <FullWidthField
                        name="q33BillingProviderPhoneNumber"
                        label="Phone Number"
                        InputLabelProps={{
                          shrink: hasValue(
                            'q33BillingProviderPhoneNumber',
                            formik.values
                          ),
                        }}
                        InputProps={{ inputComponent: PhoneNumberMask }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FullWidthField
                        name="q33BillingProviderInfo"
                        label="Information"
                        rows="2"
                        disabled
                        multiline={true}
                        variant="outlined"
                        style={{ marginTop: 5 }}
                        InputLabelProps={{
                          shrink: hasValue('q33BillingProviderInfo', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FullWidthField
                        name="q33ABillingProviderNpi"
                        label="Billing Provider NPI"
                        disabled
                        InputLabelProps={{
                          shrink: hasValue('q33ABillingProviderNpi', formik.values),
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <FullWidthField
                        name="q33B"
                        label=" " // this is to fix the spacing
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    )
  }

  private renderDiagnosisFields = (values: IClaimFormValues) => () => (
    <>
      {values.q21Diagnosis.map((diagnosis: Diagnosis, index: number) => (
        <Grid item xs={3} key={index}>
          <FullWidthField name={`q21Diagnosis.${index}.code`} label={diagnosis.pointer} />
        </Grid>
      ))}
    </>
  )

  private openContractModal(index: number) {
    this.props.openContractModal!(index)
  }

  private renderBox24 = (values: IClaimFormValues) => {
    return (
      <>
        <Grid container direction="column" spacing={1}>
          {values.q24.map((_lineItem: ILineItemFormValues, index: number) => (
            <Box24
              showContractAmount={this.props.showContractModalButton}
              openContractModal={() => this.openContractModal(index)}
              index={index}
              providerTypeId={values.providerTypeId}
              providerId={values.providerId}
            />
          ))}
        </Grid>
      </>
    )
  }

  private setFormFieldValue =
    (
      field: string,
      setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(field, event.target.value, true)
    }

  private getProviderTypes = (values: IClaimFormValues) => {
    return values.providerLocation ? values.providerLocation.providerTypes || [] : []
  }

  private getProviderTypeName = (type: IProviderTypeDto) => {
    return type.name || ''
  }

  private getProviderTypeValue = (type: IProviderTypeDto) => {
    return type.id || ''
  }

  private getLocationProvider = (values: IClaimFormValues) => {
    if (this.props.selectedLocation) {
      values.providerLocationId = this.props.selectedLocation.id
      values.providerLocation = this.props.selectedLocation
      values.q32ALocationNpi = this.props.selectedLocation.npi ?? ''
      values.q33ABillingProviderNpi =
        this.props.selectedLocation.npi! !== ''
          ? this.props.selectedLocation.npi!
          : this.props.selectedLocation.provider?.npiNumber! !== ''
            ? this.props.selectedLocation.provider?.npiNumber!
            : ''

      values.q32LocationInfo =
        (this.props.selectedLocation?.name
          ? this.props.selectedLocation?.name + '\n'
          : '') +
        (this.props.selectedLocation?.physicalAddress?.line1
          ? this.props.selectedLocation.physicalAddress.line1 + '\n'
          : '') +
        (this.props.selectedLocation?.physicalAddress?.line2
          ? this.props.selectedLocation.physicalAddress.line2 + '\n'
          : '') +
        (this.props.selectedLocation?.physicalAddress?.city
          ? this.props.selectedLocation.physicalAddress.city
          : '') +
        (this.props.selectedLocation?.physicalAddress?.state
          ? ', ' + this.props.selectedLocation?.physicalAddress?.state
          : '') +
        (this.props.selectedLocation?.physicalAddress?.zip
          ? ' ' + this.props.selectedLocation?.physicalAddress?.zip
          : '')

      if (
        this.props.selectedLocation &&
        this.props.selectedLocation!.billingAddresses !== undefined
      ) {
        var billingAddress = new AddressDto()
        for (var i = 0; i < this.props.selectedLocation!.billingAddresses.length; i++) {
          var address = this.props.selectedLocation!.billingAddresses[i]
          if (address.isActive && address.address != undefined) {
            billingAddress = address.address
            break
          }
        }

        values.q33BillingProviderInfo =
          (billingAddress?.name ? billingAddress?.name + '\n' : '') +
          (billingAddress?.line1 ? billingAddress.line1 + '\n' : '') +
          (billingAddress?.line2 ? billingAddress.line2 + '\n' : '') +
          (billingAddress?.city ? billingAddress.city : '') +
          (billingAddress?.state ? ', ' + billingAddress?.state : '') +
          (billingAddress?.zip ? ' ' + billingAddress?.zip : '')
      }

      values.provider = this.props.selectedLocation!.provider!
      values.providerId = this.props.selectedLocation.providerId!

      if (this.props.selectedLocation!.provider) {
        values.provider = this.props.selectedLocation!.provider!
        values.providerId = this.props.selectedLocation.providerId!
        return this.props.selectedLocation?.provider?.name ?? '{UNKNOWN PROVIDER GROUP}'
      }
      return ''
    }
    return values.providerLocation?.provider?.name ?? '{UNKNOWN PROVIDER GROUP}'
  }

  private isProviderTypeDisabled = (values: IClaimFormValues) => {
    if (!!values.provider) {
      return false
    }
    return true
  }

  private handleDueDateChange = (event: any) => {
    this.props.handleDueDateChange!(event.target.value)
  }
}

const InjectedFullForm = inject<IStores, IFullFormProps, Partial<IFullFormProps>, any>(
  (stores: IStores) => ({
    loadClaim: stores.cms1500.loadCMS1500,
    selectedCMS1500: stores.cms1500.selectedCMS1500,
    selectedSchedule: stores.schedules.selectedSchedule,
    setSelectedCMS1500: stores.cms1500.setSelectedCMS1500,
    openContractModal: stores.cms1500.openContractModal,
    openReferralContactModal: stores.patientEdit.openReferralContactModal,
    isReferralContactModalOpen: stores.patientEdit.isReferralContactModalOpen,
  })
)(FullForm)

export default withTheme(withStyles(styles)(connect<IFullFormProps>(InjectedFullForm)))
