import GoogleMapReact, { Coords } from 'google-map-react'
import { inject } from 'mobx-react'
import * as React from 'react'
import { IProviderLocationSummaryResult } from 'src/viewModels/ProviderLocationSummaryResult'
import { ILocation } from '../Definitions'
import { AddressDto } from '../generated_client'
import { IStores } from '../Stores'

interface IProviderLookupMapContainerProps {
  selectedAddress?: AddressDto
  locations?: IProviderLocationSummaryResult[]
}

const Marker = ({ text, isPatientLocation }: any) => (
  <div
    style={{
      backgroundColor: isPatientLocation ? '#00aaff' : '#ff791a',
      border: '2px solid #000',
      borderRadius: '100%',
      height: '21px',
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '21px',
      zIndex: 1,
    }}
  >
    <span
      style={{
        paddingLeft: '23px',
        marginBottom: '12px',
        fontSize: '12px',
        fontStyle: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        position: 'relative',
        top: isPatientLocation ? '-14px' : '0px',
      }}
    >
      {text}
    </span>
  </div>
)

class ProviderLookupMapContainer extends React.Component<IProviderLookupMapContainerProps> {
  private center = () => {
    if (this.props.selectedAddress) {
      return {
        lat: this.props.selectedAddress.latitude,
        lng: this.props.selectedAddress.longitude,
      } as Coords
    } else {
      return { lat: 39.7684, lng: -86.1581 } as Coords
    }
  }

  private renderMarker(x: ILocation, isPatientLocation: boolean) {
    if (x && x.physicalAddress) {
      return (
        <Marker
          lat={x.physicalAddress.latitude}
          lng={x.physicalAddress.longitude}
          text={x.name}
          key={x.id}
          isPatientLocation={isPatientLocation}
        />
      )
    } else {
      return <></>
    }
  }

  private renderProviderMarker(x: IProviderLocationSummaryResult) {
    if (x && x.latitude && x.longitude) {
      return (
        <Marker
          lat={x.latitude}
          lng={x.longitude}
          text={x.providerName}
          key={x.providerId}
          isPatientLocation={false}
        />
      )
    } else {
      return <></>
    }
  }

  public render() {
    const { selectedAddress, locations } = this.props

    return (
      <div style={{ marginTop: '10px', height: '400px', width: '600px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
          }}
          defaultZoom={12}
          defaultCenter={{
            lat: 39.7684,
            lng: -86.1581,
          }}
          center={this.center()}
          shouldUnregisterMapOnUnmount={true}
        >
          {locations ? locations.map((x) => this.renderProviderMarker(x)) : <></>}
          {selectedAddress ? (
            this.renderMarker(
              {
                physicalAddress: selectedAddress,
                name: 'Patient Loc.',
              } as ILocation,
              true
            )
          ) : (
            <></>
          )}
        </GoogleMapReact>
      </div>
    )
  }
}

const InjectedProviderLookupMapContainer = inject<
  IStores,
  IProviderLookupMapContainerProps,
  Partial<IProviderLookupMapContainerProps>,
  any
>((stores: IStores) => ({
  selectedAddress: stores.locations.selectedAddress,
}))(ProviderLookupMapContainer)

export default InjectedProviderLookupMapContainer
