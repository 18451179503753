import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core'
import React from 'react'
import ReactPaginate from 'react-paginate'
import { pagedList } from 'src/viewModels/pagedList'
import { getSchedulesByEpisodeOfCareId } from 'src/services/EpisodeOfCareService'
import { SearchRequestBase } from 'src/viewModels/SearchRequestBase'
import { ScheduleSummmaryResult } from 'src/viewModels/ScheduleSummaryResult'
import { CommentOutline } from 'mdi-material-ui'
import { inject } from 'mobx-react'
import { IStores } from 'src/Stores'
import ESMenu from 'src/common/ESMenu'
import { AddCircle, Schedule } from '@material-ui/icons'
import { DefaultSchedule, IPatient, IReferral, ISchedule } from 'src/Definitions'

const styles = () =>
  createStyles({
    disabledRow: {
      '& td:first-of-type': {
        borderLeft: '5px solid #930A0A',
        paddingLeft: '7px',
      },
    },
    tableHeader: {
      fontSize: '20px',
      paddingTop: '4px',
    },
  })

interface IScheduleGridProps extends WithStyles<typeof styles> {
  addSchedule?: (schedule: ISchedule) => void
  bodyPart?: string
  buildScheduleMenuItems?: (eoc: ScheduleSummmaryResult) => any[]
  episodeOfCareId: string
  patient?: IPatient
  referral?: IReferral
  rerouteToPath?: (path: string) => void
  setUpdateParentStateAfterModal?: (parentfunction?: () => void) => void
}
class ScheduleingTable extends React.Component<IScheduleGridProps> {
  public componentDidMount(): void {
    this.getScheduleData()
    this.props.setUpdateParentStateAfterModal!(this.getScheduleData)
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private getScheduleData = async () => {
    const { page, pageSize, episodeOfCareId } = this.state
    this.setState({ isLoading: true })
    const data: SearchRequestBase = {
      page,
      pageSize,
    }

    await getSchedulesByEpisodeOfCareId(episodeOfCareId, data).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        isLoading: false,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  public getRowStyle = (rescheduled: boolean) => {
    if (rescheduled) {
      return this.props.classes.disabledRow
    } else {
      return undefined
    }
  }

  public openSchedule = (schedule: ISchedule) => () => {
    this.props.addSchedule!(schedule)
    this.props.rerouteToPath!(
      `/patients/patient/${this.props.patient!.id}/referral/${
        this.props.referral!.id
      }/episodeOfCare/${this.props.episodeOfCareId}/schedule/`
    )
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getScheduleData())
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems === 0
  }

  private mapLogGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className={this.getRowStyle(record.rescheduled)} key={index}>
          <TableCell align="right" style={{ paddingRight: 0, paddingLeft: 0 }}>
            {record.comments && record.comments?.trim().length > 0 ? (
              <Tooltip
                title={
                  <React.Fragment>
                    <span
                      style={{
                        fontSize: '18px',
                        lineHeight: '1.5em',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {record.comments}
                    </span>
                  </React.Fragment>
                }
              >
                <span>
                  <CommentOutline />
                </span>
              </Tooltip>
            ) : null}
          </TableCell>
          <TableCell align="right">{record.appointmentDateDisplay}</TableCell>
          <TableCell align="right">{record.appointmentTime}</TableCell>
          <TableCell>{record.componentName}</TableCell>
          <TableCell>{record.primaryLocationDisplay}</TableCell>
          <TableCell>{record.secondaryLocations}</TableCell>
          <TableCell align="right">{record.claimCount}</TableCell>
          <TableCell>{this.renderContextMenu(record, index)}</TableCell>
        </TableRow>
      ))
    }
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  public state = {
    gridData: new pagedList().items as ScheduleSummmaryResult[],
    page: 1,
    pageCount: 1,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 10,
    episodeOfCareId: this.props.episodeOfCareId,
    isLoading: false,
  }

  private renderContextMenu = (schedule: ScheduleSummmaryResult, index: number) => {
    let options = this.props.buildScheduleMenuItems!(schedule)

    return (
      <ESMenu
        data={schedule}
        menuItems={options}
        rowIndex={index}
        menuAriaLabel={`$episode-of-care-${index}-menu`}
      />
    )
  }

  public render() {
    let schedulingHeader = `Scheduling [${this.props.bodyPart}]`
    return (
      <>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="flex-start"
          wrap="wrap"
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            wrap="nowrap"
            spacing={1}
          >
            <Grid container item alignItems="center" spacing={2}>
              <Grid item>
                <Schedule color="secondary" style={{ fontSize: '32px' }} />
              </Grid>
              <Grid item className={this.props.classes.tableHeader}>
                {schedulingHeader}
              </Grid>
            </Grid>
            <Grid item style={{ color: '#AAAAAA' }}>
              <Tooltip title="Add Schedule">
                <AddCircle
                  color="primary"
                  style={{
                    fontSize: '30px',
                    marginRight: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={this.openSchedule(DefaultSchedule(true))}
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ minWidth: '750px' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="gridPadding">
                  <TableCell style={{ paddingRight: 0, paddingLeft: 0 }}></TableCell>
                  <TableCell align="right">Appointment Date</TableCell>
                  <TableCell align="right">Appointment Time</TableCell>
                  <TableCell>Component</TableCell>
                  <TableCell>Provider Location</TableCell>
                  <TableCell>Additional Providers</TableCell>
                  <TableCell align="right"># of Claims</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {this.state.isLoading && (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CircularProgress size={50} />
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody style={{ width: '100%' }}>{this.mapLogGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                forcePage={this.state.page - 1}
                pageCount={this.state.pageCount}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}

const InjectedSchedulingTable = inject<
  IStores,
  IScheduleGridProps,
  Partial<IScheduleGridProps>,
  any
>((stores: IStores) => ({
  addSchedule: stores.schedules.addSchedule,
  buildScheduleMenuItems: stores.schedules.buildScheduleMenuItems,
  patient: stores.patients.selectedPatient,
  referral: stores.referrals.selectedReferral,
  rerouteToPath: stores.global.rerouteToPath,
  setUpdateParentStateAfterModal: stores.schedules.setParentUpdateFunction,
}))(withStyles(styles)(ScheduleingTable))

export default InjectedSchedulingTable
