import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { Check, Description, Email, Schedule, Send } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Form, Formik, FormikActions, getIn } from 'formik'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import Moment from 'moment'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { RouteComponentProps } from 'react-router'
import { Prompt } from 'react-router-dom'
import { ComponentNameConstants } from 'src/constants/ComponentNameConstants'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import * as Yup from 'yup'
import PatientActionBar from '../common/ActionBar/PatientActionBar'
import CardWithNoTitle from '../common/CardWithNoTitle/CardWithNoTitle'
import CardWithTitle from '../common/CardWithTitle/CardWithTitle'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import FullWidthField from '../common/FullWidthField'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import SelectField from '../common/SelectField/SelectField'
import { noWhitespaceString } from '../common/YupExtensions'
import {
  DefaultSchedule,
  IAppointmentTask,
  IEpisodeOfCare,
  ILocation,
  IOrganization,
  IPatient,
  IProvider,
  IReferral,
  ISchedule,
  IScheduleAppointmentTask,
} from '../Definitions'
import { IProviderDto, TestDto } from '../generated_client'
import {
  createIScheduleFormValues,
  IScheduleFormValues,
  toISchedule,
} from '../patients/Scheduling/ScheduleFormValues'
import ScheduleAppointmentTaskDialog from '../scheduleAppointmentTasks/ScheduleAppointmentTaskDialog'
import { IStores } from '../Stores'
import { OrganizationId } from '../utils/OrganizationIds'
import AuthorizationModal from './AuthorizationModal'
import CriticalCareDialog from './CriticalCareDialog'
import ProviderEditDialog from './ProviderEditDialog'
import ProviderLookupDialog from './ProviderLookupDialog'
import SingleCaseAgreementDialog from './SingleCaseAgreementDialog/SingleCaseAgreementDialog'
import { QuoteStatus } from 'src/utils/QuoteStatus'

const DIAGNOSTIC_TEST_COMPONENT_ID = '194a2cc4-424a-43d6-b4ff-292d3b72cc9b'

const styles = () =>
  createStyles({
    card: {
      paddingBottom: '15px',
      paddingLeft: '30px',
      paddingRight: '30px',
      paddingTop: '15px',
    },
  })

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#EEEEEE',
      },
    },
  })
)(TableRow)

function parseDateString(_value: Date, originalValue: string) {
  return new Date(moment(originalValue, 'YYYY-MM-DD').toISOString())
}

const DiagnosticScheduleSchema = Yup.object().shape({
  appointmentDateInput: Yup.date().when('isTBD', {
    is: false,
    then: Yup.date()
      .transform(parseDateString)
      .min(new Date('1-1-1900'), 'Please enter a complete date')
      .required('Appointment Date is required'),
  }),
  authorizationComment: Yup.string(),
  comments: Yup.string(),
  componentId: noWhitespaceString('Component is required', true),
  isActive: Yup.boolean(),
  isLocationRequired: Yup.boolean(),
  isTBD: Yup.boolean(),

  testIds: Yup.array().required('At least one Test is required'),
})

const SurgicalScheduleSchema = Yup.object().shape({
  appointmentDateInput: Yup.date().when('isTBD', {
    is: false,
    then: Yup.date()
      .transform(parseDateString)
      .min(new Date('1-1-1900'), 'Please enter a complete date')
      .required('Appointment Date is required'),
  }),
  authorizationComment: Yup.string(),
  comments: Yup.string(),
  componentId: noWhitespaceString('Component is required', true),
  isActive: Yup.boolean(),
  isLocationRequired: Yup.boolean(),
  isTBD: Yup.boolean(),
})

interface IEditScheduleRouteParams {
  patientId: string
  episodeOfCareId: string
  referralId: string
  scheduleId: string
}

interface IEditScheduleProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IEditScheduleRouteParams> {
  appointmentTasksDataTable: IDataTableManager<IAppointmentTask>
  clearAppointmentTaskFilters?: () => void
  currentAppOrganization: IOrganization
  createAppointmentConfirmation: (scheduleId: string) => void
  createAuthorizationPdf: (scheduleId: string) => void
  createResultsPdf: (scheduleId: string) => void
  scheduleAppointmentTasksDataTable: IDataTableManager<IScheduleAppointmentTask>
  getAllLocationsForProvider?: () => Promise<void>
  getAllComponents?: (includeInactive: boolean) => Promise<void>
  getPatientById?: (id: string) => Promise<void>
  getProviders?: (filter: string) => Promise<void>
  getReferralById?: (id: string) => Promise<void>
  getScheduleById?: (id: string) => Promise<void>
  getEpisodeOfCareById?: (id: string) => Promise<void>
  isCurrentContractSingleAgreement?: boolean
  isModalOpen?: boolean
  isProviderEditDialogOpen?: boolean
  isProviderLookupDialogOpen?: boolean
  isSingleCaseAgreementDialogOpen?: boolean
  isCriticalCareModalOpen?: boolean
  isTaskRed?: (data: IScheduleAppointmentTask) => boolean
  clearLocations?: () => void
  selectedComponent?: DropdownOption
  dropdownComponents?: DropdownOption[]
  loadAppointmentTasks?: () => Promise<void>
  loadScheduleAppointmentTasks?: () => Promise<void>
  loadProvider?: (id: string) => Promise<void[]>
  locations?: ILocation[]
  openProviderLookupDialog?: () => void
  openSingleCaseAgreementDialog?: () => void
  openCriticalCareModal?: () => void
  providers?: IProviderDto[]
  saveSchedule?: (
    schedule: ISchedule,
    eocId: string,
    scheduleId: string,
    resetUiState: boolean
  ) => Promise<string | void>
  selectedLocation?: ILocation
  selectedProvider?: IProvider
  getNcmAdjusterEmailLink?: (schedule: ISchedule) => string
  getPatientEmailLink?: (schedule: ISchedule) => string
  getTestResultsEmailLink?: (schedule: ISchedule) => string
  setIsCurrentContractSingleAgreement?: (isSingleAgreement?: boolean) => void
  setSelectedDropdownComponent?: (component?: DropdownOption) => void
  setSelectedLocation?: (
    location?: ILocation,
    reload?: () => void
  ) => void | ((location?: ILocation) => void)
  setSelectedProvider?: (provider?: IProvider) => Promise<void>
  setSelectedPatient?: (patient?: IPatient) => void
  setSelectedSchedule?: (schedule?: ISchedule) => void
  schedule?: ISchedule
  referral?: IReferral
  patient?: IPatient
  episodeOfCare?: IEpisodeOfCare
  rerouteToPath?: (path: string) => void
  getProviderContractType?: (provider?: IProvider) => Promise<void>
  isCriticalCare?: boolean
  physicianItems?: DropdownOption[]
  openDMEAuthorizationModal?: () => void
  openPTAuthorizationModal?: () => void
  openOTAuthorizationModal?: () => void
  openInHomeAuthorizationModal?: () => void
  openInpatientAuthorizationModal?: () => void
  openWCEAuthorizationModal?: () => void
  openFCEAuthorizationModal?: () => void
  createSurgeryFacilityAuthorization?: () => Promise<string>
  createPatientInjectionContract?: () => Promise<string>
  createPatientSurgeryContract?: () => Promise<string>
  createInitialEvalAuthorization?: () => Promise<string>
  createTakeoverOfCareAuthorization?: () => Promise<string>
  close?: (fallBack?: string) => void
}
@observer
class EditSchedule extends React.Component<IEditScheduleProps> {
  public state = {
    expandedTasksActions: '',
    isLoading: false,
    reload: false,
    selectedPhysicianId: undefined as string | undefined,
    providerError: false,
    authLoading: false,
  }

  public forceUpdateHandler() {
    const formikValues = this.scheduleRef.current?.state?.values
    formikValues.location = this.props.selectedLocation?.name
    formikValues.provider = this.props.selectedProvider?.name
    this.setState({ providerError: false })
    this.forceUpdate()
  }

  public save = async (
    values: IScheduleFormValues,
    formikBag: FormikActions<IScheduleFormValues>
  ) => {
    if (this.props.selectedLocation && this.props.selectedProvider) {
      values.locationId = this.props.selectedLocation.id
      values.providerId = this.props.selectedProvider.id
      const schedule = toISchedule(values, this.props.schedule)
      schedule.physicianId = this.state.selectedPhysicianId
      schedule.patientId = this.props.patient!.id
      schedule.episodeOfCareId = this.props.episodeOfCare!.id
      if (schedule.isNew) {
        schedule.organizationId = this.props.currentAppOrganization!.id
      }
      await this.props.saveSchedule!(
        schedule,
        this.props.match.params.episodeOfCareId,
        this.props.match.params.scheduleId,
        this.state.reload
      )
        // onfulfilled
        .then((response: any) => {
          formikBag.setSubmitting(false)
          if (this.state.reload) {
            this.props.rerouteToPath!(
              `/patients/patient/${this.props.match.params.patientId}/referral/${this.props.match.params.referralId}/episodeOfCare/${this.props.match.params.episodeOfCareId}/schedule/${response}`
            )
            this.setup()
          } else {
            this.props.close!(
              `/patients/patient/${this.props.match.params.patientId}/referral/${this.props.match.params.referralId}/episodeOfCare/${this.props.match.params.episodeOfCareId}`
            )
          }
          // onrejected
        })
        .catch(() => {})
        .finally(() => {
          formikBag.setSubmitting(false)
        })
    } else {
      formikBag.setSubmitting(false)
      this.setState({ providerError: true })
    }
  }

  private cancel = () => {
    this.props.close!(
      `/patients/patient/${this.props.match.params.patientId}/referral/${this.props.match.params.referralId}/episodeOfCare/${this.props.match.params.episodeOfCareId}`
    )
  }

  public async setup() {
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this)
    const { match } = this.props
    this.setState({
      expandedTasksActions: match && match.params.scheduleId ? 'tasksActionsPanel' : '',
    })
    this.setState({ isLoading: true })
    let promises = [] as Array<Promise<void>>
    if (match && match.params.episodeOfCareId) {
      promises.push(this.props.getEpisodeOfCareById!(match.params.episodeOfCareId))
    }

    if (match && match.params.referralId) {
      promises.push(this.props.getReferralById!(match.params.referralId))
    }

    if (match && match.params.patientId) {
      promises.push(this.props.getPatientById!(match.params.patientId))
    }

    if (match && match.params.scheduleId) {
      promises.push(
        this.props.getScheduleById!(match.params.scheduleId).then(() => {
          this.props.loadScheduleAppointmentTasks!()
          this.props.getAllComponents!(true)
        })
      )
    } else {
      this.props.setSelectedSchedule!(DefaultSchedule(true))
      this.props.getAllComponents!(false)
    }

    await Promise.all(promises).then(() => {
      promises = [] as Array<Promise<void>>
      this.setState({ selectedPhysicianId: this.props.schedule?.physicianId })
      this.props.setSelectedLocation!(
        this.props.schedule?.scheduleProviderLocations
          .filter((x) => x.isPrimaryLocation)
          .at(0)?.location as unknown as ILocation
      )
      this.props.setSelectedProvider!(
        this.props.schedule?.scheduleProviderLocations
          .filter((x) => x.isPrimaryLocation)
          .at(0)?.location?.provider as unknown as IProvider
      )
      const formikValues = this.scheduleRef.current?.state?.values
      formikValues.location = this.props.selectedLocation?.name
      formikValues.provider = this.props.selectedProvider?.name
      formikValues.componentName = this.props.schedule?.componentName
      this.setState({ isLoading: false })
    })
  }

  public async componentDidMount() {
    await this.setup()
  }

  public async componentDidUpdate(prevProps: IEditScheduleProps) {
    if (this.props.match.params.scheduleId !== prevProps.match.params.scheduleId) {
      await this.setup()
    }
  }

  public componentWillUnmount() {
    this.props.setSelectedSchedule!(undefined)
    this.props.setSelectedDropdownComponent!(undefined)
    this.props.setSelectedLocation!(undefined)
    this.props.setSelectedProvider!(undefined)
    this.props.setSelectedPatient!(undefined)
    this.props.clearAppointmentTaskFilters!()
  }

  public scheduleRef = React.createRef<any>()

  private openProviderLookupDialog =
    (setFieldValue: (field: string, value: any) => void, values: IScheduleFormValues) =>
    () => {
      if (values.providerId) {
        setFieldValue('providerId', undefined)
      }
      if (values.locationId) {
        setFieldValue('locationId', undefined)
      }
      this.props.openProviderLookupDialog!()
    }

  public getProviderId(provider: IProvider) {
    return provider !== null ? provider.id || '' : ''
  }

  public async checkIfCritical(provider?: IProvider) {
    if (provider === undefined) {
      return
    }
    await this.props.getProviderContractType!(provider)

    if (this.props.currentAppOrganization.id === OrganizationId.Diagnostic) {
      if (this.props.isCriticalCare!) {
        this.props.openCriticalCareModal!()
      }
    }
    return
  }

  private loadComponentTasks =
    (values: IScheduleFormValues, initialValues: IScheduleFormValues) => () => {
      if (values.componentId !== initialValues.componentId) {
        if (
          this.props.selectedComponent &&
          this.props.selectedComponent.id === values.componentId
        ) {
          return
        }
        const component = this.props.dropdownComponents!.find(
          (x) => x.id === values.componentId
        )
        this.props.setSelectedDropdownComponent!(component)
        if (
          this.props.selectedProvider &&
          this.props.selectedProvider.contracts &&
          component
        ) {
          const currentContract = _.orderBy(
            this.props.selectedProvider.contracts
              .filter((x) => x.isActive)
              .filter((x) => Moment(new Date()).isAfter(x.startedOn)),
            (x) => x.startedOn,
            'desc'
          )[0]

          this.props.setIsCurrentContractSingleAgreement!(
            component!.id === DIAGNOSTIC_TEST_COMPONENT_ID &&
              currentContract &&
              currentContract.isSingleCaseAgreement
          )
        } else {
          this.props.setIsCurrentContractSingleAgreement!(false)
        }
        this.props.loadAppointmentTasks!()
      }
    }

  public getProviderName(provider: IProvider) {
    return provider !== null ? provider.name : ''
  }

  private getName(item: any) {
    return item ? item.name || item.description : ''
  }

  private getTestName(item: TestDto) {
    return item ? `${item.procedure!.diagnostic} (${item.bodyPart!.name})` || '' : ''
  }

  private getValue(item: any) {
    return item ? item.id || '' : ''
  }

  private showDate = (values: IScheduleFormValues) => () => {
    if (values.appointmentDateInput !== undefined) {
      this.forceUpdate()
    }
  }

  private clearAppointmentDate =
    (setFieldValue: (field: string, value: any) => void, values: IScheduleFormValues) =>
    () => {
      setFieldValue('isTBD', !values.isTBD)
      if (!values.isTBD) {
        setFieldValue('appointmentDateInput', '')
        setFieldValue('appointmentTime', '')
      }
    }

  private rowCellStyleFunction(data: IScheduleAppointmentTask) {
    if (this.props.isTaskRed!(data)) {
      return { color: '#CC3F3F' } as React.CSSProperties
    } else {
      return {} as React.CSSProperties
    }
  }

  private getSingleCaseAgreement() {
    if (
      this.props.selectedProvider &&
      this.props.selectedProvider.contracts &&
      this.props.selectedProvider.contracts.length > 0
    ) {
      const currentDate = new Date()
      const currentContracts = _.orderBy(
        this.props.selectedProvider.contracts
          .filter((x) => x.isActive)
          .filter((x) => Moment(currentDate).isAfter(x.startedOn)),
        (x) => x.startedOn,
        'desc'
      )
      if (this.props.selectedProvider.requiresSingleCaseAgreement) {
        const currentContract = currentContracts.find(
          (x) =>
            x.isSingleCaseAgreement &&
            x.preCertNumber &&
            x.preCertNumber === this.props.patient!.patientId
        )
        if (currentContract !== undefined) {
          const preCertNumber = currentContract.preCertNumber
            ? currentContract.preCertNumber
            : 'not defined'
          return `Provider requires a Single Case Agreement. Active SCA found for Patient ID #${preCertNumber} on ${Moment(
            currentContract.startedOn
          ).format('MM/DD/YYYY')}.`
        } else {
          return `Provider requires a Single Case Agreement. No Active SCA contract found for Patient ID #${
            this.props.patient!.patientId
          }`
        }
      }
    }
    return undefined
  }

  private handleTasksActionsChange = (panel: string) => (__: React.MouseEvent) => {
    this.setState({
      expandedTasksActions: panel === this.state.expandedTasksActions ? '' : panel,
    })
  }

  private sendAppointmentConfirmation = () => {
    if (!this.isReadonly()) {
      this.props.createAppointmentConfirmation!(this.props.schedule!.id)
    }
  }

  private createResultsPdf = () => {
    if (!this.isReadonly()) {
      this.props.createResultsPdf!(this.props.schedule!.id)
    }
  }

  private getNcmAdjusterEmailLink() {
    if (!this.isReadonly()) {
      return this.props.getNcmAdjusterEmailLink!(this.props.schedule!)
    }
    return undefined
  }

  private openSingleCaseAgreementDialog = () => {
    if (!this.isReadonly()) {
      this.props.openSingleCaseAgreementDialog!()
    }
  }

  private getPatientEmailLink() {
    if (!this.isReadonly()) {
      return this.props.getPatientEmailLink!(this.props.schedule!)
    }
    return undefined
  }

  private getTestResultsEmailLink() {
    if (!this.isReadonly()) {
      return this.props.getTestResultsEmailLink!(this.props.schedule!)
    }
    return undefined
  }

  private getRemainingTasks() {
    if (this.props.schedule) {
      if (!this.props.schedule.isNew) {
        if (this.props.scheduleAppointmentTasksDataTable) {
          const numberOfRemainingTasks =
            this.props.scheduleAppointmentTasksDataTable.data.filter(
              (x) => x.dateCompleted === undefined && x.taskNotApplicable === false
            ).length
          if (numberOfRemainingTasks > 0) {
            return `${numberOfRemainingTasks} tasks remaining`
          }
        }
      }
    }
    return 'Complete'
  }

  private isReadonly() {
    const { schedule, currentAppOrganization } = this.props
    return schedule && currentAppOrganization
      ? !schedule.isNew &&
          (schedule.organizationId !== currentAppOrganization!.id ||
            schedule.isSimpleEvent ||
            (schedule.claimEvents?.length ?? 0) > 0)
      : true
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload }, () => {
      submitForm()
    })
  }

  public render() {
    const {
      dropdownComponents,
      schedule,
      referral,
      patient,
      episodeOfCare,
      currentAppOrganization,
      physicianItems,
    } = this.props

    const targetPhysicianItems =
      this.props.schedule && this.props.schedule.isNew
        ? physicianItems?.filter((x) => x.isEnabled) ?? []
        : physicianItems ?? []

    const hasValue = (field: string, values: IScheduleFormValues) => {
      const value = getIn(values, field)

      return !!value
    }

    this.rowCellStyleFunction = this.rowCellStyleFunction.bind(this)

    const appointmentTaskDialog = this.props.isModalOpen && (
      <ScheduleAppointmentTaskDialog />
    )
    const providerLookupDialog = this.props.isProviderLookupDialogOpen && (
      <ProviderLookupDialog
        open={this.props.isProviderLookupDialogOpen}
        selectedLocation={this.props.selectedLocation}
        setSelectedLocation={this.props.setSelectedLocation}
        selectedProvider={this.props.selectedProvider}
        setSelectedProvider={this.props.setSelectedProvider}
        reloadParent={this.forceUpdateHandler}
      />
    )
    const providerEditDialog = this.props.isProviderEditDialogOpen && (
      <ProviderEditDialog open={this.props.isProviderEditDialogOpen} />
    )
    const singleCaseAgreementDialog = this.props.isSingleCaseAgreementDialogOpen && (
      <SingleCaseAgreementDialog />
    )
    const readonly = this.isReadonly()
    const title =
      this.props.schedule && this.props.schedule.isNew
        ? 'Add New Schedule'
        : readonly
          ? 'View Schedule'
          : 'Edit Schedule'
    let defaultComponentId = ''

    const changeSelectHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        selectedPhysicianId: event?.target.selectedOptions[0].accessKey,
      })
    }

    const quoteIsValid =
      this.props.schedule?.episodeOfCare?.quote !== undefined &&
      (this.props.schedule!.episodeOfCare!.quote!.status! === QuoteStatus.Quoted ||
        this.props.schedule?.episodeOfCare.quote.status === QuoteStatus.NeedsRecon ||
        this.props.schedule?.episodeOfCare.quote.status === QuoteStatus.Reconciled)

    return (
      <>
        <Helmet>
          <title>
            {title +
              ': ' +
              this.props.patient?.lastName +
              ', ' +
              this.props.patient?.firstName +
              ' (' +
              moment(this.props.patient?.dateOfBirth).format('MM/DD/YYYY') +
              ') ' +
              episodeOfCare?.episodeOfCareNumberOrLegacyId}
          </title>
        </Helmet>
        {providerLookupDialog}
        {providerEditDialog}
        {singleCaseAgreementDialog}
        {schedule && episodeOfCare ? (
          <Formik
            initialValues={createIScheduleFormValues(schedule, defaultComponentId)}
            onSubmit={this.save}
            validationSchema={
              (schedule.isNew &&
                currentAppOrganization!.id === OrganizationId.Diagnostic) ||
              (!schedule.isNew && schedule.organizationId === OrganizationId.Diagnostic)
                ? DiagnosticScheduleSchema
                : SurgicalScheduleSchema
            }
            enableReinitialize={true}
            ref={this.scheduleRef}
          >
            {({
              errors,
              initialValues,
              setFieldValue,
              values,
              submitForm,
              dirty,
              submitCount,
              isValid,
            }) => (
              <Form>
                <Prompt
                  when={dirty && submitCount === 0}
                  message="Are you sure you want to leave the page? There are unsaved changes."
                />
                <AppBar style={{ boxShadow: 'none' }} position="sticky">
                  <AuthorizationModal />
                  {this.state.authLoading && (
                    <div
                      style={{
                        position: 'fixed',
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1500,
                      }}
                    >
                      <CircularProgress size={100} />
                    </div>
                  )}
                  <ResponsiveAppBar title={title} pageIcon={<Schedule />}>
                    <Grid container direction="row" spacing={2} justifyContent="flex-end">
                      {this.state.isLoading ? (
                        <Grid item>
                          <CircularProgress size={24} />
                        </Grid>
                      ) : (
                        <></>
                      )}
                      <Grid item>
                        <Button size="small" variant="contained" onClick={this.cancel}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          size="small"
                          color="secondary"
                          variant="contained"
                          onClick={this.handleSave(submitForm, true)}
                          disabled={
                            !isValid &&
                            (this.state.selectedPhysicianId == schedule.physicianId ||
                              (this.state.selectedPhysicianId == '' &&
                                schedule.physicianId == undefined)) &&
                            !schedule.isNew
                          }
                        >
                          Save
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          size="small"
                          color="secondary"
                          variant="contained"
                          onClick={this.handleSave(submitForm, false)}
                          disabled={
                            !isValid &&
                            (this.state.selectedPhysicianId == schedule.physicianId ||
                              (this.state.selectedPhysicianId == '' &&
                                schedule.physicianId == undefined)) &&
                            !schedule.isNew
                          }
                        >
                          Save and Close
                        </Button>
                      </Grid>
                    </Grid>
                  </ResponsiveAppBar>
                  <PatientActionBar isDemographicsVisible={true} />
                </AppBar>
                <Grid>
                  <Grid item xs={12}>
                    <CardWithNoTitle>
                      {patient && referral && episodeOfCare ? (
                        <Grid container spacing={1}>
                          <Grid item md={2} xs={12}>
                            {readonly || !schedule.isNew ? (
                              <FullWidthField
                                variant="outlined"
                                name="componentName"
                                label="Component"
                                disabled
                              />
                            ) : (
                              <SelectField
                                inputId="componentId"
                                getName={this.getName}
                                getValue={this.getValue}
                                items={dropdownComponents}
                                label="Component"
                                name="componentId"
                                fullWidth
                                errorMessage={errors.componentId}
                                required={true}
                                shrinkLabel={true}
                                outlined
                                disabled={readonly || !schedule.isNew}
                                disableInactive={true}
                                onChange={this.loadComponentTasks(values, initialValues)}
                              />
                            )}
                          </Grid>
                          {(schedule.isNew &&
                            currentAppOrganization!.id === OrganizationId.Diagnostic) ||
                          (!schedule.isNew &&
                            schedule.organizationId === OrganizationId.Diagnostic) ? (
                            <>
                              <Grid item md={2} xs={12}>
                                <SelectField
                                  inputId="testIds"
                                  getName={this.getTestName}
                                  getValue={this.getValue}
                                  items={episodeOfCare.tests.filter(
                                    (x) =>
                                      x.scheduleTests &&
                                      (x.scheduleTests.length === 0 ||
                                        x.scheduleTests[0].scheduleId === schedule.id)
                                  )}
                                  label="Test"
                                  name="testIds"
                                  fullWidth
                                  shrinkLabel={true}
                                  required
                                  errorMessage={errors.testIds}
                                  disabled={readonly}
                                  outlined
                                  multiple
                                  checkedItems={values.testIds}
                                />
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                          <Grid item md={4} xs={12}>
                            <FullWidthField
                              label="Provider"
                              name="provider"
                              variant="outlined"
                              disabled
                            />
                            <Button
                              style={{
                                textTransform: 'none',
                                marginBottom: '10px',
                              }}
                              size="small"
                              onClick={this.openProviderLookupDialog(
                                setFieldValue,
                                values
                              )}
                              variant="contained"
                              color="secondary"
                              disabled={readonly}
                            >
                              Select Provider Group and Location
                            </Button>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <FullWidthField
                              label="Location"
                              name="location"
                              variant="outlined"
                              disabled
                            />
                            <Grid item justifyContent="flex-end" className="error">
                              {this.state.providerError
                                ? 'Provider group and location are required.'
                                : ''}
                            </Grid>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                              <InputLabel shrink={true} variant={'outlined'}>
                                Physician/Surgeon
                              </InputLabel>
                              <Select
                                variant="outlined"
                                input={<OutlinedInput notched labelWidth={135} />}
                                disabled={
                                  this.props.selectedLocation === undefined || readonly
                                }
                                native={true}
                                fullWidth
                                onChange={changeSelectHandler}
                                id="physicianSelect"
                                value={
                                  targetPhysicianItems.find(
                                    (x) => x.id === this.state.selectedPhysicianId
                                  )?.description ?? ''
                                }
                              >
                                <option key="" accessKey="" value="" />
                                {targetPhysicianItems.map((item: DropdownOption) => (
                                  <option
                                    key={item.id}
                                    accessKey={item.id}
                                    value={item.description}
                                    disabled={!item.isEnabled}
                                  >
                                    {item.description}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item container lg={4} md={12} xs={12} spacing={1}>
                            <Grid item xs={6}>
                              <FullWidthField
                                format="YYYY-MM-DD"
                                defaultValue={values.appointmentDateInput}
                                type="date"
                                name="appointmentDateInput"
                                label="Appointment Date"
                                inputId="appointmentDateInput"
                                fullWidth={true}
                                clearable={true}
                                shrink={hasValue('appointmentDateInput', values)}
                                required={!values.isTBD}
                                variant="outlined"
                                errorMessage={errors.appointmentDateInput}
                                onChange={this.showDate(values)}
                                disabled={readonly || values.isTBD}
                              />
                            </Grid>

                            {!schedule.isSimpleEvent ? (
                              <>
                                <Grid item xs={6}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.isTBD}
                                        color="secondary"
                                        name="isTBD"
                                        onChange={this.clearAppointmentDate(
                                          setFieldValue,
                                          values
                                        )}
                                        value="isTBD"
                                        id="isTBD"
                                        disabled={readonly}
                                      />
                                    }
                                    label="Is TBD"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FullWidthField
                                    defaultValue={values.appointmentTime}
                                    format="hh:mm:ss"
                                    type="time"
                                    name="appointmentTime"
                                    label="Appointment Time"
                                    inputId="appointmentTime"
                                    fullWidth={true}
                                    clearable={true}
                                    shrink={hasValue('appointmentTime', values)}
                                    variant="outlined"
                                    onChange={this.showDate(values)}
                                    disabled={readonly || values.isTBD}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          {(schedule.isNew &&
                            currentAppOrganization!.id === OrganizationId.Diagnostic) ||
                          (!schedule.isNew &&
                            schedule.organizationId === OrganizationId.Diagnostic) ? (
                            <>
                              <Grid item container lg={8} md={12} xs={12} spacing={2}>
                                <Grid item xs={12}>
                                  <FullWidthField
                                    label="Authorization Comment"
                                    name="authorizationComment"
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FullWidthField
                                    label="Appointment Confirmation Comment"
                                    name="appointmentConfirmationComment"
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                          <Grid item xs={12}>
                            <span style={{ color: 'red' }}>
                              {this.getSingleCaseAgreement()}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <FullWidthField
                              shrink={hasValue('comments', values)}
                              name="comments"
                              label="Comments"
                              rows="3"
                              multiline={true}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}

                      <div style={{ width: '100vw' }}>
                        {schedule && !schedule.isNew ? (
                          <ExpansionPanel
                            style={{ marginTop: '10px' }}
                            expanded={
                              this.state.expandedTasksActions === 'tasksActionsPanel'
                            }
                            onChange={this.handleTasksActionsChange('tasksActionsPanel')}
                          >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography color="textSecondary" variant="body2">
                                APPOINTMENT TASKS AND ACTIONS
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Grid container>
                                <Grid item md={8} xs={12}>
                                  <CardWithTitle
                                    title={
                                      schedule.isNew
                                        ? 'Component Tasks'
                                        : `Appointment Tasks (${this.getRemainingTasks()})`
                                    }
                                    icon={Check}
                                  >
                                    {schedule.isNew ? (
                                      this.props.selectedComponent ? (
                                        <ESDataTableWithHeader
                                          dataTableManager={
                                            this.props.appointmentTasksDataTable
                                          }
                                          enableReorderColumns={false}
                                          enableSearch={false}
                                          enableShowInactives={true}
                                          enableToggleColumns={false}
                                          elevation={0}
                                          padding={0}
                                        />
                                      ) : (
                                        <></>
                                      )
                                    ) : (
                                      <>
                                        {appointmentTaskDialog}
                                        <ESDataTableWithHeader
                                          dataTableManager={
                                            this.props.scheduleAppointmentTasksDataTable
                                          }
                                          enableReorderColumns={false}
                                          enableSearch={false}
                                          enableShowInactives={true}
                                          enableToggleColumns={false}
                                          elevation={0}
                                          padding={0}
                                          rowCellStyleFunction={this.rowCellStyleFunction}
                                        />
                                      </>
                                    )}
                                  </CardWithTitle>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                  <CardWithTitle title="Actions" icon={Send}>
                                    {this.props.schedule && !this.props.schedule.isNew ? (
                                      <Table>
                                        <TableBody>
                                          {schedule &&
                                          schedule.organizationId ===
                                            OrganizationId.Diagnostic ? (
                                            <StyledTableRow>
                                              <TableCell>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  onClick={
                                                    this.sendAppointmentConfirmation
                                                  }
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <Grid item>
                                                    <span style={{ color: 'blue' }}>
                                                      Appointment Confirmation
                                                    </span>
                                                  </Grid>
                                                  <Grid item>
                                                    <Description
                                                      style={{
                                                        color: '#C0C0C0',
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                            </StyledTableRow>
                                          ) : (
                                            <></>
                                          )}
                                          {schedule &&
                                          schedule.organizationId ===
                                            OrganizationId.Diagnostic ? (
                                            <StyledTableRow>
                                              <TableCell>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                >
                                                  <Grid item>
                                                    <a
                                                      href={this.getNcmAdjusterEmailLink()}
                                                    >
                                                      Email NCM and Adjuster
                                                    </a>
                                                  </Grid>
                                                  <Grid item>
                                                    <Email
                                                      style={{
                                                        color: '#C0C0C0',
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                            </StyledTableRow>
                                          ) : (
                                            <></>
                                          )}
                                          {schedule.componentName ===
                                          ComponentNameConstants.DME ? (
                                            <StyledTableRow>
                                              <TableCell>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  onClick={
                                                    this.props.openDMEAuthorizationModal
                                                  }
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <Grid item>
                                                    <span style={{ color: 'blue' }}>
                                                      DME Authorization
                                                    </span>
                                                  </Grid>
                                                  <Grid item>
                                                    <Description
                                                      style={{
                                                        color: '#C0C0C0',
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                            </StyledTableRow>
                                          ) : (
                                            <></>
                                          )}
                                          {schedule.componentName ===
                                          ComponentNameConstants.Surgery ? (
                                            <StyledTableRow>
                                              <TableCell>
                                                {!quoteIsValid ? (
                                                  <Tooltip
                                                    title={
                                                      'Quote is not in a valid status.'
                                                    }
                                                  >
                                                    <Grid
                                                      container
                                                      direction="row"
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                      style={{
                                                        cursor: 'default',
                                                      }}
                                                    >
                                                      <Grid item>
                                                        <span
                                                          style={{
                                                            color: 'grey',
                                                          }}
                                                        >
                                                          Agreement for Surgical Injection
                                                          Services
                                                        </span>
                                                      </Grid>
                                                      <Grid item>
                                                        <Description
                                                          style={{
                                                            color: '#C0C0C0',
                                                          }}
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                  </Tooltip>
                                                ) : (
                                                  <>
                                                    <Grid
                                                      container
                                                      direction="row"
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                      onClick={async () => {
                                                        this.setState({
                                                          authLoading: true,
                                                        })
                                                        await this.props
                                                          .createPatientInjectionContract!().then(
                                                          () =>
                                                            this.setState({
                                                              authLoading: false,
                                                            })
                                                        )
                                                      }}
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      <Grid item>
                                                        <span
                                                          style={{
                                                            color: 'blue',
                                                          }}
                                                        >
                                                          Agreement for Surgical Injection
                                                          Services
                                                        </span>
                                                      </Grid>
                                                      <Grid item>
                                                        <Description
                                                          style={{
                                                            color: '#C0C0C0',
                                                          }}
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                  </>
                                                )}
                                              </TableCell>
                                            </StyledTableRow>
                                          ) : (
                                            <></>
                                          )}
                                          {schedule.componentName ===
                                          ComponentNameConstants.Surgery ? (
                                            <StyledTableRow>
                                              <TableCell>
                                                {!quoteIsValid ? (
                                                  <Tooltip
                                                    title={
                                                      'Quote is not in a valid status.'
                                                    }
                                                  >
                                                    <Grid
                                                      container
                                                      direction="row"
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                      style={{
                                                        cursor: 'default',
                                                      }}
                                                    >
                                                      <Grid item>
                                                        <span
                                                          style={{
                                                            color: 'grey',
                                                          }}
                                                        >
                                                          Agreement for Surgical Services
                                                        </span>
                                                      </Grid>
                                                      <Grid item>
                                                        <Description
                                                          style={{
                                                            color: '#C0C0C0',
                                                          }}
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                  </Tooltip>
                                                ) : (
                                                  <>
                                                    <Grid
                                                      container
                                                      direction="row"
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                      onClick={async () => {
                                                        this.setState({
                                                          authLoading: true,
                                                        })
                                                        await this.props
                                                          .createPatientSurgeryContract!().then(
                                                          () =>
                                                            this.setState({
                                                              authLoading: false,
                                                            })
                                                        )
                                                      }}
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      <Grid item>
                                                        <span
                                                          style={{
                                                            color: 'blue',
                                                          }}
                                                        >
                                                          Agreement for Surgical Services
                                                        </span>
                                                      </Grid>
                                                      <Grid item>
                                                        <Description
                                                          style={{
                                                            color: '#C0C0C0',
                                                          }}
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                  </>
                                                )}
                                              </TableCell>
                                            </StyledTableRow>
                                          ) : (
                                            <></>
                                          )}
                                          {schedule.componentName ===
                                            ComponentNameConstants.Facility ||
                                          schedule.componentName ===
                                            ComponentNameConstants.Surgery ? (
                                            <StyledTableRow>
                                              <TableCell>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  onClick={async () => {
                                                    this.setState({
                                                      authLoading: true,
                                                    })
                                                    await this.props
                                                      .createSurgeryFacilityAuthorization!().then(
                                                      () =>
                                                        this.setState({
                                                          authLoading: false,
                                                        })
                                                    )
                                                  }}
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <Grid item>
                                                    <span style={{ color: 'blue' }}>
                                                      Surgery / Facility Authorization
                                                    </span>
                                                  </Grid>
                                                  <Grid item>
                                                    <Description
                                                      style={{
                                                        color: '#C0C0C0',
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                            </StyledTableRow>
                                          ) : (
                                            <></>
                                          )}
                                          {schedule.componentName ===
                                          ComponentNameConstants.PTLocation ? (
                                            <>
                                              <StyledTableRow>
                                                <TableCell>
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    onClick={
                                                      this.props.openFCEAuthorizationModal
                                                    }
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                  >
                                                    <Grid item>
                                                      <span
                                                        style={{
                                                          color: 'blue',
                                                        }}
                                                      >
                                                        FCE Authorization
                                                      </span>
                                                    </Grid>
                                                    <Grid item>
                                                      <Description
                                                        style={{
                                                          color: '#C0C0C0',
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </TableCell>
                                              </StyledTableRow>
                                              <StyledTableRow>
                                                <TableCell>
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    onClick={
                                                      this.props
                                                        .openInHomeAuthorizationModal
                                                    }
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                  >
                                                    <Grid item>
                                                      <span
                                                        style={{
                                                          color: 'blue',
                                                        }}
                                                      >
                                                        In Home Physical Therapy
                                                        Authorization
                                                      </span>
                                                    </Grid>
                                                    <Grid item>
                                                      <Description
                                                        style={{
                                                          color: '#C0C0C0',
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </TableCell>
                                              </StyledTableRow>
                                              <StyledTableRow>
                                                <TableCell>
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    onClick={
                                                      this.props
                                                        .openInpatientAuthorizationModal
                                                    }
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                  >
                                                    <Grid item>
                                                      <span
                                                        style={{
                                                          color: 'blue',
                                                        }}
                                                      >
                                                        Inpatient Rehab Authorization
                                                      </span>
                                                    </Grid>
                                                    <Grid item>
                                                      <Description
                                                        style={{
                                                          color: '#C0C0C0',
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </TableCell>
                                              </StyledTableRow>
                                              <StyledTableRow>
                                                <TableCell>
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    onClick={
                                                      this.props.openOTAuthorizationModal
                                                    }
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                  >
                                                    <Grid item>
                                                      <span
                                                        style={{
                                                          color: 'blue',
                                                        }}
                                                      >
                                                        Occupational Therapy Authorization
                                                      </span>
                                                    </Grid>
                                                    <Grid item>
                                                      <Description
                                                        style={{
                                                          color: '#C0C0C0',
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </TableCell>
                                              </StyledTableRow>
                                              <StyledTableRow>
                                                <TableCell>
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    onClick={
                                                      this.props.openPTAuthorizationModal
                                                    }
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                  >
                                                    <Grid item>
                                                      <span
                                                        style={{
                                                          color: 'blue',
                                                        }}
                                                      >
                                                        Physical Therapy Authorization
                                                      </span>
                                                    </Grid>
                                                    <Grid item>
                                                      <Description
                                                        style={{
                                                          color: '#C0C0C0',
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </TableCell>
                                              </StyledTableRow>
                                              <StyledTableRow>
                                                <TableCell>
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    onClick={
                                                      this.props.openWCEAuthorizationModal
                                                    }
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                  >
                                                    <Grid item>
                                                      <span
                                                        style={{
                                                          color: 'blue',
                                                        }}
                                                      >
                                                        Work Conditioning Authorization
                                                      </span>
                                                    </Grid>
                                                    <Grid item>
                                                      <Description
                                                        style={{
                                                          color: '#C0C0C0',
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </TableCell>
                                              </StyledTableRow>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {schedule.componentName ===
                                          ComponentNameConstants.InitialEval ? (
                                            <StyledTableRow>
                                              <TableCell>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  onClick={async () => {
                                                    this.setState({
                                                      authLoading: true,
                                                    })
                                                    await this.props
                                                      .createInitialEvalAuthorization!().then(
                                                      () =>
                                                        this.setState({
                                                          authLoading: false,
                                                        })
                                                    )
                                                  }}
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <Grid item>
                                                    <span style={{ color: 'blue' }}>
                                                      Initial Eval Authorization
                                                    </span>
                                                  </Grid>
                                                  <Grid item>
                                                    <Description
                                                      style={{
                                                        color: '#C0C0C0',
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                            </StyledTableRow>
                                          ) : (
                                            <></>
                                          )}
                                          {schedule.componentName ===
                                          ComponentNameConstants.TakeoverOfCare ? (
                                            <StyledTableRow>
                                              <TableCell>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  onClick={async () => {
                                                    this.setState({
                                                      authLoading: true,
                                                    })
                                                    await this.props
                                                      .createTakeoverOfCareAuthorization!().then(
                                                      () =>
                                                        this.setState({
                                                          authLoading: false,
                                                        })
                                                    )
                                                  }}
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <Grid item>
                                                    <span style={{ color: 'blue' }}>
                                                      Takeover of Care Authorization
                                                    </span>
                                                  </Grid>
                                                  <Grid item>
                                                    <Description
                                                      style={{
                                                        color: '#C0C0C0',
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                            </StyledTableRow>
                                          ) : (
                                            <></>
                                          )}
                                          {schedule &&
                                          schedule.organizationId ===
                                            OrganizationId.Diagnostic ? (
                                            <StyledTableRow>
                                              <TableCell>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  onClick={
                                                    this.openSingleCaseAgreementDialog
                                                  }
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <Grid item>
                                                    <span style={{ color: 'blue' }}>
                                                      Single Case Agreement
                                                    </span>
                                                  </Grid>
                                                  <Grid item>
                                                    <Description
                                                      style={{
                                                        color: '#C0C0C0',
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                            </StyledTableRow>
                                          ) : (
                                            <></>
                                          )}
                                          {schedule &&
                                          schedule.organizationId ===
                                            OrganizationId.Diagnostic ? (
                                            <StyledTableRow>
                                              <TableCell>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  onClick={this.createResultsPdf}
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <Grid item>
                                                    <span style={{ color: 'blue' }}>
                                                      Request Results Letter
                                                    </span>
                                                  </Grid>
                                                  <Grid item>
                                                    <Description
                                                      style={{
                                                        color: '#C0C0C0',
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                            </StyledTableRow>
                                          ) : (
                                            <></>
                                          )}
                                          {schedule &&
                                          schedule.organizationId ===
                                            OrganizationId.Diagnostic ? (
                                            <StyledTableRow>
                                              <TableCell>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                >
                                                  <Grid item>
                                                    <a
                                                      href={this.getTestResultsEmailLink()}
                                                    >
                                                      Distribute Test Results Email
                                                    </a>
                                                  </Grid>
                                                  <Grid item>
                                                    <Email
                                                      style={{
                                                        color: '#C0C0C0',
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                            </StyledTableRow>
                                          ) : (
                                            <></>
                                          )}
                                          {schedule &&
                                          schedule.organizationId ===
                                            OrganizationId.Diagnostic ? (
                                            <StyledTableRow>
                                              <TableCell>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                >
                                                  <Grid item>
                                                    <a href={this.getPatientEmailLink()}>
                                                      Patient Email
                                                    </a>
                                                  </Grid>
                                                  <Grid item>
                                                    <Description
                                                      style={{
                                                        color: '#C0C0C0',
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                            </StyledTableRow>
                                          ) : (
                                            <></>
                                          )}
                                        </TableBody>
                                      </Table>
                                    ) : (
                                      <></>
                                    )}
                                  </CardWithTitle>
                                </Grid>
                              </Grid>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        ) : (
                          <></>
                        )}
                      </div>
                    </CardWithNoTitle>
                  </Grid>
                </Grid>
                {
                  <CriticalCareDialog
                    setSelectedLocation={this.props.setSelectedLocation}
                    setSelectedProvider={this.props.setSelectedProvider}
                    clearLocations={this.props.clearLocations}
                  />
                }
              </Form>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </>
    )
  }
}

const InjectedEditSchedule = inject<
  IStores,
  IEditScheduleProps,
  Partial<IEditScheduleProps>,
  any
>((stores: IStores) => ({
  appointmentTasksDataTable: stores.appointmentTasks.dataTableStore,
  clearAppointmentTaskFilters: stores.appointmentTasks.clearFilters,
  clearLocations: stores.locations.clearLocations,
  component: stores.components.selectedComponent,
  dropdownComponents: stores.components.componentOptions,
  createAppointmentConfirmation: stores.schedules.createAppointmentConfirmation,
  createAuthorizationPdf: stores.schedules.createAuthorizationPdf,
  createResultsPdf: stores.schedules.createResultsPdf,
  currentAppOrganization: stores.global.currentAppOrganization,
  episodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  getAllComponents: stores.components.getScheduleComponentOptions,
  getAllLocationsForProvider: stores.locations.getAllLocationsForProvider,
  getEpisodeOfCareById: stores.patientEdit.getEpisodeOfCareById,
  getNcmAdjusterEmailLink: stores.schedules.getNcmAdjusterEmailLink,
  getPatientById: stores.patients.getPatientById,
  getPatientEmailLink: stores.schedules.getPatientEmailLink,
  getProviderContractType: stores.providers.getProviderContractType,
  getProviders: stores.providers.getProviderSuggestions,
  getReferralById: stores.referrals.getReferralWithContactsById,
  getScheduleById: stores.schedules.getScheduleById,
  getTestResultsEmailLink: stores.schedules.getTestResultsEmailLink,
  isCriticalCare: stores.providers.isCriticalCare,
  isCriticalCareModalOpen: stores.providers.isCriticalCareModalOpen,
  isCurrentContractSingleAgreement:
    stores.appointmentTasks.isCurrentContractSingleAgreement,
  isModalOpen: stores.scheduleAppointmentTasks.isModalOpen,
  isProviderEditDialogOpen: stores.schedules.isProviderEditDialogOpen,
  isProviderLookupDialogOpen: stores.schedules.isProviderLookupDialogOpen,
  isSingleCaseAgreementDialogOpen: stores.schedules.isSingleCaseAgreementDialogOpen,
  isTaskRed: stores.scheduleAppointmentTasks.isTaskRed,
  loadAppointmentTasks: stores.appointmentTasks.getColumnSettingsAndAppointmentTasks,
  loadProvider: stores.providers.loadProvider,
  loadScheduleAppointmentTasks:
    stores.scheduleAppointmentTasks.getColumnSettingsAndScheduleAppointmentTasks,
  locations: stores.locations.locations,
  openCriticalCareModal: stores.providers.openCriticalCareModal,
  openProviderLookupDialog: stores.schedules.openProviderLookupDialog,
  openSingleCaseAgreementDialog: stores.schedules.openSingleCaseAgreementDialog,
  patient: stores.patients.selectedPatient,
  physicianItems: stores.locations.physicianItems,
  providers: stores.providers.providers,
  referral: stores.referrals.selectedReferral,
  rerouteToPath: stores.global.rerouteToPath,
  saveSchedule: stores.schedules.saveSchedule,
  schedule: stores.schedules.selectedSchedule,
  scheduleAppointmentTasksDataTable: stores.scheduleAppointmentTasks.dataTableStore,
  selectedLocation: stores.locations.selectedEditScheduleLocation,
  selectedProvider: stores.providers.selectedEditScheduleProvider,
  setIsCurrentContractSingleAgreement:
    stores.appointmentTasks.setIsCurrentContractSingleAgreement,
  setSelectedDropdownComponent: stores.components.setSelectedDropdownComponent,
  setSelectedLocation: stores.locations.setSelectedEditScheduleLocation,
  setSelectedProvider: stores.providers.setSelectedEditScheduleProvider,
  setSelectedSchedule: stores.schedules.setSelectedSchedule,
  openDMEAuthorizationModal: stores.schedules.openDMEAuthorizationModal,
  openPTAuthorizationModal: stores.schedules.openPTAuthorizationModal,
  openOTAuthorizationModal: stores.schedules.openOTAuthorizationModal,
  openInHomeAuthorizationModal: stores.schedules.openInHomeAuthorizationModal,
  openInpatientAuthorizationModal: stores.schedules.openInpatientAuthorizationModal,
  openWCEAuthorizationModal: stores.schedules.openWCEAuthorizationModal,
  openFCEAuthorizationModal: stores.schedules.openFCEAuthorizationModal,
  createSurgeryFacilityAuthorization: stores.schedules.createSurgeryFacilityAuthorization,
  createPatientInjectionContract: stores.schedules.createPatientInjectionContract,
  createPatientSurgeryContract: stores.schedules.createPatientSurgeryContract,
  createInitialEvalAuthorization: stores.schedules.createInitialEvalAuthorization,
  createTakeoverOfCareAuthorization: stores.schedules.createTakeoverOfCareAuthorization,
  close: stores.global.closeTab,
  setSelectedPatient: stores.patients.setSelectedPatient,
}))(EditSchedule)

export default withStyles(styles)(InjectedEditSchedule)
