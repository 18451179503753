import {
  Button,
  createStyles,
  Grid,
  Paper,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { Face, Person } from '@material-ui/icons'
import { Form, Formik, FormikActions } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import { inject } from 'mobx-react'
import React from 'react'
import { Prompt, RouteComponentProps } from 'react-router-dom'
import AddressField from '../common/AddressField/AddressField'
import CardWithTitle from '../common/CardWithTitle/CardWithTitle'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import FullWidthField from '../common/FullWidthField'
import IsActiveToggle from '../common/IsActiveToggle'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { noWhitespaceString } from '../common/YupExtensions'
import ContactEditDialog from '../contacts/ContactEditDialog'
import {
  DefaultContact,
  DefaultNurseCaseManager,
  IContact,
  INurseCaseManager,
} from '../Definitions'
import { IContactDto } from '../generated_client'
import { IStores } from '../Stores'
import DataTableStore from '../stores/DataTableStore'
import * as Yup from 'yup'
import {
  createINurseCaseManagerFormValues,
  INurseCaseManagerFormValues,
  toINurseCaseManager,
} from './NurseCaseManagerFormValues'
import { Helmet } from 'react-helmet'

const NurseCaseManagerSchema = Yup.object().shape({
  company: noWhitespaceString('Company is required', true),
  isActive: Yup.boolean(),
  isSpecialPricing: Yup.boolean(),
  preferences: noWhitespaceString(),
})

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      marginTop: 4,
      paddingTop: spacing(2),
    },
    topOfPage: {
      marginTop: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  })

interface INurseCaseManagerParams {
  nurseCaseManagerId: string
}

interface INurseCaseManagerProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<INurseCaseManagerParams> {
  contactsDataTableStore?: DataTableStore<IContactDto, IContact>
  disableNurseCaseManager?: (nurseCaseManager: INurseCaseManager) => void
  getColumnSettingsAndContacts?: () => Promise<void>
  getNurseCaseManagerById?: (id: string) => Promise<void>
  rerouteToPath?: (path: string) => void
  isLoading?: boolean
  createContact?: (contact: IContact, title: string) => void
  openDialogWithNurseCaseManager?: (nurseCaseManager: INurseCaseManager) => void
  recoverNurseCaseManager?: (nurseCaseManager: INurseCaseManager) => void
  saveNurseCaseManager?: (nurseCaseManager: INurseCaseManager) => Promise<void>
  setSelectedNurseCaseManager?: (nurseCaseManager: INurseCaseManager | undefined) => void
  nurseCaseManager?: INurseCaseManager
}

interface INurseCaseManagerState {
  reload: boolean
}
class NurseCaseManager extends React.Component<
  INurseCaseManagerProps,
  INurseCaseManagerState
> {
  constructor(props: INurseCaseManagerProps) {
    super(props)
    this.state = {
      reload: false,
    }
  }
  private async setup() {
    const { match } = this.props
    if (match && match.params.nurseCaseManagerId) {
      await this.props.getNurseCaseManagerById!(match.params.nurseCaseManagerId)
    } else {
      this.props.setSelectedNurseCaseManager!(DefaultNurseCaseManager(true))
    }
    await this.props.getColumnSettingsAndContacts!()
  }

  public async componentDidMount() {
    ;(window as any).formikRef = React.createRef()
    await this.setup()
  }

  public async componentDidUpdate(prevProps: INurseCaseManagerProps) {
    if (
      this.props.match.params.nurseCaseManagerId !==
      prevProps.match.params.nurseCaseManagerId
    ) {
      await this.setup()
    }
  }

  public componentWillUnmount() {
    this.props.setSelectedNurseCaseManager!(undefined)
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload }, () => {
      submitForm()
    })
  }

  public save = async (
    values: INurseCaseManagerFormValues,
    formikBag: FormikActions<INurseCaseManagerFormValues>
  ) => {
    const nurseCaseManager = await toINurseCaseManager(
      values,
      this.props.nurseCaseManager
    )
    this.props.saveNurseCaseManager!(nurseCaseManager).finally(() => {
      formikBag.setSubmitting(false)
      if (this.state.reload) {
        this.props.rerouteToPath!(
          `/admin/nurseCaseManagers/nurseCaseManager/${nurseCaseManager.id}`
        )
        this.setup()
      } else {
        this.cancel()
      }
    })
  }

  private handleIsActiveChange =
    (
      values: INurseCaseManagerFormValues,
      setFieldValue: (field: string, value: any) => void
    ) =>
    () => {
      setFieldValue('isActive', !values.isActive)
    }

  private cancel = () => {
    this.props.rerouteToPath!('/admin/nurseCaseManagers')
  }

  private openDialog = () => {
    this.props.createContact!(DefaultContact(true), 'Add New Nurse Case Manager Contact')
  }

  public render() {
    const { isLoading, nurseCaseManager, contactsDataTableStore } = this.props

    const title =
      nurseCaseManager && nurseCaseManager.isNew
        ? 'Add New Nurse Case Manager'
        : 'Edit Nurse Case Manager'

    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {nurseCaseManager ? (
          <Formik
            initialValues={createINurseCaseManagerFormValues(nurseCaseManager)}
            onSubmit={this.save}
            validationSchema={NurseCaseManagerSchema}
            enableReinitialize={true}
            ref={(window as any).formikRef}
          >
            {({ isValid, values, setFieldValue, submitForm, dirty, submitCount }) =>
              isLoading || true ? (
                <Form>
                  <Prompt
                    when={dirty && submitCount === 0}
                    message="Are you sure you want to leave the page? There are unsaved changes."
                  />
                  <ResponsiveAppBar title={title} pageIcon={<Face />}>
                    <Grid container direction="row" spacing={2} justifyContent="flex-end">
                      <Grid item>
                        <Button size="small" variant="contained" onClick={this.cancel}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          color="secondary"
                          size="small"
                          variant="contained"
                          disabled={!isValid}
                          onClick={this.handleSave(submitForm, true)}
                        >
                          Save
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          color="secondary"
                          size="small"
                          variant="contained"
                          disabled={!isValid}
                          onClick={this.handleSave(submitForm, false)}
                        >
                          Save and Close
                        </Button>
                      </Grid>
                    </Grid>
                  </ResponsiveAppBar>
                  <Grid
                    container
                    direction="row"
                    spacing={3}
                    justifyContent="space-between"
                    className={this.props.classes.topOfPage}
                  >
                    <Grid item xs={6}>
                      <CardWithTitle title="Nurse Case Manager" icon={Face}>
                        <Grid item xs={12}>
                          <FullWidthField
                            name="company"
                            label="Company"
                            variant="outlined"
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AddressField
                            name={'address'}
                            label="Address"
                            showPhone={false}
                            outlined={true}
                            required={false}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FullWidthField
                            name="preferences"
                            label="Preferences"
                            variant="outlined"
                            rows="3"
                            multiline
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FullWidthField
                            name="isSpecialPricing"
                            Label={{
                              label: 'Special Pricing?',
                            }}
                            component={CheckboxWithLabel}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <IsActiveToggle
                            isActive={values.isActive}
                            onChange={this.handleIsActiveChange(values, setFieldValue)}
                          />
                        </Grid>
                      </CardWithTitle>
                    </Grid>
                    {nurseCaseManager && !nurseCaseManager.isNew ? (
                      <Grid item xs={6}>
                        <Paper className={this.props.classes.paper}>
                          <ESDataTableWithHeader
                            dataTableManager={contactsDataTableStore!}
                            title="Contacts"
                            icon={Person}
                            addButtonOnClick={this.openDialog}
                            elevation={0}
                            enableShowInactives
                            padding={0}
                            rowsPerPage={5}
                          />
                          <ContactEditDialog contactVariant="NurseCaseManager" />
                        </Paper>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Form>
              ) : (
                <></>
              )
            }
          </Formik>
        ) : (
          <></>
        )}
      </>
    )
  }
}

const InjectedNurseCaseManagers = inject<
  IStores,
  INurseCaseManager,
  Partial<INurseCaseManagerProps>,
  any
>((stores: IStores) => ({
  contactsDataTableStore: stores.contacts.dataTableStore,
  createContact: stores.contacts.openDialogWithContact,
  dataTableManager: stores.nurseCaseManagers.dataTableStore,
  disableNurseCaseManager: stores.nurseCaseManagers.disableNurseCaseManager,
  getColumnSettingsAndContacts: stores.contacts.getColumnSettingsAndContacts,
  getNurseCaseManagerById: stores.nurseCaseManagers.getNurseCaseManagerById,
  isModalOpen: stores.nurseCaseManagers.isModalOpen,
  nurseCaseManager: stores.nurseCaseManagers.selectedNurseCaseManager,
  recoverNurseCaseManager: stores.nurseCaseManagers.recoverNurseCaseManager,
  rerouteToPath: stores.global.rerouteToPath,
  saveNurseCaseManager: stores.nurseCaseManagers.saveNurseCaseManager,
  setSelectedNurseCaseManager: stores.nurseCaseManagers.setSelectedNurseCaseManager,
}))(NurseCaseManager)

export default withStyles(styles)(InjectedNurseCaseManagers)
