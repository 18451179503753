import {
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  ArrowDropUp,
  AttachMoney,
  InsertDriveFileOutlined,
  RecordVoiceOver,
} from '@material-ui/icons'
import { connect, ErrorMessage, FieldArray, FormikContext, getIn } from 'formik'
import { inject } from 'mobx-react'
import * as moment from 'moment'
import React from 'react'
import FullWidthField from '../common/FullWidthField'
import SelectField from '../common/SelectField/SelectField'
import TitleBar from '../common/TitleBar/TitleBar'
import { ICMS1500, ILocation, IProvider, ISchedule } from '../Definitions'
import {
  AddressDto,
  EpisodeOfCareDto,
  IEpisodeOfCareDto,
  IProviderDto,
  IProviderTypeDto,
  ITypeDto,
} from '../generated_client'
import { IStores } from '../Stores'
import Box24 from './Box24'
import { Diagnosis, IClaimFormValues, createIClaimFormValues } from './ClaimFormValues'
import { ILineItemFormValues } from './LineItemFormValues'
import './ClaimForm.css'
import AppealNameConstants from 'src/constants/AppealNameConstants'
import ClaimDocumentButton from './ClaimDocumentButton'
import GeneratePDFDocumentButton from './GeneratePDFDocumentButton'
import QuoteTypeNameConstants from 'src/constants/QuoteTypeNameConstants'
import ReferralContactModal from 'src/patients/EpisodesOfCare/ReferralContactModal'

interface IBasicFormProps {
  episodeOfCare?: IEpisodeOfCareDto
  getTypesByCategory?: (type: string) => void
  isCurrentOrganizationSurgical?: boolean
  loadClaim?: (claimId: string) => Promise<void>
  locations?: ILocation[]
  openProviderLookupDialog?: () => void
  resetSelectedQuote?: () => void
  selectedCMS1500?: ICMS1500
  selectedLocation?: ILocation
  selectedProvider?: IProvider
  selectedSchedule?: ISchedule
  setSelectedCMS1500?: (cms1500: ICMS1500 | undefined) => void
  setSelectedLocation?: (location?: ILocation) => void
  setSelectedProvider?: (provider?: IProvider) => void
  tcTypes?: ITypeDto[]
  loadBodyParts?: () => Promise<void>
  errorState?: boolean
  dueDateBefore?: boolean
  showContractModal?: (provider: IProviderDto | undefined) => void
  dueDate?: string | undefined
  handleDueDateChange?: (dueDateVal: string) => void
  receivedDate?: string | undefined
  surgeryDate?: Date | undefined
  handleDateChange?: (event: any) => void
  openContractModal?: (index: number) => void
  showContractModalButton: boolean
  showPDFButton: boolean
  hasNotSubmitted: boolean
  isReferralContactModalOpen?: boolean
  openReferralContactModal?: () => void
}

interface IFormikProps {
  formik: FormikContext<IClaimFormValues>
}

class BasicForm extends React.Component<IBasicFormProps & IFormikProps> {
  constructor(props: IBasicFormProps & IFormikProps) {
    super(props)
  }

  public state = {
    isLoading: false,
  }

  public initialValues = createIClaimFormValues(
    this.props.selectedCMS1500,
    new EpisodeOfCareDto(this.props.episodeOfCare)
  )

  public getLinkPath() {
    return (
      '/patients/patient/' +
      this.props.episodeOfCare?.referral?.patientId +
      '/referral/' +
      this.props.episodeOfCare?.referralId +
      '/episodeOfCare/' +
      this.props.episodeOfCare?.id
    )
  }

  public render() {
    const hasValue = (field: string, values: IClaimFormValues) => {
      const value = getIn(values, field)

      return !!value
    }
    const { formik, showContractModal } = this.props

    if (this.props.selectedSchedule && this.props.selectedSchedule!.appointmentDate) {
      this.initialValues.dateOfService = formik.values.dateOfService
      this.initialValues.q14DateOfIllness = formik.values.q14DateOfIllness
    } else {
      this.initialValues.q14DateOfIllness = formik.values.q14DateOfIllness
    }

    if (this.props.selectedSchedule && this.props.selectedSchedule!.appointmentDate) {
      formik.values.dateOfService = this.props.selectedSchedule!.appointmentDate!
    }

    if (this.props.selectedLocation && this.props.selectedLocation!.id) {
      if (
        formik.values.providerLocationId === undefined ||
        formik.values.providerLocationId === ''
      ) {
        formik.values.providerLocationId = this.props.selectedLocation!.id
        formik.values.providerLocation = this.props.selectedLocation
      }
    }

    return (
      <>
        <Grid style={{ backgroundColor: '#FAFAFA' }}>
          {this.state.isLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '2',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          {this.props.isReferralContactModalOpen && (
            <ReferralContactModal
              selectedReferralId={this.props.episodeOfCare?.referralId!}
            />
          )}
          <Grid item>
            <Grid container item style={{ padding: 8 }}>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ArrowDropUp />}>
                  <TitleBar
                    title={
                      'General - ' +
                      (this.props.selectedCMS1500?.billStatus == 'New'
                        ? 'RECEIVED'
                        : this.props.selectedCMS1500?.billStatus
                          ? this.props.selectedCMS1500?.billStatus.toUpperCase()
                          : '[UNKNOWN]')
                    }
                    icon={AttachMoney}
                    titleComponent={
                      <div style={{ paddingTop: '8px', paddingLeft: '8px' }}>
                        {this.props.showPDFButton && (
                          <GeneratePDFDocumentButton
                            claimId={formik.values.id}
                            disabled={
                              formik.values.id == '' ||
                              (JSON.stringify(this.initialValues) !=
                                JSON.stringify(formik.values) &&
                                this.props.hasNotSubmitted)
                            }
                            setIsLoading={(loading: boolean) =>
                              this.setState({ isLoading: loading })
                            }
                          />
                        )}
                        <ClaimDocumentButton
                          docId={this.props.selectedCMS1500?.stagedDocumentId ?? ''}
                          setIsLoading={(loading: boolean) =>
                            this.setState({ isLoading: loading })
                          }
                        />
                      </div>
                    }
                    isTitleComponentPushedRight={true}
                  />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container item spacing={1}>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item>
                        <strong>Patient Name:</strong>&nbsp;
                        {this.props.episodeOfCare
                          ? this.props.episodeOfCare.referral!.patient!.firstName +
                            ' ' +
                            this.props.episodeOfCare.referral!.patient!.lastName
                          : 'undefined'}
                      </Grid>
                      <Grid
                        item
                        container
                        spacing={1}
                        xs={1}
                        style={{ minWidth: 'fit-content' }}
                      >
                        <Grid item>
                          <strong>EOC/Legacy:</strong>
                        </Grid>
                        <Grid item>
                          <div
                            style={{
                              color: 'darkblue',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              sessionStorage.setItem('patientTab', '0')
                              sessionStorage.setItem('eocTab', '1')
                              window.open(this.getLinkPath())
                            }}
                          >
                            {this.props.episodeOfCare
                              ? this.props.episodeOfCare.episodeOfCareNumberOrLegacyId
                              : 'undefined'}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Show Referral Contact">
                          <RecordVoiceOver
                            fontSize="small"
                            color={'primary'}
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={this.props.openReferralContactModal}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <strong> Group #:</strong>&nbsp;
                        {this.props.selectedCMS1500
                          ? this.props.selectedCMS1500.groupNumber
                            ? this.props.selectedCMS1500.groupNumber
                            : 'TBD'
                          : 'TBD'}
                      </Grid>
                      <Grid item>
                        <strong>Body Part:</strong>&nbsp;
                        {this.props.selectedSchedule?.bodyParts != undefined
                          ? this.props.selectedSchedule!.bodyParts
                          : this.props.episodeOfCare
                            ? this.props.episodeOfCare!.bodyPartText
                            : ''}
                      </Grid>
                      <Grid item>
                        {this.props.selectedCMS1500?.appealType != undefined ? (
                          <Typography noWrap variant="body2" color="error">
                            <strong>Appeal Type:</strong>&nbsp;
                            {AppealNameConstants[this.props.selectedCMS1500?.appealType]}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item>
                        <strong>ToC Date:</strong>&nbsp;
                        {this.props.episodeOfCare != undefined &&
                        this.props.episodeOfCare.effectiveDate != undefined
                          ? moment(this.props.episodeOfCare.effectiveDate).format('L')
                          : 'N/A'}
                      </Grid>
                      <Grid item>
                        <strong>Bundle Type:</strong>&nbsp;
                        {this.props.episodeOfCare != undefined &&
                        this.props.episodeOfCare.quote != undefined &&
                        this.props.episodeOfCare.quote.type != undefined
                          ? QuoteTypeNameConstants[this.props.episodeOfCare.quote.type]
                          : 'N/A'}
                      </Grid>
                      <Grid item>
                        <strong>Surgery Date:</strong>&nbsp;
                        {this.props.surgeryDate != undefined
                          ? moment(this.props.surgeryDate).format('L')
                          : 'N/A'}
                      </Grid>
                      <Grid item>
                        <strong># of Days:</strong>&nbsp;
                        {this.props.episodeOfCare != undefined &&
                        this.props.episodeOfCare.quote != undefined &&
                        this.props.episodeOfCare.quote.bundleDurationDays != undefined
                          ? this.props.episodeOfCare.quote.bundleDurationDays
                          : 'N/A'}
                      </Grid>
                      <Grid item>
                        <strong>End Date:</strong>&nbsp;
                        {this.props.episodeOfCare != undefined &&
                        this.props.episodeOfCare.quote != undefined &&
                        this.props.episodeOfCare.quote.endDate != undefined
                          ? moment(this.props.episodeOfCare.quote.endDate).format('L')
                          : 'N/A'}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      spacing={2}
                      xs={12}
                      style={{ paddingBottom: '34px' }}
                    >
                      <Grid item>
                        <strong>Date of Service:</strong>&nbsp;
                        {moment(formik.values.dateOfService).format('L')}
                      </Grid>
                      <Grid item>
                        <strong>Provider Group:</strong>&nbsp;
                        <span style={{ paddingRight: '4px' }}>
                          {this.getLocationProvider(formik.values)}
                        </span>
                        <Tooltip title="View Provider Contract Summary">
                          <InsertDriveFileOutlined
                            style={{
                              cursor: 'pointer',
                              fontSize: '16px',
                            }}
                            onClick={() => {
                              showContractModal!(formik.values.providerLocation?.provider)
                            }}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <strong>Provider Location:</strong>&nbsp;
                        {`${formik.values.providerLocation?.name} ${formik.values.providerLocation?.physicalAddress?.addressDisplay}` ??
                          '{UNKNOWN PROVIDER LOCATION}'}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ paddingBottom: 12 }}>
                      <Grid item xs={3}>
                        <SelectField
                          label="Provider Type"
                          name="providerTypeId"
                          inputId="providerTypeId"
                          getName={this.getProviderTypeName}
                          getValue={this.getProviderTypeValue}
                          items={this.getProviderTypes(formik.values)}
                          fullWidth
                          disabled={this.isProviderTypeDisabled(formik.values)}
                          shrinkLabel={!!formik.values.providerTypeId}
                          required
                        />
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: '3px' }}>
                        <FormControl fullWidth>
                          <TextField
                            inputProps={{
                              id: 'incomingOrOutgoingDate',
                              style: { padding: '8px' },
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={this.props.handleDateChange}
                            type="date"
                            name="incomingOrOutgoingDate"
                            variant="standard"
                            value={this.props.receivedDate}
                            label="Date Received"
                            fullWidth
                            required
                            error={this.props.receivedDate == ''}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="column"
                        xs={2}
                        style={{ marginTop: '3px' }}
                      >
                        <Grid item>
                          <TextField
                            inputProps={{
                              id: 'dueDate',
                              style: { padding: '8px' },
                            }}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            name="dueDate"
                            onChange={this.handleDueDateChange}
                            variant="standard"
                            value={this.props.dueDate}
                            label="Date Due"
                            fullWidth
                            required
                            error={this.props.dueDate == ''}
                          />
                        </Grid>
                        <Grid item style={{ paddingTop: '4px' }}>
                          {this.props.dueDateBefore && (
                            <div className="error">
                              Due date must occur on or after Date Received.
                            </div>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        xs={2}
                        style={{
                          marginRight: '10px',
                          marginTop: '11px',
                          paddingLeft: '8px',
                        }}
                      >
                        <Grid item>
                          <FullWidthField
                            name="q28TotalCharge"
                            label="Total Charge"
                            type="number"
                            required
                            InputLabelProps={{
                              shrink: hasValue('q28TotalCharge', formik.values),
                            }}
                          />
                        </Grid>
                        <Grid item style={{ paddingTop: '0px' }}>
                          {this.props.errorState && (
                            <div className="error">
                              Sum of claim line charges must match Total Charges entered.
                            </div>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} style={{ paddingBottom: 12 }}></Grid>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container style={{ padding: 8 }}>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ArrowDropUp />}>
                  <TitleBar title={'Box 21'} icon={AttachMoney} />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid item style={{ position: 'relative' }}>
                    <p
                      style={{
                        bottom: 0,
                        margin: 0,
                        position: 'static',
                      }}
                    >
                      Diagnosis or Nature of Illness or Injury (Relate A-L to service line
                      below) *
                    </p>
                    <Grid item container direction="row" xs={12}>
                      <FieldArray
                        name="q21Diagnosis"
                        render={this.renderDiagnosisFields(formik.values)}
                      />
                      <ErrorMessage name="q21Diagnosis">
                        {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container style={{ padding: 8 }}>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ArrowDropUp />}>
                  <TitleBar title={'Box 24'} icon={AttachMoney} />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {this.renderBox24(formik.values)}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  private openContractModal(index: number) {
    this.props.openContractModal!(index)
  }

  private renderBox24 = (values: IClaimFormValues) => {
    return (
      <>
        <Grid container direction="column" spacing={1}>
          {values.q24.map((_lineItem: ILineItemFormValues, index: number) => (
            <Box24
              showContractAmount={this.props.showContractModalButton}
              openContractModal={() => this.openContractModal(index)}
              index={index}
              providerTypeId={values.providerTypeId}
              providerId={values.providerId}
            />
          ))}
        </Grid>
      </>
    )
  }

  private renderDiagnosisFields = (values: IClaimFormValues) => () => (
    <>
      {values.q21Diagnosis.map((diagnosis: Diagnosis, index: number) => (
        <Grid item xs={3} key={index}>
          <FullWidthField name={`q21Diagnosis.${index}.code`} label={diagnosis.pointer} />
        </Grid>
      ))}
    </>
  )

  private getProviderTypes = (values: IClaimFormValues) => {
    return values.providerLocation
      ? values.providerLocation.providerTypes
        ? values.providerLocation.providerTypes!.sort((a, b) =>
            a.abbreviation!.toLowerCase().localeCompare(b.abbreviation!.toLowerCase())
          )
        : []
      : []
  }

  private getProviderTypeName = (type: IProviderTypeDto) => {
    return type.name || ''
  }

  private getProviderTypeValue = (type: IProviderTypeDto) => {
    return type.id || ''
  }

  private getLocationProvider = (values: IClaimFormValues) => {
    if (this.props.selectedLocation) {
      values.providerLocationId = this.props.selectedLocation.id
      values.providerLocation = this.props.selectedLocation
      if (this.props.selectedLocation!.provider) {
        values.provider = this.props.selectedLocation!.provider!
        values.providerId = this.props.selectedLocation!.providerId!
        values.q32ALocationNpi = this.props.selectedLocation!.npi ?? ''
        values.q32LocationInfo =
          (this.props.selectedLocation?.name
            ? this.props.selectedLocation?.name + '\n'
            : '') +
          (this.props.selectedLocation?.physicalAddress?.line1
            ? this.props.selectedLocation.physicalAddress.line1 + '\n'
            : '') +
          (this.props.selectedLocation?.physicalAddress?.line2
            ? this.props.selectedLocation.physicalAddress.line2 + '\n'
            : '') +
          (this.props.selectedLocation?.physicalAddress?.city
            ? this.props.selectedLocation.physicalAddress.city
            : '') +
          (this.props.selectedLocation?.physicalAddress?.state
            ? ', ' + this.props.selectedLocation?.physicalAddress?.state
            : '') +
          (this.props.selectedLocation?.physicalAddress?.zip
            ? ' ' + this.props.selectedLocation?.physicalAddress?.zip
            : '')
        if (
          this.props.selectedLocation &&
          this.props.selectedLocation!.billingAddresses !== undefined
        ) {
          var billingAddress = new AddressDto()
          for (var i = 0; i < this.props.selectedLocation!.billingAddresses.length; i++) {
            var address = this.props.selectedLocation!.billingAddresses[i]
            if (address.isActive && address.address != undefined) {
              billingAddress = address.address
              break
            }
          }
          values.q33BillingProviderInfo =
            (billingAddress?.name ? billingAddress?.name + '\n' : '') +
            (billingAddress?.line1 ? billingAddress.line1 + '\n' : '') +
            (billingAddress?.line2 ? billingAddress.line2 + '\n' : '') +
            (billingAddress?.city ? billingAddress.city : '') +
            (billingAddress?.state ? ', ' + billingAddress?.state : '') +
            (billingAddress?.zip ? ' ' + billingAddress?.zip : '')
        }
        values.q33ABillingProviderNpi =
          this.props.selectedLocation!.npi! !== ''
            ? this.props.selectedLocation!.npi!
            : this.props.selectedLocation!.provider?.npiNumber! !== ''
              ? this.props.selectedLocation!.provider?.npiNumber!
              : ''
        return this.props.selectedLocation?.provider?.name ?? '{UNKNOWN PROVIDER GROUP}'
      }
      return ''
    }
    return values.providerLocation?.provider?.name ?? '{UNKNOWN PROVIDER GROUP}'
  }

  private isProviderTypeDisabled = (values: IClaimFormValues) => {
    if (!!values.provider) {
      return false
    }
    return true
  }

  private handleDueDateChange = (event: any) => {
    this.props.handleDueDateChange!(event.target.value)
  }
}

const InjectedBasicForm = inject<IStores, IBasicFormProps, Partial<IBasicFormProps>, any>(
  (stores: IStores) => ({
    isCurrentOrganizationSurgical: stores.global.isCurrentOrganizationSurgical,
    loadClaim: stores.cms1500.loadCMS1500,
    locations: stores.locations.locations,
    openProviderLookupDialog: stores.schedules.openProviderLookupDialog,
    selectedCMS1500: stores.cms1500.selectedCMS1500,
    selectedLocation: stores.locations.selectedEditClaimLocation,
    selectedProvider: stores.providers.selectedProvider,
    selectedSchedule: stores.schedules.selectedSchedule,
    setSelectedCMS1500: stores.cms1500.setSelectedCMS1500,
    setSelectedLocation: stores.locations.setSelectedEditClaimLocation,
    setSelectedProvider: stores.providers.setSelectedEditClaimProvider,
    openContractModal: stores.cms1500.openContractModal,
    openReferralContactModal: stores.patientEdit.openReferralContactModal,
    isReferralContactModalOpen: stores.patientEdit.isReferralContactModalOpen,
  })
)(BasicForm)

export default connect<IBasicFormProps>(InjectedBasicForm)
