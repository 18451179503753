import { Storage } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultPreExistingCondition, IPreExistingCondition } from '../Definitions'
import { IStores } from '../Stores'
import PreExistingConditionDialog from './PreExistingConditionDialog'

interface IPreExistingConditionsProps {
  dataTableManager?: IDataTableManager<IPreExistingCondition>
  disablePreExistingCondition?: (preExistingCondition: IPreExistingCondition) => void
  getColumnSettingsAndPreExistingConditions?: () => void
  isModalOpen?: boolean
  openDialogWithPreExistingCondition?: (
    preExistingCondition: IPreExistingCondition
  ) => void
  recoverPreExistingCondition?: (preExistingCondition: IPreExistingCondition) => void
}

@inject((stores: IStores) => ({
  dataTableManager: stores.preExistingConditions.dataTableStore,
  disablePreExistingCondition: stores.preExistingConditions.disablePreExistingCondition,
  getColumnSettingsAndPreExistingConditions:
    stores.preExistingConditions.getColumnSettingsAndPreExistingConditions,
  isModalOpen: stores.preExistingConditions.isModalOpen,
  openDialogWithPreExistingCondition:
    stores.preExistingConditions.openDialogWithPreExistingCondition,
  recoverPreExistingCondition: stores.preExistingConditions.recoverPreExistingCondition,
}))
class PreExistingConditions extends React.Component<IPreExistingConditionsProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndPreExistingConditions!()
  }

  public render() {
    const preExistingConditionDialog = this.props.isModalOpen && (
      <PreExistingConditionDialog />
    )
    return (
      <>
        <Helmet>
          <title>Pre-Existing Conditions</title>
        </Helmet>
        <ResponsiveAppBar title="Pre-Existing Conditions" pageIcon={<Storage />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={true}
          enableToggleColumns={true}
          addButtonOnClick={this.openDialog(DefaultPreExistingCondition(true))}
        />
        {preExistingConditionDialog}
      </>
    )
  }

  public openDialog = (preExistingCondition: IPreExistingCondition) => () => {
    this.props.openDialogWithPreExistingCondition!(preExistingCondition)
  }
}

export default PreExistingConditions
