import { Loyalty } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import {
  DefaultMultipleProcedureDiscountExclusionCode,
  IMultipleProcedureDiscountExclusionCode,
} from '../Definitions'
import { IStores } from '../Stores'
import MultipleProcedureDiscountExclusionCodeDialog from './MultipleProcedureDiscountExclusionCodeDialog'

interface IMultipleProcedureDiscountExclusionCodesProps {
  dataTableManager?: IDataTableManager<IMultipleProcedureDiscountExclusionCode>
  disableMultipleProcedureDiscountExclusionCode?: (
    multipleProcedureDiscountExclusionCode: IMultipleProcedureDiscountExclusionCode
  ) => void
  getColumnSettingsAndMultipleProcedureDiscountExclusionCodes?: () => void
  isModalOpen?: boolean
  openDialogWithMultipleProcedureDiscountExclusionCode?: (
    multipleProcedureDiscountExclusionCode: IMultipleProcedureDiscountExclusionCode
  ) => void
  recoverMultipleProcedureDiscountExclusionCode?: (
    multipleProcedureDiscountExclusionCode: IMultipleProcedureDiscountExclusionCode
  ) => void
}

class MultipleProcedureDiscountExclusionCodes extends React.Component<IMultipleProcedureDiscountExclusionCodesProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndMultipleProcedureDiscountExclusionCodes!()
  }

  public render() {
    const multipleProcedureDiscountExclusionCodeDialog = this.props.isModalOpen && (
      <MultipleProcedureDiscountExclusionCodeDialog />
    )
    return (
      <>
        <Helmet>
          <title>Multiple Procedure Discount Exclusions</title>
        </Helmet>
        <ResponsiveAppBar
          title="Multiple Procedure Discount Exclusions"
          pageIcon={<Loyalty />}
        />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={false}
          enableToggleColumns={true}
          addButtonOnClick={this.openDialog(
            DefaultMultipleProcedureDiscountExclusionCode(true)
          )}
        />
        {multipleProcedureDiscountExclusionCodeDialog}
      </>
    )
  }

  public openDialog =
    (multipleProcedureDiscountExclusionCode: IMultipleProcedureDiscountExclusionCode) =>
    () => {
      this.props.openDialogWithMultipleProcedureDiscountExclusionCode!(
        multipleProcedureDiscountExclusionCode
      )
    }
}

const InjectedMultipleProcedureDiscountExclusionCode = inject<
  IStores,
  IMultipleProcedureDiscountExclusionCodesProps,
  Partial<IMultipleProcedureDiscountExclusionCodesProps>,
  any
>((stores: IStores) => ({
  dataTableManager: stores.multipleProcedureDiscountExclusionCodes.dataTableStore,
  disableMultipleProcedureDiscountExclusionCode:
    stores.multipleProcedureDiscountExclusionCodes
      .deleteMultipleProcedureDiscountExclusionCode,
  getColumnSettingsAndMultipleProcedureDiscountExclusionCodes:
    stores.multipleProcedureDiscountExclusionCodes
      .getColumnSettingsAndMultipleProcedureDiscountExclusionCodes,
  isModalOpen: stores.multipleProcedureDiscountExclusionCodes.isModalOpen,
  openDialogWithMultipleProcedureDiscountExclusionCode:
    stores.multipleProcedureDiscountExclusionCodes
      .openDialogWithMultipleProcedureDiscountExclusionCode,
}))(MultipleProcedureDiscountExclusionCodes)

export default InjectedMultipleProcedureDiscountExclusionCode
