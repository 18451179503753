import {
  createStyles,
  Grid,
  Paper,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { Person } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../../common/ESDataTableWithHeader'
import IDataTableManager from '../../common/IDataTableManager'
import SelectInput from '../../common/SelectInput/SelectInput'
import Constants from '../../config'
import { IReferral, IScheduleAppointmentTask, IUser } from '../../Definitions'
import { IStores } from '../../Stores'

const styles = ({}: Theme) =>
  createStyles({
    number: {
      color: '#94D33D',
      fontSize: 28,
    },
    paper: {
      backgroundColor: '#EEEEEE',
      cursor: 'pointer',
      fontSize: 16,
      height: '150px',
      margin: '5px',
      padding: '8px',
      textAlign: 'center',
    },
    paperContainer: {
      height: '100%',
    },
  })

interface IDiagnosticPatientFlowModuleProps {
  clearFilters?: () => void
  dataTableManager?: IDataTableManager<IReferral>
  filterByOverdue?: boolean
  episodesOfCareFlaggedAsIssueCount?: () => void
  getOrdersNotReceivedCount?: () => void
  getReferralsNotScheduledCount?: () => void
  getTestScheduledResultsNotReceivedCount?: () => void
  getScaSentNotReceivedCount?: () => void
  isModalOpen?: boolean
  referralsFlaggedAsIssueCount?: number
  ordersNotReceivedCount?: number
  referralsNotScheduledCount?: number
  testScheduledResultsNotReceivedCount?: number
  scaSentNotReceivedCount?: number
  rerouteToPath?: (path: string) => void
  selectedCareCoordinatorId?: string
  setSelectedFilter?: (filter: number) => void
  setSelectedCareCoordinatorId?: (id: string) => void
  getColumnSettingsAndReferrals?: (isPatientFlow?: boolean) => Promise<void>
  isTaskRed?: (data: IScheduleAppointmentTask) => boolean
  users?: IUser[]
  getAllUsers?: (pageSize?: number, roleFilter?: string) => Promise<void>
}

class DiagnosticPatientFlowModule extends React.Component<
  IDiagnosticPatientFlowModuleProps & WithStyles<typeof styles>
> {
  public state = {
    selectedFilter: 0,
  }
  public componentDidMount() {
    this.props.getColumnSettingsAndReferrals!(true)
    this.props.getAllUsers!(5000, Constants.Roles.CareCoordinator)
    this.props.episodesOfCareFlaggedAsIssueCount!()
    this.props.getOrdersNotReceivedCount!()
    this.props.getReferralsNotScheduledCount!()
    this.props.getTestScheduledResultsNotReceivedCount!()
    this.props.getScaSentNotReceivedCount!()
  }

  public componentWillUnmount() {
    this.props.clearFilters!()
  }

  private rowCellStyleFunction(data: IReferral) {
    if (
      this.props.isTaskRed!({
        ...data.scheduleAppointmentTaskForPatientFlow,
      } as IScheduleAppointmentTask)
    ) {
      return { color: '#CC3F3F' } as React.CSSProperties
    } else {
      return {} as React.CSSProperties
    }
  }

  private setFilter = (idx: number) => () => {
    this.setState({ selectedFilter: idx }, () => {
      this.props.setSelectedFilter!(idx)
    })
  }

  private renderHeaderContents() {
    const {
      referralsFlaggedAsIssueCount,
      ordersNotReceivedCount,
      referralsNotScheduledCount,
      testScheduledResultsNotReceivedCount,
      scaSentNotReceivedCount,
    } = this.props
    const { selectedFilter } = this.state
    return (
      <Grid item container direction="row" spacing={2} xs={12}>
        <Grid item xs>
          <Paper
            className={this.props.classes.paper}
            elevation={0}
            style={{
              border: selectedFilter === 0 ? '2px solid #94D33D' : undefined,
            }}
            onClick={this.setFilter(0)}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              className={this.props.classes.paperContainer}
              wrap="nowrap"
            >
              <Grid item xs={8}>
                <strong>Orders Not Received</strong>
              </Grid>
              <Grid item xs={4}>
                <strong className={this.props.classes.number}>
                  {ordersNotReceivedCount}
                </strong>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper
            className={this.props.classes.paper}
            elevation={0}
            style={{
              border: selectedFilter === 1 ? '2px solid #94D33D' : undefined,
            }}
            onClick={this.setFilter(1)}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              className={this.props.classes.paperContainer}
              wrap="nowrap"
            >
              <Grid item xs={8}>
                <strong>Test/Referral Flagged as Issue</strong>
              </Grid>
              <Grid item xs={4}>
                <strong className={this.props.classes.number}>
                  {referralsFlaggedAsIssueCount}
                </strong>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper
            className={this.props.classes.paper}
            elevation={0}
            style={{
              border: selectedFilter === 2 ? '2px solid #94D33D' : undefined,
            }}
            onClick={this.setFilter(2)}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              className={this.props.classes.paperContainer}
              wrap="nowrap"
            >
              <Grid item xs={8}>
                <strong>Referrals Not Scheduled</strong>
              </Grid>
              <Grid item xs={4}>
                <strong className={this.props.classes.number}>
                  {referralsNotScheduledCount}
                </strong>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper
            className={this.props.classes.paper}
            elevation={0}
            style={{
              border: selectedFilter === 3 ? '2px solid #94D33D' : undefined,
            }}
            onClick={this.setFilter(3)}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              className={this.props.classes.paperContainer}
              wrap="nowrap"
            >
              <Grid item xs={8}>
                <strong>SCAs Sent Not Received</strong>
              </Grid>
              <Grid item xs={4}>
                <strong className={this.props.classes.number}>
                  {scaSentNotReceivedCount}
                </strong>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper
            className={this.props.classes.paper}
            elevation={0}
            style={{
              border: selectedFilter === 4 ? '2px solid #94D33D' : undefined,
            }}
            onClick={this.setFilter(4)}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              className={this.props.classes.paperContainer}
              wrap="nowrap"
            >
              <Grid item xs={8}>
                <strong>Test Scheduled Results Not Received</strong>
              </Grid>
              <Grid item xs={4}>
                <strong className={this.props.classes.number}>
                  {testScheduledResultsNotReceivedCount}
                </strong>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  public render() {
    this.rowCellStyleFunction = this.rowCellStyleFunction.bind(this)
    const filters = this.renderFilters()
    return (
      <>
        <div>
          <Paper>
            <Helmet>
              <title>Coordinator Dashboard</title>
            </Helmet>
            <ESDataTableWithHeader
              title="Patient Flow"
              icon={Person}
              dataTableManager={this.props.dataTableManager!}
              enableReorderColumns={false}
              enableSearch={false}
              enableShowInactives={false}
              enableToggleColumns={false}
              disableSort={true}
              disablePagination={false}
              elevation={0}
              padding={0}
              headerContents={this.renderHeaderContents()}
              headerPadding={10}
              rowCellStyleFunction={this.rowCellStyleFunction}
              filterComponents={filters}
            />
          </Paper>
        </div>
      </>
    )
  }

  private onSelectedCareCoordinatorChanged =
    () => (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.props.setSelectedCareCoordinatorId!(event.target.value)
      this.props.getOrdersNotReceivedCount!()
      this.props.getReferralsNotScheduledCount!()
      this.props.getTestScheduledResultsNotReceivedCount!()
      this.props.getScaSentNotReceivedCount!()
    }

  public getUserName(item: IUser) {
    return item ? item.name : ''
  }

  public getUserValue(item: IUser) {
    return item ? item.id : ''
  }

  private getCareCoordinators = (users?: IUser[]) => {
    return users ? ([undefined] as any[]).concat(users) || [] : []
  }

  private renderFilters() {
    const { users, selectedCareCoordinatorId } = this.props

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs>
              <SelectInput
                inputId="careCoordinator"
                getName={this.getUserName}
                getValue={this.getUserValue}
                items={this.getCareCoordinators(users)}
                label="Care Coordinator"
                name="careCoordinator"
                fullWidth
                onChange={this.onSelectedCareCoordinatorChanged()}
                value={selectedCareCoordinatorId}
                shrinkLabel={!!selectedCareCoordinatorId}
                outlined={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const InjectedDiagnosticPatientFlowModule = inject<
  IStores,
  IDiagnosticPatientFlowModuleProps,
  Partial<IDiagnosticPatientFlowModuleProps>,
  any
>((stores: IStores) => ({
  clearFilters: stores.referrals.clearFilters,
  dataTableManager: stores.referrals.patientFlowDataTableStore,
  episodesOfCareFlaggedAsIssueCount: stores.referrals.episodesOfCareFlaggedAsIssueCount,
  getAllUsers: stores.users.getAllUsers,
  getColumnSettingsAndReferrals: stores.referrals.getColumnSettingsAndReferrals,
  getOrdersNotReceivedCount: stores.patientEdit.getOrdersNotReceivedCount,
  getReferralsNotScheduledCount: stores.patientEdit.getReferralsNotScheduledCount,
  getScaSentNotReceivedCount: stores.patientEdit.getScaSentNotReceivedCount,
  getTestScheduledResultsNotReceivedCount:
    stores.patientEdit.getTestScheduledResultsNotReceivedCount,
  isModalOpen: stores.scheduleAppointmentTasks.isModalOpen,
  isTaskRed: stores.scheduleAppointmentTasks.isTaskRed,
  ordersNotReceivedCount: stores.patientEdit.ordersNotReceivedCount,
  referralsFlaggedAsIssueCount: stores.referrals.referralsFlaggedAsIssueCount,
  referralsNotScheduledCount: stores.patientEdit.referralsNotScheduledCount,
  rerouteToPath: stores.global.rerouteToPath,
  scaSentNotReceivedCount: stores.patientEdit.scaSentNotReceivedCount,
  selectedCareCoordinatorId: stores.referrals.selectedCareCoordinatorId,
  setSelectedCareCoordinatorId: stores.referrals.setSelectedCareCoordinatorId,
  setSelectedFilter: stores.referrals.setSelectedFilter,
  testScheduledResultsNotReceivedCount:
    stores.patientEdit.testScheduledResultsNotReceivedCount,
  users: stores.users.users,
}))(DiagnosticPatientFlowModule)

export default withStyles(styles)(InjectedDiagnosticPatientFlowModule)
