import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { RecordVoiceOver } from '@material-ui/icons'
import { ContentCopy } from 'mdi-material-ui'
import { inject } from 'mobx-react'
import React from 'react'
import { IEpisodeOfCare } from 'src/Definitions'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { GetReferralContactSummary } from 'src/services/LookupService'
import { ContactSummary } from 'src/viewModels/ContactSummary'

interface IReferralContactModalProps {
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean
  selectedEOC?: IEpisodeOfCare
  selectedReferralId?: string
}

class ReferralContactModal extends React.Component<IReferralContactModalProps> {
  public state = {
    claimNumber: '',
    jurisdiction: '',
    employerName: '',
    insuranceName: '',
    insuranceBillingAddress: '',
    ncmName: '',
    primaryAdjuster: new ContactSummary(),
    primaryNCM: new ContactSummary(),
    secondaryAdjusters: new Array<ContactSummary>(),
    secondaryNCMs: new Array<ContactSummary>(),
    loading: true,
  }

  public componentDidMount() {
    GetReferralContactSummary(this.props.selectedReferralId!).then((value) => {
      this.setState({
        claimNumber: value.claimNumber,
        jurisdiction: value.jurisdiction,
        employerName: value.employerName,
        insuranceName: value.insuranceName,
        insuranceBillingAddress: value.insuranceBillingAddress,
        ncmName: value.nurseCaseManagerName,
        primaryAdjuster: value.primaryAdjuster,
        primaryNCM: value.primaryNCM,
        secondaryAdjusters: value.secondaryAdjusters,
        secondaryNCMs: value.secondaryNCMs,
        loading: false,
      })
    })
  }

  public render() {
    const { isOpen, selectedEOC } = this.props

    return (
      <Dialog key={selectedEOC?.id} fullWidth={true} maxWidth="md" open={isOpen!}>
        {this.state.loading && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title={'Referral Contact Information'}
            pageIcon={<RecordVoiceOver />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" item xs={12} spacing={2}>
            <Grid item xs={12} style={{ paddingTop: '22px' }}>
              <Grid container direction="row">
                <Grid item>
                  <Typography
                    variant={'h6'}
                    component={'h6'}
                    color="primary"
                    style={{ paddingRight: '16px', marginBottom: '8px' }}
                  >
                    Insurance Claim Number: {this.state.claimNumber}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item>
                  <Typography
                    variant={'h6'}
                    component={'h6'}
                    color="primary"
                    style={{ paddingRight: '16px', marginBottom: '8px' }}
                  >
                    Jurisdiction:{' '}
                    {this.state.jurisdiction ? this.state.jurisdiction : '[UNKNOWN]'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item>
                  <Typography
                    variant={'h6'}
                    component={'h6'}
                    color="primary"
                    style={{ paddingRight: '16px', marginBottom: '8px' }}
                  >
                    Insurance Company: {this.state.insuranceName}
                  </Typography>
                </Grid>
              </Grid>
              <Typography style={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                Billing Address:
                <div style={{ paddingLeft: '24px' }}>
                  {this.state.insuranceBillingAddress}
                </div>
              </Typography>
              <Typography>
                <React.Fragment>
                  <span style={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                    Primary Adjuster: {this.state.primaryAdjuster.name + '\n'}
                    <div style={{ paddingLeft: '24px' }}>
                      Phone: {this.state.primaryAdjuster.phone + '\n'}
                      Fax: {this.state.primaryAdjuster.fax + '\n'}
                      Email&nbsp;
                      {this.state.primaryAdjuster.email?.includes('[UNKNOWN]') ? (
                        this.state.primaryAdjuster.email
                      ) : (
                        <>
                          {this.state.primaryAdjuster.email}{' '}
                          <Tooltip title={'Copy'}>
                            <ContentCopy
                              onClick={() => this.copyAdjusterEmail()}
                              style={{ cursor: 'pointer' }}
                              fontSize="small"
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </span>
                </React.Fragment>
              </Typography>
              {this.state.secondaryAdjusters.map((element: ContactSummary, index) => (
                <Typography>
                  <span style={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                    Secondary Adjuster: {element.name} {'\n'}
                    <div style={{ paddingLeft: '24px' }}>
                      Phone: {element.phone + '\n'}
                      Email&nbsp;
                      {element.email?.includes('[UNKNOWN]') ? (
                        element.email
                      ) : (
                        <>
                          {element.email}{' '}
                          <Tooltip title={'Copy'}>
                            <ContentCopy
                              onClick={() => this.copyAdjusterEmail(index)}
                              style={{ cursor: 'pointer' }}
                              fontSize="small"
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </span>
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Grid item>
                <Typography
                  variant={'h6'}
                  component={'h6'}
                  color="primary"
                  style={{ paddingRight: '16px', marginBottom: '8px' }}
                >
                  NCM Company: {this.state.ncmName}
                </Typography>
              </Grid>
              <Typography>
                <React.Fragment>
                  <span style={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                    Primary NCM: {this.state.primaryNCM.name + '\n'}
                    <div style={{ paddingLeft: '24px' }}>
                      Phone: {this.state.primaryNCM.phone + '\n'}
                      Fax: {this.state.primaryNCM.fax + '\n'}
                      Email&nbsp;
                      {this.state.primaryNCM.email?.includes('[UNKNOWN]') ? (
                        this.state.primaryNCM.email
                      ) : (
                        <>
                          {this.state.primaryNCM.email}{' '}
                          <Tooltip title={'Copy'}>
                            <ContentCopy
                              onClick={() => this.copyNCMEmail()}
                              style={{ cursor: 'pointer' }}
                              fontSize="small"
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </span>
                </React.Fragment>
              </Typography>
              {this.state.secondaryNCMs.map((element: ContactSummary, index) => (
                <Typography>
                  <React.Fragment>
                    <span style={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                      Secondary NCM: {element.name} {'\n'}
                      <div style={{ paddingLeft: '24px' }}>
                        Phone: {element.phone + '\n'}
                        Email&nbsp;
                        {element.email?.includes('[UNKNOWN]') ? (
                          element.email
                        ) : (
                          <>
                            {element.email}{' '}
                            <Tooltip title={'Copy'}>
                              <ContentCopy
                                onClick={() => this.copyNCMEmail(index)}
                                style={{ cursor: 'pointer' }}
                                fontSize="small"
                              />
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </span>
                  </React.Fragment>
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={'h6'}
                component={'h6'}
                color="primary"
                style={{ paddingRight: '16px', marginBottom: '8px' }}
              >
                Employer: {this.state.employerName}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ display: 'flex' }}>
          <Button onClick={this.close} data-cy="close" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  public secondFormRef = React.createRef<any>()

  private copyAdjusterEmail = (index?: number) => {
    if (index != undefined) {
      navigator.clipboard.writeText(this.state.secondaryAdjusters[index].email)
    } else {
      navigator.clipboard.writeText(this.state.primaryAdjuster.email)
    }
    alert('Copied adjuster email')
  }

  private copyNCMEmail = (index?: number) => {
    if (index != undefined) {
      navigator.clipboard.writeText(this.state.secondaryNCMs[index].email)
    } else {
      navigator.clipboard.writeText(this.state.primaryNCM.email)
    }
    alert('Copied NCM email')
  }

  private close = () => {
    this.props.close!()
  }
}

const InjectedReferralContactModal = inject<
  IStores,
  IReferralContactModalProps,
  Partial<IReferralContactModalProps>,
  any
>((stores: IStores) => ({
  close: stores.patientEdit.closeReferralContactModal,
  isLoading: stores.patientEdit.isLoading,
  isOpen: stores.patientEdit.isReferralContactModalOpen,
  selectedEOC: stores.patientEdit.selectedEpisodeOfCare,
}))(ReferralContactModal)

export default InjectedReferralContactModal
