import CustomMaskedInput from './CustomMaskedInput'

const PhoneNumberMask = CustomMaskedInput([
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
])
export default PhoneNumberMask
