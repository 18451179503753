import { AddCircle, Edit } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultLocationBilling, ILocationBilling } from '../../Definitions'
import {
  Client,
  ILocationBillingDto,
  LocationBillingDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'
import LocationsStore from '../LocationsStore'

const ColumnSettingsDto = 'LocationBillingDto'

export default class LocationBillingsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public locationBillings: ILocationBilling[]
  @observable
  public selectedLocationBilling?: ILocationBillingDto
  @observable
  public dataTableStore: DataTableStore<ILocationBillingDto, ILocationBilling>
  @observable
  public updateParentStateAfterModal?: () => void
  @observable
  public activeBillingAddressCount: number

  constructor(
    private globalStore: GlobalStore,
    private client: Client,
    private locationStore: LocationsStore
  ) {
    this.dataTableStore = new DataTableStore<ILocationBillingDto, ILocationBilling>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllLocationBillings(
          filter,
          this.locationStore.selectedLocation
            ? this.locationStore.selectedLocation.id
            : undefined,
          page,
          3,
          orderBy,
          includeInactives
        ),
      (locationBilling) => this.setupLocationBillings(locationBilling)
    )
  }

  @action.bound
  public setActiveBillingAddressCount(count: number) {
    this.activeBillingAddressCount = count
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedLocationBilling = undefined
    if (this.updateParentStateAfterModal) {
      this.updateParentStateAfterModal()
    }
  }

  @action.bound
  public setParentUpdateFunction(parentFunction: () => void) {
    this.updateParentStateAfterModal = parentFunction
  }

  @action.bound
  public loadLocationBilling = (locationBillingId: string) => {
    this.isLoading = true
    return this.client
      .getLocationBillingById(locationBillingId)
      .then(({ result }) =>
        runInAction(() => {
          this.selectedLocationBilling = this.addDefaultFields(result)
          this.isModalOpen = true
        })
      )
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public disableLocationBilling(locationBilling: ILocationBilling) {
    this.isLoading = true
    return this.client
      .archiveLocationBilling(locationBilling.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndLocationBillings() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllLocationBillings() {
    this.isLoading = true
    return this.client
      .getAllLocationBillings()
      .then((resp: SwaggerResponse<ILocationBillingDto[]>) =>
        runInAction(() => {
          this.locationBillings = this.setupLocationBillings(resp.result)
        })
      )
  }

  @action.bound
  public openDialogWithLocationBilling(locationBilling: ILocationBilling) {
    this.isModalOpen = true
    this.selectedLocationBilling = locationBilling
  }

  @action.bound
  public recoverLocationBilling(locationBilling: ILocationBilling) {
    this.isLoading = true
    return this.client
      .reactivateLocationBilling(locationBilling.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveLocationBilling(locationBilling: ILocationBilling) {
    this.isLoading = true
    let promise
    const locationBillingDto = new LocationBillingDto(locationBilling)

    if (locationBilling.isNew) {
      promise = this.client.createLocationBilling(locationBillingDto)
      this.activeBillingAddressCount = 1
    } else {
      promise = this.client.updateLocationBilling(locationBilling.id, locationBillingDto)
    }

    return promise
      .then(() => {
        this.resetUIState(locationBilling.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupLocationBillings = (locationBillings: ILocationBillingDto[]) => {
    return locationBillings.map((x) =>
      this.setupLocationBillingMenuItems(this.addDefaultFields(x))
    )
  }

  private addDefaultFields = (locationBilling: ILocationBillingDto): ILocationBilling => {
    return { ...DefaultLocationBilling(), ...locationBilling }
  }

  private setupLocationBillingMenuItems = (locationBilling: ILocationBilling) => {
    locationBilling.menuItems = []
    locationBilling.menuItems = [
      {
        icon: Edit,
        name: 'Edit',
        onClick: () => this.openDialogWithLocationBilling(locationBilling),
      },
    ]
    if (!locationBilling.isActive) {
      locationBilling.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverLocationBilling(locationBilling),
      })
    }
    return locationBilling
  }
}
