import {
  createStyles,
  Grid,
  Paper,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { GridDirection, GridItemsAlignment } from '@material-ui/core/Grid'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { CommentOutline } from 'mdi-material-ui'
import * as React from 'react'

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      margin: 4,
      padding: spacing(2),
      paddingTop: 10,
    },
    tableHeader: {
      height: 60,
      marginBottom: spacing(1),
      marginLeft: spacing(1),
    },
    tableHeaderTitle: {
      fontSize: '20px',
      paddingTop: '4px',
    },
  })

interface ICardWithTitleProps extends WithStyles {
  title: string
  titleComponent?: JSX.Element
  isTitleComponentPushedRight?: boolean
  icon?: React.ComponentType<SvgIconProps>
  formGridDirection?: GridDirection
  formGridAlignItems?: GridItemsAlignment
  headerStyle?: React.CSSProperties
  fullHeight?: boolean
  onClick?: () => void
  subTitle?: string
  subTitleInfo?: string
}

const CardWithTitle: React.SFC<ICardWithTitleProps> = ({
  children,
  classes,
  formGridAlignItems = 'center',
  formGridDirection = 'row',
  icon,
  isTitleComponentPushedRight = false,
  title,
  titleComponent,
  headerStyle = {},
  fullHeight,
  onClick,
  subTitle,
  subTitleInfo,
}) => {
  const componentStyles = () => {
    if (isTitleComponentPushedRight) {
      return {
        marginLeft: 'auto',
      } as React.CSSProperties
    } else {
      return undefined
    }
  }

  const Icon = icon

  const cardHeader = (
    <Grid
      container
      item
      direction="row"
      spacing={1}
      alignItems="center"
      className={classes.tableHeader}
      xs={12}
      onClick={onClick}
      style={{ marginLeft: 0, ...headerStyle }} // this is needed because material UI grid spacing applies -8px margin.
    >
      <Grid item>{Icon && <Icon style={{ fontSize: '32px' }} color="secondary" />}</Grid>
      <Grid item className={classes.tableHeaderTitle}>
        {title}
      </Grid>
      {subTitle && (
        <Typography display="inline" variant="subtitle2">
          &nbsp;{subTitle}&nbsp;&nbsp;
          {subTitleInfo && (
            <Tooltip title={subTitleInfo}>
              <span>
                <CommentOutline fontSize="small" />
              </span>
            </Tooltip>
          )}
        </Typography>
      )}
      {titleComponent && (
        <Grid item style={componentStyles()}>
          {titleComponent}
        </Grid>
      )}
    </Grid>
  )

  return (
    <Paper className={classes.paper} style={{ height: fullHeight ? '100%' : 'auto' }}>
      <Grid container direction="column" spacing={2}>
        {cardHeader}
        <Grid
          container
          item
          direction={formGridDirection}
          xs={12}
          spacing={1}
          alignItems={formGridAlignItems}
          style={{ marginLeft: 0 }} // this is needed because material UI grid spacing applies -8px margin.
        >
          {children}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withStyles(styles)(CardWithTitle)
