import { createStyles, Theme, withStyles } from '@material-ui/core'
import { Dashboard } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IStores } from '../Stores'
import DiagnosticPatientFlowModule from './modules/DiagnosticPatientFlowModule'
import SurgicalPatientFlowModule from './modules/SurgicalPatientFlowModule'

const styles = ({}: Theme) => createStyles({})

interface IDashboardPageProps {
  isCurrentOrganizationSurgical?: boolean
  setCanSwitchOrganizations?: (canSwitch: boolean) => void
}

class DashboardPage extends React.Component<IDashboardPageProps> {
  public componentDidMount() {
    this.props.setCanSwitchOrganizations!(true)
  }

  public componentWillUnmount() {
    this.props.setCanSwitchOrganizations!(false)
  }

  public render() {
    const { isCurrentOrganizationSurgical = true } = this.props
    return (
      <>
        <ResponsiveAppBar title="Coordinator Dashboard" pageIcon={<Dashboard />} />
        {isCurrentOrganizationSurgical ? (
          <SurgicalPatientFlowModule />
        ) : (
          <DiagnosticPatientFlowModule />
        )}
      </>
    )
  }
}

const InjectedDashboardPage = inject<
  IStores,
  IDashboardPageProps,
  Partial<IDashboardPageProps>,
  any
>((stores: IStores) => ({
  isCurrentOrganizationSurgical: stores.global.isCurrentOrganizationSurgical,
  setCanSwitchOrganizations: stores.global.setCanSwitchOrganizations,
}))(DashboardPage)

export default withStyles(styles)(InjectedDashboardPage)
