import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import { DefaultProvider, IProvider } from '../../Definitions'
import {
  AddressDto,
  Client,
  IProviderDto,
  OrganizationDto,
  ProviderDto,
  ProviderTypeDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'
import { ProviderGroupSummaryResult } from 'src/viewModels/ProviderGroupSummaryResult'

const ColumnSettingsDto = 'ProviderDto'

export default class ProvidersStore {
  @observable
  public isLoading: boolean = false
  @observable
  public providers: IProviderDto[]
  @observable
  public providerTypes: ProviderTypeDto[]
  @observable
  public selectedProvider?: IProvider | undefined
  @observable
  public selectedEditScheduleProvider?: IProvider | undefined
  @observable
  public selectedEditClaimProvider?: IProvider | undefined
  @observable
  public selectedModalEditScheduleProvider?: IProvider | undefined
  @observable
  public organizations: OrganizationDto[]
  @observable
  public selectedOrganizationId: string = ''
  @observable
  public selectedProviderTypeId?: string
  @observable
  public isCriticalCare: boolean = false
  @observable
  public isCriticalCareModalOpen: boolean = false
  public dataTableStore: DataTableStore<IProviderDto, IProvider>
  @observable
  public updateParentStateAfterDeactivate?: () => void

  constructor(
    private globalStore: GlobalStore,
    private routerStore: RouterStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IProviderDto, IProvider>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllProviders(
          filter,
          this.selectedProviderTypeId,
          this.selectedOrganizationId,
          page,
          undefined,
          orderBy,
          includeInactives
        ),
      (provider) => this.setupProviders(provider)
    )
  }

  @action.bound
  public setUpdateParentStateAfterDeactivate(parentFunction: () => void) {
    this.updateParentStateAfterDeactivate = parentFunction
  }

  @action.bound
  public disableProvider(providerId: string) {
    this.isLoading = true
    return this.client
      .archiveProvider(providerId)
      .then(() => {
        if (this.updateParentStateAfterDeactivate) {
          this.updateParentStateAfterDeactivate()
        }
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndProviders() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllProviders() {
    this.isLoading = true
    return this.client.getAllProviders().then((resp: SwaggerResponse<IProviderDto[]>) =>
      runInAction(() => {
        this.providers = this.setupProviders(resp.result)
      })
    )
  }

  @action.bound
  public buildProviderGroupMenuItems = (acq: ProviderGroupSummaryResult) => {
    let menuItems: any[] = []

    menuItems.push({
      color: '#29348F',
      icon: Edit,
      name: 'Edit Group',
      onClick: () => {
        window.open(`/providers/${acq.groupId}`, '_blank')
      },
    })

    if (acq.isInactive) {
      menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        name: 'Recover',
        onClick: () => this.recoverProvider(acq.groupId),
      })
    } else {
      menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        name: 'Make Inactive',
        onClick: () => {
          this.disableProvider(acq.groupId)
        },
      })
    }

    return menuItems
  }

  @action.bound
  public editProvider(provider: IProvider) {
    this.selectedProvider = provider
    this.routerStore.push(`/providers/${provider.id}`)
  }

  @action.bound
  public createProvider() {
    this.selectedProvider = DefaultProvider(true)
    window.open(`/providers/create`, '_blank')
  }

  @action.bound
  public recoverProvider(providerId: string) {
    this.isLoading = true
    return this.client
      .reactivateProvider(providerId)
      .then(() => {
        if (this.updateParentStateAfterDeactivate) {
          this.updateParentStateAfterDeactivate()
        }
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
  }

  @action.bound
  public saveProvider(provider: IProvider, stayInProviderPage?: boolean) {
    this.isLoading = true
    let promise
    const providerDto = new ProviderDto(provider)

    if (provider.isNew) {
      promise = this.client.createProvider(providerDto)
    } else {
      promise = this.client.updateProvider(provider.id, providerDto)
    }
    return promise
      .then(() =>
        runInAction(async () => {
          this.resetUIState(provider.isNew)
          if (stayInProviderPage) {
            this.client.getProviderById(provider.id).then(({ result }) =>
              runInAction(() => {
                this.editProvider(this.addDefaultFields(result))
              })
            )
          } else if (!stayInProviderPage) {
            window.close()
          }
        })
      )
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getProviderContractType(provider: IProvider) {
    return this.client.getProviderContractType(provider.id).then(({ result }) => {
      this.isCriticalCare = result
    })
  }

  @action.bound
  public getAllProviderTypes() {
    this.isLoading = true
    return this.client
      .getProviderTypes()
      .then(({ result }) =>
        runInAction(() => {
          this.providerTypes = result
        })
      )
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public loadProvider(id?: string) {
    const promises = []
    const getProviderTypes = this.client.getProviderTypes().then(({ result }) =>
      runInAction(() => {
        this.providerTypes = result
      })
    )
    promises.push(getProviderTypes)

    if (id === undefined) {
      this.selectedProvider = DefaultProvider(true)
      return Promise.all(promises)
    }
    if (this.selectedProvider && this.selectedProvider.id === id) {
      return Promise.all(promises)
    }
    const getProvider = this.client.getProviderById(id).then(({ result }) =>
      runInAction(() => {
        this.selectedProvider = this.addDefaultFields(result)
      })
    )
    promises.push(getProvider)

    return Promise.all(promises)
  }

  @action.bound
  public loadOrganizations() {
    return this.client.getAllOrganizations().then((resp) =>
      runInAction(() => {
        this.organizations = resp.result
      })
    )
  }

  @action.bound
  public setSelectedProvider(provider: IProvider | undefined) {
    this.selectedProvider = provider
    return Promise.resolve()
  }

  @action.bound
  public setSelectedEditScheduleProvider(provider: IProvider | undefined) {
    this.selectedEditScheduleProvider = provider
    return Promise.resolve()
  }

  @action.bound
  public setSelectedEditClaimProvider(provider: IProvider | undefined) {
    this.selectedEditClaimProvider = provider
    return Promise.resolve()
  }

  @action.bound
  public setSelectedModalEditScheduleProvider(provider: IProvider | undefined) {
    this.selectedModalEditScheduleProvider = provider
    return Promise.resolve()
  }

  @action.bound
  public setSelectedOrganizationId(id: string) {
    this.selectedOrganizationId = id
    this.dataTableStore.loadData()
  }

  private setupProviders = (providers: IProviderDto[]) => {
    return providers.map((x) => this.setupProviderMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (provider: IProviderDto): IProvider => {
    return { ...DefaultProvider(), ...provider }
  }

  private setupProviderMenuItems = (provider: IProvider) => {
    provider.displayAddress = this.buildDisplayAddress(provider.physicalAddress)
    provider.menuItems = [
      {
        icon: Edit,
        name: 'Edit',
        onClick: () => this.editProvider(provider),
      },
    ]
    if (provider.isActive) {
      provider.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableProvider(provider.id),
      })
    } else {
      provider.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverProvider(provider.id),
      })
    }
    return provider
  }

  private buildDisplayAddress(address: AddressDto) {
    if (address) {
      const { line1, line2, city, state, zip } = address
      let addressString = line1 || ''
      if (line2) {
        addressString += ` ${line2}`
      }
      addressString += `, ${city}, ${state} ${zip}`
      return addressString
    }
    return ''
  }

  @action.bound
  public getProviderSuggestions(filter: string) {
    return this.client.getAllProviders(filter).then(({ result }) =>
      runInAction(() => {
        this.providers = result
      })
    )
  }

  @action.bound
  public setSelectedProviderTypeId(providerTypeId: string) {
    this.selectedProviderTypeId = providerTypeId
  }

  @action.bound
  public openCriticalCareModal() {
    this.isCriticalCareModalOpen = true
  }

  @action.bound
  public closeCriticalCareModal() {
    this.isCriticalCareModalOpen = false
  }
}
