import React, { ChangeEvent } from 'react'
import { AdvancedSearchView } from '@easyquery/ui'
import '@easyquery/enterprise'
import { Helmet } from 'react-helmet'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { PageviewOutlined } from '@material-ui/icons'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import '../EasyQuery.css'
import { Form } from 'formik'
import { GetAdvancedSearchOptions } from 'src/services/LookupService'
import { AdvancedSearchConfigurationDTO } from 'src/viewModels/AdvancedSearchConfigurationDTO'
import {
  deleteAdvancedSearchConfiguration,
  getAdvancedSearchConfigurationById,
  insertAdvancedSearchConfiguration,
  updateAdvancedSearchConfiguration,
} from 'src/services/AdvancedSearchConfigurationService'
import { RouteComponentProps } from 'react-router-dom'

interface IEasyQueryRouteParams {
  currentUserId: string
}

interface IEasyQueryProps extends RouteComponentProps<IEasyQueryRouteParams> {}

export class EasyQuery extends React.Component<IEasyQueryProps> {
  public state = {
    selectedReportId: '',
    newReportName: '',
  }

  public easyQueryURL = `${
    process.env.REACT_APP_BASE_URL || 'http://localhost:5000'
  }/api/easyquery`
  public view = new AdvancedSearchView()

  public referralDetails = 'Referral/EOC Details'

  public reports = new Array<AdvancedSearchConfigurationDTO>()

  public async componentDidMount() {
    const viewOptions = {
      enableExport: true,
      serverExporters: ['pdf', 'excel', 'csv'],
      loadModelOnStart: true,
      loadQueryOnStart: false,
      useBootstrap: true,
      handlers: {
        beforeFetchData: function (
          _context: any,
          requestData: { options: { sqlOptions?: any } }
        ) {
          requestData.options = requestData.options || {}
          requestData.options.sqlOptions = {
            selectDistinct: true,
          }
        },
        beforeExportResult: function (
          _context: any,
          requestData: { options: { sqlOptions?: any } }
        ) {
          requestData.options = requestData.options || {}
          requestData.options.sqlOptions = {
            selectDistinct: true,
          }
        },
      },
      widgets: {
        queryPanel: {
          showCheckboxes: false,
          showPoweredBy: false,
          showRootRow: true,
          showAddRow: true,
          autoEditNewCondition: true,
          sortEntities: true,
          accentActiveCondition: true,
          attrElementFormat: '{entity} > {attr}',
          titleElementFormat: '{attr}',
        },
        columnsBar: {
          accentActiveColumn: false,
          allowAggrColumns: false,
          showColumnCaptions: true,
          adjustEntitiesMenuHeight: false,
          sortEntities: true,
          menuOptions: {
            showSearchBoxAfter: 30,
            activateOnMouseOver: true,
          },
        },
        easyGrid: {
          paging: {
            enabled: true,
            pageSize: 20,
          },
        },
      },
      result: {
        showChart: false,
      },
    }

    this.view = new AdvancedSearchView()
    this.context = this.view.getContext()

    this.context.useEndpoint(this.easyQueryURL).useEnterprise(() => {
      this.view.init(viewOptions)
    })

    this.context.addEventListener('ready', async () => {
      await GetAdvancedSearchOptions().then((response) => {
        this.save = this.save.bind(this)
        this.updateReport = this.updateReport.bind(this)
        this.openDeleteReportModal = this.openDeleteReportModal.bind(this)
        this.deleteReport = this.deleteReport.bind(this)
        this.reloadAfterSave = this.reloadAfterSave.bind(this)

        this.loadReportDropdownOptions(response, this.referralDetails)
      })
    })
  }

  public loadQueryFromLocalStorage(selectedReport: string) {
    if (selectedReport) {
      this.view.getContext().clearQuery()
      let query = this.view.getContext().getQuery()
      query.loadFromDataOrJson(selectedReport)
      this.view.getContext().refreshWidgets()
      this.view.syncQuery()
    }
  }

  public clearSearch = () => {
    this.view.getContext().clearQuery()
    this.loadQueryFromLocalStorage(
      this.reports.find((x) => x.id == this.state.selectedReportId)
        ?.advanceSearchQueryJson ?? ''
    )
    this.clearAlert()
  }

  public clearAlert = () => {
    var emptyEle = document.createElement('div')
    emptyEle.id = 'replaceAlertBanner'
    var oldElement = document.getElementById('replaceAlertBanner')
    oldElement?.replaceWith(emptyEle)
  }

  public triggerAlert = (message: string) => {
    var newElement = document.createElement('paper')
    newElement.setAttribute('class', 'wrapper')
    newElement.id = 'replaceAlertBanner'
    var text = newElement.appendChild(document.createElement('div'))
    text.textContent = message

    var oldElement = document.getElementById('replaceAlertBanner')
    oldElement?.replaceWith(newElement)
  }

  private setReportType = async (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedReportId: event.target.value })
    var report = this.reports.find((x) => x.id == event.target.value)

    if (report != undefined && report.advanceSearchQueryJson) {
      this.loadQueryFromLocalStorage(report.advanceSearchQueryJson)
      this.enableButton(
        report.userId == this.props.match.params.currentUserId,
        'updateButton',
        this.updateReport
      )
      this.enableButton(
        report.userId == this.props.match.params.currentUserId,
        'deleteReportButton',
        this.openDeleteReportModal
      )
    } else {
      await getAdvancedSearchConfigurationById(event.target.value).then((response) => {
        this.reports.push(response)
        this.loadQueryFromLocalStorage(response.advanceSearchQueryJson)
        this.enableButton(
          response.userId == this.props.match.params.currentUserId,
          'updateButton',
          this.updateReport
        )
        this.enableButton(
          response.userId == this.props.match.params.currentUserId,
          'deleteReportButton',
          this.openDeleteReportModal
        )
      })
    }
  }

  private openDeleteReportModal() {
    var modalElement = document.getElementById('deletePrompt') as HTMLDialogElement
    modalElement.open = true
    var backdropElement = document.getElementById('backdrop')

    if (backdropElement) {
      backdropElement.hidden = false
    }
  }

  private async updateReport() {
    this.triggerAlert('Updating report...')
    this.enableButton(false, 'updateButton')
    var report = this.reports.find((x) => x.id == this.state.selectedReportId)

    if (report) {
      var data = report
      data.advanceSearchQueryJson = this.view.getContext().getQuery().toJSON()
      await updateAdvancedSearchConfiguration(report.id, data)
        .then(() => {
          report!.advanceSearchQueryJson = data.advanceSearchQueryJson
          this.clearAlert()
        })
        .catch(() => this.clearAlert())
    }
  }

  private addReport() {
    var modalElement = document.getElementById('modal') as HTMLDialogElement
    modalElement.open = true
    var backdropElement = document.getElementById('backdrop')

    if (backdropElement) {
      backdropElement.hidden = false
    }
  }

  private loadReportDropdownOptions(
    response: AdvancedSearchConfigurationDTO[],
    selectedReportName: string
  ) {
    var firstReport = response.find((x) => x.name == selectedReportName)

    var line = new AdvancedSearchConfigurationDTO()
    line.id = 'line'
    line.name = '─────────────────────────'

    if (firstReport) {
      this.setState({
        selectedReportId: firstReport?.id,
      })
      var dropdownElement = document.getElementById('reportType') as HTMLSelectElement
      //Clear current children
      dropdownElement.options.length = 0

      var globalReports = response.filter((x) => x.isGlobal == true)
      var userReports = response.filter((x) => x.isGlobal == false)
      var ordered =
        userReports.length > 0
          ? globalReports.concat(line).concat(userReports)
          : globalReports

      ordered.forEach((report) => {
        var optionElement = document.createElement('option')
        optionElement.value = report.id
        optionElement.innerText = report.name

        if (report.id == 'line') {
          optionElement.disabled = true
        }

        if (report.name == selectedReportName) {
          optionElement.selected = true
        }

        dropdownElement?.append(optionElement)

        if (report.advanceSearchQueryJson != '') {
          this.reports.push(report)
        }
      })
      this.loadQueryFromLocalStorage(firstReport?.advanceSearchQueryJson)
    }
    return firstReport
  }

  public async reloadAfterSave() {
    await GetAdvancedSearchOptions()
      .then((response) => {
        var newReport = this.loadReportDropdownOptions(response, this.state.newReportName)
        this.enableButton(
          newReport?.userId == this.props.match.params.currentUserId,
          'updateButton',
          this.updateReport
        )
        this.enableButton(
          newReport?.userId == this.props.match.params.currentUserId,
          'deleteReportButton',
          this.openDeleteReportModal
        )

        this.clearAlert()
        this.closeModal()
      })
      .catch(() => {
        location.reload()
      })
  }

  public async save() {
    this.triggerAlert('Saving report...')
    this.enableButton(false, 'saveButton')
    var report = this.reports.find((x) => x.id == this.state.selectedReportId)

    if (report) {
      var data = report
      data.name = this.state.newReportName
      data.advanceSearchQueryJson = this.view.getContext().getQuery().toJSON()

      if (data.name == '') {
        this.enableButton(true, 'saveButton')
        return
      } else {
        insertAdvancedSearchConfiguration(data)
          .then(() => {
            this.reloadAfterSave()
          })
          .catch(() => this.clearAlert())
      }
    }
  }

  public deleteReport() {
    var report = this.reports.find((x) => x.id == this.state.selectedReportId)

    if (report) {
      var data = report

      deleteAdvancedSearchConfiguration(data.id)
        .then(() => {
          window.location.reload()
        })
        .catch(() => this.clearAlert())
    }
  }

  private closeDeletePrompt() {
    var modalElement = document.getElementById('deletePrompt') as HTMLDialogElement
    modalElement.open = false
    var backdropElement = document.getElementById('backdrop')

    if (backdropElement) {
      backdropElement.hidden = true
    }
  }

  private closeModal() {
    var modalElement = document.getElementById('modal') as HTMLDialogElement
    modalElement.open = false
    var textFieldElement = document.getElementById('newReportName') as HTMLTextAreaElement
    textFieldElement.value = ''
    var backdropElement = document.getElementById('backdrop')

    if (backdropElement) {
      backdropElement.hidden = true
    }
  }

  private enableButton(
    enable: boolean,
    buttonId: string,
    onClick?: () => Promise<void> | void
  ) {
    var buttonElement = document.getElementById(buttonId) as HTMLButtonElement

    if (buttonElement) {
      if (onClick) {
        buttonElement.onclick = () => onClick()
      }
      if (enable) {
        buttonElement.ariaDisabled = 'false'
        buttonElement.disabled = false
        buttonElement.style.backgroundColor =
          buttonId == 'deleteReportButton' ? '#8bb84b' : '#29348F'
        buttonElement.style.color = buttonId == 'deleteReportButton' ? 'black' : 'white'
        buttonElement.style.cursor = 'pointer'
      } else {
        buttonElement.ariaDisabled = 'true'
        buttonElement.disabled = true
        buttonElement.style.backgroundColor = 'rgba(170, 170, 170, 0.3)'
        buttonElement.style.color = 'rgba(0, 0, 0, 0.26)'
        buttonElement.style.cursor = 'default'
      }
    }
  }

  public renderModal = (
    <>
      <div
        id="backdrop"
        hidden
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          opacity: '1',
          width: '100%',
          height: '100%',
          zIndex: '3',
          position: 'fixed',
        }}
      ></div>
      <dialog
        id="modal"
        open={false}
        style={{
          zIndex: '4',
          top: '30%',
          width: '60%',
          padding: '0px',
          borderRadius: '4px',
          border: 'none',
        }}
      >
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar title={'Add Report'} isModal={true} />
        </DialogTitle>
        <DialogContent style={{ padding: '12px 12px 8px' }}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Report Name"
                name="newReportName"
                id="newReportName"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                required={true}
                onChange={(event) => {
                  this.setState({ newReportName: event.target.value })
                  this.enableButton(event.target.value != '', 'saveButton', this.save)
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            id="saveButton"
            disabled
            data-cy="save"
            style={{
              pointerEvents: 'auto',
              backgroundColor: 'rgba(170, 170, 170, 0.3)',
              color: 'rgba(0, 0, 0, 0.26)',
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              this.setState({ newReportName: '' })
              this.enableButton(false, 'saveButton', this.save)
              this.closeModal()
            }}
            data-cy="close"
          >
            Close
          </Button>
        </DialogActions>
      </dialog>
    </>
  )

  public renderDeletePrompt = (
    <>
      <dialog
        id="deletePrompt"
        open={false}
        style={{
          zIndex: '4',
          top: '30%',
          width: '60%',
          padding: '0px',
          borderRadius: '4px',
          border: 'none',
        }}
      >
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar title={'Delete Report'} isModal={true} />
        </DialogTitle>
        <DialogContent style={{ padding: '12px 12px 8px' }}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
              Are you sure you want to delete?
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            id="deleteButton"
            data-cy="delete"
            onClick={() => {
              this.triggerAlert('Deleting report...')
              this.enableButton(false, 'deleteButton')
              this.deleteReport()
            }}
            style={{
              pointerEvents: 'auto',
              backgroundColor: '#d32f2f',
              color: 'white',
            }}
          >
            Delete
          </Button>
          <Button onClick={this.closeDeletePrompt} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </dialog>
    </>
  )

  public render() {
    return (
      <>
        <Helmet>
          <title>Advanced Search</title>
        </Helmet>
        <ResponsiveAppBar title="Advanced Search" pageIcon={<PageviewOutlined />} />
        {this.renderModal}
        {this.renderDeletePrompt}
        <Grid>
          <div>
            <div id="replaceAlertBanner"></div>
          </div>
          <Grid container item style={{ paddingLeft: '20px' }} xs={12}>
            <Grid
              container
              direction="column"
              md={3}
              xs={12}
              style={{ paddingTop: '25px', paddingRight: '10px' }}
            >
              <Form>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink={true}
                    htmlFor="report-type-select"
                    variant="outlined"
                  >
                    Report Type
                  </InputLabel>
                  <Select
                    fullWidth
                    id="reportType"
                    variant="outlined"
                    native={true}
                    onChange={this.setReportType}
                    input={
                      <OutlinedInput
                        id="report-type-select"
                        name="reportType"
                        notched
                        labelWidth={88}
                      />
                    }
                    inputProps={{ label: true, notched: true }}
                  ></Select>
                </FormControl>
              </Form>
              <Grid
                container
                direction="row"
                style={{
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                  paddingBottom: '30px',
                }}
              >
                <Grid item>
                  <Button
                    id="deleteReportButton"
                    size="small"
                    variant="contained"
                    onClick={this.openDeleteReportModal}
                    style={{ pointerEvents: 'auto' }}
                    disabled
                  >
                    Delete
                  </Button>
                </Grid>
                <Grid item style={{ paddingLeft: '8px' }}>
                  <Button
                    id="updateButton"
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={this.updateReport}
                    style={{ pointerEvents: 'auto' }}
                    disabled
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item style={{ paddingLeft: '8px' }}>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    style={{ boxShadow: 'none' }}
                    onClick={this.addReport}
                  >
                    Add Report
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/* Enable this for when you need to see configured entities for easy query model */}
            {/* <Grid container direction="column" md={4}>
                            <div id="EntitiesPanel"></div>
                        </Grid> */}
            <Grid container direction="column" md={8} xs={12}>
              <div id="ColumnsBar"></div>
            </Grid>
          </Grid>
          <Grid container item style={{ paddingLeft: '20px' }}>
            <Grid style={{ paddingBottom: '10px' }}>
              <Typography
                style={{ paddingLeft: '10px' }}
                display="inline"
                color="textSecondary"
                variant="body2"
              >
                SEARCH CRITERIA
              </Typography>
              <div id="QueryPanel"></div>
            </Grid>
            <Grid
              container
              direction="row"
              style={{
                marginLeft: '10px',
                marginTop: '5px',
                marginBottom: '10px',
              }}
            >
              <span className="eqv-export-buttons" style={{ width: '100%' }}>
                <Grid container style={{ justifyContent: 'space-between' }}>
                  <Grid item>
                    <Button
                      id="FetchDataButton"
                      color="primary"
                      size="medium"
                      variant="contained"
                      style={{ marginRight: '10px' }}
                      onClick={this.clearAlert}
                    >
                      Search
                    </Button>
                    <Button
                      onClick={this.clearSearch}
                      color="secondary"
                      size="medium"
                      variant="contained"
                      style={{ marginRight: '10px' }}
                    >
                      Clear Search
                    </Button>
                  </Grid>
                  <Grid item style={{ marginRight: '20px' }}>
                    <Button
                      size="medium"
                      variant="contained"
                      className="eqjs-export"
                      href="javascript:void(0)"
                      onClick={() =>
                        this.triggerAlert(
                          'Please leave the tab open while your data is exported.'
                        )
                      }
                      data-format="excel"
                    >
                      Export to Excel
                    </Button>
                  </Grid>
                </Grid>
              </span>
            </Grid>
          </Grid>
          <div id="eqv-content">
            <div className="eqv-bottom-panel">
              <div className="eqv-result-panel" style={{ width: '100%' }}>
                <hr className="eqv-result-panel-hr eqv-hr" />
                <div className="eqv-result-panel-title">
                  Result
                  <span
                    id="ResultCount"
                    style={{
                      display: 'none',
                      marginLeft: 20,
                      fontSize: 'small',
                    }}
                  ></span>
                </div>
                <div id="ResultPanel" className="eqv-result-panel-content"></div>
              </div>
            </div>
          </div>
        </Grid>
      </>
    )
  }

  //This disables rerenders
  public shouldComponentUpdate() {
    return false
  }
}
