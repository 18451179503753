import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { List, RecordVoiceOver } from '@material-ui/icons'
import moment from 'moment'
import * as React from 'react'
import CardWithTitle from '../common/CardWithTitle/CardWithTitle'
import { IQuoteFormValues } from './QuoteFormValues'
import { GetEOCPatientReferralInfo } from 'src/services/LookupService'
import { EOCPatientReferralSummaryResult } from 'src/viewModels/EOCPatientReferralSummaryResult'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import FullWidthField from 'src/common/FullWidthField'
import { GenerateInvoicePDF, GetInvoicePaymentDetails } from 'src/services/QuoteService'
import { DocumentType } from 'src/utils/DocumentType'
import InvoiceLogEntryModal from './InvoiceLogEntryModal'
import { QuoteType } from 'src/utils/QuoteType'
import QuoteTypeNameConstants from 'src/constants/QuoteTypeNameConstants'
import { QuoteInboxItem } from 'src/viewModels/workflow/QuoteInboxItem'
import { QuoteStatus } from 'src/utils/QuoteStatus'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import ReferralContactModal from 'src/patients/EpisodesOfCare/ReferralContactModal'
//import { QuoteStatus } from 'src/utils/QuoteStatus';

const styles = ({ spacing }: Theme) =>
  createStyles({
    bold: {
      fontWeight: 'bold',
    },
    paper: {
      padding: spacing(2),
    },
  })

const QuoteStatusNames = {
  0: 'Needs Quote',
  1: 'Pending',
  2: 'Needs Recon',
  3: 'Reconciled',
  4: 'Complete',
  5: 'Imported',
  6: 'Draft',
  7: 'Quoted',
  8: 'Pending Contract',
  9: 'Pending Care Coordinator',
  10: 'Pending Finance',
  12: 'Dismissed',
}

interface IQuoteDetailsProps extends WithStyles<typeof styles> {
  disableInvoiceButtons: boolean
  values: IQuoteFormValues
  takeoverOfCareDate?: Date
  selectedQuote?: IQuoteDTO
  assistantWarning?: boolean
  viewMode?: boolean
  updateMain?: () => void
  isAdjusterEmailModalOpen?: boolean
  rerouteToPath?: (path: string) => void
  createInvoice?: (quoteInboxItem: QuoteInboxItem, actionId: number) => void
  quoteInboxItem: QuoteInboxItem
  isReferralContactModalOpen?: boolean
  openReferralContactModal?: () => void
}

class QuoteDetails extends React.Component<IQuoteDetailsProps> {
  constructor(props: IQuoteDetailsProps) {
    super(props)
    this.invoicePDF = this.invoicePDF.bind(this)
  }

  public async componentDidMount() {
    this.getEOCPatientReferralInfoByEOCNumber()

    if (this.props.selectedQuote?.globalInvoiceId) {
      await GetInvoicePaymentDetails(this.props.selectedQuote!.id!).then((result) => {
        this.setState({ hasPayments: result.payments.length > 0 })
      })
    }
  }

  public getEOCPatientReferralInfoByEOCNumber() {
    const { episodeOfCareNumber } = this.props.values
    GetEOCPatientReferralInfo(episodeOfCareNumber).then(
      (response: EOCPatientReferralSummaryResult) => {
        this.setState({
          eocId: response.eocId,
          patientId: response.patientId,
          referralId: response.referralId,
        })
      }
    )
  }

  public async invoicePDF() {
    await GenerateInvoicePDF(
      this.props.selectedQuote?.id!,
      this.props.selectedQuote?.invoiceRegeneratedAfterPrint ||
        this.props.selectedQuote?.initialInvoicePrintDate
        ? DocumentType.InvoiceResend
        : DocumentType.InvoiceInitial
    ).then(() => {
      this.setState({ pdfLoading: false, pdfCreated: true })
    })
  }

  public getLinkPath() {
    return (
      '/patients/patient/' +
      this.state.patientId +
      '/referral/' +
      this.state.referralId +
      '/episodeOfCare/' +
      this.state.eocId
    )
  }

  public state = {
    eocId: '',
    patientId: '',
    referralId: '',
    pdfCreated: false,
    pdfLoading: false,
    hasPayments: true,
    invoiceModalIsOpen: false,
  }

  public render() {
    const { values, classes, disableInvoiceButtons } = this.props
    let invoiceAction = undefined as unknown as InboxActionSummary

    if (
      this.props.quoteInboxItem?.actions.filter(
        (x) => x.uiGroupButtonText != undefined
      ) &&
      this.props.quoteInboxItem?.actions.filter((x) => x.uiGroupButtonText != undefined)
        .length > 0
    ) {
      invoiceAction = this.props.quoteInboxItem?.actions.filter(
        (x) => x.uiGroupButtonText != undefined
      )[0]
    }
    const headerButtons = (
      <Grid container spacing={2}>
        {this.props.selectedQuote?.globalInvoiceId != undefined &&
          (this.props.selectedQuote.initialInvoicePrintDate != undefined ||
            this.state.pdfCreated) && (
            <Grid item>
              <Button
                color="primary"
                disabled={disableInvoiceButtons}
                variant="contained"
                onClick={() => this.setState({ invoiceModalIsOpen: true })}
              >
                Add Inv. Notes
              </Button>
            </Grid>
          )}
        {this.props.selectedQuote?.globalInvoiceId != undefined && (
          <>
            <Grid item>
              {this.state.pdfCreated ||
              this.props.selectedQuote.initialInvoicePrintDate != undefined ? (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={disableInvoiceButtons}
                    onClick={() => {
                      this.setState({ pdfLoading: true }, () => this.invoicePDF())
                    }}
                  >
                    Resend Invoice
                  </Button>
                </>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disableInvoiceButtons}
                  onClick={() => {
                    this.setState({ pdfLoading: true }, () => this.invoicePDF())
                  }}
                >
                  Print Invoice
                </Button>
              )}
            </Grid>
          </>
        )}
        {this.props.selectedQuote?.status == QuoteStatus.Reconciled && invoiceAction && (
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={disableInvoiceButtons}
              onClick={() => {
                this.props.createInvoice!(this.props.quoteInboxItem!, invoiceAction.id!)
              }}
            >
              {invoiceAction.actionName}
            </Button>
          </Grid>
        )}
      </Grid>
    )

    return (
      <>
        {this.props.selectedQuote?.globalInvoiceId != undefined &&
          this.state.invoiceModalIsOpen && (
            <InvoiceLogEntryModal
              quote={this.props.selectedQuote}
              isOpen={this.state.invoiceModalIsOpen}
              close={() => this.setState({ invoiceModalIsOpen: false })}
            />
          )}
        {this.state.pdfLoading && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 500,
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        {this.props.isReferralContactModalOpen && (
          <ReferralContactModal selectedReferralId={this.state.referralId!} />
        )}
        <CardWithTitle
          title={
            this.props.selectedQuote?.type != undefined
              ? QuoteTypeNameConstants[this.props.selectedQuote?.type] + ' Quote'
              : ''
          }
          icon={List}
          headerStyle={{ marginBottom: 0 }}
          titleComponent={headerButtons}
          isTitleComponentPushedRight={true}
          subTitle={
            '(Status: ' +
            (this.props.selectedQuote?.status != undefined
              ? QuoteStatusNames[this.props.selectedQuote?.status!]
              : '') +
            ')'
          }
          subTitleInfo={this.props.selectedQuote?.latestStatusComment}
        >
          <Grid container item direction="row" spacing={2}>
            <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
              <span className={classes.bold}>Patient Name: </span>
              <span>
                {values.patientName}
                <Typography variant="caption">
                  <strong>
                    {values.dateOfBirth
                      ? ' [' + moment(values.dateOfBirth).format('L') + ']'
                      : ''}
                  </strong>
                </Typography>
              </span>
            </Grid>
            <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
              {this.props.selectedQuote?.type == QuoteType.Surgical ||
              this.props.selectedQuote?.type == QuoteType.Injection ? (
                <>
                  <span className={classes.bold}>Surgery Date: </span>
                  <span>
                    {values.surgeryDate ? moment(values.surgeryDate).format('L') : 'N/A'}
                  </span>
                </>
              ) : (
                <>
                  <span className={classes.bold}>ToC Date: </span>
                  <span>
                    {this.props.takeoverOfCareDate
                      ? moment(this.props.takeoverOfCareDate).format('L')
                      : 'N/A'}
                  </span>
                </>
              )}
            </Grid>
            <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
              <span className={classes.bold}># of Days: </span>
              <span>{this.props.selectedQuote?.bundleDurationDays ?? 'N/A'}</span>
            </Grid>
            <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
              <span className={classes.bold}>End Date: </span>
              <span>
                {this.props.selectedQuote?.endDate
                  ? moment(this.props.selectedQuote?.endDate).format('L')
                  : 'N/A'}
              </span>
            </Grid>
            <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
              <span className={classes.bold}>Body Part: </span>
              <span>{values.bodyPartName}</span>
            </Grid>
            <Grid item sm={1} style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}>
              <span className={classes.bold}> EOC/Legacy: </span>
              <span
                style={{
                  color: 'darkblue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  sessionStorage.setItem('patientTab', '0')
                  sessionStorage.setItem('eocTab', '1')
                  window.open(this.getLinkPath())
                }}
              >
                {values.episodeOfCareOrLeagacyId}
              </span>
            </Grid>
            <Grid item>
              <Tooltip title="Show Referral Contact">
                <RecordVoiceOver
                  fontSize="small"
                  color={'primary'}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={this.props.openReferralContactModal}
                />
              </Tooltip>
            </Grid>
            <Grid item sm={12} md={6}>
              <FullWidthField
                label="Dx Codes"
                id="dxCodes"
                name="dxCodes"
                variant="outlined"
                disabled={this.props.viewMode}
              />
            </Grid>
          </Grid>
          {this.props.assistantWarning ? (
            <Typography color="error">
              *Assistant Surgeon multiplier will be applied when quote is saved.
            </Typography>
          ) : (
            <></>
          )}
        </CardWithTitle>
      </>
    )
  }
}

export default withStyles(styles)(QuoteDetails)
