function createDocumentUrl(data: string, mime: string): string {
  // Convert array buffer to Base64
  const binary_string = window.atob(data)
  const len = binary_string.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }

  // Convert Base64 string to dataURL
  const file = new Blob([bytes], {
    type: mime,
  })
  const fileURL = URL.createObjectURL(file)
  return fileURL
}

export default createDocumentUrl
