export interface IPayProvidersSearchRequest {
  locationName?: string | undefined
  eocGeneratedOrLegacyId?: string | undefined
  dueDateStart?: string | undefined
  dueDateEnd?: string | undefined
  contractAmountMin?: string | undefined
  contractAmountMax?: string | undefined
}

export class PayProvidersSearchRequest implements IPayProvidersSearchRequest {
  public locationName?: string | undefined
  public eocGeneratedOrLegacyId?: string | undefined
  public dueDateStart?: string | undefined
  public dueDateEnd?: string | undefined
  public contractAmountMin?: string | undefined
  public contractAmountMax?: string | undefined
}
