import { Card, CardContent, Grid, SvgIcon } from '@material-ui/core'
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles'
import {
  Accessibility,
  AccountBox,
  Business,
  Code,
  Description,
  Drafts,
  Face,
  Hotel,
  Inbox,
  Info,
  Label,
  LocalHospital,
  Loyalty,
  MonetizationOn,
  PersonOutline,
  RecordVoiceOver,
  Send,
  Settings,
  Storage,
} from '@material-ui/icons'
import { ClipboardOutline, Stethoscope } from 'mdi-material-ui'
import { inject } from 'mobx-react'
import { RouterStore } from 'mobx-react-router'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IStores } from '../Stores'
import { IUser } from 'src/Definitions'
import Constants from '../config'

const styles = () =>
  createStyles({
    cardContainer: {
      minWidth: '240px',
      padding: '20px',
      textAlign: 'center',
    },
    cardContent: {
      height: '100%',
    },
    pageCard: {
      borderRadius: '20px',
      cursor: 'pointer',
      height: '230px',
      paddingTop: '20px',
    },
    svgIcon: {
      fontSize: '96px',
    },
  })

interface IAdminPagesProps extends WithStyles<typeof styles> {
  routerStore?: RouterStore
  setCanSwitchOrganizations?: (canSwitch: boolean) => void
  user?: IUser
}

interface IAdminPagesState {
  pages: IAdminPage[]
}

interface IAdminPage {
  icon: React.ComponentType
  path: string
  title: string
}

@inject((stores: IStores) => ({
  routerStore: stores.routing,
  setCanSwitchOrganizations: stores.global.setCanSwitchOrganizations,
}))
class AdminPages extends Component<IAdminPagesProps, IAdminPagesState> {
  constructor(props: IAdminPagesProps) {
    super(props)

    var pages: IAdminPage[] = []

    if (this.props.user?.roles.includes(Constants.Roles.WorkflowAdmin)) {
      pages = [
        {
          icon: Inbox,
          path: '/admin/workflow',
          title: 'Workflow Administration',
        },
      ]
    }

    if (this.props.user?.roles.includes(Constants.Roles.Administrator)) {
      pages.push({
        icon: Info,
        path: '/admin/companyInfo',
        title: 'Company Information',
      })
      pages.push({
        icon: RecordVoiceOver,
        path: '/admin/referringDoctors',
        title: 'Referring Doctors',
      })
      pages.push({
        icon: Business,
        path: '/admin/employers',
        title: 'Employers',
      })
      pages.push({
        icon: Description,
        path: '/admin/insuranceCompanies',
        title: 'Insurance Companies',
      })
      pages.push({
        icon: Face,
        path: '/admin/nurseCaseManagers',
        title: 'Nurse Case Managers',
      })
      pages.push({
        icon: Storage,
        path: '/admin/preexistingconditions',
        title: 'Pre-Existing Conditions',
      })
      pages.push({
        icon: ClipboardOutline,
        path: '/admin/components',
        title: 'Components and Tasks',
      })
      pages.push({
        icon: Loyalty,
        path: '/admin/exclusionCodes',
        title: 'Multiple Procedure Discount Exclusions',
      })
      pages.push({
        icon: PersonOutline,
        path: '/admin/contactTypes',
        title: 'Contact Types',
      })
      pages.push({
        icon: Accessibility,
        path: '/admin/bodyParts',
        title: 'Body Parts',
      })
      pages.push({
        icon: Label,
        path: '/admin/tags',
        title: 'Tags',
      })
      pages.push({
        icon: Code,
        path: '/admin/cptCodes',
        title: 'CPT Codes',
      })
      pages.push({
        icon: Stethoscope,
        path: '/admin/dxCodes',
        title: 'DX Codes',
      })
      pages.push({
        icon: MonetizationOn,
        path: '/admin/medicareCost',
        title: 'Medicare Cost',
      })
      pages.push({
        icon: Hotel,
        path: '/procedures',
        title: 'Procedures',
      })
      pages.push({
        icon: LocalHospital,
        path: '/providers',
        title: 'Providers',
      })
      pages.push({
        icon: Send,
        path: '/admin/sentChecks',
        title: 'Sent Checks',
      })
      pages.push({
        icon: Drafts,
        path: '/admin/receivedChecks',
        title: 'Received Checks',
      })
      pages.push({
        icon: AccountBox,
        path: '/users',
        title: 'Users',
      })
    } else {
      if (
        this.props.user?.roles.includes(Constants.Roles.GeneralFinance) ||
        this.props.user?.roles.includes(Constants.Roles.FinanceTier1) ||
        this.props.user?.roles.includes(Constants.Roles.FinanceTier2) ||
        this.props.user?.roles.includes(Constants.Roles.FinanceTier3) ||
        this.props.user?.roles.includes(Constants.Roles.FinanceManager)
      ) {
        pages.push({
          icon: Send,
          path: '/admin/sentChecks',
          title: 'Sent Checks',
        })
        pages.push({
          icon: Drafts,
          path: '/admin/receivedChecks',
          title: 'Received Checks',
        })
      }

      if (this.props.user?.roles.includes(Constants.Roles.ProviderInfoUpdater)) {
        pages.push({
          icon: LocalHospital,
          path: '/providers',
          title: 'Providers',
        })
      }
    }

    this.state = {
      pages,
    }
  }

  public componentDidMount() {
    this.props.setCanSwitchOrganizations!(true)
  }

  public componentWillUnmount() {
    this.props.setCanSwitchOrganizations!(false)
  }

  public render() {
    return (
      <div>
        <Helmet>
          <title>Admin</title>
        </Helmet>
        <ResponsiveAppBar title="Admin" pageIcon={<Settings />} />
        <Grid container>{this.renderAdminPageCards(this.state.pages)}</Grid>
      </div>
    )
  }

  private renderAdminPageCards(pages: IAdminPage[]) {
    const { classes } = this.props

    const setPage = (page: IAdminPage) => () => {
      if (page.title == 'Workflow Administration') {
        window.open(
          process.env.REACT_APP_WORKFLOW_ADMIN_URL || 'http://localhost:4200',
          '_blank'
        )
      } else {
        this.props.routerStore!.push(page.path)
      }
    }

    return (
      <>
        {pages.map((page, index) => {
          return (
            <Grid item xs={2} key={index} className={classes.cardContainer}>
              <Card onClick={setPage(page)} className={classes.pageCard}>
                <CardContent className={classes.cardContent}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    className={classes.cardContent}
                  >
                    <Grid item>
                      <SvgIcon
                        color="primary"
                        component={page.icon}
                        className={classes.svgIcon}
                        children={[]}
                      />
                    </Grid>
                    <Grid item>{page.title}</Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </>
    )
  }
}
const InjectedAdminPages = inject<
  IStores,
  IAdminPagesProps,
  Partial<IAdminPagesProps>,
  any
>((stores: IStores) => ({
  user: stores.global.user,
}))(withStyles(styles)(AdminPages))

export default InjectedAdminPages
