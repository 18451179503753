import { Grid } from '@material-ui/core'
import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from 'src/Stores'
import WorkflowHeader from './WorkflowHeader'
import { WorkflowInboxItemHeader } from 'src/viewModels/workflow/InboxItemHeader'
import { InboxType } from 'src/utils/InboxType'
import { ICMS1500 } from 'src/Definitions'

interface IWorkflowInboxProps {
  item: JSX.Element
  headerInfo: WorkflowInboxItemHeader
  inboxType: InboxType
  icon: JSX.Element
  getClaimValuesForStaticAction?: () => Promise<ICMS1500>
}

class WorkflowInbox extends React.Component<IWorkflowInboxProps> {
  public render() {
    return (
      <>
        <WorkflowHeader
          inboxType={this.props.inboxType}
          headerInfo={this.props.headerInfo}
          icon={this.props.icon}
          getClaimValuesForStaticAction={this.props.getClaimValuesForStaticAction}
        />
        <Grid
          container
          style={{
            padding: this.props.inboxType == InboxType.Quote ? '12px' : '0px 8px',
          }}
        >
          <Grid item xs={12}>
            {this.props.item}
          </Grid>
        </Grid>
      </>
    )
  }
}

const InjectedWorkflowInbox = inject<
  IStores,
  IWorkflowInboxProps,
  Partial<IWorkflowInboxProps>,
  any
>((stores: IStores) => ({
  getClaimValuesForStaticAction: stores.cms1500.getClaimValuesForStaticAction,
}))(WorkflowInbox)

export default InjectedWorkflowInbox
