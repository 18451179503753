import { Grid } from '@material-ui/core'
import { FormikActions } from 'formik'
import _ from 'lodash'
import { inject } from 'mobx-react'
import * as React from 'react'
import * as Yup from 'yup'
import ESDialog from '../common/ESDialog'
import FreeTypeAheadField from '../common/FreeTypeAheadField/FreeTypeAheadField'
import FullWidthField from '../common/FullWidthField'
import { IContact, IProvider } from '../Definitions'
import { SwaggerResponse, TagDto } from '../generated_client'
import { IStores } from '../Stores'
import {
  createNewTag,
  getTagColor,
  getTagName,
  getTagValue,
  ITagFormValues,
} from '../Tags/TagFormValues'
import {
  createIContactFormValues,
  IContactFormValues,
  toIContact,
} from './ContactFormValues'

const ContactSchema = Yup.object().shape({
  email: Yup.string().email('Not a valid email'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  officeNumber: Yup.string(),
  title: Yup.string(),
})

interface IContactDialogProps {
  close?: () => void
  contact?: IContact
  getAllTags?: (filter: string) => void
  isLoading?: boolean
  isOpen?: boolean
  saveContact?: (contact: IContact) => Promise<void> | Promise<SwaggerResponse<void>>
  selectedProvider?: IProvider
  tags?: TagDto[]
}

class ContactDialog extends React.Component<IContactDialogProps> {
  public componentDidMount() {
    this.props.getAllTags!('')
  }
  public save = (
    values: IContactFormValues,
    formikBag: FormikActions<IContactFormValues>
  ) => {
    const contact = toIContact(values, this.props.contact)
    this.props.saveContact!(contact).finally(() => formikBag.setSubmitting(false))
  }

  public render() {
    const { close, getAllTags, isLoading, isOpen, contact, tags } = this.props
    const initialValues = createIContactFormValues(contact)

    return (
      <ESDialog
        close={close!}
        initialValues={initialValues}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={contact!.isNew ? 'Add New Contact' : 'Edit Contact'}
        validationSchema={ContactSchema}
      >
        {({ values }) => (
          <Grid
            container
            spacing={3}
            direction="column"
            style={{ marginTop: 8, marginBottom: 8 }}
          >
            <Grid container item xs={12} spacing={5} direction="row">
              <Grid item xs={4}>
                <FullWidthField
                  autoFocus
                  name="firstName"
                  label="First Name"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <FullWidthField
                  name="lastName"
                  label="Last Name"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <FullWidthField name="title" label="Title" variant="outlined" />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={5} direction="row">
              <Grid item xs={4}>
                <FullWidthField name="officeNumber" label="Office #" variant="outlined" />
              </Grid>
              <Grid item xs={4}>
                <FullWidthField name="email" label="Email" variant="outlined" />
              </Grid>
              <Grid item xs={4}>
                <FreeTypeAheadField
                  name="tags"
                  label="Tags"
                  fullWidth={true}
                  createNew={this.createNewTag(values.contactTags)}
                  getName={this.getTagName}
                  getValue={this.getTagValue}
                  getChipColor={this.getTagColor}
                  getItems={getAllTags!}
                  items={tags || []}
                  outlined
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }

  private getTagName = (t: TagDto) => {
    return getTagName(t)
  }

  private getTagValue = (t: TagDto) => {
    return getTagValue(t)
  }

  private createNewTag = (tags: ITagFormValues[]) => (t: string) => {
    return createNewTag(t, tags)
  }

  private getTagColor = (t: TagDto) => {
    return getTagColor(t)
  }
}

const InjectedContactDialog = inject<
  IStores,
  IContactDialogProps,
  Partial<IContactDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.contacts.closeDialog,
  contact: stores.contacts.selectedContact,
  getAllTags: stores.contacts.getAllTags,
  isLoading: stores.contacts.isLoading,
  isOpen: stores.contacts.isModalOpen,
  saveContact: stores.contacts.saveContact,
  selectedProvider: stores.providers.selectedProvider,
  tags: stores.contacts.tags,
}))(ContactDialog)

export default InjectedContactDialog
