import { IAnesthesiaLineItemDTO } from './AnesthesiaLineItemDTO'
import { IPhysicalTherapyLineItemDTO } from './PhysicalTherapyLineItemDTO'
import { IProfessionalComponentLineItemDTO } from './ProfessionalComponentLineItemDTO'
import { ITechnicalComponentLineItemDTO } from './TechnicalComponentLineItemDTO'
import { IMiscellaneousLineItemDTO } from './MiscellaneousLineItemDTO'
import { DocumentButtonSummaryResult } from './DocumentButtonSummaryResult'

export interface IQuoteDTO {
  id?: string
  preCertNumber: string
  patientName: string
  surgeryDate: Date
  endDate?: Date
  bundleDurationDays?: number
  dateOfBirth: Date
  bodyPartName?: string
  episodeOfCareId?: string
  episodeOfCareNumber?: number
  patientIdNumber?: string
  status: number
  type: number
  note: string
  targetMargin?: number
  targetReconciledMargin?: number
  adjustedCost?: number
  adjustedReconciledCost?: number
  isAdjustedCostOverridden: boolean
  isAdjustedReconciledCostOverridden: boolean
  adjustedCostOverriddenBy?: string
  adjustedCostOverriddenOn?: Date
  physicalTherapyLineItems?: IPhysicalTherapyLineItemDTO[]
  professionalComponentLineItems?: IProfessionalComponentLineItemDTO[]
  anesthesiaLineItems?: IAnesthesiaLineItemDTO[]
  technicalComponentLineItems?: ITechnicalComponentLineItemDTO[]
  miscellaneousLineItems?: IMiscellaneousLineItemDTO[]
  latestStatusComment?: string
  summarizedPCInvoiceLineItems?: IProfessionalComponentLineItemDTO[]
  summarizedTCInvoiceLineItems?: ITechnicalComponentLineItemDTO[]
  summarizedANInvoiceLineItems?: IAnesthesiaLineItemDTO[]
  summarizedPTInvoiceLineItems?: IPhysicalTherapyLineItemDTO[]
  claimButtons?: DocumentButtonSummaryResult[]
  dxCodes?: string
  globalInvoiceId?: string
  initialInvoicePrintDate?: Date
  invoiceRegeneratedAfterPrint?: boolean
  episodeOfCareOrLegacyId?: string
  referralId?: string
  takeoverOfCareDate?: Date
}

export class QuoteDTO implements IQuoteDTO {
  public id?: string
  public preCertNumber: string
  public patientName: string
  public surgeryDate: Date
  public endDate?: Date
  public bundleDurationDays?: number
  public dateOfBirth: Date
  public bodyPartName?: string
  public episodeOfCareId?: string
  public episodeOfCareNumber?: number
  public patientIdNumber?: string
  public status: number
  public type: number
  public note: string
  public targetMargin?: number
  public targetReconciledMargin?: number
  public adjustedCost?: number
  public adjustedReconciledCost?: number
  public isAdjustedCostOverridden: boolean
  public isAdjustedReconciledCostOverridden: boolean
  public adjustedCostOverriddenBy?: string
  public adjustedCostOverriddenOn?: Date
  public physicalTherapyLineItems?: IPhysicalTherapyLineItemDTO[]
  public professionalComponentLineItems?: IProfessionalComponentLineItemDTO[]
  public anesthesiaLineItems?: IAnesthesiaLineItemDTO[]
  public technicalComponentLineItems?: ITechnicalComponentLineItemDTO[]
  public miscellaneousLineItems?: IMiscellaneousLineItemDTO[]
  public latestStatusComment?: string
  public summarizedPCInvoiceLineItems?: IProfessionalComponentLineItemDTO[]
  public summarizedTCInvoiceLineItems?: ITechnicalComponentLineItemDTO[]
  public summarizedANInvoiceLineItems?: IAnesthesiaLineItemDTO[]
  public summarizedPTInvoiceLineItems?: IPhysicalTherapyLineItemDTO[]
  public claimButtons?: DocumentButtonSummaryResult[]
  public dxCodes?: string
  public globalInvoiceId?: string
  public initialInvoicePrintDate?: Date
  public invoiceRegeneratedAfterPrint?: boolean
  public episodeOfCareOrLegacyId?: string
  public referralId?: string
  public takeoverOfCareDate?: Date

  constructor(
    note: string,
    isAdjustedCostOverridden: boolean,
    targetMargin?: number,
    adjustedCost?: number,
    targetReconciledMargin?: number,
    adjustedReconciledCost?: number,
    adjustedCostOverriddenBy?: string,
    adjustedCostOverriddenOn?: Date,
    physicalTherapyLineItems?: IPhysicalTherapyLineItemDTO[],
    professionalComponentLineItems?: IProfessionalComponentLineItemDTO[],
    anesthesiaLineItems?: IAnesthesiaLineItemDTO[],
    technicalComponentLineItems?: ITechnicalComponentLineItemDTO[],
    miscellaneousLineItems?: IMiscellaneousLineItemDTO[],
    claimButtons?: DocumentButtonSummaryResult[],
    dxCodes?: string,
    globalInvoiceId?: string,
    initialInvoicePrintDate?: Date,
    invoiceRegeneratedAfterPrint?: boolean,
    episodeOfCareOrLegacyId?: string,
    endDate?: Date,
    bundleDurationDays?: number
  ) {
    this.note = note
    this.targetMargin = targetMargin
    this.targetReconciledMargin = targetReconciledMargin
    this.adjustedCost = adjustedCost
    this.adjustedReconciledCost = adjustedReconciledCost
    this.isAdjustedCostOverridden = isAdjustedCostOverridden
    this.adjustedCostOverriddenBy = adjustedCostOverriddenBy
    this.adjustedCostOverriddenOn = adjustedCostOverriddenOn
    this.physicalTherapyLineItems = physicalTherapyLineItems
    this.professionalComponentLineItems = professionalComponentLineItems
    this.anesthesiaLineItems = anesthesiaLineItems
    this.technicalComponentLineItems = technicalComponentLineItems
    this.miscellaneousLineItems = miscellaneousLineItems
    this.claimButtons = claimButtons
    this.dxCodes = dxCodes
    this.globalInvoiceId = globalInvoiceId
    this.initialInvoicePrintDate = initialInvoicePrintDate
    this.invoiceRegeneratedAfterPrint = invoiceRegeneratedAfterPrint
    this.episodeOfCareOrLegacyId = episodeOfCareOrLegacyId
    this.endDate = endDate
    this.bundleDurationDays = bundleDurationDays
  }
}
