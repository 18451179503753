import {
  Button,
  createStyles,
  Grid,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { Business } from '@material-ui/icons'
import { Form, Formik, FormikActions } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Prompt, RouteComponentProps } from 'react-router-dom'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { noWhitespaceString } from '../common/YupExtensions'
import { ContactSchema } from '../contacts/ContactFormValues'
import { IEmployer } from '../Definitions'
import { IStores } from '../Stores'
import * as Yup from 'yup'
import EmployerContacts from './EmployerContacts'
import EmployerDetails from './EmployerDetails'
import {
  createIEmployerFormValues,
  IEmployerFormValues,
  toIEmployer,
} from './EmployerFormValues'
import { Helmet } from 'react-helmet'

const EmployerSchema = Yup.object().shape({
  address: Yup.object({
    city: Yup.string(),
    line1: Yup.string(),
    line2: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
  }),
  comments: Yup.string(),
  contacts: Yup.array().of(ContactSchema),
  name: noWhitespaceString('Name is required', true),
})

const styles = ({ spacing }: Theme) =>
  createStyles({
    topOfPage: {
      marginTop: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  })

interface IEmployerEditRouteParams {
  employerId: string
}

interface IEmployerEditProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IEmployerEditRouteParams> {
  clearSelectedEmployer?: () => void
  isLoading?: boolean
  employer?: IEmployer
  loadContacts?: () => Promise<void>
  loadEmployer?: (id?: string) => Promise<void>
  rerouteToPath?: (path: string) => void
  saveEmployer?: (Employer: IEmployer) => Promise<void>
  onCancelEdit?: () => void
}

interface IEmployerEditState {
  reload: boolean
}
class EmployerEdit extends React.Component<IEmployerEditProps, IEmployerEditState> {
  constructor(props: IEmployerEditProps) {
    super(props)
    this.state = {
      reload: false,
    }
  }
  public componentDidMount() {
    ;(window as any).formikRef = React.createRef()
    let promise
    const { loadContacts, loadEmployer, match } = this.props
    if (match.params.employerId) {
      promise = loadEmployer!(match.params.employerId)
    } else {
      promise = loadEmployer!()
    }
    promise.then(() => {
      loadContacts!()
    })
  }

  public componentWillUnmount() {
    this.props.clearSelectedEmployer!()
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload }, () => {
      submitForm()
    })
  }

  public save = async (
    values: IEmployerFormValues,
    formikBag: FormikActions<IEmployerFormValues>
  ) => {
    const employer = await toIEmployer(values, this.props.employer)
    this.props.saveEmployer!(employer).finally(() => {
      formikBag.setSubmitting(false)
      if (this.state.reload) {
        this.props.rerouteToPath!(`/admin/employers/${employer.id}`)
      } else {
        this.props.onCancelEdit!()
      }
    })
  }

  public title() {
    return this.props.match.params.employerId ? 'Edit Employer' : 'Add New Employer'
  }

  public render() {
    const { classes, employer, onCancelEdit } = this.props
    const initialValues = createIEmployerFormValues(employer)
    return (
      <div>
        <Helmet>
          <title>{this.title()}</title>
        </Helmet>
        <Formik
          initialValues={initialValues}
          onSubmit={this.save}
          validationSchema={EmployerSchema}
          enableReinitialize={true}
          ref={(window as any).formikRef}
        >
          {({
            isValid,
            errors,
            setFieldValue,
            values,
            submitForm,
            dirty,
            submitCount,
          }) => (
            <>
              <Form>
                <Prompt
                  when={dirty && submitCount === 0}
                  message="Are you sure you want to leave the page? There are unsaved changes."
                />
                <ResponsiveAppBar title={this.title()} pageIcon={<Business />}>
                  <Grid container direction="row" spacing={3} justifyContent="flex-end">
                    <Grid item>
                      <Button size="small" variant="contained" onClick={onCancelEdit!}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={this.handleSave(submitForm, true)}
                        disabled={!isValid}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        disabled={!isValid}
                        onClick={this.handleSave(submitForm, false)}
                      >
                        Save and Close
                      </Button>
                    </Grid>
                  </Grid>
                </ResponsiveAppBar>
                <Grid
                  container
                  item
                  direction="row"
                  className={classes.topOfPage}
                  spacing={3}
                >
                  <Grid item xs={6} style={{ padding: 8 }}>
                    <EmployerDetails
                      errors={errors}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {this.isEditing && <EmployerContacts />}
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </div>
    )
  }

  public get isEditing() {
    return !!this.props.match.params.employerId
  }
}

const InjectedEmployerEdit = inject<
  IStores,
  IEmployerEditProps,
  Partial<IEmployerEditProps>,
  any
>((stores: IStores) => ({
  clearSelectedEmployer: stores.employers.clearSelectedEmployer,
  employer: stores.employers.selectedEmployer,
  isLoading: stores.employers.isLoading,
  loadContacts: stores.contacts.getColumnSettingsAndContacts,
  loadEmployer: stores.employers.loadEmployer,
  onCancelEdit: stores.employers.employerEditFinished,
  rerouteToPath: stores.global.rerouteToPath,
  saveEmployer: stores.employers.saveEmployer,
}))(EmployerEdit)

export default withStyles(styles)(InjectedEmployerEdit)
