import { Grid } from '@material-ui/core'
import { Component } from 'react'
import React from 'react'

interface ICustomGridTimeProps {
  time: string | undefined
  isTimeApplicable?: boolean | true
}

export default class CustomGridTime extends Component<ICustomGridTimeProps> {
  public render() {
    const { time, isTimeApplicable } = this.props
    return <Grid container>{isTimeApplicable ? (time ? time : '') : 'N/A'}</Grid>
  }
}
