export enum DocumentType {
  //Authorization
  DME,
  PhysicalTherapy,
  OccupationalTherapy,
  InHome,
  InpatientRehab,
  WorkConditioning,
  FCE,
  SurgeryFacility,
  InitialEval,
  TakeoverOfCare,

  //Global Invoice
  InvoiceInitial,
  InvoiceResend,

  //Claim Types
  BillOut1500,

  //EOB
  ExplanationOfBenefits,

  //Contracts
  PatientSurgicalContract,
  PatientInjectionContract,
}
