import CustomMaskedInput from './CustomMaskedInput'

const ZipCodeMask = CustomMaskedInput([
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
])
export default ZipCodeMask
