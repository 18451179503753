import { AttachMoney, Receipt } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { retrieveLineItems } from 'src/services/BillOutService'
import { ILineItemSummaryResult } from 'src/viewModels/LineItemSummaryResult'
import { IPaymentLineItem, PaymentLineItem } from 'src/viewModels/PaymentLIneItem'
import { DefaultCMS1500, ICMS1500 } from '../../Definitions'
import { Client, SwaggerResponse } from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import createPdfObjUrl from '../../utils/CreatePdfObjUrl'
import DataTableStore from '../DataTableStore'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import { GetBillOutInsuranceCompanyOptions } from 'src/services/LookupService'
import { CMS1500Dto, ICMS1500Dto } from 'src/viewModels/claim/Cms1500Dto'
import { GetBilloutDetailsByClaim } from 'src/services/WorkflowService'
import { IBilloutDetailSummary } from 'src/viewModels/workflow/BilloutDetailSummary'
import { CMS1500DiagnosisDto } from 'src/viewModels/claim/CMS1500DiagnosisDto'

const ColumnSettingsDto = 'BillOutDto'

export default class BillOutStore {
  @observable
  public updateParentStateAfterModal?: () => void
  @observable
  public isLoading: boolean = false
  @observable
  public isBillStatusLoading: boolean = false
  @observable
  public cms1500: ICMS1500[]
  @observable
  public selectedCMS1500?: ICMS1500
  @observable
  public selectedListItems?: IPaymentLineItem[]
  @observable
  public selectedDiagnosisCodes?: CMS1500DiagnosisDto[]
  @observable
  public typeFilter?: string
  @observable
  public showBilledOut: boolean = false
  @observable
  public showPaid: boolean = false
  @observable
  public isOpen: boolean = false
  @observable
  public isBillStatusDialogOpen: boolean = false
  @observable
  public isBillOutNotesModalOpen?: boolean = false
  @observable
  public isCMS1500Saved: boolean = false
  @observable
  public contractAmountTotal: number = 0
  @observable
  public physicalTherapyVisits: number[]
  @observable
  public pageSize: number = 5
  public dataTableStore: DataTableStore<ICMS1500Dto, ICMS1500>
  @observable
  public dismissIsOpen: boolean = false
  @observable
  public insuranceList?: DropdownOption[]

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<ICMS1500Dto, ICMS1500>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllCMS1500(
          filter,
          this.globalStore.currentAppOrganization
            ? this.globalStore.currentAppOrganization.id
            : undefined,
          undefined,
          undefined,
          undefined,
          this.typeFilter ? (this.typeFilter === 'Non-Surgical' ? 1 : 2) : 5,
          this.showBilledOut,
          this.showPaid,
          undefined,
          undefined,
          undefined,
          page,
          this.pageSize,
          orderBy,
          includeInactives
        ),
      (cms1500) => this.setupBillOutCMS1500(cms1500)
    )
  }

  @action.bound
  public setParentUpdateFunction(parentFunction: () => void) {
    this.updateParentStateAfterModal = parentFunction
  }

  @action.bound
  public setTypeFilter(type?: string) {
    this.typeFilter = type
    this.dataTableStore.loadData()
  }

  @action.bound
  public setShowBilledOut(showBilledOut: boolean) {
    this.showBilledOut = showBilledOut
    this.dataTableStore.loadData()
  }

  @action.bound
  public setShowPaid(showPaid: boolean) {
    this.showPaid = showPaid
    this.dataTableStore.loadData()
  }

  @action.bound
  public closeDialog = () => {
    this.isOpen = false
  }

  @action.bound
  public closeBillStatusDialog = () => {
    this.isOpen = false
    this.isBillStatusDialogOpen = false
    if (this.updateParentStateAfterModal) {
      this.updateParentStateAfterModal()
    }
  }

  @action.bound
  public closeDismissClaimModal = () => {
    this.dismissIsOpen = false
    if (this.updateParentStateAfterModal) {
      this.updateParentStateAfterModal()
    }
  }

  @action.bound
  public openDismissClaimModal = () => {
    this.dismissIsOpen = true
  }

  @action.bound
  public loadCMS1500 = async (cms1500Id: string) => {
    this.isLoading = true
    var result = { id: cms1500Id } as CMS1500Dto
    this.selectedCMS1500 = this.addDefaultFields(result)
    this.isOpen = true
    this.loadLineItemDetails(cms1500Id).finally(() => {
      this.isLoading = false
    })
  }

  @action.bound
  public loadBilloutSummaryDetails = async (cms1500Id: string) => {
    this.isLoading = true
    await GetBilloutDetailsByClaim(cms1500Id)
      .then((response: IBilloutDetailSummary) => {
        this.selectedListItems = response.lineItemDistributionDetails.map(
          (item) => new PaymentLineItem(item)
        )
        this.selectedDiagnosisCodes = response.diagnosisCodes
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public loadLineItemDetails = async (cms1500Id: string) => {
    this.isLoading = true
    await retrieveLineItems(cms1500Id)
      .then((response: ILineItemSummaryResult[]) => {
        this.selectedListItems = response.map((item) => new PaymentLineItem(item))
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public openDialog(selectedCMS1500: ICMS1500) {
    this.selectedCMS1500 = selectedCMS1500
    this.isOpen = true
  }

  @action.bound
  public openBillStatusDialog() {
    this.isBillStatusDialogOpen = true
  }

  @action.bound
  public openBillOutNotesModal() {
    this.isBillOutNotesModalOpen = true
  }

  @action.bound
  public closeBillOutNotesModal() {
    this.isBillOutNotesModalOpen = false
  }

  @action.bound
  public setIsCMS1500Saved(isSaved: boolean) {
    this.isCMS1500Saved = isSaved
  }

  @action.bound
  public createOutgoingCMS1500(cms1500: ICMS1500) {
    this.isLoading = true
    return this.client
      .createOutgoingCMS1500(cms1500.id, new CMS1500Dto(cms1500))
      .then((resp: SwaggerResponse<string>) => {
        runInAction(() => {
          window.open(createPdfObjUrl(resp.result), '_blank')
        })
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public updateCMS1500Status(cms1500Id: string, newStatus: string) {
    this.isBillStatusLoading = true
    return this.client
      .updateCMS1500status(cms1500Id, newStatus)
      .finally(() => (this.isBillStatusLoading = false))
  }

  @action.bound
  public getColumnSettingsAndBillOutCMS1500() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  private setupBillOutCMS1500 = (cms1500: ICMS1500Dto[]) => {
    return cms1500.map((x) => this.setupBillOutCMS1500MenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (cms1500: ICMS1500Dto): ICMS1500 => {
    return { ...DefaultCMS1500(), ...cms1500 }
  }

  private setupBillOutCMS1500MenuItems = (cms1500: ICMS1500) => {
    cms1500.menuItems = []
    cms1500.menuItems.push({
      color: '#94D33D',
      icon: AttachMoney,
      isConfirm: false,
      name: 'Create 1500',
      onClick: () => this.openDialog(cms1500),
    })
    cms1500.menuItems.push({
      color: '#181B55',
      icon: Receipt,
      isConfirm: false,
      name: 'Revert to New',
      onClick: () => {},
    })
    return cms1500
  }

  @action.bound
  public getInsuranceDropdown() {
    GetBillOutInsuranceCompanyOptions().then((result) => {
      this.insuranceList = result
    })
  }
}
