import { FieldProps, getIn } from 'formik'
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from 'material-ui-pickers'
import moment from 'moment'
import * as React from 'react'

export type AllKeys<T> = T extends T ? keyof T : never
export type Omit<T, K extends AllKeys<T>> = T extends T
  ? Pick<T, Exclude<keyof T, K>>
  : never

export type DatePickerProps = FieldProps &
  Omit<MuiDatePickerProps, 'error' | 'name' | 'onChange' | 'value'>

export const fieldToDatePicker = ({
  field,
  form,
  disabled = false,
  ...props
}: DatePickerProps): MuiDatePickerProps => {
  const { name } = field
  const { touched, errors, isSubmitting } = form
  const fieldError = getIn(errors, name)
  const showError = getIn(touched, name) && !!fieldError
  const newOnChange = (e: moment.Moment) => {
    form.setFieldValue(name, e ? e.startOf('day').toDate() : undefined)
  }

  const mask = (value: string) => {
    return value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
  }
  return {
    ...props,
    ...field,
    clearable: props.clearable,
    defaultValue: undefined,
    disabled: isSubmitting || disabled,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    keyboard: true,
    mask,
    onChange: newOnChange,
    value: field.value ? field.value : null,
  }
}

export const DatePicker: React.ComponentType<DatePickerProps> = ({
  children,
  ...props
}: DatePickerProps) => <MuiDatePicker {...fieldToDatePicker(props)} children={children} />

MuiDatePicker.displayName = 'FormikMaterialUIDatePicker'
