import { createStyles, Paper, Theme, WithStyles, withStyles } from '@material-ui/core'
import { Person } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ContactEditDialog from '../contacts/ContactEditDialog'
import { DefaultContact, IContact } from '../Definitions'
import { IStores } from '../Stores'

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      paddingTop: spacing(2),
    },
  })

interface IEmployerContactsProps extends WithStyles<typeof styles> {
  closeDialog?: () => void
  createContact?: (contact: IContact, title?: string) => void
  dataTableManager?: IDataTableManager<IContact>
}

class EmployerContacts extends React.Component<IEmployerContactsProps> {
  constructor(props: IEmployerContactsProps) {
    super(props)
  }

  public render() {
    const { classes, dataTableManager } = this.props

    return (
      <Paper className={classes.paper}>
        <ESDataTableWithHeader
          dataTableManager={dataTableManager!}
          title={'Contacts'}
          icon={Person}
          addButtonOnClick={this.openDialog}
          elevation={0}
          enableShowInactives
          padding={0}
          rowsPerPage={5}
        />
        <ContactEditDialog />
      </Paper>
    )
  }

  private openDialog = () => {
    this.props.createContact!(DefaultContact(true), 'Add New Employer Contact')
  }
}

const InjectedEmployerContacts = inject<
  IStores,
  IEmployerContactsProps,
  Partial<IEmployerContactsProps>,
  any
>((stores: IStores) => ({
  closeDialog: stores.contacts.closeDialog,
  createContact: stores.contacts.openDialogWithContact,
  dataTableManager: stores.contacts.dataTableStore,
}))(EmployerContacts)

export default withStyles(styles)(InjectedEmployerContacts)
