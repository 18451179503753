import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  Typography,
} from '@material-ui/core'
import { Info } from '@material-ui/icons'
import moment from 'moment'
import React from 'react'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { voidSentCheck } from 'src/services/CheckService'
import { formatCurrency } from 'src/utils/Formatter'
import { SentCheckSummaryResult } from 'src/viewModels/SentCheckSummaryResult'

interface IVoidCheckModal extends DialogProps {
  close: (reload: boolean) => void
  check: SentCheckSummaryResult
}

class VoidCheckModal extends React.Component<IVoidCheckModal> {
  public state = {
    voidCheckProcessing: false,
  }

  private voidCheck = (checkId: string) => {
    voidSentCheck(checkId)
      .then(() => {
        this.props.close(true)
      })
      .finally(() => {
        this.setState({ voidCheckProcessing: false })
      })
  }

  public render() {
    const { close, check, ...modalProps } = this.props

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => close(false)}
        {...modalProps}
        disableBackdropClick
      >
        {this.state.voidCheckProcessing && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1200',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <ResponsiveAppBar
          title={`Void Check - Check Number: ${check.checkNumber}, Date: ${moment(
            check.checkDate
          ).format('MM/DD/YYYY')}, Amount: ${formatCurrency(
            check.checkAmount.toString()
          )}`}
          pageIcon={<Info />}
          isModal
        />
        <DialogContent style={{ marginTop: 10 }}>
          <Typography variant="body1">
            Are you sure you want to void this check?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button onClick={() => close(false)}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  this.setState({ voidCheckProcessing: true })
                  this.voidCheck(check.checkId)
                }}
                color="primary"
                variant="contained"
                disabled={this.state.voidCheckProcessing}
              >
                Void
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default VoidCheckModal
