import { SwaggerResponse } from './generated_client'

export class ClientBase {
  constructor() {}

  public async transformResult(
    _url: string,
    response: Response,
    result: (response: any) => Promise<SwaggerResponse<any>>
  ): Promise<SwaggerResponse<any>> {
    const r = await result(response)
    return {
      ...r,
    }
  }
}
