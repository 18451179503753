import Axios from 'axios'
import { ISearchRequestBase } from 'src/viewModels/SearchRequestBase'
import { IPatientSearchRequest } from 'src/viewModels/PatientSearchRequest'
import { SharedDrivePathRequest } from 'src/viewModels/SharedDrivePathRequest'
import { pagedList } from 'src/viewModels/pagedList'
import { IDocumentSearchRequest } from 'src/viewModels/DocumentSearchRequest'
import { PatientDocumentDTO } from 'src/viewModels/PatientDocumentDTO'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function GetLegacySharedDrivePath(
  req: SharedDrivePathRequest
): Promise<string> {
  const response = await Axios.post<string>(`${base_url}/api/patient/path`, req)
  return response.data
}
export async function GetSharedDrivePath(
  patientId: string,
  req: SharedDrivePathRequest
): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/patient/${patientId}/path`,
    req
  )
  return response.data
}

export async function searchPatients(req: IPatientSearchRequest): Promise<pagedList> {
  const response = await Axios.post<pagedList>(`${base_url}/api/patient/search`, req)

  return response.data
}

export async function getEpisodesOfCareByPatientId(
  req: ISearchRequestBase,
  id: string
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/patient/${id}/episodes-of-care`,
    req
  )

  return response.data
}

export async function searchDocuments(
  patientId: string,
  req: IDocumentSearchRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/patient/${patientId}/documents`,
    req
  )

  return response.data
}

export async function deletePatientDocument(
  patientId: string,
  documentId: string
): Promise<any> {
  const response = await Axios.delete<any>(
    `${base_url}/api/patient/${patientId}/documents/${documentId}`
  )

  return response.data
}

export async function UploadDocument(req: PatientDocumentDTO): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/patient/${req.patientId}/upload`,
    req
  )

  return response.data
}

export async function searchReferrals(
  patientId: string,
  data: ISearchRequestBase
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/patient/${patientId}/referrals`,
    data
  )

  return response.data
}
