import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Receipt } from '@material-ui/icons'
import React from 'react'
import ReactPaginate from 'react-paginate'
import { pagedList } from 'src/viewModels/pagedList'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { GetBilloutLogByBilloutId } from 'src/services/BillOutService'
import moment from 'moment'
import { formatCurrency } from 'src/utils/Formatter'
import { inject } from 'mobx-react'
import { BilloutLogSearchRequest } from 'src/viewModels/BilloutLogSearchRequest'
import { BilloutLogDTO } from 'src/viewModels/BilloutLogDTO'
import { ClaimHeaderDetailsSummaryResult } from 'src/viewModels/ClaimHeaderDetailsSummaryResult'
import { IStores } from '../Stores'
import { IDismissClaimModal } from 'src/billOut/DismissClaimModal'
interface IBilloutLogModalProps {
  cms1500Id: string
  groupInfo: string
  patientHeader?: ClaimHeaderDetailsSummaryResult
  isOpen: boolean
  closeModal: () => void
}
class BilloutLogModal extends React.Component<IBilloutLogModalProps> {
  public componentDidMount(): void {
    this.getBilloutLogData()
  }
  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }
  private getBilloutLogData = async () => {
    const { page, pageSize } = this.state
    const cms1500Id = this.props.cms1500Id
    const data: BilloutLogSearchRequest = {
      cms1500Id,
      page,
      pageSize,
    }
    await GetBilloutLogByBilloutId(this.props.cms1500Id, data).then((response: any) => {
      this.setState({
        gridData: response.result.items,
        pageCount: response.result.totalPages,
        totalItems: response.result.totalItems,
      })
      if (response.result.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }
  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getBilloutLogData())
  }
  private isGridDataEmpty = () => {
    return this.state.totalItems === 0
  }
  private mapLogGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          <TableCell>{moment(record.createdDate).format('MM/DD/YYYY')}</TableCell>
          <TableCell>{record.createdByName}</TableCell>
          <TableCell align="right">
            {record.billoutTotal != undefined
              ? formatCurrency(record.billoutTotal.toString())
              : ''}
          </TableCell>
          <TableCell align="right">
            {record.totalAmountPaidToDate != undefined
              ? formatCurrency(record.totalAmountPaidToDate.toString())
              : ''}
          </TableCell>
        </TableRow>
      ))
    }
  }
  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }
  public state = {
    gridData: new pagedList().items as BilloutLogDTO[],
    page: 1,
    pageCount: 1,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 5,
    cms1500Id: this.props.cms1500Id,
  }
  public render() {
    return (
      <>
        <Dialog
          key={this.props.cms1500Id}
          fullWidth={true}
          maxWidth="md"
          open={this.props.isOpen!}
        >
          <DialogTitle style={{ padding: 0 }}>
            <ResponsiveAppBar
              title={
                'Bill Out Log ' +
                (this.props.patientHeader?.patientLast != undefined
                  ? '- ' +
                    this.props.patientHeader?.patientLast +
                    ', ' +
                    this.props.patientHeader?.patientFirst +
                    (this.props.patientHeader?.dateOfBirth
                      ? ' (' +
                        moment(this.props.patientHeader?.dateOfBirth).format(
                          'MM/DD/YYYY'
                        ) +
                        ')'
                      : '') +
                    ' ' +
                    this.props.groupInfo
                  : '')
              }
              pageIcon={<Receipt />}
              isModal={true}
            />
          </DialogTitle>
          <DialogContent>
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              alignContent="center"
              alignItems="flex-start"
              wrap="wrap"
            >
              <Grid item xs={12} style={{ minWidth: '750px' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className="gridPadding">
                      <TableCell>Created Date</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell align="right">Bill Out Total</TableCell>
                      <TableCell align="right">Total Amount Paid to Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ width: '100%' }}>{this.mapLogGridData()}</TableBody>
                </Table>
                <div className="pagination-row">
                  {this.paginationInfo()}
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    onPageChange={this.handlePageChange}
                    forcePage={this.state.page - 1}
                    pageCount={this.state.pageCount}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    //@ts-ignore
                    renderOnZeroPageCount={null}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeModal} data-cy="close">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}
const InjectedBilloutLogModal = inject<
  IStores,
  IBilloutLogModalProps,
  Partial<IDismissClaimModal>,
  any
>((stores: IStores) => ({
  close: stores.billOutStore.closeDialog,
  closeBillStatusDialog: stores.billOutStore.closeBillStatusDialog,
  cms1500: stores.billOutStore.selectedCMS1500,
  lineItemDetails: stores.billOutStore.selectedListItems,
  createOutgoingCMS1500: stores.billOutStore.createOutgoingCMS1500,
  isBillStatusDialogOpen: stores.billOutStore.isBillStatusDialogOpen,
  isCMS1500Saved: stores.billOutStore.isCMS1500Saved,
  isLoading: stores.billOutStore.isLoading,
  openBillOutNotesModal: stores.billOutStore.openBillOutNotesModal,
  openBillStatusDialog: stores.billOutStore.openBillStatusDialog,
  setIsCMS1500Saved: stores.billOutStore.setIsCMS1500Saved,
  patientHeader: stores.patients.headerDetails,
}))(BilloutLogModal)

export default InjectedBilloutLogModal
