import * as React from 'react'

import { ListItem, ListItemIcon, Tooltip } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const styles = ({ spacing }: Theme) =>
  createStyles({
    iconCollapsed: { marginLeft: spacing(1), width: spacing(5) },
    iconExpanded: { mariginRight: spacing(2), width: spacing(5) },
    listItem: { paddingLeft: spacing(2), paddingRight: spacing(2) },
  })

interface IExpandableListItemProps extends WithStyles<typeof styles> {
  expanded: boolean
  disabled?: boolean
  icon: React.ReactElement<SvgIconProps>
  text: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

class ExpandableListItem extends React.Component<IExpandableListItemProps> {
  public render() {
    const { classes, disabled, expanded, icon, onClick, text } = this.props
    const displayText = expanded ? text : null
    const toolTipText = expanded ? null : text
    const iconClass = expanded ? classes.iconExpanded : classes.iconCollapsed
    return (
      <ListItem button className={classes.listItem} onClick={onClick} disabled={disabled}>
        <Tooltip
          title={toolTipText || ''}
          placement="right-start"
          disableFocusListener={expanded}
          disableHoverListener={expanded}
          disableTouchListener={expanded}
        >
          <ListItemIcon style={{ minWidth: '0' }} className={iconClass}>
            {icon}
          </ListItemIcon>
        </Tooltip>
        <span>{displayText}</span>
      </ListItem>
    )
  }
}

export default withStyles(styles)(ExpandableListItem)
export { IExpandableListItemProps }
