import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog'
import { inject } from 'mobx-react'
import * as React from 'react'
import { IClaimFormValues } from '../../ReceiveClaim/ClaimFormValues'
import { IStores } from '../../Stores'
import { SwaggerResponse } from 'src/generated_client'

interface IChangeBillStatusDialogProps extends DialogProps {
  closeBillStatusDialog?: () => void
  cms1500: IClaimFormValues
  isBillStatusDialogOpen?: boolean
  isBillStatusLoading?: boolean
  updateCMS1500Status?: (
    cms1500Id: string,
    newStatus: string
  ) => Promise<SwaggerResponse<void>>
}

class ChangeBillStatusDialog extends React.Component<IChangeBillStatusDialogProps> {
  public render() {
    const { isBillStatusDialogOpen, cms1500 } = this.props
    const newStatus = cms1500.createZeroDollarBill ? 'Paid' : 'Billed Out'
    return (
      <Dialog open={isBillStatusDialogOpen!}>
        <DialogTitle>{<>Update Status?</>}</DialogTitle>
        <DialogContent>
          {`Do you want to update the status of this bill to ${newStatus}`}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close">
            Not Now
          </Button>
          <Button
            onClick={this.confirm(newStatus)}
            data-cy="close"
            color="primary"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private close = () => {
    this.props.closeBillStatusDialog!()
  }

  private confirm = (newStatus: string) => () => {
    this.props.updateCMS1500Status!(this.props.cms1500.id, newStatus).then(() => {
      this.props.closeBillStatusDialog!()
    })
  }
}

const InjectedChangeBillStatusDialog = inject<
  IStores,
  IChangeBillStatusDialogProps,
  Partial<IChangeBillStatusDialogProps>,
  any
>((stores: IStores) => ({
  closeBillStatusDialog: stores.billOutStore.closeBillStatusDialog,
  isBillStatusDialogOpen: stores.billOutStore.isBillStatusDialogOpen,
  isBillStatusLoading: stores.billOutStore.isBillStatusLoading,
  updateCMS1500Status: stores.billOutStore.updateCMS1500Status,
}))(ChangeBillStatusDialog)

export default InjectedChangeBillStatusDialog
