import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultCheck, ICheck } from '../../Definitions'
import { CheckDto, Client, ICheckDto, SwaggerResponse } from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import createPdfObjUrl from '../../utils/CreatePdfObjUrl'
import DataTableStore from '../DataTableStore'
const ColumnSettingsDto = 'CheckDto'

export default class ChecksStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public checks: ICheck[]
  @observable
  public selectedCheck?: ICheckDto
  public dataTableStore: DataTableStore<ICheckDto, ICheck>

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<ICheckDto, ICheck>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllChecks(filter, page, undefined, orderBy, includeInactives),
      (check) => this.setupChecks(check)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedCheck = undefined
  }

  @action.bound
  public disableCheck(checkId: string, loadData: boolean = true) {
    this.isLoading = true
    return this.client
      .archiveCheck(checkId)
      .then(() => {
        if (loadData) {
          this.dataTableStore.loadData()
        }
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public previewEOB(check: ICheck) {
    return this.client
      .previewEOB(new CheckDto(check))
      .then((resp: SwaggerResponse<string>) => {
        runInAction(() => {
          window.open(createPdfObjUrl(resp.result), '_blank')
        })
      })
  }

  @action.bound
  public getColumnSettingsAndChecks() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllChecks() {
    this.isLoading = true
    return this.client.getAllChecks().then((resp: SwaggerResponse<ICheckDto[]>) =>
      runInAction(() => {
        this.checks = this.setupChecks(resp.result)
      })
    )
  }

  @action.bound
  public openDialogWithCheck(check: ICheck) {
    this.isModalOpen = true
    this.selectedCheck = check
  }

  @action.bound
  public recoverCheck(check: ICheck) {
    this.isLoading = true
    return this.client
      .reactivateCheck(check.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveCheck(check: ICheck) {
    this.isLoading = true
    let promise
    const checkDto = new CheckDto(check)
    if (check.isNew) {
      promise = this.client.createCheck(checkDto)
    } else {
      promise = this.client.updateCheck(check.id, checkDto)
    }
    return promise
      .then(() => {
        this.resetUIState(check.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupChecks = (checks: ICheckDto[]) => {
    return checks.map((x) => this.setupCheckMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (check: ICheckDto): ICheck => {
    return { ...DefaultCheck(), ...check }
  }

  private setupCheckMenuItems = (check: ICheck) => {
    check.menuItems = []
    if (check.isActive) {
      check.menuItems.push({
        isConfirm: true,
        name: 'Disable',
        onClick: () => this.disableCheck(check.id),
      })
    } else {
      check.menuItems.push({
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverCheck(check),
      })
    }
    return check
  }
}
