import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React, { ChangeEvent, useEffect, useState } from 'react'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { inject } from 'mobx-react'
import { IStores } from 'src/Stores'
import { Formik } from 'formik'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import _ from 'lodash'
import { IEpisodeOfCare, ILocation, ISchedule } from 'src/Definitions'
import SimpleEventDialog from 'src/ReceiveClaim/SimpleEventDialog'
import ReceiveClaimProviderLookupDialog from 'src/ReceiveClaim/ReceiveClaimProviderLookupDialog'
import { Refresh } from 'mdi-material-ui'
import { processAction } from 'src/services/WorkflowService'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import { getEpisodesOfCareByPatientId, searchPatients } from 'src/services/PatientService'
import { SearchRequestBase } from 'src/viewModels/SearchRequestBase'
import { EpisodeOfCareSummaryResult } from 'src/viewModels/EpisodeOfCareSummaryResult'
import { ObjectiveProcessAction } from 'src/viewModels/workflow/ObjectiveProcessAction'
import { WorkflowInboxItemHeader } from 'src/viewModels/workflow/InboxItemHeader'
import moment from 'moment'
import { ClaimEventSummary } from 'src/viewModels/workflow/ClaimEventSummary'
import { PatientSearchRequest } from 'src/viewModels/PatientSearchRequest'
import { PatientSummaryResult } from 'src/viewModels/PatientSummaryResult'
import ReactPaginate from 'react-paginate'

interface IAssociatePatientEOCModalProps {
  isOpen: boolean
  closeModal: () => void
  isLoading?: boolean
  setSelectedLocation?: (location?: ILocation) => void
  setSelectedSchedule?: (schedule?: ISchedule) => void
  setSelectedScheduleById?: (id: string) => Promise<void>
  selectedSchedule?: ISchedule
  openDialogWithSchedule?: (schedule?: ISchedule) => void
  openProviderLookupDialog?: () => void
  isProviderLookupDialogOpen?: boolean
  scheduleItems?: DropdownOption[]
  locationItems?: DropdownOption[]
  episodeOfCare?: IEpisodeOfCare
  getEpisodeOfCareById?: (id: string) => Promise<void>
  action: InboxActionSummary
  inboxItemId: number
  updateClaim?: boolean
  rerouteToPath?: (path: string) => void
  getSchedules?: () => Promise<void>
  closeTab?: () => void
}

function AssociatePatientEOCModal(props: IAssociatePatientEOCModalProps) {
  const [selectedEOCId, setSelectedEOCId] = useState<string>()
  const [modalPage, setModalPage] = useState(1)
  const [showHistoricalModal, setShowHistoricalModal] = useState(false)
  const [disableProvider, setDisableProvider] = useState(true)
  const [disableDropdown, setDisableDropdown] = useState(true)
  const [hasSelectedSchedule, setHasSelectedSchedule] = useState(false)
  const [selectedLocationId, setselectedLocationId] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [gridData, setGridData] = useState<EpisodeOfCareSummaryResult[]>()
  const [hasPatient, setHasPatient] = useState(false)
  const [comments, setComments] = useState('')
  const [executed, setExecuted] = useState(false)
  const [patientGridData, setPatientGridData] = useState<PatientSummaryResult[]>()
  const [searchPatientLastName, setSearchPatientLastName] = useState('')
  const [searchPatientFirstName, setSearchPatientFirstName] = useState('')
  const [patientPage, setPatientPage] = useState(1)
  const [patientPageCount, setPatientPageCount] = useState(0)
  const [patientTotalItems, setPatientTotalItems] = useState(0)
  const [patientFirstRecordIndex, setPatientFirstRecordIndex] = useState(0)
  const [patientLastRecordIndex, setPatientLastRecordIndex] = useState(0)
  const [callSearchPatients, setCallSearchPatients] = useState(false)

  //Wait until grid data is set to calculate pagination offset and stop the spinner
  useEffect(() => {
    if (patientTotalItems > 0) {
      setPaginationOffsetData()
    }
    setIsLoading(false)
  }, [patientGridData])

  //Wait until page is set to call the API. And don't call the API for clearSearch or initial mount
  useEffect(() => {
    if (!callSearchPatients) {
      return
    }
    getPatientData()
  }, [patientPage, callSearchPatients])

  const patientPageSize = 5

  async function save(modalLocationId?: string, newComments?: string) {
    var action = props.action

    var data = new ObjectiveProcessAction()
    var summary = new ClaimEventSummary()

    summary.episodeOfCareId = selectedEOCId ?? ''
    summary.locationId = modalLocationId ?? selectedLocationId ?? ''
    summary.scheduleId = props.selectedSchedule?.id ?? ''

    data.claimEventData = summary
    data.comment = newComments ?? comments

    processAction(data, action.id.toString(), props.inboxItemId.toString())
      .then((response: WorkflowInboxItemHeader) => {
        if (action?.closeAfterProcessing && response.nextAssociatedItem == undefined) {
          props.closeTab!()
        } else {
          if (props.updateClaim) {
            window.location.reload()
          } else if (
            !action?.closeAfterProcessing &&
            response.nextAssociatedItem.inboxId == undefined
          ) {
            props.rerouteToPath!(
              `/workflow/claim/${response.nextAssociatedItem.itemId}/default-inbox`
            )
          } else {
            props.rerouteToPath!(
              `/workflow/claim/${response.nextAssociatedItem.itemId}/inbox/${response.nextAssociatedItem.inboxId}`
            )
          }
        }
      })
      .catch(() => {
        setExecuted(false)
        setIsLoading(false)
        close()
      })
  }

  function close() {
    props.closeModal()
    setModalPage(1)
    setGridData(undefined)
    setHasPatient(false)
    setExecuted(false)
    clearSearch()
  }

  function search(event: any) {
    setCallSearchPatients(true)
    setPatientPage(1)
    event.preventDefault()
  }

  function clearSearch() {
    setPatientGridData(undefined)
    setSearchPatientFirstName('')
    setSearchPatientLastName('')
    setCallSearchPatients(false)
    setPatientPage(1)
    setPatientPageCount(0)
    setPatientTotalItems(0)
  }

  async function getPatientData() {
    setIsLoading(true)
    const requestData = new PatientSearchRequest()
    requestData.page = patientPage
    requestData.pageSize = patientPageSize
    requestData.patientFirstName = searchPatientFirstName
    requestData.patientLastName = searchPatientLastName
    await searchPatients(requestData).then((response: any) => {
      setPatientTotalItems(response.totalItems)
      setPatientPageCount(response.totalPages)
      setPatientGridData(response.items)
    })
  }

  function isGridDataEmpty() {
    return patientTotalItems === 0
  }

  function paginationInfo() {
    if (!isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {patientFirstRecordIndex} to {patientLastRecordIndex} of{' '}
            {patientTotalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  function mapPatientGridData() {
    if (isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={13} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return patientGridData?.map((patient) => (
        <TableRow key={patient.patientId} className="gridPadding">
          <TableCell>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setSelectedPatientRow(patient.patientId!)}
            >
              Select
            </Button>
          </TableCell>
          <TableCell style={{ paddingLeft: '30px' }}>{patient.patientIdNumber}</TableCell>
          <TableCell>{patient.preCertNumber}</TableCell>
          <TableCell>{patient.lastName}</TableCell>
          <TableCell>{patient.firstName}</TableCell>
          <TableCell align="right">
            {patient.dateOfBirth ? moment(patient.dateOfBirth).format('MM/DD/YYYY') : ''}
          </TableCell>
        </TableRow>
      ))
    }
  }

  function setPaginationOffsetData() {
    const firstRecordIndex = (patientPage - 1) * patientPageSize + 1
    const lastRecordIndex =
      patientPage * patientPageSize < patientTotalItems
        ? patientPage * patientPageSize
        : patientTotalItems
    setPatientFirstRecordIndex(firstRecordIndex)
    setPatientLastRecordIndex(lastRecordIndex)
  }

  function handlePageChange(event: any) {
    const page = event.selected
    setCallSearchPatients(true)
    setPatientPage(page + 1)
  }

  function handlePatientFirstNameChange(event: any) {
    setSearchPatientFirstName(event.target.value)
    setCallSearchPatients(false)
  }

  function handlePatientLastNameChange(event: any) {
    setSearchPatientLastName(event.target.value)
    setCallSearchPatients(false)
  }

  const setSelectedPatientRow = (patientId: string) => {
    setHasPatient(true)
    setModalPage(2)
    setCallSearchPatients(false)
    getEOCData(patientId)
  }

  const setSelectedEOC = async (id?: string) => {
    if (id != undefined) {
      setIsLoading(true)
      setSelectedEOCId(id)
      setModalPage(3)
      await props.getEpisodeOfCareById!(id)
      await props.getSchedules!()
      setIsLoading(false)
    }
  }

  const changeSelectHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    setIsLoading(true)
    setDisableProvider(false)
    setDisableDropdown(false)
    setHasSelectedSchedule(true)
    setselectedLocationId(undefined)
    await props.setSelectedScheduleById!(event?.target.selectedOptions[0].accessKey)
    var d = document.getElementById('locationSelect') as HTMLSelectElement
    d.options[0].disabled = false
    d.selectedIndex = 0
    setIsLoading(false)
  }

  const changeLocationHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    setselectedLocationId(event?.target.selectedOptions[0].accessKey)
  }

  const mapGridData = () => {
    if (gridData == undefined || gridData?.length <= 0) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return gridData?.map((eoc, index) => (
        <TableRow key={index}>
          <TableCell>
            <Button
              onClick={() => setSelectedEOC(eoc.episodeOfCareId)}
              color="primary"
              variant="contained"
            >
              Select
            </Button>
          </TableCell>
          <TableCell> {eoc.referralDateDisplay} </TableCell>
          <TableCell>{eoc.takeoverOfCare}</TableCell>
          <TableCell>{eoc.generatedNumberOrLegacy}</TableCell>
          <TableCell>{eoc.status}</TableCell>
          <TableCell>{eoc.surgeryDateDisplay} </TableCell>
          <TableCell>{eoc.lastMMIDateDisplay}</TableCell>
          <TableCell>{eoc.careCoordinatorName}</TableCell>
          <TableCell>{eoc.bodyPart}</TableCell>
          <TableCell>{eoc.organizationName}</TableCell>
        </TableRow>
      ))
    }
  }

  const getEOCData = async (patientId: string) => {
    setIsLoading(true)
    const requestData = new SearchRequestBase()
    requestData.page = 1
    requestData.pageSize = 100
    await getEpisodesOfCareByPatientId(requestData, patientId).then((response: any) => {
      setIsLoading(false)
      setGridData(response.items)
    })
  }

  var modalTitle = props.action.actionName

  const openHistoricalModal = () => {
    setShowHistoricalModal(true)
    setHasSelectedSchedule(false)
    props.setSelectedLocation!(undefined)
    props.setSelectedSchedule!(undefined)

    var d = document.getElementById('locationSelect') as HTMLSelectElement
    d.options[0].disabled = false
    d.selectedIndex = 0
    var e = document.getElementById('eventSelect') as HTMLSelectElement
    e.options[0].disabled = false
    e.selectedIndex = 0
    props.openDialogWithSchedule!(undefined)
  }

  const openProviderModal = () => {
    props.openProviderLookupDialog!()
    var d = document.getElementById('locationSelect') as HTMLSelectElement
    d.options[0].disabled = false
    d.selectedIndex = 0
  }

  const refreshProviderList = async () => {
    await props.setSelectedScheduleById!(props.selectedSchedule!.id)
  }

  var eventDialog = showHistoricalModal && (
    <SimpleEventDialog
      isOpen={showHistoricalModal}
      episodeOfCare={props.episodeOfCare}
      updateParentStateAfterModal={(locationId: string) => {
        setIsLoading(true)
        setHasSelectedSchedule(true)
        setselectedLocationId(locationId)
        save(locationId, props.action.requireComment ? 'Simple Event' : undefined)
      }}
      updateParentStateAfterClose={() => {
        props.setSelectedSchedule!(undefined)
        props.setSelectedLocation!(undefined)
        setHasSelectedSchedule(false)
      }}
    />
  )
  var providerLookupDialog = props.isProviderLookupDialogOpen && (
    <ReceiveClaimProviderLookupDialog
      open={props.isProviderLookupDialogOpen!}
      isOpen={props.isProviderLookupDialogOpen!}
      updateParentStateAfterModal={
        showHistoricalModal
          ? undefined
          : (locationId: string) => {
              setIsLoading(true)
              setselectedLocationId(locationId)
              save(
                locationId,
                props.action.requireComment ? 'Additional Provider' : undefined
              )
            }
      }
    />
  )

  var targetScheduleItems = props.scheduleItems ?? []
  var targetLocationItems = props.locationItems ?? []
  return (
    <>
      <Dialog fullWidth={true} maxWidth="lg" open={props.isOpen!} disableBackdropClick>
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar title={modalTitle} isModal={true} />
        </DialogTitle>
        <DialogContent style={{ padding: '12px 12px 8px' }}>
          {isLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1200',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          {modalPage == 1 && (
            <Formik initialValues={{}} onSubmit={search} validationSchema={undefined}>
              <form>
                <Grid container direction="column" spacing={2} xs={12}>
                  <Grid item>
                    <Typography
                      color="primary"
                      component="h6"
                      variant="h6"
                      gutterBottom
                      style={{ paddingBottom: '10px' }}
                    >
                      SEARCH CRITERIA
                    </Typography>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={2}>
                        <FormControl fullWidth>
                          <TextField
                            value={searchPatientFirstName}
                            onChange={handlePatientFirstNameChange}
                            label="Patient First Name"
                            name="patientFirst"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl fullWidth>
                          <TextField
                            value={searchPatientLastName}
                            onChange={handlePatientLastNameChange}
                            label="Patient Last Name"
                            name="patientLast"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item direction="row" spacing={2}>
                        <Button
                          type="submit"
                          color="primary"
                          size="medium"
                          variant="contained"
                          style={{ marginRight: '10px' }}
                          onClick={search}
                        >
                          Search
                        </Button>
                        <Button
                          color="secondary"
                          onClick={clearSearch}
                          size="medium"
                          variant="contained"
                        >
                          Clear Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: '20px' }}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow className="gridPadding">
                          <TableCell />
                          <TableCell style={{ paddingLeft: '30px' }}>
                            Patient Id
                          </TableCell>
                          <TableCell>Pre-Cert #</TableCell>
                          <TableCell>Last Name</TableCell>
                          <TableCell>First Name</TableCell>
                          <TableCell align="right">DOB</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ width: '100%' }}>
                        {mapPatientGridData()}
                      </TableBody>
                    </Table>
                    <div className="pagination-row">
                      {paginationInfo()}
                      <ReactPaginate
                        forcePage={patientPage - 1}
                        previousLabel={'<'}
                        nextLabel={'>'}
                        onPageChange={handlePageChange}
                        pageCount={patientPageCount}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        //@ts-ignore
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Formik>
          )}
          {modalPage == 2 && (
            <Grid item xs={12}>
              {hasPatient && (
                <>
                  <Typography variant="body1" style={{ color: 'grey' }}>
                    Please select an Episode of Care
                  </Typography>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Referral Date</TableCell>
                        <TableCell>Takeover of Care</TableCell>
                        <TableCell>EOC/Legacy</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Surgery Date</TableCell>
                        <TableCell>MMI</TableCell>
                        <TableCell>Care Coordinator</TableCell>
                        <TableCell>Body Part</TableCell>
                        <TableCell>Org</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{mapGridData()}</TableBody>
                  </Table>
                </>
              )}
            </Grid>
          )}
          {modalPage == 3 && (
            <>
              {eventDialog}
              {providerLookupDialog}
              <Grid xs={12}>
                <strong>
                  {props.episodeOfCare?.referral?.patient?.lastName},{' '}
                  {props.episodeOfCare?.referral?.patient?.firstName} (
                  {moment(props.episodeOfCare?.referral?.patient?.dateOfBirth).format(
                    'L'
                  )}
                  )
                </strong>{' '}
                EOC/Legacy&nbsp;
                <strong>{props.episodeOfCare?.episodeOfCareNumberOrLegacyId}</strong>
              </Grid>
              <Grid
                container
                xs={12}
                style={{ paddingTop: '20px' }}
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} style={{ paddingRight: '20px' }}>
                  <InputLabel shrink={true} variant={'outlined'}>
                    Event Info
                  </InputLabel>
                  <Select
                    native={true}
                    fullWidth
                    onChange={changeSelectHandler}
                    id="eventSelect"
                  >
                    <option value="">Please select an event</option>
                    {targetScheduleItems.map((item: DropdownOption) => (
                      <option
                        accessKey={item.id}
                        value={item.description}
                        disabled={!item.isEnabled}
                      >
                        {item.description}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={openHistoricalModal}
                  >
                    ADD EVENT
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                style={{ paddingTop: '20px' }}
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} style={{ paddingRight: '20px' }}>
                  <InputLabel shrink={true} variant={'outlined'}>
                    Provider Group (Provider Location) - Billing Address
                  </InputLabel>
                  <Select
                    native={true}
                    fullWidth
                    disabled={disableDropdown}
                    onChange={changeLocationHandler}
                    id="locationSelect"
                  >
                    <option value="">Please select a provider</option>
                    {targetLocationItems.map((item: DropdownOption) => (
                      <option
                        accessKey={item.id}
                        value={item.description}
                        disabled={!item.isEnabled}
                      >
                        {item.description}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={openProviderModal}
                    disabled={disableProvider}
                    style={{ marginRight: '20px' }}
                  >
                    ADD PROVIDER
                  </Button>
                  <Tooltip title="Refresh Provider List" placement="top">
                    <Button
                      variant="contained"
                      onClick={refreshProviderList}
                      disabled={disableProvider}
                    >
                      <Refresh />
                    </Button>
                  </Tooltip>
                </Grid>
                {props.action.displayComment && (
                  <Grid item xs={12}>
                    <TextField
                      label="Comments"
                      name="notes"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required={props.action.requireComment}
                      multiline
                      minRows="4"
                      onChange={(event) => setComments(event.target.value)}
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {modalPage == 2 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setModalPage(1)
                setGridData(undefined)
              }}
            >
              Back
            </Button>
          )}
          {modalPage == 3 && (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setModalPage(2)
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: '4px' }}
                disabled={
                  executed ||
                  selectedEOCId == undefined ||
                  selectedLocationId == undefined ||
                  hasSelectedSchedule == false ||
                  (props.action.requireComment && comments === '')
                }
                onClick={() => {
                  setExecuted(true)
                  setIsLoading(true)
                  save()
                }}
              >
                Execute
              </Button>
            </>
          )}
          <Button
            onClick={() => {
              close()
            }}
            data-cy="close"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const InjectedAssociatePatientEOCModal = inject<
  IStores,
  IAssociatePatientEOCModalProps,
  Partial<IAssociatePatientEOCModalProps>,
  any
>((stores: IStores) => ({
  isLoading: stores.quotes.isLoading,
  selectedSchedule: stores.schedules.selectedSchedule,
  setSelectedLocation: stores.locations.setSelectedEditClaimLocation,
  setSelectedSchedule: stores.schedules.setSelectedSchedule,
  scheduleItems: stores.patientEdit.scheduleItems,
  locationItems: stores.schedules.scheduleItems,
  setSelectedScheduleById: stores.schedules.setSelectedScheduleById,
  getScheduledOptions: stores.patientEdit.getScheduledOptions,
  openDialogWithSchedule: stores.schedules.openDialogWithSchedule,
  openProviderLookupDialog: stores.schedules.openClaimProviderLookupDialog,
  isProviderLookupDialogOpen: stores.schedules.isClaimProviderLookupDialogOpen,
  episodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  getEpisodeOfCareById: stores.patientEdit.getEpisodeOfCareById,
  getSchedules: stores.patientEdit.getScheduledOptions,
  rerouteToPath: stores.global.rerouteToPath,
}))(AssociatePatientEOCModal)

export default InjectedAssociatePatientEOCModal
