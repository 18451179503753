import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import { LocalHospital } from '@material-ui/icons'
import { createLocation, createMemoryHistory } from 'history'
import { inject } from 'mobx-react'
import React from 'react'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IProvider } from '../Definitions'
import EditProvider from '../providers/EditProvider'
import { IStores } from '../Stores'

const styles = ({ palette }: Theme) =>
  createStyles({
    title: {
      backgroundColor: palette.primary.main,
      color: '#ffffff',
    },
  })

interface IProviderEditDialogProps extends DialogProps {
  close?: () => void
  isOpen?: boolean
  selectedProvider?: IProvider
  setSelectedProvider?: (provider?: IProvider) => void
}

class ProviderEditDialog extends React.Component<
  IProviderEditDialogProps & WithStyles<typeof styles>
> {
  private close = () => {
    this.props.close!()
  }

  public render() {
    const { selectedProvider } = this.props
    const id = selectedProvider ? selectedProvider.id : undefined
    const providerMatch = {
      isExact: true,
      params: {
        providerId: id,
      },
      path: '',
      url: '',
    }

    return (
      <Dialog open={this.props.isOpen!} maxWidth="lg" fullWidth={true}>
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title="Provider Details"
            pageIcon={<LocalHospital />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent>
          <EditProvider
            history={createMemoryHistory()}
            location={createLocation('/')}
            match={providerMatch}
            isModal={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const InjectedProviderEditDialog = inject<
  IStores,
  IProviderEditDialogProps,
  Partial<IProviderEditDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.schedules.closeProviderEditDialog,
  isOpen: stores.schedules.isProviderEditDialogOpen,
  selectedProvider: stores.providers.selectedProvider,
  setSelectedProvider: stores.providers.setSelectedProvider,
}))(ProviderEditDialog)

export default withStyles(styles)(InjectedProviderEditDialog)
