import { inject } from 'mobx-react'
import { OptionsObject } from 'notistack'
import * as React from 'react'
import { IStores } from '../../Stores'

interface IRedirectWithErrorMessageProps {
  messageId: string
  message: string
  redirectTo: string
  redirect?: (path: string) => void
  sendNotification?: (key: string, message: string, options?: OptionsObject) => void
}

class RedirectWithErrorMessage extends React.Component<IRedirectWithErrorMessageProps> {
  public componentDidMount() {
    const { messageId, message, redirect, redirectTo, sendNotification } = this.props
    sendNotification!(messageId, message, {
      variant: 'error',
    })
    redirect!(redirectTo)
  }

  public render() {
    return null
  }
}

const InjectedRedirectWithErrorMessage = inject<
  IStores,
  IRedirectWithErrorMessageProps,
  Partial<IRedirectWithErrorMessageProps>,
  any
>((stores: IStores) => ({
  redirect: stores.routing.replace,
  sendNotification: stores.notifications.sendNotification,
}))(RedirectWithErrorMessage)

export default InjectedRedirectWithErrorMessage
