import { createStyles, withStyles, WithStyles } from '@material-ui/core'
import { ClipboardOutline } from 'mdi-material-ui'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IComponent } from '../Definitions'
import { IStores } from '../Stores'

const styles = () => createStyles({})

interface IComponentsProps extends WithStyles<typeof styles> {
  createComponent?: () => void
  dataTableManager?: IDataTableManager<IComponent>
  disableComponent?: (component: IComponent) => void
  getColumnSettingsAndComponents?: () => void
  recoverComponent?: (component: IComponent) => void
}

class Components extends React.Component<IComponentsProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndComponents!()
  }

  public render() {
    return (
      <>
        <Helmet>
          <title>Components</title>
        </Helmet>
        <ResponsiveAppBar title="Components" pageIcon={<ClipboardOutline />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableSearch={true}
          enableReorderColumns={false}
          enableToggleColumns={true}
          enableShowInactives={true}
          enableFilters={true}
          addButtonOnClick={this.onCreateComponent}
        />
      </>
    )
  }

  public onCreateComponent = () => {
    this.props.createComponent!()
  }
}

const InjectedComponents = inject<IStores, IComponent, Partial<IComponentsProps>, any>(
  (stores: IStores) => ({
    createComponent: stores.components.createComponent,
    dataTableManager: stores.components.dataTableStore,
    disableComponent: stores.components.disableComponent,
    getColumnSettingsAndComponents: stores.components.getColumnSettingsAndComponents,
    recoverComponent: stores.components.recoverComponent,
  })
)(Components)

export default withStyles(styles)(InjectedComponents)
