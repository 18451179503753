import Axios from 'axios'
import { AddressDTO } from 'src/viewModels/AddressDTO'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function UpdateAllLocationBillingAddresses(
  providerId: string | undefined,
  billingAddress: AddressDTO
) {
  const response = await Axios.put(
    `${base_url}/api/provider/${providerId}/location-billing-addresses`,
    billingAddress
  )
  return response
}
