import {
  AppBar,
  Button,
  Checkbox,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { People } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Form, Formik, FormikActions, getIn } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { RouteComponentProps } from 'react-router-dom'
import { Prompt } from 'react-router-dom'
import * as Yup from 'yup'
import PatientActionBar from '../common/ActionBar/PatientActionBar'
import AddressField from '../common/AddressField/AddressField'
import CardWithNoTitle from '../common/CardWithNoTitle/CardWithNoTitle'
import FullWidthField from '../common/FullWidthField'
import IsActiveToggle from '../common/IsActiveToggle'
import NumberMaskedInput from '../common/NumberMaskedInput'
import PhoneNumberMask from '../common/PhoneNumberMask'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { noWhitespacePhone, noWhitespaceString } from '../common/YupExtensions'
import { DefaultPatient, IEpisodeOfCare, IPatient, IReferral } from '../Definitions'
import { IStores } from '../Stores'
import DeletePatientDialog from './DeletePatientDialog/DeletePatientDialog'
import EpisodesOfCare from './EpisodesOfCare/EpisodesOfCare'
import {
  createIPatientFormValues,
  IPatientFormValues,
  toIPatient,
} from './PatientFormValues'
import Referrals from './PatientReferrals/Referrals'
import PreExistingConditionSelect from './PreExistingConditionSelect/PreExistingConditionSelect'
import moment from 'moment'
import { SwaggerResponse } from 'src/generated_client'
import SelectField from 'src/common/SelectField/SelectField'
import { DropdownOption } from 'src/viewModels/DropdownOption'

const PatientSchema = Yup.object().shape({
  address: Yup.object({
    city: noWhitespaceString('City is required', true),
    line1: noWhitespaceString('Street address is required', true),
    line2: Yup.string(),
    state: noWhitespaceString('State is required', true),
    zip: noWhitespaceString('Zip code is required', true),
  }),
  allowTexting: Yup.boolean(),
  allowContact: Yup.boolean(),
  cellPhone: noWhitespacePhone(),
  dateOfBirthText: Yup.date().required('Birth date is required'),
  emailAddress: noWhitespaceString().email('Email Address must be a valid email'),
  firstName: noWhitespaceString('First name is required', true),
  gender: Yup.string(),
  heightFeet: Yup.string(),
  heightInches: Yup.string(),
  homePhone: noWhitespacePhone(),
  hxOfSx: Yup.string(),
  isActive: Yup.boolean(),
  lastName: noWhitespaceString('Last name is required', true),
  patientComments: Yup.string(),
  sSN: noWhitespaceString().test('len', 'Must be 4 characters', (val) =>
    val ? val.length === 4 : true
  ),
  weight: Yup.number(),
  workPhone: noWhitespacePhone(),
})

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      borderRadius: '5px',
      marginTop: 4,
      paddingTop: spacing(2),
    },
  })

interface IPatientRouteParams {
  patientId: string
  newTab: string
}

interface IPatientProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IPatientRouteParams> {
  episodeOfCare?: IEpisodeOfCare
  getPatientById?: (id: string) => Promise<void>
  isConfirmDialogOpen?: boolean
  isLoading?: boolean
  openConfirmDialog?: () => void
  patient?: IPatient
  rerouteToPath?: (path: string) => void
  savePatient?: (patient: IPatient) => Promise<SwaggerResponse<void>>
  setPageSize?: (pageSize: number) => void
  setSelectedPatient?: (patient: IPatient | undefined) => void
  setSelectedReferral?: (referral: IReferral | undefined) => void
  closeTab?: (fallbackPath?: string) => void
  setReturn?: (fallbackPath?: string) => void
  patientToQuotes?: boolean
  resetPatient?: () => void
}

interface IPatientState {
  reload: boolean
  allowContact: boolean
  expandedPatientPanel: string
  expandedReferralPanel: string
}

class Patient extends React.Component<IPatientProps, IPatientState> {
  constructor(props: IPatientProps) {
    super(props)
    this.state = {
      expandedPatientPanel: props.patient && props.patient.isNew ? 'patientPanel' : '',
      expandedReferralPanel: props.patient && props.patient.isNew ? '' : 'referralPanel',
      reload: false,
      allowContact:
        props.patient && props.patient.allowContact != undefined
          ? props.patient?.allowContact
          : true,
    }
  }

  public genderOptions = new Array<DropdownOption>()

  private async setup() {
    const { match } = this.props
    this.setState({ reload: false })
    this.setState({
      expandedPatientPanel: match && match.params.patientId ? '' : 'patientPanel',
      expandedReferralPanel: match && match.params.patientId ? 'referralPanel' : '',
    })
    if (match && match.params.patientId) {
      this.props.getPatientById!(match.params.patientId).then(() => {
        this.setState({
          allowContact:
            this.props.patient && this.props.patient.allowContact != undefined
              ? this.props.patient?.allowContact
              : true,
        })
        this.props.setPageSize!(10)
      })
    } else {
      this.props.setSelectedPatient!(DefaultPatient(true))
    }
  }

  public async componentDidMount() {
    ;(window as any).formikRef = React.createRef()
    var female = new DropdownOption()
    female.description = 'Female'
    female.id = 'Female'
    var male = new DropdownOption()
    male.description = 'Male'
    male.id = 'Male'
    var unknown = new DropdownOption()
    unknown.description = '[UNKNOWN]'
    unknown.id = ''
    this.genderOptions.push(unknown, male, female)

    await this.setup()
  }

  public async componentDidUpdate(prevProps: IPatientProps) {
    if (this.props.match.params.patientId !== prevProps.match.params.patientId) {
      await this.setup()
    }
  }

  public componentWillUnmount() {
    this.props.setSelectedPatient!(undefined)
    this.props.setSelectedReferral!(undefined)
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload }, () => {
      submitForm()
    })
  }

  public save = async (
    values: IPatientFormValues,
    formikBag: FormikActions<IPatientFormValues>
  ) => {
    const patient = await toIPatient(values, this.props.patient)

    patient.allowContact = this.state.allowContact
    patient.dateOfBirth = new Date(
      moment(patient.dateOfBirthText, 'YYYY-MM-DD').toISOString()
    )

    this.props.savePatient!(patient)
      .then(() => {
        formikBag.setSubmitting(false)
        if (this.state.reload) {
          this.redirectToEdit(patient.id)
          this.setup()
        } else {
          this.returnToPatients()
        }
      })
      .catch(() => formikBag.setSubmitting(false))
  }

  private returnToPatients = () => {
    if (sessionStorage.getItem('patientTab') === '1') {
      this.props.setReturn!()
    } else if (this.props.patientToQuotes) {
      this.props.setReturn!('/quotes')
      this.props.resetPatient!()
    }
    this.props.closeTab!('/patients')
  }

  private redirectToEdit = (id: string) => {
    this.props.rerouteToPath!(`/patients/patient/${id}`)
    this.setup()
  }

  public getValue(item: DropdownOption) {
    return item.id
  }

  public getName(item: DropdownOption) {
    return item.description
  }

  private handleIsActiveChange =
    (values: IPatientFormValues, setFieldValue: (field: string, value: any) => void) =>
    () => {
      setFieldValue('isActive', !values.isActive)
    }

  private calculateBMI(values: IPatientFormValues) {
    const feet = !!values.heightFeet ? parseInt(values.heightFeet, 10) : 0
    const inches = !!values.heightInches ? parseInt(values.heightInches, 10) : 0
    const weight = !!values.weight ? parseInt(values.weight, 10) : 0
    if (feet > 0 && weight > 0) {
      const weightInKg = weight / 2.2046
      const heightInMeters = (feet * 12 + inches) * 0.0254
      const bmi = (weightInKg / (heightInMeters * heightInMeters)).toFixed(1).toString()
      return bmi
    }
    return 'N/A'
  }

  private handlePatientChange = (panel: string) => (__: React.MouseEvent) => {
    this.setState({
      expandedPatientPanel: panel === this.state.expandedPatientPanel ? '' : panel,
    })
  }

  private handleReferralChange = (panel: string) => (__: React.MouseEvent) => {
    this.setState({
      expandedReferralPanel: panel === this.state.expandedReferralPanel ? '' : panel,
    })
  }

  private changeAllowContact = () => {
    this.setState({ allowContact: !this.state.allowContact })
  }

  public renderPatientHTML = (
    patient: any,
    values: any,
    setFieldValue: any,
    hasValue: any
  ) => {
    return (
      <>
        <CardWithNoTitle>
          <Grid container spacing={2}>
            <Grid item md={3} xs={6}>
              <FullWidthField
                name="firstName"
                label="First Name"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <FullWidthField
                name="lastName"
                label="Last Name"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                inputProps={{
                  id: 'dateOfBirthText',
                  style: { padding: '8px' },
                }}
                InputLabelProps={{ shrink: true }}
                type="date"
                name="dateOfBirthText"
                variant="outlined"
                className="smallDateTextbox"
                label="Birth Date"
                value={values?.dateOfBirthText}
                onChange={(event) => setFieldValue('dateOfBirthText', event.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <FullWidthField name="sSN" label="Last 4 of SSN" variant="outlined" />
            </Grid>
          </Grid>
          <div style={{ width: '100vw' }}>
            <ExpansionPanel
              style={{ marginTop: '10px' }}
              expanded={this.state.expandedPatientPanel === 'patientPanel'}
              onChange={this.handlePatientChange('patientPanel')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="textSecondary" variant="body2">
                  CONTACT INFORMATION AND DEMOGRAPHICS
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container xs={12} spacing={1}>
                  <Grid container item md={6} xs={12}>
                    <Grid container spacing={2}>
                      <Grid container lg={7} md={7} xs={12} style={{ paddingBottom: 20 }}>
                        <AddressField
                          name={'address'}
                          label="ADDRESS"
                          showPhone={false}
                          outlined={true}
                          required={true}
                        />
                        <Grid item xs={12}>
                          <FullWidthField
                            name="emailAddress"
                            label="Email Address"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        lg={5}
                        md={5}
                        xs={12}
                        spacing={1}
                        style={{
                          paddingLeft: '20px',
                          paddingTop: '20px',
                          paddingBottom: '20px',
                          paddingRight: '0px',
                        }}
                      >
                        <Grid item xs={12}>
                          <FullWidthField
                            name="cellPhone"
                            label="Cell #"
                            variant="outlined"
                            InputProps={{ inputComponent: PhoneNumberMask }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FullWidthField
                            name="homePhone"
                            label="Home #"
                            variant="outlined"
                            InputProps={{ inputComponent: PhoneNumberMask }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FullWidthField
                            name="workPhone"
                            label="Work #"
                            variant="outlined"
                            InputProps={{ inputComponent: PhoneNumberMask }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FullWidthField
                            name="allowTexting"
                            Label={{
                              label: 'Allow Texting',
                            }}
                            component={CheckboxWithLabel}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            label="Allow Contact"
                            labelPlacement="end"
                            control={
                              <Checkbox
                                checked={this.state.allowContact}
                                onChange={this.changeAllowContact}
                                inputProps={{
                                  id: 'allow-contact-filter',
                                }}
                                value="allowContact"
                                name="allowContact"
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ paddingLeft: '0px' }}>
                        <FullWidthField
                          name="patientComments"
                          label="Patient Comments"
                          variant="outlined"
                          fullWidth
                          rows={3}
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      style={{ paddingTop: 10 }}
                    >
                      <Grid item xs={3}>
                        <SelectField
                          inputId="gender"
                          getName={this.getName}
                          getValue={this.getValue}
                          items={this.genderOptions}
                          label="Gender"
                          name="gender"
                          fullWidth
                          outlined={true}
                          shrinkLabel={true}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FullWidthField
                          name="heightFeet"
                          label="Height-ft"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: hasValue('heightFeet', values),
                          }}
                          InputProps={{
                            inputComponent: NumberMaskedInput,
                            inputProps: {
                              guide: false,
                              numberOfDigits: 1,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FullWidthField
                          name="heightInches"
                          label="Height-in"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: hasValue('heightInches', values),
                          }}
                          InputProps={{
                            inputComponent: NumberMaskedInput,
                            inputProps: {
                              guide: false,
                              numberOfDigits: 2,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FullWidthField
                          name="weight"
                          label="Weight"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: hasValue('weight', values),
                          }}
                          InputProps={{
                            inputComponent: NumberMaskedInput,
                            inputProps: {
                              guide: false,
                              numberOfDigits: 3,
                              style: { padding: '8px' },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="BMI"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          disabled
                          value={this.calculateBMI(values)}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container direction="row">
                      <Grid item xs={12}>
                        <PreExistingConditionSelect
                          patientId={patient.id}
                          patientPreExistingConditions={values.preExistingConditions}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      spacing={2}
                      style={{ paddingTop: 10 }}
                    >
                      <Grid item xs={12}>
                        <FullWidthField
                          name="hxOfSx"
                          label="Hx of Sx"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12}>
                    <IsActiveToggle
                      isActive={values.isActive}
                      onChange={this.handleIsActiveChange(values, setFieldValue)}
                    />
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {patient && !patient.isNew ? (
              <ExpansionPanel
                expanded={this.state.expandedReferralPanel === 'referralPanel'}
                onChange={this.handleReferralChange('referralPanel')}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography color="textSecondary" variant="body2">
                    REFERRALS AND EPISODES OF CARE
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container xs={12} spacing={1}>
                    <Grid item xs={12} spacing={1}>
                      <Referrals />
                    </Grid>
                    <Grid item xs={12} spacing={1}>
                      <EpisodesOfCare />
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ) : (
              <></>
            )}
          </div>
        </CardWithNoTitle>
      </>
    )
  }

  public renderReferralsAndEOC = () => {
    return (
      <>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Paper className={this.props.classes.paper}>
              <Referrals />
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper className={this.props.classes.paper}>
              <EpisodesOfCare />
            </Paper>
          </Grid>
        </Grid>
        `
      </>
    )
  }

  public render() {
    const { patient } = this.props
    const hasValue = (field: string, values: IPatientFormValues) => {
      const value = getIn(values, field)

      return !!value
    }
    const deletePatientDialog = this.props.isConfirmDialogOpen && (
      <DeletePatientDialog open={true} returnToPatients={this.returnToPatients} />
    )

    const title = (patientInfo: IPatient, includeDetails: boolean) => {
      var result = patientInfo && patientInfo.isNew ? 'Add New Patient' : 'Edit Patient'

      if (includeDetails) {
        result += `: ${this.props.patient?.lastName}, ${this.props.patient
          ?.firstName} (${moment(this.props.patient?.dateOfBirthText).format(
          'MM/DD/YYYY'
        )})`
      }
      return result
    }

    return (
      <>
        {patient ? (
          <>
            <Helmet>
              <title>{title(patient, true)}</title>
            </Helmet>
            <Formik
              initialValues={createIPatientFormValues(patient)}
              onSubmit={this.save}
              validationSchema={PatientSchema}
              enableReinitialize={true}
              ref={(window as any).formikRef}
            >
              {({ isValid, setFieldValue, values, submitForm, dirty, submitCount }) => (
                <Form>
                  <Prompt
                    when={dirty && submitCount === 0}
                    message="Are you sure you want to leave the page? There are unsaved changes."
                  />
                  <AppBar style={{ boxShadow: 'none' }} position="sticky">
                    <ResponsiveAppBar title={title(patient, false)} pageIcon={<People />}>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                      >
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={this.returnToPatients}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            disabled={
                              !isValid &&
                              this.state.allowContact == this.props.patient?.allowContact
                            }
                            onClick={this.handleSave(submitForm, true)}
                          >
                            Save
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            disabled={
                              !isValid &&
                              this.state.allowContact == this.props.patient?.allowContact
                            }
                            onClick={this.handleSave(submitForm, false)}
                          >
                            Save and Close
                          </Button>
                        </Grid>
                      </Grid>
                    </ResponsiveAppBar>
                    {patient && !patient.isNew && <PatientActionBar />}
                  </AppBar>
                  <Grid>
                    <Grid item xs={12}>
                      {patient ? (
                        this.renderPatientHTML(patient, values, setFieldValue, hasValue)
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <></>
        )}
        {deletePatientDialog}
      </>
    )
  }
}

const InjectedPatient = inject<IStores, IPatientProps, Partial<IPatientProps>, any>(
  (stores: IStores) => ({
    close: stores.patients.closeDialog,
    closeTab: stores.global.closeTab,
    episodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
    getPatientById: stores.patients.getPatientById,
    isConfirmDialogOpen: stores.patients.isConfirmDialogOpen,
    openConfirmDialog: stores.patients.openConfirmDialog,
    patient: stores.patients.selectedPatient,
    patientToQuotes: stores.patients.patientToQuotes,
    rerouteToPath: stores.global.rerouteToPath,
    resetPatient: stores.patients.resetPatient,
    savePatient: stores.patients.savePatient,
    setPageSize: stores.communications.setPageSize,
    setSelectedPatient: stores.patients.setSelectedPatient,
    setSelectedReferral: stores.referrals.setSelectedReferral,
    setReturn: stores.global.setReturn,
  })
)(Patient)

export default withStyles(styles)(InjectedPatient)
