import { Code } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultCptCode, ICptCode } from '../Definitions'
import { IStores } from '../Stores'
import CptCodeDialog from './CptCodeDialog'

interface ICptCodesProps {
  dataTableManager?: IDataTableManager<ICptCode>
  disableCptCode?: (cptCode: ICptCode) => void
  getColumnSettingsAndCptCodes?: () => void
  isModalOpen?: boolean
  openDialogWithCptCode?: (cptCode: ICptCode) => void
  recoverCptCode?: (cptCode: ICptCode) => void
}

class CptCodes extends React.Component<ICptCodesProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndCptCodes!()
  }

  public render() {
    const cptCodeDialog = this.props.isModalOpen && <CptCodeDialog />
    return (
      <>
        <Helmet>
          <title>CPT Codes</title>
        </Helmet>
        <ResponsiveAppBar title="CPT Codes" pageIcon={<Code />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={false}
          enableToggleColumns={true}
          addButtonOnClick={this.openDialog(DefaultCptCode(true))}
        />
        {cptCodeDialog}
      </>
    )
  }

  public openDialog = (cptCode: ICptCode) => () => {
    this.props.openDialogWithCptCode!(cptCode)
  }
}

const InjectedCptCodes = inject<IStores, ICptCodesProps, Partial<ICptCodesProps>, any>(
  (stores: IStores) => ({
    dataTableManager: stores.cptCodes.dataTableStore,
    disableCptCode: stores.cptCodes.deleteCptCode,
    getColumnSettingsAndCptCodes: stores.cptCodes.getColumnSettingsAndCptCodes,
    isModalOpen: stores.cptCodes.isModalOpen,
    openDialogWithCptCode: stores.cptCodes.openDialogWithCptCode,
    recoverCptCode: stores.cptCodes.recoverCptCode,
  })
)(CptCodes)

export default InjectedCptCodes
