import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { IGlobalInvoicePaymentDetailSummary } from 'src/viewModels/InvoicePaymentDetailsSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { formatDate, formatCurrency } from 'src/utils/Formatter'

// tslint:disable-next-line: no-var-requires
const CurrencyFormat: any = require('react-currency-format')
// import Moment from 'moment';

interface IPaymentHistoryPanelProps {
  payments?: IGlobalInvoicePaymentDetailSummary[]
}

class PaymentHistoryPanel extends React.Component<IPaymentHistoryPanelProps> {
  public render() {
    const { payments } = this.props
    return (
      <ExpansionPanel style={{ marginTop: '10px' }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color="textSecondary" variant="body2">
            PAST RECEIVED PAYMENTS
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row" wrap="nowrap" alignContent="flex-start">
            <Table size="small" style={{ maxWidth: '800px' }}>
              <TableHead>
                <TableRow className="gridPadding">
                  <TableCell align="right">Check Date</TableCell>
                  <TableCell>Check Number</TableCell>
                  <TableCell>Payer</TableCell>
                  <TableCell align="right">Applied Date</TableCell>
                  <TableCell>Applied By</TableCell>
                  <TableCell align="right">Amount Paid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments ? (
                  payments.map((record, index) => {
                    return (
                      <TableRow className="gridPadding" key={index}>
                        <TableCell align="right">
                          {formatDate(record.checkDate!.toString())}
                        </TableCell>
                        <TableCell>{record.checkNumber}</TableCell>
                        <TableCell>{record.payer}</TableCell>
                        <TableCell align="right">
                          {formatDate(record.appliedDatePaid!.toString())}
                        </TableCell>
                        <TableCell>{record.appliedBy!}</TableCell>
                        <TableCell align="right">
                          {record.amountPaid
                            ? formatCurrency(record.amountPaid.toString())
                            : ''}
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow key="blank">
                    <TableCell colSpan={5} align="center">
                      <i>None</i>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow key="footer">
                  <TableCell colSpan={4}>
                    <div />
                  </TableCell>
                  <TableCell align="right">
                    <strong>Total:</strong>
                  </TableCell>
                  <TableCell align="right">
                    {this.props.payments ? (
                      <CurrencyFormat
                        value={payments!
                          .map((x) => Number(x.amountPaid) || 0)
                          .reduce((a, b) => a! + b!, 0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    ) : (
                      <span>$0.00</span>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default PaymentHistoryPanel
