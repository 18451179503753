export interface ILineItemDistributionDetail {
  cms1500LineDetailId: string | undefined
  amount?: number | undefined
  comment?: string | undefined
  cptCode?: string | undefined
  diagnosisPointer?: string | undefined
}

export class LineItemDistributionDetail implements ILineItemDistributionDetail {
  public constructor(
    id: string,
    keyedAmount: number,
    comment?: string,
    cptCode?: string,
    diagnosisPointer?: string
  ) {
    this.amount = keyedAmount
    this.cms1500LineDetailId = id
    this.comment = comment
    this.cptCode = cptCode
    this.diagnosisPointer = diagnosisPointer
  }

  public cms1500LineDetailId: string | undefined
  public amount?: number | undefined
  public comment?: string | undefined
  public cptCode?: string | undefined
  public diagnosisPointer?: string | undefined
}
