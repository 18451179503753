import {
  AppBar,
  CircularProgress,
  createStyles,
  Theme,
  Toolbar,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import * as React from 'react'

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      flexGrow: 1,
    },
  })

interface IResponsiveAppBarProps extends WithStyles {
  title: string
  pageIcon?: React.ReactElement<SvgIconProps>
  isLoading?: boolean
  isModal?: boolean
}

const ResponsiveAppBar: React.SFC<IResponsiveAppBarProps> = ({
  classes,
  children,
  title,
  pageIcon,
  isModal,
  isLoading = false,
}) => {
  const appBarStyle = {
    boxShadow: 'none',
    pageIcon,
    width: '100%',
    borderRadius: isModal ? '4px 4px 0px 0px' : 'default',
  }

  return (
    <AppBar style={appBarStyle} className={classes.appBar} position="sticky">
      <Toolbar variant="dense">
        {pageIcon && (
          <SvgIcon color="inherit" style={{ fontSize: 24, marginRight: 8 }}>
            {pageIcon}
          </SvgIcon>
        )}
        <Typography variant="h6" color="inherit" className={classes.title}>
          {title}
          {isLoading ? <CircularProgress style={{ marginLeft: 20 }} size={16} /> : <></>}
        </Typography>
        <div>{children}</div>
      </Toolbar>
      {process.env.REACT_APP_ENVIRONMENT != '' && !(isModal ?? false) && (
        <Toolbar
          style={{
            backgroundColor: 'darkred',
            color: 'white',
            height: '30px',
            minHeight: '30px',
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" color="inherit" style={{ width: '100%' }}>
            {process.env.REACT_APP_ENVIRONMENT}
          </Typography>
        </Toolbar>
      )}
    </AppBar>
  )
}

export default withStyles(styles)(ResponsiveAppBar)
