export interface IQuoteCalculationRequest {
  actualCost?: number
  bilateral: boolean
  contractMultiplier?: number
  multipleProcedureMultiplier?: number
  monitoredMultiplier?: number
  overrideCost?: number
  isRecon?: boolean
}

export class QuoteCalculationRequest implements IQuoteCalculationRequest {
  public actualCost?: number
  public bilateral: boolean
  public contractMultiplier?: number
  public multipleProcedureMultiplier?: number
  public monitoredMultiplier?: number
  public overrideCost?: number
  public isRecon?: boolean
}
