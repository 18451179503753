import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import React from 'react'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { inject } from 'mobx-react'
import { IStores } from 'src/Stores'
import _ from 'lodash'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import { completeValidation, processAction } from 'src/services/WorkflowService'
import { ObjectiveProcessAction } from 'src/viewModels/workflow/ObjectiveProcessAction'
import { Warning } from '@material-ui/icons'
import { ClaimCustomForm } from 'src/utils/ClaimCustomForm'
import { OptionsObject } from 'notistack'
import { DuplicateClaimSummary } from 'src/viewModels/workflow/DuplicateClaimSummary'
import moment from 'moment'
import { ILocation } from 'src/Definitions'
import { BillStatusNumber } from 'src/utils/BillStatusNumber'
import { CMS1500LineItemDto } from 'src/viewModels/claim/CMS1500LineItemDto'

interface ICompleteClaimModalProps {
  isOpen: boolean
  closeModal: () => void
  action: InboxActionSummary
  rerouteToPath?: (path: string) => void
  inboxItemId: number
  inboxId: string
  associatedItemId: string
  data: ObjectiveProcessAction
  actionList: InboxActionSummary[]
  selectedLocation?: ILocation
  sendNotification?: (
    key: string,
    message: string,
    options?: OptionsObject,
    information?: string | undefined
  ) => void
  handleProcessActionFinish?: (selectedAction?: InboxActionSummary) => void
}

class CompleteClaimModal extends React.Component<ICompleteClaimModalProps> {
  public state = {
    errors: new Array<string>(),
    enableNeedsDenied: false,
    executed: false,
    duplicateClaim: undefined as unknown as DuplicateClaimSummary,
    isLoading: true,
    needsDeniedActionId: '',
    comments: '',
    lineItems: new Array<CMS1500LineItemDto>(),
    missingReasonCodes: false,
  }

  public componentDidMount(): void {
    var needsDeniedComplete = this.props.actionList.find(
      (x) =>
        x.customFormName == ClaimCustomForm.FinalComplete &&
        x.statusId == BillStatusNumber.NeedsDenied &&
        x.id != this.props.action.id
    )
    if (needsDeniedComplete == null) {
      this.props.sendNotification!(
        '404',
        'This action is incorrectly configured. Please contact your administrator.',
        {
          variant: 'error',
        }
      )
      this.close()
    } else {
      if (this.props.data.claimData) {
        completeValidation(
          this.props.associatedItemId,
          true,
          this.props.data.claimData
        ).then((result) => {
          var zeroContracts =
            (this.props.data.claimData?.q24 &&
              this.props.data.claimData?.q24?.length > 0 &&
              this.props.data.claimData?.q24?.every((x) => x.contractAmount == 0)) ??
            false
          this.setState({
            duplicateClaim: result.duplicateClaimSummary,
            errors: result.errors,
            enableNeedsDenied: zeroContracts,
            needsDeniedActionId: needsDeniedComplete?.id.toString(),
            isLoading: false,
            lineItems: this.props.data.claimData?.q24,
          })
        })
      } else {
        this.props.sendNotification!(
          '404',
          'Claim data is missing, please refresh the page.',
          {
            variant: 'error',
          }
        )
        this.close()
      }
    }
  }

  private async save() {
    var action = this.props.action
    this.props.data.comment = this.state.comments

    if (this.state.errors.length > 0) {
      this.setState({ executed: false, isLoading: false })
      return
    } else if (
      this.state.lineItems.some((x) => x.reasonCode == '' || x.reasonCode == undefined)
    ) {
      this.setState({
        executed: false,
        missingReasonCodes: true,
        isLoading: false,
      })
      return
    }

    processAction(
      this.props.data,
      this.state.enableNeedsDenied
        ? this.state.needsDeniedActionId
        : action!.id.toString(),
      this.props.inboxItemId.toString()
    )
      .then(() => {
        this.props.handleProcessActionFinish!(action)
        this.close()
      })
      .catch(() => {
        this.setState({ executed: false, isLoading: false })
        this.close()
      })
  }

  private close = () => {
    this.setState({
      errors: new Array<string>(),
      enableNeedsDenied: false,
      executed: false,
      needsDeniedActionId: '',
      isLoading: false,
    })
    this.props.closeModal()
  }

  private getProviderTypeName(typeId: string) {
    const providerType = this.props.selectedLocation?.providerTypes?.find(
      (x) => x.id === typeId
    )
    if (providerType) {
      return providerType.abbreviation
    }
    return 'undefined'
  }

  public copyCode() {
    this.state.lineItems.forEach((x, index) => {
      x.reasonCode = this.state.lineItems[0].reasonCode
      var reasonBox = document.getElementById(`reasonCode[${index}]`) as HTMLInputElement
      if (reasonBox != undefined && this.state.lineItems[0].reasonCode != undefined) {
        reasonBox.value = this.state.lineItems[0].reasonCode
      }
    })

    this.setState({
      lineItems: this.state.lineItems,
      missingReasonCodes: false,
    })
  }

  public onReasonCodeChange(event: any, index: number) {
    this.state.lineItems[index].reasonCode = event.target.value
    this.setState({
      lineItems: this.state.lineItems,
      missingReasonCodes: false,
    })
  }

  public renderReasonCodes() {
    return (
      <Grid item>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date of Service</TableCell>
              <TableCell>CPT</TableCell>
              <TableCell>Charge Amount</TableCell>
              <TableCell>Contract Amount</TableCell>
              <TableCell>Reason Code</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.lineItems?.map((line, index) => {
              return (
                <TableRow>
                  <TableCell>
                    {moment(line.aDateOfServiceFrom).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>{line.dProcedureCptHcpcs}</TableCell>
                  <TableCell>{line.fCharges?.toFixed(2)}</TableCell>
                  <TableCell>{line.contractAmount?.toFixed(2)}</TableCell>
                  <TableCell style={{ paddingRight: '0px' }}>
                    <TextField
                      label="Reason Code"
                      name={`reasonCode[${index}]`}
                      id={`reasonCode[${index}]`}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                      onChange={(e: any) => this.onReasonCodeChange(e, index)}
                    />
                  </TableCell>
                  {index == 0 ? (
                    <TableCell style={{ paddingRight: '0px' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          this.state.executed ||
                          this.state.lineItems.length <= 1 ||
                          this.state.lineItems[0].reasonCode == undefined ||
                          this.state.lineItems[0].reasonCode == ''
                        }
                        onClick={() => {
                          this.copyCode()
                        }}
                      >
                        Copy to All Lines
                      </Button>
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Grid>
    )
  }

  public renderDuplicate() {
    return (
      <Grid container item xs={12}>
        <Typography style={{ width: '100%' }}>
          {this.state.duplicateClaim.duplicateClaimCount == 1 ? (
            <>
              <strong>A claim</strong> already exists that matches{' '}
            </>
          ) : (
            <>
              <strong>
                {this.state.duplicateClaim.duplicateClaimCount + ' '}
                claims{' '}
              </strong>
              already exist that match{' '}
            </>
          )}
          the Provider Group, Date of Service, and EOC Number on this claim. Click{' '}
          {this.state.enableNeedsDenied ? 'Needs Denied' : 'Execute'} to continue saving
          this claim or click Close to return to the current claim.
        </Typography>
        <Grid container direction="row" item xs={12}>
          <Grid container direction="column" spacing={1} xs={6}>
            <h1>
              Previous
              {this.state.duplicateClaim.duplicateClaimCount == 1 ? ' Claim' : ' Claims'}
            </h1>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Patient Name:</strong>
                </Grid>
                <Grid item>
                  <span>{this.state.duplicateClaim.patientName}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2}>
              <Grid item>
                <strong>EOC/Legacy:</strong>
              </Grid>
              <Grid item xs={9}>
                <span>{this.state.duplicateClaim.duplicateEOCLegacyGroup}</span>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Body Part:</strong>
                </Grid>
                <Grid item>
                  <span>{this.state.duplicateClaim.bodyPart}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Provider Group:</strong>
                </Grid>
                <Grid item>
                  <span>{this.state.duplicateClaim.providerGroup}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Provider Type:</strong>
                </Grid>
                <Grid item>
                  <span>{this.state.duplicateClaim.providerTypeList}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Date of Service:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {moment(this.state.duplicateClaim.dateOfService).format('MM/DD/YYYY')}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={1} xs={6}>
            <h1>Current Claim</h1>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Patient Name:</strong>
                </Grid>
                <Grid item>
                  <span>{this.state.duplicateClaim.patientName}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>EOC/Legacy:</strong>
                </Grid>
                <Grid item>
                  <span>{this.state.duplicateClaim.newEOCLegacy}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Body Part:</strong>
                </Grid>
                <Grid item>
                  <span>{this.state.duplicateClaim.bodyPart}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Provider Group:</strong>
                </Grid>
                <Grid item>
                  <span>{this.state.duplicateClaim.providerGroup}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Provider Type:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {this.getProviderTypeName(
                      this.props.data.claimData?.providerTypeId ?? ''
                    )}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Date of Service:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {moment(this.props.data.claimData?.dateOfService).format(
                      'MM/DD/YYYY'
                    )}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  public render() {
    var modalTitle = this.props.action.actionName.toUpperCase()

    return (
      <>
        <Dialog
          key={this.props.action.id}
          fullWidth={true}
          maxWidth="md"
          open={this.props.isOpen!}
          disableBackdropClick
        >
          <DialogTitle style={{ padding: 0 }}>
            <ResponsiveAppBar title={modalTitle} isModal={true} />
          </DialogTitle>
          <DialogContent style={{ padding: '12px 12px 8px' }}>
            {this.state.isLoading && (
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1200',
                }}
              >
                <CircularProgress size={100} />
              </div>
            )}
            <Grid container direction="column" spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  color: this.state.errors.length > 0 ? 'red' : 'green',
                }}
              >
                {this.state.errors.length > 0 ? (
                  <>
                    <h2>Validation Errors</h2>
                    <ul>
                      {this.state.errors.map((error) => (
                        <li>{error}</li>
                      ))}
                    </ul>
                  </>
                ) : this.state.isLoading ? (
                  <></>
                ) : (
                  <h2>Success! Claim is ready to complete.</h2>
                )}
              </Grid>
              {this.state.errors.length <= 0 && this.renderReasonCodes()}
              {this.state.duplicateClaim && this.renderDuplicate()}
              {this.props.action.displayComment && (
                <Grid item xs={12}>
                  <TextField
                    label="Comments"
                    name="notes"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required={this.props.action.requireComment}
                    multiline
                    minRows="4"
                    disabled={this.state.errors.length > 0}
                    onChange={(event) => this.setState({ comments: event.target.value })}
                  />
                </Grid>
              )}
              {this.state.missingReasonCodes && (
                <Typography noWrap variant="body2" color="error" align="right">
                  <strong>Reason code is required on every line</strong>
                </Typography>
              )}
              {this.state.enableNeedsDenied && (
                <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Warning
                    style={{
                      color: '#ffc107',
                      cursor: 'pointer',
                      paddingRight: '6px',
                    }}
                  />
                  All line items have $0 contract amounts
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              disabled={
                this.state.executed ||
                this.state.errors.length > 0 ||
                !this.state.enableNeedsDenied
              }
              onClick={() => {
                this.setState({ executed: true, isLoading: true })
                this.save()
              }}
            >
              Needs Denied
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                this.state.executed ||
                this.state.errors.length > 0 ||
                this.state.enableNeedsDenied
              }
              onClick={() => {
                this.setState({ executed: true, isLoading: true })
                this.save()
              }}
            >
              Execute
            </Button>
            <Button
              onClick={() => {
                this.close()
              }}
              data-cy="close"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const InjectedCompleteClaimModal = inject<
  IStores,
  ICompleteClaimModalProps,
  Partial<ICompleteClaimModalProps>,
  any
>((stores: IStores) => ({
  rerouteToPath: stores.global.rerouteToPath,
  sendNotification: stores.notifications.sendNotification,
  selectedLocation: stores.locations.selectedEditClaimLocation,
}))(CompleteClaimModal)

export default InjectedCompleteClaimModal
