import { IOrganization } from '../Definitions'

export default class UserDialogLogic {
  public getOrganizationFromOrganizationsById(
    organizations: IOrganization[] | undefined,
    organizationId: string
  ) {
    if (organizations) {
      return organizations.find((organization) => {
        return organization.id === organizationId
      })
    }
    return undefined
  }
}
