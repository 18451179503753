import { Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { Client, IRoleDto, RoleDto } from '../../generated_client'
import { DefaultRole, IRole } from '../../roles/Roles'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'RoleDto'

export default class RolesStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public roles: IRole[]
  @observable
  public selectedRole?: IRole
  public dataTableStore: DataTableStore<IRoleDto, IRole>

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IRoleDto, IRole>(
      globalStore,
      ({ filter, page, orderBy }) => client.apiRolesGet(filter, page, undefined, orderBy),
      (roles) => this.setupRoles(roles)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedRole = undefined
  }

  @action.bound
  public createRole(role: IRole) {
    this.isLoading = true
    return this.client
      .apiRolesPost(new RoleDto({ name: role.name }))
      .then(() => {
        this.dataTableStore.loadData()
        this.closeDialog()
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public deleteRole(role: IRole) {
    this.isLoading = true
    return this.client
      .apiRolesByRoleIdDelete(role.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public getColumnSettingsAndRoles() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public getAllRoles(filter = '') {
    this.isLoading = true
    return this.client
      .apiRolesGet(filter)
      .then((resp) => {
        runInAction(() => {
          this.roles = resp.result.map(this.addDefaultFields)
        })
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public openDialogWithRole(role: IRole) {
    this.isModalOpen = true
    this.selectedRole = role
  }

  private setupRoles = (roles: IRoleDto[]) => {
    return roles.map((x) => this.setupRoleMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (role: IRoleDto): IRole => {
    return { ...DefaultRole, ...role, isNew: false }
  }

  private setupRoleMenuItems = (role: IRole) => {
    role.menuItems = []

    if (this.globalStore.isAdmin) {
      role.menuItems.push({
        icon: Edit,
        isConfirm: false,
        name: 'Edit',
        onClick: () => this.openDialogWithRole(role),
      })
    }

    if (role.usersInRole && role.usersInRole < 1) {
      role.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.deleteRole(role),
      })
    }
    return role
  }
}
