import { createStyles, Grid, Typography, WithStyles, withStyles } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import { connect, FormikContext } from 'formik'
import React from 'react'
import FullWidthField from '../../common/FullWidthField'
import {
  createISurgeryCodeLineItemFormValues,
  IEpisodeOfCareFormValues,
  ISurgeryCodeLineItemFormValues,
} from './EpisodeOfCareFormValues'

const styles = createStyles({})

interface IFormikProps {
  formik: FormikContext<IEpisodeOfCareFormValues>
}

interface ICodeExpandingListProps extends WithStyles<typeof styles> {
  type: string
  testIndex?: number
  disabled?: boolean
}

class CodeExpandingList extends React.Component<ICodeExpandingListProps & IFormikProps> {
  private addCode = () => {
    const { type, formik, testIndex, disabled } = this.props
    if (!disabled) {
      const newLineItem = createISurgeryCodeLineItemFormValues()
      let codeList: ISurgeryCodeLineItemFormValues[]
      if (testIndex !== undefined) {
        codeList =
          type === 'cptCodes'
            ? formik.values.tests[testIndex].cptCodes
            : formik.values.tests[testIndex].dxCodes
      } else {
        codeList = type === 'cptCodes' ? formik.values.cptCodes : formik.values.dxCodes
      }
      newLineItem.priority = codeList.length

      if (testIndex !== undefined) {
        formik.setFieldValue(
          `tests[${testIndex}].${type}`,
          codeList.concat([newLineItem])
        )
      } else {
        formik.setFieldValue(type, codeList.concat([newLineItem]))
      }
    }
  }

  public render() {
    const { formik, type, testIndex, disabled } = this.props

    const createFieldName = (fieldName: string, index: number) => {
      return testIndex !== undefined
        ? `tests[${testIndex}].${type}[${index}].${fieldName}`
        : `${type}[${index}].${fieldName}`
    }

    let codeList: ISurgeryCodeLineItemFormValues[]
    if (testIndex !== undefined) {
      codeList =
        type === 'cptCodes'
          ? formik.values.tests[testIndex].cptCodes
          : formik.values.tests[testIndex].dxCodes
    } else {
      codeList = type === 'cptCodes' ? formik.values.cptCodes : formik.values.dxCodes
    }

    return (
      <>
        <Typography variant="subtitle1">
          {type === 'cptCodes' ? 'CPT' : 'DX'} Codes
        </Typography>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            <Grid container direction="row">
              {codeList.map((_, idx) => {
                return (
                  <Grid
                    item
                    key={type + idx + (testIndex !== undefined ? testIndex : '')}
                    xs={2}
                  >
                    <FullWidthField
                      variant="outlined"
                      name={createFieldName('code', idx)}
                      disabled={disabled}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <AddCircle
              color={disabled ? 'disabled' : 'primary'}
              onClick={this.addCode}
              style={{
                fontSize: '30px',
                cursor: disabled ? 'default' : 'pointer',
              }}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withStyles(styles)(
  connect<ICodeExpandingListProps, IEpisodeOfCareFormValues>(CodeExpandingList)
)
