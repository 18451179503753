import Axios from 'axios'
import { ISchedule } from 'src/Definitions'
import { ScheduleDto } from 'src/viewModels/ScheduleDto'
import { ISearchRequestBase } from 'src/viewModels/SearchRequestBase'
import { pagedList } from 'src/viewModels/pagedList'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function generateEOCEmail(id: string, type: number): Promise<string> {
  const response = await Axios.get<string>(
    `${base_url}/api/eoc/${id}/generate-email/${type}`
  )

  return response.data
}

export async function logEOCEmailGeneration(id: string, type: number): Promise<void> {
  Axios.post(`${base_url}/api/eoc/${id}/log-email/${type}`)
}

export async function getSchedulesByEpisodeOfCareId(
  episodeOfCareId: string,
  data: ISearchRequestBase
) {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/eoc/${episodeOfCareId}/schedules`,
    data
  )

  return response.data
}

export async function insertSchedule(episodeOfCareId: string, data: ScheduleDto) {
  const response = await Axios.post<ISchedule>(
    `${base_url}/api/eoc/${episodeOfCareId}/schedule`,
    data
  )

  return response.data
}

export async function updateSchedule(
  episodeOfCareId: string,
  scheduleId: string,
  data: ScheduleDto
) {
  const response = await Axios.put<ISchedule>(
    `${base_url}/api/eoc/${episodeOfCareId}/schedule/${scheduleId}`,
    data
  )

  return response.data
}

export async function getQuoteSummaryMessage(episodeOfCareId: string) {
  const response = await Axios.get<string>(
    `${base_url}/api/eoc/${episodeOfCareId}/quote-summary`
  )

  return response.data
}
