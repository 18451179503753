import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  InputLabel,
  Select,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
  withTheme,
} from '@material-ui/core'
import { AttachMoney, Refresh, ViewComfy, ViewCompact } from '@material-ui/icons'
import { Form, Formik, FormikActions } from 'formik'
import { inject } from 'mobx-react'
import React, { ChangeEvent } from 'react'
import { Prompt, RouteComponentProps } from 'react-router-dom'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import Tab from 'src/common/Tabs/Tab'
import Tabs from 'src/common/Tabs/Tabs'
import {
  DefaultCMS1500,
  ICMS1500,
  IEpisodeOfCare,
  ILocation,
  ISchedule,
  IUser,
} from 'src/Definitions'
import {
  EpisodeOfCareDto,
  IEpisodeOfCareDto,
  IProviderDto,
  SwaggerResponse,
} from 'src/generated_client'
import { IStores } from 'src/Stores'
import * as Yup from 'yup'
import BasicForm from './BasicForm'
import SimpleEventDialog from 'src/ReceiveClaim/SimpleEventDialog'
import {
  createIClaimFormValues as createIClaimFormValues,
  IClaimFormValues as IClaimFormValues,
  submitClaimFormValues as submitClaimFormValues,
} from './ClaimFormValues'
import DuplicateBillDialog from './DuplicateBillDialog'
import FullForm from './FullForm'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import ReceiveClaimProviderLookupDialog from './ReceiveClaimProviderLookupDialog'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { Helmet } from 'react-helmet'
import ViewProviderGroupContractModal from './ViewProviderGroupContractModal'
import { CopyClaim } from 'src/services/ClaimService'
import moment from 'moment'
import { IScheduleDto } from 'src/viewModels/ScheduleDto'
import LineItemContractModal from './LineItemContractModal'

const styles = ({ spacing }: Theme) =>
  createStyles({
    bottomItem: {
      bottom: 0,
      position: 'absolute',
    },
    bottomItemParent: {
      position: 'relative',
    },
    dateTo: {
      marginTop: 'auto',
    },
    form: {},
    radioExtraText: {
      fontSize: '10pt',
    },
    radioExtraTextBold: {
      fontStyle: 'italic',
      fontWeight: 'bold',
    },
    radioLabel: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    radioOption: {
      marginRight: '14px',
    },
    smallDateField: {
      marginTop: 'auto',
    },
    topOfPage: {
      marginTop: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
    topSpacing: {
      marginTop: '10px',
    },
  })

interface IReceiveClaimParams {
  episodeOfCareId: string
  quoteId: string
  claimId: string
  appealType: string
}

interface IReceiveClaimProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IReceiveClaimParams> {
  closeModal?: () => void
  episodeOfCare?: IEpisodeOfCare
  getTypesByCategory?: (type: string) => void
  isLoading?: boolean
  isModalOpen?: boolean
  isCMS1500Loading?: boolean
  isCurrentOrganizationSurgical?: boolean
  isEpisodeOfCareLoading?: boolean
  loadEpisodeOfCare?: (episodeOfCareId: string) => Promise<void>
  loadClaim?: (claimId: string) => Promise<void>
  rerouteToPath?: (path: string) => void
  saveCMS1500?: (cms1500: ICMS1500) => Promise<SwaggerResponse<void>>
  selectedCMS1500?: ICMS1500
  selectedEpisodeOfCare?: IEpisodeOfCare
  selectedLocation?: ILocation
  selectedQuote?: IQuoteDTO
  selectedSchedule?: ISchedule
  setSelectedCMS1500?: (cms1500: ICMS1500 | undefined) => void
  setSelectedEpisodeOfCare?: (episodeOfCare: IEpisodeOfCare | undefined) => void
  setSelectedLocation?: (location?: ILocation) => void
  setSelectedSchedule?: (schedule?: ISchedule) => void
  checkDuplicateBills?: (cms1500: ICMS1500) => Promise<void>
  duplicateCMS1500?: ICMS1500
  openModal?: () => void
  scheduleItems?: DropdownOption[]
  locationItems?: DropdownOption[]
  setSelectedScheduleById?: (id: string) => Promise<void>
  getScheduledOptions?: () => void
  openDialogWithSchedule?: (schedule: ISchedule | undefined) => void
  openProviderLookupDialog?: () => void
  getLocationById?: (locationId: string) => void
  isProviderLookupDialogOpen?: boolean
  showContractModal?: (provider: IProviderDto) => void
  setReturn?: () => void
  closeTab?: () => void
  workflowStyling?: boolean
  setGetClaimValuesForStaticAction: (parentfunction?: () => Promise<ICMS1500>) => void
  contractModalIsOpen?: boolean
  user?: IUser
}

interface IReceiveClaimState {
  hasSelectedSchedule: boolean
  hasSelectedLocation: boolean
  reload: boolean
  disableNext: boolean
  disableProvider: boolean
  showHistoricalModal: boolean
  hasNotSubmitted: boolean
  appealType?: string | undefined
  dueDate?: string | undefined
  receivedDate?: string | undefined
  showContractAmountModalButton: boolean
  showPDFButton: boolean
}
class ReceiveClaim extends React.Component<IReceiveClaimProps, IReceiveClaimState> {
  constructor(props: IReceiveClaimProps) {
    super(props)
    this.state = {
      hasSelectedSchedule: false,
      hasSelectedLocation: false,
      reload: false,
      disableNext: true,
      disableProvider: true,
      showHistoricalModal: false,
      hasNotSubmitted: true,
      appealType: undefined,
      dueDate: undefined,
      receivedDate: undefined,
      showContractAmountModalButton: false,
      showPDFButton: false,
    }
  }
  q28Mismatch = false
  dueDateBefore = false
  disableDropdown = true
  selectedLocationId = ''

  public componentDidMount() {
    this.props.setGetClaimValuesForStaticAction(this.staticActionSave)
    this.setState({ appealType: this.props.match.params.appealType }, () => this.setup())
  }

  private async setup() {
    const { match, loadEpisodeOfCare, loadClaim: loadClaim } = this.props
    const promises = [] as Array<Promise<void>>

    if (match && match.params.episodeOfCareId) {
      promises.push(loadEpisodeOfCare!(match.params.episodeOfCareId))
    }

    if (match && match.params.claimId) {
      if (match && match.params.appealType && this.state.appealType) {
        var copy = await CopyClaim(match.params.claimId, +match.params.appealType)
        this.props.setSelectedCMS1500!(copy as unknown as ICMS1500)
      } else {
        promises.push(loadClaim!(match.params.claimId))
      }
    } else {
      this.props.setSelectedCMS1500!(DefaultCMS1500(true))
    }

    await Promise.all(promises).then(() => {
      if (
        this.props.selectedCMS1500 &&
        this.props.selectedCMS1500!.isNew &&
        !(match && match.params.appealType && this.state.appealType)
      ) {
        this.setState({
          hasSelectedSchedule: false,
          hasSelectedLocation: false,
        })
      } else if (
        this.props.selectedCMS1500 &&
        (!this.props.selectedCMS1500!.isNew ||
          (match && match.params.appealType && this.state.appealType))
      ) {
        this.setState({
          dueDate:
            this.props.selectedCMS1500.dueDate != undefined
              ? moment(this.props.selectedCMS1500.dueDate).format('yyyy-MM-DD')
              : undefined,
          receivedDate:
            this.props.selectedCMS1500.incomingOrOutgoingDate != undefined
              ? moment(this.props.selectedCMS1500.incomingOrOutgoingDate).format(
                  'yyyy-MM-DD'
                )
              : undefined,
        })

        if (this.props.selectedCMS1500!.claimEvents!.length > 0) {
          this.setState({
            hasSelectedSchedule: true,
            hasSelectedLocation: true,
          })
          this.props.setSelectedSchedule!(
            this.props.selectedCMS1500!.claimEvents![0]!.schedule as unknown as ISchedule
          )
          this.props.setSelectedLocation!(
            this.props.selectedCMS1500!.claimEvents![0]!.location as unknown as ILocation
          )
        }
      }
      this.props.getScheduledOptions!()

      var allowedUserRoles = process.env.REACT_APP_PAID_CONTRACT_AMOUNT_GROUPS?.split(',')
      var userAllowed = this.props.user?.roles.some(
        (x) => allowedUserRoles?.some((role) => role == x)
      )
      var isWorkflowAdmin =
        this.props.user?.roles.some((x) => x == 'Workflow Admin') || false

      this.setState({
        showContractAmountModalButton: userAllowed || false,
        showPDFButton: isWorkflowAdmin || false,
      })
    })
  }

  private cancel = () => {
    if (sessionStorage.getItem('claimTab') === '1') {
      this.props.setReturn!()
      sessionStorage.setItem('claimTab', '0')
      this.props.closeTab!()
    }
    if (this.props.selectedQuote === undefined) {
      this.props.rerouteToPath!('/patients')
    } else {
      this.props.rerouteToPath!('/quotes')
    }
  }

  public componentWillUnmount() {
    this.props.setSelectedCMS1500!(undefined)
    this.props.setSelectedEpisodeOfCare!(undefined)
    this.props.setSelectedLocation!(undefined)
    this.setState({ hasNotSubmitted: true })
  }

  public renderTabs(
    cms1500?: ICMS1500,
    episodeOfCare?: IEpisodeOfCareDto,
    surgeryComponent?: IScheduleDto
  ) {
    const errorState = this.q28Mismatch
    const dueDateBefore = this.dueDateBefore
    const tabs = [
      new Tab(
        (
          <BasicForm
            dueDate={this.state.dueDate}
            handleDueDateChange={this.handleDueDateChange}
            receivedDate={this.state.receivedDate}
            handleDateChange={this.handleDateChange}
            episodeOfCare={episodeOfCare ? episodeOfCare : undefined}
            selectedCMS1500={cms1500 ? cms1500 : undefined}
            errorState={errorState}
            dueDateBefore={dueDateBefore}
            selectedSchedule={this.props.selectedSchedule}
            selectedLocation={this.props.selectedLocation}
            surgeryDate={surgeryComponent?.appointmentDate}
            showContractModal={this.props.showContractModal}
            showContractModalButton={this.state.showContractAmountModalButton}
            showPDFButton={this.state.showPDFButton}
            hasNotSubmitted={this.state.hasNotSubmitted}
          />
        ),
        'SIMPLE',
        <ViewCompact />
      ),
      new Tab(
        (
          <FullForm
            dueDate={this.state.dueDate}
            handleDueDateChange={this.handleDueDateChange}
            receivedDate={this.state.receivedDate}
            handleDateChange={this.handleDateChange}
            episodeOfCare={episodeOfCare ? episodeOfCare : undefined}
            selectedCMS1500={cms1500 ? cms1500 : undefined}
            selectedSchedule={this.props.selectedSchedule}
            selectedLocation={this.props.selectedLocation}
            surgeryDate={surgeryComponent?.appointmentDate}
            errorState={errorState}
            dueDateBefore={dueDateBefore}
            showContractModal={this.props.showContractModal}
            showContractModalButton={this.state.showContractAmountModalButton}
            showPDFButton={this.state.showPDFButton}
            hasNotSubmitted={this.state.hasNotSubmitted}
          />
        ),
        'FULL',
        <ViewComfy />
      ),
    ]
    return (
      <Tabs
        color="inherit"
        isHeaderElevated={false}
        isHeaderTransparent={true}
        tabColor="primary"
        tabs={tabs}
      />
    )
  }

  public claimRef = React.createRef<any>()

  public render() {
    const {
      isCMS1500Loading,
      isCurrentOrganizationSurgical,
      isEpisodeOfCareLoading,
      selectedEpisodeOfCare,
      selectedCMS1500,
      locationItems,
      scheduleItems,
      workflowStyling,
    } = this.props

    const episodeOfCare = selectedEpisodeOfCare ? selectedEpisodeOfCare : undefined

    const surgeryComponentId = '9c2b71e7-0898-4d8f-b40c-c197d325866a'

    const surgeryComponent = episodeOfCare?.schedules?.find(
      (x) => x.componentId == surgeryComponentId && x.isActive && !x.rescheduled
    )

    const changeSelectHandler = (event: ChangeEvent<HTMLSelectElement>) => {
      this.disableDropdown = false
      event.target.options[0].disabled = true
      this.props.setSelectedScheduleById!(event?.target.selectedOptions[0].accessKey)
      this.setState({
        disableNext: true,
        disableProvider: false,
        hasSelectedSchedule: true,
      })
      var d = document.getElementById('locationSelect') as HTMLSelectElement
      d.options[0].disabled = false
      d.selectedIndex = 0
    }

    const changeLocationHandler = (event: ChangeEvent<HTMLSelectElement>) => {
      event.target.options[0].disabled = true
      this.selectedLocationId = event?.target.selectedOptions[0].accessKey
      this.setState({ disableNext: false })
    }

    const openHistoricalModal = () => {
      this.setState({
        showHistoricalModal: true,
        hasSelectedLocation: false,
        hasSelectedSchedule: false,
      })
      this.props.setSelectedLocation!(undefined)
      this.props.setSelectedSchedule!(undefined)

      var d = document.getElementById('locationSelect') as HTMLSelectElement
      d.options[0].disabled = false
      d.selectedIndex = 0
      var e = document.getElementById('eventSelect') as HTMLSelectElement
      e.options[0].disabled = false
      e.selectedIndex = 0
      this.props.openDialogWithSchedule!(undefined)
    }

    const openProviderModal = () => {
      this.props.openProviderLookupDialog!()
      var d = document.getElementById('locationSelect') as HTMLSelectElement
      d.options[0].disabled = false
      d.selectedIndex = 0
    }

    const refreshProviderList = () => {
      this.props.setSelectedScheduleById!(this.props.selectedSchedule!.id)
    }

    const title =
      selectedCMS1500 && selectedCMS1500.isNew ? 'Add New Claim' : 'Edit Claim'

    var eventDialog = this.state.showHistoricalModal && (
      <SimpleEventDialog
        isOpen={this.state.showHistoricalModal}
        episodeOfCare={this.props.episodeOfCare}
        updateParentStateAfterModal={() => this.setState({ hasSelectedSchedule: true })}
        updateParentStateAfterClose={() => {
          this.props.setSelectedSchedule!(undefined)
          this.props.setSelectedLocation!(undefined)
          this.setState({
            hasSelectedSchedule: false,
            hasSelectedLocation: false,
          })
        }}
      />
    )
    var providerLookupDialog = this.props.isProviderLookupDialogOpen && (
      <ReceiveClaimProviderLookupDialog
        open={this.props.isProviderLookupDialogOpen!}
        isOpen={this.props.isProviderLookupDialogOpen!}
        updateParentStateAfterModal={() => this.setState({ hasSelectedLocation: true })}
      />
    )

    var targetScheduleItems = scheduleItems ?? []
    var targetLocationItems = locationItems ?? []

    return isEpisodeOfCareLoading || isCMS1500Loading ? (
      <Grid container direction="column" justifyContent="center">
        <Grid item container direction="row" justifyContent="center">
          <h1>Loading...</h1>
        </Grid>
        <Grid item container direction="row" justifyContent="center">
          <CircularProgress size={64} />
        </Grid>
      </Grid>
    ) : (
      <>
        {workflowStyling != true && (
          <Helmet>
            <title>{`${title}: ${this.props.episodeOfCare?.referral!.patient!
              .lastName}, ${this.props.episodeOfCare?.referral!.patient!
              .firstName} (${moment(
              this.props.episodeOfCare?.referral!.patient!.dateOfBirth
            ).format('MM/DD/YYYY')}) ${this.props.episodeOfCare
              ?.episodeOfCareNumberOrLegacyId}`}</title>
          </Helmet>
        )}
        <ViewProviderGroupContractModal />
        {selectedCMS1500 &&
          this.state.hasSelectedSchedule &&
          this.state.hasSelectedLocation && (
            <Formik
              ref={this.claimRef}
              initialValues={createIClaimFormValues(
                selectedCMS1500,
                new EpisodeOfCareDto(episodeOfCare)
              )}
              onSubmit={workflowStyling ? () => {} : this.save}
              validationSchema={this.buildSchema(isCurrentOrganizationSurgical)}
              enableReinitialize={false}
            >
              {({ submitForm, values, dirty }) => (
                <>
                  {this.props.contractModalIsOpen && (
                    <LineItemContractModal selectedCMS1500={values} />
                  )}
                  <Form>
                    <Prompt
                      when={dirty && this.state.hasNotSubmitted == true}
                      message="Are you sure you want to leave the page? There are unsaved changes."
                    />
                    {workflowStyling != true && (
                      <ResponsiveAppBar title={title} pageIcon={<AttachMoney />}>
                        <Grid
                          container
                          direction="row"
                          spacing={2}
                          justifyContent="flex-end"
                        >
                          <Grid item>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={this.cancel}
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              color="secondary"
                              size="small"
                              variant="contained"
                              onClick={this.handleSave(values, submitForm, true)}
                            >
                              Save
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              color="secondary"
                              size="small"
                              variant="contained"
                              onClick={this.handleSave(values, submitForm, false)}
                            >
                              Save and Close
                            </Button>
                          </Grid>
                        </Grid>
                      </ResponsiveAppBar>
                    )}
                    {this.props.selectedCMS1500?.appealType !== undefined ? (
                      <Toolbar
                        style={{
                          backgroundColor: '#d32f2f',
                          color: 'white',
                          minHeight: '30px',
                          textAlign: 'center',
                        }}
                      >
                        <Typography
                          variant="h4"
                          color="inherit"
                          style={{ width: '100%' }}
                        >
                          APPEAL
                        </Typography>
                      </Toolbar>
                    ) : (
                      <></>
                    )}
                    <Grid
                      container
                      item
                      direction="row"
                      justifyContent="flex-start"
                      style={{ overflow: 'hidden' }}
                    >
                      {this.renderTabs(selectedCMS1500, episodeOfCare, surgeryComponent)}
                    </Grid>
                    <DuplicateBillDialog
                      open={this.props.isModalOpen!}
                      submitForm={submitForm}
                      currentBill={values}
                      currentEpisodeOfCare={episodeOfCare}
                    />
                  </Form>
                </>
              )}
            </Formik>
          )}

        {episodeOfCare &&
          selectedCMS1500 &&
          (!this.state.hasSelectedSchedule || !this.state.hasSelectedLocation) && (
            <>
              {eventDialog}
              {providerLookupDialog}
              <Form>
                <ResponsiveAppBar
                  title={
                    selectedCMS1500 && selectedCMS1500.isNew
                      ? 'Add New Claim'
                      : 'Edit Claim'
                  }
                  pageIcon={<AttachMoney />}
                />
                <Grid item xs={12}>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={3}
                    style={{
                      paddingTop: '8px',
                      paddingLeft: '20px',
                      paddingBottom: '12px',
                    }}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <strong>
                        {episodeOfCare?.referral?.patient
                          ? `${episodeOfCare!.referral!.patient!.lastName}, ${
                              episodeOfCare!.referral!.patient!.firstName
                            }`
                          : ''}{' '}
                      </strong>
                    </Grid>
                    <Grid item>
                      <strong>DOB:</strong>{' '}
                      {episodeOfCare!.referral!.patient!.dateOfBirth
                        ? moment(episodeOfCare!.referral!.patient!.dateOfBirth).format(
                            'MM/DD/YYYY'
                          )
                        : ''}
                    </Grid>
                    <Grid item>
                      <strong>Body Part:</strong> {episodeOfCare!.bodyPartText}
                    </Grid>
                    <Grid item>
                      <strong>Surgery Date:</strong>{' '}
                      {surgeryComponent?.appointmentDate
                        ? moment(surgeryComponent.appointmentDate).format('L')
                        : 'N/A'}
                    </Grid>
                    <Grid item>
                      <strong>Sx + 90:</strong>{' '}
                      {surgeryComponent?.appointmentDate
                        ? moment(surgeryComponent.appointmentDate)
                            .add(90, 'day')
                            .format('L')
                        : 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    xs={12}
                    style={{ paddingLeft: '20px' }}
                  >
                    <Grid item style={{ paddingTop: '4px', fontSize: '20px' }}>
                      Please Pick a Scheduled Event
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      style={{ paddingTop: '20px' }}
                      alignItems="center"
                    >
                      <Grid item xs={4} style={{ paddingRight: '20px' }}>
                        <InputLabel shrink={true} variant={'outlined'}>
                          Event Info
                        </InputLabel>
                        <Select
                          native={true}
                          fullWidth
                          onChange={changeSelectHandler}
                          id="eventSelect"
                        >
                          <option value="">Please select an event</option>
                          {targetScheduleItems.map((item: DropdownOption) => (
                            <option
                              accessKey={item.id}
                              value={item.description}
                              disabled={!item.isEnabled}
                            >
                              {item.description}
                            </option>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={openHistoricalModal}
                        >
                          ADD EVENT
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      style={{ paddingTop: '20px' }}
                      alignItems="center"
                    >
                      <Grid item xs={8} style={{ paddingRight: '20px' }}>
                        <InputLabel shrink={true} variant={'outlined'}>
                          Provider Group (Provider Location) - Billing Address
                        </InputLabel>
                        <Select
                          native={true}
                          fullWidth
                          disabled={this.disableDropdown}
                          onChange={changeLocationHandler}
                          id="locationSelect"
                        >
                          <option value="">Please select a provider</option>
                          {targetLocationItems.map((item: DropdownOption) => (
                            <option
                              accessKey={item.id}
                              value={item.description}
                              disabled={!item.isEnabled}
                            >
                              {item.description}
                            </option>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={openProviderModal}
                          disabled={this.state.disableProvider}
                          style={{ marginRight: '20px' }}
                        >
                          ADD PROVIDER
                        </Button>
                        <Tooltip title="Refresh Provider List" placement="top">
                          <Button
                            variant="contained"
                            onClick={refreshProviderList}
                            disabled={this.state.disableProvider}
                          >
                            <Refresh />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid
                    container
                    direction="row"
                    style={{ float: 'right', marginRight: '27px' }}
                    xs={12}
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        style={{ marginRight: '10px' }}
                        onClick={this.cancel}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={this.handleNext}
                        disabled={this.state.disableNext}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
      </>
    )
  }

  private handleNext = async () => {
    this.props.selectedCMS1500!.dateOfService =
      this.props.selectedSchedule!.appointmentDate
    await this.props.getLocationById!(this.selectedLocationId)
    this.setState({ hasSelectedSchedule: true, hasSelectedLocation: true })
    if (
      this.props.selectedCMS1500!.q14DateOfIllness !== undefined &&
      this.props.selectedCMS1500!.dateOfService !== undefined
    ) {
      this.props.selectedCMS1500!.q14DateOfIllness =
        this.props.selectedCMS1500!.dateOfService
    }
  }

  private handleSave =
    (values: IClaimFormValues, submitForm: () => void, reload: boolean) => async () => {
      var scheduleId = undefined

      if (this.props.selectedSchedule) {
        scheduleId = this.props.selectedSchedule!.id
      }
      const cms1500 = await submitClaimFormValues(
        values,
        this.props.selectedCMS1500,
        scheduleId
      )
      cms1500.episodeOfCare = new EpisodeOfCareDto(this.props.episodeOfCare)
      if (cms1500.appealType != undefined) {
        this.setState({ reload }, () => {
          submitForm()
        })
      } else {
        this.setState({ reload }, () => {
          this.props.checkDuplicateBills!(cms1500).then(() => {
            if (this.props.duplicateCMS1500 != null) {
              this.props.openModal!()
            } else {
              submitForm()
            }
          })
        })
      }
    }

  public getFormikValues = () => {
    const formikValues = this.claimRef.current?.state?.values
    return formikValues
  }

  public handleDueDateChange = (dueDateVal: string) => {
    var values = this.getFormikValues()
    this.setState({ dueDate: dueDateVal })
    values.dueDate = dueDateVal
  }
  private dateReceived = undefined as Date | undefined

  public handleDateChange = (event: any) => {
    var values = this.getFormikValues()
    var incomingOrOutgoingDate = new Date(event.target.value)
    const calculatedDueDate = new Date(incomingOrOutgoingDate.getTime())

    if (
      incomingOrOutgoingDate &&
      incomingOrOutgoingDate.getDate() &&
      event.target.value &&
      values.appealType == undefined
    ) {
      calculatedDueDate.setDate(incomingOrOutgoingDate.getDate() + 31)
    }
    try {
      if (
        values.appealType == undefined &&
        incomingOrOutgoingDate.getDate() &&
        (incomingOrOutgoingDate?.getTime() !== this.dateReceived?.getTime() ||
          values.dueDate === undefined)
      ) {
        values.dueDate = moment(calculatedDueDate).format('yyyy-MM-DD')
        this.handleDueDateChange(values.dueDate)
      } else {
        values.dueDate = ''
        this.handleDueDateChange(values.dueDate)
      }

      this.setState({ receivedDate: event.target.value })
      this.dateReceived = incomingOrOutgoingDate
      values.incomingOrOutgoingDate = event.target.value
    } catch (ex) {}
  }

  public staticActionSave = async () => {
    let values = this.claimRef.current?.state?.values
    this.claimRef.current.submitForm()

    if (this.state.dueDate) {
      values.dueDate = new Date(moment(this.state.dueDate, 'YYYY-MM-DD').toISOString())
    }

    if (this.state.receivedDate) {
      values.incomingOrOutgoingDate = new Date(
        moment(this.state.receivedDate, 'YYYY-MM-DD').toISOString()
      )
    }

    const claim = await submitClaimFormValues(
      values,
      this.props.selectedCMS1500,
      this.props.selectedSchedule?.id
    ).finally(() => {
      this.setState({ hasNotSubmitted: false }, () => {
        this.claimRef.current.setSubmitting(false)
      })
    })

    return claim
  }

  public save = async (
    values: IClaimFormValues,
    formikBag: FormikActions<IClaimFormValues>
  ) => {
    let totalCharge = 0

    for (const formValue of values.q24) {
      const num = +formValue.fCharges!
      totalCharge += num * 100
    }
    totalCharge = totalCharge / 100

    values.dueDate = new Date(moment(this.state.dueDate, 'YYYY-MM-DD').toISOString())
    values.incomingOrOutgoingDate = new Date(
      moment(this.state.receivedDate, 'YYYY-MM-DD').toISOString()
    )

    this.q28Mismatch =
      values.q28TotalCharge !== totalCharge.toFixed(2) &&
      values.q28TotalCharge != totalCharge.toString()
    this.dueDateBefore = values.dueDate
      ? values.incomingOrOutgoingDate != undefined &&
        values.dueDate < values.incomingOrOutgoingDate &&
        !(
          values.dueDate.getDate() == values.incomingOrOutgoingDate!.getDate() &&
          values.dueDate.getMonth() == values.incomingOrOutgoingDate.getMonth() &&
          values.dueDate.getFullYear() == values.incomingOrOutgoingDate.getFullYear()
        )
      : true

    if (this.q28Mismatch || this.dueDateBefore) {
      formikBag.setSubmitting(false)
    } else {
      var scheduleId = undefined

      if (this.props.selectedSchedule) {
        scheduleId = this.props.selectedSchedule!.id
      }
      const cms1500 = await submitClaimFormValues(
        values,
        this.props.selectedCMS1500,
        scheduleId
      )

      cms1500.isNew =
        this.props.selectedCMS1500?.isNew != undefined
          ? this.props.selectedCMS1500.isNew
          : true
      if (this.props.episodeOfCare && cms1500.isNew) {
        cms1500.organizationId = this.props.episodeOfCare.organizationId
      }
      this.props.saveCMS1500!(cms1500)
        .then(() => {
          formikBag.setSubmitting(false)
          this.setState({ hasNotSubmitted: false })
          if (this.state.reload) {
            if (this.props.selectedQuote) {
              this.props.rerouteToPath!(
                `/claim/${cms1500.id}/quote/${this.props.selectedQuote!.id}`
              )
            } else {
              sessionStorage.setItem('patientTab', '0')
              sessionStorage.setItem('eocTab', '0')
              this.props.rerouteToPath!(
                `/claim/${cms1500.id}/episodeOfCare/${
                  this.props.selectedEpisodeOfCare!.id
                }`
              )
            }
            this.setState({ appealType: undefined }, () => this.setup())
          } else {
            if (sessionStorage.getItem('claimTab') === '1') {
              this.props.setReturn!()
              sessionStorage.setItem('claimTab', '0')
              this.props.closeTab!()
            }
            if (this.props.selectedQuote === undefined) {
              this.props.rerouteToPath!('/patients')
            } else {
              this.props.rerouteToPath!('/quotes')
            }
          }
        })
        .catch(() => formikBag.setSubmitting(false))
    }
  }

  private diagnosticSchema = Yup.object().shape({
    code: Yup.string(),
  })

  private buildSchema(isCurrentOrganizationSurgical?: boolean) {
    if (isCurrentOrganizationSurgical) {
      return Yup.object().shape({
        providerTypeId: Yup.string().required('Provider Type is required for completion'),
        dueDate: Yup.date().required('Due Date is required for completion'),
        incomingOrOutgoingDate: Yup.date().required(
          'Date Received is required for completion'
        ),
        q21Diagnosis: Yup.array()
          .of(this.diagnosticSchema)
          .test(
            'at-least-one',
            'At least one diagnosis required for completion.',
            (items) => {
              return items.some((item: { code: any }) =>
                item.code.match(/^(.*)?\S+(.*)?$/)
              )
            }
          ),
        q24: Yup.array().of(
          Yup.object({
            aDateOfServiceFrom: Yup.date().required(
              'Date of Service Required for completion'
            ),
            dProcedureCptHcpcs: Yup.string().required(
              'CPT or HCPCS Code is required for completion.'
            ),
            fCharges: Yup.string().required('Charges are required for completion.'),
          })
        ),
        q28TotalCharge: Yup.string().required('Total Charge is Required for completion.'),
        q30ReservedNucc: Yup.number().test(
          'Not 0',
          'Value cannot be 0.',
          (value) => value !== 0
        ),
      })
    } else {
      return Yup.object().shape({
        providerTypeId: Yup.string().required('Provider Type is required'),
        q21Diagnosis: Yup.array()
          .of(this.diagnosticSchema)
          .test('at-least-one', 'At least one diagnosis required.', (items) => {
            return items.some((item: { code: any }) => item.code.match(/^(.*)?\S+(.*)?$/))
          }),
        q30ReservedNucc: Yup.number().test(
          'Not 0',
          'Value cannot be 0.',
          (value) => value !== 0
        ),
      })
    }
  }
}

const InjectedReceiveClaimProps = inject<
  IStores,
  IReceiveClaimProps,
  Partial<IReceiveClaimProps>,
  any
>((stores: IStores) => ({
  checkDuplicateBills: stores.cms1500.checkDuplicateBills,
  closeModal: stores.cms1500.closeDialog,
  duplicateCMS1500: stores.cms1500.duplicateCMS1500,
  episodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  isCMS1500Loading: stores.cms1500.isLoading,
  isCurrentOrganizationSurgical: stores.global.isCurrentOrganizationSurgical,
  isEpisodeOfCareLoading: stores.patientEdit.isLoading,
  isLoading: stores.quotes.isLoading,
  isModalOpen: stores.cms1500.isModalOpen,
  loadClaim: stores.cms1500.loadCMS1500,
  loadEpisodeOfCare: stores.patientEdit.getEpisodeOfCareById,
  openModal: stores.cms1500.openDialog,
  rerouteToPath: stores.global.rerouteToPath,
  saveCMS1500: stores.cms1500.saveCMS1500,
  selectedCMS1500: stores.cms1500.selectedCMS1500,
  selectedEpisodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  selectedLocation: stores.locations.selectedEditClaimLocation,
  selectedSchedule: stores.schedules.selectedSchedule,
  setSelectedCMS1500: stores.cms1500.setSelectedCMS1500,
  setSelectedEpisodeOfCare: stores.patientEdit.setSelectedEpisodeOfCare,
  setSelectedLocation: stores.locations.setSelectedEditClaimLocation,
  setSelectedSchedule: stores.schedules.setSelectedSchedule,
  scheduleItems: stores.patientEdit.scheduleItems,
  locationItems: stores.schedules.scheduleItems,
  setSelectedScheduleById: stores.schedules.setSelectedScheduleById,
  getScheduledOptions: stores.patientEdit.getScheduledOptions,
  openDialogWithSchedule: stores.schedules.openDialogWithSchedule,
  openProviderLookupDialog: stores.schedules.openClaimProviderLookupDialog,
  getLocationById: stores.locations.getLocationById,
  isProviderLookupDialogOpen: stores.schedules.isClaimProviderLookupDialogOpen,
  showContractModal: stores.cms1500.openProviderGroupContractDialog,
  setReturn: stores.global.setReturn,
  closeTab: stores.global.closeTab,
  setGetClaimValuesForStaticAction: stores.cms1500.setGetClaimValuesForStaticAction,
  contractModalIsOpen: stores.cms1500.contractModalIsOpen,
  user: stores.global.user,
}))(ReceiveClaim)

export default withTheme(withStyles(styles)(InjectedReceiveClaimProps))
