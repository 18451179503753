import Axios from 'axios'
import { pagedList } from 'src/viewModels/pagedList'
import { IBillOutSearchRequest } from '../viewModels/BillOutSearchRequest'
import createPdfObjUrl from '../utils/CreatePdfObjUrl'
import { IApplyPaymentRequest } from 'src/viewModels/ApplyPaymentRequest'
import { LineItemDistributionDetail } from 'src/viewModels/LineItemDistributionDetail'
import { LineItemSummaryResult } from 'src/viewModels/LineItemSummaryResult'
import { IPaymentDetailSummaryResult } from 'src/viewModels/PaymentDetailSummaryResult'
import { BilloutLogSearchRequest } from 'src/viewModels/BilloutLogSearchRequest'
import { ProcessReceivedCheckResults } from 'src/viewModels/ProcessReceivedCheckResult'
import { VerifyCheckRequest } from 'src/viewModels/VerifyCheckRequest'
import { CheckCorrectionRequest } from 'src/viewModels/CheckCorrectionRequest'
import { CheckCorrectionResult } from 'src/viewModels/CheckCorrectionResult'
import { IBilloutRequest } from 'src/viewModels/workflow/BilloutRequest'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function searchBillOuts(req: IBillOutSearchRequest): Promise<pagedList> {
  const response = await Axios.post<pagedList>(`${base_url}/api/bill-out/search`, req)

  return response.data
}

export async function searchPayments(req: IBillOutSearchRequest): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/bill-out/payment-search`,
    req
  )

  return response.data
}

export async function complete1500BillOut(
  id: string,
  items: LineItemDistributionDetail[]
): Promise<string> {
  const response = await Axios.put<string>(
    `${base_url}/api/bill-out/${id}/complete-outgoing`,
    items
  )

  return response.data
}

export async function createOutgoingCMS1500(
  id: string,
  req: LineItemDistributionDetail[],
  workflow?: boolean
): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/bill-out/${id}/preview-outgoing`,
    req
  )

  if (workflow) {
    window.open(createPdfObjUrl(response.data), 'new', 'width=1200,height=800')
  } else {
    window.open(createPdfObjUrl(response.data), '_blank')
  }

  return response.data
}

export async function previewCMS1500InWorkflow(
  id: string,
  req: IBilloutRequest,
  workflow?: boolean
): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/bill-out/${id}/workflow-preview-outgoing`,
    req
  )

  if (workflow) {
    window.open(createPdfObjUrl(response.data), 'new', 'width=1200,height=800')
  } else {
    window.open(createPdfObjUrl(response.data), '_blank')
  }

  return response.data
}

export async function applyPayment(
  req: IApplyPaymentRequest
): Promise<ProcessReceivedCheckResults> {
  const response = await Axios.post<ProcessReceivedCheckResults>(
    `${base_url}/api/bill-out/${req.cms1500Id}/apply-payment`,
    req
  )
  return response.data
}

export async function retrieveLineItemsWithBalance(
  req: string
): Promise<LineItemSummaryResult[]> {
  const response = await Axios.get<LineItemSummaryResult[]>(
    `${base_url}/api/bill-out/${req}/line-item-with-balance`
  )

  return response.data
}

export async function retrieveLineItems(req: string): Promise<LineItemSummaryResult[]> {
  const response = await Axios.get<LineItemSummaryResult[]>(
    `${base_url}/api/bill-out/${req}/line-item`
  )

  return response.data
}

export async function getBillOutPaymentDetails(
  req: string
): Promise<IPaymentDetailSummaryResult> {
  const response = await Axios.get<IPaymentDetailSummaryResult>(
    `${base_url}/api/bill-out/${req}/payment-details`
  )

  return response.data
}

export async function resendBill(id: string): Promise<string> {
  const response = await Axios.post<string>(`${base_url}/api/bill-out/${id}/resend-bill`)
  window.open(createPdfObjUrl(response.data), '_blank')
  return response.data
}

export async function GetBilloutLogByBilloutId(
  cms1500Id: string,
  req?: BilloutLogSearchRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/bill-out/${cms1500Id}/bill-out-log`,
    req
  )
  return response.data
}

export async function DismissBill(id: string): Promise<string> {
  const response = await Axios.post<string>(`${base_url}/api/bill-out/${id}/dismiss`)
  return response.data
}

export async function VerifyCheck(
  req: VerifyCheckRequest
): Promise<ProcessReceivedCheckResults> {
  const response = await Axios.post<ProcessReceivedCheckResults>(
    `${base_url}/api/lookup/verify-received-check`,
    req
  )
  return response.data
}

export async function ValidateCheckCorrection(
  req: CheckCorrectionRequest
): Promise<CheckCorrectionResult> {
  const response = await Axios.post<CheckCorrectionResult>(
    `${base_url}/api/lookup/validate-check-correction`,
    req
  )
  return response.data
}

export async function ReverseBillOutOrDismissal(id: string): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/bill-out/${id}/reverse-bill-out`
  )
  return response.data
}

export async function DismissBillOutRecord(id: string): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/bill-out/${id}/dismiss-record`
  )
  return response.data
}

export async function CreateCompleteBillOutPdf(id: string): Promise<string> {
  const response = await Axios.post<string>(`${base_url}/api/bill-out/${id}/complete`)
  return response.data
}
