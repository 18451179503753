import { DefaultComponent, IComponent } from '../Definitions'

export interface IComponentFormValues {
  readonly id: string
  name: string
  isActive: boolean
  isDefaultComponent: boolean
  isLocked: boolean
  isSimpleComponent: boolean
  organizationId: string
  excludeFromPTCount: boolean
  excludeFromBundle: boolean
}

function setDefaultValues(): IComponentFormValues {
  return {
    id: '',
    isActive: true,
    isDefaultComponent: false,
    isLocked: false,
    isSimpleComponent: false,
    name: '',
    organizationId: '',
    excludeFromPTCount: false,
    excludeFromBundle: false,
  }
}

function setValuesFromComponent(component: IComponent): IComponentFormValues {
  return {
    id: component.id || '',
    isActive: component.isActive ?? true,
    isDefaultComponent: component.isDefaultComponent || false,
    isLocked: component.isLocked || false,
    isSimpleComponent: component.isSimpleComponent || false,
    name: component.name || '',
    organizationId: component.organizationId || '',
    excludeFromPTCount: component.excludeFromPTCount || false,
    excludeFromBundle: component.excludeFromBundle || false,
  }
}

export function createIComponentFormValues(component?: IComponent): IComponentFormValues {
  if (component === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromComponent(component)
  }
}

export function toIComponent(
  formValues: IComponentFormValues,
  original?: IComponent
): IComponent {
  const component = original === undefined ? DefaultComponent() : original
  return {
    ...component,
    id: formValues.id,
    isActive: formValues.isActive,
    isDefaultComponent: formValues.isDefaultComponent,
    isSimpleComponent: formValues.isSimpleComponent,
    name: formValues.name,
    organizationId: formValues.organizationId,
    excludeFromPTCount: formValues.excludeFromPTCount,
    excludeFromBundle: formValues.excludeFromBundle,
  }
}
