import { connect, FormikContext } from 'formik'
import * as React from 'react'
import Button from '../common/Button/Button'

export interface IFileFormValues {
  name: string
  mime: string
  content: string
}

export function defaultFileFormValues(): IFileFormValues {
  return {
    content: '',
    mime: '',
    name: '',
  }
}

interface IFileUploadProps {
  name: string
  accept?: string
  buttonText: string
}

interface IFormikProps {
  formik: FormikContext<IFileFormValues>
}

interface IFileUploadState {
  isLoading: boolean
}

class FileUploadField extends React.Component<
  IFileUploadProps & IFormikProps,
  IFileUploadState
> {
  public state = {
    isLoading: false,
  }
  public upload: HTMLInputElement | null

  public render() {
    const { formik, accept = '', buttonText } = this.props
    const { isLoading } = this.state
    return (
      <div>
        <Button
          component="label"
          disabled={formik.isSubmitting}
          isLoading={isLoading}
          onClick={this.onClick}
          variant="contained"
          color="primary"
        >
          {buttonText}
        </Button>
        <input
          type="file"
          accept={accept}
          onChange={this.handleFileChange}
          ref={(ref) => (this.upload = ref)}
          style={{ display: 'none' }}
        />
      </div>
    )
  }

  private getFieldName = (field: string) => `${this.props.name}.${field}`

  public onClick = () => this.upload && this.upload.click()

  public handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { formik } = this.props
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onloadstart = () => {
        this.setState({ isLoading: true })
      }
      reader.onload = () => {
        if (reader) {
          const content = (reader.result! as string).split(',')[1]
          const mime = file.type.split('/')[1]
          const name = file.name
          this.setState({
            isLoading: false,
          })

          formik.setFieldValue(this.getFieldName('content'), content)
          formik.setFieldValue(this.getFieldName('name'), name)
          formik.setFieldValue(this.getFieldName('mime'), mime)
        }
      }
      reader.readAsDataURL(file)
    }
  }
}

export default connect<IFileUploadProps, IFileFormValues>(FileUploadField)
