export interface ILocationSearchRequest {
  filter?: string
  providerTypeId?: string
  tags?: string[]
  procedures?: string
  providerGroup?: string
  latitude?: number
  longitude?: number
  range?: number
  page?: number
  pageSize?: number
}

export class LocationSearchRequest implements ILocationSearchRequest {
  filter?: string | undefined
  providerTypeId?: string | undefined
  tags?: string[] | undefined
  procedures?: string | undefined
  providerGroup?: string | undefined
  latitude?: number | undefined
  longitude?: number | undefined
  range?: number | undefined
  page?: number | undefined
  pageSize?: number | undefined
}
