import { Grid } from '@material-ui/core'
import { Storage } from '@material-ui/icons'
import { FormikActions } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import { noWhitespaceString } from '../common/YupExtensions'
import { IPreExistingCondition } from '../Definitions'
import { IStores } from '../Stores'
import * as Yup from 'yup'

const PreExistingConditionSchema = Yup.object().shape({
  name: noWhitespaceString('Name is required', true),
})

interface IPreExistingConditionDialogProps {
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean
  preExistingCondition?: IPreExistingCondition
  savePreExistingCondition?: (
    preExistingCondition: IPreExistingCondition
  ) => Promise<void>
}

class PreExistingConditionDialog extends React.Component<IPreExistingConditionDialogProps> {
  public save = (
    values: IPreExistingCondition,
    formikBag: FormikActions<IPreExistingCondition>
  ) => {
    this.props.savePreExistingCondition!({ ...values }).finally(() =>
      formikBag.setSubmitting(false)
    )
  }

  public render() {
    const { close, isLoading, isOpen, preExistingCondition } = this.props
    return (
      <ESDialog
        close={close!}
        initialValues={{ ...preExistingCondition } as IPreExistingCondition}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={
          preExistingCondition!.isNew
            ? 'Add New Pre-Existing Condition'
            : 'Edit Pre-Existing Condition'
        }
        titleIcon={<Storage />}
        validationSchema={PreExistingConditionSchema}
      >
        {() => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FullWidthField required autoFocus name="name" label="Name" />
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }
}

const InjectedPreExistingConditionDialog = inject<
  IStores,
  IPreExistingConditionDialogProps,
  Partial<IPreExistingConditionDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.preExistingConditions.closeDialog,
  isLoading: stores.preExistingConditions.isLoading,
  isOpen: stores.preExistingConditions.isModalOpen,
  preExistingCondition: stores.preExistingConditions.selectedPreExistingCondition,
  savePreExistingCondition: stores.preExistingConditions.savePreExistingCondition,
}))(PreExistingConditionDialog)

export default InjectedPreExistingConditionDialog
