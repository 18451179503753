import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ReceiptOutlined, Warning } from '@material-ui/icons'
import React from 'react'
import ReactPaginate from 'react-paginate'
import { pagedList } from 'src/viewModels/pagedList'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { inject } from 'mobx-react'
import { IStores } from 'src/Stores'
import moment from 'moment'
import { GetQuoteLogById } from 'src/services/QuoteService'
import { SearchRequestBase } from 'src/viewModels/SearchRequestBase'
import { QuoteStatusLogDTO } from 'src/viewModels/quotes/QuoteStatusLogDTO'
import { QuoteStatus } from 'src/utils/QuoteStatus'
import QuoteLogLongCommentsModal from './QuoteLogLongCommentsModal'

interface IQuoteLogModalProps {
  isOpen?: boolean
  closeModal?: () => void
  quotePatientHeader?: string
  quoteId?: string
  openCommentsModal?: (comments: string) => void
}

class QuoteLogModal extends React.Component<IQuoteLogModalProps> {
  public async componentDidMount(): Promise<void> {
    await this.getQuoteLogData()
  }

  public quoteStatus = [
    { key: QuoteStatus.Draft.toString(), text: 'Draft' },
    { key: QuoteStatus.Imported.toString(), text: 'Imported' },
    { key: QuoteStatus.NeedsQuote.toString(), text: 'Needs Quote' },
    { key: QuoteStatus.NeedsRecon.toString(), text: 'Needs Reconcilation' },
    {
      key: QuoteStatus.PendingCareCoordinator.toString(),
      text: 'Pending Care Coordinator',
    },
    { key: QuoteStatus.PendingContract.toString(), text: 'Pending Contract' },
    { key: QuoteStatus.PendingFinance.toString(), text: 'Pending Finance' },
    { key: QuoteStatus.Quoted.toString(), text: 'Quoted' },
    { key: QuoteStatus.Reconciled.toString(), text: 'Reconciled' },
    { key: QuoteStatus.Dismissed.toString(), text: 'Dismissed' },
  ]

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private getQuoteLogData = async () => {
    const { page, pageSize } = this.state
    const data: SearchRequestBase = {
      page,
      pageSize,
    }
    this.setState({ isLoading: true })
    await GetQuoteLogById(this.props.quoteId!, data).then(async (response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        isLoading: false,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getQuoteLogData())
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems === 0
  }

  private mapLogGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          <TableCell>
            {record.urgent && (
              <Tooltip title="Urgent">
                <Warning color="error" fontSize="small" />
              </Tooltip>
            )}
          </TableCell>
          <TableCell>
            {record.createdDate ? moment(record.createdDate).format('MM/DD/YYYY') : ''}
          </TableCell>
          <TableCell>
            {record.createdDate ? moment(record.createdDate).format('hh:mm A') : ''}
          </TableCell>
          <TableCell>{record.createdByName}</TableCell>
          <TableCell>
            {
              this.quoteStatus
                .filter((x) => x.key == record.quoteStatus.toString())
                ?.pop()?.text
            }
          </TableCell>
          <TableCell>
            {record.commentsShort ? (
              <>
                <pre>{record.commentsShort}</pre>
                <Link
                  underline="always"
                  onClick={() => this.props.openCommentsModal!(record.comments)}
                >
                  More
                </Link>
              </>
            ) : (
              <pre>{record.comments}</pre>
            )}
          </TableCell>
        </TableRow>
      ))
    }
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  public state = {
    gridData: new pagedList().items as QuoteStatusLogDTO[],
    page: 1,
    pageCount: 1,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 5,
    isLoading: true,
  }

  public render() {
    const { quotePatientHeader } = this.props
    var modalTitle = `Quote Log - ${quotePatientHeader}`
    return (
      <>
        <QuoteLogLongCommentsModal />
        <Dialog
          key={this.props.quoteId}
          fullWidth={true}
          maxWidth="xl"
          open={this.props.isOpen!}
          disableBackdropClick
        >
          <DialogTitle style={{ padding: 0 }}>
            <ResponsiveAppBar
              title={modalTitle}
              pageIcon={<ReceiptOutlined />}
              isModal={true}
            />
          </DialogTitle>
          <DialogContent style={{ padding: '12px 12px 8px' }}>
            {this.state.isLoading && (
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '2',
                }}
              >
                <CircularProgress size={100} />
              </div>
            )}
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              alignContent="center"
              alignItems="flex-start"
              wrap="wrap"
            >
              <Grid item xs={12} style={{ minWidth: '750px' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className="gridPadding">
                      <TableCell></TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Created Time</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Comments</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ width: '100%' }}>{this.mapLogGridData()}</TableBody>
                </Table>
                <div className="pagination-row">
                  {this.paginationInfo()}
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    onPageChange={this.handlePageChange}
                    forcePage={this.state.page - 1}
                    pageCount={this.state.pageCount}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    //@ts-ignore
                    renderOnZeroPageCount={null}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeModal} data-cy="close">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const InjectedQuoteLogModal = inject<
  IStores,
  IQuoteLogModalProps,
  Partial<IQuoteLogModalProps>,
  any
>((stores: IStores) => ({
  isOpen: stores.quotes.isQuoteLogModalOpen,
  closeModal: stores.quotes.closeQuoteLogModal,
  quotePatientHeader: stores.quotes.selectedQuotePatientHeader,
  quoteId: stores.quotes.selectedQuoteId,
  openCommentsModal: stores.quotes.openLongCommentsModal,
}))(QuoteLogModal)

export default InjectedQuoteLogModal
