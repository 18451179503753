import { IHaveAddressFormValues } from '../common/AddressField/AddressField'
import {
  createAddressFormValues,
  toAddressDto,
} from '../common/AddressField/AddressFormValues'
import { DefaultReferringDoctor, IReferringDoctor } from '../Definitions'

export interface IReferringDoctorFormValues extends IHaveAddressFormValues {
  readonly id: string
  npi: string
  firstName: string
  lastName: string
  suffix: string
  phoneNumber: string
  faxNumber: string
  preferences: string
  isActive: boolean
  legacyId: string
}

function setDefaultValues(): IReferringDoctorFormValues {
  return {
    address: createAddressFormValues(),
    faxNumber: '',
    firstName: '',
    id: '',
    isActive: true,
    lastName: '',
    legacyId: '',
    npi: '',
    phoneNumber: '',
    preferences: '',
    suffix: '',
  }
}

function setValuesFromReferringDoctor(
  referringDoctor: IReferringDoctor
): IReferringDoctorFormValues {
  return {
    address: createAddressFormValues(referringDoctor.address),
    faxNumber: referringDoctor.faxNumber || '',
    firstName: referringDoctor.firstName || '',
    id: referringDoctor.id || '',
    isActive: referringDoctor.isActive || false,
    lastName: referringDoctor.lastName || '',
    legacyId: referringDoctor.legacyId || '',
    npi: referringDoctor.npi || '',
    phoneNumber: referringDoctor.phoneNumber || '',
    preferences: referringDoctor.preferences || '',
    suffix: referringDoctor.suffix || '',
  }
}

export function createIReferringDoctorFormValues(
  referringDoctor?: IReferringDoctor
): IReferringDoctorFormValues {
  if (referringDoctor === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromReferringDoctor(referringDoctor)
  }
}

export async function toIReferringDoctor(
  formValues: IReferringDoctorFormValues,
  original?: IReferringDoctor
): Promise<IReferringDoctor> {
  const referringDoctor = original === undefined ? DefaultReferringDoctor() : original
  const address = await toAddressDto(formValues.address!)
  return {
    ...referringDoctor,
    address,
    faxNumber: formValues.faxNumber,
    firstName: formValues.firstName,
    id: formValues.id,
    isActive: formValues.isActive,
    lastName: formValues.lastName,
    legacyId: formValues.legacyId,
    npi: formValues.npi,
    phoneNumber: formValues.phoneNumber,
    preferences: formValues.preferences,
    suffix: formValues.suffix,
  }
}
