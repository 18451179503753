import { Edit } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultPatient, IPatient } from '../../Definitions'
import { Client, IPatientDto, PatientDto, SwaggerResponse } from '../../generated_client'
import DataTableStore from '../DataTableStore'
import NotificationStore from '../NotificationStore'
import { getHeaderInfo } from 'src/services/ClaimService'
import React from 'react'
import { Tooltip } from '@material-ui/core'
import { ClaimHeaderDetailsSummaryResult } from 'src/viewModels/ClaimHeaderDetailsSummaryResult'

export default class PatientsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public patients: IPatient[]
  @observable
  public selectedPatient?: IPatient
  @observable
  public dataTableStore: DataTableStore<IPatientDto, IPatient>
  @observable
  public isConfirmDialogOpen: boolean
  @observable
  public isPatientSMSDialogOpen: boolean
  @observable
  public isSMSLoading: boolean
  @observable
  public headerDetails?: ClaimHeaderDetailsSummaryResult
  @observable
  public isUploadModalOpen: boolean
  @observable
  public patientToQuotes?: boolean
  @observable
  public updateParentStateAfterModal?: () => void

  constructor(
    private client: Client,
    private notificationStore: NotificationStore
  ) {}

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedPatient = undefined
  }

  @action.bound
  public openUploadModal(patientId: string) {
    this.getPatientById(patientId).then(() => (this.isUploadModalOpen = true))
  }

  @action.bound
  public closeUploadModal() {
    this.isUploadModalOpen = false
    if (this.updateParentStateAfterModal) {
      this.updateParentStateAfterModal()
    } else {
      this.selectedPatient = undefined
    }
  }

  @action.bound
  public setParentUpdateFunction(parentfunction?: () => void) {
    this.updateParentStateAfterModal = parentfunction
  }

  @action.bound
  public getHeaderInfo(claimId: string) {
    this.isLoading = true
    return getHeaderInfo(claimId).then((resp) => {
      this.headerDetails = resp
    })
  }

  @action.bound
  public openPatientSMSDialog() {
    this.isPatientSMSDialogOpen = true
  }

  @action.bound
  public closePatientSMSDialog() {
    this.isPatientSMSDialogOpen = false
  }

  @action.bound
  public sendPatientSMS(patient: IPatient, contents: string) {
    this.isSMSLoading = true
    return this.client
      .sendPatientSMS(patient.id, contents)
      .then(() => {
        runInAction(() => {
          this.notificationStore.sendNotification('200', 'SMS Message has been sent.', {
            variant: 'success',
          })
          this.closePatientSMSDialog()
        })
      })
      .finally(() => {
        runInAction(() => {
          this.isSMSLoading = false
        })
      })
  }

  @action.bound
  public disablePatient(patient: IPatient) {
    this.isLoading = true
    return this.client
      .archivePatient(patient.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getAllPatients() {
    this.isLoading = true
    return this.client.getAllPatients().then((resp: SwaggerResponse<IPatientDto[]>) =>
      runInAction(() => {
        this.patients = this.setupPatients(resp.result)
      })
    )
  }

  @action.bound
  public getPatientById(id: string) {
    this.isLoading = true
    return this.client.getPatientById(id).then((resp: SwaggerResponse<IPatientDto>) =>
      runInAction(() => {
        this.selectedPatient = this.addDefaultFields(resp.result)
      })
    )
  }

  @action.bound
  public openDialogWithPatient(patient: IPatient) {
    this.isModalOpen = true
    this.selectedPatient = patient
  }

  @action.bound
  public recoverPatient(patient: IPatient) {
    this.isLoading = true
    return this.client
      .reactivatePatient(patient.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public openConfirmDialog() {
    this.isConfirmDialogOpen = true
  }

  @action.bound
  public closeConfirmDialog() {
    this.isConfirmDialogOpen = false
  }

  @action.bound
  public resetPatient() {
    this.patientToQuotes = false
  }

  @action.bound
  public savePatient(patient: IPatient) {
    this.isLoading = true
    let promise
    const patientDto = new PatientDto(patient)

    if (patient.isNew) {
      promise = this.client.createPatient(patientDto)
    } else {
      promise = this.client.updatePatient(patient.id, patientDto)
    }
    return promise.finally(() =>
      runInAction(() => {
        this.isLoading = false
      })
    )
  }

  @action.bound
  public setSelectedPatient(patient: IPatient | undefined) {
    this.selectedPatient = patient
  }

  private setupPatients = (patients: IPatientDto[]) => {
    return patients.map((x) => this.setupPatientMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (patient: IPatientDto): IPatient => {
    return { ...DefaultPatient(), ...patient }
  }

  @action.bound
  public editPatient(patientId: string) {
    sessionStorage.setItem('patientTab', '1')
    window.open(`/patients/patient/${patientId}`, '_blank')
  }

  private setupPatientMenuItems = (patient: IPatient) => {
    if (patient.isActive) {
      patient.menuReplacer = React.createElement(Tooltip, {
        title: 'Edit Patient',
        children: React.createElement(Edit, {
          onClick: () => this.editPatient(patient.id),
        }),
      })
    }
    return patient
  }
}
