import { DocumentType } from 'src/models/enums/DocumentType.enum'

export interface IAuthorizationLogDTO {
  id: string
  createdDate: string
  authorizationType: DocumentType
  authorizationTypeString: string
  data: string
  createdBy: string
  createdByName: string
  scheduleId: string
}

export class AuthorizationLogDTO implements IAuthorizationLogDTO {
  public id: string
  public createdDate: string
  authorizationType: DocumentType
  public authorizationTypeString: string
  public data: string
  public createdBy: string
  public createdByName: string
  public scheduleId: string

  constructor(
    data: string,
    scheduleId: string,
    type: DocumentType,
    typeName?: string | undefined
  ) {
    this.data = data
    this.scheduleId = scheduleId
    this.authorizationType = type
    if (typeName !== undefined) {
      this.authorizationTypeString = typeName
    }
  }
}
