import { createStyles, withStyles, WithStyles } from '@material-ui/core'
import { Description } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultInsuranceCompany, IInsuranceCompany } from '../Definitions'
import { IStores } from '../Stores'

const styles = () => createStyles({})

interface IInsuranceCompaniesProps extends WithStyles<typeof styles> {
  dataTableManager?: IDataTableManager<IInsuranceCompany>
  disableInsuranceCompany?: (insuranceCompany: IInsuranceCompany) => void
  getColumnSettingsAndInsuranceCompanies?: () => void
  isModalOpen?: boolean
  openInsuranceCompanyPage?: (insuranceCompany: IInsuranceCompany | undefined) => void
  rerouteToPath?: (path: string) => void
  recoverInsuranceCompany?: (insuranceCompany: IInsuranceCompany) => void
  setSelectedInsuranceCompany?: (insuranceCompany: IInsuranceCompany) => void
}
class InsuranceCompanies extends React.Component<IInsuranceCompaniesProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndInsuranceCompanies!()
  }

  public render() {
    return (
      <>
        <Helmet>
          <title>Insurance Companies</title>
        </Helmet>
        <ResponsiveAppBar title="Insurance Companies" pageIcon={<Description />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={true}
          enableToggleColumns={true}
          addButtonOnClick={this.handleOpenDialog(DefaultInsuranceCompany(true))}
        />
      </>
    )
  }

  public handleOpenDialog = (insuranceCompany: IInsuranceCompany) => () => {
    this.props.setSelectedInsuranceCompany!(insuranceCompany)
    this.props.openInsuranceCompanyPage!(undefined)
  }
}

const InjectedInsuranceCompanies = inject<
  IStores,
  IInsuranceCompaniesProps,
  Partial<IInsuranceCompaniesProps>,
  any
>((stores: IStores) => ({
  dataTableManager: stores.insuranceCompanies.dataTableStore,
  disableInsuranceCompany: stores.insuranceCompanies.disableInsuranceCompany,
  getColumnSettingsAndInsuranceCompanies:
    stores.insuranceCompanies.getColumnSettingsAndInsuranceCompanies,
  isModalOpen: stores.insuranceCompanies.isModalOpen,
  openInsuranceCompanyPage: stores.insuranceCompanies.openInsuranceCompanyPage,
  recoverInsuranceCompany: stores.insuranceCompanies.recoverInsuranceCompany,
  rerouteToPath: stores.global.rerouteToPath,
  setSelectedInsuranceCompany: stores.insuranceCompanies.setSelectedInsuranceCompany,
}))(InsuranceCompanies)

export default withStyles(styles)(InjectedInsuranceCompanies)
