import { createStyles, Grid, Theme, withStyles, WithStyles } from '@material-ui/core'
import { GridDirection, GridItemsAlignment } from '@material-ui/core/Grid'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import * as React from 'react'

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      margin: 4,
      padding: spacing(2),
      paddingTop: 28,
    },
    tableHeader: {
      height: 60,
      marginBottom: spacing(1),
      marginLeft: spacing(1),
    },
    tableHeaderTitle: {
      fontSize: '20px',
      paddingTop: '4px',
    },
  })

interface ITitleBarProps extends WithStyles {
  title: string
  titleComponent?: JSX.Element
  titleButton?: JSX.Element
  isTitleComponentPushedRight?: boolean
  icon?: React.ComponentType<SvgIconProps>
  formGridDirection?: GridDirection
  formGridAlignItems?: GridItemsAlignment
  headerStyle?: React.CSSProperties
}

const TitleBar: React.SFC<ITitleBarProps> = ({
  classes,
  icon,
  isTitleComponentPushedRight = false,
  title,
  titleComponent,
  titleButton,
  headerStyle = {},
}) => {
  const componentStyles = () => {
    if (isTitleComponentPushedRight) {
      return {
        marginLeft: 'auto',
      } as React.CSSProperties
    } else {
      return undefined
    }
  }

  const Icon = icon

  const cardHeader = (
    <Grid
      container
      item
      direction="row"
      spacing={2}
      alignItems="center"
      className={classes.tableHeader}
      xs={12}
      style={{ marginLeft: 0, ...headerStyle }} // this is needed because material UI grid spacing applies -8px margin.
    >
      <Grid item>{Icon && <Icon style={{ fontSize: '32px' }} color="secondary" />}</Grid>
      <Grid item className={classes.tableHeaderTitle}>
        {title}&nbsp;{titleButton}
      </Grid>
      {titleComponent && (
        <Grid item style={componentStyles()}>
          {titleComponent}
        </Grid>
      )}
    </Grid>
  )

  return (
    <Grid container direction="row" spacing={1}>
      {cardHeader}
    </Grid>
  )
}

export default withStyles(styles)(TitleBar)
