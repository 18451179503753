import {
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  Select,
} from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank, LocalHospital } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Field, FormikErrors } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import * as React from 'react'
import { ChangeEvent } from 'react'
import AddressField from '../common/AddressField/AddressField'
import CardWithTitle from '../common/CardWithTitle/CardWithTitle'
import FullWidthField from '../common/FullWidthField'
import IsActiveToggle from '../common/IsActiveToggle'
import { ContactDto, ProviderTypeDto, TagDto } from '../generated_client'
import { IProviderFormValues } from './ProviderFormValues'

const styles = ({ spacing }: Theme) =>
  createStyles({
    card: {
      border: '1px',
      borderColor: 'lightgray',
      borderRadius: '10px',
      borderStyle: 'solid',
      color: 'gray',
      hyphens: 'none',
      overflowWrap: 'break-word',
      padding: spacing(2),
      wordWrap: 'break-word',
    },
    paper: {
      padding: spacing(2),
    },
  })

const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 400,
    },
  },
}

interface IProviderDetailsState {
  expandedAddressPanel: string
  selectedTags: Map<string, string>
  expandedContractPanel: string
}
interface IProviderDetailProps extends WithStyles<typeof styles> {
  errors: FormikErrors<IProviderFormValues>
  providerTypes: ProviderTypeDto[]
  setFieldValue: (filter: string, value: any) => void
  tags?: TagDto[]
  importantContacts: ContactDto[]
  values: IProviderFormValues
  isNew: boolean
  selectedTags?: TagDto[]
  addSelectedTag?: (tag: TagDto) => void
  removeSelectedTag?: (tagId: string) => Promise<void>
  forceUpdate?: () => void
}

class ProviderDetails extends React.Component<
  IProviderDetailProps,
  IProviderDetailsState
> {
  constructor(props: IProviderDetailProps) {
    super(props)
    this.state = {
      expandedAddressPanel: props.isNew ? 'addressPanel' : '',
      expandedContractPanel: 'contractPanel',
      selectedTags: new Map<string, string>(),
    }
  }

  private handleIsActiveChange =
    (values: IProviderFormValues, setFieldValue: (field: string, value: any) => void) =>
    () => {
      setFieldValue('isActive', !values.isActive)
    }

  private setSelectedTags = (event: ChangeEvent<HTMLSelectElement>) => {
    if (this.state.selectedTags.has(event.currentTarget.dataset.value!)) {
      this.state.selectedTags.delete(event.currentTarget.dataset.value!)
      this.setState({ selectedTags: this.state.selectedTags })
      this.props.removeSelectedTag!(event.currentTarget.dataset.value!).then(() => {
        this.props.forceUpdate!()
      })
    } else {
      this.state.selectedTags.set(
        event.currentTarget.dataset.value!,
        event.currentTarget.dataset.name!
      )
      this.setState({ selectedTags: this.state.selectedTags })
      var selectedTag = this.props.tags?.find(
        (x) => x.id == event.currentTarget.dataset.value!
      )
      if (selectedTag) {
        this.props.addSelectedTag!(selectedTag)
        this.props.forceUpdate!()
      }
    }
  }

  public render() {
    const { classes, importantContacts, values, setFieldValue } = this.props

    const billingAddressName = values.isBillingAddressSameAsPhysicalAddress
      ? 'physicalAddress'
      : 'billingAddress'

    if (
      this.state.selectedTags.size == 0 &&
      this.props.selectedTags != undefined &&
      this.props.selectedTags.length > 0
    ) {
      this.props.selectedTags?.forEach((tag) => {
        this.state.selectedTags.set(tag.id!, tag.name!)
      })
      this.setState({ selectedTags: this.state.selectedTags })
    }

    return (
      <CardWithTitle title="Provider Group" icon={LocalHospital}>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={6}>
            <FullWidthField
              autoFocus
              name="name"
              label="Name"
              required={true}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={6} />
        </Grid>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={6}>
            <FullWidthField name="legalName" label="Legal Name" variant="outlined" />
          </Grid>
          <Grid item xs={6}>
            <FullWidthField
              name="billingServicer"
              label="Billing Servicer Name"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <ExpansionPanel
          expanded={this.state.expandedAddressPanel === 'addressPanel'}
          onChange={this.handleAddressChange('addressPanel')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color="textSecondary" variant="body2">
              PHYSICAL AND BILLING SERVICER ADDRESSES
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ marginRight: '20px' }}>
            <Grid item container direction="row" spacing={4}>
              <Grid item xs={6}>
                <AddressField
                  name="physicalAddress"
                  label="PHYSICAL ADDRESS"
                  showFax
                  outlined
                />
              </Grid>
              <Grid item xs={6}>
                <AddressField
                  name={billingAddressName}
                  disabled={values.isBillingAddressSameAsPhysicalAddress}
                  label="BILLING SERVICER"
                  header={this.sameAddressCheckbox()}
                  showFax
                  showEmail
                  outlined
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Grid
          item
          container
          direction="row"
          spacing={4}
          alignItems="flex-end"
          style={{ paddingTop: 28 }}
        >
          <Grid item xs={3}>
            <FullWidthField name="ccnField" label="CCN" variant="outlined" />
          </Grid>
          <Grid item xs={2}>
            <FullWidthField
              name="npiNumber"
              label="NPI Number"
              required={false}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={1}>
            <a
              href="https://npiregistry.cms.hhs.gov/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lookup
            </a>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" style={{ maxWidth: '600px' }}>
              <InputLabel shrink={true} variant="outlined">
                Tag
              </InputLabel>
              <Select
                variant="outlined"
                input={<OutlinedInput notched labelWidth={28} />}
                inputProps={{ label: true, notched: true }}
                fullWidth
                id="eventSelect"
                multiple
                value={Array.from(this.state.selectedTags.keys())}
                renderValue={() =>
                  Array.from(this.state.selectedTags.values()).join(', ')
                }
                onChange={this.setSelectedTags}
                MenuProps={MenuProps}
              >
                {this.props.tags?.map((item: TagDto) => (
                  <MenuItem value={item.id} data-name={item.name}>
                    {item.id && this.state.selectedTags.has(item.id) ? (
                      <CheckBox color="secondary" style={{ paddingRight: '10px' }} />
                    ) : (
                      <CheckBoxOutlineBlank
                        color="action"
                        style={{ paddingRight: '10px' }}
                      />
                    )}{' '}
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FullWidthField
              name="notes"
              label="Billing Servicer Notes"
              required={false}
              variant="outlined"
              multiline
              rows={10}
            />
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanel
              expanded={this.state.expandedContractPanel === 'contractPanel'}
              onChange={this.handleContractPanelChange('contractPanel')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="textSecondary" variant="body2">
                  CONTRACT SUMMARY
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ marginRight: '20px' }}>
                <Grid item container direction="column" spacing={2}>
                  <Grid item>
                    <FullWidthField
                      name="contractLink"
                      label="Contract Link"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <FullWidthField
                      name="contractSummary"
                      label="Contract Summary"
                      variant="outlined"
                      multiline
                      rows="5"
                    />
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={6}>
            <IsActiveToggle
              isActive={values.isActive}
              onChange={this.handleIsActiveChange(values, setFieldValue)}
            />
          </Grid>
          <Grid item container direction="row" spacing={5} xs={6}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                {this.buildContactCards(importantContacts, classes)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardWithTitle>
    )
  }

  private buildContactCards = (
    contacts: ContactDto[],
    classes: Record<'card', string>
  ) => {
    return contacts.map((contact) => {
      const title = contact.importantTags!.join(', ') + ' Contact'
      return (
        <Grid
          item
          style={{
            width: '340px',
          }}
          key={contact.id!}
        >
          <Grid className={classes.card}>
            <Grid item>{title}</Grid>
            <Grid
              item
              style={{
                color: 'black',
              }}
            >
              <Typography variant="h6">{contact.name!}</Typography>
            </Grid>
            <Grid item>{contact.title!}</Grid>
            <Grid item>
              <br />
            </Grid>
            <Grid item>{contact.email!}</Grid>
            <Grid item>{contact.officeNumber!}</Grid>
          </Grid>
        </Grid>
      )
    })
  }

  private handleAddressChange = (panel: string) => (__: React.MouseEvent) => {
    this.setState({
      expandedAddressPanel: panel === this.state.expandedAddressPanel ? '' : panel,
    })
  }

  private handleContractPanelChange = (panel: string) => (__: React.MouseEvent) => {
    this.setState({
      expandedContractPanel: panel === this.state.expandedContractPanel ? '' : panel,
    })
  }

  public sameAddressCheckbox() {
    return (
      <Field
        name="isBillingAddressSameAsPhysicalAddress"
        Label={{
          label: 'Same As Physical Address',
        }}
        style={{
          height: '20px',
        }}
        component={CheckboxWithLabel}
      />
    )
  }
}

export default withStyles(styles)(ProviderDetails)
