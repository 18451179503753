import {
  CircularProgress,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import {
  Add,
  Assignment,
  InfoOutlined,
  RemoveCircle,
  UndoRounded,
} from '@material-ui/icons'
import { Form, Formik } from 'formik'
import * as React from 'react'
import { inject } from 'mobx-react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { RouteComponentProps } from 'react-router'
import { Prompt } from 'react-router-dom'
import CardWithTitle from '../common/CardWithTitle/CardWithTitle'
import FullWidthField from '../common/FullWidthField'
import { IProviderTypeDto } from '../generated_client'
import * as Yup from 'yup'
import Constants from '../config'
import QuoteDetails from './QuoteDetails'
import { createIQuoteFormValues, submitQuoteFormValues } from './QuoteFormValues'
import QuoteTotals from './QuoteTotals'
import { IStores } from 'src/Stores'
import { formatCurrency } from 'src/utils/Formatter'
import AddQuoteLineModal from './AddQuoteLineModal'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { QuoteStatus } from 'src/utils/QuoteStatus'
import { CostSourceText } from './CostSourceText'
import { QuoteLineItemType } from 'src/utils/QuoteLineItemType'
import { QuoteInboxItem } from 'src/viewModels/workflow/QuoteInboxItem'

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(5),
    },
    notes: {
      padding: spacing(2),
    },
    totals: {
      padding: spacing(2),
    },
    strikeOut: {
      textDecoration: 'line-through red',
    },
  })

const QuoteSchema = Yup.object().shape({
  adjustedCost: Yup.number()
    .transform(function (value, _originalValue) {
      return this.isType(value) ? value : -1
    })
    .min(0, 'Adjusted Cost must be 0 or greater')
    .max(1000000000000, 'Adjusted Cost must be 1000000000000 or less'),
  targetMargin: Yup.number()
    .transform(function (value, _originalValue) {
      return this.isType(value) ? value : -1
    })
    .min(0, 'Target Margin must be 0% or greater')
    .max(100, 'Target Margin must be 100% or less'),
})

interface ICreateQuoteRouteParams {
  quoteId: string
}

interface ICreateQuoteProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<ICreateQuoteRouteParams> {
  getAllUsers?: (pageSize?: number, roleFilter?: string) => Promise<void>
  getLineItemsTotals?: (lineItems: any) => number
  getProviderTypes?: () => Promise<void>
  getTypesByCategory?: (type: string) => Promise<void>
  isContractModalOpen?: boolean
  isMultpleProcedureDiscountDialogOpen?: boolean
  loadQuote?: (quoteId: string, reload?: boolean) => Promise<void>
  providerTypes?: IProviderTypeDto[]
  rerouteToPath?: (path: string) => void
  selectedQuote?: IQuoteDTO
  openModal?: (quote: IQuoteDTO, modalSwitch: string) => void
  assistantWarning?: boolean
  isAdjusterEmailModalOpen?: boolean
  setAsViewOnly?: boolean
  isNum?: (str: any) => boolean
  deleteLineItem?: (
    property: QuoteLineItemType,
    index: number,
    reconcile: boolean,
    forceUpdateHandler: () => void
  ) => void
  getSubtotal?: (property: QuoteLineItemType) => string
  renderLegend?: () => JSX.Element
  setGetQuoteValuesForStaticAction?: (parentfunction?: () => Promise<IQuoteDTO>) => void
  renderClaimButtons?: (section: string) => JSX.Element | JSX.Element[]
  quoteInboxItem: QuoteInboxItem
  isReferralContactModalOpen?: boolean
  openReferralContactModal?: () => void
}

interface ICreateQuoteState {
  reload: boolean
  generatedLink: string
  expandProfessional: boolean
  expandTechnical: boolean
  expandAnesth: boolean
  expandPhysical: boolean
  expandMisc: boolean
  lineItemDirty: boolean
  pcListStringify: string
  tcListStringify: string
  anListStringify: string
  ptListStringify: string
  miListStringify: string
  isLoading: boolean
  viewOnly: boolean
  hasNotSubmitted: boolean
}

class CreateQuote extends React.Component<ICreateQuoteProps, ICreateQuoteState> {
  constructor(props: ICreateQuoteProps) {
    super(props)
    this.state = {
      reload: false,
      generatedLink: '',
      expandProfessional: true,
      expandTechnical: true,
      expandAnesth: true,
      expandPhysical: true,
      expandMisc: true,
      lineItemDirty: false,
      pcListStringify: '',
      tcListStringify: '',
      anListStringify: '',
      ptListStringify: '',
      miListStringify: '',
      isLoading: true,
      viewOnly: false,
      hasNotSubmitted: true,
    }
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this)
  }

  public setMode() {
    var status = this.props.selectedQuote?.status

    if (this.props.setAsViewOnly) {
      this.setState({ viewOnly: true })
    } else {
      if (
        status == QuoteStatus.Imported ||
        status == QuoteStatus.Quoted ||
        status == QuoteStatus.Dismissed
      ) {
        this.setState({ viewOnly: true })
      }
    }
  }

  public forceUpdateHandler() {
    var newPC = JSON.stringify(
      this.props.selectedQuote?.professionalComponentLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )
    var newTC = JSON.stringify(
      this.props.selectedQuote?.technicalComponentLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )
    var newAN = JSON.stringify(
      this.props.selectedQuote?.anesthesiaLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )
    var newPT = JSON.stringify(
      this.props.selectedQuote?.physicalTherapyLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )
    var newMI = JSON.stringify(
      this.props.selectedQuote?.miscellaneousLineItems!.filter(
        (x) => x.id != undefined || (x.id == undefined && x.markedDeleted != true)
      )
    )

    this.setState({
      lineItemDirty:
        this.state.pcListStringify != newPC ||
        this.state.tcListStringify != newTC ||
        this.state.anListStringify != newAN ||
        this.state.ptListStringify != newPT ||
        this.state.miListStringify != newMI,
    })

    this.setMode()
    this.forceUpdate()
  }

  public async componentDidMount() {
    this.props.setGetQuoteValuesForStaticAction!(this.staticActionSave)
    await this.setup()
  }

  public componentWillUnmount() {
    this.setState({ hasNotSubmitted: true })
  }

  public getLineItemStyle = (deleted: boolean) => {
    if (deleted) {
      return this.props.classes.strikeOut
    } else {
      return undefined
    }
  }

  public async setup() {
    const { getProviderTypes, getTypesByCategory, getAllUsers } = this.props

    await Promise.all([
      getProviderTypes!(),
      getTypesByCategory!('PC'),
      getTypesByCategory!('TC'),
      getTypesByCategory!('MI'),
      getAllUsers!(5000, Constants.Roles.CareCoordinator),
    ]).then(() => {
      this.setState(
        {
          lineItemDirty: false,
          pcListStringify: JSON.stringify(
            this.props.selectedQuote!.professionalComponentLineItems
          ),
          tcListStringify: JSON.stringify(
            this.props.selectedQuote!.technicalComponentLineItems
          ),
          anListStringify: JSON.stringify(this.props.selectedQuote!.anesthesiaLineItems),
          ptListStringify: JSON.stringify(
            this.props.selectedQuote!.physicalTherapyLineItems
          ),
          miListStringify: JSON.stringify(
            this.props.selectedQuote!.miscellaneousLineItems
          ),
          isLoading: false,
        },
        () => {
          this.forceUpdateHandler()
        }
      )
    })
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload, isLoading: true }, () => {
      submitForm()
    })
  }

  public get title() {
    var title = 'Add New Quote'
    if (this.props.match.params.quoteId) {
      if (this.state.viewOnly) {
        title = 'View Quote'
      } else {
        title = 'Edit Quote'
      }
    }

    return title
  }

  public staticActionSave = async () => {
    let values = this.quoteRef.current?.state?.values
    this.quoteRef.current.submitForm()

    const quote = await submitQuoteFormValues(values, false).finally(() => {
      this.setState({ hasNotSubmitted: false }, () => {
        this.quoteRef.current.setSubmitting(false)
      })
    })

    return quote
  }

  private mapAnesthesiaGridData = () => {
    if (this.props.selectedQuote?.anesthesiaLineItems!.length == 0) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            Click + to Add a Line Item
          </TableCell>
        </TableRow>
      )
    } else {
      return this.props.selectedQuote?.anesthesiaLineItems!.map((billOut, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell
            className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
            style={{ whiteSpace: 'nowrap' }}
          >
            {billOut.providerName}
          </TableCell>
          <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
            {billOut.physicianName}
          </TableCell>
          <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
            {billOut.cptCode ?? ''}
          </TableCell>
          <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
            {billOut.costSource != undefined ? CostSourceText[billOut.costSource!] : ''}
            {billOut.otherDescription ? (
              <Tooltip title={billOut.otherDescription}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell
            style={{ paddingRight: 0 }}
            className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
            align="right"
          >
            {formatCurrency((billOut.actualCost ?? 0).toString())}
          </TableCell>
          <TableCell
            style={{ paddingRight: 0 }}
            className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
            align="right"
          >
            {formatCurrency((billOut.cost ?? 0).toString())}
          </TableCell>
          <TableCell style={{ paddingLeft: 5 }} align="left">
            {billOut.calculationExplanation ? (
              <Tooltip title={billOut.calculationExplanation}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          {this.state.viewOnly ? (
            <></>
          ) : billOut.markedDeleted ? (
            <TableCell>
              <UndoRounded
                onClick={() =>
                  this.props.deleteLineItem!(
                    QuoteLineItemType.AN,
                    index,
                    false,
                    this.forceUpdateHandler
                  )
                }
                cursor="pointer"
              ></UndoRounded>
            </TableCell>
          ) : (
            <TableCell>
              <RemoveCircle
                onClick={() =>
                  this.props.deleteLineItem!(
                    QuoteLineItemType.AN,
                    index,
                    false,
                    this.forceUpdateHandler
                  )
                }
                cursor="pointer"
                color="error"
              ></RemoveCircle>
            </TableCell>
          )}
        </TableRow>
      ))
    }
  }

  private mapProfessionalGridData = () => {
    if (this.props.selectedQuote?.professionalComponentLineItems!.length == 0) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            Click + to Add a Line Item
          </TableCell>
        </TableRow>
      )
    } else {
      return this.props.selectedQuote?.professionalComponentLineItems!.map(
        (billOut, index) => (
          <TableRow key={index} className="gridPadding">
            <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
              {billOut.providerName}
            </TableCell>
            <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
              {billOut.physicianName}
            </TableCell>
            <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
              {billOut.typeText ?? ''}
            </TableCell>
            <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
              {billOut.cptCode ?? ''}
            </TableCell>
            <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
              {billOut.costSource != undefined ? CostSourceText[billOut.costSource!] : ''}
              {billOut.otherDescription ? (
                <Tooltip title={billOut.otherDescription}>
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>
            <TableCell
              style={{ paddingRight: 0 }}
              className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
              align="right"
            >
              {billOut.cost == 0 && billOut.assistantMultiplier != undefined
                ? 'N/A'
                : formatCurrency((billOut.actualCost ?? 0).toString())}
            </TableCell>
            <TableCell align="left" style={{ paddingLeft: 5 }}>
              {billOut.assistantSurgeonInfo ? (
                <Tooltip title={billOut.assistantSurgeonInfo}>
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>
            <TableCell
              style={{ paddingRight: 0 }}
              className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
              align="right"
            >
              {billOut.cost == 0 &&
              billOut.assistantMultiplier != undefined &&
              billOut.calculationExplanation == undefined
                ? 'Will Calculate on Save'
                : formatCurrency((billOut.cost ?? 0).toString())}
            </TableCell>
            <TableCell align="left" style={{ paddingLeft: 5 }}>
              {billOut.calculationExplanation ? (
                <Tooltip title={billOut.calculationExplanation}>
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>
            {this.state.viewOnly ? (
              <></>
            ) : billOut.markedDeleted ? (
              <TableCell>
                <UndoRounded
                  onClick={() =>
                    this.props.deleteLineItem!(
                      QuoteLineItemType.PC,
                      index,
                      false,
                      this.forceUpdateHandler
                    )
                  }
                  cursor="pointer"
                ></UndoRounded>
              </TableCell>
            ) : (
              <TableCell>
                <RemoveCircle
                  onClick={() =>
                    this.props.deleteLineItem!(
                      QuoteLineItemType.PC,
                      index,
                      false,
                      this.forceUpdateHandler
                    )
                  }
                  cursor="pointer"
                  color="error"
                ></RemoveCircle>
              </TableCell>
            )}
          </TableRow>
        )
      )
    }
  }

  private mapPhysicalGridData = () => {
    if (this.props.selectedQuote?.physicalTherapyLineItems!.length == 0) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            Click + to Add a Line Item
          </TableCell>
        </TableRow>
      )
    } else {
      return this.props.selectedQuote?.physicalTherapyLineItems!.map((billOut, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell
            className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
            style={{ whiteSpace: 'nowrap' }}
          >
            {billOut.providerName}
          </TableCell>
          <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
            {billOut.numberOfVisits?.toString()}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
            align="right"
          >
            {billOut.actualCostPerVisit
              ? formatCurrency(billOut.actualCostPerVisit?.toString())
              : ''}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
            align="right"
          >
            {billOut.actualCostPerVisit && billOut.numberOfVisits
              ? formatCurrency(
                  (billOut.actualCostPerVisit * billOut.numberOfVisits)?.toString()
                )
              : ''}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
            align="right"
          >
            {billOut.costPerVisit ? formatCurrency(billOut.costPerVisit?.toString()) : ''}
          </TableCell>
          <TableCell
            style={{ paddingRight: 0 }}
            className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
            align="right"
          >
            {formatCurrency((billOut.cost ?? 0).toString())}
          </TableCell>
          {this.state.viewOnly ? (
            <></>
          ) : billOut.markedDeleted ? (
            <TableCell>
              <UndoRounded
                onClick={() =>
                  this.props.deleteLineItem!(
                    QuoteLineItemType.PT,
                    index,
                    false,
                    this.forceUpdateHandler
                  )
                }
                cursor="pointer"
              ></UndoRounded>
            </TableCell>
          ) : (
            <TableCell>
              <RemoveCircle
                onClick={() =>
                  this.props.deleteLineItem!(
                    QuoteLineItemType.PT,
                    index,
                    false,
                    this.forceUpdateHandler
                  )
                }
                cursor="pointer"
                color="error"
              ></RemoveCircle>
            </TableCell>
          )}
        </TableRow>
      ))
    }
  }

  private mapMiscGridData = () => {
    if (this.props.selectedQuote?.miscellaneousLineItems!.length == 0) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            Click + to Add a Line Item
          </TableCell>
        </TableRow>
      )
    } else {
      return this.props.selectedQuote?.miscellaneousLineItems!.map((billOut, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
            {billOut.typeText ?? ''}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
            style={{ whiteSpace: 'nowrap' }}
          >
            {billOut.providerName}
          </TableCell>
          <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
            {billOut.lineDescription ?? ''}
          </TableCell>
          <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
            {billOut.cptCode ?? ''}
          </TableCell>
          <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
            {billOut.costSource != undefined ? CostSourceText[billOut.costSource!] : ''}
            {billOut.otherDescription ? (
              <Tooltip title={billOut.otherDescription}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell
            style={{ paddingRight: 0 }}
            className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
            align="right"
          >
            {formatCurrency((billOut.actualCost ?? 0).toString())}
          </TableCell>
          <TableCell
            style={{ paddingRight: 0 }}
            className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
            align="right"
          >
            {formatCurrency((billOut.cost ?? 0).toString())}
          </TableCell>
          <TableCell style={{ paddingLeft: 5 }} align="left">
            {billOut.calculationExplanation ? (
              <Tooltip title={billOut.calculationExplanation}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          {this.state.viewOnly ? (
            <></>
          ) : billOut.markedDeleted ? (
            <TableCell>
              <UndoRounded
                onClick={() =>
                  this.props.deleteLineItem!(
                    QuoteLineItemType.MI,
                    index,
                    false,
                    this.forceUpdateHandler
                  )
                }
                cursor="pointer"
              ></UndoRounded>
            </TableCell>
          ) : (
            <TableCell>
              <RemoveCircle
                onClick={() =>
                  this.props.deleteLineItem!(
                    QuoteLineItemType.MI,
                    index,
                    false,
                    this.forceUpdateHandler
                  )
                }
                cursor="pointer"
                color="error"
              ></RemoveCircle>
            </TableCell>
          )}
        </TableRow>
      ))
    }
  }

  private mapTechnicalGridData = () => {
    if (this.props.selectedQuote?.technicalComponentLineItems!.length == 0) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            Click + to Add a Line Item
          </TableCell>
        </TableRow>
      )
    } else {
      return this.props.selectedQuote?.technicalComponentLineItems!.map(
        (billOut, index) => (
          <TableRow key={index} className="gridPadding">
            <TableCell
              className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
              style={{ whiteSpace: 'nowrap' }}
            >
              {billOut.providerName}
            </TableCell>
            <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
              {billOut.typeText ?? ''}
            </TableCell>
            <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
              {billOut.cptCode ?? ''}
            </TableCell>
            <TableCell className={this.getLineItemStyle(billOut.markedDeleted ?? false)}>
              {billOut.costSource != undefined ? CostSourceText[billOut.costSource!] : ''}
              {billOut.otherDescription ? (
                <Tooltip title={billOut.otherDescription}>
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>
            <TableCell
              style={{ paddingRight: 0 }}
              className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
              align="right"
            >
              {formatCurrency((billOut.actualCost ?? 0).toString())}
            </TableCell>
            <TableCell
              style={{ paddingRight: 0 }}
              className={this.getLineItemStyle(billOut.markedDeleted ?? false)}
              align="right"
            >
              {formatCurrency((billOut.cost ?? 0).toString())}
            </TableCell>
            <TableCell style={{ paddingLeft: 5 }} align="left">
              {billOut.calculationExplanation ? (
                <Tooltip title={billOut.calculationExplanation}>
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>
            {this.state.viewOnly ? (
              <></>
            ) : billOut.markedDeleted ? (
              <TableCell>
                <UndoRounded
                  onClick={() =>
                    this.props.deleteLineItem!(
                      QuoteLineItemType.TC,
                      index,
                      false,
                      this.forceUpdateHandler
                    )
                  }
                  cursor="pointer"
                ></UndoRounded>
              </TableCell>
            ) : (
              <TableCell>
                <RemoveCircle
                  onClick={() =>
                    this.props.deleteLineItem!(
                      QuoteLineItemType.TC,
                      index,
                      false,
                      this.forceUpdateHandler
                    )
                  }
                  cursor="pointer"
                  color="error"
                ></RemoveCircle>
              </TableCell>
            )}
          </TableRow>
        )
      )
    }
  }

  private renderPC() {
    return (
      <ExpansionPanel
        expanded={this.state.expandProfessional}
        onChange={() =>
          this.setState({ expandProfessional: !this.state.expandProfessional })
        }
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container justifyContent="space-between" style={{ paddingTop: '5px' }}>
            <Typography display="inline" color="textSecondary" variant="body2">
              PROFESSIONAL COMPONENTS
            </Typography>
            <Typography
              display="inline"
              color="textPrimary"
              variant="subtitle1"
              align="right"
            >
              <span>
                {' Subtotal: ' +
                  this.props.getSubtotal!(QuoteLineItemType.PC) +
                  ' (' +
                  this.props.selectedQuote?.professionalComponentLineItems!.length +
                  (this.props.selectedQuote?.professionalComponentLineItems!.length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
            </Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row">
            <Grid item xs={12}>
              <>{this.props.renderClaimButtons!('PC')}</>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="gridPaddingDense">
                    <TableCell>Provider</TableCell>
                    <TableCell>Physician</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>CPT</TableCell>
                    <TableCell>Cost Source</TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                        paddingLeft: 0,
                      }}
                    >
                      Billed Charges
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Quoted $
                    </TableCell>
                    <TableCell align="right" style={{ paddingRight: '40px' }} colSpan={2}>
                      {this.state.viewOnly ? (
                        <></>
                      ) : (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.props.selectedQuote == undefined}
                          aria-label="Add"
                          onClick={() =>
                            this.props.openModal!(this.props.selectedQuote!, 'PC')
                          }
                        >
                          <Add />
                        </Fab>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '100%' }}>
                  {this.mapProfessionalGridData()}
                </TableBody>
              </Table>
            </Grid>
            {!this.state.viewOnly && this.props.renderLegend!()}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  private renderTC() {
    return (
      <ExpansionPanel
        expanded={this.state.expandTechnical}
        onChange={() => this.setState({ expandTechnical: !this.state.expandTechnical })}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container justifyContent="space-between" style={{ paddingTop: '5px' }}>
            <Typography display="inline" color="textSecondary" variant="body2">
              TECHNICAL COMPONENTS
            </Typography>
            <Typography
              display="inline"
              color="textPrimary"
              variant="subtitle1"
              align="right"
            >
              <span>
                {' Subtotal: ' +
                  this.props.getSubtotal!(QuoteLineItemType.TC) +
                  ' (' +
                  this.props.selectedQuote?.technicalComponentLineItems!.length +
                  (this.props.selectedQuote?.technicalComponentLineItems!.length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
            </Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row">
            <Grid item xs={12}>
              <>{this.props.renderClaimButtons!('TC')}</>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="gridPaddingDense">
                    <TableCell>Provider</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>CPT</TableCell>
                    <TableCell>Cost Source</TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                        paddingLeft: 0,
                      }}
                    >
                      Billed Charges
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Quoted $
                    </TableCell>
                    <TableCell align="right" style={{ paddingRight: '40px' }} colSpan={2}>
                      {this.state.viewOnly ? (
                        <></>
                      ) : (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.props.selectedQuote == undefined}
                          aria-label="Add"
                          onClick={() =>
                            this.props.openModal!(this.props.selectedQuote!, 'TC')
                          }
                        >
                          <Add />
                        </Fab>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '100%' }}>
                  {this.mapTechnicalGridData()}
                </TableBody>
              </Table>
            </Grid>
            {!this.state.viewOnly && this.props.renderLegend!()}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  private renderAN() {
    return (
      <ExpansionPanel
        expanded={this.state.expandAnesth}
        onChange={() => this.setState({ expandAnesth: !this.state.expandAnesth })}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container justifyContent="space-between" style={{ paddingTop: '5px' }}>
            <Typography display="inline" color="textSecondary" variant="body2">
              ANESTHESIOLOGIST
            </Typography>
            <Typography
              display="inline"
              color="textPrimary"
              variant="subtitle1"
              align="right"
            >
              <span>
                {' Subtotal: ' +
                  this.props.getSubtotal!(QuoteLineItemType.AN) +
                  ' (' +
                  this.props.selectedQuote?.anesthesiaLineItems!.length +
                  (this.props.selectedQuote?.anesthesiaLineItems!.length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
            </Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row">
            <Grid item xs={12}>
              <>{this.props.renderClaimButtons!('AN')}</>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="gridPaddingDense">
                    <TableCell>Provider</TableCell>
                    <TableCell>Physician</TableCell>
                    <TableCell>CPT</TableCell>
                    <TableCell>Cost Source</TableCell>
                    <TableCell align="right" style={{ paddingRight: 0 }}>
                      Billed Charges
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Quoted $
                    </TableCell>
                    <TableCell align="right" style={{ paddingRight: '40px' }} colSpan={2}>
                      {this.state.viewOnly ? (
                        <></>
                      ) : (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.props.selectedQuote == undefined}
                          aria-label="Add"
                          onClick={() =>
                            this.props.openModal!(this.props.selectedQuote!, 'AN')
                          }
                        >
                          <Add />
                        </Fab>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '100%' }}>
                  {this.mapAnesthesiaGridData()}
                </TableBody>
              </Table>
            </Grid>
            {!this.state.viewOnly && this.props.renderLegend!()}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  private renderPT() {
    return (
      <ExpansionPanel
        expanded={this.state.expandPhysical}
        onChange={() => this.setState({ expandPhysical: !this.state.expandPhysical })}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container justifyContent="space-between" style={{ paddingTop: '5px' }}>
            <Typography display="inline" color="textSecondary" variant="body2">
              PHYSICAL THERAPY
            </Typography>
            <Typography
              display="inline"
              color="textPrimary"
              variant="subtitle1"
              align="right"
            >
              <span>
                {' Subtotal: ' +
                  this.props.getSubtotal!(QuoteLineItemType.PT) +
                  ' (' +
                  this.props.selectedQuote?.physicalTherapyLineItems!.length +
                  (this.props.selectedQuote?.physicalTherapyLineItems!.length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
            </Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row">
            <Grid item xs={12}>
              <>{this.props.renderClaimButtons!('PT')}</>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="gridPadding">
                    <TableCell>Provider</TableCell>
                    <TableCell>Number of Visits</TableCell>
                    <TableCell align="right">Est. U&C Per Visit</TableCell>
                    <TableCell align="right">Est. U&C $</TableCell>
                    <TableCell align="right">Quoted Per Visit</TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Quoted $
                    </TableCell>
                    <TableCell align="right" style={{ paddingRight: '40px' }} colSpan={2}>
                      {this.state.viewOnly ? (
                        <></>
                      ) : (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.props.selectedQuote == undefined}
                          aria-label="Add"
                          onClick={() =>
                            this.props.openModal!(this.props.selectedQuote!, 'PT')
                          }
                        >
                          <Add />
                        </Fab>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '100%' }}>
                  {this.mapPhysicalGridData()}
                </TableBody>
              </Table>
            </Grid>
            {!this.state.viewOnly && this.props.renderLegend!()}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  private renderMI() {
    return (
      <ExpansionPanel
        expanded={this.state.expandMisc}
        onChange={() => this.setState({ expandMisc: !this.state.expandMisc })}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container justifyContent="space-between" style={{ paddingTop: '5px' }}>
            <Typography display="inline" color="textSecondary" variant="body2">
              MISCELLANEOUS
            </Typography>
            <Typography
              display="inline"
              color="textPrimary"
              variant="subtitle1"
              align="right"
            >
              <span>
                {' Subtotal: ' +
                  this.props.getSubtotal!(QuoteLineItemType.MI) +
                  ' (' +
                  this.props.selectedQuote?.miscellaneousLineItems!.length +
                  (this.props.selectedQuote?.miscellaneousLineItems!.length == 1
                    ? ' item)'
                    : ' items)')}
              </span>
            </Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row">
            <Grid item xs={12}>
              <>{this.props.renderClaimButtons!('MI')}</>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="gridPaddingDense">
                    <TableCell>Type</TableCell>
                    <TableCell>Provider</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>CPT</TableCell>
                    <TableCell>Cost Source</TableCell>
                    <TableCell align="right" style={{ paddingRight: 0 }}>
                      Billed Charges
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingRight: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Quoted $
                    </TableCell>
                    <TableCell align="right" style={{ paddingRight: '40px' }} colSpan={2}>
                      {this.state.viewOnly ? (
                        <></>
                      ) : (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.props.selectedQuote == undefined}
                          aria-label="Add"
                          onClick={() =>
                            this.props.openModal!(this.props.selectedQuote!, 'MI')
                          }
                        >
                          <Add />
                        </Fab>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '100%' }}>{this.mapMiscGridData()}</TableBody>
              </Table>
            </Grid>
            {!this.state.viewOnly && this.props.renderLegend!()}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  public quoteRef = React.createRef<any>()

  public render() {
    const { selectedQuote, getLineItemsTotals } = this.props

    return (
      <>
        {selectedQuote ? (
          <Formik
            ref={this.quoteRef}
            initialValues={createIQuoteFormValues(selectedQuote)}
            onSubmit={() => {}}
            validationSchema={QuoteSchema}
            enableReinitialize={true}
          >
            {({ values, dirty, submitCount }) => (
              <>
                <Prompt
                  when={(dirty || this.state.lineItemDirty) && submitCount === 0}
                  message="Are you sure you want to leave the page? There are unsaved changes."
                />
                <AddQuoteLineModal reloadParent={this.forceUpdateHandler} />
                <Form>
                  {
                    //@ts-ignore
                    values.adjustedCost === '' || values.targetMargin === '' ? (
                      <Grid item container justifyContent="flex-end">
                        <Typography
                          style={{
                            paddingRight: '10px',
                            paddingBottom: '10px',
                          }}
                          color="error"
                        >
                          Missing Quote Total Data
                        </Typography>
                      </Grid>
                    ) : (
                      <></>
                    )
                  }
                  <Grid container direction="column" spacing={3} wrap="nowrap">
                    {this.state.isLoading && (
                      <div
                        style={{
                          position: 'fixed',
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          cursor: 'pointer',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 800,
                        }}
                      >
                        <CircularProgress size={100} />
                      </div>
                    )}
                    <Grid item>
                      {this.props.selectedQuote && this.props.quoteInboxItem && (
                        <QuoteDetails
                          disableInvoiceButtons={
                            (dirty || this.state.lineItemDirty) && submitCount === 0
                          }
                          values={values}
                          selectedQuote={this.props.selectedQuote}
                          assistantWarning={this.props.assistantWarning}
                          viewMode={this.state.viewOnly}
                          updateMain={this.forceUpdateHandler}
                          isAdjusterEmailModalOpen={this.props.isAdjusterEmailModalOpen}
                          rerouteToPath={this.props.rerouteToPath}
                          takeoverOfCareDate={this.props.quoteInboxItem.takeoverOfCare}
                          quoteInboxItem={this.props.quoteInboxItem}
                          isReferralContactModalOpen={
                            this.props.isReferralContactModalOpen
                          }
                          openReferralContactModal={this.props.openReferralContactModal}
                        />
                      )}
                    </Grid>
                    <Grid item>{this.renderPC()}</Grid>
                    <Grid item>{this.renderTC()}</Grid>
                    <Grid item>{this.renderAN()}</Grid>
                    <Grid item>{this.renderPT()}</Grid>
                    <Grid item>{this.renderMI()}</Grid>
                    <Grid container item direction="row" spacing={3}>
                      <Grid item xs={8}>
                        <CardWithTitle title="Notes" icon={Assignment}>
                          <Grid item container>
                            <FullWidthField
                              disabled={this.state.viewOnly}
                              name="note"
                              fullWidth
                              multiline
                              rows="9"
                              style={{ marginBottom: 4 }}
                            />
                          </Grid>
                        </CardWithTitle>
                      </Grid>
                      <QuoteTotals
                        viewMode={this.state.viewOnly}
                        reconMode={false}
                        getLineItemsTotal={getLineItemsTotals!}
                      />
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <>
            {this.state.isLoading && (
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 800,
                }}
              >
                <CircularProgress size={100} />
              </div>
            )}
          </>
        )}
      </>
    )
  }
}

const InjectedquotesList = inject<
  IStores,
  ICreateQuoteProps,
  Partial<ICreateQuoteProps>,
  any
>((stores: IStores) => ({
  getAllUsers: stores.users.getAllUsers,
  getLineItemsTotals: stores.quotes.getLineItemsTotal,
  getProviderTypes: stores.quotes.getProviderTypes,
  getTypesByCategory: stores.quotes.getTypesByCategory,
  loadQuote: stores.quotes.loadQuote,
  providerTypes: stores.quotes.providerTypes,
  rerouteToPath: stores.global.rerouteToPath,
  selectedQuote: stores.quotes.selectedQuote,
  openModal: stores.quotes.openQuoteLineModal,
  assistantWarning: stores.quotes.assistantWarning,
  isAdjusterEmailModalOpen: stores.quotes.isAdjusterEmailModalOpen,
  isNum: stores.quotes.isNum,
  deleteLineItem: stores.quotes.deleteLineItem,
  getSubtotal: stores.quotes.getSubtotal,
  renderLegend: stores.quotes.renderLegend,
  setGetQuoteValuesForStaticAction: stores.quotes.setGetQuoteValuesForStaticAction,
  renderClaimButtons: stores.quotes.renderClaimButtons,
  openReferralContactModal: stores.patientEdit.openReferralContactModal,
  isReferralContactModalOpen: stores.patientEdit.isReferralContactModalOpen,
}))(CreateQuote)

export default withStyles(styles)(InjectedquotesList)
