import { Button, Grid } from '@material-ui/core'
import { FormikActions } from 'formik'
import { inject } from 'mobx-react'
import moment from 'moment'
import * as React from 'react'
import FullWidthField from 'src/common/FullWidthField'
import SelectField from 'src/common/SelectField/SelectField'
import TypeAheadField from 'src/common/TypeAheadField/TypeAheadField'
import { noWhitespaceString } from 'src/common/YupExtensions'
import { IProviderDto } from 'src/generated_client'
import {
  createIScheduleFormValues,
  IScheduleFormValues,
  toISchedule,
} from 'src/patients/Scheduling/ScheduleFormValues'
import ReceiveClaimProviderLookupDialog from './ReceiveClaimProviderLookupDialog'
import * as Yup from 'yup'
import ESDialog from '../common/ESDialog'
import {
  IComponent,
  IEpisodeOfCare,
  ILocation,
  IOrganization,
  IPatient,
  IProvider,
  ISchedule,
} from '../Definitions'
import { IStores } from '../Stores'
import { Schedule } from '@material-ui/icons'

const maximumDate = moment().subtract(1, 'days')

const SimpleEventSchema = Yup.object().shape({
  appointmentDateInput: Yup.date()
    .max(maximumDate.toLocaleString(), 'Appointment Date must be in the past.')
    .required('Appointment Date is required'),
  componentId: noWhitespaceString('Component is required', true),
  locationId: noWhitespaceString('Location is required', true),
  providerId: noWhitespaceString('Provider is required', true),
})

interface ISimpleEventDialogProps {
  clearLocations?: () => void
  closeModal?: () => void
  components?: IComponent[]
  currentAppOrganization?: IOrganization
  getAllActiveComponents?: () => Promise<void>
  episodeOfCare?: IEpisodeOfCare
  getAllLocationsForProvider?: (providerId: string) => Promise<void>
  getProviders?: (filter: string) => Promise<void>
  getScheduleById?: (id: string) => Promise<void>
  isLoading?: boolean
  isOpen?: boolean
  isProviderLookupDialogOpen?: boolean
  locations?: ILocation[]
  openProviderLookupDialog?: () => void
  patient?: IPatient
  providers?: IProviderDto[]
  saveSchedule?: (
    schedule: ISchedule,
    eocId: string,
    scheduleId: string,
    resetUiState: boolean
  ) => Promise<string | void>
  schedule?: ISchedule
  selectedLocation?: ILocation
  selectedProvider?: IProvider
  setSelectedLocation?: (location?: ILocation) => void
  setSelectedProvider?: (provider?: IProvider) => Promise<void>
  updateParentStateAfterModal?: (locationId: string | undefined) => void
  updateParentStateAfterClose?: () => void
  getLocationById?: (locationId: string) => void
}

class SimpleEventDialog extends React.Component<ISimpleEventDialogProps> {
  public save = async (
    values: IScheduleFormValues,
    formikBag: FormikActions<IScheduleFormValues>
  ) => {
    values.locationId = this.props.selectedLocation?.id ?? ''
    const schedule = await toISchedule(values, this.props.schedule)
    schedule.patientId = this.props.episodeOfCare?.referral?.patient?.id ?? ''
    schedule.episodeOfCareId = this.props.episodeOfCare!.id
    schedule.isTBD = false
    if (!schedule.episodeOfCare) {
      schedule.isNew = true
    }

    schedule.appointmentDate = new Date(
      moment(values.appointmentDateInput, 'YYYY-MM-DD HH:mm').toISOString()
    )
    this.props.saveSchedule!(schedule, this.props.episodeOfCare!.id, schedule.id, false)
      .then((response: string) => {
        schedule.id = response
      })
      .catch(() => {})
      .finally(async () => {
        formikBag.setSubmitting(false)

        if (this.props.updateParentStateAfterModal) {
          this.props.getScheduleById!(schedule!.id)
            .then(() => this.props.getLocationById!(this.props.selectedLocation!.id))
            .finally(() => {
              this.props.updateParentStateAfterModal!(this.props.selectedLocation?.id)
            })
        }
        this.props.closeModal!()
      })
  }

  public close = () => {
    if (this.props.updateParentStateAfterClose != undefined) {
      this.props.updateParentStateAfterClose()
    }
    this.props.closeModal!()
  }

  public async componentDidMount() {
    await this.setup()
  }

  public async setup() {
    this.props.setSelectedLocation!(undefined)
    this.props.setSelectedProvider!(undefined)
    this.props.getAllActiveComponents!()
  }

  public render() {
    const {
      components,
      currentAppOrganization,
      getProviders,
      isLoading,
      isOpen,
      providers,
      schedule,
    } = this.props
    const providerLookupDialog = this.props.isProviderLookupDialogOpen && (
      <ReceiveClaimProviderLookupDialog
        open={this.props.isProviderLookupDialogOpen!}
        selectedSchedule={this.props.schedule}
        selectedLocation={this.props.selectedLocation}
        setSelectedLocation={this.props.setSelectedLocation}
        selectedProvider={this.props.selectedProvider}
        setSelectedProvider={this.props.setSelectedProvider}
      />
    )
    const dropdownComponents = components?.filter(
      (x) => x.organizationId === currentAppOrganization!.id && x.isSimpleComponent
    )

    return (
      <>
        {providerLookupDialog}
        <ESDialog
          close={this.close}
          initialValues={createIScheduleFormValues(schedule)}
          isLoading={isLoading}
          open={isOpen!}
          save={this.save}
          title={'Add Historical Event'}
          isInitialValid={schedule && !schedule.isNew}
          validationSchema={SimpleEventSchema}
          titleIcon={<Schedule />}
        >
          {({ values, setFieldValue, errors }) => (
            <Grid
              container
              spacing={3}
              style={{ marginBottom: '12px', marginTop: '12px' }}
            >
              <Grid item md={2} xs={12}>
                <SelectField
                  inputId="componentId"
                  getName={this.getName}
                  getValue={this.getValue}
                  items={dropdownComponents}
                  label="Component"
                  name="componentId"
                  fullWidth
                  shrinkLabel={true}
                  required
                  errorMessage={errors.componentId}
                  outlined
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TypeAheadField
                  name="provider"
                  inputId="providerId"
                  label="Provider Group"
                  placeholder="{NOT SELECTED}"
                  outlined
                  fullWidth
                  setFieldValue={this.setProviderValue(setFieldValue, values)}
                  errorMessage={errors.providerId}
                  getName={this.getProviderName}
                  getValue={this.getProviderId}
                  items={providers}
                  getItems={getProviders}
                  selectedValue={this.getSelectedProvider(values, setFieldValue)}
                  required
                  labelShrink={true}
                  disabled={true}
                />
                <div style={{ marginTop: '10px' }}>
                  <Button
                    style={{ textTransform: 'none', marginBottom: '10px' }}
                    size="small"
                    onClick={this.openProviderLookupDialog(setFieldValue, values)}
                    variant="contained"
                    color="secondary"
                  >
                    Select Provider Group and Location
                  </Button>
                </div>
              </Grid>
              <Grid item md={3} xs={12} style={{ paddingTop: 'revert' }}>
                <FullWidthField
                  inputId="locationId"
                  label="Provider Location"
                  name="locationId"
                  fullWidth
                  placeholder="{NOT SELECTED}"
                  shrinkLabel={true}
                  disabled={true}
                  outlined
                  required
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FullWidthField
                  format="YYYY-MM-DD"
                  defaultValue={values.appointmentDateInput}
                  type="date"
                  name="appointmentDateInput"
                  label="Appointment Date"
                  inputId="appointmentDateInput"
                  fullWidth={true}
                  clearable={true}
                  shrink={true}
                  required
                  variant="outlined"
                  errorMessage={errors.appointmentDateInput}
                />
              </Grid>
            </Grid>
          )}
        </ESDialog>
      </>
    )
  }

  public getProviderName(provider: IProvider) {
    return provider !== null ? provider.name : ''
  }

  public getProviderId(provider: IProvider) {
    return provider !== null ? provider.id || '' : ''
  }

  private openProviderLookupDialog =
    (setFieldValue: (field: string, value: any) => void, values: IScheduleFormValues) =>
    () => {
      if (values.providerId) {
        setFieldValue('providerId', undefined)
      }
      if (values.locationId) {
        setFieldValue('locationId', undefined)
      }
      this.props.openProviderLookupDialog!()
    }

  private getName(item: any) {
    return item ? item.name || '' : ''
  }

  private getValue(item: any) {
    return item ? item.id || '' : ''
  }

  private setProviderValue =
    (setFieldValue: (field: string, value: any) => void, values: IScheduleFormValues) =>
    (fieldName: string, value: any) => {
      const newValue = value !== undefined ? value.id : ''
      setFieldValue(`${fieldName}Id`, newValue)
      setFieldValue(fieldName, value)
      this.props.setSelectedProvider!(value).then(() => {
        this.props.getAllLocationsForProvider!(newValue)
      })

      if (!!newValue) {
        if (newValue !== values.providerId) {
          setFieldValue('locationId', undefined)
          this.props.setSelectedLocation!(undefined)
          this.props.clearLocations!()
          this.props.setSelectedProvider!(value)
          this.props.getAllLocationsForProvider!(newValue).then(() => {
            if (this.props.locations!.length === 1) {
              setFieldValue('locationId', this.props.locations![0].name)
              this.props.setSelectedLocation!(this.props.locations![0])
            }
          })
        }
      } else {
        this.props.setSelectedProvider!(undefined)
        this.props.setSelectedLocation!(undefined)
        this.props.clearLocations!()
        setFieldValue('locationId', undefined)
      }
    }

  private getSelectedProvider(
    values: IScheduleFormValues,
    setFieldValue: (field: string, value: any) => void
  ) {
    if (
      this.props.selectedProvider &&
      this.props.selectedProvider.id !== values.providerId
    ) {
      setFieldValue('providerId', this.props.selectedProvider.id)
    }
    if (this.props.selectedProvider) {
      if (
        this.props.selectedLocation &&
        this.props.selectedLocation.name !== values.locationId
      ) {
        setFieldValue('locationId', this.props.selectedLocation.name)
      }
      return this.props.selectedProvider
    }

    return undefined
  }
}

const InjectedScheduleDialog = inject<
  IStores,
  ISimpleEventDialogProps,
  Partial<ISimpleEventDialogProps>,
  any
>((stores: IStores) => ({
  clearLocations: stores.locations.clearLocations,
  closeModal: stores.schedules.closeEventDialog,
  components: stores.components.components,
  currentAppOrganization: stores.global.currentAppOrganization,
  episodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  getAllActiveComponents: stores.components.getAllActiveComponents,
  getAllLocationsForProvider: stores.locations.getAllLocationsByProviderId,
  getProviders: stores.providers.getProviderSuggestions,
  getScheduleById: stores.schedules.getScheduleById,
  isLoading: stores.schedules.isLoading,
  isOpen: stores.schedules.isModalOpen,
  isProviderLookupDialogOpen: stores.schedules.isClaimProviderLookupDialogOpen,
  locations: stores.locations.locations,
  openProviderLookupDialog: stores.schedules.openClaimProviderLookupDialog,
  patient: stores.patients.selectedPatient,
  providers: stores.providers.providers,
  saveSchedule: stores.schedules.saveSchedule,
  schedule: stores.schedules.selectedSchedule,
  selectedLocation: stores.locations.selectedEditClaimLocation,
  selectedProvider: stores.providers.selectedModalEditScheduleProvider,
  setSelectedLocation: stores.locations.setSelectedEditClaimLocation,
  setSelectedProvider: stores.providers.setSelectedModalEditScheduleProvider,
  getLocationById: stores.locations.getLocationById,
}))(SimpleEventDialog)

export default InjectedScheduleDialog
