export interface ISharedDrivePathRequest {
  preCertNumber?: string | undefined
  patientFirst: string
  patientLast: string
}

export class SharedDrivePathRequest {
  public preCertNumber?: string | undefined
  public patientFirst: string
  public patientLast: string
}
