import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
} from '@material-ui/core/styles'
import { TableCellProps } from '@material-ui/core/TableCell'
import { TableRowProps } from '@material-ui/core/TableRow'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import * as moment from 'moment'
import * as React from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { ColumnSettingsDto, TagDto } from '../../generated_client'
import { DraggableTableHeaderCell } from '../DraggableTableHeaderCell'
import { DroppableTableHeaderRow } from '../DroppableTableHeaderRow'
import ESDataTableSettingsButton from '../ESDataTableSettingsButton'
import ESMenu from '../ESMenu'
import IDataTableManager from '../IDataTableManager'
import ITableOptions from '../ITableOptions'

const styles = createStyles({
  disabledRow: {
    '& td:first-of-type': {
      borderLeft: '5px solid #930A0A',
      paddingLeft: '7px',
    },
  },
  esTableRow: {
    height: '12px',
  },
  paperTable: {
    overflowX: 'auto',
    overflowY: 'auto',
  },
})

const StyledTableRow = withStyles(({ palette }: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: '#E1E1E1',
      },
      '&:nth-of-type(odd)': {
        '&:hover': {
          backgroundColor: '#E1E1E1',
        },
        backgroundColor: '#EEEEEE',
      },
    },
    selected: {
      backgroundColor: `${palette.primary.main} !important`,
      color: 'white',
    },
  })
)(TableRow)

const StyledTableCell = withStyles(() =>
  createStyles({
    root: {
      color: 'inherit',
      fontSize: 16,
      paddingBottom: 8,
      paddingLeft: 10,
      paddingRight: '10px !important',
      paddingTop: 8,
    },
  })
)(TableCell)

const StyledTableHeaderCell = withStyles(() =>
  createStyles({
    root: {
      borderBottom: 'none',
      fontSize: 16,
      paddingLeft: 10,
      paddingRight: '10px !important',
    },
  })
)(TableCell)

export interface IColumnSetting extends ColumnSettingsDto {
  isImage?: boolean
}
export interface IMenuItem {
  color?: string
  isConfirm?: boolean
  icon?: React.ComponentType
  onClick: (e: React.MouseEvent, data: any) => void
  onMouseLeave?: (e: React.MouseEvent) => void
  name: string
  disabled?: boolean
}
export type IESDataTableProps = WithStyles<typeof styles> & {
  customDataRows?: any[]
  dataTableManager: IDataTableManager<any>
  disablePagination?: boolean
  disableSort?: boolean
  elevation?: number
  enableExpandRow?: boolean
  canExpandMultipleRows?: boolean
  enableMenuItems?: boolean
  enableMultiSelect?: boolean
  enableReorderColumns?: boolean
  enableToggleColumns?: boolean
  expandContent?: JSX.Element
  onRowClick?: (event: React.MouseEvent, data: any, idx: number) => void
  onSelected?: (event: React.MouseEvent, data: any, idx: number) => void
  rowCellStyleFunction?: (data: any, columnName?: string) => React.CSSProperties
  rowsPerPage?: number
  title?: string
  tableMaxHeight?: string
  verifiedIcon?: JSX.Element
  selectable?: boolean
}
export interface IESDataTableState {
  isToggleColumnMenuOpen: boolean
  multiSelectToggle: boolean
  selectedRow?: number
}

interface ITablePaginationActionsProps {
  count: number
  color?: string
  icon?: React.ComponentType
  page: number
  rowsPerPage: number
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

interface ITableDisplayedPagesProps {
  from: number
  to: number
  count: number
}
@observer
class ESDataTable extends React.Component<IESDataTableProps, IESDataTableState> {
  public state: IESDataTableState = {
    isToggleColumnMenuOpen: false,
    multiSelectToggle: false,
    selectedRow: undefined,
  }

  public async componentDidMount() {
    this.onPageChange = this.onPageChange.bind(this)
  }

  public setSelectedRow = (idx: number) => {
    this.setState({
      selectedRow: idx,
    })
  }

  public onSortLabelClick = (column: IColumnSetting) => () => {
    if (this.props.disableSort) {
      return
    }
    let newOrderBy: string | undefined
    const { orderBy } = this.props.dataTableManager.tableOptions
    if (!orderBy || orderBy.toLowerCase() !== (column.sort || '').toLowerCase()) {
      newOrderBy = column.fieldName + '+'
    } else if (
      orderBy.toLowerCase() === column.sort!.toLowerCase() &&
      orderBy[orderBy.length - 1] !== '-'
    ) {
      newOrderBy = column.fieldName + '-'
    }
    if (newOrderBy) {
      newOrderBy = this.upperCaseFirstCharacter(newOrderBy)
    }
    const columns = this.props.dataTableManager.columns.map((col) => {
      if (col.id !== column.id) {
        col.sort = ''
      } else {
        col.sort = newOrderBy
      }
      return col
    })
    this.onColumnsChanged(columns)
    this.updateTableOptions({ orderBy: newOrderBy, page: 1 })
  }

  public onRowClick = (rowOfData: any, idx: number) => (event: React.MouseEvent) => {
    if (!this.props.canExpandMultipleRows) {
      const { data } = this.props.dataTableManager
      data.filter((x) => x.id !== rowOfData.id).forEach((x) => (x.isExpanded = false))
    }
    if (this.props.enableExpandRow) {
      rowOfData.isExpanded = !rowOfData.isExpanded
    }

    if (this.props.onRowClick) {
      this.props.onRowClick(event, rowOfData, idx)
    }
    if (this.props.selectable ?? true) {
      this.setSelectedRow(idx)
    }
  }

  public onSelected = (rowOfData: any, idx: number) => (event: React.MouseEvent) => {
    if (this.props.onSelected) {
      this.props.onSelected(event, rowOfData, idx)
    }
  }

  public render() {
    const {
      classes,
      elevation,
      rowsPerPage,
      dataTableManager,
      disablePagination,
      customDataRows,
    } = this.props
    const { count, data } = dataTableManager
    const footer = !disablePagination && (
      <TablePagination
        count={count || 0}
        onPageChange={this.onPageChange}
        page={dataTableManager.tableOptions.page - 1}
        rowsPerPage={rowsPerPage || 25}
        rowsPerPageOptions={[]}
        ActionsComponent={this.TablePaginationActions}
        labelDisplayedRows={this.tableDisplayedRows}
        style={{ color: 'black', borderBottom: 'none' }}
      />
    )
    return (
      <>
        <Paper
          className={classes.paperTable}
          elevation={elevation}
          style={{ maxHeight: this.props.tableMaxHeight, overflowY: 'auto' }}
        >
          <Table size="small">
            {customDataRows === undefined && this.renderHeader()}
            <TableBody>
              {this.renderNoDataRow()}
              {this.renderDataRows()}
              {data && data.length > 10 && this.renderNoDataRow()}
            </TableBody>
            <TableFooter>
              <tr>{footer}</tr>
            </TableFooter>
          </Table>
        </Paper>
      </>
    )
  }

  private tableDisplayedRows(props: ITableDisplayedPagesProps) {
    const { from, to, count } = props
    return `Showing ${from} to ${to === -1 ? count : to} of ${count} entries`
  }

  private TablePaginationActions(props: ITablePaginationActionsProps) {
    const { count, page, rowsPerPage, onPageChange } = props

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1)
    }

    return (
      <Grid
        container
        direction="row"
        style={{
          marginLeft: '10px',
          marginRight: '0',
          minWidth: '192px',
          width: '192px',
        }}
        justifyContent="flex-end"
      >
        <Grid item style={{ marginRight: '20px' }}>
          <Button
            onClick={handleBackButtonClick}
            disabled={page === 0}
            variant="contained"
            size="small"
            style={{ width: '80px', textTransform: 'capitalize' }}
          >
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            variant="contained"
            size="small"
            style={{ width: '80px', textTransform: 'capitalize' }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    )
  }

  public onPageChange = (_event: React.MouseEvent<HTMLButtonElement>, page: number) => {
    this.updateTableOptions({ page: page + 1 })
  }

  private updateTableOptions = (options: ITableOptions) => {
    const tableOptions = {
      ...this.props.dataTableManager.tableOptions,
      ...options,
    }
    this.props.dataTableManager.updateTableOptions(tableOptions)
  }

  private reorderColumns(list: IColumnSetting[], startIndex: number, endIndex: number) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  private upperCaseFirstCharacter(thing = '') {
    return thing.charAt(0).toUpperCase() + thing.substr(1)
  }

  private isAnyMenuItems() {
    const data = this.props.dataTableManager.data
    if (data) {
      return _.some(data, (row) => row.menuItems)
    }
    return false
  }

  private isBoolean(data: any): boolean {
    return typeof data === 'boolean'
  }

  private renderDataRows(): React.ReactNode[] {
    const rows: React.ReactNode[] = []
    const { columns, data } = this.props.dataTableManager
    if (columns && data) {
      data.forEach((rowOfData, idx) => {
        const cells = this.renderCellsInRow(rowOfData, idx)
        const row = (
          <>
            <StyledTableRow
              className={`${this.props.classes.esTableRow} ${
                rowOfData.isActive === false || rowOfData.rescheduled === true
                  ? this.props.classes.disabledRow
                  : ''
              }`}
              data-cy="data-table-row"
              key={rowOfData.id}
              onClick={this.onRowClick(rowOfData, idx)}
              selected={idx === this.state.selectedRow}
              style={{
                cursor: this.props.onRowClick ? 'pointer' : 'auto',
              }}
            >
              {cells}
            </StyledTableRow>
          </>
        )
        rows.push(row)
        if (rowOfData.isExpanded) {
          rows.push(
            <StyledTableRow key={`expanded-${rowOfData.id}`}>
              <StyledTableCell colSpan={this.props.dataTableManager.columns.length + 2}>
                {this.props.expandContent}
              </StyledTableCell>
            </StyledTableRow>
          )
        }
      })
    }
    return rows
  }

  private toggleSelectedDatum =
    (datum: any, idx: number) => (event: React.MouseEvent) => {
      datum.isSelected = !datum.isSelected
      this.props.onSelected!(event, datum, idx)
    }

  private renderCellsInRow(rowOfData: any, idx: number) {
    const { rowCellStyleFunction, enableMenuItems = true, title } = this.props

    const cells: React.ReactNode[] = []
    this.toggleSelectedDatum = this.toggleSelectedDatum.bind(this)
    if (this.props.enableMultiSelect) {
      cells.push(
        <StyledTableCell
          colSpan={1}
          key={`rowCheckToggle-${idx}`}
          size="small"
          style={{ borderBottom: 'none' }}
        >
          <Checkbox
            id={`rowCheckToggle-${idx}`}
            checked={rowOfData.isSelected}
            onClick={this.toggleSelectedDatum(rowOfData, 0)}
          />
        </StyledTableCell>
      )
    }
    if (this.props.customDataRows) {
      cells.push(
        <StyledTableCell key={idx} style={{ borderBottom: 'none' }} size="small">
          {this.props.customDataRows[idx]}
        </StyledTableCell>
      )
    } else {
      this.props.dataTableManager.columns.forEach((column, index) => {
        if (column.isVisible) {
          let cellValue = rowOfData[column.fieldName!]

          const cellStyles: React.CSSProperties = rowCellStyleFunction
            ? { ...rowCellStyleFunction!(rowOfData, column.fieldName) }
            : {}
          if (column.fieldName === 'isActive') {
            if (cellValue) {
              cellValue = 'Active'
            } else {
              cellValue = 'Inactive'
            }
          } else if (this.isBoolean(cellValue)) {
            if (this.props.verifiedIcon) {
              if (cellValue) {
                cellValue = this.props.verifiedIcon
              }
            } else {
              if (cellValue) {
                cellValue = 'Yes'
              } else {
                cellValue = 'No'
              }
            }
          } else if (column.isImage) {
            cellStyles.width = '40px'
            cellValue = <Avatar src={rowOfData[column.fieldName!]} />
          } else if (cellValue instanceof Date) {
            cellValue = moment(cellValue).format('L')
          } else if (column.fieldName === 'displayTags') {
            cellValue = (
              <Grid direction="row">
                {(cellValue !== undefined
                  ? (cellValue as TagDto[])
                  : ([] as TagDto[])
                ).map((x) => (
                  <span
                    style={{
                      backgroundColor: x.tagColor,
                      borderRadius: '2px',
                      color: '#FFFFFF',
                      marginLeft: '1px',
                      marginRight: '8px',
                      padding: '2px',
                      width: 'fit-content',
                    }}
                    key={x.id}
                  >
                    {x.name}
                  </span>
                ))}
              </Grid>
            )
          } else if (column.fieldName === 'message') {
            cellValue = (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '50vw',
                }}
              >
                {cellValue}
              </div>
            )
          } else if (column.type !== 'React.Component') {
            if (!!cellValue) {
              cellValue += ''
            }
          }
          const colSpan = this.getColSpanForDataCell(index, rowOfData)

          cells.push(
            <StyledTableCell
              colSpan={colSpan}
              key={column.id}
              style={{ ...cellStyles, borderBottom: 'none' }}
              size="small"
            >
              {cellValue}
            </StyledTableCell>
          )
        }
      })
    }
    if (rowOfData.menuReplacer) {
      cells.push(
        <StyledTableCell
          key="menuitem"
          align="right"
          size="small"
          style={{ borderBottom: 'none', borderTop: 'none' }}
        >
          {rowOfData.menuReplacer}
        </StyledTableCell>
      )
    } else if (enableMenuItems && rowOfData.menuItems && rowOfData.menuItems.length > 0) {
      cells.push(
        <StyledTableCell
          key="menuitem"
          align="right"
          size="small"
          style={{ borderBottom: 'none', borderTop: 'none' }}
        >
          <ESMenu
            data={rowOfData}
            menuItems={rowOfData.menuItems}
            rowIndex={idx}
            setSelectedRow={
              this.props.selectable == false ? undefined : this.setSelectedRow
            }
            menuAriaLabel={`${title}-${idx}-menu`}
          />
        </StyledTableCell>
      )
    }
    return cells
  }

  private onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const columns = this.reorderColumns(
      this.props.dataTableManager.columns,
      result.source.index,
      result.destination.index
    )

    this.onColumnsChanged(columns)
  }

  private toggleVisibilityOfColumn = (column: IColumnSetting, index: number) => {
    const columns = [...this.props.dataTableManager.columns]
    columns[index].isVisible = !column.isVisible
    this.onColumnsChanged(columns)
  }

  private getColSpanForDataCell(index: number, rowOfData: any) {
    let colSpan = 1
    if (
      this.props.dataTableManager.columns.length - 1 === index &&
      (!rowOfData.menuItems || rowOfData.menuItems.length < 1) &&
      this.isAnyMenuItems()
    ) {
      colSpan = 2
    }
    return colSpan
  }

  private onColumnsChanged(columns: IColumnSetting[]) {
    this.props.dataTableManager.updateColumnSettings(columns)
  }

  private toggleMultiSelect = () => (event: React.MouseEvent) => {
    const { data } = this.props.dataTableManager
    this.setState(
      {
        multiSelectToggle: !this.state.multiSelectToggle,
      },
      () => {
        data.forEach((x) => (x.isSelected = this.state.multiSelectToggle))
        this.props.onSelected!(event, {}, 0)
      }
    )
  }

  private renderHeader(): React.ReactNode {
    const headerCells: React.ReactNode[] = []
    const { enableReorderColumns, enableToggleColumns, dataTableManager, title } =
      this.props
    const { columns, tableOptions } = dataTableManager
    this.toggleMultiSelect = this.toggleMultiSelect.bind(this)
    if (columns) {
      if (this.props.enableMultiSelect) {
        const checkboxTitle = title
          ? `multiSelectCheckbox-${title}`
          : 'multiSelectCheckbox'
        headerCells.push(
          <TableCell colSpan={1} key={checkboxTitle} size="small">
            <Checkbox
              id={checkboxTitle}
              checked={this.state.multiSelectToggle}
              onClick={this.toggleMultiSelect()}
            />
          </TableCell>
        )
      }
      columns.forEach((column, index) => {
        if (column.isVisible) {
          const last = columns.length - 1 === index
          let cellValue = column.columnName
          if (column.isImage) {
            cellValue = ''
          }
          const cellProps: TableCellProps = {}
          if (enableReorderColumns) {
            cellProps.component = DraggableTableHeaderCell(column.id!, index)
          }

          headerCells.push(
            <StyledTableHeaderCell
              style={{
                borderBottom: 'none',
              }}
              {...cellProps}
              key={column.id}
              sortDirection={this.getSortDirection(column)}
              colSpan={
                !enableToggleColumns && this.isAnyMenuItems() && last
                  ? this.props.enableMultiSelect
                    ? 3
                    : 2
                  : 1
              }
            >
              <TableSortLabel
                active={!!tableOptions.orderBy && tableOptions.orderBy === column.sort}
                key={`${column.id}-label`}
                direction={this.getSortDirection(column)}
                onClick={this.onSortLabelClick(column)}
              >
                {cellValue}
              </TableSortLabel>
            </StyledTableHeaderCell>
          )
        }
      })
      if (enableToggleColumns) {
        headerCells.push(
          <StyledTableHeaderCell key="options" align="right">
            <ESDataTableSettingsButton
              columns={columns}
              toggleVisibilityOfColumn={this.toggleVisibilityOfColumn}
            />
          </StyledTableHeaderCell>
        )
      }
    }
    const rowProps: TableRowProps<
      'tr',
      { children?: React.ReactNode; component?: React.ElementType }
    > = {}
    if (enableReorderColumns) {
      rowProps.component = DroppableTableHeaderRow(this.onDragEnd)
    }
    return (
      <TableHead style={{ borderBottom: 'none' }}>
        <TableRow
          {...rowProps}
          className={this.props.classes.esTableRow}
          style={{ borderBottom: 'none' }}
        >
          {headerCells}
        </TableRow>
      </TableHead>
    )
  }

  private getSortDirection = (column: IColumnSetting) => {
    const { orderBy } = this.props.dataTableManager.tableOptions
    if (!orderBy) {
      return
    } else if (orderBy === column.sort) {
      return orderBy[orderBy.length - 1] === '-' ? 'desc' : 'asc'
    }
    return
  }

  private renderNoDataRow(): React.ReactNode {
    const { data, isLoading } = this.props.dataTableManager
    if (isLoading || (data && data.length === 0)) {
      const progressCircle = isLoading && <CircularProgress size={50} />
      const noDataText = !isLoading && data && data.length === 0 && (
        <Typography variant="h4" style={{ fontSize: '1rem' }}>
          No records found
        </Typography>
      )
      const additionalCols = this.isAnyMenuItems() ? 1 : 0
      const numberOfColumns = this.props.dataTableManager.columns.length
      const colSpan =
        numberOfColumns + additionalCols + (this.props.enableMultiSelect ? 1 : 0)
      return (
        <TableRow>
          <TableCell key="progress" colSpan={colSpan} size="small">
            <Grid container justifyContent="center" alignItems="center">
              {progressCircle}
              {noDataText}
            </Grid>
          </TableCell>
        </TableRow>
      )
    }
    return null
  }
}

export default withTheme(withStyles(styles)(ESDataTable))
