import {
  Button,
  createStyles,
  Grid,
  List,
  ListItem,
  Paper,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import _ from 'lodash'
import { inject } from 'mobx-react'
import React from 'react'
import { IPreExistingCondition } from '../../Definitions'
import {
  IdentifierEntityDto,
  PatientPreExistingConditionDto,
} from '../../generated_client'
import { IStores } from '../../Stores'

const styles = ({}: Theme) =>
  createStyles({
    listBorder: {
      border: '#CCCCCC 1px solid',
    },
    listContainer: {
      height: '150px',
      overflowY: 'auto',
    },
  })

interface IPreExistingConditionSelectProps extends WithStyles<typeof styles> {
  getAllPreExistingConditions?: () => Promise<void>
  patientId: string
  patientPreExistingConditions: PatientPreExistingConditionDto[]
  preExistingConditions?: IPreExistingCondition[]
  setFieldValue: (name: string, value: any) => void
}

interface IPreExistingConditionState {
  selectedCondition?: IPreExistingCondition
  selectedPatientCondition?: PatientPreExistingConditionDto
}

class PreExistingConditionSelect extends React.Component<
  IPreExistingConditionSelectProps,
  IPreExistingConditionState
> {
  constructor(props: IPreExistingConditionSelectProps) {
    super(props)
    this.state = {
      selectedCondition: undefined,
      selectedPatientCondition: undefined,
    }
  }

  public componentDidMount() {
    this.props.getAllPreExistingConditions!()
  }

  private addCondition = () => {
    const { selectedCondition } = this.state
    const { patientPreExistingConditions, patientId, setFieldValue } = this.props
    if (selectedCondition) {
      const newCondition = new PatientPreExistingConditionDto({
        patientId,
        preExistingCondition: new IdentifierEntityDto({
          id: selectedCondition.id,
          name: selectedCondition.name,
        }),
        preExistingConditionId: selectedCondition.id,
      })

      const conditions = patientPreExistingConditions.concat([newCondition])
      setFieldValue('preExistingConditions', conditions)
      this.setState({ selectedCondition: undefined })
    }
  }

  private removeCondition = () => {
    const { selectedPatientCondition } = this.state
    const { patientPreExistingConditions, setFieldValue } = this.props
    if (selectedPatientCondition) {
      const conditions = patientPreExistingConditions.filter(
        (c) =>
          c.preExistingConditionId !== selectedPatientCondition.preExistingConditionId
      )
      setFieldValue('preExistingConditions', conditions)
      this.setState({ selectedPatientCondition: undefined })
    }
  }

  public selectCondition = (condition: IPreExistingCondition) => () => {
    this.setState({
      selectedCondition: condition,
      selectedPatientCondition: undefined,
    })
  }

  public selectPatientCondition = (condition: PatientPreExistingConditionDto) => () => {
    this.setState({
      selectedCondition: undefined,
      selectedPatientCondition: condition,
    })
  }

  public render() {
    const {
      patientPreExistingConditions,
      classes,
      preExistingConditions = [],
    } = this.props
    const { selectedCondition, selectedPatientCondition } = this.state

    const availableConditions = preExistingConditions.filter(
      (c) => !patientPreExistingConditions.find((x) => x.preExistingConditionId === c.id)
    )

    return (
      <div>
        <Grid
          item
          container
          direction="row"
          spacing={2}
          alignItems="center"
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <Grid item>
            <Typography
              variant={'body1'}
              color="textSecondary"
              style={{ height: '18px' }}
            >
              PRE-EXISTING CONDITIONS
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column">
          <Grid item container direction="row">
            <Grid item xs={5}>
              <div>Available</div>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <div>Selected</div>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction="row" justifyContent="center">
              <Grid item xs={5}>
                <Paper elevation={0} className={classes.listBorder}>
                  <List
                    dense={true}
                    disablePadding={true}
                    className={this.props.classes.listContainer}
                  >
                    {availableConditions ? (
                      availableConditions.map((x: IPreExistingCondition) => (
                        <ListItem
                          key={x.id}
                          button
                          onClick={this.selectCondition(x)}
                          selected={
                            selectedCondition ? x.id === selectedCondition.id : false
                          }
                        >
                          {x.name}
                        </ListItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: '100%' }}
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.addCondition}
                    >
                      &gt;
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.removeCondition}
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Paper elevation={0} className={classes.listBorder}>
                  <List
                    dense={true}
                    disablePadding={true}
                    className={this.props.classes.listContainer}
                  >
                    {patientPreExistingConditions.map((x) => (
                      <ListItem
                        key={x.preExistingConditionId}
                        button
                        onClick={this.selectPatientCondition(x)}
                        selected={
                          selectedPatientCondition
                            ? x.preExistingConditionId ===
                              selectedPatientCondition.preExistingConditionId
                            : false
                        }
                      >
                        {x.preExistingCondition!.name}
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const InjectedPreExistingConditionSelect = inject<
  IStores,
  IPreExistingConditionSelectProps,
  Partial<IPreExistingConditionSelectProps>,
  any
>((stores: IStores) => ({
  getAllPreExistingConditions: stores.preExistingConditions.getAllPreExistingConditions,
  preExistingConditions: stores.preExistingConditions.preExistingConditions,
}))(PreExistingConditionSelect)

export default withStyles(styles)(InjectedPreExistingConditionSelect)
