import { FormControl, FormHelperText } from '@material-ui/core'
import { Field } from 'formik'
import * as React from 'react'
import { DatePicker } from './FormikDatePicker'

export interface IDatePickerFieldProps {
  name: string
  label: string
  inputId: string
  required?: boolean
  fullWidth?: boolean
  variant?: string
  clearable?: boolean
  className?: string
  shrink?: boolean
  errorMessage?: string
  onChange?: () => void
  disabled?: boolean
}

export default class DatePickerField extends React.Component<IDatePickerFieldProps> {
  constructor(props: IDatePickerFieldProps) {
    super(props)
  }

  public render() {
    const {
      name,
      label,
      inputId,
      required = false,
      fullWidth = false,
      variant,
      clearable,
      className,
      errorMessage,
      onChange,
      disabled = false,
    } = this.props
    return (
      <FormControl
        fullWidth={fullWidth}
        required={required}
        className={className}
        error={errorMessage !== undefined}
      >
        <Field
          format="MM/DD/YYYY"
          label={label}
          name={name}
          disabled={disabled}
          inputProps={{ id: inputId, style: { padding: '8px' } }}
          InputLabelProps={{ shrink: this.shouldShrink() }}
          component={DatePicker}
          variant={variant}
          clearable={clearable}
          required={required}
          onChange={onChange ? onChange!() : undefined}
        />
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    )
  }

  public shouldShrink() {
    // return this.props.shrink ? true : undefined;
    return true // Temporary override for data upload 03/09/21
  }
}
