export interface IClaimLastHistorySummaryResult {
  patientName: string
  patientDOB: string
  eocGeneratedOrLegacyId: string
  claimCreatedOn: Date
  claimCreatedOnDisplay: string
  claimCreatedByUser: string
  billOutCreatedOn?: Date
  billOutCreatedOnDisplay: string
  billOutCreatedByUser: string
  claimModifiedOn: Date
  claimModifiedByUser: string
  billOutModifiedOn?: Date
  billOutModifiedByUser: string

  overallModifiedOnDate: string
  overallModifiedByUser: string
}

export class ClaimLastHistorySummaryResult implements IClaimLastHistorySummaryResult {
  public patientName: string
  public patientDOB: string
  public eocGeneratedOrLegacyId: string
  public claimCreatedOn: Date
  public claimCreatedOnDisplay: string
  public claimCreatedByUser: string
  public billOutCreatedOn?: Date | undefined
  public billOutCreatedOnDisplay: string
  public billOutCreatedByUser: string
  public claimModifiedOn: Date
  public claimModifiedByUser: string
  public billOutModifiedOn?: Date | undefined
  public billOutModifiedByUser: string

  public overallModifiedOnDate: string
  public overallModifiedByUser: string
}
