import { AddCircle, Edit, Lock, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import React from 'react'
import { IColumnSetting } from '../../common/ESDataTable'
import { DefaultComponent, IComponent } from '../../Definitions'
import {
  Client,
  ComponentDto,
  IComponentDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'
import { GetScheduleComponentOptions } from 'src/services/LookupService'
import { DropdownOption } from 'src/viewModels/DropdownOption'

const ColumnSettingsDto = 'ComponentDto'

export default class ComponentsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public components: IComponent[]
  @observable
  public selectedComponent?: IComponent | undefined
  @observable
  public selectedDropdownComponent?: DropdownOption | undefined
  @observable
  public componentOptions: DropdownOption[]
  public dataTableStore: DataTableStore<IComponentDto, IComponent>

  constructor(
    private globalStore: GlobalStore,
    private routerStore: RouterStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IComponentDto, IComponent>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllComponents(filter, page, undefined, orderBy, includeInactives),
      (component) => this.setupComponents(component)
    )
  }

  @action.bound
  public disableComponent(component: IComponent) {
    this.isLoading = true
    return this.client
      .archiveComponent(component.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndComponents() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
          this.addLockIcon()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  private addLockIcon() {
    this.dataTableStore.columns.unshift({
      columnName: 'Locked',
      fieldName: 'lock',
      isVisible: true,
      type: 'React.Component',
    } as IColumnSetting)
  }

  @action.bound
  public getScheduleComponentOptions(includeInactive: boolean) {
    this.isLoading = true
    return GetScheduleComponentOptions(false, includeInactive).then(
      (resp: DropdownOption[]) =>
        runInAction(() => {
          this.componentOptions = resp
        })
    )
  }

  @action.bound
  public getAllComponents() {
    this.isLoading = true
    // Switched to page size of 10000 so it would get all.  Likely no chance of more than 10000 components.
    // Did it in the UI as this is the place where it calls to fill the dropdowns and you don't want it defaulted to 25
    // It is done in a seperate call on the UI for the grid load in administration that calls the same API but with a NULL
    // page size which will be defaulted to 25
    return this.client
      .getAllComponents(undefined, undefined, 10000)
      .then((resp: SwaggerResponse<IComponentDto[]>) =>
        runInAction(() => {
          this.components = this.setupComponents(resp.result)
        })
      )
  }

  @action.bound
  public getAllActiveComponents() {
    this.isLoading = true
    // Switched to page size of 10000 so it would get all.  Likely no chance of more than 10000 components.
    // Did it in the UI as this is the place where it calls to fill the dropdowns and you don't want it defaulted to 25
    // It is done in a seperate call on the UI for the grid load in administration that calls the same API but with a NULL
    // page size which will be defaulted to 25
    return this.client
      .getAllComponents(undefined, undefined, 10000, undefined, false)
      .then((resp: SwaggerResponse<IComponentDto[]>) =>
        runInAction(() => {
          this.components = this.setupComponents(resp.result)
        })
      )
  }

  @action.bound
  public getComponentById(id: string) {
    this.isLoading = true
    return this.client.getComponentById(id).then((resp: SwaggerResponse<IComponentDto>) =>
      runInAction(() => {
        this.selectedComponent = this.addDefaultFields(resp.result)
      })
    )
  }

  @action.bound
  public editComponent(component: IComponent) {
    this.selectedComponent = component
    this.routerStore.push(`/admin/components/component/${component.id}`)
  }

  @action.bound
  public createComponent() {
    this.selectedComponent = DefaultComponent(true)
    this.routerStore.push(`/admin/components/component/`)
  }

  @action.bound
  public componentEditFinished() {
    this.selectedComponent = undefined
    this.routerStore.push(`/admin/components`)
  }

  @action.bound
  public recoverComponent(component: IComponent) {
    this.isLoading = true
    return this.client
      .reactivateComponent(component.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
  }

  @action.bound
  public setSelectedComponent(component: IComponent | undefined) {
    this.selectedComponent = component
  }

  @action.bound
  public setSelectedDropdownComponent(component: DropdownOption | undefined) {
    this.selectedDropdownComponent = component
  }

  @action.bound
  public saveComponent(component: IComponent) {
    this.isLoading = true
    let promise
    const componentDto = new ComponentDto(component)
    if (component.isNew) {
      promise = this.client.createComponent(componentDto)
    } else {
      promise = this.client.updateComponent(component.id, componentDto)
    }
    return promise
      .then(() => {
        this.resetUIState(component.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupComponents = (components: IComponentDto[]) => {
    return components.map((x) => this.setupComponentMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (component: IComponentDto): IComponent => {
    return { ...DefaultComponent(), ...component }
  }

  private setupComponentMenuItems = (component: IComponent) => {
    component.menuItems = [
      {
        icon: Edit,
        name: 'Edit',
        onClick: () => this.editComponent(component),
      },
    ]
    if (component.isActive && !component.isLocked) {
      component.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableComponent(component),
      })
    }
    if (!component.isActive && !component.isLocked) {
      component.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverComponent(component),
      })
    }
    component.lock = this.getLockedIcon(component.isLocked)
    return component
  }

  @action.bound
  public getLockedIcon = (isLocked: boolean) => {
    if (isLocked) {
      return <Lock color="primary" />
    } else {
      return undefined
    }
  }
}
