import * as _ from 'lodash'
import { action, observable } from 'mobx'
import {
  getBillOutPaymentDetails,
  retrieveLineItemsWithBalance,
} from 'src/services/BillOutService'
import { GetReceivePaymentInsuranceOptions } from 'src/services/LookupService'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import { ILineItemSummaryResult } from 'src/viewModels/LineItemSummaryResult'
import { IPaymentDetailSummaryResult } from 'src/viewModels/PaymentDetailSummaryResult'
import { IPaymentLineItem, PaymentLineItem } from 'src/viewModels/PaymentLIneItem'
import {
  IPaymentLineItemDetailSummary,
  PaymentLineItemDetailSummary,
} from 'src/viewModels/PaymentLineItemDetailSummary'
import { ProcessReceivedCheckResults } from 'src/viewModels/ProcessReceivedCheckResult'
import { ICMS1500 } from '../../Definitions'
import { ICMS1500Dto } from '../../viewModels/claim/Cms1500Dto'
import DataTableStore from '../DataTableStore'

export default class ReceivePaymentStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isBillStatusLoading: boolean = false
  @observable
  public cms1500: ICMS1500[]
  @observable
  public selectedCMS1500?: ICMS1500
  @observable
  public selectedCMS1500Id?: string
  @observable
  public selectedListItemsWithBalance?: IPaymentLineItem[]
  @observable
  public selectedAppliedPayments?: IPaymentLineItemDetailSummary[]
  @observable
  public insuranceList?: DropdownOption[]
  @observable
  public typeFilter?: string
  @observable
  public showNegativeBalance: boolean = false
  @observable
  public showZeroBalance: boolean = false
  @observable
  public isOpen: boolean = false
  @observable
  public isProcessReceivedCheckOpen?: boolean = false
  @observable
  public isVerifyReceivedCheckOpen?: boolean = false
  @observable
  public pageSize: number = 5
  public dataTableStore: DataTableStore<ICMS1500Dto, ICMS1500>
  @observable
  public updateParentStateAfterModal?: () => void
  @observable
  public isLogModalOpen: boolean = false
  @observable
  public checkResults?: ProcessReceivedCheckResults
  @observable
  public selectedPaymentSummary?: IPaymentDetailSummaryResult
  @observable
  public insuranceCompanyName: string
  @observable
  public dismissModalIsOpen: boolean = false

  constructor() {}

  @action.bound
  public setTypeFilter(type?: string) {
    this.typeFilter = type
    if (this.updateParentStateAfterModal) {
      this.updateParentStateAfterModal()
    }
  }

  @action.bound
  public setShowNegativeBalance(showBilledOut: boolean) {
    this.showNegativeBalance = showBilledOut
    if (this.showZeroBalance) {
      this.showZeroBalance = false
    }
    if (this.updateParentStateAfterModal) {
      this.updateParentStateAfterModal()
    }
  }

  @action.bound
  public setShowZeroBalance(showPaid: boolean) {
    this.showZeroBalance = showPaid
    if (this.showNegativeBalance) {
      this.showNegativeBalance = false
    }
    if (this.updateParentStateAfterModal) {
      this.updateParentStateAfterModal()
    }
  }

  @action.bound
  public closeDialog = () => {
    this.selectedCMS1500 = undefined
    this.selectedListItemsWithBalance = undefined
    this.isOpen = false
    if (this.updateParentStateAfterModal) {
      this.updateParentStateAfterModal()
    }
  }

  @action.bound
  public openDialog(selectedCMS1500: ICMS1500) {
    this.selectedCMS1500 = selectedCMS1500
    this.isOpen = true
  }

  @action.bound
  public openProcessReceivedCheckDialog(results: ProcessReceivedCheckResults) {
    this.checkResults = results
    this.isProcessReceivedCheckOpen = true
  }

  @action.bound
  public openVerifyReceivedCheckDialog(results: ProcessReceivedCheckResults) {
    this.checkResults = results
    this.isVerifyReceivedCheckOpen = true
  }

  @action.bound
  public openLogModal() {
    this.isLogModalOpen = true
  }

  @action.bound
  public closeLogModal() {
    this.isLogModalOpen = false
    this.selectedCMS1500Id = undefined
  }

  @action.bound
  closeProcessReceivedCheck() {
    this.isProcessReceivedCheckOpen = false
    this.isVerifyReceivedCheckOpen = false
    this.checkResults = undefined
  }

  @action.bound
  public closeDismissBilloutModal = (cancel: boolean) => {
    this.dismissModalIsOpen = false

    if (!cancel) {
      this.selectedCMS1500 = undefined
      this.selectedListItemsWithBalance = undefined
      this.isOpen = false
      if (this.updateParentStateAfterModal) {
        this.updateParentStateAfterModal()
      }
    }
  }

  @action.bound
  public openDismissBilloutModal = () => {
    this.dismissModalIsOpen = true
  }

  public getTypeFilter(type: string) {
    switch (type) {
      case 'Non-Surgical':
        return 1
      case 'Surgical +90':
        return 2
      case 'Surgical':
        return 3
      case 'Global':
        return 4
      case '':
      default:
        return 6
    }
  }

  @action.bound
  public setSelectedCMS1500Id(cms1500Id: string) {
    this.selectedCMS1500Id = cms1500Id
  }

  @action.bound
  public setInsuranceCompanyName(insuranceCompanyName?: string) {
    if (insuranceCompanyName != undefined) {
      this.insuranceCompanyName = insuranceCompanyName
    }
  }

  private loadLineItemDetailsWithBalance = async (cms1500Id: string) => {
    this.isLoading = true

    await retrieveLineItemsWithBalance(cms1500Id).then(
      (response: ILineItemSummaryResult[]) => {
        this.selectedListItemsWithBalance = response.map(
          (item) => new PaymentLineItem(item)
        )
      }
    )
  }

  private loadAppliedPayments = async (cms1500Id: string) => {
    this.isLoading = true

    await getBillOutPaymentDetails(cms1500Id).then(
      (response: IPaymentDetailSummaryResult) => {
        this.selectedPaymentSummary = response
        this.selectedAppliedPayments = response.lineItems.map(
          (item) => new PaymentLineItemDetailSummary(item)
        )
      }
    )
  }

  @action.bound
  public loadReceivePaymentData = async (cms1500Id: string) => {
    this.isLoading = true
    const promises = [] as Array<Promise<void>>
    promises.push(this.loadLineItemDetailsWithBalance(cms1500Id))
    promises.push(this.loadAppliedPayments(cms1500Id))

    await Promise.all(promises).finally(() => {
      this.isLoading = false
      this.isOpen = true
    })
  }

  @action.bound
  public setParentUpdateFunction(parentFunction: () => void) {
    this.updateParentStateAfterModal = parentFunction
  }

  @action.bound
  public getInsuranceDropdown() {
    GetReceivePaymentInsuranceOptions().then((result) => {
      this.insuranceList = result
    })
  }
}
