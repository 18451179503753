import { Grid } from '@material-ui/core'
import { FormikActions, FormikProps, getIn } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import DatePickerField from '../common/DatePickerField/DatePickerField'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import IsActiveToggle from '../common/IsActiveToggle'
import NumberMaskedInput from '../common/NumberMaskedInput'
import SelectField from '../common/SelectField/SelectField'
import TypeAheadField from '../common/TypeAheadField/TypeAheadField'
import { noWhitespaceString } from '../common/YupExtensions'
import { IMedicareCostLookup } from '../Definitions'
import { IStores } from '../Stores'
import States, { IState } from '../utils/States'
import * as Yup from 'yup'
import {
  createIMedicareCostLookupFormValues,
  IMedicareCostLookupFormValues,
  NoteValue,
  toMedicareCostLookupDto,
} from './MedicareCostLookupFormValues'

const MedicareCostLookupSchema = Yup.object().shape({
  effectiveDate: Yup.date().required('Effective Date is Required.'),
  limitingCharge: Yup.number(),
  locality: Yup.string(),
  modifier: Yup.string(),
  nonParAmount: Yup.number(),
  note: Yup.string(),
  parAmount: Yup.number().required('Par Amount is Required.'),
  procedure: noWhitespaceString('Procedure is Required.', true),
  state: noWhitespaceString('State is Required.', true),
})

interface IMedicareCostLookupDialogProps {
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean
  medicareCostLookup?: IMedicareCostLookup
  saveMedicareCostLookup?: (medicareCostLookup: IMedicareCostLookup) => Promise<void>
}

@inject((stores: IStores) => ({
  close: stores.medicareCostLookups.closeDialog,
  isLoading: stores.medicareCostLookups.isLoading,
  isOpen: stores.medicareCostLookups.isModalOpen,
  medicareCostLookup: stores.medicareCostLookups.selectedMedicareCostLookup,
  saveMedicareCostLookup: stores.medicareCostLookups.saveMedicareCostLookup,
}))
export default class MedicareCostLookupDialog extends React.Component<IMedicareCostLookupDialogProps> {
  public save = (
    values: IMedicareCostLookup,
    formikBag: FormikActions<IMedicareCostLookup>
  ) => {
    const medicareCostLookup = toMedicareCostLookupDto(
      values,
      this.props.medicareCostLookup
    )
    this.props.saveMedicareCostLookup!(medicareCostLookup).finally(() =>
      formikBag.setSubmitting(false)
    )
  }

  private getValue = (item: string) => {
    return item
  }

  private bottomActions(formikProps: FormikProps<IMedicareCostLookupFormValues>) {
    const onChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      formikProps.setFieldValue('isActive', checked)
    }
    return <IsActiveToggle isActive={formikProps.values.isActive} onChange={onChange} />
  }

  public render() {
    const { close, isLoading, isOpen, medicareCostLookup } = this.props
    const hasValue = (field: string, values: IMedicareCostLookupFormValues) => {
      const value = getIn(values, field)

      return !!value
    }
    const getStateName = (state: IState) => (state ? state.name : '')
    return (
      <ESDialog
        close={close!}
        closeButtonText="Cancel"
        initialValues={createIMedicareCostLookupFormValues(medicareCostLookup)}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={medicareCostLookup!.isNew ? 'Add New Medicare Cost' : 'Edit Medicare Cost'}
        validationSchema={MedicareCostLookupSchema}
        bottomActions={this.bottomActions}
      >
        {({ setFieldValue, errors, values, initialValues }) => (
          <Grid container direction="column" spacing={3} style={{ marginTop: '20px' }}>
            <Grid item container direction="row" spacing={2}>
              <Grid item>
                <TypeAheadField
                  label="State"
                  labelShrink={hasValue('state', values)}
                  inputId={'state'}
                  name={'state'}
                  cypressLabel="states"
                  childCypressLabel="state"
                  fullWidth
                  setFieldValue={setFieldValue}
                  initialValue={
                    typeof initialValues.state === 'string'
                      ? { name: initialValues.state }
                      : initialValues.state
                  }
                  selectedValue={
                    typeof values.state === 'string'
                      ? { name: values.state }
                      : values.state
                  }
                  items={States}
                  getName={getStateName}
                  getValue={getStateName}
                  required={true}
                  errorMessage={getIn(errors, 'state')}
                  outlined={true}
                />
              </Grid>
              <Grid item>
                <FullWidthField name="locality" label="Locality" variant="outlined" />
              </Grid>
              <Grid item>
                <FullWidthField
                  name="procedure"
                  label="Procedure"
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item>
                <FullWidthField name="mod" label="Modifier" variant="outlined" />
              </Grid>
              <Grid item xs={3}>
                <SelectField
                  inputId="note"
                  getName={this.getValue}
                  getValue={this.getValue}
                  items={['', NoteValue.Performed, NoteValue.Payment]}
                  label="Note"
                  name="note"
                  fullWidth={true}
                  shrinkLabel={hasValue('note', values)}
                  outlined={true}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2}>
              <Grid item>
                <FullWidthField
                  required
                  name="parAmount"
                  label="Par Amount"
                  InputProps={{
                    inputComponent: NumberMaskedInput,
                    inputProps: {
                      currency: true,
                      guide: false,
                      numberOfDigits: 12,
                    },
                  }}
                  InputLabelProps={{
                    shrink: hasValue('parAmount', values),
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <FullWidthField
                  name="nonParAmount"
                  label="Non-Par Amount"
                  InputProps={{
                    inputComponent: NumberMaskedInput,
                    inputProps: {
                      currency: true,
                      guide: false,
                      numberOfDigits: 12,
                    },
                  }}
                  InputLabelProps={{
                    shrink: hasValue('nonParAmount', values),
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <FullWidthField
                  name="limitingCharge"
                  label="Limiting Charge"
                  InputProps={{
                    inputComponent: NumberMaskedInput,
                    inputProps: {
                      currency: true,
                      guide: false,
                      numberOfDigits: 12,
                    },
                  }}
                  InputLabelProps={{
                    shrink: hasValue('limitingCharge', values),
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2}>
              <Grid item>
                <DatePickerField
                  inputId="effectiveDate"
                  name="effectiveDate"
                  label="Effective Date"
                  shrink={hasValue('effectiveDate', values)}
                  required
                  clearable
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }
}
