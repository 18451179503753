export interface IClaimsAcquisitionSearchRequest {
  page: number
  pageSize: number
  urgentOnly: boolean
  components: string[] | undefined
  claimsAcqStatuses?: number[] | undefined
  billingName: string
  billingServicer: string
  dateOfServiceEnd: string
  dateOfServiceStart: string
  providerGroup: string
  patientFirstName: string
  patientLastName: string
  claimsOnly: boolean
  insuranceCompany: string
}

export class ClaimsAcquisitionSearchRequest implements IClaimsAcquisitionSearchRequest {
  public page: number
  public pageSize: number
  public urgentOnly: boolean
  public components: string[] | undefined
  public claimsAcqStatuses?: number[] | undefined
  public billingName: string
  public billingServicer: string
  public dateOfServiceEnd: string
  public dateOfServiceStart: string
  public providerGroup: string
  public patientFirstName: string
  public patientLastName: string
  public claimsOnly: boolean
  public insuranceCompany: string
}
