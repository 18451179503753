export interface IInvoiceLogDTO {
  documentId?: string | undefined
  invoiceId: string | undefined
  totalAmountPaid: number
  ucTotal?: number
  invoiceTotal?: number
  sentDate?: Date | undefined
  createdByName: string
  notes?: string | undefined
}

export class InvoiceLogDTO {
  public documentId?: string | undefined
  public invoiceId: string | undefined
  public totalAmountPaid: number
  public ucTotal?: number
  public invoiceTotal?: number
  public sentDate?: Date | undefined
  public createdByName: string
  public notes?: string | undefined
}
