import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core'
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { UnfoldMore } from '@material-ui/icons'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import Downshift, {
  GetItemPropsOptions,
  GetMenuPropsOptions,
  GetPropsCommonOptions,
} from 'downshift'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { IEmployer } from 'src/Definitions'
import { IState } from 'src/utils/States'
import { DropdownOption } from 'src/viewModels/DropdownOption'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

export interface ITypeAheadFieldProps<T> {
  disabled?: boolean
  labelShrink?: boolean
  name: string
  label?: string
  items?: T[]
  initialValue?: T | T[]
  selectedValue?: T | T[]
  inputId?: string
  required?: boolean
  fullWidth?: boolean
  showResultsWhileEmpty?: boolean
  errorMessage?: string
  cypressLabel?: string
  childCypressLabel?: string
  getItems?: (filter: string) => void
  getName: (item: T) => string
  getValue: (item: T) => string
  setFieldValue: (field: string, value: any) => void
  outlined?: boolean
  placeholder?: string
  updateParentComponent?: (value: any) => void
}

interface IRenderItemsProps<T> {
  childCypressLabel?: string
  filter: string | null
  getItemProps: (options: GetItemPropsOptions<any>) => any
  highlightedIndex: number | null
  selectedItem: T
  showResultsWhileEmpty: boolean
}

interface IRenderPopperProps<T> extends IRenderItemsProps<T> {
  isOpen: boolean
  getMenuProps: (
    options?: GetMenuPropsOptions | undefined,
    otherOptions?: GetPropsCommonOptions | undefined
  ) => any
}

interface IRenderSingleItemProps<T> {
  childCypressLabel?: string
  item: T
  highlightedIndex: number | null
  itemProps: MenuItemProps
  index: number
  selectedItem: T
}

interface IPopperState<T> {
  popperNode: HTMLElement | null | undefined
  currentFilter: string
  allItemsSoFar: T[] | null
  labelWidth: number
  isInputFocused: boolean
  tabFilter: string
}

export default class TypeAheadField<T> extends React.Component<
  ITypeAheadFieldProps<T>,
  IPopperState<T>
> {
  private InputLabelRef: HTMLElement | null
  constructor(props: ITypeAheadFieldProps<T>) {
    super(props)
    this.state = {
      allItemsSoFar: null,
      currentFilter: '',
      isInputFocused: false,
      labelWidth: 0,
      popperNode: null,
      tabFilter: '',
    }
  }

  public componentDidMount() {
    if (this.props.outlined) {
      this.setState({
        labelWidth: (
          ReactDOM.findDOMNode(this.InputLabelRef as React.ReactInstance) as HTMLElement
        ).offsetWidth,
      })
    }
  }

  public onDownshiftChange = (selection: T) => {
    const selectedValue = this.props.getValue(selection)
    if (this.props.items) {
      const foundItem = this.props.items!.find((item) => {
        return this.props.getValue(item) === selectedValue
      })
      if (foundItem) {
        this.props.setFieldValue(this.props.name, foundItem)
        if (this.props.updateParentComponent != undefined) {
          this.props.updateParentComponent!(foundItem)
        }
      }
    }

    this.focus()
  }

  public focus = () => {
    if (this.state.popperNode) {
      this.state.popperNode.focus()
    }
  }

  public getItemsWithFilter(filter = '') {
    if (this.props.items) {
      return this.props.items.filter(
        (item) =>
          !filter || this.props.getName(item).toLowerCase().includes(filter.toLowerCase())
      )
    }
    return []
  }

  public handleItemDelete = (item: T) => () => {
    if (this.props.selectedValue instanceof Array) {
      const selectedItems = this.props.selectedValue ? [...this.props.selectedValue] : []
      selectedItems.splice(selectedItems.indexOf(item), 1)
      this.props.setFieldValue(this.props.name, selectedItems)
    }
  }

  public itemToString = (item: T) => {
    if (this.props.getName && item) {
      return this.props.getName(item)
    }
    return ''
  }

  public setPopperNode = (popperNode: HTMLElement) => {
    this.setState({ popperNode })
  }

  public render() {
    const {
      disabled = false,
      label,
      fullWidth = false,
      required = false,
      showResultsWhileEmpty = false,
      errorMessage,
      cypressLabel,
      childCypressLabel,
      outlined,
      placeholder,
    } = this.props
    const initialItem = this.props.initialValue
    const selectItem = this.props.selectedValue

    return (
      <FormControl
        fullWidth={fullWidth}
        required={required}
        error={errorMessage !== undefined}
        disabled={disabled}
        variant={outlined ? 'outlined' : undefined}
      >
        <Downshift
          itemToString={this.itemToString}
          onChange={this.onDownshiftChange}
          initialSelectedItem={initialItem || null}
          selectedItem={selectItem || null}
          onInputValueChange={this.onInputValueChanged}
          onStateChange={this.onStateChange}
        >
          {({
            clearSelection,
            isOpen,
            inputValue,
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            selectedItem,
          }) => (
            <div>
              <InputLabel
                ref={
                  outlined
                    ? (ref) => {
                        this.InputLabelRef = ref
                      }
                    : undefined
                }
                variant={outlined ? 'outlined' : undefined}
                shrink={this.shouldShrinkLabel(inputValue)}
                style={
                  this.shouldShrinkLabel(inputValue)
                    ? {}
                    : { transform: 'translate(10px, 10px)' }
                }
              >
                {label}
              </InputLabel>
              {outlined ? (
                <OutlinedInput
                  style={{ width: '100%' }}
                  data-cy={cypressLabel}
                  autoComplete="off"
                  placeholder={placeholder}
                  {...getInputProps({
                    labelWidth: this.state.labelWidth,
                    onBlur: this.onInputBlur,
                    onChange: (e) => {
                      if (e.target.value === '') {
                        clearSelection()
                      }
                    },
                    onFocus: this.onInputFocus,
                    onKeyDown: this.onKeyDown,
                  })}
                  ref={this.setPopperNode}
                  color={undefined}
                  endAdornment={
                    <InputAdornment position="end">
                      <UnfoldMore />
                    </InputAdornment>
                  }
                  inputProps={{
                    style: { padding: '8px' },
                  }}
                />
              ) : (
                <Input
                  {...getInputProps({
                    onBlur: this.onInputBlur,
                    onChange: (e) => {
                      if (e.target.value === '') {
                        clearSelection()
                      }
                    },
                    onFocus: this.onInputFocus,
                    onKeyDown: this.onKeyDown,
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <UnfoldMore />
                    </InputAdornment>
                  }
                  ref={this.setPopperNode}
                  color={undefined}
                  style={{ width: '100%' }}
                  data-cy={cypressLabel}
                  autoComplete="off"
                  placeholder={placeholder}
                />
              )}
              {this.renderPopper({
                childCypressLabel,
                filter: inputValue,
                getItemProps,
                getMenuProps,
                highlightedIndex,
                isOpen,
                selectedItem,
                showResultsWhileEmpty,
              })}
            </div>
          )}
        </Downshift>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    )
  }

  private onKeyDown = (
    _event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { inputId, name, setFieldValue } = this.props
    if (_event.key === 'Tab') {
      const items = this.getItemsWithFilter(this.state.tabFilter)
      if (items.length === 1) {
        setFieldValue(name, items.pop())
      }
    } else if (
      (_event.keyCode >= 48 && _event.keyCode <= 57) ||
      (_event.keyCode >= 65 && _event.keyCode <= 90) ||
      _event.key === 'Backspace' ||
      _event.key === 'Delete'
    ) {
      setFieldValue(name, undefined)
      if (inputId) {
        setFieldValue(inputId, undefined)
      }
    }
  }

  private onInputFocus = (
    _event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    this.setState({ isInputFocused: true })
  }

  private onInputBlur = (
    _event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    this.setState({ isInputFocused: false })
  }

  private onInputValueChanged = (inputValue: string) => {
    const { inputId, name, setFieldValue, selectedValue } = this.props

    if (
      selectedValue &&
      (inputValue === '' ||
        (!(selectedValue as unknown as IEmployer).isActive &&
          !(selectedValue as unknown as DropdownOption).isEnabled &&
          (selectedValue as unknown as IState).name?.length != 2))
    ) {
      setFieldValue(name, undefined)
      if (inputId) {
        setFieldValue(inputId, undefined)
      }
    }
  }

  private onStateChange = (changes: any) => {
    if (changes.hasOwnProperty('inputValue')) {
      this.setState({ currentFilter: changes.inputValue })
    }
  }

  private shouldShrinkLabel(inputValue: string | null) {
    const hasInput = !!inputValue
    if (hasInput) {
      return true
    } else if (this.props.placeholder && this.state.isInputFocused) {
      return true
    } else if (this.props.outlined && this.state.isInputFocused) {
      return true
    }
    return false
  }

  private debouncedFilterItems = AwesomeDebouncePromise(
    (filter: string, showResultsWhileEmpty: boolean) => {
      if (this.props.getItems && (filter !== '' || showResultsWhileEmpty)) {
        this.props.getItems(filter)
        this.setState({ currentFilter: filter })
      }
    },
    600
  )

  private renderItems = (renderItemsProps: IRenderItemsProps<T>) => {
    const {
      childCypressLabel,
      filter = '',
      getItemProps,
      highlightedIndex,
      selectedItem,
      showResultsWhileEmpty,
    } = renderItemsProps
    if (this.props.getItems) {
      if (this.state.currentFilter !== filter) {
        this.debouncedFilterItems(filter, showResultsWhileEmpty)
        return null
      } else if (this.props.items) {
        return this.props.items.map((item, index) =>
          this.renderSingleItem({
            childCypressLabel,
            highlightedIndex,
            index,
            item,
            itemProps: getItemProps({ item }),
            selectedItem,
          })
        )
      }
    } else if (filter === '' && !showResultsWhileEmpty) {
      return null
    }
    if (filter !== null && this.state.tabFilter !== filter) {
      this.setState({ tabFilter: filter })
    }
    return this.getItemsWithFilter(filter!).map((item, index) =>
      this.renderSingleItem({
        childCypressLabel,
        highlightedIndex,
        index,
        item,
        itemProps: getItemProps({ item }),
        selectedItem,
      })
    )
  }

  private renderPopper = (renderPopperProps: IRenderPopperProps<T>) => {
    const {
      filter,
      getItemProps,
      highlightedIndex,
      selectedItem,
      showResultsWhileEmpty,
      isOpen,
      getMenuProps,
      childCypressLabel,
    } = renderPopperProps
    return (
      <Popper
        open={isOpen}
        anchorEl={this.state.popperNode}
        placement="bottom-start"
        modifiers={{
          preventOverflow: {
            boundariesElement: 'viewport',
            enabled: true,
          },
        }}
        style={{
          zIndex: 1400,
        }}
      >
        <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
          <Paper
            style={{
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              overflow: 'auto',
              width:
                this.state.popperNode && this.state.popperNode.parentElement
                  ? this.state.popperNode.parentElement.clientWidth
                  : undefined,
            }}
          >
            {isOpen
              ? this.renderItems({
                  childCypressLabel,
                  filter: filter as string,
                  getItemProps,
                  highlightedIndex,
                  selectedItem,
                  showResultsWhileEmpty,
                })
              : null}
          </Paper>
        </div>
      </Popper>
    )
  }

  private renderSingleItem = (renderSingleItemProps: IRenderSingleItemProps<T>) => {
    const { childCypressLabel, item, index, itemProps, highlightedIndex, selectedItem } =
      renderSingleItemProps
    const isHighlighted = highlightedIndex === index
    const isSelected =
      item && selectedItem
        ? this.props.getValue(item) === this.props.getValue(selectedItem)
        : false
    return (
      <MenuItem
        {...itemProps}
        button={itemProps.button || undefined}
        data-cy={childCypressLabel}
        key={this.props.getValue(item)}
        selected={isHighlighted}
        style={{ fontWeight: isSelected ? 500 : 400 }}
        disabled={
          !(item as unknown as IEmployer).isActive &&
          !(item as unknown as DropdownOption).isEnabled &&
          (item as unknown as IState).name?.length != 2
        }
      >
        {this.props.getName(item)}
      </MenuItem>
    )
  }
}
