import { PersonOutline } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultContactType, IContactType } from '../Definitions'
import { IStores } from '../Stores'
import ContactTypeDialog from './ContactTypeDialog'

interface IContactTypesProps {
  dataTableManager?: IDataTableManager<IContactType>
  disableContactType?: (contactType: IContactType) => void
  getColumnSettingsAndContactTypes?: () => void
  isModalOpen?: boolean
  openDialogWithContactType?: (contactType: IContactType) => void
  recoverContactType?: (contactType: IContactType) => void
}

@inject((stores: IStores) => ({
  dataTableManager: stores.contactTypes.dataTableStore,
  disableContactType: stores.contactTypes.disableContactType,
  getColumnSettingsAndContactTypes: stores.contactTypes.getColumnSettingsAndContactTypes,
  isModalOpen: stores.contactTypes.isModalOpen,
  openDialogWithContactType: stores.contactTypes.openDialogWithContactType,
  recoverContactType: stores.contactTypes.recoverContactType,
}))
class ContactTypes extends React.Component<IContactTypesProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndContactTypes!()
  }

  public render() {
    const contactTypeDialog = this.props.isModalOpen && <ContactTypeDialog />
    return (
      <>
        <Helmet>
          <title>Contact Types</title>
        </Helmet>
        <ResponsiveAppBar title="Contact Types" pageIcon={<PersonOutline />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={true}
          enableToggleColumns={true}
          addButtonOnClick={this.openDialog(DefaultContactType(true))}
        />
        {contactTypeDialog}
      </>
    )
  }

  public openDialog = (contactType: IContactType) => () => {
    this.props.openDialogWithContactType!(contactType)
  }
}

export default ContactTypes
