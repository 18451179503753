import Axios from 'axios'
import { DocumentInboxItem } from 'src/viewModels/workflow/DocumentInboxItem'
import { InboxSummary } from 'src/viewModels/workflow/InboxSummary'
import { IncomingDocumentSummary } from 'src/viewModels/IncomingDocumentSummary'
import { IProcessAction, ProcessAction } from 'src/viewModels/workflow/ProcessAction'
import { SearchRequestBase } from 'src/viewModels/SearchRequestBase'
import { UserDropdownOption } from 'src/viewModels/UserDropdownOption'
import { pagedList } from 'src/viewModels/pagedList'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import { WorkflowInboxItemHeader } from 'src/viewModels/workflow/InboxItemHeader'
import { ClaimInboxItem } from 'src/viewModels/workflow/ClaimInboxItem'
import { StagedDocumentDTO } from 'src/viewModels/StagedDocumentDTO'
import { ICMS1500Dto } from 'src/viewModels/claim/Cms1500Dto'
import { ClaimValidationSummary } from 'src/viewModels/workflow/ClaimValidationSummary'
import { QuoteInboxItem } from 'src/viewModels/workflow/QuoteInboxItem'
import { InboxType } from 'src/utils/InboxType'
import { BillOutInboxItem } from 'src/viewModels/workflow/BillOutInboxItem'
import { BilloutDetailSummary } from 'src/viewModels/workflow/BilloutDetailSummary'

const base_url = process.env.REACT_APP_WORKFLOW_BASE_URL || 'http://localhost:5002'

export async function getAllIncomingDocuments(
  data: SearchRequestBase
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/document/incoming-documents`,
    data
  )

  return response.data
}

export async function getAllInboxItems(
  data: SearchRequestBase,
  inboxId: string,
  sortColumnQuery: string
): Promise<pagedList> {
  const response = await Axios.get<pagedList>(
    `${base_url}/api/inbox/${inboxId}/inbox-items`,
    {
      params: {
        page: data.page,
        pageSize: data.pageSize,
        sort: sortColumnQuery,
      },
    }
  )

  return response.data
}

export async function getInboxesByUser(type?: InboxType): Promise<Array<InboxSummary>> {
  const response = await Axios.get<Array<InboxSummary>>(`${base_url}/api/inbox`, {
    params: {
      inboxType: type,
    },
  })

  return response.data
}

export async function processToWorkflow(
  data: IncomingDocumentSummary
): Promise<DocumentInboxItem> {
  const response = await Axios.post<DocumentInboxItem>(
    `${base_url}/api/document/workflow`,
    data
  )

  return response.data
}

export async function getDocumentInboxItem(
  documentId: string,
  inboxId: string
): Promise<DocumentInboxItem> {
  const response = await Axios.get<DocumentInboxItem>(
    `${base_url}/api/document/${documentId}/inbox/${inboxId}`
  )

  return response.data
}

export async function processAction(
  data: IProcessAction,
  actionId: string,
  itemId: string
): Promise<WorkflowInboxItemHeader> {
  const response = await Axios.post<WorkflowInboxItemHeader>(
    `${base_url}/api/inbox-item/${itemId}/actions/${actionId}`,
    data
  )

  return response.data
}

export async function processDynamicAction(
  data: ProcessAction,
  inboxId: string,
  actionId: string,
  itemId: string
): Promise<WorkflowInboxItemHeader> {
  const response = await Axios.post<WorkflowInboxItemHeader>(
    `${base_url}/api/inbox/${inboxId}/actions/${actionId}/process`,
    data,
    {
      params: {
        itemId: itemId,
      },
    }
  )

  return response.data
}

export async function GetUserOptions(
  inboxId: string,
  actionId: string
): Promise<Array<UserDropdownOption>> {
  const response = await Axios.get<Array<UserDropdownOption>>(
    `${base_url}/api/inbox/${inboxId}/actions/${actionId}/assign-users`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function GetPatientOptions(
  patientInfo: string
): Promise<Array<DropdownOption>> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/patients`,
    {
      params: {
        filter: patientInfo,
      },
    }
  )

  return response.data
}

export async function getClaimInboxItem(
  claimId: string,
  inboxId: string
): Promise<ClaimInboxItem> {
  const response = await Axios.get<ClaimInboxItem>(
    `${base_url}/api/claim/${claimId}/inbox/${inboxId}`
  )

  return response.data
}

export async function getDefaultClaimInboxItem(claimId: string): Promise<ClaimInboxItem> {
  const response = await Axios.get<ClaimInboxItem>(
    `${base_url}/api/claim/${claimId}/default-inbox`
  )

  return response.data
}

export async function getStagedDocument(docId: string): Promise<StagedDocumentDTO> {
  const response = await Axios.get<StagedDocumentDTO>(`${base_url}/api/document/${docId}`)

  return response.data
}

export async function getQuoteInboxItem(
  quoteId: string,
  inboxId: string
): Promise<QuoteInboxItem> {
  const response = await Axios.get<QuoteInboxItem>(
    `${base_url}/api/quote/${quoteId}/inbox/${inboxId}`
  )

  return response.data
}

export async function getDefaultQuoteInboxItem(quoteId: string): Promise<QuoteInboxItem> {
  const response = await Axios.get<QuoteInboxItem>(
    `${base_url}/api/quote/${quoteId}/default-inbox`
  )

  return response.data
}

export async function getBilloutInboxItem(
  billoutId: string,
  inboxId: string
): Promise<BillOutInboxItem> {
  const response = await Axios.get<BillOutInboxItem>(
    `${base_url}/api/billout/${billoutId}/inbox/${inboxId}`
  )

  return response.data
}

export async function getDefaultBilloutInboxItem(
  billoutId: string
): Promise<BillOutInboxItem> {
  const response = await Axios.get<BillOutInboxItem>(
    `${base_url}/api/billout/${billoutId}/default-inbox`
  )

  return response.data
}

export async function getDocumentHistory(
  docId: string,
  data: SearchRequestBase
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/document/${docId}/history`,
    data
  )

  return response.data
}

export async function getClaimHistory(
  claimId: string,
  data: SearchRequestBase
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/claim/${claimId}/history`,
    data
  )

  return response.data
}

export async function getQuoteHistory(
  quoteId: string,
  data: SearchRequestBase
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/quote/${quoteId}/history`,
    data
  )

  return response.data
}

export async function getBilloutHistory(
  billoutId: string,
  data: SearchRequestBase
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/billout/${billoutId}/history`,
    data
  )

  return response.data
}

export async function completeValidation(
  claimId: string,
  requireContract: boolean,
  data: ICMS1500Dto
): Promise<ClaimValidationSummary> {
  const response = await Axios.post<ClaimValidationSummary>(
    `${base_url}/api/claim/${claimId}/complete-validation`,
    data,
    {
      params: {
        requireContract: requireContract,
      },
    }
  )

  return response.data
}

export async function GetBilloutDetailsByClaim(
  claimId: string
): Promise<BilloutDetailSummary> {
  const response = await Axios.get<BilloutDetailSummary>(
    `${base_url}/api/lookup/claim/${claimId}/billout-detail-summary`
  )

  return response.data
}

export async function GetClaimReadyForBilloutInbox(): Promise<number> {
  const response = await Axios.get<number>(
    `${base_url}/api/lookup/claim-ready-for-billout-inbox`
  )

  return response.data
}
