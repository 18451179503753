export interface IReceivedCheckTotalSummary {
  checkCount: number
  totalCheckAmount: number
  totalCheckAppliedFunds: number
}
export class ReceivedCheckTotalSummary {
  public checkCount: number
  public totalCheckAmount: number
  public totalCheckAppliedFunds: number
}
