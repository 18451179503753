import { StaticAction } from './StaticAction'

export enum QuoteStaticAction {
  PEND = StaticAction.PEND,
  ASSIGN = StaticAction.ASSIGN,
  RELEASE_LOCK = StaticAction.RELEASE_LOCK,
  FINISH = StaticAction.FINISH,
  AUTO_ASSIGN = StaticAction.AUTO_ASSIGN,
  //VALUES 6-15 reserved for Claim ItemType
  //VALUES 16-25 reserved for Quote ItemType
  SAVE_QUOTE = 16,
  CREATE_INVOICE = 17,
  RECONCILED_CLAIM = 18,
}
