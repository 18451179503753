import { Hotel } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultProcedure, IProcedure } from '../Definitions'
import { IStores } from '../Stores'
import ProcedureDialog from './ProcedureDialog'

interface IProceduresProps {
  dataTableManager?: IDataTableManager<IProcedure>
  disableProcedure?: (procedure: IProcedure) => void
  getColumnSettingsAndProcedures?: () => void
  isModalOpen?: boolean
  openDialogWithProcedure?: (procedure: IProcedure) => void
  recoverProcedure?: (procedure: IProcedure) => void
}

@inject((stores: IStores) => ({
  dataTableManager: stores.procedures.dataTableStore,
  disableProcedure: stores.procedures.disableProcedure,
  getColumnSettingsAndProcedures: stores.procedures.getColumnSettingsAndProcedures,
  isModalOpen: stores.procedures.isModalOpen,
  openDialogWithProcedure: stores.procedures.openDialogWithProcedure,
  recoverProcedure: stores.procedures.recoverProcedure,
}))
class Procedures extends React.Component<IProceduresProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndProcedures!()
  }

  public render() {
    const procedureDialog = this.props.isModalOpen && <ProcedureDialog />
    return (
      <>
        <Helmet>
          <title>Procedures</title>
        </Helmet>
        <ResponsiveAppBar title="Procedures" pageIcon={<Hotel />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={true}
          enableToggleColumns={true}
          addButtonOnClick={this.openDialog(DefaultProcedure(true))}
        />
        {procedureDialog}
      </>
    )
  }

  public openDialog = (procedure: IProcedure) => () => {
    this.props.openDialogWithProcedure!(procedure)
  }
}

export default Procedures
