import {
  Button,
  createStyles,
  Grid,
  Paper,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { Person, RecordVoiceOver } from '@material-ui/icons'
import { Form, Formik, FormikActions } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Prompt, RouteComponentProps } from 'react-router-dom'
import AddressField from '../common/AddressField/AddressField'
import CardWithTitle from '../common/CardWithTitle/CardWithTitle'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import FullWidthField from '../common/FullWidthField'
import IsActiveToggle from '../common/IsActiveToggle'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { noWhitespaceString } from '../common/YupExtensions'
import ContactEditDialog from '../contacts/ContactEditDialog'
import {
  DefaultContact,
  DefaultReferringDoctor,
  IContact,
  IReferringDoctor,
} from '../Definitions'
import { IContactDto } from '../generated_client'
import { IStores } from '../Stores'
import DataTableStore from '../stores/DataTableStore'
import * as Yup from 'yup'
import {
  createIReferringDoctorFormValues,
  IReferringDoctorFormValues,
  toIReferringDoctor,
} from './ReferringDoctorFormValues'
import _logic from './ReferringDoctorLogic'
import { Helmet } from 'react-helmet'

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      paddingTop: spacing(2),
    },
    topOfPage: {
      marginTop: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  })

const ReferringDoctorSchema = Yup.object().shape({
  address: Yup.object({
    city: noWhitespaceString('City is required', true),
    line1: noWhitespaceString('Street address is required', true),
    line2: Yup.string(),
    state: noWhitespaceString('State is required', true),
    zip: noWhitespaceString('Zip code is required', true),
  }),
  firstName: noWhitespaceString('First name is required', true),
  lastName: noWhitespaceString('Last name is required', true),
  npi: Yup.string(),
  preferences: Yup.string(),
  suffix: Yup.string(),
})

interface IEditReferringDoctorParams {
  referringDoctorId: string
}

interface IEditReferringDoctorProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IEditReferringDoctorParams> {
  contactsDataTableStore?: DataTableStore<IContactDto, IContact>
  createContact?: (contact: IContact, title: string) => void
  getReferringDoctorById?: (id: string) => Promise<void>
  isLoading?: boolean
  referringDoctor?: IReferringDoctor
  referringDoctorEditFinished?: () => void
  rerouteToPath?: (path: string) => void
  saveReferringDoctor?: (referringDoctor: IReferringDoctor) => Promise<void>
  setSelectedReferringDoctor?: (referringDoctor: IReferringDoctor | undefined) => void
  getColumnSettingsAndContacts?: () => Promise<void> | undefined
}

interface IEditReferringDoctorState {
  reload: boolean
}
class EditReferringDoctor extends React.Component<
  IEditReferringDoctorProps,
  IEditReferringDoctorState
> {
  constructor(props: IEditReferringDoctorProps) {
    super(props)
    this.state = {
      reload: false,
    }
  }

  private async setup() {
    const { match } = this.props
    if (match && match.params.referringDoctorId) {
      await this.props.getReferringDoctorById!(match.params.referringDoctorId)
    } else {
      this.props.setSelectedReferringDoctor!(DefaultReferringDoctor(true))
    }
    await this.props.getColumnSettingsAndContacts!()
  }

  public async componentDidMount() {
    ;(window as any).formikRef = React.createRef()

    await this.setup()
  }

  public async componentDidUpdate(prevProps: IEditReferringDoctorProps) {
    if (
      this.props.match.params.referringDoctorId !==
      prevProps.match.params.referringDoctorId
    ) {
      await this.setup()
    }
  }

  public componentWillUnmount() {
    this.props.setSelectedReferringDoctor!(undefined)
  }

  private cancel = () => {
    this.props.rerouteToPath!('/admin/referringDoctors')
  }

  private handleIsActiveChange =
    (
      values: IReferringDoctorFormValues,
      setFieldValue: (field: string, value: any) => void
    ) =>
    () => {
      setFieldValue('isActive', !values.isActive)
    }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload }, () => {
      submitForm()
    })
  }

  public save = async (
    values: IReferringDoctorFormValues,
    formikBag: FormikActions<IReferringDoctorFormValues>
  ) => {
    const referringDoctor = await toIReferringDoctor(values, this.props.referringDoctor)
    this.props.saveReferringDoctor!(referringDoctor).finally(() => {
      formikBag.setSubmitting(false)
      if (this.state.reload) {
        this.props.rerouteToPath!(
          `/admin/referringDoctors/referringDoctor/${referringDoctor.id}`
        )
        this.setup()
      } else {
        this.props.rerouteToPath!(`/admin/referringDoctors`)
      }
    })
  }

  private openDialog = () => {
    this.props.createContact!(DefaultContact(true), 'Add New Referring Doctor Contact')
  }

  public render() {
    const { contactsDataTableStore, classes, referringDoctor } = this.props
    const initialValues = createIReferringDoctorFormValues(referringDoctor)
    const title =
      referringDoctor && referringDoctor.isNew
        ? 'Add New Referring Doctor'
        : 'Edit Referring Doctor'

    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {referringDoctor ? (
          <Formik
            initialValues={initialValues}
            onSubmit={this.save}
            validationSchema={ReferringDoctorSchema}
            enableReinitialize={true}
            ref={(window as any).formikRef}
          >
            {({ isValid, values, setFieldValue, submitForm, dirty, submitCount }) => (
              <Form>
                <Prompt
                  when={dirty && submitCount === 0}
                  message="Are you sure you want to leave the page? There are unsaved changes."
                />
                <ResponsiveAppBar title={title} pageIcon={<RecordVoiceOver />}>
                  <Grid container direction="row" spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button size="small" variant="contained" onClick={this.cancel}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        color="secondary"
                        variant="contained"
                        disabled={!isValid}
                        onClick={this.handleSave(submitForm, true)}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        color="secondary"
                        variant="contained"
                        disabled={!isValid}
                        onClick={this.handleSave(submitForm, false)}
                      >
                        Save and Close
                      </Button>
                    </Grid>
                  </Grid>
                </ResponsiveAppBar>
                <Grid container direction="row" className={classes.topOfPage}>
                  <Grid container item xs={6} style={{ padding: 8 }}>
                    <CardWithTitle title="Referring Doctor" icon={RecordVoiceOver}>
                      <Grid item xs={12}>
                        <FullWidthField
                          variant="outlined"
                          type="outlined"
                          name="npi"
                          label="NPI"
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <FullWidthField
                          required
                          variant="outlined"
                          name="firstName"
                          label="First Name"
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <FullWidthField
                          required
                          variant="outlined"
                          name="lastName"
                          label="Last Name"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FullWidthField variant="outlined" name="suffix" label="Suffix" />
                      </Grid>
                      <Grid item xs={12}>
                        <AddressField
                          name={'address'}
                          label="Address"
                          showPhone={true}
                          showFax={true}
                          outlined={true}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FullWidthField
                          name="preferences"
                          label="Preferences"
                          variant="outlined"
                          multiline
                          rows="3"
                        />
                      </Grid>
                      <Grid item>
                        <IsActiveToggle
                          isActive={values.isActive}
                          onChange={this.handleIsActiveChange(values, setFieldValue)}
                        />
                      </Grid>
                    </CardWithTitle>
                  </Grid>
                  {referringDoctor && !referringDoctor.isNew ? (
                    <Grid item xs={6}>
                      <Paper className={classes.paper}>
                        <ESDataTableWithHeader
                          dataTableManager={contactsDataTableStore!}
                          addButtonOnClick={this.openDialog}
                          elevation={0}
                          enableShowInactives
                          padding={0}
                          title="Contacts"
                          icon={Person}
                          rowsPerPage={5}
                        />
                      </Paper>
                      <ContactEditDialog contactVariant="ReferringDoctor" />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </>
    )
  }
}

const InjectedEditReferringDoctor = inject<
  IStores,
  IEditReferringDoctorProps,
  Partial<IEditReferringDoctorProps>,
  any
>((stores: IStores) => ({
  contactsDataTableStore: stores.contacts.dataTableStore,
  createContact: stores.contacts.openDialogWithContact,
  getColumnSettingsAndContacts: stores.contacts.getColumnSettingsAndContacts,
  getReferringDoctorById: stores.referringDoctors.getReferringDoctorById,
  isLoading: stores.referringDoctors.isLoading,
  referringDoctor: stores.referringDoctors.selectedReferringDoctor,
  referringDoctorEditFinished: stores.referringDoctors.referringDoctorEditFinished,
  rerouteToPath: stores.global.rerouteToPath,
  saveReferringDoctor: stores.referringDoctors.saveReferringDoctor,
  setSelectedReferringDoctor: stores.referringDoctors.setSelectedReferringDoctor,
}))(EditReferringDoctor)

export default withStyles(styles)(InjectedEditReferringDoctor)
