import { createBrowserHistory } from 'history'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { SyncTrunk } from 'mobx-sync'
import { createErrorInterceptor, FetchService } from './core'
import { Client } from './generated_client'
import ServiceBase from './services/ServiceBase'
import AppointmentTasksStore from './stores/AppointmentTasksStore/AppointmentTasksStore'
import AttachmentFilesStore from './stores/AttachmentFilesStore'
import BillOutStore from './stores/BillOutStore/BillOutStore'
import BillsStore from './stores/BillsStore/BillsStore'
import BodyPartsStore from './stores/BodyPartsStore/BodyPartsStore'
import ChecksStore from './stores/ChecksStore/ChecksStore'
import CMS1500Store from './stores/CMS1500Store/CMS1500Store'
import CommunicationsStore from './stores/CommunicationsStore/CommunicationsStore'
import ComponentsStore from './stores/ComponentsStore'
import ContactsStore from './stores/ContactsStore'
import ContactTypesStore from './stores/ContactTypesStore/ContactTypesStore'
import ContractsStore from './stores/ContractsStore'
import CptCodesStore from './stores/CptCodesStore/CptCodesStore'
import DxCodesStore from './stores/DxCodesStore/DxCodesStore'
import EmployersStore from './stores/EmployersStore/EmployersStore'
import GlobalStore from './stores/GlobalStore'
import InsuranceCompaniesStore from './stores/InsuranceCompaniesStore'
import LocationBillingsStore from './stores/LocationBillingsStore/LocationBillingsStore'
import LocationsStore from './stores/LocationsStore'
import LoginStore from './stores/LoginStore'
import MedicareCostLookupsStore from './stores/MedicareCostLookupsStore/MedicareCostLookupsStore'
import MultipleProcedureDiscountExclusionCodesStore from './stores/MultipleProcedureDiscountExclusionCodesStore'
import NotificationStore from './stores/NotificationStore'
import NurseCaseManagersStore from './stores/NurseCaseManagersStore'
import OrganizationsStore from './stores/OrganizationsStore'
import PatientEditStore from './stores/PatientEditStore/PatientEditStore'
import PatientsStore from './stores/PatientsStore/PatientsStore'
import PhysiciansStore from './stores/PhysiciansStore'
import PreExistingConditionsStore from './stores/PreExistingConditionsStore/PreExistingConditionsStore'
import ProceduresStore from './stores/ProceduresStore/ProceduresStore'
import ProvidersStore from './stores/ProvidersStore'
import QuotesStore from './stores/QuotesStore'
import ReceivePaymentStore from './stores/ReceivePaymentStore/ReceivePaymentStore'
import ReferralsStore from './stores/ReferralsStore/ReferralsStore'
import ReferringDoctorsStore from './stores/ReferringDoctorsStore/ReferringDoctorsStore'
import RolesStore from './stores/RolesStore'
import ScheduleAppointmentTasksStore from './stores/ScheduleAppointmentTasksStore/ScheduleAppointmentTasksStore'
import SchedulesStore from './stores/SchedulesStore/SchedulesStore'
import TagsStore from './stores/TagsStore/TagsStore'
import UsersStore from './stores/UsersStore'
import ProviderRefundStore from './stores/ProviderRefundStore/ProviderRefundStore'

interface IStores {
  // BEGIN: STORES INTERFACE -- DO NOT REMOVE
  appointmentTasks: AppointmentTasksStore
  attachmentFiles: AttachmentFilesStore
  billOutStore: BillOutStore
  billsStore: BillsStore
  bodyParts: BodyPartsStore
  cms1500: CMS1500Store
  tags: TagsStore
  checks: ChecksStore
  components: ComponentsStore
  communications: CommunicationsStore
  contactTypes: ContactTypesStore
  contacts: ContactsStore
  contracts: ContractsStore
  cptCodes: CptCodesStore
  dxCodes: DxCodesStore
  employers: EmployersStore
  global: GlobalStore
  insuranceCompanies: InsuranceCompaniesStore
  locationBillings: LocationBillingsStore
  locations: LocationsStore
  login: LoginStore
  medicareCostLookups: MedicareCostLookupsStore
  multipleProcedureDiscountExclusionCodes: MultipleProcedureDiscountExclusionCodesStore
  notifications: NotificationStore
  nurseCaseManagers: NurseCaseManagersStore
  organizations: OrganizationsStore
  patients: PatientsStore
  patientEdit: PatientEditStore
  physicians: PhysiciansStore
  preExistingConditions: PreExistingConditionsStore
  procedures: ProceduresStore
  providers: ProvidersStore
  quotes: QuotesStore
  receivePayments: ReceivePaymentStore
  referrals: ReferralsStore
  referringDoctors: ReferringDoctorsStore
  roles: RolesStore
  routing: RouterStore
  users: UsersStore
  schedules: SchedulesStore
  scheduleAppointmentTasks: ScheduleAppointmentTasksStore
  providerRefundStore: ProviderRefundStore
  // END: STORES INTERFACE -- DO NOT REMOVE
}

let baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

const fetchService = new FetchService()

const routerStore = new RouterStore()
const global = new GlobalStore(routerStore)
const trunk = new SyncTrunk(global, { storage: localStorage, delay: 1000 })
trunk.init()
const browserHistory = createBrowserHistory()
const history = syncHistoryWithStore(browserHistory, routerStore)
const notifications = new NotificationStore()

fetchService.addInterceptor(createErrorInterceptor(global, notifications))
ServiceBase.attachResponseInterceptor(global, notifications)
const client = new Client(baseUrl, fetchService)
const components = new ComponentsStore(global, routerStore, client)
const insuranceCompanies = new InsuranceCompaniesStore(global, routerStore, client)
const medicareCostLookups = new MedicareCostLookupsStore(global, client)
const providers = new ProvidersStore(global, routerStore, client)
const employers = new EmployersStore(global, client, routerStore)
const referringDoctors = new ReferringDoctorsStore(global, routerStore, client)
const nurseCaseManagers = new NurseCaseManagersStore(global, client)
const patients = new PatientsStore(client, notifications)
const referrals = new ReferralsStore(global, routerStore, patients, client)
const login = new LoginStore(global, notifications, routerStore, client)
const locations = new LocationsStore(providers, global, client)
const locationBillings = new LocationBillingsStore(global, client, locations)
const users = new UsersStore(global, login, client)
const quotes = new QuotesStore(global, routerStore, client)
const patientEdit = new PatientEditStore(
  global,
  client,
  patients,
  referrals,
  routerStore,
  baseUrl
)
const schedules = new SchedulesStore(
  global,
  patientEdit,
  patients,
  referrals,
  routerStore,
  client,
  notifications
)
const billsStore = new BillsStore(global, routerStore, client)

const stores: IStores = {
  // BEGIN: STORES INSTANCE -- DO NOT REMOVE
  appointmentTasks: new AppointmentTasksStore(global, client, components),
  attachmentFiles: new AttachmentFilesStore(global, client),
  billOutStore: new BillOutStore(global, client),
  billsStore,
  bodyParts: new BodyPartsStore(global, client),
  checks: new ChecksStore(global, client),
  cms1500: new CMS1500Store(global, client),
  communications: new CommunicationsStore(patients, providers, global, client),
  components,
  contactTypes: new ContactTypesStore(global, client),
  contacts: new ContactsStore(
    providers,
    insuranceCompanies,
    referringDoctors,
    nurseCaseManagers,
    employers,
    global,
    client
  ),
  contracts: new ContractsStore(global, providers, quotes, client, notifications),
  cptCodes: new CptCodesStore(global, client),
  dxCodes: new DxCodesStore(global, client),
  employers,
  global,
  insuranceCompanies,
  locationBillings,
  locations,
  login,
  medicareCostLookups,
  multipleProcedureDiscountExclusionCodes:
    new MultipleProcedureDiscountExclusionCodesStore(global, client),
  notifications,
  nurseCaseManagers,
  organizations: new OrganizationsStore(global, client),
  patients,
  patientEdit,
  physicians: new PhysiciansStore(providers, global, client),
  preExistingConditions: new PreExistingConditionsStore(global, client),
  procedures: new ProceduresStore(global, client),
  providers,
  quotes,
  receivePayments: new ReceivePaymentStore(),
  referrals,
  referringDoctors,
  roles: new RolesStore(global, client),
  routing: routerStore,
  scheduleAppointmentTasks: new ScheduleAppointmentTasksStore(
    global,
    routerStore,
    client,
    schedules
  ),
  schedules,
  tags: new TagsStore(global, client),
  users,
  providerRefundStore: new ProviderRefundStore(),
  // END: STORES INSTANCE -- DO NOT REMOVE
}

export default stores
export { IStores, history }
