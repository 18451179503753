import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import { AddCircle, Assignment, Edit, People } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { PatientSearchRequest } from 'src/viewModels/PatientSearchRequest'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultPatient, IEpisodeOfCare, IPatient } from '../Definitions'
import { IStores } from '../Stores'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import { pagedList } from 'src/viewModels/pagedList'
import { PatientSummaryResult } from 'src/viewModels/PatientSummaryResult'
import { searchPatients } from 'src/services/PatientService'
import EpisodesOfCareModal from './EpisodesOfCareModal'
import { Upload } from 'mdi-material-ui'
import UploadModal from './UploadModal'

const styles = ({}: Theme) => createStyles({})

interface IPatientsProps {
  setCanSwitchOrganizations?: (canSwitch: boolean) => void
  setEnterBillsMode?: (enableEnterBillsMode: boolean) => void
  eoc?: IDataTableManager<IEpisodeOfCare>
  disablePatient?: (patient: IPatient) => void
  isModalOpen?: boolean
  rerouteToPath?: (path: string) => void
  recoverPatient?: (patient: IPatient) => void
  setSelectedPatient?: (patient: IPatient) => void
  getColumnSettingsAndEpisodeOfCares?: (full: boolean) => Promise<void>
  getEpisodeOfCareBillsColumnSettings?: () => Promise<void>
  editPatient?: (patientId: string) => void
  openEOCModal?: (patientId: string) => void
  openUploadModal?: (patientId: string) => void
  loading?: boolean
}

class Patients extends React.Component<IPatientsProps> {
  public componentDidMount() {
    this.props.setEnterBillsMode!(true)
    this.props.setCanSwitchOrganizations!(true)
  }

  public componentWillUnmount() {
    this.props.setEnterBillsMode!(false)
    this.props.setCanSwitchOrganizations!(false)
  }

  public state = {
    gridData: new pagedList().items as PatientSummaryResult[],
    gridLoading: false,
    searchPatientFirstName: '',
    searchPatientLastName: '',
    searchEocGeneratedOrLegacyId: '',
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 25,
    selectedPatientId: '',
    selectedPatientInfo: '',
  }

  private search = (event: any) => {
    this.setState({ page: 1 }, () => this.getPatientData())
    event.preventDefault()
  }

  private clearSearch = () => {
    this.setState({
      page: 1,
      searchPatientLastName: '',
      searchPatientFirstName: '',
      searchEocGeneratedOrLegacyId: '',
      searchCptCode: '',
      searchProviderGroup: '',
      searchInsuranceCompany: '',
      searchPrimaryAdjuster: '',
      searchSurgeryDateStart: '',
      searchSurgeryDateEnd: '',
      searchQuoteStatus: new Map<string, string>(),
      searchEOCStatus: '',
      searchEmployer: '',
      selectedPatientId: '',
      gridData: undefined,
      pageCount: 0,
      totalItems: 0,
    })
  }

  private getPatientData = async () => {
    this.setState({ gridLoading: true })
    const { page, pageSize } = this.state
    const requestData = new PatientSearchRequest()
    requestData.page = page
    requestData.pageSize = pageSize
    requestData.patientFirstName = this.state.searchPatientFirstName
    requestData.patientLastName = this.state.searchPatientLastName
    requestData.eocGeneratedOrLegacyId = this.state.searchEocGeneratedOrLegacyId
    await searchPatients(requestData).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        gridLoading: false,
      })

      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems === 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={13} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((acq, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell style={{ paddingLeft: '30px' }}>{acq.patientIdNumber}</TableCell>
          <TableCell>{acq.preCertNumber}</TableCell>
          <TableCell>{acq.lastName}</TableCell>
          <TableCell>{acq.firstName}</TableCell>
          <TableCell align="right">
            {acq.dateOfBirth ? moment(acq.dateOfBirth).format('MM/DD/YYYY') : ''}
          </TableCell>
          <TableCell>
            <Tooltip title="Edit Patient">
              <Edit
                data-testid={`patientGridEdit.${index}`}
                style={{ cursor: 'pointer', color: '#29348F' }}
                onClick={() => this.props.editPatient!(acq.patientId!)}
              />
            </Tooltip>
            &nbsp;&nbsp;
            <Tooltip title="View EOCs">
              <Assignment
                style={{ cursor: 'pointer', color: '#29348F' }}
                onClick={() => {
                  this.setState(
                    {
                      selectedPatientId: acq.patientId!,
                      selectedPatientInfo:
                        acq.lastName +
                        ', ' +
                        acq.firstName +
                        ' (' +
                        (acq.dateOfBirth
                          ? moment(acq.dateOfBirth).format('MM/DD/YYYY')
                          : '') +
                        ')',
                    },
                    () => {
                      this.props.openEOCModal!(acq.patientId!)
                    }
                  )
                }}
              />
            </Tooltip>
            &nbsp;&nbsp;
            <Tooltip title="Upload Document">
              <span>
                <Upload
                  style={{ cursor: 'pointer', color: '#29348F' }}
                  onClick={() => this.props.openUploadModal!(acq.patientId!)}
                />
              </span>
            </Tooltip>
          </TableCell>
        </TableRow>
      ))
    }
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getPatientData())
  }

  private handlePatientFirstNameChange = (event: any) => {
    this.setState({ searchPatientFirstName: event.target.value })
  }

  private handlePatientLastNameChange = (event: any) => {
    this.setState({ searchPatientLastName: event.target.value })
  }

  private handleIdChange = (event: any) => {
    this.setState({ searchEocGeneratedOrLegacyId: event.target.value })
  }

  public render() {
    return (
      <>
        <Helmet>
          <title>Patients</title>
        </Helmet>
        <Grid container style={{ width: '100%', paddingBottom: '20px' }}>
          {this.state.selectedPatientId != '' && (
            <EpisodesOfCareModal
              patientId={this.state.selectedPatientId}
              patientInfo={this.state.selectedPatientInfo}
            />
          )}
          <UploadModal />
          <ResponsiveAppBar title="Patients" pageIcon={<People />} />
          {(this.state.gridLoading || this.props.loading) && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1500',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <form onSubmit={this.search}>
            <Grid container style={{ margin: '10px', marginLeft: '30px' }} item md={12}>
              <Typography
                color="primary"
                component="h6"
                variant="h6"
                gutterBottom
                style={{ paddingBottom: '10px' }}
              >
                SEARCH CRITERIA
              </Typography>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchPatientFirstName}
                      onChange={this.handlePatientFirstNameChange}
                      label="Patient First Name"
                      name="patientFirst"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchPatientLastName}
                      onChange={this.handlePatientLastNameChange}
                      label="Patient Last Name"
                      name="patientLast"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchEocGeneratedOrLegacyId}
                      onChange={this.handleIdChange}
                      label="EOC/Legacy ID"
                      name="eocLegacyId"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{
                    marginLeft: '8px',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                  }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="contained"
                    style={{ marginRight: '10px' }}
                  >
                    Search
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.clearSearch}
                    size="medium"
                    variant="contained"
                  >
                    Clear Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Grid item md={12}>
            <div className="pagination-row">
              <Tooltip title="Add Patient">
                <AddCircle
                  color="primary"
                  style={{ fontSize: '30px', cursor: 'pointer' }}
                  onClick={this.openPatient(DefaultPatient(true))}
                />
              </Tooltip>
            </div>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="gridPadding">
                  <TableCell style={{ paddingLeft: '30px' }}>Patient Id</TableCell>
                  <TableCell>Pre-Cert #</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell align="right">DOB</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '100%' }}>{this.mapGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                forcePage={this.state.page - 1}
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                pageCount={this.state.pageCount}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }

  public openPatient = (patient: IPatient) => () => {
    this.props.setSelectedPatient!(patient)
    sessionStorage.setItem('patientTab', '0')
    this.props.rerouteToPath!('/patients/patient')
  }

  public onRowClick = (_event: React.MouseEvent<Element>, patient: IPatient) => {
    this.props.setSelectedPatient!(patient)
    this.props.getColumnSettingsAndEpisodeOfCares!(true)
  }
}

const InjectedPatients = inject<IStores, IPatientsProps, Partial<IPatientsProps>, any>(
  (stores: IStores) => ({
    disablePatient: stores.patients.disablePatient,
    eoc: stores.patientEdit.dataTableStore,
    getColumnSettingsAndEpisodeOfCares:
      stores.patientEdit.getColumnSettingsAndEpisodeOfCares,
    getEpisodeOfCareBillsColumnSettings:
      stores.patientEdit.getEpisodeOfCareBillsColumnSettings,
    isModalOpen: stores.patients.isModalOpen,
    recoverPatient: stores.patients.recoverPatient,
    rerouteToPath: stores.global.rerouteToPath,
    setCanSwitchOrganizations: stores.global.setCanSwitchOrganizations,
    setEnterBillsMode: stores.patientEdit.setEnterBillsMode,
    setSelectedPatient: stores.patients.setSelectedPatient,
    editPatient: stores.patients.editPatient,
    openEOCModal: stores.patientEdit.openEOCModal,
    openUploadModal: stores.patients.openUploadModal,
    loading: stores.patientEdit.isLoading,
  })
)(Patients)

export default withStyles(styles)(InjectedPatients)
