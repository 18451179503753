//@ts-nocheck
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@material-ui/core'
import {
  AttachMoney,
  Block,
  EditRounded,
  InfoOutlined,
  OpenInBrowserSharp,
} from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import ReactPaginate from 'react-paginate'
import { reviewClaim, searchClaims } from 'src/services/ClaimService'
import { formatCurrency, formatDate } from 'src/utils/Formatter'
import { ClaimSearchRequest } from 'src/viewModels/ClaimSearchRequest'
import ResponsiveAppBar from '../../../common/ResponsiveAppBar'
import { ICMS1500, IEpisodeOfCare } from '../../../Definitions'
import { IStores } from '../../../Stores'
import { BillStatus } from '../../../utils/BillStatus'
import {
  ClaimSummaryResult,
  IClaimSummaryResult,
} from 'src/viewModels/ClaimSummaryResult'
import { pagedList } from 'src/viewModels/pagedList'
import AppealNameConstants from 'src/constants/AppealNameConstants'
import { AppealType } from 'src/utils/AppealType'

interface IEpisodeOfCareBillsDialogProps {
  billStatusFilter?: BillStatus
  close?: () => void
  editBill?: (cms1500Id: string, eocId: string) => void
  isOpen?: boolean
  episodeOfCare?: IEpisodeOfCare
  patientInfo?: string
  setClaimsModalLoadFunction?: (
    parentfunction?: (episodeOfCareId: string) => void
  ) => void
}

class EpisodeOfCareBillsDialog extends React.Component<IEpisodeOfCareBillsDialogProps> {
  public render() {
    const { isOpen, close, episodeOfCare, patientInfo } = this.props
    const episodeOfcareId = episodeOfCare ? episodeOfCare.id : ''
    return (
      <Dialog
        key={episodeOfcareId}
        fullWidth={true}
        maxWidth="xl"
        open={isOpen!}
        onClose={close}
      >
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title={
              'Claim Status: ' +
              patientInfo +
              ' ' +
              episodeOfCare?.episodeOfCareNumberOrLegacyId
            }
            pageIcon={<AttachMoney />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent>
          {this.state.pdfLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <Grid item xs={12}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>EOC/Legacy</TableCell>
                  <TableCell>Claim Status</TableCell>
                  <TableCell align="right">Claim Rcv</TableCell>
                  <TableCell style={{ minWidth: '250px' }}>Provider Location</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell align="right">DOS</TableCell>
                  <TableCell align="right">Claim Amt</TableCell>
                  <TableCell align="right">Contract Amt</TableCell>
                  <TableCell>Check#</TableCell>
                  <TableCell align="right">Processed</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.mapGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                pageCount={this.state.pageCount}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  public state = {
    gridData: new pagedList().items as ClaimSummaryResult[],
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 25,
    pdfLoading: false,
  }

  public componentDidMount() {
    this.props.setClaimsModalLoadFunction!(this.getClaimData)
  }

  private reviewClaim = async (id: string) => {
    await reviewClaim(id).then(() => {
      this.setState({ pdfLoading: false })
    })
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private getClaimReceivedDate = (claim: IClaimSummaryResult) => {
    if (!claim.isPastDue) {
      return <TableCell align="right">{formatDate(claim.claimReceivedDate)}</TableCell>
    } else {
      const toolTipTitle = 'Due date: ' + formatDate(claim.dueDate)
      return (
        <Tooltip title={toolTipTitle}>
          <TableCell style={{ color: 'red' }} align="right">
            {formatDate(claim.claimReceivedDate)}
          </TableCell>
        </Tooltip>
      )
    }
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }
  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((claim, index) => (
        <TableRow key={index}>
          <TableCell style={{ whiteSpace: 'nowrap', width: '1px' }}>
            {claim.groupNumber}&nbsp;&nbsp;
            {claim.appealType != undefined ? (
              <Tooltip title={`Appeal Type: ${AppealNameConstants[claim.appealType]}`}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell>{claim.status}</TableCell>
          {this.getClaimReceivedDate(claim)}
          <TableCell>{claim.providerLocation}</TableCell>
          <TableCell>{claim.locationType}</TableCell>
          <TableCell align="right">{formatDate(claim.dateOfService)}</TableCell>
          <TableCell align="right">{formatCurrency(claim.totalClaimCharges)}</TableCell>
          <TableCell
            align="right"
            style={{
              color:
                claim.appealType != undefined &&
                claim.appealType === AppealType.OverpaidProvider
                  ? 'red'
                  : 'black',
            }}
          >
            {claim.totalContractAmountDisplay != undefined
              ? claim.totalContractAmountDisplay
              : ''}
          </TableCell>
          <TableCell align="right">{claim.checkNumber}</TableCell>
          <TableCell align="right">{formatDate(claim.processedDate)}</TableCell>
          <TableCell>
            <Tooltip title="Open Claim">
              <OpenInBrowserSharp
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open(
                    `/workflow/claim/${claim.cms1500Id}/default-inbox`,
                    '_blank'
                  )
                }}
              />
            </Tooltip>
          </TableCell>
        </TableRow>
      ))
    }
  }

  private getClaimData = async (episodeOfCareId: string) => {
    this.setState({ pdfLoading: true })
    const { page, pageSize } = this.state
    const requestData = new ClaimSearchRequest()
    requestData.page = page
    requestData.pageSize = pageSize
    requestData.episodeOfCareId = episodeOfCareId
    await searchClaims(requestData).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        pdfLoading: false,
      })

      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () =>
      this.getClaimData(this.props.episodeOfCare!.id)
    )
  }
}

const InjectedEpisodeOfCareBillsDialog = inject<
  IStores,
  IEpisodeOfCareBillsDialogProps,
  Partial<IEpisodeOfCareBillsDialogProps>,
  any
>((stores: IStores) => ({
  billStatusFilter: stores.patientEdit.billStatusFilter,
  close: stores.patientEdit.closeBillsDialog,
  editBill: stores.patientEdit.openEditClaim,
  episodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  isOpen: stores.patientEdit.isBillsDialogOpen,
  setClaimsModalLoadFunction: stores.patientEdit.setClaimsModalLoad,
}))(EpisodeOfCareBillsDialog)

export default InjectedEpisodeOfCareBillsDialog
