export class CMS1500LineItemDto implements ICMS1500LineItemDto {
  id?: string | undefined
  cmS1500Id?: string | undefined
  lineNumber?: number | undefined
  aDateOfServiceFrom?: Date | undefined
  aDateOfServiceTo?: Date | undefined
  bPlaceOfServiceTo?: string | undefined
  cEmg?: string | undefined
  dProcedureCptHcpcs?: string | undefined
  dModifier1?: string | undefined
  dModifier2?: string | undefined
  dModifier3?: string | undefined
  dModifier4?: string | undefined
  eDiagnosisPointer?: string | undefined
  fCharges?: number | undefined
  gDaysOrUnits?: string | undefined
  hFamilyPlan?: string | undefined
  iIdQualifier?: string | undefined
  iId?: string | undefined
  iNpi?: string | undefined
  overrideAmount?: number | undefined
  contractAmount?: number | undefined
  billOutAmount?: number | undefined
  reasonCode?: string | undefined

  constructor(data?: ICMS1500LineItemDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.cmS1500Id = data['cmS1500Id']
      this.lineNumber = data['lineNumber']
      this.aDateOfServiceFrom = data['aDateOfServiceFrom']
        ? new Date(data['aDateOfServiceFrom'].toString())
        : <any>undefined
      this.aDateOfServiceTo = data['aDateOfServiceTo']
        ? new Date(data['aDateOfServiceTo'].toString())
        : <any>undefined
      this.bPlaceOfServiceTo = data['bPlaceOfServiceTo']
      this.cEmg = data['cEmg']
      this.dProcedureCptHcpcs = data['dProcedureCptHcpcs']
      this.dModifier1 = data['dModifier1']
      this.dModifier2 = data['dModifier2']
      this.dModifier3 = data['dModifier3']
      this.dModifier4 = data['dModifier4']
      this.eDiagnosisPointer = data['eDiagnosisPointer']
      this.fCharges = data['fCharges']
      this.gDaysOrUnits = data['gDaysOrUnits']
      this.hFamilyPlan = data['hFamilyPlan']
      this.iIdQualifier = data['iIdQualifier']
      this.iId = data['iId']
      this.iNpi = data['iNpi']
      this.overrideAmount = data['overrideAmount']
      this.contractAmount = data['contractAmount']
      this.billOutAmount = data['billOutAmount']
      this.reasonCode = data['reasonCode']
    }
  }

  static fromJS(data: any): CMS1500LineItemDto {
    data = typeof data === 'object' ? data : {}
    let result = new CMS1500LineItemDto()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['cmS1500Id'] = this.cmS1500Id
    data['lineNumber'] = this.lineNumber
    data['aDateOfServiceFrom'] = this.aDateOfServiceFrom
      ? new Date(this.aDateOfServiceFrom).toISOString()
      : <any>undefined
    data['aDateOfServiceTo'] = this.aDateOfServiceTo
      ? new Date(this.aDateOfServiceTo).toISOString()
      : <any>undefined
    data['bPlaceOfServiceTo'] = this.bPlaceOfServiceTo
    data['cEmg'] = this.cEmg
    data['dProcedureCptHcpcs'] = this.dProcedureCptHcpcs
    data['dModifier1'] = this.dModifier1
    data['dModifier2'] = this.dModifier2
    data['dModifier3'] = this.dModifier3
    data['dModifier4'] = this.dModifier4
    data['eDiagnosisPointer'] = this.eDiagnosisPointer
    data['fCharges'] = this.fCharges
    data['gDaysOrUnits'] = this.gDaysOrUnits
    data['hFamilyPlan'] = this.hFamilyPlan
    data['iIdQualifier'] = this.iIdQualifier
    data['iId'] = this.iId
    data['iNpi'] = this.iNpi
    data['overrideAmount'] = this.overrideAmount
    data['contractAmount'] = this.contractAmount
    data['billOutAmount'] = this.billOutAmount
    data['reasonCode'] = this.reasonCode
    return data
  }
}

export interface ICMS1500LineItemDto {
  id?: string | undefined
  cmS1500Id?: string | undefined
  lineNumber?: number | undefined
  aDateOfServiceFrom?: Date | undefined
  aDateOfServiceTo?: Date | undefined
  bPlaceOfServiceTo?: string | undefined
  cEmg?: string | undefined
  dProcedureCptHcpcs?: string | undefined
  dModifier1?: string | undefined
  dModifier2?: string | undefined
  dModifier3?: string | undefined
  dModifier4?: string | undefined
  eDiagnosisPointer?: string | undefined
  fCharges?: number | undefined
  gDaysOrUnits?: string | undefined
  hFamilyPlan?: string | undefined
  iIdQualifier?: string | undefined
  iId?: string | undefined
  iNpi?: string | undefined
  overrideAmount?: number | undefined
  contractAmount?: number | undefined
  billOutAmount?: number | undefined
  reasonCode?: string | undefined
}
