import {
  Assignment,
  FileCopy,
  NoteAdd,
  OpenInBrowserSharp,
  ReceiptOutlined,
} from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { AppealType } from 'src/utils/AppealType'
import { FinancialResearchSummaryResult } from 'src/viewModels/FinancialResearchSummaryResult'
import { DefaultCMS1500, ICMS1500 } from '../../Definitions'
import { Client, IProviderDto, SwaggerResponse } from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'
import { ClaimHeaderDetailsSummaryResult } from 'src/viewModels/ClaimHeaderDetailsSummaryResult'
import { CMS1500Dto, ICMS1500Dto } from 'src/viewModels/claim/Cms1500Dto'

const ColumnSettingsDto = 'CMS1500Dto'

export default class CMS1500Store {
  @observable
  public isLoading: boolean = false
  @observable
  public cms1500: ICMS1500[]
  @observable
  public selectedCMS1500?: ICMS1500
  @observable
  public duplicateCMS1500?: ICMS1500
  public dataTableStore: DataTableStore<ICMS1500Dto, ICMS1500>
  @observable
  public isModalOpen?: boolean = false
  @observable
  public isProviderGroupContractDialogOpen: boolean
  @observable
  public selectedProvider?: IProviderDto
  @observable
  public isAppealLogModalOpen?: boolean = false
  @observable
  public isLastUpdateHistoryModalOpen?: boolean = false
  @observable
  public selectedClaimId?: string
  @observable
  public isAppealNoteModalOpen?: boolean = false
  @observable
  public headerDetails?: ClaimHeaderDetailsSummaryResult
  @observable
  public selectedEOCLegacy?: string
  @observable
  public isMarkClaimDeletedModalOpen?: boolean = false
  @observable
  public updateParentStateAfterModal?: () => void
  @observable
  public getClaimValuesForStaticAction?: () => Promise<ICMS1500>
  @observable
  public contractModalIsOpen?: boolean = false
  @observable
  public contractModalIndex?: number = undefined

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<ICMS1500Dto, ICMS1500>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllCMS1500(
          filter,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          page,
          undefined,
          orderBy,
          includeInactives
        ),
      (cms1500) => this.setupCMS1500(cms1500)
    )
  }

  @action.bound
  public openProviderGroupContractDialog(provider: IProviderDto) {
    this.selectedProvider = provider
    this.isProviderGroupContractDialogOpen = true
  }

  @action.bound
  public openProviderGroupContractDialogById(id: string) {
    this.client.getProviderById(id).then(({ result }) => {
      this.selectedProvider = result
      this.isProviderGroupContractDialogOpen = true
    })
  }

  @action.bound
  public closeProviderGroupContractDialog() {
    this.selectedProvider = undefined
    this.isProviderGroupContractDialogOpen = false
  }

  @action.bound
  public openContractModal(index: number) {
    this.contractModalIsOpen = true
    this.contractModalIndex = index
  }

  @action.bound
  public closeContractModal() {
    this.contractModalIsOpen = false
    this.contractModalIndex = undefined
  }

  @action.bound
  public disableCMS1500(cms1500: ICMS1500) {
    this.isLoading = true
    return this.client
      .archiveCMS1500(cms1500.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndCMS1500() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public loadCMS1500(cms1500Id: string) {
    this.isLoading = true
    return this.client
      .getCMS1500ById(cms1500Id)
      .then(({ result }) =>
        runInAction(() => {
          this.selectedCMS1500 = this.addDefaultFields(result)
        })
      )
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public getAllCMS1500(episodeOfCareId?: string) {
    this.isLoading = true
    return this.client
      .getAllCMS1500(
        undefined,
        undefined,
        undefined,
        episodeOfCareId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        1,
        5000,
        undefined,
        false
      )
      .then((resp: SwaggerResponse<ICMS1500Dto[]>) =>
        runInAction(() => {
          this.cms1500 = this.setupCMS1500(resp.result)
        })
      )
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public recoverCMS1500(cms1500: ICMS1500) {
    this.isLoading = true
    return this.client
      .reactivateCMS1500(cms1500.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public saveCMS1500(cms1500: ICMS1500) {
    this.isLoading = true
    let promise
    const cms1500Dto = new CMS1500Dto(cms1500)
    if (cms1500.isNew) {
      promise = this.client.createCMS1500(cms1500Dto)
    } else {
      promise = this.client.updateCMS1500(cms1500.id, cms1500Dto)
    }
    return promise.finally(() =>
      runInAction(() => {
        this.isLoading = false
      })
    )
  }

  @action.bound
  public checkDuplicateBills(cms1500Bill: ICMS1500) {
    const cms1500Dto = new CMS1500Dto(cms1500Bill)

    cms1500Dto!.dueDate = cms1500Dto?.dueDate ? new Date(cms1500Dto?.dueDate) : undefined
    cms1500Dto!.incomingOrOutgoingDate = cms1500Dto?.incomingOrOutgoingDate
      ? new Date(cms1500Dto?.incomingOrOutgoingDate)
      : undefined

    return this.client.checkDuplicateBill(cms1500Dto).then(({ result }) => {
      runInAction(() => {
        if (result) {
          this.duplicateCMS1500 = this.addDefaultFields(result)
        } else {
          this.duplicateCMS1500 = undefined
        }
      })
    })
  }

  @action.bound
  public setSelectedCMS1500(cms1500: ICMS1500 | undefined) {
    this.selectedCMS1500 = cms1500
  }
  @action.bound
  public closeDialog() {
    this.isModalOpen = false
  }

  @action.bound
  public openDialog() {
    this.isModalOpen = true
  }

  @action.bound
  public OpenAppealLogModal(claimId: string) {
    this.isAppealLogModalOpen = true
    this.selectedClaimId = claimId
  }

  @action.bound
  public closeAppealLogModal() {
    this.isAppealLogModalOpen = false
    this.selectedClaimId = undefined
  }

  @action.bound
  public openLastUpdateHistoryModal(claimId: string) {
    this.isLastUpdateHistoryModalOpen = true
    this.selectedClaimId = claimId
  }

  @action.bound
  public closeLastUpdateHistoryModal() {
    this.isLastUpdateHistoryModalOpen = false
    this.selectedClaimId = undefined
  }

  @action.bound
  public openAppealNoteModal(claimId: string) {
    this.isAppealNoteModalOpen = true
    this.selectedClaimId = claimId
  }

  @action.bound
  public closeAppealNoteModal() {
    this.isAppealNoteModalOpen = false
    this.selectedClaimId = undefined
  }

  @action.bound
  public closeMarkClaimDeleteModal() {
    this.isMarkClaimDeletedModalOpen = false

    if (this.updateParentStateAfterModal) {
      this.updateParentStateAfterModal()
    }

    this.selectedClaimId = undefined
    this.headerDetails = undefined
    this.selectedEOCLegacy = undefined
  }

  private openEOC = async (patientId: string, referralId: string, eocId: string) => {
    sessionStorage.setItem('patientTab', '0')
    sessionStorage.setItem('eocTab', '1')
    window.open(
      `patients/patient/${patientId}/referral/${referralId}/episodeOfCare/${eocId}`,
      '_blank'
    )
  }

  private openClaimCopy = async (claimId: string, eocId: string, type: AppealType) => {
    sessionStorage.setItem('claimTab', '1')
    window.open(`/claim/${claimId}/episodeOfCare/${eocId}/appealType/${type}`, '_blank')
  }

  @action.bound
  public setParentUpdateFunction(parentFunction: () => void) {
    this.updateParentStateAfterModal = parentFunction
  }

  @action.bound
  public async setGetClaimValuesForStaticAction(parentFunction: () => Promise<ICMS1500>) {
    this.getClaimValuesForStaticAction = parentFunction
  }

  private setupCMS1500 = (cms1500: ICMS1500Dto[]) => {
    return cms1500.map((x) => this.setupCMS1500MenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (cms1500: ICMS1500Dto): ICMS1500 => {
    return { ...DefaultCMS1500(), ...cms1500 }
  }

  private setupCMS1500MenuItems = (cms1500: ICMS1500) => {
    cms1500.menuItems = []
    if (cms1500.isActive) {
      cms1500.menuItems.push({
        isConfirm: true,
        name: 'Disable',
        onClick: () => this.disableCMS1500(cms1500),
      })
    } else {
      cms1500.menuItems.push({
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverCMS1500(cms1500),
      })
    }
    return cms1500
  }

  @action.bound
  public buildResearchMenuItems = (record: FinancialResearchSummaryResult) => {
    let menuItems: any[] = []

    if (record.lastAppealDate != null) {
      menuItems.push({
        color: '#29348F',
        icon: NoteAdd,
        name: 'Add Appeal Note',
        onClick: () => this.openAppealNoteModal(record.claimId!),
      })
    } else {
      menuItems.push({
        disabled: true,
        icon: NoteAdd,
        name: 'Add Appeal Note',
      })
    }
    menuItems.push({
      color: '#29348F',
      icon: OpenInBrowserSharp,
      name: 'Open Claim',
      onClick: () =>
        window.open(`/workflow/claim/${record.claimId}/default-inbox`, '_blank'),
    })
    if (record.billOutId) {
      menuItems.push({
        color: '#29348F',
        icon: OpenInBrowserSharp,
        name: 'Open Bill Out',
        onClick: () =>
          window.open(`/workflow/billout/${record.billOutId}/default-inbox`, '_blank'),
      })
    }

    menuItems.push({
      color: '#29348F',
      icon: Assignment,
      name: 'Edit EOC',
      onClick: () => this.openEOC(record.patientId!, record.referralId!, record.eocId!),
    })

    menuItems.push({
      color: '#29348F',
      icon: ReceiptOutlined,
      name: 'View Last Update History',
      onClick: () => this.openLastUpdateHistoryModal(record.claimId!),
    })

    if (
      (((record.claimStatus == 'Paid' ||
        record.claimStatus == 'Imported Paid' ||
        record.billOutId != undefined) &&
        record.hasAnyZeroContract &&
        record.billOutStatus !== 'Dismissed' &&
        record.claimStatus !== 'Paid-Holding' &&
        record.claimStatus !== 'Denied-Holding') ||
        record.claimStatus == 'Denied' ||
        record.claimStatus == 'Imported Denied') &&
      record.appealType == undefined
    ) {
      menuItems.push({
        color: '#29348F',
        icon: FileCopy,
        name: 'Revised: Pay After Denial',
        onClick: () =>
          this.openClaimCopy(record.claimId!, record.eocId!, AppealType.PayAfterDenial),
      })
    } else {
      menuItems.push({
        icon: FileCopy,
        name: 'Revised: Pay After Denial',
        disabled: true,
      })
    }

    //Can be copied if paid or has a billout record AND this isn't a copy AND this isn't imported AND this isn't Holding
    if (
      (record.claimStatus == 'Paid' ||
        record.claimStatus == 'Imported Paid' ||
        (record.billOutId != undefined && record.billOutStatus != 'Dismissed')) &&
      record.claimStatus !== 'Paid-Holding' &&
      record.claimStatus !== 'Denied-Holding' &&
      record.appealType == undefined
    ) {
      menuItems.push({
        color: '#29348F',
        icon: FileCopy,
        name: 'Revised: Provider Was Overpaid',
        onClick: () =>
          this.openClaimCopy(record.claimId!, record.eocId!, AppealType.OverpaidProvider),
      })
      menuItems.push({
        color: '#29348F',
        icon: FileCopy,
        name: 'Revised: Provider Was Underpaid',
        onClick: () =>
          this.openClaimCopy(
            record.claimId!,
            record.eocId!,
            AppealType.UnderpaidProvider
          ),
      })
    } else {
      menuItems.push({
        icon: FileCopy,
        name: 'Revised: Provider Was Overpaid',
        disabled: true,
      })
      menuItems.push({
        icon: FileCopy,
        name: 'Revised: Provider Was Underpaid',
        disabled: true,
      })
    }

    return menuItems
  }
}
