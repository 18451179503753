import { IHaveAddressFormValues } from '../common/AddressField/AddressField'
import {
  createAddressFormValues,
  toAddressDto,
} from '../common/AddressField/AddressFormValues'
import { DefaultLocationBilling, ILocationBilling } from '../Definitions'

export interface ILocationBillingFormValues extends IHaveAddressFormValues {
  readonly id: string
  isActive: boolean
  addressId: string
  locationId: string
}

function setDefaultValues(locationId: string): ILocationBillingFormValues {
  return {
    address: createAddressFormValues(),
    addressId: '',
    id: '',
    isActive: true,
    locationId,
  }
}

function setValuesFromLocationBilling(
  locationBilling: ILocationBilling,
  locationId: string
): ILocationBillingFormValues {
  return {
    address: createAddressFormValues(locationBilling.address),
    addressId: locationBilling.addressId || '',
    id: locationBilling.id || '',
    isActive: locationBilling.isActive,
    locationId: locationBilling.locationId || locationId || '',
  }
}

export function createILocationBillingFormValues(
  locationId: string,
  locationBilling?: ILocationBilling
): ILocationBillingFormValues {
  if (locationBilling === undefined) {
    return setDefaultValues(locationId)
  } else {
    return setValuesFromLocationBilling(locationBilling, locationId)
  }
}

export async function toILocationBilling(
  formValues: ILocationBillingFormValues,
  original?: ILocationBilling
): Promise<ILocationBilling> {
  const locationBilling = original === undefined ? DefaultLocationBilling() : original
  const address = await toAddressDto(formValues.address!)
  return {
    ...locationBilling,
    address,
    addressId: formValues.addressId,
    id: formValues.id,
    isActive: formValues.isActive,
    locationId: formValues.locationId,
  }
}
