export enum InboxType {
  Claim = '1',
  Document = '2',
  Quote = '3',
  Invoice = '4',
  Billout = '5',
}

const InboxTypePathConstants = {
  '1': 'claim',
  '2': 'document',
  '3': 'quote',
  '4': 'invoice',
  '5': 'billout',
}
export default InboxTypePathConstants
