import { CMS1500LineItemDto } from './CMS1500LineItemDto'
import { CMS1500DiagnosisDto } from './CMS1500DiagnosisDto'
import {
  AddressDto,
  BillCheckDto,
  ClaimEventDto,
  EpisodeOfCareDto,
  ProviderDto,
} from 'src/generated_client'
import { AppealType } from 'src/utils/AppealType'

export interface ICMS1500Dto {
  name?: string | undefined
  createdOn?: Date | undefined
  providerName?: string | undefined
  isActive?: boolean | undefined
  organizationId?: string | undefined
  isIncoming?: boolean | undefined
  incomingOrOutgoingDate?: Date | undefined
  dueDate?: Date | undefined
  dateOfService?: Date | undefined
  providerTypeId?: string | undefined
  q1Insurance?: string | undefined
  q1AInsuranceId?: string | undefined
  q2PatientName?: string | undefined
  q3Date?: Date | undefined
  q3Sex?: string | undefined
  q4InsuredName?: string | undefined
  q5PatientAddress?: AddressDto | undefined
  q6RelationshipToInsured?: string | undefined
  q7InsuredAddress?: AddressDto | undefined
  q8ReservedNucc?: string | undefined
  q9OtherInsuredName?: string | undefined
  q9AOtherInsuredPolicyNumber?: string | undefined
  q9BReservedNucc?: string | undefined
  q9CReservedNucc?: string | undefined
  q9DInsuranceProgramName?: string | undefined
  q10ARelatedToEmployment?: boolean | undefined
  q10BAutoAccidentState?: string | undefined
  q10BRelatedToAutoAccident?: boolean | undefined
  q10CRelatedToOtherAccident?: boolean | undefined
  q10DClaimCodes?: string | undefined
  q11PolicyFecaNumber?: string | undefined
  q11ADateOfBirth?: Date | undefined
  q11ASex?: string | undefined
  q11BOtherClaimId?: string | undefined
  q11BQualifier?: string | undefined
  q11CInsuranceProgramName?: string | undefined
  q11DAnotherBenefitPlan?: boolean | undefined
  q12PatientSignature?: string | undefined
  q12PatientSignatureDate?: Date | undefined
  q13InsuredSignature?: string | undefined
  q14DateOfIllness?: Date | undefined
  q14Qualifier?: string | undefined
  q15OtherDate?: Date | undefined
  q15Qualifier?: string | undefined
  q16UnableToWorkFrom?: Date | undefined
  q16UnabletoWorkTo?: Date | undefined
  q17NameOfReferring?: string | undefined
  q17Qualifier?: string | undefined
  q17AId?: string | undefined
  q17AQualifier?: string | undefined
  q17BNpi?: string | undefined
  q18HospitalizationFrom?: Date | undefined
  q18HospitalizationTo?: Date | undefined
  q19AdditionalClaims?: string | undefined
  q20OutsideLab?: boolean | undefined
  q20Charges?: number | undefined
  q21DiagnosisIcdInd?: string | undefined
  q21Diagnosis?: CMS1500DiagnosisDto[] | undefined
  q22ResubmissionCode?: string | undefined
  q22OriginalRefNo?: string | undefined
  q23PrioAuthorizationNumber?: string | undefined
  q24?: CMS1500LineItemDto[] | undefined
  q25FederalTaxId?: string | undefined
  q25FederalTaxIdSource?: string | undefined
  q26PatientAccountNo?: string | undefined
  q27AcceptAssignment?: boolean | undefined
  q28TotalCharge?: number | undefined
  totalCharge?: string | undefined
  q29AmountPaid?: number | undefined
  q30ReservedNucc?: number | undefined
  q31Signature?: string | undefined
  q31Date?: Date | undefined
  q32LocationInfo?: string | undefined
  q32ALocationNpi?: string | undefined
  q32B?: string | undefined
  q33BillingProviderInfo?: string | undefined
  q33BillingProviderPhoneNumber?: string | undefined
  q33ABillingProviderNpi?: string | undefined
  q33B?: string | undefined
  episodeOfCareId?: string | undefined
  episodeOfCareNumber?: number | undefined
  episodeOfCare?: EpisodeOfCareDto | undefined
  providerId?: string | undefined
  provider?: ProviderDto | undefined
  billStatus?: string | undefined
  groupNumber?: number | undefined
  contractAmountTotal?: number | undefined
  contractAmountTotalData?: string | undefined
  billedOutAmountTotal?: number | undefined
  billedOutAmountTotalData?: string | undefined
  balanceDue?: string | undefined
  primaryContact?: string | undefined
  billCheckDtos?: BillCheckDto[] | undefined
  claimEvents?: ClaimEventDto[] | undefined
  id?: string | undefined
  modifiedOn?: Date | undefined
  deletedOn?: Date | undefined
  lastAppealDate?: Date | undefined
  originalCMS1500Id?: string | undefined
  appealType?: AppealType | undefined
  appealDescription?: string | undefined
  appealNotes?: string | undefined
  inGlobal?: boolean | undefined
  alertText?: string | undefined
  percentageOfLineItemsReconciled?: number | undefined
  lastAppealCMS1500Id?: string | undefined
  stagedDocumentId?: string | undefined
}

export class CMS1500Dto implements ICMS1500Dto {
  name?: string | undefined
  createdOn?: Date | undefined
  providerName?: string | undefined
  isActive?: boolean | undefined
  organizationId?: string | undefined
  isIncoming?: boolean | undefined
  incomingOrOutgoingDate?: Date | undefined
  dueDate?: Date | undefined
  dateOfService?: Date | undefined
  providerTypeId?: string | undefined
  q1Insurance?: string | undefined
  q1AInsuranceId?: string | undefined
  q2PatientName?: string | undefined
  q3Date?: Date | undefined
  q3Sex?: string | undefined
  q4InsuredName?: string | undefined
  q5PatientAddress?: AddressDto | undefined
  q6RelationshipToInsured?: string | undefined
  q7InsuredAddress?: AddressDto | undefined
  q8ReservedNucc?: string | undefined
  q9OtherInsuredName?: string | undefined
  q9AOtherInsuredPolicyNumber?: string | undefined
  q9BReservedNucc?: string | undefined
  q9CReservedNucc?: string | undefined
  q9DInsuranceProgramName?: string | undefined
  q10ARelatedToEmployment?: boolean | undefined
  q10BAutoAccidentState?: string | undefined
  q10BRelatedToAutoAccident?: boolean | undefined
  q10CRelatedToOtherAccident?: boolean | undefined
  q10DClaimCodes?: string | undefined
  q11PolicyFecaNumber?: string | undefined
  q11ADateOfBirth?: Date | undefined
  q11ASex?: string | undefined
  q11BOtherClaimId?: string | undefined
  q11BQualifier?: string | undefined
  q11CInsuranceProgramName?: string | undefined
  q11DAnotherBenefitPlan?: boolean | undefined
  q12PatientSignature?: string | undefined
  q12PatientSignatureDate?: Date | undefined
  q13InsuredSignature?: string | undefined
  q14DateOfIllness?: Date | undefined
  q14Qualifier?: string | undefined
  q15OtherDate?: Date | undefined
  q15Qualifier?: string | undefined
  q16UnableToWorkFrom?: Date | undefined
  q16UnabletoWorkTo?: Date | undefined
  q17NameOfReferring?: string | undefined
  q17Qualifier?: string | undefined
  q17AId?: string | undefined
  q17AQualifier?: string | undefined
  q17BNpi?: string | undefined
  q18HospitalizationFrom?: Date | undefined
  q18HospitalizationTo?: Date | undefined
  q19AdditionalClaims?: string | undefined
  q20OutsideLab?: boolean | undefined
  q20Charges?: number | undefined
  q21DiagnosisIcdInd?: string | undefined
  q21Diagnosis?: CMS1500DiagnosisDto[] | undefined
  q22ResubmissionCode?: string | undefined
  q22OriginalRefNo?: string | undefined
  q23PrioAuthorizationNumber?: string | undefined
  q24?: CMS1500LineItemDto[] | undefined
  q25FederalTaxId?: string | undefined
  q25FederalTaxIdSource?: string | undefined
  q26PatientAccountNo?: string | undefined
  q27AcceptAssignment?: boolean | undefined
  q28TotalCharge?: number | undefined
  totalCharge?: string | undefined
  q29AmountPaid?: number | undefined
  q30ReservedNucc?: number | undefined
  q31Signature?: string | undefined
  q31Date?: Date | undefined
  q32LocationInfo?: string | undefined
  q32ALocationNpi?: string | undefined
  q32B?: string | undefined
  q33BillingProviderInfo?: string | undefined
  q33BillingProviderPhoneNumber?: string | undefined
  q33ABillingProviderNpi?: string | undefined
  q33B?: string | undefined
  episodeOfCareId?: string | undefined
  episodeOfCareNumber?: number | undefined
  episodeOfCare?: EpisodeOfCareDto | undefined
  providerId?: string | undefined
  provider?: ProviderDto | undefined
  billStatus?: string | undefined
  groupNumber?: number | undefined
  contractAmountTotal?: number | undefined
  contractAmountTotalData?: string | undefined
  billedOutAmountTotal?: number | undefined
  billedOutAmountTotalData?: string | undefined
  balanceDue?: string | undefined
  primaryContact?: string | undefined
  billCheckDtos?: BillCheckDto[] | undefined
  claimEvents?: ClaimEventDto[] | undefined
  id?: string | undefined
  modifiedOn?: Date | undefined
  deletedOn?: Date | undefined
  lastAppealDate?: Date | undefined
  originalCMS1500Id?: string | undefined
  appealType?: AppealType | undefined
  appealDescription?: string | undefined
  appealNotes?: string | undefined
  inGlobal?: boolean | undefined
  alertText?: string | undefined
  percentageOfLineItemsReconciled?: number | undefined
  lastAppealCMS1500Id?: string | undefined
  stagedDocumentId?: string | undefined

  constructor(data?: ICMS1500Dto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name']
      this.createdOn = data['createdOn']
        ? new Date(data['createdOn'].toString())
        : <any>undefined
      this.providerName = data['providerName']
      this.isActive = data['isActive']
      this.organizationId = data['organizationId']
      this.isIncoming = data['isIncoming']
      this.incomingOrOutgoingDate = data['incomingOrOutgoingDate']
        ? new Date(data['incomingOrOutgoingDate'].toString())
        : <any>undefined
      this.dueDate = data['dueDate']
        ? new Date(data['dueDate'].toString())
        : <any>undefined
      this.dateOfService = data['dateOfService']
        ? new Date(data['dateOfService'].toString())
        : <any>undefined
      this.providerTypeId = data['providerTypeId']
      this.q1Insurance = data['q1Insurance']
      this.q1AInsuranceId = data['q1AInsuranceId']
      this.q2PatientName = data['q2PatientName']
      this.q3Date = data['q3Date'] ? new Date(data['q3Date'].toString()) : <any>undefined
      this.q3Sex = data['q3Sex']
      this.q4InsuredName = data['q4InsuredName']
      this.q5PatientAddress = data['q5PatientAddress']
        ? AddressDto.fromJS(data['q5PatientAddress'])
        : <any>undefined
      this.q6RelationshipToInsured = data['q6RelationshipToInsured']
      this.q7InsuredAddress = data['q7InsuredAddress']
        ? AddressDto.fromJS(data['q7InsuredAddress'])
        : <any>undefined
      this.q8ReservedNucc = data['q8ReservedNucc']
      this.q9OtherInsuredName = data['q9OtherInsuredName']
      this.q9AOtherInsuredPolicyNumber = data['q9AOtherInsuredPolicyNumber']
      this.q9BReservedNucc = data['q9BReservedNucc']
      this.q9CReservedNucc = data['q9CReservedNucc']
      this.q9DInsuranceProgramName = data['q9DInsuranceProgramName']
      this.q10ARelatedToEmployment = data['q10ARelatedToEmployment']
      this.q10BAutoAccidentState = data['q10BAutoAccidentState']
      this.q10BRelatedToAutoAccident = data['q10BRelatedToAutoAccident']
      this.q10CRelatedToOtherAccident = data['q10CRelatedToOtherAccident']
      this.q10DClaimCodes = data['q10DClaimCodes']
      this.q11PolicyFecaNumber = data['q11PolicyFecaNumber']
      this.q11ADateOfBirth = data['q11ADateOfBirth']
        ? new Date(data['q11ADateOfBirth'].toString())
        : <any>undefined
      this.q11ASex = data['q11ASex']
      this.q11BOtherClaimId = data['q11BOtherClaimId']
      this.q11BQualifier = data['q11BQualifier']
      this.q11CInsuranceProgramName = data['q11CInsuranceProgramName']
      this.q11DAnotherBenefitPlan = data['q11DAnotherBenefitPlan']
      this.q12PatientSignature = data['q12PatientSignature']
      this.q12PatientSignatureDate = data['q12PatientSignatureDate']
        ? new Date(data['q12PatientSignatureDate'].toString())
        : <any>undefined
      this.q13InsuredSignature = data['q13InsuredSignature']
      this.q14DateOfIllness = data['q14DateOfIllness']
        ? new Date(data['q14DateOfIllness'].toString())
        : <any>undefined
      this.q14Qualifier = data['q14Qualifier']
      this.q15OtherDate = data['q15OtherDate']
        ? new Date(data['q15OtherDate'].toString())
        : <any>undefined
      this.q15Qualifier = data['q15Qualifier']
      this.q16UnableToWorkFrom = data['q16UnableToWorkFrom']
        ? new Date(data['q16UnableToWorkFrom'].toString())
        : <any>undefined
      this.q16UnabletoWorkTo = data['q16UnabletoWorkTo']
        ? new Date(data['q16UnabletoWorkTo'].toString())
        : <any>undefined
      this.q17NameOfReferring = data['q17NameOfReferring']
      this.q17Qualifier = data['q17Qualifier']
      this.q17AId = data['q17AId']
      this.q17AQualifier = data['q17AQualifier']
      this.q17BNpi = data['q17BNpi']
      this.q18HospitalizationFrom = data['q18HospitalizationFrom']
        ? new Date(data['q18HospitalizationFrom'].toString())
        : <any>undefined
      this.q18HospitalizationTo = data['q18HospitalizationTo']
        ? new Date(data['q18HospitalizationTo'].toString())
        : <any>undefined
      this.q19AdditionalClaims = data['q19AdditionalClaims']
      this.q20OutsideLab = data['q20OutsideLab']
      this.q20Charges = data['q20Charges']
      this.q21DiagnosisIcdInd = data['q21DiagnosisIcdInd']
      if (data['q21Diagnosis'] && data['q21Diagnosis'].constructor === Array) {
        this.q21Diagnosis = []
        for (let item of data['q21Diagnosis'])
          this.q21Diagnosis.push(CMS1500DiagnosisDto.fromJS(item))
      }
      this.q22ResubmissionCode = data['q22ResubmissionCode']
      this.q22OriginalRefNo = data['q22OriginalRefNo']
      this.q23PrioAuthorizationNumber = data['q23PrioAuthorizationNumber']
      if (data['q24'] && data['q24'].constructor === Array) {
        this.q24 = []
        for (let item of data['q24']) this.q24.push(CMS1500LineItemDto.fromJS(item))
      }
      this.q25FederalTaxId = data['q25FederalTaxId']
      this.q25FederalTaxIdSource = data['q25FederalTaxIdSource']
      this.q26PatientAccountNo = data['q26PatientAccountNo']
      this.q27AcceptAssignment = data['q27AcceptAssignment']
      this.q28TotalCharge = data['q28TotalCharge']
      this.totalCharge = data['totalCharge']
      this.q29AmountPaid = data['q29AmountPaid']
      this.q30ReservedNucc = data['q30ReservedNucc']
      this.q31Signature = data['q31Signature']
      this.q31Date = data['q31Date']
        ? new Date(data['q31Date'].toString())
        : <any>undefined
      this.q32LocationInfo = data['q32LocationInfo']
      this.q32ALocationNpi = data['q32ALocationNpi']
      this.q32B = data['q32B']
      this.q33BillingProviderInfo = data['q33BillingProviderInfo']
      this.q33BillingProviderPhoneNumber = data['q33BillingProviderPhoneNumber']
      this.q33ABillingProviderNpi = data['q33ABillingProviderNpi']
      this.q33B = data['q33B']
      this.episodeOfCareId = data['episodeOfCareId']
      this.episodeOfCareNumber = data['episodeOfCareNumber']
      this.episodeOfCare = data['episodeOfCare']
        ? EpisodeOfCareDto.fromJS(data['episodeOfCare'])
        : <any>undefined
      this.providerId = data['providerId']
      this.provider = data['provider']
        ? ProviderDto.fromJS(data['provider'])
        : <any>undefined
      this.billStatus = data['billStatus']
      this.groupNumber = data['groupNumber']
      this.contractAmountTotal = data['contractAmountTotal']
      this.contractAmountTotalData = data['contractAmountTotalData']
      this.billedOutAmountTotal = data['billedOutAmountTotal']
      this.billedOutAmountTotalData = data['billedOutAmountTotalData']
      this.balanceDue = data['balanceDue']
      this.primaryContact = data['primaryContact']
      if (data['billCheckDtos'] && data['billCheckDtos'].constructor === Array) {
        this.billCheckDtos = []
        for (let item of data['billCheckDtos'])
          this.billCheckDtos.push(BillCheckDto.fromJS(item))
      }
      if (data['claimEvents'] && data['claimEvents'].constructor === Array) {
        this.claimEvents = []
        for (let item of data['claimEvents'])
          this.claimEvents.push(ClaimEventDto.fromJS(item))
      }
      this.id = data['id']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.deletedOn = data['deletedOn']
        ? new Date(data['deletedOn'].toString())
        : <any>undefined
      this.lastAppealDate = data['lastAppealDate']
        ? new Date(data['lastAppealDate'].toString())
        : <any>undefined
      this.originalCMS1500Id = data['originalCMS1500Id']
      this.appealType = data['appealType']
      this.appealDescription = data['appealDescription']
      this.appealNotes = data['appealNotes']
      this.inGlobal = data['inGlobal']
      this.stagedDocumentId = data['stagedDocumentId']
    }
  }

  static fromJS(data: any): CMS1500Dto {
    data = typeof data === 'object' ? data : {}
    let result = new CMS1500Dto()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['name'] = this.name
    data['createdOn'] = this.createdOn
      ? new Date(this.createdOn).toISOString()
      : <any>undefined
    data['providerName'] = this.providerName
    data['isActive'] = this.isActive
    data['organizationId'] = this.organizationId
    data['isIncoming'] = this.isIncoming
    data['incomingOrOutgoingDate'] = this.incomingOrOutgoingDate
      ? new Date(this.incomingOrOutgoingDate).toISOString()
      : <any>undefined
    data['dueDate'] = this.dueDate ? this.dueDate.toISOString() : <any>undefined
    data['dateOfService'] = this.dateOfService
      ? new Date(this.dateOfService).toISOString()
      : <any>undefined
    data['providerTypeId'] = this.providerTypeId
    data['q1Insurance'] = this.q1Insurance
    data['q1AInsuranceId'] = this.q1AInsuranceId
    data['q2PatientName'] = this.q2PatientName
    data['q3Date'] = this.q3Date ? new Date(this.q3Date).toISOString() : <any>undefined
    data['q3Sex'] = this.q3Sex
    data['q4InsuredName'] = this.q4InsuredName
    data['q5PatientAddress'] = this.q5PatientAddress
      ? this.q5PatientAddress.toJSON()
      : <any>undefined
    data['q6RelationshipToInsured'] = this.q6RelationshipToInsured
    data['q7InsuredAddress'] = this.q7InsuredAddress
      ? this.q7InsuredAddress.toJSON()
      : <any>undefined
    data['q8ReservedNucc'] = this.q8ReservedNucc
    data['q9OtherInsuredName'] = this.q9OtherInsuredName
    data['q9AOtherInsuredPolicyNumber'] = this.q9AOtherInsuredPolicyNumber
    data['q9BReservedNucc'] = this.q9BReservedNucc
    data['q9CReservedNucc'] = this.q9CReservedNucc
    data['q9DInsuranceProgramName'] = this.q9DInsuranceProgramName
    data['q10ARelatedToEmployment'] = this.q10ARelatedToEmployment
    data['q10BAutoAccidentState'] = this.q10BAutoAccidentState
    data['q10BRelatedToAutoAccident'] = this.q10BRelatedToAutoAccident
    data['q10CRelatedToOtherAccident'] = this.q10CRelatedToOtherAccident
    data['q10DClaimCodes'] = this.q10DClaimCodes
    data['q11PolicyFecaNumber'] = this.q11PolicyFecaNumber
    data['q11ADateOfBirth'] = this.q11ADateOfBirth
      ? new Date(this.q11ADateOfBirth).toISOString()
      : <any>undefined
    data['q11ASex'] = this.q11ASex
    data['q11BOtherClaimId'] = this.q11BOtherClaimId
    data['q11BQualifier'] = this.q11BQualifier
    data['q11CInsuranceProgramName'] = this.q11CInsuranceProgramName
    data['q11DAnotherBenefitPlan'] = this.q11DAnotherBenefitPlan
    data['q12PatientSignature'] = this.q12PatientSignature
    data['q12PatientSignatureDate'] = this.q12PatientSignatureDate
      ? new Date(this.q12PatientSignatureDate).toISOString()
      : <any>undefined
    data['q13InsuredSignature'] = this.q13InsuredSignature
    data['q14DateOfIllness'] = this.q14DateOfIllness
      ? new Date(this.q14DateOfIllness).toISOString()
      : <any>undefined
    data['q14Qualifier'] = this.q14Qualifier
    data['q15OtherDate'] = this.q15OtherDate
      ? new Date(this.q15OtherDate).toISOString()
      : <any>undefined
    data['q15Qualifier'] = this.q15Qualifier
    data['q16UnableToWorkFrom'] = this.q16UnableToWorkFrom
      ? new Date(this.q16UnableToWorkFrom).toISOString()
      : <any>undefined
    data['q16UnabletoWorkTo'] = this.q16UnabletoWorkTo
      ? new Date(this.q16UnabletoWorkTo).toISOString()
      : <any>undefined
    data['q17NameOfReferring'] = this.q17NameOfReferring
    data['q17Qualifier'] = this.q17Qualifier
    data['q17AId'] = this.q17AId
    data['q17AQualifier'] = this.q17AQualifier
    data['q17BNpi'] = this.q17BNpi
    data['q18HospitalizationFrom'] = this.q18HospitalizationFrom
      ? new Date(this.q18HospitalizationFrom).toISOString()
      : <any>undefined
    data['q18HospitalizationTo'] = this.q18HospitalizationTo
      ? new Date(this.q18HospitalizationTo).toISOString()
      : <any>undefined
    data['q19AdditionalClaims'] = this.q19AdditionalClaims
    data['q20OutsideLab'] = this.q20OutsideLab
    data['q20Charges'] = this.q20Charges
    data['q21DiagnosisIcdInd'] = this.q21DiagnosisIcdInd
    if (this.q21Diagnosis && this.q21Diagnosis.constructor === Array) {
      data['q21Diagnosis'] = []
      for (let item of this.q21Diagnosis) data['q21Diagnosis'].push(item.toJSON())
    }
    data['q22ResubmissionCode'] = this.q22ResubmissionCode
    data['q22OriginalRefNo'] = this.q22OriginalRefNo
    data['q23PrioAuthorizationNumber'] = this.q23PrioAuthorizationNumber
    if (this.q24 && this.q24.constructor === Array) {
      data['q24'] = []
      for (let item of this.q24) data['q24'].push(item.toJSON())
    }
    data['q25FederalTaxId'] = this.q25FederalTaxId
    data['q25FederalTaxIdSource'] = this.q25FederalTaxIdSource
    data['q26PatientAccountNo'] = this.q26PatientAccountNo
    data['q27AcceptAssignment'] = this.q27AcceptAssignment
    data['q28TotalCharge'] = this.q28TotalCharge
    data['totalCharge'] = this.totalCharge
    data['q29AmountPaid'] = this.q29AmountPaid
    data['q30ReservedNucc'] = this.q30ReservedNucc
    data['q31Signature'] = this.q31Signature
    data['q31Date'] = this.q31Date ? new Date(this.q31Date).toISOString() : <any>undefined
    data['q32LocationInfo'] = this.q32LocationInfo
    data['q32ALocationNpi'] = this.q32ALocationNpi
    data['q32B'] = this.q32B
    data['q33BillingProviderInfo'] = this.q33BillingProviderInfo
    data['q33BillingProviderPhoneNumber'] = this.q33BillingProviderPhoneNumber
    data['q33ABillingProviderNpi'] = this.q33ABillingProviderNpi
    data['q33B'] = this.q33B
    data['episodeOfCareId'] = this.episodeOfCareId
    data['episodeOfCareNumber'] = this.episodeOfCareNumber
    data['episodeOfCare'] = this.episodeOfCare
      ? this.episodeOfCare.toJSON()
      : <any>undefined
    data['providerId'] = this.providerId
    data['provider'] = this.provider
      ? new ProviderDto(this.provider).toJSON()
      : <any>undefined
    data['billStatus'] = this.billStatus
    data['groupNumber'] = this.groupNumber
    data['contractAmountTotal'] = this.contractAmountTotal
    data['contractAmountTotalData'] = this.contractAmountTotalData
    data['billedOutAmountTotal'] = this.billedOutAmountTotal
    data['billedOutAmountTotalData'] = this.billedOutAmountTotalData
    data['balanceDue'] = this.balanceDue
    data['primaryContact'] = this.primaryContact
    if (this.billCheckDtos && this.billCheckDtos.constructor === Array) {
      data['billCheckDtos'] = []
      for (let item of this.billCheckDtos) data['billCheckDtos'].push(item.toJSON())
    }
    if (this.claimEvents && this.claimEvents.constructor === Array) {
      data['claimEvents'] = []
      for (let item of this.claimEvents) data['claimEvents'].push(item.toJSON())
    }
    data['id'] = this.id
    data['modifiedOn'] = this.modifiedOn
      ? new Date(this.modifiedOn).toISOString()
      : <any>undefined
    data['deletedOn'] = this.deletedOn
      ? new Date(this.deletedOn).toISOString()
      : <any>undefined
    data['lastAppealDate'] = this.lastAppealDate
      ? new Date(this.lastAppealDate).toISOString()
      : <any>undefined
    data['originalCMS1500Id'] = this.originalCMS1500Id
    data['appealType'] = this.appealType
    data['appealDescription'] = this.appealDescription
    data['appealNotes'] = this.appealNotes
    data['inGlobal'] = this.inGlobal
    data['lastAppealCMS1500Id'] = this.lastAppealCMS1500Id

    return data
  }
}
