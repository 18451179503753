import { Grid, Typography } from '@material-ui/core'
import { connect, FormikContext, getIn } from 'formik'
import { CheckCircle } from 'mdi-material-ui'
import * as React from 'react'
import { CSSProperties } from 'react'
import { ILocation, IPatient } from 'src/Definitions'
import FullWidthField from '../../common/FullWidthField'
import TypeAheadField from '../../common/TypeAheadField/TypeAheadField'
import States, { IState } from '../../utils/States'
import PhoneNumberMask from '../PhoneNumberMask'
import { IAddressFormValues } from './AddressFormValues'

export interface IHaveAddressFormValues {
  address?: IAddressFormValues
  billingAddress?: IAddressFormValues
  insuredAddress?: IAddressFormValues
  patientAddress?: IAddressFormValues
  physicalAddress?: IAddressFormValues
  billingInquiries?: IAddressFormValues
  generalInquiries?: IAddressFormValues
  isBillingAddressSameAsPhysicalAddress?: boolean
}

interface IFormikProps {
  formik: FormikContext<IHaveAddressFormValues>
}

interface IAddressFieldProps {
  disabled?: boolean
  header?: JSX.Element
  label?: string
  name: string
  handleParentChange?: (dist: boolean) => void
  showPhone?: boolean
  showEmail?: boolean
  showFax?: boolean
  showWebsite?: boolean
  outlined?: boolean
  required?: boolean
  requireWebsite?: boolean
  requireFax?: boolean
  requirePhone?: boolean
  labelIcon?: JSX.Element
  largeLabel?: boolean
  setStyle?: CSSProperties
  addressLabel?: string
  verified?: boolean
  resetCoordinates?: () => void
  location?: ILocation
  patient?: IPatient
  previousValues?: string
  setPrevious?: (vals: string) => void
}

const AddressField: React.SFC<IAddressFieldProps & IFormikProps> = (props) => {
  const {
    disabled,
    formik,
    showPhone,
    showEmail,
    showFax,
    showWebsite,
    outlined,
    required,
    requireWebsite,
    requireFax,
    requirePhone,
    labelIcon,
    largeLabel,
    setStyle,
    addressLabel,
    verified,
    resetCoordinates,
    location,
    patient,
    previousValues,
    setPrevious,
  } = props

  const createName = (field: string) => `${props.name}.${field}`
  const hasValue = (field: string) => {
    const name = createName(field)
    const value = getIn(formik.values, name)
    return !!value
  }
  const getStateName = (state: IState) => (state ? state.name : '')

  const stateInitialValue = getIn(formik.initialValues, createName('state'))
  const stateValue = getIn(formik.values, createName('state'))

  const getValue = (fieldName: string) => {
    const name = createName(fieldName)
    const value = getIn(formik.values, name)
    return value
  }

  const handleChange = () => {
    if (props.handleParentChange) {
      props.handleParentChange!(
        !(hasValue('city') || hasValue('state') || hasValue('zip'))
      )
    }
    var state = getValue('state')
    var actualState = ''
    if (state) {
      actualState = state.name
    }

    if (location && location?.physicalAddress) {
      if (
        resetCoordinates &&
        setPrevious &&
        getValue('line1') + getValue('city') + actualState + getValue('zip') !=
          previousValues &&
        (getValue('line1') != location?.physicalAddress.line1 ||
          getValue('city') != location?.physicalAddress.city ||
          actualState != location?.physicalAddress.state ||
          getValue('zip') != location?.physicalAddress.zip)
      ) {
        resetCoordinates()
        setPrevious!(getValue('line1') + getValue('city') + actualState + getValue('zip'))
      }
    } else if (patient && patient?.address) {
      if (
        resetCoordinates &&
        setPrevious &&
        getValue('line1') + getValue('city') + actualState + getValue('zip') !=
          previousValues &&
        (getValue('line1') != patient?.address.line1 ||
          getValue('city') != patient?.address.city ||
          actualState != patient?.address.state ||
          getValue('zip') != patient?.address.zip)
      ) {
        resetCoordinates()
        setPrevious!(getValue('line1') + getValue('city') + actualState + getValue('zip'))
      }
    }
  }

  var actualState = ''
  var state = getValue('state')
  if (state) {
    actualState = state.name
  }

  if (
    location?.physicalAddress &&
    actualState != location?.physicalAddress.state &&
    getValue('line1') + getValue('city') + actualState + getValue('zip') != previousValues
  ) {
    handleChange()
  }

  return (
    <Grid container direction="column" spacing={2} wrap="nowrap">
      {props.label && (
        <Grid
          item
          container
          direction="row"
          spacing={0}
          alignItems="center"
          style={setStyle}
        >
          {labelIcon ? <Grid item>{labelIcon}</Grid> : <></>}
          <Grid item style={setStyle}>
            <Typography
              variant={largeLabel ? 'h6' : 'body1'}
              color="textSecondary"
              style={{ height: '18px', paddingRight: '8px' }}
            >
              {props.label}
            </Typography>
          </Grid>
          <Grid item style={{ paddingTop: '10px' }}>
            {props.header}
            {verified == true ? <CheckCircle color="primary" /> : undefined}
          </Grid>
        </Grid>
      )}

      <Grid item>
        <FullWidthField
          name={createName('line1')}
          disabled={disabled}
          label={addressLabel ?? 'Street Address'}
          InputLabelProps={{ shrink: hasValue('line1') }}
          variant={outlined ? 'outlined' : 'standard'}
          required={required}
          onChange={handleChange()}
          errorMessage={getIn(formik.errors, createName('line1'))}
        />
      </Grid>
      <Grid item>
        <FullWidthField
          name={createName('line2')}
          disabled={disabled}
          label={addressLabel ? addressLabel + ' 2' : 'Street Address 2'}
          InputLabelProps={{ shrink: hasValue('line2') }}
          variant={outlined ? 'outlined' : 'standard'}
          errorMessage={getIn(formik.errors, createName('line2'))}
        />
      </Grid>
      <Grid item container direction="row" spacing={2} style={{ marginBottom: 8 }}>
        <Grid item md={6}>
          <FullWidthField
            name={createName('city')}
            disabled={disabled}
            label="City"
            InputLabelProps={{ shrink: hasValue('city') }}
            variant={outlined ? 'outlined' : 'standard'}
            required={required}
            onChange={handleChange()}
          />
        </Grid>
        <Grid item md={3}>
          <TypeAheadField
            label="State"
            labelShrink={hasValue('state')}
            inputId={createName('state')}
            name={createName('state')}
            cypressLabel="states"
            childCypressLabel="state"
            disabled={disabled}
            fullWidth
            setFieldValue={formik.setFieldValue}
            initialValue={
              typeof stateInitialValue === 'string'
                ? { name: stateInitialValue }
                : stateInitialValue
            }
            selectedValue={
              typeof stateValue === 'string' ? { name: stateValue } : stateValue
            }
            items={States}
            getName={getStateName}
            getValue={getStateName}
            required={required}
            errorMessage={getIn(formik.errors, createName('state'))}
            outlined={outlined}
          />
        </Grid>
        <Grid item md={3}>
          <FullWidthField
            name={createName('zip')}
            disabled={disabled}
            label="Zip Code"
            InputLabelProps={{ shrink: hasValue('zip') }}
            variant={outlined ? 'outlined' : 'standard'}
            required={required}
            errorMessage={getIn(formik.errors, createName('zip'))}
            onChange={handleChange()}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        {showPhone == null || showPhone ? (
          <Grid item md={3}>
            <FullWidthField
              name={createName('phoneNumber')}
              disabled={disabled}
              label="Phone #"
              required={requirePhone ?? false}
              InputLabelProps={{ shrink: hasValue('phoneNumber') }}
              InputProps={{ inputComponent: PhoneNumberMask }}
              variant={outlined ? 'outlined' : 'standard'}
              errorMessage={getIn(formik.errors, createName('phoneNumber'))}
            />
          </Grid>
        ) : (
          <></>
        )}
        {showFax ? (
          <Grid item md={3}>
            <FullWidthField
              name={createName('faxNumber')}
              disabled={disabled}
              label="Fax #"
              required={requireFax ?? false}
              InputLabelProps={{ shrink: hasValue('faxNumber') }}
              InputProps={{ inputComponent: PhoneNumberMask }}
              variant={outlined ? 'outlined' : 'standard'}
              errorMessage={getIn(formik.errors, createName('faxNumber'))}
            />
          </Grid>
        ) : (
          <></>
        )}
        {showWebsite ? (
          <Grid item md={3}>
            <FullWidthField
              name={createName('website')}
              disabled={disabled}
              label="Website"
              InputLabelProps={{ shrink: hasValue('website') }}
              variant={outlined ? 'outlined' : 'standard'}
              required={requireWebsite !== undefined ? requireWebsite : required}
              errorMessage={getIn(formik.errors, createName('website'))}
            />
          </Grid>
        ) : (
          <></>
        )}
        {showEmail ? (
          <Grid item md={3}>
            <FullWidthField
              name={createName('email')}
              disabled={disabled}
              label="Email"
              InputLabelProps={{ shrink: hasValue('email') }}
              variant={outlined ? 'outlined' : 'standard'}
              required={required}
              errorMessage={getIn(formik.errors, createName('email'))}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  )
}

export default connect<IAddressFieldProps, IHaveAddressFormValues>(AddressField)
