import { Grid, InputAdornment, Typography } from '@material-ui/core'
import { MonetizationOn } from '@material-ui/icons'
import { connect, FormikContext } from 'formik'
import React from 'react'
import CardWithTitle from 'src/common/CardWithTitle/CardWithTitle'
import InterceptTextField from '../common/InterceptTextField'
import { IQuoteFormValues } from './QuoteFormValues'

interface IQuoteTotalProps {
  getLineItemsTotal: (lineItems: any) => number
  getLineItemsReconciledTotal?: (lineItems: any) => number
  viewMode: boolean
  reconMode: boolean
}

interface IFormikProps {
  formik: FormikContext<IQuoteFormValues>
}

class QuoteTotals extends React.Component<IQuoteTotalProps & IFormikProps> {
  public state = {
    total: 0,
    reconciledTotal: 0,
  }

  public componentDidMount(): void {
    this.setState({ total: this.calculateTotal() })
    if (this.props.reconMode) {
      this.setState({ reconciledTotal: this.calculateReconciledTotal() })
    }
  }

  public calculateTotal() {
    const { formik, getLineItemsTotal } = this.props

    return (
      getLineItemsTotal(formik.values.professionalComponentLineItems) +
      getLineItemsTotal(formik.values.miscellaneousLineItems) +
      getLineItemsTotal(formik.values.technicalComponentLineItems) +
      getLineItemsTotal(formik.values.anesthesiaLineItems) +
      getLineItemsTotal(formik.values.physicalTherapyLineItems)
    )
  }

  public calculateReconciledTotal() {
    const { formik, getLineItemsReconciledTotal } = this.props

    return (
      getLineItemsReconciledTotal!(formik.values.professionalComponentLineItems) +
      getLineItemsReconciledTotal!(formik.values.miscellaneousLineItems) +
      getLineItemsReconciledTotal!(formik.values.technicalComponentLineItems) +
      getLineItemsReconciledTotal!(formik.values.anesthesiaLineItems) +
      getLineItemsReconciledTotal!(formik.values.physicalTherapyLineItems)
    )
  }

  public getTargetRate(targetMargin: number) {
    return this.calculateTotal() * (1 + targetMargin / 100)
  }

  public getTargetReconciledRate(targetMargin: number) {
    return this.calculateReconciledTotal() * (1 + targetMargin / 100)
  }

  public calculateAdjustedCost(form: FormikContext<IQuoteFormValues>) {
    const total = this.calculateTotal()
    if (this.state.total !== total) {
      this.setState({ total: total })
      form.setFieldValue('adjustedCost', '')
    }
  }

  public calculateAdjustedReconciledCost(form: FormikContext<IQuoteFormValues>) {
    const reconciledTotal = this.calculateReconciledTotal()
    if (this.state.reconciledTotal !== reconciledTotal) {
      this.setState({ reconciledTotal: reconciledTotal })
      form.setFieldValue('adjustedReconciledCost', '')
    }
  }

  public overrideAdjustedCost(form: FormikContext<IQuoteFormValues>) {
    if (!form.values.isAdjustedCostOverridden) {
      form.setFieldValue('isAdjustedCostOverridden', true)
    }
  }

  public overrideAdjustedReconciledCost(form: FormikContext<IQuoteFormValues>) {
    if (!form.values.isAdjustedReconciledCostOverridden) {
      form.setFieldValue('isAdjustedReconciledCostOverridden', true)
    }
  }

  public getAdjustedMargin(adjustedCost: number) {
    // (Adjusted Cost - Total Cost) / Total Cost
    const totalCost = this.calculateTotal()
    return totalCost !== 0
      ? Math.round(((adjustedCost - totalCost) / totalCost) * 100)
      : 0
  }

  public getAdjustedReconciledMargin(adjustedCost: number) {
    // (Adjusted Cost - Total Cost) / Total Cost
    const totalCost = this.calculateReconciledTotal()
    return totalCost !== 0
      ? Math.round(((adjustedCost - totalCost) / totalCost) * 100)
      : 0
  }

  public render() {
    const { formik, reconMode } = this.props
    this.calculateAdjustedCost(formik)
    if (reconMode) {
      this.calculateAdjustedReconciledCost(formik)
    }
    return (
      <>
        <Grid item xs={4}>
          <CardWithTitle
            title={this.props.reconMode ? 'Quoted Total' : 'Total'}
            icon={MonetizationOn}
          >
            <Grid item container>
              <Grid
                alignItems="center"
                spacing={2}
                container
                justifyContent="space-between"
                direction="column"
                wrap="nowrap"
              >
                <Grid container item direction="row" wrap="nowrap" spacing={1}>
                  <Grid item xs={4}>
                    <Typography color="textSecondary">Total Cost:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography color="textSecondary">
                      ${this.calculateTotal().toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item direction="row" wrap="nowrap" spacing={1}>
                  <Grid item xs={4}>
                    <Typography color="textSecondary">Target Margin:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <InterceptTextField
                      disabled={this.props.viewMode || this.props.reconMode || false}
                      name="targetMargin"
                      type="number"
                      inputProps={{ min: '0', max: '100' }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item direction="row" wrap="nowrap" spacing={1}>
                  <Grid item xs={4}>
                    <Typography color="textSecondary">Target Rate:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {formik.values.targetMargin && (
                      <Typography color="textSecondary">
                        ${this.getTargetRate(formik.values.targetMargin).toFixed(2)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container item direction="row" wrap="nowrap" spacing={1}>
                  <Grid item xs={4}>
                    <Typography color="textSecondary">Adjusted Global Price:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InterceptTextField
                      disabled={this.props.viewMode || this.props.reconMode || false}
                      name="adjustedCost"
                      type="number"
                      inputProps={{ min: '0', max: '1000000000000' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item direction="row" wrap="nowrap" spacing={1}>
                  <Grid item xs={4}>
                    <Typography color="textSecondary">Adjusted Margin:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {formik.values.adjustedCost && (
                      <Typography color="textSecondary">
                        {`${this.getAdjustedMargin(formik.values.adjustedCost)}%`}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardWithTitle>
        </Grid>
        {reconMode && (
          <Grid item xs={4}>
            <CardWithTitle title="Reconciled Total" icon={MonetizationOn}>
              <Grid item container>
                <Grid
                  alignItems="center"
                  spacing={2}
                  container
                  justifyContent="space-between"
                  direction="column"
                  wrap="nowrap"
                >
                  <Grid container item direction="row" wrap="nowrap" spacing={1}>
                    <Grid item xs={4}>
                      <Typography color="textSecondary">Total Cost:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography color="textSecondary">
                        ${this.calculateReconciledTotal().toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" wrap="nowrap" spacing={1}>
                    <Grid item xs={4}>
                      <Typography color="textSecondary">Target Margin:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <InterceptTextField
                        disabled={this.props.viewMode || false}
                        name="targetReconciledMargin"
                        type="number"
                        inputProps={{ min: '0', max: '100' }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" wrap="nowrap" spacing={1}>
                    <Grid item xs={4}>
                      <Typography color="textSecondary">Target Rate:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {formik.values.targetReconciledMargin && (
                        <Typography color="textSecondary">
                          $
                          {this.getTargetReconciledRate(
                            formik.values.targetReconciledMargin
                          ).toFixed(2)}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" wrap="nowrap" spacing={1}>
                    <Grid item xs={4}>
                      <Typography color="textSecondary">
                        Adjusted Global Price:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <InterceptTextField
                        disabled={this.props.viewMode || false}
                        name="adjustedReconciledCost"
                        type="number"
                        inputProps={{
                          min: '0',
                          max: '1000000000000',
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" wrap="nowrap" spacing={1}>
                    <Grid item xs={4}>
                      <Typography color="textSecondary">Adjusted Margin:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {formik.values.adjustedReconciledCost && (
                        <Typography color="textSecondary">
                          {`${this.getAdjustedReconciledMargin(
                            formik.values.adjustedReconciledCost
                          )}%`}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardWithTitle>
          </Grid>
        )}
      </>
    )
  }
}

export default connect<IQuoteTotalProps, IQuoteFormValues>(QuoteTotals)
