import {
  AddShoppingCart,
  Assignment,
  CallMerge,
  Person,
  RemoveCircle,
  UndoRounded,
  CallSplit,
  PlaylistAdd,
  OpenInBrowserSharp,
} from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import { IMenuItem } from '../../common/ESDataTable'
import { DefaultCMS1500, ICMS1500, IQuote } from '../../Definitions'
import {
  Client,
  IPhysicianDto,
  IProviderDto,
  IProviderTypeDto,
  IQuoteDto,
  ITypeDto,
} from '../../generated_client'
import { ICMS1500Dto } from '../../viewModels/claim/Cms1500Dto'
import {
  IProfessionalComponentLineItemFormValues,
  ITechnicalComponentLineItemFormValues,
} from '../../quotes/QuoteFormValues'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'
import { GetInvoicePaymentDetails, GetQuoteById } from 'src/services/QuoteService'
import { QuoteSummaryResult } from 'src/viewModels/QuoteSummaryResult'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { PlaylistRemove } from 'mdi-material-ui'
import {
  GetCptDescriptions,
  GetReconcileQuoteClaimOptions,
} from 'src/services/LookupService'
import { ReconcileQuoteClaimDropdownOption } from 'src/viewModels/quotes/ReconcileQuoteClaimDropdownOption'
import { IGlobalInvoiceCPTCodeDTO } from 'src/viewModels/quotes/GlobalInvoiceCPTCodeDTO'
import { IGlobalInvoicePaymentSummary } from 'src/viewModels/InvoicePaymentSummaryResult'
import moment from 'moment'
import { formatCurrency } from 'src/utils/Formatter'
import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { QuoteLineItemType } from 'src/utils/QuoteLineItemType'
import { IProfessionalComponentLineItemDTO } from 'src/viewModels/quotes/ProfessionalComponentLineItemDTO'
import { ITechnicalComponentLineItemDTO } from 'src/viewModels/quotes/TechnicalComponentLineItemDTO'
import { IAnesthesiaLineItemDTO } from 'src/viewModels/quotes/AnesthesiaLineItemDTO'
import { IMiscellaneousLineItemDTO } from 'src/viewModels/quotes/MiscellaneousLineItemDTO'
import { IPhysicalTherapyLineItemDTO } from 'src/viewModels/quotes/PhysicalTherapyLineItemDTO'
import { DocumentButtonSummaryResult } from 'src/viewModels/quotes/DocumentButtonSummaryResult'
import QuoteClaimButton from 'src/quotes/QuoteClaimButton'
import { QuoteInboxItem } from 'src/viewModels/workflow/QuoteInboxItem'

export default class QuotesStore {
  @observable
  public isLoading: boolean = false
  @observable
  public quotes: IQuoteDTO[]
  @observable
  public selectedQuote?: IQuoteDTO
  @observable
  public selectedQuoteInboxItem?: QuoteInboxItem
  @observable
  public selectedLongComments?: string
  @observable
  public cptDescriptionList?: IGlobalInvoiceCPTCodeDTO[]
  @observable
  public invoiceSuffix?: string
  @observable
  public providers: IProviderDto[] = []
  @observable
  public providerTypes: IProviderTypeDto[] = []
  @observable
  public physicians: IPhysicianDto[] = []
  @observable
  public pcTypes: ITypeDto[] = []
  @observable
  public tcTypes: ITypeDto[] = []
  @observable
  public miTypes: ITypeDto[] = []
  @observable
  public ptTypes: ITypeDto[] = []
  @observable
  public anTypes: ITypeDto[] = []
  @observable
  public genericTypes: ITypeDto[] = []
  @observable
  public selectedTypes: ITypeDto[] = []
  @observable
  public isBillsDialogOpen: boolean = false
  @observable
  public isQuoteLineModalOpen: boolean = false
  @observable
  public isQuoteStatusModalOpen: boolean = false
  @observable
  public isQuoteReconcileLineModalOpen: boolean = false
  @observable
  public isQuoteReconcileLineWizardModalOpen: boolean = false
  @observable
  public isQuoteLogModalOpen: boolean = false
  @observable
  public isQuoteLogCommentModalOpen: boolean = false
  @observable
  public isAdjusterEmailModalOpen: boolean = false
  @observable
  public isLongCommentsModalOpen: boolean = false
  @observable
  public claimModalLoad?: (episodeOfCareId: string) => void
  @observable
  public selectedLineItem?:
    | IProfessionalComponentLineItemFormValues
    | ITechnicalComponentLineItemFormValues
  @observable
  public selectedType?: ITypeDto
  @observable modalSwitch?: string
  public dataTableStore: DataTableStore<IQuoteDto, IQuote>
  public billsDataTableStore: DataTableStore<ICMS1500Dto, ICMS1500>
  @observable
  public assistantWarning: boolean
  @observable
  public existingQuoteLineId?: string
  @observable
  public existingQuoteLine: boolean
  @observable
  public claimDropdown: Array<ReconcileQuoteClaimDropdownOption>
  @observable
  public claimLineDropdown: Array<ReconcileQuoteClaimDropdownOption>
  @observable
  public usedClaimLines: Array<string>
  @observable
  public invoiceLogModalIsOpen: boolean
  @observable
  public selectedQuoteId?: string
  @observable
  public selectedQuotePatientHeader?: string
  @observable
  public selectedInvoiceId?: string
  @observable
  public startRecon: boolean
  @observable
  public restartQuote: boolean
  @observable
  public selectedInvoicePaymentSummary?: IGlobalInvoicePaymentSummary
  @observable
  public refreshParentOnBundleModalClose: boolean = true
  @observable
  public search: boolean = false
  @observable
  public reloadFunction: () => void
  @observable
  public billoutBundleClaimsTitle: string
  @observable
  public getQuoteValuesForStaticAction?: () => Promise<IQuoteDTO>

  constructor(
    private globalStore: GlobalStore,
    private routerStore: RouterStore,
    private client: Client
  ) {
    this.billsDataTableStore = new DataTableStore<ICMS1500Dto, ICMS1500>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) => {
        const episodeOfCareId = this.selectedQuote
          ? this.selectedQuote.episodeOfCareId
          : undefined
        if (orderBy === undefined) {
          orderBy = 'GroupNumber'
        }
        return this.client.getAllCMS1500(
          filter,
          undefined,
          undefined,
          episodeOfCareId,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          page,
          1,
          orderBy,
          includeInactives
        )
      },
      (cms1500) => {
        return this.setupCMS1500(cms1500)
      }
    )
  }

  //Garbage needed to make the store instantiate
  private setupCMS1500 = (cms1500: ICMS1500Dto[]) => {
    return cms1500.map(this.addDefaultBillFields).map(this.setCMS1500MenuItems)
  }

  private addDefaultBillFields = (cms1500: ICMS1500Dto): ICMS1500 => {
    return { ...DefaultCMS1500(), ...cms1500 }
  }

  private setCMS1500MenuItems = (cms1500: ICMS1500) => {
    const menuItems: IMenuItem[] = []
    cms1500.menuItems = menuItems
    return cms1500
  }
  //End section

  @action.bound
  public closeDialog() {
    this.selectedQuote = undefined
  }

  @action.bound
  public openLongCommentsModal(comments: string) {
    this.selectedLongComments = comments
    this.isLongCommentsModalOpen = true
  }

  @action.bound
  public closeLongCommentsModal() {
    this.selectedLongComments = undefined
    this.isLongCommentsModalOpen = false
  }

  @action.bound
  public openInvoiceLogModal(quote: QuoteSummaryResult) {
    this.invoiceLogModalIsOpen = true
    this.selectedQuoteId = quote.quoteId
    this.selectedInvoiceId = quote.invoiceId
    this.selectedQuotePatientHeader = `${quote.patientName} (${moment(
      quote?.patientDateOfBirth
    ).format('MM/DD/YYYY')}) ${quote.eocNumber}`
  }
  @action.bound
  public closeInvoiceLogModal() {
    this.invoiceLogModalIsOpen = false
    this.selectedQuoteId = undefined
    this.selectedInvoiceId = undefined
    this.selectedQuotePatientHeader = ''
  }

  @action.bound
  public openQuoteLogModal(quote: QuoteSummaryResult) {
    this.isLoading = true
    this.selectedQuoteId = quote.quoteId
    this.selectedQuotePatientHeader = `${quote.patientName} (${moment(
      quote?.patientDateOfBirth
    ).format('MM/DD/YYYY')}) ${quote.eocNumber}`
    this.getQuoteById(quote.quoteId).then(() => {
      this.isQuoteLogModalOpen = true
      this.isLoading = false
    })
  }

  @action.bound
  public closeQuoteLogModal() {
    this.isQuoteLogModalOpen = false
    this.selectedQuote = undefined
    this.selectedQuoteId = undefined
    this.selectedQuotePatientHeader = ''
  }

  @action.bound
  public showAssistantWarning() {
    this.assistantWarning = true
  }

  @action.bound
  public addReconciledClaimLine(id: string) {
    if (this.usedClaimLines == undefined) {
      this.usedClaimLines = new Array<string>()
    }
    var lineId = id
    this.usedClaimLines.push(lineId)
  }

  @action.bound
  public removeReconciledClaimLine(id: string) {
    if (this.usedClaimLines == undefined) {
      this.usedClaimLines = new Array<string>()
    }
    for (let i = 0; i < this.usedClaimLines.length; i++) {
      if (this.usedClaimLines[i] == id) {
        this.usedClaimLines.splice(i, 1)
      }
    }
  }

  @action.bound
  public getQuoteById(id: string) {
    this.isLoading = true
    return GetQuoteById(id)
      .then((resp: any) =>
        runInAction(() => {
          this.selectedQuote = resp

          if (
            this.selectedQuote?.professionalComponentLineItems?.length == 0 ||
            this.selectedQuote?.technicalComponentLineItems?.length == 0 ||
            this.selectedQuote?.anesthesiaLineItems?.length == 0 ||
            this.selectedQuote?.miscellaneousLineItems?.length == 0
          ) {
            this.usedClaimLines = new Array<string>()
          }
          this.selectedQuote?.professionalComponentLineItems?.forEach((line) => {
            if (line.reconciledCMS1500LineItemId) {
              this.addReconciledClaimLine(line.reconciledCMS1500LineItemId)
            }
          })
          this.selectedQuote?.technicalComponentLineItems?.forEach((line) => {
            if (line.reconciledCMS1500LineItemId) {
              this.addReconciledClaimLine(line.reconciledCMS1500LineItemId)
            }
          })
          this.selectedQuote?.anesthesiaLineItems?.forEach((line) => {
            if (line.reconciledCMS1500LineItemId) {
              this.addReconciledClaimLine(line.reconciledCMS1500LineItemId)
            }
          })
          this.selectedQuote?.miscellaneousLineItems?.forEach((line) => {
            if (line.reconciledCMS1500LineItemId) {
              this.addReconciledClaimLine(line.reconciledCMS1500LineItemId)
            }
          })
        })
      )
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private renderQuote = (quoteId: string) => () => {
    window.open(`/workflow/quote/${quoteId}/default-inbox`, '_blank')
  }

  @action.bound
  public createInvoice = (quoteInboxItem: QuoteInboxItem, actionId: number) => {
    this.getQuoteById(quoteInboxItem.associatedItemId).then(async () => {
      this.selectedQuoteInboxItem = quoteInboxItem
      this.routerStore.push(
        `/quotes/${quoteInboxItem.associatedItemId}/globalinvoice/${actionId}`
      )
    })
  }

  @action.bound
  public setSelectedQuoteInboxItem(quoteInboxItem?: QuoteInboxItem) {
    this.selectedQuoteInboxItem = quoteInboxItem
  }

  public buildQuoteMenuItems = (quote: QuoteSummaryResult) => {
    let menuItems: any[] = []

    menuItems.push({
      color: '#29348F',
      icon: OpenInBrowserSharp,
      name: 'Open Quote',
      onClick: this.renderQuote(quote.quoteId),
    })

    menuItems.push({
      color: '#94D33D',
      icon: AddShoppingCart,
      name: 'Bundle Claims',
      onClick: () => {
        this.openBillsDialog(quote.quoteId)
      },
    })

    menuItems.push({
      color: '#94D33D',
      icon: Assignment,
      name: 'View Episode of Care',
      onClick: () => {
        this.globalStore.setReturn('/quotes').then(() => {
          sessionStorage.setItem('patientTab', '0')
          sessionStorage.setItem('eocTab', '0')
          this.routerStore.push(
            `/patients/patient/${quote.patientId}/referral/${quote.referralId}/episodeOfCare/${quote.episodeOfCareId}`
          )
        })
      },
    })

    menuItems.push({
      color: '#29348F',
      icon: Person,
      name: 'View Patient',
      onClick: () => {
        this.globalStore.setReturn('/quotes').then(() => {
          sessionStorage.setItem('patientTab', '0')
          this.routerStore.push(`/patients/patient/${quote.patientId}`)
        })
      },
    })

    return menuItems
  }

  @action.bound
  public async setGetQuoteValuesForStaticAction(
    parentFunction: () => Promise<IQuoteDTO>
  ) {
    this.getQuoteValuesForStaticAction = parentFunction
  }

  @action.bound
  public loadQuote(quoteId: string, reload: boolean = true) {
    this.isLoading = true
    this.assistantWarning = false
    if (this.selectedQuote && this.selectedQuote.id === quoteId && !reload) {
      this.isLoading = false
      return Promise.resolve()
    }
    this.selectedQuote = undefined
    return GetQuoteById(quoteId)
      .then((_response: any) =>
        runInAction(() => {
          this.selectedQuote = _response
          if (
            this.selectedQuote?.professionalComponentLineItems?.length == 0 ||
            this.selectedQuote?.technicalComponentLineItems?.length == 0 ||
            this.selectedQuote?.anesthesiaLineItems?.length == 0 ||
            this.selectedQuote?.miscellaneousLineItems?.length == 0
          ) {
            this.usedClaimLines = new Array<string>()
          }
          this.selectedQuote?.professionalComponentLineItems?.forEach((line) => {
            if (line.reconciledCMS1500LineItemId) {
              this.addReconciledClaimLine(line.reconciledCMS1500LineItemId)
            }
          })
          this.selectedQuote?.technicalComponentLineItems?.forEach((line) => {
            if (line.reconciledCMS1500LineItemId) {
              this.addReconciledClaimLine(line.reconciledCMS1500LineItemId)
            }
          })
          this.selectedQuote?.anesthesiaLineItems?.forEach((line) => {
            if (line.reconciledCMS1500LineItemId) {
              this.addReconciledClaimLine(line.reconciledCMS1500LineItemId)
            }
          })
          this.selectedQuote?.miscellaneousLineItems?.forEach((line) => {
            if (line.reconciledCMS1500LineItemId) {
              this.addReconciledClaimLine(line.reconciledCMS1500LineItemId)
            }
          })
        })
      )
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public setInvoiceSuffix(suffix: string) {
    this.invoiceSuffix = suffix
  }

  @action.bound
  public fetchCptDescriptions() {
    this.isLoading = true
    if (this.selectedQuote) {
      return GetCptDescriptions!(this.selectedQuote)
        .then((_response: any) =>
          runInAction(() => {
            this.cptDescriptionList = _response
          })
        )
        .finally(() => {
          this.isLoading = false
        })
    } else {
      this.isLoading = false
      return Promise.resolve()
    }
  }

  @action.bound
  public getInvoicePaymentSummary() {
    this.isLoading = true
    if (
      this.selectedQuote &&
      this.selectedQuote.id &&
      this.selectedQuote.globalInvoiceId
    ) {
      return GetInvoicePaymentDetails!(this.selectedQuote!.id).then((_response: any) =>
        runInAction(() => {
          this.selectedInvoicePaymentSummary = _response
        })
      )
    } else {
      return Promise.resolve()
    }
  }

  @action.bound
  public resetSelectedQuote() {
    this.selectedQuote = undefined
  }

  @action.bound
  public getProviderSuggestions(filter: string, providerTypeId?: string) {
    return this.client.getAllProviders(filter, providerTypeId).then(({ result }) =>
      runInAction(() => {
        this.providers = result
      })
    )
  }

  @action.bound
  public getPhysicianSuggestions(filter: string, providerId: string) {
    return this.client.getAllPhysicians(providerId, filter).then(({ result }) =>
      runInAction(() => {
        this.physicians = result
      })
    )
  }

  @action.bound
  public getProviderTypes() {
    return this.client.getProviderTypes().then(({ result }) =>
      runInAction(() => {
        this.providerTypes = result
      })
    )
  }

  @action.bound
  public setSelectedType(type?: ITypeDto) {
    this.selectedType = type
  }

  @action.bound
  public getTypesForModal(type: string) {
    return this.client.getTypesByCategory(type).then(({ result }) =>
      runInAction(() => {
        this.genericTypes = result
        this.selectedTypes = this.selectedTypes.concat(result)
      })
    )
  }

  @action.bound
  public async getDropdownOptions(id: string, modalSwitch: string) {
    this.claimDropdown = await GetReconcileQuoteClaimOptions(id, modalSwitch)
  }

  @action.bound
  public getTypesByCategory(type: string) {
    return this.client.getTypesByCategory(type).then(({ result }) =>
      runInAction(() => {
        switch (type) {
          case 'PC':
            this.pcTypes = result
            break
          case 'TC':
            this.tcTypes = result
            break
          case 'MI':
            this.miTypes = result
            break
          case 'PT':
            this.ptTypes = result
            break
          case 'AN':
            this.anTypes = result
            break
        }
        this.selectedTypes = this.selectedTypes.concat(result)
      })
    )
  }

  @action.bound
  public async openBillsDialogById(
    episodeOfCareId: string,
    quoteId: string,
    altTitle?: string
  ) {
    await GetQuoteById(quoteId).then((_response: any) => {
      this.selectedQuote = _response

      if (altTitle) {
        this.billoutBundleClaimsTitle = `Researching ${altTitle}`
      } else {
        this.billoutBundleClaimsTitle = ''
      }

      if (this.claimModalLoad) {
        this.claimModalLoad(episodeOfCareId)
      }
      this.isBillsDialogOpen = true
      return Promise.resolve()
    })
  }

  @action.bound
  public async openBillsDialog(quoteId: string) {
    await GetQuoteById(quoteId).then((_response: any) => {
      this.selectedQuote = _response
      if (this.claimModalLoad) {
        this.claimModalLoad(this.selectedQuote!.episodeOfCareId!)
      }
      this.billoutBundleClaimsTitle = ''
      this.isBillsDialogOpen = true
    })
  }

  @action.bound
  public setClaimsModalLoad(parentFunction: () => void) {
    this.claimModalLoad = parentFunction
  }

  @action.bound
  public closeBillsDialog(actionTaken: boolean) {
    this.selectedQuote = undefined
    this.isBillsDialogOpen = false
    this.billsDataTableStore.clearData()

    if (this.refreshParentOnBundleModalClose && actionTaken) {
      location.reload()
    }

    if (this.search && actionTaken) {
      this.reloadFunction()
    }
  }

  @action.bound
  public setParentRefreshWithBundle(setRefresh: boolean) {
    this.refreshParentOnBundleModalClose = setRefresh
  }

  @action.bound
  public setReloadFunction(searchFunc: () => void) {
    this.search = true
    this.reloadFunction = searchFunc
  }

  @action.bound
  public openQuoteLineModal(quote: IQuoteDTO, modalSwitch: string) {
    this.selectedQuote = quote
    this.modalSwitch = modalSwitch
    this.getTypesForModal!(modalSwitch)
    this.isQuoteLineModalOpen = true
  }

  @action.bound
  public closeQuoteLineModal(reload?: () => void) {
    this.isQuoteLineModalOpen = false
    if (reload != undefined) {
      reload()
    }
  }

  @action.bound
  public openReconcileQuoteLineModal(
    quote: IQuoteDTO,
    modalSwitch: string,
    existingQuoteLineId?: string
  ) {
    this.selectedQuote = quote
    this.modalSwitch = modalSwitch
    this.existingQuoteLineId = existingQuoteLineId
    this.getTypesForModal!(modalSwitch)
    this.getDropdownOptions(quote.id!, modalSwitch).then(() => {
      if (
        (modalSwitch == 'AN' || modalSwitch == 'PT' || modalSwitch == 'MI') &&
        existingQuoteLineId === undefined
      ) {
        this.isQuoteReconcileLineWizardModalOpen = true
      } else {
        this.isQuoteReconcileLineModalOpen = true
      }
    })
  }

  @action.bound
  public closeReconcileQuoteLineModal(reload?: () => void) {
    this.isQuoteReconcileLineModalOpen = false
    if (reload != undefined) {
      reload()
    }
  }

  @action.bound
  public closeReconcileQuoteLineWizardModal(reload?: () => void) {
    this.isQuoteReconcileLineWizardModalOpen = false
    if (reload != undefined) {
      reload()
    }
  }

  @action.bound
  public getLineItemsTotal = (lineItems: any) => {
    return lineItems
      .map((item: any) => {
        return Number(item.cost || 0)
      })
      .reduce((a: number, b: number) => a + b, 0)
  }

  @action.bound
  public getLineItemsReconciledTotal = (lineItems: any) => {
    return lineItems
      .map((item: any) => {
        return Number(item.reconciledCost || 0)
      })
      .reduce((a: number, b: number) => a + b, 0)
  }

  @action.bound
  public isNum(str: any) {
    if (typeof str == 'number') return true
    if (typeof str != 'string') return false
    return !isNaN(+str) && !isNaN(parseFloat(str))
  }

  private markLineItemDeleted(
    lineDTO:
      | IProfessionalComponentLineItemDTO
      | ITechnicalComponentLineItemDTO
      | IAnesthesiaLineItemDTO
      | IMiscellaneousLineItemDTO,
    reconcile: boolean
  ) {
    if (lineDTO.markedDeleted) {
      lineDTO.cost = lineDTO.markedDeletedCost
      lineDTO.markedDeletedCost = 0
      lineDTO.markedDeleted = !lineDTO.markedDeleted
      if (reconcile) {
        lineDTO.reconciledCost = lineDTO.markExcludedCost
        lineDTO.reasonCode = lineDTO.markedDeletedReasonCode
        this.addReconciledClaimLine!(lineDTO.reconciledCMS1500LineItemId!)
      }
    } else {
      lineDTO.markedDeletedCost = lineDTO.cost
      lineDTO.cost = undefined
      lineDTO.markedDeleted = !lineDTO.markedDeleted
      if (reconcile) {
        lineDTO.markExcludedCost = lineDTO.reconciledCost
        lineDTO.markedDeletedReasonCode = lineDTO.reasonCode
        if (lineDTO.reconciledCost) {
          lineDTO.reconciledCost = undefined
          lineDTO.reasonCode = ''
        }
        this.removeReconciledClaimLine!(lineDTO.reconciledCMS1500LineItemId!)
      }
    }
  }

  @action.bound
  public deleteLineItem(
    property: QuoteLineItemType,
    index: number,
    reconcile: boolean,
    forceUpdateHandler: () => void
  ) {
    switch (property) {
      case QuoteLineItemType.PC:
        this.markLineItemDeleted(
          this.selectedQuote?.professionalComponentLineItems![index]!,
          reconcile
        )
        break
      case QuoteLineItemType.TC:
        this.markLineItemDeleted(
          this.selectedQuote?.technicalComponentLineItems![index]!,
          reconcile
        )
        break
      case QuoteLineItemType.AN:
        this.markLineItemDeleted(
          this.selectedQuote?.anesthesiaLineItems![index]!,
          reconcile
        )
        break
      case QuoteLineItemType.PT:
        if (this.selectedQuote?.physicalTherapyLineItems![index].markedDeleted) {
          this.selectedQuote!.physicalTherapyLineItems![index].cost =
            this.selectedQuote!.physicalTherapyLineItems![index].markedDeletedCost
          this.selectedQuote!.physicalTherapyLineItems![index].markedDeletedCost = 0
          this.selectedQuote!.physicalTherapyLineItems![index].markedDeleted =
            !this.selectedQuote!.physicalTherapyLineItems![index].markedDeleted
        } else {
          this.selectedQuote!.physicalTherapyLineItems![index].markedDeletedCost =
            this.selectedQuote!.physicalTherapyLineItems![index].cost
          this.selectedQuote!.physicalTherapyLineItems![index].cost = 0
          this.selectedQuote!.physicalTherapyLineItems![index].markedDeleted =
            !this.selectedQuote!.physicalTherapyLineItems![index].markedDeleted
        }
        break
      case QuoteLineItemType.MI:
        this.markLineItemDeleted(
          this.selectedQuote?.miscellaneousLineItems![index]!,
          reconcile
        )
        break
      default:
        break
    }
    forceUpdateHandler()
  }

  private markLineItemExcluded(
    lineDTO:
      | IProfessionalComponentLineItemDTO
      | ITechnicalComponentLineItemDTO
      | IAnesthesiaLineItemDTO
      | IMiscellaneousLineItemDTO
      | IPhysicalTherapyLineItemDTO
  ) {
    if (lineDTO.markExcluded) {
      lineDTO.reconciledCost = lineDTO.markExcludedCost
      lineDTO.markExcludedCost = 0
      lineDTO.markExcluded = !lineDTO.markExcluded
    } else {
      lineDTO.markExcludedCost = lineDTO.reconciledCost
      lineDTO.markExcluded = !lineDTO.markExcluded

      if (lineDTO.reconciledCost) {
        lineDTO.reconciledCost = 0
      }
    }
  }

  @action.bound
  public excludeLineItem(
    property: QuoteLineItemType,
    index: number,
    forceUpdateHandler: () => void
  ) {
    switch (property) {
      case QuoteLineItemType.PC:
        this.markLineItemExcluded(
          this.selectedQuote!.professionalComponentLineItems![index]!
        )
        break
      case QuoteLineItemType.TC:
        this.markLineItemExcluded(
          this.selectedQuote!.technicalComponentLineItems![index]!
        )
        break
      case QuoteLineItemType.AN:
        this.markLineItemExcluded(this.selectedQuote!.anesthesiaLineItems![index]!)
        break
      case QuoteLineItemType.PT:
        this.markLineItemExcluded(this.selectedQuote!.physicalTherapyLineItems![index]!)
        break
      case QuoteLineItemType.MI:
        this.markLineItemExcluded(this.selectedQuote!.miscellaneousLineItems![index]!)
        break
      default:
        break
    }
    forceUpdateHandler()
  }

  private clearReconciledProperties(
    lineDTO:
      | IProfessionalComponentLineItemDTO
      | ITechnicalComponentLineItemDTO
      | IAnesthesiaLineItemDTO
      | IMiscellaneousLineItemDTO
  ) {
    this.removeReconciledClaimLine!(lineDTO.reconciledCMS1500LineItemId!)
    lineDTO.reconciledCost = undefined
    lineDTO.reconciledCMS1500LineItemId = undefined
    lineDTO.reconciledActualCPT = undefined
    lineDTO.reconciledActualLocationGroupName = undefined
    lineDTO.reconciledActualCost = undefined
    lineDTO.reasonCode = undefined
  }

  @action.bound
  public clearReconcileLineItem(
    property: QuoteLineItemType,
    index: number,
    forceUpdateHandler: () => void
  ) {
    switch (property) {
      case QuoteLineItemType.PC:
        this.clearReconciledProperties(
          this.selectedQuote?.professionalComponentLineItems![index]!
        )
        break
      case QuoteLineItemType.TC:
        this.clearReconciledProperties(
          this.selectedQuote?.technicalComponentLineItems![index]!
        )
        break
      case QuoteLineItemType.AN:
        this.clearReconciledProperties(this.selectedQuote?.anesthesiaLineItems![index]!)
        break
      case QuoteLineItemType.MI:
        this.clearReconciledProperties(
          this.selectedQuote?.miscellaneousLineItems![index]!
        )
        break
      case QuoteLineItemType.PT:
        this.selectedQuote!.physicalTherapyLineItems![index].reconciledCost = undefined
      default:
        break
    }
    forceUpdateHandler()
  }

  @action.bound
  public getSubtotal(property: QuoteLineItemType) {
    var subtotal = 0

    if (this.selectedQuote) {
      switch (property) {
        case QuoteLineItemType.PC:
          this.selectedQuote
            .professionalComponentLineItems!.filter((x) => !x.markedDeleted)
            .forEach((x) => {
              if (x.cost) {
                subtotal += +x.cost
              }
            })
          break
        case QuoteLineItemType.TC:
          this.selectedQuote.technicalComponentLineItems!.forEach((x) => {
            if (x.cost) {
              subtotal += +x.cost
            }
          })
          break
        case QuoteLineItemType.AN:
          this.selectedQuote.anesthesiaLineItems!.forEach((x) => {
            if (x.cost) {
              subtotal += +x.cost
            }
          })
          break
        case QuoteLineItemType.PT:
          this.selectedQuote.physicalTherapyLineItems!.forEach((x) => {
            if (x.cost) {
              subtotal += +x.cost
            }
          })
          break
        case QuoteLineItemType.MI:
          this.selectedQuote.miscellaneousLineItems!.forEach((x) => {
            if (x.cost) {
              subtotal += +x.cost
            }
          })
          break
        default:
          break
      }
    }
    return formatCurrency(subtotal.toString())
  }

  @action.bound
  public renderLegend() {
    return (
      <Grid item xs={12}>
        <Typography align="right" style={{ paddingTop: '5px', paddingRight: '10px' }}>
          <>
            [<strong>LEGEND:&nbsp;&nbsp;</strong>
            <RemoveCircle color="error" />
            &nbsp;Remove&nbsp;&nbsp;
            <UndoRounded />
            &nbsp;Undo Remove
            <>
              &nbsp;&nbsp;
              <CallMerge color="primary" />
              &nbsp;Reconcile&nbsp;&nbsp;
              <CallSplit color="primary" />
              &nbsp;Un-reconcile&nbsp;&nbsp;
              <PlaylistRemove color="error" />
              &nbsp;Exclude&nbsp;&nbsp;
              <PlaylistAdd />
              &nbsp;Include&nbsp;&nbsp;
            </>
            ]
          </>
        </Typography>
      </Grid>
    )
  }
  @action.bound
  public renderClaimButtons(section: string) {
    return this.selectedQuote?.claimButtons ? (
      this.selectedQuote!.claimButtons!.map((element: DocumentButtonSummaryResult) =>
        element.type == section ? (
          <QuoteClaimButton element={element} selectedQuote={this.selectedQuote} />
        ) : (
          <></>
        )
      )
    ) : (
      <></>
    )
  }
}
