import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  WithStyles,
  withStyles,
  Button,
  Typography,
  Tooltip,
  TextField,
  CircularProgress,
  InputLabel,
  Select,
  OutlinedInput,
} from '@material-ui/core'
import ReactPaginate from 'react-paginate'
import {
  AddShoppingCart,
  InfoOutlined,
  InsertDriveFileOutlined,
  Receipt,
  ReceiptOutlined,
  RemoveShoppingCart,
  Warning,
} from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import SelectInput from '../common/SelectInput/SelectInput'
import { ICMS1500 } from '../Definitions'
import { IStores } from '../Stores'
import { BillOutSearchRequest } from '../viewModels/BillOutSearchRequest'
import BillOutDialog from './BillOutDialog'
import { searchBillOuts } from 'src/services/BillOutService'
import { IOrganization } from '../Definitions'
import { formatCurrency, formatDate } from '../utils/Formatter'
import '../App.css'
import '../Grid.css'
import { pagedList } from 'src/viewModels/pagedList'
import { BillOutSummaryResult } from 'src/viewModels/BillOutSummaryResult'
import BundleClaimsModal from 'src/quotes/QuoteBills/BundleClaimsModal'
import { Helmet } from 'react-helmet'
import AppealNameConstants from 'src/constants/AppealNameConstants'
import AppealLogModal from 'src/search/AppealLogModal'
import moment from 'moment'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import { GetComponentOptions } from 'src/services/LookupService'
import { QuoteOption } from 'src/utils/QuoteOption'
import ViewProviderGroupContractModal from 'src/ReceiveClaim/ViewProviderGroupContractModal'
import { GetClaimReadyForBilloutInbox } from 'src/services/WorkflowService'

const styles = ({}) =>
  createStyles({
    warning: {
      color: '#ffc107',
    },
  })

interface IBillOutProps extends WithStyles<typeof styles> {
  showBilledOut?: boolean
  showPaid?: boolean
  typeFilter?: string
  dataTableManager?: IDataTableManager<ICMS1500>
  isOpen?: boolean
  getColumnSettingsAndBillOutCMS1500?: () => void
  pageSize: number
  loadCMS1500: (loadCMS1500: string) => void
  setCanSwitchOrganizations?: (canSwitch: boolean) => void
  setShowBilledOut?: (showBilledOout: boolean) => void
  setShowPaid?: (showPaid: boolean) => void
  setTypeFilter?: (type?: string) => void
  setUpdateParentStateAfterModal: (parentfunction?: () => void) => void
  currentAppOrganization: IOrganization
  openBillsDialogById: (
    eocId: string,
    quoteId: string,
    altTitle?: string
  ) => Promise<void>
  getHeaderInfo: (billoutId: string) => void
  openAppealLogModal?: (claimId: string) => void
  closeAppealLogModal?: () => void
  isAppealLogModalOpen?: boolean
  setBillOutRequest?: (billOutSearchRequest: BillOutSearchRequest) => void
  billOutRequest?: BillOutSearchRequest
  insuranceList?: DropdownOption[]
  getInsuranceDropdown?: () => void
  showContractModal?: (providerId: string) => void
  rerouteToPath?: (path: string) => void
}

class BillOut extends React.Component<IBillOutProps> {
  public async componentDidMount() {
    var createInboxId = await GetClaimReadyForBilloutInbox()

    if (createInboxId) {
      this.setState({ createInboxId: createInboxId })
      this.props.setCanSwitchOrganizations!(true)
      this.props.setUpdateParentStateAfterModal(this.getBillOutData)
      this.props.getInsuranceDropdown!()
      var components = await GetComponentOptions(false, true)
      this.setState({ componentList: components })
      this.getBillOutData()
    }
  }

  public componentWillUnmount() {
    this.props.setCanSwitchOrganizations!(false)
  }

  public state = {
    gridData: new pagedList().items as BillOutSummaryResult[],
    gridLoading: true,
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 50,
    selectedCMS1500Id: '',
    selectedGroupInfo: '',
    searchBillOutDateStart: this.props.billOutRequest?.startDate
      ? moment(this.props.billOutRequest?.startDate).format('yyyy-MM-DD')
      : moment(new Date()).subtract(12, 'months').format('yyyy-MM-DD'),
    searchBillOutDateEnd: this.props.billOutRequest?.endDate
      ? moment(this.props.billOutRequest?.endDate).format('yyyy-MM-DD')
      : moment(new Date()).format('yyyy-MM-DD'),
    searchEocLegacyId: this.props.billOutRequest?.eocGeneratedOrLegacyId ?? '',
    searchComponentId: this.props.billOutRequest?.componentId ?? '',
    searchInsuranceId: this.props.billOutRequest?.insuranceId ?? '',
    searchOnlyQuotes: this.props.billOutRequest?.quoteOption ?? QuoteOption.ShowAll,
    searchProviderGroup: this.props.billOutRequest?.providerGroupName ?? '',
    componentList: new Array<DropdownOption>(),
    createInboxId: undefined,
  }

  // @ts-ignore
  private filters() {
    const { typeFilter } = this.props
    const value = typeFilter || ''
    return (
      <>
        <Grid container direction="column">
          <Grid item>
            <SelectInput
              name="typeFilter"
              inputId="typeFilter"
              label="Type"
              fullWidth
              multiple={false}
              native={false}
              value={value}
              items={['', 'Non-Surgical', 'Surgical +90']}
              getName={this.getName}
              getValue={this.getName}
              onChange={this.onTypeFilterChange}
            />
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <FormControlLabel
                label="Show Billed Out"
                labelPlacement="end"
                control={
                  <Checkbox
                    checked={this.props.showBilledOut!}
                    onChange={this.onShowBilledOutFilterChanged}
                    inputProps={{
                      id: 'show-billed-out-filter',
                    }}
                    value="showBilledOut"
                    name="showBilledOut"
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <FormControlLabel
                label="Show Paid"
                labelPlacement="end"
                control={
                  <Checkbox
                    checked={this.props.showPaid}
                    onChange={this.onShowPaidFilterChanged}
                    inputProps={{
                      id: 'show-paid-filter',
                    }}
                    value="showPaid"
                    name="showPaid"
                  />
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </>
    )
  }

  private getName = (s: string) => {
    return s
  }

  private onTypeFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const typeFilter = event.target.value === '' ? undefined : event.target.value
    this.props.setTypeFilter!(typeFilter)
  }

  private onShowBilledOutFilterChanged = (_event: React.ChangeEvent, value: boolean) => {
    this.props.setShowBilledOut!(value)
  }

  private onShowPaidFilterChanged = (_event: React.ChangeEvent, value: boolean) => {
    this.props.setShowPaid!(value)
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }
  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={12} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((billOut, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.openBilloutItem(billOut.cms1500Id!)}
            >
              Open
            </Button>
          </TableCell>
          <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
            <div style={{ padding: '0px 0px 10px' }}>
              {formatDate(billOut.claimReceivedDate)}
            </div>
          </TableCell>
          <TableCell style={{ padding: '0px' }}>
            {billOut.actualDbStatus?.includes('Paid') &&
            !billOut.actualDbStatus.includes('Holding') ? (
              <Warning style={{ visibility: 'hidden' }} />
            ) : (
              <Tooltip title="Claim has not been paid">
                <Warning className={this.props.classes.warning} />
              </Tooltip>
            )}
          </TableCell>
          <TableCell style={{ whiteSpace: 'nowrap' }}>
            {billOut.groupNumber}&nbsp;&nbsp;
            {billOut.appealType != undefined ? (
              <Tooltip title={AppealNameConstants[billOut.appealType]}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
            {billOut.cms1500Id &&
            billOut.lastAppealDate &&
            billOut.appealType == undefined ? (
              <Tooltip title={'Show Appeal Details'}>
                <ReceiptOutlined
                  style={{ cursor: 'pointer' }}
                  fontSize="small"
                  onClick={() => {
                    this.props.openAppealLogModal!(billOut.cms1500Id!)
                    this.setState({ selectedCMS1500Id: billOut.cms1500Id })
                  }}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell>{billOut.patientName}</TableCell>
          <TableCell align="right">{formatDate(billOut.dateOfService)}</TableCell>
          <TableCell>{billOut.component}</TableCell>
          <TableCell style={{ minWidth: 200 }}>
            {billOut.providerGroup}&nbsp;
            <Tooltip title="View Provider Contract Summary">
              <InsertDriveFileOutlined
                style={{ cursor: 'pointer', fontSize: '16px' }}
                onClick={
                  billOut.providerId
                    ? () => {
                        this.props.showContractModal!(billOut.providerId!)
                      }
                    : () => {}
                }
              />
            </Tooltip>
          </TableCell>
          <TableCell>{billOut.eocStatus}</TableCell>
          <TableCell>{billOut.bundleTypeDisplay}</TableCell>
          <TableCell align="right">{formatCurrency(billOut.billedAmount)}</TableCell>
          <TableCell>
            {billOut.quoteId ? (
              <Tooltip title={billOut.alertText ?? 'Bundle Claims'}>
                {billOut.alertText ? (
                  <RemoveShoppingCart
                    style={{ cursor: 'pointer', color: 'red' }}
                    onClick={() => {
                      this.setState({ gridLoading: true })
                      this.props
                        .openBillsDialogById(
                          billOut.episodeOfCareId!,
                          billOut.quoteId!,
                          billOut.groupNumber
                        )
                        .then(() => this.setState({ gridLoading: false }))
                    }}
                  ></RemoveShoppingCart>
                ) : (
                  <AddShoppingCart
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.setState({ gridLoading: true }, () => {
                        this.props
                          .openBillsDialogById(
                            billOut.episodeOfCareId!,
                            billOut.quoteId!,
                            billOut.groupNumber
                          )
                          .then(() => this.setState({ gridLoading: false }))
                      })
                    }}
                  ></AddShoppingCart>
                )}
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
        </TableRow>
      ))
    }
  }

  private search = (event: any) => {
    this.setState({ page: 1 }, () => this.getBillOutData(true))
    event.preventDefault()
  }

  private clearSearch = () => {
    var start = document.getElementById('startDate') as HTMLInputElement
    if (start != undefined) {
      start.value = ''
    }
    var end = document.getElementById('endDate') as HTMLInputElement
    if (end != undefined) {
      end.value = ''
    }
    var select_box = document.getElementById('companySelect') as HTMLSelectElement
    if (select_box != undefined) {
      select_box.selectedIndex = 0
    }
    var select_box = document.getElementById('componentSelect') as HTMLSelectElement
    if (select_box != undefined) {
      select_box.selectedIndex = 0
    }
    this.setState(
      {
        searchEocLegacyId: '',
        searchBillOutDateStart: undefined,
        searchBillOutDateEnd: undefined,
        searchComponentId: '',
        searchInsuranceId: '',
        searchOnlyQuotes: QuoteOption.ShowAll,
        searchProviderGroup: '',
      },
      () => {
        this.setState({ gridData: undefined, pageCount: 0, totalItems: 0 })
        this.props.setBillOutRequest!(new BillOutSearchRequest())
      }
    )
  }

  private handleIdChange = (event: any) => {
    this.setState({ searchEocLegacyId: event.target.value })
  }
  private handleStartDateChange = (event: any) => {
    this.setState({ searchBillOutDateStart: event.target.value })
  }
  private handleEndDateChange = (event: any) => {
    this.setState({ searchBillOutDateEnd: event.target.value })
  }

  private setSelectedQuoteOption = (event: any) => {
    this.setState({ searchOnlyQuotes: event.target.value })
  }

  private setSelectedCompany = (event: any) => {
    this.setState({ searchInsuranceId: event.target.value })
  }

  private setSelectedComponent = (event: any) => {
    this.setState({ searchComponentId: event.target.value })
  }

  private handleProviderGroupChange = (event: any) => {
    this.setState({ searchProviderGroup: event.target.value })
  }

  private getBillOutData = async (savedSearch: boolean = false) => {
    this.setState({ gridLoading: true })
    const { page, pageSize } = this.state
    const requestData = new BillOutSearchRequest()
    requestData.page = page
    requestData.pageSize = pageSize
    requestData.organizationId = this.props.currentAppOrganization.id
    requestData.startDate = this.state.searchBillOutDateStart
    requestData.endDate = this.state.searchBillOutDateEnd
    requestData.eocGeneratedOrLegacyId = this.state.searchEocLegacyId
    requestData.componentId = this.state.searchComponentId
    requestData.insuranceId = this.state.searchInsuranceId
    requestData.quoteOption = this.state.searchOnlyQuotes
    requestData.providerGroupName = this.state.searchProviderGroup
    await searchBillOuts(requestData).then(async (response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        gridLoading: false,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
      if (savedSearch) {
        this.props.setBillOutRequest!(requestData)
      }
    })
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getBillOutData())
  }

  private openBilloutItem = (associatedItemId: string) => {
    this.props.rerouteToPath!(
      `/workflow/billout/${associatedItemId}/billout-search/${this.state.createInboxId}`
    )
  }

  public render() {
    const { isOpen } = this.props
    const billOutDialog = isOpen && (
      <BillOutDialog groupInfo={this.state.selectedGroupInfo} />
    )
    return (
      <>
        <Helmet>
          <title>Bill Out</title>
        </Helmet>
        <Grid container style={{ width: '100%', paddingBottom: '20px' }}>
          {billOutDialog}
          <ViewProviderGroupContractModal />
          <BundleClaimsModal />
          {this.state.selectedCMS1500Id && this.props.isAppealLogModalOpen ? (
            <AppealLogModal
              claimId={this.state.selectedCMS1500Id}
              isOpen={this.props.isAppealLogModalOpen}
              closeModal={this.props.closeAppealLogModal!}
              groupInfo={this.state.selectedGroupInfo}
            />
          ) : (
            <></>
          )}
          <ResponsiveAppBar title={'Bill Out'} pageIcon={<Receipt />} />
          {this.state.gridLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '2',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <form>
            <Grid
              container
              style={{ padding: '10px 10px 10px 30px', width: '100%' }}
              item
              md={12}
            >
              <Typography
                color="primary"
                component="h6"
                variant="h6"
                gutterBottom
                style={{ paddingBottom: '10px' }}
              >
                SEARCH CRITERIA
              </Typography>
              <Grid container direction="row" spacing={2} style={{ width: '100%' }}>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      inputProps={{ id: 'startDate' }}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleStartDateChange}
                      type="date"
                      name="startDate"
                      variant="outlined"
                      defaultValue={this.state.searchBillOutDateStart}
                      label="Claim Rcv Start Date"
                    />
                  </FormControl>
                </Grid>
                <Grid style={{ margin: '10px 0px' }}>
                  <strong> - </strong>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      inputProps={{ id: 'endDate' }}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleEndDateChange}
                      type="date"
                      name="endDate"
                      variant="outlined"
                      defaultValue={this.state.searchBillOutDateEnd}
                      label="Claim Rcv End Date"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl style={{ width: '100%' }} variant="outlined">
                    <InputLabel shrink={true} variant="outlined">
                      Insurance Company
                    </InputLabel>
                    <Select
                      variant="outlined"
                      input={<OutlinedInput notched labelWidth={145} />}
                      inputProps={{ label: true, notched: true }}
                      native={true}
                      fullWidth
                      onChange={this.setSelectedCompany}
                      id="companySelect"
                      value={this.state.searchInsuranceId}
                      disabled={
                        this.props.insuranceList == undefined ||
                        this.props.insuranceList?.length == 0
                      }
                    >
                      <option value="">Select a company</option>
                      {this.props.insuranceList?.map((company) => (
                        <option value={company.id}>{company.description}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl style={{ width: '100%' }} variant="outlined">
                    <InputLabel shrink={true} variant="outlined">
                      Component
                    </InputLabel>
                    <Select
                      variant="outlined"
                      input={<OutlinedInput notched labelWidth={85} />}
                      inputProps={{ label: true, notched: true }}
                      native={true}
                      fullWidth
                      onChange={this.setSelectedComponent}
                      id="componentSelect"
                      value={this.state.searchComponentId}
                      disabled={
                        this.state.componentList == undefined ||
                        this.state.componentList?.length == 0
                      }
                    >
                      <option value="">Select a component</option>
                      {this.state.componentList?.map((component) => (
                        <option value={component.id}>{component.description}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchEocLegacyId}
                      onChange={this.handleIdChange}
                      label="EOC/Legacy"
                      name="eocLegacyId"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchProviderGroup}
                      onChange={this.handleProviderGroupChange}
                      label="Provider Group"
                      name="providerGroupName"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl style={{ width: '100%' }} variant="outlined">
                    <InputLabel shrink={true} variant="outlined">
                      Quote
                    </InputLabel>
                    <Select
                      variant="outlined"
                      input={<OutlinedInput notched labelWidth={45} />}
                      inputProps={{ label: true, notched: true }}
                      native={true}
                      fullWidth
                      onChange={this.setSelectedQuoteOption}
                      id="quoteSelect"
                      value={this.state.searchOnlyQuotes}
                    >
                      <option value={QuoteOption.ShowAll}>Show All</option>
                      <option value={QuoteOption.With}>With Quote</option>
                      <option value={QuoteOption.Without}>Without Quote</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{
                    marginLeft: '8px',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                    width: '100%',
                  }}
                >
                  <Button
                    type="submit"
                    onClick={this.search}
                    color="primary"
                    size="medium"
                    variant="contained"
                    style={{ marginRight: '10px' }}
                  >
                    Search
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.clearSearch}
                    size="medium"
                    variant="contained"
                  >
                    Clear Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Grid item md={12}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="gridPadding">
                  <TableCell></TableCell>
                  <TableCell align="right">Claim Rcv</TableCell>
                  <TableCell></TableCell>
                  <TableCell>EOC/Legacy</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell align="right">DOS</TableCell>
                  <TableCell>Event Component</TableCell>
                  <TableCell>Provider Group</TableCell>
                  <TableCell>EOC Status</TableCell>
                  <TableCell>Bundle Type</TableCell>
                  <TableCell align="right">Claim Amt</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '100%' }}>{this.mapGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                forcePage={this.state.page - 1}
                pageCount={this.state.pageCount}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}

const InjectedBillOut = inject<IStores, IBillOutProps, Partial<IBillOutProps>, any>(
  (stores: IStores) => ({
    dataTableManager: stores.billOutStore.dataTableStore,
    getColumnSettingsAndBillOutCMS1500:
      stores.billOutStore.getColumnSettingsAndBillOutCMS1500,
    isOpen: stores.billOutStore.isOpen,
    loadCMS1500: stores.billOutStore.loadCMS1500,
    currentAppOrganization: stores.global.currentAppOrganization,
    pageSize: stores.billOutStore.pageSize,
    setCanSwitchOrganizations: stores.global.setCanSwitchOrganizations,
    setShowBilledOut: stores.billOutStore.setShowBilledOut,
    setShowPaid: stores.billOutStore.setShowPaid,
    setTypeFilter: stores.billOutStore.setTypeFilter,
    setUpdateParentStateAfterModal: stores.billOutStore.setParentUpdateFunction,
    showBilledOut: stores.billOutStore.showBilledOut,
    showPaid: stores.billOutStore.showPaid,
    typeFilter: stores.billOutStore.typeFilter,
    openBillsDialogById: stores.quotes.openBillsDialogById,
    getHeaderInfo: stores.patients.getHeaderInfo,
    openAppealLogModal: stores.cms1500.OpenAppealLogModal,
    closeAppealLogModal: stores.cms1500.closeAppealLogModal,
    isAppealLogModalOpen: stores.cms1500.isAppealLogModalOpen,
    setBillOutRequest: stores.global.setBillOutRequest,
    billOutRequest: stores.global.billOutRequest,
    insuranceList: stores.billOutStore.insuranceList,
    getInsuranceDropdown: stores.billOutStore.getInsuranceDropdown,
    showContractModal: stores.cms1500.openProviderGroupContractDialogById,
    rerouteToPath: stores.global.rerouteToPath,
  })
)(BillOut)

export default withStyles(styles)(InjectedBillOut)
