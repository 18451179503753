import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultContactType, IContactType } from '../../Definitions'
import {
  Client,
  ContactTypeDto,
  IContactTypeDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'ContactTypeDto'

export default class ContactTypesStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public contactTypes: IContactType[]
  @observable
  public selectedContactType?: IContactType
  public dataTableStore: DataTableStore<IContactTypeDto, IContactType>

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IContactTypeDto, IContactType>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllContactTypes(filter, page, undefined, orderBy, includeInactives),
      (contactType) => this.setupContactTypes(contactType)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedContactType = undefined
  }

  @action.bound
  public disableContactType(contactType: IContactType) {
    this.isLoading = true
    return this.client
      .archiveContactType(contactType.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndContactTypes() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllContactTypes(includeInactives: boolean) {
    this.isLoading = true
    return this.client
      .getAllContactTypes('', undefined, undefined, undefined, includeInactives)
      .then((resp: SwaggerResponse<IContactTypeDto[]>) =>
        runInAction(() => {
          this.contactTypes = this.setupContactTypes(resp.result)
        })
      )
  }

  @action.bound
  public openDialogWithContactType(contactType: IContactType) {
    this.isModalOpen = true
    this.selectedContactType = contactType
  }

  @action.bound
  public recoverContactType(contactType: IContactType) {
    this.isLoading = true
    return this.client
      .reactivateContactType(contactType.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveContactType(contactType: IContactType) {
    this.isLoading = true
    let promise
    const contactTypeDto = new ContactTypeDto(contactType)
    if (contactType.isNew) {
      promise = this.client.createContactType(contactTypeDto)
    } else {
      promise = this.client.updateContactType(contactType.id, contactTypeDto)
    }
    return promise
      .then(() => {
        this.resetUIState(contactType.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupContactTypes = (contactTypes: IContactTypeDto[]) => {
    return contactTypes.map((x) =>
      this.setupContactTypeMenuItems(this.addDefaultFields(x))
    )
  }

  private addDefaultFields = (contactType: IContactTypeDto): IContactType => {
    return { ...DefaultContactType(), ...contactType }
  }

  private setupContactTypeMenuItems = (contactType: IContactType) => {
    contactType.menuItems = [
      {
        icon: Edit,
        name: 'Edit',
        onClick: () => this.openDialogWithContactType(contactType),
      },
    ]
    if (contactType.isActive) {
      contactType.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableContactType(contactType),
      })
    } else {
      contactType.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverContactType(contactType),
      })
    }
    return contactType
  }
}
