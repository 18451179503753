import { Avatar, Menu, MenuItem } from '@material-ui/core'
import * as React from 'react'

import { inject } from 'mobx-react'
import { IUser } from '../../Definitions'
import { IStores } from '../../Stores'
import ChangePasswordDialog from '../../users/ChangePasswordDialog'
import UserDialog from '../../users/UserDialog'
import ExpandableListItem from '../ExpandableListItem'

interface IProfileListItemProps {
  currentUser?: IUser
  expanded: boolean
  isChangePasswordModalOpen?: boolean
  isModalOpen?: boolean
  logout: () => void
  openChangePasswordDialog?: () => void
  openDialogWithUser?: (user: IUser) => void
  canSwitchOrganizations?: boolean
  switchOrganization?: () => void
}

interface IProfileListItemState {
  anchorEl?: HTMLElement
  exitedMethod?: () => void
  isMenuOpen: boolean
}

class ProfileListItem extends React.Component<
  IProfileListItemProps,
  IProfileListItemState
> {
  public state = {
    anchorEl: undefined,
    exitedMethod: undefined,
    isMenuOpen: false,
  }

  public render() {
    const { currentUser, expanded, isChangePasswordModalOpen, isModalOpen } = this.props
    const { anchorEl, isMenuOpen } = this.state
    const changePasswordDialog = isChangePasswordModalOpen && <ChangePasswordDialog />
    const userDialog = isModalOpen && <UserDialog />
    return (
      <>
        <ExpandableListItem
          key={'profile'}
          expanded={expanded}
          icon={<Avatar src={currentUser!.profileImageUrl} />}
          text={currentUser!.name}
          onClick={this.onClick}
        />
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={isMenuOpen}
          getContentAnchorEl={null}
          onClose={this.closeAndSetExitedMethod()}
          onExited={this.state.exitedMethod}
        >
          {this.props.canSwitchOrganizations &&
          (process.env.REACT_APP_OBJECTIVE_DIAGNOSTIC_AVAILABLE == 'true' || false) ? (
            <MenuItem
              key={'switchMode'}
              onClick={this.closeAndSetExitedMethod(this.props.switchOrganization)}
            >
              Toggle Organization
            </MenuItem>
          ) : (
            <></>
          )}

          <MenuItem
            key={'changePassword'}
            onClick={this.closeAndSetExitedMethod(this.openChangePassword)}
          >
            Change Password
          </MenuItem>
          <MenuItem
            key={'myProfile'}
            onClick={this.closeAndSetExitedMethod(this.openMyProfile)}
          >
            My Profile
          </MenuItem>
          <MenuItem key={'logout'} onClick={this.closeAndSetExitedMethod(this.logout)}>
            Logout
          </MenuItem>
        </Menu>
        {changePasswordDialog}
        {userDialog}
      </>
    )
  }

  private closeAndSetExitedMethod = (method?: () => void) => () => {
    this.setState({
      anchorEl: undefined,
      exitedMethod: method,
      isMenuOpen: false,
    })
  }

  private openChangePassword = () => {
    this.props.openChangePasswordDialog!()
  }

  private openMyProfile = () => {
    this.props.openDialogWithUser!(this.props.currentUser!)
  }

  private logout = () => {
    this.props.logout()
  }

  private onClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget, isMenuOpen: true })
  }
}

const InjectedProfileListItem = inject<
  IStores,
  IProfileListItemProps,
  Partial<IProfileListItemProps>,
  any
>((stores: IStores) => ({
  canSwitchOrganizations: stores.global.canSwitchOrganizations,
  currentAppOrganization: stores.global.currentAppOrganization,
  currentUser: stores.global.user,
  getOrganizationsMenu: () => stores.organizations.getOrganizationsMenu(),
  isChangePasswordModalOpen: stores.users.isChangePasswordModalOpen,
  isModalOpen: stores.users.isModalOpen,
  openChangePasswordDialog: stores.users.openChangePasswordDialog,
  openDialogWithUser: stores.users.openDialogWithUser,
  switchOrganization: () => stores.organizations.switchOrganization(),
}))(ProfileListItem)

export default InjectedProfileListItem
export { IProfileListItemProps }
