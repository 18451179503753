export interface IGlobalInvoiceLineItemDTO {
  id?: string
  createdBy?: string
  createdDate?: Date
  globalInvoiceId?: string
  charge?: number
  UCCharge?: number
  providerId?: string
  providerName?: string
  physicianName?: string
  legacyLocationId?: string
  legacyInvoiceText?: string
  lineDescription?: string
  lineNumber?: number
  invoiceLineType?: string
  miscItemType?: string

  hasInitialEvaluation?: boolean
  hasSurgeonFees?: boolean
  hasAssistantSurgeonFees?: boolean
  hasPostOppSurgeon90Days?: boolean
  hasPostOppSurgeon10Days?: boolean
  hasPermanentImpairment?: boolean
  hasFacilityFees?: boolean
  hasHardwareImplants?: boolean
  hasInpatientStay?: boolean
  hasAnesthesia?: boolean
  hasPTThrough90DaysPostOpp?: boolean
  hasPTThrough10DaysPostOpp?: boolean
  hasPTHomeOrSingleAppt?: boolean
}

export class GlobalInvoiceLineItemDTO implements IGlobalInvoiceLineItemDTO {
  id?: string
  createdBy?: string
  createdDate?: Date
  globalInvoiceId?: string
  charge?: number
  UCCharge?: number
  providerId?: string
  providerName?: string
  physicianName?: string
  legacyLocationId?: string
  legacyInvoiceText?: string
  lineDescription?: string //For misc and pt's visit count
  lineNumber?: number
  invoiceLineType?: string
  miscItemType?: string

  hasInitialEvaluation?: boolean
  hasSurgeonFees?: boolean
  hasAssistantSurgeonFees?: boolean
  hasPostOppSurgeon90Days?: boolean
  hasPostOppSurgeon10Days?: boolean
  hasPermanentImpairment?: boolean
  hasFacilityFees?: boolean
  hasHardwareImplants?: boolean
  hasInpatientStay?: boolean
  hasAnesthesia?: boolean
  hasPTThrough90DaysPostOpp?: boolean
  hasPTThrough10DaysPostOpp?: boolean
  hasPTHomeOrSingleAppt?: boolean
  hasPTWorkConditioning?: boolean
  newLineItem?: boolean

  constructor(
    id?: string,
    createdBy?: string,
    createdDate?: Date,
    globalInvoiceId?: string,
    charge?: number,
    UCCharge?: number,
    providerId?: string,
    providerName?: string,
    physicianName?: string,
    legacyLocationId?: string,
    legacyInvoiceText?: string,
    lineDescription?: string,
    lineNumber?: number,
    invoiceLineType?: string,
    miscItemType?: string,

    hasInitialEvaluation?: boolean,
    hasSurgeonFees?: boolean,
    hasAssistantSurgeonFees?: boolean,
    hasPostOppSurgeon90Days?: boolean,
    hasPostOppSurgeon10Days?: boolean,
    hasPermanentImpairment?: boolean,
    hasFacilityFees?: boolean,
    hasHardwareImplants?: boolean,
    hasInpatientStay?: boolean,
    hasAnesthesia?: boolean,
    hasPTThrough90DaysPostOpp?: boolean,
    hasPTThrough10DaysPostOpp?: boolean,
    hasPTHomeOrSingleAppt?: boolean,
    hasPTWorkConditioning?: boolean,
    newLineItem?: boolean
  ) {
    this.id = id
    this.createdBy = createdBy
    this.createdDate = createdDate
    this.globalInvoiceId = globalInvoiceId
    this.charge = charge
    this.UCCharge = UCCharge
    this.providerId = providerId
    this.providerName = providerName
    this.physicianName = physicianName
    this.legacyLocationId = legacyLocationId
    this.legacyInvoiceText = legacyInvoiceText
    this.lineDescription = lineDescription
    this.lineNumber = lineNumber
    this.invoiceLineType = invoiceLineType
    this.miscItemType = miscItemType
    this.hasInitialEvaluation = hasInitialEvaluation
    this.hasSurgeonFees = hasSurgeonFees
    this.hasAssistantSurgeonFees = hasAssistantSurgeonFees
    this.hasPostOppSurgeon90Days = hasPostOppSurgeon90Days
    this.hasPostOppSurgeon10Days = hasPostOppSurgeon10Days
    this.hasPermanentImpairment = hasPermanentImpairment
    this.hasFacilityFees = hasFacilityFees
    this.hasHardwareImplants = hasHardwareImplants
    this.hasInpatientStay = hasInpatientStay
    this.hasAnesthesia = hasAnesthesia
    this.hasPTThrough90DaysPostOpp = hasPTThrough90DaysPostOpp
    this.hasPTThrough10DaysPostOpp = hasPTThrough10DaysPostOpp
    this.hasPTHomeOrSingleAppt = hasPTHomeOrSingleAppt
    this.hasPTWorkConditioning = hasPTWorkConditioning
    this.newLineItem = newLineItem
  }
}
