import React from 'react'
import MaskedInput from 'react-text-mask'

const NumberMaskedInput = (props: any) => {
  const { inputRef, numberOfDigits, currency, ...other } = props
  const mask = []
  for (let i = 0; i < numberOfDigits; i++) {
    if (currency) {
      mask.push(/(\d|\.)/)
    } else {
      mask.push(/\d/)
    }
  }

  return <MaskedInput {...other} ref={inputRef} mask={mask} />
}

export default NumberMaskedInput
