import { debounce } from 'lodash'
import { action, computed, observable, runInAction } from 'mobx'
import { IColumnSetting } from '../../common/ESDataTable'
import IDataTableManager from '../../common/IDataTableManager'
import ITableOptions from '../../common/ITableOptions'
import { ColumnSettingsDto, SwaggerResponse } from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'

class DataTableStore<TDto, KEntity> implements IDataTableManager<KEntity> {
  @observable
  public columns: IColumnSetting[] = []
  @observable
  public count: number = 0
  @observable
  public data: KEntity[] = []
  @observable
  public isLoading: boolean = false
  @observable
  public tableOptions: ITableOptions = {
    filter: '',
    includeInactives: false,
    page: 1,
  }

  @computed
  public get orderBy(): string {
    return this.tableOptions.orderBy || ''
  }

  private debouncedLoadData = debounce(this.loadData, 1000)

  constructor(
    protected globalStore: GlobalStore,
    private getData: (options: ITableOptions) => Promise<SwaggerResponse<TDto[]>>,
    private mapData: (data: TDto[]) => KEntity[]
  ) {}

  @action.bound
  public setColumns(columns: ColumnSettingsDto[]) {
    this.columns = columns
  }

  @action.bound
  public setTableOrderBy(orderBy?: string) {
    this.tableOptions.orderBy = orderBy
  }

  @action.bound
  public updateColumnSettings(columns: IColumnSetting[]) {
    this.setColumns(columns)
  }

  @action.bound
  public updateTableOptions(tableOptions: ITableOptions) {
    this.tableOptions = tableOptions
    this.debouncedLoadData()
  }

  @action.bound
  public loadData() {
    this.isLoading = true
    return this.getData(this.tableOptions)
      .then((resp: SwaggerResponse<TDto[]>) => {
        runInAction(() => {
          this.count = this.getTotalCountFromHeader(resp)
          this.data = this.mapData(resp.result)
        })
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public clearData() {
    this.count = 0
    this.data = []
    this.columns = []
  }

  @action.bound
  public clearFilter() {
    this.tableOptions.filter = ''
  }

  private getTotalCountFromHeader(resp: SwaggerResponse<TDto[]>): number {
    const header = JSON.parse(resp.headers['x-pagination'] || '{"TotalCount": 0}')
    return header.TotalCount
  }
}

export default DataTableStore
