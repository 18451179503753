import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core'
import {
  InsertDriveFileOutlined,
  LocalAtmRounded,
  ReceiptOutlined,
  Warning,
} from '@material-ui/icons'
import {
  ErrorMessage,
  FieldArray,
  Form,
  Formik,
  FormikActions,
  FormikProps,
} from 'formik'
import { inject } from 'mobx-react'
import React from 'react'
import * as Yup from 'yup'
import Moment from 'moment'
import { IPaymentLineItem } from 'src/viewModels/PaymentLIneItem'
import FullWidthField from 'src/common/FullWidthField'
import NumberMaskedInput from 'src/common/NumberMaskedInput'
import { ClaimHeaderDetailsSummaryResult } from 'src/viewModels/ClaimHeaderDetailsSummaryResult'
import { formatDate } from 'src/utils/Formatter'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { ICMS1500, IUser } from 'src/Definitions'
import {
  IClaimFormValues,
  createIClaimFormValues,
  setValuesFromDiagnosis,
  submitBillOutWorkflowCustomFormValues,
} from 'src/ReceiveClaim/ClaimFormValues'
import BillOutNotesModal from 'src/billOut/BillOutNotesModal'
import { IStores } from 'src/Stores'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import { ObjectiveProcessAction } from 'src/viewModels/workflow/ObjectiveProcessAction'
import { processDynamicAction } from 'src/services/WorkflowService'
import { WorkflowInboxItemHeader } from 'src/viewModels/workflow/InboxItemHeader'
import { previewCMS1500InWorkflow } from 'src/services/BillOutService'
import { CMS1500DiagnosisDto } from 'src/viewModels/claim/CMS1500DiagnosisDto'
import { CMS1500Dto } from 'src/viewModels/claim/Cms1500Dto'
import LineItemBilledOutModal from 'src/billOut/LineItemBilledOutModal'

const CurrencyFormat: any = require('react-currency-format')

const CreateBilloutModalSchema = Yup.object().shape({
  q24: Yup.array().of(
    Yup.object({
      billOutAmount: Yup.number()
        .required('Required')
        .typeError('Bill Out Amount must be a number'),
      dProcedureCptHcpcs: Yup.string().required('Required'),
    })
  ),
  q21Diagnosis: Yup.array()
    .of(
      Yup.object().shape({
        code: Yup.string(),
      })
    )
    .test('at-least-one', 'At least one diagnosis required for completion.', (items) => {
      return items.some((item: { code: any }) => item.code.match(/^(.*)?\S+(.*)?$/))
    }),
})

interface ICreateBilloutModalProps {
  getBilloutDetailSummary?: (cms1500Id: string) => Promise<void>
  patientHeader?: ClaimHeaderDetailsSummaryResult
  getHeaderInfo?: (cms1500Id: string) => Promise<void>
  diagnosisCode?: CMS1500DiagnosisDto[]
  lineItemDetails?: IPaymentLineItem[]
  createOutgoingCMS1500?: (cms1500: ICMS1500) => Promise<void>
  setIsCMS1500Saved?: (isSaved: boolean) => void
  isCMS1500Saved?: boolean
  openBillOutNotesModal?: () => void
  closeModal: () => void
  isOpen: boolean
  inboxItemId: number
  inboxId: string
  associatedItemId: string
  action: InboxActionSummary
  handleProcessActionFinish?: (selectedAction?: InboxActionSummary) => void
  rerouteToPath?: (path: string) => void
  closeTab?: () => void
  showContractModal?: (providerId: string) => void
  user?: IUser
}

class CreateBilloutModal extends React.Component<ICreateBilloutModalProps> {
  public state = {
    billoutMissing: false,
    executed: false,
    currentValues: undefined,
    comments: '',
    missingComments: false,
    billOutMismatch: false,
    allDataReady: false,
    showBilledOutButton: false,
    isBilledOutModalOpen: false,
    billedOutIndex: undefined,
  }

  async componentDidMount(): Promise<void> {
    this.closeBilloutHistoryModal = this.closeBilloutHistoryModal.bind(this)
    this.setState({ allDataReady: false })

    var allowedUserRoles = process.env.REACT_APP_BILLED_OUT_AMOUNT_GROUPS?.split(',')

    var userAllowed = this.props.user?.roles.some(
      (x) => allowedUserRoles?.some((role) => role == x)
    )

    this.setState({ showBilledOutButton: userAllowed })

    let promises = [] as Array<Promise<void>>

    promises.push(this.props.getBilloutDetailSummary!(this.props.associatedItemId))
    promises.push(this.props.getHeaderInfo!(this.props.associatedItemId))

    await Promise.all(promises).then(async () => {
      this.mapInitialFormValues()
    })
  }

  public componentWillUnmount() {
    this.props.setIsCMS1500Saved!(false)
  }

  public closeBilloutHistoryModal = () => {
    this.setState({ isBilledOutModalOpen: false })
  }

  private mapInitialFormValues() {
    var initialValues = createIClaimFormValues({
      id: this.props.associatedItemId,
    } as CMS1500Dto)
    this.props.lineItemDetails!.map((y, lineIdx) => {
      if (lineIdx === 0) {
        initialValues.q24[lineIdx].aDateOfServiceFrom = y.dateOfService
        initialValues.q24[lineIdx].aDateOfServiceTo = y.dateOfService
        initialValues.q24[lineIdx].contractAmount = y.contractAmount
          ? y.contractAmount?.toString()
          : ''
        initialValues.q24[lineIdx].dProcedureCptHcpcs = y.dProcedureCptHcpcs ?? ''
        initialValues.q24[lineIdx].eDiagnosisPointer = y.eDiagnosisPointer ?? ''
        initialValues.q24[lineIdx].fCharges = y.remainingBalance
          ? y.remainingBalance.toString()
          : ''
        initialValues.q24[lineIdx].gDaysOrUnits = y.gDaysOrUnits ?? ''
        initialValues.q24[lineIdx].dModifier1 = y.dModifier1 ?? ''
        initialValues.q24[lineIdx].dModifier2 = y.dModifier2 ?? ''
        initialValues.q24[lineIdx].dModifier3 = y.dModifier3 ?? ''
        initialValues.q24[lineIdx].dModifier4 = y.dModifier4 ?? ''
        initialValues.q24[lineIdx].id = y.cmS1500LineItemId ?? ''
      } else {
        initialValues.q24.push({
          aDateOfServiceFrom: y.dateOfService,
          aDateOfServiceTo: y.dateOfService,
          bPlaceOfServiceTo: '',
          billOutAmount: undefined,
          cEmg: '',
          cms1500Id: this.props.associatedItemId,
          contractAmount: y.contractAmount ? y.contractAmount?.toString() : '',
          dModifier1: y.dModifier1 ?? '',
          dModifier2: y.dModifier2 ?? '',
          dModifier3: y.dModifier3 ?? '',
          dModifier4: y.dModifier4 ?? '',
          dProcedureCptHcpcs: y.dProcedureCptHcpcs ?? '',
          eDiagnosisPointer: y.eDiagnosisPointer ?? '',
          fCharges: y.remainingBalance ? y.remainingBalance.toString() : '',
          gDaysOrUnits: y.gDaysOrUnits ?? '',
          hFamilyPlan: '',
          id: y.cmS1500LineItemId ?? '',
          iIdQualifier: '',
          iNpi: '',
          iId: '',
          isModalOpen: false,
          overrideAmount: undefined,
          reasonCode: '',
        })
      }
    })
    initialValues.q21Diagnosis = setValuesFromDiagnosis(this.props.diagnosisCode!)
    this.setState({ allDataReady: true, currentValues: initialValues })
  }

  private previewPdf = async (
    values: IClaimFormValues,
    formikBag: FormikActions<IClaimFormValues>
  ) => {
    this.setState({ executed: true })
    this.previewBillOutPdf(values, formikBag)
    this.setState({ currentValues: values, billOutMismatch: false })
  }

  private previewBillOutPdf = async (
    values: IClaimFormValues,
    formikBag: FormikActions<IClaimFormValues>
  ) => {
    if (this.props.lineItemDetails != null && this.props.diagnosisCode != null) {
      if (
        values.q24.length != this.props.lineItemDetails.length ||
        values.q24.some((x) => x.billOutAmount == undefined || isNaN(x.billOutAmount))
      ) {
        this.setState({ billoutMissing: true, executed: false })
        formikBag.setSubmitting(false)
        return
      }

      const billOutData = await submitBillOutWorkflowCustomFormValues(values)
      await previewCMS1500InWorkflow(this.props.associatedItemId, billOutData, true).then(
        (_response: any) => {
          this.props.setIsCMS1500Saved!(true)
          formikBag.setSubmitting(false)
          this.setState({ executed: false })
        }
      )
    }
  }

  private execute = async (
    values: IClaimFormValues,
    formikBag: FormikActions<IClaimFormValues>
  ) => {
    this.setState({ executed: true })

    if (this.state.comments == '' && this.props.action.requireComment) {
      this.setState({ missingComments: true, executed: false })
      return
    }
    if (values !== this.state.currentValues) {
      this.setState({ billOutMismatch: true, executed: false })
      return
    }

    this.CreateBillOut(values, formikBag)
  }

  private CreateBillOut = async (
    values: IClaimFormValues,
    formikBag: FormikActions<IClaimFormValues>
  ) => {
    if (this.props.lineItemDetails != null) {
      if (
        values.q24.length != this.props.lineItemDetails.length ||
        values.q24.some((x) => x.billOutAmount == undefined || isNaN(x.billOutAmount))
      ) {
        this.setState({ billoutMissing: true, executed: false })
        formikBag.setSubmitting(false)
        return
      }
    }

    var data = new ObjectiveProcessAction()
    data.billOutData = await submitBillOutWorkflowCustomFormValues(values)
    data.comment = this.state.comments

    processDynamicAction(
      data,
      this.props.inboxId,
      this.props.action!.id.toString(),
      this.props.associatedItemId
    )
      .then((response: WorkflowInboxItemHeader) => {
        if (this.props.action.closeAfterProcessing) {
          this.props.setIsCMS1500Saved!(true)
          formikBag.setSubmitting(false)
          this.props.closeTab!()
        } else if (response.nextAssociatedItem) {
          if (window.location.toString().indexOf('billout-search') > -1) {
            this.props.rerouteToPath!(
              `/workflow/billout/${response.nextAssociatedItem.itemId}/inbox/${response.nextAssociatedItem.inboxId}/'billout-search'`
            )
          } else {
            this.props.rerouteToPath!(
              `/workflow/billout/${response.nextAssociatedItem.itemId}/inbox/${response.nextAssociatedItem.inboxId}`
            )
          }
          window.location.reload()
        }

        this.props.closeModal()
      })
      .catch(() => {
        formikBag.setSubmitting(false)
        this.setState({ executed: false })
      })
  }

  private onBlur(values: IClaimFormValues) {
    if (values.q24 !== undefined) {
      values.billedOutAmountTotal = values.q24
        .filter(
          (x) =>
            x != undefined &&
            x.billOutAmount != undefined &&
            !isNaN(x.billOutAmount) &&
            x.billOutAmount.toString() != ''
        )
        .map((x) => parseFloat((x.billOutAmount ?? 0) as unknown as string))
        .reduce((a, b) => a! + b!, 0)
    }
  }

  private onKeyUp() {
    if (this.state.billoutMissing != false) {
      this.setState({ billoutMissing: false })
    }
  }

  private renderLineItemList(values: IClaimFormValues) {
    return (
      <>
        <Table style={{ paddingTop: '10px' }} size="small">
          <TableHead>
            <TableRow key={'cpt-header'}>
              <TableCell align="right">Date of Service</TableCell>
              <TableCell align="right">Line</TableCell>
              <TableCell>CPT</TableCell>
              <TableCell colSpan={4}>Modifier</TableCell>
              <TableCell>Diagnosis Pointer</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Charge Amount</TableCell>
              <TableCell align="right">Contract Amount</TableCell>
              <TableCell align="right">Bill Out</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.lineItemDetails ? (
              this.props.lineItemDetails!.map((y, lineIdx) => {
                return (
                  <TableRow key={`q24-${lineIdx}`}>
                    <TableCell align="right">
                      {Moment(y.dateOfService).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell align="right">{y.lineNumber}</TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <FullWidthField name={`q24.${lineIdx}.dProcedureCptHcpcs`} />
                      </FormControl>
                    </TableCell>
                    <TableCell>{y.dModifier1}</TableCell>
                    <TableCell>{y.dModifier2}</TableCell>
                    <TableCell>{y.dModifier3}</TableCell>
                    <TableCell>{y.dModifier4}</TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <FullWidthField name={`q24.${lineIdx}.eDiagnosisPointer`} />
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">{y.gDaysOrUnits}</TableCell>
                    <TableCell align="right">
                      <CurrencyFormat
                        value={
                          //Charge amount
                          y.remainingBalance
                        }
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <CurrencyFormat
                        value={y.contractAmount}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <FullWidthField
                        name={`q24.${lineIdx}.${'billOutAmount'}`}
                        required={true}
                        InputProps={{
                          inputComponent: NumberMaskedInput,
                          inputProps: {
                            currency: true,
                            guide: false,
                            numberOfDigits: 12,
                          },
                        }}
                        onBlur={this.onBlur(values)}
                        onKeyUp={() => this.onKeyUp()}
                        autoComplete="off"
                      />
                    </TableCell>
                    <TableCell>
                      {this.state.showBilledOutButton && (
                        <Grid
                          item
                          style={{
                            paddingRight: '10px',
                            paddingTop: '20px',
                            alignItems: 'flex-start',
                            display: 'flex',
                          }}
                        >
                          <Tooltip title="Choose Historical Billed Out Amount">
                            <LocalAtmRounded
                              fontSize="large"
                              color={'primary'}
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.openBilloutHistoryModal(lineIdx)}
                            />
                          </Tooltip>
                        </Grid>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow key="blank">
                <TableCell colSpan={10} align="center">
                  <i>None</i>
                </TableCell>
              </TableRow>
            )}
            <TableRow key="footer">
              <TableCell colSpan={9} align="right">
                <strong>Total:</strong>
              </TableCell>
              <TableCell align="right">
                {this.props.lineItemDetails ? (
                  <CurrencyFormat
                    value={this.props
                      .lineItemDetails!.map((x) => x.remainingBalance || 0)
                      .reduce((a, b) => a! + b!, 0)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                ) : (
                  <span>$0.00</span>
                )}
              </TableCell>
              <TableCell align="right">
                {this.props.lineItemDetails ? (
                  <CurrencyFormat
                    value={this.props
                      .lineItemDetails!.map((x) => x.contractAmount || 0)
                      .reduce((a, b) => a! + b!, 0)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                ) : (
                  <span>$0.00</span>
                )}
              </TableCell>
              <TableCell align="right">
                {values ? (
                  <CurrencyFormat
                    value={values.billedOutAmountTotal}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                ) : (
                  <span>$0.00</span>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    )
  }

  private openBilloutHistoryModal(index: number) {
    this.setState({ isBilledOutModalOpen: true, billedOutIndex: index })
  }

  private renderDiagnosisFields = () => () => (
    <>
      {this.props.diagnosisCode!.map((diagnosis: CMS1500DiagnosisDto, index: number) => (
        <Grid item xs={2} key={index}>
          <FormControl fullWidth>
            <FullWidthField
              name={`q21Diagnosis.${index}.code`}
              label={diagnosis.pointer}
            />
          </FormControl>
        </Grid>
      ))}
    </>
  )

  public render() {
    const { isOpen, isCMS1500Saved, patientHeader, ...rest } = this.props
    return (
      <>
        {this.props.diagnosisCode &&
          this.props.lineItemDetails &&
          this.state.allDataReady && (
            <Dialog
              fullWidth={true}
              maxWidth="xl"
              onClose={this.props.closeModal}
              open={isOpen!}
              disableBackdropClick={true}
              {...rest}
            >
              {this.state.executed && (
                <div
                  style={{
                    position: 'fixed',
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1200',
                  }}
                >
                  <CircularProgress size={100} />
                </div>
              )}
              <Formik
                initialValues={this.state.currentValues}
                onSubmit={this.previewPdf}
                validationSchema={CreateBilloutModalSchema}
                enableReinitialize={false}
              >
                {(formikProps: FormikProps<IClaimFormValues>) => (
                  <>
                    {this.state.isBilledOutModalOpen && patientHeader && (
                      <LineItemBilledOutModal
                        providerGroup={patientHeader.providerName ?? ''}
                        providerTypeId={patientHeader.providerTypeId ?? ''}
                        isOpen={this.state.isBilledOutModalOpen}
                        index={this.state.billedOutIndex}
                        providerId={patientHeader.providerId}
                        createBilloutFormikProps={formikProps}
                        close={this.closeBilloutHistoryModal}
                      />
                    )}
                    <Form>
                      <ResponsiveAppBar
                        title={this.props.action.actionName}
                        isModal={true}
                      />
                      <DialogContent>
                        <Grid container>
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={2}
                            wrap="nowrap"
                            alignItems="baseline"
                          >
                            {patientHeader ? (
                              <>
                                <Grid item>
                                  <strong>Patient: </strong>
                                  {patientHeader?.patientLast != undefined
                                    ? patientHeader?.patientLast +
                                      ', ' +
                                      patientHeader?.patientFirst +
                                      (patientHeader?.dateOfBirth
                                        ? ' (' +
                                          Moment(patientHeader?.dateOfBirth).format(
                                            'MM/DD/YYYY'
                                          ) +
                                          ')'
                                        : '')
                                    : ''}
                                </Grid>
                                <Grid item>
                                  <strong>EOC/Legacy: </strong>
                                  {patientHeader?.eocLegacy}&nbsp;
                                  <Tooltip title={'Show Notes'}>
                                    <ReceiptOutlined
                                      style={{ cursor: 'pointer' }}
                                      fontSize="small"
                                      onClick={() => {
                                        this.props.openBillOutNotesModal!()
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <strong>Provider Type: </strong>
                                  {patientHeader?.providerType}
                                </Grid>
                                <Grid item>
                                  <strong>Claim Rcv: </strong>&nbsp;
                                  {patientHeader!.claimReceiveDate
                                    ? formatDate(patientHeader!.claimReceiveDate)
                                    : 'N/A'}
                                  &nbsp;&nbsp;
                                  {patientHeader!.claimStatus!.includes('Paid') ? (
                                    <></>
                                  ) : (
                                    <Tooltip title="Claim has not been paid">
                                      <Warning
                                        style={{
                                          color: '#ffc107',
                                          cursor: 'pointer',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </Grid>
                                <Grid item>
                                  <strong>Provider Group: </strong>
                                  {patientHeader?.providerName}&nbsp;
                                  <Tooltip title="View Provider Contract Summary">
                                    <InsertDriveFileOutlined
                                      style={{
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                      }}
                                      onClick={
                                        patientHeader.providerId
                                          ? () => {
                                              this.props.showContractModal!(
                                                patientHeader.providerId!
                                              )
                                            }
                                          : () => {}
                                      }
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <strong>Insurance Company: </strong>
                                  {patientHeader?.insuranceCompany}
                                </Grid>
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          {this.props.diagnosisCode ? (
                            <Grid item style={{ position: 'relative' }}>
                              <p>
                                Diagnosis or Nature of Illness or Injury (Relate A-L to
                                service line below) *
                              </p>
                              <Grid item container direction="row" xs={12}>
                                <FieldArray
                                  name="q21Diagnosis"
                                  render={this.renderDiagnosisFields()}
                                />
                                <ErrorMessage name="q21Diagnosis">
                                  {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                                </ErrorMessage>
                              </Grid>
                            </Grid>
                          ) : (
                            <div></div>
                          )}
                          <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            spacing={5}
                            wrap="nowrap"
                            style={{ margin: 0, width: '100%', padding: 16 }}
                          >
                            <Grid
                              container
                              direction="column"
                              wrap="nowrap"
                              justifyContent="center"
                              alignItems="center"
                            >
                              {this.props.lineItemDetails ? (
                                this.renderLineItemList(formikProps.values)
                              ) : (
                                <div></div>
                              )}
                            </Grid>
                          </Grid>
                          {this.props.action.displayComment && (
                            <Grid item xs={12}>
                              <TextField
                                label="Comments"
                                name="notes"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                required={this.props.action.requireComment}
                                multiline
                                minRows="4"
                                onChange={(event) =>
                                  this.setState({
                                    comments: event.target.value,
                                    missingComments: false,
                                  })
                                }
                              />
                            </Grid>
                          )}
                        </Grid>
                      </DialogContent>
                      {this.state.billOutMismatch && (
                        <div
                          style={{
                            textAlign: 'right',
                            margin: '0px 20px 12px',
                          }}
                          className="error"
                        >
                          Bill out values do not match created 1500, please recreate 1500.
                        </div>
                      )}
                      {(this.state.missingComments || this.state.billoutMissing) && (
                        <div
                          style={{
                            textAlign: 'right',
                            margin: '0px 20px 12px',
                          }}
                          className="error"
                        >
                          Please fill all required fields.
                        </div>
                      )}
                      <DialogActions>
                        <Button
                          onClick={this.props.closeModal}
                          style={{ marginRight: '8px' }}
                        >
                          Cancel
                        </Button>

                        <Button
                          disabled={
                            !formikProps.isValid ||
                            !isCMS1500Saved ||
                            this.state.executed ||
                            this.state.billOutMismatch ||
                            (this.props.action.requireComment &&
                              this.state.comments === '')
                          }
                          onClick={() => {
                            this.execute(formikProps.values, formikProps)
                          }}
                          color="primary"
                          variant="contained"
                          style={{ marginRight: '8px' }}
                        >
                          Complete Bill Out
                        </Button>

                        <Button
                          disabled={!formikProps.isValid}
                          color="primary"
                          onClick={() => {
                            formikProps.submitForm()
                          }}
                          variant="contained"
                        >
                          Preview PDF
                        </Button>
                      </DialogActions>
                    </Form>
                  </>
                )}
              </Formik>
            </Dialog>
          )}
        <BillOutNotesModal patientHeader={patientHeader} />
      </>
    )
  }
}

const InjectedCreateBilloutModal = inject<
  IStores,
  ICreateBilloutModalProps,
  Partial<ICreateBilloutModalProps>,
  any
>((stores: IStores) => ({
  diagnosisCode: stores.billOutStore.selectedDiagnosisCodes,
  lineItemDetails: stores.billOutStore.selectedListItems,
  createOutgoingCMS1500: stores.billOutStore.createOutgoingCMS1500,
  isCMS1500Saved: stores.billOutStore.isCMS1500Saved,
  openBillOutNotesModal: stores.billOutStore.openBillOutNotesModal,
  setIsCMS1500Saved: stores.billOutStore.setIsCMS1500Saved,
  patientHeader: stores.patients.headerDetails,
  getHeaderInfo: stores.patients.getHeaderInfo,
  getBilloutDetailSummary: stores.billOutStore.loadBilloutSummaryDetails,
  rerouteToPath: stores.global.rerouteToPath,
  showContractModal: stores.cms1500.openProviderGroupContractDialogById,
  user: stores.global.user,
}))(CreateBilloutModal)

export default InjectedCreateBilloutModal
