import { Grid } from '@material-ui/core'
import { MonetizationOn } from '@material-ui/icons'
import { DatePicker } from 'material-ui-pickers'
import { inject } from 'mobx-react'
import { Moment as moment } from 'moment'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultMedicareCostLookup, IMedicareCostLookup } from '../Definitions'
import { IStores } from '../Stores'
import MedicareCostLookupDialog from './MedicareCostLookupDialog'

interface IMedicareCostLookupsProps {
  clearFilters?: () => void
  dataTableManager?: IDataTableManager<IMedicareCostLookup>
  disableMedicareCostLookup?: (medicareCostLookup: IMedicareCostLookup) => void
  getColumnSettingsAndMedicareCostLookups?: () => void
  isModalOpen?: boolean
  openDialogWithMedicareCostLookup?: (medicareCostLookup: IMedicareCostLookup) => void
  recoverMedicareCostLookup?: (medicareCostLookup: IMedicareCostLookup) => void
  selectedDate?: Date
  setSelectedDate?: (date: Date | null) => void
}

@inject((stores: IStores) => ({
  clearFilters: stores.medicareCostLookups.clearFilters,
  dataTableManager: stores.medicareCostLookups.dataTableStore,
  disableMedicareCostLookup: stores.medicareCostLookups.disableMedicareCostLookup,
  getColumnSettingsAndMedicareCostLookups:
    stores.medicareCostLookups.getColumnSettingsAndMedicareCostLookups,
  isModalOpen: stores.medicareCostLookups.isModalOpen,
  openDialogWithMedicareCostLookup:
    stores.medicareCostLookups.openDialogWithMedicareCostLookup,
  recoverMedicareCostLookup: stores.medicareCostLookups.recoverMedicareCostLookup,
  selectedDate: stores.medicareCostLookups.selectedDate,
  setSelectedDate: stores.medicareCostLookups.setSelectedDate,
}))
class MedicareCostLookups extends React.Component<IMedicareCostLookupsProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndMedicareCostLookups!()
  }

  public componentWillUnmount() {
    this.props.clearFilters!()
  }

  public render() {
    const medicareCostLookupDialog = this.props.isModalOpen && (
      <MedicareCostLookupDialog />
    )
    return (
      <>
        <Helmet>
          <title>Medicare Cost</title>
        </Helmet>
        <ResponsiveAppBar title="Medicare Cost" pageIcon={<MonetizationOn />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableSearch={true}
          enableShowInactives={true}
          addButtonOnClick={this.openDialog(DefaultMedicareCostLookup(true))}
          filterComponents={this.getFilters()}
        />
        {medicareCostLookupDialog}
      </>
    )
  }

  private getFilters = () => {
    return (
      <Grid item xs={12}>
        <DatePicker
          value={this.props.selectedDate!}
          format="MM/DD/YYYY"
          label="Restrict to Codes Effective on"
          clearable
          variant="outlined"
          onChange={this.onDateChange()}
          InputLabelProps={{ shrink: !!this.props.selectedDate }}
          fullWidth={true}
        />
      </Grid>
    )
  }

  private onDateChange = () => (date: moment) => {
    this.props.setSelectedDate!(date ? date.toDate() : null)
  }

  public openDialog = (medicareCostLookup: IMedicareCostLookup) => () => {
    this.props.openDialogWithMedicareCostLookup!(medicareCostLookup)
  }
}

export default MedicareCostLookups
