import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
} from '@material-ui/core'
import { inject } from 'mobx-react'
import React, { ChangeEvent } from 'react'
import { IStores } from 'src/Stores'
import Moment from 'moment'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { Form, Formik } from 'formik'
import FullWidthField from 'src/common/FullWidthField'
import { IPatient } from 'src/Definitions'
import { DocumentCategory } from 'src/utils/DocumentCategory'
import { PatientDocumentDTO } from 'src/viewModels/PatientDocumentDTO'
import { UploadDocument } from 'src/services/PatientService'
import { Upload } from 'mdi-material-ui'
import FileUploadField, { defaultFileFormValues } from 'src/common/FileUploadField'
import DatePickerField from 'src/common/DatePickerField/DatePickerField'
import { OptionsObject } from 'notistack'
import * as Yup from 'yup'

interface IUploadModalProps {
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean
  patient?: IPatient
  sendNotification?: (
    key: string,
    message: string,
    options?: OptionsObject,
    information?: string | undefined
  ) => void
}

class UploadModal extends React.Component<IUploadModalProps> {
  public state = {
    selectedCategory: 0,
    selectedCategoryString: '',
    documentName: '',
    missingCategory: false,
    missingDocument: false,
    exeDocument: false,
  }

  public secondFormRef = React.createRef<any>()

  public render() {
    const { isOpen } = this.props
    const patientName =
      this.props.patient?.lastName + ', ' + this.props.patient?.firstName
    const dob =
      this.props.patient?.dateOfBirth != undefined
        ? Moment(this.props.patient.dateOfBirth).format('MM/DD/YYYY')
        : ''
    var modalTitle = 'Upload Document: ' + patientName + ' (' + dob + ')'

    const UploadModalSchema = Yup.object().shape({
      date: Yup.date().required('File Date is required'),
    })

    return (
      <Dialog
        key={this.props.patient?.id}
        fullWidth={true}
        maxWidth="sm"
        open={isOpen!}
        onClose={this.close}
        disableBackdropClick
      >
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar title={modalTitle} pageIcon={<Upload />} isModal={true} />
        </DialogTitle>
        <DialogContent style={{ paddingBottom: '0px', paddingTop: '8px' }}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                description: '',
                document: defaultFileFormValues(),
                date: new Date(),
              }}
              onSubmit={() => {}}
              validationSchema={UploadModalSchema}
              ref={this.secondFormRef}
            >
              {({ values }) => (
                <Form id="secondForm">
                  <Grid spacing={2} justifyContent="space-between">
                    <>
                      <Grid container item xs={12} spacing={2} style={{ margin: '0px' }}>
                        <Grid item xs={3} style={{ display: 'flex' }}>
                          <FileUploadField name="document" buttonText="Select File" />
                        </Grid>
                        <Grid item xs={9} style={{ display: 'flex' }}>
                          <div>{values.document.name}</div>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          justifyContent="space-evenly"
                          style={{ display: 'flex' }}
                        >
                          <DatePickerField
                            name="date"
                            label="Date"
                            inputId="date"
                            fullWidth={true}
                            required
                            shrink={true}
                            variant="outlined"
                            className="smallDateTextbox"
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel shrink={true} variant="outlined">
                              Category *
                            </InputLabel>
                            <Select
                              variant="outlined"
                              input={<OutlinedInput notched labelWidth={80} />}
                              inputProps={{ label: true, notched: true }}
                              native={true}
                              fullWidth
                              onChange={this.setSelectedCategory}
                              id="eventSelect"
                            >
                              <option value="">Select a category</option>
                              <option value={DocumentCategory.Authorization}>
                                Authorization
                              </option>
                              <option value={DocumentCategory.Billing}>Billing</option>
                              <option value={DocumentCategory.Contracts}>
                                Contracts
                              </option>
                              <option value={DocumentCategory.Medical}>Medical</option>
                              <option value={DocumentCategory.Misc}>Miscellaneous</option>
                              <option value={DocumentCategory.PT}>PT</option>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FullWidthField
                            label="Description"
                            name="description"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
        {this.state.missingDocument && (
          <div style={{ textAlign: 'right', margin: '0px 20px 12px' }} className="error">
            Please select a document
          </div>
        )}
        {this.state.exeDocument && (
          <div style={{ textAlign: 'right', margin: '0px 20px 12px' }} className="error">
            Cannot upload file of type exe
          </div>
        )}
        {this.state.missingCategory && (
          <div style={{ textAlign: 'right', margin: '0px 20px 12px' }} className="error">
            Category is required
          </div>
        )}
        <DialogActions>
          <Button onClick={this.close} data-cy="close" variant="contained">
            Close
          </Button>
          <Button onClick={this.save} data-cy="save" variant="contained" color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private setSelectedCategory = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    this.setState({
      selectedCategory: event.target.selectedOptions[0].value,
      selectedCategoryString: event.target.selectedOptions[0].innerText,
      missingCategory: false,
    })
  }

  private close = () => {
    this.setState({
      selectedCategory: 0,
      selectedCategoryString: '',
      documentName: '',
      missingCategory: false,
      missingDocument: false,
      exeDocument: false,
    })
    this.props.close!()
  }

  private save = () => {
    const formikValues = this.secondFormRef.current?.state?.values
    if (
      this.state.selectedCategoryString == '' ||
      formikValues.document.name == '' ||
      formikValues.document.name.includes('.exe')
    ) {
      this.setState({
        missingCategory: this.state.selectedCategoryString == '',
        missingDocument: formikValues.document.name == '',
        exeDocument: formikValues.document.name.includes('exe'),
      })
      return
    }

    var dto = new PatientDocumentDTO(
      this.props.patient!.id,
      formikValues.document.name,
      formikValues.document.mime,
      formikValues.document.content,
      this.state.selectedCategory,
      formikValues.date ?? new Date(),
      formikValues.description
    )

    UploadDocument(dto).then((result) => {
      this.props.sendNotification!('200', `${result} has been successfully uploaded.`, {
        variant: 'success',
      })
      this.close()
    })
  }
}
const InjectedUploadModal = inject<
  IStores,
  IUploadModalProps,
  Partial<IUploadModalProps>,
  any
>((stores: IStores) => ({
  close: stores.patients.closeUploadModal,
  isOpen: stores.patients.isUploadModalOpen,
  patient: stores.patients.selectedPatient,
  sendNotification: stores.notifications.sendNotification,
}))(UploadModal)

export default InjectedUploadModal
