import { Grid } from '@material-ui/core'
import { FieldProps, getIn } from 'formik'
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  TimePicker as MuiTimePicker,
  TimePickerProps as MuiTimePickerProps,
} from 'material-ui-pickers'
import moment from 'moment'
import * as React from 'react'

export type AllKeys<T> = T extends T ? keyof T : never
export type Omit<T, K extends AllKeys<T>> = T extends T
  ? Pick<T, Exclude<keyof T, K>>
  : never

export type DateTimePickerProps = FieldProps &
  Omit<MuiDatePickerProps, 'error' | 'name' | 'onChange' | 'value'> &
  Omit<MuiTimePickerProps, 'error' | 'name' | 'onChange' | 'value'>

export const fieldToDatePicker = ({
  field,
  form,
  disabled = false,
  ...props
}: DateTimePickerProps): MuiDatePickerProps => {
  const { name } = field
  const { touched, errors, isSubmitting } = form
  const fieldError = getIn(errors, name)
  const showError = getIn(touched, name) && !!fieldError
  const newOnChange = (e: moment.Moment) => {
    form.setFieldValue(name, e ? e.format() : undefined)
  }
  const mask = (value: string) => {
    return value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
  }
  return {
    ...props,
    ...field,
    clearable: props.clearable,
    defaultValue: undefined,
    disabled: isSubmitting || disabled,
    error: showError,
    format: 'MM/DD/YYYY',
    keyboard: true,
    label: `${props.label} Date`,
    mask,
    onChange: newOnChange,
    value: field.value ? field.value : null,
  }
}

export const fieldToTimePicker = ({
  field,
  form,
  disabled = false,
  ...props
}: DateTimePickerProps): MuiTimePickerProps => {
  const { name } = field
  const { touched, errors, isSubmitting } = form
  const fieldError = getIn(errors, name)
  const showError = getIn(touched, name) && !!fieldError
  const newOnChange = (e: moment.Moment) => {
    form.setFieldValue(name, e ? e.format() : undefined)
  }

  return {
    ...props,
    ...field,
    clearable: props.clearable,
    defaultValue: undefined,
    disabled: isSubmitting || disabled,
    error: showError,
    keyboard: true,
    label: `${props.label} Time`,
    onChange: newOnChange,
    value: field.value ? field.value : null,
  }
}

export const DateTimePicker: React.ComponentType<DateTimePickerProps> = ({
  children,
  ...props
}: DateTimePickerProps) => (
  <Grid container direction="column" spacing={1}>
    <Grid item>
      <MuiDatePicker {...fieldToDatePicker(props)} children={children} />
    </Grid>
    <Grid item>
      <MuiTimePicker {...fieldToTimePicker(props)} children={children} />
    </Grid>
  </Grid>
)

MuiDatePicker.displayName = 'FormikMaterialUIDatePicker'
