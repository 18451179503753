import { createStyles, Grid, WithStyles, withStyles } from '@material-ui/core'
import { AttachMoney } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IStores } from '../Stores'
import BillsTable from './billsTable/BillsTable'
import { PayProvidersSearchRequest } from 'src/viewModels/PayProvidersSearchRequest'
const styles = () => createStyles({})

interface IBillsProps extends WithStyles<typeof styles> {
  loadBills?: (request: PayProvidersSearchRequest) => Promise<void>
  setCanSwitchOrganizations?: (canSwitch: boolean) => void
  storedRequest?: PayProvidersSearchRequest
}

class Bills extends React.Component<IBillsProps> {
  constructor(props: IBillsProps) {
    super(props)
  }

  public componentDidMount() {
    var request = this.props.storedRequest ?? new PayProvidersSearchRequest()
    this.props.loadBills!(request)
    this.props.setCanSwitchOrganizations!(true)
  }

  public componentWillUnmount() {
    this.props.setCanSwitchOrganizations!(false)
  }

  public render() {
    return (
      <>
        <Helmet>
          <title>Pay Providers</title>
        </Helmet>
        <ResponsiveAppBar title={'Pay Providers'} pageIcon={<AttachMoney />} />
        <Grid container style={{ width: '100%' }}>
          <BillsTable />
        </Grid>
      </>
    )
  }
}

const InjectedBills = inject<IStores, IBillsProps, Partial<IBillsProps>, any>(
  (stores: IStores) => ({
    loadBills: stores.billsStore.loadSurgeryPayProviderBills,
    setCanSwitchOrganizations: stores.global.setCanSwitchOrganizations,
    storedRequest: stores.global.payProviderRequest,
  })
)(Bills)

export default withStyles(styles)(InjectedBills)
