export interface IBillingLocationSearchRequest {
  providerTypeId?: string | undefined
  providerGroup?: string | undefined
  providerLocation?: string | undefined
  page: number
  pageSize: number
}

export class BillingLocationSearchRequest implements IBillingLocationSearchRequest {
  public providerTypeId?: string | undefined
  public providerGroup?: string | undefined
  public providerLocation?: string | undefined
  public page: number
  public pageSize: number
}
