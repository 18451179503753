import { PaymentLineItemDetailSummary } from './PaymentLineItemDetailSummary'

export interface IPaymentDetailSummaryResult {
  patientName: string | undefined
  patientDob: string | undefined
  groupNumber: string | undefined
  billingAddress: string | undefined
  totalAmount: string
  totalPaid: string
  insuranceCompanyName: string
  totalPaidDisplay: string
  lineItems: PaymentLineItemDetailSummary[]
}

export class PaymentDetailSummaryResult implements IPaymentDetailSummaryResult {
  public patientName: string | undefined
  public patientDob: string | undefined
  public groupNumber: string | undefined
  public billingAddress: string | undefined
  public totalAmount: string
  public totalPaid: string
  public insuranceCompanyName: string
  public totalPaidDisplay: string
  public lineItems: PaymentLineItemDetailSummary[]
}
