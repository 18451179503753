import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { List } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from 'src/Stores'
import Moment from 'moment'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { Form, Formik } from 'formik'
import FullWidthField from 'src/common/FullWidthField'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { AddInvoiceLog, GetInvoiceLogByInvoiceId } from 'src/services/QuoteService'
import { InvoiceLogDTO } from 'src/viewModels/quotes/InvoiceLogDTO'
import { pagedList } from 'src/viewModels/pagedList'
import { InvoiceLogSearchRequest } from 'src/viewModels/quotes/InvoiceLogSearchRequest'
import { formatCurrency } from 'src/utils/Formatter'

interface IInvoiceLogEntryModalProps {
  close: () => void
  isOpen: boolean
  quote?: IQuoteDTO
}

class InvoiceLogEntryModal extends React.Component<IInvoiceLogEntryModalProps> {
  public secondFormRef = React.createRef<any>()

  public state = {
    invoiceTotals: undefined as unknown as InvoiceLogDTO,
    isLoading: true,
    notesMissing: false,
  }

  public async componentDidMount(): Promise<void> {
    const data: InvoiceLogSearchRequest = {
      invoiceId: this.props.quote?.globalInvoiceId,
      page: 1,
      pageSize: 1,
    }

    await GetInvoiceLogByInvoiceId(this.props.quote?.id ?? '', data).then(
      (response: pagedList) => {
        if (response && response.items.length > 0) {
          var first = response.items.pop()
          this.setState({ invoiceTotals: first, isLoading: false })
        } else {
          this.close()
        }
      }
    )
  }

  public render() {
    const { isOpen, quote } = this.props
    const quoteId = quote ? quote.id : ''
    const patientName = quote?.patientName
    const dob =
      quote?.dateOfBirth != undefined
        ? Moment(quote?.dateOfBirth).format('MM/DD/YYYY')
        : ''
    const surgery =
      quote?.surgeryDate != undefined
        ? Moment(quote?.surgeryDate).format('MM/DD/YYYY')
        : 'TBD'
    const body = quote?.bodyPartName != undefined ? quote?.bodyPartName : 'TBD'
    const eoc = quote?.episodeOfCareOrLegacyId ?? ''

    var modalTitle = `Add Invoice Notes - ${patientName} (${dob}) ${eoc}`

    return (
      <Dialog
        key={quoteId}
        fullWidth={true}
        maxWidth="md"
        open={isOpen!}
        onClose={this.close}
        disableBackdropClick
      >
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar title={modalTitle} pageIcon={<List />} isModal={true} />
        </DialogTitle>
        <DialogContent style={{ paddingBottom: '0px' }}>
          {this.state.isLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1200',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <Grid
            container
            item
            xs={12}
            spacing={3}
            style={{ paddingTop: 12, paddingBottom: 12 }}
            wrap="nowrap"
          >
            <Grid item>
              <strong>{patientName}</strong>
            </Grid>
            <Grid item>
              <strong>DOB:</strong> {dob}
            </Grid>
            <Grid item>
              <strong>Surgery:</strong> {surgery}
            </Grid>
            <Grid item>
              <strong>Body Part:</strong> {body}
            </Grid>
            <Grid item>
              <strong>Invoice Total:</strong>{' '}
              {this.state.invoiceTotals?.invoiceTotal != undefined
                ? formatCurrency(this.state.invoiceTotals?.invoiceTotal.toString())
                : 'N/A'}
            </Grid>
            <Grid item>
              <strong>U&C Total:</strong>{' '}
              {this.state.invoiceTotals?.ucTotal != undefined
                ? formatCurrency(this.state.invoiceTotals?.ucTotal.toString())
                : 'N/A'}
            </Grid>
            <Grid item>
              <strong>Amount Paid:</strong>{' '}
              {this.state.invoiceTotals?.totalAmountPaid != undefined
                ? formatCurrency(this.state.invoiceTotals?.totalAmountPaid.toString())
                : 'N/A'}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{ notes: '' }}
              onSubmit={() => {}}
              validationSchema={undefined}
              ref={this.secondFormRef}
            >
              {() => (
                <Form id="secondForm">
                  <Grid spacing={2} justifyContent="space-between">
                    <>
                      <Grid container item xs={12} spacing={2} style={{ margin: '0px' }}>
                        <Grid item xs={12}>
                          <FullWidthField
                            required
                            label="Notes"
                            name="notes"
                            variant="outlined"
                            multiline
                            rows="4"
                            onKeyUp={() => this.setState({ notesMissing: false })}
                          />
                        </Grid>
                        {this.state.notesMissing && (
                          <div
                            style={{
                              textAlign: 'right',
                              margin: '0px 20px 12px',
                            }}
                            className="error"
                          >
                            Notes are required.
                          </div>
                        )}
                      </Grid>
                    </>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close" variant="contained">
            Close
          </Button>
          <Button
            onClick={this.save}
            data-cy="save"
            variant="contained"
            color="primary"
            disabled={this.state.invoiceTotals == undefined}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private close = () => {
    this.setState({
      invoiceTotals: undefined as unknown as InvoiceLogDTO,
      isLoading: true,
    })
    this.props.close()
  }

  private save = () => {
    this.setState({ isLoading: true })
    const formikValues = this.secondFormRef.current?.state?.values

    if (formikValues.notes == '') {
      this.setState({ notesMissing: true, isLoading: false })
    } else {
      var dto = new InvoiceLogDTO()

      dto.notes = formikValues.notes
      dto.invoiceId = this.props.quote?.globalInvoiceId

      AddInvoiceLog(this.props.quote?.id ?? '', dto).then(() => this.props.close())
    }
  }
}

const InjectedInvoiceLogEntryModal = inject<
  IStores,
  IInvoiceLogEntryModalProps,
  Partial<IInvoiceLogEntryModalProps>,
  any
>((stores: IStores) => ({
  quote: stores.quotes.selectedQuote,
}))(InvoiceLogEntryModal)

export default InjectedInvoiceLogEntryModal
