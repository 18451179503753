import { Business } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IEmployer } from '../Definitions'
import { IStores } from '../Stores'

interface IEmployersProps {
  dataTableManager?: IDataTableManager<IEmployer>
  disableEmployer?: (Employer: IEmployer) => void
  getColumnSettingsAndEmployers?: () => void
  isModalOpen?: boolean
  createEmployer?: () => void
  recoverEmployer?: (Employer: IEmployer) => void
}

class Employers extends React.Component<IEmployersProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndEmployers!()
  }

  public render() {
    return (
      <>
        <Helmet>
          <title>Employers</title>
        </Helmet>
        <ResponsiveAppBar title="Employers" pageIcon={<Business />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={true}
          enableToggleColumns={true}
          addButtonOnClick={this.createEmployer}
        />
      </>
    )
  }

  public createEmployer = () => {
    this.props.createEmployer!()
  }
}

const InjectedEmployers = inject<IStores, IEmployersProps, Partial<IEmployersProps>, any>(
  (stores: IStores) => ({
    createEmployer: stores.employers.createEmployer,
    dataTableManager: stores.employers.dataTableStore,
    disableEmployer: stores.employers.disableEmployer,
    getColumnSettingsAndEmployers: stores.employers.getColumnSettingsAndEmployers,
    isModalOpen: stores.employers.isModalOpen,
    recoverEmployer: stores.employers.recoverEmployer,
  })
)(Employers)

export default InjectedEmployers
