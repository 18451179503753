export interface IClaimsAcqLogDTO {
  claimsAcqStatus: number
  followUpDate?: Date | undefined
  urgent: boolean
  notes?: string | undefined
  createdDate?: Date | undefined
  createdByName: string
  scheduleId: string
}

export class ClaimsAcqLogDTO {
  public claimsAcqStatus: number
  public followUpDate?: Date | undefined
  public urgent: boolean
  public notes?: string | undefined
  public createdDate?: Date | undefined
  public createdByName: string
  public scheduleId: string
}
