import { AddCircle, Edit, RecordVoiceOver } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { IPatient } from '../../Definitions'
import { IStores } from '../../Stores'
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ReactPaginate from 'react-paginate'
import { pagedList } from 'src/viewModels/pagedList'
import { ReferralSummaryResult } from 'src/viewModels/ReferralSummaryResult'
import { SearchRequestBase } from 'src/viewModels/SearchRequestBase'
import { searchReferrals } from 'src/services/PatientService'
import moment from 'moment'

interface IReferralsProps {
  editReferral?: (referralId: string | undefined, patientId: string) => void
  loadEpisodesOfCare?: () => Promise<void>
  selectedPatient?: IPatient
  setSelectedReferral?: (referralId: string) => Promise<void>
  setPage?: (page: number) => void
}

class Referrals extends React.Component<IReferralsProps> {
  public state = {
    gridLoading: false,
    gridData: new pagedList().items as ReferralSummaryResult[],
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 5,
    selectedReferralId: '',
  }

  componentDidMount(): void {
    if (this.props.selectedPatient && !this.props.selectedPatient.isNew) {
      this.getReferralData().then(() => {
        if (this.state.gridData != undefined && this.state.gridData.length != 0) {
          var firstId = this.state.gridData.at(0)!.referralId
          this.setSelectedReferralRow(firstId)
        }
      })
    }
  }

  public setSelectedReferralRow(referralId: string) {
    this.setState({ selectedReferralId: referralId, gridLoading: true }, async () => {
      await this.props.setSelectedReferral!(referralId)
      this.props.setPage!(1)
      await this.props.loadEpisodesOfCare!()
      this.setState({ gridLoading: false })
    })
  }
  private getReferralData = async () => {
    this.setState({ gridLoading: true })
    const { page, pageSize } = this.state
    var requestData = new SearchRequestBase()
    requestData.page = page
    requestData.pageSize = pageSize
    await searchReferrals(this.props.selectedPatient?.id!, requestData).then(
      (response: any) => {
        this.setState({
          gridData: response.items,
          pageCount: response.totalPages,
          totalItems: response.totalItems,
          gridLoading: false,
        })

        if (response.totalItems > 0) {
          this.setPaginationOffsetData()
        }
      }
    )
  }

  public addButtonOnClick = () => {
    if (this.props.selectedPatient && !this.props.selectedPatient.isNew) {
      this.props.editReferral!(undefined, this.props.selectedPatient.id)
    }
  }

  public isAddButtonDisabled = this.props.selectedPatient
    ? this.props.selectedPatient.isNew
    : false

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getReferralData())
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div style={{ paddingBottom: '20px' }} className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={14} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((referral, index) => (
        <TableRow
          key={index}
          className="gridPadding"
          style={{
            backgroundColor:
              this.state.selectedReferralId == referral.referralId ? '#29348F' : 'white',
            color:
              this.state.selectedReferralId == referral.referralId ? 'white' : 'black',
          }}
          onClick={() => this.setSelectedReferralRow(referral.referralId)}
        >
          <TableCell style={{ color: 'inherit' }} align="right">
            {referral.doi != undefined ? moment(referral.doi).format('MM/DD/YYYY') : ''}
          </TableCell>
          <TableCell style={{ color: 'inherit' }} align="right">
            {referral.referralDateDisplay}
          </TableCell>
          <TableCell style={{ color: 'inherit' }}>{referral.insuranceCompany}</TableCell>
          <TableCell style={{ color: 'inherit' }}>{referral.primaryAdjuster}</TableCell>
          <TableCell style={{ color: 'inherit' }}>{referral.ncmCompany}</TableCell>
          <TableCell style={{ color: 'inherit' }}>{referral.primaryNCM}</TableCell>
          <TableCell style={{ color: 'inherit' }}>
            {referral.insuranceClaimNumber}
          </TableCell>
          <TableCell style={{ padding: '0px', color: 'inherit' }}>
            <Edit
              data-testid={`referralGridEdit.${index}`}
              style={{ cursor: 'pointer' }}
              fontSize="small"
              onClick={() =>
                this.props.editReferral!(
                  referral.referralId,
                  this.props.selectedPatient!.id
                )
              }
            />
          </TableCell>
        </TableRow>
      ))
    }
  }

  public render() {
    return (
      <>
        <Grid container direction="row-reverse">
          <Grid item style={{ color: '#AAAAAA' }}>
            <AddCircle
              color={this.isAddButtonDisabled ? 'inherit' : 'primary'}
              onClick={this.isAddButtonDisabled ? undefined : this.addButtonOnClick}
              style={{
                fontSize: '30px',
                marginRight: '8px',
                cursor: this.isAddButtonDisabled ? 'auto' : 'pointer',
              }}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="center" spacing={2}>
          <Grid item>
            <RecordVoiceOver style={{ fontSize: '32px' }} color="secondary" />
          </Grid>
          <Grid item style={{ fontSize: '20px', paddingTop: '4px' }}>
            Referrals
          </Grid>
        </Grid>
        {this.state.gridLoading && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1200',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <Grid container item md={12} style={{ minWidth: '750px', width: '100%' }}>
          <Table aria-label="simple table" style={{ padding: '20' }}>
            <TableHead>
              <TableRow className="gridPadding">
                <TableCell align="right">DOI</TableCell>
                <TableCell align="right">Referral Date</TableCell>
                <TableCell>Insurance Company</TableCell>
                <TableCell>Primary Adjuster</TableCell>
                <TableCell>NCM Company</TableCell>
                <TableCell>Primary NCM</TableCell>
                <TableCell>Ins Claim Num</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ width: '100%' }}>{this.mapGridData()}</TableBody>
          </Table>
        </Grid>
        <div className="pagination-row">
          {this.paginationInfo()}
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            onPageChange={this.handlePageChange}
            pageCount={this.state.pageCount}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={this.state.page - 1}
            //@ts-ignore
            renderOnZeroPageCount={null}
          />
        </div>
      </>
    )
  }
}

const InjectedReferrals = inject<IStores, IReferralsProps, Partial<IReferralsProps>, any>(
  (stores: IStores) => ({
    editReferral: stores.referrals.editReferral,
    loadEpisodesOfCare: stores.patientEdit.getEOCs,
    selectedPatient: stores.patients.selectedPatient,
    setSelectedReferral: stores.referrals.getReferralWithContactsById,
    setPage: stores.patientEdit.setEOCPage,
  })
)(Referrals)

export default InjectedReferrals
