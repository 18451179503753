import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { ILocation, IProvider } from '../Definitions'
import { IStores } from '../Stores'

interface ICriticalCareDialogProps {
  close?: () => void
  setSelectedLocation?: (location?: ILocation) => void
  setSelectedProvider?: (provider?: IProvider) => Promise<void>
  clearLocations?: () => void
  isOpen?: boolean
}

class CriticalCareDialog extends React.Component<ICriticalCareDialogProps> {
  private clearProvider = () => {
    this.props.close!()
    this.props.setSelectedProvider!(undefined)
    this.props.setSelectedLocation!(undefined)
    this.props.clearLocations!()
  }

  public render() {
    return (
      <>
        {
          <Dialog open={this.props.isOpen!} maxWidth="md">
            <DialogTitle style={{ padding: 0 }}>
              {
                <ResponsiveAppBar
                  title="Critical Care Access"
                  pageIcon={<Warning />}
                  isModal={true}
                />
              }
            </DialogTitle>
            <DialogContent style={{ padding: 8 }}>
              {
                <>
                  {
                    <div style={{ padding: 20 }}>
                      <Typography>
                        {' '}
                        This provider is a Critical Care Hospital. Are you sure you want
                        to use them?{' '}
                      </Typography>
                    </div>
                  }
                </>
              }
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.props.close}
                data-cy="close"
                color="primary"
                variant="contained"
              >
                Yes
              </Button>
              <Button onClick={this.clearProvider} data-cy="close">
                No
              </Button>
            </DialogActions>
          </Dialog>
        }
      </>
    )
  }
}

const InjectedCriticalCareDialog = inject<
  IStores,
  ICriticalCareDialogProps,
  Partial<ICriticalCareDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.providers.closeCriticalCareModal,
  isOpen: stores.providers.isCriticalCareModalOpen,
}))(CriticalCareDialog)

export default InjectedCriticalCareDialog
