import MomentUtils from '@date-io/moment'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { Done, Error, Info, Warning } from '@material-ui/icons'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { inject } from 'mobx-react'
import { SnackbarProvider } from 'notistack'
import * as React from 'react'
import config from './config'
import { Shell } from './core'
import { Notifier, NotifierInfoDialog } from './core/Notifier/'
import diagnosticsTheme from './DiagnosticsTheme'
import { IStores } from './Stores'
import surgicalTheme from './SurgicalTheme'

interface IAppProps {
  isLoggedIn?: boolean
  defaultTheme?: boolean
}

@inject((stores: IStores) => ({
  defaultTheme: stores.global.isSurgical,
  isLoggedIn: stores.global.isLoggedIn,
}))
class App extends React.Component<IAppProps> {
  public render() {
    return (
      <CssBaseline>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          iconVariant={{
            error: <Error />,
            info: <Info />,
            success: <Done />,
            warning: <Warning />,
          }}
        >
          <>
            <Notifier notificationConfig={config.NotificationConfig} />
            <NotifierInfoDialog />
            <MuiThemeProvider
              theme={this.props.defaultTheme ? surgicalTheme : diagnosticsTheme}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Shell />
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </>
        </SnackbarProvider>
      </CssBaseline>
    )
  }
}

export default App
