import { ListSubheader } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import * as React from 'react'
import { Link } from 'react-router-dom'
import ExpandableListItem from '../../common/ExpandableListItem'
import { IUser } from '../../Definitions'
import { INavigationSection } from '../../Routes'
import { getInboxesByUser } from 'src/services/WorkflowService'
import { throwError } from 'rxjs'

const styles = ({}: Theme) => {
  return createStyles({
    navLink: {
      color: 'unset',
      textDecoration: 'none',
    },
  })
}

interface INavigationSectionProps extends WithStyles<typeof styles> {
  isOpen: boolean
  navigationSection: INavigationSection
  user: IUser
  currentOrganizationId: string
}

class NavigationSection extends React.Component<INavigationSectionProps> {
  state = {
    hasInboxes: false,
  }

  public async componentDidMount(): Promise<void> {
    try {
      var inboxes = await getInboxesByUser()
      this.setState({ hasInboxes: inboxes.length > 0 })
    } catch (error) {
      throwError(error)
    }
  }

  public render() {
    const { isOpen, navigationSection } = this.props

    return (
      <div>
        {isOpen && navigationSection.header && (
          <ListSubheader>{navigationSection.header}</ListSubheader>
        )}
        {this.renderNavigationLinks()}
      </div>
    )
  }

  private renderNavigationLinks(): JSX.Element[] {
    const { classes, navigationSection, isOpen, user, currentOrganizationId } = this.props
    const userRoles = user.roles
    const guidRegex =
      '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'

    return navigationSection.navigationRoutes.reduce<JSX.Element[]>((routes, route) => {
      const allowedRoles = route.allowedRoles ? route.allowedRoles : []
      const allowedOrganizations = route.allowedOrganization
        ? route.allowedOrganization
        : []
      if (
        (route.text != 'Inboxes' &&
          (allowedRoles.length === 0 ||
            allowedRoles.some((r) => userRoles.indexOf(r) > -1)) &&
          (allowedOrganizations.length === 0 ||
            allowedOrganizations.some((o) => o === currentOrganizationId))) ||
        (route.text == 'Inboxes' && this.state.hasInboxes)
      ) {
        const link = (
          <Link
            key={route.path}
            to={
              route.text == 'Advanced Search'
                ? route.path.replace(`:currentUserId(${guidRegex})`, user.id)
                : route.path
            }
            className={classes.navLink}
          >
            <ExpandableListItem expanded={isOpen} icon={route.icon} text={route.text} />
          </Link>
        )
        return routes.concat(link)
      } else {
        return routes
      }
    }, [])
  }
}

export default withStyles(styles)(NavigationSection)
