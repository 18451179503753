import {
  createStyles,
  IconButton,
  MenuItem,
  SvgIcon,
  Theme,
  WithStyles,
  withStyles,
  withTheme,
} from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import { MoreVert } from '@material-ui/icons'
import * as React from 'react'
import ConfirmMenuItem from './ConfirmMenuItem/ConfirmMenuItem'
import { IMenuItem } from './ESDataTable'

const styles = ({ palette }: Theme) =>
  createStyles({
    esMenuItem: {
      '&:focus': {
        backgroundColor: 'white',
      },
      '&:hover': {
        backgroundColor: palette.primary.main,
        color: 'white',
      },
      '&:hover svg': {
        color: 'white !important',
      },
    },
  })

export interface IESMenuProps extends WithStyles<typeof styles> {
  data: any
  menuItems: IMenuItem[]
  rowIndex: number
  setSelectedRow?: (idx: number) => void
  icon?: JSX.Element
  titleMenuItem?: string
  menuAriaLabel?: string
}

class ESMenu extends React.Component<IESMenuProps> {
  public state = {
    anchorEl: null,
    isOpen: false,
  }

  public toggleIsOpen = (event: React.MouseEvent) => {
    event.stopPropagation()
    let anchorEl = null
    if (!this.state.isOpen) {
      anchorEl = event.currentTarget
    }
    this.setState({ anchorEl, isOpen: !this.state.isOpen })
    if (this.props.setSelectedRow) {
      this.props.setSelectedRow(this.props.rowIndex)
    }
  }

  public onMenuItemClick =
    (item: IMenuItem) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      this.setState({ anchorEl: null, isOpen: false })
      return item.onClick(event, this.props.data)
    }
  public onMouseLeave = (item: IMenuItem) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    this.setState({ anchorEl: null, isOpen: false })
    return item.onMouseLeave!(event)
  }

  public render() {
    const menuItems: React.ReactNode[] = []
    if (this.props.titleMenuItem) {
      menuItems.push(
        <MenuItem
          key={`titleMenuItem`}
          className={this.props.classes.esMenuItem}
          disabled
        >
          {this.props.titleMenuItem}
        </MenuItem>
      )
    }
    this.props.menuItems.forEach((item: IMenuItem, idx: number) => {
      if (item.isConfirm) {
        menuItems.push(
          <ConfirmMenuItem
            key={`${item.name}-${idx}`}
            onClick={this.onMenuItemClick(item)}
            onMouseLeave={this.onMouseLeave(item)}
            className={this.props.classes.esMenuItem}
            disabled={item.disabled}
          >
            {!!item.icon && (
              <SvgIcon
                color="inherit"
                component={item.icon}
                style={{ paddingRight: '10px', color: `${item.color}` }}
                children={[]}
              />
            )}
            {item.name}
          </ConfirmMenuItem>
        )
      } else {
        menuItems.push(
          <MenuItem
            key={`${item.name}-${idx}`}
            onClick={this.onMenuItemClick(item)}
            className={this.props.classes.esMenuItem}
            disabled={item.disabled}
          >
            {!!item.icon && (
              <SvgIcon
                color="inherit"
                component={item.icon}
                fontSize="large"
                style={{ paddingRight: '10px', color: `${item.color}` }}
                children={[]}
              />
            )}
            {item.name}
          </MenuItem>
        )
      }
    })
    return (
      <>
        <IconButton
          id={this.props.menuAriaLabel}
          aria-label={this.props.menuAriaLabel}
          onClick={this.toggleIsOpen}
          style={{ padding: 0 }}
          color="inherit"
        >
          {this.props.icon ? this.props.icon : <MoreVert />}
        </IconButton>
        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.isOpen}
          onClose={this.toggleIsOpen}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
        >
          {menuItems}
        </Menu>
      </>
    )
  }
}

export default withTheme(withStyles(styles)(ESMenu))
