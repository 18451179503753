import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from '@material-ui/core'
import { AttachMoney } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { IProviderDto } from 'src/generated_client'

interface IViewProviderGroupContractModalProps {
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean

  selectedProvider?: IProviderDto

  reloadParent?: () => void
}

class ViewProviderGroupContractModal extends React.Component<IViewProviderGroupContractModalProps> {
  public state = {
    contractLink: '',
    contractSummary: '',
  }

  public render() {
    const { isOpen, selectedProvider } = this.props

    return (
      <Dialog key={selectedProvider?.id} fullWidth={true} maxWidth="md" open={isOpen!}>
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title={`Contract Summary - ${selectedProvider?.name}`}
            pageIcon={<AttachMoney />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" item xs={12} spacing={2}>
            <Grid item xs={12} style={{ paddingTop: '16px' }}>
              <Typography
                variant={'body1'}
                color="textSecondary"
                style={{
                  height: '18px',
                  paddingRight: '8px',
                  marginBottom: '8px',
                }}
              >
                CONTRACT LINK
              </Typography>
              {selectedProvider?.contractLink ? (
                <Link href={selectedProvider?.contractLink} target="_blank">
                  Go To Contract Summary
                </Link>
              ) : (
                <Typography>No link available</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={'body1'}
                color="textSecondary"
                style={{
                  height: '18px',
                  paddingRight: '8px',
                  marginBottom: '8px',
                }}
              >
                CONTRACT SUMMARY
              </Typography>
              <Typography>
                <React.Fragment>
                  <span style={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                    {selectedProvider?.contractSummary
                      ? selectedProvider.contractSummary
                      : 'No comments available'}
                  </span>
                </React.Fragment>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  public secondFormRef = React.createRef<any>()

  private close = () => {
    this.props.close!()
  }
}

const InjectedViewProviderGroupContractModal = inject<
  IStores,
  IViewProviderGroupContractModalProps,
  Partial<IViewProviderGroupContractModalProps>,
  any
>((stores: IStores) => ({
  close: stores.cms1500.closeProviderGroupContractDialog,
  isLoading: stores.quotes.isLoading,
  isOpen: stores.cms1500.isProviderGroupContractDialogOpen,
  selectedProvider: stores.cms1500.selectedProvider,
}))(ViewProviderGroupContractModal)

export default InjectedViewProviderGroupContractModal
