import { ContactDto } from 'src/generated_client'
import { ReferralContactDTO } from './ReferralContactDTO'

export interface IReferralDTO {
  id: string
  name: string
  isActive: boolean
  patientId: string
  doi: Date | undefined
  referralDate: Date
  referralTime: string
  employerId: string | undefined
  employerContact: ContactDto | undefined
  employerContactId: string | undefined
  occupation: string
  insuranceCompanyId: string | undefined
  insuranceCompanyContactId: string | undefined
  nurseCaseManagerId: string | undefined
  nurseCaseManagerContactId: string | undefined
  referralCaller: string
  claimNumber: string
  health: boolean | undefined
  initials: string
  patientName: string
  insuranceCompanyName: string | undefined
  jurisdiction?: string | undefined
  additionalInsuranceContacts: ReferralContactDTO[]
  additionalNCMContacts: ReferralContactDTO[]
}

export class ReferralDTO implements IReferralDTO {
  public id: string
  public name: string
  public isActive: boolean
  public patientId: string
  public doi: Date | undefined
  public referralDate: Date
  public referralTime: string
  public employerId: string | undefined
  public employerContact: ContactDto | undefined
  public employerContactId: string | undefined
  public occupation: string
  public insuranceCompanyId: string | undefined
  public insuranceCompanyContactId: string | undefined
  public nurseCaseManagerId: string | undefined
  public nurseCaseManagerContactId: string | undefined
  public referralCaller: string
  public claimNumber: string
  public health: boolean | undefined
  public initials: string
  public patientName: string
  public insuranceCompanyName: string | undefined
  public jurisdiction: string | undefined
  public additionalInsuranceContacts: ReferralContactDTO[]
  public additionalNCMContacts: ReferralContactDTO[]

  constructor(
    id: string,
    name: string,
    isActive: boolean,
    patientId: string,
    doi: Date | undefined,
    referralDate: Date,
    referralTime: string,
    employerId: string | undefined,
    employerContact: ContactDto | undefined,
    employerContactId: string | undefined,
    occupation: string,
    insuranceCompanyId: string | undefined,
    insuranceCompanyContactId: string | undefined,
    nurseCaseManagerId: string | undefined,
    nurseCaseManagerContactId: string | undefined,
    referralCaller: string,
    claimNumber: string,
    health: boolean | undefined,
    initials: string,
    insuranceCompanyName: string | undefined,
    jurisdiction: string | undefined,
    additionalInsuranceContacts: ReferralContactDTO[],
    additionalNCMContacts: ReferralContactDTO[]
  ) {
    this.id = id
    this.name = name
    this.isActive = isActive
    this.patientId = patientId
    this.doi = doi
    this.referralDate = referralDate
    this.referralTime = referralTime
    this.employerId = employerId
    this.employerContact = employerContact
    this.employerContactId = employerContactId
    this.occupation = occupation
    this.insuranceCompanyId = insuranceCompanyId
    this.insuranceCompanyContactId = insuranceCompanyContactId
    this.nurseCaseManagerId = nurseCaseManagerId
    this.nurseCaseManagerContactId = nurseCaseManagerContactId
    this.referralCaller = referralCaller
    this.claimNumber = claimNumber
    this.health = health
    this.initials = initials
    this.insuranceCompanyName = insuranceCompanyName
    this.jurisdiction = jurisdiction
    this.additionalInsuranceContacts = additionalInsuranceContacts
    this.additionalNCMContacts = additionalNCMContacts
  }
}
