import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog'
import { inject } from 'mobx-react'
import * as React from 'react'
import { IPatient } from '../../Definitions'
import { IStores } from '../../Stores'

interface IDeletePatientDialogProps extends DialogProps {
  close?: () => void
  disablePatient?: (patient: IPatient) => Promise<void>
  returnToPatients?: () => void
  selectedPatient?: IPatient
}

class DeletePatientDialog extends React.Component<IDeletePatientDialogProps> {
  public render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>{<>Are you sure?</>}</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this patient? They will be marked as inactive.
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.close} data-cy="close">
            No
          </Button>
          <Button
            onClick={this.confirm}
            data-cy="close"
            color="primary"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private confirm = () => {
    this.props.disablePatient!(this.props.selectedPatient!)
    this.props.returnToPatients!()
    this.props.close!()
  }
}

const InjectedDeletePatientDialog = inject<
  IStores,
  IDeletePatientDialogProps,
  Partial<IDeletePatientDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.patients.closeConfirmDialog,
  disablePatient: stores.patients.disablePatient,
  selectedPatient: stores.patients.selectedPatient,
}))(DeletePatientDialog)

export default InjectedDeletePatientDialog
