import { Grid } from '@material-ui/core'
import { FormikActions } from 'formik'
import _ from 'lodash'
import { inject } from 'mobx-react'
import * as React from 'react'
import { noWhitespaceString } from 'src/common/YupExtensions'
import * as Yup from 'yup'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import SelectField from '../common/SelectField/SelectField'
import { DefaultPhysician, ILocation, IPhysician, IProvider } from '../Definitions'
import {
  IdentifierEntityDto,
  ILocationDto,
  ISpecialtyDto,
  ISubSpecialtyDto,
  PhysicianLocationDto,
  SwaggerResponse,
} from '../generated_client'
import { IStores } from '../Stores'

interface IPhysicianDialogFormValues {
  locationIds: string[]
  specialtyId: string
  subSpecialtyId: string
  name: string
  npi: string
  firstName: string
  lastName: string
}

const PhysicianSchema = Yup.object().shape({
  firstName: noWhitespaceString('First Name is required', true),
  lastName: noWhitespaceString('Last Name is required', true),
  npi: noWhitespaceString('NPI Number is required', true),
})

interface IPhysicianDialogProps {
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean
  getAllLocations?: () => Promise<SwaggerResponse<ILocationDto[]>>
  getSpecialties?: () => Promise<void>
  getSubSpecialties?: () => Promise<void>
  locations?: ILocation[]
  physician?: IPhysician
  savePhysician?: (physician: IPhysician) => Promise<void>
  selectedProvider?: IProvider
  specialties?: ISpecialtyDto[]
  subSpecialties?: ISubSpecialtyDto[]
}

class PhysicianDialog extends React.Component<IPhysicianDialogProps> {
  public componentDidMount() {
    this.props.getSpecialties!()
    this.props.getSubSpecialties!()
    this.props.getAllLocations!()
  }

  public save = (
    values: IPhysicianDialogFormValues,
    formikBag: FormikActions<IPhysicianDialogFormValues>
  ) => {
    const locations = values.locationIds.map(
      (x) =>
        new PhysicianLocationDto({
          locationId: x,
          physicianId: this.props.physician!.id,
        })
    )
    const specialty = new IdentifierEntityDto({
      id: values.specialtyId ? values.specialtyId : undefined,
    })
    const subSpecialty = new IdentifierEntityDto({
      id: values.subSpecialtyId ? values.subSpecialtyId : undefined,
    })
    const physician = {
      ...DefaultPhysician(this.props.physician!.isNew),
      ...values,
      locations,
      providerId: this.props.selectedProvider!.id,
      specialty,
      subSpecialty,
    } as IPhysician
    this.props.savePhysician!(physician).finally(() => formikBag.setSubmitting(false))
  }

  public getName = (a: any) => {
    if (a) {
      return a.name || ''
    }
    return ''
  }

  public getValue = (a: any) => {
    if (a) {
      return a.id || ''
    }
    return ''
  }

  public render() {
    const { close, isLoading, isOpen, physician, locations, selectedProvider } =
      this.props
    const initialValues = {
      firstName: physician!.firstName ? physician!.firstName : '',
      lastName: physician!.lastName ? physician!.lastName : '',
      locationIds:
        physician!.locations!.length > 0
          ? physician!.locations!.map((x) => x.locationId)
          : [],
      name: physician!.name ? physician!.name : '',
      npi: physician!.npi ? physician!.npi : '',
      specialtyId: physician!.specialty ? physician!.specialty.id : '',
      subSpecialtyId: physician!.subSpecialty ? physician!.subSpecialty.id : '',
    } as IPhysicianDialogFormValues
    return (
      <ESDialog
        close={close!}
        initialValues={initialValues}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={physician!.isNew ? 'Add New Physician' : 'Edit Physician'}
        validationSchema={PhysicianSchema}
      >
        {({ values }) => (
          <Grid container spacing={3} direction="column" style={{ marginTop: 8 }}>
            <Grid container item direction="row" spacing={3}>
              <Grid item xs={6}>
                <FullWidthField
                  autoFocus
                  name="firstName"
                  label="First Name"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <FullWidthField
                  name="lastName"
                  label="Last Name"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={5}>
                <FullWidthField
                  name="npi"
                  label="NPI Number"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid container item xs={1} direction="column">
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <a
                    href="https://npiregistry.cms.hhs.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lookup
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={3}>
              {selectedProvider && !(selectedProvider.locationCount === 0) && (
                <Grid item xs={6}>
                  <SelectField
                    fullWidth={true}
                    items={locations}
                    label="Location"
                    getName={this.getName}
                    getValue={this.getValue}
                    checkedItems={values.locationIds}
                    inputId="locationIds"
                    name="locationIds"
                    multiple
                    outlined
                    shrinkLabel={true}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                {this.specialtyField()}
              </Grid>
              <Grid item xs={6}>
                {this.subSpecialtyField()}
              </Grid>
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }

  private specialtyField() {
    const { specialties } = this.props

    return (
      <SelectField
        fullWidth={true}
        items={specialties}
        label="Specialty"
        getName={this.getName}
        getValue={this.getValue}
        inputId="specialtyId"
        name="specialtyId"
        outlined
        shrinkLabel={true}
      />
    )
  }

  private subSpecialtyField() {
    const { subSpecialties } = this.props

    return (
      <SelectField
        fullWidth={true}
        items={subSpecialties}
        label="Sub-Specialty"
        getName={this.getName}
        getValue={this.getValue}
        inputId="subSpecialtyId"
        name="subSpecialtyId"
        outlined
        shrinkLabel={true}
      />
    )
  }
}

const InjectedPhysicianDialog = inject<
  IStores,
  IPhysicianDialogProps,
  Partial<IPhysicianDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.physicians.closeDialog,
  getAllLocations: stores.locations.getAllLocations,
  getSpecialties: stores.physicians.getAllSpecialties,
  getSubSpecialties: stores.physicians.getAllSubSpecialties,
  isLoading: stores.physicians.isLoading,
  isOpen: stores.physicians.isModalOpen,
  locations: stores.locations.locations,
  physician: stores.physicians.selectedPhysician,
  savePhysician: stores.physicians.savePhysician,
  selectedProvider: stores.providers.selectedProvider,
  specialties: stores.physicians.specialties,
  subSpecialties: stores.physicians.subSpecialties,
}))(PhysicianDialog)

export default InjectedPhysicianDialog
