import { Grid } from '@material-ui/core'
import { Lock } from '@material-ui/icons'
import { FormikActions } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import { IStores } from '../Stores'
import * as Yup from 'yup'
import { IRole } from './Roles'

const RoleSchema = Yup.object().shape({
  role: Yup.object().shape({
    name: Yup.string().required('Name is Required'),
  }),
})

interface IRoleFormEvent {
  role: IRole
}

interface IRoleDialog {
  close?: () => void
  createRole?: (role: IRole) => Promise<void>
  isLoading?: boolean
  isOpen?: boolean
  role?: IRole
}

@inject((stores: IStores) => ({
  close: stores.roles.closeDialog,
  createRole: stores.roles.createRole,
  isLoading: stores.roles.isLoading,
  isOpen: stores.roles.isModalOpen,
  role: stores.roles.selectedRole,
}))
export default class RoleDialog extends React.Component<IRoleDialog> {
  public save = (
    values: IRoleFormEvent,
    { setSubmitting }: FormikActions<IRoleFormEvent>
  ) => {
    this.props.createRole!({ ...values.role }).then(() => {
      setSubmitting(false)
    })
  }

  public render() {
    const { close, isLoading, isOpen } = this.props
    const role = this.props.role!
    const title = role.isNew ? 'Add New Role' : 'Edit Role'
    return (
      <ESDialog
        close={close!}
        initialValues={{ role }}
        isLoading={isLoading}
        maxWidth="sm"
        open={isOpen!}
        save={this.save}
        title={title}
        titleIcon={<Lock />}
        validationSchema={RoleSchema}
      >
        {() => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FullWidthField autoFocus label="Role Name" name="role.name" required />
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }
}
