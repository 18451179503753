import {
  Chip,
  createStyles,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { InputProps } from '@material-ui/core/Input'
import { UnfoldMore } from '@material-ui/icons'
import Downshift, {
  ControllerStateAndHelpers,
  GetItemPropsOptions,
  GetMenuPropsOptions,
  GetPropsCommonOptions,
} from 'downshift'
import * as _ from 'lodash'
import ChipInput, { ChipRenderer, ChipRendererArgs } from 'material-ui-chip-input'
import * as React from 'react'

type GetMenuPropsFn = (
  options?: GetMenuPropsOptions,
  otherOptions?: GetPropsCommonOptions
) => any

type GetItemPropsFn = (options: GetItemPropsOptions<any>) => any

const getItemsDebounceTimeout = 500

const styles = createStyles({
  chip: {
    marginRight: 4,
  },
  noSuggestions: {
    fontStyle: 'italic',
    padding: `11px 16px`,
  },
  popper: {
    width: 'auto !important',
    zIndex: 9999,
  },
})

const downshiftResetState = {
  isOpen: false,
}

interface ITypeAheadFieldFilterProps<T> extends WithStyles<typeof styles> {
  fullWidth?: boolean
  getName: (item: T) => string
  getValue: (item: T) => string
  getItems: (filter: string) => void
  items: T[]
  label: string
  onChipChange: (items: T[]) => void
  required?: boolean
  selectedItems: T[]
}

interface ITypeAheadFieldFilterState {
  downshiftInputValue: string
}

class TypeAheadFieldFilter<T> extends React.Component<
  ITypeAheadFieldFilterProps<T>,
  ITypeAheadFieldFilterState
> {
  private inputRef: any

  constructor(props: ITypeAheadFieldFilterProps<T>) {
    super(props)
    this.state = {
      downshiftInputValue: '',
    }
  }

  private get currentFormValues() {
    return this.props.selectedItems
  }

  private setInputRef = (element: any) => {
    this.inputRef = element
  }

  public render() {
    const { fullWidth, label, required } = this.props

    return (
      <Downshift
        inputValue={this.state.downshiftInputValue}
        onChange={this.onExistingSelected}
        itemToString={this.itemToString}
        onInputValueChange={this.onInputChanged}
        onOuterClick={this.onOuterClick}
        selectedItem={this.currentFormValues}
      >
        {({
          getInputProps,
          getMenuProps,
          getItemProps,
          highlightedIndex,
          inputValue,
          isOpen,
          setState,
        }) => {
          const inputProps = getInputProps<InputProps>()
          const onKeyDown = inputProps.onKeyDown
          delete inputProps.onKeyDown
          return (
            <div>
              <ChipInput
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UnfoldMore />
                    </InputAdornment>
                  ),
                  ...inputProps,
                }}
                InputLabelProps={{ required }}
                label={label}
                fullWidth={fullWidth}
                inputValue={inputValue || ''}
                value={this.currentFormValues}
                chipRenderer={this.renderChip}
                onDelete={this.onDelete(setState)}
                onKeyDown={onKeyDown}
                inputRef={this.setInputRef}
                variant="outlined"
              />
              {this.renderSuggestions(
                getMenuProps,
                getItemProps,
                isOpen,
                highlightedIndex
              )}
            </div>
          )
        }}
      </Downshift>
    )
  }

  public itemToString = (item: T) => {
    return this.props.getName(item)
  }

  private onOuterClick = (helpers: ControllerStateAndHelpers<T>) => {
    helpers.setState(downshiftResetState)
    this.setState({ downshiftInputValue: '' })
  }

  private onExistingSelected = (
    selectedItem: T,
    helpers: ControllerStateAndHelpers<T>
  ) => {
    this.addChip(selectedItem)
    helpers.setState(downshiftResetState)
    this.setState({ downshiftInputValue: '' })
  }

  private addChip = (itemToAdd: T) => {
    if (itemToAdd !== null) {
      const newValues = this.currentFormValues.concat([itemToAdd])
      this.props.onChipChange(newValues)
    }
  }

  private onDelete = (setState: any) => (chip: T) => {
    const { getValue } = this.props
    const value = getValue(chip)
    const values = this.currentFormValues
    const newValues = values.filter((x: T) => getValue(x) !== value)
    this.props.onChipChange(newValues)
    setState(downshiftResetState)
  }

  private onInputChanged = (inputValue: string) => {
    if (typeof inputValue === 'string') {
      this.setState({ downshiftInputValue: inputValue })
    }
    _.debounce(() => {
      if (this.props.getItems && typeof inputValue === 'string') {
        this.props.getItems(inputValue)
      }
    }, getItemsDebounceTimeout)()
  }

  private renderChip: ChipRenderer = ({
    chip,
    handleClick,
    handleDelete,
  }: ChipRendererArgs) => {
    const { classes, getName, getValue } = this.props
    return (
      <Chip
        className={classes.chip}
        key={getValue(chip)}
        label={getName(chip)}
        onClick={handleClick}
        onDelete={handleDelete}
        variant="outlined"
      />
    )
  }

  private filterSelectedFromOptions = () => {
    const { getValue } = this.props
    const chipValues: string[] = this.currentFormValues.map(getValue)
    return (item: T) => {
      return !chipValues.some((cv) => getValue(item) === cv)
    }
  }

  private renderSuggestions(
    getMenuProps: GetMenuPropsFn,
    getItemProps: GetItemPropsFn,
    isOpen: boolean,
    highlightedIndex: number | null
  ) {
    const { classes, items = [] } = this.props
    const menuProps = getMenuProps({}, { suppressRefError: true })
    const menuItems = items
      .filter(this.filterSelectedFromOptions())
      .map(this.renderMenuItem(getItemProps, highlightedIndex))

    const noSuggestionsMessage = (
      <Typography className={classes.noSuggestions} variant="body2">
        No suggestions found. Press Enter to create a new entry
      </Typography>
    )

    const suggestions = <MenuList {...menuProps}>{menuItems}</MenuList>
    return (
      <div>
        <Popper
          className={classes.popper}
          open={isOpen}
          keepMounted={false}
          anchorEl={this.inputRef}
          placement="bottom"
          modifiers={{
            preventOverflow: {
              boundariesElement: 'viewport',
              enabled: true,
            },
          }}
          style={{
            width: this.inputRef ? this.inputRef.clientWidth : undefined,
          }}
        >
          <Paper>{menuItems.length > 0 ? suggestions : noSuggestionsMessage}</Paper>
        </Popper>
      </div>
    )
  }

  private renderMenuItem =
    (getItemProps: GetItemPropsFn, highlightedIndex: number | null) =>
    (item: T, index: number) => {
      const { getName, getValue } = this.props
      const itemProps = getItemProps({ key: getValue(item), item })
      const isSelected = highlightedIndex === index
      return (
        <MenuItem {...itemProps} selected={isSelected}>
          {getName(item)}
        </MenuItem>
      )
    }
}

export default withStyles(styles)(TypeAheadFieldFilter)
