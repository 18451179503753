import Axios, { AxiosRequestConfig } from 'axios'
import GlobalStore from 'src/stores/GlobalStore'
import NotificationStore from 'src/stores/NotificationStore'

const ServiceBase = {
  attachAuthInterceptor,
  attachResponseInterceptor,
}

export default ServiceBase

function attachAuthInterceptor(): void {
  Axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
    config.withCredentials = true
    return config
  })
}

function attachResponseInterceptor(
  global: GlobalStore,
  notifications: NotificationStore
): void {
  Axios.interceptors.response.use(undefined, (err) => {
    let response = err.response
    const data = response.data
    if (response.status === 401) {
      global.clearUserAndRouteToLogin()
      notifications.sendNotification(
        '401',
        data === '' ? 'Session has ended. Please login.' : data,
        {
          variant: 'error',
        }
      )
    } else if (data === '') {
      notifications.sendNotification(
        response.url,
        'An error has occurred: ' + response.statusText,
        { variant: 'error' }
      )
    } else {
      notifications.sendNotification(
        response.url,
        data ? data : 'An error has occurred',
        { variant: 'error' }
      )
    }
    return Promise.reject(response)
  })
}
