import { StaticAction } from './StaticAction'

export enum BilloutStaticAction {
  PEND = StaticAction.PEND,
  ASSIGN = StaticAction.ASSIGN,
  RELEASE_LOCK = StaticAction.RELEASE_LOCK,
  FINISH = StaticAction.FINISH,
  AUTO_ASSIGN = StaticAction.AUTO_ASSIGN,
  //VALUES 6-15 reserved for Claim ItemType
  //VALUES 16-25 reserved for Quotes ItemType
  //VALUES 26-35 reserved for Invoices ItemType
  //VALUES 36-45 reserved for Billouts ItemType
  PRINT = 36,
  PRINT_VERIFIED = 37,
  REVERSE_BILLOUT = 38,
  REVERSE_DISMISSAL = 39,
}
