import { Grid } from '@material-ui/core'
import moment from 'moment'
import { Component } from 'react'
import React from 'react'

interface ICustomGridDateProps {
  date: Date | undefined
}

export default class CustomGridDate extends Component<ICustomGridDateProps> {
  public render() {
    const { date } = this.props
    return (
      <Grid container>{date ? moment(date.toString()).format('MM/DD/YYYY') : 'TBD'}</Grid>
    )
  }
}
