import { Provider } from 'mobx-react'
import promiseFinally from 'promise.prototype.finally'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import { unregister } from './registerServiceWorker'
import ServiceBase from './services/ServiceBase'
import stores from './Stores'

promiseFinally.shim()
// Axios integration configuration for requests
ServiceBase.attachAuthInterceptor()

const root = (
  <Provider {...stores}>
    <App />
  </Provider>
)

ReactDOM.render(root, document.getElementById('root') as HTMLElement)
unregister()
