export default {
  Cancelled: 'Cancelled',
  Evaluation: 'Evaluation',
  NonSurgicalBundle: 'Non-Surgical Bundle',
  NonSurgical: 'Non-Surgical',
  None: '',
  SurgicalBundle: 'Surgical Bundle',
  SxPostponed: 'Sx Postponed',
  CaseClosed: 'MMI/Case Closed',
  InjectionBundle: 'Injection Bundle',
}
