import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import { FormikActions } from 'formik'
import { Stethoscope } from 'mdi-material-ui'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import { noWhitespaceString } from '../common/YupExtensions'
import { DefaultDxCode, IDxCode } from '../Definitions'
import { IStores } from '../Stores'
import * as Yup from 'yup'

const DxCodeSchema = Yup.object().shape({
  code: noWhitespaceString('Code is required', true),
  description: noWhitespaceString('Description is required', true),
})

interface IDxCodeFormValues {
  id: string
  code: string
  description: string
  createdOn: Date
  isActive: boolean
  modifiedOn: Date
}
interface IDxCodeDialogProps {
  close?: () => void
  dxCode?: IDxCode
  isLoading?: boolean
  isOpen?: boolean
  saveDxCode?: (dxCode: IDxCode) => Promise<void>
}

class DxCodeDialog extends React.Component<IDxCodeDialogProps> {
  public save = (values: IDxCode, formikBag: FormikActions<IDxCode>) => {
    const dxCode = {
      ...DefaultDxCode(this.props.dxCode!.isNew),
      ...values,
    }
    dxCode.code = dxCode.code.toUpperCase()
    this.props.saveDxCode!(dxCode).finally(() => formikBag.setSubmitting(false))
  }

  private handleIsActiveChange =
    (values: IDxCodeFormValues, setFieldValue: (field: string, value: any) => void) =>
    () => {
      setFieldValue('isActive', !values.isActive)
    }

  public render() {
    const { close, isLoading, isOpen, dxCode } = this.props
    return (
      <ESDialog
        close={close!}
        initialValues={{ ...dxCode } as IDxCodeFormValues}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={dxCode!.isNew ? 'Add New DX Code' : 'Edit DX Code'}
        validationSchema={DxCodeSchema}
        titleIcon={<Stethoscope />}
      >
        {({ values, setFieldValue }) => (
          <Grid style={{ paddingTop: '20px' }} container direction="column" spacing={3}>
            <Grid item xs={3}>
              <FullWidthField
                autoFocus
                name="code"
                label="Code"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FullWidthField
                name="description"
                label="Description"
                variant="outlined"
                required
                multiline={true}
                rows="3"
              />
            </Grid>
            <Grid item xs={3} style={{ position: 'absolute', bottom: -10 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.isActive}
                    onChange={this.handleIsActiveChange(values, setFieldValue)}
                    value="isActive"
                    color="secondary"
                    name="isActive"
                  />
                }
                label={values.isActive ? 'Active' : 'Inactive'}
              />
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }
}

const InjectedDxCodeDialog = inject<
  IStores,
  IDxCodeDialogProps,
  Partial<IDxCodeDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.dxCodes.closeDialog,
  dxCode: stores.dxCodes.selectedDxCode,
  isLoading: stores.dxCodes.isLoading,
  isOpen: stores.dxCodes.isModalOpen,
  saveDxCode: stores.dxCodes.saveDxCode,
}))(DxCodeDialog)

export default InjectedDxCodeDialog
