import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from 'src/Stores'
import WorkflowInbox from './WorkflowInbox'
import { WithStyles } from '@material-ui/core'
import { styles } from 'material-ui-pickers/wrappers/InlineWrapper'
import { RouteComponentProps } from 'react-router-dom'
import { IEpisodeOfCare, ICMS1500, ILocation, ISchedule } from 'src/Definitions'
import { IProviderDto, SwaggerResponse } from 'src/generated_client'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import ReceiveClaim from 'src/ReceiveClaim/ReceiveClaim'
import { AttachMoney } from '@material-ui/icons'
import { getClaimInboxItem, getDefaultClaimInboxItem } from 'src/services/WorkflowService'
import { ClaimInboxItem } from 'src/viewModels/workflow/ClaimInboxItem'
import { InboxType } from 'src/utils/InboxType'
import ViewClaim from 'src/viewClaim/viewClaim'
import { IClaimFormValues } from 'src/ReceiveClaim/ClaimFormValues'

interface IClaimInboxParams {
  claimId: string
  inboxId: string
}

class ReceiveClaimParams {
  episodeOfCareId: string
  quoteId: string
  claimId: string
  appealType: string
}

class matchParams {
  params: ReceiveClaimParams
  isExact: boolean
  path: string
  url: string
}

interface IClaimInboxProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IClaimInboxParams> {
  closeModal?: () => void
  episodeOfCare?: IEpisodeOfCare
  isLoading?: boolean
  isModalOpen?: boolean
  isCMS1500Loading?: boolean
  isCurrentOrganizationSurgical?: boolean
  isEpisodeOfCareLoading?: boolean
  isQuoteLoading?: boolean
  loadEpisodeOfCare?: (episodeOfCareId: string) => Promise<void>
  loadClaim?: (claimId: string) => Promise<void>
  rerouteToPath?: (path: string) => void
  saveCMS1500?: (cms1500: ICMS1500) => Promise<SwaggerResponse<void>>
  selectedCMS1500?: ICMS1500
  selectedEpisodeOfCare?: IEpisodeOfCare
  selectedLocation?: ILocation
  selectedSchedule?: ISchedule
  setSelectedCMS1500?: (cms1500: ICMS1500 | undefined) => void
  setSelectedEpisodeOfCare?: (episodeOfCare: IEpisodeOfCare | undefined) => void
  setSelectedLocation?: (location?: ILocation) => void
  setSelectedSchedule?: (schedule?: ISchedule) => void
  checkDuplicateBills?: (cms1500: ICMS1500) => Promise<void>
  duplicateCMS1500?: ICMS1500
  openModal?: () => void
  scheduleItems?: DropdownOption[]
  locationItems?: DropdownOption[]
  setSelectedScheduleById?: (id: string) => Promise<void>
  getScheduledOptions?: () => void
  openDialogWithSchedule?: (schedule: ISchedule | undefined) => void
  openProviderLookupDialog?: () => void
  getLocationById?: (locationId: string) => void
  isProviderLookupDialogOpen?: boolean
  showContractModal?: (provider: IProviderDto) => void
  setReturn?: () => void
  closeTab?: () => void
  setUpdateParentAfterStaticAction: (parentfunction?: () => void) => void
}

class ClaimInbox extends React.Component<IClaimInboxProps> {
  public state = {
    headerInfo: undefined as unknown as ClaimInboxItem,
    isLoading: true,
    eocId: '',
    claimFormValues: undefined as unknown as IClaimFormValues,
  }

  public async componentDidMount(): Promise<void> {
    if (this.props.match.params.claimId && this.props.match.params.inboxId) {
      await getClaimInboxItem(
        this.props.match.params.claimId,
        this.props.match.params.inboxId
      )
        .then(async (response: any) => {
          this.setState({
            headerInfo: response,
            eocId: response.associatedEpisodeOfCareId,
            isLoading: false,
          })
        })
        .catch(() => {
          this.setState({ isLoading: false })
        })
    } else if (this.props.match.params.claimId) {
      await getDefaultClaimInboxItem(this.props.match.params.claimId)
        .then(async (response: any) => {
          this.setState({
            headerInfo: response,
            eocId: response.associatedEpisodeOfCareId,
            isLoading: false,
          })
        })
        .catch(() => {
          this.setState({ isLoading: false })
        })
    }
  }

  public renderClaim() {
    var match = new matchParams()
    match.params = new ReceiveClaimParams()
    match.params.episodeOfCareId = this.state.eocId
    match.params.claimId = this.props.match.params.claimId
    if (
      !this.state.headerInfo.restrictedToOutOfWorkflowActions &&
      !this.state.headerInfo.isDynamicItem
    ) {
      return (
        <ReceiveClaim
          checkDuplicateBills={this.props.checkDuplicateBills}
          closeModal={this.props.closeModal}
          duplicateCMS1500={this.props.duplicateCMS1500}
          episodeOfCare={this.props.episodeOfCare}
          isCMS1500Loading={this.props.isCMS1500Loading}
          isCurrentOrganizationSurgical={this.props.isCurrentOrganizationSurgical}
          isEpisodeOfCareLoading={this.props.isEpisodeOfCareLoading}
          isLoading={this.props.isLoading}
          isModalOpen={this.props.isModalOpen}
          loadClaim={this.props.loadClaim}
          loadEpisodeOfCare={this.props.loadEpisodeOfCare}
          openModal={this.props.openModal}
          rerouteToPath={this.props.rerouteToPath}
          saveCMS1500={this.props.saveCMS1500}
          selectedCMS1500={this.props.selectedCMS1500}
          selectedEpisodeOfCare={this.props.selectedEpisodeOfCare}
          selectedLocation={this.props.selectedLocation}
          selectedSchedule={this.props.selectedSchedule}
          setSelectedCMS1500={this.props.setSelectedCMS1500}
          setSelectedEpisodeOfCare={this.props.setSelectedEpisodeOfCare}
          setSelectedLocation={this.props.setSelectedLocation}
          setSelectedSchedule={this.props.setSelectedSchedule}
          scheduleItems={this.props.scheduleItems}
          locationItems={this.props.locationItems}
          setSelectedScheduleById={this.props.setSelectedScheduleById}
          getScheduledOptions={this.props.getScheduledOptions}
          openDialogWithSchedule={this.props.openDialogWithSchedule}
          openProviderLookupDialog={this.props.openProviderLookupDialog}
          getLocationById={this.props.getLocationById}
          isProviderLookupDialogOpen={this.props.isProviderLookupDialogOpen}
          showContractModal={this.props.showContractModal}
          setReturn={this.props.setReturn}
          closeTab={this.props.closeTab}
          history={this.props.history}
          location={this.props.location}
          match={match}
          workflowStyling={true}
          setGetClaimValuesForStaticAction={this.props.setUpdateParentAfterStaticAction}
        />
      )
    } else {
      return (
        <ViewClaim
          loadClaim={this.props.loadClaim}
          loadEpisodeOfCare={this.props.loadEpisodeOfCare}
          selectedLocation={this.props.selectedLocation}
          selectedSchedule={this.props.selectedSchedule}
          setSelectedCMS1500={this.props.setSelectedCMS1500}
          setSelectedEpisodeOfCare={this.props.setSelectedEpisodeOfCare}
          setSelectedLocation={this.props.setSelectedLocation}
          setSelectedSchedule={this.props.setSelectedSchedule}
          showContractModal={this.props.showContractModal}
          setReturn={this.props.setReturn}
          closeTab={this.props.closeTab}
          history={this.props.history}
          location={this.props.location}
          match={match}
          workflowStyling={true}
        />
      )
    }
  }

  public saveClaim() {}

  public render() {
    return this.state.eocId ? (
      <WorkflowInbox
        inboxType={InboxType.Claim}
        item={this.renderClaim()}
        headerInfo={this.state.headerInfo}
        icon={<AttachMoney />}
      />
    ) : (
      <></>
    )
  }
}

const InjectedClaimInbox = inject<
  IStores,
  IClaimInboxProps,
  Partial<IClaimInboxProps>,
  any
>((stores: IStores) => ({
  checkDuplicateBills: stores.cms1500.checkDuplicateBills,
  closeModal: stores.cms1500.closeDialog,
  duplicateCMS1500: stores.cms1500.duplicateCMS1500,
  episodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  getProviderSuggestions: stores.quotes.getProviderSuggestions,
  getTypesByCategory: stores.quotes.getTypesByCategory,
  isCMS1500Loading: stores.cms1500.isLoading,
  isCurrentOrganizationSurgical: stores.global.isCurrentOrganizationSurgical,
  isEpisodeOfCareLoading: stores.patientEdit.isLoading,
  isLoading: stores.quotes.isLoading,
  isModalOpen: stores.cms1500.isModalOpen,
  loadClaim: stores.cms1500.loadCMS1500,
  loadEpisodeOfCare: stores.patientEdit.getEpisodeOfCareById,
  openModal: stores.cms1500.openDialog,
  providers: stores.quotes.providers,
  ptTypes: stores.quotes.ptTypes,
  rerouteToPath: stores.global.rerouteToPath,
  saveCMS1500: stores.cms1500.saveCMS1500,
  selectedCMS1500: stores.cms1500.selectedCMS1500,
  selectedEpisodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  selectedLocation: stores.locations.selectedEditClaimLocation,
  selectedSchedule: stores.schedules.selectedSchedule,
  setSelectedCMS1500: stores.cms1500.setSelectedCMS1500,
  setSelectedEpisodeOfCare: stores.patientEdit.setSelectedEpisodeOfCare,
  setSelectedLocation: stores.locations.setSelectedEditClaimLocation,
  setSelectedSchedule: stores.schedules.setSelectedSchedule,
  scheduleItems: stores.patientEdit.scheduleItems,
  locationItems: stores.schedules.scheduleItems,
  setSelectedScheduleById: stores.schedules.setSelectedScheduleById,
  getScheduledOptions: stores.patientEdit.getScheduledOptions,
  openDialogWithSchedule: stores.schedules.openDialogWithSchedule,
  openProviderLookupDialog: stores.schedules.openClaimProviderLookupDialog,
  getLocationById: stores.locations.getLocationById,
  isProviderLookupDialogOpen: stores.schedules.isClaimProviderLookupDialogOpen,
  showContractModal: stores.cms1500.openProviderGroupContractDialog,
  setReturn: stores.global.setReturn,
  closeTab: stores.global.closeTab,
  setUpdateParentAfterStaticAction: stores.cms1500.setGetClaimValuesForStaticAction,
}))(ClaimInbox)

export default InjectedClaimInbox
