import Axios from 'axios'
import { IClaimSearchRequest } from 'src/viewModels/ClaimSearchRequest'
import { ClaimsAcqLogDTO } from 'src/viewModels/ClaimsAcqLogDTO'
import { IClaimsAcquisitionLogSearchRequest } from 'src/viewModels/ClaimsAcquisitionLogSearchRequest'
import { IClaimsAcquisitionSearchRequest } from 'src/viewModels/ClaimsAcquisitionSearchRequest'
import { pagedList } from 'src/viewModels/pagedList'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function searchClaimsAcquisition(
  req: IClaimsAcquisitionSearchRequest
): Promise<pagedList> {
  //Send search request
  const response = await Axios.post<pagedList>(
    `${base_url}/api/claims-acquisition/search`,
    req
  )

  return response.data
}

export async function GetClaimsAcquisitionLogByScheduleId(
  req: IClaimsAcquisitionLogSearchRequest
): Promise<pagedList> {
  //Send search request
  const response = await Axios.post<pagedList>(
    `${base_url}/api/claims-acquisition/logs`,
    req
  )

  return response.data
}

export async function CreateClaimsAcquisitionLog(
  req: ClaimsAcqLogDTO
): Promise<pagedList> {
  //Send search request
  const response = await Axios.post<pagedList>(
    `${base_url}/api/claims-acquisition/log`,
    req
  )

  return response.data
}

export async function viewClaimsBySchedule(req: IClaimSearchRequest): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/claims-acquisition/view-claims`,
    req
  )

  return response.data
}
