import React from 'react'

export default class Tab {
  constructor(
    public child: React.ReactNode,
    public label: string,
    private icon?: React.ReactElement
  ) {}

  public getIcon = () => {
    return this.icon ? this.icon : undefined
  }
}
