export interface IPaymentLineItemDetailSummary {
  dateOfService: string | undefined
  cptCode: string | undefined
  amount: string | undefined
  amountPaid: string | undefined
  amountPaidDisplay: string | undefined
  checkNumber: string | undefined
  reasonCode: string | undefined
  checkDate: string | undefined
  appliedBy: string | undefined
  appliedDatePaid: string | undefined
  modifiedBy: string | undefined
  modifiedOn: Date | undefined
  lineNumber: string | undefined
  refund: boolean
  payer?: string
  pendingCheck: boolean
  voidedCheck: boolean
}

export class PaymentLineItemDetailSummary implements IPaymentLineItemDetailSummary {
  constructor(summaryObject: IPaymentLineItemDetailSummary) {
    this.dateOfService = summaryObject.dateOfService
    this.cptCode = summaryObject.cptCode
    this.amount = summaryObject.amount
    this.amountPaid = summaryObject.amountPaid
    this.amountPaidDisplay = summaryObject.amountPaidDisplay
    this.checkNumber = summaryObject.checkNumber
    this.reasonCode = summaryObject.reasonCode
    this.checkDate = summaryObject.checkDate
    this.appliedDatePaid = summaryObject.appliedDatePaid
    this.appliedBy = summaryObject.appliedBy
    this.modifiedOn = summaryObject.modifiedOn
    this.modifiedBy = summaryObject.modifiedBy
    this.lineNumber = summaryObject.lineNumber
    this.refund = summaryObject.refund
    this.payer = summaryObject.payer
    this.pendingCheck = summaryObject.pendingCheck
    this.voidedCheck = summaryObject.voidedCheck
  }
  public dateOfService: string | undefined
  public cptCode: string | undefined
  public amount: string | undefined
  public amountPaid: string | undefined
  public amountPaidDisplay: string | undefined
  public checkNumber: string | undefined
  public reasonCode: string | undefined
  public checkDate: string | undefined
  public appliedBy: string | undefined
  public appliedDatePaid: string | undefined
  public modifiedBy: string | undefined
  public modifiedOn: Date | undefined
  public lineNumber: string | undefined
  public refund: boolean
  public payer?: string
  public pendingCheck: boolean
  public voidedCheck: boolean
}
