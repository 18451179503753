import { Lock } from '@material-ui/icons'
import * as _ from 'lodash'
import { inject } from 'mobx-react'
import * as React from 'react'
import { IMenuItem } from '../common/ESDataTable'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IRoleDto } from '../generated_client'
import { IStores } from '../Stores'
import RoleDialog from './RoleDialog'

export type IRole = typeof DefaultRole & IRoleDto
export let DefaultRole = {
  id: '',
  isMenuOpen: false,
  isNew: true,
  menuItems: [] as IMenuItem[],
  name: '',
  usersInRole: undefined as number | undefined,
}

interface IRolesProps {
  getColumnSettingsAndRoles?: () => void
  dataTableManager?: IDataTableManager<IRole>
  deleteRole?: (role: IRole) => void
  isModalOpen?: boolean
  openDialogWithRole?: (role: IRole) => void
}

@inject((stores: IStores) => ({
  dataTableManager: stores.roles.dataTableStore,
  deleteRole: stores.roles.deleteRole,
  getColumnSettingsAndRoles: stores.roles.getColumnSettingsAndRoles,
  isModalOpen: stores.roles.isModalOpen,
  openDialogWithRole: stores.roles.openDialogWithRole,
}))
class Roles extends React.Component<IRolesProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndRoles!()
  }

  public render() {
    return (
      <>
        <ResponsiveAppBar title="Roles" pageIcon={<Lock />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          addButtonOnClick={this.openDialog(DefaultRole)}
        />
        {this.props.isModalOpen && <RoleDialog />}
      </>
    )
  }

  public openDialog = (role: IRole) => () => {
    this.props.openDialogWithRole!(role)
  }
}

export default Roles
