import { IHaveAddressFormValues } from '../common/AddressField/AddressField'
import {
  createAddressFormValues,
  toAddressDto,
} from '../common/AddressField/AddressFormValues'
import { DefaultEmployer, IEmployer } from '../Definitions'
import { ContactDto } from '../generated_client'

export interface IEmployerFormValues extends IHaveAddressFormValues {
  readonly id: string
  name: string
  comments: string
  contacts: ContactDto[]
  isSpecialPricing: boolean
  isActive: boolean
  legacyId: string
}

function setDefaultValues(): IEmployerFormValues {
  return {
    address: createAddressFormValues(),
    comments: '',
    contacts: [],
    id: '',
    isActive: true,
    isSpecialPricing: false,
    legacyId: '',
    name: '',
  }
}

function setValuesFromEmployer(employer: IEmployer): IEmployerFormValues {
  return {
    address: createAddressFormValues(employer.address),
    comments: employer.comments || '',
    contacts: employer.contacts || [],
    id: employer.id || '',
    isActive: employer.isActive,
    isSpecialPricing: employer.isSpecialPricing || false,
    legacyId: employer.legacyId || '',
    name: employer.name || '',
  }
}

export function createIEmployerFormValues(employer?: IEmployer): IEmployerFormValues {
  if (employer === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromEmployer(employer)
  }
}

export async function toIEmployer(
  formValues: IEmployerFormValues,
  original?: IEmployer
): Promise<IEmployer> {
  const employer = original === undefined ? DefaultEmployer() : original
  const address = await toAddressDto(formValues.address!)
  return {
    ...employer,
    address,
    comments: formValues.comments,
    contacts: formValues.contacts,
    id: formValues.id,
    isActive: formValues.isActive,
    isSpecialPricing: formValues.isSpecialPricing,
    legacyId: formValues.legacyId,
    name: formValues.name,
  }
}
