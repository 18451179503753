import {
  DefaultEpisodeOfCare,
  DefaultSurgeryCode,
  IEpisodeOfCare,
} from '../../Definitions'
import { IReferringDoctorDto, SurgeryCodeDto, UserDto } from '../../generated_client'
import { createITestFormValues, ITestFormValues, toITest } from '../Test/TestFormValues'

export interface IEpisodeOfCareFormValues {
  bodyPartText: string
  calledPatientFlag: boolean
  careCoordinator?: UserDto
  careCoordinatorId: string
  careCoordinatorName?: string
  cptCodes: ISurgeryCodeLineItemFormValues[]
  ctScan: string
  dxCodes: ISurgeryCodeLineItemFormValues[]
  effectiveDate?: Date
  episodeOfCareNumber: string
  emg: string
  readonly id: string
  isActive: boolean
  isClinicalNotesReceived: boolean
  isNoPtToBeScheduled: boolean
  isPatientOnBoarding: boolean
  invoiceStatus: string
  invoiceSurgeryStatus: string
  leftVoicemailFlag: boolean
  mri: string
  noAnswerFlag: boolean
  notes: string
  organizationId: string
  quoteId?: string
  readyToScheduleFlag: boolean
  referringDoctor: IReferringDoctorDto | undefined
  referringDoctorId: string
  referringDoctorContactId: string
  sentTextFlag: boolean
  xRay: string
  tests: ITestFormValues[]
  claimNotes: string
}

function setDefaultValues(): IEpisodeOfCareFormValues {
  return {
    bodyPartText: '',
    calledPatientFlag: false,
    careCoordinatorId: '',
    cptCodes: [],
    ctScan: '',
    dxCodes: [],
    effectiveDate: undefined,
    emg: '',
    episodeOfCareNumber: '',
    id: '',
    invoiceStatus: 'Active',
    invoiceSurgeryStatus: 'Active',
    isActive: true,
    isClinicalNotesReceived: false,
    isNoPtToBeScheduled: false,
    isPatientOnBoarding: false,
    leftVoicemailFlag: false,
    mri: '',
    noAnswerFlag: false,
    notes: '',
    organizationId: '',
    readyToScheduleFlag: false,
    referringDoctor: undefined,
    referringDoctorContactId: '',
    referringDoctorId: '',
    sentTextFlag: false,
    tests: [],
    xRay: '',
    claimNotes: '',
  }
}

function setValuesFromEpisodeOfCare(
  episodeOfCare: IEpisodeOfCare
): IEpisodeOfCareFormValues {
  return {
    bodyPartText: episodeOfCare.bodyPartText || '',
    calledPatientFlag: episodeOfCare.calledPatientFlag,
    careCoordinator: episodeOfCare.careCoordinator,
    careCoordinatorId: episodeOfCare.careCoordinatorId || '',
    careCoordinatorName: episodeOfCare.careCoordinator
      ? episodeOfCare.careCoordinator.name
      : '',
    cptCodes: fillBlankSurgeryCodes(
      episodeOfCare.cptCodes.map(createISurgeryCodeLineItemFormValues)
    ),
    ctScan: episodeOfCare.ctScan || '',
    dxCodes: fillBlankSurgeryCodes(
      episodeOfCare.dxCodes.map(createISurgeryCodeLineItemFormValues)
    ),
    effectiveDate: episodeOfCare.effectiveDate || undefined,
    emg: episodeOfCare.emg || '',
    episodeOfCareNumber: episodeOfCare.episodeOfCareNumber
      ? episodeOfCare.episodeOfCareNumber.toString()
      : undefined || 'Auto-Generated',
    id: episodeOfCare.id || '',
    invoiceStatus: episodeOfCare.invoiceStatus || '',
    invoiceSurgeryStatus: episodeOfCare.invoiceSurgeryStatus || '',
    isActive: episodeOfCare.isActive || false,
    isClinicalNotesReceived: episodeOfCare.isClinicalNotesReceived || false,
    isNoPtToBeScheduled: episodeOfCare.isNoPtToBeScheduled || false,
    isPatientOnBoarding: episodeOfCare.isPatientOnboarding || false,
    leftVoicemailFlag: episodeOfCare.leftVoicemailFlag,
    mri: episodeOfCare.mri || '',
    noAnswerFlag: episodeOfCare.noAnswerFlag,
    notes: episodeOfCare.notes || '',
    organizationId: episodeOfCare.organizationId || '',
    quoteId: episodeOfCare.quoteId || undefined,
    readyToScheduleFlag: episodeOfCare.readyToScheduleFlag,
    referringDoctor: undefined,
    referringDoctorContactId: episodeOfCare.referringDoctorContactId || '',
    referringDoctorId: episodeOfCare.referringDoctorId || '',
    sentTextFlag: episodeOfCare.sentTextFlag,
    tests: episodeOfCare.tests.map((x) => createITestFormValues(x)),
    xRay: episodeOfCare.xRay || '',
    claimNotes: episodeOfCare.claimNotes || '',
  }
}

export function createIEpisodeOfCareFormValues(
  episodeOfCare?: IEpisodeOfCare
): IEpisodeOfCareFormValues {
  if (episodeOfCare === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromEpisodeOfCare(episodeOfCare)
  }
}

export function fillBlankSurgeryCodes(
  surgeryCodeList: ISurgeryCodeLineItemFormValues[]
): ISurgeryCodeLineItemFormValues[] {
  let diff = 5 - surgeryCodeList.length
  const newList = Array<ISurgeryCodeLineItemFormValues>()
  if (diff > 0) {
    for (diff; diff > 0; diff--) {
      const lineItem = createISurgeryCodeLineItemFormValues()
      lineItem.priority = 5 - diff
      newList.push(lineItem)
    }
  }
  return surgeryCodeList.concat(newList)
}

export function filterSurgeryCodes(
  surgeryCodeList: ISurgeryCodeLineItemFormValues[]
): ISurgeryCodeLineItemFormValues[] {
  const codeList = surgeryCodeList.filter((x) => x.code !== '').sort((x) => x.priority)
  return codeList.map((code, idx) => {
    code.priority = idx
    return code
  })
}

export function toIEpisodeOfCare(
  formValues: IEpisodeOfCareFormValues,
  original?: IEpisodeOfCare
): IEpisodeOfCare {
  const episodeOfCare = original === undefined ? DefaultEpisodeOfCare() : original
  return {
    ...episodeOfCare,
    bodyPartText: formValues.bodyPartText,
    calledPatientFlag: formValues.calledPatientFlag,
    careCoordinator: undefined,
    careCoordinatorId: formValues.careCoordinatorId,
    cptCodes: filterSurgeryCodes(formValues.cptCodes).map(
      ISurgeryCodeLineItemFormValuesToDto
    ),
    ctScan: formValues.ctScan,
    dxCodes: filterSurgeryCodes(formValues.dxCodes).map(
      ISurgeryCodeLineItemFormValuesToDto
    ),
    effectiveDate: formValues.effectiveDate,
    emg: formValues.emg,
    episodeOfCareNumber:
      formValues.episodeOfCareNumber !== 'Auto-Generated'
        ? Number(formValues.episodeOfCareNumber)
        : undefined,
    id: formValues.id,
    invoiceStatus: formValues.invoiceStatus,
    invoiceSurgeryStatus: formValues.invoiceSurgeryStatus,
    isActive: formValues.isActive,
    isClinicalNotesReceived: formValues.isClinicalNotesReceived,
    isNoPtToBeScheduled: formValues.isNoPtToBeScheduled,
    isPatientOnboarding: formValues.isPatientOnBoarding,
    leftVoicemailFlag: formValues.leftVoicemailFlag,
    mri: formValues.mri,
    noAnswerFlag: formValues.noAnswerFlag,
    notes: formValues.notes,
    organizationId: formValues.organizationId,
    quoteId: formValues.quoteId,
    readyToScheduleFlag: formValues.readyToScheduleFlag,
    referringDoctorContactId: formValues.referringDoctorContactId || '',
    referringDoctorId: formValues.referringDoctorId || '',
    sentTextFlag: formValues.sentTextFlag,
    tests: formValues.tests.map((x) => toITest(x)),
    xRay: formValues.xRay,
    claimNotes: formValues.claimNotes,
  }
}

export interface ISurgeryCodeLineItemFormValues {
  code: string
  readonly id: string
  priority: number
}

export function createISurgeryCodeLineItemFormValues(
  surgeryCode?: SurgeryCodeDto
): ISurgeryCodeLineItemFormValues {
  if (surgeryCode) {
    return {
      code: surgeryCode.code!.trim() || '',
      id: surgeryCode.id || '',
      priority: surgeryCode.priority || 0,
    }
  }
  return DefaultSurgeryCode()
}

export function ISurgeryCodeLineItemFormValuesToDto(
  formValues: ISurgeryCodeLineItemFormValues
): SurgeryCodeDto {
  return new SurgeryCodeDto({
    code: formValues.code!.trim() || '',
    id: formValues.id,
    priority: formValues.priority,
  })
}
