import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { inject } from 'mobx-react'
import React from 'react'
import { ITag } from '../Definitions'
import { IStores } from '../Stores'

interface ITagDeleteDialogProps {
  close?: () => void
  tag?: ITag
  tagReferences?: number
  disableTag?: (tag: ITag) => void
  isOpen?: boolean
  getTagReferences?: () => Promise<void>
}

class TagDeleteDialog extends React.Component<ITagDeleteDialogProps> {
  public deleteTag = () => {
    if (this.props.tag) {
      this.props.disableTag!(this.props.tag)
    }
    this.props.close!()
  }

  public render() {
    const { close, isOpen } = this.props
    return (
      <Dialog
        maxWidth="sm"
        open={isOpen!}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Tag</DialogTitle>
        <DialogContent>
          <Grid justifyContent="center" alignItems="center" container spacing={2}>
            <Grid item xs={10}>
              <Typography>
                {this.props.tagReferences
                  ? `This tag is currently being used on ${this.props.tagReferences.toLocaleString()} communication, contact, or provider entries. Are you sure you want to delete it? This action cannot be undone.`
                  : `Are you sure you want to delete this tag? This action cannot be undone.`}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button onClick={this.deleteTag} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const InjectedTagDeleteDialog = inject<
  IStores,
  ITagDeleteDialogProps,
  Partial<ITagDeleteDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.tags.closeDeleteDialog,
  disableTag: stores.tags.disableTag,
  getTagReferences: stores.tags.getTagReferences,
  isOpen: stores.tags.isDeleteDialogOpen,
  tag: stores.tags.selectedTag,
  tagReferences: stores.tags.selectedTagReferences,
}))(TagDeleteDialog)

export default InjectedTagDeleteDialog
