import { Field } from 'formik'
import React from 'react'
import InterceptCheckboxWithLabel from './InterceptCheckboxWithLabel'

interface ICheckboxFieldProps {
  name: string
  label: string
}

const CheckboxField: React.FunctionComponent<ICheckboxFieldProps> = (props) => {
  return (
    <Field
      name={props.name}
      Label={{
        label: props.label,
      }}
      component={InterceptCheckboxWithLabel}
    />
  )
}

export default CheckboxField
