import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { inject } from 'mobx-react'
import * as React from 'react'
import { pagedList } from 'src/viewModels/pagedList'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IStores } from '../Stores'
import ReactPaginate from 'react-paginate'
import { Helmet } from 'react-helmet'
import {
  AddCircle,
  CheckBox,
  CheckBoxOutlineBlank,
  LocalHospital,
} from '@material-ui/icons'
import { ProviderGroupSummaryResult } from 'src/viewModels/ProviderGroupSummaryResult'
import { searchProviderGroups } from 'src/services/SearchService'
import { ProviderGroupSearchRequest } from 'src/viewModels/ProviderGroupSearchRequest'
import ESMenu from 'src/common/ESMenu'
import { ChangeEvent } from 'react'
import States from 'src/utils/States'
import Constants from '../config'
import { IUser } from 'src/Definitions'

const styles = ({}: Theme) =>
  createStyles({
    strikeOut: {
      textDecoration: 'line-through red',
    },
  })

interface IProviderGroupsProps extends WithStyles<typeof styles> {
  buildProviderGroupMenuItems?: (acq: ProviderGroupSummaryResult) => any[]
  onCreateProvider?: () => void
  setUpdateParentStateAfterDeactivate: (parentfunction?: () => void) => void
  user?: IUser
}

class ProviderGroups extends React.Component<IProviderGroupsProps> {
  public state = {
    providerTypes: new Array<string>(),
    gridData: new pagedList().items as ProviderGroupSummaryResult[],
    searchLocationName: '',
    searchGroupName: '',
    searchState: '',
    searchBillingServicer: '',
    searchCity: '',
    searchProviderTypes: new Array<string>(),
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 25,
    gridLoading: false,
  }

  public async componentDidMount() {
    this.setState({
      providerTypes: new Array<string>('PC', 'TC', 'AN', 'PT', 'MI'),
    })
    this.props.setUpdateParentStateAfterDeactivate(this.getProviderData)
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems === 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={13} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((acq, index) => (
        <TableRow
          key={index}
          className={`gridPadding ${acq.isInactive ? this.props.classes.strikeOut : ''}`}
        >
          <TableCell className={acq.isInactive ? this.props.classes.strikeOut : ''}>
            {acq.groupName}
          </TableCell>
          <TableCell>{acq.locations}</TableCell>
          <TableCell>{acq.activeLocationCount}</TableCell>
          <TableCell>{acq.inactiveLocationCount}</TableCell>
          <TableCell>{acq.providerTypes}</TableCell>
          <TableCell>{this.renderContextMenu(acq, index)}</TableCell>
        </TableRow>
      ))
    }
  }

  private getProviderData = async () => {
    this.setState({ gridLoading: true })
    const { page, pageSize } = this.state
    const requestData = new ProviderGroupSearchRequest()
    requestData.page = page
    requestData.pageSize = pageSize
    requestData.groupName = this.state.searchGroupName
    requestData.locationName = this.state.searchLocationName
    requestData.city = this.state.searchCity
    requestData.state = this.state.searchState
    requestData.billingServicer = this.state.searchBillingServicer
    requestData.providerType = this.state.searchProviderTypes

    await searchProviderGroups(requestData).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        gridLoading: false,
      })

      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private search = (event: any) => {
    this.setState({ page: 1 }, () => this.getProviderData())
    event.preventDefault()
  }

  private clearSearch = () => {
    this.setState(
      {
        page: 1,
        searchLocationName: '',
        searchGroupName: '',
        searchState: '',
        searchProviderTypes: new Array<string>(),
        searchBillingServicer: '',
        searchCity: '',
      },
      () => {
        this.setState({ gridData: undefined, pageCount: 0, totalItems: 0 })
      }
    )
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getProviderData())
  }

  private handleGroupNameChange = (event: any) => {
    this.setState({ searchGroupName: event.target.value })
  }

  private handleLocationNameChange = (event: any) => {
    this.setState({ searchLocationName: event.target.value })
  }

  private handleStateChange = (event: any) => {
    this.setState({ searchState: event.target.value })
  }

  private handleCity = (event: any) => {
    this.setState({ searchCity: event.target.value })
  }

  private handleBillingServicerChange = (event: any) => {
    this.setState({ searchBillingServicer: event.target.value })
  }

  private handleProviderTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (this.state.searchProviderTypes.includes(event.currentTarget.dataset.value!)) {
      this.state.searchProviderTypes = this.state.searchProviderTypes.filter(
        (x) => x != event.currentTarget.dataset.value!
      )
      this.setState({ searchProviderTypes: this.state.searchProviderTypes })
    } else {
      this.state.searchProviderTypes.push(event.currentTarget.dataset.value!)
      this.setState({ searchProviderTypes: this.state.searchProviderTypes })
    }
  }

  public createProvider = () => {
    this.props.onCreateProvider!()
  }

  private renderContextMenu = (acq: ProviderGroupSummaryResult, index: number) => {
    let options = this.props.buildProviderGroupMenuItems!(acq)

    return (
      <ESMenu
        data={acq}
        menuItems={options}
        rowIndex={index}
        menuAriaLabel={`$provider-group-${index}-menu`}
      />
    )
  }

  public render() {
    const MenuProps = {
      getContentAnchorEl: null,
      PaperProps: {
        style: {
          maxHeight: 400,
        },
      },
    }

    const disableAdd = !this.props.user?.roles.includes(Constants.Roles.Administrator)
    return (
      <>
        <Helmet>
          <title>Provider Groups</title>
        </Helmet>
        <Grid container style={{ width: '100%', paddingBottom: '20px' }}>
          <ResponsiveAppBar title={'Provider Groups'} pageIcon={<LocalHospital />} />
          {this.state.gridLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '2',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <form onSubmit={this.search}>
            <Grid
              container
              style={{ margin: '10px 0px', paddingLeft: '30px', width: '100%' }}
              md={12}
            >
              <Typography
                color="primary"
                component="h6"
                variant="h6"
                gutterBottom
                style={{ paddingBottom: '10px' }}
              >
                SEARCH CRITERIA
              </Typography>
              <Grid container style={{ width: '100%' }} direction="row" spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchGroupName}
                      onChange={this.handleGroupNameChange}
                      label="Provider Group Name"
                      name="providerGroupName"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchLocationName}
                      onChange={this.handleLocationNameChange}
                      label="Location Name"
                      name="locationName"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchBillingServicer}
                      onChange={this.handleBillingServicerChange}
                      label="Billing Servicer"
                      name="billingServicer"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel shrink={true} variant="outlined">
                      Provider Types
                    </InputLabel>
                    <Select
                      variant="outlined"
                      input={<OutlinedInput notched labelWidth={105} />}
                      inputProps={{ label: true, notched: true }}
                      fullWidth
                      id="providerTypes"
                      multiple
                      value={this.state.searchProviderTypes}
                      renderValue={() => this.state.searchProviderTypes.join(', ')}
                      onChange={this.handleProviderTypeChange}
                      MenuProps={MenuProps}
                    >
                      {this.state.providerTypes.map((item) => (
                        <MenuItem value={item} data-name={item}>
                          {this.state.searchProviderTypes.includes(item) ? (
                            <CheckBox
                              color="secondary"
                              style={{ paddingRight: '10px' }}
                            />
                          ) : (
                            <CheckBoxOutlineBlank
                              color="action"
                              style={{ paddingRight: '10px' }}
                            />
                          )}{' '}
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchCity}
                      onChange={this.handleCity}
                      label="Physical City"
                      name="city"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <FormControl style={{ width: '100%' }} variant="outlined">
                    <InputLabel shrink={true} variant="outlined">
                      State
                    </InputLabel>
                    <Select
                      variant="outlined"
                      input={<OutlinedInput notched labelWidth={35} />}
                      inputProps={{ label: true, notched: true }}
                      native={true}
                      fullWidth
                      onChange={this.handleStateChange}
                      id="stateSelect"
                      value={this.state.searchState}
                    >
                      <option value=""></option>
                      {States.map((state) => (
                        <option value={state.name}>{state.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{
                    marginLeft: '8px',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                  }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="contained"
                    style={{ marginRight: '10px' }}
                  >
                    Search
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.clearSearch}
                    size="medium"
                    variant="contained"
                  >
                    Clear Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Grid
            item
            xs={12}
            style={{ width: '100%', textAlign: 'end', paddingRight: '20px' }}
          >
            <AddCircle
              color={disableAdd ? 'disabled' : 'primary'}
              fontSize="large"
              cursor={disableAdd ? 'default' : 'pointer'}
              onClick={disableAdd ? () => {} : this.createProvider}
            />
          </Grid>
          <Grid item md={12}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="gridPadding">
                  <TableCell>Provider Group</TableCell>
                  <TableCell>Locations</TableCell>
                  <TableCell># of Active Locations</TableCell>
                  <TableCell># of Inactive Locations</TableCell>
                  <TableCell>Types</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '100%' }}>{this.mapGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                forcePage={this.state.page - 1}
                pageCount={this.state.pageCount}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}

const InjectedProviderGroups = inject<
  IStores,
  IProviderGroupsProps,
  Partial<IProviderGroupsProps>,
  any
>((stores: IStores) => ({
  buildProviderGroupMenuItems: stores.providers.buildProviderGroupMenuItems,
  onCreateProvider: stores.providers.createProvider,
  setUpdateParentStateAfterDeactivate:
    stores.providers.setUpdateParentStateAfterDeactivate,
  user: stores.global.user,
}))(ProviderGroups)

export default withStyles(styles)(InjectedProviderGroups)
