export interface IProviderGroupSearchRequest {
  groupName?: string
  locationName?: string
  city?: string
  state?: string
  providerType?: Array<string>
  billingServicer?: string
  page?: number
  pageSize?: number
}

export class ProviderGroupSearchRequest implements IProviderGroupSearchRequest {
  public groupName?: string
  public locationName?: string
  public city?: string
  public state?: string
  public providerType?: Array<string>
  public billingServicer?: string
  public page?: number | undefined
  public pageSize?: number | undefined
}
