import {
  AppBar,
  createStyles,
  Tab as MuiTab,
  Tabs as MuiTabs,
  withStyles,
} from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import React, { Component } from 'react'
import Tab from './Tab'
import TabPanel from './TabPanel'

const StyledMuiTab = withStyles(() =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: fade('#29348F', 0.1),
      },
    },
  })
)(MuiTab)
interface ITabsProps {
  centered?: boolean
  color?: 'inherit' | 'default' | 'primary' | 'secondary'
  fullWidth?: boolean
  tabs: Tab[]
  isHeaderElevated?: boolean
  isHeaderTransparent?: boolean
  isTextColored?: boolean
  tabColor?: 'inherit' | 'primary' | 'secondary'
}
interface ITabsState {
  currentTab: number
}

export default class Tabs extends Component<ITabsProps, ITabsState> {
  constructor(props: ITabsProps) {
    super(props)

    this.state = {
      currentTab: 0,
    }
  }

  private tabProps = (index: number) => {
    return {
      'aria-controls': `tabpanel-${index}`,
      id: `tab-${index}`,
    }
  }

  private handleChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    this.setState({ currentTab: newTab })
  }

  public render() {
    const {
      centered,
      color,
      fullWidth,
      isHeaderElevated = true,
      isHeaderTransparent = false,
      tabColor,
    } = this.props

    const indicatorColor =
      tabColor === 'secondary' || tabColor === 'primary' ? tabColor : undefined

    const styles: React.CSSProperties = { zIndex: '10' }
    if (!isHeaderElevated) {
      styles.boxShadow = 'none'
    }
    if (isHeaderTransparent) {
      styles.background = 'transparent'

      return (
        <>
          <AppBar style={styles} position="static" color={color}>
            <MuiTabs
              textColor={tabColor}
              indicatorColor={indicatorColor}
              value={this.state.currentTab}
              onChange={this.handleChange}
              centered={centered}
              variant={fullWidth ? 'scrollable' : undefined}
            >
              {this.props.tabs.map((tab: Tab, index: number) => {
                return (
                  <StyledMuiTab
                    label={tab.label}
                    icon={tab.getIcon()}
                    {...this.tabProps(index)}
                    key={index}
                  />
                )
              })}
            </MuiTabs>
          </AppBar>
          {this.props.tabs.map((tab: Tab, index: number) => {
            return (
              <TabPanel value={this.state.currentTab} index={index} key={index}>
                {tab.child}
              </TabPanel>
            )
          })}
        </>
      )
    } else {
      return (
        <>
          <AppBar style={styles} position="static" color={color}>
            <MuiTabs
              textColor={tabColor}
              indicatorColor={indicatorColor}
              value={this.state.currentTab}
              onChange={this.handleChange}
              centered={centered}
              variant={fullWidth ? 'scrollable' : undefined}
            >
              {this.props.tabs.map((tab: Tab, index: number) => {
                return (
                  <MuiTab
                    label={tab.label}
                    icon={tab.getIcon()}
                    {...this.tabProps(index)}
                    key={index}
                  />
                )
              })}
            </MuiTabs>
          </AppBar>
          {this.props.tabs.map((tab: Tab, index: number) => {
            return (
              <TabPanel value={this.state.currentTab} index={index} key={index}>
                {tab.child}
              </TabPanel>
            )
          })}
        </>
      )
    }
  }
}
