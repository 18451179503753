import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultCptCode, ICptCode } from '../../Definitions'
import {
  Client,
  CptCodeDto,
  ICptCodeDto,
  SurgeryCodeDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'CptCodeDto'

export default class CptCodesStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public cptCodes: ICptCode[]
  @observable
  public newCptCodesToSave: SurgeryCodeDto[]
  @observable
  public selectedCptCode?: ICptCode
  public dataTableStore: DataTableStore<ICptCodeDto, ICptCode>

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<ICptCodeDto, ICptCode>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllCptCodes('', filter, page, undefined, orderBy, includeInactives),
      (cptCode) => this.setupCptCodes(cptCode)
    )
  }

  @action.bound
  public setNewCptCodesToSave(cptCodes: SurgeryCodeDto[]) {
    this.newCptCodesToSave = cptCodes
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedCptCode = undefined
  }

  @action.bound
  public deleteCptCode(cptCode: ICptCode) {
    this.isLoading = true
    return this.client
      .archiveCptCode(cptCode.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndCptCodes() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllCptCodes(cptCodeList?: string[]) {
    this.isLoading = true
    return this.client
      .getAllCptCodes(JSON.stringify(cptCodeList), undefined, 1, 5000)
      .then((resp: SwaggerResponse<ICptCodeDto[]>) =>
        runInAction(() => {
          this.cptCodes = this.setupCptCodes(resp.result)
        })
      )
  }

  @action.bound
  public openDialogWithCptCode(cptCode: ICptCode) {
    this.isModalOpen = true
    this.selectedCptCode = cptCode
  }

  @action.bound
  public recoverCptCode(cptCode: ICptCode) {
    this.isLoading = true
    return this.client
      .reactivateCptCode(cptCode.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveCptCode(cptCode: ICptCode) {
    this.isLoading = true
    let promise
    const cptCodeDto = new CptCodeDto(cptCode)
    if (cptCode.isNew) {
      promise = this.client.createCptCode(cptCodeDto)
    } else {
      promise = this.client.updateCptCode(cptCode.id, cptCodeDto)
    }
    return promise
      .then(() => {
        this.resetUIState(cptCode.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupCptCodes = (cptCodes: ICptCodeDto[]) => {
    return cptCodes.map((x) => this.setupCptCodeMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (cptCode: ICptCodeDto): ICptCode => {
    return { ...DefaultCptCode(), ...cptCode }
  }

  private setupCptCodeMenuItems = (cptCode: ICptCode) => {
    cptCode.menuItems = [
      {
        icon: Edit,
        name: 'Edit',
        onClick: () => this.openDialogWithCptCode(cptCode),
      },
    ]
    if (cptCode.isActive) {
      cptCode.menuItems.push({
        color: '#930A0A',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Delete',
        onClick: () => this.deleteCptCode(cptCode),
      })
    } else {
      cptCode.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverCptCode(cptCode),
      })
    }
    return cptCode
  }
}
