import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CreditCard, Warning } from '@material-ui/icons'
import { Formik, getIn } from 'formik'
import { inject } from 'mobx-react'
// tslint:disable-next-line: no-var-requires
const CurrencyFormat: any = require('react-currency-format')
import Moment from 'moment'
import React from 'react'
import FullWidthField from 'src/common/FullWidthField'
import NumberMaskedInput from 'src/common/NumberMaskedInput'
import { IStores } from 'src/Stores'
import {
  createICheckFormValues,
  ICheckFormValues,
  toIApplyPayment,
} from 'src/receivePayment/CheckFormValues'
import {
  getBillOutPaymentDetails,
  retrieveLineItemsWithBalance,
  VerifyCheck,
} from 'src/services/BillOutService'
import { IPaymentLineItem, PaymentLineItem } from 'src/viewModels/PaymentLIneItem'
import { formatCurrency, formatDate } from 'src/utils/Formatter'
import {
  IPaymentLineItemDetailSummary,
  PaymentLineItemDetailSummary,
} from 'src/viewModels/PaymentLineItemDetailSummary'
import { ProcessReceivedCheckResults } from 'src/viewModels/ProcessReceivedCheckResult'
import { IPaymentDetailSummaryResult } from 'src/viewModels/PaymentDetailSummaryResult'
import { VerifyCheckRequest } from 'src/viewModels/VerifyCheckRequest'
import * as Yup from 'yup'
import moment from 'moment'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import { ObjectiveProcessAction } from 'src/viewModels/workflow/ObjectiveProcessAction'
import { ILineItemSummaryResult } from 'src/viewModels/LineItemSummaryResult'
import ProcessReceivedCheckModal from 'src/common/ProcessReceivedCheckModal'
import { processAction, processDynamicAction } from 'src/services/WorkflowService'
import { OptionsObject } from 'notistack'
import { BillOutCustomForm } from 'src/utils/BillOutCustomForm'
import { BillStatusNumber } from 'src/utils/BillStatusNumber'

const styles = () =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        '&:hover': {
          backgroundColor: '#E1E1E1',
        },
        backgroundColor: '#EEEEEE',
      },
    },
    bold: {
      fontWeight: 'bold',
      paddingRight: '10px',
    },
  })

const maximumDate = moment().subtract(0, 'days')
const ReceiveBilloutPaymentModalSchema = Yup.object().shape({
  amount: Yup.number().required('Required').min(0, 'Please enter a positive number.'),
  checkNumber: Yup.string().required('Required'),
  datePaid: Yup.date()
    .max(maximumDate.toLocaleString(), 'Date Cannot be in the future!')
    .required('Required'),
  payer: Yup.string().required('Required'),
  applyAmount: Yup.number()
    .required('Required')
    .min(0, 'Please enter a positive number.'),
})

interface IReceiveBilloutPaymentModalProps extends WithStyles<typeof styles> {
  isOpen: boolean
  closeModal: () => void
  openProcessReceivedCheckDialog?: (results: ProcessReceivedCheckResults) => void
  openVerifyCheckDialog?: (results: ProcessReceivedCheckResults) => void
  action: InboxActionSummary
  isDynamicItem: boolean
  rerouteToPath?: (path: string) => void
  inboxItemId: number
  inboxId: string
  associatedItemId: string
  data: ObjectiveProcessAction
  entryUser: string
  nextInboxId?: number
  nextInboxText?: string
  handleProcessActionFinish?: (selectedAction?: InboxActionSummary) => void
  actionList: InboxActionSummary[]
  sendNotification?: (
    key: string,
    message: string,
    options?: OptionsObject,
    information?: string | undefined
  ) => void
}

class ReceiveBilloutPaymentModal extends React.Component<IReceiveBilloutPaymentModalProps> {
  public state = {
    isCheckVerified: false,
    isPaymentValidated: false,
    totalMismatch: false,
    checkAmountLessThanApplyAmount: false,
    moreThanBalance: false,
    lineItemDetailsWithBalance: undefined as unknown as IPaymentLineItem[],
    appliedPayments: undefined as unknown as IPaymentLineItemDetailSummary[],
    paymentSummary: undefined as unknown as IPaymentDetailSummaryResult,
    isLoading: false,
    comments: '',
    applyPaymentWillInFull: false,
    payer: '',
  }

  negativeArray = new Array<boolean>()

  public secondFormRef = React.createRef<any>()

  public async componentDidMount() {
    this.setState({
      isLoading: true,
    })

    this.validateActionConfiguration()

    const promises = [] as Array<Promise<void>>
    promises.push(this.loadLineItemDetailsWithBalance())
    promises.push(this.loadAppliedPayments())

    await Promise.all(promises).finally(() => {
      this.state.lineItemDetailsWithBalance?.forEach(() => {
        this.negativeArray.push(false)
      })

      this.setState({ isLoading: false })
    })
  }

  private validateActionConfiguration() {
    var paidInFull = this.props.actionList.find(
      (x) =>
        x.customFormName == BillOutCustomForm.ReceiveBilloutPayment &&
        x.statusId == BillStatusNumber.Paid &&
        x.id != this.props.action.id
    )

    if (paidInFull == null) {
      this.props.sendNotification!(
        '404',
        'This action is incorrectly configured. Please contact your administrator.',
        {
          variant: 'error',
        }
      )
      this.close()
    }
  }

  private loadLineItemDetailsWithBalance = async () => {
    await retrieveLineItemsWithBalance(this.props.associatedItemId).then(
      (response: ILineItemSummaryResult[]) => {
        this.setState({
          lineItemDetailsWithBalance: response.map((item) => new PaymentLineItem(item)),
        })
      }
    )
  }

  private loadAppliedPayments = async () => {
    await getBillOutPaymentDetails(this.props.associatedItemId).then(
      (response: IPaymentDetailSummaryResult) => {
        this.setState({
          appliedPayments: response.lineItems.map(
            (item) => new PaymentLineItemDetailSummary(item)
          ),
          paymentSummary: response,
          payer: response.insuranceCompanyName!,
        })
      }
    )
  }

  private getFormikValues = async () => {
    const formikValues = this.secondFormRef.current?.state?.values
    await this.secondFormRef.current.submitForm()
    return formikValues
  }

  private saveCheck = async () => {
    const formValues = await this.getFormikValues()

    let totalCharge = 0
    var i = 0

    for (const formValue of formValues.itemList) {
      const num = +(formValue.paymentAmount ?? 0)
      if (num < 0) {
        this.negativeArray[i] = true
        this.secondFormRef.current.setSubmitting(false)
        return
      } else {
        this.negativeArray[i] = false
      }
      totalCharge += num * 100
      i++
    }
    totalCharge = totalCharge / 100

    var remainingBalance = formValues.itemList
      .filter(
        (x: { remainingBalance: number | undefined } | undefined) =>
          x != undefined &&
          x.remainingBalance != undefined &&
          !isNaN(x.remainingBalance) &&
          x.remainingBalance.toString() != ''
      )
      .map((x: { remainingBalance: any }) =>
        parseFloat((x.remainingBalance ?? 0) as unknown as string)
      )
      .reduce((a: any, b: any) => a! + b!, 0)

    this.setState({
      totalMismatch: totalCharge != formValues.applyAmount,
      checkAmountLessThanApplyAmount:
        Number(formValues.amount ?? 0) < Number(formValues.applyAmount ?? 0),
      moreThanBalance: remainingBalance < formValues.applyAmount,
      applyPaymentWillInFull: remainingBalance == Number(formValues.applyAmount ?? 0),
    })

    if (this.state.totalMismatch || this.state.checkAmountLessThanApplyAmount) {
      this.setState({ isPaymentValidated: false })
      this.secondFormRef.current.setSubmitting(false)
      return
    } else if (this.state.moreThanBalance && !this.state.isPaymentValidated) {
      this.setState({ isPaymentValidated: true })
      this.secondFormRef.current.setSubmitting(false)
      return
    } else if (!this.state.isPaymentValidated) {
      this.setState({ isPaymentValidated: true })
      this.secondFormRef.current.setSubmitting(false)
    } else {
      this.props.data.billoutCheckData = toIApplyPayment(
        formValues,
        this.props.associatedItemId!
      )
      var action = this.props.action
      this.props.data.comment = this.state.comments

      let paidInFullAction = this.props.actionList.find(
        (x) =>
          x.customFormName == BillOutCustomForm.ReceiveBilloutPayment &&
          x.statusId == BillStatusNumber.Paid &&
          x.id != this.props.action.id
      )

      let executeAction =
        this.state.applyPaymentWillInFull && paidInFullAction ? paidInFullAction : action

      if (this.props.isDynamicItem) {
        processDynamicAction(
          this.props.data,
          this.props.inboxId.toString(),
          executeAction.id.toString(),
          this.props.associatedItemId.toString()
        )
          .then(() => {
            this.props.handleProcessActionFinish!(executeAction)
            this.props.closeModal()
          })
          .catch(() => {
            this.setState({ executed: false, loading: false })
          })
      } else {
        processAction(
          this.props.data,
          executeAction.id.toString(),
          this.props.inboxItemId.toString()
        )
          .then(() => {
            this.props.handleProcessActionFinish!(executeAction)
            this.close()
          })
          .catch(() => {
            this.setState({ executed: false, isLoading: false })
            this.close()
          })
      }
    }
  }

  private close = () => {
    this.setState({
      isCheckVerified: false,
      isPaymentValidated: false,
      totalMismatch: false,
      checkAmountLessThanApplyAmount: false,
      moreThanBalance: false,
      applyPaymentWillInFull: false,
    })

    this.negativeArray = new Array<boolean>()
    this.props.closeModal!()
  }

  private renderCheckList() {
    return (
      <Table size="small">
        <TableHead>
          <TableRow className="gridPadding">
            <TableCell align="right">Check Date</TableCell>
            <TableCell>Check Number</TableCell>
            <TableCell>Payer</TableCell>
            <TableCell align="right">Applied Date</TableCell>
            <TableCell>Applied By</TableCell>
            <TableCell align="right">DOS</TableCell>
            <TableCell align="right">Line#</TableCell>
            <TableCell>CPT Code</TableCell>
            <TableCell align="right">Amount Paid</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.appliedPayments ? (
            this.state.appliedPayments.map((record, index) => {
              return (
                <TableRow className="gridPadding" key={index}>
                  <TableCell align="right">{formatDate(record.checkDate!)}</TableCell>
                  <TableCell>{record.checkNumber}</TableCell>
                  <TableCell>{record.payer}</TableCell>
                  <TableCell align="right">
                    {formatDate(record.appliedDatePaid!)}
                  </TableCell>
                  <TableCell>{record.appliedBy!}</TableCell>
                  <TableCell align="right">{formatDate(record.dateOfService!)}</TableCell>
                  <TableCell align="right">{record.lineNumber}</TableCell>
                  <TableCell>{record.cptCode}</TableCell>
                  <TableCell align="right">
                    {record.amountPaid ? formatCurrency(record.amountPaid) : ''}
                  </TableCell>
                </TableRow>
              )
            })
          ) : (
            <TableRow key="blank">
              <TableCell colSpan={9} align="center">
                <i>No records found</i>
              </TableCell>
            </TableRow>
          )}
          <TableRow key="footer">
            <TableCell colSpan={7}>
              <div />
            </TableCell>
            <TableCell>
              <strong>Total:</strong>
            </TableCell>
            <TableCell colSpan={2} align="right">
              {this.state.appliedPayments ? (
                <CurrencyFormat
                  value={this.state
                    .appliedPayments!.map((x) => Number(x.amountPaid) || 0)
                    .reduce((a, b) => a! + b!, 0)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              ) : (
                <span>$0.00</span>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  private renderLineItemList(itemList: IPaymentLineItem[]) {
    return (
      <Table style={{ paddingTop: '10px' }} size="small">
        <TableHead>
          <TableRow key={'cpt-header'}>
            <TableCell align="right">Date of Service</TableCell>
            <TableCell align="right">Line</TableCell>
            <TableCell>CPT</TableCell>
            <TableCell colSpan={4}>Modifier</TableCell>
            <TableCell align="right">Charge Amount</TableCell>
            <TableCell align="right">Remaining Balance</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemList ? (
            itemList!.map((y, lineIdx) => {
              return (
                <TableRow key={`q24-${lineIdx}`}>
                  <TableCell align="right">
                    {Moment(y.dateOfService).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell align="right">{y.lineNumber}</TableCell>
                  <TableCell>{y.dProcedureCptHcpcs}</TableCell>
                  <TableCell>{y.dModifier1}</TableCell>
                  <TableCell>{y.dModifier2}</TableCell>
                  <TableCell>{y.dModifier3}</TableCell>
                  <TableCell>{y.dModifier4}</TableCell>
                  <TableCell align="right">
                    <CurrencyFormat
                      value={y.billOutAmount}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <CurrencyFormat
                      value={y.remainingBalance}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <FullWidthField
                      inputProps={{
                        max: '1000000',
                        style: {
                          maxWidth: '90px',
                          padding: '4px',
                        },
                      }}
                      variant="outlined"
                      name={`[itemList[${lineIdx}].paymentAmount`}
                      onKeyUp={() => this.resetPaymentValidate()}
                    />
                    {this.negativeArray[lineIdx] && (
                      <div className="error" style={{ paddingTop: '10px' }}>
                        Please enter a positive number.
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              )
            })
          ) : (
            <TableRow key="blank">
              <TableCell colSpan={10} align="center">
                <i>None</i>
              </TableCell>
            </TableRow>
          )}
          <TableRow key="footer">
            <TableCell colSpan={7} align="right">
              <strong>Total:</strong>
            </TableCell>
            <TableCell align="right">
              {itemList ? (
                <CurrencyFormat
                  value={itemList!
                    .map((x) => x.billOutAmount || 0)
                    .reduce((a, b) => a! + b!, 0)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              ) : (
                <span>$0.00</span>
              )}
            </TableCell>
            <TableCell align="right">
              {itemList ? (
                <CurrencyFormat
                  value={itemList!
                    .map((x) => x.remainingBalance || 0)
                    .reduce((a, b) => a! + b!, 0)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              ) : (
                <span>$0.00</span>
              )}
            </TableCell>
            <TableCell align="right">
              {itemList ? (
                <CurrencyFormat
                  value={itemList!
                    .map((x) => +(x.paymentAmount || 0))
                    .reduce((a, b) => a! + b!, 0)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              ) : (
                <span>$0.00</span>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  private resetVerify() {
    if (this.state.isCheckVerified != false) {
      this.setState({ isCheckVerified: false })
    }
    if (this.state.moreThanBalance != false) {
      this.setState({ moreThanBalance: false })
    }
    if (this.state.totalMismatch != false) {
      this.setState({ totalMismatch: false })
    }
    if (this.state.checkAmountLessThanApplyAmount != false) {
      this.setState({ checkAmountLessThanApplyAmount: false })
    }
    if (this.state.applyPaymentWillInFull != false) {
      this.setState({ applyPaymentWillInFull: false })
    }
  }

  private resetPaymentValidate() {
    if (this.state.isPaymentValidated != false) {
      this.setState({ isPaymentValidated: false })
    }
  }

  private verifyCheck = async (formValues: ICheckFormValues) => {
    this.setState({ isCheckVerified: true })
    var checkRequest = new VerifyCheckRequest()
    checkRequest.checkNumber = formValues.checkNumber || ''
    checkRequest.payer = formValues.payer || ''
    checkRequest.checkDate = formValues.datePaid || new Date()
    checkRequest.checkAmount = formValues.amount || 0

    await VerifyCheck(checkRequest).then((response: ProcessReceivedCheckResults) => {
      this.setState({ isCheckVerified: true })
      this.props.openVerifyCheckDialog!(response)
    })
  }

  public render() {
    const { isOpen } = this.props
    const hasValue = (field: string, values: any) => {
      const value = getIn(values, field)

      return !!value
    }
    return (
      <>
        <ProcessReceivedCheckModal />
        {!this.state.isLoading && (
          <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={isOpen!}
            disableBackdropClick={true}
          >
            <Formik
              initialValues={createICheckFormValues(
                this.props.associatedItemId!,
                undefined,
                this.state.lineItemDetailsWithBalance!,
                this.state.payer
              )}
              onSubmit={() => {}}
              validationSchema={ReceiveBilloutPaymentModalSchema}
              ref={this.secondFormRef}
            >
              {({ values, isValid, errors }) => (
                <form id="secondForm">
                  <DialogTitle style={{ padding: 0 }}>
                    <ResponsiveAppBar
                      title={this.props.action.actionName}
                      pageIcon={<CreditCard />}
                      isModal={true}
                    />
                  </DialogTitle>
                  <DialogContent>
                    {this.state.isLoading && (
                      <div
                        style={{
                          position: 'fixed',
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          cursor: 'pointer',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: '1200',
                        }}
                      >
                        <CircularProgress size={100} />
                      </div>
                    )}
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      style={{ marginTop: 8 }}
                    >
                      <Grid container item direction="row" spacing={2}>
                        <Grid item style={{ whiteSpace: 'nowrap' }}>
                          <span className={this.props.classes.bold}>EOC/Legacy:</span>
                          <span>{this.state.paymentSummary?.groupNumber}</span>
                        </Grid>
                        <Grid item style={{ whiteSpace: 'nowrap' }}>
                          <span className={this.props.classes.bold}>Patient Name:</span>
                          <span>{this.state.paymentSummary?.patientName}</span>
                        </Grid>
                        <Grid item style={{ whiteSpace: 'nowrap' }}>
                          <span className={this.props.classes.bold}>Patient DOB:</span>
                          <span>
                            {this.state.paymentSummary?.patientDob
                              ? moment(this.state.paymentSummary?.patientDob).format('L')
                              : ''}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid item container direction="row" spacing={2}>
                        <Grid item xs={12} md={2}>
                          <FullWidthField
                            format="YYYY-MM-DD"
                            type="date"
                            name="datePaid"
                            label="Check Date"
                            inputId="datePaid"
                            fullWidth={true}
                            clearable={true}
                            shrink={hasValue('datePaid', values)}
                            required
                            errorMessage={errors.datePaid}
                            onKeyUp={() => this.resetVerify()}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <FullWidthField
                            required
                            name="checkNumber"
                            label="Check Number"
                            InputLabelProps={{
                              shrink: hasValue('checkNumber', values),
                            }}
                            onKeyUp={() => this.resetVerify()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FullWidthField
                            required
                            name="payer"
                            label="Payer"
                            InputLabelProps={{
                              shrink: hasValue('payer', values),
                            }}
                            onKeyUp={() => this.resetVerify()}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <FullWidthField
                            required
                            name="amount"
                            label="Check Amount"
                            InputProps={{
                              inputComponent: NumberMaskedInput,
                              inputProps: {
                                currency: true,
                                guide: false,
                                numberOfDigits: 12,
                              },
                            }}
                            InputLabelProps={{
                              shrink: hasValue('amount', values),
                            }}
                            onKeyUp={() => this.resetVerify()}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <Button
                            onClick={() => this.verifyCheck(values)}
                            size="medium"
                            variant="outlined"
                            className="gridButton"
                            color="primary"
                            data-cy="verify check"
                            disabled={
                              !hasValue('datePaid', values) ||
                              !hasValue('checkNumber', values) ||
                              !hasValue('amount', values) ||
                              !hasValue('payer', values) ||
                              this.state.isCheckVerified == true
                            }
                          >
                            {'Verify Check'}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={2} style={{ paddingLeft: '23px' }}>
                          <FullWidthField
                            required
                            name="applyAmount"
                            label="Amount to Apply"
                            InputProps={{
                              inputComponent: NumberMaskedInput,
                              inputProps: {
                                currency: true,
                                guide: false,
                                numberOfDigits: 12,
                              },
                            }}
                            InputLabelProps={{
                              shrink: hasValue('applyAmount', values),
                            }}
                            onKeyUp={() => this.resetPaymentValidate()}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {this.renderLineItemList(values.itemList)}
                      </Grid>
                      <ExpansionPanel style={{ marginTop: '10px' }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography color="textSecondary" variant="body2">
                            PAST RECEIVED PAYMENTS
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {this.renderCheckList()}
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <Grid
                        item
                        container
                        direction="column"
                        wrap="nowrap"
                        alignItems="flex-end"
                      >
                        <Grid item xs={12} style={{ paddingTop: '0px' }}>
                          {this.state.totalMismatch && (
                            <div className="error">
                              Sum of claim line amounts must equal Amount to Apply
                              entered.
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: '0px' }}>
                          {this.state.checkAmountLessThanApplyAmount && (
                            <div className="error">
                              Amount to Apply must be less than or equal to Check Amount.
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: '0px' }}>
                          {this.state.moreThanBalance &&
                            !this.state.checkAmountLessThanApplyAmount &&
                            !this.state.totalMismatch && (
                              <Grid item style={{ display: 'flex' }}>
                                <Warning
                                  alignmentBaseline="baseline"
                                  style={{
                                    color: '#ffa726',
                                    marginRight: '8px',
                                  }}
                                />
                                <Typography
                                  variant={'body1'}
                                  color="textPrimary"
                                  style={{ height: '18px' }}
                                >
                                  Amount to Apply is greater than total remaining balance.
                                </Typography>
                              </Grid>
                            )}
                        </Grid>
                      </Grid>
                      {this.props.action.displayComment && (
                        <Grid item xs={12} style={{ marginTop: '16px' }}>
                          <TextField
                            label="Comments"
                            name="notes"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required={this.props.action.requireComment}
                            multiline
                            minRows="4"
                            onChange={(event) =>
                              this.setState({ comments: event.target.value })
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      data-cy="save"
                      onClick={() => this.saveCheck()}
                      disabled={
                        !(
                          isValid &&
                          this.state.isCheckVerified &&
                          this.state.isPaymentValidated &&
                          this.state.applyPaymentWillInFull
                        )
                      }
                    >
                      Paid In Full
                    </Button>
                    <Button
                      style={{
                        display: !this.state.isPaymentValidated ? 'none' : undefined,
                      }}
                      variant="contained"
                      color="primary"
                      data-cy="save"
                      onClick={() => this.saveCheck()}
                      disabled={
                        !(
                          isValid &&
                          this.state.isPaymentValidated &&
                          !this.state.applyPaymentWillInFull
                        ) ||
                        (this.props.action.requireComment && this.state.comments === '')
                      }
                    >
                      Execute
                    </Button>
                    <Button
                      style={{
                        display: this.state.isPaymentValidated ? 'none' : undefined,
                      }}
                      variant="contained"
                      color="primary"
                      data-cy="save"
                      onClick={() => this.saveCheck()}
                      disabled={
                        !(isValid && this.state.isCheckVerified) ||
                        (this.props.action.requireComment && this.state.comments === '')
                      }
                    >
                      Validate
                    </Button>
                    <Button onClick={this.close} data-cy="close">
                      Cancel
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </Dialog>
        )}
      </>
    )
  }
}

const InjectedReceiveBilloutPaymentModal = inject<
  IStores,
  IReceiveBilloutPaymentModalProps,
  Partial<IReceiveBilloutPaymentModalProps>,
  any
>((stores: IStores) => ({
  close: stores.receivePayments.closeDialog,
  selectedCMS1500Id: stores.receivePayments.selectedCMS1500Id,
  openProcessReceivedCheckDialog: stores.receivePayments.openProcessReceivedCheckDialog,
  openVerifyCheckDialog: stores.receivePayments.openVerifyReceivedCheckDialog,
  getHeaderInfo: stores.patients.getHeaderInfo,
  rerouteToPath: stores.global.rerouteToPath,
  sendNotification: stores.notifications.sendNotification,
}))(withStyles(styles)(ReceiveBilloutPaymentModal))

export default InjectedReceiveBilloutPaymentModal
