import {
  Button,
  Collapse,
  createStyles,
  Grid,
  IconButton,
  Popover,
  TextField,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { AddCircle, ExpandLess, ExpandMore, FilterList } from '@material-ui/icons'
import { observer } from 'mobx-react'
import * as React from 'react'
import ESDataTable, { IESDataTableProps } from './ESDataTable'
import ESDataTableFilterDialog from './ESDataTableFilterDialog/ESDataTableFilterDialog'
import ITableOptions from './ITableOptions'

const styles = createStyles({
  gridContainer: {
    paddingBottom: '10px',
  },
  pageContainer: {
    padding: '8px 24px',
  },
  searchField: {
    marginBottom: '5px',
  },
  tableHeader: {
    fontSize: '20px',
    paddingTop: '4px',
  },
})

export interface IESDataTableWithHeaderProps {
  isAddButtonDisabled?: boolean
  elevation?: number
  enableMultiSelect?: boolean
  enableSearch?: boolean
  enableShowInactives?: boolean
  enableFilters?: boolean
  filterComponents?: JSX.Element
  headerContents?: JSX.Element
  headerPadding?: string | number
  addButtonOnClick?: () => void
  padding?: string | number
  additionalHeaderFeatures?: JSX.Element[]
  title?: string
  icon?: React.ComponentType<SvgIconProps>
  rowsPerPage?: number
  rowCellStyleFunction?: (data: any, columnFieldName?: string) => React.CSSProperties
  isCollapsible?: boolean
  expanded?: boolean
  verifiedIcon?: JSX.Element
  selectable?: boolean
}

@observer
class ESDataTableWithHeader extends React.Component<
  IESDataTableWithHeaderProps & IESDataTableProps & WithStyles<typeof styles>
> {
  public state = {
    anchorEl: null,
    isExpanded: this.props.expanded,
    showFilters: false,
  }
  private handleFiltersClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    let anchorEl = null
    if (!this.state.showFilters) {
      anchorEl = event.currentTarget
    }
    this.setState({ anchorEl, showFilters: !this.state.showFilters })
  }
  public render() {
    const {
      additionalHeaderFeatures = [],
      classes,
      dataTableManager,
      elevation,
      enableMultiSelect,
      enableSearch,
      enableShowInactives,
      enableFilters = true,
      filterComponents,
      headerContents,
      headerPadding,
      isCollapsible,
      padding,
      addButtonOnClick,
      isAddButtonDisabled = false,
      title,
      rowCellStyleFunction,
      ...rest
    } = this.props
    const search = enableSearch && (
      <TextField
        className={classes.searchField}
        label="Search"
        margin="none"
        name="filter"
        onChange={this.onSearchChanged}
        value={dataTableManager.tableOptions.filter}
      />
    )

    const addButtonComponent = addButtonOnClick && (
      <Grid item style={{ color: '#AAAAAA' }}>
        <AddCircle
          color={isAddButtonDisabled ? 'inherit' : 'primary'}
          onClick={isAddButtonDisabled ? undefined : addButtonOnClick}
          style={{
            fontSize: '30px',
            marginRight: '8px',
            cursor: isAddButtonDisabled ? 'auto' : 'pointer',
          }}
        />
      </Grid>
    )

    function setUpAdditionalHeaderFeatures() {
      return additionalHeaderFeatures.map((feature: JSX.Element) => (
        <Grid item>{feature}</Grid>
      ))
    }

    const onArrowClick = (event: React.MouseEvent) => {
      event.stopPropagation()
      this.setState({ isExpanded: !this.state.isExpanded })
    }

    const arrowButton = (
      <>
        {this.state.isExpanded ? (
          <IconButton onClick={onArrowClick}>
            <ExpandLess />
          </IconButton>
        ) : (
          <IconButton onClick={onArrowClick}>
            <ExpandMore />
          </IconButton>
        )}
      </>
    )

    return (
      <Grid className={classes.pageContainer} container direction="column">
        <Grid
          alignItems="center"
          className={classes.gridContainer}
          style={{ padding: headerPadding }}
          container
          item
          justifyContent="space-between"
          wrap="nowrap"
          spacing={1}
        >
          <Grid container item alignItems="center" spacing={2}>
            {this.tableHeader()}
            <Grid item>{search}</Grid>
            {setUpAdditionalHeaderFeatures()}
          </Grid>
          {enableFilters && (
            <>
              {isCollapsible && <Grid item> {arrowButton} </Grid>}
              <Grid item>
                <Button
                  onClick={this.handleFiltersClick}
                  style={{ textTransform: 'none' }}
                  size="small"
                >
                  <FilterList style={{ fontSize: '32px' }} color="secondary" />
                </Button>
                <Popover
                  open={this.state.showFilters}
                  transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                  }}
                  anchorEl={this.state.anchorEl}
                >
                  <ESDataTableFilterDialog
                    dataTableManager={dataTableManager}
                    filterComponents={filterComponents}
                    enableShowInactives={enableShowInactives}
                    updateTableOptions={this.updateTableOptions}
                    handleFilterClickAway={this.handleFilterClickAway}
                  />
                </Popover>
              </Grid>
            </>
          )}

          {addButtonComponent}
        </Grid>
        {headerContents ? (
          <Grid container item>
            {headerContents}
          </Grid>
        ) : (
          <></>
        )}
        {this.props.isCollapsible ? (
          <Collapse in={this.state.isExpanded}>
            <ESDataTable
              elevation={elevation}
              enableMultiSelect={enableMultiSelect}
              {...rest}
              dataTableManager={dataTableManager}
              rowCellStyleFunction={rowCellStyleFunction}
            />
          </Collapse>
        ) : (
          <ESDataTable
            elevation={elevation}
            enableMultiSelect={enableMultiSelect}
            {...rest}
            dataTableManager={dataTableManager}
            rowCellStyleFunction={rowCellStyleFunction}
            verifiedIcon={this.props.verifiedIcon}
            selectable={this.props.selectable}
            title={title}
          />
        )}
      </Grid>
    )
  }
  private tableHeader(): React.ReactNode {
    const title = this.props.title
    const icon = this.props.icon

    return (
      <>
        {icon && (
          <Grid item>
            <SvgIcon component={icon} style={{ fontSize: '32px' }} color="secondary" />
          </Grid>
        )}
        {title && (
          <Grid item className={this.props.classes.tableHeader}>
            {title}
          </Grid>
        )}
      </>
    )
  }

  private handleFilterClickAway = () => {
    this.setState({
      showFilters: false,
    })
  }

  private onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    this.updateTableOptions({ filter: value, page: 1 })
  }

  private updateTableOptions = (tableOptions: ITableOptions) => {
    const options = {
      ...this.props.dataTableManager.tableOptions,
      ...tableOptions,
    }
    this.props.dataTableManager.updateTableOptions(options)
  }
}

export default withStyles(styles)(ESDataTableWithHeader)
