import { Grid, IconButton, InputAdornment, Paper, withTheme } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Form, Formik, FormikActions } from 'formik'
import { Location, LocationState } from 'history'
import { inject } from 'mobx-react'
import { OutputParams, parse } from 'query-string'
import * as React from 'react'
import Button from '../common/Button/Button'
import FullWidthField from '../common/FullWidthField'
import { IStores } from '../Stores'
import { IPasswordPattern } from '../stores/LoginStore'
import * as Yup from 'yup'
import logo from '../OBXIcon.svg'
import ForgotPasswordDialog from './ForgotPasswordDialog'

type ILoginValues = typeof DefaultLoginValues

const DefaultLoginValues = {
  confirmPassword: '',
  password: '',
  username: '',
}

const styles = ({ palette }: Theme) =>
  createStyles({
    backDropContainer: {
      backgroundColor: 'grey',
      height: '100vh',
    },
    eContainer: {
      backgroundColor: palette.primary[500],
      borderRadius: '4px',
      color: 'white',
      fontSize: '86px',
      marginBottom: '20px',
      padding: '16px 40px',
    },
    loginDialog: {
      padding: '60px 40px',
    },
  })

interface ILoginProps extends WithStyles<typeof styles> {
  getPasswordPattern?: () => void
  isLoading?: boolean
  isModalOpen?: boolean
  isResetTokenValid?: boolean
  location?: Location<LocationState>
  login?: (username: string, password: string) => Promise<void>
  openDialog?: () => void
  passwordPattern?: IPasswordPattern
  getTokenValidity?: (
    username: string | string[],
    resetToken: string | string[]
  ) => Promise<boolean>
  resetPassword?: (
    username: string,
    password: string,
    resetToken: string
  ) => Promise<void>
}

@inject((stores: IStores) => ({
  getPasswordPattern: stores.login.getPasswordPattern,
  getTokenValidity: stores.login.getTokenValidity,
  isLoading: stores.login.isLoading,
  isModalOpen: stores.login.isModalOpen,
  isResetTokenValid: stores.login.isResetTokenValid,
  location: stores.routing.location,
  login: stores.login.login,
  openDialog: () => stores.login.openDialog(),
  passwordPattern: stores.login.passwordPattern,
  resetPassword: stores.login.resetPassword,
}))
class Login extends React.Component<ILoginProps> {
  public state = {
    confirmPassword: '',
    isTokenValid: false,
    password: '',
    resetToken: '',
    showPassword: false,
    username: '',
  }

  public componentDidMount() {
    ;(window as any).formikRef = React.createRef()
    this.props.getPasswordPattern!()
    let queryParams: OutputParams = {
      resetToken: '',
      username: '',
    }
    if (this.props.location) {
      queryParams = parse(this.props.location!.search)
      if (queryParams) {
        if (queryParams.username) {
          this.setState({ username: queryParams.username })
        }
        if (queryParams.resetToken) {
          this.setState({ resetToken: queryParams.resetToken })
        }
      }
    }
    if (queryParams.username && queryParams.resetToken) {
      this.props.getTokenValidity!(queryParams.username, queryParams.resetToken)
    }
  }

  public login = (values: ILoginValues, formikBag: FormikActions<ILoginValues>) => {
    const { login, resetPassword } = this.props

    if (this.state.resetToken) {
      resetPassword!(values.username, values.password, this.state.resetToken)
        .then(() => {
          this.setState({ resetToken: '' })
        })
        .finally(() => {
          formikBag.resetForm()
          this.setState({ showPassword: false })
        })
    } else {
      login!(values.username, values.password).then(() => {
        formikBag.setSubmitting(false)
      })
    }
  }

  public onShowPasswordChange = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  public render() {
    const {
      classes,
      isLoading,
      isModalOpen,
      isResetTokenValid,
      openDialog,
      passwordPattern,
    } = this.props
    const { resetToken } = this.state

    let resendLinkField: React.ReactNode = null
    let submitButton = 'Login'

    let password = Yup.string().required('Password is Required')
    const username = Yup.string().required('Username is Required')
    let LoginSchema = Yup.object().shape({
      password,
      username,
    })

    if (resetToken) {
      submitButton = 'Reset'
      DefaultLoginValues.confirmPassword = ''
      const confirmPassword = Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords do not match')
        .required('Confirm Password is Required')
      if (passwordPattern && passwordPattern.regex) {
        password = password.matches(passwordPattern.regex, passwordPattern.failMessage)
      }
      LoginSchema = LoginSchema.shape({
        confirmPassword,
        password,
      })

      if (isResetTokenValid === false) {
        resendLinkField = (
          <Grid item xs={12}>
            <Button color="primary" variant="contained" onClick={openDialog}>
              You have used an expired password reset link. Please click here to request a
              new one.
            </Button>
          </Grid>
        )
      }
    }
    const forgotPasswordDialog = isModalOpen && <ForgotPasswordDialog />

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.backDropContainer}
      >
        <Grid item xs={10} sm={6} md={4} lg={3}>
          <Formik
            initialValues={DefaultLoginValues}
            onSubmit={this.login}
            validationSchema={LoginSchema}
            ref={(window as any).formikRef}
          >
            {() => (
              <Form>
                <Paper>
                  <Grid
                    alignItems="center"
                    container
                    direction="column"
                    justifyContent="center"
                    className={classes.loginDialog}
                  >
                    <img src={logo} alt="Objective Surgical" width="200" />
                    <Grid container spacing={3} justifyContent="center">
                      {resendLinkField}
                      <Grid item xs={12}>
                        <FullWidthField
                          label="Username"
                          name="username"
                          value={this.state.username}
                          disabled={resetToken !== '' && !isResetTokenValid}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FullWidthField
                          FormHelperTextProps={{
                            component: 'pre',
                          }}
                          label="Password"
                          value={this.state.password}
                          disabled={resetToken !== '' && !isResetTokenValid}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.onShowPasswordChange}
                                  disabled={resetToken !== '' && !isResetTokenValid}
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          name="password"
                          required
                          type={this.state.showPassword ? 'text' : 'password'}
                        />
                      </Grid>
                      {isResetTokenValid ? (
                        <Grid item xs={12}>
                          <FullWidthField
                            label="Confirm Password"
                            name="confirmPassword"
                            disabled={!isResetTokenValid}
                            required
                            type={this.state.showPassword ? 'text' : 'password'}
                          />
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          disabled={resetToken !== '' && !isResetTokenValid}
                          fullWidth
                          isLoading={isLoading}
                          type="submit"
                          variant="contained"
                        >
                          {submitButton}
                        </Button>
                      </Grid>
                      <div className="mt-20">
                        <Button
                          onClick={openDialog}
                          disabled={resetToken !== '' && !isResetTokenValid}
                        >
                          Forgot Password
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Form>
            )}
          </Formik>
        </Grid>
        {forgotPasswordDialog}
      </Grid>
    )
  }
}

export default withTheme(withStyles(styles)(Login))
