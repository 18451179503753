type Interceptor = (
  next: (r: Request) => Promise<Response>
) => (req: Request) => Promise<Response>

const send = (req: Request) => fetch(req)
class FetchService {
  private interceptors: Interceptor[]

  constructor() {
    this.interceptors = []
  }

  public addInterceptor(interceptor: Interceptor) {
    this.interceptors.push(interceptor)
  }

  public fetch(url: RequestInfo, init?: RequestInit) {
    const request = new Request(url, {
      ...init,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const sendRequest = this.interceptors.reduceRight((reqPipeline, interceptor) => {
      return interceptor(reqPipeline)
    }, send)

    return sendRequest(request)
  }
}

export default FetchService
export { Interceptor }
