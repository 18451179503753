import { Divider, Drawer, Grid, List, Tooltip } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import ChevronLeftOutlined from '@material-ui/icons/ChevronLeftOutlined'
import ChevronRightOutlined from '@material-ui/icons/ChevronRightOutlined'
import { inject } from 'mobx-react'
import * as React from 'react'
import ProfileListItem from '../../common/ProfileListItem'
import { IOrganization, IUser } from '../../Definitions'
import fullLogo from '../../Objective Surgical Logo-01.svg'
import logo from '../../OBXIcon.svg'
import { INavigationSection } from '../../Routes'
import { IStores } from '../../Stores'
import NavigationSection from '../NavigationSection/NavigationSection'

const styles = ({ spacing }: Theme) => {
  const drawerClosedWidth = spacing(9)
  const drawerExpandedWidth = 255
  return createStyles({
    drawerClosed: {
      width: drawerClosedWidth,
    },
    drawerExpanded: {
      width: drawerExpandedWidth,
    },
    drawerGrid: {
      height: '100%',
    },
    expandButton: {
      display: 'flex',
    },
    expandButtonDrawerClosed: {
      flexDirection: 'column',
    },
    expandButtonDrawerExpanded: {
      flexDirection: 'row',
    },
    expandDiv: {
      cursor: 'pointer',
      display: 'flex',
    },
    expandDivDrawerClosed: {
      marginLeft: spacing(3),
    },
    expandDivDrawerExpanded: {
      marginTop: spacing(2),
    },
    imageContainer: {
      height: spacing(8),
    },
  })
}

interface INavigationDrawerProps extends WithStyles<typeof styles> {
  currentAppOrganization?: IOrganization
  logout?: () => void
  navigationSections: INavigationSection[]
  isOpen: boolean
  isTooltipOpen: boolean
  toggleDrawer: () => void
  toggleDrawerTooltip?: () => void
  user?: IUser
}

class NavigationDrawer extends React.Component<INavigationDrawerProps> {
  public render() {
    const { classes, logout, isOpen, isTooltipOpen } = this.props
    const drawerClassName = this.getDrawerClassName()
    const finalCollapseClassName = this.getExpandButtonClassName()
    const finalCollapseDivClassName = this.getExpandButtonDivClassName()
    const expandCollapseToolTipText = isOpen
      ? 'Show Less Information'
      : 'Show More Information'

    return (
      <div>
        <Drawer variant="permanent" open={isOpen}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            className={drawerClassName}
            wrap="nowrap"
            style={{ position: 'sticky' }}
          >
            <Grid item>
              <Grid
                className={classes.imageContainer}
                container
                direction="row"
                alignItems="center"
                item
                justifyContent="center"
              >
                {this.props.isOpen ? (
                  <img src={fullLogo} alt="Objective Surgical" width="200" />
                ) : (
                  <img src={logo} alt="Objective Surgical" width="50" />
                )}
              </Grid>
              <Divider />
              {this.renderNavigationSections()}
            </Grid>
            <Grid item>
              <Divider />
              <List>
                <div className={finalCollapseClassName}>
                  <div>
                    <ProfileListItem expanded={isOpen} logout={logout!} />
                  </div>
                  <div className={finalCollapseDivClassName}>
                    <Tooltip
                      title={expandCollapseToolTipText}
                      placement="right-start"
                      open={isTooltipOpen}
                    >
                      {isOpen ? (
                        <ChevronLeftOutlined
                          fontSize="large"
                          onClick={this.props.toggleDrawer}
                          onMouseOver={this.props.toggleDrawerTooltip}
                          onMouseLeave={this.props.toggleDrawerTooltip}
                        />
                      ) : (
                        <ChevronRightOutlined
                          fontSize="large"
                          onClick={this.props.toggleDrawer}
                          onMouseOver={this.props.toggleDrawerTooltip}
                          onMouseLeave={this.props.toggleDrawerTooltip}
                        />
                      )}
                    </Tooltip>
                  </div>
                </div>
              </List>
            </Grid>
          </Grid>
        </Drawer>
      </div>
    )
  }

  private renderNavigationSections(): JSX.Element[] {
    const { currentAppOrganization, navigationSections, isOpen, user } = this.props

    return navigationSections.reduce<JSX.Element[]>((sections, section, index) => {
      const sectionElement = (
        <NavigationSection
          key={'section-' + index}
          isOpen={isOpen}
          navigationSection={section}
          user={user!}
          currentOrganizationId={currentAppOrganization ? currentAppOrganization.id : ''}
        />
      )
      if (sections.length > 0) {
        sections.push(<Divider key={'divider-' + index} />)
      }
      sections.push(sectionElement)
      return sections
    }, [])
  }

  private getDrawerClassName(): string {
    const { classes, isOpen } = this.props
    const classesToApply = [classes.drawerGrid]
    classesToApply.push(isOpen ? classes.drawerExpanded : classes.drawerClosed)
    return classesToApply.join(' ')
  }

  private getExpandButtonClassName(): string {
    const { classes, isOpen } = this.props
    const classesToApply = [classes.expandButton]
    classesToApply.push(
      isOpen ? classes.expandButtonDrawerExpanded : classes.expandButtonDrawerClosed
    )
    return classesToApply.join(' ')
  }

  private getExpandButtonDivClassName(): string {
    const { classes, isOpen } = this.props
    const classesToApply = [classes.expandDiv]
    classesToApply.push(
      isOpen ? classes.expandDivDrawerExpanded : classes.expandDivDrawerClosed
    )
    return classesToApply.join(' ')
  }
}

export default inject<
  IStores,
  INavigationDrawerProps,
  Partial<INavigationDrawerProps>,
  any
>((stores: IStores) => ({
  canSwitchOrganizations: stores.global.canSwitchOrganizations,
  currentAppOrganization: stores.global.currentAppOrganization,
  isTooltipOpen: stores.global.isToggleDrawerTooltipOpen,
  logout: () => stores.login.logout(),
  organizationsMenu: stores.organizations.sessionUserOrganizations,
  toggleDrawerTooltip: () => stores.global.toggleDrawerToolTip(),
  user: stores.global.user,
}))(withStyles(styles)(NavigationDrawer))
