import { Button, Tooltip, withStyles } from '@material-ui/core'
import { CheckCircle, Place } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import {
  DefaultLocation,
  DefaultLocationBilling,
  ILocation,
  ILocationBilling,
  IUser,
} from '../Definitions'
import { IStores } from '../Stores'
import LocationDialog from './LocationDialog'
import UpdateAllBillingAddressModal from 'src/locationBillings/UpdateAllBillingAddressesModal'
import Constants from '../config'

const styles = {}
interface ILocationsProps {
  dataTableManager?: IDataTableManager<ILocation>
  isModalOpen?: boolean
  isBillingModalOpen?: boolean
  locations?: ILocation[]
  openDialogWithLocation?: (location: ILocation) => void
  openBillingAddressModal?: (locationBilling: ILocationBilling) => void
  providerId: string
  user?: IUser
}

class Locations extends React.Component<ILocationsProps> {
  public render() {
    const locationDialog = this.props.isModalOpen && <LocationDialog />
    return (
      <>
        {this.props.isBillingModalOpen && (
          <UpdateAllBillingAddressModal providerId={this.props.providerId} />
        )}
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={true}
          enableToggleColumns={true}
          addButtonOnClick={this.openDialog(DefaultLocation(true))}
          isAddButtonDisabled={
            !this.props.user?.roles.includes(Constants.Roles.Administrator)
          }
          title="Locations"
          icon={Place}
          elevation={0}
          padding={0}
          rowsPerPage={5}
          verifiedIcon={
            <Tooltip title="Physical Address Verified">
              <CheckCircle color="primary" />
            </Tooltip>
          }
          selectable={false}
          additionalHeaderFeatures={
            this.props.locations?.some((x) => x.isActive) &&
            this.props.user?.roles.includes(Constants.Roles.Administrator)
              ? new Array<JSX.Element>(
                  (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        this.props.openBillingAddressModal!(DefaultLocationBilling(true))
                      }
                    >
                      Update Billing Addresses
                    </Button>
                  )
                )
              : undefined
          }
        />
        {locationDialog}
      </>
    )
  }

  public openDialog = (location: ILocation) => () => {
    this.props.openDialogWithLocation!(location)
  }
}

const InjectedLocations = inject<IStores, ILocationsProps, Partial<ILocationsProps>, any>(
  (stores: IStores) => ({
    dataTableManager: stores.locations.dataTableStore,
    isModalOpen: stores.locations.isModalOpen,
    locations: stores.locations.locations,
    openDialogWithLocation: stores.locations.openDialogWithLocation,
    openBillingAddressModal: stores.locationBillings.openDialogWithLocationBilling,
    isBillingModalOpen: stores.locationBillings.isModalOpen,
    user: stores.global.user,
  })
)(Locations)
export default withStyles(styles)(InjectedLocations)
