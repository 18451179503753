import {
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { Formik, getIn } from 'formik'
import { inject } from 'mobx-react'
import React from 'react'
import FullWidthField from '../../common/FullWidthField'
import NumberMaskedInput from '../../common/NumberMaskedInput'
import { IStores } from '../../Stores'
import * as Yup from 'yup'
import moment from 'moment'
import { CreditCard, Warning } from '@material-ui/icons'
import {
  GlobalInvoicePaymentSummary,
  IGlobalInvoicePaymentSummary,
} from 'src/viewModels/InvoicePaymentSummaryResult'
import PaymentHistoryPanel from '../../quotes/globalInvoice/PaymentHistoryPanel'
import { formatCurrency } from 'src/utils/Formatter'
import {
  createICheckFormValues,
  IGlobalInvoiceCheckFormValues,
  toIApplyPayment,
} from '../../quotes/globalInvoice/InvoiceCheckFormValues'
import { ProcessReceivedCheckResults } from 'src/viewModels/ProcessReceivedCheckResult'
import Button from 'src/common/Button'
import { VerifyCheckRequest } from 'src/viewModels/VerifyCheckRequest'
import { VerifyCheck } from 'src/services/BillOutService'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { InboxActionSummary } from 'src/viewModels/workflow/InboxActionSummary'
import { ObjectiveProcessAction } from 'src/viewModels/workflow/ObjectiveProcessAction'
import { OptionsObject } from 'notistack'
import { processAction } from 'src/services/WorkflowService'
import { GetInvoicePaymentDetails } from 'src/services/QuoteService'
import ProcessReceivedCheckModal from 'src/common/ProcessReceivedCheckModal'

const styles = () =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        '&:hover': {
          backgroundColor: '#E1E1E1',
        },
        backgroundColor: '#EEEEEE',
      },
    },
    bold: {
      fontWeight: 'bold',
      paddingRight: '10px',
    },
  })

const maximumDate = moment().subtract(0, 'days')

const ReceiveInvoicePaymentModalSchema = (amountDue: number) =>
  Yup.object().shape({
    checkAmount: Yup.number().required('Required'),
    applyAmount: Yup.number()
      .required('Required')
      .max(amountDue, 'Applied amount cannot exceed the Balance Due.'),
    checkNumber: Yup.string().required('Required'),
    payer: Yup.string().required('Required'),
    datePaid: Yup.date()
      .max(maximumDate.toLocaleString(), 'Date Cannot be in the future!')
      .required('Required'),
  })

interface IReceiveInvoicePaymentModalProps extends WithStyles<typeof styles> {
  openProcessReceivedCheckDialog?: (results: ProcessReceivedCheckResults) => void
  openVerifyCheckDialog?: (results: ProcessReceivedCheckResults) => void
  //new
  isOpen: boolean
  closeModal: () => void
  action: InboxActionSummary
  rerouteToPath?: (path: string) => void
  inboxItemId: number
  inboxId: string
  associatedItemId: string
  data: ObjectiveProcessAction
  entryUser: string
  nextInboxId?: number
  nextInboxText?: string
  sendNotification?: (
    key: string,
    message: string,
    options?: OptionsObject,
    information?: string | undefined
  ) => void
  handleProcessActionFinish?: (selectedAction?: InboxActionSummary) => void
}

class ReceiveInvoicePaymentModal extends React.Component<IReceiveInvoicePaymentModalProps> {
  public state = {
    paymentSummary: undefined as unknown as GlobalInvoicePaymentSummary,
    isCheckVerified: false,
    isPaymentValidated: false,
    checkAmountLessThanApplyAmount: false,
    moreThanBalance: false,
    executed: false,
    isLoading: true,
    comments: '',
    nextInboxId: undefined,
    nextInboxText: '',
  }

  public componentDidMount(): void {
    this.loadInvoicePaymentSummary(this.props.associatedItemId).finally(() => {
      this.setState({
        isLoading: false,
      })
    })
  }

  private close = () => {
    this.setState({
      comments: '',
      executed: false,
      nextInboxId: undefined,
      nextInboxText: '',
      isLoading: false,
    })
    this.props.closeModal()
  }

  private getFormikValues = async () => {
    const formikValues = this.secondFormRef.current?.state?.values
    await this.secondFormRef.current.submitForm()
    return formikValues
  }

  private save = async () => {
    const formValues = await this.getFormikValues()
    this.setState({
      checkAmountLessThanApplyAmount:
        Number(formValues.checkAmount ?? 0) < Number(formValues.applyAmount ?? 0),
      moreThanBalance:
        (this.state.paymentSummary?.remainingBalance ?? 0) < formValues.applyAmount,
    })
    if (this.state.checkAmountLessThanApplyAmount) {
      this.setState({ isPaymentValidated: false, isLoading: false })
      this.secondFormRef.current.setSubmitting(false)
      return
    } else if (this.state.moreThanBalance && !this.state.isPaymentValidated) {
      this.setState({ isPaymentValidated: true, isLoading: false })
      this.secondFormRef.current.setSubmitting(false)
      return
    } else if (!this.state.isPaymentValidated) {
      this.setState({ isPaymentValidated: true, isLoading: false })
      this.secondFormRef.current.setSubmitting(false)
    } else {
      this.props.data.InvoiceCheckData = toIApplyPayment(
        formValues,
        this.props.associatedItemId!
      )
      var action = this.props.action
      this.props.data.comment = this.state.comments

      processAction(
        this.props.data,
        action!.id.toString(),
        this.props.inboxItemId.toString()
      )
        .then(() => {
          this.props.handleProcessActionFinish!(action)
          this.close()
        })
        .catch(() => {
          this.setState({ executed: false, isLoading: false })
          this.close()
        })
    }
  }

  private loadInvoicePaymentSummary = async (quoteId: string) => {
    this.setState({ isLoading: true })

    await GetInvoicePaymentDetails(quoteId)
      .then((response: IGlobalInvoicePaymentSummary) => {
        this.setState({ paymentSummary: response })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  private verifyCheck = async (formValues: IGlobalInvoiceCheckFormValues) => {
    this.setState({ isCheckVerified: true })
    var checkRequest = new VerifyCheckRequest()
    checkRequest.checkNumber = formValues.checkNumber || ''
    checkRequest.payer = formValues.payer || ''
    checkRequest.checkDate = formValues.datePaid || new Date()
    checkRequest.checkAmount = formValues.checkAmount || 0
    await VerifyCheck(checkRequest).then((response: ProcessReceivedCheckResults) => {
      this.setState({ isCheckVerified: true })
      this.props.openVerifyCheckDialog!(response)
    })
  }

  private resetVerify() {
    if (this.state.isCheckVerified != false) {
      this.setState({ isCheckVerified: false })
    }
    if (this.state.moreThanBalance != false) {
      this.setState({ moreThanBalance: false })
    }
    if (this.state.checkAmountLessThanApplyAmount != false) {
      this.setState({ checkAmountLessThanApplyAmount: false })
    }
  }

  public secondFormRef = React.createRef<any>()

  public render() {
    const { isOpen, classes } = this.props
    const { paymentSummary } = this.state
    const hasValue = (field: string, values: any) => {
      const value = getIn(values, field)
      return !!value
    }
    return (
      <>
        <ProcessReceivedCheckModal />
        <Dialog fullWidth={true} maxWidth="xl" open={isOpen!} disableBackdropClick={true}>
          <Formik
            initialValues={createICheckFormValues(paymentSummary?.payerName!)}
            onSubmit={() => {}}
            validationSchema={ReceiveInvoicePaymentModalSchema(
              paymentSummary?.remainingBalance!
            )}
            ref={this.secondFormRef}
          >
            {({ values, isValid, errors }) => (
              <form id="secondForm">
                <DialogTitle style={{ padding: 0 }}>
                  <ResponsiveAppBar
                    title="Receive Invoice Payment"
                    pageIcon={<CreditCard />}
                    isModal={true}
                  />
                </DialogTitle>
                <DialogContent>
                  {this.state.isLoading && (
                    <div
                      style={{
                        position: 'fixed',
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '1200',
                      }}
                    >
                      <CircularProgress size={100} />
                    </div>
                  )}
                  <Grid>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <span className={classes.bold}>EOC/Legacy (Invoice) #:</span>
                        <span>{paymentSummary?.invoiceNumber}</span>
                      </Grid>
                      <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <span className={classes.bold}>Patient ID #:</span>
                        <span>{paymentSummary?.patientId}</span>
                      </Grid>
                      <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <span className={classes.bold}>Patient Name:</span>
                        <span>{paymentSummary?.patientName}</span>
                      </Grid>
                      <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <span className={classes.bold}>Patient DOB:</span>
                        <span>
                          {paymentSummary?.dateOfBirth
                            ? moment(paymentSummary?.dateOfBirth).format('L')
                            : ''}
                        </span>
                      </Grid>
                      <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <span className={classes.bold}>Claim #:</span>
                        <span>{paymentSummary?.referralClaimNumber}</span>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      direction="row"
                      spacing={2}
                      style={{ paddingTop: '15px', paddingBottom: '10px' }}
                    >
                      <Typography
                        variant={'body1'}
                        color="textSecondary"
                        style={{ height: '18px' }}
                      >
                        PROCEDURE DESCRIPTION
                      </Typography>
                    </Grid>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <span className={classes.bold}>DX Codes:</span>
                        <span>{paymentSummary?.dxCodes}</span>
                      </Grid>
                      <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <span className={classes.bold}>Body Part:</span>
                        <span>{paymentSummary?.bodyPart}</span>
                      </Grid>
                      <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <span className={classes.bold}>Date of Surgery:</span>
                        <span>
                          {paymentSummary?.dateOfSurgery
                            ? moment(paymentSummary?.dateOfSurgery).format('L')
                            : 'N/A'}
                        </span>
                      </Grid>
                      <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <span className={classes.bold}>Global Period End Date:</span>
                        <span>
                          {paymentSummary?.globalEndDate
                            ? moment(paymentSummary?.globalEndDate).format('L')
                            : 'N/A'}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item>
                        <span className={classes.bold}>CPT Codes:</span>
                        <span>
                          {paymentSummary?.cptCodes
                            .map((item) => {
                              let cptSummary = item.cptCode

                              if (item.cptDescription) {
                                cptSummary = `${cptSummary} (${item.cptDescription})`
                              }
                              return cptSummary
                            })
                            .join('; ')}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      direction="row"
                      spacing={2}
                      style={{ paddingTop: '15px', paddingBottom: '10px' }}
                    >
                      <Typography
                        variant={'body1'}
                        color="textSecondary"
                        style={{ height: '18px' }}
                      >
                        INVOICE SUMMARY
                      </Typography>
                    </Grid>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <span className={classes.bold}>Payer:</span>
                        <span>{paymentSummary?.payerName}</span>
                      </Grid>
                      <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <span className={classes.bold}>Invoice Total:</span>
                        <span>
                          {formatCurrency((paymentSummary?.totalAmount ?? 0).toString())}
                        </span>
                      </Grid>
                      <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <span className={classes.bold}>Balance Due:</span>
                        <span>
                          {formatCurrency(
                            (paymentSummary?.remainingBalance ?? 0).toString()
                          )}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                      <Grid container direction="column">
                        <Grid
                          item
                          style={{
                            paddingTop: '20px',
                            paddingBottom: '20px',
                          }}
                        >
                          <Divider />
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            spacing={3}
                            justifyContent="flex-start"
                          >
                            <Grid item xs={2}>
                              <FullWidthField
                                format="YYYY-MM-DD"
                                defaultValue={values.datePaid}
                                type="date"
                                name="datePaid"
                                label="Check Date"
                                inputId="datePaid"
                                fullWidth={true}
                                clearable={true}
                                shrink={true}
                                required
                                errorMessage={errors.datePaid}
                                onKeyUp={() => this.resetVerify()}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <FullWidthField
                                required
                                name="checkNumber"
                                label="Check Number"
                                InputLabelProps={{
                                  shrink: hasValue('checkNumber', values),
                                }}
                                onKeyUp={() => this.resetVerify()}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <FullWidthField
                                required
                                name="payer"
                                label="Payer"
                                InputLabelProps={{
                                  shrink: hasValue('payer', values),
                                }}
                                onKeyUp={() => this.resetVerify()}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <FullWidthField
                                required
                                name="checkAmount"
                                label="Check Amount"
                                InputProps={{
                                  inputComponent: NumberMaskedInput,
                                  inputProps: {
                                    currency: true,
                                    guide: false,
                                    numberOfDigits: 12,
                                  },
                                }}
                                InputLabelProps={{
                                  shrink: hasValue('amount', values),
                                }}
                                onKeyUp={() => this.resetVerify()}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Button
                                onClick={() => this.verifyCheck(values)}
                                size="medium"
                                variant="outlined"
                                className="gridButton"
                                color="primary"
                                data-cy="verify check"
                                disabled={
                                  !hasValue('datePaid', values) ||
                                  !hasValue('checkNumber', values) ||
                                  !hasValue('checkAmount', values) ||
                                  !hasValue('payer', values) ||
                                  this.state.isCheckVerified == true
                                }
                              >
                                {'Verify Check'}
                              </Button>
                            </Grid>
                            <Grid item xs={2}>
                              <FullWidthField
                                required
                                name="applyAmount"
                                label="Amount to Apply"
                                InputProps={{
                                  inputComponent: NumberMaskedInput,
                                  inputProps: {
                                    currency: true,
                                    guide: false,
                                    numberOfDigits: 12,
                                  },
                                }}
                                InputLabelProps={{
                                  shrink: hasValue('applyAmount', values),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item style={{ paddingTop: '20px' }}>
                          {paymentSummary ? (
                            <PaymentHistoryPanel payments={paymentSummary?.payments} />
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid
                          item
                          container
                          direction="column"
                          wrap="nowrap"
                          alignItems="flex-end"
                        >
                          <Grid item xs={12} style={{ paddingTop: '0px' }}>
                            {this.state.checkAmountLessThanApplyAmount && (
                              <div className="error">
                                Amount to Apply must be less than or equal to Check
                                Amount.
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: '0px' }}>
                            {this.state.moreThanBalance &&
                              !this.state.checkAmountLessThanApplyAmount && (
                                <Grid item style={{ display: 'flex' }}>
                                  <Warning
                                    alignmentBaseline="baseline"
                                    style={{
                                      color: '#ffa726',
                                      marginRight: '8px',
                                    }}
                                  />
                                  <Typography
                                    variant={'body1'}
                                    color="textPrimary"
                                    style={{ height: '18px' }}
                                  >
                                    Amount to Apply is greater than total Balance Due.
                                  </Typography>
                                </Grid>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {this.props.action.displayComment && (
                      <Grid item xs={12} style={{ marginTop: '16px' }}>
                        <TextField
                          label="Comments"
                          name="notes"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required={this.props.action.requireComment}
                          multiline
                          minRows="4"
                          onChange={(event) =>
                            this.setState({ comments: event.target.value })
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.setState({ executed: true, isLoading: true })
                      this.save()
                    }}
                    disabled={
                      !(isValid && this.state.isCheckVerified) ||
                      (this.props.action.requireComment && this.state.comments === '')
                    }
                  >
                    {this.state.isPaymentValidated ? 'Execute' : 'Validate'}
                  </Button>
                  <Button onClick={this.close} data-cy="close">
                    Close
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Dialog>
      </>
    )
  }
}

const InjectedReceiveInvoicePaymentModal = inject<
  IStores,
  IReceiveInvoicePaymentModalProps,
  Partial<IReceiveInvoicePaymentModalProps>,
  any
>((stores: IStores) => ({
  paymentSummary: stores.quotes.selectedInvoicePaymentSummary,
  openProcessReceivedCheckDialog: stores.receivePayments.openProcessReceivedCheckDialog,
  openVerifyCheckDialog: stores.receivePayments.openVerifyReceivedCheckDialog,
  sendNotification: stores.notifications.sendNotification,
}))(withStyles(styles)(ReceiveInvoicePaymentModal))

export default InjectedReceiveInvoicePaymentModal
