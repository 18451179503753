export interface IEmailLogRequest {
  body?: string
  cc?: string
  recipients?: string
}

export class EmailLogRequest implements IEmailLogRequest {
  public body?: string
  public cc?: string
  public recipients?: string

  constructor(body: string, cc: string, recipients: string) {
    this.body = body
    this.cc = cc
    this.recipients = recipients
  }
}
