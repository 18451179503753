import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IStores } from '../Stores'
import { Block, DoneAll, PictureAsPdfRounded, Send, Warning } from '@material-ui/icons'
import {
  Typography,
  TableCell,
  Table,
  TableRow,
  Button,
  CircularProgress,
  FormControl,
  TableBody,
  TableHead,
  TextField,
  Grid,
  Tooltip,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { formatCurrency } from 'src/utils/Formatter'
import { pagedList } from 'src/viewModels/pagedList'
import ReactPaginate from 'react-paginate'
import { SentCheckSearchRequest } from 'src/viewModels/SentCheckSearchRequest'
import { SentCheckSummaryResult } from 'src/viewModels/SentCheckSummaryResult'
import { searchSentChecks } from 'src/services/SearchService'
import moment from 'moment'
import { regenerateEOB } from 'src/services/CheckService'
import CheckCorrectionModal from './CheckCorrectionModal'
import VoidCheckModal from './VoidCheckModal'
const styles = () =>
  createStyles({
    strikeOut: {
      textDecoration: 'line-through red',
    },
  })

interface ISentCheckProps extends WithStyles<typeof styles> {
  setCheckRequest?: (request: SentCheckSearchRequest) => void
  storedRequest?: SentCheckSearchRequest
}

class SentChecks extends React.Component<ISentCheckProps> {
  public state = {
    searchCheckNumber: this.props.storedRequest?.checkNumber ?? '',
    searchCheckDateStart: this.props.storedRequest?.checkDateStart ?? undefined,
    searchCheckDateEnd: this.props.storedRequest?.checkDateEnd ?? undefined,
    gridData: new pagedList().items as SentCheckSummaryResult[],
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 20,
    gridLoading: false,
    pdfLoading: false,
    CheckCorrectionModalOpen: false,
    selectedCheck: undefined as unknown as SentCheckSummaryResult,
    isVoidCheckModalOpen: false,
  }

  public componentDidMount() {}

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getSentChecksData())
  }

  private isSearchGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isSearchGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private getSentChecksData = async (setSavedSearch: boolean = false) => {
    this.setState({ gridLoading: true })
    const { page, pageSize } = this.state

    const data: SentCheckSearchRequest = {
      checkDateStart: this.state.searchCheckDateStart,
      checkDateEnd: this.state.searchCheckDateEnd,
      checkNumber: this.state.searchCheckNumber,
      page: page,
      pageSize: pageSize,
    }
    await searchSentChecks(data).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        gridLoading: false,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }

      if (setSavedSearch) {
        this.props.setCheckRequest!(data)
      }
    })
  }

  private generatePdf = (checkId: string) => {
    regenerateEOB(checkId).then(() => {
      this.setState({ pdfLoading: false })
    })
  }

  private search = (event: any) => {
    this.setState({ page: 1 }, () => {
      this.getSentChecksData(true)
    })
    event.preventDefault()
  }

  private clearSearch = () => {
    var select_box = document.getElementById('claimStatus') as HTMLSelectElement
    if (select_box != undefined) {
      select_box.selectedIndex = 0
    }

    var select_box2 = document.getElementById('billoutStatus') as HTMLSelectElement
    if (select_box2 != undefined) {
      select_box2.selectedIndex = 0
    }
    var start = document.getElementById('startDate') as HTMLInputElement
    if (start != undefined) {
      start.value = ''
    }
    var end = document.getElementById('endDate') as HTMLInputElement
    if (end != undefined) {
      end.value = ''
    }
    this.setState({
      searchCheckDateStart: undefined,
      searchCheckDateEnd: undefined,
      searchPayer: '',
      searchCheckNumber: '',
      gridData: undefined,
      pageCount: 0,
      totalItems: 0,
      gridLoading: false,
      CheckCorrectionModalOpen: false,
    })
  }

  private handleStartDateChange = (event: any) => {
    this.setState({ searchCheckDateStart: event.target.value })
  }

  private handleEndDateChange = (event: any) => {
    this.setState({ searchCheckDateEnd: event.target.value })
  }

  private handleCheckNumberChange = (event: any) => {
    this.setState({ searchCheckNumber: event.target.value })
  }

  private mapSearchGridData = () => {
    if (this.isSearchGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={14} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          <TableCell>
            {record.pendingCheck ? (
              <Tooltip title="Holding">
                <Warning style={{ color: '#ffc107' }} />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
          >
            <strong>{record.billingName}</strong>
            <br></br>
            {record.billingAddress}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
            align="right"
          >
            {record.checkDate ? moment(record.checkDate).format('MM/DD/YYYY') : ''}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
          >
            {record.checkNumber}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
            align="right"
          >
            {formatCurrency(record.checkAmount.toString())}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
          >
            <pre>
              {record.originalCheckDate
                ? moment(record.originalCheckDate).format('MM/DD/YYYY') +
                  ' - ' +
                  record.originalCheckNumber
                : ''}
            </pre>
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
          >
            {moment(record.createdOn).format('MM/DD/YYYY')}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
          >
            {record.createdBy}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
          >
            {record.modifiedOn ? moment(record.modifiedOn).format('MM/DD/YYYY') : 'N/A'}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
          >
            {record.modifiedBy ?? 'N/A'}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
            align="right"
          >
            {record.claimCount}
          </TableCell>
          <TableCell>
            {!record.pendingCheck &&
              !record.voidedCheck &&
              (record.originalCheckNumber == '' ||
                record.originalCheckNumber == undefined) && (
                <Tooltip title="Check Correction">
                  <DoneAll
                    color="primary"
                    style={{ cursor: 'pointer', paddingLeft: '4px' }}
                    onClick={() =>
                      this.setState({
                        selectedCheck: record,
                        CheckCorrectionModalOpen: true,
                      })
                    }
                  />
                </Tooltip>
              )}
          </TableCell>
          <TableCell>
            {record.pendingCheck || record.voidedCheck ? (
              <></>
            ) : (
              <Tooltip title="Generate New Explanation of Payment">
                <PictureAsPdfRounded
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.generatePdf(record.checkId)
                    this.setState({ pdfLoading: true })
                  }}
                ></PictureAsPdfRounded>
              </Tooltip>
            )}
          </TableCell>
          <TableCell>
            {!record.pendingCheck && !record.voidedCheck && (
              <Tooltip title="Void Check">
                <Block
                  color={'error'}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.setState({
                      selectedCheck: record,
                      isVoidCheckModalOpen: true,
                    })
                  }}
                />
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
      ))
    }
  }

  public render() {
    const { isVoidCheckModalOpen } = this.state

    return (
      <>
        <Helmet>
          <title>Sent Checks</title>
        </Helmet>
        <ResponsiveAppBar title="Sent Checks" pageIcon={<Send />} />
        {this.state.CheckCorrectionModalOpen && (
          <CheckCorrectionModal
            close={(reload: boolean) => {
              if (reload) {
                this.getSentChecksData()
              }
              this.setState({ CheckCorrectionModalOpen: false })
            }}
            isOpen={this.state.CheckCorrectionModalOpen}
            check={this.state.selectedCheck}
          />
        )}

        {isVoidCheckModalOpen && (
          <VoidCheckModal
            open={isVoidCheckModalOpen}
            close={(reload: boolean) => {
              if (reload) {
                this.getSentChecksData()
              }
              this.setState({ isVoidCheckModalOpen: false })
            }}
            check={this.state.selectedCheck}
          />
        )}

        {(this.state.gridLoading || this.state.pdfLoading) && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '2',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <form onSubmit={() => {}}>
          <Grid container style={{ padding: '10px', width: '100%' }} xs={12}>
            <Typography
              color="primary"
              component="h6"
              variant="h6"
              gutterBottom
              style={{ paddingBottom: '10px' }}
            >
              SEARCH CRITERIA
            </Typography>
            <Grid container direction="row" spacing={2} style={{ width: '100%' }}>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <TextField
                    value={this.state.searchCheckNumber}
                    onChange={this.handleCheckNumberChange}
                    label="Check Number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ fullWidth: true }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item container style={{ width: '356px' }} direction="row">
                <Grid item>
                  <TextField
                    inputProps={{ id: 'startDate', style: { padding: '8px' } }}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.handleStartDateChange}
                    type="date"
                    name="startDate"
                    variant="outlined"
                    defaultValue={this.state.searchCheckDateStart}
                    label="Check Start Date"
                    fullWidth
                  />
                </Grid>
                <Grid item style={{ margin: '0px 10px' }}>
                  <strong> - </strong>
                </Grid>
                <Grid item>
                  <TextField
                    inputProps={{ id: 'endDate', style: { padding: '8px' } }}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.handleEndDateChange}
                    type="date"
                    name="endDate"
                    variant="outlined"
                    defaultValue={this.state.searchCheckDateEnd}
                    label="Check End Date"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={2}
              style={{
                marginLeft: '10px',
                marginTop: '10px',
                paddingTop: '10px',
                width: '100%',
              }}
            >
              <Button
                type="submit"
                onClick={this.search}
                color="primary"
                size="medium"
                variant="contained"
                style={{ marginRight: '10px' }}
              >
                Search
              </Button>
              <Button
                color="secondary"
                onClick={this.clearSearch}
                size="medium"
                variant="contained"
              >
                Clear Search
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="flex-start"
          wrap="wrap"
        >
          <Grid item xs={12}>
            <Table aria-label="simple table" style={{ padding: '20px' }}>
              <TableHead>
                <TableRow className="gridPadding">
                  <TableCell></TableCell>
                  <TableCell>Billing Information</TableCell>
                  <TableCell align="right">Check Date</TableCell>
                  <TableCell>Check Number</TableCell>
                  <TableCell align="right">Check Amount</TableCell>
                  <TableCell>Original Check Info</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Modified</TableCell>
                  <TableCell>Modified By</TableCell>
                  <TableCell align="right"># of Claims</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '10%' }}>{this.mapSearchGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                forcePage={this.state.page - 1}
                pageCount={this.state.pageCount}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}

const InjectedSentChecks = inject<
  IStores,
  ISentCheckProps,
  Partial<ISentCheckProps>,
  any
>((stores: IStores) => ({
  setCheckRequest: stores.global.setSentCheckRequest,
  storedRequest: stores.global.sentCheckRequest,
}))(SentChecks)

export default withStyles(styles)(InjectedSentChecks)
