import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultProcedure, IProcedure } from '../../Definitions'
import {
  Client,
  IProcedureDto,
  ProcedureDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'ProcedureDto'

export default class ProceduresStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public procedures: IProcedure[]
  @observable
  public selectedProcedure?: IProcedureDto
  public dataTableStore: DataTableStore<IProcedureDto, IProcedure>

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IProcedureDto, IProcedure>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllProcedures(filter, page, undefined, orderBy, includeInactives),
      (procedure) => this.setupProcedures(procedure)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedProcedure = undefined
  }

  @action.bound
  public disableProcedure(procedure: IProcedure) {
    this.isLoading = true
    return this.client
      .archiveProcedure(procedure.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndProcedures() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllProcedures() {
    this.isLoading = true
    return this.client
      .getAllProcedures(undefined, 1, 5000, undefined, true)
      .then((resp: SwaggerResponse<IProcedureDto[]>) =>
        runInAction(() => {
          this.procedures = this.setupProcedures(resp.result)
        })
      )
  }

  @action.bound
  public openDialogWithProcedure(procedure: IProcedure) {
    this.isModalOpen = true
    this.selectedProcedure = procedure
  }

  @action.bound
  public recoverProcedure(procedure: IProcedure) {
    this.isLoading = true
    return this.client
      .reactivateProcedure(procedure.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveProcedure(procedure: IProcedure) {
    this.isLoading = true
    let promise
    const procedureDto = new ProcedureDto(procedure)
    if (procedure.isNew) {
      promise = this.client.createProcedure(procedureDto)
    } else {
      promise = this.client.updateProcedure(procedure.id, procedureDto)
    }
    return promise
      .then(() => {
        this.resetUIState(procedure.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupProcedures = (procedures: IProcedureDto[]) => {
    return procedures.map((x) => this.setupProcedureMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (procedure: IProcedureDto): IProcedure => {
    return { ...DefaultProcedure(), ...procedure }
  }

  private setupProcedureMenuItems = (procedure: IProcedure) => {
    procedure.menuItems = []
    if (procedure.isActive) {
      procedure.menuItems.push({
        icon: Edit,
        name: 'Edit',
        onClick: () => this.openDialogWithProcedure(procedure),
      })
      procedure.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableProcedure(procedure),
      })
    } else {
      procedure.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverProcedure(procedure),
      })
    }
    return procedure
  }
}
