import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import {
  ArrowDropDown,
  ArrowRight,
  Error,
  InsertDriveFileOutlined,
  ReceiptOutlined,
  Visibility,
  Warning,
} from '@material-ui/icons'
import { Form, Formik, getIn } from 'formik'
import _ from 'lodash'
import { inject } from 'mobx-react'
import Moment from 'moment'
import React from 'react'
import { Prompt } from 'react-router-dom'
import FullWidthField from '../../common/FullWidthField'
import { ICMS1500, IProvider } from '../../Definitions'
import { IProviderFormValues } from '../../providers/ProviderFormValues'
import { IStores } from '../../Stores'
import BillTotals from '../BillTotals'
import { createIBillTableFormValues, IBillTableFormValues } from './BillTableFormValues'
import { IProviderDto, ProviderDto, SwaggerResponse } from 'src/generated_client'
import ViewProviderGroupContractModal from '../../ReceiveClaim/ViewProviderGroupContractModal'
import AppealNameConstants from 'src/constants/AppealNameConstants'
import AppealLogModal from 'src/search/AppealLogModal'
import { IClaimFormValues } from '../../ReceiveClaim/ClaimFormValues'
import { PayProvidersSearchRequest } from 'src/viewModels/PayProvidersSearchRequest'
import NumberMaskedInput from 'src/common/NumberMaskedInput'
import moment from 'moment'
import { PayProvidersTotalSummary } from 'src/viewModels/PayProvidersTotalSummary'
// tslint:disable-next-line: no-var-requires
const CurrencyFormat: any = require('react-currency-format')
const styles = ({ palette }: Theme) =>
  createStyles({
    arrow: {
      padding: 0,
    },
    billTable: {
      '&:last-child td': {
        borderBottom: 0,
      },
    },
    checkbox: {
      height: 12,
      padding: 0,
      width: 12,
    },
    cms1500Row: {
      display: 'table',
      width: '100%',
      '&:nth-of-type(4n+1)': {
        '&:hover': {
          backgroundColor: '#E1E1E1',
        },
        backgroundColor: fade(palette.secondary.main, 0.3),
      },
    },
    headerRow: {
      color: 'gray',
      display: 'table',
    },
    input: {},
    onTime: {
      color: 'black',
    },
    overDue: {
      color: 'red',
    },
    providerTableRow: {
      width: '100%',
      '&:nth-of-type(odd)': {
        '&:hover': {
          backgroundColor: '#E1E1E1',
        },
        backgroundColor: '#EEEEEE',
      },
    },
    sidebar: {
      overflowY: 'auto',
      width: '100%',
    },
    claimHeaderRow: {
      backgroundColor: palette.grey[200],
    },
  })

interface IBillsTableProps extends WithStyles<typeof styles> {
  clearFilters?: () => void
  isCurrentOrganizationSurgical?: boolean
  isLoading?: boolean
  openWriteChecks?: (values: IBillTableFormValues) => void
  providerBills?: IProvider[]
  saveCMS1500?: (cms1500: ICMS1500) => Promise<SwaggerResponse<void>>
  selectedDateRange?: string | undefined
  selectedFromDate?: Date | null
  selectedToDate?: Date | null
  setSelectedDate?: (date: Date | null, isStartDate: boolean) => void
  setSelectedDateRange?: (dateRange: string | undefined) => void
  showContractModal?: (provider: IProviderDto | undefined) => void
  openAppealLogModal?: (claimId: string) => void
  selectedClaimId?: string
  isAppealLogModalOpen?: boolean
  closeAppealLogModal?: () => void
  loadBills?: (request: PayProvidersSearchRequest, saveSearch?: boolean) => Promise<void>
  alertMessage?: string
  clearProviders?: () => void
  storedRequest?: PayProvidersSearchRequest
  payProviderTotals?: PayProvidersTotalSummary
}

interface IBillsTableState {
  anchorEl: any
  expanded: boolean
  showFilters: boolean
  pdfLoading: boolean
  selectedGroupInfo: string
  searchEOCLegacy: string
  searchLocation: string
  searchStartDueDate: string
  searchEndDueDate: string
  searchContractAmountMin: string
  searchContractAmountMax: string
  alertMessage: string
  selectAll: boolean
}

class BillsTable extends React.Component<IBillsTableProps, IBillsTableState> {
  constructor(props: IBillsTableProps) {
    super(props)
    this.state = {
      anchorEl: null,
      expanded: false,
      showFilters: false,
      pdfLoading: false,
      selectedGroupInfo: '',
      searchEOCLegacy: this.props.storedRequest?.eocGeneratedOrLegacyId ?? '',
      searchLocation: this.props.storedRequest?.locationName ?? '',
      searchStartDueDate: this.props.storedRequest?.dueDateStart
        ? moment(this.props.storedRequest?.dueDateStart).format('yyyy-MM-DD')
        : '',
      searchEndDueDate: this.props.storedRequest?.dueDateEnd
        ? moment(this.props.storedRequest?.dueDateEnd).format('yyyy-MM-DD')
        : '',
      searchContractAmountMin: this.props.storedRequest?.contractAmountMin ?? '',
      searchContractAmountMax: this.props.storedRequest?.contractAmountMax ?? '',
      alertMessage: '',
      selectAll: false,
    }
  }

  public componentWillUnmount() {
    this.props.clearFilters!()
  }

  private save = async (values: IBillTableFormValues) => {
    this.props.openWriteChecks!(values)
  }

  private onSelectAllChanged = (
    billValues: IBillTableFormValues,
    setFieldValue: (field: string, value: any) => void
  ) => {
    billValues.providers.forEach((provider, index) => {
      setFieldValue(`providers[${index}].isChecked`, !this.state.selectAll)
      provider.cmS1500s!.forEach((__, idx) => {
        setFieldValue(
          `providers[${index}].cmS1500s[${idx}].isChecked`,
          !this.state.selectAll
        )
      })
    })
    this.setState({ selectAll: !this.state.selectAll })
  }

  private multiCheckboxClick =
    (
      setFieldValue: (field: string, value: any) => void,
      billValues?: IBillTableFormValues,
      provider?: { providerValues: IProviderFormValues; providerIndex: number }
    ) =>
    (event: React.MouseEvent<HTMLElement>) => {
      if (billValues !== undefined) {
        setFieldValue('isChecked', !billValues.isChecked)
        billValues.providers.forEach((x, idx) => {
          setFieldValue(`providers[${idx}].isChecked`, !billValues.isChecked)
          x.cmS1500s!.forEach((__, cmsIdx) => {
            setFieldValue(
              `providers[${idx}].cmS1500s[${cmsIdx}].isChecked`,
              !billValues.isChecked
            )
          })
        })
      } else if (provider !== undefined) {
        setFieldValue(
          `providers[${provider.providerIndex}].isChecked`,
          !provider.providerValues.isChecked
        )
        provider.providerValues.cmS1500s!.forEach((__, idx) => {
          setFieldValue(
            `providers[${provider.providerIndex}].cmS1500s[${idx}].isChecked`,
            !provider.providerValues.isChecked
          )
        })
      }
      event.stopPropagation()
    }

  private checkboxClick =
    (
      setFieldValue: (field: string, value: any) => void,
      checkedValue: boolean,
      fieldName: string
    ) =>
    (event: React.MouseEvent<HTMLElement>) => {
      setFieldValue(fieldName, !checkedValue)
      event.stopPropagation()
    }

  private expandProviderRow =
    (
      provider: IProviderFormValues,
      providerFieldName: string,
      setFieldValue: (field: string, value: any) => void
    ) =>
    () => {
      setFieldValue(`${providerFieldName}.isExpanded`, !provider.isExpanded)
    }

  private expandClaimRow =
    (
      cms1500: IClaimFormValues,
      cms1500FieldName: string,
      setFieldValue: (field: string, value: any) => void
    ) =>
    () => {
      setFieldValue(`${cms1500FieldName}.isExpanded`, !cms1500.isExpanded)
    }

  private handleEOCLegacyChange = (event: any) => {
    this.setState({ searchEOCLegacy: event.target.value })
  }

  private handleStartDateChange = (event: any) => {
    this.setState({ searchStartDueDate: event.target.value })
  }

  private handleEndDateChange = (event: any) => {
    this.setState({ searchEndDueDate: event.target.value })
  }

  private handleContractAmountMinChange = (event: any) => {
    this.setState({ searchContractAmountMin: event.target.value })
  }

  private handleContractAmountMaxChange = (event: any) => {
    this.setState({ searchContractAmountMax: event.target.value })
  }

  private handleLocationChange = (event: any) => {
    this.setState({ searchLocation: event.target.value })
  }

  private search = (event: any) => {
    var request = new PayProvidersSearchRequest()
    request.eocGeneratedOrLegacyId = this.state.searchEOCLegacy
    request.locationName = this.state.searchLocation
    request.dueDateStart = this.state.searchStartDueDate
    request.dueDateEnd = this.state.searchEndDueDate
    request.contractAmountMax = this.state.searchContractAmountMax
    request.contractAmountMin = this.state.searchContractAmountMin
    this.setState({ selectAll: false })

    this.props.loadBills!(request, true)
    event.preventDefault()
  }

  private clearSearch = () => {
    var select_box = document.getElementById('locationSelect') as HTMLSelectElement

    if (select_box != undefined) {
      select_box.selectedIndex = 0
    }

    var eoc_legacy_box = document.getElementById('eocLegacy') as HTMLInputElement

    if (eoc_legacy_box != undefined) {
      eoc_legacy_box.value = ''
    }

    this.setState({
      searchLocation: '',
      searchEOCLegacy: '',
      searchStartDueDate: '',
      searchEndDueDate: '',
      searchContractAmountMin: '',
      searchContractAmountMax: '',
      selectAll: false,
    })

    this.props.clearProviders!()
  }

  public renderProviderRow(
    values: IBillTableFormValues,
    index: number,
    setFieldValue: (field: string, value: any) => void
  ) {
    const provider = getIn(values, `providers[${index}]`) as IProviderFormValues
    const nextDueDate = provider.cmS1500s![0].dueDate

    const isOverdue = Moment(new Date()).isAfter(nextDueDate)
    const providerDto = new ProviderDto()
    providerDto.id = provider.id
    providerDto.name = provider.name
    providerDto.contractLink = provider.contractLink
    providerDto.contractSummary = provider.contractSummary

    return (
      <>
        <TableRow className={this.props.classes.providerTableRow} key={provider.id} hover>
          <TableCell style={{ width: '60px', paddingRight: 0, paddingLeft: '30px' }}>
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={6}>
                <Checkbox
                  className={this.props.classes.checkbox}
                  checked={provider.isChecked!}
                  onClick={this.multiCheckboxClick(setFieldValue, undefined, {
                    providerIndex: index,
                    providerValues: provider,
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                {provider.isExpanded ? (
                  <ArrowDropDown
                    className={this.props.classes.arrow}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={this.expandProviderRow(
                      provider,
                      `providers[${index}]`,
                      setFieldValue
                    )}
                  />
                ) : (
                  <ArrowRight
                    className={this.props.classes.arrow}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={this.expandProviderRow(
                      provider,
                      `providers[${index}]`,
                      setFieldValue
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </TableCell>
          <TableCell style={{ width: '220px', textAlign: 'left', paddingRight: 0 }}>
            <Tooltip title="View Provider Contract Summary">
              <InsertDriveFileOutlined
                style={{ cursor: 'pointer', fontSize: '16px' }}
                onClick={() => {
                  this.props.showContractModal!(providerDto)
                }}
              />
            </Tooltip>
            &nbsp;{provider.name}
          </TableCell>
          <TableCell
            style={{
              width: '400px',
              textAlign: 'left',
              paddingRight: 0,
              whiteSpace: 'pre-wrap',
            }}
          >
            {provider.billingAddress?.name != undefined &&
            provider.billingAddress?.name != '' ? (
              <>
                <strong>{provider.billingAddress?.name}</strong>
                <br></br>
              </>
            ) : (
              <></>
            )}
            <span>
              {provider.billingAddress?.addressDisplay}&nbsp;&nbsp;
              {provider.billingAddress?.addressDisplay != '' &&
              (provider.billingAddress?.line1 == undefined ||
                provider.billingAddress.line1 == '' ||
                provider.billingAddress.city == undefined ||
                provider.billingAddress.city == '' ||
                provider.billingAddress.state == undefined ||
                provider.billingAddress.zip == '' ||
                provider.billingAddress.zip == undefined) ? (
                <Tooltip title="Incomplete billing address">
                  <Warning style={{ color: '#ffc107' }} />
                </Tooltip>
              ) : (
                <></>
              )}
            </span>
          </TableCell>
          <TableCell
            style={{ width: '100px', textAlign: 'right', paddingRight: 0 }}
            className={isOverdue ? this.props.classes.overDue : this.props.classes.onTime}
          >
            {Moment(nextDueDate).format('MM/DD/YYYY')}
          </TableCell>
          <TableCell style={{ width: '90px', textAlign: 'right', paddingRight: 0 }}>
            <CurrencyFormat
              value={provider
                .cmS1500s!.map((x) => +x.contractAmountTotal!)
                .reduce((a, b) => a! + b!, 0)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              fixedDecimalScale={true}
              decimalScale={2}
            />
          </TableCell>
          <TableCell style={{ width: '60px', textAlign: 'left' }}>
            {isOverdue ? (
              <Tooltip title="Payment is overdue">
                <Error color="error" />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
        </TableRow>
        {provider.isExpanded ? (
          <TableRow key={`provider-${index}`}>
            <TableCell colSpan={8} style={{ padding: '0px' }}>
              <Table className={this.props.classes.billTable} size="small">
                <TableHead className={this.props.classes.headerRow}>
                  <TableRow>
                    <TableCell style={{ width: '60px', paddingRight: 0 }}></TableCell>
                    <TableCell
                      style={{
                        width: '165px',
                        textAlign: 'left',
                        paddingRight: 0,
                      }}
                    >
                      Patient Name
                    </TableCell>
                    <TableCell
                      style={{
                        width: '155px',
                        textAlign: 'right',
                        paddingRight: 0,
                      }}
                    >
                      Date of Service
                    </TableCell>
                    <TableCell
                      style={{
                        width: '140px',
                        textAlign: 'right',
                        paddingRight: 0,
                      }}
                    >
                      +90 Days
                    </TableCell>
                    <TableCell
                      style={{
                        width: '140px',
                        textAlign: 'right',
                        paddingRight: 0,
                      }}
                    >
                      Date Received
                    </TableCell>
                    <TableCell
                      style={{
                        width: '140px',
                        textAlign: 'right',
                        paddingRight: 0,
                      }}
                    >
                      Due Date
                    </TableCell>
                    <TableCell style={{ width: '160px', textAlign: 'right' }}>
                      Total Contract
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {provider.cmS1500s ? (
                    provider.cmS1500s!.map((x, idx) => {
                      const isCMS1500Overdue = Moment(new Date()).isAfter(x.dueDate)

                      return (
                        <>
                          <TableRow
                            className={this.props.classes.cms1500Row}
                            key={x.id}
                            hover
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={this.expandClaimRow(
                              x,
                              `providers[${index}].cmS1500s[${idx}]`,
                              setFieldValue
                            )}
                          >
                            <TableCell style={{ width: '60px', paddingRight: 0 }}>
                              <Grid container direction="row" spacing={1}>
                                <Grid item xs={6}>
                                  <Checkbox
                                    className={this.props.classes.checkbox}
                                    checked={x.isChecked!}
                                    onClick={this.checkboxClick(
                                      setFieldValue,
                                      x.isChecked!,
                                      `providers[${index}].cmS1500s[${idx}].isChecked`
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  {x.isExpanded ? (
                                    <ArrowDropDown className={this.props.classes.arrow} />
                                  ) : (
                                    <ArrowRight className={this.props.classes.arrow} />
                                  )}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell
                              style={{
                                width: '165px',
                                textAlign: 'left',
                                paddingRight: 0,
                              }}
                            >
                              {x.q2PatientName}
                            </TableCell>
                            <TableCell
                              style={{
                                width: '155px',
                                textAlign: 'right',
                                paddingRight: 0,
                              }}
                            >
                              {Moment(x.dateOfService).format('MM/DD/YYYY')}
                            </TableCell>
                            <TableCell
                              style={{
                                width: '140px',
                                textAlign: 'right',
                                paddingRight: 0,
                              }}
                            >
                              {this.props.isCurrentOrganizationSurgical
                                ? Moment(
                                    new Date().setDate(
                                      new Date(x.dateOfService).getDate() + 90
                                    )
                                  ).format('MM/DD/YYYY')
                                : 'N/A'}
                            </TableCell>
                            <TableCell
                              style={{
                                width: '140px',
                                textAlign: 'right',
                                paddingRight: 0,
                              }}
                            >
                              {Moment(x.incomingOrOutgoingDate).format('MM/DD/YYYY')}
                            </TableCell>
                            <TableCell
                              style={{
                                width: '140px',
                                textAlign: 'right',
                                paddingRight: 0,
                              }}
                              className={
                                isCMS1500Overdue
                                  ? this.props.classes.overDue
                                  : this.props.classes.onTime
                              }
                            >
                              {Moment(x.dueDate).format('MM/DD/YYYY')}
                            </TableCell>
                            <TableCell style={{ width: '160px', textAlign: 'right' }}>
                              <CurrencyFormat
                                value={x.contractAmountTotal}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={this.props.classes.cms1500Row}
                            style={{
                              display: x.isExpanded ? undefined : 'none',
                            }}
                            key={`${index}-cms1500-${idx}`}
                          >
                            <TableCell colSpan={9}>
                              <Table>
                                <TableRow className={this.props.classes.claimHeaderRow}>
                                  <TableCell>
                                    <Grid container item spacing={2}>
                                      <Grid item>
                                        {x.episodeOfCare ? (
                                          <Typography noWrap variant="caption">
                                            <strong>EOC/LEGACY:</strong>
                                            &nbsp;&nbsp;
                                            {
                                              x.episodeOfCare
                                                ?.episodeOfCareNumberOrLegacyId
                                            }
                                            -{x.groupNumber}
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                      <Grid item style={{ paddingLeft: 0 }}>
                                        <Tooltip title="View Claim">
                                          <div
                                            key={index}
                                            style={{
                                              alignItems: 'center',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              window.open(
                                                `/claim/${x.id}/episodeOfCare/${x.episodeOfCareId}/view`,
                                                '_blank'
                                              )
                                            }}
                                          >
                                            <Visibility />
                                          </div>
                                        </Tooltip>
                                      </Grid>
                                      <Grid item>
                                        {x.appealType != undefined ? (
                                          <Typography noWrap variant="caption">
                                            <strong>APPEAL TYPE:</strong>
                                            &nbsp;&nbsp;
                                            {AppealNameConstants[x.appealType.valueOf()]}
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                        {x.appealType == undefined &&
                                        x.lastAppealDate != undefined ? (
                                          <Typography noWrap variant="caption">
                                            <strong>CLAIM UNDER APPEAL</strong>
                                            &nbsp;&nbsp;
                                            <i>
                                              Last Appealed on{' '}
                                              {Moment(x.lastAppealDate).format(
                                                'MM/DD/YYYY'
                                              )}
                                            </i>
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                      <Grid item style={{ paddingLeft: 0 }}>
                                        {x.appealType == undefined &&
                                        x.lastAppealDate != undefined ? (
                                          <Tooltip title="View Appeal Log">
                                            <div
                                              key={index}
                                              style={{
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => {
                                                this.props.openAppealLogModal!(x.id)
                                                this.setState({
                                                  selectedGroupInfo:
                                                    x.episodeOfCare
                                                      ?.episodeOfCareNumberOrLegacyId ??
                                                    '',
                                                })
                                              }}
                                            >
                                              <ReceiptOutlined />
                                            </div>
                                          </Tooltip>
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              </Table>
                              <Table
                                size="small"
                                className={this.props.classes.billTable}
                              >
                                <TableHead>
                                  <TableRow key={'cpt-header'}>
                                    <TableCell>Date of Service</TableCell>
                                    <TableCell>CPT</TableCell>
                                    <TableCell>Charge Amount</TableCell>
                                    <TableCell>Contract Amount</TableCell>
                                    <TableCell>Reason Code</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {x.q24.map((y, lineIdx) => {
                                    return (
                                      <TableRow key={`${index}-${idx}-q24-${lineIdx}`}>
                                        <TableCell>
                                          {Moment(y.aDateOfServiceFrom).format(
                                            'MM/DD/YYYY'
                                          )}
                                        </TableCell>
                                        <TableCell>{y.dProcedureCptHcpcs}</TableCell>
                                        <TableCell>
                                          <CurrencyFormat
                                            value={y.fCharges}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <CurrencyFormat
                                            value={y.contractAmount}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <FullWidthField
                                            inputProps={{
                                              style: {
                                                padding: '4px',
                                              },
                                            }}
                                            variant="outlined"
                                            name={`providers[${index}].cmS1500s[${idx}].q24[${lineIdx}].reasonCode`}
                                            id={`providers[${index}].cmS1500s[${idx}].q24[${lineIdx}].reasonCode`}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  })}
                                </TableBody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    })
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
      </>
    )
  }

  public render() {
    const { providerBills, isLoading } = this.props

    return (
      <>
        <form>
          <Grid container style={{ margin: '10px', marginLeft: '30px' }}>
            <Typography
              color="primary"
              component="h6"
              variant="h6"
              gutterBottom
              style={{ paddingBottom: '10px' }}
            >
              SEARCH CRITERIA
            </Typography>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <TextField
                    inputProps={{ id: 'startDate' }}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.handleStartDateChange}
                    type="date"
                    name="startDate"
                    variant="outlined"
                    value={this.state.searchStartDueDate}
                    label="Due Date Start"
                  />
                </FormControl>
              </Grid>
              <Grid style={{ margin: '10px 0px' }}>
                <strong> - </strong>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <TextField
                    inputProps={{ id: 'endDate' }}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.handleEndDateChange}
                    type="date"
                    name="endDate"
                    variant="outlined"
                    value={this.state.searchEndDueDate}
                    label="Due Date End"
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    inputProps={{ id: 'location' }}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.handleLocationChange}
                    name="location"
                    variant="outlined"
                    value={this.state.searchLocation}
                    label="Provider Location / Billing Name"
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <TextField
                    value={this.state.searchContractAmountMin}
                    onChange={this.handleContractAmountMinChange}
                    label="Contract Amount Min"
                    name="contractAmountMin"
                    InputProps={{
                      inputComponent: NumberMaskedInput,
                      inputProps: {
                        currency: true,
                        guide: false,
                        numberOfDigits: 20,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid style={{ margin: '10px 0px' }}>
                <strong> - </strong>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <TextField
                    value={this.state.searchContractAmountMax}
                    onChange={this.handleContractAmountMaxChange}
                    label="Contract Amount Max"
                    name="contractAmountMax"
                    InputProps={{
                      inputComponent: NumberMaskedInput,
                      inputProps: {
                        currency: true,
                        guide: false,
                        numberOfDigits: 20,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <TextField
                    value={this.state.searchEOCLegacy}
                    onChange={this.handleEOCLegacyChange}
                    label="EOC/Legacy"
                    name="eocLegacy"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              style={{ marginTop: '10px', paddingTop: '10px' }}
            >
              <Button
                onClick={this.search}
                color="primary"
                size="medium"
                variant="contained"
                style={{ marginRight: '10px' }}
                type="submit"
              >
                Search
              </Button>
              <Button
                color="secondary"
                onClick={this.clearSearch}
                size="medium"
                variant="contained"
              >
                Clear Search
              </Button>
              <Grid item style={{ justifyContent: 'flex-end', display: 'flex' }} xs={9}>
                <Link
                  style={{
                    marginRight: '20px',
                    cursor: 'pointer',
                    color: '#29348F',
                  }}
                  underline={'always'}
                  onClick={() => {
                    window.open('/payproviders/checks-holding', '_blank')
                  }}
                >
                  CHECKS HOLDING
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <ViewProviderGroupContractModal />
        {this.props.selectedClaimId && this.props.isAppealLogModalOpen ? (
          <AppealLogModal
            claimId={this.props.selectedClaimId}
            isOpen={this.props.isAppealLogModalOpen}
            closeModal={this.props.closeAppealLogModal!}
            groupInfo={this.state.selectedGroupInfo}
          />
        ) : (
          <></>
        )}
        {(isLoading || this.state.pdfLoading) && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '2',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        {!isLoading ? (
          <Formik
            initialValues={createIBillTableFormValues(providerBills!)}
            onSubmit={this.save}
            enableReinitialize={true}
          >
            {({ values, setFieldValue, submitForm, dirty, submitCount }) => (
              <Form>
                <Prompt
                  when={
                    dirty &&
                    submitCount === 0 &&
                    providerBills &&
                    providerBills.length > 0
                  }
                  message="Are you sure you want to leave the page? There are unsaved changes."
                />
                <Grid
                  container
                  direction="row"
                  style={{ paddingRight: '5px', paddingLeft: '10px' }}
                >
                  <Grid item md={12} lg={9} xl={8}>
                    <Grid container direction="column">
                      {this.props.payProviderTotals && (
                        <Grid
                          item
                          container
                          direction="row"
                          style={{ paddingLeft: '30px', paddingTop: '10px' }}
                        >
                          <Grid item xs={12}>
                            <Typography noWrap variant="h5">
                              Total Amount Outstanding to Providers&nbsp;
                              <strong>
                                <CurrencyFormat
                                  value={this.props.payProviderTotals.totalContractAmount}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                />
                              </strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography noWrap variant="h5">
                              Total Amount Past Due (30+ days)&nbsp;
                              <strong>
                                <CurrencyFormat
                                  value={
                                    this.props.payProviderTotals
                                      .totalContractAmountPastDue
                                  }
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                />
                              </strong>
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <Grid xs={12} style={{ paddingLeft: '30px', paddingTop: '10px' }}>
                        <Typography noWrap variant="body2" color="error">
                          <strong>{this.props.alertMessage!}</strong>
                        </Typography>
                      </Grid>
                      <Grid item container xs={12}>
                        <Table size="small">
                          <TableHead className={this.props.classes.headerRow}>
                            <TableRow key={'header'}>
                              <TableCell style={{ width: '60px', paddingRight: 0 }}>
                                <Checkbox
                                  checked={this.state.selectAll}
                                  onClick={() =>
                                    this.onSelectAllChanged(values, setFieldValue)
                                  }
                                  inputProps={{
                                    id: 'select-all',
                                  }}
                                  value="selectAll"
                                  name="selectAll"
                                />
                              </TableCell>
                              <TableCell
                                style={{
                                  width: '220px',
                                  textAlign: 'left',
                                  paddingRight: 0,
                                }}
                              >
                                Location Name
                              </TableCell>
                              <TableCell
                                style={{
                                  width: '400px',
                                  textAlign: 'left',
                                  paddingRight: 0,
                                }}
                              >
                                Billing Information
                              </TableCell>
                              <TableCell
                                style={{
                                  width: '100px',
                                  textAlign: 'right',
                                  paddingRight: 0,
                                }}
                              >
                                Next Due Date
                              </TableCell>
                              <TableCell
                                style={{
                                  width: '90px',
                                  textAlign: 'right',
                                  paddingRight: 0,
                                }}
                              >
                                Total Contract
                              </TableCell>
                              <TableCell
                                style={{ width: '60px', textAlign: 'left' }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody style={{ display: 'table' }}>
                            {values && values.providers!.length == 0 ? (
                              <>
                                <TableRow key={'noRecords'}>
                                  <TableCell
                                    style={{
                                      width: '930px',
                                      textAlign: 'center',
                                    }}
                                    colSpan={7}
                                  >
                                    No records found
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : (
                              <></>
                            )}
                            {values.providers.map((__, idx) =>
                              this.renderProviderRow(values, idx, setFieldValue)
                            )}
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid xs={12} style={{ paddingLeft: '30px' }}>
                        <Typography noWrap variant="body2" color="error">
                          <strong>{this.props.alertMessage!}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} lg={3} xl={4} className={this.props.classes.sidebar}>
                    <Grid container direction="column">
                      <BillTotals billTableValues={values} submitForm={submitForm} />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </>
    )
  }
}

const InjectedBillsTable = inject<
  IStores,
  IBillsTableProps,
  Partial<IBillsTableProps>,
  any
>((stores: IStores) => ({
  clearFilters: stores.billsStore.clearFilters,
  isCurrentOrganizationSurgical: stores.global.isCurrentOrganizationSurgical,
  isLoading: stores.billsStore.isLoading,
  openWriteChecks: stores.billsStore.openWriteChecks,
  providerBills: stores.billsStore.filteredProviders,
  saveCMS1500: stores.cms1500.saveCMS1500,
  selectedDateRange: stores.billsStore.selectedDateRange,
  selectedFromDate: stores.billsStore.selectedFromDate,
  selectedToDate: stores.billsStore.selectedToDate,
  setSelectedDate: stores.billsStore.setSelectedDate,
  setSelectedDateRange: stores.billsStore.setSelectedDateRange,
  showContractModal: stores.cms1500.openProviderGroupContractDialog,
  openAppealLogModal: stores.cms1500.OpenAppealLogModal,
  selectedClaimId: stores.cms1500.selectedClaimId,
  closeAppealLogModal: stores.cms1500.closeAppealLogModal,
  isAppealLogModalOpen: stores.cms1500.isAppealLogModalOpen,
  loadBills: stores.billsStore.loadSurgeryPayProviderBills,
  alertMessage: stores.billsStore.payProviderAlertMessage,
  clearProviders: stores.billsStore.clearProviders,
  storedRequest: stores.global.payProviderRequest,
  payProviderTotals: stores.billsStore.payProviderTotals,
}))(BillsTable)

export default withStyles(styles)(InjectedBillsTable)
