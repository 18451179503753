import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  Switch,
  Typography,
} from '@material-ui/core'
import { ReceiptOutlined } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React, { ChangeEvent } from 'react'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { Form, Formik } from 'formik'
import FullWidthField from 'src/common/FullWidthField'
import { ClaimsAcquisitionSummaryResult } from 'src/viewModels/ClaimsAcquisitionSummaryResult'
import { ClaimsAcquisitionStatus } from 'src/utils/ClaimsAcquisitionStatus'
import ClaimsAcquisitionNameConstants from 'src/constants/ClaimsAcquisitionNameConstants'
import { ClaimsAcqLogDTO } from 'src/viewModels/ClaimsAcqLogDTO'
import { CreateClaimsAcquisitionLog } from 'src/services/ClaimsAcquisitionService'
import moment from 'moment'

interface IAddClaimsAcqLogModalProps {
  acq?: ClaimsAcquisitionSummaryResult | undefined
  close?: (update?: () => void) => void
  isLoading?: boolean
  isOpen?: boolean
  reloadMain?: () => void
}

class AddClaimsAcqLogModal extends React.Component<IAddClaimsAcqLogModalProps> {
  public state = {
    selectedStatus: 0,
    selectedStatusString: '',
    urgent: false,
    statusError: false,
    dateError: false,
  }

  public secondFormRef = React.createRef<any>()

  public render() {
    const { isOpen, acq } = this.props
    const patientName = acq?.patientName
    const dob =
      acq?.dateOfBirth != undefined ? moment(acq?.dateOfBirth).format('MM/DD/YYYY') : ''
    const eoc = acq?.eocLegacyNum
    const group = acq?.providerGroupName
    const component = acq?.componentName
    const dos =
      acq?.dateOfService != undefined
        ? moment(acq?.dateOfService).format('MM/DD/YYYY')
        : ''
    var modalTitle =
      'Add Claims Acquisition Log - ' + patientName + ' (' + dob + ') ' + eoc

    return (
      <Dialog
        key={acq?.scheduleId}
        fullWidth={true}
        maxWidth="md"
        open={isOpen!}
        disableBackdropClick
      >
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title={modalTitle}
            pageIcon={<ReceiptOutlined />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent style={{ padding: '12px 12px 8px' }}>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ paddingBottom: '10px' }}
            wrap="nowrap"
          >
            <Grid item>
              <strong>Component: </strong>
              {component!}
            </Grid>
            <Grid item>
              <strong>Provider Group: </strong> {group}
            </Grid>
            <Grid item>
              <strong>Date of Service: </strong> {dos}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{ notes: '' }}
              onSubmit={() => {}}
              validationSchema={undefined}
              ref={this.secondFormRef}
            >
              {() => (
                <Form id="secondForm">
                  <Grid container item xs={12} spacing={2} style={{ margin: '0px' }}>
                    <Grid item>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel shrink={true} variant="outlined">
                          Status *
                        </InputLabel>
                        <Select
                          variant="outlined"
                          input={<OutlinedInput notched labelWidth={58} />}
                          inputProps={{ label: true, notched: true }}
                          native={true}
                          fullWidth
                          style={{ minWidth: '300px' }}
                          onChange={this.setSelectedStatus}
                          id="eventSelect"
                        >
                          <option value="">Select a status</option>
                          <option value={ClaimsAcquisitionStatus.Confirmed}>
                            {
                              ClaimsAcquisitionNameConstants[
                                ClaimsAcquisitionStatus.Confirmed
                              ]
                            }
                          </option>
                          <option value={ClaimsAcquisitionStatus.Complete}>
                            {
                              ClaimsAcquisitionNameConstants[
                                ClaimsAcquisitionStatus.Complete
                              ]
                            }
                          </option>
                          <option value={ClaimsAcquisitionStatus.Dismissed}>
                            {
                              ClaimsAcquisitionNameConstants[
                                ClaimsAcquisitionStatus.Dismissed
                              ]
                            }
                          </option>
                          <option value={ClaimsAcquisitionStatus.Corrected}>
                            {
                              ClaimsAcquisitionNameConstants[
                                ClaimsAcquisitionStatus.Corrected
                              ]
                            }
                          </option>
                          <option value={ClaimsAcquisitionStatus.Issued}>
                            {
                              ClaimsAcquisitionNameConstants[
                                ClaimsAcquisitionStatus.Issued
                              ]
                            }
                          </option>
                          <option value={ClaimsAcquisitionStatus.IssuePaid}>
                            {
                              ClaimsAcquisitionNameConstants[
                                ClaimsAcquisitionStatus.IssuePaid
                              ]
                            }
                          </option>
                          <option value={ClaimsAcquisitionStatus.IssueWithClaim}>
                            {
                              ClaimsAcquisitionNameConstants[
                                ClaimsAcquisitionStatus.IssueWithClaim
                              ]
                            }
                          </option>
                          <option value={ClaimsAcquisitionStatus.NeedsAddressed}>
                            {
                              ClaimsAcquisitionNameConstants[
                                ClaimsAcquisitionStatus.NeedsAddressed
                              ]
                            }
                          </option>
                          <option value={ClaimsAcquisitionStatus.NeedsConfirmed}>
                            {
                              ClaimsAcquisitionNameConstants[
                                ClaimsAcquisitionStatus.NeedsConfirmed
                              ]
                            }
                          </option>
                          <option value={ClaimsAcquisitionStatus.WaitClaims}>
                            {
                              ClaimsAcquisitionNameConstants[
                                ClaimsAcquisitionStatus.WaitClaims
                              ]
                            }
                          </option>
                          <option value={ClaimsAcquisitionStatus.WaitResponse}>
                            {
                              ClaimsAcquisitionNameConstants[
                                ClaimsAcquisitionStatus.WaitResponse
                              ]
                            }
                          </option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FullWidthField
                        format="YYYY-MM-DD"
                        type="date"
                        name="followupDateInput"
                        label="Follow-up Date"
                        inputId="followupDateInput"
                        fullWidth={true}
                        clearable={true}
                        shrink={true}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.urgent}
                            onChange={() => this.setState({ urgent: !this.state.urgent })}
                            color="secondary"
                            name="urgent"
                          />
                        }
                        label={this.state.urgent ? 'Urgent' : 'Not Urgent'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FullWidthField
                        label="Notes"
                        name="notes"
                        variant="outlined"
                        multiline
                        rows="4"
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
            {this.state.statusError && (
              <Typography color="error" style={{ textAlign: 'right' }}>
                Please select a status
              </Typography>
            )}
            {this.state.dateError && (
              <Typography color="error" style={{ textAlign: 'right' }}>
                Follow-up date cannot be in the past
              </Typography>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close" variant="contained">
            Close
          </Button>
          <Button onClick={this.save} data-cy="save" variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private setSelectedStatus = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    this.setState({
      selectedStatus: event.target.selectedOptions[0].value,
      selectedStatusString: event.target.selectedOptions[0].innerText,
      statusError: false,
    })
  }

  private close = () => {
    this.setState({
      selectedStatus: 0,
      selectedStatusString: '',
      urgent: false,
      statusError: false,
      dateError: false,
    })
    this.props.close!()
  }

  private save = () => {
    const formikValues = this.secondFormRef.current?.state?.values
    if (this.state.selectedStatusString == '') {
      this.setState({ statusError: true })
      return
    }

    //This is here because Typescript has decided formikValues.followupDateInput is undefined
    var dto = new ClaimsAcqLogDTO()
    dto.followUpDate = formikValues.followupDateInput

    if (dto.followUpDate != undefined) {
      var now = new Date()
      var today = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
      )
      var userEnteredDate = new Date(dto.followUpDate)

      // Typescript trick to find if it is in the past
      if (userEnteredDate.getTime() < today.getTime()) {
        this.setState({ dateError: true })
        return
      }
    }

    dto.claimsAcqStatus = this.state.selectedStatus
    dto.notes = formikValues.notes
    dto.scheduleId = this.props.acq!.scheduleId!
    dto.urgent = this.state.urgent

    CreateClaimsAcquisitionLog(dto)
      // onfulfilled
      .then(
        () => {
          this.props.acq!.claimsAcqStatus = dto.claimsAcqStatus
          //Update the rest
          this.props.close!(this.props.reloadMain!)
          // ontrejected
        },
        function () {}
      )
  }
}
const InjectedAddClaimsAcqLogModal = inject<
  IStores,
  IAddClaimsAcqLogModalProps,
  Partial<IAddClaimsAcqLogModalProps>,
  any
>((stores: IStores) => ({
  close: stores.schedules.closeAddAcqLogModal,
  isOpen: stores.schedules.isAddClaimsAcqLogModalOpen,
  acq: stores.schedules.selectedClaimsAcquisition,
  patientHeader: stores.patients.headerDetails,
}))(AddClaimsAcqLogModal)

export default InjectedAddClaimsAcqLogModal
