import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import ViewListIcon from '@material-ui/icons/ViewList'
import { inject } from 'mobx-react'
import * as React from 'react'
import { pagedList } from 'src/viewModels/pagedList'
import { QuoteSummaryResult } from 'src/viewModels/QuoteSummaryResult'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IStores } from '../Stores'
import { QuoteStatus } from '../utils/QuoteStatus'
import BundleClaimsModal from './QuoteBills/BundleClaimsModal'
import { formatCurrency } from '../utils/Formatter'
import ReactPaginate from 'react-paginate'
import { QuoteSearchRequest } from 'src/viewModels/QuoteSearchRequest'
import {
  generateCostSavings,
  GetQuoteTotalsSummary,
  searchQuotes,
} from 'src/services/QuoteService'
import Moment from 'moment'
import ESMenu from 'src/common/ESMenu'
import { CommentOutline } from 'mdi-material-ui'
import { Helmet } from 'react-helmet'
import {
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  InfoOutlined,
  ReceiptOutlined,
  Warning,
} from '@material-ui/icons'
import InvoiceLogModal from './InvoiceLogModal'
import { ChangeEvent } from 'react'
import EOCStatus from 'src/utils/EOCStatus'
import QuoteLogModal from './QuoteLogModal'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { QuoteTotalsSummary } from 'src/viewModels/quotes/QuoteTotalsSummary'
import { QuoteType } from 'src/utils/QuoteType'
// tslint:disable-next-line: no-var-requires
const CurrencyFormat: any = require('react-currency-format')

const styles = ({}: Theme) => createStyles({})

interface IQuoteListProps extends WithStyles<typeof styles> {
  buildQuoteMenuItems?: (quote: QuoteSummaryResult) => any[]
  invoiceLogModalIsOpen?: boolean
  selectedQuoteId?: string
  selectedInvoiceId?: string
  closeInvoiceLogModal?: () => void
  openInvoiceLogModal?: (request: QuoteSummaryResult) => void
  startRecon?: boolean
  restartQuote?: boolean
  setParentRefreshWithBundle?: (setRefresh: boolean) => void
  setReloadFunction?: (searchFunc: () => void) => void
  setQuoteRequest?: (request: QuoteSearchRequest) => void
  storedRequest?: QuoteSearchRequest
  openQuoteLogModal?: (request: QuoteSummaryResult) => void
  selectedPatientHeader?: string
  selectedQuote?: IQuoteDTO
  adjusterModalIsOpen?: boolean
}

class QuoteList extends React.Component<IQuoteListProps> {
  public quoteStatus = [
    { key: QuoteStatus.Draft.toString(), text: 'Draft' },
    { key: QuoteStatus.Imported.toString(), text: 'Imported' },
    { key: QuoteStatus.NeedsQuote.toString(), text: 'Needs Quote' },
    { key: QuoteStatus.NeedsRecon.toString(), text: 'Needs Reconciliation' },
    {
      key: QuoteStatus.PendingCareCoordinator.toString(),
      text: 'Pending Care Coordinator',
    },
    { key: QuoteStatus.PendingContract.toString(), text: 'Pending Contract' },
    { key: QuoteStatus.PendingFinance.toString(), text: 'Pending Finance' },
    { key: QuoteStatus.Quoted.toString(), text: 'Quoted' },
    { key: QuoteStatus.Reconciled.toString(), text: 'Reconciled' },
    { key: QuoteStatus.Dismissed.toString(), text: 'Dismissed' },
  ]

  public quoteType = [
    { key: QuoteType.Surgical.toString(), text: 'Surgical' },
    { key: QuoteType.NonSurgical.toString(), text: 'Non-Surgical' },
    { key: QuoteType.Injection.toString(), text: 'Injection' },
  ]

  public state = {
    gridData: new pagedList().items as QuoteSummaryResult[],
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 25,
    searchPatientLastName: this.props.storedRequest?.patientLastName ?? '',
    searchPatientFirstName: this.props.storedRequest?.patientFirstName ?? '',
    searchEOCNum: this.props.storedRequest?.eocNumber ?? '',
    searchCptCode: this.props.storedRequest?.cptCode ?? '',
    searchProviderGroup: this.props.storedRequest?.providerGroup ?? '',
    searchInsuranceCompany: this.props.storedRequest?.insuranceCompany ?? '',
    searchPrimaryAdjuster: this.props.storedRequest?.primaryAdjuster ?? '',
    searchSurgeryDateStart: this.props.storedRequest?.surgeryDateStart ?? '',
    searchSurgeryDateEnd: this.props.storedRequest?.surgeryDateEnd ?? '',
    searchQuoteStatus: new Map<string, string>(),
    searchQuoteType: new Map<string, string>(),
    searchEOCStatus: this.props.storedRequest?.eocStatus ?? '',
    searchEmployer: this.props.storedRequest?.employer ?? '',
    searchUrgentOnly: false,
    selectedEocNumber: '',
    gridLoading: false,
    hasMMI: false,
    disableLinks: true,
    pdfLoading: false,
    selectedInvoiceId: '',
    selectedGroupInfo: '',
    quoteTotals: new QuoteTotalsSummary(),
  }

  public componentDidMount() {
    if (this.props.storedRequest?.quoteStatuses != undefined) {
      this.props.storedRequest?.quoteStatuses.forEach((status) => {
        this.state.searchQuoteStatus.set(
          status.toString(),
          this.quoteStatus.find((x) => x.key == status.toString())!.text
        )
      })
    }

    if (this.props.storedRequest?.quoteTypes != undefined) {
      this.props.storedRequest?.quoteTypes.forEach((type) => {
        this.state.searchQuoteType.set(
          type.toString(),
          this.quoteType.find((x) => x.key == type.toString())!.text
        )
      })
    }

    this.setState({
      searchQuoteStatus: this.state.searchQuoteStatus,
      searchQuoteType: this.state.searchQuoteType,
    })
    this.props.setParentRefreshWithBundle!(false)
    this.props.setReloadFunction!(this.getQuoteData)
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div style={{ paddingBottom: '20px' }} className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private search = (event: any) => {
    this.setState({ page: 1 }, () => this.getQuoteData(true))
    event.preventDefault()
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={14} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((quote, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell
            style={{
              paddingLeft: '40px',
              paddingRight: '0px',
              color: quote.urgent ? 'red' : 'default',
            }}
          >
            {quote.urgent && (
              <Tooltip title="Urgent">
                <Warning color="error" fontSize="small" />
              </Tooltip>
            )}
          </TableCell>
          <TableCell style={{ whiteSpace: 'nowrap' }}>
            {quote.eocNumber}&nbsp;&nbsp;
            {
              <Tooltip title="View Quote Log">
                <ReceiptOutlined
                  style={{ cursor: 'pointer' }}
                  fontSize="small"
                  onClick={() => {
                    this.props.openQuoteLogModal!(quote)
                  }}
                />
              </Tooltip>
            }
          </TableCell>
          <TableCell>{quote.quoteType}</TableCell>
          <TableCell style={{ whiteSpace: 'nowrap' }}>
            {quote.quoteStatus}&nbsp;&nbsp;
            {quote.latestStatusComment ? (
              <Tooltip title={quote.latestStatusComment}>
                <span>
                  <CommentOutline fontSize="small" />
                </span>
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell>{quote.patientName}</TableCell>
          <TableCell>{quote.insuranceCompanyName}</TableCell>
          <TableCell align="right">
            {quote.startDate &&
            new Date(quote.startDate!).valueOf() >
              new Date('1900-01-17T03:24:00').valueOf()
              ? Moment(quote.startDate).format('MM/DD/YYYY')
              : ''}
          </TableCell>
          <TableCell align="right">{quote.bundleDurationDays}</TableCell>
          <TableCell>{quote.inbox}</TableCell>
          <TableCell>{quote.eocStatus}</TableCell>
          <TableCell align="right">
            {quote.amount != undefined ? formatCurrency(quote.amount.toString()) : ''}
          </TableCell>
          <TableCell align="right">
            {quote.reconciledAmount != undefined
              ? formatCurrency(quote.reconciledAmount.toString())
              : ''}
          </TableCell>
          {quote.nonPTBundledClaimCount > 0 ? (
            <TableCell style={{ whiteSpace: 'nowrap' }} align="right">
              {quote.nonPTOverallClaimPercentageReconciled.toString() + '%'}
              &nbsp;&nbsp;
              <Tooltip
                title={`Based on ${quote.nonPTBundledClaimCount.toString()} bundled non-PT claim${
                  quote.nonPTBundledClaimCount > 1 ? 's' : ''
                }`}
              >
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </TableCell>
          ) : (
            <TableCell></TableCell>
          )}
          <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
            {quote.invoiceId ? <Check htmlColor="green" /> : <Close htmlColor="red" />}
            &nbsp;&nbsp;
            {quote.invoiceId != undefined ? (
              <Tooltip title="Show Invoice Log">
                <ReceiptOutlined
                  style={{ cursor: 'pointer' }}
                  fontSize="small"
                  onClick={() => this.props.openInvoiceLogModal!(quote)}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell style={{ padding: '0px' }}>
            {this.renderContextMenu(quote, index)}
          </TableCell>
        </TableRow>
      ))
    }
  }

  private getQuoteData = async (
    setSavedSearch: boolean = false,
    forPageChange: boolean = false
  ) => {
    this.setState({ gridLoading: true })
    const { page, pageSize } = this.state
    const requestData = new QuoteSearchRequest()
    const selectStatuses = Array.from(this.state.searchQuoteStatus.keys())
    const selectedTypes = Array.from(this.state.searchQuoteType.keys())
    requestData.page = page
    requestData.pageSize = pageSize
    requestData.patientFirstName = this.state.searchPatientFirstName
    requestData.patientLastName = this.state.searchPatientLastName
    requestData.cptCode = this.state.searchCptCode
    requestData.providerGroup = this.state.searchProviderGroup
    requestData.quoteStatuses = selectStatuses.map(Number)
    requestData.quoteTypes = selectedTypes.map(Number)
    requestData.employer = this.state.searchEmployer
    requestData.surgeryDateStart = this.state.searchSurgeryDateStart
    requestData.surgeryDateEnd = this.state.searchSurgeryDateEnd
    requestData.eocNumber = this.state.searchEOCNum
    requestData.insuranceCompany = this.state.searchInsuranceCompany
    requestData.primaryAdjuster = this.state.searchPrimaryAdjuster
    requestData.eocStatus = this.state.searchEOCStatus
    requestData.urgent = this.state.searchUrgentOnly
    await searchQuotes(requestData).then(async (response: any) => {
      if (forPageChange) {
        this.setState({
          gridData: response.items,
          pageCount: response.totalPages,
          totalItems: response.totalItems,
          gridLoading: false,
        })
      } else {
        await GetQuoteTotalsSummary(requestData).then((totals: any) => {
          this.setState({
            quoteTotals: totals,
            gridData: response.items,
            pageCount: response.totalPages,
            totalItems: response.totalItems,
            gridLoading: false,
          })

          if (
            this.state.totalItems > 0 &&
            this.state.searchEOCStatus == EOCStatus.CaseClosed
          ) {
            this.setState({ hasMMI: true, disableLinks: false })
          } else {
            this.setState({ disableLinks: true })
          }

          if (response.totalItems > 0) {
            this.setPaginationOffsetData()
          }

          if (setSavedSearch) {
            this.props.setQuoteRequest!(requestData)
          }
        })
      }
    })
  }

  private getPdf = async (bundled: boolean) => {
    this.setState({ pdfLoading: true })
    const requestData = new QuoteSearchRequest()
    const selectStatuses = Array.from(this.state.searchQuoteStatus.keys())
    const selectedTypes = Array.from(this.state.searchQuoteType.keys())
    requestData.patientFirstName = this.state.searchPatientFirstName
    requestData.patientLastName = this.state.searchPatientLastName
    requestData.cptCode = this.state.searchCptCode
    requestData.providerGroup = this.state.searchProviderGroup
    requestData.quoteStatuses = selectStatuses.map(Number)
    requestData.quoteTypes = selectedTypes.map(Number)
    requestData.employer = this.state.searchEmployer
    requestData.surgeryDateStart = this.state.searchSurgeryDateStart
    requestData.surgeryDateEnd = this.state.searchSurgeryDateEnd
    requestData.eocNumber = this.state.searchEOCNum
    requestData.insuranceCompany = this.state.searchInsuranceCompany
    requestData.primaryAdjuster = this.state.searchPrimaryAdjuster
    requestData.eocStatus = this.state.searchEOCStatus

    await generateCostSavings(requestData, bundled).then(() => {
      this.setState({ pdfLoading: false })
    })
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getQuoteData(undefined, true))
  }

  private clearSearch = () => {
    this.setState(
      {
        page: 1,
        searchPatientLastName: '',
        searchPatientFirstName: '',
        searchCptCode: '',
        searchProviderGroup: '',
        searchEOCNum: '',
        searchInsuranceCompany: '',
        searchPrimaryAdjuster: '',
        searchSurgeryDateStart: '',
        searchSurgeryDateEnd: '',
        searchQuoteStatus: new Map<string, string>(),
        searchQuoteType: new Map<string, string>(),
        searchEOCStatus: '',
        searchEmployer: '',
        hasMMI: false,
        searchUrgentOnly: false,
      },
      () => {
        this.setState({ gridData: undefined, pageCount: 0, totalItems: 0 })
        this.props.setQuoteRequest!(new QuoteSearchRequest())
      }
    )
  }

  private handlePatientFirstNameChange = (event: any) => {
    this.setState({ searchPatientFirstName: event.target.value })
  }

  private handlePatientLastNameChange = (event: any) => {
    this.setState({ searchPatientLastName: event.target.value })
  }

  private handleEOCNumChange = (event: any) => {
    this.setState({ searchEOCNum: event.target.value })
  }

  private handleProviderGroupChange = (event: any) => {
    this.setState({ searchProviderGroup: event.target.value })
  }

  private handleCptCodeChange = (event: any) => {
    this.setState({ searchCptCode: event.target.value })
  }

  private handleInsuranceCompanyChange = (event: any) => {
    this.setState({ searchInsuranceCompany: event.target.value })
  }

  private handlePrimaryAdjusterChange = (event: any) => {
    this.setState({ searchPrimaryAdjuster: event.target.value })
  }

  private handleSurgeryStartDateChange = (event: any) => {
    this.setState({ searchSurgeryDateStart: event.target.value })
  }

  private handleSurgeryEndDateChange = (event: any) => {
    this.setState({ searchSurgeryDateEnd: event.target.value })
  }

  private handleQuoteStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (this.state.searchQuoteStatus.has(event.currentTarget.dataset.value!)) {
      this.state.searchQuoteStatus.delete(event.currentTarget.dataset.value!)
      this.setState({ searchQuoteStatus: this.state.searchQuoteStatus })
    } else {
      this.state.searchQuoteStatus.set(
        event.currentTarget.dataset.value!,
        event.currentTarget.dataset.name!
      )
      this.setState({ searchQuoteStatus: this.state.searchQuoteStatus })
    }
  }

  private handleQuoteTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (this.state.searchQuoteType.has(event.currentTarget.dataset.value!)) {
      this.state.searchQuoteType.delete(event.currentTarget.dataset.value!)
      this.setState({ searchQuoteType: this.state.searchQuoteType })
    } else {
      this.state.searchQuoteType.set(
        event.currentTarget.dataset.value!,
        event.currentTarget.dataset.name!
      )
      this.setState({ searchQuoteType: this.state.searchQuoteType })
    }
  }

  private handleEmployerChange = (event: any) => {
    this.setState({ searchEmployer: event.target.value })
  }

  private handleEOCStatusChange = (event: any) => {
    this.setState({ searchEOCStatus: event.target.value })
  }

  private handleOnlyUrgentChange = () => {
    this.setState({ searchUrgentOnly: !this.state.searchUrgentOnly })
  }

  private renderContextMenu = (quote: QuoteSummaryResult, index: number) => {
    let options = this.props.buildQuoteMenuItems!(quote)

    return (
      <ESMenu
        data={quote}
        menuItems={options}
        rowIndex={index}
        menuAriaLabel={`$quote-${index}-menu`}
      />
    )
  }

  public render() {
    const disableLink = this.state.disableLinks
    const MenuProps = {
      getContentAnchorEl: null,
      PaperProps: {
        style: {
          maxHeight: 400,
        },
      },
    }

    return (
      <>
        <Helmet>
          <title>Quotes</title>
        </Helmet>
        <ResponsiveAppBar title={'Quotes'} pageIcon={<ViewListIcon />} />
        <Grid container style={{ width: '100%', paddingBottom: '20px' }}>
          <BundleClaimsModal />
          {this.props.selectedQuoteId && <QuoteLogModal />}
          {this.props.selectedQuoteId && this.props.selectedInvoiceId ? (
            <InvoiceLogModal
              headerText={this.props.selectedPatientHeader!}
              quoteId={this.props.selectedQuoteId}
              invoiceId={this.props.selectedInvoiceId}
              isOpen={this.props.invoiceLogModalIsOpen}
              closeModal={this.props.closeInvoiceLogModal!}
            />
          ) : (
            <></>
          )}
          {(this.state.gridLoading || this.state.pdfLoading) && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '2',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <form onSubmit={this.search}>
            <Grid
              container
              style={{ margin: '10px 0px', paddingLeft: '30px', width: '100%' }}
              xs={12}
            >
              <Typography
                color="primary"
                component="h6"
                variant="h6"
                gutterBottom
                style={{ paddingBottom: '10px' }}
              >
                SEARCH CRITERIA
              </Typography>
              <Grid container direction="row" spacing={2} style={{ width: '100%' }}>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchPatientFirstName}
                      onChange={this.handlePatientFirstNameChange}
                      label="Patient First Name"
                      name="patientFirst"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchPatientLastName}
                      onChange={this.handlePatientLastNameChange}
                      label="Patient Last Name"
                      name="patientLast"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchProviderGroup}
                      onChange={this.handleProviderGroupChange}
                      label="Provider Group"
                      name="providerGroup"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item container xs={4} direction="row">
                  <Grid item>
                    <FormControl fullWidth>
                      <TextField
                        value={this.state.searchSurgeryDateStart}
                        onChange={this.handleSurgeryStartDateChange}
                        label="Surgery Date Start"
                        name="surgeryDateStart"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        type="date"
                        defaultValue={this.state.searchSurgeryDateStart}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item style={{ margin: '0px 10px' }}>
                    <strong> - </strong>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <TextField
                        value={this.state.searchSurgeryDateEnd}
                        onChange={this.handleSurgeryEndDateChange}
                        label="Surgery Date End"
                        name="surgeryDateEnd"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        type="date"
                        defaultValue={this.state.searchSurgeryDateEnd}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchEOCNum}
                      onChange={this.handleEOCNumChange}
                      label="EOC/Legacy"
                      name="eocNum"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchCptCode}
                      onChange={this.handleCptCodeChange}
                      label="CPT"
                      name="cptCode"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchInsuranceCompany}
                      onChange={this.handleInsuranceCompanyChange}
                      label="Insurance Co"
                      name="insuranceCompany"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchPrimaryAdjuster}
                      onChange={this.handlePrimaryAdjusterChange}
                      label="Primary Adjuster"
                      name="primaryAdjuster"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchEmployer}
                      onChange={this.handleEmployerChange}
                      label="Employer"
                      name="employer"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel shrink={true} variant="outlined">
                      Quote Type
                    </InputLabel>
                    <Select
                      variant="outlined"
                      input={<OutlinedInput notched labelWidth={86} />}
                      inputProps={{ label: true, notched: true }}
                      fullWidth
                      id="quoteType"
                      multiple
                      value={Array.from(this.state.searchQuoteType.keys())}
                      renderValue={() =>
                        Array.from(this.state.searchQuoteType.values()).join(', ')
                      }
                      onChange={this.handleQuoteTypeChange}
                      MenuProps={MenuProps}
                    >
                      {this.quoteType.map((item) => (
                        <MenuItem value={item.key} data-name={item.text}>
                          {item.key &&
                          this.state.searchQuoteType.has(item.key.toString()) ? (
                            <CheckBox
                              color="secondary"
                              style={{ paddingRight: '10px' }}
                            />
                          ) : (
                            <CheckBoxOutlineBlank
                              color="action"
                              style={{ paddingRight: '10px' }}
                            />
                          )}{' '}
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel shrink={true} variant="outlined">
                      Quote Status
                    </InputLabel>
                    <Select
                      variant="outlined"
                      input={<OutlinedInput notched labelWidth={96} />}
                      inputProps={{ label: true, notched: true }}
                      fullWidth
                      id="quoteStatus"
                      multiple
                      value={Array.from(this.state.searchQuoteStatus.keys())}
                      renderValue={() =>
                        Array.from(this.state.searchQuoteStatus.values()).join(', ')
                      }
                      onChange={this.handleQuoteStatusChange}
                      MenuProps={MenuProps}
                    >
                      {this.quoteStatus.map((item) => (
                        <MenuItem value={item.key} data-name={item.text}>
                          {item.key &&
                          this.state.searchQuoteStatus.has(item.key.toString()) ? (
                            <CheckBox
                              color="secondary"
                              style={{ paddingRight: '10px' }}
                            />
                          ) : (
                            <CheckBoxOutlineBlank
                              color="action"
                              style={{ paddingRight: '10px' }}
                            />
                          )}{' '}
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      shrink={true}
                      htmlFor="eoc-status-select"
                      variant="outlined"
                    >
                      EOC Status
                    </InputLabel>
                    <Select
                      variant="outlined"
                      input={
                        <OutlinedInput
                          id="eoc-status-select"
                          name="eocStatus"
                          notched
                          labelWidth={80}
                        />
                      }
                      inputProps={{ label: true, notched: true }}
                      fullWidth
                      id="eocStatus"
                      value={this.state.searchEOCStatus}
                      onChange={this.handleEOCStatusChange}
                      native
                    >
                      <option value={EOCStatus.None}>{EOCStatus.None}</option>
                      <option value={EOCStatus.Cancelled}>{EOCStatus.Cancelled}</option>
                      <option value={EOCStatus.Evaluation}>{EOCStatus.Evaluation}</option>
                      <option value={EOCStatus.CaseClosed}>{EOCStatus.CaseClosed}</option>
                      <option value={EOCStatus.InjectionBundle}>
                        {EOCStatus.InjectionBundle}
                      </option>
                      <option value={EOCStatus.NonSurgical}>
                        {EOCStatus.NonSurgical}
                      </option>
                      <option value={EOCStatus.NonSurgicalBundle}>
                        {EOCStatus.NonSurgicalBundle}
                      </option>
                      <option value={EOCStatus.SurgicalBundle}>
                        {EOCStatus.SurgicalBundle}
                      </option>
                      <option value={EOCStatus.SxPostponed}>
                        {EOCStatus.SxPostponed}
                      </option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={1}>
                  <FormControl>
                    <FormControlLabel
                      label="Only Show Urgent"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checked={this.state.searchUrgentOnly}
                          onChange={this.handleOnlyUrgentChange}
                          inputProps={{
                            id: 'urgent-only-filter',
                          }}
                          value="searchUrgentOnly"
                          name="searchUrgentOnly"
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{
                    width: '100%',
                    marginLeft: '8px',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                  }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="contained"
                    style={{ marginRight: '10px' }}
                  >
                    Search
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.clearSearch}
                    size="medium"
                    variant="contained"
                  >
                    Clear Search
                  </Button>
                </Grid>
              </Grid>
              <Grid item container direction="row" style={{ paddingTop: '20px' }}>
                <Grid item xs={12}>
                  <Typography noWrap variant="h5">
                    Total Invoice Balances Due:&nbsp;
                    <strong>
                      {this.isGridDataEmpty() ? (
                        'N/A'
                      ) : (
                        <CurrencyFormat
                          value={this.state.quoteTotals.totalInvoice}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />
                      )}
                    </strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography noWrap variant="h5">
                    Total Balance Past Due (30+ days):&nbsp;
                    <strong>
                      {this.isGridDataEmpty() ? (
                        'N/A'
                      ) : (
                        <CurrencyFormat
                          value={this.state.quoteTotals.totalInvoicePastDue}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />
                      )}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ justifyContent: 'flex-end', display: 'flex' }} xs={9}>
              {this.state.hasMMI && (
                <>
                  <Link
                    style={{
                      marginRight: '20px',
                      cursor: disableLink ? 'default' : 'pointer',
                      color: disableLink ? 'grey' : '#29348F',
                    }}
                    underline={'always'}
                    onClick={
                      disableLink
                        ? () => {}
                        : () => {
                            this.getPdf(true)
                          }
                    }
                  >
                    Bundled Cost Savings Report
                  </Link>
                  <Link
                    style={{
                      cursor: disableLink ? 'default' : 'pointer',
                      color: disableLink ? 'grey' : '#29348F',
                    }}
                    underline={'always'}
                    onClick={
                      disableLink
                        ? () => {}
                        : () => {
                            this.getPdf(false)
                          }
                    }
                  >
                    Non-Bundled Cost Savings Report
                  </Link>
                </>
              )}
            </Grid>
          </form>
          <Grid container item md={12} style={{ minWidth: '750px', width: '100%' }}>
            <Table aria-label="simple table" style={{ padding: '20' }}>
              <TableHead>
                <TableRow className="gridPadding">
                  <TableCell></TableCell>
                  <TableCell>EOC/Legacy</TableCell>
                  <TableCell>Quote Type</TableCell>
                  <TableCell>Quote Status</TableCell>
                  <TableCell style={{ paddingRight: 0 }}>Patient</TableCell>
                  <TableCell>Insurance Co</TableCell>
                  <TableCell align="right">Start Date</TableCell>
                  <TableCell align="right"># of Days</TableCell>
                  <TableCell>Inbox</TableCell>
                  <TableCell>EOC Status</TableCell>
                  <TableCell align="right">Quoted $</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }} align="right">
                    Reconciled $
                  </TableCell>
                  <TableCell align="right">Claim Reconciled %</TableCell>
                  <TableCell>Invoiced</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '100%' }}>{this.mapGridData()}</TableBody>
            </Table>
          </Grid>
        </Grid>
        <div className="pagination-row">
          {this.paginationInfo()}
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            onPageChange={this.handlePageChange}
            pageCount={this.state.pageCount}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={this.state.page - 1}
            //@ts-ignore
            renderOnZeroPageCount={null}
          />
        </div>
      </>
    )
  }
}

const InjectedQuoteList = inject<IStores, IQuoteListProps, Partial<IQuoteListProps>, any>(
  (stores: IStores) => ({
    buildQuoteMenuItems: stores.quotes.buildQuoteMenuItems,
    invoiceLogModalIsOpen: stores.quotes.invoiceLogModalIsOpen,
    selectedQuoteId: stores.quotes.selectedQuoteId,
    selectedInvoiceId: stores.quotes.selectedInvoiceId,
    openInvoiceLogModal: stores.quotes.openInvoiceLogModal,
    closeInvoiceLogModal: stores.quotes.closeInvoiceLogModal,
    restartQuote: stores.quotes.restartQuote,
    startRecon: stores.quotes.startRecon,
    setParentRefreshWithBundle: stores.quotes.setParentRefreshWithBundle,
    setReloadFunction: stores.quotes.setReloadFunction,
    setQuoteRequest: stores.global.setQuoteRequest,
    storedRequest: stores.global.quoteRequest,
    openQuoteLogModal: stores.quotes.openQuoteLogModal,
    selectedPatientHeader: stores.quotes.selectedQuotePatientHeader,
    headerText: stores.quotes.selectedQuotePatientHeader,
    selectedQuote: stores.quotes.selectedQuote,
    adjusterModalIsOpen: stores.quotes.isAdjusterEmailModalOpen,
  })
)(QuoteList)

export default withStyles(styles)(InjectedQuoteList)
