import { AppealType } from 'src/utils/AppealType'

export interface IMarkClaimAsAppealSummary {
  originalClaimId: string
  appealType: AppealType
}

export class MarkClaimAsAppealSummary implements IMarkClaimAsAppealSummary {
  public originalClaimId: string
  public appealType: AppealType
}
