import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { AttachMoney } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React, { ChangeEvent } from 'react'
import {
  DefaultProvider,
  IEpisodeOfCare,
  ILocation,
  IOrganization,
  IProvider,
  IUser,
} from 'src/Definitions'
import { IStores } from 'src/Stores'
import Moment from 'moment'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { Form, Formik, FormikActions, FormikProps, getIn } from 'formik'
import {
  ProviderTypeDto,
  IProviderTypeDto,
  IPhysicianDto,
  ITypeDto,
  TypeDto,
} from 'src/generated_client'
import { IProcedureFilter } from 'src/schedules/ProviderLookupDialog'
import {
  searchProviderBillingLocations,
  GetLocationById,
} from 'src/services/LookupService'
import { BillingLocationSearchRequest } from 'src/viewModels/BillingLocationSearchRequest'
import { pagedList } from 'src/viewModels/pagedList'
import { ProviderBillingLocationSummaryResult } from 'src/viewModels/ProviderBillingLocationSummaryResult'
import ReactPaginate from 'react-paginate'
import FullWidthField from 'src/common/FullWidthField'
import TypeAheadField from 'src/common/TypeAheadField/TypeAheadField'
import * as Yup from 'yup'
import { ProfessionalComponentLineItemDTO } from 'src/viewModels/quotes/ProfessionalComponentLineItemDTO'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { TechnicalComponentLineItemDTO } from 'src/viewModels/quotes/TechnicalComponentLineItemDTO'
import { AnesthesiaLineItemDTO } from 'src/viewModels/quotes/AnesthesiaLineItemDTO'
import { PhysicalTherapyLineItemDTO } from 'src/viewModels/quotes/PhysicalTherapyLineItemDTO'
import { MiscellaneousLineItemDTO } from 'src/viewModels/quotes/MiscellaneousLineItemDTO'
import { QuoteCalculationRequest } from 'src/viewModels/QuoteCalculationRequest'
import { calculateLineItem } from 'src/services/QuoteService'
import { formatCurrency } from 'src/utils/Formatter'
import { CostSourceText } from './CostSourceText'
import QuoteTypeNameConstants from 'src/constants/QuoteTypeNameConstants'

interface IAddQuoteLineModalProps {
  close?: ((reload: () => void) => void) | (() => void)
  isLoading?: boolean
  isOpen?: boolean
  quote?: IQuoteDTO

  addProviderLocation?: (scheduleId: string, locationId: string) => Promise<void>
  clearData?: () => Promise<void>
  clearLocations?: () => void
  currentAppOrganization?: IOrganization
  getAllProviderTypes?: () => Promise<void>
  getScheduleById?: (id: string) => Promise<void>
  lookupTitle?: string
  providerTypes?: ProviderTypeDto[]
  selectedEpisodeOfCare?: IEpisodeOfCare
  selectedProvider?: IProvider
  selectedLocation?: ILocation
  selectedProviderTypeId?: string
  setSelectedLocation?: (location?: ILocation) => void
  setSelectedProcedureFilters?: (procedureFilters?: IProcedureFilter[]) => void
  setSelectedProvider?: (provider?: IProvider) => void
  setSelectedProviderGroup?: (providerGroup?: string) => void
  setSelectedProviderLocation?: (locationName?: string) => void
  setSelectedProviderTypeId?: (selectedProviderTypeId: string) => void
  updateParentStateAfterModalEvent?: () => void
  updateParentStateAfterModal?: () => void
  isNum?: (str: any) => boolean

  getPhysicians?: (filter: string, providerId: string) => Promise<void>
  physicians?: IPhysicianDto[]
  getTypes?: (filter: string) => Promise<void>
  types?: ITypeDto[]
  selectedType?: ITypeDto
  setSelectedType?: (selectedType?: ITypeDto) => void

  reloadParent?: () => void
  modalSwitch?: string
  user?: IUser
  showAssistantWarning?: () => void
}

interface IBillingProviderLookupFormValues {
  providerTypeId?: string
  providerLocation?: string
  providerGroup?: string
}

class AddQuoteLineModal extends React.Component<IAddQuoteLineModalProps> {
  private search = async (
    values: IBillingProviderLookupFormValues,
    formikActions: FormikActions<IBillingProviderLookupFormValues>
  ) => {
    this.props.setSelectedLocation!(undefined)
    this.props.setSelectedProvider!(undefined)
    this.props.setSelectedProviderGroup!(values.providerGroup)
    this.props.setSelectedProviderLocation!(values.providerLocation)
    this.setState(
      {
        providerGroup: values.providerGroup,
        providerLocation: values.providerLocation,
        providerTypeId: this.props.selectedProviderTypeId,
        page: 1,
      },
      () => this.getProviderData()
    )
    formikActions.setSubmitting(false)
  }

  private getProviderTypes = (providerTypes?: ProviderTypeDto[]) => {
    return providerTypes ? ([undefined] as any[]).concat(providerTypes) || [] : []
  }

  private getProviderTypeName = (type: IProviderTypeDto) => {
    return type ? type.name || '' : ''
  }

  private getProviderTypeValue = (type: IProviderTypeDto) => {
    return type ? type.id || '' : ''
  }

  private setSelectedProviderTypeId = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setSelectedProviderTypeId!(event.target.value)
  }

  public setPhysicianValue = (fieldName: string, value: any) => {
    const newValue = value !== undefined ? value.id : ''
    this.secondFormRef.current.setFieldValue('physicianId', newValue)
    this.secondFormRef.current.setFieldValue(fieldName, value)
  }

  private getPhysicianName = (physician: IPhysicianDto) =>
    (physician ? physician.name : '')!
  private getPhysicianValue = (physician: IPhysicianDto) =>
    (physician ? physician.id : '')!

  private setSelectedType = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true

    var type = new TypeDto()
    type.id = event.target.selectedOptions[0].accessKey
    type.name = event.target.selectedOptions[0].value

    if (this.props.modalSwitch == 'AN') {
      this.secondFormRef.current!.state!.values!.monitored = undefined
      this.calculateLineCost()
    }

    this.props.setSelectedType!(type)
    this.assistantSetFields(type.name)
  }

  private setSelectedCostSource = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    this.setState({
      selectedCostSource: event.target.selectedOptions[0].value,
    })
  }

  private setSelectedMultiDiscount = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    this.setState({ selectedMultiDiscount: event.target.selectedOptions[0].value }, () =>
      this.calculateLineCost()
    )
  }

  private setBilateral = async () => {
    this.setState({ bilateral: !this.state.bilateral }, () => this.calculateLineCost())
  }

  private calculateLineCost = async () => {
    this.setState({ cost: 'Calculating...' })
    const formikValues = this.secondFormRef.current?.state?.values
    const requestData = new QuoteCalculationRequest()

    requestData.multipleProcedureMultiplier = this.state.selectedMultiDiscount
    requestData.contractMultiplier =
      !this.props.isNum!(formikValues.contractMultiplier) &&
      formikValues.contractMultiplier != undefined
        ? undefined
        : formikValues.contractMultiplier
    requestData.actualCost =
      !this.props.isNum!(formikValues.actualCost) && formikValues.actualCost != undefined
        ? undefined
        : formikValues.actualCost
    requestData.bilateral = this.state.bilateral || false
    requestData.monitoredMultiplier =
      !this.props.isNum!(formikValues.monitored) && formikValues.monitored != undefined
        ? undefined
        : formikValues.monitored

    await calculateLineItem(requestData).then((response: any) => {
      this.setState({
        cost: response.cost,
        calcText: response.calculationExplanation,
      })
    })
  }

  private assistantSetFields(type?: string) {
    const formikValues = this.secondFormRef.current?.state?.values

    if (
      (type == undefined || type == 'Assistant') &&
      formikValues.assistant != undefined &&
      this.props.isNum!(formikValues.assistant)
    ) {
      formikValues.actualCost = undefined
      formikValues.contractMultiplier = undefined
      var select_box = document.getElementById('discountSelect') as HTMLSelectElement

      if (select_box != undefined) {
        select_box.selectedIndex = 0
      }

      this.setState({
        assistantPresent: true,
        bilateral: false,
        cost: 'Will Calculate on Save',
        selectedMultiDiscount: undefined,
      })
    } else {
      if (type != undefined && type != 'Assistant') {
        formikValues.assistant = undefined
      }
      this.setState({ assistantPresent: false, cost: '' })
    }
  }

  public render() {
    const { providerTypes } = this.props
    const initialValues = {
      providerLocation: '',
      providerTypeId: '',
      providerGroup: '',
    }

    const { isOpen, quote } = this.props
    const quoteId = quote ? quote.id : ''
    const patientName = quote?.patientName
    const dob =
      quote?.dateOfBirth != undefined
        ? Moment(quote?.dateOfBirth).format('MM/DD/YYYY')
        : ''
    const surgery =
      quote?.surgeryDate != undefined
        ? Moment(quote?.surgeryDate).format('MM/DD/YYYY')
        : 'TBD'
    const body = quote?.bodyPartName != undefined ? quote?.bodyPartName : 'TBD'
    var QuoteLineSchema = undefined
    var modalTitle = ''

    switch (this.props.modalSwitch) {
      case 'PC':
        if (this.state.assistantPresent) {
          QuoteLineSchema = Yup.object().shape({
            physician: Yup.string(),
            cpt: Yup.string(),
            assistant: Yup.number().typeError('Assistant must be a valid number'),
            description: Yup.string().required('Description is required'),
            costSource: Yup.number().required('Cost Source is required'),
          })
        } else {
          QuoteLineSchema = Yup.object().shape({
            physician: Yup.string(),
            cpt: Yup.string(),
            assistant: Yup.number().typeError('Assistant must be a valid number'),
            actualCost: Yup.number()
              .typeError('Billed charges must be a valid dollar amount')
              .required('Billed Charges is Required'),
            contractMultiplier: Yup.number().typeError(
              'Percentage of contract must be a valid dollar amount'
            ),
            description: Yup.string().required('Description is required'),
            costSource: Yup.number().required('Cost Source is required'),
          })
        }

        modalTitle = 'Add Professional Component Line'
        break
      case 'TC':
        QuoteLineSchema = Yup.object().shape({
          cpt: Yup.string(),
          actualCost: Yup.number()
            .typeError('Billed charges must be a valid dollar amount')
            .required('Billed Charges is Required'),
          contractMultiplier: Yup.number().typeError(
            'Percentage of contract must be a valid dollar amount'
          ),
          description: Yup.string().required('Description is required'),
          costSource: Yup.number().required('Cost Source is required'),
        })
        modalTitle = 'Add Technical Component Line'
        break

      case 'AN':
        QuoteLineSchema = Yup.object().shape({
          cpt: Yup.string(),
          actualCost: Yup.number()
            .typeError('Billed charges must be a valid dollar amount')
            .required('Billed Charges is Required'),
          contractMultiplier: Yup.number().typeError(
            'Percentage of contract must be a valid dollar amount'
          ),
          description: Yup.string().required('Description is required'),
          costSource: Yup.number().required('Cost Source is required'),
          monitored: Yup.number().typeError('Monitored AN must be a number'),
        })
        modalTitle = 'Add Anesthesiologist Component Line'
        break

      case 'PT':
        QuoteLineSchema = Yup.object().shape({
          numberOfVisits: Yup.number()
            .typeError('Number of Visits must be a number')
            .required('Number of Visits is required'),
          costPerVisit: Yup.number()
            .typeError('Quoted Per Visit must be a valid dollar amount')
            .required('Quoted Per Visit is required'),
          actualCostPerVisit: Yup.number()
            .typeError('Est. U&C Per Visit must be a valid dollar amount')
            .required('Est. U&C Per Visit is required'),
        })
        modalTitle = 'Add Physical Therapy Component Line'
        break
      case 'MI':
        QuoteLineSchema = Yup.object().shape({
          cpt: Yup.string(),
          actualCost: Yup.number()
            .typeError('Billed charges must be a valid dollar amount')
            .required('Billed Charges is Required'),
          contractMultiplier: Yup.number().typeError(
            'Percentage of contract must be a valid dollar amount'
          ),
          description: Yup.string().required('Description is required'),
          costSource: Yup.number().required('Cost Source is required'),
          type: Yup.string().required('Type is required'),
          lineDescription: Yup.string().required('Line Description is required'),
        })
        modalTitle = 'Add Miscellaneous Line'
        break
    }

    return (
      <Dialog key={quoteId} fullWidth={true} maxWidth="lg" open={isOpen!}>
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title={modalTitle}
            pageIcon={<AttachMoney />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            style={{ paddingTop: 12, paddingBottom: 12 }}
            wrap="nowrap"
          >
            <Grid item>
              <strong>{patientName}</strong>
            </Grid>
            <Grid item>
              <strong>DOB:</strong> {dob}
            </Grid>
            <Grid item>
              <strong>Quote Type:</strong>{' '}
              {quote?.type != undefined ? QuoteTypeNameConstants[quote?.type] : ''}
            </Grid>
            <Grid item>
              <strong>Surgery:</strong> {surgery}
            </Grid>
            <Grid item>
              <strong>Body Part:</strong> {body}
            </Grid>
            {this.state.providerName != '' ? (
              <Grid item>
                <strong>Provider:</strong> {this.state.providerName}
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12}>
            {this.state.modalPage == 2 ? (
              <Formik
                initialValues={{ cpt: '', costSourceSelect: '' }}
                onSubmit={() => {}}
                validationSchema={QuoteLineSchema}
                ref={this.secondFormRef}
              >
                {({ values }) => (
                  <Form id="secondForm">
                    <Grid spacing={2} justifyContent="space-between">
                      {this.props.modalSwitch == 'PC' ? (
                        <>
                          <Grid
                            item
                            container
                            style={{ margin: '10px 0px' }}
                            xs={12}
                            spacing={2}
                          >
                            <Grid item xs={6} md={3}>
                              <TypeAheadField
                                outlined
                                name="physician"
                                label="Physician"
                                cypressLabel="Physician"
                                fullWidth
                                setFieldValue={this.setPhysicianValue}
                                selectedValue={getIn(values, 'physician')}
                                items={this.props.physicians}
                                getName={this.getPhysicianName}
                                getValue={this.getPhysicianValue}
                                getItems={() =>
                                  this.props.getPhysicians!(
                                    '',
                                    this.props.selectedProvider?.id!
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={6} md={3}>
                              {this.props.types ? (
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel shrink={true} variant="outlined">
                                    Type
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    input={<OutlinedInput notched labelWidth={38} />}
                                    inputProps={{ label: true, notched: true }}
                                    native={true}
                                    fullWidth
                                    onChange={this.setSelectedType}
                                    id="type"
                                  >
                                    <option value="">Select a type</option>
                                    {this.props.types.map((item: TypeDto) => (
                                      <option
                                        accessKey={item.id}
                                        value={item.name}
                                        disabled={false}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </Select>{' '}
                                </FormControl>
                              ) : (
                                <></>
                              )}
                            </Grid>
                            {this.props.selectedType?.name == 'Assistant' ? (
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="Assistant Surgeon %"
                                  name="assistant"
                                  variant="outlined"
                                  onKeyUp={() => this.assistantSetFields()}
                                />
                              </Grid>
                            ) : (
                              <></>
                            )}
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="CPT"
                                name="cpt"
                                variant="outlined"
                                disabled={false}
                              />
                            </Grid>
                            <Grid item xs={6} md={3} lg={2}>
                              <FormControl fullWidth>
                                <FormControlLabel
                                  label="Bilateral"
                                  labelPlacement="end"
                                  disabled={this.state.assistantPresent}
                                  onChange={() => {
                                    this.setBilateral()
                                  }}
                                  control={
                                    <Checkbox
                                      checked={this.state.bilateral}
                                      inputProps={{
                                        id: 'bilateral',
                                      }}
                                      value={this.state.bilateral}
                                      name="bilateral"
                                    />
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3} lg={4}>
                              <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Cost Source *
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={96} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  required
                                  onChange={this.setSelectedCostSource}
                                  id="costSourceSelect"
                                >
                                  <option value="">Select a cost source</option>
                                  {Object.keys(CostSourceText).map((key) => (
                                    <option accessKey={key} value={key}>
                                      {CostSourceText[key]}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {CostSourceText[this.state.selectedCostSource ?? 0] ==
                            'Other' ? (
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="Description"
                                  name="description"
                                  variant="outlined"
                                  required
                                />
                              </Grid>
                            ) : (
                              <></>
                            )}
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="Billed Charges"
                                name="actualCost"
                                variant="outlined"
                                onKeyUp={() => this.calculateLineCost()}
                                disabled={this.state.assistantPresent}
                                required={!this.state.assistantPresent}
                              />
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="% of Contract"
                                name="contractMultiplier"
                                variant="outlined"
                                disabled={this.state.assistantPresent}
                                onKeyUp={() => this.calculateLineCost()}
                              />
                            </Grid>
                            <Grid item xs={6} md={2}>
                              {this.props.types ? (
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel shrink={true} variant="outlined">
                                    MPPR
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    input={<OutlinedInput notched labelWidth={45} />}
                                    inputProps={{ label: true, notched: true }}
                                    native={true}
                                    fullWidth
                                    onChange={this.setSelectedMultiDiscount}
                                    disabled={this.state.assistantPresent}
                                    id="discountSelect"
                                  >
                                    <option value="">Select a MPPR</option>
                                    <option value="100">100%</option>
                                    <option value="50">50%</option>
                                    <option value="25">25%</option>
                                  </Select>
                                </FormControl>
                              ) : (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <strong>Quoted $:</strong>{' '}
                              {this.props.isNum!(this.state.cost) ||
                              this.state.cost == undefined
                                ? formatCurrency((this.state.cost ?? 0).toString())
                                : this.state.cost.toString()}
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.modalSwitch == 'TC' ? (
                        <>
                          <Grid
                            item
                            container
                            style={{ margin: '10px 0px' }}
                            xs={12}
                            spacing={2}
                          >
                            <Grid item xs={6} md={3}>
                              {this.props.types ? (
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel shrink={true} variant="outlined">
                                    Type
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    input={<OutlinedInput notched labelWidth={38} />}
                                    inputProps={{ label: true, notched: true }}
                                    native={true}
                                    fullWidth
                                    onChange={this.setSelectedType}
                                    id="type"
                                  >
                                    <option value="">Select a type</option>
                                    {this.props.types.map((item: TypeDto) => (
                                      <option
                                        accessKey={item.id}
                                        value={item.name}
                                        disabled={false}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </Select>{' '}
                                </FormControl>
                              ) : (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="CPT"
                                name="cpt"
                                variant="outlined"
                                disabled={false}
                              />
                            </Grid>
                            <Grid item xs={6} md={5} lg={4}>
                              <FormControl fullWidth>
                                <FormControlLabel
                                  label="Bilateral"
                                  labelPlacement="end"
                                  onChange={() => {
                                    this.setBilateral()
                                  }}
                                  control={
                                    <Checkbox
                                      checked={this.state.bilateral}
                                      inputProps={{
                                        id: 'bilateral',
                                      }}
                                      value={this.state.bilateral}
                                      name="bilateral"
                                    />
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3} lg={4}>
                              <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Cost Source *
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={96} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  required
                                  onChange={this.setSelectedCostSource}
                                  id="costSourceSelect"
                                >
                                  <option value="">Select a cost source</option>
                                  {Object.keys(CostSourceText).map((key) => (
                                    <option accessKey={key} value={key}>
                                      {CostSourceText[key]}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {CostSourceText[this.state.selectedCostSource ?? 0] ==
                            'Other' ? (
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="Description"
                                  name="description"
                                  variant="outlined"
                                  required
                                />
                              </Grid>
                            ) : (
                              <></>
                            )}
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="Billed Charges"
                                name="actualCost"
                                variant="outlined"
                                onKeyUp={() => this.calculateLineCost()}
                                required
                              />
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="% of Contract"
                                name="contractMultiplier"
                                variant="outlined"
                                onKeyUp={() => this.calculateLineCost()}
                              />
                            </Grid>
                            <Grid item xs={6} md={2}>
                              {this.props.types ? (
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel shrink={true} variant="outlined">
                                    MPPR
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    input={<OutlinedInput notched labelWidth={45} />}
                                    inputProps={{ label: true, notched: true }}
                                    native={true}
                                    fullWidth
                                    onChange={this.setSelectedMultiDiscount}
                                    id="eventSelect"
                                  >
                                    <option value="">Select a MPPR</option>
                                    <option value="100">100%</option>
                                    <option value="50">50%</option>
                                    <option value="25">25%</option>
                                  </Select>
                                </FormControl>
                              ) : (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <strong>Quoted $:</strong>{' '}
                              {this.props.isNum!(this.state.cost) ||
                              this.state.cost == undefined
                                ? formatCurrency((this.state.cost ?? 0).toString())
                                : this.state.cost.toString()}
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.modalSwitch == 'AN' ? (
                        <>
                          <Grid
                            item
                            container
                            style={{ margin: '10px 0px' }}
                            xs={12}
                            spacing={2}
                          >
                            <Grid item xs={6} md={3}>
                              <TypeAheadField
                                outlined
                                disabled={this.state.providerId == ''}
                                name="physician"
                                label="Physician"
                                cypressLabel="Physician"
                                fullWidth
                                setFieldValue={this.setPhysicianValue}
                                selectedValue={getIn(values, 'physician')}
                                items={this.props.physicians}
                                getName={this.getPhysicianName}
                                getValue={this.getPhysicianValue}
                                getItems={() =>
                                  this.props.getPhysicians!(
                                    '',
                                    this.props.selectedProvider?.id!
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={6} md={3}>
                              {this.props.types ? (
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel shrink={true} variant="outlined">
                                    Type
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    input={<OutlinedInput notched labelWidth={38} />}
                                    inputProps={{ label: true, notched: true }}
                                    native={true}
                                    fullWidth
                                    onChange={this.setSelectedType}
                                    id="type"
                                  >
                                    <option value="">Select a type</option>
                                    {this.props.types.map((item: TypeDto) => (
                                      <option
                                        accessKey={item.id}
                                        value={item.name}
                                        disabled={false}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </Select>{' '}
                                </FormControl>
                              ) : (
                                <></>
                              )}
                            </Grid>
                            {this.props.selectedType?.name == 'General/MAC' ? (
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="# Units"
                                  name="monitored"
                                  variant="outlined"
                                  onKeyUp={() => this.calculateLineCost()}
                                />
                              </Grid>
                            ) : (
                              <></>
                            )}
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="CPT"
                                name="cpt"
                                variant="outlined"
                                disabled={false}
                              />
                            </Grid>
                            <Grid item xs={6} md={3} lg={2}>
                              <FormControl fullWidth>
                                <FormControlLabel
                                  label="Bilateral"
                                  labelPlacement="end"
                                  onChange={() => {
                                    this.setBilateral()
                                  }}
                                  control={
                                    <Checkbox
                                      checked={this.state.bilateral}
                                      inputProps={{
                                        id: 'bilateral',
                                      }}
                                      value={this.state.bilateral}
                                      name="bilateral"
                                    />
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3} lg={4}>
                              <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Cost Source *
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={96} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  required
                                  onChange={this.setSelectedCostSource}
                                  id="costSourceSelect"
                                >
                                  <option value="">Select a cost source</option>
                                  {Object.keys(CostSourceText).map((key) => (
                                    <option accessKey={key} value={key}>
                                      {CostSourceText[key]}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {CostSourceText[this.state.selectedCostSource ?? 0] ==
                            'Other' ? (
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="Description"
                                  name="description"
                                  variant="outlined"
                                  required
                                />
                              </Grid>
                            ) : (
                              <></>
                            )}
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="Billed Charges"
                                name="actualCost"
                                variant="outlined"
                                onKeyUp={() => this.calculateLineCost()}
                                required
                              />
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="% of Contract"
                                name="contractMultiplier"
                                variant="outlined"
                                onKeyUp={() => this.calculateLineCost()}
                              />
                            </Grid>
                            <Grid item xs={6} md={2}>
                              {this.props.types ? (
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel shrink={true} variant="outlined">
                                    MPPR
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    input={<OutlinedInput notched labelWidth={45} />}
                                    inputProps={{ label: true, notched: true }}
                                    native={true}
                                    fullWidth
                                    onChange={this.setSelectedMultiDiscount}
                                    id="eventSelect"
                                  >
                                    <option value="">Select a MPPR</option>
                                    <option value="100">100%</option>
                                    <option value="50">50%</option>
                                    <option value="25">25%</option>
                                  </Select>
                                </FormControl>
                              ) : (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <strong>Quoted $:</strong>{' '}
                              {this.props.isNum!(this.state.cost) ||
                              this.state.cost == undefined
                                ? formatCurrency((this.state.cost ?? 0).toString())
                                : this.state.cost.toString()}
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.modalSwitch == 'PT' ? (
                        <>
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={2}
                            style={{ margin: '0px' }}
                          >
                            <Grid item xs={6}>
                              <FullWidthField
                                label="Est. U&C Per Visit"
                                name="actualCostPerVisit"
                                variant="outlined"
                                required
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FullWidthField
                                label="Quoted Per Visit"
                                name="costPerVisit"
                                variant="outlined"
                                onKeyUp={() => this.calculateCost()}
                                required
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FullWidthField
                                label="Number of Visits"
                                name="numberOfVisits"
                                variant="outlined"
                                onKeyUp={() => this.calculateCost()}
                                required
                              />
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <strong>Quoted $:</strong>{' '}
                              {this.props.isNum!(this.state.cost) ||
                              this.state.cost == undefined
                                ? formatCurrency((this.state.cost ?? 0).toString())
                                : this.state.cost.toString()}
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.modalSwitch == 'MI' ? (
                        <>
                          <Grid
                            item
                            container
                            style={{ margin: '10px 0px' }}
                            xs={12}
                            spacing={2}
                          >
                            <Grid item xs={6} md={3}>
                              {this.props.types ? (
                                <>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel shrink={true} variant="outlined">
                                      Type *
                                    </InputLabel>
                                    <Select
                                      variant="outlined"
                                      input={<OutlinedInput notched labelWidth={44} />}
                                      inputProps={{
                                        label: true,
                                        notched: true,
                                      }}
                                      native={true}
                                      fullWidth
                                      onChange={this.setSelectedType}
                                      id="type"
                                      required
                                    >
                                      <option value="">Select a type</option>
                                      {this.props.types.map((item: TypeDto) => (
                                        <option
                                          accessKey={item.id}
                                          value={item.name}
                                          disabled={false}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </Select>{' '}
                                  </FormControl>
                                  {this.state.typeMissing && (
                                    <Grid
                                      container
                                      justifyContent="flex-start"
                                      className="error modal-form-error"
                                    >
                                      Type is Required
                                    </Grid>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={6} md={4} lg={4}>
                              <FullWidthField
                                label="Line Description"
                                name="lineDescription"
                                variant="outlined"
                                required
                              />
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="CPT"
                                name="cpt"
                                variant="outlined"
                                disabled={false}
                              />
                            </Grid>
                            <Grid item xs={6} md={3} lg={2}>
                              <FormControl fullWidth>
                                <FormControlLabel
                                  label="Bilateral"
                                  labelPlacement="end"
                                  onChange={() => {
                                    this.setBilateral()
                                  }}
                                  control={
                                    <Checkbox
                                      checked={this.state.bilateral}
                                      inputProps={{
                                        id: 'bilateral',
                                      }}
                                      value={this.state.bilateral}
                                      name="bilateral"
                                    />
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3} lg={4}>
                              <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Cost Source *
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={96} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  required
                                  onChange={this.setSelectedCostSource}
                                  id="costSourceSelect"
                                >
                                  <option value="">Select a cost source</option>
                                  {Object.keys(CostSourceText).map((key) => (
                                    <option accessKey={key} value={key}>
                                      {CostSourceText[key]}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {CostSourceText[this.state.selectedCostSource ?? 0] ==
                            'Other' ? (
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="Description"
                                  name="description"
                                  variant="outlined"
                                  required
                                />
                              </Grid>
                            ) : (
                              <></>
                            )}
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="Billed Charges"
                                name="actualCost"
                                variant="outlined"
                                onKeyUp={() => this.calculateLineCost()}
                                required
                              />
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <FullWidthField
                                label="% of Contract"
                                name="contractMultiplier"
                                variant="outlined"
                                onKeyUp={() => this.calculateLineCost()}
                              />
                            </Grid>
                            <Grid item xs={6} md={2}>
                              {this.props.types ? (
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel shrink={true} variant="outlined">
                                    MPPR
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    input={<OutlinedInput notched labelWidth={45} />}
                                    inputProps={{ label: true, notched: true }}
                                    native={true}
                                    fullWidth
                                    onChange={this.setSelectedMultiDiscount}
                                    id="eventSelect"
                                  >
                                    <option value="">Select a MPPR</option>
                                    <option value="100">100%</option>
                                    <option value="50">50%</option>
                                    <option value="25">25%</option>
                                  </Select>
                                </FormControl>
                              ) : (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <strong>Quoted $:</strong>{' '}
                              {this.props.isNum!(this.state.cost) ||
                              this.state.cost == undefined
                                ? formatCurrency((this.state.cost ?? 0).toString())
                                : this.state.cost.toString()}
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={initialValues}
                onSubmit={this.search}
                enableReinitialize={true}
              >
                {(
                  formikProps: FormikProps<IBillingProviderLookupFormValues>,
                  submitForm: () => void
                ) => (
                  <Form>
                    <Grid
                      container
                      direction="column"
                      spacing={1}
                      style={{ paddingTop: '10px' }}
                    >
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item xs={6}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <FullWidthField
                                label="Provider Location"
                                name="providerLocation"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item>
                              <FullWidthField
                                label="Provider Group"
                                name="providerGroup"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item>
                              <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel shrink={true} variant="outlined">
                                  Provider Type
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  input={<OutlinedInput notched labelWidth={100} />}
                                  inputProps={{ label: true, notched: true }}
                                  native={true}
                                  fullWidth
                                  onChange={this.setSelectedProviderTypeId}
                                  id="providerTypeId"
                                  value={this.props.selectedProviderTypeId}
                                >
                                  {this.getProviderTypes(providerTypes)?.map(
                                    (provider) => (
                                      <option value={this.getProviderTypeValue(provider)}>
                                        {this.getProviderTypeName(provider)}
                                      </option>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item container direction="row">
                              <div style={{ marginTop: '16px' }}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  type="submit"
                                  onClick={submitForm}
                                >
                                  Search
                                </Button>
                              </div>
                              <div
                                style={{
                                  marginTop: '16px',
                                  marginLeft: '10px',
                                }}
                              >
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  onClick={() => {
                                    formikProps.resetForm(initialValues)
                                    this.props.setSelectedProviderTypeId!('')
                                    this.state.totalItems = 0
                                  }}
                                >
                                  Clear Search
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignContent="center"
                        alignItems="flex-start"
                        wrap="wrap"
                      >
                        <Grid item xs={12} style={{ minWidth: '750px' }}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow className="gridPadding">
                                <TableCell></TableCell>
                                <TableCell>Provider Group (Location)</TableCell>
                                <TableCell>Billing Name</TableCell>
                                <TableCell>Billing Address</TableCell>
                                <TableCell>Physical Address</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody style={{ width: '100%' }}>
                              {this.mapGridData()}
                            </TableBody>
                          </Table>
                          <div className="pagination-row">
                            {this.paginationInfo()}
                            <ReactPaginate
                              forcePage={this.state.page - 1}
                              previousLabel={'<'}
                              nextLabel={'>'}
                              onPageChange={this.handlePageChange}
                              pageCount={this.state.pageCount}
                              containerClassName={'pagination'}
                              activeClassName={'active'}
                              //@ts-ignore
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {this.state.modalPage == 2 ? (
            <>
              <Button
                variant="contained"
                style={{ margin: '0px 4px' }}
                onClick={() => {
                  this.setState({ modalPage: 1 })
                  this.clearSearch()
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                form="secondForm"
                color="primary"
                type="submit"
                onClick={() => this.save()}
              >
                Add
              </Button>
            </>
          ) : this.props.modalSwitch != 'PC' &&
            this.props.modalSwitch != 'TC' &&
            this.props.modalSwitch != 'PT' &&
            this.state.totalItems == 0 ? (
            <Button
              variant="contained"
              style={{ margin: '0px 4px' }}
              onClick={() => {
                this.setState({
                  modalPage: 2,
                  selectedCostSource: undefined,
                  selectedMultiDiscount: undefined,
                  bilateral: false,
                  cost: '',
                })
              }}
            >
              Skip
            </Button>
          ) : (
            <></>
          )}
          <Button onClick={this.close} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  public state = {
    modalPage: 1,
    providerName: '',
    providerId: '',
    gridData: new pagedList().items as ProviderBillingLocationSummaryResult[],
    providerGroup: '',
    providerLocation: '',
    providerTypeId: '',
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 25,
    selectedMultiDiscount: undefined,
    selectedCostSource: undefined,
    bilateral: false,
    cost: '',
    assistantPresent: false,
    calcText: undefined,
    typeMissing: false,
  }

  public secondFormRef = React.createRef<any>()

  private save = async () => {
    const formikValues = this.getFormikValues()
    if (this.props.modalSwitch == 'PT') {
      await this.calculateCost()
    } else {
      await this.calculateLineCost()
    }

    var returned = undefined

    switch (this.props.modalSwitch) {
      case 'PC':
        if (
          formikValues.assistant == undefined &&
          (!this.props.isNum!(this.state.cost) ||
            (!this.props.isNum!(formikValues.assistant) &&
              formikValues.assistant != undefined) ||
            (!this.props.isNum!(formikValues.actualCost) &&
              formikValues.actualCost != undefined) ||
            (!this.props.isNum!(formikValues.contractMultiplier) &&
              formikValues.contractMultiplier != undefined))
        ) {
          return
        }

        //Required even if assistant is used
        if (
          this.state.selectedCostSource == undefined ||
          (CostSourceText[this.state.selectedCostSource] == 'Other' &&
            formikValues.description == undefined)
        ) {
          return
        }

        returned = new ProfessionalComponentLineItemDTO(
          this.state.providerId,
          this.state.providerName,
          this.props.selectedType?.id!,
          formikValues.physicianId,
          formikValues.physician ? formikValues.physician.name : '',
          formikValues.cpt,
          this.state.bilateral || false,
          +(this.props.isNum!(this.state.cost) ? this.state.cost : 0),
          undefined,
          false,
          this.state.selectedCostSource,
          formikValues.description,
          formikValues.assistant,
          formikValues.actualCost,
          formikValues.contractMultiplier,
          this.state.selectedMultiDiscount,
          this.props.selectedType?.name,
          this.state.calcText
        )
        if (returned.assistantMultiplier != undefined) {
          this.props.showAssistantWarning!()
        }

        if (returned.typeText == 'Surgeon') {
          this.props.quote!.professionalComponentLineItems!.forEach((element) => {
            if (element.assistantMultiplier != undefined) {
              this.props.showAssistantWarning!()
              return
            }
          })
        }
        this.props.quote?.professionalComponentLineItems?.push(returned)
        break

      case 'TC':
        if (
          !this.props.isNum!(this.state.cost) ||
          (!this.props.isNum!(formikValues.actualCost) &&
            formikValues.actualCost != undefined) ||
          (!this.props.isNum!(formikValues.contractMultiplier) &&
            formikValues.contractMultiplier != undefined) ||
          this.state.selectedCostSource == undefined ||
          (CostSourceText[this.state.selectedCostSource] == 'Other' &&
            formikValues.description == undefined)
        ) {
          return
        }

        returned = new TechnicalComponentLineItemDTO(
          this.state.providerId,
          this.state.providerName,
          this.state.bilateral || false,
          +this.state.cost,
          this.props.selectedType?.id,
          formikValues.cpt,
          undefined,
          false,
          this.state.selectedCostSource,
          formikValues.description,
          formikValues.actualCost,
          formikValues.contractMultiplier,
          this.state.selectedMultiDiscount,
          this.props.selectedType?.name,
          this.state.calcText
        )
        this.props.quote?.technicalComponentLineItems!.push(returned)
        break

      case 'AN':
        if (
          !this.props.isNum!(this.state.cost) ||
          (!this.props.isNum!(formikValues.actualCost) &&
            formikValues.actualCost != undefined) ||
          (!this.props.isNum!(formikValues.contractMultiplier) &&
            formikValues.contractMultiplier != undefined) ||
          this.state.selectedCostSource == undefined ||
          (CostSourceText[this.state.selectedCostSource] == 'Other' &&
            formikValues.description == undefined)
        ) {
          return
        }
        var actual = formikValues.actualCost

        if (
          this.props.isNum!(formikValues.monitored) &&
          formikValues.monitored != undefined
        ) {
          actual = formikValues.actualCost * formikValues.monitored
        }

        returned = new AnesthesiaLineItemDTO(
          this.state.providerId,
          this.state.providerName,
          formikValues.physicianId,
          formikValues.physician ? formikValues.physician.name : '',
          formikValues.cpt,
          this.state.bilateral || false,
          actual,
          +this.state.cost,
          undefined,
          false,
          this.state.selectedCostSource,
          formikValues.description,
          formikValues.contractMultiplier,
          this.state.selectedMultiDiscount,
          this.props.selectedType?.id,
          this.props.selectedType?.name,
          formikValues.monitored,
          this.state.calcText
        )

        this.props.quote?.anesthesiaLineItems!.push(returned)
        break

      case 'PT':
        if (
          !this.props.isNum!(this.state.cost) ||
          !this.props.isNum!(formikValues.costPerVisit) ||
          !this.props.isNum!(formikValues.numberOfVisits) ||
          !this.props.isNum!(formikValues.actualCostPerVisit)
        ) {
          return
        }
        returned = new PhysicalTherapyLineItemDTO(
          this.state.providerId,
          this.state.providerName,
          +this.state.cost,
          +formikValues.numberOfVisits,
          +formikValues.costPerVisit,
          +formikValues.actualCostPerVisit,
          undefined,
          false
        )

        this.props.quote?.physicalTherapyLineItems!.push(returned)
        break

      case 'MI':
        var typeMissing = this.props.selectedType == undefined
        if (
          !this.props.isNum!(this.state.cost) ||
          (!this.props.isNum!(formikValues.actualCost) &&
            formikValues.actualCost != undefined) ||
          (!this.props.isNum!(formikValues.contractMultiplier) &&
            formikValues.contractMultiplier != undefined) ||
          this.state.selectedCostSource == undefined ||
          typeMissing ||
          (CostSourceText[this.state.selectedCostSource] == 'Other' &&
            formikValues.description == undefined) ||
          typeof formikValues.lineDescription != 'string'
        ) {
          this.setState({ typeMissing: typeMissing })
          return
        }

        returned = new MiscellaneousLineItemDTO(
          this.state.providerId,
          this.state.providerName,
          this.state.bilateral || false,
          formikValues.lineDescription,
          +this.state.cost,
          this.props.selectedType?.id,
          formikValues.cpt,
          undefined,
          false,
          this.props.selectedType?.name,
          this.state.selectedCostSource,
          formikValues.description,
          formikValues.actualCost,
          formikValues.contractMultiplier,
          this.state.selectedMultiDiscount,
          this.state.calcText
        )

        this.props.quote?.miscellaneousLineItems!.push(returned)
        break
    }

    this.secondFormRef.current.setSubmitting(false)

    this.close()
  }
  private getFormikValues = () => {
    const formikValues = this.secondFormRef.current?.state?.values
    this.secondFormRef.current.submitForm()
    return formikValues
  }

  private calculateCost = () => {
    const formikValues = this.secondFormRef.current?.state?.values
    var costPer = formikValues.costPerVisit ?? 0
    var numVisits = formikValues.numberOfVisits ?? 0
    this.setState({ cost: numVisits * costPer })
  }

  private close = () => {
    this.setState({
      modalPage: 1,
      pageCount: 0,
      totalItems: 0,
      providerName: '',
      providerId: '',
      selectedCostSource: undefined,
      selectedMultiDiscount: undefined,
      bilateral: false,
      cost: '',
      assistantPresent: false,
      calcText: undefined,
      typeMissing: false,
    })
    this.props.setSelectedProviderTypeId!('')
    this.props.setSelectedProvider!(undefined)
    this.props.setSelectedLocation!(undefined)
    this.props.setSelectedType!(undefined)
    this.props.clearLocations!()
    this.props.close!(this.props.reloadParent!)
  }

  public componentDidMount() {
    this.props.clearData!().then(() => {
      this.props.setSelectedProvider!(undefined)
      this.props.getAllProviderTypes!()
    })
  }

  public clearSearch() {
    this.props.clearData!().then(() => {
      this.props.setSelectedProvider!(undefined)
      this.props.setSelectedLocation!(undefined)
      this.props.clearLocations!()
      this.props.setSelectedProviderTypeId!('')
      this.setState({
        page: 1,
        pageCount: 0,
        totalItems: 0,
        providerName: '',
        providerId: '',
      })
    })
  }

  private getProviderData = async () => {
    const { page, pageSize, providerGroup, providerLocation, providerTypeId } = this.state
    const requestData = new BillingLocationSearchRequest()
    requestData.providerGroup = providerGroup
    requestData.providerLocation = providerLocation
    if (providerTypeId !== '') {
      requestData.providerTypeId = providerTypeId
    }
    requestData.page = page
    requestData.pageSize = pageSize
    await searchProviderBillingLocations(requestData).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getProviderData())
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private async selectProvider(
    locationId: string,
    providerId: string,
    providerName: string,
    locationName: string
  ) {
    let provider = DefaultProvider(false)
    provider.id = providerId
    provider.name = providerName
    let location = await GetLocationById(locationId)
    location.name = locationName
    this.props.setSelectedProvider!({ ...DefaultProvider(), ...provider })
    this.props.setSelectedLocation!(location as unknown as ILocation)

    this.setState({
      providerName: providerName,
      providerId: providerId,
      modalPage: 2,
      selectedCostSource: undefined,
      selectedMultiDiscount: undefined,
      bilateral: false,
      cost: '',
    })

    if (this.props.updateParentStateAfterModalEvent) {
      this.props.updateParentStateAfterModalEvent()
    }
    if (this.props.updateParentStateAfterModal) {
      this.props.updateParentStateAfterModal()
    }
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((provider, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              className="gridButton"
              onClick={() =>
                this.selectProvider(
                  provider.locationId!,
                  provider.providerId!,
                  provider.providerGroupName!,
                  provider.locationName!
                )
              }
            >
              Select
            </Button>
          </TableCell>
          <TableCell>
            {provider.providerGroupName} ({provider.locationName})
          </TableCell>
          <TableCell>{provider.billingName}</TableCell>
          <TableCell>{provider.fullBillingAddress}</TableCell>
          <TableCell>{provider.fullPhysicalAddress}</TableCell>
        </TableRow>
      ))
    }
  }
}

const InjectedAddQuoteLineModal = inject<
  IStores,
  IAddQuoteLineModalProps,
  Partial<IAddQuoteLineModalProps>,
  any
>((stores: IStores) => ({
  close: stores.quotes.closeQuoteLineModal,
  dataTableManager: stores.quotes.billsDataTableStore,
  isLoading: stores.quotes.isLoading,
  isOpen: stores.quotes.isQuoteLineModalOpen,
  quote: stores.quotes.selectedQuote,
  patientHeader: stores.patients.headerDetails,

  addProviderLocation: stores.schedules.addProviderLocation,
  clearData: stores.locations.clearData,
  clearLocations: stores.locations.clearLocations,
  currentAppOrganization: stores.global.currentAppOrganization,
  getAllProviderTypes: stores.providers.getAllProviderTypes,
  getScheduleById: stores.schedules.getScheduleById,
  providerTypes: stores.providers.providerTypes,
  selectedEpisodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  selectedProviderTypeId: stores.locations.selectedProviderTypeId,
  selectedLocation: stores.locations.selectedEditClaimLocation,
  selectedProvider: stores.providers.selectedModalEditScheduleProvider,
  setSelectedAddress: stores.locations.setSelectedAddress,
  setSelectedProcedureFilters: stores.locations.setSelectedProcedureFilters,
  setSelectedProviderGroup: stores.locations.setSelectedProviderGroup,
  setSelectedProviderLocation: stores.locations.setSelectedProviderLocation,
  setSelectedProviderTypeId: stores.locations.setSelectedProviderTypeId,
  setSelectedLocation: stores.locations.setSelectedEditClaimLocation,
  setSelectedProvider: stores.providers.setSelectedModalEditScheduleProvider,
  setSelectedSchedule: stores.schedules.setSelectedSchedule,

  getPhysicians: stores.quotes.getPhysicianSuggestions,
  physicians: stores.quotes.physicians,
  getTypes: stores.quotes.getTypesForModal,
  types: stores.quotes.genericTypes,
  setSelectedType: stores.quotes.setSelectedType,
  selectedType: stores.quotes.selectedType,
  modalSwitch: stores.quotes.modalSwitch,
  user: stores.global.user,
  showAssistantWarning: stores.quotes.showAssistantWarning,
  isNum: stores.quotes.isNum,
}))(AddQuoteLineModal)

export default InjectedAddQuoteLineModal
