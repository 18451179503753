import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { DoneAll } from '@material-ui/icons'
import { ProcessReceivedCheckResults } from 'src/viewModels/ProcessReceivedCheckResult'
import Button from 'src/common/Button'
import { ValidateCheckCorrection } from 'src/services/BillOutService'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { formatCurrency } from 'src/utils/Formatter'
import { CheckCorrectionResult } from 'src/viewModels/CheckCorrectionResult'
import { CheckCorrectionRequest } from 'src/viewModels/CheckCorrectionRequest'
import { sentCheckCorrection } from 'src/services/CheckService'
import moment from 'moment'
import { SentCheckSummaryResult } from 'src/viewModels/SentCheckSummaryResult'

interface ICheckCorrectionModalProps {
  close: (reloadGrid: boolean) => void
  isOpen: boolean
  openVerifyCheckDialog?: (results: ProcessReceivedCheckResults) => void
  check: SentCheckSummaryResult
}

class CheckCorrectionModal extends React.Component<ICheckCorrectionModalProps> {
  public state = {
    isCheckVerified: false,
    checkNumber: '',
    datePaid: '',
    validationText: '',
    isLoading: false,
  }

  private saveCheck = async () => {
    this.setState({ isLoading: true })
    var checkRequest = new CheckCorrectionRequest()
    checkRequest.checkNumber = this.state.checkNumber || ''
    checkRequest.checkDate =
      new Date(moment(this.state.datePaid, 'YYYY-MM-DD').toISOString()) || new Date()
    await sentCheckCorrection(this.props.check.checkId, checkRequest)
      .then(() => {
        this.close(true)
      })
      .catch(() => this.setState({ isLoading: false }))
  }

  private verifyCheck = async () => {
    this.setState({
      isCheckVerified: true,
      validationText: '',
      isLoading: true,
    })

    if (this.state.checkNumber != '' && this.state.datePaid != '') {
      var checkRequest = new CheckCorrectionRequest()
      checkRequest.checkNumber = this.state.checkNumber || ''
      checkRequest.checkDate = new Date(this.state.datePaid) || new Date()
      await ValidateCheckCorrection(checkRequest)
        .then((response: CheckCorrectionResult) => {
          this.setState({
            isCheckVerified: response.isValid,
            validationText: response.validationText,
            isLoading: false,
          })
        })
        .catch(() => {
          this.setState({ isLoading: false, isCheckVerified: false })
        })
    } else {
      this.setState({ isLoading: false, isCheckVerified: false })
    }
  }

  private close = (reloadGrid: boolean) => {
    this.setState({
      isCheckVerified: false,
      isPaymentValidated: false,
      checkAmountLessThanApplyAmount: false,
      moreThanBalance: false,
      validationText: '',
      isLoading: false,
    })
    this.props.close!(reloadGrid)
  }

  public render() {
    const { isOpen } = this.props
    return (
      <>
        <Dialog fullWidth={true} maxWidth="md" open={isOpen!} disableBackdropClick={true}>
          <DialogTitle style={{ padding: 0 }}>
            <ResponsiveAppBar
              title="Sent Check Correction"
              pageIcon={<DoneAll />}
              isModal={true}
            />
          </DialogTitle>
          <DialogContent style={{ padding: '8px 24px 0px' }}>
            {this.state.isLoading && (
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1200',
                }}
              >
                <CircularProgress size={100} />
              </div>
            )}
            <Grid>
              <Grid container item direction="row" spacing={2}>
                <Grid item style={{ whiteSpace: 'nowrap' }}>
                  <strong style={{ paddingRight: '4px' }}>Billing Info:</strong>
                  <span>{this.props.check.billingName}</span>
                </Grid>
                <Grid item style={{ whiteSpace: 'nowrap' }}>
                  <strong style={{ paddingRight: '4px' }}>Check Amount:</strong>
                  <span>{formatCurrency(this.props.check.checkAmount.toString())}</span>
                </Grid>
                <Grid item style={{ whiteSpace: 'nowrap' }}>
                  <strong style={{ paddingRight: '4px' }}>Check Date:</strong>
                  <span>{moment(this.props.check.checkDate).format('MM/DD/YYYY')}</span>
                </Grid>
                <Grid item style={{ whiteSpace: 'nowrap' }}>
                  <strong style={{ paddingRight: '4px' }}>Check Number:</strong>
                  <span>{this.props.check.checkNumber}</span>
                </Grid>
              </Grid>
              <Grid container direction="row" wrap="nowrap" alignItems="center">
                <Grid container direction="column">
                  <Grid
                    item
                    style={{
                      paddingTop: '20px',
                      paddingBottom: '20px',
                    }}
                  >
                    <Divider />
                  </Grid>
                  <Grid item style={{ paddingBottom: '20px' }}>
                    <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      spacing={1}
                      style={{ margin: '0px' }}
                      justifyContent="flex-start"
                    >
                      <Grid item xs={2}>
                        <FormControl fullWidth>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            name="datePaid"
                            label="Check Date"
                            variant="outlined"
                            fullWidth={true}
                            required
                            onChange={(event) =>
                              this.setState({
                                datePaid: event.target.value,
                                isCheckVerified: false,
                                validationText: '',
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          required
                          name="checkNumber"
                          label="Check Number"
                          variant="outlined"
                          onChange={(event) =>
                            this.setState({
                              checkNumber: event.target.value,
                              isCheckVerified: false,
                              validationText: '',
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <Button
                          onClick={() => this.verifyCheck()}
                          size="medium"
                          variant="outlined"
                          className="gridButton"
                          color="primary"
                          data-cy="verify check"
                          disabled={
                            this.state.checkNumber == '' ||
                            this.state.datePaid == '' ||
                            this.state.isCheckVerified == true
                          }
                        >
                          {'Verify Check'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        color: this.state.isCheckVerified ? 'green' : 'red',
                      }}
                    >
                      {this.state.validationText}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: '0px 8px 8px' }}>
            <Button
              variant="contained"
              color="primary"
              data-cy="save"
              onClick={() => this.saveCheck()}
              disabled={!this.state.isCheckVerified}
            >
              Save
            </Button>
            <Button onClick={() => this.close(false)} data-cy="close">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default CheckCorrectionModal
