import { AddCircle, AttachMoney, Edit, Visibility } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import {
  DefaultCMS1500,
  DefaultEpisodeOfCare,
  ICMS1500,
  IEpisodeOfCare,
} from '../../Definitions'
import {
  Client,
  EpisodeOfCareDto,
  IBodyPartDto,
  IEpisodeOfCareDto,
  SwaggerResponse,
} from '../../generated_client'
import { ICMS1500Dto } from '../../viewModels/claim/Cms1500Dto'
import GlobalStore from '../../stores/GlobalStore'
import { BillStatus } from '../../utils/BillStatus'
import DataTableStore from '../DataTableStore'
import PatientsStore from '../PatientsStore/PatientsStore'
import ReferralsStore from '../ReferralsStore/ReferralsStore'
import { GetScheduledOptionsAsync } from 'src/services/LookupService'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import { EpisodeOfCareSummaryResult } from 'src/viewModels/EpisodeOfCareSummaryResult'
import { Tooltip } from '@material-ui/core'
import React from 'react'
import { searchEOCs } from 'src/services/ReferralService'
import { SearchRequestBase } from 'src/viewModels/SearchRequestBase'
import { GetPatientOptions } from 'src/services/WorkflowService'

const ColumnSettingsDto = 'EpisodeOfCareDto'
const QuoteBillsColumnSettingsType = 'QuoteBills'

export default class PatientEditStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public episodeOfCares: IEpisodeOfCare[]
  @observable
  public selectedEpisodeOfCare?: IEpisodeOfCare | undefined
  @observable
  public pageSize: number = 1
  @observable
  public billStatusFilter?: BillStatus = undefined
  @observable
  public dataTableStore: DataTableStore<IEpisodeOfCareDto, IEpisodeOfCare>
  public billsDataTableStore: DataTableStore<ICMS1500Dto, ICMS1500>
  @observable
  public enableEnterBillsMode: boolean = false
  @observable
  public isBillsDialogOpen: boolean = false
  @observable
  public claimModalLoad?: (episodeOfCareId: string) => void
  @observable
  public EOCModalLoad?: (patientId: string) => void
  @observable
  public ordersNotReceivedCount: number = 0
  @observable
  public referralsNotScheduledCount: number = 0
  @observable
  public testScheduledResultsNotReceivedCount: number = 0
  @observable
  public scaSentNotReceivedCount: number = 0
  @observable
  public isCodeCheckModalOpen: boolean
  @observable
  public scheduleItems: Array<DropdownOption>
  @observable
  public isEpisodesOfCareModalOpen: boolean
  @observable
  public isReferralContactModalOpen: boolean
  @observable
  public bodyParts?: IBodyPartDto[]
  @observable
  public eocTotalItems?: number
  @observable
  public eocPageCount?: number
  @observable
  public eocs?: EpisodeOfCareSummaryResult[]
  @observable
  public eocFirstRecordIndex?: number
  @observable
  public eocLastRecordIndex?: number
  @observable
  public eocPage: number = 1
  @observable
  public filteredPatientOptions: DropdownOption[] = []

  constructor(
    private globalStore: GlobalStore,
    private client: Client,
    private patientStore: PatientsStore,
    private referralsStore: ReferralsStore,
    private routerStore: RouterStore,
    private baseUrl: string
  ) {
    this.dataTableStore = new DataTableStore<IEpisodeOfCareDto, IEpisodeOfCare>(
      globalStore,
      ({ filter, page, orderBy }) => {
        if (this.referralsStore.selectedReferral && this.patientStore.selectedPatient) {
          return client.getAllEpisodesOfCare(
            undefined,
            this.referralsStore.selectedReferral
              ? this.referralsStore.selectedReferral.id
              : undefined,
            this.patientStore.selectedPatient
              ? this.patientStore.selectedPatient.id
              : undefined,
            filter,
            page,
            this.pageSize,
            orderBy,
            true
          )
        }
        return Promise.resolve(
          new SwaggerResponse<EpisodeOfCareDto[]>(200, {}, new Array())
        )
      },
      (episodeOfCare) => this.setupEpisodeOfCares(episodeOfCare)
    )

    this.billsDataTableStore = new DataTableStore<ICMS1500Dto, ICMS1500>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) => {
        const episodeOfCareId = this.selectedEpisodeOfCare
          ? this.selectedEpisodeOfCare.id
          : undefined
        if (orderBy === undefined) {
          orderBy = 'GroupNumber'
        }
        return this.client.getAllCMS1500(
          filter,
          this.globalStore.currentAppOrganization
            ? this.globalStore.currentAppOrganization.id
            : undefined,
          undefined,
          episodeOfCareId,
          this.billStatusFilter,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          page,
          1,
          orderBy,
          includeInactives
        )
      },
      (cms1500) => {
        return this.setupCMS1500(cms1500)
      }
    )
  }

  @action.bound
  public getColumnSettingsAndEpisodeOfCares(): Promise<void> {
    if (!this.globalStore.user) {
      return Promise.resolve()
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)

          const fullOnlyColumns = [
            'referralDate',
            'referralDoctor',
            'referralDOI',
            'careCoordinatorName',
          ]
          this.dataTableStore.columns = this.dataTableStore.columns.filter(
            (x) => fullOnlyColumns.find((y) => y === x.fieldName) === undefined
          )
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }

          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public openReferralContactModal() {
    this.isReferralContactModalOpen = true
  }

  @action.bound
  public closeReferralContactModal() {
    this.isReferralContactModalOpen = false
  }

  private setupEpisodeOfCares = (episodeOfCares: IEpisodeOfCareDto[]) => {
    return episodeOfCares.map((x) =>
      this.setupEpisodeOfCareMenuItems(this.addDefaultFields(x))
    )
  }

  private addDefaultFields = (episodeOfCare: IEpisodeOfCareDto): IEpisodeOfCare => {
    return { ...DefaultEpisodeOfCare(), ...episodeOfCare }
  }

  @action.bound
  public openEOCFromGrid(eocId: string) {
    this.getEpisodeOfCareById(eocId).then(() => {
      this.addOrEditEpisodeOfCare(this.selectedEpisodeOfCare, false)
    })
  }

  @action.bound
  public addOrEditEpisodeOfCare(
    episodeOfCare: IEpisodeOfCare | undefined,
    newTab: boolean
  ) {
    const referralId =
      episodeOfCare?.referralId ?? this.referralsStore.selectedReferral?.id
    const patientId =
      episodeOfCare?.referral?.patientId ??
      this.referralsStore.selectedReferral?.patientId
    const route = `/patients/patient/${patientId ?? ''}/referral/${
      referralId ?? ''
    }/episodeOfCare/${episodeOfCare ? episodeOfCare.id : ''}`
    this.isLoading = false

    if (this.globalStore.returnRoutePath === '/quotes') {
      this.patientStore.patientToQuotes = true
    }

    if (newTab) {
      sessionStorage.setItem('eocTab', '1')
      window.open(route, '_blank')
    } else {
      this.globalStore.setReturn!(`/patients/patient/${patientId ?? ''}`).then(() => {
        sessionStorage.setItem('eocTab', '0')
        this.routerStore.push(route)
      })
    }
  }

  private setupEpisodeOfCareMenuItems = (episodeOfCare: IEpisodeOfCare) => {
    episodeOfCare.menuReplacer = React.createElement(Tooltip, {
      title: 'Edit EOC',
      children: React.createElement(Edit, {
        onClick: () => this.addOrEditEpisodeOfCare(episodeOfCare, false),
      }),
    })

    return episodeOfCare
  }

  public setupCMS1500 = (cms1500: ICMS1500Dto[]) => {
    return cms1500.map(this.addDefaultBillFields)
  }

  public addDefaultBillFields = (cms1500: ICMS1500Dto): ICMS1500 => {
    return { ...DefaultCMS1500(), ...cms1500 }
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedEpisodeOfCare = undefined
  }

  @action.bound
  public generatePatientDemographicsReport() {
    const patientId = this.patientStore.selectedPatient
      ? this.patientStore.selectedPatient.id
      : ''
    const episodeOfCareId = this.selectedEpisodeOfCare
      ? this.selectedEpisodeOfCare.id
      : ''
    let url =
      this.baseUrl +
      `/api/patients/${patientId}/episodeOfCare/${episodeOfCareId}/PatientDemographics`
    url = url.replace(/[?&]$/, '')
    window.open(url)
  }

  @action.bound
  public openCodeCheckModal() {
    this.isCodeCheckModalOpen = true
  }

  @action.bound
  public closeCodeCheckModal() {
    this.isCodeCheckModalOpen = false
  }

  @action.bound
  public openDialogWithEpisodeOfCare(episodeOfCare: IEpisodeOfCare) {
    this.isModalOpen = true
    this.selectedEpisodeOfCare = episodeOfCare
  }

  @action.bound
  public saveEpisodeOfCare(episodeOfCare: IEpisodeOfCare) {
    this.isLoading = true
    let promise
    const episodeOfCareDto = new EpisodeOfCareDto(episodeOfCare)
    if (episodeOfCare.isNew) {
      promise = this.client.createEpisodeOfCare(
        this.referralsStore.selectedReferral!.id,
        episodeOfCareDto
      )
    } else {
      promise = this.client.updateEpisodeOfCare(episodeOfCare.id, episodeOfCareDto)
    }
    return promise
      .then(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public async getEpisodeOfCareById(id: string) {
    this.isLoading = true
    this.selectedEpisodeOfCare = undefined
    try {
      await this.client.getEpisodeOfCareById(id).then((resp) => {
        this.selectedEpisodeOfCare = this.addDefaultFields(resp.result)
      })
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  public setSelectedEpisodeOfCare(episodeOfCare: IEpisodeOfCare | undefined) {
    this.selectedEpisodeOfCare = episodeOfCare
    this.getScheduledOptions()
  }

  @action.bound
  public setEnterBillsMode(enableEnterBillsMode: boolean) {
    this.enableEnterBillsMode = enableEnterBillsMode
  }

  @action.bound
  public enterBill(episodeOfCare: IEpisodeOfCare) {
    sessionStorage.setItem('claimTab', '1')
    window.open(`/claim/episodeOfCare/${episodeOfCare.id}`, '_blank')
  }

  @action.bound
  public getBodyParts() {
    return this.client
      .getAllBodyParts(undefined, undefined, 50000, undefined, true)
      .then(({ result }) =>
        runInAction(() => {
          this.bodyParts = result
        })
      )
  }

  @action.bound
  public closeBillsDialog() {
    this.selectedEpisodeOfCare = undefined
    this.isBillsDialogOpen = false
    this.billsDataTableStore.clearData()
  }

  @action.bound
  public openEOCModal(patientId: string) {
    this.isEpisodesOfCareModalOpen = true
    if (this.EOCModalLoad) {
      this.EOCModalLoad(patientId)
    }
  }

  @action.bound
  public closeEOCModal() {
    this.isEpisodesOfCareModalOpen = false
  }

  @action.bound
  public setBillStatusFilter(billStatus?: BillStatus) {
    this.billStatusFilter = billStatus
    this.billsDataTableStore.loadData()
  }

  @action.bound
  public buildEOCMenuItems = (eoc: EpisodeOfCareSummaryResult) => {
    let menuItems: any[] = []

    menuItems.push({
      color: '#29348F',
      icon:
        this.globalStore.currentAppOrganization!.id !== eoc.organizationId
          ? Visibility
          : Edit,
      name:
        this.globalStore.currentAppOrganization!.id !== eoc.organizationId
          ? 'View EOC'
          : 'Edit EOC',
      onClick: () => this.editEpisodeOfCareFromMenu(eoc.episodeOfCareId!),
    })

    menuItems.push({
      color: '#29348F',
      disabled: this.globalStore.currentAppOrganization!.id !== eoc.organizationId,
      icon: AddCircle,
      name: 'Enter Claim',
      onClick: () => this.enterClaimFromMenu(eoc.episodeOfCareId!),
    })

    menuItems.push({
      color: '#94D33D',
      disabled: this.globalStore.currentAppOrganization!.id !== eoc.organizationId,
      icon: AttachMoney,
      name: 'View Claims',
      onClick: () => this.openViewClaimsModal(eoc.episodeOfCareId!),
    })

    return menuItems
  }

  @action.bound
  public openEditClaim(cms1500Id: string, eocId: string) {
    sessionStorage.setItem('claimTab', '1')
    window.open(`/claim/${cms1500Id}/episodeOfCare/${eocId}`, '_blank')
  }

  @action.bound
  public getEpisodeOfCareBillsColumnSettings() {
    if (!this.globalStore.user) {
      return Promise.resolve()
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(
        this.globalStore.user!.id,
        QuoteBillsColumnSettingsType
      )
      .then((data) => {
        runInAction(() => {
          this.billsDataTableStore.setColumns(data.result)
          const sortedColumn = _.find(
            this.billsDataTableStore.columns,
            (col) => !!col.sort
          )
          if (sortedColumn) {
            this.billsDataTableStore.setTableOrderBy(sortedColumn.sort)
          }
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public async getEOCs() {
    if (this.referralsStore.selectedReferral) {
      var referralId = this.referralsStore.selectedReferral?.id
      var request = new SearchRequestBase()
      request.page = this.eocPage
      request.pageSize = 5
      await searchEOCs(referralId, request).then((response: any) => {
        this.eocTotalItems = response.totalItems
        this.eocPageCount = response.totalPages
        this.eocs = response.items
        if ((this.eocTotalItems ?? 0) > 0) {
          this.setEOCPaginationOffsetData(request.page, request.pageSize)
        }
      })
    }
  }

  @action.bound
  public setEOCPage(page: number) {
    this.eocPage = page
  }

  @action.bound
  public setEOCPaginationOffsetData = (page: number, pageSize: number) => {
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex =
      page * pageSize < (this.eocTotalItems ?? 0) ? page * pageSize : this.eocTotalItems
    this.eocFirstRecordIndex = firstRecordIndex
    this.eocLastRecordIndex = lastRecordIndex
  }

  @action.bound
  public async editEpisodeOfCareFromMenu(episodeOfCareId: string) {
    this.isLoading = true

    await this.getEpisodeOfCareById(episodeOfCareId).then(() => {
      this.addOrEditEpisodeOfCare(this.selectedEpisodeOfCare, true)
    })
  }

  @action.bound
  public async enterClaimFromMenu(episodeOfCareId: string) {
    await this.getEpisodeOfCareById(episodeOfCareId).then(() => {
      if (this.selectedEpisodeOfCare != undefined) {
        this.enterBill(this.selectedEpisodeOfCare)
      }
    })
  }

  @action.bound
  public async openViewClaimsModal(episodeOfCareId: string, scheduleId?: string) {
    this.isLoading = true
    await this.getEpisodeOfCareById(episodeOfCareId).then(() => {
      if (this.claimModalLoad && scheduleId) {
        this.claimModalLoad(scheduleId)
      } else if (this.claimModalLoad) {
        this.claimModalLoad(episodeOfCareId)
      }
      this.isBillsDialogOpen = true
      this.isLoading = false
    })
  }

  @action.bound
  public async openBillsDialog(episodeOfCare: IEpisodeOfCare) {
    this.selectedEpisodeOfCare = episodeOfCare
    if (this.claimModalLoad) {
      this.claimModalLoad(episodeOfCare!.id)
    }
    this.isBillsDialogOpen = true
  }

  @action.bound
  public setClaimsModalLoad(parentFunction: () => void) {
    this.claimModalLoad = parentFunction
  }

  @action.bound
  public setEOCModalLoad(parentFunction: () => void) {
    this.EOCModalLoad = parentFunction
  }

  @action.bound
  public getOrdersNotReceivedCount() {
    return this.client
      .ordersNotReceivedCount(
        this.referralsStore.selectedCareCoordinatorId
          ? this.referralsStore.selectedCareCoordinatorId
          : ''
      )
      .then((resp: SwaggerResponse<number>) =>
        runInAction(() => {
          this.ordersNotReceivedCount = resp.result
        })
      )
  }

  @action.bound
  public getReferralsNotScheduledCount() {
    return this.client
      .referralsNotScheduledCount(
        this.referralsStore.selectedCareCoordinatorId
          ? this.referralsStore.selectedCareCoordinatorId
          : ''
      )
      .then((resp: SwaggerResponse<number>) =>
        runInAction(() => {
          this.referralsNotScheduledCount = resp.result
        })
      )
  }

  @action.bound
  public getTestScheduledResultsNotReceivedCount() {
    return this.client
      .testScheduledResultsNotReceivedCount(
        this.referralsStore.selectedCareCoordinatorId
          ? this.referralsStore.selectedCareCoordinatorId
          : ''
      )
      .then((resp: SwaggerResponse<number>) =>
        runInAction(() => {
          this.testScheduledResultsNotReceivedCount = resp.result
        })
      )
  }

  @action.bound
  public getScaSentNotReceivedCount() {
    return this.client
      .scaSentNotReceivedCount(
        this.referralsStore.selectedCareCoordinatorId
          ? this.referralsStore.selectedCareCoordinatorId
          : ''
      )
      .then((resp: SwaggerResponse<number>) =>
        runInAction(() => {
          this.scaSentNotReceivedCount = resp.result
        })
      )
  }

  @action.bound
  public async getScheduledOptions() {
    if (this.selectedEpisodeOfCare != undefined) {
      this.scheduleItems = await GetScheduledOptionsAsync(this.selectedEpisodeOfCare.id)
    }
  }

  @action.bound
  public async getPatientOptions(filter?: string) {
    if (filter != undefined && filter != '') {
      this.filteredPatientOptions = await GetPatientOptions(filter)
    }
  }
}
