import { Accessibility } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultBodyPart, IBodyPart } from '../Definitions'
import { IStores } from '../Stores'
import BodyPartDialog from './BodyPartDialog'

interface IBodyPartsProps {
  dataTableManager?: IDataTableManager<IBodyPart>
  getColumnSettingsAndBodyParts?: () => void
  isModalOpen?: boolean
  openDialogWithBodyPart?: (bodyPart: IBodyPart) => void
}

class BodyParts extends React.Component<IBodyPartsProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndBodyParts!()
  }

  public render() {
    const bodyPartDialog = this.props.isModalOpen && <BodyPartDialog />
    return (
      <>
        <Helmet>
          <title>Body Parts</title>
        </Helmet>
        <ResponsiveAppBar title="Body Parts" pageIcon={<Accessibility />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableSearch={true}
          enableShowInactives={true}
          enableToggleColumns={true}
          addButtonOnClick={this.openDialog(DefaultBodyPart(true))}
        />
        {bodyPartDialog}
      </>
    )
  }

  public openDialog = (bodyPart: IBodyPart) => () => {
    this.props.openDialogWithBodyPart!(bodyPart)
  }
}

const InjectedBodyParts = inject<IStores, IBodyPartsProps, Partial<IBodyPartsProps>, any>(
  (stores: IStores) => ({
    dataTableManager: stores.bodyParts.dataTableStore,
    getColumnSettingsAndBodyParts: stores.bodyParts.getColumnSettingsAndBodyParts,
    isModalOpen: stores.bodyParts.isModalOpen,
    openDialogWithBodyPart: stores.bodyParts.openDialogWithBodyPart,
  })
)(BodyParts)

export default InjectedBodyParts
