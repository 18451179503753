import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { Field, FieldProps, getIn } from 'formik'
import * as React from 'react'

type InterceptTextFieldProps = FieldProps &
  Omit<TextFieldProps, 'error' | 'name' | 'value'> & {
    // Fix for the type for variant which is using union
    // https://stackoverflow.com/questions/55664421
    variant: 'standard' | 'filled' | 'outlined' | undefined
  }

const fieldToTextField = ({
  field,
  form,
  disabled,
  ...props
}: InterceptTextFieldProps): TextFieldProps => {
  const { name } = field
  const { touched, errors, isSubmitting } = form

  const fieldError = getIn(errors, name)
  const showError = getIn(touched, name) && !!fieldError
  const onChange = (e: React.ChangeEvent<any>) => {
    if (props.onChange) {
      props.onChange(e)
    }
    field.onChange(e)
  }

  return {
    ...props,
    ...field,
    disabled: disabled !== undefined ? disabled : isSubmitting,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    onChange,
  }
}

const InnerInterceptTextField: React.ComponentType<InterceptTextFieldProps> = ({
  children,
  ...props
}) => {
  return <TextField {...fieldToTextField(props)}>{children}</TextField>
}

const InterceptTextField: React.ComponentType<TextFieldProps> = (props) => {
  return <Field component={InnerInterceptTextField} {...props} />
}

export default InterceptTextField
