import * as React from 'react'
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'

export const DroppableTableHeaderRow =
  (onDragEnd: (result: DropResult, provided: ResponderProvided) => void) =>
  (props: { children?: React.ReactNode }) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'1'} direction="horizontal">
          {(provided, snapshot) => (
            <tr
              {...props}
              ref={provided.innerRef}
              style={{
                backgroundColor: snapshot.isDraggingOver ? 'lightgrey' : 'white',
              }}
              {...provided.droppableProps}
            >
              {props.children}
            </tr>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
