import uuidv4 from 'uuid/v4'
import { IMenuItem } from './common/ESDataTable'
import { CMS1500Dto, ICMS1500Dto } from './viewModels/claim/Cms1500Dto'
import { ICMS1500DiagnosisDto } from './viewModels/claim/CMS1500DiagnosisDto'
import { ICMS1500LineItemDto } from './viewModels/claim/CMS1500LineItemDto'

import {
  AddressDto,
  AnesthesiaLineItemDto,
  AppointmentTaskDto,
  BillCheckDto,
  CodeDto,
  ContactDto,
  ContractAttachmentFileDto,
  EpisodeOfCareDto,
  IAddressDto,
  IAnesthesiaLineItemDto,
  IAppointmentTaskDto,
  IAttachmentFileDto,
  IBillCheckDto,
  IBodyPartDto,
  ICalculationExplanationDto,
  ICheckDto,
  ICodeDto,
  ICommunicationDto,
  IComponentDto,
  IContactDto,
  IContactTypeDto,
  IContractDto,
  ICptCodeDto,
  IdentifierEntityDto,
  IDxCodeDto,
  IEmployerDto,
  IEpisodeOfCareDto,
  IIdentifierEntityDto,
  IInsuranceCompanyDto,
  ILocationBillingDto,
  ILocationDto,
  ILocationProcedureDto,
  IMedicareCostLookupDto,
  IMiscellaneousLineItemDto,
  IMultipleProcedureDiscountDto,
  IMultipleProcedureDiscountExclusionCodeDto,
  INurseCaseManagerDto,
  IOrganizationDto,
  IPatientDto,
  IPatientPreExistingConditionDto,
  IPhysicalTherapyLineItemDto,
  IPhysicianDto,
  IPreExistingConditionDto,
  IProcedureDto,
  IProfessionalComponentLineItemDto,
  IProviderDto,
  IQuoteDto,
  IReferralDto,
  IReferringDoctorDto,
  IScheduleAppointmentTaskDto,
  ISimpleEntityDto,
  ISurgeryCodeDto,
  ITagDto,
  ITechnicalComponentLineItemDto,
  ITestDto,
  ITypeDto,
  IUserDto,
  LocationBillingDto,
  LocationProcedureDto,
  MiscellaneousLineItemDto,
  MultipleProcedureDiscountDto,
  PatientPreExistingConditionDto,
  PhysicalTherapyLineItemDto,
  PhysicianLocationDto,
  ProfessionalComponentLineItemDto,
  ProviderDto,
  ProviderTypeDto,
  QuoteDto,
  ScheduleAppointmentTaskDto,
  ScheduleProviderLocationDto,
  SimpleEntityDto,
  SurgeryCodeDto,
  TagDto,
  TechnicalComponentLineItemDto,
  TestDto,
} from './generated_client'
import { AppealType } from './utils/AppealType'
import { ReferralContactDTO } from './viewModels/ReferralContactDTO'
import { IScheduleDto, ScheduleDto } from './viewModels/ScheduleDto'

const Defaults = (isNew = false) => ({
  id: uuidv4(),
  isExpanded: false,
  isMenuOpen: false,
  isNew,
  isSelected: false,
  menuItems: [] as IMenuItem[],
})

export type IUser = typeof User & IUserDto
export let DefaultUser = (isNew = false) =>
  Object.assign(
    {
      address: '',
      city: '',
      directNumber: '',
      displayRoles: '',
      email: '',
      firstName: '',
      id: '',
      isActive: true,
      jobTitle: '',
      lastName: '',
      name: '',
      organization: {} as SimpleEntityDto | undefined,
      organizationId: '',
      phoneExtension: '',
      phoneNumber: '',
      profileImageContent: '',
      profileImageMimeType: '',
      profileImageName: '',
      profileImageUrl: '',
      roles: [] as string[],
      state: '',
      tollFreeNumber: '',
      userName: '',
      zipCode: '',
    },
    Defaults(isNew)
  )
const User = DefaultUser()

export type IIdentifierEntity = typeof IdentifierEntity & IIdentifierEntityDto
export let DefaultIdentifierEntity = (isNew = false) =>
  Object.assign(
    {
      id: '',
      name: '',
    },
    Defaults(isNew)
  )
const IdentifierEntity = DefaultIdentifierEntity()

export type ISimpleEntity = typeof SimpleEntity & ISimpleEntityDto
export let DefaultSimpleEntity = (isNew = false) =>
  Object.assign(
    {
      createdOn: new Date(),
      id: '',
      isActive: true,
      modifiedOn: new Date(),
      name: '',
    },
    Defaults(isNew)
  )
const SimpleEntity = DefaultSimpleEntity()

export type IOrganization = typeof Organization & IOrganizationDto
export let DefaultOrganization = (isNew = false) =>
  Object.assign(
    {
      billingInquiries: {} as AddressDto,
      createdOn: new Date(),
      description: '',
      generalInquiries: {} as AddressDto,
      id: '',
      initials: '',
      isActive: true,
      modifiedOn: new Date(),
      name: '',
      navigationLabel: '',
    },
    Defaults(isNew)
  )
const Organization = DefaultOrganization()

export type IProvider = typeof Provider & IProviderDto
export let DefaultProvider = (isNew = false) =>
  Object.assign(
    {
      billingAddress: {} as AddressDto,
      billingServicer: '',
      ccn: '',
      cmS1500s: [] as CMS1500Dto[],
      communicationCount: 0,
      contractCount: 0,
      createdOn: new Date(),
      displayAddress: '',
      id: '',
      isActive: true,
      isBillingAddressSameAsPhysicalAddress: false,
      isExpanded: false,
      isSingleLocation: false,
      legacyId: '',
      legalName: '',
      locationCount: 0,
      modifiedOn: new Date(),
      name: '',
      npiNumber: '',
      organizationId: '',
      physicalAddress: {} as AddressDto,
      physicianCount: 0,
      providerTags: [] as TagDto[],
      providerTypes: [] as ProviderTypeDto[] | undefined,
      requiresSingleCaseAgreement: false,
      notes: '',
    },
    Defaults(isNew)
  )
const Provider = DefaultProvider()

export type IInsuranceCompany = typeof InsuranceCompany & IInsuranceCompanyDto
export let DefaultInsuranceCompany = (isNew = false) =>
  Object.assign(
    {
      billingAddress: {} as AddressDto,
      billingFaxNumber: '',
      claimsEmail: '',
      contacts: [] as ContactDto[],
      createdOn: new Date(),
      id: '',
      isActive: true,
      isSpecialPricing: false,
      includeNurseCaseManager: false,
      legacyId: '',
      modifiedOn: new Date(),
      name: '',
      physicalAddress: {} as AddressDto,
    },
    Defaults(isNew)
  )
const InsuranceCompany = DefaultInsuranceCompany()

export type IInsuranceCompanyContact = typeof InsuranceCompanyContact
export let DefaultInsuranceCompanyContact = (isNew = false) =>
  Object.assign(
    {
      comments: '',
      contactType: '',
      costSavingsReport: false,
      createdOn: new Date(),
      dateIncentiveGiven: new Date(),
      email: '',
      faxNumber: '',
      firstName: '',
      id: '',
      isActive: true,
      lastName: '',
      modifiedOn: new Date(),
      name: '',
      phoneNumber: '',
      specialPricing: false,
    },
    Defaults(isNew)
  )
const InsuranceCompanyContact = DefaultInsuranceCompanyContact()

export type ITag = typeof Tag & ITagDto
export let DefaultTag = (isNew = false) =>
  Object.assign(
    {
      color: undefined as JSX.Element | undefined,
      createdOn: new Date(),
      id: '',
      isActive: true,
      lock: undefined as JSX.Element | undefined,
      name: '',
      tagColor: '',
    },
    Defaults(isNew)
  )
const Tag = DefaultTag()

export type ILocation = typeof Location & ILocationDto
export let DefaultLocation = (isNew = false) =>
  Object.assign(
    {
      billingAddresses: [] as LocationBillingDto[],
      billingAddress: {} as AddressDto,
      createdOn: new Date(),
      displayAddress: '',
      email: '',
      faxNumber: '',
      id: '',
      isActive: true,
      isBillingAddressSameAsPhysicalAddress: false,
      locationProcedure: [] as LocationProcedureDto[],
      modifiedOn: new Date(),
      name: '',
      npi: '',
      phoneNumber: '',
      physicalAddress: {} as AddressDto,
      providerContractComments: '',
      providerId: '',
      providerLocationComments: '',
      requiresSingleCaseAgreement: false,
      verified: false,
    },
    Defaults(isNew)
  )
const Location = DefaultLocation()

export type IAddress = typeof Address & IAddressDto
export let DefaultAddress = (isNew = false) =>
  Object.assign(
    {
      city: '',
      faxNumber: '',
      id: '',
      line1: '',
      line2: '',
      name: '',
      phoneNumber: '',
      state: '',
      website: '',
      zip: '',
      addressDisplay: '',
    },
    Defaults(isNew)
  )
const Address = DefaultAddress()

export type IPhysician = typeof Physician & IPhysicianDto
export let DefaultPhysician = (isNew = false) =>
  Object.assign(
    {
      createdOn: new Date(),
      firstName: '',
      id: '',
      isActive: true,
      lastName: '',
      locations: [] as PhysicianLocationDto[],
      modifiedOn: new Date(),
      name: '',
      npi: '',
      providerId: '',
      specialization: {} as IdentifierEntityDto,
    },
    Defaults(isNew)
  )
const Physician = DefaultPhysician()

export type IMultipleProcedureDiscount = typeof MultipleProcedureDiscount &
  IMultipleProcedureDiscountDto
export let DefaultMultipleProcedureDiscount = (isNew = false) =>
  Object.assign(
    {
      contractId: '',
      costPercentage: 0,
      id: '',
      procedureNumber: 0,
    },
    Defaults(isNew)
  )
const MultipleProcedureDiscount = DefaultMultipleProcedureDiscount()

export type IContract = typeof Contract & IContractDto
export let DefaultContract = (isNew = false) => {
  const multipleProcedureDiscounts = [
    new MultipleProcedureDiscountDto({
      ...DefaultMultipleProcedureDiscount(isNew),
      costPercentage: 0,
      procedureNumber: 1,
    }),
    new MultipleProcedureDiscountDto({
      ...DefaultMultipleProcedureDiscount(isNew),
      costPercentage: 50,
      procedureNumber: 2,
    }),
  ]

  return Object.assign(
    {
      codes: [] as CodeDto[],
      contractAttachmentFile: [] as ContractAttachmentFileDto[],
      createdOn: new Date(),
      criticalCareAccess: false,
      id: '',
      isActive: true,
      isCopy: false,
      isSingleCaseAgreement: false,
      modifiedOn: new Date(),
      multipleProcedureDiscounts: isNew ? multipleProcedureDiscounts : [],
      name: '',
      organizationDto: {} as IdentifierEntityDto,
      percentageOfMedicare: 0,
      preCertNumber: '',
      providerId: '',
      startedOn: new Date(),
      useMultipleProcedureDiscount: false,
      usePercentageOfMedicare: false,
    },
    Defaults(isNew)
  )
}
const Contract = DefaultContract()

export type ICode = typeof Code & ICodeDto
export let DefaultCode = (isNew = false) =>
  Object.assign(
    {
      codeType: '',
      contractId: '',
      id: '',
      operationCode: '',
      rate: 0,
    },
    Defaults(isNew)
  )
const Code = DefaultCode()

export type ICommunication = typeof Communication & ICommunicationDto
export let DefaultCommunication = (isNew = false) =>
  Object.assign(
    {
      communicationDate: new Date(),
      communicationTags: [] as TagDto[],
      contact: '',
      createdOn: new Date(),
      creatorName: '',
      id: '',
      isActive: true,
      location: {} as IdentifierEntityDto,
      message: '',
      modifiedOn: new Date(),
      name: '',
      patientId: '',
      preCertNumber: '',
      providerId: '',
      type: '',
    },
    Defaults(isNew)
  )
const Communication = DefaultCommunication()

export type IAttachmentFile = typeof AttachmentFile & IAttachmentFileDto
export let DefaultAttachmentFile = (isNew = false) =>
  Object.assign(
    {
      content: '',
      createdOn: new Date(),
      id: '',
      isActive: true,
      mimeType: '',
      modifiedOn: new Date(),
      name: '',
      observationId: '',
      url: '',
    },
    Defaults(isNew)
  )
const AttachmentFile = DefaultAttachmentFile()

export type IContact = typeof Contact & IContactDto
export let DefaultContact = (isNew = false) =>
  Object.assign(
    {
      cellNumber: '',
      comments: '',
      contactType: '',
      costSavingsReport: false,
      createdOn: new Date(),
      email: '',
      faxNumber: '',
      firstName: '',
      id: '',
      isActive: true,
      lastName: '',
      modifiedOn: new Date(),
      name: '',
      officeNumber: '',
      phone: '',
      providerId: '',
      specialPricing: false,
      suffix: '',
      contactTags: [] as TagDto[],
      title: '',
    },
    Defaults(isNew)
  )
const Contact = DefaultContact()

export type IQuote = typeof Quote & IQuoteDto
export let DefaultQuote = (isNew = false) =>
  Object.assign(
    {
      adjustedCostOverrddenBy: '',
      adjustedCostOverriddenOn: new Date(),
      anesthesiaLineItems: [] as AnesthesiaLineItemDto[],
      anesthesiaStatus: '',
      bodyPartText: '',
      createdOn: new Date(),
      customColumnCell: undefined as JSX.Element | undefined,
      dateOfBirth: undefined as Date | undefined,
      dateOfBirthText: '',
      id: '',
      isActive: true,
      isAdjustedCostOverridden: false,
      miscellaneousLineItems: [] as MiscellaneousLineItemDto[],
      modifiedOn: new Date(),
      name: '',
      note: '',
      physicalTherapyLineItems: [] as PhysicalTherapyLineItemDto[],
      physicalTherapyStatus: '',
      preCertNumber: '',
      patientName: '',
      professionalComponentLineItems: [] as ProfessionalComponentLineItemDto[],
      professionalComponentStatus: '',
      status: 0,
      surgeryDate: new Date(),
      technicalComponentLineItems: [] as TechnicalComponentLineItemDto[],
      technicalComponentStatus: '',
    },
    Defaults(isNew)
  )
const Quote = DefaultQuote()

export type IProfessionalComponentLineItem = typeof ProfessionalComponentLineItem &
  IProfessionalComponentLineItemDto
export let DefaultProfessionalComponentLineItem = (isNew = false) =>
  Object.assign(
    {
      calculationExplanation: '',
      cost: 0,
      costOverriddenBy: '',
      costOverriddenOn: new Date(),
      cptCode: '',
      id: '',
      isCostOverridden: false,
      physicianId: '',
      providerId: '',
      typeId: '',
      typeText: '',
      markedDeleted: false,
    },
    Defaults(isNew)
  )
const ProfessionalComponentLineItem = DefaultProfessionalComponentLineItem()

export type ITechnicalComponentLineItem = typeof TechnicalComponentLineItem &
  ITechnicalComponentLineItemDto
export let DefaultTechnicalComponentLineItem = (isNew = false) =>
  Object.assign(
    {
      calculationExplanation: '',
      cost: 0,
      costOverriddenBy: '',
      costOverriddenOn: new Date(),
      cptCode: '',
      id: '',
      inclusions: '',
      isCostOverridden: false,
      providerId: '',
      typeId: '',
      typeText: '',
      markedDeleted: false,
    },
    Defaults(isNew)
  )
const TechnicalComponentLineItem = DefaultTechnicalComponentLineItem()

export type IAnesthesiaLineItem = typeof AnesthesiaLineItem & IAnesthesiaLineItemDto
export let DefaultAnesthesiaLineItem = (isNew = false) =>
  Object.assign(
    {
      calculationExplanation: '',
      cost: 0,
      costOverriddenBy: '',
      costOverriddenOn: new Date(),
      cptCode: '',
      id: '',
      inclusions: '',
      isCostOverridden: false,
      physicianId: '',
      providerId: '',
      markedDeleted: false,
    },
    Defaults(isNew)
  )
const AnesthesiaLineItem = DefaultAnesthesiaLineItem()

export type IPhysicalTherapyLineItem = typeof PhysicalTherapyLineItem &
  IPhysicalTherapyLineItemDto
export let DefaultPhysicalTherapyLineItem = (isNew = false) =>
  Object.assign(
    {
      calculationExplanation: '',
      cost: 0,
      costOverriddenBy: '',
      costOverriddenOn: new Date(),
      costPerVisit: 0,
      id: '',
      inclusions: '',
      isCostOverridden: false,
      numberOfVisits: 0,
      physicianId: '',
      providerId: '',
      markedDeleted: false,
    },
    Defaults(isNew)
  )
const PhysicalTherapyLineItem = DefaultPhysicalTherapyLineItem()

export type IMiscellaneousLineItem = typeof MiscellaneousLineItem &
  IMiscellaneousLineItemDto
export let DefaultMiscellaneousLineItem = (isNew = false) =>
  Object.assign(
    {
      calculationExplanation: '',
      cost: 0,
      costOverriddenBy: '',
      costOverriddenOn: new Date(),
      cptCode: '',
      description: '',
      id: '',
      inclusions: '',
      isCostOverridden: false,
      providerId: '',
      typeId: '',
      typeText: '',
      markedDeleted: false,
    },
    Defaults(isNew)
  )
const MiscellaneousLineItem = DefaultMiscellaneousLineItem()

export type ICalculationExplanation = typeof CalculationExplanation &
  ICalculationExplanationDto
export let DefaultCalculationExplanation = (isNew = false) =>
  Object.assign(
    {
      contractDate: new Date(),
      contractId: '',
      isExcludedFromMultipleProcedureDiscount: false,
      isMultiProcedureDiscounted: false,
      medicarePercentage: 0,
      multiProcedureDiscountPercentage: 0,
      rate: 0,
      source: '',
    },
    Defaults(isNew)
  )
const CalculationExplanation = DefaultCalculationExplanation()

export type IType = typeof Type & ITypeDto
export let DefaultType = (isNew = false) =>
  Object.assign(
    {
      id: '',
      name: '',
    },
    Defaults(isNew)
  )
const Type = DefaultType()

export type IBodyPart = typeof BodyPart & IBodyPartDto
export let DefaultBodyPart = (isNew = false) =>
  Object.assign(
    {
      createdOn: new Date(),
      deletedOn: undefined as Date | undefined,
      id: '',
      isActive: true,
      modifiedOn: new Date(),
      name: '',
    },
    Defaults(isNew)
  )
const BodyPart = DefaultBodyPart()

export type IMultipleProcedureDiscountExclusionCode =
  typeof MultipleProcedureDiscountExclusionCode &
    IMultipleProcedureDiscountExclusionCodeDto
export let DefaultMultipleProcedureDiscountExclusionCode = (isNew = false) =>
  Object.assign(
    {
      code: '',
      createdOn: new Date(),
      id: '',
      isActive: true,
      modifiedOn: new Date(),
      name: '',
    },
    Defaults(isNew)
  )
const MultipleProcedureDiscountExclusionCode =
  DefaultMultipleProcedureDiscountExclusionCode()

export type ICMS1500 = typeof CMS1500 & ICMS1500Dto
export let DefaultCMS1500 = (isNew = false) =>
  Object.assign(
    {
      billedOutAmountTotal: 0,
      contractAmountTotal: 0,
      createdOn: new Date(),
      episodeOfCare: undefined as EpisodeOfCareDto | undefined,
      episodeOfCareId: '',
      groupNumber: 0,
      id: '',
      isActive: true,
      isIncoming: true,
      modifiedOn: new Date(),
      name: '',
      organizationId: '',
      provider: undefined as ProviderDto | undefined,
      providerId: '',
      providerTypeId: '',
      q10ARelatedToEmployment: true,
      q10BAutoAccidentState: '',
      q10BRelatedToAutoAccident: false,
      q10CRelatedToOtherAccident: false,
      q10DClaimCodes: '',
      q11ADateOfBirth: undefined as Date | undefined,
      q11ASex: '',
      q11BOtherClaimId: '',
      q11BQualifier: '',
      q11CInsuranceProgramName: 'WORKERS COMPENSATION',
      q11DAnotherBenefitPlan: false,
      q11PolicyFecaNumber: '',
      q12PatientSignature: '',
      q12PatientSignatureDate: undefined as Date | undefined,
      q13InsuredSignature: '',
      q14DateOfIllness: new Date(),
      q14Qualifier: '',
      q15OtherDate: undefined as Date | undefined,
      q15Qualifier: '',
      q16UnableToWorkFrom: undefined as Date | undefined,
      q16UnabletoWorkTo: undefined as Date | undefined,
      q17AId: '',
      q17AQualifier: '',
      q17BNpi: '',
      q17NameOfReferring: '',
      q17Qualifier: '',
      q18HospitalizationFrom: undefined as Date | undefined,
      q18HospitalizationTo: undefined as Date | undefined,
      q19AdditionalClaims: '',
      q1AInsuranceId: '',
      q1Insurance: '',
      q20Charges: 0,
      q20OutsideLab: undefined as boolean | undefined,
      q21DiagnosisIcdInd: '',
      q22OriginalRefNo: '',
      q22ResubmissionCode: '',
      q23PrioAuthorizationNumber: '',
      q25FederalTaxId: '',
      q25FederalTaxIdSource: '',
      q26PatientAccountNo: '',
      q27AcceptAssignment: undefined as boolean | undefined,
      q28TotalCharge: undefined as number | undefined,
      q29AmountPaid: 0,
      q2PatientName: '',
      q30ReservedNucc: undefined as number | undefined,
      q31Date: undefined as Date | undefined,
      q31Signature: '',
      q32ALocationNpi: '',
      q32B: '',
      q32LocationInfo: '',
      q33ABillingProviderNpi: '',
      q33B: '',
      q33BillingProviderInfo: '',
      q33BillingProviderPhoneNumber: '',
      q3Date: undefined as Date | undefined,
      q3Sex: '',
      q4InsuredName: '',
      q6RelationshipToInsured: '',
      q8ReservedNucc: '',
      q9AOtherInsuredPolicyNumber: '',
      q9BReservedNucc: '',
      q9CReservedNucc: '',
      q9DInsuranceProgramName: '',
      q9OtherInsuredName: '',
      quote: undefined as QuoteDto | undefined,
      quoteId: '',
      lastAppealDate: undefined as Date | undefined,
      originalCMS1500Id: undefined as string | undefined,
      appealType: undefined as AppealType | undefined,
      lastAppealCMS1500Id: '',
      stagedDocumentId: '',
    },
    Defaults(isNew)
  )
const CMS1500 = DefaultCMS1500()

export type ICMS1500Diagnosis = typeof CMS1500Diagnosis & ICMS1500DiagnosisDto
export let DefaultCMS1500Diagnosis = (isNew = false) =>
  Object.assign(
    {
      Code: '',
      Pointer: '',
      id: '',
    },
    Defaults(isNew)
  )
const CMS1500Diagnosis = DefaultCMS1500Diagnosis()

export type ICMS1500LineItem = typeof CMS1500LineItem & ICMS1500LineItemDto
export let DefaultCMS1500LineItem = (isNew = false) =>
  Object.assign(
    {
      ADateOfServiceFrom: new Date(),
      ADateOfServiceTo: new Date(),
      BPlaceOfServiceTo: '',
      CEmg: '',
      CMS1500Id: '',
      DModifier1: '',
      DModifier2: '',
      DModifier3: '',
      DModifier4: '',
      DProcedureCptHcpcs: '',
      EDiagnosisPointer: '',
      FCharges: 0,
      GDaysOrUnits: '',
      HFamilyPlan: '',
      IId: '',
      IIdQualifier: '',
      INpi: '',
      LineNumber: 0,
      OverrideAmount: 0,
      ReasonCode: '',
      id: '',
    },
    Defaults(isNew)
  )
const CMS1500LineItem = DefaultCMS1500LineItem()

export type IReferringDoctor = typeof ReferringDoctor & IReferringDoctorDto
export let DefaultReferringDoctor = (isNew = false) =>
  Object.assign(
    {
      address: {} as AddressDto,
      contacts: [] as ContactDto[],
      createdOn: new Date(),
      firstName: '',
      id: '',
      isActive: true,
      lastName: '',
      legacyId: '',
      modifiedOn: new Date(),
      name: '',
      npi: '',
      preferences: '',
      suffix: '',
    },
    Defaults(isNew)
  )
const ReferringDoctor = DefaultReferringDoctor()

export type INurseCaseManager = typeof NurseCaseManager & INurseCaseManagerDto
export let DefaultNurseCaseManager = (isNew = false) =>
  Object.assign(
    {
      address: {} as AddressDto,
      company: '',
      contacts: [] as ContactDto[],
      createdOn: new Date(),
      id: '',
      isActive: true,
      isSpecialPricing: false,
      legacyId: '',
      modifiedOn: new Date(),
      name: '',
      preferences: '',
    },
    Defaults(isNew)
  )
const NurseCaseManager = DefaultNurseCaseManager()

export type IPreExistingCondition = typeof PreExistingCondition & IPreExistingConditionDto
export let DefaultPreExistingCondition = (isNew = false) =>
  Object.assign(
    {
      id: '',
      isActive: true,
    },
    Defaults(isNew)
  )
const PreExistingCondition = DefaultPreExistingCondition()

export type IEmployer = typeof Employer & IEmployerDto
export let DefaultEmployer = (isNew = false) =>
  Object.assign(
    {
      address: {
        state: '',
      } as AddressDto,
      comments: '',
      contacts: [] as ContactDto[],
      createdOn: new Date(),
      id: '',
      isActive: true,
      legacyId: '',
      modifiedOn: new Date(),
      name: '',
    },
    Defaults(isNew)
  )
const Employer = DefaultEmployer()

export type IPatient = typeof Patient & IPatientDto
export let DefaultPatient = (isNew = false) =>
  Object.assign(
    {
      address: {} as AddressDto,
      allowTexting: false,
      allowContact: true,
      cellPhone: '',
      createdOn: new Date(),
      firstName: '',
      dateOfBirthText: '',
      gender: '',
      height: '',
      homePhone: '',
      hxOfSx: '',
      id: '',
      isActive: true,
      lastName: '',
      modifiedOn: new Date(),
      name: '',
      patientComments: '',
      preExistingConditions: [] as PatientPreExistingConditionDto[],
      sSN: '',
      weight: 0,
      workPhone: '',
    },
    Defaults(isNew)
  )
const Patient = DefaultPatient()

export type IPatientPreExistingCondition = typeof PatientPreExistingCondition &
  IPatientPreExistingConditionDto
export let DefaultPatientPreExistingCondition = (isNew = false) =>
  Object.assign(
    {
      patient: {} as IdentifierEntityDto,
      patientId: '',
      preExistingCondition: {} as IdentifierEntityDto,
      preExistingConditionId: '',
    },
    Defaults(isNew)
  )
const PatientPreExistingCondition = DefaultPatientPreExistingCondition()

export type ISurgeryCode = typeof SurgeryCode & ISurgeryCodeDto
export let DefaultSurgeryCode = () =>
  Object.assign({
    code: '',
    id: '',
    priority: 0,
  })
const SurgeryCode = DefaultSurgeryCode()

export type IReferral = typeof Referral & IReferralDto
export let DefaultReferral = (isNew = false) =>
  Object.assign(
    {
      claimNumber: '',
      createdOn: new Date(),
      customColumnCell: undefined as JSX.Element | undefined,
      customCommentCell: undefined as JSX.Element | undefined,
      episodesOfCare: [] as EpisodeOfCareDto[],
      additionalInsuranceContacts: [] as ReferralContactDTO[],
      additionalNCMContacts: [] as ReferralContactDTO[],
      id: '',
      initials: '',
      isActive: true,
      modifiedOn: new Date(),
      name: '',
      occupation: '',
      patientId: '',
      referralCaller: '',
      referralTime: '',
      doi: undefined as Date | undefined,
      referralDate: new Date(),
      jurisdiction: '',
    },
    Defaults(isNew)
  )
const Referral = DefaultReferral()

export type IEpisodeOfCare = typeof EpisodeOfCare & IEpisodeOfCareDto
export let DefaultEpisodeOfCare = (isNew = false) =>
  Object.assign(
    {
      calledPatientFlag: false,
      careCoordinatorId: '',
      cptCodes: [] as SurgeryCodeDto[],
      createdOn: new Date(),
      ctScan: '',
      dxCodes: [] as SurgeryCodeDto[],
      emg: '',
      id: '',
      invoiceStatus: '',
      invoiceSurgeryStatus: '',
      isActive: true,
      isClinicalNotesReceived: false,
      isNoPtToBeScheduled: false,
      isPatientOnboarding: false,
      leftVoicemailFlag: false,
      modifiedOn: new Date(),
      mri: '',
      name: '',
      noAnswerFlag: false,
      notes: '',
      organizationId: '',
      readyToScheduleFlag: false,
      referralId: '',
      schedules: [] as ScheduleDto[],
      sentTextFlag: false,
      tests: [] as TestDto[],
      xRay: '',
      claimNotes: '',
    },
    Defaults(isNew)
  )
const EpisodeOfCare = DefaultEpisodeOfCare()

export type IComponent = typeof Component & IComponentDto
export let DefaultComponent = (isNew = false) =>
  Object.assign(
    {
      appointmentTasks: [] as AppointmentTaskDto[],
      createdOn: new Date(),
      id: '',
      isActive: true,
      isDefaultComponent: false,
      isLocked: false,
      isSimpleComponent: false,
      legacyId: '',
      lock: undefined as JSX.Element | undefined,
      modifiedOn: new Date(),
      name: '',
      organizationId: '',
      excludeFromPTCount: false,
      excludeFromBundle: false,
    },
    Defaults(isNew)
  )
const Component = DefaultComponent()

export type IAppointmentTask = typeof AppointmentTask & IAppointmentTaskDto
export let DefaultAppointmentTask = (isNew = false) =>
  Object.assign(
    {
      authorizationType: '',
      componentId: '',
      createdOn: new Date(),
      days: 0,
      id: '',
      isActive: true,
      legacyId: '',
      modifiedOn: new Date(),
      name: '',
      organizationId: '',
      priority: 0,
      requiresApproval: false,
      timing: 'Before Appointment Date',
      updatedByUserId: '',
    },
    Defaults(isNew)
  )
const AppointmentTask = DefaultAppointmentTask()

export type IContactType = typeof ContactType & IContactTypeDto
export let DefaultContactType = (isNew = false) =>
  Object.assign(
    {
      createdOn: new Date(),
      id: '',
      isActive: true,
      modifiedOn: new Date(),
      name: '',
    },
    Defaults(isNew)
  )
const ContactType = DefaultContactType()

export type ISchedule = typeof Schedule & IScheduleDto
export let DefaultSchedule = (isNew = false) =>
  Object.assign(
    {
      appointmentConfirmationComment: '',
      appointmentDateColumnCell: undefined as JSX.Element | undefined,
      appointmentTasks: [] as ScheduleAppointmentTaskDto[],
      appointmentTimeColumnCell: undefined as JSX.Element | undefined,
      authorizationComment: '',
      comments: '',
      componentId: '',
      createdOn: new Date(),
      episodeOfCareId: '',
      id: '',
      isActive: true,
      isLocationRequired: false,
      isTBD: false,
      modifiedOn: new Date(),
      name: '',
      patientId: '',
      physicianId: undefined as string | undefined,
      scheduleProviderLocations: [] as ScheduleProviderLocationDto[],
      testId: '',
      rescheduled: false,
      numberOfClaims: '0',
    },
    Defaults(isNew)
  )
const Schedule = DefaultSchedule()

export type IScheduleAppointmentTask = typeof ScheduleAppointmentTask &
  IScheduleAppointmentTaskDto
export let DefaultScheduleAppointmentTask = (isNew = false) =>
  Object.assign(
    {
      authorizationType: '',
      comments: '',
      componentId: '',
      createdOn: new Date(),
      customColumnCell: undefined as JSX.Element | undefined,
      customCommentCell: undefined as JSX.Element | undefined,
      days: 0,
      id: '',
      isActive: true,
      modifiedOn: new Date(),
      name: '',
      priority: 0,
      requiresApproval: false,
      scheduleId: '',
      taskNotApplicable: false,
      timing: '',
    },
    Defaults(isNew)
  )
const ScheduleAppointmentTask = DefaultScheduleAppointmentTask()

export type ICheck = typeof Check & ICheckDto
export let DefaultCheck = (isNew = false) =>
  Object.assign(
    {
      amount: 0,
      billChecks: [] as BillCheckDto[],
      checkNumber: '',
      createdOn: new Date(),
      datePaid: new Date(),
      id: '',
      isActive: true,
      modifiedOn: new Date(),
      name: '',
      payer: '',
    },
    Defaults(isNew)
  )
const Check = DefaultCheck()

export type IBillCheck = typeof BillCheck & IBillCheckDto
export let DefaultBillCheck = (isNew = false) =>
  Object.assign(
    {
      CMS1500Id: '',
      CMS1500LineItemId: '',
      amount: 0,
      checkId: '',
      comments: '',
      cms1500: {} as CMS1500Dto | undefined,
      createdOn: new Date(),
      id: '',
      isActive: true,
      modifiedOn: new Date(),
      name: '',
    },
    Defaults(isNew)
  )
const BillCheck = DefaultBillCheck()

export type ICptCode = typeof CptCode & ICptCodeDto
export let DefaultCptCode = (isNew = false) =>
  Object.assign(
    {
      code: '',
      createdOn: new Date(),
      description: '',
      id: '',
      isActive: true,
      modifiedOn: new Date(),
      name: '',
    },
    Defaults(isNew)
  )
const CptCode = DefaultCptCode()

export type IMedicareCostLookup = typeof MedicareCostLookup & IMedicareCostLookupDto
export let DefaultMedicareCostLookup = (isNew = false) =>
  Object.assign(
    {
      createdOn: new Date(),
      id: '',
      isActive: true,
      limitingCharge: 0,
      locality: '',
      mod: '',
      modifiedOn: new Date(),
      name: '',
      nonParAmount: 0,
      note: '',
      parAmount: 0,
      procedure: '',
      state: '',
    },
    Defaults(isNew)
  )
const MedicareCostLookup = DefaultMedicareCostLookup()

export type IProcedure = typeof Procedure & IProcedureDto
export let DefaultProcedure = (isNew = false) =>
  Object.assign(
    {
      createdOn: new Date(),
      diagnostic: '',
      id: '',
      isActive: true,
      name: '',
    },
    Defaults(isNew)
  )
const Procedure = DefaultProcedure()

export type ILocationBilling = typeof LocationBilling & ILocationBillingDto
export let DefaultLocationBilling = (isNew = false) =>
  Object.assign(
    {
      addressId: '',
      createdOn: new Date(),
      id: '',
      isActive: true,
      locationId: '',
      name: '',
    },
    Defaults(isNew)
  )
const LocationBilling = DefaultLocationBilling()

export type ILocationProcedure = typeof LocationProcedure & ILocationProcedureDto
export let DefaultLocationProcedure = (isNew = false) =>
  Object.assign(
    {
      contractPrice: 0,
      createdOn: new Date(),
      id: '',
      isActive: true,
      locationId: '',
      name: '',
      otherBilling: '',
      procedureId: '',
      tesla: undefined,
      usesSecondProvider: false,
      weightLimit: undefined,
    },
    Defaults(isNew)
  )
const LocationProcedure = DefaultLocationProcedure()

export type ITest = typeof Test & ITestDto
export let DefaultTest = (isNew = false) =>
  Object.assign(
    {
      bodyPartId: '',
      cptCodes: [] as SurgeryCodeDto[],
      createdOn: new Date(),
      diagnosis: '',
      dxCodes: [] as SurgeryCodeDto[],
      episodeOfCareId: '',
      id: '',
      isActive: true,
      modifiedOn: new Date(),
      name: '',
      procedureId: '',
      referralIssue: false,
      rxReceived: false,
      status: '',
      testIssue: false,
    },
    Defaults(isNew)
  )
const Test = DefaultTest()

export type IDxCode = typeof DxCode & IDxCodeDto
export let DefaultDxCode = (isNew = false) =>
  Object.assign(
    {
      code: '',
      createdOn: new Date(),
      description: '',
      id: '',
      isActive: true,
      modifiedOn: new Date(),
      name: '',
    },
    Defaults(isNew)
  )
const DxCode = DefaultDxCode()
