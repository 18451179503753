import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultContact, IContact, IProvider } from '../../Definitions'
import {
  Client,
  ContactDto,
  IContactDto,
  SwaggerResponse,
  TagDto,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'
import EmployersStore from '../EmployersStore/EmployersStore'
import InsuranceCompaniesStore from '../InsuranceCompaniesStore'
import NurseCaseManagersStore from '../NurseCaseManagersStore'
import ProvidersStore from '../ProvidersStore'
import ReferringDoctorsStore from '../ReferringDoctorsStore/ReferringDoctorsStore'

const ColumnSettingsDto = 'ContactDto'

export default class ContactsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public modalTitle: string = ''
  @observable
  public contacts: IContact[]
  @observable
  public insuranceCompanyContacts: IContact[]
  @observable
  public referringDoctorContacts: IContact[]
  @observable
  public employerContacts: IContact[]
  @observable
  public nurseCaseManagerContacts: IContact[]
  @observable
  public selectedContact?: IContact
  @observable
  public importantContacts?: ContactDto[] = []
  @observable
  public tags?: TagDto[] = []
  @observable
  public contactType?: number
  public dataTableStore: DataTableStore<IContactDto, IContact>

  constructor(
    private providersStore: ProvidersStore,
    private insuranceCompaniesStore: InsuranceCompaniesStore,
    private referringDoctorsStore: ReferringDoctorsStore,
    private nurseCaseManagerStore: NurseCaseManagersStore,
    private employersStore: EmployersStore,
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IContactDto, IContact>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllContacts(
          this.providersStore.selectedProvider
            ? this.providersStore.selectedProvider.id
            : undefined,
          this.insuranceCompaniesStore.selectedInsuranceCompany
            ? this.insuranceCompaniesStore.selectedInsuranceCompany.id
            : undefined,
          this.referringDoctorsStore.selectedReferringDoctor
            ? this.referringDoctorsStore.selectedReferringDoctor.id
            : undefined,
          this.nurseCaseManagerStore.selectedNurseCaseManager
            ? this.nurseCaseManagerStore.selectedNurseCaseManager.id
            : undefined,
          this.employersStore.selectedEmployer
            ? this.employersStore.selectedEmployer.id
            : undefined,
          undefined,
          filter,
          page,
          5,
          orderBy,
          includeInactives
        ),
      (contact) => this.setupContacts(contact)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedContact = undefined
  }

  @action.bound
  public disableContact(contact: IContact) {
    this.isLoading = true
    return this.client
      .archiveContact(contact.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndContacts() {
    if (!this.globalStore.user) {
      return Promise.resolve()
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public openDialogWithContact(contact: IContact, title: string = '') {
    if (!contact.isNew) {
      let contactSource
      if (contact.providerId) {
        contactSource = 'Provider'
      } else if (contact.insuranceCompanyId) {
        contactSource = 'Insurance Company'
      } else if (contact.referringDoctorId) {
        contactSource = 'Referring Doctor'
      } else if (contact.nurseCaseManagerId) {
        contactSource = 'Nurse Case Manager'
      } else if (contact.employerId) {
        contactSource = 'Employer'
      }
      if (contactSource) {
        title = `Edit ${contactSource} Contact`
      }
    }
    this.isModalOpen = true
    this.selectedContact = contact
    this.modalTitle = title
  }

  @action.bound
  public recoverContact(contact: IContact) {
    this.isLoading = true
    return this.client
      .reactivateContact(contact.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveContact(contact: IContact) {
    if (this.providersStore && this.providersStore.selectedProvider) {
      contact.providerId = this.providersStore.selectedProvider.id
    }
    if (
      this.insuranceCompaniesStore &&
      this.insuranceCompaniesStore.selectedInsuranceCompany
    ) {
      contact.insuranceCompanyId =
        this.insuranceCompaniesStore.selectedInsuranceCompany.id
    }
    if (
      this.referringDoctorsStore &&
      this.referringDoctorsStore.selectedReferringDoctor
    ) {
      contact.referringDoctorId = this.referringDoctorsStore.selectedReferringDoctor.id
    }
    if (
      this.nurseCaseManagerStore &&
      this.nurseCaseManagerStore.selectedNurseCaseManager
    ) {
      contact.nurseCaseManagerId = this.nurseCaseManagerStore.selectedNurseCaseManager.id
    }
    if (this.employersStore && this.employersStore.selectedEmployer) {
      contact.employerId = this.employersStore.selectedEmployer.id
    }
    this.isLoading = true
    let promise
    const contactDto = new ContactDto(contact)
    if (contact.isNew) {
      promise = this.client.createContact(contactDto)
    } else {
      promise = this.client.updateContact(contact.id, contactDto)
    }
    return promise
      .then(() => {
        if (this.providersStore.selectedProvider) {
          this.getImportantContacts(this.providersStore.selectedProvider)
        }
        this.resetUIState(contact.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getInsuranceCompanyContacts(insuranceCompanyId?: string, filter?: string) {
    this.isLoading = true
    return this.client
      .getAllContacts(
        undefined,
        insuranceCompanyId,
        undefined,
        undefined,
        undefined,
        0,
        filter,
        undefined,
        undefined,
        undefined,
        true
      )
      .then((resp: SwaggerResponse<ContactDto[]>) =>
        runInAction(() => {
          this.insuranceCompanyContacts = this.setupContacts(resp.result)
        })
      )
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public getNurseCaseManagerContacts(nurseCaseManagerId?: string, filter?: string) {
    this.isLoading = true
    return this.client
      .getAllContacts(
        undefined,
        undefined,
        undefined,
        nurseCaseManagerId,
        undefined,
        2,
        filter,
        undefined,
        undefined,
        undefined,
        true
      )
      .then((resp: SwaggerResponse<ContactDto[]>) =>
        runInAction(() => {
          this.nurseCaseManagerContacts = this.setupContacts(resp.result)
        })
      )
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public getAllTags = (filter: string) => {
    return this.client.getAllTags(filter).then((resp: SwaggerResponse<TagDto[]>) =>
      runInAction(() => {
        this.tags = resp.result
      })
    )
  }

  @action.bound
  public getImportantContacts(provider: IProvider) {
    this.isLoading = true
    return this.client
      .getImportantContacts(provider.id)
      .then((data) => {
        runInAction(() => {
          this.importantContacts = data.result
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  private setupContacts = (contacts: IContactDto[]) => {
    return contacts.map((x) => this.setupContactMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (contact: IContactDto): IContact => {
    return { ...DefaultContact(), ...contact }
  }

  private setupContactMenuItems = (contact: IContact) => {
    contact.menuItems = []
    contact.menuItems.push({
      icon: Edit,
      isConfirm: false,
      name: 'Edit',
      onClick: () => this.openDialogWithContact(contact),
    })
    if (contact.isActive) {
      contact.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableContact(contact),
      })
    } else {
      contact.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverContact(contact),
      })
    }
    return contact
  }
}
