import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { inject } from 'mobx-react'
import * as React from 'react'
import { IStores } from '../../Stores'
import { INotification } from '../../stores/NotificationStore/NotificationStore'

export interface IInfoDialogProps {
  closeDialog?: () => void
  isOpen?: boolean
  notification?: INotification
}

class InfoDialog extends React.Component<IInfoDialogProps> {
  public render() {
    const { closeDialog, isOpen } = this.props
    return (
      <div>
        <Dialog maxWidth="lg" open={isOpen!}>
          <DialogTitle>{this.getMessage()}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.getInformation()}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} type="submit" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  public getMessage() {
    if (this.props.notification) {
      return this.props.notification.message
    } else {
      return 'No notification to show'
    }
  }

  public getInformation() {
    if (this.props.notification && this.props.notification.information) {
      return this.props.notification.information
    } else {
      return 'There is no detailed information to show'
    }
  }
}

const InjectedInfoDialog = inject<
  IStores,
  IInfoDialogProps,
  Partial<IInfoDialogProps>,
  any
>((stores: IStores) => ({
  closeDialog: stores.notifications.closeDialog,
  isOpen: stores.notifications.isModalOpen,
  notification: stores.notifications.modalNotification,
}))(InfoDialog)

export default InjectedInfoDialog
