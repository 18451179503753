import { Assignment, Edit, People, RecordVoiceOver } from '@material-ui/icons'
import _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import Moment from 'moment'
import { IMenuItem } from 'src/common/ESDataTable'
import {
  GetInsuranceCompanyContactOptions,
  GetAllInsuranceCompanyOptions,
  GetNCMCompanyOptions,
  GetNCMContactOptions,
} from 'src/services/LookupService'
import {
  getReferralById,
  insertReferral,
  updateReferral,
} from 'src/services/ReferralService'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import { ReferralContactDTO } from 'src/viewModels/ReferralContactDTO'
import { IReferralDTO, ReferralDTO } from 'src/viewModels/ReferralDTO'
import { DefaultReferral, IReferral } from '../../Definitions'
import {
  Client,
  EpisodeOfCareDto,
  IReferralDto,
  SwaggerResponse,
} from '../../generated_client'
import DataTableStore from '../DataTableStore'
import GlobalStore from '../GlobalStore'
import PatientsStore from '../PatientsStore/PatientsStore'
import React from 'react'
import { Tooltip } from '@material-ui/core'

const ColumnSettingsDto = 'ReferralDto'
const DiagnosticPatientFlowDto = 'DiagnosticPatientFlowDto'

export default class ReferralsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public referrals: IReferral[]
  @observable
  public selectedReferral?: IReferral
  @observable
  public pageSize: number = 1
  @observable
  public referralsFlaggedAsIssueCount: number = 0
  @observable
  public selectedCareCoordinatorId: string
  @observable
  public selectedFilter: number = 0
  public dataTableStore: DataTableStore<IReferralDto, IReferral>
  public patientFlowDataTableStore: DataTableStore<IReferralDto, IReferral>
  @observable
  insuranceCompanyOptions: DropdownOption[]
  @observable
  filteredInsuranceCompanyOptions: DropdownOption[]
  @observable
  ncmCompanyOptions: DropdownOption[]
  @observable
  filteredNCMCompanyOptions: DropdownOption[]
  @observable
  insuranceCompanyContactOptions: DropdownOption[]
  @observable
  ncmContactOptions: DropdownOption[]

  constructor(
    private globalStore: GlobalStore,
    private routerStore: RouterStore,
    private patientStore: PatientsStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IReferralDto, IReferral>(
      globalStore,
      ({ filter, page, orderBy }) =>
        client.getAllReferrals(
          this.patientStore.selectedPatient ? this.patientStore.selectedPatient.id : '',
          filter,
          page,
          this.pageSize,
          orderBy,
          true
        ),
      (referral) => this.setupReferrals(referral, false)
    )

    this.patientFlowDataTableStore = new DataTableStore<IReferralDto, IReferral>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.diagnosticPatientFlow(
          this.selectedFilter,
          this.selectedCareCoordinatorId,
          filter,
          page,
          1,
          orderBy,
          includeInactives
        ),
      (referral) =>
        this.setupReferrals(
          _.orderBy(referral, (x) => Moment(x.dueDate!), ['asc']),
          true
        )
    )
  }

  @action.bound
  public clearFilters() {
    this.selectedCareCoordinatorId = ''
  }

  @action.bound
  public setSelectedCareCoordinatorId(id: string) {
    this.selectedCareCoordinatorId = id
    this.patientFlowDataTableStore.loadData()
  }

  @action.bound
  public setSelectedFilter(filter: number) {
    this.selectedFilter = filter
    this.patientFlowDataTableStore.loadData()
  }

  @action.bound
  public async getInsuranceCompanyOptions() {
    this.insuranceCompanyOptions = await GetAllInsuranceCompanyOptions()
    this.filteredInsuranceCompanyOptions = this.insuranceCompanyOptions
  }

  @action.bound
  public async filterInsuranceCompanyOptions(filter: string) {
    this.filteredInsuranceCompanyOptions = this.insuranceCompanyOptions.filter((x) =>
      x.description.toLowerCase().includes(filter.toLowerCase())
    )
  }

  @action.bound
  public async getNCMCompanyOptions() {
    this.ncmCompanyOptions = await GetNCMCompanyOptions()
    this.filteredNCMCompanyOptions = this.ncmCompanyOptions
  }

  @action.bound
  public async filterNCMCompanyOptions(filter: string) {
    this.filteredNCMCompanyOptions = this.ncmCompanyOptions.filter((x) =>
      x.description.toLowerCase().includes(filter.toLowerCase())
    )
  }

  @action.bound
  public async getInsuranceCompanyContactOptions(companyId: string) {
    this.insuranceCompanyContactOptions =
      await GetInsuranceCompanyContactOptions(companyId)
  }

  @action.bound
  public async getNCMContactOptions(companyId: string) {
    this.ncmContactOptions = await GetNCMContactOptions(companyId)
  }

  @action.bound
  public getColumnSettingsAndReferrals(isPatientFlow: boolean = false): Promise<void> {
    if (!this.globalStore.user) {
      return Promise.resolve()
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(
        this.globalStore.user!.id,
        isPatientFlow ? DiagnosticPatientFlowDto : ColumnSettingsDto
      )
      .then((data) => {
        runInAction(() => {
          if (isPatientFlow) {
            this.patientFlowDataTableStore.setColumns(data.result)
            const sortedColumn = _.find(
              this.patientFlowDataTableStore.columns,
              (col) => !!col.sort
            )
            if (sortedColumn) {
              this.patientFlowDataTableStore.setTableOrderBy(sortedColumn.sort)
            }
            this.patientFlowDataTableStore.loadData()
          } else {
            this.dataTableStore.setColumns(data.result)
            const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
            if (sortedColumn) {
              this.dataTableStore.setTableOrderBy(sortedColumn.sort)
            }
            this.dataTableStore.loadData()
          }
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public episodesOfCareFlaggedAsIssueCount() {
    return this.client
      .episodesOfCareFlaggedAsIssueCount()
      .then((resp: SwaggerResponse<number>) =>
        runInAction(() => {
          this.referralsFlaggedAsIssueCount = resp.result
        })
      )
  }

  @action.bound
  public async getReferralWithContactsById(id: string) {
    this.isLoading = true
    await getReferralById(id)
      .then((resp: IReferralDTO) => (this.selectedReferral = this.toIReferral(resp)))
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public editReferral(referralId: string | undefined, patientId: string) {
    const patientIdString =
      patientId != '' && patientId != undefined
        ? patientId
        : this.patientStore.selectedPatient?.id
    if (referralId) {
      this.getReferralWithContactsById(referralId)
    }
    this.routerStore.push(
      `/patients/patient/${patientIdString}/referral/${
        referralId != '' && referralId != undefined ? referralId : ''
      }`
    )
  }

  @action.bound
  public editEpisodeOfCare(referralDto: IReferralDto | undefined) {
    const referralId = referralDto ? referralDto.id : ''
    const patientId = referralDto ? referralDto.patientId : ''
    const episodeOfCareId = referralDto ? referralDto.episodeOfCareIdForPatientFlow : ''
    sessionStorage.setItem('eocTab', '0')
    this.routerStore.push(
      `/patients/patient/${patientId}/referral/${referralId}/episodeOfCare/${episodeOfCareId}`
    )
  }

  @action.bound
  public editSchedule(referralDto: IReferralDto | undefined) {
    const referralId = referralDto ? referralDto.id : ''
    const patientId = referralDto ? referralDto.patientId : ''
    const episodeOfCareId = referralDto ? referralDto.episodeOfCareIdForPatientFlow : ''
    const scheduleId = referralDto ? referralDto.scheduleIdForPatientFlow : ''
    this.routerStore.push(
      `/patients/patient/${patientId}/referral/${referralId}/episodeOfCare/${episodeOfCareId}/schedule/${scheduleId}`
    )
  }

  @action.bound
  public setSelectedReferral(referral: IReferral | undefined) {
    this.selectedReferral = referral
  }

  @action.bound
  public saveReferral(referral: IReferral) {
    this.isLoading = true
    let promise
    const referralDto = this.toReferralDTO(referral)
    if (referral.isNew) {
      promise = insertReferral(referralDto)
    } else {
      promise = updateReferral(referral.id, referralDto)
    }
    return promise
      .then((result) => {
        referral.id = result
      })
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupReferrals = (referrals: IReferralDto[], isPatientFlow: boolean) => {
    return referrals.map((x) =>
      this.setupReferralMenuItems(this.addDefaultFields(x), isPatientFlow)
    )
  }

  private addDefaultFields = (referral: IReferralDto): IReferral => {
    return { ...DefaultReferral(), ...referral }
  }

  private toIReferral = (referralDTO: IReferralDTO): IReferral => {
    return {
      ...{
        claimNumber: '',
        createdOn: new Date(),
        customColumnCell: undefined as JSX.Element | undefined,
        customCommentCell: undefined as JSX.Element | undefined,
        episodesOfCare: [] as EpisodeOfCareDto[],
        additionalInsuranceContacts: [] as ReferralContactDTO[],
        additionalNCMContacts: [] as ReferralContactDTO[],
        id: '',
        initials: '',
        isActive: true,
        modifiedOn: new Date(),
        name: '',
        occupation: '',
        patientId: '',
        referralCaller: '',
        referralTime: '',
        doi: undefined as Date | undefined,
        referralDate: new Date(),
        isExpanded: false,
        isMenuOpen: false,
        isNew: false,
        isSelected: true,
        menuItems: [] as IMenuItem[],
        jurisdiction: '',
      },
      ...referralDTO,
    }
  }

  private toReferralDTO = (referral: IReferral): ReferralDTO => {
    return new ReferralDTO(
      referral.id,
      referral.name,
      referral.isActive,
      referral.patientId,
      referral.doi,
      referral.referralDate,
      referral.referralTime,
      referral.employerId,
      referral.employerContact,
      referral.employerContactId,
      referral.occupation,
      referral.insuranceCompanyId,
      referral.insuranceCompanyContactId,
      referral.nurseCaseManagerId,
      referral.nurseCaseManagerContactId,
      referral.referralCaller,
      referral.claimNumber,
      referral.health,
      referral.initials,
      referral.insuranceCompany?.name,
      referral.jurisdiction,
      referral.additionalInsuranceContacts,
      referral.additionalNCMContacts
    )
  }

  private setupReferralMenuItems = (referral: IReferral, isPatientFlow: boolean) => {
    if (!isPatientFlow) {
      referral.menuReplacer = React.createElement(Tooltip, {
        title: 'Edit Referral',
        children: React.createElement(Edit, {
          onClick: () => this.editReferral(referral.id, referral.patientId),
        }),
      })
    } else {
      referral.menuItems = []
      if (referral.id) {
        referral.menuItems.push({
          color: '#94D33D',
          icon: RecordVoiceOver,
          name: 'Referral',
          onClick: () => this.editReferral(referral.id, referral.patientId),
        })
      }
      if (referral.episodeOfCareIdForPatientFlow) {
        referral.menuItems.push({
          color: '#94D33D',
          icon: Assignment,
          name: 'Episode of Care',
          onClick: () => this.editEpisodeOfCare(referral),
        })
      }
      if (referral.scheduleIdForPatientFlow) {
        referral.menuItems.push({
          color: '#94D33D',
          icon: People,
          name: 'Scheduling',
          onClick: () => this.editSchedule(referral),
        })
      }
    }

    return referral
  }
}
