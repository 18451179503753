import { ReferralContactType } from 'src/models/enums/ReferralContactType.enum'

export interface IReferralContactDTO {
  referralId: string
  contactId: string
  contactType: ReferralContactType
  contactName: string
}

export class ReferralContactDTO implements IReferralContactDTO {
  public referralId: string
  public contactId: string
  public contactType: ReferralContactType
  public contactName: string

  constructor(
    referralId: string,
    contactId: string,
    contactType: ReferralContactType,
    contactName: string
  ) {
    this.referralId = referralId
    this.contactId = contactId
    this.contactType = contactType
    this.contactName = contactName
  }
}
