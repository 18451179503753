import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { LocalHospital } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { Form, Formik, FormikActions } from 'formik'
import { ILocation } from 'src/Definitions'
import TypeAheadField from 'src/common/TypeAheadField/TypeAheadField'
import _ from 'lodash'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import { ChangeLocationProviderGroup } from 'src/services/LocationService'

interface IChangeGroupModalProps {
  close?: () => void
  isOpen?: boolean
  selectedLocation?: ILocation | undefined
  groupOptions?: DropdownOption[]
  getGroups?: (filter: string) => Promise<void>
}

export interface IGroupChangeValues {
  group: undefined | DropdownOption
}

class ChangeGroupModal extends React.Component<IChangeGroupModalProps> {
  private getSelectedGroup(values: IGroupChangeValues) {
    return _.find(
      this.props.groupOptions,
      (x: DropdownOption) => x.id === values.group?.id
    )
  }

  public state = {
    isLoading: false,
  }

  private getGroupName(item: DropdownOption) {
    if (item) {
      return item.description
    } else {
      return ''
    }
  }

  private getValue(item: any) {
    return item ? item.id : ''
  }

  private setValue =
    (setFieldValue: (field: string, value: any) => void) =>
    (fieldName: string, value: IGroupChangeValues) => {
      const newValue = value !== undefined ? value.group : undefined
      setFieldValue(`${fieldName}Id`, newValue)
      setFieldValue(fieldName, value)
    }

  public render() {
    const { isOpen } = this.props

    return (
      <Dialog
        key={this.props.selectedLocation?.id}
        fullWidth={true}
        maxWidth="md"
        open={isOpen!}
        onClose={this.close}
        disableBackdropClick
      >
        <Formik
          initialValues={{ group: undefined }}
          onSubmit={this.save}
          validationSchema={undefined}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, submitForm }) => (
            <>
              <DialogTitle style={{ padding: 0 }}>
                <ResponsiveAppBar
                  title={'Change Group - ' + this.props.selectedLocation?.name}
                  pageIcon={<LocalHospital />}
                  isModal={true}
                />
              </DialogTitle>
              <DialogContent>
                {this.state.isLoading && (
                  <div
                    style={{
                      position: 'fixed',
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1200',
                    }}
                  >
                    <CircularProgress size={100} />
                  </div>
                )}
                <Form>
                  <Grid xs={12} style={{ paddingTop: '20px' }}>
                    <TypeAheadField
                      outlined
                      label="Provider Group"
                      labelShrink={true}
                      inputId="groupId"
                      name="group"
                      cypressLabel="group"
                      fullWidth
                      setFieldValue={this.setValue(setFieldValue)}
                      selectedValue={this.getSelectedGroup(values)}
                      items={this.props.groupOptions}
                      getName={this.getGroupName}
                      getValue={this.getValue}
                      getItems={this.props.getGroups}
                    />
                  </Grid>
                </Form>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.close} data-cy="close" variant="contained">
                  Close
                </Button>
                <Button
                  onClick={this.handleSave(submitForm)}
                  data-cy="save"
                  variant="contained"
                  color="primary"
                >
                  Change Group
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    )
  }

  private close = () => {
    this.props.close!()
  }

  public handleSave = (submitForm: () => void) => () => {
    this.setState({ isLoading: true })
    submitForm()
  }

  private save = (
    values: IGroupChangeValues,
    formikBag: FormikActions<IGroupChangeValues>
  ) => {
    if (values.group != undefined) {
      ChangeLocationProviderGroup(this.props.selectedLocation!.id, values.group!.id)
        .then(() => {
          formikBag.setSubmitting(false)
          this.setState({ isLoading: false })
          this.props.close!()
        })
        .catch(() => {
          this.setState({ isLoading: false })
          formikBag.setSubmitting(false)
        })
    }
  }
}
const InjectedChangeGroupModal = inject<
  IStores,
  IChangeGroupModalProps,
  Partial<IChangeGroupModalProps>,
  any
>((stores: IStores) => ({
  close: stores.locations.closeGroupModal,
  isOpen: stores.locations.isGroupModalOpen,
  selectedLocation: stores.locations.selectedLocation,
  getGroups: stores.locations.filterProviderGroupOptions,
  groupOptions: stores.locations.filteredGroupOptions,
}))(ChangeGroupModal)

export default InjectedChangeGroupModal
