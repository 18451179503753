import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { CallMerge } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React, { ChangeEvent } from 'react'
import {
  DefaultProvider,
  IEpisodeOfCare,
  ILocation,
  IOrganization,
  IProvider,
  IUser,
} from 'src/Definitions'
import { IStores } from 'src/Stores'
import Moment from 'moment'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { Form, Formik, FormikActions, FormikProps, getIn } from 'formik'
import {
  ProviderTypeDto,
  IPhysicianDto,
  ITypeDto,
  TypeDto,
  IProviderTypeDto,
} from 'src/generated_client'
import { IProcedureFilter } from 'src/schedules/ProviderLookupDialog'
import {
  GetLocationById,
  searchProviderBillingLocations,
} from 'src/services/LookupService'
import { pagedList } from 'src/viewModels/pagedList'
import { ProviderBillingLocationSummaryResult } from 'src/viewModels/ProviderBillingLocationSummaryResult'
import FullWidthField from 'src/common/FullWidthField'
import TypeAheadField from 'src/common/TypeAheadField/TypeAheadField'
import * as Yup from 'yup'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { AnesthesiaLineItemDTO } from 'src/viewModels/quotes/AnesthesiaLineItemDTO'
import { PhysicalTherapyLineItemDTO } from 'src/viewModels/quotes/PhysicalTherapyLineItemDTO'
import { MiscellaneousLineItemDTO } from 'src/viewModels/quotes/MiscellaneousLineItemDTO'
import { QuoteCalculationRequest } from 'src/viewModels/QuoteCalculationRequest'
import { calculateLineItem } from 'src/services/QuoteService'
import { formatCurrency } from 'src/utils/Formatter'
import { ReconcileQuoteClaimDropdownOption } from 'src/viewModels/quotes/ReconcileQuoteClaimDropdownOption'
import { GetReconcileQuoteClaimLineOptions } from 'src/services/LookupService'
import { BillingLocationSearchRequest } from 'src/viewModels/BillingLocationSearchRequest'
import ReactPaginate from 'react-paginate'
import { CostSourceText } from './CostSourceText'
import QuoteTypeNameConstants from 'src/constants/QuoteTypeNameConstants'

const styles = ({ palette }: Theme) =>
  createStyles({
    quoteInfo: {
      paddingTop: '2px!important',
      paddingBottom: '2px!important',
    },
    quoteDetailContainer: {
      marginTop: '5px',
      marginBottom: '10px',
    },
    quoteDetailRow: {
      borderTop: '2px',
      borderTopColor: palette.secondary.main,
      borderTopStyle: 'solid',
      borderBottom: '1px',
      borderBottomColor: palette.grey[300],
      borderBottomStyle: 'ridge',
      borderLeft: '1px',
      borderLeftColor: palette.grey[300],
      borderLeftStyle: 'ridge',
      borderRight: '1px',
      borderRightColor: palette.grey[300],
      borderRightStyle: 'ridge',
      marginBottom: -1,
      minHeight: '32px',
      paddingLeft: '10px',
    },
  })
interface IAddQuoteLineModalProps extends WithStyles<typeof styles> {
  close?: ((reload: () => void) => void) | (() => void)
  isLoading?: boolean
  isOpen?: boolean
  quote?: IQuoteDTO
  isNum?: (str: any) => boolean

  addProviderLocation?: (scheduleId: string, locationId: string) => Promise<void>
  clearData?: () => Promise<void>
  clearLocations?: () => void
  currentAppOrganization?: IOrganization
  getAllProviderTypes?: () => Promise<void>
  getScheduleById?: (id: string) => Promise<void>
  lookupTitle?: string
  providerTypes?: ProviderTypeDto[]
  selectedEpisodeOfCare?: IEpisodeOfCare
  selectedProvider?: IProvider
  selectedLocation?: ILocation
  selectedProviderTypeId?: string
  setSelectedLocation?: (location?: ILocation) => void
  setSelectedProcedureFilters?: (procedureFilters?: IProcedureFilter[]) => void
  setSelectedProvider?: (provider?: IProvider) => void
  setSelectedProviderGroup?: (providerGroup?: string) => void
  setSelectedProviderLocation?: (locationName?: string) => void
  setSelectedProviderTypeId?: (selectedProviderTypeId: string) => void
  updateParentStateAfterModalEvent?: () => void
  updateParentStateAfterModal?: () => void
  addReconciledClaimLine?: (id: string) => void
  usedClaimLines?: Array<string>

  getPhysicians?: (filter: string, providerId: string) => Promise<void>
  physicians?: IPhysicianDto[]
  getTypes?: (filter: string) => Promise<void>
  types?: ITypeDto[]
  selectedType?: ITypeDto
  setSelectedType?: (selectedType?: ITypeDto) => void

  reloadParent?: () => void
  modalSwitch?: string
  user?: IUser
  existingQuoteLineId?: string
  showAssistantWarning?: () => void
  claimDropdown?: Array<ReconcileQuoteClaimDropdownOption>
}

interface IBillingProviderLookupFormValues {
  providerTypeId?: string
  providerLocation?: string
  providerGroup?: string
}

class ReconcileLineItemWizardModal extends React.Component<IAddQuoteLineModalProps> {
  private search = async (
    values: IBillingProviderLookupFormValues,
    formikActions: FormikActions<IBillingProviderLookupFormValues>
  ) => {
    this.props.setSelectedLocation!(undefined)
    this.props.setSelectedProvider!(undefined)
    this.props.setSelectedProviderGroup!(values.providerGroup)
    this.props.setSelectedProviderLocation!(values.providerLocation)
    this.setState(
      {
        providerGroup: values.providerGroup,
        providerLocation: values.providerLocation,
        providerTypeId: this.props.selectedProviderTypeId,
      },
      () => this.getProviderData()
    )
    formikActions.setSubmitting(false)
  }

  private getProviderTypes = (providerTypes?: ProviderTypeDto[]) => {
    return providerTypes ? ([undefined] as any[]).concat(providerTypes) || [] : []
  }

  private getProviderTypeName = (type: IProviderTypeDto) => {
    return type ? type.name || '' : ''
  }

  private getProviderTypeValue = (type: IProviderTypeDto) => {
    return type ? type.id || '' : ''
  }

  private setSelectedProviderTypeId = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setSelectedProviderTypeId!(event.target.value)
  }

  public setPhysicianValue = (fieldName: string, value: any) => {
    const newValue = value !== undefined ? value.id : ''
    this.secondFormRef.current.setFieldValue('physicianId', newValue)
    this.secondFormRef.current.setFieldValue(fieldName, value)
  }

  private getPhysicianName = (physician: IPhysicianDto) =>
    (physician ? physician.name : '')!
  private getPhysicianValue = (physician: IPhysicianDto) =>
    (physician ? physician.id : '')!

  private setSelectedType = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true

    var type = new TypeDto()
    type.id = event.target.selectedOptions[0].accessKey
    type.name = event.target.selectedOptions[0].value

    if (this.props.modalSwitch == 'AN') {
      this.secondFormRef.current!.state!.values!.monitored = undefined
      this.calculateLineCost()
    }

    this.props.setSelectedType!(type)
  }

  private setSelectedCostSource = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    this.setState({
      selectedCostSource: event.target.selectedOptions[0].value,
      warningCostSource: false,
    })
  }

  private setSelectedMultiDiscount = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    this.setState({ selectedMultiDiscount: event.target.selectedOptions[0].value }, () =>
      this.calculateLineCost()
    )
  }

  private setBilateral = async () => {
    this.setState({ bilateral: !this.state.bilateral }, () => this.calculateLineCost())
  }

  private calculateLineCost = async () => {
    this.setState({ cost: 'Calculating...' })
    const formikValues = this.secondFormRef.current?.state?.values
    const requestData = new QuoteCalculationRequest()

    requestData.multipleProcedureMultiplier = this.state.selectedMultiDiscount
    requestData.contractMultiplier =
      !this.props.isNum!(formikValues.contractMultiplier) &&
      formikValues.contractMultiplier != undefined
        ? undefined
        : formikValues.contractMultiplier
    requestData.actualCost =
      !this.props.isNum!(formikValues.actualCost) && formikValues.actualCost != undefined
        ? undefined
        : formikValues.actualCost
    requestData.bilateral = this.state.bilateral || false
    requestData.monitoredMultiplier =
      !this.props.isNum!(formikValues.monitored) && formikValues.monitored != undefined
        ? undefined
        : formikValues.monitored
    //Still need calc explanation, so just pass along override
    requestData.isRecon = true
    requestData.overrideCost =
      !this.props.isNum!(formikValues.overrideCost) &&
      formikValues.overrideCost != undefined
        ? undefined
        : formikValues.overrideCost

    await calculateLineItem(requestData).then((response: any) => {
      this.setState({
        cost: response.reconciledCost,
        calcText: response.reconciledCalculationExplanation,
        reconciledOverrideCost: response.reconciledOverrideCost,
      })
    })
  }

  public changeSelectHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    var claimId = event?.target.selectedOptions[0].accessKey
    var line = this.props.claimDropdown?.find(
      (x) => x.id == event?.target.selectedOptions[0].accessKey
    )
    this.setState(
      {
        claimLineDropdown: await GetReconcileQuoteClaimLineOptions(claimId),
        claimId: claimId,
        groupNumber: line?.groupNumber,
        episodeOfCareNumber: line?.episodeOfCareNumber,
        providerName: line?.providerName,
        providerId: line?.providerId,
        disableLineSelect: false,
        modalPage: 0,
        warningClaim: false,
      },
      () => {
        this.selectProvider(
          line?.providerLocationId ?? '',
          line?.providerId ?? '',
          line?.providerName ?? '',
          line?.providerLocationName ?? ''
        )
        this.props.usedClaimLines?.forEach((lineId) => {
          this.state.claimLineDropdown?.forEach((claimLine) => {
            if (lineId == claimLine.id) {
              claimLine.isEnabled = false
            }
          })
        })
        this.setState({
          claimLineDropdown: this.state.claimLineDropdown,
        })
      }
    )
    var d = document.getElementById('lineSelect') as HTMLSelectElement
    d.options[0].disabled = false
    d.selectedIndex = 0
  }

  public changeLineHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.options[0].disabled = true
    var line = this.state.claimLineDropdown.find(
      (x) => x.lineNumber?.toString() == event?.target.selectedOptions[0].accessKey
    )
    this.setState({
      modalPage: 0,
      claimLineId: line?.id,
      lineNumber: line?.lineNumber,
      reconciledCPT: line?.cpt,
      reconciledBillCharges: line?.billedCharges,
      contractAmount: line?.contractAmount,
      modifiers: line?.modifiers,
      warningClaimLine: false,
    })
  }

  public async setClaimValuesForAdd() {
    const formikValues = await this.getFormikValues()
    formikValues.actualCost = this.state.reconciledBillCharges
    formikValues.cpt = this.state.reconciledCPT
    if (this.state.claimId && this.state.lineNumber && this.state.modalPage == 2) {
      const formikValues = await this.getFormikValues()
      this.setState({
        claimDescriptionText:
          this.state.episodeOfCareNumber +
          '-' +
          this.state.groupNumber +
          ': ' +
          this.state.providerName +
          ' [' +
          this.state.lineNumber +
          '-' +
          formikValues.cpt +
          ' ' +
          this.state.modifiers +
          ' (Charges: ' +
          formatCurrency((formikValues.actualCost ?? 0).toString()) +
          ', Contract: ' +
          formatCurrency((this.state.contractAmount ?? 0).toString()) +
          ')]',
      })
    }
    this.calculateLineCost()
  }

  public onReasonCodeChange(event: any) {
    this.setState({
      reasonCode: event.target.value,
      warningReasonCode: false,
    })
  }

  public render() {
    const { providerTypes } = this.props
    const initialValues = {
      providerLocation: '',
      providerTypeId: '',
      providerGroup: '',
    }

    const { isOpen, quote } = this.props
    const quoteId = quote ? quote.id : ''
    const patientName = quote?.patientName
    const dob =
      quote?.dateOfBirth != undefined
        ? Moment(quote?.dateOfBirth).format('MM/DD/YYYY')
        : ''
    const surgery =
      quote?.surgeryDate != undefined
        ? Moment(quote?.surgeryDate).format('MM/DD/YYYY')
        : 'TBD'
    const body = quote?.bodyPartName != undefined ? quote?.bodyPartName : 'TBD'
    var QuoteLineSchema = undefined
    var modalTitle = ''
    var claimLabel = ''

    switch (this.props.modalSwitch) {
      case 'AN':
        QuoteLineSchema = Yup.object().shape({
          cpt: Yup.string(),
          actualCost: Yup.number()
            .typeError('Billed Charges must be a valid dollar amount')
            .required('Billed Charges is Required'),
          contractMultiplier: Yup.number().typeError(
            'Percentage of contract must be a valid number'
          ),
          description: Yup.string().required('Description is required'),
          costSource: Yup.number().required('Cost Source is required'),
          monitored: Yup.number().typeError('Monitored AN must be a number'),
          overrideCost: Yup.number().typeError('Reconciled Override $ must be a number'),
        })
        modalTitle = `${
          this.props.existingQuoteLineId ? '' : 'Add '
        } Reconcile Anesthesiologist Component Line`
        claimLabel = 'AN Claims'
        break
      case 'PT':
        QuoteLineSchema = Yup.object().shape({
          numberOfVisits: Yup.number()
            .typeError('Number of Visits must be a number')
            .required('Number of Visits is required'),
          costPerVisit: Yup.number()
            .typeError("'Reconciled $' Per Visit must be a valid dollar amount")
            .required("'Reconciled $' per Visit is required"),
          cost: Yup.number()
            .typeError("'Reconciled $' must be a valid dollar amount")
            .required("'Reconciled $' is required"),
        })
        modalTitle = 'Add Reconcile Physical Therapy Line'
        break
      case 'MI':
        QuoteLineSchema = Yup.object().shape({
          cpt: Yup.string(),
          actualCost: Yup.number()
            .typeError('Billed Charges must be a valid dollar amount')
            .required("Actual 'Reconciled $' is Required"),
          contractMultiplier: Yup.number().typeError(
            'Percentage of contract must be a valid number'
          ),
          description: Yup.string().required('Description is required'),
          costSource: Yup.number().required('Cost Source is required'),
          type: Yup.string().required('Type is required'),
          lineDescription: Yup.string().required('Line Description is required'),
          overrideCost: Yup.number().typeError('Reconciled Override $ must be a number'),
        })
        modalTitle = `${
          this.props.existingQuoteLineId ? '' : 'Add '
        } Reconcile Miscellaneous Component Line`
        claimLabel = 'MI Claims'
        break
    }

    return (
      <Dialog key={quoteId} fullWidth={true} maxWidth="lg" open={isOpen!}>
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar title={modalTitle} pageIcon={<CallMerge />} isModal={true} />
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ paddingTop: 12, paddingBottom: 8 }}
          >
            <Grid container item spacing={3} xs={12} wrap="nowrap">
              <Grid item>
                <strong>{patientName}</strong>
              </Grid>
              <Grid item>
                <strong>DOB:</strong> {dob}
              </Grid>
              <Grid item>
                <strong>Quote Type:</strong>{' '}
                {quote?.type != undefined ? QuoteTypeNameConstants[quote?.type] : ''}
              </Grid>
              <Grid item>
                <strong>Surgery:</strong> {surgery}
              </Grid>
              <Grid item>
                <strong>Body Part:</strong> {body}
              </Grid>
              {this.state.claimId != undefined &&
              this.state.claimLineId != undefined &&
              this.state.modalPage == 2 ? (
                <Grid item>
                  <strong>Claim: </strong>
                  {this.state.claimDescriptionText}
                </Grid>
              ) : (
                <></>
              )}
              {this.state.claimId == undefined &&
              this.state.claimLineId == undefined &&
              this.state.modalPage == 2 ? (
                this.state.providerName != '' ? (
                  <Grid item>
                    <strong>Provider:</strong> {this.state.providerName}
                  </Grid>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </Grid>
            {this.state.modalPage == 0 && this.props.modalSwitch != 'PT' ? (
              <Grid item xs={12}>
                <Formik
                  initialValues={{ cpt: '', costSourceSelect: '' }}
                  onSubmit={() => {}}
                  validationSchema={QuoteLineSchema}
                  ref={this.claimSelection}
                >
                  <Form>
                    <Grid container item xs={12} spacing={1}>
                      <Grid item lg={6}>
                        <FormControl style={{ width: '100%' }} variant="outlined">
                          <InputLabel shrink={true} variant="outlined">
                            {claimLabel} *
                          </InputLabel>
                          <Select
                            variant="outlined"
                            input={<OutlinedInput notched labelWidth={80} />}
                            inputProps={{ label: true, notched: true }}
                            native={true}
                            fullWidth
                            required
                            onChange={this.changeSelectHandler}
                            id="pcClaimSelect"
                          >
                            <option value="">Please select a claim</option>
                            {this.props.claimDropdown?.map(
                              (item: ReconcileQuoteClaimDropdownOption) => (
                                <option
                                  accessKey={item.id}
                                  value={item.description}
                                  disabled={!item.isEnabled}
                                >
                                  {item.description}
                                </option>
                              )
                            )}
                          </Select>
                        </FormControl>
                        {this.state.warningClaim && (
                          <FormHelperText style={{ color: 'red' }}>
                            Claim is Required
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item lg={5}>
                        <FormControl style={{ width: '100%' }} variant="outlined">
                          <InputLabel shrink={true} variant="outlined">
                            Claim Line Item *
                          </InputLabel>
                          <Select
                            variant="outlined"
                            input={<OutlinedInput notched labelWidth={120} />}
                            inputProps={{ label: true, notched: true }}
                            native={true}
                            fullWidth
                            required
                            disabled={this.state.disableLineSelect}
                            onChange={this.changeLineHandler}
                            id="lineSelect"
                          >
                            <option value="">Please select claim line</option>
                            {this.state.claimLineDropdown?.map(
                              (item: ReconcileQuoteClaimDropdownOption) => (
                                <option
                                  accessKey={item.lineNumber?.toString()}
                                  value={item.description}
                                  disabled={!item.isEnabled}
                                >
                                  {item.description}
                                </option>
                              )
                            )}
                          </Select>
                        </FormControl>
                        {this.state.warningClaimLine && (
                          <FormHelperText style={{ color: 'red' }}>
                            Claim Line is Required
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Form>
                </Formik>
              </Grid>
            ) : (
              <></>
            )}
            {this.state.modalPage == 1 ||
            (this.state.modalPage == 0 && this.props.modalSwitch == 'PT') ? (
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={this.search}
                  enableReinitialize={true}
                >
                  {(
                    formikProps: FormikProps<IBillingProviderLookupFormValues>,
                    submitForm: () => void
                  ) => (
                    <Form>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        style={{ paddingTop: '10px' }}
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}
                        >
                          <Grid item xs={6}>
                            <Grid container direction="column" spacing={2}>
                              <Grid item>
                                <FullWidthField
                                  label="Provider Location"
                                  name="providerLocation"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item>
                                <FullWidthField
                                  label="Provider Group"
                                  name="providerGroup"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item>
                                <FormControl style={{ width: '100%' }} variant="outlined">
                                  <InputLabel shrink={true} variant="outlined">
                                    Provider Type
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    input={<OutlinedInput notched labelWidth={100} />}
                                    inputProps={{ label: true, notched: true }}
                                    native={true}
                                    fullWidth
                                    onChange={this.setSelectedProviderTypeId}
                                    id="providerTypeId"
                                    value={this.props.selectedProviderTypeId}
                                  >
                                    {this.getProviderTypes(providerTypes)?.map(
                                      (provider) => (
                                        <option
                                          value={this.getProviderTypeValue(provider)}
                                        >
                                          {this.getProviderTypeName(provider)}
                                        </option>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item container direction="row">
                                <div style={{ marginTop: '16px' }}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    onClick={submitForm}
                                  >
                                    Search
                                  </Button>
                                </div>
                                <div
                                  style={{
                                    marginTop: '16px',
                                    marginLeft: '10px',
                                  }}
                                >
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                      formikProps.resetForm(initialValues)
                                      this.props.setSelectedProviderTypeId!('')
                                      this.state.totalItems = 0
                                    }}
                                  >
                                    Clear Search
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignContent="center"
                          alignItems="flex-start"
                          wrap="wrap"
                        >
                          <Grid item xs={12} style={{ minWidth: '750px' }}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow className="gridPadding">
                                  <TableCell></TableCell>
                                  <TableCell>Provider Group (Location)</TableCell>
                                  <TableCell>Billing Name</TableCell>
                                  <TableCell>Billing Address</TableCell>
                                  <TableCell>Physical Address</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody style={{ width: '100%' }}>
                                {this.mapGridData()}
                              </TableBody>
                            </Table>
                            <div className="pagination-row">
                              {this.paginationInfo()}
                              <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                onPageChange={this.handlePageChange}
                                pageCount={this.state.pageCount}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                //@ts-ignore
                                renderOnZeroPageCount={null}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            ) : (
              <></>
            )}
            {this.state.modalPage == 2 ? (
              <Grid item xs={12}>
                <Formik
                  initialValues={{ cpt: '', costSourceSelect: '' }}
                  onSubmit={() => {}}
                  validationSchema={QuoteLineSchema}
                  ref={this.secondFormRef}
                >
                  {({ values }) => (
                    <Form>
                      <Grid spacing={2} justifyContent="space-between">
                        {this.props.modalSwitch == 'AN' ? (
                          <>
                            <Grid
                              item
                              container
                              style={{ margin: '10px 0px' }}
                              xs={12}
                              spacing={2}
                            >
                              <Grid item xs={6} md={3}>
                                <TypeAheadField
                                  outlined
                                  disabled={this.state.providerId == ''}
                                  name="physician"
                                  label="Physician"
                                  cypressLabel="Physician"
                                  fullWidth
                                  setFieldValue={this.setPhysicianValue}
                                  selectedValue={getIn(values, 'physician')}
                                  items={this.props.physicians}
                                  getName={this.getPhysicianName}
                                  getValue={this.getPhysicianValue}
                                  getItems={() =>
                                    this.props.getPhysicians!(
                                      '',
                                      this.props.selectedProvider?.id!
                                    )
                                  }
                                />
                              </Grid>
                              <Grid item xs={6} md={3}>
                                {this.props.types ? (
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel shrink={true} variant="outlined">
                                      Type
                                    </InputLabel>
                                    <Select
                                      variant="outlined"
                                      input={<OutlinedInput notched labelWidth={38} />}
                                      inputProps={{
                                        label: true,
                                        notched: true,
                                      }}
                                      native={true}
                                      fullWidth
                                      onChange={this.setSelectedType}
                                      id="type"
                                    >
                                      <option value="">Select a type</option>
                                      {this.props.types.map((item: TypeDto) => (
                                        <option
                                          accessKey={item.id}
                                          value={item.name}
                                          disabled={false}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </Select>{' '}
                                  </FormControl>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                              {this.props.selectedType?.name == 'General/MAC' ? (
                                <Grid item xs={6} md={2}>
                                  <FullWidthField
                                    label="# Units"
                                    name="monitored"
                                    variant="outlined"
                                    onKeyUp={() => this.calculateLineCost()}
                                  />
                                </Grid>
                              ) : (
                                <></>
                              )}
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="CPT"
                                  name="cpt"
                                  variant="outlined"
                                  value="12232"
                                  disabled={this.state.claimLineId != undefined}
                                />
                              </Grid>
                              <Grid item xs={6} md={3} lg={2}>
                                <FormControl fullWidth>
                                  <FormControlLabel
                                    label="Bilateral"
                                    labelPlacement="end"
                                    onChange={() => {
                                      this.setBilateral()
                                    }}
                                    control={
                                      <Checkbox
                                        checked={this.state.bilateral}
                                        inputProps={{
                                          id: 'bilateral',
                                        }}
                                        value={this.state.bilateral}
                                        name="bilateral"
                                      />
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} md={3} lg={4}>
                                <FormControl style={{ width: '100%' }} variant="outlined">
                                  <InputLabel shrink={true} variant="outlined">
                                    Cost Source *
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    input={<OutlinedInput notched labelWidth={96} />}
                                    inputProps={{ label: true, notched: true }}
                                    native={true}
                                    fullWidth
                                    required
                                    onChange={this.setSelectedCostSource}
                                    id="costSourceSelect"
                                  >
                                    <option value="">Select a cost source</option>
                                    {Object.keys(CostSourceText).map((key) => (
                                      <option accessKey={key} value={key}>
                                        {CostSourceText[key]}
                                      </option>
                                    ))}
                                  </Select>
                                </FormControl>
                                {this.state.warningCostSource && (
                                  <FormHelperText style={{ color: 'red' }}>
                                    Cost Source is Required
                                  </FormHelperText>
                                )}
                              </Grid>
                              {CostSourceText[this.state.selectedCostSource ?? 0] ==
                              'Other' ? (
                                <Grid item xs={6} md={2}>
                                  <FullWidthField
                                    label="Description"
                                    name="description"
                                    variant="outlined"
                                    onKeyUp={() =>
                                      this.setState({
                                        warningOtherDescription: false,
                                      })
                                    }
                                    required
                                  />
                                  {this.state.warningOtherDescription && (
                                    <FormHelperText style={{ color: 'red' }}>
                                      Description is Required
                                    </FormHelperText>
                                  )}
                                </Grid>
                              ) : (
                                <></>
                              )}
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="Billed Charges"
                                  name="actualCost"
                                  variant="outlined"
                                  onKeyUp={() => {
                                    this.calculateLineCost()
                                    this.setState({
                                      warningBilledCharges: false,
                                    })
                                  }}
                                  required
                                  disabled={this.state.claimLineId != undefined}
                                />
                                {this.state.warningBilledCharges && (
                                  <FormHelperText style={{ color: 'red' }}>
                                    Billed Charges is Required
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="% of Contract"
                                  name="contractMultiplier"
                                  variant="outlined"
                                  onKeyUp={() => this.calculateLineCost()}
                                />
                              </Grid>
                              <Grid item xs={6} md={2}>
                                {this.props.types ? (
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel shrink={true} variant="outlined">
                                      MPPD
                                    </InputLabel>
                                    <Select
                                      variant="outlined"
                                      input={<OutlinedInput notched labelWidth={45} />}
                                      inputProps={{
                                        label: true,
                                        notched: true,
                                      }}
                                      native={true}
                                      fullWidth
                                      onChange={this.setSelectedMultiDiscount}
                                      id="eventSelect"
                                    >
                                      <option value="">Select a MPPD</option>
                                      <option value="100">100%</option>
                                      <option value="50">50%</option>
                                      <option value="25">25%</option>
                                    </Select>
                                  </FormControl>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <strong>Reconciled $:</strong>{' '}
                                {this.props.isNum!(this.state.cost) ||
                                this.state.cost == undefined
                                  ? formatCurrency((this.state.cost ?? 0).toString())
                                  : this.state.cost.toString()}
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="Reconciled Override $"
                                  name="overrideCost"
                                  variant="outlined"
                                  onKeyUp={() => this.calculateLineCost()}
                                />
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        {this.props.modalSwitch == 'PT' ? (
                          <>
                            <Grid
                              container
                              item
                              xs={12}
                              spacing={2}
                              style={{ margin: '0px' }}
                            >
                              <Grid item xs={6}>
                                <FullWidthField
                                  label="Est. U&C Per Visit"
                                  name="actualCostPerVisit"
                                  variant="outlined"
                                  onKeyUp={() =>
                                    this.setState({
                                      warningActualCostPerVisit: false,
                                    })
                                  }
                                  required
                                />
                                {this.state.warningActualCostPerVisit && (
                                  <FormHelperText style={{ color: 'red' }}>
                                    Est. U&C Per Visit is Required
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                <FullWidthField
                                  label="Quoted Per Visit"
                                  name="costPerVisit"
                                  variant="outlined"
                                  onKeyUp={() => {
                                    this.calculateCost()
                                    this.setState({
                                      warningCostPerVisit: false,
                                    })
                                  }}
                                  required
                                />
                                {this.state.warningCostPerVisit && (
                                  <FormHelperText style={{ color: 'red' }}>
                                    Quoted Per Visit is Required
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                <FullWidthField
                                  label="Number of Visits"
                                  name="numberOfVisits"
                                  variant="outlined"
                                  onKeyUp={() => {
                                    this.calculateCost()
                                    this.setState({
                                      warningNumberOfVisits: false,
                                    })
                                  }}
                                  required
                                />
                                {this.state.warningNumberOfVisits && (
                                  <FormHelperText style={{ color: 'red' }}>
                                    Number of Visits is Required
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <strong>Reconciled $:</strong>{' '}
                                {this.props.isNum!(this.state.cost) ||
                                this.state.cost == undefined
                                  ? formatCurrency((this.state.cost ?? 0).toString())
                                  : this.state.cost.toString()}
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        {this.props.modalSwitch == 'MI' ? (
                          <>
                            <Grid
                              item
                              container
                              style={{ margin: '10px 0px' }}
                              xs={12}
                              spacing={2}
                            >
                              <Grid item xs={6} md={3}>
                                {this.props.types ? (
                                  <>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel shrink={true} variant="outlined">
                                        Type *
                                      </InputLabel>
                                      <Select
                                        variant="outlined"
                                        input={<OutlinedInput notched labelWidth={44} />}
                                        inputProps={{
                                          label: true,
                                          notched: true,
                                        }}
                                        native={true}
                                        fullWidth
                                        onChange={this.setSelectedType}
                                        id="type"
                                        required
                                      >
                                        <option value="">Select a type</option>
                                        {this.props.types.map((item: TypeDto) => (
                                          <option
                                            accessKey={item.id}
                                            value={item.name}
                                            disabled={false}
                                          >
                                            {item.name}
                                          </option>
                                        ))}
                                      </Select>{' '}
                                    </FormControl>
                                    {this.state.typeMissing && (
                                      <Grid
                                        container
                                        justifyContent="flex-start"
                                        className="error modal-form-error"
                                      >
                                        Type is Required
                                      </Grid>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <FullWidthField
                                  label="Line Description"
                                  name="lineDescription"
                                  variant="outlined"
                                  onKeyUp={() =>
                                    this.setState({
                                      warningMiscDescription: false,
                                    })
                                  }
                                  required
                                />
                                {this.state.warningMiscDescription && (
                                  <FormHelperText style={{ color: 'red' }}>
                                    Line Description is Required
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="CPT"
                                  name="cpt"
                                  variant="outlined"
                                  disabled={this.state.claimLineId != undefined}
                                />
                              </Grid>
                              <Grid item xs={6} md={3} lg={2}>
                                <FormControl fullWidth>
                                  <FormControlLabel
                                    label="Bilateral"
                                    labelPlacement="end"
                                    onChange={() => {
                                      this.setBilateral()
                                    }}
                                    control={
                                      <Checkbox
                                        checked={this.state.bilateral}
                                        inputProps={{
                                          id: 'bilateral',
                                        }}
                                        value={this.state.bilateral}
                                        name="bilateral"
                                      />
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} md={3} lg={4}>
                                <FormControl style={{ width: '100%' }} variant="outlined">
                                  <InputLabel shrink={true} variant="outlined">
                                    Cost Source *
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    input={<OutlinedInput notched labelWidth={96} />}
                                    inputProps={{ label: true, notched: true }}
                                    native={true}
                                    fullWidth
                                    required
                                    onChange={this.setSelectedCostSource}
                                    id="costSourceSelect"
                                  >
                                    <option value="">Select a cost source</option>
                                    {Object.keys(CostSourceText).map((key) => (
                                      <option accessKey={key} value={key}>
                                        {CostSourceText[key]}
                                      </option>
                                    ))}
                                  </Select>
                                </FormControl>
                                {this.state.warningCostSource && (
                                  <FormHelperText style={{ color: 'red' }}>
                                    Cost Source is Required
                                  </FormHelperText>
                                )}
                              </Grid>
                              {CostSourceText[this.state.selectedCostSource ?? 0] ==
                              'Other' ? (
                                <Grid item xs={6} md={2}>
                                  <FullWidthField
                                    label="Description"
                                    name="description"
                                    variant="outlined"
                                    onKeyUp={() =>
                                      this.setState({
                                        warningOtherDescription: false,
                                      })
                                    }
                                    required
                                  />
                                  {this.state.warningOtherDescription && (
                                    <FormHelperText style={{ color: 'red' }}>
                                      Description is Required
                                    </FormHelperText>
                                  )}
                                </Grid>
                              ) : (
                                <></>
                              )}
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="Billed Charges"
                                  name="actualCost"
                                  variant="outlined"
                                  onKeyUp={() => {
                                    this.calculateLineCost()
                                    this.setState({
                                      warningBilledCharges: false,
                                    })
                                  }}
                                  required
                                  disabled={this.state.claimLineId != undefined}
                                />
                                {this.state.warningBilledCharges && (
                                  <FormHelperText style={{ color: 'red' }}>
                                    Billed Charges is Required
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="% of Contract"
                                  name="contractMultiplier"
                                  variant="outlined"
                                  onKeyUp={() => this.calculateLineCost()}
                                />
                              </Grid>
                              <Grid item xs={6} md={2}>
                                {this.props.types ? (
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel shrink={true} variant="outlined">
                                      MPPD
                                    </InputLabel>
                                    <Select
                                      variant="outlined"
                                      input={<OutlinedInput notched labelWidth={45} />}
                                      inputProps={{
                                        label: true,
                                        notched: true,
                                      }}
                                      native={true}
                                      fullWidth
                                      onChange={this.setSelectedMultiDiscount}
                                      id="eventSelect"
                                    >
                                      <option value="">Select a MPPD</option>
                                      <option value="100">100%</option>
                                      <option value="50">50%</option>
                                      <option value="25">25%</option>
                                    </Select>
                                  </FormControl>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <strong>Reconciled $:</strong>{' '}
                                {this.props.isNum!(this.state.cost) ||
                                this.state.cost == undefined
                                  ? formatCurrency((this.state.cost ?? 0).toString())
                                  : this.state.cost.toString()}
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <FullWidthField
                                  label="Reconciled Override $"
                                  name="overrideCost"
                                  variant="outlined"
                                  onKeyUp={() => this.calculateLineCost()}
                                />
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignItems: 'flex-end' }}>
          {this.state.modalPage < 2 ? (
            <>
              {this.state.modalPage > 0 ? (
                <Button
                  variant="contained"
                  style={{ margin: '0px 4px' }}
                  onClick={() => {
                    this.setState({ modalPage: this.state.modalPage - 1 })
                    this.clearSearch()
                  }}
                >
                  Back
                </Button>
              ) : (
                <></>
              )}

              {(this.state.modalPage == 0 &&
                this.props.modalSwitch != 'PT' &&
                (this.state.claimId == undefined ||
                  this.state.claimLineId == undefined)) ||
              (this.state.modalPage == 1 && this.state.totalItems == 0) ? (
                <Button
                  variant="contained"
                  style={{ margin: '0px 4px' }}
                  onClick={() => this.skipWizardStep()}
                >
                  Skip
                </Button>
              ) : (
                <></>
              )}
              {this.state.modalPage == 0 &&
              this.state.claimId != undefined &&
              this.state.claimLineId != undefined ? (
                <Button
                  variant="contained"
                  style={{ margin: '0px 4px' }}
                  onClick={() => {
                    this.setState(
                      {
                        modalPage: this.state.modalPage + 2,
                        selectedCostSource: undefined,
                        selectedMultiDiscount: undefined,
                        bilateral: false,
                      },
                      () => this.setClaimValuesForAdd()
                    )
                  }}
                >
                  Next
                </Button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {this.props.modalSwitch != 'PT' && (
                <Grid xs={2}>
                  <TextField
                    label="Reason Code"
                    name={`reasonCode`}
                    id={`reasonCode`}
                    variant="outlined"
                    style={{
                      display: this.state.claimLineId == undefined ? 'none' : 'inherit',
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                    onChange={(e: any) => this.onReasonCodeChange(e)}
                  />
                  {this.state.warningReasonCode && (
                    <FormHelperText
                      style={{
                        color: 'red',
                        paddingRight: '4px',
                        textAlign: 'right',
                      }}
                    >
                      Reason Code is Required
                    </FormHelperText>
                  )}
                </Grid>
              )}
              <Button
                variant="contained"
                form="secondForm"
                color="primary"
                type="submit"
                onClick={() => this.save()}
              >
                Add
              </Button>
            </>
          )}
          <Button onClick={this.close} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  public state = {
    modalPage: 0,
    providerName: '',
    providerId: '',
    gridData: new pagedList().items as ProviderBillingLocationSummaryResult[],
    providerGroup: '',
    providerLocation: '',
    providerTypeId: '',
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 25,
    selectedMultiDiscount: undefined,
    selectedCostSource: undefined,
    bilateral: false,
    cost: '',
    calcText: undefined,
    claimId: undefined,
    assistantPresent: false,
    claimLineDropdown: Array<ReconcileQuoteClaimDropdownOption>(),
    groupNumber: '',
    lineNumber: undefined,
    claimLineId: undefined,
    reconciledOverrideCost: undefined,
    modifiers: undefined,
    contractAmount: undefined,
    disableLineSelect: true,
    episodeOfCareNumber: undefined,
    reconciledCPT: undefined,
    reconciledBillCharges: undefined,
    claimDescriptionText: undefined,
    reasonCode: '',
    warningBilledCharges: false,
    warningClaim: false,
    warningClaimLine: false,
    warningCostSource: false,
    warningOtherDescription: false,
    warningCostPerVisit: false,
    warningActualCostPerVisit: false,
    warningNumberOfVisits: false,
    warningMiscDescription: false,
    warningReasonCode: false,
    typeMissing: false,
  }

  public secondFormRef = React.createRef<any>()
  public claimSelection = React.createRef<any>()

  private skipWizardStep() {
    if (this.state.modalPage == 0) {
      this.setState({
        modalPage: 1,
        claimLineId: undefined,
        lineNumber: undefined,
        reconciledCPT: '',
        reconciledBillCharges: '',
        reconciledCMS1500LineItemId: undefined,
      })
    } else if (this.state.modalPage == 1) {
      this.setState({
        modalPage: 2,
        selectedCostSource: undefined,
        selectedMultiDiscount: undefined,
        bilateral: false,
        cost: '',
        providerName: '',
        providerId: '',
        gridData: new pagedList().items as ProviderBillingLocationSummaryResult[],
        providerGroup: '',
        providerLocation: '',
        providerTypeId: '',
      })
    }
  }

  private validateRequiredFields = (formikValues: any) => {
    if (!this.props.isNum!(this.state.cost)) {
      this.setState({ warningBilledCharges: true })
    }
    if (this.state.claimId == undefined) {
      this.setState({ warningClaim: true })
    }
    if (this.state.claimLineId == undefined) {
      this.setState({ warningClaimLine: true })
    }
    if (this.state.selectedCostSource == undefined) {
      this.setState({ warningCostSource: true })
    }
    if (formikValues.description == undefined) {
      this.setState({ warningOtherDescription: true })
    }
    if (formikValues.costPerVisit == undefined) {
      this.setState({ warningCostPerVisit: true })
    }
    if (formikValues.actualCostPerVisit == undefined) {
      this.setState({ warningActualCostPerVisit: true })
    }
    if (formikValues.numberOfVisits == undefined) {
      this.setState({ warningNumberOfVisits: true })
    }
    if (formikValues.lineDescription == undefined) {
      this.setState({ warningMiscDescription: true })
    }
    if (this.state.reasonCode == '' && this.state.claimLineId != undefined) {
      this.setState({ warningReasonCode: true })
    }
  }

  private save = async () => {
    const formikValues = await this.getFormikValues()

    var returned = undefined

    if (this.props.modalSwitch == 'PT') {
      await this.calculateCost()
    } else {
      await this.calculateLineCost()
    }

    this.validateRequiredFields(formikValues)

    switch (this.props.modalSwitch) {
      case 'AN':
        if (
          !this.props.isNum!(this.state.cost) ||
          (!this.props.isNum!(formikValues.actualCost) &&
            formikValues.actualCost != undefined) ||
          (!this.props.isNum!(formikValues.contractMultiplier) &&
            formikValues.contractMultiplier != undefined) ||
          this.state.selectedCostSource == undefined ||
          (CostSourceText[this.state.selectedCostSource] == 'Other' &&
            formikValues.description == undefined) ||
          (this.state.reasonCode == '' && this.state.claimLineId != undefined)
        ) {
          return
        }
        var actual = formikValues.actualCost

        if (
          this.props.isNum!(formikValues.monitored) &&
          formikValues.monitored != undefined
        ) {
          actual = formikValues.actualCost * formikValues.monitored
        }

        if (this.state.claimLineId != undefined) {
          this.props.addReconciledClaimLine!(this.state.claimLineId)
        }

        returned = new AnesthesiaLineItemDTO(
          this.state.providerId,
          this.state.providerName,
          formikValues.physicianId,
          formikValues.physician ? formikValues.physician.name : '',
          formikValues.cpt,
          this.state.bilateral || false,
          actual,
          undefined,
          undefined,
          false,
          this.state.selectedCostSource,
          formikValues.description,
          formikValues.contractMultiplier,
          this.state.selectedMultiDiscount,
          this.props.selectedType?.id,
          this.props.selectedType?.name,
          formikValues.monitored,
          this.state.calcText,
          false,
          undefined,
          this.state.reconciledCPT,
          this.state.providerName,
          this.state.reconciledBillCharges,
          this.props.isNum!(this.state.cost) ? +this.state.cost : undefined,
          this.state.reconciledOverrideCost,
          this.state.claimLineId,
          this.state.calcText,
          this.state.reasonCode
        )

        this.props.quote?.anesthesiaLineItems!.push(returned)
        break

      case 'PT':
        if (
          !this.props.isNum!(this.state.cost) ||
          !this.props.isNum!(formikValues.costPerVisit) ||
          !this.props.isNum!(formikValues.numberOfVisits)
        ) {
          return
        }
        returned = new PhysicalTherapyLineItemDTO(
          this.state.providerId,
          this.state.providerName,
          undefined,
          +formikValues.numberOfVisits,
          +formikValues.costPerVisit,
          +formikValues.actualCostPerVisit,
          undefined,
          false,
          false,
          undefined,
          +this.state.cost,
          undefined
        )

        this.props.quote?.physicalTherapyLineItems!.push(returned)
        break

      case 'MI':
        var typeMissing = this.props.selectedType == undefined
        if (
          !this.props.isNum!(this.state.cost) ||
          (!this.props.isNum!(formikValues.actualCost) &&
            formikValues.actualCost != undefined) ||
          (!this.props.isNum!(formikValues.contractMultiplier) &&
            formikValues.contractMultiplier != undefined) ||
          this.state.selectedCostSource == undefined ||
          typeMissing ||
          (CostSourceText[this.state.selectedCostSource] == 'Other' &&
            formikValues.description == undefined) ||
          typeof formikValues.lineDescription != 'string' ||
          (this.state.reasonCode == '' && this.state.claimLineId != undefined)
        ) {
          this.setState({ typeMissing: typeMissing })
          return
        }

        if (this.state.claimLineId != undefined) {
          this.props.addReconciledClaimLine!(this.state.claimLineId)
        }

        returned = new MiscellaneousLineItemDTO(
          this.state.providerId,
          this.state.providerName,
          this.state.bilateral || false,
          formikValues.lineDescription,
          undefined,
          this.props.selectedType?.id,
          formikValues.cpt,
          '',
          false,
          this.props.selectedType?.name,
          this.state.selectedCostSource,
          formikValues.description,
          formikValues.actualCost,
          formikValues.contractMultiplier,
          this.state.selectedMultiDiscount,
          this.state.calcText,
          false,
          undefined,
          this.state.reconciledCPT,
          this.state.providerName,
          this.state.reconciledBillCharges,
          this.props.isNum!(this.state.cost) ? +this.state.cost : undefined,
          this.state.reconciledOverrideCost,
          this.state.claimLineId,
          this.state.calcText,
          this.state.reasonCode
        )

        this.props.quote?.miscellaneousLineItems!.push(returned)
        break
    }

    this.secondFormRef.current.setSubmitting(false)

    this.close()
  }

  private getFormikValues = async () => {
    const formikValues = this.secondFormRef.current?.state?.values
    await this.secondFormRef.current.submitForm()
    return formikValues
  }

  private calculateCost = () => {
    const formikValues = this.secondFormRef.current?.state?.values
    var costPer = formikValues.costPerVisit ?? 0
    var numVisits = formikValues.numberOfVisits ?? 0
    this.setState({ cost: numVisits * costPer })
  }

  private close = () => {
    this.setState({
      modalPage: 0,
      pageCount: 0,
      totalItems: 0,
      providerName: '',
      providerId: '',
      selectedCostSource: undefined,
      selectedMultiDiscount: undefined,
      bilateral: false,
      cost: '',
      assistantPresent: false,
      calcText: undefined,
      claimId: undefined,
      claimLineDropdown: new Array<ReconcileQuoteClaimDropdownOption>(),
      groupNumber: '',
      lineNumber: undefined,
      disableLineSelect: true,
      claimLineId: undefined,
      reasonCode: '',
      warningBilledCharges: false,
      warningClaim: false,
      warningClaimLine: false,
      warningCostSource: false,
      warningOtherDescription: false,
      warningCostPerVisit: false,
      warningActualCostPerVisit: false,
      warningNumberOfVisits: false,
      warningMiscDescription: false,
      warningReasonCode: false,
      typeMissing: false,
    })
    this.props.setSelectedProviderTypeId!('')
    this.props.setSelectedProvider!(undefined)
    this.props.setSelectedLocation!(undefined)
    this.props.setSelectedType!(undefined)
    this.props.clearLocations!()
    this.props.close!(this.props.reloadParent!)
  }

  public componentDidMount() {
    this.props.clearData!().then(() => {
      this.props.setSelectedProvider!(undefined)
      this.props.getAllProviderTypes!()
    })
  }

  public clearSearch() {
    this.props.clearData!().then(() => {
      this.props.setSelectedProvider!(undefined)
      this.props.setSelectedLocation!(undefined)
      this.props.clearLocations!()
      this.props.setSelectedProviderTypeId!('')
      this.setState({
        pageCount: 0,
        totalItems: 0,
        providerName: '',
        providerId: '',
      })
    })
  }

  private getProviderData = async () => {
    const { page, pageSize, providerGroup, providerLocation, providerTypeId } = this.state
    const requestData = new BillingLocationSearchRequest()
    requestData.providerGroup = providerGroup
    requestData.providerLocation = providerLocation
    if (providerTypeId !== '') {
      requestData.providerTypeId = providerTypeId
    }
    requestData.page = page
    requestData.pageSize = pageSize
    await searchProviderBillingLocations(requestData).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getProviderData())
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private async selectProvider(
    locationId: string,
    providerId: string,
    providerName: string,
    locationName: string,
    modalPage?: number
  ) {
    let provider = DefaultProvider(false)
    provider.id = providerId
    provider.name = providerName
    let location = await GetLocationById(locationId)
    location.name = locationName
    this.props.setSelectedProvider!({ ...DefaultProvider(), ...provider })
    this.props.setSelectedLocation!(location as unknown as ILocation)
    if (modalPage != undefined) {
      this.setState({
        providerName: providerName,
        providerId: providerId,
        modalPage: modalPage,
        selectedCostSource: undefined,
        selectedMultiDiscount: undefined,
        bilateral: false,
        cost: '',
      })
    } else {
      this.setState({ providerName: providerName, providerId: providerId })
    }

    if (this.props.updateParentStateAfterModalEvent) {
      this.props.updateParentStateAfterModalEvent()
    }
    if (this.props.updateParentStateAfterModal) {
      this.props.updateParentStateAfterModal()
    }
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((provider, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              className="gridButton"
              disabled={
                provider.fullBillingAddress == '' ||
                provider.fullBillingAddress == undefined
              }
              onClick={() =>
                this.selectProvider(
                  provider.locationId!,
                  provider.providerId!,
                  provider.providerGroupName!,
                  provider.locationName!,
                  2
                )
              }
            >
              Select
            </Button>
          </TableCell>
          <TableCell>
            {provider.providerGroupName} ({provider.locationName})
          </TableCell>
          <TableCell>{provider.billingName}</TableCell>
          <TableCell>{provider.fullBillingAddress}</TableCell>
          <TableCell>{provider.fullPhysicalAddress}</TableCell>
        </TableRow>
      ))
    }
  }
}

const InjectedReconcileQuoteLineModal = inject<
  IStores,
  IAddQuoteLineModalProps,
  Partial<IAddQuoteLineModalProps>,
  any
>((stores: IStores) => ({
  close: stores.quotes.closeReconcileQuoteLineWizardModal,
  dataTableManager: stores.quotes.billsDataTableStore,
  isLoading: stores.quotes.isLoading,
  isOpen: stores.quotes.isQuoteReconcileLineWizardModalOpen,
  quote: stores.quotes.selectedQuote,
  patientHeader: stores.patients.headerDetails,

  addProviderLocation: stores.schedules.addProviderLocation,
  clearData: stores.locations.clearData,
  clearLocations: stores.locations.clearLocations,
  currentAppOrganization: stores.global.currentAppOrganization,
  getAllProviderTypes: stores.providers.getAllProviderTypes,
  getScheduleById: stores.schedules.getScheduleById,
  providerTypes: stores.providers.providerTypes,
  selectedEpisodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  selectedProviderTypeId: stores.locations.selectedProviderTypeId,
  selectedLocation: stores.locations.selectedEditClaimLocation,
  selectedProvider: stores.providers.selectedModalEditScheduleProvider,
  setSelectedAddress: stores.locations.setSelectedAddress,
  setSelectedProcedureFilters: stores.locations.setSelectedProcedureFilters,
  setSelectedProviderGroup: stores.locations.setSelectedProviderGroup,
  setSelectedProviderLocation: stores.locations.setSelectedProviderLocation,
  setSelectedProviderTypeId: stores.locations.setSelectedProviderTypeId,
  setSelectedLocation: stores.locations.setSelectedEditClaimLocation,
  setSelectedProvider: stores.providers.setSelectedModalEditScheduleProvider,
  setSelectedSchedule: stores.schedules.setSelectedSchedule,

  getPhysicians: stores.quotes.getPhysicianSuggestions,
  physicians: stores.quotes.physicians,
  getTypes: stores.quotes.getTypesForModal,
  types: stores.quotes.genericTypes,
  setSelectedType: stores.quotes.setSelectedType,
  selectedType: stores.quotes.selectedType,
  modalSwitch: stores.quotes.modalSwitch,
  user: stores.global.user,
  existingQuoteLineId: stores.quotes.existingQuoteLineId,
  existingQuoteLine: stores.quotes.existingQuoteLine,
  showAssistantWarning: stores.quotes.showAssistantWarning,
  claimDropdown: stores.quotes.claimDropdown,
  usedClaimLines: stores.quotes.usedClaimLines,
  addReconciledClaimLine: stores.quotes.addReconciledClaimLine,
  isNum: stores.quotes.isNum,
}))(ReconcileLineItemWizardModal)

export default withStyles(styles)(InjectedReconcileQuoteLineModal)
