import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import { Code } from '@material-ui/icons'
import { FormikActions } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import { noWhitespaceString } from '../common/YupExtensions'
import { DefaultCptCode, ICptCode } from '../Definitions'
import { IStores } from '../Stores'
import * as Yup from 'yup'

const CptCodeSchema = Yup.object().shape({
  code: noWhitespaceString('Code is required', true),
  description: noWhitespaceString('Description is required', true),
})

interface ICptCodeFormValues {
  id: string
  code: string
  description: string
  createdOn: Date
  isActive: boolean
  modifiedOn: Date
}

interface ICptCodeDialogProps {
  close?: () => void
  cptCode?: ICptCode
  isLoading?: boolean
  isOpen?: boolean
  saveCptCode?: (cptCode: ICptCode) => Promise<void>
}

class CptCodeDialog extends React.Component<ICptCodeDialogProps> {
  public save = (values: ICptCode, formikBag: FormikActions<ICptCode>) => {
    const cptCode = {
      ...DefaultCptCode(this.props.cptCode!.isNew),
      ...values,
    }
    cptCode.code = cptCode.code.toUpperCase()
    this.props.saveCptCode!(cptCode).finally(() => formikBag.setSubmitting(false))
  }

  private handleIsActiveChange =
    (values: ICptCodeFormValues, setFieldValue: (field: string, value: any) => void) =>
    () => {
      setFieldValue('isActive', !values.isActive)
    }

  public render() {
    const { close, isLoading, isOpen, cptCode } = this.props
    return (
      <ESDialog
        close={close!}
        initialValues={{ ...cptCode } as ICptCodeFormValues}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={cptCode!.isNew ? 'Add New CPT Code' : 'Edit CPT Code'}
        validationSchema={CptCodeSchema}
        titleIcon={<Code />}
      >
        {({ values, setFieldValue }) => (
          <Grid style={{ paddingTop: '20px' }} container direction="column" spacing={3}>
            <Grid item xs={3}>
              <FullWidthField
                autoFocus
                name="code"
                label="Code"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FullWidthField
                name="description"
                label="Description"
                variant="outlined"
                required
                multiline={true}
                rows="3"
              />
            </Grid>
            <Grid item xs={3} style={{ position: 'absolute', bottom: -10 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.isActive}
                    onChange={this.handleIsActiveChange(values, setFieldValue)}
                    value="isActive"
                    color="secondary"
                    name="isActive"
                  />
                }
                label={values.isActive ? 'Active' : 'Inactive'}
              />
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }
}

const InjectedCptCodeDialog = inject<
  IStores,
  ICptCodeDialogProps,
  Partial<ICptCodeDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.cptCodes.closeDialog,
  cptCode: stores.cptCodes.selectedCptCode,
  isLoading: stores.cptCodes.isLoading,
  isOpen: stores.cptCodes.isModalOpen,
  saveCptCode: stores.cptCodes.saveCptCode,
}))(CptCodeDialog)

export default InjectedCptCodeDialog
