import { Grid, Typography } from '@material-ui/core'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import { IStores } from '../Stores'
import * as Yup from 'yup'

const DefaultEmailValue = {
  email: '',
}

interface IEmailFormEvent extends React.FormEvent<Element> {
  email: string
}

interface IForgotPasswordDialog {
  close?: () => void
  forgotPassword?: (email: string) => void
  isLoading?: boolean
  isOpen?: boolean
}

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Not a valid email').required('Email is Required'),
})

@inject((stores: IStores) => ({
  close: stores.login.closeDialog,
  forgotPassword: stores.login.forgotPassword,
  isLoading: stores.login.isLoadingForgotPassword,
  isOpen: stores.login.isModalOpen,
}))
export default class ForgotPasswordDialog extends React.Component<IForgotPasswordDialog> {
  public save = (values: IEmailFormEvent) => {
    this.props.forgotPassword!(values.email)
  }

  public render() {
    return (
      <ESDialog
        close={this.props.close!}
        initialValues={DefaultEmailValue}
        isLoading={this.props.isLoading}
        open={this.props.isOpen!}
        save={this.save}
        saveButtonText="Submit"
        title="Reset Your Password"
        validationSchema={ForgotPasswordSchema}
      >
        {() => (
          <>
            <Typography variant="body2" paragraph>
              Please enter the email address associated with your account. If the email
              address matches one in our system, instructions will be sent to the email
              address with next steps for resetting the password.
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FullWidthField autoFocus label="Email" name="email" required />
              </Grid>
            </Grid>
          </>
        )}
      </ESDialog>
    )
  }
}
