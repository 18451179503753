import { Avatar, createStyles, Grid, withStyles, WithStyles } from '@material-ui/core'
import { AccountBox } from '@material-ui/icons'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { FormikActions, FormikErrors, getIn } from 'formik'
import * as _ from 'lodash'
import { inject } from 'mobx-react'
import * as React from 'react'
import Button from '../common/Button/Button'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import PhoneNumberMask from '../common/PhoneNumberMask'
import SelectField from '../common/SelectField/SelectField'
import TypeAheadField from '../common/TypeAheadField/TypeAheadField'
import ZipCodeMask from '../common/ZipCodeMask'
import { IOrganization } from '../Definitions'
import { IUser } from '../Definitions'
import { IRole } from '../roles/Roles'
import { IStores } from '../Stores'
import States from '../utils/States'
import * as Yup from 'yup'
import UserDialogLogic from './UserDialogLogic'

interface IUserFormValues {
  firstName: string
  lastName: string
  phoneNumber: string
  directNumber: string
  tollFreeNumber: string
  phoneExtension: string
  jobTitle: string
  userName: string
  email: string
  roles: string[]
  organizationId: string
  address: string
  city: string
  state: string
  zipCode: string
  profileImageContent: string
  profileImageMimeType: string
  profileImageName: string
  profileImageUrl: string
}

const styles = () =>
  createStyles({
    fileInput: { display: 'none' },
    profileImage: {
      border: 'rgba(0,0,0,0.25) 1px solid',
      height: 60,
      marginRight: 20,
      width: 60,
    },
  })
const UserSchema = Yup.object().shape({
  directNumber: Yup.string(),
  email: Yup.string().email('Not a valid email').required('Email is Required'),
  firstName: Yup.string().required('First Name is Required'),
  lastName: Yup.string().required('Last Name is Required'),
  organizationId: Yup.string().required('Organization is Required'),
  phoneExtension: Yup.string(),
  tollFreeNumber: Yup.string(),
  userName: Yup.string().required('Username is Required'),
})

type ISetFieldValue = (field: string, value: any) => void

export interface IUserDialogProps extends WithStyles<typeof styles> {
  checkUsernameAvailability?: (user: IUser) => Promise<boolean>
  checkEmailAvailability?: (user: IUser) => Promise<boolean>
  close?: () => void
  currentUser?: IUser
  getOrganizations?: () => void
  getRoles?: (filter?: string) => void
  isAdmin?: boolean
  isLoading?: boolean
  isOpen?: boolean
  organizations?: IOrganization[]
  roles?: IRole[]
  saveUser?: (user: IUser, imageHasChanged: boolean) => Promise<void>
  user?: IUser
}

class UserDialog extends React.Component<IUserDialogProps> {
  private _logic: UserDialogLogic = new UserDialogLogic()
  public isCancelled: boolean
  public state = {
    imageHasChanged: false,
    isFileLoading: false,
  }
  public upload: HTMLInputElement | null

  public debouncedValidateUsernameAvailability = AwesomeDebouncePromise(
    (value: IUser) => {
      return this.props.checkUsernameAvailability!(value).then((data: boolean) => {
        if (!data) {
          throw 'Username Already In Use'
        }
      })
    },
    1000
  )

  public debouncedValidateEmailAvailability = AwesomeDebouncePromise((value: IUser) => {
    return this.props.checkEmailAvailability!(value).then((data: boolean) => {
      if (!data) {
        throw 'Email Already In Use'
      }
    })
  }, 500)

  public handleEmailChange = (email: string) => {
    if (email !== '') {
      const userDto = this.props.user!
      userDto.email = email
      return this.debouncedValidateEmailAvailability(userDto)
    }
  }

  public handleUsernameChange = (username: string) => {
    if (username !== '') {
      const userDto = this.props.user!
      userDto.userName = username
      return this.debouncedValidateUsernameAvailability(userDto)
    }
  }

  public componentDidMount() {
    this.props.getOrganizations!()
    this.props.getRoles!()
  }

  public save = (
    values: IUserFormValues,
    { setSubmitting }: FormikActions<IUserFormValues>
  ) => {
    const userToSave = { ...values } as IUser
    userToSave.state = userToSave.state
      ? (userToSave.state as unknown as { name: string }).name
      : ''
    delete userToSave.organization
    this.props.saveUser!(userToSave, this.state.imageHasChanged).then(() => {
      setSubmitting(false)
    })
  }

  public handleOnButtonClick = (target: UserDialog) => {
    return () => target.upload && target.upload.click()
  }

  public handleFileChange =
    (setFieldValue: ISetFieldValue) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onloadstart = () => {
          this.setState({ isFileLoading: true })
        }
        reader.onload = () => {
          if (reader) {
            const imageContent = (reader.result! as string).split(',')[1]
            const imageMimeType = file.type.split('/')[1]
            const imageName = file.name
            const imageUrl = reader.result
            this.setState({
              imageHasChanged: true,
              isFileLoading: false,
            })

            setFieldValue('profileImageContent', imageContent)
            setFieldValue('profileImageMimeType', imageMimeType)
            setFieldValue('profileImageName', imageName)
            setFieldValue('profileImageUrl', imageUrl)
          }
        }
        reader.readAsDataURL(file)
      }
    }

  public renderRolesText = (x: string[]) => x.join(', ')

  private getInitialState(name: string) {
    return _.find(States, (x: { name: string }) => x.name === name)
  }

  private getSelectedState(values: IUserFormValues) {
    return _.find(States, (x: { name: string }) => x.name === values.state)
  }

  public getStateName(state: { name: string }) {
    return state ? state.name : ''
  }

  public getOrganizationName(organization: IOrganization) {
    return organization ? organization.name : ''
  }

  public getOrganizationValue(organization: IOrganization) {
    return organization ? organization.id : ''
  }

  public getRoleName(role: IRole) {
    return role ? role.name : ''
  }

  public getUserOrganizationName(user: IUser) {
    if (user.organization) {
      return this.getOrganizationName({
        ...user.organization,
        description: '',
      } as IOrganization)
    }
    return undefined
  }

  public render() {
    const { user, classes, close, isAdmin, isLoading, isOpen, roles, organizations } =
      this.props
    const initialValues = { ...user } as IUserFormValues
    const stateInitialValue = initialValues.state
    const hasValue = (field: string, values: IUserFormValues) => {
      const value = getIn(values, field)

      return !!value
    }

    return (
      <ESDialog
        close={close!}
        initialValues={initialValues}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={user!.isNew ? 'Add New User' : 'Edit User'}
        titleIcon={<AccountBox />}
        validationSchema={UserSchema}
      >
        {({ errors, isSubmitting, setFieldValue, values }) => (
          <Grid container spacing={3}>
            <Grid item container direction="row" alignItems="center" xs={12}>
              <Grid item>
                <Avatar src={values.profileImageUrl} className={classes.profileImage} />
              </Grid>
              <Grid item>
                <Button
                  component="label"
                  disabled={isSubmitting}
                  isLoading={this.state.isFileLoading}
                  onClick={this.handleOnButtonClick(this)}
                  variant="outlined"
                >
                  Change
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  className={classes.fileInput}
                  onChange={this.handleFileChange(setFieldValue)}
                  ref={(ref) => (this.upload = ref)}
                  style={{ display: 'none' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FullWidthField
                autoFocus
                label="First Name"
                name="firstName"
                required
                InputLabelProps={{
                  shrink: hasValue('firstName', values),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FullWidthField
                label="Last Name"
                name="lastName"
                required
                InputLabelProps={{
                  shrink: hasValue('lastName', values),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FullWidthField
                label="Job Title"
                name="jobTitle"
                InputLabelProps={{
                  shrink: hasValue('jobTitle', values),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FullWidthField
                label="Direct Phone Number"
                name="directNumber"
                InputProps={{ inputComponent: PhoneNumberMask }}
                InputLabelProps={{
                  shrink: hasValue('directNumber', values),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FullWidthField
                label="Toll-Free Phone Number"
                name="tollFreeNumber"
                InputProps={{ inputComponent: PhoneNumberMask }}
                InputLabelProps={{
                  shrink: hasValue('tollFreeNumber', values),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FullWidthField
                label="Extension"
                name="phoneExtension"
                InputLabelProps={{
                  shrink: hasValue('phoneExtension', values),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FullWidthField
                label="Username"
                name="userName"
                validate={this.handleUsernameChange}
                required
                InputLabelProps={{
                  shrink: hasValue('userName', values),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FullWidthField
                label="Email"
                name="email"
                validate={this.handleEmailChange}
                required
                InputLabelProps={{
                  shrink: hasValue('email', values),
                }}
                variant="outlined"
              />
            </Grid>
            {this.renderRolesField(isAdmin, roles, values)}
            {this.renderOrganizationField(
              isAdmin,
              organizations,
              errors,
              values.organizationId,
              initialValues.organizationId,
              setFieldValue
            )}
            <Grid item xs={12}>
              <FullWidthField
                label="Address"
                name="address"
                InputLabelProps={{
                  shrink: hasValue('address', values),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FullWidthField
                label="City"
                name="city"
                InputLabelProps={{
                  shrink: hasValue('city', values),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TypeAheadField
                label="State"
                name="state"
                inputId="state"
                fullWidth
                setFieldValue={setFieldValue}
                items={States}
                initialValue={this.getInitialState(stateInitialValue)}
                selectedValue={this.getSelectedState(values)}
                getName={this.getStateName}
                getValue={this.getStateName}
                labelShrink={hasValue('state', values)}
                outlined
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FullWidthField
                label="Zip Code"
                name="zipCode"
                InputProps={{ inputComponent: ZipCodeMask }}
                InputLabelProps={{
                  shrink: hasValue('zipCode', values),
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }

  private renderOrganizationField(
    isAdmin: boolean | undefined,
    organizations: IOrganization[] | undefined,
    errors: FormikErrors<IUserFormValues>,
    selectedOrganizationId: string,
    initialOrganizationId: string,
    setFieldValue: (field: string, value: any) => void
  ) {
    if (!isAdmin) {
      return null
    }

    const initialOrganization = this._logic.getOrganizationFromOrganizationsById(
      organizations,
      initialOrganizationId
    )
    const selectedOrganization = this._logic.getOrganizationFromOrganizationsById(
      organizations,
      selectedOrganizationId
    )

    const setOrganization = (_name: string, value: any) => {
      const newValue = value !== undefined ? value.id : undefined
      setFieldValue('organization', value)
      setFieldValue('organizationId', newValue)
    }

    return (
      <Grid item xs={12}>
        {organizations && (
          <TypeAheadField
            label="Organization"
            inputId="organizationId"
            name="organization"
            cypressLabel="organizations"
            childCypressLabel="organization"
            required
            fullWidth
            errorMessage={errors.organizationId}
            initialValue={initialOrganization}
            selectedValue={selectedOrganization}
            items={organizations}
            getName={this.getOrganizationName}
            getValue={this.getOrganizationValue}
            setFieldValue={setOrganization}
            outlined
          />
        )}
      </Grid>
    )
  }

  private renderRolesField(
    isAdmin: boolean | undefined,
    roles: IRole[] | undefined,
    values: IUserFormValues
  ) {
    if (isAdmin) {
      const newRoles = roles!.map(this.getRoleName)
      const id = (x: string) => x
      return (
        <Grid item xs={12}>
          <SelectField
            multiple
            fullWidth
            name="roles"
            inputId="roles"
            label="Roles (Select all that apply)"
            items={newRoles}
            checkedItems={values.roles}
            getName={id}
            getValue={id}
          />
        </Grid>
      )
    }
    return null
  }
}

const InjectedUserDialog = inject<
  IStores,
  IUserDialogProps,
  Partial<IUserDialogProps>,
  any
>((stores: IStores) => ({
  checkEmailAvailability: stores.users.checkEmailAvailability,
  checkUsernameAvailability: stores.users.checkUsernameAvailability,
  close: stores.users.closeDialog,
  currentUser: stores.global.user,
  getOrganizations: stores.organizations.getAllOrganizations,
  getRoles: stores.roles.getAllRoles,
  isAdmin: stores.global.isAdmin,
  isLoading: stores.users.isLoading,
  isOpen: stores.users.isModalOpen,
  organizations: stores.organizations.organizations,
  roles: stores.roles.roles,
  saveUser: stores.users.saveUser,
  user: stores.users.selectedUser,
}))(withStyles(styles)(UserDialog))

export default InjectedUserDialog
