import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import { DefaultEmployer, IEmployer } from '../../Definitions'
import {
  Client,
  EmployerDto,
  IEmployerDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'EmployerDto'

export default class EmployersStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public employers: IEmployer[]
  @observable
  public selectedEmployer?: IEmployer
  public dataTableStore: DataTableStore<IEmployerDto, IEmployer>

  constructor(
    private globalStore: GlobalStore,
    private client: Client,
    private routerStore: RouterStore
  ) {
    this.dataTableStore = new DataTableStore<IEmployerDto, IEmployer>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllEmployers(filter, page, undefined, orderBy, includeInactives),
      (employer) => this.setupEmployers(employer)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedEmployer = undefined
  }

  @action.bound
  public disableEmployer(Employer: IEmployer) {
    this.isLoading = true
    return this.client
      .archiveEmployer(Employer.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndEmployers() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllEmployers(filter: string) {
    this.isLoading = true
    return this.client
      .getAllEmployers(filter, undefined, undefined, undefined, true)
      .then((resp: SwaggerResponse<IEmployerDto[]>) =>
        runInAction(() => {
          this.employers = this.setupEmployers(resp.result)
        })
      )
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public editEmployer(employer: IEmployer) {
    this.selectedEmployer = employer
    this.routerStore.push(`/admin/employers/${employer.id}`)
  }

  @action.bound
  public createEmployer() {
    this.selectedEmployer = DefaultEmployer(true)
    this.routerStore.push(`/admin/employers/create`)
  }

  @action.bound
  public employerEditFinished() {
    this.routerStore.push(`/admin/employers/`)
  }

  @action.bound
  public recoverEmployer(employer: IEmployer) {
    this.isLoading = true
    return this.client
      .reactivateEmployer(employer.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveEmployer(employer: IEmployer) {
    this.isLoading = true
    let promise
    const employerDto = new EmployerDto(employer)
    if (employer.isNew) {
      promise = this.client.createEmployer(employerDto)
    } else {
      promise = this.client.updateEmployer(employer.id, employerDto)
    }
    return promise
      .then(() => {
        this.resetUIState(employer.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public loadEmployer(id?: string): Promise<void> {
    if (id === undefined) {
      this.selectedEmployer = DefaultEmployer(true)
      return Promise.resolve()
    }
    if (this.selectedEmployer && this.selectedEmployer.id === id) {
      return Promise.resolve()
    }
    return this.client.getEmployerById(id).then(({ result }) =>
      runInAction(() => {
        this.selectedEmployer = this.addDefaultFields(result)
      })
    )
  }

  @action.bound
  public clearSelectedEmployer() {
    this.selectedEmployer = undefined
  }

  private setupEmployers = (employers: IEmployerDto[]) => {
    return employers.map((x) => this.setupEmployerMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (employer: IEmployerDto): IEmployer => {
    return { ...DefaultEmployer(), ...employer }
  }

  private setupEmployerMenuItems = (employer: IEmployer) => {
    employer.menuItems = []
    employer.menuItems.push({
      icon: Edit,
      isConfirm: false,
      name: 'Edit',
      onClick: () => this.editEmployer(employer),
    })
    if (employer.isActive) {
      employer.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableEmployer(employer),
      })
    } else {
      employer.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverEmployer(employer),
      })
    }
    return employer
  }
}
