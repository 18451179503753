import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { AttachMoney } from '@material-ui/icons'
import { ContentCopy } from 'mdi-material-ui'
import { inject } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { getBilledOutAmountHistory } from 'src/services/LookupService'
import { formatCurrency } from 'src/utils/Formatter'
import { IClaimFormValues } from 'src/ReceiveClaim/ClaimFormValues'
import ReactPaginate from 'react-paginate'
import { ClaimLineItemLookupRequest } from 'src/viewModels/ClaimLineItemLookupRequest'
import { BilledOutLineItemSummaryResult } from 'src/viewModels/BilledOutLineItemSummaryResult'
import { FormikProps } from 'formik'

interface ILineItemBilledOutModalProps {
  close: () => void
  isOpen?: boolean
  index?: number
  createBilloutFormikProps: FormikProps<IClaimFormValues>
  providerTypeId?: string
  providerId: string
  providerGroup: string
  hideButton?: boolean
}

class LineItemBilledOutModal extends React.Component<ILineItemBilledOutModalProps> {
  public state = {
    gridData: new Array<BilledOutLineItemSummaryResult>(),
    pageSize: 5,
    page: 1,
    pageCount: 1,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    loading: true,
  }

  public async componentDidMount(): Promise<void> {
    this.getData()
  }

  public async getData() {
    this.setState({ loading: true })
    if (
      this.props.providerTypeId &&
      this.props.index != undefined &&
      this.props.createBilloutFormikProps.values.q24 != undefined &&
      this.props.providerId
    ) {
      var data = new ClaimLineItemLookupRequest()
      data.providerGroupId = this.props.providerId
      data.cptCode =
        this.props.createBilloutFormikProps.values.q24?.at(this.props.index!)
          ?.dProcedureCptHcpcs ?? ''
      data.providerTypeId = this.props.providerTypeId
      data.page = this.state.page
      data.pageSize = this.state.pageSize
      await getBilledOutAmountHistory(data).then((response: any) => {
        this.setState({
          pageCount: response.totalPages,
          totalItems: response.totalItems,
          gridData: response.items,
          loading: false,
        })

        if (response.totalItems > 0) {
          this.setPaginationOffsetData()
        }
      })
    } else {
      this.setState({ loading: false })
    }
  }

  private close = () => {
    this.props.close!()
  }

  private copy = (paidAmount: number) => {
    var lineItem = this.props.createBilloutFormikProps.values.q24?.at(this.props.index!)

    if (lineItem) {
      this.props.createBilloutFormikProps.setFieldValue(
        `q24.${this.props.index!}.${'billOutAmount'}`,
        +paidAmount.toFixed(2)
      )
    }

    this.close()
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems === 0
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getData())
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private mapLogGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={10} align="center">
            No previous payments
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          <TableCell>
            {record.episodeOfCareNumber}-{record.groupNumber}
          </TableCell>
          <TableCell>{record.insuranceCompany}</TableCell>
          <TableCell>{record.placeOfService}</TableCell>
          <TableCell>{record.cptCode}</TableCell>
          <TableCell>{record.modifiers}</TableCell>
          <TableCell align="right">{record.quantity}</TableCell>
          <TableCell align="right">
            {moment(record.dateBilledOut).format('MM/DD/YYYY')}
          </TableCell>
          <TableCell align="right">
            {formatCurrency(record.billedOutAmount.toString())}
          </TableCell>
          {this.props.hideButton ? (
            <></>
          ) : (
            <TableCell>
              <ContentCopy
                color="primary"
                style={{ cursor: 'pointer' }}
                onClick={() => this.copy(record.billedOutAmount)}
              />
            </TableCell>
          )}
        </TableRow>
      ))
    }
  }

  public render() {
    const { isOpen, index } = this.props

    return (
      <Dialog key={index} fullWidth={true} maxWidth="lg" open={isOpen!}>
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title={`Billed Out Amount History:  ${this.props.providerGroup}`}
            pageIcon={<AttachMoney />}
            isModal={true}
          />
        </DialogTitle>
        {this.state.loading && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <DialogContent>
          {this.props.providerGroup == 'UNKNOWN' ? (
            <Grid xs={12}>
              <Typography
                noWrap
                style={{ textAlign: 'center', paddingTop: '10px' }}
                color="error"
              >
                Provider Group is unknown, pricing information is not available
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="gridPadding">
                    <TableCell>EOC-Group#</TableCell>
                    <TableCell>Ins Company</TableCell>
                    <TableCell>Place of Service</TableCell>
                    <TableCell>CPT</TableCell>
                    <TableCell>Modifiers</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Date Paid</TableCell>
                    <TableCell align="right">Amount Paid</TableCell>
                    {this.props.hideButton ? <></> : <TableCell />}
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: '100%' }}>{this.mapLogGridData()}</TableBody>
              </Table>
              <div className="pagination-row">
                {this.paginationInfo()}
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  onPageChange={this.handlePageChange}
                  forcePage={this.state.page - 1}
                  pageCount={this.state.pageCount}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  //@ts-ignore
                  renderOnZeroPageCount={null}
                />
              </div>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const InjectedILineItemBilledOutModal = inject<
  IStores,
  ILineItemBilledOutModalProps,
  Partial<ILineItemBilledOutModalProps>,
  any
>(() => ({}))(LineItemBilledOutModal)

export default InjectedILineItemBilledOutModal
