export interface IInboxActionSummary {
  id: number
  actionName: string
  extendedDescription: string
  staticActionId: number | undefined
  hasCustomForm: boolean
  customFormName: string | undefined
  outOfWorkflow: boolean
  statusId?: number
  statusText?: string
  requireComment: boolean
  displayComment: boolean
  closeAfterProcessing: boolean
  excludeFromMainActionList: boolean
  uiGroupId: number | undefined
  uiGroupButtonText: string | undefined
  nextInboxId?: number
  nextInboxText?: string | undefined
}

export class InboxActionSummary implements IInboxActionSummary {
  public id: number
  public actionName: string
  public extendedDescription: string
  public staticActionId: number | undefined
  public hasCustomForm: boolean
  public customFormName: string | undefined
  public outOfWorkflow: boolean
  public statusId?: number
  public statusText?: string
  public requireComment: boolean
  public displayComment: boolean
  public closeAfterProcessing: boolean
  public excludeFromMainActionList: boolean
  public uiGroupId: number | undefined
  public uiGroupButtonText: string | undefined
  public nextInboxId?: number
  public nextInboxText?: string | undefined
}
