import moment from 'moment'
import { DefaultSchedule, ISchedule } from '../../Definitions'
import {
  IProviderDto,
  ScheduleAppointmentTaskDto,
  ScheduleProviderLocationDto,
  ScheduleTestDto,
} from '../../generated_client'

export interface IScheduleFormValues {
  appointmentConfirmationComment?: string
  appointmentDate?: Date
  appointmentDateInput?: string
  appointmentTasks: ScheduleAppointmentTaskDto[]
  appointmentTime?: string
  authorizationComment?: string
  comments: string
  componentId: string
  readonly id: string
  isActive: boolean
  isLocationRequired: boolean
  isTBD: boolean
  locationId: string
  name: string
  provider?: IProviderDto
  providerId: string
  testIds?: string[]
  physicianId?: string | undefined
}

function setDefaultValues(defaultComponentId?: string): IScheduleFormValues {
  return {
    appointmentConfirmationComment: '',
    appointmentDate: undefined,
    appointmentDateInput: undefined,
    appointmentTasks: [] as ScheduleAppointmentTaskDto[],
    appointmentTime: undefined,
    authorizationComment: '',
    comments: '',
    componentId: defaultComponentId || '',
    id: '',
    isActive: true,
    isLocationRequired: true,
    isTBD: false,
    locationId: '',
    name: '',
    physicianId: undefined,
    provider: undefined,
    providerId: '',
    testIds: [] as string[],
  }
}

function setValuesFromSchedule(schedule: ISchedule): IScheduleFormValues {
  const mappedTestIds =
    schedule && schedule.scheduleTests && schedule.scheduleTests.length > 0
      ? (schedule.scheduleTests.map((x) => x.testId) as string[])
      : []

  return {
    appointmentConfirmationComment: schedule.appointmentConfirmationComment || '',
    appointmentDate: schedule.appointmentDate || undefined,
    appointmentDateInput: schedule.appointmentDate
      ? moment(schedule.appointmentDate.toString()).format('YYYY-MM-DD')
      : undefined,
    appointmentTasks: schedule.appointmentTasks || [],
    appointmentTime:
      schedule.appointmentTime !== undefined
        ? moment(schedule.appointmentTime, 'hh:mm A').format('HH:mm:ss')
        : undefined,
    authorizationComment: schedule.authorizationComment || '',
    comments: schedule.comments || '',
    componentId: schedule.componentId || '',
    id: schedule.id || '',
    isActive: schedule.isActive,
    isLocationRequired: !schedule.scheduleProviderLocations!.filter(
      (x) => x.isPrimaryLocation
    )[0]!.locationId,
    isTBD: schedule.isTBD || false,
    locationId:
      schedule.scheduleProviderLocations!.filter((x) => x.isPrimaryLocation)[0]!
        .locationId || '',
    name: schedule.name || '',
    physicianId: schedule.physicianId,
    providerId:
      schedule.scheduleProviderLocations!.filter((x) => x.isPrimaryLocation)[0]!.location!
        .providerId || '',
    testIds: mappedTestIds,
  }
}

export function createIScheduleFormValues(
  schedule?: ISchedule,
  defaultComponentId?: string
): IScheduleFormValues {
  if (schedule === undefined || schedule.isNew) {
    return setDefaultValues(defaultComponentId)
  } else {
    return setValuesFromSchedule(schedule)
  }
}

//

export function toISchedule(
  formValues: IScheduleFormValues,
  original?: ISchedule
): ISchedule {
  const schedule = original === undefined ? DefaultSchedule() : original

  const scheduleTests: ScheduleTestDto[] = formValues.testIds!.map(
    (x) =>
      new ScheduleTestDto({
        testId: x,
      })
  )
  const scheduleProviderLocations: ScheduleProviderLocationDto[] = schedule
    .scheduleProviderLocations!.filter(
      (x) => x.isPrimaryLocation === false && x.locationId !== formValues.locationId
    )
    .map(
      (x) =>
        new ScheduleProviderLocationDto({
          isActive: x.isActive,
          isPrimaryLocation: x.isPrimaryLocation,
          location: x.location,
          locationId: x.locationId,
        })
    )
  scheduleProviderLocations.push(
    new ScheduleProviderLocationDto({
      isActive: true,
      isPrimaryLocation: true,
      locationId: formValues.locationId,
    })
  )
  return {
    ...schedule,
    appointmentConfirmationComment: formValues.appointmentConfirmationComment || '',
    appointmentDate: formValues.appointmentDateInput
      ? new Date(moment(formValues.appointmentDateInput, 'YYYY-MM-DD').toISOString())
      : undefined,
    appointmentTime: formValues.appointmentTime
      ? moment(formValues.appointmentTime, 'HH:mm').format('hh:mm A')
      : undefined,
    authorizationComment: formValues.authorizationComment || '',
    comments: formValues.comments || '',
    componentId: formValues.componentId || '',
    isActive: formValues.isActive,
    isLocationRequired: formValues.isLocationRequired,
    isTBD: formValues.isTBD || false,
    name: formValues.name || '',
    physicianId: formValues.physicianId,
    scheduleProviderLocations,
    scheduleTests,
  }
}
