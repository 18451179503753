import * as Yup from 'yup'
import { noWhitespaceString } from '../common/YupExtensions'
import { DefaultAppointmentTask, IAppointmentTask } from '../Definitions'

export const ActiveAppointmentTaskSchema = Yup.object().shape({
  days: Yup.number()
    .min(0, 'Number of days must be larger than 0')
    .typeError('Days field must be a valid number')
    .integer('Days field must contain a whole number'),
  displayOrder: Yup.number().min(1, 'Display Order must be at least 1'),
  name: noWhitespaceString().required().label('Task Name'),
  priority: noWhitespaceString().required().label('Priority'),
})

export const InactiveAppointmentTaskSchema = Yup.object().shape({
  days: Yup.number()
    .min(0, 'Number of days must be larger than 0')
    .typeError('Days field must be a valid number')
    .integer('Days field must contain a whole number'),
  displayOrder: Yup.number(),
  name: noWhitespaceString().required().label('Task Name'),
  priority: noWhitespaceString().required().label('Priority'),
})

export interface IAppointmentTaskFormValues {
  authorizationType: string
  days: number
  displayOrder: number
  readonly id: string
  isActive: boolean
  name: string
  priority: number
  requiresApproval: boolean
  timing: string
}

function setDefaultValues(): IAppointmentTaskFormValues {
  return {
    authorizationType: '',
    days: 0,
    displayOrder: 0,
    id: '',
    isActive: true,
    name: '',
    priority: 0,
    requiresApproval: false,
    timing: 'Before Appointment Date',
  }
}

function setValuesFromAppointmentTask(
  appointmentTask: IAppointmentTask,
  displayOrder?: number
): IAppointmentTaskFormValues {
  return {
    authorizationType: appointmentTask.authorizationType || '',
    days: appointmentTask.days || 0,
    displayOrder: appointmentTask.displayOrder || displayOrder || 1,
    id: appointmentTask.id || '',
    isActive: appointmentTask.isActive,
    name: appointmentTask.name || '',
    priority: appointmentTask.priority || 0,
    requiresApproval: appointmentTask.requiresApproval,
    timing: appointmentTask.timing || '',
  }
}

export function createIAppointmentTaskFormValues(
  appointmentTask?: IAppointmentTask,
  displayOrder?: number
) {
  if (appointmentTask) {
    return setValuesFromAppointmentTask(appointmentTask, displayOrder)
  } else {
    return setDefaultValues()
  }
}

export function toAppointmentTask(
  formValues: IAppointmentTaskFormValues,
  original?: IAppointmentTask
): IAppointmentTask {
  const appointmentTask = original === undefined ? DefaultAppointmentTask() : original
  return {
    ...appointmentTask,
    authorizationType: formValues.authorizationType,
    days: formValues.days,
    displayOrder: formValues.displayOrder,
    id: formValues.id,
    isActive: formValues.isActive,
    name: formValues.name,
    priority: formValues.priority,
    requiresApproval: formValues.requiresApproval,
    timing: formValues.timing,
  }
}
