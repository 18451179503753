import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from 'src/Stores'
import WorkflowInbox from './WorkflowInbox'
import { Box, Button, Typography, WithStyles } from '@material-ui/core'
import { styles } from 'material-ui-pickers/wrappers/InlineWrapper'
import { RouteComponentProps } from 'react-router-dom'
import { IEpisodeOfCare, ICMS1500, ILocation, ISchedule } from 'src/Definitions'
import { IProviderDto, SwaggerResponse } from 'src/generated_client'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import { Receipt, Warning } from '@material-ui/icons'
import {
  getBilloutInboxItem,
  getDefaultBilloutInboxItem,
} from 'src/services/WorkflowService'
import { BillOutInboxItem } from 'src/viewModels/workflow/BillOutInboxItem'
import { InboxType } from 'src/utils/InboxType'
import ViewClaim from 'src/viewClaim/viewClaim'
import createDocumentUrl from 'src/utils/createDocumentUrl'

interface IBillOutInboxParams {
  claimId: string
  inboxId: string
}

class ReceiveClaimParams {
  episodeOfCareId: string
  quoteId: string
  claimId: string
  appealType: string
}

class matchParams {
  params: ReceiveClaimParams
  isExact: boolean
  path: string
  url: string
}

interface IBillOutInboxProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IBillOutInboxParams> {
  closeModal?: () => void
  episodeOfCare?: IEpisodeOfCare
  isLoading?: boolean
  isModalOpen?: boolean
  isCMS1500Loading?: boolean
  isCurrentOrganizationSurgical?: boolean
  isEpisodeOfCareLoading?: boolean
  isQuoteLoading?: boolean
  loadEpisodeOfCare?: (episodeOfCareId: string) => Promise<void>
  loadClaim?: (claimId: string) => Promise<void>
  rerouteToPath?: (path: string) => void
  saveCMS1500?: (cms1500: ICMS1500) => Promise<SwaggerResponse<void>>
  selectedCMS1500?: ICMS1500
  selectedEpisodeOfCare?: IEpisodeOfCare
  selectedLocation?: ILocation
  selectedSchedule?: ISchedule
  setSelectedCMS1500?: (cms1500: ICMS1500 | undefined) => void
  setSelectedEpisodeOfCare?: (episodeOfCare: IEpisodeOfCare | undefined) => void
  setSelectedLocation?: (location?: ILocation) => void
  setSelectedSchedule?: (schedule?: ISchedule) => void
  checkDuplicateBills?: (cms1500: ICMS1500) => Promise<void>
  duplicateCMS1500?: ICMS1500
  openModal?: () => void
  scheduleItems?: DropdownOption[]
  locationItems?: DropdownOption[]
  setSelectedScheduleById?: (id: string) => Promise<void>
  getScheduledOptions?: () => void
  openDialogWithSchedule?: (schedule: ISchedule | undefined) => void
  openProviderLookupDialog?: () => void
  getLocationById?: (locationId: string) => void
  isProviderLookupDialogOpen?: boolean
  showContractModal?: (provider: IProviderDto) => void
  setReturn?: () => void
  closeTab?: () => void
  setUpdateParentAfterStaticAction: (parentfunction?: () => void) => void
}

class BillOutInbox extends React.Component<IBillOutInboxProps> {
  public state = {
    headerInfo: undefined as unknown as BillOutInboxItem,
    isLoading: true,
    eocId: '',
    billoutData: '',
  }

  public async componentDidMount(): Promise<void> {
    //claimOrBilloutId
    if (this.props.match.params.claimId && this.props.match.params.inboxId) {
      await getBilloutInboxItem(
        this.props.match.params.claimId,
        this.props.match.params.inboxId
      )
        .then(async (response: any) => {
          var url = response.associatedBillOutPdfData
            ? createDocumentUrl(
                response.associatedBillOutPdfData.toString(),
                'application/pdf'
              )
            : ''
          this.setState({
            billoutData: url,
            headerInfo: response,
            eocId: response.associatedEpisodeOfCareId,
            isLoading: false,
          })
        })
        .catch(() => {
          this.setState({ isLoading: false })
        })
    } else if (this.props.match.params.claimId) {
      await getDefaultBilloutInboxItem(this.props.match.params.claimId)
        .then(async (response: any) => {
          var url = response.associatedBillOutPdfData
            ? createDocumentUrl(
                response.associatedBillOutPdfData.toString(),
                'application/pdf'
              )
            : ''
          this.setState({
            billoutData: url,
            headerInfo: response,
            eocId: response.associatedEpisodeOfCareId,
            isLoading: false,
          })
        })
        .catch(() => {
          this.setState({ isLoading: false })
        })
    }
  }

  public renderPdf() {
    return (
      <div style={{ width: '100%' }}>
        <Box display="flex" p={1} style={{ padding: 0 }}>
          <Box p={1} flexGrow={1}>
            {this.state.headerInfo.printAlert && (
              <Typography variant="h5" color="error">
                <Warning color="error"></Warning>&nbsp;&nbsp;
                {this.state.headerInfo.printAlert}
              </Typography>
            )}
          </Box>
          <Box p={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(
                  `/workflow/claim/${this.state.headerInfo.claimId}/default-inbox`
                )
              }
            >
              View Original Claim
            </Button>
          </Box>
          <Box p={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(this.state.billoutData, 'new', 'width=1200,height=800')
              }
            >
              View in New Window
            </Button>
          </Box>
        </Box>
        <iframe
          src={this.state.billoutData}
          height="600px"
          width="100%"
          style={{ border: 'none' }}
        />
      </div>
    )
  }

  public renderBillOut() {
    var match = new matchParams()
    match.params = new ReceiveClaimParams()
    match.params.episodeOfCareId = this.state.eocId
    match.params.claimId = this.props.match.params.claimId

    if (this.state.headerInfo.isIncoming) {
      return (
        <ViewClaim
          loadClaim={this.props.loadClaim}
          loadEpisodeOfCare={this.props.loadEpisodeOfCare}
          selectedLocation={this.props.selectedLocation}
          selectedSchedule={this.props.selectedSchedule}
          setSelectedCMS1500={this.props.setSelectedCMS1500}
          setSelectedEpisodeOfCare={this.props.setSelectedEpisodeOfCare}
          setSelectedLocation={this.props.setSelectedLocation}
          setSelectedSchedule={this.props.setSelectedSchedule}
          showContractModal={this.props.showContractModal}
          setReturn={this.props.setReturn}
          closeTab={this.props.closeTab}
          history={this.props.history}
          location={this.props.location}
          match={match}
          workflowStyling={true}
          billoutButton={
            this.state.headerInfo.isIncoming ? (
              <></>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: '8px' }}
                onClick={() =>
                  window.open(
                    `/workflow/claim/${this.state.headerInfo.claimId}/default-inbox`
                  )
                }
              >
                View Original Claim
              </Button>
            )
          }
        />
      )
    } else {
      return this.renderPdf()
    }
  }

  public render() {
    return this.state.eocId ? (
      <WorkflowInbox
        inboxType={InboxType.Billout}
        item={this.renderBillOut()}
        headerInfo={this.state.headerInfo}
        icon={<Receipt />}
      />
    ) : (
      <></>
    )
  }
}

const InjectedBillOutInbox = inject<
  IStores,
  IBillOutInboxProps,
  Partial<IBillOutInboxProps>,
  any
>((stores: IStores) => ({
  checkDuplicateBills: stores.cms1500.checkDuplicateBills,
  closeModal: stores.cms1500.closeDialog,
  duplicateCMS1500: stores.cms1500.duplicateCMS1500,
  episodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  getProviderSuggestions: stores.quotes.getProviderSuggestions,
  getTypesByCategory: stores.quotes.getTypesByCategory,
  isCMS1500Loading: stores.cms1500.isLoading,
  isCurrentOrganizationSurgical: stores.global.isCurrentOrganizationSurgical,
  isEpisodeOfCareLoading: stores.patientEdit.isLoading,
  isLoading: stores.quotes.isLoading,
  isModalOpen: stores.cms1500.isModalOpen,
  loadClaim: stores.cms1500.loadCMS1500,
  loadEpisodeOfCare: stores.patientEdit.getEpisodeOfCareById,
  openModal: stores.cms1500.openDialog,
  providers: stores.quotes.providers,
  ptTypes: stores.quotes.ptTypes,
  rerouteToPath: stores.global.rerouteToPath,
  saveCMS1500: stores.cms1500.saveCMS1500,
  selectedCMS1500: stores.cms1500.selectedCMS1500,
  selectedEpisodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  selectedLocation: stores.locations.selectedEditClaimLocation,
  selectedSchedule: stores.schedules.selectedSchedule,
  setSelectedCMS1500: stores.cms1500.setSelectedCMS1500,
  setSelectedEpisodeOfCare: stores.patientEdit.setSelectedEpisodeOfCare,
  setSelectedLocation: stores.locations.setSelectedEditClaimLocation,
  setSelectedSchedule: stores.schedules.setSelectedSchedule,
  scheduleItems: stores.patientEdit.scheduleItems,
  locationItems: stores.schedules.scheduleItems,
  setSelectedScheduleById: stores.schedules.setSelectedScheduleById,
  getScheduledOptions: stores.patientEdit.getScheduledOptions,
  openDialogWithSchedule: stores.schedules.openDialogWithSchedule,
  openProviderLookupDialog: stores.schedules.openClaimProviderLookupDialog,
  getLocationById: stores.locations.getLocationById,
  isProviderLookupDialogOpen: stores.schedules.isClaimProviderLookupDialogOpen,
  showContractModal: stores.cms1500.openProviderGroupContractDialog,
  setReturn: stores.global.setReturn,
  closeTab: stores.global.closeTab,
  setUpdateParentAfterStaticAction: stores.cms1500.setGetClaimValuesForStaticAction,
}))(BillOutInbox)

export default InjectedBillOutInbox
