import GoogleMapReact, { Coords } from 'google-map-react'
import { inject } from 'mobx-react'
import * as React from 'react'
import { ILocation } from '../Definitions'
import { AddressDto } from '../generated_client'
import { IStores } from '../Stores'

interface ILocationLookupMapContainerProps {
  selectedAddress?: AddressDto
  location?: ILocation
}

const Marker = ({ text }: any) => (
  <div
    style={{
      backgroundColor: '#ff791a',
      border: '2px solid #000',
      borderRadius: '100%',
      height: '21px',
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '21px',
      zIndex: 1,
    }}
  >
    <span
      style={{
        paddingLeft: '23px',
        marginBottom: '12px',
        fontSize: '12px',
        fontStyle: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        position: 'relative',
        top: '0px',
      }}
    >
      {text}
    </span>
  </div>
)

class LocationLookupMapContainer extends React.Component<ILocationLookupMapContainerProps> {
  private center = () => {
    if (this.props.selectedAddress) {
      return {
        lat: this.props.selectedAddress.latitude,
        lng: this.props.selectedAddress.longitude,
      } as Coords
    } else if (
      this.props.location &&
      this.props.location.physicalAddress &&
      this.props.location.physicalAddress.latitude &&
      this.props.location.physicalAddress.longitude
    ) {
      return {
        lat: this.props.location.physicalAddress.latitude,
        lng: this.props.location.physicalAddress.longitude,
      }
    } else {
      return { lat: 39.7684, lng: -86.1581 } as Coords
    }
  }

  private renderMarker(x: ILocation) {
    if (x && x.physicalAddress) {
      return (
        <Marker
          lat={x.physicalAddress.latitude}
          lng={x.physicalAddress.longitude}
          text={x.name}
          key={x.id}
        />
      )
    } else {
      return <></>
    }
  }

  public render() {
    const { selectedAddress, location } = this.props

    return (
      <div style={{ marginTop: '10px', height: '200px', width: '500px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
          }}
          defaultZoom={12}
          defaultCenter={{
            lat: 39.7684,
            lng: -86.1581,
          }}
          center={this.center()}
        >
          {selectedAddress ? (
            this.renderMarker({
              physicalAddress: selectedAddress,
              name: '',
            } as ILocation)
          ) : location ? (
            this.renderMarker(location)
          ) : (
            <></>
          )}
        </GoogleMapReact>
      </div>
    )
  }
}

const InjectedLocationLookupMapContainer = inject<
  IStores,
  ILocationLookupMapContainerProps,
  Partial<ILocationLookupMapContainerProps>,
  any
>((stores: IStores) => ({
  selectedAddress: stores.locations.selectedAddress,
}))(LocationLookupMapContainer)

export default InjectedLocationLookupMapContainer
