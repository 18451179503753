import { DefaultCommunication, ICommunication } from '../Definitions'
import { IdentifierEntityDto } from '../generated_client'
import { createITagFormValues, ITagFormValues, toTagDto } from '../Tags/TagFormValues'

export interface ICommunicationFormValues {
  readonly id: string
  type: string
  isActive: boolean
  message: string
  communicationDate: Date
  dateOfService: Date | undefined
  communicationTags: ITagFormValues[]
  location: IdentifierEntityDto
}

function setDefaultValues(): ICommunicationFormValues {
  return {
    communicationDate: new Date(),
    communicationTags: [] as ITagFormValues[],
    dateOfService: undefined,
    id: '',
    isActive: true,
    location: new IdentifierEntityDto(),
    message: '',
    type: '',
  }
}

function setValuesFromCommunication(
  communication: ICommunication
): ICommunicationFormValues {
  return {
    communicationDate: communication.communicationDate || new Date(),
    communicationTags: communication.communicationTags!.map(createITagFormValues) || [],
    dateOfService: communication.dateOfService || undefined,
    id: communication.id || '',
    isActive: communication.isActive || false,
    location: new IdentifierEntityDto(),
    message: communication.message || '',
    type: communication.type || '',
  }
}

export function isEditable(communication?: ICommunication) {
  const initialValues = createICommunicationFormValues(communication)
  if (
    initialValues.communicationTags.find(
      (x) => x.id === '0cc5a5b1-98dd-4485-a7a2-3ba22353655a'
    ) !== undefined
  ) {
    return true
  } else {
    return false
  }
}

export function createICommunicationFormValues(communication?: ICommunication) {
  if (communication) {
    return setValuesFromCommunication(communication)
  } else {
    return setDefaultValues()
  }
}

export function toICommunication(
  formValues: ICommunicationFormValues,
  original?: ICommunication
): ICommunication {
  const communication = original === undefined ? DefaultCommunication() : original
  return {
    ...communication,
    communicationDate: formValues.communicationDate,
    communicationTags: formValues.communicationTags
      ? formValues.communicationTags.map(toTagDto)
      : [],
    dateOfService: formValues.dateOfService,
    id: formValues.id,
    isActive: formValues.isActive,
    location: new IdentifierEntityDto(),
    message: formValues.message,
    type: formValues.type,
  }
}
