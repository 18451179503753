import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { InfoOutlined, ReceiptOutlined } from '@material-ui/icons'
import React from 'react'
import ReactPaginate from 'react-paginate'
import { pagedList } from 'src/viewModels/pagedList'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { inject } from 'mobx-react'
import { IStores } from 'src/Stores'
import moment from 'moment'
import { InboxType } from 'src/utils/InboxType'
import { WorkflowHistory } from 'src/viewModels/workflow/WorkflowHistory'
import {
  getBilloutHistory,
  getClaimHistory,
  getDocumentHistory,
  getQuoteHistory,
} from 'src/services/WorkflowService'
import { SearchRequestBase } from 'src/viewModels/SearchRequestBase'

interface IActionLogModalProps {
  isOpen: boolean
  closeModal: () => void
  associatedItemId: string
  inboxType: InboxType
}

class ActionLogModal extends React.Component<IActionLogModalProps> {
  public async componentDidMount(): Promise<void> {
    await this.getActionLogData()
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private getActionLogData = async () => {
    this.setState({ isLoading: true })
    const { page, pageSize } = this.state
    const data: SearchRequestBase = {
      page,
      pageSize,
    }

    switch (this.props.inboxType) {
      case InboxType.Document:
        await getDocumentHistory(this.props.associatedItemId, data).then(
          async (response: any) => {
            this.setState({
              gridData: response.items,
              pageCount: response.totalPages,
              totalItems: response.totalItems,
            })
            if (response.totalItems > 0) {
              this.setPaginationOffsetData()
            }
          }
        )
        break
      case InboxType.Claim:
        await getClaimHistory(this.props.associatedItemId, data).then(
          async (response: any) => {
            this.setState({
              gridData: response.items,
              pageCount: response.totalPages,
              totalItems: response.totalItems,
            })
            if (response.totalItems > 0) {
              this.setPaginationOffsetData()
            }
          }
        )
        break
      case InboxType.Quote:
        await getQuoteHistory(this.props.associatedItemId, data).then(
          async (response: any) => {
            this.setState({
              gridData: response.items,
              pageCount: response.totalPages,
              totalItems: response.totalItems,
            })
            if (response.totalItems > 0) {
              this.setPaginationOffsetData()
            }
          }
        )
        break
      case InboxType.Billout:
        await getBilloutHistory(this.props.associatedItemId, data).then(
          async (response: any) => {
            this.setState({
              gridData: response.items,
              pageCount: response.totalPages,
              totalItems: response.totalItems,
            })
            if (response.totalItems > 0) {
              this.setPaginationOffsetData()
            }
          }
        )
        break
      default:
        break
    }
    this.setState({ isLoading: false })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getActionLogData())
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems === 0
  }

  private mapLogGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData?.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          <TableCell align="right">{record.workflowRun}</TableCell>
          <TableCell>{record.inboxName}</TableCell>
          <TableCell align="right">
            {record.actionDate ? moment(record.actionDate).format('MM/DD/YYYY') : ''}
          </TableCell>
          <TableCell>{record.actionUser}</TableCell>
          <TableCell>
            {record.actionName}&nbsp;
            {record.actionDescription && (
              <Tooltip title={record.actionDescription}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            )}
          </TableCell>
          <TableCell>
            {record.actionAssignUser && (
              <Typography>
                ASSIGNMENT:&nbsp;
                <strong>{record.actionAssignUser}</strong>
                {record.actionAssignDueDate && (
                  <i>
                    &nbsp;with due date&nbsp;
                    {moment(record.actionAssignDueDate).format('MM/DD/YYYY')}
                  </i>
                )}
                <br />
              </Typography>
            )}
            {record.actionPendDate && (
              <Typography>
                PEND:&nbsp;
                <strong>{moment(record.actionPendDate).format('MM/DD/YYYY')}</strong>
                &nbsp;<i>{record.actionPendNote}</i>
                <br />
              </Typography>
            )}
            {record.comment}
          </TableCell>
        </TableRow>
      ))
    }
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  public state = {
    gridData: new pagedList().items as WorkflowHistory[],
    page: 1,
    pageCount: 1,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 10,
    isLoading: true,
  }

  public render() {
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="xl"
          open={this.props.isOpen!}
          disableBackdropClick
        >
          <DialogTitle style={{ padding: 0 }}>
            <ResponsiveAppBar
              title={'Action Log'}
              pageIcon={<ReceiptOutlined />}
              isModal={true}
            />
          </DialogTitle>
          <DialogContent style={{ padding: '12px 12px 8px' }}>
            {this.state.isLoading && (
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1200',
                }}
              >
                <CircularProgress size={100} />
              </div>
            )}
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              alignContent="center"
              alignItems="flex-start"
              wrap="wrap"
            >
              <Grid item xs={12} style={{ minWidth: '750px' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className="gridPadding">
                      <TableCell align="right">Workflow Run</TableCell>
                      <TableCell>Inbox</TableCell>
                      <TableCell align="right">Performed On</TableCell>
                      <TableCell>Performed By</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell>Comments</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ width: '100%' }}>{this.mapLogGridData()}</TableBody>
                </Table>
                <div className="pagination-row">
                  {this.paginationInfo()}
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    onPageChange={this.handlePageChange}
                    forcePage={this.state.page - 1}
                    pageCount={this.state.pageCount}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    //@ts-ignore
                    renderOnZeroPageCount={null}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeModal} data-cy="close">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const InjectedActionLogModal = inject<
  IStores,
  IActionLogModalProps,
  Partial<IActionLogModalProps>,
  any
>(() => ({}))(ActionLogModal)

export default InjectedActionLogModal
