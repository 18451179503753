import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from 'src/Stores'
import WorkflowInbox from './WorkflowInbox'
import { IProviderTypeDto } from 'src/generated_client'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { RouteComponentProps } from 'react-router-dom'
import { InboxType } from 'src/utils/InboxType'
import { QuoteInboxItem } from 'src/viewModels/workflow/QuoteInboxItem'
import { getDefaultQuoteInboxItem, getQuoteInboxItem } from 'src/services/WorkflowService'
import { List } from '@material-ui/icons'
import RenderQuote from 'src/quotes/RenderQuote'

interface IRenderQuoteRouteParams {
  quoteId: string
  inboxId: string
}

interface IQuoteInboxProps extends RouteComponentProps<IRenderQuoteRouteParams> {
  getAllUsers?: (pageSize?: number, roleFilter?: string) => Promise<void>
  getLineItemsTotals?: (lineItems: any) => number
  getProviderTypes?: () => Promise<void>
  getTypesByCategory?: (type: string) => Promise<void>
  isContractModalOpen?: boolean
  isMultpleProcedureDiscountDialogOpen?: boolean
  loadQuote?: (quoteId: string, reload?: boolean) => Promise<void>
  providerTypes?: IProviderTypeDto[]
  rerouteToPath?: (path: string) => void
  selectedQuote?: IQuoteDTO
  openModal?: (quote: IQuoteDTO, modalSwitch: string) => void
  openStatusModal?: (quote: IQuoteDTO) => void
  openReconcileModal?: (quote: IQuoteDTO, modalSwitch: string) => void
  assistantWarning?: boolean
  viewMode?: boolean
  setViewMode?: (view: boolean) => void
  reconMode?: boolean
  setReconMode?: (recon: boolean) => void
  reconViewMode?: boolean
  setReconViewMode?: (recon: boolean) => void
  openReconModal?: (
    quote: IQuoteDTO,
    modalSwitch: string,
    existingQuoteLineId?: string
  ) => void
  createInvoice?: (quoteInboxItem: QuoteInboxItem, actionId: number) => void
}
class QuoteInbox extends React.Component<IQuoteInboxProps> {
  public state = {
    headerInfo: undefined as unknown as QuoteInboxItem,
  }

  public async componentDidMount(): Promise<void> {
    if (this.props.match.params.quoteId && this.props.match.params.inboxId) {
      await getQuoteInboxItem(
        this.props.match.params.quoteId,
        this.props.match.params.inboxId
      ).then(async (response: any) => {
        this.setState({
          headerInfo: response,
        })
      })
    } else if (this.props.match.params.quoteId) {
      await getDefaultQuoteInboxItem(this.props.match.params.quoteId).then(
        async (response: any) => {
          this.setState({
            headerInfo: response,
          })
        }
      )
    }
  }

  public renderQuote() {
    if (this.state.headerInfo != undefined) {
      return (
        <RenderQuote
          getAllUsers={this.props.getAllUsers}
          getLineItemsTotals={this.props.getLineItemsTotals}
          getProviderTypes={this.props.getProviderTypes}
          getTypesByCategory={this.props.getTypesByCategory}
          loadQuote={this.props.loadQuote}
          providerTypes={this.props.providerTypes}
          rerouteToPath={this.props.rerouteToPath}
          selectedQuote={this.props.selectedQuote}
          openModal={this.props.openModal}
          openReconcileModal={this.props.openReconcileModal}
          openReconModal={this.props.openReconModal}
          assistantWarning={this.props.assistantWarning}
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
          setAsViewOnly={
            this.state.headerInfo?.restrictedToOutOfWorkflowActions ||
            this.state.headerInfo?.isDynamicItem
          }
          createInvoice={this.props.createInvoice}
          quoteInboxItem={this.state.headerInfo}
        />
      )
    } else {
      return <></>
    }
  }

  public render() {
    return (
      <>
        <WorkflowInbox
          inboxType={InboxType.Quote}
          item={this.renderQuote()}
          headerInfo={this.state.headerInfo}
          icon={<List />}
        />
      </>
    )
  }
}

const InjectedQuoteInbox = inject<
  IStores,
  IQuoteInboxProps,
  Partial<IQuoteInboxProps>,
  any
>((stores: IStores) => ({
  getAllUsers: stores.users.getAllUsers,
  getLineItemsTotals: stores.quotes.getLineItemsTotal,
  getProviderTypes: stores.quotes.getProviderTypes,
  getTypesByCategory: stores.quotes.getTypesByCategory,
  loadQuote: stores.quotes.loadQuote,
  providerTypes: stores.quotes.providerTypes,
  rerouteToPath: stores.global.rerouteToPath,
  selectedQuote: stores.quotes.selectedQuote,
  openModal: stores.quotes.openQuoteLineModal,
  openReconModal: stores.quotes.openReconcileQuoteLineModal,
  assistantWarning: stores.quotes.assistantWarning,
  createInvoice: stores.quotes.createInvoice,
}))(QuoteInbox)

export default InjectedQuoteInbox
