import {
  AppBar,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import Moment from 'moment'
import * as React from 'react'
import { IPatient } from '../../Definitions'

const styles = ({ palette }: Theme) =>
  createStyles({
    appbar: {
      backgroundColor: palette.grey[200],
      color: '#333',
      paddingBottom: '10px',
      paddingLeft: '12px',
      paddingTop: '10px',
    },
  })

export interface IActionBarItem {
  icon: React.ReactElement<SvgIconProps>
  label: string
  onClick: () => void
  disabled?: boolean
}

interface IActionBarProps extends WithStyles {
  items: IActionBarItem[]
  patient?: IPatient
}

const ActionBar: React.SFC<IActionBarProps> = ({ classes, items, patient }) => {
  const appBarStyle = {
    boxShadow: 'none',
    width: `100%`,
  }

  const phoneNumber = () => {
    return patient ? (
      patient!.cellPhone ? (
        `CELL PHONE: ${patient!.cellPhone}`
      ) : patient!.homePhone ? (
        `HOME PHONE: ${patient!.homePhone}`
      ) : patient!.workPhone ? (
        `WORK PHONE: ${patient!.workPhone}`
      ) : (
        <em>No phone number available</em>
      )
    ) : (
      ''
    )
  }

  return (
    <AppBar style={appBarStyle} className={classes.appbar} position="relative">
      <Grid container direction="row">
        <Grid item wrap="nowrap">
          <Typography noWrap variant="caption">
            {patient ? `PATIENT ID: ${patient!.patientIdNumber}` : ''}
            {patient && patient!.preCertNumber
              ? ` [PRE-CERT#: ${patient!.preCertNumber}]`
              : ''}
          </Typography>
          <Typography noWrap variant="body2">
            <strong>
              {patient
                ? `${patient!.lastName}, ${patient!.firstName} 
                        [DOB: ${
                          patient!.dateOfBirthText
                            ? Moment(patient!.dateOfBirthText).format('MM/DD/YYYY')
                            : ''
                        }]`
                : ''}
            </strong>
          </Typography>
          <Typography noWrap variant="caption">
            {patient
              ? `PATIENT ADDRESS: ${patient!.address!.line1}, ${
                  patient!.address!.city
                }, ${patient!.address!.state} ${patient!.address!.zip}`
              : ''}
          </Typography>
          <Grid container spacing={2} style={{ alignItems: 'baseline' }}>
            <Grid item>
              <Typography noWrap variant="caption">
                {phoneNumber()}
              </Typography>
            </Grid>
            <Grid item>
              {patient?.allowContact == false && (
                <Typography noWrap variant="body2" color="error">
                  <strong>DO NOT CONTACT PATIENT</strong>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item alignContent="center">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            style={{ marginTop: '24px' }}
          >
            {items.map((child, index) => {
              return (
                <div
                  key={index}
                  style={{
                    alignItems: 'center',
                    color: child.disabled ? '#AAAAAA' : undefined,
                    cursor: child.disabled ? 'default' : 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '25px',
                  }}
                  onClick={child.onClick}
                >
                  <div>{child.icon}</div>
                  <div
                    style={{
                      fontSize: '0.8125rem',
                      marginLeft: '5px',
                    }}
                  >
                    {child.label}
                  </div>
                </div>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  )
}

export default withStyles(styles)(ActionBar)
