export enum DocumentType {
  DME,
  PhysicalTherapy,
  OccupationalTherapy,
  InHome,
  InpatientRehab,
  WorkConditioning,
  FCE,
  SurgeryFacility,
  InitialEval,
  TakeoverOfCare,

  //Global Invoice
  InvoiceInitial,
  InvoiceResend,

  BillOut1500,
  ExplanationOfBenefits,
  PatientSurgicalContract,
  PatientInjectionContract,
  Upload,
  PatientDemographics,
  InsuranceLetter,

  CMS1500,
}
