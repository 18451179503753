import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { ReceiptOutlined } from '@material-ui/icons'
import moment from 'moment'
import React from 'react'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { getClaimLastUpdateHistory } from 'src/services/ClaimService'
import { ClaimLastHistorySummaryResult } from 'src/viewModels/quotes/ClaimLastHistorySummaryResult'

interface ILastHistoryModalProps {
  isOpen?: boolean
  claimId: string
  closeModal: () => void
}

class ClaimLastUpdateHistoryModal extends React.Component<ILastHistoryModalProps> {
  public state = {
    claimId: this.props.claimId,
    data: new ClaimLastHistorySummaryResult(),
    loading: true,
  }

  public async componentDidMount(): Promise<void> {
    await this.getLastUpdateData()
  }

  private getLastUpdateData = async () => {
    const claimId = this.props.claimId

    await getClaimLastUpdateHistory(claimId).then(async (response: any) => {
      this.setState({ claimId: claimId, data: response, loading: false })
    })
  }

  public render() {
    return (
      <>
        <Dialog
          key={this.props.claimId}
          fullWidth={true}
          maxWidth="md"
          open={this.props.isOpen!}
        >
          {this.state.loading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <DialogTitle style={{ padding: 0 }}>
            <ResponsiveAppBar
              title={
                'Last Update History' +
                (this.state.data != undefined
                  ? ' - ' +
                    this.state.data.patientName +
                    (this.state.data?.patientDOB
                      ? ' (' +
                        moment(this.state.data?.patientDOB).format('MM/DD/YYYY') +
                        ')'
                      : '') +
                    ' ' +
                    this.state.data?.eocGeneratedOrLegacyId
                  : '')
              }
              pageIcon={<ReceiptOutlined />}
              isModal={true}
            />
          </DialogTitle>
          <DialogContent>
            {!this.state.loading ? (
              <>
                <Grid item container spacing={2} style={{ paddingTop: '10px' }}>
                  <Grid item md={6} sm={12}>
                    <strong>Claim Created On: </strong>
                    {this.state.data?.claimCreatedOnDisplay}
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <strong>Claim Created By: </strong>
                    {this.state.data?.claimCreatedByUser}
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item md={6} sm={12}>
                    <strong>Billout Created On: </strong>
                    {this.state.data?.billOutCreatedOnDisplay}
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <strong>Billout Created By: </strong>
                    {this.state.data?.billOutCreatedByUser}
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item md={6} sm={12}>
                    <strong>Claim Last Modified On: </strong>
                    {this.state.data?.overallModifiedOnDate}
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <strong>Claim Last Modified By: </strong>
                    {this.state.data?.overallModifiedByUser}
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeModal} data-cy="close">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default ClaimLastUpdateHistoryModal
