import Axios from 'axios'
import createPdfObjUrl from 'src/utils/CreatePdfObjUrl'
import { DocumentType } from 'src/utils/DocumentType'
import { IGlobalInvoicePaymentSummary } from 'src/viewModels/InvoicePaymentSummaryResult'
import { pagedList } from 'src/viewModels/pagedList'
import { IQuoteCalculationRequest } from 'src/viewModels/QuoteCalculationRequest'
import { EmailSummaryResult } from 'src/viewModels/quotes/EmailSummaryResult'
import { GlobalInvoiceDTO } from 'src/viewModels/quotes/GlobalInvoiceDTO'
import { InvoiceLogDTO } from 'src/viewModels/quotes/InvoiceLogDTO'
import { InvoiceLogSearchRequest } from 'src/viewModels/quotes/InvoiceLogSearchRequest'
import { QuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { QuoteStatusLogDTO } from 'src/viewModels/quotes/QuoteStatusLogDTO'
import { IQuoteTotalsSummary } from 'src/viewModels/quotes/QuoteTotalsSummary'
import { IQuoteSearchRequest } from 'src/viewModels/QuoteSearchRequest'
import { SearchRequestBase } from 'src/viewModels/SearchRequestBase'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function CreateAdjusterEmail(id: string): Promise<EmailSummaryResult> {
  const response = await Axios.get<EmailSummaryResult>(
    `${base_url}/api/quote/${id}/email`
  )

  return response.data
}

export async function searchQuotes(req: IQuoteSearchRequest): Promise<pagedList> {
  //Send search request
  const response = await Axios.post<pagedList>(`${base_url}/api/quote/search`, req)

  return response.data
}

export async function GetQuoteById(id: string): Promise<QuoteDTO> {
  const response = await Axios.get<QuoteDTO>(`${base_url}/api/quote/${id}`)

  return response.data
}

export async function calculateLineItem(req: IQuoteCalculationRequest): Promise<number> {
  const response = await Axios.post<number>(`${base_url}/api/quote/total`, req)

  return response.data
}

export async function UpdateStatusLog(req: QuoteStatusLogDTO): Promise<string> {
  const response = await Axios.patch<string>(
    `${base_url}/api/quote/${req.quoteId}/status`,
    req
  )

  return response.data
}

export async function AddInvoiceLog(
  quoteId: string,
  req: InvoiceLogDTO
): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/quote/${quoteId}/invoice/${req.invoiceId}/log`,
    req
  )

  return response.data
}

export async function GenerateInvoicePDF(
  quoteId: string,
  templateType: DocumentType
): Promise<string> {
  const response = await Axios.get<string>(
    `${base_url}/api/quote/${quoteId}/generate-invoice-pdf`,
    { params: { type: templateType } }
  )

  window.open(createPdfObjUrl(response.data), '_blank')
  return response.data
}

export async function PreviewInvoicePDF(
  quoteId: string,
  req: GlobalInvoiceDTO
): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/quote/${quoteId}/preview-invoice-pdf`,
    req
  )

  window.open(createPdfObjUrl(response.data), '_blank')
  return response.data
}

export async function GetInvoiceLogByInvoiceId(
  quoteId: string,
  req?: InvoiceLogSearchRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/quote/${quoteId}/invoice-log`,
    req
  )
  return response.data
}

export async function GetInvoicePaymentDetails(
  quoteId: string
): Promise<IGlobalInvoicePaymentSummary> {
  const response = await Axios.get<IGlobalInvoicePaymentSummary>(
    `${base_url}/api/quote/${quoteId}/invoice-payment-details`
  )
  return response.data
}

export async function generateCostSavings(
  req: IQuoteSearchRequest,
  bundled: boolean
): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/quote/cost-savings-pdf`,
    req,
    {
      params: {
        bundled,
      },
    }
  )

  window.open(createPdfObjUrl(response.data), '_blank')
  return response.data
}

export async function GetQuoteLogById(
  quoteId: string,
  req?: SearchRequestBase
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/quote/${quoteId}/status-log`,
    req
  )
  return response.data
}

export async function AddStatusLogComment(id: string, req: string): Promise<string> {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'application/json; charset=utf-8',
  }
  const response = await Axios.post<string>(
    `${base_url}/api/quote/${id}/add-comment-log`,
    req,
    { headers }
  )

  return response.data
}

export async function GetQuoteTotalsSummary(
  req: IQuoteSearchRequest
): Promise<IQuoteTotalsSummary> {
  const response = await Axios.post<IQuoteTotalsSummary>(
    `${base_url}/api/quote/totals`,
    req
  )
  return response.data
}
