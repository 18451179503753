import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import { inject } from 'mobx-react'
import * as React from 'react'
import { pagedList } from 'src/viewModels/pagedList'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IStores } from '../Stores'
import ReactPaginate from 'react-paginate'
import { Helmet } from 'react-helmet'
import {
  CheckBox,
  CheckBoxOutlineBlank,
  HeadsetMicRounded,
  ReceiptOutlined,
  Warning,
} from '@material-ui/icons'
import ProcessReceivedCheckModal from 'src/common/ProcessReceivedCheckModal'
import { ClaimsAcquisitionSummaryResult } from 'src/viewModels/ClaimsAcquisitionSummaryResult'
import { searchClaimsAcquisition } from 'src/services/ClaimsAcquisitionService'
import { ClaimsAcquisitionSearchRequest } from 'src/viewModels/ClaimsAcquisitionSearchRequest'
import ESMenu from 'src/common/ESMenu'
import ClaimsAcquisitionNameConstants from 'src/constants/ClaimsAcquisitionNameConstants'
import ViewClaimsAcqLogsModal from '../claimsAcquisition/ViewClaimsAcqLogsModal'
import AddClaimsAcqLogModal from './AddClaimsAcqLogModal'
import { CommentOutline } from 'mdi-material-ui'
import moment from 'moment'
import { ClaimsAcquisitionStatus } from 'src/utils/ClaimsAcquisitionStatus'
import { ChangeEvent } from 'react'
import { GetComponentOptions } from 'src/services/LookupService'
import ViewClaimsModal from './ViewClaimsModal'
import { DropdownOption } from 'src/viewModels/DropdownOption'

const styles = ({}: Theme) => createStyles({})

interface IClaimsAcquisitionProps {
  isModalOpen?: boolean
  buildClaimsAcqMenuItems?: (acq: ClaimsAcquisitionSummaryResult) => any[]
  selectedScheduleId?: string | undefined
  openViewClaimsAcqLogModal?: (acq: ClaimsAcquisitionSummaryResult) => void
  isViewAcqClaimsModalOpen?: boolean
  setAcqRequest?: (request: ClaimsAcquisitionSearchRequest) => void
  storedRequest?: ClaimsAcquisitionSearchRequest
  selectedPatientInfo?: string
  isLoading?: boolean
}

class ClaimsAcquisition extends React.Component<IClaimsAcquisitionProps> {
  public state = {
    componentNames: new Array<{ id: string; description: string }>(),
    gridData: new pagedList().items as ClaimsAcquisitionSummaryResult[],
    searchPatientLastName: this.props.storedRequest?.patientLastName ?? '',
    searchPatientFirstName: this.props.storedRequest?.patientFirstName ?? '',
    searchProviderGroup: this.props.storedRequest?.providerGroup ?? '',
    searchDateOfServiceStart: this.props.storedRequest?.dateOfServiceStart ?? '',
    searchDateOfServiceEnd: this.props.storedRequest?.dateOfServiceEnd ?? '',
    searchInsuranceCompany: this.props.storedRequest?.insuranceCompany ?? '',
    searchBillingServicer: this.props.storedRequest?.billingServicer ?? '',
    searchBillingName: this.props.storedRequest?.billingName ?? '',
    searchClaimsAcqStatus: new Map<number, string>(),
    searchUrgentOnly: this.props.storedRequest?.urgentOnly ?? false,
    searchClaimsOnly: this.props.storedRequest?.claimsOnly ?? false,
    searchComponents: new Map<string, string>(),
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 50,
    gridLoading: false,
  }

  public claimAcqStatusKeys = [...Array(12).keys()]
    .map((key) => ({ key: key, val: ClaimsAcquisitionNameConstants[key] }))
    .sort(function (a, b) {
      return a.val.localeCompare(b.val)
    })

  public async componentDidMount() {
    if (this.props.storedRequest?.claimsAcqStatuses === undefined) {
      Object.keys(ClaimsAcquisitionStatus)
        .filter((key) => !isNaN(Number(key)) && key != '4' && key != '5' && key != '6')
        .forEach((key) => {
          this.state.searchClaimsAcqStatus.set(
            Number(key),
            ClaimsAcquisitionNameConstants[Number(key)]
          )
        })

      this.state.searchClaimsAcqStatus.set(-1, '[BLANK]')
    } else {
      this.props.storedRequest?.claimsAcqStatuses.forEach((status) => {
        this.state.searchClaimsAcqStatus.set(
          status,
          ClaimsAcquisitionNameConstants[status]
        )
      })

      if (this.props.storedRequest.claimsAcqStatuses.includes(-1)) {
        this.state.searchClaimsAcqStatus.set(-1, '[BLANK]')
      }
    }

    if (this.props.storedRequest?.components != undefined) {
      this.props.storedRequest!.components!.forEach((component) => {
        this.state.searchComponents.set(component, this.state.componentNames[component])
      })
    }

    await this.getComponents()
    this.setState({ searchStatus: this.state.searchClaimsAcqStatus })
    this.getAcquisitionData()
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this)
  }

  public forceUpdateHandler() {
    window.location.reload()
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems === 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={13} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((acq, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell
            style={{
              color: acq.urgent ? 'red' : 'default',
              paddingLeft: '30px',
            }}
          >
            {acq.urgent && (
              <Tooltip title="Urgent">
                <Warning color="error" fontSize="small" />
              </Tooltip>
            )}
          </TableCell>
          <TableCell style={{ whiteSpace: 'nowrap' }}>
            {acq.claimsAcqStatus != undefined
              ? ClaimsAcquisitionNameConstants[acq.claimsAcqStatus]
              : ''}
            &nbsp;&nbsp;
            {acq.claimsAcqStatus != undefined && (
              <Tooltip title="Show Claims Acquisition Log">
                <ReceiptOutlined
                  style={{ cursor: 'pointer' }}
                  fontSize="small"
                  onClick={() => {
                    this.props.openViewClaimsAcqLogModal!(acq)
                  }}
                />
              </Tooltip>
            )}
          </TableCell>
          <TableCell align="right">
            {acq.dateOfService ? moment(acq.dateOfService).format('MM/DD/YYYY') : ''}
          </TableCell>
          <TableCell>{acq.patientName}</TableCell>
          <TableCell align="right">
            {acq.dateOfBirth ? moment(acq.dateOfBirth).format('MM/DD/YYYY') : ''}
          </TableCell>
          <TableCell>{acq.providerGroupName}</TableCell>
          <TableCell>{acq.providerLocationName}</TableCell>
          <TableCell>{acq.billingServicer}</TableCell>
          <TableCell>{acq.billingName}</TableCell>
          <TableCell>{acq.componentName}</TableCell>
          <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
                gap: '1rem',
              }}
            >
              <div>{acq.claimCount}</div>
              {acq.latestNote ? (
                <Tooltip
                  title={
                    <span style={{ whiteSpace: 'pre-line' }}> {acq.latestNote}</span>
                  }
                >
                  <div>
                    <CommentOutline style={{ display: 'block' }} fontSize="small" />
                  </div>
                </Tooltip>
              ) : null}
            </div>
          </TableCell>
          <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
            {acq.followUpDate ? moment(acq.followUpDate).format('MM/DD/YYYY') : ''}
            &nbsp;&nbsp;
          </TableCell>
          <TableCell>{this.renderContextMenu(acq, index)}</TableCell>
        </TableRow>
      ))
    }
  }

  private getComponents = async () => {
    await GetComponentOptions(true, false).then((response: Array<DropdownOption>) => {
      var specialComponents = response.filter(
        (item) =>
          item.description === 'Facility' ||
          item.description === 'Surgery' ||
          item.description === 'Anesthesia' ||
          item.description === 'Initial PT'
      )
      var otherComponents = response.filter(
        (item) =>
          item.description != 'Facility' &&
          item.description != 'Surgery' &&
          item.description != 'Anesthesia' &&
          item.description != 'Initial PT'
      )

      var line = new DropdownOption()
      line.id = 'line'
      line.description = '─────────────────────────'

      var components = specialComponents.concat(line).concat(otherComponents)

      this.setState({ componentNames: components })

      if (this.state.searchComponents.size > 0) {
        this.state.componentNames.map((item) => {
          if (this.state.searchComponents.has(item.id)) {
            this.state.searchComponents.set(item.id, item.description)
          }
        })
      } else if (this.props.storedRequest?.components === undefined) {
        this.state.componentNames.map((item) => {
          if (
            item.description === 'Facility' ||
            item.description === 'Surgery' ||
            item.description === 'Anesthesia'
          ) {
            this.state.searchComponents.set(item.id, item.description)
          }
        })
      }
    })
  }

  private getAcquisitionData = async (setSavedSearch: boolean = false) => {
    this.setState({ gridLoading: true })
    const { page, pageSize } = this.state
    const requestData = new ClaimsAcquisitionSearchRequest()
    requestData.page = page
    requestData.pageSize = pageSize
    requestData.patientFirstName = this.state.searchPatientFirstName
    requestData.patientLastName = this.state.searchPatientLastName
    requestData.billingName = this.state.searchBillingName
    requestData.billingServicer = this.state.searchBillingServicer
    requestData.claimsAcqStatuses = Array.from(this.state.searchClaimsAcqStatus.keys())
    requestData.urgentOnly = this.state.searchUrgentOnly
    requestData.claimsOnly = this.state.searchClaimsOnly
    requestData.providerGroup = this.state.searchProviderGroup
    requestData.insuranceCompany = this.state.searchInsuranceCompany
    requestData.dateOfServiceStart = this.state.searchDateOfServiceStart
    requestData.dateOfServiceEnd = this.state.searchDateOfServiceEnd
    requestData.components = Array.from(this.state.searchComponents.keys())

    await searchClaimsAcquisition(requestData).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        gridLoading: false,
      })

      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }

      if (setSavedSearch) {
        this.props.setAcqRequest!(requestData)
      }
    })
  }

  private search = (event: any) => {
    this.setState({ page: 1 }, () => this.getAcquisitionData(true))
    event.preventDefault()
  }

  private clearSearch = () => {
    this.setState(
      {
        page: 1,
        searchPatientLastName: '',
        searchPatientFirstName: '',
        searchProviderGroup: '',
        searchComponents: new Map<string, string>(),
        searchDateOfServiceStart: '',
        searchDateOfServiceEnd: '',
        searchInsuranceCompany: '',
        searchBillingServicer: '',
        searchBillingName: '',
        searchClaimsAcqStatus: new Map<number, string>(),
        searchUrgentOnly: false,
        searchClaimsOnly: false,
      },
      () => {
        this.setState({ gridData: undefined, pageCount: 0, totalItems: 0 })
        this.props.setAcqRequest!(new ClaimsAcquisitionSearchRequest())
      }
    )
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getAcquisitionData())
  }

  private handlePatientFirstNameChange = (event: any) => {
    this.setState({ searchPatientFirstName: event.target.value })
  }

  private handlePatientLastNameChange = (event: any) => {
    this.setState({ searchPatientLastName: event.target.value })
  }

  private handleProviderGroupChange = (event: any) => {
    this.setState({ searchProviderGroup: event.target.value })
  }

  private handleDateOfServiceStartChange = (event: any) => {
    this.setState({ searchDateOfServiceStart: event.target.value })
  }

  private handleDateOfServiceEndChange = (event: any) => {
    this.setState({ searchDateOfServiceEnd: event.target.value })
  }

  private handleInsuranceCoompanyChange = (event: any) => {
    this.setState({ searchInsuranceCompany: event.target.value })
  }

  private handleBillingName = (event: any) => {
    this.setState({ searchBillingName: event.target.value })
  }

  private handleBillingServicerChange = (event: any) => {
    this.setState({ searchBillingServicer: event.target.value })
  }

  private handleOnlyUrgentChange = () => {
    this.setState({ searchUrgentOnly: !this.state.searchUrgentOnly })
  }

  private handleOnlyClaimsChange = () => {
    this.setState({ searchClaimsOnly: !this.state.searchClaimsOnly })
  }

  private handleClaimsAcqStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (this.state.searchClaimsAcqStatus.has(+event.currentTarget.dataset.value!)) {
      this.state.searchClaimsAcqStatus.delete(+event.currentTarget.dataset.value!)
      this.setState({ searchStatus: this.state.searchClaimsAcqStatus })
    } else {
      this.state.searchClaimsAcqStatus.set(
        +event.currentTarget.dataset.value!,
        event.currentTarget.dataset.name!
      )
      this.setState({ searchStatus: this.state.searchClaimsAcqStatus })
    }
  }

  private handleComponentTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (this.state.searchComponents.has(event.currentTarget.dataset.value!)) {
      this.state.searchComponents.delete(event.currentTarget.dataset.value!)
      this.setState({ searchStatus: this.state.searchComponents })
    } else {
      this.state.searchComponents.set(
        event.currentTarget.dataset.value!,
        event.currentTarget.dataset.name!
      )
      this.setState({ searchStatus: this.state.searchComponents })
    }
  }

  private renderContextMenu = (acq: ClaimsAcquisitionSummaryResult, index: number) => {
    let options = this.props.buildClaimsAcqMenuItems!(acq)

    return (
      <ESMenu
        data={acq}
        menuItems={options}
        rowIndex={index}
        menuAriaLabel={`$claims-acquisition-${index}-menu`}
      />
    )
  }

  public render() {
    const MenuProps = {
      getContentAnchorEl: null,
      PaperProps: {
        style: {
          maxHeight: 400,
        },
      },
    }
    return (
      <>
        <Helmet>
          <title>Claims Acquisition</title>
        </Helmet>
        <ResponsiveAppBar title={'Claims Acquisition'} pageIcon={<HeadsetMicRounded />} />
        <Grid container style={{ width: '100%', paddingBottom: '20px' }}>
          <ProcessReceivedCheckModal />
          <ViewClaimsModal patientInfo={this.props.selectedPatientInfo} />
          <AddClaimsAcqLogModal reloadMain={this.forceUpdateHandler} />
          {this.props.isViewAcqClaimsModalOpen && <ViewClaimsAcqLogsModal />}
          {(this.state.gridLoading || this.props.isLoading) && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '2',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <form onSubmit={this.search}>
            <Grid
              container
              style={{ margin: '10px 0px', paddingLeft: '30px', width: '100%' }}
              md={12}
            >
              <Typography
                color="primary"
                component="h6"
                variant="h6"
                gutterBottom
                style={{ paddingBottom: '10px' }}
              >
                SEARCH CRITERIA
              </Typography>
              <Grid container style={{ width: '100%' }} direction="row" spacing={2}>
                <Grid item lg={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel shrink={true} variant="outlined">
                      Claims Acq Status
                    </InputLabel>
                    <Select
                      variant="outlined"
                      input={<OutlinedInput notched labelWidth={134} />}
                      inputProps={{ label: true, notched: true }}
                      fullWidth
                      id="claimsAcqStatus"
                      multiple
                      value={Array.from(this.state.searchClaimsAcqStatus.keys())}
                      renderValue={() =>
                        Array.from(this.state.searchClaimsAcqStatus.values()).join(', ')
                      }
                      onChange={this.handleClaimsAcqStatusChange}
                      MenuProps={MenuProps}
                    >
                      {this.claimAcqStatusKeys.map((entry) => (
                        <MenuItem value={entry.key} data-name={entry.val}>
                          {entry.val &&
                          this.state.searchClaimsAcqStatus.has(entry.key) ? (
                            <CheckBox
                              color="secondary"
                              style={{ paddingRight: '10px' }}
                            />
                          ) : (
                            <CheckBoxOutlineBlank
                              color="action"
                              style={{ paddingRight: '10px' }}
                            />
                          )}{' '}
                          {entry.val}
                        </MenuItem>
                      ))}
                      <MenuItem value={-1} data-name="[BLANK]">
                        {this.state.searchClaimsAcqStatus.has(-1) ? (
                          <CheckBox color="secondary" style={{ paddingRight: '10px' }} />
                        ) : (
                          <CheckBoxOutlineBlank
                            color="action"
                            style={{ paddingRight: '10px' }}
                          />
                        )}{' '}
                        [BLANK]
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container lg={4} direction="row">
                  <FormControl fullWidth style={{ width: '48%' }}>
                    <TextField
                      value={this.state.searchDateOfServiceStart}
                      onChange={this.handleDateOfServiceStartChange}
                      label="Date of Service Start"
                      name="dateOfServiceStart"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      type="date"
                      defaultValue={this.state.searchDateOfServiceStart}
                    />
                  </FormControl>
                  <strong style={{ margin: '0px 10px' }}> - </strong>
                  <FormControl fullWidth style={{ width: '45%' }}>
                    <TextField
                      value={this.state.searchDateOfServiceEnd}
                      onChange={this.handleDateOfServiceEndChange}
                      label="Date of Service End"
                      name="dateOfServiceEnd"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      type="date"
                      defaultValue={this.state.searchDateOfServiceEnd}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel shrink={true} variant="outlined">
                      Component
                    </InputLabel>
                    <Select
                      variant="outlined"
                      input={<OutlinedInput notched labelWidth={90} />}
                      inputProps={{ label: true, notched: true }}
                      fullWidth
                      id="components"
                      multiple
                      value={Array.from(this.state.searchComponents.keys())}
                      renderValue={() =>
                        Array.from(this.state.searchComponents.values()).join(', ')
                      }
                      onChange={this.handleComponentTypeChange}
                      MenuProps={MenuProps}
                    >
                      {this.state.componentNames.map((item) => (
                        <MenuItem
                          value={item.id}
                          data-name={item.description}
                          disabled={item.id == 'line'}
                        >
                          {item.id && this.state.searchComponents.has(item.id) ? (
                            <CheckBox
                              color="secondary"
                              style={{ paddingRight: '10px' }}
                            />
                          ) : (
                            <CheckBoxOutlineBlank
                              color="action"
                              style={{
                                paddingRight: '10px',
                                visibility: item.id !== 'line' ? 'visible' : 'hidden',
                              }}
                            />
                          )}{' '}
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={3}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchInsuranceCompany}
                      onChange={this.handleInsuranceCoompanyChange}
                      label="Insurance Company"
                      name="insuranceCompany"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchPatientFirstName}
                      onChange={this.handlePatientFirstNameChange}
                      label="Patient First Name"
                      name="patientFirst"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item container lg={4} direction="row">
                  <FormControl>
                    <FormControlLabel
                      label="Only With Claims"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checked={this.state.searchClaimsOnly}
                          onChange={this.handleOnlyClaimsChange}
                          inputProps={{
                            id: 'claims-only-filter',
                          }}
                          value="searchClaimsOnly"
                          name="searchClaimsOnly"
                        />
                      }
                    />
                  </FormControl>
                  &nbsp;&nbsp;
                  <FormControl>
                    <FormControlLabel
                      label="Only Show Urgent"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checked={this.state.searchUrgentOnly}
                          onChange={this.handleOnlyUrgentChange}
                          inputProps={{
                            id: 'urgent-only-filter',
                          }}
                          value="searchUrgentOnly"
                          name="searchUrgentOnly"
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchPatientLastName}
                      onChange={this.handlePatientLastNameChange}
                      label="Patient Last Name"
                      name="patientLast"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchProviderGroup}
                      onChange={this.handleProviderGroupChange}
                      label="Provider Group"
                      name="providerGroup"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchBillingServicer}
                      onChange={this.handleBillingServicerChange}
                      label="Billing Servicer"
                      name="billingServicer"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchBillingName}
                      onChange={this.handleBillingName}
                      label="Billing Name"
                      name="billingName"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{
                    marginLeft: '8px',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                  }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="contained"
                    style={{ marginRight: '10px' }}
                  >
                    Search
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.clearSearch}
                    size="medium"
                    variant="contained"
                  >
                    Clear Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Grid item md={12}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="gridPadding">
                  <TableCell style={{ paddingLeft: '30px' }}></TableCell>
                  <TableCell>Claims Acq Status</TableCell>
                  <TableCell align="right">Date of Service</TableCell>
                  <TableCell align="center">Patient</TableCell>
                  <TableCell align="right">DOB</TableCell>
                  <TableCell>Provider Group</TableCell>
                  <TableCell>Provider Location</TableCell>
                  <TableCell>Billing Servicer</TableCell>
                  <TableCell>Billing Name</TableCell>
                  <TableCell>Component</TableCell>
                  <TableCell align="right">Claim Count</TableCell>
                  <TableCell style={{ paddingRight: 0 }} align="right">
                    Follow-up Date
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '100%' }}>{this.mapGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                forcePage={this.state.page - 1}
                pageCount={this.state.pageCount}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}

const InjectedClaimsAcquisition = inject<
  IStores,
  IClaimsAcquisitionProps,
  Partial<IClaimsAcquisitionProps>,
  any
>((stores: IStores) => ({
  isModalOpen: stores.patientEdit.isModalOpen,
  buildClaimsAcqMenuItems: stores.schedules.buildClaimsAcqMenuItems,
  openViewClaimsAcqLogModal: stores.schedules.openViewClaimsAcqLogModal,
  isViewAcqClaimsModalOpen: stores.schedules.isViewClaimsAcqLogModalOpen,
  setAcqRequest: stores.global.setAcqRequest,
  storedRequest: stores.global.acqRequest,
  selectedPatientInfo: stores.schedules.selectedPatientInfo,
  isLoading: stores.patientEdit.isLoading,
}))(ClaimsAcquisition)

export default withStyles(styles)(InjectedClaimsAcquisition)
