import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { Description } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import { inject } from 'mobx-react'
import React from 'react'
import FullWidthField from 'src/common/FullWidthField'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { ISchedule } from 'src/Definitions'
import { DocumentType } from 'src/models/enums/DocumentType.enum'
import { createAuthorizationLog } from 'src/services/AuthorizationLogService'
import { IStores } from 'src/Stores'
import { AuthorizationLogDTO } from 'src/viewModels/AuthorizationLogDTO'
import * as Yup from 'yup'

interface IAuthorizationModalProps {
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean
  schedule?: ISchedule
  authorizationType?: DocumentType
}

class AuthorizationModal extends React.Component<IAuthorizationModalProps> {
  public state = {
    loadingPdf: false,
  }
  private getFormikValues = () => {
    const formikValues = this.formRef.current.state.values
    this.formRef.current.submitForm()
    return formikValues
  }

  private close = () => {
    this.props.close!()
  }

  private save = () => {
    const formikValues = this.getFormikValues()

    var returned: AuthorizationLogDTO | undefined = new AuthorizationLogDTO(
      formikValues.data,
      this.props.schedule!.id,
      this.props.authorizationType!
    )

    createAuthorizationLog(returned).finally(() => {
      this.formRef.current.setSubmitting(false)

      this.setState({ loadingPdf: false })
      this.close()
    })
  }

  public formRef = React.createRef<any>()

  public render() {
    const { isOpen, schedule, authorizationType } = this.props

    var schema = Yup.object().shape({
      data: Yup.string().required('Details required'),
    })
    var title = ''
    var isDME = false
    var isPTLocation = false

    switch (schedule?.componentName) {
      case 'DME':
        title = 'DME Authorization Data'
        isDME = true
        break
      case 'PT Location':
        switch (authorizationType) {
          case DocumentType.PhysicalTherapy:
            title = 'Physical Therapy Authorization Data'
            break
          case DocumentType.OccupationalTherapy:
            title = 'Occupational Therapy Authorization Data'
            break
          case DocumentType.InHome:
            title = 'In Home Physical Therapy Authorization Data'
            break
          case DocumentType.InpatientRehab:
            title = 'Inpatient Rehab Authorization Data'
            break
          case DocumentType.WorkConditioning:
            title = 'Work Conditioning Authorization Data'
            break
          case DocumentType.FCE:
            title = 'FCE Authorization Data'
            break
        }
        isPTLocation = true
        break
    }

    return (
      <Dialog open={isOpen!} fullWidth maxWidth="sm">
        {this.state.loadingPdf && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <Formik
          initialValues={{}}
          onSubmit={() => {}}
          validationSchema={schema}
          ref={this.formRef}
        >
          {({ isValid }) => (
            <Form id="authorizationModalForm">
              <DialogTitle style={{ padding: 0 }}>
                <ResponsiveAppBar
                  title={title}
                  pageIcon={<Description />}
                  isModal={true}
                />
              </DialogTitle>
              <DialogContent>
                <Grid item xs={12}>
                  <Grid spacing={2} justifyContent="space-between">
                    {isDME ? (
                      <Grid
                        item
                        container
                        style={{ margin: '10px 0px' }}
                        xs={12}
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <FullWidthField
                            variant="outlined"
                            name="data"
                            label="DME Type, Cost, Total"
                            fullWidth
                            rows={9}
                            multiline
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    {isPTLocation ? (
                      <Grid
                        item
                        container
                        style={{ margin: '10px 0px' }}
                        xs={12}
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <FullWidthField
                            variant="outlined"
                            name="data"
                            label="Number of Visits, Frequency, Duration"
                            fullWidth
                            rows={9}
                            multiline
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={!isValid || this.state.loadingPdf}
                  variant="contained"
                  form="authorizationModalForm"
                  color="primary"
                  type="button"
                  onClick={() => {
                    this.save()
                    this.setState({ loadingPdf: true })
                  }}
                >
                  Submit Authorization
                </Button>
                <Button
                  onClick={this.close}
                  disabled={this.state.loadingPdf}
                  data-cy="close"
                >
                  Close
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  }
}

const InjectedAuthorizationModal = inject<
  IStores,
  IAuthorizationModalProps,
  Partial<IAuthorizationModalProps>,
  any
>((stores: IStores) => ({
  close: stores.schedules.closeAuthorizationModal,
  isLoading: stores.schedules.isLoading,
  isOpen: stores.schedules.isAuthorizationModalOpen,
  schedule: stores.schedules.selectedSchedule,
  authorizationType: stores.schedules.authorizationType,
}))(AuthorizationModal)

export default InjectedAuthorizationModal
