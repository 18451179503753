import { getState } from '../common/AddressField/AddressFormValues'
import { DefaultMedicareCostLookup, IMedicareCostLookup } from '../Definitions'

export enum NoteValue {
  Payment = 'C - Payment for TC Capped at Opps Amount',
  Performed = '# - Performed in a Facility Setting',
}

export interface IMedicareCostLookupFormValues {
  effectiveDate?: Date | null
  limitingCharge?: number
  locality?: string
  mod?: string
  nonParAmount?: number
  note?: string
  parAmount?: number
  procedure?: string
  state: string | { name: string } | undefined
  isActive: boolean
}

function setDefaultValues(): IMedicareCostLookupFormValues {
  return {
    effectiveDate: undefined,
    isActive: true,
    limitingCharge: undefined,
    locality: '',
    mod: '',
    nonParAmount: undefined,
    note: '',
    parAmount: undefined,
    procedure: '',
    state: undefined,
  }
}

function convertNoteValue(note?: string | undefined) {
  switch (note) {
    case '#':
      return NoteValue.Performed
    case 'C':
      return NoteValue.Payment
    case NoteValue.Performed:
      return '#'
    case NoteValue.Payment:
      return 'C'
    default:
      return ''
  }
}

function setValuesFromMedicareCostLookup(
  medicareCostLookup: IMedicareCostLookup
): IMedicareCostLookupFormValues {
  return {
    effectiveDate: medicareCostLookup.effectiveDate || undefined,
    isActive: medicareCostLookup.isActive,
    limitingCharge: medicareCostLookup.limitingCharge || undefined,
    locality: medicareCostLookup.locality || '',
    mod: medicareCostLookup.mod || '',
    nonParAmount: medicareCostLookup.nonParAmount || undefined,
    note: convertNoteValue(medicareCostLookup.note) || '',
    parAmount: medicareCostLookup.parAmount || undefined,
    procedure: medicareCostLookup.procedure || '',
    state: medicareCostLookup.state ? { name: medicareCostLookup.state } : undefined,
  }
}

export function createIMedicareCostLookupFormValues(
  medicareCostLookup?: IMedicareCostLookup
): IMedicareCostLookupFormValues {
  if (medicareCostLookup === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromMedicareCostLookup(medicareCostLookup)
  }
}

export function toMedicareCostLookupDto(
  formValues: IMedicareCostLookupFormValues,
  original?: IMedicareCostLookup
): IMedicareCostLookup {
  const medicareCostLookup =
    original === undefined ? DefaultMedicareCostLookup() : original
  const currentDate = new Date()
  return {
    ...medicareCostLookup,
    effectiveDate: formValues.effectiveDate || currentDate,
    isActive: formValues.isActive,
    limitingCharge: formValues.limitingCharge || 0,
    locality: formValues.locality || '',
    mod: formValues.mod || '',
    nonParAmount: formValues.nonParAmount || 0,
    note: convertNoteValue(formValues.note) || '',
    parAmount: formValues.parAmount || 0,
    procedure: formValues.procedure || '',
    state: getState(formValues.state),
  }
}
