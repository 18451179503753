import { GlobalInvoiceCPTCodeDTO } from './GlobalInvoiceCPTCodeDTO'
import { GlobalInvoiceLineItemDTO } from './GlobalInvoiceLineItemDTO'

export interface IGlobalInvoiceDTO {
  id?: string
  createdBy?: string
  createdDate?: Date
  quoteId?: string
  chargesTotal?: number
  UCChargesTotal?: number
  adjustedReconciledCost?: number
  dxCodes?: string
  suffix?: string
  globalInvoiceLineItems?: GlobalInvoiceLineItemDTO[]
  globalCPTCodes?: GlobalInvoiceCPTCodeDTO[]
}

export class GlobalInvoiceDTO implements IGlobalInvoiceDTO {
  public id?: string
  public createdBy?: string
  public createdDate?: Date
  public quoteId?: string
  public chargesTotal?: number
  public UCChargesTotal?: number
  public adjustedReconciledCost?: number
  public dxCodes?: string
  public suffix?: string
  public globalInvoiceLineItems?: GlobalInvoiceLineItemDTO[]
  public globalCPTCodes?: GlobalInvoiceCPTCodeDTO[]
  constructor(
    id?: string,
    createdBy?: string,
    createdDate?: Date,
    quoteId?: string,
    chargesTotal?: number,
    UCChargesTotal?: number,
    adjustedReconciledCost?: number,
    dxCodes?: string,
    suffix?: string,
    globalInvoiceLineItems?: GlobalInvoiceLineItemDTO[],
    globalCPTCodes?: GlobalInvoiceCPTCodeDTO[]
  ) {
    this.id = id
    this.createdBy = createdBy
    this.createdDate = createdDate
    this.quoteId = quoteId
    this.chargesTotal = chargesTotal
    this.UCChargesTotal = UCChargesTotal
    this.adjustedReconciledCost = adjustedReconciledCost
    this.dxCodes = dxCodes
    this.suffix = suffix
    this.globalInvoiceLineItems = globalInvoiceLineItems
    this.globalCPTCodes = globalCPTCodes
  }
}
