import { AddCircle, Edit, Lock, RemoveCircle } from '@material-ui/icons'
import { action, observable, runInAction } from 'mobx'
import { IUser } from '../../Definitions'
import { Client, IUserDto, SwaggerResponse, UserDto } from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import addDefaultUserFields from '../../utils/addDefaultUserFields'
import DataTableStore from '../DataTableStore'
import LoginStore from '../LoginStore'
import { GetUserOptions } from 'src/services/WorkflowService'
import { UserDropdownOption } from 'src/viewModels/UserDropdownOption'

const ColumnSettingsDto = 'UserDto'

export default class UsersStore {
  @observable
  public isLoading: boolean = false
  @observable
  public selectedUser?: IUser
  @observable
  public users: IUser[] = []
  @observable
  public isModalOpen: boolean
  @observable
  public isChangePasswordModalOpen: boolean
  public dataTableStore: DataTableStore<IUserDto, IUser>
  @observable
  public userOptions: UserDropdownOption[] = []

  constructor(
    private globalStore: GlobalStore,
    private loginStore: LoginStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IUserDto, IUser>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.apiUsersGet(
          filter,
          '',
          undefined,
          page,
          undefined,
          orderBy,
          includeInactives
        ),
      (users) => this.setupUsers(users)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedUser = undefined
  }

  @action.bound
  public closeChangePasswordDialog() {
    this.isChangePasswordModalOpen = false
  }

  @action.bound
  public disableUser(user: IUser) {
    this.isLoading = true
    return this.client
      .apiUsersByIdDelete(user.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getAllUsers(pageSize?: number, roleFilter?: string) {
    this.isLoading = true
    return this.client
      .apiUsersGet(
        '',
        roleFilter,
        this.globalStore.currentAppOrganization
          ? this.globalStore.currentAppOrganization.id
          : undefined,
        undefined,
        pageSize,
        undefined,
        true
      )
      .then((resp: SwaggerResponse<IUserDto[]>) =>
        runInAction(() => {
          this.users = this.setupUsers(resp.result)
        })
      )
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public enableUser(user: IUser) {
    user.isActive = true
    this.isLoading = true
    user.organization = undefined
    return this.client
      .apiUsersByIdPut(user.id, new UserDto(user))
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndUsers() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          this.dataTableStore.columns.forEach((col) => {
            if (col.fieldName === 'profileImageUrl') {
              col.isImage = true
            }
            if (!!col.sort) {
              this.dataTableStore.setTableOrderBy(col.sort)
            }
          })
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public openDialogWithUser(user: IUser) {
    this.isModalOpen = true
    this.selectedUser = user
  }

  @action.bound
  public openChangePasswordDialog() {
    this.isChangePasswordModalOpen = true
  }

  @action.bound
  public saveUser(user: IUser, imageHasChanged: boolean) {
    this.isLoading = true
    const isCreating = user.isNew
    let promise
    const userDto = new UserDto(user)

    userDto.createdOn = user.createdOn ? new Date(user.createdOn) : new Date()
    userDto.modifiedOn = user.modifiedOn ? new Date(user.modifiedOn) : undefined
    userDto.deletedOn = user.deletedOn ? new Date(user.deletedOn) : undefined

    if (isCreating) {
      promise = this.client.apiUsersPost(userDto)
    } else {
      promise = this.client.apiUsersByIdPut(user.id, userDto)
    }
    return promise
      .then((resp: SwaggerResponse<UserDto>) => {
        const data = resp.result
        this.updateCurrentUser(addDefaultUserFields(data))
        if (imageHasChanged) {
          data.profileImageContent = user.profileImageContent
          data.profileImageMimeType = user.profileImageMimeType
          data.profileImageName = user.profileImageName
          data.profileImageUrl = user.profileImageUrl
          this.client.apiUsersProfileImagePost(data).then(() => {
            this.resetUIState(isCreating)
          })
        } else {
          this.resetUIState(isCreating)
        }
      })

      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  public checkUsernameAvailability = (user: IUser) => {
    if (user.isNew) {
      user.id = ''
    }
    return this.client
      .apiUsersUsernameAvailableGet(user.id, user.userName)
      .then((response: SwaggerResponse<boolean>) => {
        return response.result
      })
  }

  public checkEmailAvailability = (user: IUser) => {
    if (user.isNew) {
      user.id = ''
    }
    return this.client
      .apiUsersEmailAvailableGet(user.id, user.email)
      .then((response: SwaggerResponse<boolean>) => {
        return response.result
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
    this.closeChangePasswordDialog()
  }

  private setupUsers = (users: IUserDto[]) => {
    return users.map((x) => this.setupUserMenuItems(addDefaultUserFields(x)))
  }

  private setupUserMenuItems = (user: IUser) => {
    user.displayRoles = user.roles.join(', ')
    user.menuItems = [
      {
        icon: Edit,
        isConfirm: false,
        name: 'Edit',
        onClick: () => this.openDialogWithUser(user),
      },
      {
        color: '#29348F',
        icon: Lock,
        isConfirm: true,
        name: 'Reset Password',
        onClick: () => this.loginStore.forgotPassword(user.email),
      },
    ]
    if (user.isActive) {
      user.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableUser(user),
      })
    } else {
      user.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Reenable',
        onClick: () => this.enableUser(user),
      })
    }
    return user
  }

  private updateCurrentUser(user: IUser) {
    if (user.id === this.globalStore.user!.id) {
      this.globalStore.setUser(user)
    }
  }

  @action.bound
  public async getUserOptions(inboxId: string, actionId: string) {
    this.userOptions = await GetUserOptions(inboxId, actionId)
  }
}
