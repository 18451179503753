export interface IInboxSummary {
  id: number
  inboxName: string
  inboxType: string
  itemType: string
  itemTypeName: string
  enabled: boolean
}

export class InboxSummary implements IInboxSummary {
  public id: number
  public inboxName: string
  public inboxType: string
  public itemType: string
  public itemTypeName: string
  public enabled: boolean
}
