import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core'
import { ReceiptOutlined } from '@material-ui/icons'
import { CashRefund } from 'mdi-material-ui'
import { inject } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import { Helmet } from 'react-helmet'
import ReactPaginate from 'react-paginate'
import { IStores } from 'src/Stores'
import ProcessReceivedCheckModal from 'src/common/ProcessReceivedCheckModal'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import AppealLogModal from 'src/search/AppealLogModal'
import { searchProviderRefunds } from 'src/services/SearchService'
import { formatCurrency } from 'src/utils/Formatter'
import { ProviderRefundsSearchRequest } from 'src/viewModels/ProviderRefundsSearchRequest'
import { ProviderRefundsSummaryResult } from 'src/viewModels/ProviderRefundsSummaryResult'
import { pagedList } from 'src/viewModels/pagedList'
import ReceiveRefundPaymentDialog from './ReceiveRefundPaymentDialog'
import { ProcessReceivedCheckResults } from 'src/viewModels/ProcessReceivedCheckResult'

const styles = () => createStyles({})

interface IProviderRefundsProps extends WithStyles<typeof styles> {
  openAppealLogModal?: (claimId: string) => void
  closeAppealLogModal?: () => void
  selectedClaimId?: string
  isAppealLogModalOpen?: boolean
  loadReceiveRefundPaymentData: (cms1500Id: string) => void
  setSelectedCMS1500Id: (cms1500Id?: string) => void
  setUpdateParentStateAfterModal: (parentfunction?: () => void) => void
  closePaymentModal: () => void
  isPaymentResultOpen?: boolean
  paymentResults?: ProcessReceivedCheckResults
  isLoading: boolean
}

class ProviderRefunds extends React.Component<IProviderRefundsProps> {
  public componentDidMount() {
    this.props.setUpdateParentStateAfterModal(this.getProviderRefundsData)
    this.getProviderRefundsData()
  }

  private getProviderRefundsData = async () => {
    this.setState({ gridLoading: true })
    const { page, pageSize } = this.state

    const data: ProviderRefundsSearchRequest = {
      page: page,
      pageSize: pageSize,
    }

    await searchProviderRefunds(data).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        gridLoading: false,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private receiveRefund = (cms1500Id: string) => {
    this.props.setSelectedCMS1500Id(cms1500Id)
    this.props.loadReceiveRefundPaymentData(cms1500Id)
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getProviderRefundsData())
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={7} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className="gridPadding" key={index} style={{ paddingLeft: '30px' }}>
          <TableCell style={{ whiteSpace: 'nowrap' }}>
            <Button
              size="medium"
              variant="outlined"
              className="gridButton"
              color="primary"
              onClick={() => this.receiveRefund(record.appealId!)}
            >
              Receive Refund
            </Button>
          </TableCell>
          <TableCell>{record.patientName}</TableCell>
          <TableCell>{record.eocLegacyGroup}</TableCell>
          <TableCell align="right">
            {moment(record.dateOfService).format('MM/DD/YYYY')}
          </TableCell>
          <TableCell>{record.providerGroupName}</TableCell>
          <TableCell align="right">
            {record.daysPastDue}&nbsp;&nbsp;
            <Tooltip title={'View Appeal Log'}>
              <ReceiptOutlined
                style={{ cursor: 'pointer' }}
                fontSize="small"
                onClick={() => {
                  this.props.openAppealLogModal!(record.claimId)
                  this.setState({ selectedGroupInfo: record.eocLegacyGroup })
                }}
              />
            </Tooltip>
          </TableCell>
          <TableCell align="right">
            {formatCurrency(record.refundBalance.toString())}
          </TableCell>
        </TableRow>
      ))
    }
  }

  public state = {
    gridData: new pagedList().items as ProviderRefundsSummaryResult[],
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 20,
    gridLoading: false,
    selectedGroupInfo: '',
  }

  public render() {
    return (
      <>
        <Helmet>
          <title>Receive Provider Refunds</title>
        </Helmet>
        <Grid container>
          <ProcessReceivedCheckModal />
          <ReceiveRefundPaymentDialog />
          <ResponsiveAppBar title="Process Refunds" pageIcon={<CashRefund />} />
          {this.props.selectedClaimId && this.props.isAppealLogModalOpen ? (
            <AppealLogModal
              claimId={this.props.selectedClaimId}
              isOpen={this.props.isAppealLogModalOpen}
              closeModal={this.props.closeAppealLogModal!}
              groupInfo={this.state.selectedGroupInfo}
            />
          ) : (
            <></>
          )}
          {(this.props.isLoading || this.state.gridLoading) && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '2',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <Grid container style={{ margin: '10px', marginLeft: '0px' }} xs={12}>
            <Typography
              color="primary"
              component="h6"
              variant="h6"
              gutterBottom
              style={{ paddingBottom: '10px', marginLeft: '30px' }}
            >
              RECEIVE PROVIDER REFUNDS
            </Typography>
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              alignContent="center"
              alignItems="flex-start"
              wrap="wrap"
            >
              <Grid item xs={12}>
                <Table aria-label="simple table" style={{ padding: '20' }}>
                  <TableHead>
                    <TableRow className="gridPadding" style={{ paddingLeft: '30px' }}>
                      <TableCell />
                      <TableCell>Patient</TableCell>
                      <TableCell>EOC/Legacy</TableCell>
                      <TableCell align="right">DOS</TableCell>
                      <TableCell>Provider Group</TableCell>
                      <TableCell align="right">Days Past Due</TableCell>
                      <TableCell align="right">Refund Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ width: '100%' }}>{this.mapGridData()}</TableBody>
                </Table>
                <div className="pagination-row">
                  {this.paginationInfo()}
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    onPageChange={this.handlePageChange}
                    forcePage={this.state.page - 1}
                    pageCount={this.state.pageCount}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    //@ts-ignore
                    renderOnZeroPageCount={null}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
}

const InjectedProviderRefunds = inject<
  IStores,
  IProviderRefundsProps,
  Partial<IProviderRefundsProps>,
  any
>((stores: IStores) => ({
  openAppealLogModal: stores.cms1500.OpenAppealLogModal,
  closeAppealLogModal: stores.cms1500.closeAppealLogModal,
  isAppealLogModalOpen: stores.cms1500.isAppealLogModalOpen,
  loadReceiveRefundPaymentData: stores.providerRefundStore.loadReceivePaymentData,
  selectedClaimId: stores.cms1500.selectedClaimId,
  setSelectedCMS1500Id: stores.providerRefundStore.setSelectedCMS1500Id,
  setUpdateParentStateAfterModal: stores.providerRefundStore.setParentUpdateFunction,
  closePaymentModal: stores.providerRefundStore.closeProcessReceivedCheck,
  isPaymentResultOpen: stores.providerRefundStore.isProcessRefundCheckOpen,
  paymentResults: stores.providerRefundStore.checkResults,
  isLoading: stores.providerRefundStore.isLoading,
}))(ProviderRefunds)

export default withStyles(styles)(InjectedProviderRefunds)
