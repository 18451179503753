export function formatCurrency(value: string): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formatter.format(Number(value))
}

export function formatDate(value: string): string {
  if (value === '' || typeof value === 'undefined') return ''
  const parts = value.split('-')
  const date = new Date(
    Number(parts[0]),
    Number(parts[1]) - 1,
    Number(parts[2].substring(0, 2))
  )
  const formattedDate = date.toLocaleString('en-US').split(' ')[0]
  return formattedDate.replace(',', '')
}
