export interface IContactSummary {
  name: string
  email: string
  phone: string
  fax: string
}

export class ContactSummary implements IContactSummary {
  public name: string
  public email: string
  public phone: string
  public fax: string
}
