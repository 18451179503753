import {
  ClaimEventDto,
  EpisodeOfCareDto,
  PatientDto,
  PhysicianDto,
  ScheduleAppointmentTaskDto,
  ScheduleProviderLocationDto,
  ScheduleTestDto,
} from 'src/generated_client'

export class ScheduleDto implements IScheduleDto {
  organizationId?: string | undefined
  name?: string | undefined
  isActive?: boolean | undefined
  componentId?: string | undefined
  componentName?: string | undefined
  isSimpleEvent?: boolean | undefined
  excludeFromBundle: boolean | undefined
  excludeFromPTCount: boolean | undefined
  patientId?: string | undefined
  patient?: PatientDto | undefined
  episodeOfCareId?: string | undefined
  episodeOfCare?: EpisodeOfCareDto | undefined
  comments?: string | undefined
  appointmentDate?: Date | undefined
  appointmentTime?: string | undefined
  isTBD?: boolean | undefined
  appointmentTasks?: ScheduleAppointmentTaskDto[] | undefined
  scheduleTests?: ScheduleTestDto[] | undefined
  tests?: string | undefined
  bodyParts?: string | undefined
  authorizationComment?: string | undefined
  appointmentConfirmationComment?: string | undefined
  scheduleProviderLocations?: ScheduleProviderLocationDto[] | undefined
  primaryLocationName: string
  secondaryLocationNames?: string | undefined
  claimEvents?: ClaimEventDto[] | undefined
  physicianId?: string | undefined
  physician?: PhysicianDto | undefined
  rescheduled?: boolean | undefined
  numberOfClaims?: string

  constructor(data?: IScheduleDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.organizationId = data['organizationId']
      this.name = data['name']
      this.isActive = data['isActive']
      this.componentId = data['componentId']
      this.componentName = data['componentName']
      this.isSimpleEvent = data['isSimpleEvent']
      this.excludeFromBundle = data['excludeFromBundle']
      this.excludeFromPTCount = data['excludeFromPTCount']
      this.patientId = data['patientId']
      this.patient = data['patient'] ? PatientDto.fromJS(data['patient']) : <any>undefined
      this.episodeOfCareId = data['episodeOfCareId']
      this.episodeOfCare = data['episodeOfCare']
        ? EpisodeOfCareDto.fromJS(data['episodeOfCare'])
        : <any>undefined
      this.comments = data['comments']
      this.appointmentDate = data['appointmentDate']
        ? new Date(data['appointmentDate'].toString())
        : <any>undefined
      this.appointmentTime = data['appointmentTime']
        ? data['appointmentTime']
        : <any>undefined
      this.isTBD = data['isTBD']
      if (data['appointmentTasks'] && data['appointmentTasks'].constructor === Array) {
        this.appointmentTasks = []
        for (let item of data['appointmentTasks'])
          this.appointmentTasks.push(ScheduleAppointmentTaskDto.fromJS(item))
      }
      if (data['scheduleTests'] && data['scheduleTests'].constructor === Array) {
        this.scheduleTests = []
        for (let item of data['scheduleTests'])
          this.scheduleTests.push(ScheduleTestDto.fromJS(item))
      }
      this.tests = data['tests']
      this.bodyParts = data['bodyParts']
      this.authorizationComment = data['authorizationComment']
      this.appointmentConfirmationComment = data['appointmentConfirmationComment']
      if (
        data['scheduleProviderLocations'] &&
        data['scheduleProviderLocations'].constructor === Array
      ) {
        this.scheduleProviderLocations = []
        for (let item of data['scheduleProviderLocations'])
          this.scheduleProviderLocations.push(ScheduleProviderLocationDto.fromJS(item))
      }
      this.primaryLocationName = data['primaryLocationName']
      this.secondaryLocationNames = data['secondaryLocationNames']
      if (data['claimEvents'] && data['claimEvents'].constructor === Array) {
        this.claimEvents = []
        for (let item of data['claimEvents'])
          this.claimEvents.push(ClaimEventDto.fromJS(item))
      }
      this.physicianId = data['physicianId']
      this.physician = data['physician']
        ? PhysicianDto.fromJS(data['physician'])
        : <any>undefined
      this.rescheduled = data['rescheduled']
      this.numberOfClaims = data['numberOfClaims']
    }
  }

  static fromJS(data: any): ScheduleDto {
    data = typeof data === 'object' ? data : {}
    let result = new ScheduleDto()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['organizationId'] = this.organizationId
    data['name'] = this.name
    data['isActive'] = this.isActive
    data['componentId'] = this.componentId
    data['componentName'] = this.componentName
    data['isSimpleEvent'] = this.isSimpleEvent
    data['excludeFromBundle'] = this.excludeFromBundle
    data['excludeFromPTCount'] = this.excludeFromPTCount
    data['patientId'] = this.patientId
    data['patient'] = this.patient ? this.patient.toJSON() : <any>undefined
    data['episodeOfCareId'] = this.episodeOfCareId
    data['episodeOfCare'] = this.episodeOfCare
      ? this.episodeOfCare.toJSON()
      : <any>undefined
    data['comments'] = this.comments
    data['appointmentDate'] = this.appointmentDate
      ? this.appointmentDate.toISOString()
      : <any>undefined
    data['appointmentTime'] = this.appointmentTime ? this.appointmentTime : <any>undefined
    data['isTBD'] = this.isTBD
    if (this.appointmentTasks && this.appointmentTasks.constructor === Array) {
      data['appointmentTasks'] = []
      for (let item of this.appointmentTasks) data['appointmentTasks'].push(item.toJSON())
    }
    if (this.scheduleTests && this.scheduleTests.constructor === Array) {
      data['scheduleTests'] = []
      for (let item of this.scheduleTests) data['scheduleTests'].push(item.toJSON())
    }
    data['tests'] = this.tests
    data['bodyParts'] = this.bodyParts
    data['authorizationComment'] = this.authorizationComment
    data['appointmentConfirmationComment'] = this.appointmentConfirmationComment
    if (
      this.scheduleProviderLocations &&
      this.scheduleProviderLocations.constructor === Array
    ) {
      data['scheduleProviderLocations'] = []
      for (let item of this.scheduleProviderLocations)
        data['scheduleProviderLocations'].push(item.toJSON())
    }
    data['primaryLocationName'] = this.primaryLocationName
    data['secondaryLocationNames'] = this.secondaryLocationNames
    if (this.claimEvents && this.claimEvents.constructor === Array) {
      data['claimEvents'] = []
      for (let item of this.claimEvents) data['claimEvents'].push(item.toJSON())
    }
    data['physicianId'] = this.physicianId
    data['physician'] = this.physician ? this.physician.toJSON() : <any>undefined
    data['rescheduled'] = this.rescheduled
    data['numberOfClaims'] = this.numberOfClaims
    return data
  }
}

export interface IScheduleDto {
  organizationId?: string | undefined
  name?: string | undefined
  isActive?: boolean | undefined
  componentId?: string | undefined
  componentName?: string | undefined
  isSimpleEvent?: boolean | undefined
  excludeFromPTCount?: boolean | undefined
  excludeFromBundle?: boolean | undefined
  patientId?: string | undefined
  patient?: PatientDto | undefined
  episodeOfCareId?: string | undefined
  episodeOfCare?: EpisodeOfCareDto | undefined
  comments?: string | undefined
  appointmentDate?: Date | undefined
  appointmentTime?: string | undefined
  isTBD?: boolean | undefined
  appointmentTasks?: ScheduleAppointmentTaskDto[] | undefined
  scheduleTests?: ScheduleTestDto[] | undefined
  tests?: string | undefined
  bodyParts?: string | undefined
  authorizationComment?: string | undefined
  appointmentConfirmationComment?: string | undefined
  scheduleProviderLocations?: ScheduleProviderLocationDto[] | undefined
  primaryLocationName?: string | undefined
  secondaryLocationNames?: string | undefined
  claimEvents?: ClaimEventDto[] | undefined
  physicianId?: string | undefined
  physician?: PhysicianDto | undefined
  rescheduled?: boolean | undefined
  numberOfClaims?: string | undefined
}
