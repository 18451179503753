import { AddCircle, Edit, FileCopy, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import uuidv4 from 'uuid/v4'
import { DefaultContract, IContract } from '../../Definitions'
import {
  Client,
  CodeDto,
  ContractAttachmentFileDto,
  ContractDto,
  IContractDto,
  MultipleProcedureDiscountDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'
import NotificationStore from '../NotificationStore'
import ProvidersStore from '../ProvidersStore'
import QuotesStore from '../QuotesStore'

const ColumnSettingsDto = 'ContractDto'

export default class ContractsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public contracts: IContract[]
  @observable
  public singleCaseAgreementsOnly: boolean = false
  @observable
  public selectedContract?: IContract
  @observable
  public isContractsDialogOpen: boolean = false
  @observable
  public contractNote?: string
  public dataTableStore: DataTableStore<IContractDto, IContract>

  constructor(
    private globalStore: GlobalStore,
    private providerStore: ProvidersStore,
    private quotesStore: QuotesStore,
    private client: Client,
    private notificationStore: NotificationStore
  ) {
    this.dataTableStore = new DataTableStore<IContractDto, IContract>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllContracts(
          this.providerStore.selectedProvider!.id,
          filter,
          this.singleCaseAgreementsOnly,
          page,
          5,
          orderBy,
          includeInactives
        ),
      (contract) => this.setupContracts(contract)
    )
  }

  @action.bound
  public setContractNote(note?: string) {
    this.contractNote = note
  }

  @action.bound
  public openContractsDialog() {
    this.isContractsDialogOpen = true
  }

  @action.bound
  public async closeContractsDialog(reloadSelectedLineItem: boolean = false) {
    if (reloadSelectedLineItem) {
      await this.client
        .lookupMedicareCosts(
          this.quotesStore.selectedLineItem!.id,
          this.quotesStore.selectedLineItem!.providerId,
          undefined,
          this.quotesStore.selectedLineItem!.cptCode,
          ''
        )
        .then((resp) => {
          this.quotesStore.selectedLineItem!.cptCostLookups = resp.result
        })
        .finally(() => {
          this.isContractsDialogOpen = false
        })
    } else {
      this.isContractsDialogOpen = false
    }
  }

  @action.bound
  public setSingleCaseAgreementsOnly(value: boolean) {
    this.singleCaseAgreementsOnly = value
    this.dataTableStore.loadData()
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedContract = undefined
  }

  @action.bound
  public disableContract(contract: IContract) {
    this.isLoading = true
    return this.client
      .archiveContract(this.providerStore.selectedProvider!.id, contract.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public downloadAttachmentFile(url?: string, error?: string) {
    if (!url || url === '') {
      this.notificationStore.sendNotification(
        '404',
        error ? error : 'No file exists to download.',
        {
          variant: 'error',
        }
      )
    } else {
      window.open(url)
    }
  }

  @action.bound
  public getColumnSettingsAndContracts() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllContracts() {
    this.isLoading = true
    return this.client
      .getAllContracts(this.providerStore.selectedProvider!.id)
      .then((resp: SwaggerResponse<IContractDto[]>) =>
        runInAction(() => {
          this.contracts = this.setupContracts(resp.result)
        })
      )
  }

  private copyCodes(codes: CodeDto[], contractId: string) {
    const newCodes: CodeDto[] = []

    codes.forEach((c) => {
      newCodes.push({
        ...c,
        contractId,
        id: uuidv4(),
      } as CodeDto)
    })

    return newCodes
  }

  private copyMultipleProcedureDiscounts(
    MPDs: MultipleProcedureDiscountDto[],
    contractId: string
  ) {
    const newMPDs: CodeDto[] = []

    MPDs.forEach((m) => {
      newMPDs.push({
        ...m,
        contractId,
        id: uuidv4(),
      } as MultipleProcedureDiscountDto)
    })

    return newMPDs
  }

  private copyAttachmentFiles(
    attachments: ContractAttachmentFileDto[],
    contractId: string
  ) {
    const newAttachments: ContractAttachmentFileDto[] = []

    attachments.forEach((a) => {
      newAttachments.push({
        attachmentFile: { ...a.attachmentFile, id: uuidv4() },
        contractId,
      } as ContractAttachmentFileDto)
    })

    return newAttachments
  }

  @action.bound
  public openDialogWithContract(contract: IContract) {
    this.isModalOpen = true
    this.selectedContract = contract
    this.orderMultipleProcedureDiscountsOnSelectedContract()
  }

  @action.bound
  public copyDialogWithContract(contract: IContract) {
    contract.isNew = true
    contract.isCopy = true

    const newContractId = uuidv4()
    const newContract = {
      ...contract,
      codes: this.copyCodes(contract.codes, newContractId),
      contractAttachmentFiles: this.copyAttachmentFiles(
        contract.contractAttachmentFiles!,
        newContractId
      ),
      id: newContractId,
      multipleProcedureDiscounts: this.copyMultipleProcedureDiscounts(
        contract.multipleProcedureDiscounts,
        newContractId
      ),
    } as IContract

    this.isModalOpen = true
    this.selectedContract = newContract
    this.orderMultipleProcedureDiscountsOnSelectedContract()
  }

  @action.bound
  public orderMultipleProcedureDiscountsOnSelectedContract() {
    this.selectedContract!.multipleProcedureDiscounts.splice(
      0,
      this.selectedContract!.multipleProcedureDiscounts.length,
      ...this.selectedContract!.multipleProcedureDiscounts.slice().sort(
        (mpd1: MultipleProcedureDiscountDto, mpd2: MultipleProcedureDiscountDto) => {
          if (mpd1.procedureNumber && mpd2.procedureNumber) {
            if (mpd1.procedureNumber < mpd2.procedureNumber) {
              return -1
            }
            if (mpd1.procedureNumber > mpd2.procedureNumber) {
              return 1
            }
          }
          return 0
        }
      )
    )
  }

  @action.bound
  public recoverContract(contract: IContract) {
    this.isLoading = true
    return this.client
      .reactivateContract(this.providerStore.selectedProvider!.id, contract.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveContract(contract: IContract) {
    this.isLoading = true
    let promise
    const contractDto = new ContractDto(contract)
    if (contract.isNew) {
      promise = this.client.createContract(
        this.providerStore.selectedProvider!.id,
        contractDto
      )
    } else {
      promise = this.client.updateContract(
        this.providerStore.selectedProvider!.id,
        contract.id,
        contractDto
      )
    }
    return promise
      .then(() => {
        this.resetUIState(contract.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public openContractDialogById(providerId: string, contractId: string) {
    return this.client.getContractById(providerId, contractId).then(({ result }) => {
      const contract = this.addDefaultFields(result)
      this.openDialogWithContract(contract)
    })
  }

  private setupContracts = (contracts: IContractDto[]) => {
    return contracts.map((x) => this.setupContractMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (contract: IContractDto): IContract => {
    return { ...DefaultContract(), ...contract }
  }

  private setupContractMenuItems = (contract: IContract) => {
    contract.menuItems = [
      {
        icon: Edit,
        name: 'Edit',
        onClick: () => this.openDialogWithContract(contract),
      },
    ]
    if (contract.isActive) {
      contract.menuItems.push({
        color: '#29348F',
        icon: FileCopy,
        name: 'Copy',
        onClick: () => this.copyDialogWithContract(contract),
      })
      contract.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableContract(contract),
      })
    } else {
      contract.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverContract(contract),
      })
    }
    return contract
  }
}
