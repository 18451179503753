import Axios from 'axios'
import createPdfObjUrl from 'src/utils/CreatePdfObjUrl'
import { AuthorizationLogDTO } from 'src/viewModels/AuthorizationLogDTO'
import { IAuthorizationLogSearchRequest } from 'src/viewModels/AuthorizationLogSearchRequest'
import { pagedList } from 'src/viewModels/pagedList'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function getAuthorizationLogsByEpisodeOfCareId(
  data: IAuthorizationLogSearchRequest
) {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/authorization-log/episode-of-care/${data.episodeOfCareId}/authorization-logs`,
    data
  )

  return response.data
}

export async function createAuthorizationLog(data: AuthorizationLogDTO | undefined) {
  const response = await Axios.post<string>(`${base_url}/api/authorization-log`, data)
  window.open(createPdfObjUrl(response.data), '_blank')
  return response.data
}

export async function createContractLog(data: AuthorizationLogDTO | undefined) {
  const response = await Axios.post<string>(`${base_url}/api/contract-log`, data, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
  })
  // Extract filename from header
  const filename = response.headers['content-disposition']
    .split(';')
    .find((n) => n.includes('filename='))
    ?.replace('filename="', '')
    .replace('"', '')
    .trim()
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename!)
  document.body.appendChild(link)
  link.click()
  link.remove()
  return response.data
}
