import GlobalStore from '../../stores/GlobalStore/GlobalStore'
import NotificationStore from '../../stores/NotificationStore/NotificationStore'
import { Interceptor } from '../FetchService'

function createErrorInterceptor(
  globalStore: GlobalStore,
  notificationStore: NotificationStore
): Interceptor {
  return (next) => async (request: Request) => {
    const response: Response = await next(request)
    if (!response.ok) {
      const data = await response.text()
      if (response.status === 401) {
        globalStore.clearUserAndRouteToLogin()
        notificationStore.sendNotification(
          '401',
          data === '' ? 'Session has ended. Please login.' : data,
          {
            variant: 'error',
          }
        )
      } else if (data === '') {
        notificationStore.sendNotification(
          response.url,
          'An error has occurred: ' + response.statusText,
          { variant: 'error' }
        )
      } else {
        try {
          const parsedData = JSON.parse(data)
          notificationStore.sendNotification(
            response.url,
            parsedData ? parsedData.message : 'An error has occurred',
            { variant: 'error' }
          )
        } catch {
          notificationStore.sendNotification(response.url, data, {
            variant: 'error',
          })
        }
      }
      return Promise.reject(response)
    }
    return response
  }
}

export default createErrorInterceptor
