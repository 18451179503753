import { IProvider } from '../../Definitions'
import {
  IProviderFormValues,
  setValuesFromProvider,
} from '../../providers/ProviderFormValues'

export interface IBillTableFormValues {
  providers: IProviderFormValues[]
  isChecked?: boolean
}

export function createIBillTableFormValues(providers: IProvider[]): IBillTableFormValues {
  return {
    providers: providers.map((x) => setValuesFromProvider(x)),
  }
}
