import { DefaultBodyPart, IBodyPart } from '../Definitions'

export interface IBodyPartFormValues {
  readonly id: string
  name: string
  isActive: boolean
}

function setDefaultValues(): IBodyPartFormValues {
  return {
    id: '',
    isActive: false,
    name: '',
  }
}

function setValuesFromBodyPart(bodyPart: IBodyPart): IBodyPartFormValues {
  return {
    id: bodyPart.id || '',
    isActive: bodyPart.isActive || false,
    name: bodyPart.name || '',
  }
}

export function createIBodyPartFormValues(bodyPart?: IBodyPart): IBodyPartFormValues {
  if (bodyPart === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromBodyPart(bodyPart)
  }
}

export function toIBodyPart(
  formValues: IBodyPartFormValues,
  original?: IBodyPart
): IBodyPart {
  const bodyPart = original === undefined ? DefaultBodyPart() : original
  return {
    ...bodyPart,
    id: formValues.id,
    isActive: formValues.isActive,
    name: formValues.name,
  }
}
