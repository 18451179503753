import { Button, Tooltip } from '@material-ui/core'
import React from 'react'
import { getStagedDocument } from 'src/services/WorkflowService'
import createDocumentUrl from 'src/utils/createDocumentUrl'

interface IClaimDocumentButtonProps {
  docId: string
  setIsLoading: (loading: boolean) => void
}

function ClaimDocumentButton(props: IClaimDocumentButtonProps) {
  async function openDoc(event: any) {
    event.stopPropagation()
    props.setIsLoading(true)
    await getStagedDocument(props.docId).then((result) => {
      var doc = createDocumentUrl(result.content.toString(), result.mime)
      window.open(doc, 'new', 'width=1200,height=800')
      props.setIsLoading(false)
    })
  }

  return (
    <Tooltip title={props.docId == '' ? 'Original document not available' : ''}>
      <span>
        <Button
          variant="contained"
          disabled={props.docId == ''}
          color="primary"
          onClick={(e) => openDoc(e)}
        >
          View Original Document
        </Button>
      </span>
    </Tooltip>
  )
}

export default ClaimDocumentButton
