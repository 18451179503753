import { AddCircle, Edit, Lock, RemoveCircle } from '@material-ui/icons'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import React from 'react'
import { IColumnSetting } from '../../common/ESDataTable'
import { DefaultTag, ITag } from '../../Definitions'
import { Client, ITagDto, SwaggerResponse, TagDto } from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'TagDto'

export default class TagsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public isDeleteDialogOpen: boolean
  @observable
  public tags: TagDto[] = []
  @observable
  public selectedTagReferences: number
  @observable
  public selectedTag?: ITag
  public dataTableStore: DataTableStore<ITagDto, ITag>

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<ITagDto, ITag>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllTags(filter, page, undefined, orderBy, includeInactives, true),
      (communicationTag) => this.setupTags(communicationTag)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedTag = undefined
  }

  @action.bound
  public getTagReferences() {
    this.isLoading = true
    return this.client
      .getTagReferences(this.selectedTag!.id)
      .then((data) => {
        runInAction(() => {
          this.selectedTagReferences = data.result
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public disableTag(communicationTag: ITag) {
    this.isLoading = true
    return this.client
      .archiveTag(communicationTag.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndTags() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
          this.addLockIcon()
          this.addColorIcon()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  private addColorIcon() {
    this.dataTableStore.columns.push({
      columnName: 'Tag Color',
      fieldName: 'color',
      isVisible: true,
      type: 'React.Component',
    } as IColumnSetting)
  }

  private addLockIcon() {
    this.dataTableStore.columns.unshift({
      columnName: 'Locked',
      fieldName: 'lock',
      isVisible: true,
      type: 'React.Component',
    } as IColumnSetting)
  }

  @action.bound
  public getAllTags(filter?: string | undefined, includeSystem: boolean = true) {
    this.isLoading = true
    return this.client
      .getAllTags(filter, 1, 5000, undefined, false, includeSystem)
      .then((resp: SwaggerResponse<TagDto[]>) =>
        runInAction(() => {
          this.tags = resp.result
        })
      )
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public openDialogWithTag(communicationTag: ITag) {
    this.isModalOpen = true
    this.selectedTag = communicationTag
  }

  @action.bound
  public recoverTag(communicationTag: ITag) {
    this.isLoading = true
    return this.client
      .reactivateTag(communicationTag.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveTag(communicationTag: ITag) {
    this.isLoading = true
    let promise
    const communicationTagDto = new TagDto(communicationTag)
    if (communicationTag.isNew) {
      promise = this.client.createTag(communicationTagDto)
    } else {
      promise = this.client.updateTag(communicationTag.id, communicationTagDto)
    }
    return promise
      .then(() => {
        this.resetUIState(communicationTag.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupTags = (tags: ITagDto[]) => {
    return tags.map((x) => this.setupTagMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (communicationTag: ITagDto): ITag => {
    return { ...DefaultTag(), ...communicationTag }
  }

  private setupTagMenuItems = (communicationTag: ITag) => {
    if (!communicationTag.isLocked) {
      communicationTag.menuItems = [
        {
          icon: Edit,
          name: 'Edit',
          onClick: () => this.openDialogWithTag(communicationTag),
        },
      ]
      if (communicationTag.isActive) {
        communicationTag.menuItems.push({
          color: 'red',
          icon: RemoveCircle,
          isConfirm: true,
          name: 'Delete',
          onClick: () => {
            this.openDeleteDialog(communicationTag)
          },
        })
      } else {
        communicationTag.menuItems.push({
          color: '#94D33D',
          icon: AddCircle,
          isConfirm: true,
          name: 'Recover',
          onClick: () => this.recoverTag(communicationTag),
        })
      }
    }
    communicationTag.color = this.getColorIcon(communicationTag)
    communicationTag.lock = this.getLockedIcon(communicationTag.isLocked!)

    return communicationTag
  }

  @action.bound
  public openDeleteDialog = (communicationTag: ITag) => {
    this.selectedTag = communicationTag
    this.getTagReferences().then(() => {
      this.isDeleteDialogOpen = true
    })
  }

  @action.bound
  public closeDeleteDialog() {
    this.isDeleteDialogOpen = false
    this.selectedTag = undefined
  }

  @action.bound
  public getColorIcon = (communicationTag: ITag) => {
    return <FiberManualRecordIcon htmlColor={communicationTag.tagColor} />
  }
  @action.bound
  public getLockedIcon = (isLocked: boolean) => {
    if (isLocked) {
      return <Lock htmlColor="#29348F" />
    } else {
      return undefined
    }
  }
}
