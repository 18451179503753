import {
  Button,
  createStyles,
  Grid,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { Chat, FolderShared, LocalHospital, Person, Place } from '@material-ui/icons'
import { Form, Formik, FormikActions } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Prompt } from 'react-router-dom'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import Tab from '../common/Tabs/Tab'
import Tabs from '../common/Tabs/Tabs'
import Contacts from '../contacts/Contacts'
import { ICommunication, ILocation, IProvider } from '../Definitions'
import { ContactDto, ProviderTypeDto, TagDto } from '../generated_client'
import Locations from '../locations/Locations'
import Physicians from '../physicians/Physicians'
import { IStores } from '../Stores'
import * as Yup from 'yup'
import EditCommunicationsLog from './EditCommunicationsLog'
import ProviderDetails from './ProviderDetails'
import {
  createIProviderFormValues,
  IProviderFormValues,
  toIProvider,
} from './ProviderFormValues'
import { Helmet } from 'react-helmet'
import ChangeGroupModal from './ChangeGroupModal'

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(5),
    },
  })

interface IEditProviderRouteParams {
  providerId?: string
}

interface IEditProviderProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IEditProviderRouteParams> {
  provider?: IProvider
  providerTypes?: ProviderTypeDto[]
  loadProvider?: (id?: string) => Promise<void[]>
  saveProvider?: (provider: IProvider, stayInProviderPage?: boolean) => Promise<void>
  setSelectedProvider?: (provider: IProvider | undefined) => void
  setSelectedTags?: (tags: TagDto[]) => void
  communications?: ICommunication[]
  loadCommunications?: () => Promise<void>
  emptyLoadedCommunications?: () => void
  isAdmin?: boolean
  getImportantContacts?: (provider: IProvider) => Promise<void>
  getProviders?: (filter: string) => Promise<void>
  importantContacts?: ContactDto[]
  tags?: TagDto[]
  selectedTags?: TagDto[]
  getColumnSettingsAndLocations?: () => void
  locations?: ILocation[]
  isModal?: boolean
  getLocations?: () => Promise<void>
  getAllTags?: (filter: string) => void
  rerouteToPath?: (path: string) => void
  addSelectedTag?: (tag: TagDto) => void
  removeSelectedTag?: (tagId: string) => Promise<void>
}
interface IEditProviderState {
  reload: boolean
  dirtyTags: boolean
  oldTags: string
}

class EditProvider extends React.Component<IEditProviderProps, IEditProviderState> {
  constructor(props: IEditProviderProps) {
    super(props)
    this.state = {
      reload: false,
      dirtyTags: false,
      oldTags: '',
    }
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this)
  }
  public get title() {
    return this.props.match.params.providerId
      ? 'Edit Provider Group'
      : 'Add New Provider Group'
  }

  public componentDidMount() {
    ;(window as any).formikRef = React.createRef()
    this.setup()
    this.props.getAllTags!('')
  }

  public setup() {
    let promise
    const {
      loadCommunications,
      loadProvider,
      match,
      getImportantContacts,
      getColumnSettingsAndLocations,
      isModal,
      setSelectedProvider,
      getLocations,
    } = this.props
    if (match.params.providerId) {
      if (isModal) {
        setSelectedProvider!(undefined)
      }
      promise = loadProvider!(match.params.providerId)
    } else {
      promise = loadProvider!()
    }
    promise.then(() => {
      getImportantContacts!(this.props.provider!)
      loadCommunications!()
      getColumnSettingsAndLocations!()
      getLocations!()
      this.props.setSelectedTags!(
        this.props.provider?.providerTags ?? new Array<TagDto>()
      )
      this.setState({ oldTags: JSON.stringify(this.props.selectedTags) })
    })
  }

  public componentWillUnmount() {
    if (!this.props.isModal) {
      this.props.setSelectedProvider!(undefined)
    }
  }

  public forceUpdateHandler() {
    var newTags = JSON.stringify(this.props.selectedTags)
    this.setState({ dirtyTags: newTags != this.state.oldTags })
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload }, () => {
      submitForm()
    })
  }

  public save = async (
    values: IProviderFormValues,
    formikBag: FormikActions<IProviderFormValues>
  ) => {
    const provider = await toIProvider(values, this.props.provider)
    provider.providerTags = this.props.selectedTags!
    this.props.saveProvider!(provider, this.props.isModal || this.state.reload)
      .then(() => {
        formikBag.setSubmitting(false)
        if (!this.props.isModal) {
          if (this.state.reload) {
            this.props.rerouteToPath!(`/providers/${provider.id}`)
            location.reload()
            this.setup()
          } else {
            window.close()
          }
        }
      })
      .catch(() => formikBag.setSubmitting(false))
  }

  public renderTabs() {
    if (this.props.match.params.providerId) {
      const tabs = [
        new Tab(
          <Locations providerId={this.props.match.params.providerId} />,
          'Locations',
          <Place />
        ),
        new Tab(<Physicians />, 'Physicians', <FolderShared />),
        new Tab(<Contacts />, 'Contacts', <Person />),
        new Tab(<EditCommunicationsLog />, 'Communication Log', <Chat />),
      ]
      return (
        <Tabs
          color="default"
          isHeaderElevated={false}
          isHeaderTransparent={true}
          tabColor="primary"
          fullWidth
          tabs={tabs}
        />
      )
    } else {
      return <></>
    }
  }

  public render() {
    const {
      classes,
      provider,
      tags,
      importantContacts = [],
      isModal = false,
    } = this.props
    const providerTypes = this.props.providerTypes || []
    return (
      <div>
        <Helmet>
          <title>{this.title}</title>
        </Helmet>
        {provider ? (
          <Formik
            initialValues={createIProviderFormValues(provider, providerTypes)}
            onSubmit={this.save}
            validationSchema={this.buildSchema()}
            enableReinitialize={true}
            ref={(window as any).formikRef}
          >
            {({
              errors,
              isValid,
              setFieldValue,
              values,
              submitForm,
              dirty,
              submitCount,
            }) => (
              <>
                {!isModal ? (
                  <>
                    <ChangeGroupModal />
                    <ResponsiveAppBar title={this.title} pageIcon={<LocalHospital />}>
                      <Grid
                        container
                        item
                        direction="row"
                        justifyContent="flex-end"
                        spacing={3}
                      >
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => window.close()}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            color="secondary"
                            size="small"
                            variant="contained"
                            disabled={!isValid && !this.state.dirtyTags}
                            onClick={this.handleSave(submitForm, true)}
                          >
                            Save
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            color="secondary"
                            size="small"
                            variant="contained"
                            disabled={!isValid && !this.state.dirtyTags}
                            onClick={this.handleSave(submitForm, false)}
                          >
                            Save and Close
                          </Button>
                        </Grid>
                      </Grid>
                    </ResponsiveAppBar>
                  </>
                ) : (
                  <></>
                )}
                <Form className={classes.form}>
                  <Prompt
                    when={dirty && submitCount === 0}
                    message="Are you sure you want to leave the page? There are unsaved changes."
                  />
                  <Grid container direction="column" spacing={3} wrap="nowrap">
                    <Grid item>
                      <ProviderDetails
                        importantContacts={importantContacts}
                        errors={errors}
                        key={values.id}
                        setFieldValue={setFieldValue}
                        providerTypes={providerTypes}
                        values={values}
                        tags={tags}
                        isNew={!this.props.match.params.providerId}
                        selectedTags={this.props.selectedTags}
                        addSelectedTag={this.props.addSelectedTag}
                        removeSelectedTag={this.props.removeSelectedTag}
                        forceUpdate={this.forceUpdateHandler}
                      />
                    </Grid>
                    {isModal ? (
                      <Grid container item justifyContent="flex-end" spacing={3}>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!isValid}
                            onClick={submitForm}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Form>
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="flex-start"
                  className={this.props.classes.form}
                >
                  {this.renderTabs()}
                </Grid>
              </>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </div>
    )
  }

  private buildSchema() {
    return Yup.object().shape({
      isSingleLocation: Yup.boolean().default(false).required(),
      name: Yup.string().required('Name is required'),
      contractLink: Yup.string().url('Link must start with http://, https://, or ftp://'),
    })
  }
}

const InjectedEditProvider = inject<
  IStores,
  IEditProviderProps,
  Partial<IEditProviderProps>,
  any
>((stores: IStores) => ({
  getColumnSettingsAndLocations: stores.locations.getColumnSettingsAndLocations,
  getImportantContacts: stores.contacts.getImportantContacts,
  getLocations: stores.locations.getAllLocationsForProvider,
  getOrganizations: stores.organizations.getAllOrganizations,
  getProviders: stores.providers.getProviderSuggestions,
  getAllTags: stores.tags.getAllTags,
  importantContacts: stores.contacts.importantContacts,
  isAdmin: stores.global.isAdmin,
  loadCommunications: stores.communications.getColumnSettingsAndFullCommunications,
  loadProvider: stores.providers.loadProvider,
  locations: stores.locations.locations,
  provider: stores.providers.selectedProvider,
  providerTypes: stores.providers.providerTypes,
  rerouteToPath: stores.global.rerouteToPath,
  saveProvider: stores.providers.saveProvider,
  setSelectedProvider: stores.providers.setSelectedProvider,
  tags: stores.tags.tags,
  selectedTags: stores.locations.selectedTags,
  setSelectedTags: stores.locations.setSelectedTags,
  removeSelectedTag: stores.locations.removeSelectedTag,
  addSelectedTag: stores.locations.addSelectedTag,
}))(EditProvider)

export default withStyles(styles)(InjectedEditProvider)
