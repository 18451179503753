import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import { FormikActions } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import * as Yup from 'yup'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import { noWhitespaceString } from '../common/YupExtensions'
import { IContactType } from '../Definitions'
import { IStores } from '../Stores'

const ContactTypeSchema = Yup.object().shape({
  name: noWhitespaceString('Name is required', true),
})

interface IContactTypeDialogProps {
  close?: () => void
  contactType?: IContactType
  isLoading?: boolean
  isOpen?: boolean
  saveContactType?: (contactType: IContactType) => Promise<void>
}

class ContactTypeDialog extends React.Component<IContactTypeDialogProps> {
  public save = (values: IContactType, formikBag: FormikActions<IContactType>) => {
    this.props.saveContactType!({ ...values }).finally(() =>
      formikBag.setSubmitting(false)
    )
  }

  public render() {
    const { close, isLoading, isOpen, contactType } = this.props
    return (
      <ESDialog
        close={close!}
        initialValues={{ ...contactType } as IContactType}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={contactType!.isNew ? 'Add New Contact Type' : 'Edit Contact Type'}
        validationSchema={ContactTypeSchema}
      >
        {({ setFieldValue, values }) => (
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <FullWidthField autoFocus name="name" label="Name" required />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.isActive}
                    onChange={this.handleIsActiveChange(values, setFieldValue)}
                    value="isActive"
                    color="secondary"
                    name="isActive"
                  />
                }
                label={values.isActive ? 'Active' : 'Inactive'}
              />
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }

  private handleIsActiveChange =
    (values: IContactType, setFieldValue: (field: string, value: any) => void) => () => {
      setFieldValue('isActive', !values.isActive)
    }
}

const InjectedContactTypeDialog = inject<
  IStores,
  IContactTypeDialogProps,
  Partial<IContactTypeDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.contactTypes.closeDialog,
  contactType: stores.contactTypes.selectedContactType,
  isLoading: stores.contactTypes.isLoading,
  isOpen: stores.contactTypes.isModalOpen,
  saveContactType: stores.contactTypes.saveContactType,
}))(ContactTypeDialog)

export default InjectedContactTypeDialog
