import {
  Button,
  createStyles,
  Grid,
  Paper,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { Field, Form, Formik, FormikActions } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import { ClipboardOutline } from 'mdi-material-ui'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Prompt, RouteComponentProps } from 'react-router-dom'
import * as Yup from 'yup'
import CardWithTitle from '../common/CardWithTitle/CardWithTitle'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import FullWidthField from '../common/FullWidthField'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import SelectField from '../common/SelectField/SelectField'
import { noWhitespaceString } from '../common/YupExtensions'
import {
  DefaultAppointmentTask,
  DefaultComponent,
  IAppointmentTask,
  IComponent,
  IOrganization,
} from '../Definitions'
import { IStores } from '../Stores'
import AppointmentTaskDialog from './AppointmentTaskDialog'
import {
  createIComponentFormValues,
  IComponentFormValues,
  toIComponent,
} from './ComponentFormValues'

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      padding: spacing(2),
      width: '100%',
    },
    topOfPage: {
      marginTop: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  })

const ComponentSchema = Yup.object().shape({
  name: noWhitespaceString('Name is required', true),
  organizationId: Yup.string().required('Organization is required'),
})

interface IEditComponentParams {
  componentId: string
}

interface IEditComponentState {
  reload: boolean
}
interface IEditComponentProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IEditComponentParams> {
  allAppointmentTasks?: IAppointmentTask[]
  clearAppointmentTasks: () => void
  component?: IComponent
  componentEditFinished?: () => void
  currentAppointmentTaskCount?: number
  dataTableManager?: IDataTableManager<IAppointmentTask>
  getAllAppointmentTasks?: () => Promise<void>
  getColumnSettingsAndAppointmentTasks?: () => void
  getComponentById?: (id: string) => Promise<void>
  getOrganizations: () => Promise<void>
  isLoading?: boolean
  organizations?: IOrganization[]
  openDialog?: (appointmentTask: IAppointmentTask) => void
  rerouteToPath?: (path: string) => void
  saveComponent?: (component: IComponent) => Promise<void>
  setSelectedComponent?: (component: IComponent | undefined) => void
  currentAppOrganization?: IOrganization
}

class EditComponent extends React.Component<IEditComponentProps, IEditComponentState> {
  constructor(props: IEditComponentProps) {
    super(props)
    this.state = {
      reload: false,
    }
  }
  private async setup() {
    const { match } = this.props
    if (match && match.params.componentId) {
      await this.props.getComponentById!(match.params.componentId)
    } else {
      this.props.setSelectedComponent!(DefaultComponent(true))
    }
    this.props.getColumnSettingsAndAppointmentTasks!()
    this.props.getOrganizations!()
  }

  public async componentDidMount() {
    await this.setup()
  }

  public async componentDidUpdate(prevProps: IEditComponentProps) {
    if (this.props.match.params.componentId !== prevProps.match.params.componentId) {
      await this.setup()
    }
  }

  public componentWillUnmount() {
    this.props.setSelectedComponent!(undefined)
    this.props.clearAppointmentTasks!()
  }

  private cancel = () => {
    this.props.rerouteToPath!('/admin/components')
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload }, () => {
      submitForm()
    })
  }

  public save = (
    values: IComponentFormValues,
    formikBag: FormikActions<IComponentFormValues>
  ) => {
    const component = toIComponent(values, this.props.component)

    this.props.saveComponent!(component).finally(() => {
      formikBag.setSubmitting(false)
      if (this.state.reload) {
        this.props.rerouteToPath!(`/admin/components/component/${component.id}`)
        this.setup()
      } else {
        this.props.rerouteToPath!('/admin/components')
      }
    })
  }

  public openDialogWithAppointment = () => () => {
    const appointmentTask = DefaultAppointmentTask(true)
    appointmentTask.componentId = this.props.component!.id
    this.props.openDialog!(appointmentTask)
  }

  public getOrganizationName = (organization: IOrganization) => {
    return organization.name
  }

  public getOrganizationValue = (organization: IOrganization) => {
    return organization.id
  }

  public render() {
    const { classes, component } = this.props
    const initialValues = createIComponentFormValues(component)
    const title = component && component.isNew ? 'Add New Component' : 'Edit Component'

    return (
      <Grid>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {component ? (
          <Formik
            initialValues={initialValues}
            onSubmit={this.save}
            validationSchema={ComponentSchema}
            enableReinitialize={true}
          >
            {({ isValid, values, submitForm, dirty, submitCount }) => (
              <Form>
                <Prompt
                  when={dirty && submitCount === 0}
                  message="Are you sure you want to leave the page? There are unsaved changes."
                />
                <ResponsiveAppBar title={title} pageIcon={<ClipboardOutline />}>
                  <Grid container direction="row" spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button size="small" variant="contained" onClick={this.cancel}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        disabled={!isValid}
                        onClick={this.handleSave(submitForm, true)}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        disabled={!isValid}
                        onClick={this.handleSave(submitForm, false)}
                      >
                        Save and Close
                      </Button>
                    </Grid>
                  </Grid>
                </ResponsiveAppBar>

                <Grid container direction="row" spacing={3} className={classes.topOfPage}>
                  <Grid container lg={6} md={12} item spacing={1}>
                    <CardWithTitle title="Component" icon={ClipboardOutline}>
                      <Grid container xs={12} spacing={1}>
                        <Grid item xs={6}>
                          <FullWidthField
                            variant="outlined"
                            type="outlined"
                            name="name"
                            label="Name"
                            disabled={values.isLocked}
                            required
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SelectField
                            inputId="organizationIdUpdate"
                            getName={this.getOrganizationName}
                            getValue={this.getOrganizationValue}
                            items={this.props.organizations}
                            label="Organization"
                            name="organizationId"
                            fullWidth
                            outlined={true}
                            shrinkLabel={true}
                            required
                            disabled={values.isLocked || !this.props.component!.isNew}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="isDefaultComponent"
                            Label={{
                              label: 'Default Component',
                            }}
                            component={CheckboxWithLabel}
                            disabled={values.isLocked}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="isSimpleComponent"
                            Label={{
                              label: 'Simple Event',
                            }}
                            component={CheckboxWithLabel}
                            disabled={
                              values.isLocked ||
                              this.props.currentAppointmentTaskCount! > 0
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="excludeFromBundle"
                            Label={{
                              label: 'Exclude From Bundle',
                            }}
                            component={CheckboxWithLabel}
                            disabled={values.isLocked}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="excludeFromPTCount"
                            Label={{
                              label: 'Exclude From PT Count',
                            }}
                            component={CheckboxWithLabel}
                            disabled={values.isLocked}
                          />
                        </Grid>
                      </Grid>
                    </CardWithTitle>
                  </Grid>
                  {component && !component.isNew ? (
                    <Grid item lg={6} md={12} spacing={1}>
                      <Paper className={classes.paper}>
                        <ESDataTableWithHeader
                          dataTableManager={this.props.dataTableManager!}
                          addButtonOnClick={this.openDialogWithAppointment()}
                          title="Appointment Tasks"
                          icon={CheckCircle}
                          enableShowInactives={true}
                          padding={0}
                          elevation={0}
                          isAddButtonDisabled={values.isSimpleComponent}
                        />
                        <AppointmentTaskDialog />
                      </Paper>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </Grid>
    )
  }
}

const InjectedEditComponent = inject<
  IStores,
  IEditComponentProps,
  Partial<IEditComponentProps>,
  any
>((stores: IStores) => ({
  allAppointmentTasks: stores.appointmentTasks.appointmentTasks,
  clearAppointmentTasks: stores.appointmentTasks.clearAppointmentTaskData,
  component: stores.components.selectedComponent,
  componentEditFinished: stores.components.componentEditFinished,
  currentAppOrganization: stores.global.currentAppOrganization,
  currentAppointmentTaskCount: stores.appointmentTasks.appointmentTaskCount,
  dataTableManager: stores.appointmentTasks.dataTableStore,
  getAllAppointmentTasks: stores.appointmentTasks.getAllAppointmentTasks,
  getColumnSettingsAndAppointmentTasks:
    stores.appointmentTasks.getColumnSettingsAndAppointmentTasks,
  getComponentById: stores.components.getComponentById,
  getOrganizations: stores.organizations.getAllOrganizations,
  isLoading: stores.components.isLoading,
  openDialog: stores.appointmentTasks.openDialog,
  organizations: stores.organizations.organizations,
  rerouteToPath: stores.global.rerouteToPath,
  saveComponent: stores.components.saveComponent,
  setSelectedComponent: stores.components.setSelectedComponent,
}))(EditComponent)

export default withStyles(styles)(InjectedEditComponent)
