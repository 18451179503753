import {
  Button,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { RemoveCircle } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { connect, Field, FormikContext, getIn } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import React from 'react'
import FullWidthField from '../../common/FullWidthField'
import SelectField from '../../common/SelectField/SelectField'
import { IBodyPartDto, IProcedureDto, TestDto } from '../../generated_client'
import CodeExpandingList from '../EpisodesOfCare/CodeExpandingList'
import { IEpisodeOfCareFormValues } from '../EpisodesOfCare/EpisodeOfCareFormValues'
import { createITestFormValues } from './TestFormValues'

const styles = (theme: Theme) =>
  createStyles({
    card: {
      border: '1px solid black',
      borderRadius: '5px',
      marginBottom: '10px',
    },
    cardContents: {
      padding: '10px',
    },
    cardHeader: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '5px 5px 0px 0px',
      color: 'white',
      cursor: 'pointer',
      paddingLeft: '5px',
    },
  })

interface IFormikProps {
  formik: FormikContext<IEpisodeOfCareFormValues>
}

interface ITestsContainerProps extends WithStyles<typeof styles> {
  addDxCodeDescriptions: (
    values: IEpisodeOfCareFormValues,
    testIndex: number,
    setFieldValue: (field: string, value: any) => void
  ) => void
  procedures?: IProcedureDto[]
  bodyParts?: IBodyPartDto[]
  readonly?: boolean
}

class TestsContainer extends React.Component<ITestsContainerProps & IFormikProps> {
  private createFieldName = (fieldName: string, index: number) => {
    return `tests[${index}].${fieldName}`
  }

  private hasValue = (fieldName: string, index: number) => {
    const { formik } = this.props
    return getIn(formik.values, this.createFieldName(fieldName, index))
  }

  public getBodyPartName(bodyPart: IBodyPartDto) {
    return bodyPart ? bodyPart.inactiveName! : ''
  }

  public getBodyPartId(bodyPart: IBodyPartDto) {
    return bodyPart ? bodyPart.id! : ''
  }

  public getProcedureValue(item: IProcedureDto) {
    return item ? item.id! : ''
  }

  public getProcedureName(item: IProcedureDto) {
    return item ? item.diagnostic! : ''
  }

  public getValue(item: string) {
    return item
  }

  private renderTest(idx: number) {
    const { bodyParts, procedures, formik, readonly } = this.props
    const addDxCodeDescriptions = () => {
      if (!readonly) {
        this.props.addDxCodeDescriptions(formik.values, idx, formik.setFieldValue)
      }
    }
    return (
      <>
        <Grid container xs={12} spacing={1} style={{ paddingBottom: '10px' }}>
          <Grid item md={4} xs={12}>
            <SelectField
              inputId="procedureId"
              getName={this.getProcedureName}
              getValue={this.getProcedureValue}
              items={procedures}
              label="Procedure"
              name={this.createFieldName('procedureId', idx)}
              fullWidth
              shrinkLabel={true}
              outlined
              required
              errorMessage={
                formik.errors.tests &&
                formik.errors.tests[idx] &&
                formik.errors.tests![idx]!.procedureId
              }
              disabled={readonly}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectField
              inputId="bodyPartId"
              getName={this.getBodyPartName}
              getValue={this.getBodyPartId}
              items={bodyParts}
              label="Body Part"
              name={this.createFieldName('bodyPartId', idx)}
              fullWidth
              shrinkLabel={true}
              outlined
              required
              errorMessage={
                formik.errors.tests &&
                formik.errors.tests[idx] &&
                formik.errors.tests![idx]!.bodyPartId
              }
              disabled={readonly}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Field
              name={this.createFieldName('testIssue', idx)}
              Label={{
                label: 'Test Issue',
              }}
              component={CheckboxWithLabel}
              disabled={readonly}
            />
            <Field
              name={this.createFieldName('rxReceived', idx)}
              Label={{
                label: 'RX Received',
              }}
              component={CheckboxWithLabel}
              disabled={readonly}
            />
            <Field
              name={this.createFieldName('referralIssue', idx)}
              Label={{
                label: 'Referral Issue',
              }}
              component={CheckboxWithLabel}
              disabled={readonly}
            />
          </Grid>
          <Grid item xs={12}>
            <CodeExpandingList testIndex={idx} type="cptCodes" disabled={readonly} />
          </Grid>
          <Grid item xs={12}>
            <CodeExpandingList testIndex={idx} type="dxCodes" disabled={readonly} />
          </Grid>
          <Grid item xs={12}>
            {readonly ? (
              <></>
            ) : (
              <a href="javascript:void(0)" onClick={addDxCodeDescriptions}>
                Add Descriptions from DX Codes
              </a>
            )}
          </Grid>
          <Grid item xs={12}>
            <FullWidthField
              variant="outlined"
              name={this.createFieldName('diagnosis', idx)}
              label="Diagnosis"
              fullWidth
              rows={4}
              multiline
              InputLabelProps={{
                shrink: this.hasValue('diagnosis', idx),
              }}
              disabled={readonly}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  private handleExpandClick = (idx: number) => (__: React.MouseEvent) => {
    const { formik } = this.props
    if (formik.values.tests[idx]) {
      formik.setFieldValue(
        this.createFieldName('extended', idx),
        !formik.values.tests[idx].extended
      )
    }
  }

  private addTest = () => {
    const { formik, readonly } = this.props

    if (!readonly) {
      const tests = formik.values.tests
      tests.forEach((x) => (x.extended = false))

      const newTest = createITestFormValues(new TestDto({ cptCodes: [], dxCodes: [] }))

      tests.push(newTest)
      formik.setFieldValue('tests', tests)
    }
  }

  private delete = (idx: number) => (e: React.MouseEvent) => {
    e.stopPropagation()

    const { formik, readonly } = this.props
    if (!readonly) {
      const tests = formik.values.tests

      tests.splice(idx, 1)

      formik.setFieldValue('tests', tests)
    }
  }

  public render() {
    const { formik, readonly } = this.props

    return (
      <>
        <Grid item container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Typography
              variant={'body1'}
              color="textSecondary"
              style={{ height: '18px' }}
            >
              TEST INFORMATION
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            {formik.values.tests.map((x, idx) => (
              <div key={idx} style={{ width: '100vw' }}>
                <ExpansionPanel
                  style={{ marginTop: '10px' }}
                  expanded={x.extended}
                  onChange={this.handleExpandClick(idx)}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <RemoveCircle
                      color={readonly ? 'disabled' : 'error'}
                      onClick={this.delete(idx)}
                      style={{
                        fontSize: '30px',
                        cursor: readonly ? 'default' : 'pointer',
                        paddingRight: '5px',
                      }}
                    />
                    &nbsp;&nbsp;
                    <Typography color="textSecondary" variant="body2">
                      #{(idx + 1).toString().padStart(3, '0')}
                    </Typography>
                    {x && x.procedure && x.bodyPart ? (
                      <>
                        <Typography color="textSecondary" variant="body2">
                          &nbsp;[
                          {this.getProcedureName(x.procedure).toString()}
                          &nbsp;-&nbsp;
                          {this.getBodyPartName(x.bodyPart).toString()}]
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography color="textSecondary" variant="body2">
                          &nbsp;NEW*
                        </Typography>
                      </>
                    )}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>{this.renderTest(idx)}</ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            ))}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={this.addTest}
              disabled={readonly}
            >
              Add New Test
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withStyles(styles)(
  connect<ITestsContainerProps, IEpisodeOfCareFormValues>(TestsContainer)
)
