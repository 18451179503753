import Axios from 'axios'
import { ProviderDto } from 'src/generated_client'
import { IDashboardSearchRequest } from 'src/viewModels/DashboardSearchRequest'
import { IFinancialResearchSearchRequest } from 'src/viewModels/FinancialResearchSearchRequest'
import { IProviderRefundsSearchRequest } from 'src/viewModels/ProviderRefundsSearchRequest'
import { IReceivedCheckSearchRequest } from 'src/viewModels/ReceivedCheckSearchRequest'
import { pagedList } from 'src/viewModels/pagedList'
import { IPayProvidersSearchRequest } from 'src/viewModels/PayProvidersSearchRequest'
import { ISentCheckSearchRequest } from 'src/viewModels/SentCheckSearchRequest'
import { PendingChecksSummaryResult } from 'src/viewModels/PendingChecksSummaryResult'
import { IProviderGroupSearchRequest } from 'src/viewModels/ProviderGroupSearchRequest'
import { IReceivedCheckTotalSummary } from 'src/viewModels/ReceivedCheckTotalSummary'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function searchFinancial(
  data: IFinancialResearchSearchRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(`${base_url}/api/search/financial`, data)

  return response.data
}

export async function getDocumentUrlById(id: string): Promise<string> {
  const response = await Axios.post<string>(`${base_url}/api/search/document/${id}`)

  return response.data
}

export async function searchDashboard(data: IDashboardSearchRequest): Promise<pagedList> {
  const response = await Axios.post<pagedList>(`${base_url}/api/search/dashboard`, data)

  return response.data
}

export async function searchProviderRefunds(
  data: IProviderRefundsSearchRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/search/provider-refunds`,
    data
  )

  return response.data
}

export async function searchReceivedChecks(
  data: IReceivedCheckSearchRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/search/received-checks`,
    data
  )

  return response.data
}

export async function getAllSurgeryProviderBills(
  data: IPayProvidersSearchRequest
): Promise<ProviderDto[]> {
  const response = await Axios.post<ProviderDto[]>(
    `${base_url}/api/providers/surgery-bills`,
    data
  )

  return response.data
}
export async function searchSentChecks(
  data: ISentCheckSearchRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(`${base_url}/api/search/sent-checks`, data)

  return response.data
}

export async function getPendingSentChecks(): Promise<PendingChecksSummaryResult> {
  const response = await Axios.get<PendingChecksSummaryResult>(
    `${base_url}/api/search/pending-checks`
  )

  return response.data
}

export async function searchProviderGroups(
  data: IProviderGroupSearchRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/search/provider-groups`,
    data
  )

  return response.data
}

export async function GetReceivedChecksTotalsSummary(
  req: IReceivedCheckSearchRequest
): Promise<IReceivedCheckTotalSummary> {
  const response = await Axios.post<IReceivedCheckTotalSummary>(
    `${base_url}/api/search/received-checks-totals`,
    req
  )
  return response.data
}
