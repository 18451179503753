import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog'
import { Chat } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import SelectInput from '../common/SelectInput/SelectInput'
import TypeAheadFieldFilter from '../common/TypeAheadFieldFilter/TypeAheadFieldFilter'
import { DefaultCommunication, ICommunication, IPatient } from '../Definitions'
import { TagDto } from '../generated_client'
import { IStores } from '../Stores'
import EditCommunicationDialog from './EditCommunicationDialog'

interface ICommunicationsDialogProps extends DialogProps {
  clearFilters?: () => void
  close?: () => void
  contentFilter?: string
  dataTableManager?: IDataTableManager<ICommunication>
  getAllTags?: (filter: string, includeSystem: boolean) => void
  isLoading?: boolean
  isOpen?: boolean
  isEditModalOpen?: boolean
  openDialogWithCommunication?: (communication: ICommunication) => void
  selectedPatient?: IPatient
  selectedDateRange?: string
  selectedStartDate?: Date | null
  selectedEndDate?: Date | null
  selectedDOSRange?: string
  selectedStartDOS?: Date | null
  selectedEndDOS?: Date | null
  selectedType?: string
  setSelectedDateRange?: (dateRange: string) => void
  setSelectedDOSRange?: (dosRange: string) => void
  setSelectedDate?: (date: Date | null, isStartDate: boolean) => void
  setSelectedDOS?: (dateOfService: Date | null, isStartDate: boolean) => void
  setSelectedType?: (type: string) => void
  setSelectedTags?: (tags: TagDto[]) => void
  updateSearchFilter?: (value: string) => void
  tags?: TagDto[]
  selectedTags?: TagDto[]
}

class CommunicationsDialog extends React.Component<ICommunicationsDialogProps> {
  public componentWillUnmount() {
    this.props.clearFilters!()
  }
  public render() {
    const { selectedPatient, isLoading, isOpen, close, ...rest } = this.props
    const communicationDialog = this.props.isEditModalOpen && <EditCommunicationDialog />
    const title =
      'Communication Log' + (selectedPatient ? ` for ${selectedPatient!.name}` : '')
    const filters = this.renderDateFilters()
    return (
      <Dialog fullWidth={true} maxWidth="xl" {...rest} open={isOpen!} onClose={close}>
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar title={title} pageIcon={<Chat />} isModal={true} />
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          {!isLoading ? (
            <>
              <ESDataTableWithHeader
                dataTableManager={this.props.dataTableManager!}
                enableReorderColumns={true}
                enableSearch={false}
                enableShowInactives={false}
                enableToggleColumns={true}
                filterComponents={filters}
                addButtonOnClick={this.openDialog(DefaultCommunication(true))}
                rowsPerPage={15}
                elevation={0}
              />
              {communicationDialog}
            </>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  public openDialog = (communication: ICommunication) => () => {
    this.props.openDialogWithCommunication!(communication)
  }

  private getValue(item: string) {
    return item
  }

  private renderDateFilters() {
    const { selectedType, contentFilter, tags, selectedTags, setSelectedTags } =
      this.props

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={4}>
              <SelectInput
                inputId="type"
                getName={this.getValue}
                getValue={this.getValue}
                items={[undefined, 'Email', 'Phone', 'Text', 'Fax', 'Other']}
                label="Type"
                name="type"
                fullWidth
                onChange={this.onselectedTypeChange()}
                value={selectedType}
                shrinkLabel={!!selectedType}
                outlined={true}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Content"
                margin="none"
                name="content"
                onChange={this.onSearchChanged}
                value={contentFilter}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TypeAheadFieldFilter
            data-cy="tagsFilter"
            label="Tags"
            getName={this.getTagName}
            getValue={this.getTagValue}
            fullWidth
            getItems={this.getTags}
            items={tags || []}
            onChipChange={setSelectedTags!}
            selectedItems={selectedTags || []}
          />
        </Grid>
      </Grid>
    )
  }

  private getTags = (filter: string) => {
    this.props.getAllTags!(filter, true)
  }

  public getTagName(tag: TagDto) {
    return tag ? tag.name! : ''
  }

  public getTagValue(tag: TagDto) {
    return tag ? tag.id! : ''
  }

  private onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    this.props.updateSearchFilter!(value)
  }

  private onselectedTypeChange = () => (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setSelectedType!(event.target.value)
  }
}

const InjectedCommunicationDialog = inject<
  IStores,
  ICommunicationsDialogProps,
  Partial<ICommunicationsDialogProps>,
  any
>((stores: IStores) => ({
  clearFilters: stores.communications.clearFilters,
  close: stores.communications.closeDialog,
  closeCommunicationDialog: stores.communications.closeEditDialog,
  contentFilter: stores.communications.contentFilter,
  dataTableManager: stores.communications.fullDataTableStore,
  getAllTags: stores.tags.getAllTags,
  isCommunicationDialogOpen: stores.communications.isModalOpen,
  isEditModalOpen: stores.communications.isEditModalOpen,
  isLoading: stores.communications.isLoading,
  isOpen: stores.communications.isModalOpen,
  openDialogWithCommunication: stores.communications.openDialogWithCommunication,
  selectedDOSRange: stores.communications.selectedDOSRange,
  selectedDateRange: stores.communications.selectedDateRange,
  selectedEndDOS: stores.communications.selectedEndDOS,
  selectedEndDate: stores.communications.selectedEndDate,
  selectedPatient: stores.patients.selectedPatient,
  selectedStartDOS: stores.communications.selectedStartDOS,
  selectedStartDate: stores.communications.selectedStartDate,
  selectedTags: stores.communications.selectedTags,
  selectedType: stores.communications.selectedType,
  setSelectedDOS: stores.communications.setSelectedDOS,
  setSelectedDOSRange: stores.communications.setSelectedDOSRange,
  setSelectedDate: stores.communications.setSelectedDate,
  setSelectedDateRange: stores.communications.setSelectedDateRange,
  setSelectedTags: stores.communications.setSelectedTags,
  setSelectedType: stores.communications.setSelectedType,
  tags: stores.tags.tags,
  updateSearchFilter: stores.communications.updateSearchFilter,
}))(CommunicationsDialog)

export default InjectedCommunicationDialog
