import Axios from 'axios'
import { AdvancedSearchConfigurationDTO } from 'src/viewModels/AdvancedSearchConfigurationDTO'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function insertAdvancedSearchConfiguration(
  data: AdvancedSearchConfigurationDTO
) {
  const response = await Axios.post<AdvancedSearchConfigurationDTO>(
    `${base_url}/api/advanced-search`,
    data
  )

  return response.data
}

export async function updateAdvancedSearchConfiguration(
  id: string,
  data: AdvancedSearchConfigurationDTO
) {
  const response = await Axios.put<AdvancedSearchConfigurationDTO>(
    `${base_url}/api/advanced-search/${id}`,
    data
  )

  return response.data
}

export async function getAdvancedSearchConfigurationById(id: string) {
  const response = await Axios.get<AdvancedSearchConfigurationDTO>(
    `${base_url}/api/advanced-search/${id}`
  )

  return response.data
}

export async function deleteAdvancedSearchConfiguration(id: string) {
  const response = await Axios.delete<void>(`${base_url}/api/advanced-search/${id}`)

  return response.data
}
