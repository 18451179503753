import {
  Button,
  createStyles,
  Grid,
  Paper,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { Description, Person } from '@material-ui/icons'
import { Form, Formik, FormikActions } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Prompt, RouteComponentProps } from 'react-router-dom'
import AddressField from '../common/AddressField/AddressField'
import CardWithTitle from '../common/CardWithTitle/CardWithTitle'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import FullWidthField from '../common/FullWidthField'
import IDataTableManager from '../common/IDataTableManager'
import IsActiveToggle from '../common/IsActiveToggle'
import PhoneNumberMask from '../common/PhoneNumberMask'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import {
  noWhitespaceEmail,
  noWhitespacePhone,
  noWhitespaceString,
} from '../common/YupExtensions'
import ContactEditDialog from '../contacts/ContactEditDialog'
import {
  DefaultContact,
  DefaultInsuranceCompany,
  IContact,
  IInsuranceCompany,
} from '../Definitions'
import { IContactDto } from '../generated_client'
import { IStores } from '../Stores'
import DataTableStore from '../stores/DataTableStore'
import * as Yup from 'yup'
import {
  createIInsuranceCompanyFormValues,
  IInsuranceCompanyFormValues,
  toIInsuranceCompany,
} from './InsuranceCompanyFormValues'
import { Helmet } from 'react-helmet'

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      marginTop: 4,
      paddingTop: spacing(2),
    },
    topOfPage: {
      marginTop: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  })

interface IInsuranceCompanyRouteParams {
  insuranceCompanyId: string
}

interface IInsuranceCompanyProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<IInsuranceCompanyRouteParams> {
  contactsDataTableStore?: DataTableStore<IContactDto, IContact>
  createContact?: (contact: IContact, title: string) => void
  getColumnSettingsAndContacts?: () => Promise<void>
  getInsuranceCompanyById?: (id: string) => Promise<void>
  isConfirmDialogOpen?: boolean
  close?: () => void
  insuranceCompany?: IInsuranceCompany | undefined
  isLoading?: boolean
  isOpen?: boolean
  openConfirmDialog?: () => void
  saveInsuranceCompany?: (insuranceCompany: IInsuranceCompany) => Promise<void>
  setSelectedInsuranceCompany?: (insuranceCompany: IInsuranceCompany | undefined) => void
  rerouteToPath?: (path: string) => void
  dataTableManager?: IDataTableManager<IContact>
}

interface IInsuranceCompanyState {
  reload: boolean
}
class InsuranceCompany extends React.Component<
  IInsuranceCompanyProps,
  IInsuranceCompanyState
> {
  constructor(props: IInsuranceCompanyProps) {
    super(props)
    this.state = {
      reload: false,
    }
  }
  private async setup() {
    const { match } = this.props
    this.setState({ reload: false })
    if (match && match.params.insuranceCompanyId) {
      await this.props.getInsuranceCompanyById!(match.params.insuranceCompanyId)
    } else {
      this.props.setSelectedInsuranceCompany!(DefaultInsuranceCompany(true))
    }
    await this.props.getColumnSettingsAndContacts!()
  }

  public async componentDidMount() {
    ;(window as any).formikRef = React.createRef()

    await this.setup()
  }

  public async componentDidUpdate(prevProps: IInsuranceCompanyProps) {
    if (
      this.props.match.params.insuranceCompanyId !==
      prevProps.match.params.insuranceCompanyId
    ) {
      await this.setup()
    }
  }

  public componentWillUnmount() {
    this.props.setSelectedInsuranceCompany!(undefined)
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload }, () => {
      submitForm()
    })
  }

  public save = async (
    values: IInsuranceCompanyFormValues,
    formikBag: FormikActions<IInsuranceCompanyFormValues>
  ) => {
    const insuranceCompany = await toIInsuranceCompany(
      values,
      this.props.insuranceCompany
    )
    this.props.saveInsuranceCompany!(insuranceCompany).finally(() => {
      formikBag.setSubmitting(false)
      if (this.state.reload) {
        this.props.rerouteToPath!(
          `/admin/insuranceCompanies/insuranceCompany/${insuranceCompany.id}`
        )
        this.setup()
      } else {
        this.cancel()
      }
    })
  }

  private cancel = () => {
    this.props.rerouteToPath!('/admin/insuranceCompanies')
  }

  private openDialog = () => {
    this.props.createContact!(DefaultContact(true), 'Add New Insurance Company Contact')
  }

  private handleIsActiveChange =
    (
      values: IInsuranceCompanyFormValues,
      setFieldValue: (field: string, value: any) => void
    ) =>
    () => {
      setFieldValue('isActive', !values.isActive)
    }

  public InsuranceCompanySchema = Yup.object().shape({
    billingAddress: Yup.object({
      city: noWhitespaceString('City is required', true),
      line1: noWhitespaceString('Street address is required', true),
      state: noWhitespaceString('State is required', true),
      zip: noWhitespaceString('Zip code is required', true),
    }),
    billingFaxNumber: noWhitespacePhone(),
    billingPhone: noWhitespacePhone(),
    claimsEmail: noWhitespaceEmail(),
    isSpecialPricing: Yup.boolean(),
    includeNurseCaseManager: Yup.boolean(),
    name: noWhitespaceString('Name is required', true),
  })

  public render() {
    const { insuranceCompany, classes } = this.props

    const title =
      insuranceCompany && insuranceCompany.isNew
        ? 'Add New Insurance Company'
        : 'Edit Insurance Company'

    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {insuranceCompany ? (
          <Formik
            initialValues={createIInsuranceCompanyFormValues(insuranceCompany)}
            onSubmit={this.save}
            validationSchema={this.InsuranceCompanySchema}
            isInitialValid={false}
            enableReinitialize={true}
            ref={(window as any).formikRef}
          >
            {({ isValid, setFieldValue, values, submitForm, dirty, submitCount }) => (
              <Form>
                <Prompt
                  when={dirty && submitCount === 0}
                  message="Are you sure you want to leave the page? There are unsaved changes."
                />
                <ResponsiveAppBar title={title} pageIcon={<Description />}>
                  <Grid container direction="row" spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button size="small" variant="contained" onClick={this.cancel}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        disabled={!isValid}
                        onClick={this.handleSave(submitForm, true)}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        disabled={!isValid}
                        onClick={this.handleSave(submitForm, false)}
                      >
                        Save and Close
                      </Button>
                    </Grid>
                  </Grid>
                </ResponsiveAppBar>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  justifyContent="space-between"
                  className={this.props.classes.topOfPage}
                >
                  <Grid item xs={6}>
                    <CardWithTitle title="Insurance Company" icon={Description}>
                      <Grid item xs={12}>
                        <FullWidthField
                          name="name"
                          label="Name"
                          variant="outlined"
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AddressField
                          name={'billingAddress'}
                          label="Billing Address"
                          showPhone={true}
                          outlined={true}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FullWidthField
                          name="billingFaxNumber"
                          label="Billing Fax #"
                          variant="outlined"
                          InputProps={{
                            inputComponent: PhoneNumberMask,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AddressField
                          name={'physicalAddress'}
                          label="Physical Address"
                          showPhone={false}
                          outlined={true}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FullWidthField
                          InputLabelProps={{
                            shrink: !!values.claimsEmail,
                          }}
                          variant="outlined"
                          name="claimsEmail"
                          label="Claims Email"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FullWidthField
                          name="isSpecialPricing"
                          Label={{
                            label: 'Special Pricing?',
                          }}
                          component={CheckboxWithLabel}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FullWidthField
                          name="includeNurseCaseManager"
                          Label={{
                            label: 'Include NCM on Quote Adjuster Email?',
                          }}
                          component={CheckboxWithLabel}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <IsActiveToggle
                          isActive={values.isActive}
                          onChange={this.handleIsActiveChange(values, setFieldValue)}
                        />
                      </Grid>
                    </CardWithTitle>
                  </Grid>
                  {insuranceCompany && !insuranceCompany.isNew ? (
                    <Grid item xs={6}>
                      <Paper className={classes.paper}>
                        <ESDataTableWithHeader
                          dataTableManager={this.props.contactsDataTableStore!}
                          addButtonOnClick={this.openDialog}
                          elevation={0}
                          enableShowInactives
                          padding={0}
                          title="Contacts"
                          icon={Person}
                          rowsPerPage={5}
                        />
                        <ContactEditDialog contactVariant="InsuranceCompany" />
                      </Paper>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </>
    )
  }
}

const InjectedInsuranceCompany = inject<
  IStores,
  IInsuranceCompanyProps,
  Partial<IInsuranceCompanyProps>,
  any
>((stores: IStores) => ({
  close: stores.insuranceCompanies.closeDialog,
  contactsDataTableStore: stores.contacts.dataTableStore,
  createContact: stores.contacts.openDialogWithContact,
  getColumnSettingsAndContacts: stores.contacts.getColumnSettingsAndContacts,
  getInsuranceCompanyById: stores.insuranceCompanies.getInsuranceCompanyById,
  insuranceCompany: stores.insuranceCompanies.selectedInsuranceCompany,
  isLoading: stores.insuranceCompanies.isLoading,
  isOpen: stores.insuranceCompanies.isModalOpen,
  rerouteToPath: stores.global.rerouteToPath,
  saveInsuranceCompany: stores.insuranceCompanies.saveInsuranceCompany,
  setSelectedInsuranceCompany: stores.insuranceCompanies.setSelectedInsuranceCompany,
}))(InsuranceCompany)

export default withStyles(styles)(InjectedInsuranceCompany)
