import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultAttachmentFile, IAttachmentFile } from '../../Definitions'
import {
  AttachmentFileDto,
  Client,
  IAttachmentFileDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'AttachmentFileDto'

export default class AttachmentFilesStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public attachmentFiles: IAttachmentFile[]
  @observable
  public selectedAttachmentFile?: IAttachmentFile
  public dataTableStore: DataTableStore<IAttachmentFileDto, IAttachmentFile>

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IAttachmentFileDto, IAttachmentFile>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllAttachmentFiles(filter, page, undefined, orderBy, includeInactives),
      (attachmentFile) => this.setupAttachmentFiles(attachmentFile)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedAttachmentFile = undefined
  }

  @action.bound
  public disableAttachmentFile(attachmentFile: IAttachmentFile) {
    this.isLoading = true
    return this.client
      .deleteAttachmentFile(attachmentFile.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndAttachmentFiles() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllAttachmentFiles() {
    this.isLoading = true
    return this.client
      .getAllAttachmentFiles()
      .then((resp: SwaggerResponse<IAttachmentFileDto[]>) =>
        runInAction(() => {
          this.attachmentFiles = this.setupAttachmentFiles(resp.result)
        })
      )
  }

  @action.bound
  public openDialogWithAttachmentFile(attachmentFile: IAttachmentFile) {
    this.isModalOpen = true
    this.selectedAttachmentFile = attachmentFile
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveAttachmentFile(attachmentFile: IAttachmentFile) {
    this.isLoading = true
    let promise
    const attachmentFileDto = new AttachmentFileDto(attachmentFile)
    if (attachmentFile.isNew) {
      promise = this.client.createAttachmentFile(attachmentFileDto)
    } else {
      promise = this.client.updateAttachmentFile(attachmentFile.id, attachmentFileDto)
    }
    return promise
      .then(() => {
        this.resetUIState(attachmentFile.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupAttachmentFiles = (attachmentFiles: IAttachmentFileDto[]) => {
    return attachmentFiles.map((x) =>
      this.setupAttachmentFileMenuItems(this.addDefaultFields(x))
    )
  }

  private addDefaultFields = (attachmentFile: IAttachmentFileDto): IAttachmentFile => {
    return { ...DefaultAttachmentFile(), ...attachmentFile }
  }

  private setupAttachmentFileMenuItems = (attachmentFile: IAttachmentFile) => {
    attachmentFile.menuItems = []
    if (attachmentFile.isActive) {
      attachmentFile.menuItems.push({
        isConfirm: true,
        name: 'Disable',
        onClick: () => this.disableAttachmentFile(attachmentFile),
      })
    }
    return attachmentFile
  }
}
