import { Button, Tooltip } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import React from 'react'
import { DocumentButtonSummaryResult } from 'src/viewModels/quotes/DocumentButtonSummaryResult'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'

interface IClaimDocumentButtonProps {
  element: DocumentButtonSummaryResult
  selectedQuote?: IQuoteDTO
}

function QuoteClaimButton(props: IClaimDocumentButtonProps) {
  return (
    <Tooltip
      title={
        props.element.percentageLineItemsReconciled !== undefined
          ? props.element.providerGroup +
            ' - ' +
            props.element.percentageLineItemsReconciled +
            '% Reconciled'
          : props.element.providerGroup
      }
    >
      <Button
        variant={
          props.element.percentageLineItemsReconciled === 100 ? 'contained' : 'outlined'
        }
        size="small"
        color="primary"
        style={{ margin: '10px' }}
        onClick={() => {
          window.open(
            `/claim/${props.element.claimId}/episodeOfCare/${props.selectedQuote
              ?.episodeOfCareId!}/view`,
            '_blank'
          )
        }}
      >
        <Visibility />
        &nbsp;{props.element.label}
      </Button>
    </Tooltip>
  )
}
export default QuoteClaimButton
