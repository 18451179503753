import { IHaveAddressFormValues } from '../common/AddressField/AddressField'
import {
  createAddressFormValues,
  toAddressDto,
} from '../common/AddressField/AddressFormValues'
import { DefaultNurseCaseManager, INurseCaseManager } from '../Definitions'
import { ContactDto } from '../generated_client'

export interface INurseCaseManagerFormValues extends IHaveAddressFormValues {
  readonly id: string
  name: string
  preferences: string
  company: string
  contacts: ContactDto[]
  isSpecialPricing: boolean
  isActive: boolean
  legacyId: string
}

function setDefaultValues(): INurseCaseManagerFormValues {
  return {
    address: createAddressFormValues(),
    company: '',
    contacts: [],
    id: '',
    isActive: true,
    isSpecialPricing: false,
    legacyId: '',
    name: '',
    preferences: '',
  }
}

function setValuesFromNurseCaseManager(
  nurseCaseManager: INurseCaseManager
): INurseCaseManagerFormValues {
  return {
    address: createAddressFormValues(nurseCaseManager.address),
    company: nurseCaseManager.company || '',
    contacts: nurseCaseManager.contacts || [],
    id: nurseCaseManager.id || '',
    isActive: nurseCaseManager.isActive || false,
    isSpecialPricing: nurseCaseManager.isSpecialPricing || false,
    legacyId: nurseCaseManager.legacyId || '',
    name: nurseCaseManager.name || '',
    preferences: nurseCaseManager.preferences || '',
  }
}

export function createINurseCaseManagerFormValues(
  nurseCaseManager?: INurseCaseManager
): INurseCaseManagerFormValues {
  if (nurseCaseManager === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromNurseCaseManager(nurseCaseManager)
  }
}

export async function toINurseCaseManager(
  formValues: INurseCaseManagerFormValues,
  original?: INurseCaseManager
): Promise<INurseCaseManager> {
  const nurseCaseManager = original === undefined ? DefaultNurseCaseManager() : original
  const address = await toAddressDto(formValues.address!)
  return {
    ...nurseCaseManager,
    address,
    company: formValues.company,
    contacts: formValues.contacts,
    id: formValues.id,
    isActive: formValues.isActive,
    isSpecialPricing: formValues.isSpecialPricing,
    legacyId: formValues.legacyId,
    name: formValues.name,
    preferences: formValues.preferences,
  }
}
