import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultPhysician, IPhysician } from '../../Definitions'
import {
  Client,
  IPhysicianDto,
  ISpecialtyDto,
  ISubSpecialtyDto,
  PhysicianDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'
import ProvidersStore from '../ProvidersStore'

const ColumnSettingsDto = 'PhysicianDto'

export default class PhysiciansStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public physicians: IPhysician[]
  @observable
  public selectedPhysician?: IPhysician
  @observable
  public specialties: ISpecialtyDto[]
  @observable
  public subSpecialties: ISubSpecialtyDto[]
  public dataTableStore: DataTableStore<IPhysicianDto, IPhysician>

  constructor(
    private providersStore: ProvidersStore,
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IPhysicianDto, IPhysician>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllPhysicians(
          this.providersStore.selectedProvider!.id,
          filter,
          page,
          5,
          orderBy,
          includeInactives
        ),
      (physician) => this.setupPhysicians(physician)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedPhysician = undefined
  }

  @action.bound
  public disablePhysician(physician: IPhysician) {
    this.isLoading = true
    return this.client
      .archivePhysician(this.providersStore.selectedProvider!.id, physician.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndPhysicians() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public getAllPhysicians() {
    this.isLoading = true
    return this.client
      .getAllPhysicians(this.providersStore.selectedProvider!.id)
      .then((resp: SwaggerResponse<IPhysicianDto[]>) =>
        runInAction(() => {
          this.physicians = this.setupPhysicians(resp.result)
        })
      )
  }

  @action.bound
  public getAllSpecialties() {
    this.isLoading = true
    return this.client
      .getAllSpecialties()
      .then((resp: SwaggerResponse<ISpecialtyDto[]>) =>
        runInAction(() => {
          this.specialties = resp.result
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getAllSubSpecialties() {
    this.isLoading = true
    return this.client
      .getAllSubSpecialties()
      .then((resp: SwaggerResponse<ISubSpecialtyDto[]>) =>
        runInAction(() => {
          this.subSpecialties = resp.result
          this.isLoading = false
        })
      )
  }

  @action.bound
  public openDialogWithPhysician(physician: IPhysician) {
    this.isModalOpen = true
    this.selectedPhysician = physician
  }

  @action.bound
  public recoverPhysician(physician: IPhysician) {
    this.isLoading = true
    return this.client
      .reactivatePhysician(this.providersStore.selectedProvider!.id, physician.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public savePhysician(physician: IPhysician) {
    this.isLoading = true
    let promise
    const physicianDto = new PhysicianDto(physician)
    if (physician.isNew) {
      promise = this.client.createPhysician(
        this.providersStore.selectedProvider!.id,
        physicianDto
      )
    } else {
      promise = this.client.updatePhysician(
        this.providersStore.selectedProvider!.id,
        this.selectedPhysician!.id,
        physicianDto
      )
    }
    return promise
      .then(() => {
        this.resetUIState(physician.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupPhysicians = (physicians: IPhysicianDto[]) => {
    return physicians.map((x) => this.setupPhysicianMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (physician: IPhysicianDto): IPhysician => {
    return { ...DefaultPhysician(), ...physician }
  }

  private setupPhysicianMenuItems = (physician: IPhysician) => {
    physician.menuItems = [
      {
        icon: Edit,
        name: 'Edit',
        onClick: () => this.openDialogWithPhysician(physician),
      },
    ]
    if (physician.isActive) {
      physician.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disablePhysician(physician),
      })
    } else {
      physician.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverPhysician(physician),
      })
    }
    return physician
  }
}
