import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { AttachMoney } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { ICMS1500, IEpisodeOfCare, ILocation } from '../Definitions'
import { EpisodeOfCareDto, ITypeDto } from '../generated_client'
import { IStores } from '../Stores'
import { IClaimFormValues } from './ClaimFormValues'
import { ILineItemFormValues } from './LineItemFormValues'

export interface IHaveLineItemFormValues {
  lineItems: ILineItemFormValues
}

interface IDuplicateBillProps {
  currentEpisodeOfCare?: EpisodeOfCareDto | IEpisodeOfCare
  currentBill?: IClaimFormValues
  duplicateBill?: ICMS1500
  open?: boolean
  selectedTypes?: ITypeDto[]
  close?: () => void
  submitForm?: () => void
  getEpisodeOfCareById?: (id: string) => Promise<void>
  selectedLocation?: ILocation
}

class DuplicateBillDialog extends React.Component<IDuplicateBillProps> {
  private save = () => {
    this.props.submitForm!()
    this.props.close!()
  }

  private close = () => {
    this.props.close!()
  }

  private getProviderTypeName(bill: IClaimFormValues | ICMS1500 | undefined) {
    const providerType = this.props.selectedLocation?.providerTypes?.find(
      (x) => x.id === bill?.providerTypeId
    )
    if (providerType) {
      return providerType.abbreviation
    }
    return 'undefined'
  }

  public renderField() {
    const duplicateEpisodeOfCare = this.props.duplicateBill!.episodeOfCare

    return (
      <Grid>
        <Typography>
          A claim already exists that matches the Provider Group, Date of Service, and EOC
          Number on this claim. Click Save to continue saving this claim or click Cancel
          to return to the current claim.
        </Typography>
        <Grid container direction="row" item xs={12}>
          <Grid container direction="column" spacing={1} xs={6}>
            <h1>Previous Claim</h1>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Patient Name:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {duplicateEpisodeOfCare
                      ? duplicateEpisodeOfCare.referral!.patient!.firstName +
                        ' ' +
                        duplicateEpisodeOfCare.referral!.patient!.lastName
                      : 'undefined'}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>EOC/Legacy:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {this.props.duplicateBill?.episodeOfCare
                      ? this.props.duplicateBill.episodeOfCare
                          ?.episodeOfCareNumberOrLegacyId
                      : 'undefined'}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Body Part:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {duplicateEpisodeOfCare
                      ? duplicateEpisodeOfCare.bodyPartText
                      : 'undefined'}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Provider Group:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {this.props.duplicateBill
                      ? this.props.duplicateBill.providerName
                      : 'undefined'}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Provider Type:</strong>
                </Grid>
                <Grid item>
                  <span>{this.getProviderTypeName(this.props.duplicateBill)}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Date of Service:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {this.props.duplicateBill?.dateOfService
                      ? new Date(this.props.duplicateBill?.dateOfService!).toDateString()
                      : 'undefined'}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={1} xs={6}>
            <h1>Current Claim</h1>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Patient Name:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {this.props.currentEpisodeOfCare
                      ? this.props.currentEpisodeOfCare.referral!.patient!.firstName +
                        ' ' +
                        this.props.currentEpisodeOfCare.referral!.patient!.lastName
                      : 'undefined'}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>EOC/Legacy:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {this.props.currentEpisodeOfCare
                      ? this.props.currentEpisodeOfCare.episodeOfCareNumberOrLegacyId
                      : 'undefined'}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Body Part:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {this.props.currentEpisodeOfCare
                      ? this.props.currentEpisodeOfCare.bodyPartText
                      : 'undefined'}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Provider Group:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {this.props.currentBill && this.props.currentBill.provider
                      ? this.props.currentBill.provider!.name
                      : 'undefined'}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Provider Type:</strong>
                </Grid>
                <Grid item>
                  <span>{this.getProviderTypeName(this.props.currentBill)}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <strong>Date of Service:</strong>
                </Grid>
                <Grid item>
                  <span>
                    {this.props.currentBill
                      ? new Date(this.props.currentBill?.dateOfService!).toDateString()
                      : 'undefined'}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  public render() {
    return (
      <>
        {this.props.currentBill && this.props.duplicateBill && (
          <Dialog open={this.props.open!} maxWidth="md">
            <DialogTitle style={{ padding: 0 }}>
              {
                <ResponsiveAppBar
                  title="Duplicate Claim?"
                  pageIcon={<AttachMoney />}
                  isModal={true}
                />
              }
            </DialogTitle>
            <DialogContent style={{ padding: 8 }}>
              {<>{this.renderField()}</>}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.close} data-cy="close">
                Cancel
              </Button>
              <Button
                onClick={this.save}
                data-cy="close"
                color="primary"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    )
  }
}

const InjectedDuplicateBillDialog = inject<
  IStores,
  IDuplicateBillProps,
  Partial<IDuplicateBillProps>,
  any
>((stores: IStores) => ({
  close: stores.cms1500.closeDialog,
  duplicateBill: stores.cms1500.duplicateCMS1500,
  getEpisodeOfCareById: stores.patientEdit.getEpisodeOfCareById,
  open: stores.cms1500.isModalOpen,
  selectedTypes: stores.quotes.selectedTypes,
  selectedLocation: stores.locations.selectedEditClaimLocation,
}))(DuplicateBillDialog)

export default InjectedDuplicateBillDialog
