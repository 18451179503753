import { SvgIconProps } from '@material-ui/core/SvgIcon'
import {
  AttachMoney,
  CreditCard,
  Dashboard,
  List,
  Receipt,
  Settings,
  Search,
  PageviewOutlined,
  HeadsetMicRounded,
  Inbox,
} from '@material-ui/icons'
import PeopleIcon from '@material-ui/icons/People'
import * as React from 'react'
import AdminPages from './adminPages/AdminPages'
import BillOut from './billOut/BillOut'
import Bills from './bills/Bills'
import WriteChecks from './bills/writeChecks/WriteChecks'
import PendingChecks from './bills/PendingChecks'
import BodyParts from './bodyParts/BodyParts'
import Components from './components/Components'
import EditComponent from './components/EditComponent'
import Constants from './config'
import ContactTypes from './contactTypes/ContactTypes'
import CptCodes from './cptCodes/CptCodes'
import DashboardPage from './dashboardPage/DashboardPage'
import DxCodes from './dxCodes/DxCodes'
import EmployerEdit from './employers/EmployerEdit'
import Employers from './employers/Employers'
import InsuranceCompanies from './insuranceCompanies/InsuranceCompanies'
import InsuranceCompany from './insuranceCompanies/InsuranceCompany'
import Login from './login/Login'
import MedicareCostLookups from './medicareCostLookups/MedicareCostLookups'
import MultipleProcedureDiscountExclusionCodes from './multipleProcedureDiscountExclusionCodes/MultipleProcedureDiscountExclusionCodes'
import NurseCaseManager from './nurseCaseManagers/NurseCaseManager'
import NurseCaseManagers from './nurseCaseManagers/NurseCaseManagers'
import EditCompany from './organizations/EditCompany'
import EditEpisodeOfCare from './patients/EpisodesOfCare/EditEpisodeOfCare'
import Patient from './patients/Patient'
import EditReferral from './patients/PatientReferrals/EditReferral'
import Patients from './patients/Patients'
import PreExistingConditions from './preExistingConditions/PreExistingConditions'
import Procedures from './procedures/Procedures'
import EditProvider from './providers/EditProvider'
import Quotes from './quotes/Quotes'
import ReceiveClaim from './ReceiveClaim/ReceiveClaim'
import ReceivePayment from './receivePayment/ReceivePayment'
import EditReferringDoctor from './referringDoctors/EditReferringDoctor'
import ReferringDoctors from './referringDoctors/ReferringDoctors'
import FinancialResearch from './search/FinancialResearch'
import { IRoute } from './routing'
import EditSchedule from './schedules/EditSchedule'
import Tags from './Tags/Tags'
import Users from './users/Users'
import { OrganizationId } from './utils/OrganizationIds'
import CreateGlobalInvoice from './quotes/globalInvoice/CreateGlobalInvoice'
import { EasyQuery } from './easyQuery/EasyQuery'
import ClaimsAcquisition from './claimsAcquisition/ClaimsAcquisition'
import { CashRefund } from 'mdi-material-ui'
import ProviderRefunds from './providerRefunds/providerRefunds'
import PatientDocuments from './patients/PatientDocuments'
import ReceivedChecks from './receivedChecks/ReceivedChecks'
import SentChecks from './SentChecks/SentChecks'
import ProviderGroups from './providers/ProviderGroups'
import DocumentInbox from './workflow/DocumentInbox'
import QuoteInbox from './workflow/QuoteInbox'
import ClaimInbox from './workflow/ClaimInbox'
import Inboxes from './workflow/Inboxes'
import viewClaim from './viewClaim/viewClaim'
import BillOutInbox from './workflow/BillOutInbox'

interface INavigationSection {
  header?: string
  navigationRoutes: INavigationRoute[]
}

interface INavigationRoute extends IRoute {
  icon: React.ReactElement<SvgIconProps>
  text: string
}

const guidRegex =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'
const typeRegex = '[0-9]+'
const fileNameRegex = '.+'

const routes: IRoute[] = [
  {
    component: () => <Login />,
    exact: true,
    path: '/login',
  },
  {
    allowedRoles: [Constants.Roles.Administrator, Constants.Roles.ProviderInfoUpdater],
    component: EditProvider,
    exact: true,
    path: `/providers/:providerId(${guidRegex})`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: EditProvider,
    exact: true,
    path: '/providers/create',
  },

  {
    allowedRoles: [Constants.Roles.Administrator],
    component: EmployerEdit,
    exact: true,
    path: `/admin/employers/:employerId(${guidRegex})`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: EmployerEdit,
    exact: true,
    path: '/admin/employers/create',
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: Employers,
    exact: true,
    path: '/admin/employers',
  },
  {
    allowedRoles: [
      Constants.Roles.Administrator,
      Constants.Roles.FinanceTier2,
      Constants.Roles.FinanceManager,
    ],
    component: CreateGlobalInvoice,
    exact: true,
    path: `/quotes/:quoteId(${guidRegex})/globalInvoice/:actionId`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: ReceiveClaim,
    exact: true,
    path: `/quotes/claim`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: PreExistingConditions,
    exact: true,
    path: '/admin/preexistingconditions',
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: Tags,
    exact: true,
    path: '/admin/tags',
  },
  {
    component: ReceiveClaim,
    exact: true,
    path: `/claim/quote/:quoteId(${guidRegex})`,
  },
  {
    component: ReceiveClaim,
    exact: true,
    path: `/claim/episodeOfCare/:episodeOfCareId(${guidRegex})`,
  },
  {
    component: ReceiveClaim,
    exact: true,
    path: `/claim/:claimId(${guidRegex})/quote/:quoteId(${guidRegex})`,
  },
  {
    component: ReceiveClaim,
    exact: true,
    path: `/claim/:claimId(${guidRegex})/episodeOfCare/:episodeOfCareId(${guidRegex})`,
  },
  {
    component: ReceiveClaim,
    exact: true,
    path: `/claim/:claimId(${guidRegex})/episodeOfCare/:episodeOfCareId(${guidRegex})/appealType/:appealType(${typeRegex})`,
  },
  {
    component: viewClaim,
    exact: true,
    path: `/claim/:claimId(${guidRegex})/episodeOfCare/:episodeOfCareId(${guidRegex})/view`,
  },
  {
    allowedRoles: [
      Constants.Roles.Administrator,
      Constants.Roles.FinanceTier3,
      Constants.Roles.FinanceManager,
    ],
    component: Bills,
    exact: true,
    path: `/payproviders/review/:providerId(${guidRegex})`,
  },
  {
    allowedRoles: [
      Constants.Roles.Administrator,
      Constants.Roles.FinanceTier3,
      Constants.Roles.FinanceManager,
    ],
    component: WriteChecks,
    exact: true,
    path: '/payproviders/checks',
  },
  {
    allowedRoles: [
      Constants.Roles.Administrator,
      Constants.Roles.FinanceTier3,
      Constants.Roles.FinanceManager,
    ],
    component: PendingChecks,
    exact: true,
    path: '/payproviders/checks-holding',
  },
  {
    component: Patient,
    exact: true,
    path: `/patients/patient/:patientId(${guidRegex})?`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: InsuranceCompanies,
    exact: true,
    path: `/admin/insuranceCompanies`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: InsuranceCompany,
    exact: true,
    path: `/admin/insuranceCompanies/insuranceCompany/:insuranceCompanyId(${guidRegex})?`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: MultipleProcedureDiscountExclusionCodes,
    exact: true,
    path: '/admin/exclusionCodes',
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: EditCompany,
    exact: true,
    path: '/admin/companyInfo',
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: ReferringDoctors,
    exact: true,
    path: '/admin/referringDoctors',
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: NurseCaseManagers,
    exact: true,
    path: `/admin/nurseCaseManagers`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: NurseCaseManager,
    exact: true,
    path: `/admin/nurseCaseManagers/nurseCaseManager/:nurseCaseManagerId(${guidRegex})?`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: EditReferringDoctor,
    exact: true,
    path: `/admin/referringDoctors/referringDoctor/:referringDoctorId(${guidRegex})?`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: EditReferringDoctor,
    exact: true,
    path: `/admin/referringDoctors/:referringDoctorId(${guidRegex})`,
  },
  {
    component: EditReferral,
    exact: true,
    path: `/patients/patient/:patientId(${guidRegex})?/referral/:referralId(${guidRegex})?`,
  },
  {
    component: EditEpisodeOfCare,
    exact: true,
    path: `/patients/patient/:patientId(${guidRegex})/referral/:referralId(${guidRegex})/episodeOfCare/:episodeOfCareId(${guidRegex})?`,
  },
  {
    component: EditSchedule,
    exact: true,
    path: `/patients/patient/:patientId(${guidRegex})/referral/:referralId(${guidRegex})/episodeOfCare/:episodeOfCareId(${guidRegex})/schedule/:scheduleId(${guidRegex})?`,
  },
  {
    component: PatientDocuments,
    exact: true,
    path: `/patients/patient/:patientId(${guidRegex})/documents`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: Components,
    exact: true,
    path: `/admin/components`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: EditComponent,
    exact: true,
    path: `/admin/components/component/:componentId(${guidRegex})?`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: ContactTypes,
    exact: true,
    path: `/admin/contactTypes`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: BodyParts,
    exact: true,
    path: `/admin/bodyParts`,
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: CptCodes,
    exact: true,
    path: '/admin/cptCodes',
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: DxCodes,
    exact: true,
    path: '/admin/dxCodes',
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: MedicareCostLookups,
    exact: true,
    path: '/admin/medicareCost',
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: Procedures,
    exact: true,
    path: '/procedures',
  },
  {
    allowedRoles: [Constants.Roles.Administrator, Constants.Roles.ProviderInfoUpdater],
    component: ProviderGroups,
    exact: true,
    path: '/providers',
  },
  {
    allowedRoles: [Constants.Roles.GeneralFinance, Constants.Roles.Administrator],
    component: () => <SentChecks />,
    exact: true,
    path: '/admin/sentChecks',
  },
  {
    allowedRoles: [Constants.Roles.GeneralFinance, Constants.Roles.Administrator],
    component: () => <ReceivedChecks />,
    exact: true,
    path: '/admin/receivedChecks',
  },
  {
    allowedRoles: [Constants.Roles.Administrator],
    component: () => <Users />,
    exact: true,
    path: '/users',
  },
  {
    component: DocumentInbox,
    exact: true,
    path: `/workflow/document/file/:documentName(${fileNameRegex})`,
  },
  {
    component: DocumentInbox,
    exact: true,
    path: `/workflow/document/:documentId(${guidRegex})/inbox/:inboxId(${fileNameRegex})`,
  },
  {
    component: QuoteInbox,
    exact: true,
    path: `/workflow/quote/:quoteId(${guidRegex})/inbox/:inboxId(${fileNameRegex})`,
  },
  {
    component: QuoteInbox,
    exact: true,
    path: `/workflow/quote/:quoteId(${guidRegex})/default-inbox`,
  },
  {
    component: ClaimInbox,
    exact: true,
    path: `/workflow/claim/:claimId(${guidRegex})/inbox/:inboxId(${fileNameRegex})`,
  },
  {
    component: ClaimInbox,
    exact: true,
    path: `/workflow/claim/:claimId(${guidRegex})/default-inbox`,
  },
  {
    component: BillOutInbox,
    exact: true,
    path: `/workflow/billout/:claimId(${guidRegex})/inbox/:inboxId(${fileNameRegex})`,
  },
  {
    component: BillOutInbox,
    exact: true,
    path: `/workflow/billout/:claimId(${guidRegex})/default-inbox`,
  },
  {
    component: BillOutInbox,
    exact: true,
    path: `/workflow/billout/:claimId(${guidRegex})/billout-search/:inboxId(${fileNameRegex})`,
  },
  {
    component: BillOutInbox,
    exact: true,
    path: `/workflow/billout/:claimId(${guidRegex})/default-billout-search/:inboxId(${fileNameRegex})`,
  },
  {
    component: BillOutInbox,
    exact: true,
    path: `/workflow/billout/:claimId(${guidRegex})/received-search-default`,
  },
  {
    component: BillOutInbox,
    exact: true,
    path: `/workflow/billout/:claimId(${guidRegex})/inbox/:inboxId(${fileNameRegex})/billout-search`,
  },
]

const navigationSection: INavigationSection = {
  navigationRoutes: [
    {
      allowedRoles: [Constants.Roles.CareCoordinator, Constants.Roles.Administrator],
      component: () => <DashboardPage />,
      exact: true,
      icon: <Dashboard />,
      path: '/',
      text: 'Coordinator Dashboard',
    },
    {
      allowedRoles: [],
      component: Inboxes,
      exact: true,
      icon: <Inbox />,
      path: `/workflow-inboxes`,
      text: 'Inboxes',
    },
    {
      component: () => <Patients />,
      exact: true,
      icon: <PeopleIcon />,
      path: '/patients',
      text: 'Patients',
    },
    {
      allowedOrganization: [OrganizationId.Surgical],
      allowedRoles: [
        Constants.Roles.Administrator,
        Constants.Roles.FinanceTier2,
        Constants.Roles.FinanceManager,
      ],
      component: () => <Quotes />,
      exact: true,
      icon: <List />,
      path: '/quotes',
      text: 'Quotes',
    },
    {
      allowedRoles: [
        Constants.Roles.Administrator,
        Constants.Roles.FinanceTier3,
        Constants.Roles.FinanceManager,
      ],
      component: Bills,
      exact: true,
      icon: <AttachMoney />,
      path: '/payproviders',
      text: 'Pay Providers',
    },
    {
      allowedRoles: [
        Constants.Roles.Administrator,
        Constants.Roles.FinanceTier2,
        Constants.Roles.FinanceManager,
      ],
      component: BillOut,
      exact: true,
      icon: <Receipt />,
      path: '/billOut',
      text: 'Bill Out',
    },
    {
      allowedRoles: [
        Constants.Roles.Administrator,
        Constants.Roles.FinanceTier2,
        Constants.Roles.FinanceManager,
      ],
      component: ReceivePayment,
      exact: true,
      icon: <CreditCard />,
      path: '/receivePayment',
      text: 'Receive Payment',
    },
    {
      allowedRoles: [
        Constants.Roles.Administrator,
        Constants.Roles.FinanceTier2,
        Constants.Roles.FinanceManager,
      ],
      component: ProviderRefunds,
      exact: true,
      icon: <CashRefund />,
      path: '/providerRefunds',
      text: 'Process Refunds',
    },
    {
      component: FinancialResearch,
      exact: true,
      icon: <Search />,
      path: '/financialResearch',
      text: 'Financial Research',
    },
    {
      component: EasyQuery,
      exact: true,
      icon: <PageviewOutlined />,
      path: `/advancedSearch/:currentUserId(${guidRegex})`,
      text: 'Advanced Search',
    },
    {
      component: () => <ClaimsAcquisition />,
      exact: true,
      icon: <HeadsetMicRounded />,
      path: '/claimsAcquisition',
      text: 'Claims Acquisition',
    },
    {
      allowedRoles: [
        Constants.Roles.ProviderInfoUpdater,
        Constants.Roles.GeneralFinance,
        Constants.Roles.Administrator,
      ],
      component: () => <AdminPages />,
      exact: true,
      icon: <Settings />,
      path: '/admin',
      text: 'Admin',
    },
    // DO NOT REMOVE -- USED FOR CODE GENERATION
  ],
}

const navigationSections: INavigationSection[] = [navigationSection]

let allRoutes = routes
navigationSections.forEach((section) => {
  allRoutes = allRoutes.concat(section.navigationRoutes)
})

export default allRoutes
export { INavigationRoute, INavigationSection, navigationSections }
