import _ from 'lodash'
import { IHaveAddressFormValues } from '../common/AddressField/AddressField'
import {
  createAddressFormValues,
  toAddressDto,
} from '../common/AddressField/AddressFormValues'
import { DefaultPatient, IPatient } from '../Definitions'
import { PatientPreExistingConditionDto } from '../generated_client'

export interface IPatientFormValues extends IHaveAddressFormValues {
  patientId?: string | undefined
  preCertNumber?: string | undefined
  patientIdNumber?: number | undefined
  name?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  dateOfBirthText: string
  cellPhone?: string | undefined
  homePhone?: string | undefined
  workPhone?: string | undefined
  allowTexting?: boolean | undefined
  gender?: string | undefined
  heightFeet?: string
  heightInches?: string
  weight?: string
  hxOfSx?: string | undefined
  patientComments?: string | undefined
  sSN?: string | undefined
  preExistingConditions: PatientPreExistingConditionDto[]
  readonly id?: string | undefined
  createdOn?: Date | undefined
  modifiedOn?: Date | undefined
  deletedOn?: Date | undefined
  isActive: boolean
  emailAddress: string
}

function setDefaultValues(): IPatientFormValues {
  return {
    address: createAddressFormValues(),
    allowTexting: false,
    cellPhone: '',
    createdOn: new Date(),
    dateOfBirthText: '',
    emailAddress: '',
    firstName: '',
    gender: '',
    heightFeet: undefined,
    heightInches: undefined,
    homePhone: '',
    hxOfSx: '',
    id: '',
    isActive: false,
    lastName: '',
    modifiedOn: new Date(),
    name: '',
    patientComments: '',
    patientId: undefined,
    preCertNumber: '',
    preExistingConditions: [],
    sSN: '',
    weight: '',
    workPhone: '',
  }
}

function setValuesFromPatient(patient: IPatient): IPatientFormValues {
  return {
    address: createAddressFormValues(patient.address),
    allowTexting: patient.allowTexting || false,
    cellPhone: patient.cellPhone || '',
    createdOn: patient.createdOn || new Date(),
    dateOfBirthText: patient.dateOfBirthText,
    emailAddress: patient.emailAddress || '',
    firstName: patient.firstName || '',
    gender: patient.gender || '',
    heightFeet: patient.heightFeet === undefined ? '' : patient.heightFeet.toString(),
    heightInches:
      patient.heightInches === undefined ? '' : patient.heightInches.toString(),
    homePhone: patient.homePhone || '',
    hxOfSx: patient.hxOfSx || '',
    id: patient.id || '',
    isActive: patient.isActive,
    lastName: patient.lastName || '',
    modifiedOn: patient.modifiedOn || new Date(),
    name: patient.name || '',
    patientComments: patient.patientComments || '',
    patientId: patient.patientIdNumber
      ? _.padStart(patient.patientIdNumber.toString(), 7, '0')
      : undefined,
    patientIdNumber: patient.patientIdNumber || undefined,
    preCertNumber: patient.preCertNumber || '',
    preExistingConditions: patient.preExistingConditions,
    sSN: patient.sSN || '',
    weight: patient.weight === undefined ? '' : patient.weight.toString(),
    workPhone: patient.workPhone || '',
  }
}

function toPreExistingConditionsDto(
  preExistingConditions: PatientPreExistingConditionDto[]
) {
  const preExistingConditionDtos = [] as PatientPreExistingConditionDto[]
  preExistingConditions.forEach((e) => {
    preExistingConditionDtos.push(
      new PatientPreExistingConditionDto({
        patientId: e.patientId,
        preExistingConditionId: e.preExistingConditionId,
      })
    )
  })
  return preExistingConditionDtos
}

export function createIPatientFormValues(Patient?: IPatient): IPatientFormValues {
  if (Patient === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromPatient(Patient)
  }
}

export async function toIPatient(
  formValues: IPatientFormValues,
  original?: IPatient
): Promise<IPatient> {
  const patient = original === undefined ? DefaultPatient() : original
  const address = await toAddressDto(formValues.address!)
  const preExistingConditions = toPreExistingConditionsDto(
    formValues.preExistingConditions
  )
  address.id = formValues.address!.id
  return {
    ...patient,
    address,
    allowTexting: formValues.allowTexting || false,
    cellPhone: formValues.cellPhone || '',
    dateOfBirthText: formValues.dateOfBirthText,
    emailAddress: formValues.emailAddress,
    firstName: formValues.firstName || '',
    gender: formValues.gender || '',
    heightFeet:
      formValues.heightFeet === undefined
        ? undefined
        : parseInt(formValues.heightFeet, 10),
    heightInches:
      formValues.heightInches === undefined
        ? undefined
        : parseInt(formValues.heightInches, 10),
    homePhone: formValues.homePhone || '',
    hxOfSx: formValues.hxOfSx || '',
    id: formValues.id || '',
    isActive: formValues.isActive,
    lastName: formValues.lastName || '',
    name: formValues.name || '',
    patientComments: formValues.patientComments || '',
    patientIdNumber: formValues.patientIdNumber || undefined,
    preCertNumber: formValues.preCertNumber || '',
    preExistingConditions,
    sSN: formValues.sSN || '',
    weight: formValues.weight === undefined ? 0 : parseInt(formValues.weight, 10),
    workPhone: formValues.workPhone || '',
  }
}
