import {
  ApplyPaymentRequest,
  IApplyPaymentRequest,
} from 'src/viewModels/ApplyPaymentRequest'
import { LineItemDistributionDetail } from 'src/viewModels/LineItemDistributionDetail'
import { IHaveAddressFormValues } from '../common/AddressField/AddressField'
import {
  createAddressFormValues,
  toAddressDto,
} from '../common/AddressField/AddressFormValues'
import {
  DefaultBillCheck,
  DefaultCheck,
  DefaultProvider,
  ICheck,
  IProvider,
} from '../Definitions'
import { BillCheckDto, IProviderTypeDto, ProviderTypeDto } from '../generated_client'
import { createIClaimFormValues, IClaimFormValues } from '../ReceiveClaim/ClaimFormValues'
import { createITagFormValues, ITagFormValues, toTagDto } from '../Tags/TagFormValues'
import moment from 'moment'

export interface IProviderTypeFormValues {
  readonly id: string
  abbreviation: string
  name: string
  checked: boolean
}

export interface IProviderFormValues extends IHaveAddressFormValues {
  readonly id: string
  isSingleLocation: boolean
  name: string
  npiNumber: string
  legacyId: string
  legalName: string
  locationCount: number
  billingServicer: string
  ccnField: string
  cmS1500s?: IClaimFormValues[]
  organizationId: string
  providerTags: ITagFormValues[]
  providerTypeIds: IProviderTypeFormValues[]
  providerTypes: IProviderTypeFormValues[]
  isActive: boolean
  isExpanded?: boolean
  isChecked?: boolean
  checkNumber?: string // This needs moved to some check object that doesn't exist currently
  datePaid?: string
  requiresSingleCaseAgreement: boolean
  contractLink?: string
  contractSummary?: string
  notes?: string
}

function setDefaultValues(): IProviderFormValues {
  return {
    billingAddress: createAddressFormValues(),
    billingServicer: '',
    ccnField: '',
    checkNumber: '',
    datePaid: '',
    id: '',
    isActive: true,
    isBillingAddressSameAsPhysicalAddress: false,
    isChecked: false,
    isExpanded: false,
    isSingleLocation: false,
    legacyId: '',
    legalName: '',
    locationCount: 0,
    name: '',
    npiNumber: '',
    organizationId: '',
    physicalAddress: createAddressFormValues(),
    providerTags: [] as ITagFormValues[],
    providerTypeIds: [] as IProviderTypeFormValues[],
    providerTypes: [] as IProviderTypeFormValues[],
    requiresSingleCaseAgreement: false,
    notes: '',
  }
}

export function setValuesFromProvider(
  provider: IProvider,
  allProviderTypes?: IProviderTypeDto[]
): IProviderFormValues {
  const selectedProviders = allProviderTypes
    ? allProviderTypes.map((x) => setValuesFromProviderType(x))
    : []

  selectedProviders.forEach((element) => {
    const providerTypesSelected = provider.providerTypes ? provider.providerTypes : []
    element.checked = providerTypesSelected.some((x) => x.id === element.id)
  })

  return {
    billingAddress: createAddressFormValues(provider.billingAddress),
    billingServicer: provider.billingServicer || '',
    ccnField: provider.ccn || '',
    datePaid: '',
    cmS1500s: provider.cmS1500s
      ? provider.cmS1500s.map((x) => createIClaimFormValues(x))
      : undefined,
    id: provider.id || '',
    isActive: provider.isActive || false,
    isBillingAddressSameAsPhysicalAddress:
      provider.isBillingAddressSameAsPhysicalAddress || false,
    isChecked: false,
    isExpanded: false,
    isSingleLocation: provider.isSingleLocation,
    legacyId: provider.legacyId,
    legalName: provider.legalName || '',
    locationCount: provider.locationCount,
    name: provider.name || '',
    npiNumber: provider.npiNumber || '',
    organizationId: provider.organizationId,
    physicalAddress: createAddressFormValues(provider.physicalAddress),
    providerTags: provider.providerTags
      ? provider.providerTags.map(createITagFormValues)
      : [],
    providerTypeIds: selectedProviders ? selectedProviders : [],
    providerTypes: provider.providerTypes
      ? provider.providerTypes.map((x) => setValuesFromProviderType(x))
      : [],
    requiresSingleCaseAgreement: provider.requiresSingleCaseAgreement,
    contractLink: provider.contractLink,
    contractSummary: provider.contractSummary,
    notes: provider.notes,
  }
}

export function setValuesFromProviderType(
  providerType: IProviderTypeDto
): IProviderTypeFormValues {
  return { ...providerType } as IProviderTypeFormValues
}

export function createIProviderFormValues(
  provider?: IProvider,
  providerTypes?: IProviderTypeDto[]
): IProviderFormValues {
  if (provider === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromProvider(provider, providerTypes)
  }
}

export async function toIProvider(
  formValues: IProviderFormValues,
  original?: IProvider
): Promise<IProvider> {
  const provider = original === undefined ? DefaultProvider() : original
  const physicalAddress = await toAddressDto(formValues.physicalAddress!)
  const billingAddress = formValues.isBillingAddressSameAsPhysicalAddress
    ? await toAddressDto(formValues.physicalAddress!)
    : await toAddressDto(formValues.billingAddress!)
  billingAddress.id = formValues.billingAddress!.id
  const checkedTypes = formValues.providerTypeIds.filter((x) => x.checked)
  return {
    ...provider,
    billingAddress,
    billingServicer: formValues.billingServicer,
    ccn: formValues.ccnField,
    id: formValues.id,
    isActive: formValues.isActive,
    isBillingAddressSameAsPhysicalAddress:
      formValues.isBillingAddressSameAsPhysicalAddress!,
    isSingleLocation: formValues.isSingleLocation,
    legacyId: formValues.legacyId,
    legalName: formValues.legalName,
    name: formValues.name,
    npiNumber: formValues.npiNumber,
    organizationId: formValues.organizationId,
    physicalAddress,
    providerTags: formValues.providerTags ? formValues.providerTags.map(toTagDto) : [],
    providerTypes: checkedTypes.length > 0 ? checkedTypes.map(toProviderTypeDto) : [],
    requiresSingleCaseAgreement: formValues.requiresSingleCaseAgreement,
    contractLink: formValues.contractLink,
    contractSummary: formValues.contractSummary,
    notes: formValues.notes ?? '',
  }
}

export function createICheckFromProvider(formValues: IProviderFormValues): ICheck {
  const newCheck = DefaultCheck(true)
  var checks = [] as BillCheckDto[]
  formValues
    .cmS1500s!.filter((x) => x.isChecked)
    .forEach(function (claim) {
      createBillChecks(claim).forEach(function (individualBillCheck) {
        checks.push(individualBillCheck)
      })
    })
  return {
    ...newCheck,
    amount: calculateProviderTotal(formValues),
    billChecks: checks,
    checkNumber: formValues.checkNumber || '',
    datePaid: new Date(moment(formValues.datePaid, 'YYYY-MM-DD').toISOString()),
  }
}

export function toIApplyPayment(formValues: IProviderFormValues): IApplyPaymentRequest[] {
  var total = calculateProviderTotal(formValues)
  var payments = formValues
    .cmS1500s!.filter((x) => x.isChecked)
    .map(
      (item) =>
        new ApplyPaymentRequest(
          item.id,
          formValues.checkNumber || '',
          new Date(moment(formValues.datePaid, 'YYYY-MM-DD').toISOString()),
          total,
          item.q24.map(
            (lineItem) =>
              new LineItemDistributionDetail(
                lineItem.id,
                lineItem.overrideAmount
                  ? lineItem.overrideAmount
                  : +lineItem.contractAmount!,
                lineItem.reasonCode
              )
          ),
          undefined,
          formValues.billingAddress?.name,
          formValues.billingAddress?.addressDisplay
        )
    )

  return payments
}

function createBillChecks(cms1500: IClaimFormValues) {
  var bills = [] as BillCheckDto[]
  const newBillCheck = DefaultBillCheck(true)
  cms1500.q24.forEach(function (claimLine) {
    bills.push(
      new BillCheckDto({
        ...newBillCheck,
        amount: claimLine.overrideAmount
          ? claimLine.overrideAmount
          : +claimLine.contractAmount!,
        cmS1500Id: cms1500.id,
        CMS1500LineItemId: claimLine.id,
        comments: claimLine.reasonCode,
      })
    )
  })

  return bills
}

function calculateProviderTotal(provider: IProviderFormValues) {
  let total = 0

  provider
    .cmS1500s!.filter((x) => x.isChecked)
    .forEach((y) => {
      total += y.q24.map((z) => +z.contractAmount! * 100).reduce((a, b) => a + b, 0)
    })

  return total / 100
}

export function toProviderTypeDto(providerTypeId: IProviderTypeFormValues) {
  return new ProviderTypeDto({
    id: providerTypeId.id,
  })
}
