import Axios from 'axios'
import { AddressDTO } from 'src/viewModels/AddressDTO'
import { pagedList } from 'src/viewModels/pagedList'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function ChangeLocationProviderGroup(
  locationId: string,
  groupId: string
): Promise<void> {
  const response = await Axios.put<void>(
    `${base_url}/api/location/${locationId}/update-provider-group/${groupId}`
  )

  return response.data
}

export async function getProviderLocationBillingAddresees(
  locationId: string | undefined,
  page: number,
  pageSize: number
): Promise<pagedList> {
  let data = {
    page: page,
    pageSize: pageSize,
  }
  const response = await Axios.post<pagedList>(
    `${base_url}/api/location/${locationId}/billing-addresses`,
    data
  )

  return response.data
}

export async function recoverProviderLocationBillingAddress(
  locationId: string | undefined,
  locationAddressId: string | undefined
) {
  const response = await Axios.put(
    `${base_url}/api/location/${locationId}/recover-billing-address/${locationAddressId}`
  )
  return response
}

export async function createProviderLocationBillingAddress(
  locationId: string | undefined,
  address: AddressDTO
) {
  const response = await Axios.post(
    `${base_url}/api/location/${locationId}/billing-address`,
    address
  )

  return response.data
}
