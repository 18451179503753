export enum ClaimsAcquisitionStatus {
  Confirmed,
  NeedsAddressed,
  WaitClaims,
  WaitResponse,
  Dismissed,
  Complete,
  Imported,
  Corrected,
  Issued,
  IssueWithClaim,
  NeedsConfirmed,
  IssuePaid,
}
