import { Grid } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { FormikActions, getIn } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import * as Yup from 'yup'
import ESDialog from '../../common/ESDialog'
import FullWidthField from '../../common/FullWidthField'
import { ISchedule } from '../../Definitions'
import { SingleCaseAgreementTextDto } from '../../generated_client'
import { IStores } from '../../Stores'

const SingleCaseAgreementSchema = Yup.object().shape({
  additionalText: Yup.string().max(200, 'Must be fewer or equal to 200 characters'),
  reimbursementAmount: Yup.string().required('Reimbursement Amount is required'),
})

interface ISingleCaseAgreementDialogProps {
  close?: () => void
  createSingleCaseAgreementPdf?: (
    scheduleId: string,
    singleCaseAgreementDto: SingleCaseAgreementTextDto
  ) => Promise<void>
  isLoading?: boolean
  isOpen?: boolean
  schedule?: ISchedule
}

class SingleCaseAgreementDialog extends React.Component<ISingleCaseAgreementDialogProps> {
  public save = (
    values: SingleCaseAgreementTextDto,
    formikBag: FormikActions<SingleCaseAgreementTextDto>
  ) => {
    if (
      values.reimbursementAmount !== undefined &&
      !isNaN(values.reimbursementAmount as any)
    ) {
      const formatter = new Intl.NumberFormat('en-US', {
        currency: 'USD',
        minimumFractionDigits: 2,
        style: 'currency',
      })
      values.reimbursementAmount = formatter.format(
        parseFloat(values.reimbursementAmount)
      )
    }
    this.props.createSingleCaseAgreementPdf!(this.props.schedule!.id, values).finally(
      () => {
        formikBag.setSubmitting(false)
        this.props.close!()
      }
    )
  }

  private getInitialReimbursementAmount() {
    const { schedule } = this.props
    let reimbursementAmount: string = ''
    const primaryLocation = schedule!.scheduleProviderLocations!.filter(
      (x) => x.isPrimaryLocation
    )![0]!.location
    if (
      schedule &&
      primaryLocation &&
      primaryLocation.locationProcedures!.length > 0 &&
      schedule.scheduleTests![0].test
    ) {
      const locationProcedure = primaryLocation.locationProcedures!.find(
        (x) =>
          x.procedure!.diagnostic ===
          schedule.scheduleTests![0].test!.procedure!.diagnostic
      )
      if (locationProcedure) {
        if (locationProcedure.contractPrice && locationProcedure.contractPrice > 0) {
          const formatter = new Intl.NumberFormat('en-US', {
            currency: 'USD',
            minimumFractionDigits: 2,
            style: 'currency',
          })
          reimbursementAmount = formatter.format(locationProcedure.contractPrice)
        } else if (
          locationProcedure.contractPrice === undefined ||
          locationProcedure.contractPrice === 0
        ) {
          reimbursementAmount =
            locationProcedure.otherBilling !== undefined
              ? locationProcedure.otherBilling
              : ''
        }
      }
    }

    return reimbursementAmount
  }

  public render() {
    const { close, isLoading, isOpen } = this.props

    const hasValue = (field: string, values: SingleCaseAgreementTextDto) => {
      const value = getIn(values, field)

      return !!value
    }
    return (
      <ESDialog
        close={close!}
        initialValues={
          {
            additionalText: '',
            reimbursementAmount: this.getInitialReimbursementAmount(),
          } as SingleCaseAgreementTextDto
        }
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        saveButtonText="Continue"
        title="Reimbursement and Additional Text"
        titleIcon={<CheckCircle />}
        validationSchema={SingleCaseAgreementSchema}
        isInitialValid={this.getInitialReimbursementAmount() !== ''}
      >
        {({ values, initialValues }) => (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h5>
                Please verify/enter reimbursement and additional text for this Single Case
                Agreement
              </h5>
            </Grid>
            <Grid item xs={4}>
              <FullWidthField
                autoFocus
                name="reimbursementAmount"
                label="Reimbursement"
                InputLabelProps={{
                  shrink: hasValue('reimbursementAmount', values),
                }}
                variant="outlined"
                required
                disabled={initialValues.reimbursementAmount !== ''}
              />
            </Grid>
            <Grid item xs={12}>
              <FullWidthField
                name="additionalText"
                label="Additional Text (up to 200 characters)"
                rows="3"
                multiline={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: hasValue('additionalText', values),
                }}
              />
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }
}

const InjectedSingleCaseAgreement = inject<
  IStores,
  ISingleCaseAgreementDialogProps,
  Partial<ISingleCaseAgreementDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.schedules.closeSingleCaseAgreementDialog,
  createSingleCaseAgreementPdf: stores.schedules.createSingleCaseAgreementPdf,
  isLoading: stores.schedules.isLoading,
  isOpen: stores.schedules.isSingleCaseAgreementDialogOpen,
  schedule: stores.schedules.selectedSchedule,
}))(SingleCaseAgreementDialog)

export default InjectedSingleCaseAgreement
