import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultBodyPart, IBodyPart } from '../../Definitions'
import { BodyPartDto, Client, IBodyPartDto } from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'BodyPartDto'

export default class BodyPartsStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public selectedBodyPart?: IBodyPart
  public dataTableStore: DataTableStore<IBodyPartDto, IBodyPart>

  constructor(
    private globalStore: GlobalStore,
    private client: Client
  ) {
    this.dataTableStore = new DataTableStore<IBodyPartDto, IBodyPart>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllBodyParts(filter, page, undefined, orderBy, includeInactives),
      (bodyPart) => this.setupBodyParts(bodyPart)
    )
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedBodyPart = undefined
  }

  @action.bound
  public disableBodyPart(bodyPart: IBodyPart) {
    this.isLoading = true
    return this.client
      .archiveBodyPart(bodyPart.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getColumnSettingsAndBodyParts() {
    if (!this.globalStore.user) {
      return
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  @action.bound
  public openDialogWithBodyPart(bodyPart: IBodyPart) {
    this.isModalOpen = true
    this.selectedBodyPart = bodyPart
  }

  @action.bound
  public recoverBodyPart(bodyPart: IBodyPart) {
    this.isLoading = true
    return this.client
      .reactivateBodyPart(bodyPart.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveBodyPart(bodyPart: IBodyPart) {
    this.isLoading = true
    let promise
    const bodyPartDto = new BodyPartDto(bodyPart)
    if (bodyPart.isNew) {
      promise = this.client.createBodyPart(bodyPartDto)
    } else {
      promise = this.client.updateBodyPart(bodyPart.id, bodyPartDto)
    }
    return promise
      .then(() => {
        this.resetUIState(bodyPart.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  private setupBodyParts = (bodyParts: IBodyPartDto[]) => {
    return bodyParts.map((x) => this.setupBodyPartMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (bodyPart: IBodyPartDto): IBodyPart => {
    return { ...DefaultBodyPart(), ...bodyPart }
  }

  private setupBodyPartMenuItems = (bodyPart: IBodyPart) => {
    bodyPart.menuItems = [
      {
        icon: Edit,
        name: 'Edit',
        onClick: () => this.openDialogWithBodyPart(bodyPart),
      },
    ]
    if (bodyPart.isActive) {
      bodyPart.menuItems.push({
        color: 'red',
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableBodyPart(bodyPart),
      })
    } else {
      bodyPart.menuItems.push({
        color: '#94D33D',
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverBodyPart(bodyPart),
      })
    }
    return bodyPart
  }
}
