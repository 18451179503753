const ClaimsAcquisitionNameConstants: Record<number, string> = {
  0: 'Confirmed',
  1: 'Needs Addressed',
  2: 'Waiting on Claims',
  3: 'Waiting on Response',
  4: 'Dismissed',
  5: 'Complete',
  6: 'Imported',
  7: 'Inc. Billing - Corrected',
  8: 'Inc. Billing - Issued',
  9: 'Issue with Claim',
  10: 'Needs Confirmed',
  11: 'Inc. Billing - Paid',
}
export default ClaimsAcquisitionNameConstants
