export interface IClaimEventSummary {
  locationId: string
  scheduleId: string
  episodeOfCareId: string
}

export class ClaimEventSummary implements IClaimEventSummary {
  public locationId: string
  public scheduleId: string
  public episodeOfCareId: string
}
