import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  FormControl,
  Button,
  InputLabel,
  TextField,
  Select,
  OutlinedInput,
  createStyles,
  WithStyles,
  withStyles,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  MenuItem,
} from '@material-ui/core'
import {
  AddShoppingCart,
  AttachMoney,
  Block,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CreditCard,
  InfoOutlined,
  PictureAsPdfRounded,
  ReceiptOutlined,
  Search,
  Warning,
} from '@material-ui/icons'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import React, { ChangeEvent } from 'react'
import ReactPaginate from 'react-paginate'
import { getDocumentUrlById, searchFinancial } from 'src/services/SearchService'
import { formatCurrency, formatDate } from '../utils/Formatter'
import '../Grid.css'
import ESDialog from 'src/common/ESDialog'
import { getClaimPaymentDetails } from 'src/services/ClaimService'
import { getBillOutPaymentDetails } from 'src/services/BillOutService'
import { PaymentDetailSummaryResult } from 'src/viewModels/PaymentDetailSummaryResult'
import { pagedList } from 'src/viewModels/pagedList'
import { FinancialResearchSummaryResult } from 'src/viewModels/FinancialResearchSummaryResult'
import { FinancialResearchSearchRequest } from 'src/viewModels/FinancialResearchSearchRequest'
import { Helmet } from 'react-helmet'
import { inject } from 'mobx-react'
import { IStores } from 'src/Stores'
import BundleClaimsModal from 'src/quotes/QuoteBills/BundleClaimsModal'
import ESMenu from 'src/common/ESMenu'
import AppealLogModal from './AppealLogModal'
import AppealNameConstants from 'src/constants/AppealNameConstants'
import AppealNoteModal from './AppealNoteModal'
import { GetComponentOptions } from 'src/services/LookupService'
import { AppealType } from 'src/utils/AppealType'
import ClaimLastUpdateHistoryModal from './ClaimLastUpdateHistoryModal'
import MarkClaimDeletedModal from './MarkClaimDeletedModal'
import { ClaimHeaderDetailsSummaryResult } from 'src/viewModels/ClaimHeaderDetailsSummaryResult'

const styles = () =>
  createStyles({
    strikeOut: {
      textDecoration: 'line-through red',
    },
  })

interface IResearchProps extends WithStyles<typeof styles> {
  openBillsDialogById?: (eocId: string, quoteId: string, altTitle: string) => void
  setParentRefreshWithBundle?: (setRefresh: boolean) => void
  buildResearchMenuItems?: (record: FinancialResearchSummaryResult) => any[]
  openAppealLogModal?: (claimId: string) => void
  closeAppealLogModal?: () => void
  openAppealNoteModal?: (claimId: string) => void
  openLastUpdateHistoryModal?: (claimId: string) => void
  closeAppealNoteModal?: () => void
  closeLastHistoryModal?: () => void
  closeMarkClaimDeleteModal?: () => void
  selectedClaimId?: string
  isAppealLogModalOpen?: boolean
  isAppealNoteModalOpen?: boolean
  isLastHistoryModalOpen?: boolean
  isMarkClaimDeletedModalOpen?: boolean
  selectedPatientHeader: ClaimHeaderDetailsSummaryResult
  setUpdateParentStateAfterModal: (parentfunction?: () => void) => void
}
class FinancialResearch extends React.Component<IResearchProps> {
  public async componentDidMount() {
    var result = await GetComponentOptions(true, true)
    this.props.setParentRefreshWithBundle!(false)
    this.props.setUpdateParentStateAfterModal(this.getFinancialResearchData)
    this.setState({
      searchStatus: this.state.searchComponents,
      componentNameDict: result,
    })
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private getFinancialResearchData = async () => {
    this.setState({ gridLoading: true })
    const {
      page,
      pageSize,
      searchClaimStatus,
      searchBillOutStatus,
      searchPatientFirstName,
      searchPatientLastName,
    } = this.state
    var received = undefined
    const selectComponents = Array.from(this.state.searchComponents.keys())

    if (
      this.state.receivedCheckNumber != '' &&
      this.state.receivedCheckNumber.trim().length > 0
    ) {
      received = true
    } else if (
      this.state.sentCheckNumber != '' &&
      this.state.sentCheckNumber.trim().length > 0
    ) {
      received = false
    }

    const data: FinancialResearchSearchRequest = {
      patientFirstName: searchPatientFirstName,
      patientLastName: searchPatientLastName,
      claimStatus: searchClaimStatus,
      billOutStatus: searchBillOutStatus,
      components: selectComponents,
      checkNumber:
        this.state.searchCheckNumber.trim().length == 0
          ? ''
          : this.state.searchCheckNumber,
      serviceDateStart: this.state.searchServiceDateStart,
      serviceDateEnd: this.state.searchServiceDateEnd,
      checkDateStart: this.state.searchCheckDateStart,
      checkDateEnd: this.state.searchCheckDateEnd,
      showAppeals: this.state.searchShowAppeals,
      receivedChecks: received,
      eocGeneratedLegacyNumber: this.state.eocGeneratedLegacyNumber,
      groupNumber: this.state.groupNumber,
      page: page,
      pageSize: pageSize,
    }
    await searchFinancial(data).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        gridLoading: false,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getFinancialResearchData())
  }

  private isSearchGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private isDialogGridDataEmpty = () => {
    return (
      this.state.modalData.lineItems == undefined ||
      this.state.modalData.lineItems.length == 0
    )
  }

  private close = () => {
    this.setState({ detailDialogOpen: false })
  }

  private showClaimPayments = async (claimId: string, appealType: number) => {
    this.setState({ detailDialogTitle: 'Pay Provider Details' })
    this.setState({ detailAmountTotalLabel: 'Total Contract:' })
    this.setState({ detailAmountColumnName: 'Contract Amount' })
    this.setState({ appealType: appealType })
    this.setState({ dialogIcon: <AttachMoney /> })
    await getClaimPaymentDetails(claimId).then((_response: any) => {
      this.setState({ modalData: _response })
      this.setState({ detailDialogOpen: true })
    })
  }

  private showbillOutPayments = async (billOutId: string) => {
    this.setState({ detailDialogTitle: 'Receive Payment Details' })
    this.setState({ detailAmountTotalLabel: 'Total Amt Billed:' })
    this.setState({ detailAmountColumnName: 'Amt Billed' })
    this.setState({ dialogIcon: <CreditCard /> })
    await getBillOutPaymentDetails(billOutId).then((_response: any) => {
      this.setState({ modalData: _response })
      this.setState({ detailDialogOpen: true })
    })
  }

  private renderContextMenu = (record: FinancialResearchSummaryResult, index: number) => {
    let options = this.props.buildResearchMenuItems!(record)

    return (
      <ESMenu
        data={record}
        menuItems={options}
        rowIndex={index}
        menuAriaLabel={`$quote-${index}-menu`}
      />
    )
  }

  public getLineItemStyle = (status: string) => {
    if (status !== undefined && status === 'Deleted') {
      return this.props.classes.strikeOut
    } else {
      return undefined
    }
  }

  private mapSearchGridData = () => {
    if (this.isSearchGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={14} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          <TableCell
            style={{
              whiteSpace: 'nowrap',
              paddingRight: 0,
              paddingLeft: '30px',
            }}
          >
            {record.hasProviderPayment ? (
              <>
                <Tooltip title="Show Pay Provider Details">
                  <AttachMoney
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.showClaimPayments(record.claimId!, record.appealType!)
                    }}
                  />
                </Tooltip>
                &nbsp;&nbsp;
              </>
            ) : (
              <>
                <AttachMoney style={{ visibility: 'hidden' }} />
                &nbsp;&nbsp;
              </>
            )}
            {record.billOutId && record.claimStatus !== 'Deleted' ? (
              <>
                <Tooltip title="Show Received Payment Details">
                  <CreditCard
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.showbillOutPayments(record.billOutId!)
                    }}
                  />
                </Tooltip>
                &nbsp;&nbsp;
              </>
            ) : (
              <>
                <CreditCard style={{ visibility: 'hidden' }} />
                &nbsp;&nbsp;
              </>
            )}
            {record.inGlobal && record.eocId && record.quoteId ? (
              <>
                <Tooltip title="Show Bundle Details">
                  <AddShoppingCart
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.props.openBillsDialogById!(
                        record.eocId!,
                        record.quoteId!,
                        record.eocGeneratedOrLegacyId
                      )
                    }}
                  />
                </Tooltip>
                &nbsp;&nbsp;
              </>
            ) : (
              <AddShoppingCart style={{ visibility: 'hidden' }} />
            )}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(record.claimStatus!)}
            style={{ whiteSpace: 'nowrap', paddingRight: 0 }}
          >
            {record.eocGeneratedOrLegacyId}&nbsp;&nbsp;
            {record.appealType != undefined ? (
              <Tooltip title={`Appeal Type: ${AppealNameConstants[record.appealType]}`}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
            {record.claimId &&
            record.lastAppealDate &&
            record.appealType === undefined ? (
              <Tooltip title={'View Appeal Log'}>
                <ReceiptOutlined
                  style={{ cursor: 'pointer' }}
                  fontSize="small"
                  onClick={() => {
                    this.props.openAppealLogModal!(record.claimId!)
                    this.setState({ groupInfo: record.eocGeneratedOrLegacyId })
                  }}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(record.claimStatus!)}
            style={{ paddingRight: 0 }}
          >
            {record.patientName}
          </TableCell>
          <TableCell className={this.getLineItemStyle(record.claimStatus!)} align="right">
            {formatDate(record.dateOfService)}
          </TableCell>
          <TableCell className={this.getLineItemStyle(record.claimStatus!)}>
            {record.type}
          </TableCell>
          <TableCell className={this.getLineItemStyle(record.claimStatus!)} align="right">
            {record.dateReceived != '' ? formatDate(record.dateReceived!) : ''}
          </TableCell>
          <TableCell className={this.getLineItemStyle(record.claimStatus!)}>
            {record.component}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(record.claimStatus!)}
            style={{ paddingRight: 0 }}
          >
            {record.claimStatus}&nbsp;&nbsp;
            {record.currentEOBDocumentId != undefined &&
            (record.claimStatus?.toLowerCase().includes('paid') ||
              record.claimStatus?.toLowerCase().includes('denied')) ? (
              <Tooltip title="Show Latest Explanation of Payment">
                <PictureAsPdfRounded
                  fontSize="small"
                  style={{ cursor: 'pointer' }}
                  onClick={async () =>
                    window.open(await getDocumentUrlById(record.currentEOBDocumentId!))
                  }
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell className={this.getLineItemStyle(record.claimStatus!)}>
            {record.billOutStatus}
          </TableCell>
          <Tooltip title={record.providerLocation || ''}>
            <TableCell
              className={this.getLineItemStyle(record.claimStatus!)}
              style={{
                paddingRight: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '150px',
              }}
            >
              {record.providerLocation}
            </TableCell>
          </Tooltip>
          <TableCell className={this.getLineItemStyle(record.claimStatus!)} align="right">
            {formatCurrency(record.totalCharges)}
          </TableCell>
          <TableCell
            className={this.getLineItemStyle(record.claimStatus!)}
            align="right"
            style={{
              color:
                record.appealType != undefined &&
                record.appealType === AppealType.OverpaidProvider
                  ? 'red'
                  : 'black',
            }}
          >
            {record.totalContractAmountDisplay != undefined
              ? record.totalContractAmountDisplay
              : ''}
          </TableCell>
          <TableCell className={this.getLineItemStyle(record.claimStatus!)} align="right">
            {record.totalBilledOutAmount != undefined
              ? formatCurrency(record.totalBilledOutAmount)
              : ''}
          </TableCell>
          <TableCell>{this.renderContextMenu(record, index)}</TableCell>
        </TableRow>
      ))
    }
  }

  private mapDialogGridData = () => {
    if (this.isDialogGridDataEmpty()) {
      return (
        <TableRow className="gridNoPadding" key={1}>
          <TableCell colSpan={13} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.modalData.lineItems.map((record, index) => (
        <TableRow className="gridPadding" key={index}>
          {this.state.detailDialogTitle == 'Pay Provider Details' ? (
            <TableCell>
              {record.pendingCheck ? (
                <Tooltip title="Holding">
                  <Warning style={{ color: '#ffc107' }} />
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>
          ) : (
            <></>
          )}
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
            style={{ paddingLeft: '30px' }}
            align="right"
          >
            {formatDate(record.checkDate!)}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
          >
            {record.checkNumber}
          </TableCell>
          {this.state.detailDialogTitle == 'Receive Payment Details' ? (
            <TableCell
              className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
            >
              {record.payer}
            </TableCell>
          ) : (
            <></>
          )}
          <TableCell>{record.refund ? <Check color="error" /> : <></>}</TableCell>
          <TableCell>{record.voidedCheck ? <Block color="error" /> : <></>}</TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
            align="right"
          >
            {formatDate(record.appliedDatePaid!)}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
          >
            {record.appliedBy!}
          </TableCell>
          {this.state.detailDialogTitle == 'Pay Provider Details' ? (
            <>
              <TableCell
                className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
              >
                {record.modifiedOn ? formatDate(record.modifiedOn.toString()) : 'N/A'}
              </TableCell>
              <TableCell
                className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
              >
                {record.modifiedBy ?? 'N/A'}
              </TableCell>
            </>
          ) : (
            <></>
          )}
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
            align="right"
          >
            {formatDate(record.dateOfService!)}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
            align="right"
          >
            {record.lineNumber}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
          >
            {record.cptCode}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
            align="right"
          >
            {record.amount != undefined ? formatCurrency(record.amount) : ''}
          </TableCell>
          <TableCell
            className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
            align="right"
            style={{
              color:
                record.amountPaid != undefined && Number(record.amountPaid) < 0
                  ? 'red'
                  : 'black',
            }}
          >
            {record.amountPaid != undefined ? record.amountPaidDisplay : ''}
          </TableCell>
          {this.state.detailDialogTitle == 'Receive Payment Details' ? (
            <></>
          ) : (
            <TableCell
              className={record.voidedCheck ? this.props.classes.strikeOut : 'none'}
            >
              {record.reasonCode}
            </TableCell>
          )}
        </TableRow>
      ))
    }
  }

  private paginationInfo = () => {
    if (!this.isSearchGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private search = (event: any) => {
    this.setState({ page: 1 }, () => {
      if (this.state.specialChars) {
        return
      } else if (
        (this.state.searchBillOutStatus != undefined &&
          this.state.searchBillOutStatus != '') ||
        (this.state.searchServiceDateStart != undefined &&
          this.state.searchServiceDateStart != '') ||
        (this.state.searchServiceDateEnd != undefined &&
          this.state.searchServiceDateEnd != '') ||
        (this.state.searchCheckDateEnd != undefined &&
          this.state.searchCheckDateEnd != '') ||
        this.state.eocGeneratedLegacyNumber != '' ||
        this.state.groupNumber != '' ||
        (this.state.searchCheckDateStart != undefined &&
          this.state.searchCheckDateStart != '') ||
        this.state.searchCheckNumber != '' ||
        (this.state.searchClaimStatus != undefined &&
          this.state.searchClaimStatus != '') ||
        this.state.searchComponents.size > 0 ||
        this.state.searchPatientFirstName != '' ||
        this.state.searchPatientLastName != '' ||
        this.state.searchShowAppeals == true
      ) {
        this.getFinancialResearchData()
      } else {
        this.setState({
          gridData: undefined,
          pageCount: 0,
          totalItems: 0,
          gridLoading: false,
          noFilters: true,
        })
      }
    })
    event.preventDefault()
  }

  private clearSearch = () => {
    var select_box = document.getElementById('claimStatus') as HTMLSelectElement
    if (select_box != undefined) {
      select_box.selectedIndex = 0
    }

    var select_box2 = document.getElementById('billoutStatus') as HTMLSelectElement
    if (select_box2 != undefined) {
      select_box2.selectedIndex = 0
    }

    var serviceStart = document.getElementById('serviceStartDate') as HTMLInputElement
    if (serviceStart != undefined) {
      serviceStart.value = ''
    }
    var serviceEnd = document.getElementById('serviceEndDate') as HTMLInputElement
    if (serviceEnd != undefined) {
      serviceEnd.value = ''
    }

    var start = document.getElementById('startDate') as HTMLInputElement
    if (start != undefined) {
      start.value = ''
    }
    var end = document.getElementById('endDate') as HTMLInputElement
    if (end != undefined) {
      end.value = ''
    }
    this.setState({
      searchClaimStatus: undefined,
      searchBillOutStatus: undefined,
      searchPatientFirstName: '',
      searchPatientLastName: '',
      searchCheckNumber: '',
      searchServiceDateStart: undefined,
      searchServiceDateEnd: undefined,
      searchCheckDateStart: undefined,
      searchCheckDateEnd: undefined,
      receivedCheckNumber: '',
      sentCheckNumber: '',
      eocGeneratedLegacyNumber: '',
      groupNumber: '',
      searchShowAppeals: false,
      searchComponents: new Map<string, string>(),
      gridData: undefined,
      pageCount: 0,
      totalItems: 0,
      gridLoading: false,
      noFilters: false,
    })
  }

  private handleClaimStatusChange = (event: any) => {
    this.setState({ searchClaimStatus: event.target.value, noFilters: false })
  }

  private handleBillOutStatusChange = (event: any) => {
    this.setState({
      searchBillOutStatus: event.target.value,
      noFilters: false,
    })
  }

  private handlePatientFirstNameChange = (event: any) => {
    this.setState({
      searchPatientFirstName: event.target.value,
      noFilters: false,
    })
  }

  private handlePatientLastNameChange = (event: any) => {
    this.setState({
      searchPatientLastName: event.target.value,
      noFilters: false,
    })
  }

  private handleReceivedCheckChange = (event: any) => {
    this.setState({
      receivedCheckNumber: event.target.value,
      searchCheckNumber: event.target.value,
      sentCheckNumber: '',
      noFilters: false,
    })
  }

  private handleSentCheckChange = (event: any) => {
    this.setState({
      sentCheckNumber: event.target.value,
      searchCheckNumber: event.target.value,
      receivedCheckNumber: '',
      noFilters: false,
    })
  }

  private handleServiceStartDateChange = (event: any) => {
    this.setState({
      searchServiceDateStart: event.target.value,
      noFilters: false,
    })
  }

  private handleServiceEndDateChange = (event: any) => {
    this.setState({
      searchServiceDateEnd: event.target.value,
      noFilters: false,
    })
  }

  private handleStartDateChange = (event: any) => {
    this.setState({
      searchCheckDateStart: event.target.value,
      noFilters: false,
    })
  }

  private handleEndDateChange = (event: any) => {
    this.setState({ searchCheckDateEnd: event.target.value, noFilters: false })
  }

  private handleEOCLegacyChange = (event: any) => {
    this.setState({
      eocGeneratedLegacyNumber: event.target.value,
      noFilters: false,
    })
  }

  private handleGroupNumberChange = (event: any) => {
    var val = String(event.target.value)
    var nan = val.includes('-') || val.includes('.')

    this.setState({ groupNumber: val, noFilters: false, specialChars: nan })
  }

  private handleShowAppealsChange = (_event: React.ChangeEvent, value: boolean) => {
    this.setState({ searchShowAppeals: value, noFilters: false })
  }

  private handleComponentChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (this.state.searchComponents.has(event.currentTarget.dataset.value!)) {
      this.state.searchComponents.delete(event.currentTarget.dataset.value!)
      this.setState({
        searchStatus: this.state.searchComponents,
        noFilters: false,
      })
    } else {
      this.state.searchComponents.set(
        event.currentTarget.dataset.value!,
        event.currentTarget.dataset.name!
      )
      this.setState({
        searchStatus: this.state.searchComponents,
        noFilters: false,
      })
    }
  }

  public state = {
    searchClaimStatus: undefined,
    searchBillOutStatus: undefined,
    searchPatientFirstName: '',
    searchPatientLastName: '',
    searchCheckNumber: '',
    searchServiceDateStart: undefined,
    searchServiceDateEnd: undefined,
    searchCheckDateStart: undefined,
    searchCheckDateEnd: undefined,
    searchShowAppeals: false,
    searchComponents: new Map<string, string>(),
    receivedCheckNumber: '',
    sentCheckNumber: '',
    eocGeneratedLegacyNumber: '',
    groupNumber: '',
    detailDialogOpen: false,
    detailDialogTitle: '',
    detailAmountTotalLabel: '',
    detailAmountColumnName: '',
    dialogIcon: <AttachMoney />,
    gridData: new pagedList().items as FinancialResearchSummaryResult[],
    modalData: new PaymentDetailSummaryResult(),
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 20,
    gridLoading: false,
    componentNameDict: new Array<{ id: string; description: string }>(),
    groupInfo: '',
    appealType: undefined,
    noFilters: false,
    specialChars: false,
  }

  public render() {
    const MenuProps = {
      getContentAnchorEl: null,
      PaperProps: {
        style: {
          maxHeight: 400,
        },
      },
    }
    return (
      <>
        <Helmet>
          <title>Financial Research</title>
        </Helmet>
        <Grid container style={{ width: '100%', paddingBottom: '20px' }}>
          <ResponsiveAppBar title="Financial Research" pageIcon={<Search />} />
          {this.props.selectedClaimId && this.props.isAppealLogModalOpen ? (
            <AppealLogModal
              claimId={this.props.selectedClaimId}
              isOpen={this.props.isAppealLogModalOpen}
              closeModal={this.props.closeAppealLogModal!}
              groupInfo={this.state.groupInfo}
            />
          ) : (
            <></>
          )}
          {this.props.selectedClaimId && this.props.isLastHistoryModalOpen ? (
            <ClaimLastUpdateHistoryModal
              claimId={this.props.selectedClaimId}
              isOpen={this.props.isLastHistoryModalOpen}
              closeModal={this.props.closeLastHistoryModal!}
            />
          ) : (
            <></>
          )}
          {this.props.selectedClaimId && this.props.isAppealNoteModalOpen ? (
            <AppealNoteModal
              claimId={this.props.selectedClaimId}
              isOpen={this.props.isAppealNoteModalOpen}
              closeModal={this.props.closeAppealNoteModal!}
            />
          ) : (
            <></>
          )}
          <BundleClaimsModal showButtons={false} />
          {this.state.gridLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '2',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          {this.props.selectedClaimId &&
          this.props.isMarkClaimDeletedModalOpen &&
          this.props.selectedPatientHeader ? (
            <MarkClaimDeletedModal
              CMS1500Id={this.props.selectedClaimId!}
              cancel={this.props.closeMarkClaimDeleteModal!}
              open={this.props.isMarkClaimDeletedModalOpen ?? false}
            />
          ) : (
            <></>
          )}
          <form onSubmit={this.search}>
            <Grid
              container
              style={{ margin: '10px 0px', paddingLeft: '30px', width: '100%' }}
              xs={12}
            >
              <Typography
                color="primary"
                component="h6"
                variant="h6"
                gutterBottom
                style={{ paddingBottom: '10px' }}
              >
                SEARCH CRITERIA
              </Typography>
              <Grid container style={{ width: '100%' }} direction="row" spacing={2}>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchPatientFirstName}
                      onChange={this.handlePatientFirstNameChange}
                      variant="outlined"
                      label="Patient First Name"
                      name="searchPatientFirstName"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.searchPatientLastName}
                      onChange={this.handlePatientLastNameChange}
                      label="Patient Last Name"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ fullWidth: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      shrink={true}
                      htmlFor="claim-status-select"
                      variant="outlined"
                    >
                      Claim Status
                    </InputLabel>
                    <Select
                      value={this.state.searchClaimStatus}
                      id="claimStatus"
                      onChange={this.handleClaimStatusChange}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          id="claim-status-select"
                          name="claimStatus"
                          notched
                          labelWidth={90}
                        />
                      }
                      inputProps={{ label: true, notched: true }}
                      native={true}
                      fullWidth
                    >
                      <option value=""></option>
                      <option value="Contract TBD">Contract TBD</option>
                      <option value="Deleted">Deleted</option>
                      <option value="Denied">Denied</option>
                      <option value="Denied-Holding">Denied-Holding</option>
                      <option value="Draft">Draft</option>
                      <option value="Imported">Imported</option>
                      <option value="Imported Denied">Imported Denied</option>
                      <option value="Imported Paid">Imported Paid</option>
                      <option value="Imported Received">Imported Received</option>
                      <option value="Needs Denied">Needs Denied</option>
                      <option value="Paid">Paid</option>
                      <option value="Paid-Holding">Paid-Holding</option>
                      <option value="Received">Received</option>
                      <option value="Refunded">Refunded</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      htmlFor="billout-status-select"
                      shrink={true}
                      variant="outlined"
                    >
                      Bill-Out Status
                    </InputLabel>
                    <Select
                      value={this.state.searchBillOutStatus}
                      onChange={this.handleBillOutStatusChange}
                      variant="outlined"
                      input={
                        <OutlinedInput
                          name="billOutStatus"
                          id="billout-status-select"
                          notched
                          labelWidth={100}
                        />
                      }
                      inputProps={{ label: true, notched: true }}
                      native={true}
                      id="billoutStatus"
                      fullWidth
                    >
                      <option value=""></option>
                      <option value="Billed Out">Billed Out</option>
                      <option value="Dismissed">Dismissed</option>
                      <option value="Dismissed w/ Balance">Dismissed w/ Balance</option>
                      <option value="Imported">Imported</option>
                      <option value="Imported Billed Out">Imported Billed Out</option>
                      <option value="Imported Paid">Imported Paid</option>
                      <option value="Paid">Paid</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      value={this.state.eocGeneratedLegacyNumber}
                      onChange={this.handleEOCLegacyChange}
                      label="EOC/Legacy"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ fullWidth: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <FormControl fullWidth>
                    <TextField
                      error={this.state.specialChars}
                      value={this.state.groupNumber}
                      onChange={this.handleGroupNumberChange}
                      label="Group #"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ fullWidth: true }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} style={{ paddingRight: '170px' }}>
                  <FormControl>
                    <FormControlLabel
                      label="Only Show Appeals"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checked={this.state.searchShowAppeals}
                          onChange={this.handleShowAppealsChange}
                          inputProps={{
                            id: 'show-appeals-filter',
                          }}
                          value="searchShowAppeals"
                          name="searchShowAppeals"
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  {this.state.componentNameDict && (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel shrink={true} variant="outlined">
                        Component
                      </InputLabel>
                      <Select
                        variant="outlined"
                        input={<OutlinedInput notched labelWidth={90} />}
                        inputProps={{ label: true, notched: true }}
                        fullWidth
                        id="componentNameDict"
                        multiple
                        value={Array.from(this.state.searchComponents.keys())}
                        renderValue={() =>
                          Array.from(this.state.searchComponents.values()).join(', ')
                        }
                        onChange={this.handleComponentChange}
                        MenuProps={MenuProps}
                      >
                        {this.state.componentNameDict.map((item) => (
                          <MenuItem value={item.id} data-name={item.description}>
                            {item.id && this.state.searchComponents.has(item.id) ? (
                              <CheckBox
                                color="secondary"
                                style={{ paddingRight: '10px' }}
                              />
                            ) : (
                              <CheckBoxOutlineBlank
                                color="action"
                                style={{ paddingRight: '10px' }}
                              />
                            )}{' '}
                            {item.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item container style={{ width: '356px' }} direction="row">
                  <Grid item>
                    <TextField
                      inputProps={{
                        id: 'serviceStartDate',
                        style: { padding: '8px' },
                      }}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleServiceStartDateChange}
                      type="date"
                      name="serviceStartDate"
                      variant="outlined"
                      defaultValue={this.state.searchServiceDateStart}
                      label="Date of Service Start"
                      fullWidth
                    />
                  </Grid>
                  <Grid item style={{ margin: '0px 10px' }}>
                    <strong> - </strong>
                  </Grid>
                  <Grid item>
                    <TextField
                      inputProps={{
                        id: 'serviceEndDate',
                        style: { padding: '8px' },
                      }}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleServiceEndDateChange}
                      type="date"
                      name="serviceEndDate"
                      variant="outlined"
                      defaultValue={this.state.searchServiceDateEnd}
                      label="Date of Service End"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <TextField
                        value={this.state.receivedCheckNumber}
                        onChange={this.handleReceivedCheckChange}
                        disabled={this.state.sentCheckNumber != ''}
                        label="Received Check #"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ fullWidth: true }}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <TextField
                        value={this.state.sentCheckNumber}
                        disabled={this.state.receivedCheckNumber != ''}
                        onChange={this.handleSentCheckChange}
                        label="Sent Check #"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ fullWidth: true }}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item container style={{ width: '356px' }} direction="row">
                    <Grid item>
                      <TextField
                        inputProps={{
                          id: 'startDate',
                          style: { padding: '8px' },
                        }}
                        InputLabelProps={{ shrink: true }}
                        onChange={this.handleStartDateChange}
                        type="date"
                        name="startDate"
                        variant="outlined"
                        defaultValue={this.state.searchCheckDateStart}
                        label="Check Start Date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item style={{ margin: '0px 10px' }}>
                      <strong> - </strong>
                    </Grid>
                    <Grid item>
                      <TextField
                        inputProps={{
                          id: 'endDate',
                          style: { padding: '8px' },
                        }}
                        InputLabelProps={{ shrink: true }}
                        onChange={this.handleEndDateChange}
                        type="date"
                        name="endDate"
                        variant="outlined"
                        defaultValue={this.state.searchCheckDateEnd}
                        label="Check End Date"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{
                    width: '50%',
                    marginLeft: '8px',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                  }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="contained"
                    style={{ marginRight: '10px' }}
                  >
                    Search
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.clearSearch}
                    size="medium"
                    variant="contained"
                  >
                    Clear Search
                  </Button>
                  {this.state.noFilters && (
                    <div
                      style={{ textAlign: 'right', margin: '10px 20px 12px' }}
                      className="error"
                    >
                      At least one filter is required to search.
                    </div>
                  )}
                  {this.state.specialChars && (
                    <div
                      style={{ textAlign: 'right', margin: '10px 20px 12px' }}
                      className="error"
                    >
                      Group # must be a positive whole number.
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </form>
          <ESDialog
            close={this.close}
            open={this.state.detailDialogOpen}
            title={
              `${this.state.detailDialogTitle} -
                        ${this.state.modalData.patientName} (${this.state.modalData.patientDob})` +
              (this.state.modalData.groupNumber
                ? ` ${this.state.modalData.groupNumber}`
                : '')
            }
            titleIcon={this.state.dialogIcon}
            showSaveButton={false}
            maxWidth="xl"
          >
            {({}) => (
              <Grid container direction="row" style={{ marginTop: '5px' }} spacing={2}>
                <Grid item>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <strong>Billing Address:</strong>
                    </Grid>
                    <Grid item>
                      {this.state.modalData.billingAddress
                        ? this.state.modalData.billingAddress
                        : '{UNKNOWN}'}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <strong>{this.state.detailAmountTotalLabel}</strong>
                    </Grid>
                    <Grid item>
                      {this.state.modalData.totalAmount != undefined
                        ? formatCurrency(this.state.modalData.totalAmount)
                        : ''}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <strong>Total Paid:</strong>
                    </Grid>
                    <Grid
                      item
                      style={{
                        paddingRight: '0px',
                        color:
                          this.state.appealType === AppealType.OverpaidProvider
                            ? 'red'
                            : 'black',
                      }}
                    >
                      {this.state.modalData.totalPaidDisplay}
                    </Grid>
                  </Grid>
                </Grid>
                {this.state.appealType != undefined ? (
                  <Grid item>
                    <Tooltip
                      title={`Appeal Type: ${AppealNameConstants[this.state.appealType]}`}
                    >
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignContent="center"
                  alignItems="flex-start"
                  wrap="wrap"
                >
                  <Grid item xs={12}>
                    <Table aria-label="simple table" style={{ padding: '20' }}>
                      <TableHead>
                        <TableRow className="gridPadding">
                          {this.state.detailDialogTitle == 'Pay Provider Details' ? (
                            <TableCell></TableCell>
                          ) : (
                            <></>
                          )}
                          <TableCell align="right">Check Date</TableCell>
                          <TableCell>Check Number</TableCell>
                          {this.state.detailDialogTitle == 'Receive Payment Details' ? (
                            <TableCell>Payer</TableCell>
                          ) : (
                            <></>
                          )}
                          <TableCell>Refund</TableCell>
                          <TableCell>Voided</TableCell>
                          {this.state.detailDialogTitle == 'Receive Payment Details' ? (
                            <TableCell align="right">Applied Date</TableCell>
                          ) : (
                            <TableCell align="right">Created</TableCell>
                          )}
                          {this.state.detailDialogTitle == 'Receive Payment Details' ? (
                            <TableCell>Applied By</TableCell>
                          ) : (
                            <TableCell>Created By</TableCell>
                          )}
                          {this.state.detailDialogTitle == 'Pay Provider Details' ? (
                            <>
                              <TableCell>Modified</TableCell>
                              <TableCell>Modified By</TableCell>
                            </>
                          ) : (
                            <></>
                          )}
                          <TableCell align="right">Date of Service</TableCell>
                          <TableCell align="right">Line#</TableCell>
                          <TableCell>CPT Code</TableCell>
                          <TableCell align="right">
                            {this.state.detailAmountColumnName}
                          </TableCell>
                          <TableCell align="right">Amount Paid</TableCell>

                          {this.state.detailDialogTitle == 'Receive Payment Details' ? (
                            <></>
                          ) : (
                            <TableCell>Reason Code</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ width: '100%' }}>
                        {this.mapDialogGridData()}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </ESDialog>
          <Grid item xs={12} style={{ minWidth: '750px', width: '100%' }}>
            <Table aria-label="simple table" style={{ padding: '20' }}>
              <TableHead>
                <TableRow className="gridPadding">
                  <TableCell style={{ paddingLeft: '30px' }}></TableCell>
                  <TableCell>EOC/Legacy</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell align="right">Date of Service</TableCell>
                  <TableCell>EOC Status</TableCell>
                  <TableCell align="right">Date Rcv</TableCell>
                  <TableCell>Component</TableCell>
                  <TableCell>Claim Status</TableCell>
                  <TableCell>Bill Out Status</TableCell>
                  <TableCell>Claim Provider Location</TableCell>
                  <TableCell align="right">Total Charges</TableCell>
                  <TableCell align="right">Total Contract Amt</TableCell>
                  <TableCell align="right">Total Billed Out</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '10%' }}>{this.mapSearchGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                forcePage={this.state.page - 1}
                pageCount={this.state.pageCount}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}

const InjectedResearch = inject<IStores, IResearchProps, Partial<IResearchProps>, any>(
  (stores: IStores) => ({
    openBillsDialogById: stores.quotes.openBillsDialogById,
    setParentRefreshWithBundle: stores.quotes.setParentRefreshWithBundle,
    buildResearchMenuItems: stores.cms1500.buildResearchMenuItems,
    openAppealLogModal: stores.cms1500.OpenAppealLogModal,
    closeAppealLogModal: stores.cms1500.closeAppealLogModal,
    isAppealLogModalOpen: stores.cms1500.isAppealLogModalOpen,
    openAppealNoteModal: stores.cms1500.openAppealNoteModal,
    closeAppealNoteModal: stores.cms1500.closeAppealNoteModal,
    isAppealNoteModalOpen: stores.cms1500.isAppealNoteModalOpen,
    isLastHistoryModalOpen: stores.cms1500.isLastUpdateHistoryModalOpen,
    closeLastHistoryModal: stores.cms1500.closeLastUpdateHistoryModal,
    selectedClaimId: stores.cms1500.selectedClaimId,
    openLastUpdateHistoryModal: stores.cms1500.openLastUpdateHistoryModal,
    isMarkClaimDeletedModalOpen: stores.cms1500.isMarkClaimDeletedModalOpen,
    closeMarkClaimDeleteModal: stores.cms1500.closeMarkClaimDeleteModal,
    selectedPatientHeader: stores.cms1500.headerDetails,
    setUpdateParentStateAfterModal: stores.cms1500.setParentUpdateFunction,
  })
)(FinancialResearch)

export default withStyles(styles)(InjectedResearch)
