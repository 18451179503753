import { Grid, Tooltip } from '@material-ui/core'
import {
  AddCircle,
  LaunchRounded,
  LocalAtmRounded,
  RemoveCircle,
} from '@material-ui/icons'
import { connect, FormikContext, getIn } from 'formik'
import React from 'react'
import DatePickerField from '../common/DatePickerField/DatePickerField'
import FullWidthField from '../common/FullWidthField'
import Box24Dialog from './Box24Dialog'
import { IClaimFormValues } from './ClaimFormValues'
import {
  createLineItemFormValues,
  ILineItemFormValues,
  toCMS1500LineItemDto,
} from './LineItemFormValues'

export interface IHaveLineItemFormValues {
  lineItems: ILineItemFormValues
}

interface IBox24Props {
  index: number
  openContractModal: () => void
  disabled?: boolean
  providerTypeId?: string
  providerId?: string
  showContractAmount: boolean
}

interface IFormikProps {
  formik: FormikContext<IClaimFormValues>
}

const Box24: React.SFC<IBox24Props & IFormikProps> = (props) => {
  const { index, formik, openContractModal, disabled = false } = props
  const createName = (field: string) => `q24.${index}.${field}`
  const hasValue = (field: string) => {
    const name = createName(field)
    const value = getIn(formik.values, name)
    return !!value
  }
  const showLabels = index === 0

  function renderIcons(totalLines: number, lineNumber: number) {
    const launchRoundedStyle = {
      color: '#878787',
      cursor: 'pointer',
      fontSize: '30px',
    } as React.CSSProperties

    let removeCircleStyle = {
      color: '#930A0A',
      cursor: 'pointer',
      fontSize: '30px',
    } as React.CSSProperties

    let addCircleStyle = {
      color: 'white',
      fontSize: '30px',
    } as React.CSSProperties

    if (totalLines > 1) {
      if (lineNumber === totalLines - 1) {
        addCircleStyle = {
          color: '#29348F',
          cursor: 'pointer',
          fontSize: '30px',
        }
      }
    } else {
      removeCircleStyle = { color: 'white', fontSize: '30px' }
      addCircleStyle = {
        color: '#29348F',
        cursor: 'pointer',
        fontSize: '30px',
      }
    }

    return (
      <Grid xs={1}>
        <LaunchRounded style={launchRoundedStyle} onClick={openModalWithIndex} />
        <RemoveCircle
          style={removeCircleStyle}
          onClick={totalLines > 1 ? removeLineItem(lineNumber) : undefined}
        />
        <AddCircle
          style={addCircleStyle}
          onClick={lineNumber === totalLines - 1 ? renderFieldClick : undefined}
          tabIndex={lineNumber === totalLines - 1 ? 0 : -1}
          onKeyDown={lineNumber === totalLines - 1 ? renderFieldKeyDown : undefined}
        />
      </Grid>
    )
  }

  const openModalWithIndex = () => {
    formik.setFieldValue(createName('isModalOpen'), true)
  }

  const removeLineItem = (lineIndex: number) => () => {
    const codeList = formik.values.q24
    codeList.splice(lineIndex, 1)
    formik.setFieldValue('q24', codeList)
  }

  const onFocus = () => {
    if (formik.values.dateOfService) {
      if (!hasValue('aDateOfServiceFrom')) {
        formik.setFieldValue(
          createName('aDateOfServiceFrom'),
          formik.values.dateOfService
        )
      }
      setDatesEqual()
    }
  }

  const renderFieldClick = () => {
    const newLineItem = createLineItemFormValues()
    newLineItem.aDateOfServiceFrom = formik.values.dateOfService
    const codeList = formik.values.q24

    if (codeList.length > 0) {
      newLineItem.iNpi = codeList[codeList.length - 1].iNpi
      newLineItem.bPlaceOfServiceTo = codeList[codeList.length - 1].bPlaceOfServiceTo
    }

    formik.setFieldValue('q24', codeList.concat([newLineItem]))
    onFocus()
  }

  const renderFieldKeyDown = (event: any) => {
    const code = event.keyCode || event.which
    if (code === 13 || code === 32) {
      renderFieldClick()
    }
  }

  const setDatesEqual = () => {
    const q24 = formik.values.q24
    q24[index].aDateOfServiceTo = q24[index].aDateOfServiceFrom
  }

  function renderField() {
    var disable = !(
      props.providerTypeId &&
      props.providerId &&
      hasValue('dProcedureCptHcpcs')
    )
    var showContractModal = props.showContractAmount
    return (
      <Grid container direction="row" justifyContent="space-evenly">
        <Grid item container spacing={1} xs={3}>
          <Grid item xs={7} onFocus={onFocus}>
            <DatePickerField
              name={createName('aDateOfServiceFrom')}
              inputId="aDateOfServiceFrom"
              label="Date of Service"
              fullWidth
              clearable={true}
              shrink={true}
              disabled={disabled}
              required
              onChange={setDatesEqual}
            />
          </Grid>
          <Grid item xs={5}>
            <FullWidthField
              name={createName('bPlaceOfServiceTo')}
              label={showLabels ? 'Place of Service' : ' '}
              InputLabelProps={{ shrink: true }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={3}>
          <Grid item container spacing={1} xs={9}>
            <Grid item xs={4}>
              <FullWidthField
                name={createName('dProcedureCptHcpcs')}
                label="CPT/HCPCS"
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={2}>
              <FullWidthField
                name={createName('dModifier1')}
                label={showLabels ? 'MODIFIER' : ' '}
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={2}>
              <FullWidthField
                name={createName('dModifier2')}
                label=" "
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={2}>
              <FullWidthField
                name={createName('dModifier3')}
                label=" "
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={2}>
              <FullWidthField
                name={createName('dModifier4')}
                label=" "
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <FullWidthField
              name={createName('eDiagnosisPointer')}
              label={showLabels ? 'Diagnosis Pointer' : ' '}
              InputLabelProps={{ shrink: true }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={5}>
          <Grid item xs={2}>
            <FullWidthField
              name={createName('fCharges')}
              label="Charges"
              InputLabelProps={{ shrink: true }}
              disabled={disabled}
              required
            />
          </Grid>
          <Grid item xs={2} style={{ paddingRight: '0px' }}>
            <FullWidthField
              name={createName('contractAmount')}
              label={showLabels ? 'Contract Amount' : ' '}
              InputLabelProps={{ shrink: true }}
              disabled={disabled}
            />
          </Grid>
          {showContractModal && (
            <Grid
              item
              style={{
                paddingRight: '10px',
                paddingTop: '20px',
                alignItems: 'flex-start',
                display: 'flex',
              }}
            >
              <Tooltip title="Choose Historical Contract Amount">
                <LocalAtmRounded
                  fontSize="large"
                  color={disable ? 'disabled' : 'primary'}
                  style={{ cursor: disable ? 'auto' : 'pointer' }}
                  onClick={
                    disable
                      ? () => {}
                      : () => {
                          openContractModal()
                        }
                  }
                />
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={2}>
            <FullWidthField
              name={createName('gDaysOrUnits')}
              label="Quantity"
              InputLabelProps={{ shrink: true }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={2}>
            <FullWidthField
              name={createName('iNpi')}
              label={showLabels ? 'NPI' : ' '}
              InputLabelProps={{ shrink: true }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        {renderIcons(formik.values.q24.length, index)}
      </Grid>
    )
  }

  return (
    <Grid item>
      {renderField()}
      {
        <Box24Dialog
          index={index}
          lineItem={toCMS1500LineItemDto(formik.values.q24[index], index)}
        />
      }
    </Grid>
  )
}

export default connect<IBox24Props, IClaimFormValues>(Box24)
