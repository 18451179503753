export interface IAppealLogDTO {
  appealType: number
  cms1500CopyId?: string | undefined
  originalCMS1500Id?: string | undefined
  notes?: string | undefined
  createdDate?: Date | undefined
  createdByName: string
}

export class AppealLogDTO {
  public appealType: number
  public cms1500CopyId?: string | undefined
  public originalCMS1500Id?: string | undefined
  public notes?: string | undefined
  public createdDate?: Date | undefined
  public createdByName: string
}
