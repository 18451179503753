import { IApplyInvoicePaymentRequest } from 'src/viewModels/ApplyInvoicePaymentRequest'

export interface IGlobalInvoiceCheckFormValues {
  invoiceId: string
  checkAmount?: number
  applyAmount?: number
  checkNumber: string
  datePaid?: Date
  payer?: string
}

export function toIApplyPayment(
  formValues: IGlobalInvoiceCheckFormValues,
  quoteId: string
): IApplyInvoicePaymentRequest {
  return {
    quoteId: quoteId,
    checkAmount: formValues.checkAmount,
    checkNumber: formValues.checkNumber || '',
    datePaid: formValues.datePaid || new Date(),
    amount: formValues.applyAmount,
    payer: formValues.payer || '',
  }
}

export function createICheckFormValues(payerName: string): IGlobalInvoiceCheckFormValues {
  return {
    invoiceId: '',
    checkAmount: undefined,
    checkNumber: '',
    datePaid: undefined,
    payer: payerName,
  }
}
