import { FolderShared } from '@material-ui/icons'
import { inject } from 'mobx-react'
import * as React from 'react'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import { DefaultPhysician, IPhysician } from '../Definitions'
import { IStores } from '../Stores'
import PhysicianDialog from './PhysicianDialog'

interface IPhysiciansProps {
  dataTableManager?: IDataTableManager<IPhysician>
  disablePhysician?: (physician: IPhysician) => void
  getColumnSettingsAndPhysicians?: () => void
  isModalOpen?: boolean
  openDialogWithPhysician?: (physician: IPhysician) => void
  recoverPhysician?: (physician: IPhysician) => void
}

class Physicians extends React.Component<IPhysiciansProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndPhysicians!()
  }

  public render() {
    const physicianDialog = this.props.isModalOpen && <PhysicianDialog />
    return (
      <>
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={true}
          enableToggleColumns={true}
          addButtonOnClick={this.openDialog(DefaultPhysician(true))}
          title="Physicians"
          icon={FolderShared}
          elevation={0}
          padding={0}
          rowsPerPage={5}
        />
        {physicianDialog}
      </>
    )
  }

  public openDialog = (physician: IPhysician) => () => {
    this.props.openDialogWithPhysician!(physician)
  }
}

const InjectedPhysicians = inject<
  IStores,
  IPhysiciansProps,
  Partial<IPhysiciansProps>,
  any
>((stores: IStores) => ({
  dataTableManager: stores.physicians.dataTableStore,
  disablePhysician: stores.physicians.disablePhysician,
  getColumnSettingsAndPhysicians: stores.physicians.getColumnSettingsAndPhysicians,
  isModalOpen: stores.physicians.isModalOpen,
  openDialogWithPhysician: stores.physicians.openDialogWithPhysician,
  recoverPhysician: stores.physicians.recoverPhysician,
}))(Physicians)

export default InjectedPhysicians
