import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  withStyles,
  WithStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  Select,
  CircularProgress,
} from '@material-ui/core'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import ReactPaginate from 'react-paginate'
import { LocalHospital } from '@material-ui/icons'
import { Form, Formik, FormikActions } from 'formik'
import { inject } from 'mobx-react'
import React from 'react'
import FullWidthField from '../common/FullWidthField'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import {
  DefaultProvider,
  IEpisodeOfCare,
  ILocation,
  IOrganization,
  IProvider,
  ISchedule,
} from '../Definitions'
import { IProviderTypeDto, ProviderTypeDto, TestDto } from '../generated_client'
import { IStores } from '../Stores'
import {
  GetLocationById,
  searchProviderBillingLocations,
} from 'src/services/LookupService'
import { BillingLocationSearchRequest } from '../viewModels/BillingLocationSearchRequest'
import { pagedList } from 'src/viewModels/pagedList'
import { ProviderBillingLocationSummaryResult } from 'src/viewModels/ProviderBillingLocationSummaryResult'

const styles = ({ palette }: Theme) =>
  createStyles({
    procedureFilter: {
      backgroundColor: palette.primary.main,
    },
    title: {
      backgroundColor: palette.primary.main,
      color: '#ffffff',
    },
  })

interface IProviderLookupDialogProps extends DialogProps {
  addProviderLocation?: (scheduleId: string, locationId: string) => Promise<void>
  clearData?: () => Promise<void>
  clearLocations?: () => void
  close?: () => void
  currentAppOrganization?: IOrganization
  getAllProviderTypes?: () => Promise<void>
  getScheduleById?: (id: string) => Promise<void>
  isOpen?: boolean
  lookupTitle?: string
  providerTypes?: ProviderTypeDto[]
  selectedEpisodeOfCare?: IEpisodeOfCare
  selectedProvider?: IProvider
  selectedLocation?: ILocation
  selectedProviderTypeId?: string
  selectedSchedule?: ISchedule
  setSelectedLocation?: (location?: ILocation) => void
  setSelectedProcedureFilters?: (procedureFilters?: IProcedureFilter[]) => void
  setSelectedProvider?: (provider?: IProvider) => void
  setSelectedProviderGroup?: (providerGroup?: string) => void
  setSelectedProviderLocation?: (locationName?: string) => void
  setSelectedProviderTypeId?: (selectedProviderTypeId: string) => void
  updateParentStateAfterModalEvent?: () => void
  updateParentStateAfterModal?: (locationId?: string) => void
}

export interface IProcedureFilter extends TestDto {
  selected: boolean
}

interface IBillingProviderLookupFormValues {
  providerTypeId?: string
  providerLocation?: string
  providerGroup?: string
}

class ReceiveClaimProviderLookupDialog extends React.Component<
  IProviderLookupDialogProps & WithStyles<typeof styles>
> {
  public componentDidMount() {
    this.props.clearData!().then(() => {
      this.props.setSelectedProvider!(undefined)
      this.props.getAllProviderTypes!().then(() => this.setState({ gridLoading: false }))
    })
  }

  private search = async (
    values: IBillingProviderLookupFormValues,
    formikActions: FormikActions<IBillingProviderLookupFormValues>
  ) => {
    this.props.setSelectedLocation!(undefined)
    this.props.setSelectedProvider!(undefined)
    this.props.setSelectedProviderGroup!(values.providerGroup)
    this.props.setSelectedProviderLocation!(values.providerLocation)
    this.setState({
      providerGroup: values.providerGroup,
      providerLocation: values.providerLocation,
      providerTypeId: this.props.selectedProviderTypeId,
      page: 1,
    })
    this.getProviderData()
    formikActions.setSubmitting(false)
  }

  private getProviderTypes = (providerTypes?: ProviderTypeDto[]) => {
    return providerTypes ? ([undefined] as any[]).concat(providerTypes) || [] : []
  }

  private getProviderTypeName = (type: IProviderTypeDto) => {
    return type ? type.name || '' : ''
  }

  private getProviderTypeValue = (type: IProviderTypeDto) => {
    return type ? type.id || '' : ''
  }

  private setSelectedProviderTypeId = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setSelectedProviderTypeId!(event.target.value)
  }

  private close = () => {
    this.props.setSelectedProvider!(undefined)
    this.props.setSelectedLocation!(undefined)
    this.props.clearLocations!()
    this.props.close!()
  }

  private getProviderData = async () => {
    this.setState({ gridLoading: true })
    const { page, pageSize, providerGroup, providerLocation, providerTypeId } = this.state
    const requestData = new BillingLocationSearchRequest()
    requestData.providerGroup = providerGroup
    requestData.providerLocation = providerLocation
    if (providerTypeId !== '') {
      requestData.providerTypeId = providerTypeId
    }
    requestData.page = page
    requestData.pageSize = pageSize
    await searchProviderBillingLocations(requestData).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        gridLoading: false,
      })
      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getProviderData())
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private async selectProvider(
    locationId: string,
    providerId: string,
    providerName: string,
    locationName: string
  ) {
    let provider = DefaultProvider(false)
    provider.id = providerId
    provider.name = providerName
    let location = await GetLocationById(locationId)
    location.name = locationName
    this.props.setSelectedProvider!({ ...DefaultProvider(), ...provider })
    this.props.setSelectedLocation!(location as unknown as ILocation)

    if (this.props.selectedSchedule) {
      this.props.addProviderLocation!(
        this.props.selectedSchedule!.id,
        this.props.selectedLocation!.id
      ).finally(() => {
        this.props.getScheduleById!(this.props.selectedSchedule!.id).finally(() => {
          const newItem = this.props.selectedSchedule!.scheduleProviderLocations!.filter(
            (x) => x.locationId === this.props.selectedLocation!.id
          )
          if (newItem.length > 0 && newItem[0]) {
            this.props.setSelectedLocation!(newItem[0]!.location as unknown as ILocation)
          }
        })
      })
    }
    this.props.close!()

    if (this.props.updateParentStateAfterModalEvent) {
      this.props.updateParentStateAfterModalEvent()
    }
    if (this.props.updateParentStateAfterModal) {
      this.props.updateParentStateAfterModal(locationId)
    }
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((provider, index) => (
        <TableRow key={index} className="gridPadding">
          <TableCell>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              className="gridButton"
              disabled={!provider.isEnabled}
              onClick={() =>
                this.selectProvider(
                  provider.locationId!,
                  provider.providerId!,
                  provider.providerGroupName!,
                  provider.locationName!
                )
              }
            >
              Select
            </Button>
          </TableCell>
          <TableCell>
            {provider.providerGroupName} ({provider.locationName})
          </TableCell>
          <TableCell>{provider.npi ?? ''}</TableCell>
          <TableCell>{provider.billingName}</TableCell>
          <TableCell>{provider.fullBillingAddress}</TableCell>
          <TableCell>{provider.fullPhysicalAddress}</TableCell>
        </TableRow>
      ))
    }
  }

  public state = {
    gridData: new pagedList().items as ProviderBillingLocationSummaryResult[],
    providerGroup: '',
    providerLocation: '',
    providerTypeId: '',
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 25,
    gridLoading: true,
  }

  public render() {
    const { providerTypes } = this.props
    const initialValues = {
      providerLocation: '',
      providerTypeId: '',
      providerGroup: '',
      page: 1,
    }

    return (
      <Dialog open={this.props.isOpen!} maxWidth="lg" fullWidth={true}>
        <DialogTitle style={{ padding: 0, marginBottom: '15px' }}>
          <ResponsiveAppBar
            title="Provider Lookup"
            pageIcon={<LocalHospital />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent>
          {this.state.gridLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1200',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={this.search}
            enableReinitialize={true}
          >
            {({ submitForm, resetForm }) => (
              <Form>
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  style={{ paddingTop: '10px' }}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <Grid item xs={6}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <FullWidthField
                            label="Provider Location / Billing Name"
                            name="providerLocation"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item>
                          <FullWidthField
                            label="Provider Group"
                            name="providerGroup"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item>
                          <FormControl style={{ width: '100%' }} variant="outlined">
                            <InputLabel shrink={true} variant="outlined">
                              Provider Type
                            </InputLabel>
                            <Select
                              variant="outlined"
                              input={<OutlinedInput notched labelWidth={100} />}
                              inputProps={{ label: true, notched: true }}
                              native={true}
                              fullWidth
                              onChange={this.setSelectedProviderTypeId}
                              id="providerTypeId"
                              value={this.props.selectedProviderTypeId}
                            >
                              {this.getProviderTypes(providerTypes)?.map((provider) => (
                                <option value={this.getProviderTypeValue(provider)}>
                                  {this.getProviderTypeName(provider)}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item container direction="row">
                          <div style={{ marginTop: '16px' }}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={submitForm}
                              type="submit"
                            >
                              Search
                            </Button>
                          </div>
                          <div style={{ marginTop: '16px', marginLeft: '10px' }}>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => {
                                resetForm(initialValues)
                                this.props.setSelectedProviderTypeId!('')
                                this.state.totalItems = 0
                              }}
                            >
                              Clear Search
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="flex-start"
                    wrap="wrap"
                  >
                    <Grid item xs={12} style={{ minWidth: '750px' }}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow className="gridPadding">
                            <TableCell></TableCell>
                            <TableCell>Provider Group (Location)</TableCell>
                            <TableCell>NPI</TableCell>
                            <TableCell>Billing Name</TableCell>
                            <TableCell>Billing Address</TableCell>
                            <TableCell>Physical Address</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ width: '100%' }}>
                          {this.mapGridData()}
                        </TableBody>
                      </Table>
                      <div className="pagination-row">
                        {this.paginationInfo()}
                        <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          onPageChange={this.handlePageChange}
                          forcePage={this.state.page - 1}
                          pageCount={this.state.pageCount}
                          containerClassName={'pagination'}
                          activeClassName={'active'}
                          //@ts-ignore
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const InjectedProviderLookupDialog = inject<
  IStores,
  IProviderLookupDialogProps,
  Partial<IProviderLookupDialogProps>,
  any
>((stores: IStores) => ({
  addProviderLocation: stores.schedules.addProviderLocation,
  clearData: stores.locations.clearData,
  clearLocations: stores.locations.clearLocations,
  close: stores.schedules.closeClaimProviderLookupDialog,
  currentAppOrganization: stores.global.currentAppOrganization,
  getAllProviderTypes: stores.providers.getAllProviderTypes,
  getScheduleById: stores.schedules.getScheduleById,
  isOpen: stores.schedules.isClaimProviderLookupDialogOpen,
  providerTypes: stores.providers.providerTypes,
  selectedEpisodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
  selectedProviderTypeId: stores.locations.selectedProviderTypeId,
  selectedSchedule: stores.schedules.selectedSchedule,
  selectedLocation: stores.locations.selectedEditClaimLocation,
  selectedProvider: stores.providers.selectedModalEditScheduleProvider,
  setSelectedAddress: stores.locations.setSelectedAddress,
  setSelectedProcedureFilters: stores.locations.setSelectedProcedureFilters,
  setSelectedProviderGroup: stores.locations.setSelectedProviderGroup,
  setSelectedProviderLocation: stores.locations.setSelectedProviderLocation,
  setSelectedProviderTypeId: stores.locations.setSelectedProviderTypeId,
  setSelectedLocation: stores.locations.setSelectedEditClaimLocation,
  setSelectedProvider: stores.providers.setSelectedModalEditScheduleProvider,
  setSelectedSchedule: stores.schedules.setSelectedSchedule,
}))(ReceiveClaimProviderLookupDialog)

export default withStyles(styles)(InjectedProviderLookupDialog)
