import { createStyles, Grid, Theme, WithStyles, withStyles } from '@material-ui/core'
import BusinessIcon from '@material-ui/icons/Business'
import { FormikErrors } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import * as React from 'react'
import AddressField from '../common/AddressField/AddressField'
import CardWithTitle from '../common/CardWithTitle/CardWithTitle'
import FullWidthField from '../common/FullWidthField'
import IsActiveToggle from '../common/IsActiveToggle'
import { IEmployerFormValues } from './EmployerFormValues'

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      margin: spacing(1),
      padding: spacing(2),
    },
  })

interface IEmployerDetailProps extends WithStyles<typeof styles> {
  errors: FormikErrors<IEmployerFormValues>
  setFieldValue: (filter: string, value: any) => void
  values: IEmployerFormValues
}

class EmployerDetails extends React.Component<IEmployerDetailProps> {
  public render() {
    const { values, setFieldValue } = this.props
    return (
      <CardWithTitle title="Employer" icon={BusinessIcon}>
        <Grid item xs={12}>
          <FullWidthField
            InputLabelProps={{ shrink: !!values.name }}
            variant="outlined"
            name="name"
            label="Name"
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <AddressField
            outlined
            name="address"
            label="Address"
            showPhone={false}
            required={false}
          />
        </Grid>
        <Grid item xs={12}>
          <FullWidthField
            InputLabelProps={{ shrink: !!values.comments }}
            multiline
            rows="3"
            variant="outlined"
            name="comments"
            label="Comments"
          />
        </Grid>
        <Grid item xs={12}>
          <FullWidthField
            name="isSpecialPricing"
            Label={{
              label: 'Special Pricing',
            }}
            component={CheckboxWithLabel}
          />
        </Grid>
        <Grid item xs={12}>
          <IsActiveToggle
            isActive={values.isActive}
            onChange={this.handleIsActiveChange(values, setFieldValue)}
          />
        </Grid>
      </CardWithTitle>
    )
  }

  private handleIsActiveChange =
    (values: IEmployerFormValues, setFieldValue: (field: string, value: any) => void) =>
    () => {
      setFieldValue('isActive', !values.isActive)
    }
}

export default withStyles(styles)(EmployerDetails)
