import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { inject } from 'mobx-react'
import React from 'react'
import ReactPaginate from 'react-paginate'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { IStores } from '../Stores'
import { pagedList } from 'src/viewModels/pagedList'
import { Assignment } from '@material-ui/icons'
import { getEpisodesOfCareByPatientId } from 'src/services/PatientService'
import { SearchRequestBase } from 'src/viewModels/SearchRequestBase'
import { EpisodeOfCareSummaryResult } from 'src/viewModels/EpisodeOfCareSummaryResult'
import ESMenu from 'src/common/ESMenu'
import EpisodeOfCareBillsDialog from './EpisodesOfCare/EpisodeOfCareBills/EpisodeOfCareBillsDialog'
interface IEpisodesOfCareModalProps {
  close?: () => void
  isOpen?: boolean
  patientId?: string
  patientInfo?: string
  buildEOCMenuItems?: (eoc: EpisodeOfCareSummaryResult) => any[]
  setEOCModalLoadFunction?: (parentfunction?: (patientId: string) => void) => void
}

class EpisodesOfCareModal extends React.Component<IEpisodesOfCareModalProps> {
  public render() {
    const { isOpen, close, patientId, patientInfo } = this.props
    return (
      <>
        <EpisodeOfCareBillsDialog patientInfo={patientInfo} />
        <Dialog
          key={patientId}
          fullWidth={true}
          maxWidth="xl"
          open={isOpen!}
          onClose={close}
        >
          <DialogTitle style={{ padding: 0 }}>
            <ResponsiveAppBar
              title={'Episodes of Care: ' + patientInfo}
              pageIcon={<Assignment />}
              isModal={true}
            />
          </DialogTitle>
          {this.state.isLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <DialogContent>
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Referral Date</TableCell>
                    <TableCell>Takeover of Care</TableCell>
                    <TableCell>EOC/Legacy</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Surgery Date</TableCell>
                    <TableCell>MMI</TableCell>
                    <TableCell>Care Coordinator</TableCell>
                    <TableCell>Body Part</TableCell>
                    <TableCell>Org</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{this.mapGridData()}</TableBody>
              </Table>
              <div className="pagination-row">
                {this.paginationInfo()}
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  onPageChange={this.handlePageChange}
                  pageCount={this.state.pageCount}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  //@ts-ignore
                  renderOnZeroPageCount={null}
                />
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} data-cy="close">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  public state = {
    gridData: new pagedList().items as EpisodeOfCareSummaryResult[],
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 25,
    isLoading: false,
  }

  public componentDidMount() {
    this.props.setEOCModalLoadFunction!(this.getEOCData)
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private renderContextMenu = (eoc: EpisodeOfCareSummaryResult, index: number) => {
    let options = this.props.buildEOCMenuItems!(eoc)

    return (
      <ESMenu
        data={eoc}
        menuItems={options}
        rowIndex={index}
        menuAriaLabel={`$episode-of-care-${index}-menu`}
      />
    )
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={10} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((eoc, index) => (
        <TableRow key={index}>
          <TableCell>{eoc.referralDateDisplay}</TableCell>
          <TableCell>{eoc.takeoverOfCare}</TableCell>
          <TableCell>{eoc.generatedNumberOrLegacy}</TableCell>
          <TableCell>{eoc.status}</TableCell>
          <TableCell>{eoc.surgeryDateDisplay}</TableCell>
          <TableCell>{eoc.lastMMIDateDisplay}</TableCell>
          <TableCell>{eoc.careCoordinatorName}</TableCell>
          <TableCell>{eoc.bodyPart}</TableCell>
          <TableCell>{eoc.organizationName}</TableCell>
          <TableCell>{this.renderContextMenu(eoc, index)}</TableCell>
        </TableRow>
      ))
    }
  }

  private getEOCData = async (patientId: string) => {
    this.setState({ isLoading: true })
    const { page, pageSize } = this.state
    const requestData = new SearchRequestBase()
    requestData.page = page
    requestData.pageSize = pageSize
    await getEpisodesOfCareByPatientId(requestData, patientId).then((response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        isLoading: false,
      })

      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }
    })
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getEOCData(this.props.patientId!))
  }
}

const InjectedEpisodesOfCareModal = inject<
  IStores,
  IEpisodesOfCareModalProps,
  Partial<IEpisodesOfCareModalProps>,
  any
>((stores: IStores) => ({
  close: stores.patientEdit.closeEOCModal,
  isOpen: stores.patientEdit.isEpisodesOfCareModalOpen,
  buildEOCMenuItems: stores.patientEdit.buildEOCMenuItems,
  setEOCModalLoadFunction: stores.patientEdit.setEOCModalLoad,
}))(EpisodesOfCareModal)

export default InjectedEpisodesOfCareModal
