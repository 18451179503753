import { DefaultScheduleAppointmentTask, IScheduleAppointmentTask } from '../Definitions'
import * as Yup from 'yup'

export const ScheduleAppointmentTaskSchema = Yup.object().shape({
  authorizationType: Yup.string(),
  comments: Yup.string(),
  dateCompleted: Yup.date(),
  days: Yup.number(),
  id: Yup.string(),
  isActive: Yup.boolean(),
  name: Yup.string(),
  priority: Yup.number(),
  requiresApproval: Yup.boolean(),
  taskNotApplicable: Yup.boolean(),
  timing: Yup.string(),
})

export interface IScheduleAppointmentTaskFormValues {
  authorizationType?: string
  days?: number
  dateCompleted?: Date
  comments: string
  readonly id: string
  isActive: boolean
  name?: string
  priority?: number
  requiresApproval: boolean
  taskNotApplicable: boolean
  timing?: string
}

function setDefaultValues(): IScheduleAppointmentTaskFormValues {
  return {
    comments: '',
    dateCompleted: undefined,
    id: '',
    isActive: true,
    requiresApproval: false,
    taskNotApplicable: false,
  }
}

function setValuesFromScheduleAppointmentTask(
  appointmentTask: IScheduleAppointmentTask
): IScheduleAppointmentTaskFormValues {
  return {
    authorizationType: appointmentTask.authorizationType || '',
    comments: appointmentTask.comments || '',
    dateCompleted: appointmentTask.dateCompleted || undefined,
    days: appointmentTask.days || 0,
    id: appointmentTask.id || '',
    isActive: appointmentTask.isActive,
    name: appointmentTask.name || '',
    priority: appointmentTask.priority || 0,
    requiresApproval: appointmentTask.requiresApproval,
    taskNotApplicable: appointmentTask.taskNotApplicable,
    timing: appointmentTask.timing || '',
  }
}

export function createIScheduleAppointmentTaskFormValues(
  appointmentTask?: IScheduleAppointmentTask
) {
  if (appointmentTask) {
    return setValuesFromScheduleAppointmentTask(appointmentTask)
  } else {
    return setDefaultValues()
  }
}

export function toScheduleAppointmentTask(
  formValues: IScheduleAppointmentTaskFormValues,
  original?: IScheduleAppointmentTask
): IScheduleAppointmentTask {
  const appointmentTask =
    original === undefined ? DefaultScheduleAppointmentTask() : original
  return {
    ...appointmentTask,
    authorizationType: formValues.authorizationType || '',
    comments: formValues.comments || '',
    dateCompleted: formValues.dateCompleted || undefined,
    days: formValues.days || 0,
    isActive: formValues.isActive,
    name: formValues.name || '',
    priority: formValues.priority || 0,
    requiresApproval: formValues.requiresApproval,
    taskNotApplicable: formValues.taskNotApplicable || false,
    timing: formValues.timing || '',
  }
}
