import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
} from '@material-ui/core'
import Select, { SelectProps } from '@material-ui/core/Select'
import * as React from 'react'
import ReactDOM from 'react-dom'

export interface ISelectInputProps<T> extends SelectProps {
  name: string
  label?: string
  items?: T[]
  inputId: string
  required?: boolean
  fullwidth?: boolean
  disabled?: boolean
  errorMessage?: string
  checkedItems?: string[]
  cypressLabel?: string
  childCypressLabel?: string
  getName: (item: T) => string
  getValue: (item: T) => string
  shrinkLabel?: boolean
  onChange: (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => void
  outlined?: boolean
  value?: Array<string | number | boolean | object> | string | number | boolean | object
}

export default class SelectInput<T> extends React.Component<ISelectInputProps<T>> {
  private InputLabelRef: HTMLElement | null
  constructor(props: ISelectInputProps<T>) {
    super(props)
  }

  public componentDidMount() {
    if (this.props.outlined) {
      const labelNode = ReactDOM.findDOMNode(this.InputLabelRef)
      if (labelNode instanceof HTMLElement)
        this.setState({ labelWidth: labelNode.offsetWidth })
    }
  }

  public state = {
    labelWidth: 0,
  }

  public render() {
    const {
      name,
      label,
      items,
      inputId,
      disabled,
      fullWidth = false,
      required = false,
      multiple = false,
      checkedItems = Array<string>(),
      errorMessage,
      getName,
      getValue,
      cypressLabel,
      childCypressLabel,
      value,
      onChange,
      outlined,
    } = this.props
    return (
      <FormControl
        fullWidth={fullWidth}
        required={required}
        disabled={disabled}
        error={errorMessage !== undefined}
        variant={outlined ? 'outlined' : 'standard'}
      >
        {label && (
          <InputLabel
            variant={outlined ? 'outlined' : 'standard'}
            htmlFor={inputId}
            shrink={this.shouldShrinkLabel()}
            ref={
              outlined
                ? (ref) => {
                    this.InputLabelRef = ref
                  }
                : undefined
            }
          >
            {label}
          </InputLabel>
        )}
        <Select
          data-cy={cypressLabel}
          name={name}
          inputProps={{ id: inputId }}
          multiple={multiple}
          disabled={disabled}
          displayEmpty
          input={
            outlined ? (
              <OutlinedInput
                id={inputId}
                name={name}
                labelWidth={this.state.labelWidth}
                notched={this.shouldShrinkLabel()}
              />
            ) : undefined
          }
          value={value}
          onChange={onChange}
          renderValue={multiple ? this.renderText(items || []) : undefined}
        >
          {items &&
            items.map((item: T, index: number) => (
              <MenuItem key={index} value={getValue(item)} data-cy={childCypressLabel}>
                {multiple && <Checkbox checked={checkedItems.includes(getValue(item))} />}
                {getName(item)}
              </MenuItem>
            ))}
        </Select>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    )
  }
  private renderText = (items: T[]) => (x: string[]) =>
    items
      .filter((i) => x.includes(this.props.getValue(i)))
      .map((i) => this.props.getName(i))
      .join(', ')

  private shouldShrinkLabel() {
    return this.props.shrinkLabel ? true : undefined
  }
}
