import { DefaultBillCheck, DefaultCheck, ICheck } from '../Definitions'
import { BillCheckDto } from '../generated_client'
import uuidv4 from 'uuid/v4'
import { IApplyPaymentRequest } from 'src/viewModels/ApplyPaymentRequest'
import { IPaymentLineItem } from 'src/viewModels/PaymentLIneItem'
import { LineItemDistributionDetail } from 'src/viewModels/LineItemDistributionDetail'

export interface IBillCheckFormValues {
  readonly id?: string
  amount?: number
  cmS1500Id: string
  checkId: string
}

export interface ICheckFormValues {
  readonly id?: string
  amount?: number
  applyAmount?: number
  checkNumber: string
  datePaid?: Date
  billChecks: IBillCheckFormValues[]
  itemList: IPaymentLineItem[]
  payer?: string
}

function setDefaultValues(
  cms1500Id: string,
  lineItems?: IPaymentLineItem[],
  insuranceCompany?: string
): ICheckFormValues {
  return {
    amount: undefined,
    applyAmount: undefined,
    billChecks: [{ amount: 0, cmS1500Id: cms1500Id } as IBillCheckFormValues],
    checkNumber: '',
    datePaid: undefined,
    id: '',
    itemList: lineItems ?? ([] as IPaymentLineItem[]),
    payer: insuranceCompany ?? undefined,
  }
}

export function setValuesFromCheck(check: ICheck) {
  return {
    amount: check.amount || 0,
    applyAmount: 0,
    billChecks: check.billChecks.map((x) => setValuesFromBillCheck(x)) || [],
    checkNumber: check.checkNumber || '',
    datePaid: check.datePaid || undefined,
    itemList: [] as IPaymentLineItem[],
    payer: check.payer,
  }
}

export function setValuesFromBillCheck(billCheck: BillCheckDto) {
  return {
    amount: billCheck.amount || 0,
    applyAmount: 0,
    checkId: billCheck.checkId || '',
    cmS1500Id: billCheck.cmS1500Id || '',
  }
}

export function createICheckFormValues(
  cms1500Id: string,
  check?: ICheck,
  lineItems?: IPaymentLineItem[],
  insuranceCompanyName?: string
) {
  if (check === undefined) {
    return setDefaultValues(cms1500Id, lineItems, insuranceCompanyName)
  } else {
    return setValuesFromCheck(check)
  }
}

export function toICheck(
  formValues: ICheckFormValues,
  cms1500Id: string,
  original?: ICheck
): ICheck {
  const check = original === undefined ? DefaultCheck(true) : original

  return {
    ...check,
    amount: formValues.amount || 0,
    billChecks: formValues.billChecks.map((x) =>
      createBillChecks(formValues, cms1500Id, x)
    ),
    checkNumber: formValues.checkNumber || '',
    datePaid: formValues.datePaid || new Date(),
  }
}

export function toIApplyPayment(
  formValues: ICheckFormValues,
  cmsId: string
): IApplyPaymentRequest {
  var lineItems = formValues.itemList!.map(
    (item) => new LineItemDistributionDetail(item.cmS1500LineItemId!, item.paymentAmount!)
  )

  return {
    cms1500Id: cmsId,
    checkAmount: formValues.amount,
    checkNumber: formValues.checkNumber || '',
    datePaid: formValues.datePaid || new Date(),
    lineDistributionDetail: lineItems,
    payer: formValues.payer,
  }
}

function createBillChecks(
  check: ICheckFormValues,
  cms1500Id: string,
  original?: IBillCheckFormValues
) {
  const newBillCheck = original === undefined ? DefaultBillCheck(true) : original

  return new BillCheckDto({
    ...newBillCheck,
    amount: check.amount,
    checkId: check.id,
    cmS1500Id: cms1500Id,
    createdOn: new Date(),
    id: uuidv4(),
    isActive: true,
    modifiedOn: new Date(),
  })
}
