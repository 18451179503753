import { CircularProgress } from '@material-ui/core'
import MaterialUIButton, { ButtonProps } from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import * as React from 'react'

const FloatingCircularProgress = withStyles({
  root: {
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
    position: 'absolute',
    top: '50%',
  },
})(CircularProgress)

const Button = <C extends React.ElementType>({
  children,
  isLoading,
  ...buttonProps
}: ButtonProps<C, { component?: C; isLoading?: boolean }>) => {
  return (
    <MaterialUIButton {...buttonProps} disabled={isLoading || buttonProps.disabled}>
      {children}
      {isLoading && <FloatingCircularProgress size={24} />}
    </MaterialUIButton>
  )
}

export default Button
