export interface IPatientSearchRequest {
  page: number
  pageSize: number
  patientFirstName?: string | undefined
  patientLastName?: string | undefined
  eocGeneratedOrLegacyId?: string | undefined
}

export class PatientSearchRequest implements IPatientSearchRequest {
  public page: number
  public pageSize: number
  public patientFirstName?: string | undefined
  public patientLastName?: string | undefined
  public eocGeneratedOrLegacyId?: string | undefined
}
