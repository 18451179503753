import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { DefaultAppointmentTask, IAppointmentTask } from '../../Definitions'
import {
  AppointmentTaskDto,
  Client,
  IAppointmentTaskDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import ComponentsStore from '../ComponentsStore/ComponentsStore'
import DataTableStore from '../DataTableStore'

const ColumnSettingsDto = 'AppointmentTaskDto'
const SEND_SCA_TASK_ID = '0bab785f-772b-4677-8ca3-12dcae31e9fb'
const SCA_SIGNED = '58cc1d25-15a7-45d6-8956-0bb92666819c'

export default class AppointmentTasksStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public appointmentTasks: IAppointmentTask[]
  @observable
  public selectedAppointmentTask?: IAppointmentTask
  public modalTitle: string = ''
  @observable
  public isCurrentContractSingleAgreement?: boolean
  @observable
  public dataTableStore: DataTableStore<IAppointmentTaskDto, IAppointmentTask>
  @observable
  public appointmentTaskCount: number = 0

  constructor(
    private globalStore: GlobalStore,
    private client: Client,
    private componentsStore: ComponentsStore
  ) {
    this.dataTableStore = new DataTableStore<IAppointmentTaskDto, IAppointmentTask>(
      globalStore,
      ({ filter, page, orderBy, includeInactives }) =>
        client.getAllAppointmentTasks(
          undefined,
          this.componentsStore.selectedComponent
            ? this.componentsStore.selectedComponent.id
            : '',
          filter,
          page,
          undefined,
          orderBy,
          includeInactives
        ),
      (appointmentTask) => this.setupAppointmentTasks(appointmentTask)
    )
  }

  @action.bound
  public setIsCurrentContractSingleAgreement(isSingleAgreement?: boolean) {
    this.isCurrentContractSingleAgreement = isSingleAgreement
  }

  @action.bound
  public clearFilters() {
    this.isCurrentContractSingleAgreement = undefined
  }

  @action.bound
  public clearAppointmentTaskData() {
    this.clearFilters()
    this.dataTableStore.clearData()
  }

  @action.bound
  public openDialog(appointmentTask: IAppointmentTask) {
    this.selectedAppointmentTask = appointmentTask
    this.isModalOpen = true
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedAppointmentTask = undefined
  }

  @action.bound
  public disableAppointmentTask(AppointmentTask: IAppointmentTask) {
    this.isLoading = true
    return this.client
      .archiveAppointmentTask(AppointmentTask.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
          this.appointmentTaskCount = this.dataTableStore!.data.filter(
            (x) => x.isActive
          )!.length
        })
      )
  }

  @action.bound
  public getColumnSettingsAndAppointmentTasks() {
    if (!this.globalStore.user) {
      return Promise.resolve()
    }
    this.isLoading = true
    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.appointmentTaskCount = data!.result!.filter((x) => x.isActive)!.length
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
        })
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public getAllAppointmentTasks() {
    this.isLoading = true
    return this.client
      .getAllAppointmentTasks()
      .then((resp: SwaggerResponse<IAppointmentTaskDto[]>) =>
        runInAction(() => {
          this.appointmentTasks = this.setupAppointmentTasks(resp.result)
          this.isLoading = false
        })
      )
  }

  @action.bound
  public editAppointmentTask(appointmentTask: IAppointmentTask) {
    this.selectedAppointmentTask = appointmentTask
  }

  @action.bound
  public recoverAppointmentTask(appointmentTask: IAppointmentTask) {
    this.isLoading = true
    return this.client
      .reactivateAppointmentTask(appointmentTask.id)
      .then(() => this.dataTableStore.loadData())
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
          this.appointmentTaskCount = this.dataTableStore!.data.filter(
            (x) => x.isActive
          )!.length
        })
      })
  }

  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData().finally(() =>
      runInAction(() => {
        this.appointmentTaskCount = this.dataTableStore!.data.filter(
          (x) => x.isActive
        )!.length
      })
    )

    this.closeDialog()
  }

  @action.bound
  public saveAppointmentTask(appointmentTask: IAppointmentTask) {
    this.isLoading = true
    let promise
    const appointmentTaskDto = new AppointmentTaskDto(appointmentTask)
    if (appointmentTask.isNew) {
      promise = this.client.createAppointmentTask(appointmentTaskDto)
    } else {
      promise = this.client.updateAppointmentTask(appointmentTask.id, appointmentTaskDto)
    }
    return promise
      .then(() => {
        this.resetUIState(appointmentTask.isNew)
      })
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound
  public clearSelectedAppointmentTask() {
    this.selectedAppointmentTask = undefined
  }

  private setupAppointmentTasks = (appointmentTasks: IAppointmentTaskDto[]) => {
    this.appointmentTaskCount = appointmentTasks.filter((x) => x.isActive)!.length
    return appointmentTasks
      .filter(
        (x) =>
          !this.isCurrentContractSingleAgreement ||
          (x.id !== SEND_SCA_TASK_ID && x.id !== SCA_SIGNED)
      )
      .map((x) => this.setupAppointmentTaskMenuItems(this.addDefaultFields(x)))
  }

  private addDefaultFields = (appointmentTask: IAppointmentTaskDto): IAppointmentTask => {
    return { ...DefaultAppointmentTask(), ...appointmentTask }
  }

  private setupAppointmentTaskMenuItems = (appointmentTask: IAppointmentTask) => {
    appointmentTask.menuItems = []
    appointmentTask.menuItems.push({
      disabled: this.componentsStore.selectedComponent!.isSimpleComponent,
      icon: Edit,
      isConfirm: false,
      name: 'Edit',
      onClick: () => this.openDialog(appointmentTask),
    })
    if (appointmentTask.isActive && !appointmentTask.isLocked) {
      appointmentTask.menuItems.push({
        color: 'red',
        disabled: this.componentsStore.selectedComponent!.isSimpleComponent,
        icon: RemoveCircle,
        isConfirm: true,
        name: 'Make Inactive',
        onClick: () => this.disableAppointmentTask(appointmentTask),
      })
    } else if (!appointmentTask.isActive) {
      appointmentTask.menuItems.push({
        color: '#94D33D',
        disabled: this.componentsStore.selectedComponent!.isSimpleComponent,
        icon: AddCircle,
        isConfirm: true,
        name: 'Recover',
        onClick: () => this.recoverAppointmentTask(appointmentTask),
      })
    }
    return appointmentTask
  }
}
