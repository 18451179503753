import { Grid } from '@material-ui/core'
import { Accessibility } from '@material-ui/icons'
import { FormikActions, FormikProps } from 'formik'
import { inject } from 'mobx-react'
import * as React from 'react'
import * as Yup from 'yup'
import ESDialog from '../common/ESDialog'
import FullWidthField from '../common/FullWidthField'
import IsActiveToggle from '../common/IsActiveToggle'
import { noWhitespaceString } from '../common/YupExtensions'
import { IBodyPart } from '../Definitions'
import { IStores } from '../Stores'
import {
  createIBodyPartFormValues,
  IBodyPartFormValues,
  toIBodyPart,
} from './BodyPartFormValues'

const BodyPartSchema = Yup.object().shape({
  name: noWhitespaceString('Name is required', true),
})

interface IBodyPartDialogProps {
  bodyPart?: IBodyPart
  close?: () => void
  isLoading?: boolean
  isOpen?: boolean
  saveBodyPart?: (bodyPart: IBodyPart) => Promise<void>
}

class BodyPartDialog extends React.Component<IBodyPartDialogProps> {
  public save = (
    values: IBodyPartFormValues,
    formikBag: FormikActions<IBodyPartFormValues>
  ) => {
    this.props.saveBodyPart!(toIBodyPart(values, this.props.bodyPart)).finally(() =>
      formikBag.setSubmitting(false)
    )
  }

  public render() {
    const { close, isLoading, isOpen, bodyPart } = this.props
    const initialValues = createIBodyPartFormValues(bodyPart)
    return (
      <ESDialog
        bottomActions={this.bottomActions}
        close={close!}
        initialValues={initialValues}
        isLoading={isLoading}
        open={isOpen!}
        save={this.save}
        title={bodyPart!.isNew ? 'Add New BodyPart' : 'Edit BodyPart'}
        validationSchema={BodyPartSchema}
        maxWidth="sm"
        titleIcon={<Accessibility />}
      >
        {() => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FullWidthField autoFocus name="name" label="Name" required />
            </Grid>
          </Grid>
        )}
      </ESDialog>
    )
  }

  private bottomActions(formikProps: FormikProps<IBodyPartFormValues>) {
    const onChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      formikProps.setFieldValue('isActive', checked)
    }
    return <IsActiveToggle isActive={formikProps.values.isActive} onChange={onChange} />
  }
}

const InjectedBodyPartDialog = inject<
  IStores,
  IBodyPartDialogProps,
  Partial<IBodyPartDialogProps>,
  any
>((stores: IStores) => ({
  bodyPart: stores.bodyParts.selectedBodyPart,
  close: stores.bodyParts.closeDialog,
  isLoading: stores.bodyParts.isLoading,
  isOpen: stores.bodyParts.isModalOpen,
  saveBodyPart: stores.bodyParts.saveBodyPart,
}))(BodyPartDialog)

export default InjectedBodyPartDialog
