import { History } from 'history'
import * as React from 'react'
import { Route, Router } from 'react-router-dom'
import IRoute from '../IRoute'
import RoleRequiredRoute from '../RoleRequiredRoute'

const renderRoute = (route: IRoute, index: number) => {
  if (
    (route.allowedRoles && route.allowedRoles.length > 0) ||
    (route.allowedOrganization && route.allowedOrganization.length > 0)
  ) {
    return <RoleRequiredRoute key={index} route={route} />
  } else {
    return (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    )
  }
}

interface IRouterProps {
  container: React.ComponentClass | React.StatelessComponent
  containerProps?: any
  routes: IRoute[]
  history: History
  children?: React.ReactNode
}

const RouterWrapper: React.SFC<IRouterProps> = (props) => (
  <Router history={props.history}>
    <>
      {props.children}
      <props.container {...props.containerProps}>
        {props.routes.map(renderRoute)}
      </props.container>
    </>
  </Router>
)

export type { RouterWrapper, IRoute, IRouterProps }
export default RouterWrapper
