import { Location, LocationState } from 'history'
import { action, computed, observable } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import { QuoteSearchRequest } from 'src/viewModels/QuoteSearchRequest'
import Constants from '../../config'
import { IOrganization, IUser } from '../../Definitions'
import { SimpleEntityDto } from '../../generated_client'
import { OrganizationId } from '../../utils/OrganizationIds'
import { ClaimsAcquisitionSearchRequest } from 'src/viewModels/ClaimsAcquisitionSearchRequest'
import { DashboardSearchRequest } from 'src/viewModels/DashboardSearchRequest'
import { BillOutSearchRequest } from 'src/viewModels/BillOutSearchRequest'
import { ReceivedCheckSearchRequest } from 'src/viewModels/ReceivedCheckSearchRequest'
import { SentCheckSearchRequest } from 'src/viewModels/SentCheckSearchRequest'
import { InboxSummary } from 'src/viewModels/workflow/InboxSummary'
import { PayProvidersSearchRequest } from 'src/viewModels/PayProvidersSearchRequest'
import { InboxType } from 'src/utils/InboxType'

class GlobalStore {
  @computed
  public get isLoggedIn() {
    return !!this.user
  }

  @computed
  public get isOrganizationSwitchAvailable() {
    if (this.canSwitchOrganizations && this.canSwitchOrganizations === true) {
      return true
    }
    return false
  }

  @computed
  public get isAdmin() {
    if (this.user) {
      return this.user.roles.indexOf(Constants.Roles.Administrator) !== -1
    }
    return false
  }

  @computed
  public get isCurrentOrganizationSurgical(): boolean {
    if (
      this.currentAppOrganization &&
      this.currentAppOrganization.id === OrganizationId.Surgical
    ) {
      return true
    }
    return false
  }

  @computed
  public get currentUserOrganization(): SimpleEntityDto {
    if (this.user) {
      return this.user.organization as SimpleEntityDto
    }
    return {} as SimpleEntityDto
  }

  @computed
  public get drawerWidth() {
    return this.isDrawerOpen ? 255 : 72
  }

  @observable
  public canSwitchOrganizations: boolean = false

  @observable
  public user?: IUser

  @observable
  public isDrawerOpen: boolean = false

  @observable
  public isToggleDrawerTooltipOpen = false

  @observable
  public currentAppOrganization?: IOrganization

  @observable
  public isSurgical: boolean = true

  @observable
  public quoteRequest?: QuoteSearchRequest

  @observable
  public checkRequest?: ReceivedCheckSearchRequest

  @observable
  public sentCheckRequest?: SentCheckSearchRequest

  @observable
  public acqRequest?: ClaimsAcquisitionSearchRequest

  @observable
  public billOutRequest?: BillOutSearchRequest

  @observable
  public receivePaymentRequest?: BillOutSearchRequest

  @observable
  public dashboardRequest?: DashboardSearchRequest

  @observable
  public payProviderRequest?: PayProvidersSearchRequest

  @observable
  public selectedInbox?: InboxSummary

  @observable
  public selectedInboxOrder?: string

  @observable
  public selectedInboxOrderColumn?: string

  @observable
  public returnRoutePath?: string

  @observable
  public selectedInboxType?: InboxType

  constructor(private routerStore: RouterStore) {}

  @action.bound
  public toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen
    this.isToggleDrawerTooltipOpen = false
  }

  @action.bound
  public toggleDrawerToolTip() {
    this.isToggleDrawerTooltipOpen = !this.isToggleDrawerTooltipOpen
  }

  @action.bound
  public setUser(user: IUser) {
    this.user = user
  }

  @action.bound
  public setCanSwitchOrganizations(canSwitch: boolean) {
    this.canSwitchOrganizations = canSwitch
  }

  @action.bound
  public clearUser() {
    this.user = undefined
  }

  @action.bound
  public setCurrentAppOrganization(organization?: IOrganization) {
    this.currentAppOrganization = organization
  }

  @action.bound
  public changeTheme(id: string | undefined) {
    this.isSurgical = id === OrganizationId.Surgical ? true : false
  }

  @action.bound
  public setQuoteRequest(request: QuoteSearchRequest) {
    this.quoteRequest = request
  }

  @action.bound
  public setCheckRequest(request: ReceivedCheckSearchRequest) {
    this.checkRequest = request
  }

  @action.bound
  public setSentCheckRequest(request: SentCheckSearchRequest) {
    this.sentCheckRequest = request
  }

  @action.bound
  public setBillOutRequest(request: BillOutSearchRequest) {
    this.billOutRequest = request
  }

  @action.bound
  public setReceivePaymentRequest(request: BillOutSearchRequest) {
    this.receivePaymentRequest = request
  }

  @action.bound
  public setAcqRequest(request: ClaimsAcquisitionSearchRequest) {
    this.acqRequest = request
  }

  @action.bound
  public setDashboardRequest(request: DashboardSearchRequest) {
    this.dashboardRequest = request
  }

  @action.bound
  public setPayProviderRequest(request: PayProvidersSearchRequest) {
    this.payProviderRequest = request
  }

  @action.bound
  public setSelectedInbox(inbox: InboxSummary | undefined) {
    this.selectedInbox = inbox
  }

  @action.bound
  public setSelectedInboxType(type: InboxType | undefined) {
    this.selectedInboxType = type
  }

  @action.bound
  public setSelectedInboxSort(order: string | undefined, orderBy: string | undefined) {
    this.selectedInboxOrder = order
    this.selectedInboxOrderColumn = orderBy
  }

  @action.bound
  public async setReturn(path?: string | undefined) {
    this.returnRoutePath = path === undefined ? 'new tab' : path
  }

  @action.bound
  public closeTab(fallBackPath?: string | undefined) {
    if (this.returnRoutePath === 'new tab') {
      window.close()
      this.returnRoutePath = undefined
    } else if (this.returnRoutePath !== undefined) {
      this.routerStore.push(this.returnRoutePath)
      this.returnRoutePath = undefined
    } else if (fallBackPath) {
      this.routerStore.push(fallBackPath)
    }
  }

  @action.bound
  public rerouteToPath(path: string) {
    this.routerStore.push(path)
  }

  @action.bound
  public rerouteIfNotLoggedIn(location?: Location<LocationState>) {
    if (!location) {
      location = this.routerStore.location
    }
    if (!this.isLoggedIn && location.pathname !== '/login') {
      const previousRoute = btoa(location.pathname + location.search)
      this.routerStore.push('/login?previousRoute=' + previousRoute)
    }
  }

  @action.bound
  public clearUserAndRouteToLogin() {
    this.clearUser()
    this.quoteRequest = undefined
    this.billOutRequest = undefined
    this.acqRequest = undefined
    this.dashboardRequest = undefined
    this.checkRequest = undefined
    this.payProviderRequest = undefined
    this.selectedInbox = undefined
    this.selectedInboxOrder = undefined
    this.selectedInboxOrderColumn = undefined
    this.selectedInboxType = undefined
    this.rerouteIfNotLoggedIn()
  }
}

export default GlobalStore
