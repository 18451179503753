import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  Button,
} from '@material-ui/core'
import {
  AddShoppingCart,
  Alarm,
  Check,
  Close,
  Inbox,
  InfoOutlined,
} from '@material-ui/icons'
import { inject } from 'mobx-react'
import React, { ChangeEvent } from 'react'
import ReactPaginate from 'react-paginate'
import { pagedList } from 'src/viewModels/pagedList'
import { IStores } from 'src/Stores'
import { SearchRequestBase } from 'src/viewModels/SearchRequestBase'
import { Helmet } from 'react-helmet'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { CommentOutline } from 'mdi-material-ui'
import { IncomingDocumentSummary } from 'src/viewModels/IncomingDocumentSummary'
import {
  getAllInboxItems,
  getAllIncomingDocuments,
  getInboxesByUser,
} from 'src/services/WorkflowService'
import moment from 'moment'
import { InboxSummary } from 'src/viewModels/workflow/InboxSummary'
import { DocumentInboxItemSummary } from 'src/viewModels/workflow/DocumentInboxItemSummary'
import { RouterStore } from 'mobx-react-router'
import { ClaimInboxItemSummary } from 'src/viewModels/workflow/ClaimInboxItemSummary'
import { formatCurrency } from 'src/utils/Formatter'
import { InboxType } from 'src/utils/InboxType'
import SortedTableCell from './SortedTableCell'
import { QuoteInboxItemSummary } from 'src/viewModels/workflow/QuoteInboxItemSummary'
import BundleClaimsModal from 'src/quotes/QuoteBills/BundleClaimsModal'
import { BilloutInboxItemSummary } from 'src/viewModels/workflow/BilloutInboxItemSummary'

interface IInboxesProps {
  routerStore?: RouterStore
  editBill?: (eocId: string) => void
  isLoading?: boolean
  setClaimsModalLoadFunction?: (
    parentfunction?: (episodeOfCareId: string) => void
  ) => void
  patientInfo?: string
  scheduleId?: string
  rerouteToPath?: (path: string) => void
  setSelectedInbox?: (inbox: InboxSummary | undefined) => void
  savedInbox?: InboxSummary | undefined
  savedOrder?: string | undefined
  savedOrderBy?: string | undefined
  setSavedSort?: (order: string | undefined, orderBy: string | undefined) => void
  openBillsDialog?: (quoteId: string) => Promise<void>
  savedInboxType?: InboxType | undefined
  setSelectedInboxType?: (type: InboxType | undefined) => void
}

class Inboxes extends React.Component<IInboxesProps> {
  private alphabetSort = (a: InboxSummary, b: InboxSummary) => {
    if (b.inboxName > a.inboxName) return -1
    if (b.inboxName < a.inboxName) return 1
    return 0
  }

  private loadInboxes = async () => {
    var line = new InboxSummary()
    line.inboxType = 'line'
    line.inboxName = '─────────────────────────'
    var inboxes = await getInboxesByUser(this.state.selectedType)
    var incomingBox = inboxes.filter((x) => x.id == -1)
    var personal = inboxes
      .filter((x) => x.inboxType == 'DynamicPersonal')
      .sort(this.alphabetSort)
    var docInboxes = inboxes
      .filter((x) => x.itemType == InboxType.Document && x.inboxType != 'DynamicPersonal')
      .sort(this.alphabetSort)
    var claimInboxes = inboxes
      .filter((x) => x.itemType == InboxType.Claim && x.inboxType != 'DynamicPersonal')
      .sort(this.alphabetSort)
    var quoteInboxes = inboxes
      .filter((x) => x.itemType == InboxType.Quote && x.inboxType != 'DynamicPersonal')
      .sort(this.alphabetSort)

    var billoutInboxes = inboxes
      .filter((x) => x.itemType == InboxType.Billout && x.inboxType != 'DynamicPersonal')
      .sort(this.alphabetSort)

    var joinedInboxes = new Array<InboxSummary>()

    if (
      personal.length > 0 &&
      (docInboxes.length > 0 ||
        claimInboxes.length > 0 ||
        incomingBox.length > 0 ||
        quoteInboxes.length > 0 ||
        billoutInboxes.length > 0)
    ) {
      joinedInboxes = personal.concat(line)
    }

    if (incomingBox.length > 0) {
      joinedInboxes = joinedInboxes.concat(incomingBox)

      if (
        docInboxes.length > 0 ||
        claimInboxes.length > 0 ||
        quoteInboxes.length > 0 ||
        billoutInboxes.length > 0
      ) {
        joinedInboxes = joinedInboxes.concat(line)
      }
    }

    if (docInboxes.length > 0 || claimInboxes.length > 0) {
      if (docInboxes.length > 0 && claimInboxes.length > 0) {
        joinedInboxes = joinedInboxes.concat(docInboxes).concat(line).concat(claimInboxes)
      } else if (docInboxes.length > 0) {
        joinedInboxes = joinedInboxes.concat(docInboxes)
      } else {
        joinedInboxes = joinedInboxes.concat(claimInboxes)
      }

      if (quoteInboxes.length > 0) {
        joinedInboxes = joinedInboxes.concat(line)
      }
    }

    if (quoteInboxes.length > 0) {
      joinedInboxes = joinedInboxes.concat(quoteInboxes)
    }

    if (billoutInboxes.length > 0) {
      if (docInboxes.length > 0 || claimInboxes.length > 0 || quoteInboxes.length > 0) {
        joinedInboxes = joinedInboxes.concat(line)
      }
      joinedInboxes = joinedInboxes.concat(billoutInboxes)
    }

    this.setState(
      {
        inboxes: joinedInboxes,
        inbox: this.props.savedInbox,
        selectedItemType: this.props.savedInbox?.itemType,
        gridLoading: this.props.savedInbox != undefined,
        order: this.props.savedOrder ?? 'asc',
        orderBy: this.props.savedOrderBy ?? '',
      },
      () => {
        if (this.props.savedInbox != undefined) {
          this.getInboxData()
        }
      }
    )
  }

  async componentDidMount(): Promise<void> {
    this.sort = this.sort.bind(this)
    this.setState({ selectedType: this.props.savedInboxType }, async () => {
      await this.loadInboxes()
    })
  }

  public state = {
    dynamicColumns: new Array<string>(),
    gridData: new pagedList().items as
      | DocumentInboxItemSummary[]
      | IncomingDocumentSummary[]
      | ClaimInboxItemSummary[]
      | QuoteInboxItemSummary[]
      | BilloutInboxItemSummary[]
      | { [key: string]: any }[],

    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 25,
    selectedItemType: '',
    inbox: undefined as unknown as InboxSummary,
    gridLoading: true,
    inboxes: new Array<InboxSummary>(),
    order: 'asc',
    orderBy: '',
    selectedType: undefined,
  }

  private priorityNames = {
    1: 'Normal',
    2: 'Medium',
    3: 'High',
  }

  private setSelectedInbox = async (event: any) => {
    var request = new SearchRequestBase()
    request.page = 1
    request.pageSize = this.state.pageSize
    var inboxId = event.target.value
    var inbox = this.state.inboxes.find((x) => x.id == inboxId)
    event.target.options[0].disabled = true
    this.props.setSelectedInbox!(inbox)
    this.props.setSavedSort!(undefined, undefined)
    this.setState(
      {
        selectedItemType: inbox?.itemType,
        inbox: inbox,
        gridLoading: inboxId != '',
        page: 1,
        orderBy: '',
        order: 'asc',
      },
      async () => {
        if (this.state.inbox.id == -1) {
          await getAllIncomingDocuments(request).then((response: any) => {
            this.setState({
              gridData: response.items,
              pageCount: response.totalPages,
              totalItems: response.totalItems,
              gridLoading: false,
            })
            if (response.totalItems > 0) {
              this.setPaginationOffsetData()
            }
          })
        } else {
          await getAllInboxItems(request, this.state.inbox.id.toString(), 'default').then(
            (response: any) => {
              this.setState({
                dynamicColumns: response.columnNames,
                gridData: response.items,
                pageCount: response.totalPages,
                totalItems: response.totalItems,
                gridLoading: false,
              })
              if (response.totalItems > 0) {
                this.setPaginationOffsetData()
              }
            }
          )
        }
      }
    )
  }

  public getExpiredStyle = (expired: boolean) => {
    if (expired) {
      return 'line-through red'
    } else {
      return 'none'
    }
  }

  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to {this.state.lastRecordIndex} of{' '}
            {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }

  private mapClaimData = () => {
    var claimItems = this.state.gridData as ClaimInboxItemSummary[]
    return claimItems.map((claim) => (
      <TableRow>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.openClaimItem(claim.associatedItemId)}
          >
            Open
          </Button>
        </TableCell>
        <TableCell>{this.priorityNames[claim.priority]}</TableCell>
        <TableCell align="right">{moment(claim.entryDate).format('L')}</TableCell>
        <TableCell>{claim.eocLegacy}</TableCell>
        <TableCell align="right">{moment(claim.dateOfService).format('L')}</TableCell>
        <TableCell>{claim.patientName}</TableCell>
        <TableCell>{claim.claimType}</TableCell>
        <TableCell>
          {claim.inGlobal ? <Check htmlColor="green" /> : <Close htmlColor="red" />}
        </TableCell>
        <TableCell>{claim.scheduleComponent}</TableCell>
        <TableCell>
          {claim.billingName}&nbsp;&nbsp;
          <Tooltip title={claim.providerLocation || ''}>
            <InfoOutlined fontSize="small" />
          </Tooltip>
        </TableCell>
        <TableCell align="right">
          {claim.totalCharges ? formatCurrency(claim.totalCharges?.toString()) : ''}
        </TableCell>
        <TableCell align="right">
          {claim.totalContract ? formatCurrency(claim.totalContract.toString()) : ''}
        </TableCell>
        <TableCell>{claim.assignedUser}</TableCell>
        <TableCell
          style={{
            textDecoration: this.getExpiredStyle(
              claim.isLockExpired && claim.lockedBy != undefined
            ),
          }}
        >
          {claim.lockedBy}&nbsp;&nbsp;
          {claim.lockedOn && (
            <Tooltip
              title={
                'Expires at ' + moment(claim.lockExpiresAt).format('MM/DD/YYYY HH:mm:ss')
              }
            >
              <Alarm fontSize="small" />
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="right">
          {claim.pendDate && moment(claim.pendDate).format('L')}
        </TableCell>
        <TableCell style={{ paddingLeft: '0px' }}>
          {claim.pendNote && (
            <Tooltip title={claim.pendNote}>
              <span>
                <CommentOutline fontSize="small" />
              </span>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    ))
  }

  private mapDocumentData = () => {
    var docItems = this.state.gridData as DocumentInboxItemSummary[]
    return docItems.map((doc) => (
      <TableRow>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.openDocumentItem(doc.associatedItemId)}
          >
            Open
          </Button>
        </TableCell>
        <TableCell>{this.priorityNames[doc.priority]}</TableCell>
        <TableCell align="right">{moment(doc.entryDate).format('L')}</TableCell>
        <TableCell style={{ minWidth: '200px' }}>{doc.fileName}</TableCell>
        <TableCell>{doc.assignedUser}</TableCell>
        <TableCell
          style={{
            textDecoration: this.getExpiredStyle(
              doc.isLockExpired && doc.lockedBy != undefined
            ),
          }}
        >
          {doc.lockedBy}&nbsp;&nbsp;
          {doc.lockedOn && (
            <Tooltip
              title={
                'Expires at ' + moment(doc.lockExpiresAt).format('MM/DD/YYYY HH:mm:ss')
              }
            >
              <Alarm fontSize="small" />
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="right">
          {doc.pendDate && moment(doc.pendDate).format('L')}
        </TableCell>
        <TableCell style={{ paddingLeft: '0px' }}>
          {doc.pendNote && (
            <Tooltip title={doc.pendNote}>
              <span>
                <CommentOutline fontSize="small" />
              </span>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    ))
  }

  private mapQuoteData = () => {
    var quoteItems = this.state.gridData as QuoteInboxItemSummary[]
    return quoteItems.map((quote) => (
      <TableRow>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.openQuoteItem(quote.associatedItemId)}
          >
            Open
          </Button>
        </TableCell>
        <TableCell>
          <Tooltip title="Bundle Claims">
            <AddShoppingCart
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState({ gridLoading: true }, () => {
                  this.props.openBillsDialog!(quote.associatedItemId).then(() =>
                    this.setState({ gridLoading: false })
                  )
                })
              }}
            />
          </Tooltip>
        </TableCell>
        <TableCell>{this.priorityNames[quote.priority]}</TableCell>
        <TableCell align="right">{moment(quote.entryDate).format('L')}</TableCell>
        <TableCell>{quote.eocLegacy}</TableCell>
        <TableCell>{quote.quoteType}</TableCell>
        <TableCell align="right">
          {quote.endDate ? moment(quote.endDate).format('MM/DD/YYYY') : ''}
        </TableCell>
        <TableCell>{quote.quoteStatus}</TableCell>
        <TableCell>{quote.patientName}</TableCell>
        <TableCell>{quote.insuranceCompanyName}</TableCell>
        <TableCell>{quote.eocStatus}</TableCell>
        <TableCell align="right">
          {quote.quoteTotal ? formatCurrency(quote.quoteTotal.toString()) : ''}
        </TableCell>
        <TableCell align="right">
          {quote.reconciledTotal != undefined && quote.reconciledTotal > 0
            ? formatCurrency(quote.reconciledTotal.toString())
            : ''}
        </TableCell>
        <TableCell>{quote.assignedUser}</TableCell>
        <TableCell
          style={{
            textDecoration: this.getExpiredStyle(
              quote.isLockExpired && quote.lockedBy != undefined
            ),
          }}
        >
          {quote.lockedBy}&nbsp;&nbsp;
          {quote.lockedOn && (
            <Tooltip
              title={
                'Expires at ' + moment(quote.lockExpiresAt).format('MM/DD/YYYY HH:mm:ss')
              }
            >
              <Alarm fontSize="small" />
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="right">
          {quote.pendDate && moment(quote.pendDate).format('L')}
        </TableCell>
        <TableCell style={{ paddingLeft: '0px' }}>
          {quote.pendNote && (
            <Tooltip title={quote.pendNote}>
              <span>
                <CommentOutline fontSize="small" />
              </span>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    ))
  }

  private mapBilloutData = () => {
    var billoutItems = this.state.gridData as BilloutInboxItemSummary[]
    return billoutItems.map((billout) => (
      <TableRow>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.openBilloutItem(billout.associatedItemId)}
          >
            Open
          </Button>
        </TableCell>
        <TableCell>{this.priorityNames[billout.priority]}</TableCell>
        <TableCell align="right">{moment(billout.entryDate).format('L')}</TableCell>
        <TableCell align="right">{billout.daysInInbox}</TableCell>
        <TableCell>{billout.eocLegacy}</TableCell>
        <TableCell>{billout.patientName}</TableCell>
        <TableCell>
          {billout.dateOfService
            ? moment(billout.dateOfService).format('MM/DD/YYYY')
            : ''}
        </TableCell>
        <TableCell>{billout.providerGroup}</TableCell>
        <TableCell>{billout.eocStatus}</TableCell>
        <TableCell>{billout.bundleType}</TableCell>
        <TableCell>{billout.insuranceCompanyName}</TableCell>
        <TableCell align="right">
          {billout.amountBilled != undefined
            ? formatCurrency(billout.amountBilled.toString())
            : ''}
        </TableCell>
        <TableCell align="right">
          {billout.balanceDue != undefined
            ? formatCurrency(billout.balanceDue.toString())
            : ''}
        </TableCell>
        <TableCell>{billout.assignedUser}</TableCell>
        <TableCell
          style={{
            textDecoration: this.getExpiredStyle(
              billout.isLockExpired && billout.lockedBy != undefined
            ),
          }}
        >
          {billout.lockedBy}&nbsp;&nbsp;
          {billout.lockedOn && (
            <Tooltip
              title={
                'Expires at ' +
                moment(billout.lockExpiresAt).format('MM/DD/YYYY HH:mm:ss')
              }
            >
              <Alarm fontSize="small" />
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="right">
          {billout.pendDate && moment(billout.pendDate).format('L')}
        </TableCell>
        <TableCell style={{ paddingLeft: '0px' }}>
          {billout.pendNote && (
            <Tooltip title={billout.pendNote}>
              <span>
                <CommentOutline fontSize="small" />
              </span>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    ))
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      var columns = this.state.dynamicColumns?.length

      if (!this.state.inbox.inboxType.includes('Dynamic')) {
        switch (this.state.selectedItemType.toString()) {
          case InboxType.Claim:
            columns = 16
            break
          case InboxType.Document:
            columns = 9
            break
          case InboxType.Quote:
            columns = 16
            break
          case InboxType.Billout:
            columns = 15
            break
          default:
            columns = 9
            break
        }
      }
      return (
        <TableRow key={1}>
          <TableCell colSpan={columns} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      if (!this.state.inbox.inboxType.includes('Dynamic')) {
        switch (this.state.selectedItemType.toString()) {
          case InboxType.Claim:
            return this.mapClaimData()
          case InboxType.Document:
            return this.mapDocumentData()
          case InboxType.Quote:
            return this.mapQuoteData()
          case InboxType.Billout:
            return this.mapBilloutData()
          default:
            if (this.state.inbox?.id == -1) {
              var docs = this.state.gridData as IncomingDocumentSummary[]
              return docs.map((doc) => (
                <TableRow>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.openNewlyAddedDocument(doc)}
                    >
                      Open
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    {moment(doc.incomingDate).format('L')}
                  </TableCell>
                  <TableCell style={{ minWidth: '550px' }}>{doc.name}</TableCell>
                </TableRow>
              ))
            }
            break
        }
      } else {
        return this.state.gridData.map((dynamicRecord) => (
          <TableRow>
            {this.state.dynamicColumns?.map((column) =>
              this.renderDynamicColumn(column, dynamicRecord)
            )}
          </TableRow>
        ))
      }

      return <></>
    }
  }

  public sort(columnName: string) {
    this.setState(
      {
        orderBy: columnName,
        order: this.state.order == 'asc' ? 'desc' : 'asc',
        gridLoading: true,
        page: 1,
      },
      () => {
        this.props.setSavedSort!(this.state.order, this.state.orderBy)
        this.getInboxData()
      }
    )
  }

  private renderSortedHeaderIfNotNewlyAdded = (
    columnName: string,
    propertyName: string,
    align?: any,
    style?: React.CSSProperties
  ) => {
    return this.state.inbox?.id != -1 ? (
      <SortedTableCell
        columnName={columnName}
        propertyName={propertyName}
        align={align}
        style={style}
        order={this.state.order}
        orderBy={this.state.orderBy}
        onClick={this.sort}
      />
    ) : (
      <></>
    )
  }

  private renderDynamicHeaderColumn = (columnName: string, columnIndex: string) => {
    if (columnName == 'hdn_AssociatedItemId') {
      if (this.state.inbox.itemType == InboxType.Quote) {
        return (
          <>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </>
        )
      } else {
        return <TableCell></TableCell>
      }
    } else if (columnName.includes('hdn_')) {
      return <TableCell style={{ display: 'none' }}></TableCell>
    } else {
      let align = this.dynamicColumnAlignment(columnName)

      return this.renderSortedHeaderIfNotNewlyAdded(columnName, columnIndex, align)
    }
  }

  private renderDynamicColumn = (columnName: string, record: any) => {
    if (columnName == 'hdn_AssociatedItemId') {
      if (this.state.selectedItemType == InboxType.Claim) {
        return (
          <TableCell>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.openClaimItem(record[columnName.toLowerCase()])}
            >
              Open
            </Button>
          </TableCell>
        )
      } else if (this.state.selectedItemType == InboxType.Document) {
        return (
          <TableCell>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.openDocumentItem(record[columnName.toLowerCase()])}
            >
              Open
            </Button>
          </TableCell>
        )
      } else if (this.state.selectedItemType == InboxType.Quote) {
        return (
          <>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.openQuoteItem(record[columnName.toLowerCase()])}
              >
                Open
              </Button>
            </TableCell>
            <TableCell>
              <Tooltip title="Bundle Claims">
                <AddShoppingCart
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.setState({ gridLoading: true }, () => {
                      this.props.openBillsDialog!(record[columnName.toLowerCase()]).then(
                        () => this.setState({ gridLoading: false })
                      )
                    })
                  }}
                />
              </Tooltip>
            </TableCell>
          </>
        )
      } else if (this.state.selectedItemType == InboxType.Billout) {
        return (
          <TableCell>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.openBilloutItem(record[columnName.toLowerCase()])}
            >
              Open
            </Button>
          </TableCell>
        )
      }
    }

    if (columnName.indexOf('hdn_') > -1) {
      return (
        <TableCell style={{ display: 'none' }}>
          {record[columnName.toLowerCase()]}
        </TableCell>
      )
    } else {
      let align = this.dynamicColumnAlignment(columnName)
      let formattedValue = this.dynamicColumnFormatted(
        columnName,
        record[columnName.toLowerCase()]
      )

      if (
        formattedValue.toLowerCase() == 'true' ||
        formattedValue.toLowerCase() == 'false'
      ) {
        return (
          <TableCell>
            {formattedValue.toLowerCase() == 'true' ? (
              <Check htmlColor="green" />
            ) : (
              <Close htmlColor="red" />
            )}
          </TableCell>
        )
      }
      return <TableCell align={align as any}>{formattedValue}</TableCell>
    }
  }

  private dynamicColumnAlignment = (columnName: string) => {
    return columnName.toLowerCase().includes('date') ||
      columnName.toLowerCase().includes('total') ||
      columnName.toLowerCase().includes('amt') ||
      columnName.toLowerCase().includes('amount') ||
      columnName.toLowerCase().includes('balance') ||
      columnName.toLowerCase().includes('owed') ||
      columnName.toLowerCase().includes('owes') ||
      columnName.toLowerCase().includes('count') ||
      columnName.toLowerCase().includes('%') ||
      columnName.toLowerCase().includes('rcv') ||
      columnName.toLowerCase().includes('dos')
      ? 'right'
      : 'left'
  }

  private dynamicColumnFormatted = (columnName: string, value: string) => {
    let formattedValue = value

    if (
      (columnName.toLowerCase().includes('date') ||
        columnName.toLowerCase().includes('rcv') ||
        columnName.toLowerCase().includes('dos')) &&
      value
    ) {
      formattedValue = moment(value).format('L')
    } else if (
      (columnName.toLowerCase().includes('total') ||
        columnName.toLowerCase().includes('amt') ||
        columnName.toLowerCase().includes('amount') ||
        columnName.toLowerCase().includes('balance') ||
        columnName.toLowerCase().includes('owed') ||
        columnName.toLowerCase().includes('owes')) &&
      value
    ) {
      formattedValue = formatCurrency(value)
    }

    return formattedValue
  }

  private mapClaimHeaders = () => {
    return (
      <>
        {this.renderSortedHeaderIfNotNewlyAdded('EOC/Legacy', 'EOCLegacy')}
        {this.renderSortedHeaderIfNotNewlyAdded(
          'Date of Service',
          'DateOfService',
          'right'
        )}
        {this.renderSortedHeaderIfNotNewlyAdded('Patient', 'PatientName')}
        {this.renderSortedHeaderIfNotNewlyAdded('Type', 'ClaimType')}
        {this.renderSortedHeaderIfNotNewlyAdded('In Global', 'InGlobal')}
        {this.renderSortedHeaderIfNotNewlyAdded('Component', 'ScheduleComponent')}
        {this.renderSortedHeaderIfNotNewlyAdded(
          'Billing Info',
          'BillingName',
          undefined,
          {
            minWidth: '250px',
          }
        )}
        {this.renderSortedHeaderIfNotNewlyAdded('Total Charges', 'TotalCharges', 'right')}
        {this.renderSortedHeaderIfNotNewlyAdded(
          'Total Contract',
          'TotalContract',
          'right'
        )}
      </>
    )
  }

  private mapDocumentHeaders = () => {
    return <>{this.renderSortedHeaderIfNotNewlyAdded('Document Name', 'FileName')}</>
  }

  private mapQuoteHeaders = () => {
    return (
      <>
        {this.renderSortedHeaderIfNotNewlyAdded('EOC/Legacy', 'EOCLegacy')}
        {this.renderSortedHeaderIfNotNewlyAdded('Quote Type', 'QuoteType')}
        {this.renderSortedHeaderIfNotNewlyAdded('End Date', 'right')}
        {this.renderSortedHeaderIfNotNewlyAdded('Quote Status', 'QuoteStatus')}
        {this.renderSortedHeaderIfNotNewlyAdded('Patient', 'PatientName')}
        {this.renderSortedHeaderIfNotNewlyAdded(
          'Insurance Company',
          'InsuranceCompanyName'
        )}
        {this.renderSortedHeaderIfNotNewlyAdded('EOC Status', 'EOCStatus')}
        {this.renderSortedHeaderIfNotNewlyAdded('Quoted $', 'QuoteTotal', 'right')}
        {this.renderSortedHeaderIfNotNewlyAdded(
          'Reconciled $',
          'ReconciledTotal',
          'right'
        )}
      </>
    )
  }

  private mapBilloutHeaders = () => {
    return (
      <>
        {this.renderSortedHeaderIfNotNewlyAdded('Days in Inbox', 'DaysInInbox', 'right')}
        {this.renderSortedHeaderIfNotNewlyAdded('EOC/Legacy', 'EOCLegacy')}
        {this.renderSortedHeaderIfNotNewlyAdded('Patient', 'PatientName')}
        {this.renderSortedHeaderIfNotNewlyAdded('DOS', 'DateOfService', 'right')}
        {this.renderSortedHeaderIfNotNewlyAdded('Provider Group', 'ProviderGroup')}
        {this.renderSortedHeaderIfNotNewlyAdded('EOC Status', 'EOCStatus')}
        {this.renderSortedHeaderIfNotNewlyAdded('Bundle Type', 'BundleType')}
        {this.renderSortedHeaderIfNotNewlyAdded('Ins Company', 'InsuranceCompanyName')}
        {this.renderSortedHeaderIfNotNewlyAdded('Amt Billed', 'AmountBilled', 'right')}
        {this.renderSortedHeaderIfNotNewlyAdded('Balance Due', 'BalanceDue', 'right')}
      </>
    )
  }

  private mapNewDocumentHeaders = () => {
    return (
      <>
        <TableCell>Document Name</TableCell>
      </>
    )
  }

  private openNewlyAddedDocument = async (summary: IncomingDocumentSummary) => {
    this.props.rerouteToPath!(`/workflow/document/file/${summary.name}`)
  }

  private openDocumentItem = (associatedItemId: string) => {
    this.props.rerouteToPath!(
      `/workflow/document/${associatedItemId}/inbox/${this.state.inbox.id}`
    )
  }

  private openClaimItem = (associatedItemId: string) => {
    this.props.rerouteToPath!(
      `/workflow/claim/${associatedItemId}/inbox/${this.state.inbox.id}`
    )
  }

  private openQuoteItem = (associatedItemId: string) => {
    this.props.rerouteToPath!(
      `/workflow/quote/${associatedItemId}/inbox/${this.state.inbox.id}`
    )
  }

  private openBilloutItem = (associatedItemId: string) => {
    this.props.rerouteToPath!(
      `/workflow/billout/${associatedItemId}/inbox/${this.state.inbox.id}`
    )
  }

  private getInboxData = async () => {
    this.setState({ gridLoading: true })
    const { page, pageSize } = this.state
    const requestData = new SearchRequestBase()
    requestData.page = page
    requestData.pageSize = pageSize
    if (this.state.inbox?.id == -1) {
      await getAllIncomingDocuments(requestData).then((response: any) => {
        this.setState({
          gridData: response.items,
          pageCount: response.totalPages,
          totalItems: response.totalItems,
          gridLoading: false,
        })
        if (response.totalItems > 0) {
          this.setPaginationOffsetData()
        }
      })
    } else {
      await getAllInboxItems(
        requestData,
        this.state.inbox.id.toString(),
        `${this.state.order}(${this.state.orderBy})`
      ).then((response: any) => {
        this.setState({
          dynamicColumns: response.columnNames,
          gridData: response.items,
          pageCount: response.totalPages,
          totalItems: response.totalItems,
          gridLoading: false,
        })
        if (response.totalItems > 0) {
          this.setPaginationOffsetData()
        }
      })
    }
  }

  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () => this.getInboxData())
  }

  private setSelectedType = (event: ChangeEvent<HTMLSelectElement>) => {
    this.props.setSelectedInboxType!(
      event.target.selectedOptions[0].value as InboxType | undefined
    )
    this.props.setSelectedInbox!(undefined)
    var inboxSelect = document.getElementById('actionSelect') as HTMLSelectElement

    if (inboxSelect != undefined) {
      inboxSelect.selectedIndex = 0
      inboxSelect.options[0].disabled = false
    }

    this.setState(
      {
        selectedType: event.target.selectedOptions[0].value,
        gridLoading: true,
        dynamicColumns: new Array<string>(),
        gridData: new pagedList().items as
          | DocumentInboxItemSummary[]
          | IncomingDocumentSummary[]
          | ClaimInboxItemSummary[]
          | QuoteInboxItemSummary[]
          | BilloutInboxItemSummary[]
          | { [key: string]: any }[],
        page: 1,
        pageCount: 0,
        firstRecordIndex: 0,
        lastRecordIndex: 0,
        totalItems: 0,
        selectedItemType: '',
        inbox: undefined as unknown as InboxSummary,
        order: 'asc',
        orderBy: '',
      },
      () => this.loadInboxes()
    )
  }

  private mapInboxHeaders = () => {
    if (this.state.inbox && !this.state.inbox.inboxType.includes('Dynamic')) {
      return (
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {this.state.inbox.itemType == InboxType.Quote && <TableCell></TableCell>}
            {this.renderSortedHeaderIfNotNewlyAdded('Priority', 'Priority')}
            {this.renderSortedHeaderIfNotNewlyAdded('Entry Date', 'EntryDate', 'right')}
            {this.state.inbox?.id == -1 && (
              <TableCell align="right">Entry Date</TableCell>
            )}
            {this.state.selectedItemType == InboxType.Claim && this.mapClaimHeaders()}
            {this.state.selectedItemType == InboxType.Document &&
              this.mapDocumentHeaders()}
            {this.state.selectedItemType == InboxType.Quote && this.mapQuoteHeaders()}
            {this.state.selectedItemType == InboxType.Billout && this.mapBilloutHeaders()}
            {this.state.inbox?.id == -1 && this.mapNewDocumentHeaders()}
            {this.renderSortedHeaderIfNotNewlyAdded('Assigned To', 'AssignedUser')}
            {this.renderSortedHeaderIfNotNewlyAdded('Locked By', 'LockedBy')}
            {this.renderSortedHeaderIfNotNewlyAdded('Pend', 'PendDate', 'right')}
          </TableRow>
        </TableHead>
      )
    } else {
      return (
        <TableHead>
          <TableRow>
            {this.state.dynamicColumns?.map((column, index) =>
              this.renderDynamicHeaderColumn(column, (index + 1).toString())
            )}
          </TableRow>
        </TableHead>
      )
    }
  }

  public render() {
    return (
      <>
        <BundleClaimsModal />
        <Helmet>
          <title>Inboxes</title>
        </Helmet>
        <ResponsiveAppBar title={'Inboxes'} pageIcon={<Inbox />} />
        {this.state.gridLoading && (
          <div
            style={{
              position: 'fixed',
              display: 'flex',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        <Grid
          container
          style={{ width: '100%', paddingLeft: '8px', paddingRight: '8px' }}
        >
          <Grid
            item
            style={{
              paddingTop: '4px',
              paddingLeft: '8px',
              paddingBottom: '8px',
              fontSize: '20px',
            }}
            xs={12}
          >
            Please select an inbox
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ paddingLeft: '8px', paddingBottom: '8px' }}
            spacing={2}
          >
            <Grid item xs={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel shrink={true} variant="outlined">
                  Inbox Type
                </InputLabel>
                <Select
                  variant="outlined"
                  input={<OutlinedInput notched labelWidth={78} />}
                  inputProps={{ label: true, notched: true }}
                  native={true}
                  fullWidth
                  onChange={this.setSelectedType}
                  id="type"
                  value={this.state.selectedType}
                >
                  <option value="">All</option>
                  <option value={InboxType.Billout}>Bill Out</option>
                  <option value={InboxType.Claim}>Claim</option>
                  <option value={InboxType.Document}>Document</option>
                  <option value={InboxType.Quote}>Quote</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel shrink={true} variant="outlined">
                  Inboxes
                </InputLabel>
                <Select
                  variant="outlined"
                  input={<OutlinedInput notched labelWidth={55} />}
                  inputProps={{ label: true, notched: true }}
                  native={true}
                  fullWidth
                  onChange={this.setSelectedInbox}
                  id="actionSelect"
                  value={this.state.inbox?.id}
                >
                  <option value="" disabled={this.props.savedInbox != undefined}></option>
                  {this.state.inboxes?.map((inbox) => (
                    <option value={inbox.id} disabled={inbox.inboxType == 'line'}>
                      {inbox.inboxName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {this.state.inbox && (
            <Grid item xs={12}>
              <Table aria-label="simple table">
                {this.mapInboxHeaders()}
                <TableBody>{this.mapGridData()}</TableBody>
              </Table>
              <div className="pagination-row">
                {this.paginationInfo()}
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  forcePage={this.state.page - 1}
                  onPageChange={this.handlePageChange}
                  pageCount={this.state.pageCount}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  //@ts-ignore
                  renderOnZeroPageCount={null}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </>
    )
  }
}

const InjectedInboxes = inject<IStores, IInboxesProps, Partial<IInboxesProps>, any>(
  (stores: IStores) => ({
    billStatusFilter: stores.patientEdit.billStatusFilter,
    editBill: stores.patientEdit.enterClaimFromMenu,
    episodeOfCare: stores.patientEdit.selectedEpisodeOfCare,
    isLoading: stores.patientEdit.isLoading,
    setClaimsModalLoadFunction: stores.patientEdit.setClaimsModalLoad,
    scheduleId: stores.schedules.selectedScheduleId,
    rerouteToPath: stores.global.rerouteToPath,
    setSelectedInbox: stores.global.setSelectedInbox,
    savedInbox: stores.global.selectedInbox,
    savedOrder: stores.global.selectedInboxOrder,
    savedOrderBy: stores.global.selectedInboxOrderColumn,
    setSavedSort: stores.global.setSelectedInboxSort,
    openBillsDialog: stores.quotes.openBillsDialog,
    savedInboxType: stores.global.selectedInboxType,
    setSelectedInboxType: stores.global.setSelectedInboxType,
  })
)(Inboxes)

export default InjectedInboxes
