import Axios from 'axios'
import { pagedList } from '../viewModels/pagedList'
import { DropdownOption } from '../viewModels/DropdownOption'
import { ILocationSearchRequest } from '../viewModels/LocationSearchRequest'
import { IBillingLocationSearchRequest } from '../viewModels/BillingLocationSearchRequest'
import { AddressDto, LocationDto } from 'src/generated_client'
import { EOCPatientReferralSummaryResult } from 'src/viewModels/EOCPatientReferralSummaryResult'
import { ReconcileQuoteClaimDropdownOption } from 'src/viewModels/quotes/ReconcileQuoteClaimDropdownOption'
import { GlobalInvoiceCPTCodeDTO } from 'src/viewModels/quotes/GlobalInvoiceCPTCodeDTO'
import { Coordinates } from 'src/viewModels/Coordinates'
import { ReferralContactSummary } from 'src/viewModels/ReferralContactSummary'
import { PayProvidersTotalSummary } from 'src/viewModels/PayProvidersTotalSummary'
import { AdvancedSearchConfigurationDTO } from 'src/viewModels/AdvancedSearchConfigurationDTO'
import { IClaimLineItemLookupRequest } from 'src/viewModels/ClaimLineItemLookupRequest'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function searchProviderLocations(
  data: ILocationSearchRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/lookup/provider-locations`,
    data
  )

  return response.data
}

export async function GetScheduledOptionsAsync(
  eocId: string
): Promise<Array<DropdownOption>> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/episode-of-care/${eocId}/schedule-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data
}

export async function GetPhysicianOptionsAsync(
  providerLocationId: string
): Promise<Array<DropdownOption>> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/locations/${providerLocationId}/physician-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data
}

export async function ScheduleLocationsOptions(
  id: string
): Promise<Array<DropdownOption>> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/schedule/${id}/location-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data
}

export async function searchProviderBillingLocations(
  data: IBillingLocationSearchRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/lookup/provider-billing-locations`,
    data
  )

  return response.data
}

export async function GetLocationById(locationId: string): Promise<LocationDto> {
  const response = await Axios.get<LocationDto>(`${base_url}/api/locations/${locationId}`)
  return response.data
}

export async function GetEOCPatientReferralInfo(
  eocNumber?: number
): Promise<EOCPatientReferralSummaryResult> {
  const response = await Axios.get<EOCPatientReferralSummaryResult>(
    `${base_url}/api/Lookup/patient-referral-info/${eocNumber}`
  )
  return response.data
}

export async function GetReconcileQuoteClaimOptions(
  quoteId: string,
  type: string
): Promise<Array<ReconcileQuoteClaimDropdownOption>> {
  const response = await Axios.get<Array<ReconcileQuoteClaimDropdownOption>>(
    `${base_url}/api/lookup/quote/${quoteId}/claim-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      params: { type },
    }
  )

  return response.data
}

export async function GetReconcileQuoteClaimLineOptions(
  claimId: string
): Promise<Array<ReconcileQuoteClaimDropdownOption>> {
  const response = await Axios.get<Array<ReconcileQuoteClaimDropdownOption>>(
    `${base_url}/api/lookup/claim/${claimId}/claim-line-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function GetReceivePaymentInsuranceOptions(): Promise<
  Array<DropdownOption>
> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/receive-payment-insurance-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function GetCptDescriptions(
  req: IQuoteDTO
): Promise<GlobalInvoiceCPTCodeDTO[]> {
  const response = await Axios.post<GlobalInvoiceCPTCodeDTO[]>(
    `${base_url}/api/lookup/cpt-descriptions`,
    req
  )

  return response.data
}

export async function GetAllInsuranceCompanyOptions(): Promise<Array<DropdownOption>> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/insurance-company-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function GetBillOutInsuranceCompanyOptions(): Promise<
  Array<DropdownOption>
> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/bill-out-insurance-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function GetInsuranceCompanyContactOptions(
  companyId: string
): Promise<Array<DropdownOption>> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/insurance-company/${companyId}/insurance-contact-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function GetNCMCompanyOptions(): Promise<Array<DropdownOption>> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/ncm-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function GetNCMContactOptions(
  companyId: string
): Promise<Array<DropdownOption>> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/nurse-case-manager/${companyId}/ncm-contact-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function GetCoordinates(req: AddressDto): Promise<Coordinates> {
  const response = await Axios.post<Coordinates>(
    `${base_url}/api/lookup/address-coordinates`,
    req
  )

  return response.data
}

export async function GetComponentOptions(
  overrideActive: boolean,
  includeBlank: boolean
): Promise<Array<DropdownOption>> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/components`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      params: { overrideActive, includeBlank },
    }
  )

  return response.data
}

export async function GetScheduleComponentOptions(
  overrideActive: boolean,
  includeInactive?: boolean
): Promise<Array<DropdownOption>> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/schedule-components`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      params: { overrideActive, includeInactive },
    }
  )

  return response.data
}

export async function GetSurgeryPayProviderLocationOptions(): Promise<
  Array<DropdownOption>
> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/surgery-pay-provider-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function GetReferralContactSummary(
  referralId: string
): Promise<ReferralContactSummary> {
  const response = await Axios.get<ReferralContactSummary>(
    `${base_url}/api/lookup/referral-contact/${referralId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function GetProviderGroupOptions(): Promise<Array<DropdownOption>> {
  const response = await Axios.get<Array<DropdownOption>>(
    `${base_url}/api/lookup/provider-group-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function GetPayProviderTotals(): Promise<PayProvidersTotalSummary> {
  const response = await Axios.get<PayProvidersTotalSummary>(
    `${base_url}/api/lookup/pay-provider-totals`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function GetAdvancedSearchOptions(): Promise<
  Array<AdvancedSearchConfigurationDTO>
> {
  const response = await Axios.get<Array<AdvancedSearchConfigurationDTO>>(
    `${base_url}/api/lookup/advanced-search-options`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}

export async function getPaidContractAmountHistory(
  data: IClaimLineItemLookupRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/lookup/paid-claim-line-amount-history`,
    data
  )

  return response.data
}

export async function getBilledOutAmountHistory(
  data: IClaimLineItemLookupRequest
): Promise<pagedList> {
  const response = await Axios.post<pagedList>(
    `${base_url}/api/lookup/billed-out-line-amount-history`,
    data
  )

  return response.data
}
