import {
  Assignment,
  AttachMoney,
  Edit,
  EventBusy,
  LocalHospital,
  NoteAdd,
  Visibility,
} from '@material-ui/icons'
import * as _ from 'lodash'
import { action, observable, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import moment from 'moment'
import { IColumnSetting } from '../../common/ESDataTable'
import { DefaultSchedule, ISchedule } from '../../Definitions'
import {
  Client,
  SimpleUserDto,
  SingleCaseAgreementTextDto,
  SwaggerResponse,
} from '../../generated_client'
import GlobalStore from '../../stores/GlobalStore'
import createPdfObjUrl from '../../utils/CreatePdfObjUrl'
import DataTableStore from '../DataTableStore'
import PatientEditStore from '../PatientEditStore/PatientEditStore'
import NotificationStore from '../NotificationStore'
import PatientsStore from '../PatientsStore/PatientsStore'
import ReferralsStore from '../ReferralsStore/ReferralsStore'
import { ScheduleLocationsOptions } from 'src/services/LookupService'
import { DropdownOption } from 'src/viewModels/DropdownOption'
import {
  createAuthorizationLog,
  createContractLog,
} from 'src/services/AuthorizationLogService'
import { AuthorizationLogDTO } from 'src/viewModels/AuthorizationLogDTO'
import { DocumentType } from 'src/models/enums/DocumentType.enum'
import { SetRescheduled } from 'src/services/ScheduleService'
import { ClaimsAcquisitionSummaryResult } from 'src/viewModels/ClaimsAcquisitionSummaryResult'
import { IScheduleSummmaryResult } from 'src/viewModels/ScheduleSummaryResult'
import CustomGridDate from 'src/common/CustomGridComponents/CustomGridDate'
import React from 'react'
import CustomGridTime from 'src/common/CustomGridComponents/CustomGridTime'
import { insertSchedule, updateSchedule } from 'src/services/EpisodeOfCareService'
import { IScheduleDto, ScheduleDto } from 'src/viewModels/ScheduleDto'

const ColumnSettingsDto = 'ScheduleDto'

export default class SchedulesStore {
  @observable
  public isLoading: boolean = false
  @observable
  public isModalOpen: boolean
  @observable
  public isSingleCaseAgreementDialogOpen: boolean
  @observable
  public schedules: ISchedule[]
  @observable
  public selectedSchedule?: ISchedule
  @observable
  public isProviderLookupDialogOpen: boolean
  @observable
  public removeEllipseColumn: boolean
  @observable
  public isProviderEditDialogOpen: boolean
  @observable
  public isClaimProviderLookupDialogOpen: boolean
  public dataTableStore: DataTableStore<IScheduleDto, ISchedule>
  @observable
  public scheduleItems: Array<DropdownOption>
  @observable
  public isAuthorizationModalOpen: boolean
  @observable
  public authorizationType: DocumentType | undefined
  @observable
  public isViewClaimsAcqLogModalOpen: boolean
  @observable
  public selectedScheduleId: string | undefined
  @observable
  public isAddClaimsAcqLogModalOpen: boolean = false
  @observable
  public selectedClaimsAcquisition: ClaimsAcquisitionSummaryResult | undefined
  @observable
  selectedPatientInfo: string
  @observable
  public eocToDashboard: boolean
  @observable
  public eocToQuotes: boolean
  @observable
  public updateParentStateAfterModal?: () => void

  constructor(
    private globalStore: GlobalStore,
    private PatientEditStore: PatientEditStore,
    private patientStore: PatientsStore,
    private referralsStore: ReferralsStore,
    private routerStore: RouterStore,
    private client: Client,
    private notificationStore: NotificationStore
  ) {
    this.dataTableStore = new DataTableStore<IScheduleDto, ISchedule>(
      globalStore,
      ({ filter, page, orderBy }) =>
        client.getAllSchedules(
          undefined,
          PatientEditStore.selectedEpisodeOfCare!.id,
          filter,
          page,
          1,
          orderBy,
          true
        ),
      (schedule) => this.setupSchedules(schedule)
    )
  }

  @action.bound
  public buildClaimsAcqMenuItems = (acq: ClaimsAcquisitionSummaryResult) => {
    let menuItems: any[] = []

    menuItems.push({
      color: '#29348F',
      icon: NoteAdd,
      name: 'Add Claims Acquisition Log',
      onClick: () => this.createAcqLog(acq),
    })

    menuItems.push({
      color: '#94D33D',
      icon: AttachMoney,
      name: 'View Claims',
      onClick: () => {
        this.setSelectedPatient(acq)
        this.PatientEditStore.openViewClaimsModal(acq.episodeOfCareId, acq.scheduleId)
      },
    })

    menuItems.push({
      color: '#94D33D',
      icon: Assignment,
      name: 'View Episode of Care',
      onClick: () => {
        sessionStorage.setItem('patientTab', '0')
        sessionStorage.setItem('eocTab', '1')
        window.open(
          `/patients/patient/${acq.patientId}/referral/${acq.referralId}/episodeOfCare/${acq.episodeOfCareId}`,
          '_blank'
        )
      },
    })

    if (acq.providerId) {
      menuItems.push({
        color: '#29348F',
        icon: LocalHospital,
        name: 'View Provider Group',
        onClick: () => {
          window.open(`/providers/${acq.providerId}`, '_blank')
        },
      })
    } else {
      menuItems.push({
        disabled: true,
        icon: LocalHospital,
        name: 'View Provider Group',
      })
    }

    return menuItems
  }

  @action.bound
  public setSelectedPatient(acq: ClaimsAcquisitionSummaryResult) {
    this.selectedScheduleId = acq.scheduleId
    var text =
      acq.patientName +
      ' (' +
      (acq.dateOfBirth ? moment(acq.dateOfBirth).format('MM/DD/YYYY') : 'Unknown') +
      ')'
    this.selectedPatientInfo = text
  }

  @action.bound
  public createAcqLog(acq: ClaimsAcquisitionSummaryResult) {
    this.selectedClaimsAcquisition = acq
    this.selectedScheduleId = acq.scheduleId
    this.isAddClaimsAcqLogModalOpen = true
  }

  @action.bound
  public closeAddAcqLogModal(reloadMain?: () => void) {
    this.selectedScheduleId = undefined
    this.isAddClaimsAcqLogModalOpen = false
    this.selectedClaimsAcquisition = undefined

    if (reloadMain != undefined) {
      reloadMain()
    }
  }

  @action.bound
  public openViewClaimsAcqLogModal(acq: ClaimsAcquisitionSummaryResult) {
    this.selectedClaimsAcquisition = acq
    this.selectedScheduleId = acq.scheduleId
    this.isViewClaimsAcqLogModalOpen = true
  }

  @action.bound
  public closeViewClaimsAcqLogModal() {
    this.selectedClaimsAcquisition = undefined
    this.selectedScheduleId = undefined
    this.isViewClaimsAcqLogModalOpen = false
  }

  @action.bound
  public openAuthorizationModal() {
    this.isAuthorizationModalOpen = true
  }

  @action.bound
  public openDMEAuthorizationModal() {
    this.isAuthorizationModalOpen = true
    this.authorizationType = DocumentType.DME
  }

  @action.bound
  public openPTAuthorizationModal() {
    this.isAuthorizationModalOpen = true
    this.authorizationType = DocumentType.PhysicalTherapy
  }

  @action.bound
  public openOTAuthorizationModal() {
    this.isAuthorizationModalOpen = true
    this.authorizationType = DocumentType.OccupationalTherapy
  }

  @action.bound
  public openInHomeAuthorizationModal() {
    this.isAuthorizationModalOpen = true
    this.authorizationType = DocumentType.InHome
  }

  @action.bound
  public openInpatientAuthorizationModal() {
    this.isAuthorizationModalOpen = true
    this.authorizationType = DocumentType.InpatientRehab
  }

  @action.bound
  public openWCEAuthorizationModal() {
    this.isAuthorizationModalOpen = true
    this.authorizationType = DocumentType.WorkConditioning
  }

  @action.bound
  public openFCEAuthorizationModal() {
    this.isAuthorizationModalOpen = true
    this.authorizationType = DocumentType.FCE
  }

  @action.bound
  public async createSurgeryFacilityAuthorization() {
    var auth = new AuthorizationLogDTO(
      '',
      this.selectedSchedule!.id,
      DocumentType.SurgeryFacility
    )

    return createAuthorizationLog(auth)
  }

  @action.bound
  public async createPatientInjectionContract() {
    var auth = new AuthorizationLogDTO(
      '',
      this.selectedSchedule!.id,
      DocumentType.PatientInjectionContract,
      'Agreement for Surgical Injection Services'
    )

    return createContractLog(auth)
  }

  @action.bound
  public async createPatientSurgeryContract() {
    var auth = new AuthorizationLogDTO(
      '',
      this.selectedSchedule!.id,
      DocumentType.PatientSurgicalContract,
      'Agreement for Surgical Services'
    )

    return createContractLog(auth)
  }

  @action.bound
  public async createInitialEvalAuthorization() {
    var auth = new AuthorizationLogDTO(
      '',
      this.selectedSchedule!.id,
      DocumentType.InitialEval
    )

    return createAuthorizationLog(auth)
  }

  @action.bound
  public async createTakeoverOfCareAuthorization() {
    var auth = new AuthorizationLogDTO(
      '',
      this.selectedSchedule!.id,
      DocumentType.TakeoverOfCare
    )

    return createAuthorizationLog(auth)
  }

  @action.bound
  public closeAuthorizationModal() {
    this.isAuthorizationModalOpen = false
    this.authorizationType = undefined
  }

  @action.bound
  public closeDialog() {
    this.isModalOpen = false
    this.selectedSchedule = undefined
  }

  @action.bound
  public closeEventDialog() {
    this.isModalOpen = false
  }

  @action.bound
  public closeSingleCaseAgreementDialog() {
    this.isSingleCaseAgreementDialogOpen = false
  }

  @action.bound
  public setSelectedSchedule(schedule: ISchedule) {
    this.selectedSchedule = schedule
  }

  @action.bound
  public getColumnSettingsAndSchedules(noEllipseMenu: boolean = false) {
    if (!this.globalStore.user) {
      return Promise.resolve()
    }

    this.isLoading = true
    this.removeEllipseColumn = noEllipseMenu

    return this.client
      .apiColumnSettingsByUserIdByTypeGet(this.globalStore.user!.id, ColumnSettingsDto)
      .then((data) => {
        runInAction(() => {
          this.dataTableStore.setColumns(data.result)
          const sortedColumn = _.find(this.dataTableStore.columns, (col) => !!col.sort)
          if (sortedColumn) {
            this.dataTableStore.setTableOrderBy(sortedColumn.sort)
          }
          this.dataTableStore.loadData()
          this.addCustomColumn()
        })
      })
      .catch(() => {})
      .finally(() => runInAction(() => (this.isLoading = false)))
  }

  private addCustomColumn() {
    this.dataTableStore.columns.splice(
      0,
      0,
      {
        columnName: 'Date',
        fieldName: 'appointmentDateColumnCell',
        isVisible: true,
        type: 'React.Component',
      } as IColumnSetting,
      {
        columnName: 'Time',
        fieldName: 'appointmentTimeColumnCell',
        isVisible: true,
        type: 'React.Component',
      } as IColumnSetting
    )
  }

  @action.bound
  public getScheduleById(id: string) {
    this.isLoading = true
    return this.client
      .getScheduleById(id)
      .then((resp: SwaggerResponse<IScheduleDto>) =>
        runInAction(() => {
          this.selectedSchedule = this.addDefaultFields(resp.result)
          this.selectedSchedule.id = id
          this.selectedSchedule.isNew = false
        })
      )
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  public openDialogWithSchedule(schedule: ISchedule | undefined) {
    this.isModalOpen = true
    this.selectedSchedule = schedule
  }

  @action.bound
  public openSingleCaseAgreementDialog() {
    this.isSingleCaseAgreementDialogOpen = true
  }

  @action.bound
  public reschedule(schedule: IScheduleSummmaryResult) {
    this.isLoading = true
    return SetRescheduled(schedule.scheduleId!)
      .then(() => {
        if (this.updateParentStateAfterModal) {
          this.updateParentStateAfterModal()
        }
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public setParentUpdateFunction(parentFunction: () => void) {
    this.updateParentStateAfterModal = parentFunction
  }

  private setupSchedules = (schedules: IScheduleDto[]) => {
    return schedules.map((x) => this.customizeScheduleRecord(this.addDefaultFields(x)))
  }
  private resetUIState(shouldClearFilter: boolean) {
    if (shouldClearFilter) {
      this.dataTableStore.clearFilter()
    }
    this.dataTableStore.loadData()
    this.closeDialog()
  }

  @action.bound
  public saveSchedule(
    schedule: ISchedule,
    eocId: string,
    scheduleId: string,
    resetUiState: boolean = true
  ) {
    this.isLoading = true
    let promise
    const scheduleDto = new ScheduleDto(schedule)
    if (schedule.isNew) {
      promise = insertSchedule(eocId, scheduleDto)
    } else {
      promise = updateSchedule(eocId, scheduleId, scheduleDto)
    }
    return promise
      .then((result) => {
        if (resetUiState) {
          this.resetUIState(schedule.isNew)
        }
        return result.id
      })
      .catch(() => {})
  }

  @action.bound
  public addProviderLocation(scheduleId: string, locationId: string): Promise<void> {
    this.isLoading = true
    let promise
    promise = this.client.addScheduleProviderLocation(scheduleId, locationId)
    return promise
      .then(() => {})
      .catch(() => {})
      .finally(() =>
        runInAction(() => {
          this.isLoading = false
        })
      )
  }

  @action.bound addSchedule(schedule: ISchedule) {
    const referralId = this.referralsStore.selectedReferral
      ? this.referralsStore.selectedReferral.id
      : ''
    const patientId = this.patientStore.selectedPatient
      ? this.patientStore.selectedPatient.id
      : ''
    const episodeOfCareId = this.PatientEditStore.selectedEpisodeOfCare
      ? this.PatientEditStore.selectedEpisodeOfCare.id
      : ''

    if (this.globalStore.returnRoutePath === '/') {
      this.eocToDashboard = true
    } else if (this.globalStore.returnRoutePath === '/quotes') {
      this.eocToQuotes = true
    }
    this.globalStore.setReturn!(
      `/patients/patient/${patientId}/referral/${referralId}/episodeOfCare/${episodeOfCareId}`
    ).then(() => {
      this.setSelectedSchedule(schedule)
    })
  }

  @action.bound
  public editSchedule(schedule: IScheduleSummmaryResult) {
    const referralId = this.referralsStore.selectedReferral
      ? this.referralsStore.selectedReferral.id
      : ''
    const patientId = this.patientStore.selectedPatient
      ? this.patientStore.selectedPatient.id
      : ''
    const episodeOfCareId = this.PatientEditStore.selectedEpisodeOfCare
      ? this.PatientEditStore.selectedEpisodeOfCare.id
      : ''

    if (this.globalStore.returnRoutePath === '/') {
      this.eocToDashboard = true
    } else if (this.globalStore.returnRoutePath === '/quotes') {
      this.eocToQuotes = true
    }
    this.globalStore.setReturn!(
      `/patients/patient/${patientId}/referral/${referralId}/episodeOfCare/${episodeOfCareId}`
    ).then(() => {
      this.routerStore.push(
        `/patients/patient/${patientId}/referral/${referralId}/episodeOfCare/${episodeOfCareId}/schedule/${schedule.scheduleId}`
      )
    })
  }

  @action.bound
  public buildScheduleMenuItems = (schedule: IScheduleSummmaryResult) => {
    let menuItems: any[] = []

    if (!this.removeEllipseColumn) {
      menuItems.push({
        icon:
          this.globalStore.currentAppOrganization!.id !== schedule.organizationId ||
          schedule.canEdit
            ? Edit
            : Visibility,
        name:
          this.globalStore.currentAppOrganization!.id !== schedule.organizationId ||
          schedule.canEdit
            ? 'Edit'
            : 'View',
        onClick: () => this.editSchedule(schedule),
      })
    }
    if (!schedule.rescheduled && schedule.canEdit) {
      menuItems.push({
        color: '#29348F',
        icon: EventBusy,
        isConfirm: false,
        name: 'CXL/Resched',
        onClick: () => this.reschedule(schedule),
      })
    }

    return menuItems
  }

  @action.bound
  public resetEOC() {
    this.eocToDashboard = false
    this.eocToQuotes = false
  }

  private addDefaultFields = (schedule: IScheduleDto): ISchedule => {
    return { ...DefaultSchedule(), ...schedule }
  }

  private customizeScheduleRecord = (schedule: ISchedule) => {
    schedule.appointmentDateColumnCell = (
      <CustomGridDate date={schedule.appointmentDate} />
    )
    schedule.appointmentTimeColumnCell = (
      <CustomGridTime
        isTimeApplicable={!schedule.isSimpleEvent}
        time={schedule.appointmentTime}
      />
    )

    return schedule
  }

  @action.bound
  public openClaimProviderLookupDialog() {
    this.isClaimProviderLookupDialogOpen = true
  }

  @action.bound
  public closeClaimProviderLookupDialog() {
    this.isClaimProviderLookupDialogOpen = false
  }

  @action.bound
  public openProviderLookupDialog() {
    this.isProviderLookupDialogOpen = true
  }

  @action.bound
  public closeProviderLookupDialog() {
    this.isProviderLookupDialogOpen = false
  }

  @action.bound
  public openProviderEditDialog() {
    this.isProviderEditDialogOpen = true
  }

  @action.bound
  public closeProviderEditDialog() {
    this.isProviderEditDialogOpen = false
  }

  @action.bound
  public createAppointmentConfirmation(scheduleId: string) {
    this.isLoading = true
    this.notificationStore.sendNotification('200', 'Please Wait...', {
      variant: 'info',
      persist: false,
    })
    return this.client
      .createAppointmentConfirmation(scheduleId)
      .then((resp: SwaggerResponse<string>) => {
        runInAction(() => {
          window.open(createPdfObjUrl(resp.result), '_blank')
        })
      })
      .catch(() => {
        runInAction(() => {
          this.notificationStore.sendNotification(
            '500',
            'Error! Something went wrong. Please try again later.',
            { variant: 'error' }
          )
        })
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public createAuthorizationPdf(scheduleId: string) {
    this.isLoading = true
    this.notificationStore.sendNotification('200', 'Please Wait...', {
      variant: 'info',
      persist: false,
    })
    return this.client
      .createAuthorizationPdf(scheduleId)
      .then((resp: SwaggerResponse<string>) => {
        runInAction(() => {
          window.open(createPdfObjUrl(resp.result), '_blank')
        })
      })
      .catch(() => {
        runInAction(() => {
          this.notificationStore.sendNotification(
            '500',
            'Error! Something went wrong. Please try again later.',
            { variant: 'error' }
          )
        })
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public createResultsPdf(scheduleId: string) {
    this.isLoading = true
    this.notificationStore.sendNotification('200', 'Please Wait...', {
      variant: 'info',
      persist: false,
    })
    return this.client
      .createResultsPdf(scheduleId)
      .then((resp: SwaggerResponse<string>) => {
        runInAction(() => {
          window.open(createPdfObjUrl(resp.result), '_blank')
        })
      })
      .catch(() => {
        runInAction(() => {
          this.notificationStore.sendNotification(
            '500',
            'Error! Something went wrong. Please try again later.',
            { variant: 'error' }
          )
        })
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public createSingleCaseAgreementPdf(
    scheduleId: string,
    singleCaseAgreementTextDto: SingleCaseAgreementTextDto
  ) {
    this.isLoading = true
    this.notificationStore.sendNotification('200', 'Please Wait...', {
      variant: 'info',
      persist: false,
    })
    return this.client
      .createSingleCaseAgreementPdf(scheduleId, singleCaseAgreementTextDto)
      .then((resp: SwaggerResponse<string>) => {
        runInAction(() => {
          window.open(createPdfObjUrl(resp.result), '_blank')
        })
      })
      .catch(() => {
        runInAction(() => {
          this.notificationStore.sendNotification(
            '500',
            'Error! Something went wrong. Please try again later.',
            { variant: 'error' }
          )
        })
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  @action.bound
  public getNcmAdjusterEmailLink(schedule: ISchedule) {
    const nurseCaseManagerContact = `${
      schedule.episodeOfCare!.referral!.nurseCaseManagerContact
        ? schedule.episodeOfCare!.referral!.nurseCaseManagerContact.email
          ? schedule.episodeOfCare!.referral!.nurseCaseManagerContact.email
          : ''
        : ''
    }`
    const insuranceContact = `${
      schedule.episodeOfCare!.referral!.insuranceCompanyContact
        ? schedule.episodeOfCare!.referral!.insuranceCompanyContact.email
          ? schedule.episodeOfCare!.referral!.insuranceCompanyContact.email
          : ''
        : ''
    }`
    const toEmails = [nurseCaseManagerContact, insuranceContact]
      .filter((x) => x !== '' && x !== undefined)
      .toString()
    const careCoordinator = `${
      schedule.episodeOfCare!.careCoordinator
        ? schedule.episodeOfCare!.careCoordinator.email
          ? schedule.episodeOfCare!.careCoordinator.email
          : ''
        : ''
    }`
    const subject = `ODX Appointment Confirmation for ${
      schedule.patient ? schedule.patient.name : ''
    } Claim Number - ${schedule.episodeOfCare!.referral!.claimNumber}`
    return `mailto:${toEmails}?cc=${careCoordinator}&subject=${subject}&body=${encodeURIComponent(
      `Bringing quality and cost full circle\n\n${this.getBillingInuiriesAddress()}\n\nAppointment Confirmation\n\n${this.getAppointmentConfirmationText(
        schedule
      )}`
    )}`
  }

  @action.bound
  public getPatientEmailLink(schedule: ISchedule) {
    const patientContact = `${
      schedule.patient
        ? schedule.patient.emailAddress
          ? schedule.patient.emailAddress
          : ''
        : ''
    }`

    const subject = `ODX Appointment Confirmation for ${
      schedule.patient ? schedule.patient.name : ''
    } Claim Number - ${schedule.episodeOfCare!.referral!.claimNumber}`
    return `mailto:${patientContact}?subject=${subject}&body=${encodeURIComponent(
      `Bringing quality and cost full circle\n\n${this.getBillingInuiriesAddress()}\n\nAppointment Confirmation\n\n${this.getPatientEmailText(
        schedule
      )}`
    )}`
  }

  @action.bound
  public getTestResultsEmailLink(schedule: ISchedule) {
    const nurseCaseManagerContact = `${
      schedule.episodeOfCare!.referral!.nurseCaseManagerContact
        ? schedule.episodeOfCare!.referral!.nurseCaseManagerContact.email
          ? schedule.episodeOfCare!.referral!.nurseCaseManagerContact.email
          : ''
        : ''
    }`
    const insuranceContact = `${
      schedule.episodeOfCare!.referral!.insuranceCompanyContact
        ? schedule.episodeOfCare!.referral!.insuranceCompanyContact.email
          ? schedule.episodeOfCare!.referral!.insuranceCompanyContact.email
          : ''
        : ''
    }`
    const toEmails = [nurseCaseManagerContact, insuranceContact]
      .filter((x) => x !== '' && x !== undefined)
      .toString()
    const careCoordinator = `${
      schedule.episodeOfCare!.careCoordinator
        ? schedule.episodeOfCare!.careCoordinator.email
          ? schedule.episodeOfCare!.careCoordinator.email
          : ''
        : ''
    }`
    const patientContact = `${
      this.globalStore.currentAppOrganization!.distributeTestResultsCC
        ? this.globalStore.currentAppOrganization!.distributeTestResultsCC!.email
        : ''
    }`

    const ccEmails = [careCoordinator, patientContact]
      .filter((x) => x !== '' && x !== undefined)
      .toString()

    const subject = `${
      schedule.patient ? schedule.patient.name : ''
    } Results Claim Number - ${schedule.episodeOfCare!.referral!.claimNumber}`
    return `mailto:${toEmails}?cc=${ccEmails}&subject=${subject}&body=${encodeURIComponent(
      `${this.getTestResultsText(schedule)}`
    )}`
  }

  public getBillingInuiriesAddress() {
    if (
      this.globalStore.currentAppOrganization &&
      this.globalStore.currentAppOrganization!.billingInquiries
    ) {
      return `${this.globalStore.currentAppOrganization!.billingInquiries.website}\n${
        this.globalStore.currentAppOrganization!.billingInquiries.phoneNumber
      }\nFax: ${this.globalStore.currentAppOrganization!.billingInquiries.faxNumber}\n${
        this.globalStore.currentAppOrganization!.billingInquiries.line1
      }\n${
        this.globalStore.currentAppOrganization!.billingInquiries.line2
          ? this.globalStore.currentAppOrganization!.billingInquiries.line2 + '\n'
          : ''
      }${this.globalStore.currentAppOrganization!.billingInquiries.city}, ${
        this.globalStore.currentAppOrganization!.billingInquiries.state
      } ${this.globalStore.currentAppOrganization!.billingInquiries.zip}`
    }
    return ''
  }

  public getPatientEmailText(schedule: ISchedule) {
    const primaryLocation = schedule!.scheduleProviderLocations!.filter(
      (x) => x.isPrimaryLocation
    )![0]!.location
    const nextApptDate =
      schedule.scheduleTests &&
      schedule.scheduleTests!.length > 0 &&
      schedule.scheduleTests![0]!.test
        ? schedule.scheduleTests![0]!.test.followUpDateTime
        : new Date()

    return `Name: ${schedule.patient!.name}
			DOB: ${moment(schedule.patient!.dateOfBirth).format('MM/DD/YYYY')}
			Date of Referral: ${moment(schedule.episodeOfCare!.referral!.referralDate!).format(
        'MM/DD/YYYY'
      )}
			Claim #: ${schedule.episodeOfCare!.referral!.claimNumber}
			Procedure: ${schedule
        .episodeOfCare!.tests!.map(
          (x) => `${x.procedure!.diagnostic} (${x.bodyPart!.name})`
        )
        .join(', ')}
			Provider: ${primaryLocation!.provider!.name} (${primaryLocation!.name})
			Address: ${
        primaryLocation && primaryLocation.physicalAddress
          ? primaryLocation.physicalAddress.line1
          : ''
      }
			Phone: ${
        primaryLocation && primaryLocation.physicalAddress
          ? primaryLocation.physicalAddress.phoneNumber
          : ''
      }
			Fax: ${
        primaryLocation && primaryLocation.physicalAddress
          ? primaryLocation.physicalAddress.faxNumber
          : ''
      }
			DOS: ${moment(schedule.appointmentDate).format('MM/DD/YYYY')}
			Next Drs. Appt.: ${moment(nextApptDate).format('MM/DD/YYYY')}
			Confirmation: ${schedule.appointmentConfirmationComment}\n
			Objective Diagnostics, LLC (ODx) has been engaged to schedule and bill for the following service. By accepting this appointment, the appropriate and authorizing payor (insurance company, TPA and/or employer) agree to pay all ODx bills pertaining to this service IN FULL within 15 days of receiving our invoice. No additional discounts or fee schedule pricing is to be taken as negotiated discounts are reflected in our invoice. This is a binding contract.`
  }

  public getAppointmentConfirmationText(schedule: ISchedule) {
    const primaryLocation = schedule!.scheduleProviderLocations!.filter(
      (x) => x.isPrimaryLocation
    )![0]!.location
    const nextApptDate =
      schedule.scheduleTests &&
      schedule.scheduleTests!.length > 0 &&
      schedule.scheduleTests![0]!.test
        ? schedule.scheduleTests![0]!.test.followUpDateTime
        : new Date()
    return `Name: ${schedule.patient!.name}
			DOB: ${moment(schedule.patient!.dateOfBirth).format('MM/DD/YYYY')}
			Date of Referral: ${moment(schedule.episodeOfCare!.referral!.referralDate!).format(
        'MM/DD/YYYY'
      )}
			Claim #: ${schedule.episodeOfCare!.referral!.claimNumber}
			Procedure: ${schedule
        .episodeOfCare!.tests!.map(
          (x) => `${x.procedure!.diagnostic} (${x.bodyPart!.name})`
        )
        .join(', ')}
			Provider: ${primaryLocation!.provider!.name} (${primaryLocation!.name})
			Address: ${
        primaryLocation && primaryLocation.physicalAddress
          ? primaryLocation.physicalAddress.line1
          : ''
      }
			Phone: ${
        primaryLocation && primaryLocation.physicalAddress
          ? primaryLocation.physicalAddress.phoneNumber
          : ''
      }
			Fax: ${
        primaryLocation && primaryLocation.physicalAddress
          ? primaryLocation.physicalAddress.faxNumber
          : ''
      }
			DOS: ${moment(schedule.appointmentDate).format('MM/DD/YYYY')}
			Next Drs. Appt.: ${moment(nextApptDate).format('MM/DD/YYYY')}
			Confirmation: ${schedule.appointmentConfirmationComment}\n
			Thank you for the referral!\n\n
			Objective Diagnostics, LLC (ODx) has been engaged to schedule and bill for the following service. By accepting this appointment, the appropriate and authorizing payor (insurance company, TPA and/or employer) agree to pay all ODx bills pertaining to this service IN FULL within 15 days of receiving our invoice. No additional discounts or fee schedule pricing is to be taken as negotiated discounts are reflected in our invoice. This is a binding contract.`
  }

  public getTestResultsText(schedule: ISchedule) {
    const distributeTestResultsAdditionalInfo = this.globalStore.currentAppOrganization!
      .distributeTestResultsAdditionalInfo
      ? this.globalStore.currentAppOrganization!.distributeTestResultsAdditionalInfo
      : ({
          firstName: 'undefined',
          lastName: 'undefined',
          email: 'undefined',
          directNumber: 'undefined',
        } as SimpleUserDto)
    return `Please find attached test results of procedure ${schedule
      .episodeOfCare!.tests!.map(
        (x) => `${x.procedure!.diagnostic} (${x.bodyPart!.name})`
      )
      .join(', ')} for ${schedule.patient!.name}\n
As a reminder, Objective Surgical is here when you have a surgical need! We offer global bundled pricing for the entire episode of care. Savings are typically 40-60% of billed charges.\n
For additional information, contact ${distributeTestResultsAdditionalInfo!.firstName} ${
      distributeTestResultsAdditionalInfo!.lastName
    } at ${distributeTestResultsAdditionalInfo!.email} or ${
      distributeTestResultsAdditionalInfo!.directNumber
    }\n
Thank you,
${distributeTestResultsAdditionalInfo!.firstName}\n
${this.globalStore.currentAppOrganization!.name}\n
Bringing quality and cost full circle\n
${this.getBillingInuiriesAddress()}\n
We Stand behind this work. If you have any questions about this study, please contact our quality control department.`
  }

  @action.bound
  public async ScheduleLocationsOptions(id: string) {
    this.scheduleItems = await ScheduleLocationsOptions(
      this.selectedSchedule ? this.selectedSchedule.id : id
    )
  }

  @action.bound
  public async setSelectedScheduleById(id: string) {
    await this.getScheduleById(id)
    await this.ScheduleLocationsOptions(id)
  }
}
