import { Stethoscope } from 'mdi-material-ui'
import { inject } from 'mobx-react'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import ESDataTableWithHeader from '../common/ESDataTableWithHeader'
import IDataTableManager from '../common/IDataTableManager'
import ResponsiveAppBar from '../common/ResponsiveAppBar'
import { DefaultDxCode, IDxCode } from '../Definitions'
import { IStores } from '../Stores'
import DxCodeDialog from './DxCodeDialog'

interface IDxCodesProps {
  dataTableManager?: IDataTableManager<IDxCode>
  disableDxCode?: (dxCode: IDxCode) => void
  getColumnSettingsAndDxCodes?: () => void
  isModalOpen?: boolean
  openDialogWithDxCode?: (dxCode: IDxCode) => void
  recoverDxCode?: (dxCode: IDxCode) => void
}

@inject((stores: IStores) => ({
  dataTableManager: stores.dxCodes.dataTableStore,
  disableDxCode: stores.dxCodes.disableDxCode,
  getColumnSettingsAndDxCodes: stores.dxCodes.getColumnSettingsAndDxCodes,
  isModalOpen: stores.dxCodes.isModalOpen,
  openDialogWithDxCode: stores.dxCodes.openDialogWithDxCode,
  recoverDxCode: stores.dxCodes.recoverDxCode,
}))
class DxCodes extends React.Component<IDxCodesProps> {
  public componentDidMount() {
    this.props.getColumnSettingsAndDxCodes!()
  }

  public render() {
    const dxCodeDialog = this.props.isModalOpen && <DxCodeDialog />
    return (
      <>
        <Helmet>
          <title>DX Codes</title>
        </Helmet>
        <ResponsiveAppBar title="DX Codes" pageIcon={<Stethoscope />} />
        <ESDataTableWithHeader
          dataTableManager={this.props.dataTableManager!}
          enableReorderColumns={true}
          enableSearch={true}
          enableShowInactives={true}
          enableToggleColumns={true}
          addButtonOnClick={this.openDialog(DefaultDxCode(true))}
        />
        {dxCodeDialog}
      </>
    )
  }

  public openDialog = (dxCode: IDxCode) => () => {
    this.props.openDialogWithDxCode!(dxCode)
  }

  public onRowClick = (
    _event: React.MouseEvent<Element>,
    dxCode: IDxCode,
    _idx: number
  ) => {
    this.props.openDialogWithDxCode!(dxCode)
  }
}

export default DxCodes
