import {
  createStyles,
  Grid,
  Paper,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { GridDirection, GridItemsAlignment } from '@material-ui/core/Grid'
import * as React from 'react'

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      margin: 4,
      padding: spacing(2),
      paddingTop: 10,
    },
    tableHeader: {
      height: 60,
      marginBottom: spacing(1),
      marginLeft: spacing(1),
    },
    tableHeaderTitle: {
      fontSize: '20px',
      paddingTop: '4px',
    },
  })

interface ICardWithNoTitleProps extends WithStyles {
  formGridDirection?: GridDirection
  formGridAlignItems?: GridItemsAlignment
  headerStyle?: React.CSSProperties
  fullHeight?: boolean
  onClick?: () => void
}

const CardWithNoTitle: React.SFC<ICardWithNoTitleProps> = ({
  children,
  classes,
  formGridAlignItems = 'center',
  formGridDirection = 'row',
  fullHeight,
}) => {
  return (
    <Paper className={classes.paper} style={{ height: fullHeight ? '100%' : 'auto' }}>
      <Grid container direction="column" spacing={2}>
        <Grid
          container
          item
          direction={formGridDirection}
          xs={12}
          spacing={1}
          alignItems={formGridAlignItems}
          style={{ marginLeft: 0, paddingTop: '15px' }} // this is needed because material UI grid spacing applies -8px margin.
        >
          {children}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withStyles(styles)(CardWithNoTitle)
