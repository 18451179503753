export const CostSourceText = {
  0: 'ASC',
  1: 'Billed Charges %',
  2: 'Carve Out',
  3: 'CMS',
  4: 'OPPS',
  5: 'SCA',
  6: 'State Fee Schedule',
  7: 'Other',
}
