import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { CheckCircle, Close, Info, Warning } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from 'src/Stores'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { ProcessReceivedCheckResults } from 'src/viewModels/ProcessReceivedCheckResult'

interface IProcessReceivedCheckModalProps {
  closeModal?: () => void
  isOpen?: boolean
  isVerifyOpen?: boolean
  results?: ProcessReceivedCheckResults
}

class ProcessReceivedCheckModel extends React.Component<IProcessReceivedCheckModalProps> {
  public render() {
    return (
      <Dialog
        key={this.props.results?.checkId}
        fullWidth={true}
        maxWidth="lg"
        open={this.props.isOpen! || this.props.isVerifyOpen!}
      >
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title={this.props.isOpen! ? 'Payment Applied' : 'Check Details'}
            pageIcon={<Info />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent style={{ paddingBottom: '10px' }}>
          <Grid
            container
            direction="row"
            item
            xs={12}
            style={{ textAlign: 'center', justifyContent: 'center' }}
          >
            <Grid item style={{ marginTop: '16px', display: 'flex' }}>
              {this.props.results?.applyPaymentResponse.includes('distributed') ? (
                this.props.isOpen! ? (
                  <CheckCircle
                    alignmentBaseline="baseline"
                    style={{ color: 'green', marginRight: '8px' }}
                  />
                ) : (
                  <Close
                    alignmentBaseline="baseline"
                    style={{ color: 'red', marginRight: '8px' }}
                  />
                )
              ) : this.props.results?.validateCheckResponse.includes('new') ? (
                <></>
              ) : (
                <Warning
                  alignmentBaseline="baseline"
                  style={{ color: '#ffa726', marginRight: '8px' }}
                />
              )}
              <Typography
                variant={'body1'}
                color="textPrimary"
                style={{ height: '18px' }}
              >
                {this.props.isOpen!
                  ? this.props.results?.applyPaymentResponse
                  : this.props.results?.validateCheckResponse}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private close = () => {
    this.props.closeModal!()
  }
}

const InjectedProcessReceivedCheckModel = inject<
  IStores,
  IProcessReceivedCheckModalProps,
  Partial<IProcessReceivedCheckModalProps>,
  any
>((stores: IStores) => ({
  closeModal: stores.receivePayments.closeProcessReceivedCheck,
  isOpen: stores.receivePayments.isProcessReceivedCheckOpen,
  isVerifyOpen: stores.receivePayments.isVerifyReceivedCheckOpen,
  results: stores.receivePayments.checkResults,
}))(ProcessReceivedCheckModel)

export default InjectedProcessReceivedCheckModel
