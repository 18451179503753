import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { Check, Settings } from '@material-ui/icons'
import * as React from 'react'
import { IColumnSetting } from './ESDataTable'

export interface IESDataTableSettingsButton {
  columns: IColumnSetting[]
  toggleVisibilityOfColumn: (column: IColumnSetting, index: number) => void
}

class ESDataTableSettingsButton extends React.Component<IESDataTableSettingsButton> {
  public state = {
    isToggleColumnMenuOpen: false,
    toggleColumnMenuAnchorEl: null,
  }

  public render() {
    return (
      <>
        <IconButton onClick={this.toggleColumnsMenu} style={{ padding: 0 }}>
          <Settings />
        </IconButton>
        <Menu
          anchorEl={this.state.toggleColumnMenuAnchorEl}
          open={this.state.isToggleColumnMenuOpen}
          onClose={this.toggleColumnsMenu}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
        >
          {this.props.columns.map((column: IColumnSetting, index: number) => (
            <MenuItem key={index} onClick={this.toggleVisibilityOfColumn(column, index)}>
              {column.isVisible ? <Check /> : ''}
              {column.columnName}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  }

  private toggleColumnsMenu = (event: React.MouseEvent) => {
    let toggleColumnMenuAnchorEl = null
    if (!this.state.isToggleColumnMenuOpen) {
      toggleColumnMenuAnchorEl = event.currentTarget
    }
    this.setState({
      isToggleColumnMenuOpen: !this.state.isToggleColumnMenuOpen,
      toggleColumnMenuAnchorEl,
    })
  }

  private toggleVisibilityOfColumn =
    (column: IColumnSetting, index: number) => (event: React.MouseEvent) => {
      event.stopPropagation()
      this.props.toggleVisibilityOfColumn(column, index)
    }
}

export default ESDataTableSettingsButton
