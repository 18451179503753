import _ from 'lodash'
import Moment from 'moment'
import { IHaveAddressFormValues } from '../common/AddressField/AddressField'
import {
  createAddressFormValues,
  toAddressDto,
} from '../common/AddressField/AddressFormValues'
import { DefaultLocation, ILocation } from '../Definitions'
import {
  IProviderTypeDto,
  LocationProcedureDto,
  ProcedureDto,
  ProviderTypeDto,
} from '../generated_client'
import uuid from 'uuid'
import { IProviderTypeFormValues } from '../providers/ProviderFormValues'

export interface ILocationFormValues extends IHaveAddressFormValues {
  readonly id: string
  name: string
  npi: string
  email?: string
  faxNumber?: string
  locationProcedures: ILocationProcedureFormValues[]
  phoneNumber?: string
  providerLocationComments?: string
  providerContractComments?: string
  requiresSingleCaseAgreement: boolean
  providerTypeIds: IProviderTypeFormValues[]
  providerTypes: IProviderTypeFormValues[]
}

export interface ILocationProcedureFormValues {
  readonly id: string
  contractPrice?: number | string
  locationId?: string
  procedureId?: string
  procedure?: ProcedureDto
  createdOn?: Date
  usesSecondProvider: boolean
  isActive?: boolean
  tesla?: number
  weightLimit?: number
  otherBilling?: string
}

export function setDefaultValuesLocationProcedureFormValues(): ILocationProcedureFormValues {
  return {
    contractPrice: '',
    createdOn: new Date(),
    id: uuid(),
    isActive: true,
    locationId: undefined,
    otherBilling: undefined,
    procedure: undefined,
    procedureId: undefined,
    tesla: undefined,
    usesSecondProvider: false,
    weightLimit: undefined,
  }
}

function setValuesFromLocationProcedure(
  locationProcedure: LocationProcedureDto
): ILocationProcedureFormValues {
  return {
    contractPrice: locationProcedure.contractPrice || 0,
    createdOn: locationProcedure.createdOn || new Date(),
    id: locationProcedure.id || '',
    isActive: locationProcedure.isActive,
    locationId: locationProcedure.locationId || undefined,
    otherBilling: locationProcedure.otherBilling || undefined,
    procedure: locationProcedure.procedure || undefined,
    procedureId: locationProcedure.procedureId || undefined,
    tesla: locationProcedure.tesla || undefined,
    usesSecondProvider: locationProcedure.usesSecondProvider || false,
    weightLimit: locationProcedure.weightLimit || undefined,
  }
}

export function createILocationProcedureFormValues(
  locationProcedure?: LocationProcedureDto
): ILocationProcedureFormValues {
  if (locationProcedure === undefined) {
    return setDefaultValuesLocationProcedureFormValues()
  } else {
    return setValuesFromLocationProcedure(locationProcedure)
  }
}

function setDefaultValues(): ILocationFormValues {
  return {
    billingAddress: createAddressFormValues(),
    email: '',
    faxNumber: '',
    id: '',
    isBillingAddressSameAsPhysicalAddress: false,
    locationProcedures: [setDefaultValuesLocationProcedureFormValues()],
    name: '',
    npi: '',
    phoneNumber: '',
    physicalAddress: createAddressFormValues(),
    providerContractComments: '',
    providerLocationComments: '',
    providerTypeIds: [] as IProviderTypeFormValues[],
    providerTypes: [] as IProviderTypeFormValues[],
    requiresSingleCaseAgreement: false,
  }
}

function setValuesFromLocation(
  location: ILocation,
  allProviderTypes?: IProviderTypeDto[]
): ILocationFormValues {
  const selectedProviders = allProviderTypes
    ? allProviderTypes.map((x) => setValuesFromProviderType(x))
    : []

  selectedProviders.forEach((element) => {
    const providerTypesSelected = location.providerTypes ? location.providerTypes : []
    element.checked = providerTypesSelected.some((x) => x.id === element.id)
  })

  return {
    billingAddress: createAddressFormValues(location.billingAddress),
    email: location.email || '',
    faxNumber: location.faxNumber || '',
    id: location.id || '',
    isBillingAddressSameAsPhysicalAddress:
      location.isBillingAddressSameAsPhysicalAddress || false,
    locationProcedures:
      location.locationProcedures && location.locationProcedures.length > 0
        ? _.orderBy(
            location.locationProcedures.map((x) => createILocationProcedureFormValues(x)),
            (x) => Moment(x.createdOn!),
            ['asc']
          )
        : [setDefaultValuesLocationProcedureFormValues()],
    name: location.name || '',
    npi: location.npi || '',
    phoneNumber: location.phoneNumber || '',
    physicalAddress: createAddressFormValues(location.physicalAddress),
    providerContractComments: location.providerContractComments || '',
    providerLocationComments: location.providerLocationComments || '',
    providerTypeIds: selectedProviders ? selectedProviders : [],
    providerTypes: location.providerTypes
      ? location.providerTypes.map((x) => setValuesFromProviderType(x))
      : [],
    requiresSingleCaseAgreement: location.requiresSingleCaseAgreement,
  }
}

export function createILocationFormValues(
  location?: ILocation,
  providerTypes?: IProviderTypeDto[]
): ILocationFormValues {
  if (location === undefined) {
    return setDefaultValues()
  } else {
    return setValuesFromLocation(location, providerTypes)
  }
}

export function setValuesFromProviderType(
  providerType: IProviderTypeDto
): IProviderTypeFormValues {
  return { ...providerType } as IProviderTypeFormValues
}

export async function toILocation(
  formValues: ILocationFormValues,
  original?: ILocation
): Promise<ILocation> {
  let location = original === undefined ? DefaultLocation() : original
  const physicalAddress = await toAddressDto(formValues.physicalAddress!)
  if (location!.physicalAddress?.id != undefined) {
    physicalAddress.id = location!.physicalAddress!.id
  }
  // TECH DEBT: This functionality is NOT working and doesn't follow the rules we are now
  // setting for One billing address per location
  const billingAddress = formValues.isBillingAddressSameAsPhysicalAddress
    ? await toAddressDto(formValues.physicalAddress!)
    : await toAddressDto(formValues.billingAddress!)
  billingAddress.id = formValues.billingAddress!.id
  const checkedTypes = formValues.providerTypeIds.filter((x) => x.checked)
  return {
    ...location,
    billingAddress,
    email: formValues.email || '',
    faxNumber: formValues.faxNumber || '',
    id: formValues.id,
    isBillingAddressSameAsPhysicalAddress:
      formValues.isBillingAddressSameAsPhysicalAddress!,
    locationProcedures: formValues.locationProcedures
      .filter((x) => x.procedureId !== undefined)
      .map(
        (x: ILocationProcedureFormValues) =>
          new LocationProcedureDto({
            ...x,
            contractPrice:
              x.contractPrice && typeof x.contractPrice === 'number'
                ? (x.contractPrice as number)
                : parseInt(x.contractPrice! as string, 10),
            procedureId: x.procedureId,
            usesSecondProvider: x.usesSecondProvider,
          })
      ),
    name: formValues.name,
    npi: formValues.npi,
    phoneNumber: formValues.phoneNumber || '',
    physicalAddress,
    providerContractComments: formValues.providerContractComments || '',
    providerLocationComments: formValues.providerLocationComments || '',
    providerTypes: checkedTypes.length > 0 ? checkedTypes.map(toProviderTypeDto) : [],
    requiresSingleCaseAgreement: formValues.requiresSingleCaseAgreement,
  }
}

export function toProviderTypeDto(providerTypeId: IProviderTypeFormValues) {
  return new ProviderTypeDto({
    id: providerTypeId.id,
  })
}
