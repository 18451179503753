import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { inject } from 'mobx-react'
import React from 'react'
// tslint:disable-next-line: no-var-requires
const CurrencyFormat: any = require('react-currency-format')
import { AttachMoney, Warning } from '@material-ui/icons'
import { Form, Formik, FormikActions } from 'formik'
import _ from 'lodash'
import { Prompt } from 'react-router-dom'
import * as Yup from 'yup'
import FullWidthField from '../../common/FullWidthField'
import ResponsiveAppBar from '../../common/ResponsiveAppBar'
import { ICheck } from '../../Definitions'
import {
  createICheckFromProvider,
  IProviderFormValues,
  toIApplyPayment,
} from '../../providers/ProviderFormValues'
import { IStores } from '../../Stores'
import { IBillTableFormValues } from '../billsTable/BillTableFormValues'
import { applyPayments } from 'src/services/ClaimService'
import { IApplyPaymentRequest } from 'src/viewModels/ApplyPaymentRequest'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { SentCheckSummaryResult } from 'src/viewModels/SentCheckSummaryResult'
import AssignedCheckModel from 'src/bills/writeChecks/AssignedCheckModal'

const styles = ({ spacing }: Theme) =>
  createStyles({
    buttonGrid: {
      marginTop: '20px',
    },
    paperTable: {
      overflow: 'auto',
    },
    sidebar: {
      height: '100%',
    },
    table: {
      marginTop: '20px',
    },
    form: {
      padding: spacing(2),
    },
  })

const CheckSchema = Yup.object().shape({
  providers: Yup.array().of(
    Yup.object({
      checkNumber: Yup.string().required('Check Number is required'),
    })
  ),
})

export interface IWriteChecksProps extends WithStyles<typeof styles> {
  selectedBills?: IBillTableFormValues
  previewEOB?: (check: ICheck) => Promise<void>
  rerouteToPath?: (path: string) => void
  saveCheck?: (check: ICheck) => Promise<void>
  openAssignedCheck?: (results: SentCheckSummaryResult[]) => void
}

class WriteChecks extends React.Component<IWriteChecksProps> {
  public state = {
    isSaved: false,
    reload: false,
    checkDates: new Array<string>(),
  }

  public handleSave = (submitForm: () => void, reload: boolean) => () => {
    this.setState({ reload }, () => {
      submitForm()
    })
  }

  private save = async (
    values: IBillTableFormValues,
    formikBag: FormikActions<IBillTableFormValues>
  ) => {
    formikBag.setSubmitting(true)
    values.providers.forEach((x, idx) => (x.datePaid = this.state.checkDates[idx]))

    // This is loading checks the old way to work with the existing previewEOB
    // will need to be removed when we change how it works.
    const checks = values.providers.map((x) => createICheckFromProvider(x))
    let listOfPayments: IApplyPaymentRequest[] = []

    values.providers.forEach((x) => {
      listOfPayments = listOfPayments.concat(toIApplyPayment(x))
    })

    applyPayments(listOfPayments)
      .then((_response: SentCheckSummaryResult[]) => {
        formikBag.setSubmitting(false)
        this.setState({ isSaved: true, saveChecks: checks })
        this.props.openAssignedCheck!(_response)
      })
      .catch(() => formikBag.setSubmitting(false))
  }

  public componentDidMount() {
    this.setState({ reload: false })
    if (!this.props.selectedBills) {
      this.props.rerouteToPath!('/payproviders')
    } else {
      this.setState({
        checkDates: new Array<string>(this.props.selectedBills!.providers!.length).fill(
          moment(new Date()).format('yyyy-MM-DD')
        ),
      })
    }
  }

  private calculateProviderTotal(provider: IProviderFormValues) {
    let total = 0

    provider
      .cmS1500s!.filter((x) => x.isChecked)
      .forEach((y) => {
        total += y.q24
          .map((z) => (z.overrideAmount ? z.overrideAmount : +z.contractAmount!))
          .reduce((a, b) => a + b, 0)
      })

    return total
  }

  private cancel = () => {
    this.props.rerouteToPath!('/payproviders')
  }

  private getTotal(values: IBillTableFormValues) {
    let total = 0

    values.providers.forEach((x) => {
      total += this.calculateProviderTotal(x)
    })

    return total
  }

  private handleCheckDateChange = (event: any, idx: number) => {
    this.state.checkDates[idx] = event.target.value
    this.setState({ checkDates: this.state.checkDates })
  }

  public render() {
    const { classes, selectedBills } = this.props
    return (
      <>
        <Helmet>
          <title>{'Check Summary'}</title>
        </Helmet>
        {selectedBills ? (
          <Formik
            initialValues={selectedBills!}
            onSubmit={this.save}
            enableReinitialize={true}
            validationSchema={CheckSchema}
          >
            {({ values, submitForm, isValid, isSubmitting, dirty, submitCount }) => (
              <>
                {isSubmitting && (
                  <div
                    style={{
                      position: 'fixed',
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: 800,
                    }}
                  >
                    <CircularProgress size={100} />
                  </div>
                )}

                <Prompt
                  when={dirty && submitCount === 0}
                  message="Are you sure you want to leave the page? There are unsaved changes."
                />
                <ResponsiveAppBar title={'Assign Checks'} pageIcon={<AttachMoney />}>
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <AssignedCheckModel />
                    <Grid item>
                      <Button size="small" variant="contained" onClick={this.cancel}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        color="secondary"
                        variant="contained"
                        disabled={!isValid || isSubmitting || this.state.isSaved}
                        onClick={this.handleSave(submitForm, false)}
                      >
                        Assign Checks
                      </Button>
                    </Grid>
                  </Grid>
                </ResponsiveAppBar>
                <Form className={classes.form}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    style={{ height: '100%' }}
                  >
                    <Grid item xs={12} spacing={2} className={classes.table}>
                      <Paper>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Location Name</TableCell>
                              <TableCell>Billing Information</TableCell>
                              <TableCell>Claims Paid</TableCell>
                              <TableCell align="right">Amount</TableCell>
                              <TableCell>Check Number*</TableCell>
                              <TableCell>Check Date*</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.providers.map((provider, idx) => (
                              <TableRow key={provider.id}>
                                <TableCell component="th" scope="row">
                                  {provider.name}
                                </TableCell>
                                <TableCell>
                                  {provider.billingAddress?.name != undefined &&
                                  provider.billingAddress?.name != '' ? (
                                    <>
                                      <strong>{provider.billingAddress?.name}</strong>
                                      <br></br>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <span>
                                    {provider.billingAddress?.addressDisplay}
                                    &nbsp;&nbsp;
                                    {provider.billingAddress?.addressDisplay != '' &&
                                    (provider.billingAddress?.line1 == undefined ||
                                      provider.billingAddress.line1 == '' ||
                                      provider.billingAddress.city == undefined ||
                                      provider.billingAddress.city == '' ||
                                      provider.billingAddress.state == undefined ||
                                      provider.billingAddress.zip == '' ||
                                      provider.billingAddress.zip == undefined) ? (
                                      <Tooltip title="Incomplete billing address">
                                        <Warning style={{ color: '#ffc107' }} />
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  {provider.cmS1500s!.filter((x) => x.isChecked).length}
                                </TableCell>
                                <TableCell align="right">
                                  <CurrencyFormat
                                    value={this.calculateProviderTotal(provider)}
                                    thousandSeparator={true}
                                    displayType={'text'}
                                    prefix={'$'}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                  />
                                </TableCell>
                                <TableCell>
                                  <FullWidthField
                                    required
                                    name={`providers[${idx}].checkNumber`}
                                  />
                                </TableCell>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <TextField
                                      inputProps={{ id: 'datePaid' }}
                                      InputLabelProps={{ shrink: true }}
                                      type="date"
                                      name={`providers[${idx}].datePaid`}
                                      variant="outlined"
                                      value={this.state.checkDates[idx]}
                                      onChange={(e) => this.handleCheckDateChange(e, idx)}
                                    />
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={3} align="right">
                                <Typography variant="body2">
                                  <strong>Total:</strong>
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="body2">
                                  <strong>
                                    <CurrencyFormat
                                      value={this.getTotal(values)}
                                      thousandSeparator={true}
                                      displayType={'text'}
                                      prefix={'$'}
                                      fixedDecimalScale={true}
                                      decimalScale={2}
                                    />
                                  </strong>
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={2}></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </>
    )
  }
}

const InjectedWriteChecks = inject<
  IStores,
  IWriteChecksProps,
  Partial<IWriteChecksProps>,
  any
>((stores: IStores) => ({
  previewEOB: stores.checks.previewEOB,
  rerouteToPath: stores.global.rerouteToPath,
  saveCheck: stores.checks.saveCheck,
  selectedBills: stores.billsStore.selectedBills,
  openAssignedCheck: stores.billsStore.openAssignedCheckDialog,
}))(withStyles(styles)(WriteChecks))

export default InjectedWriteChecks
