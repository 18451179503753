import Axios from 'axios'
import createPdfObjUrl from 'src/utils/CreatePdfObjUrl'
import { PendingCheckRequest } from 'src/viewModels/PendingCheckRequest'
import { CheckCorrectionRequest } from 'src/viewModels/CheckCorrectionRequest'
import { ReceivedCheckDetailSummary } from 'src/viewModels/ReceivedCheckDetailSummary'

const base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:5000'

export async function regenerateEOB(id: string) {
  const response = await Axios.post<string>(`${base_url}/api/check/${id}/regenerate-eob`)
  window.open(createPdfObjUrl(response.data), '_blank')
  return response.data
}

export async function processPendingChecks(listOfChecks: Array<PendingCheckRequest>) {
  const response = await Axios.post<Array<PendingCheckRequest>>(
    `${base_url}/api/check/process-pending-checks`,
    listOfChecks
  )
  return response.data
}

export async function sentCheckCorrection(
  id: string,
  req: CheckCorrectionRequest
): Promise<string> {
  const response = await Axios.post<string>(`${base_url}/api/check/${id}/correction`, req)
  return response.data
}

export async function voidSentCheck(id: string): Promise<string> {
  const response = await Axios.post<string>(`${base_url}/api/check/${id}/void-check`)
  return response.data
}

export async function unapplyReceivedCheck(id: string): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/check/${id}/unapply-received-check`
  )
  return response.data
}

export async function getReceivedCheckAppliedFundsDetail(
  id: string
): Promise<ReceivedCheckDetailSummary> {
  const response = await Axios.get<ReceivedCheckDetailSummary>(
    `${base_url}/api/check/${id}/received-applied-funds`
  )
  return response.data
}

export async function GetUnappliedReceivedCheckHistory(
  id: string
): Promise<ReceivedCheckDetailSummary> {
  const response = await Axios.get<ReceivedCheckDetailSummary>(
    `${base_url}/api/check/${id}/received-unapplied-history`
  )
  return response.data
}

export async function markAsDeletedReceivedCheck(id: string): Promise<string> {
  const response = await Axios.post<string>(
    `${base_url}/api/check/${id}/mark-received-check-deleted`
  )
  return response.data
}
